export interface AgPracticesSchemaChild {
  name: string
  id: string
  path?: string
  type?: string
  items?: { [key: string]: AgPracticesSchemaChild }
  description?: string
  enum?: string[]
  children: AgPracticesSchemaChild[]
}

interface AgPracticesSchema {
  name: string
  children: AgPracticesSchemaChild[]
}

export const agPracticesSchema: AgPracticesSchema = {
  name: 'AgPracticesSchema',
  children: [
    {
      name: 'Crop & Soil',
      id: 'crop_and_soil',
      children: [
        {
          name: 'Crop details',
          id: 'crop_details',
          type: 'text',
          description: 'Information about crop details',
          children: [
            {
              name: 'Crop name',
              id: 'crop_name',
              children: [],
            },
            {
              name: 'Harvest year',
              id: 'harvest_year',
              children: [],
            },
            {
              name: 'Crop area',
              id: 'crop_area',
              children: [],
            },
            {
              name: 'Harvested amount (total)',
              id: 'harvested_amount',
              children: [],
            },
          ],
        },
        {
          name: 'Soil characteristics',
          id: 'soil_characteristics',
          type: 'text',
          description: 'Information about soil characteristics',
          children: [],
        },
      ],
    },
    {
      name: 'Inputs',
      id: 'inputs',
      children: [
        {
          name: 'Fertilizer inputs',
          id: 'fertilizer_inputs',
          type: 'text',
          description: 'Information about fertilizer inputs',
          children: [
            {
              name: 'Fertilizer type',
              id: 'fertilizer_type',
              path: 'fertiliser.type',
              type: 'string',
              enum: ['Organic', 'Synthetic'],
              children: [],
            },
          ],
        },
        {
          name: 'Crop protection inputs',
          id: 'crop_protection_inputs',
          type: 'text',
          description: 'Information about crop protection inputs',
          children: [
            {
              name: 'Crop protection applications',
              id: 'crop_protection_applications',
              type: 'array',
              items: {
                type: {
                  name: 'Crop protection type',
                  id: 'crop_protection_type',
                  path: 'crop_protection.type',
                  type: 'string',
                  enum: ['Organic', 'Synthetic'],
                  children: [],
                },
              },
              children: [],
            },
          ],
        },
      ],
    },
    {
      name: 'Fuel & Energy',
      id: 'fuel_and_energy',
      children: [],
    },
    {
      name: 'Irrigation',
      id: 'irrigation',
      children: [],
    },
    {
      name: 'Carbon',
      id: 'carbon',
      children: [],
    },
    {
      name: 'Farm Settings',
      id: 'farm_settings',
      children: [],
    },
  ],
}
