import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { faFlagSwallowtail } from '@fortawesome/pro-solid-svg-icons'
import { Box, CircularProgress, Stack, Text } from '@howgood/design'

import {
  initializeInitiativeDetail,
  selectCurrentInitiative,
  selectCurrentInitiativeBaseline,
  selectisInitiativesLoading,
} from '@/state/initiatives'
import { ChartPanel } from '../ChartPanel/ChartPanel'
import { ScenarioGrid } from '../ScenarioGrid/ScenarioGrid'
import { ExportBaseline } from './ExportBaseline'
import { DebugPanel } from '../DebugPanel'
import { selectDebugToolsSplit } from '@/state/splitio'
import { ScoreTypeSelector } from '@/components/Scores/ScoreTypeSelector'
import { InitiativesMenu } from '../Initiatives/InitiativesMenu'
import { Heading } from '@/components/Layout/Heading'

export const InitiativeDetail = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const params: { initiative: string } = useParams()
  const initiativeId = +params.initiative
  const baseline = useSelector(selectCurrentInitiativeBaseline)
  const isLoading = useSelector(selectisInitiativesLoading)
  const isDebugSplitEnabled = useSelector(selectDebugToolsSplit)
  const initiative = useSelector(selectCurrentInitiative)

  useEffect(() => {
    dispatch(initializeInitiativeDetail(initiativeId, history))
  }, [dispatch, initiativeId, history])

  if (isLoading || !initiative?.id) {
    return (
      <Stack alignItems="center" mt={8}>
        <CircularProgress />
        <Box mt={2}>
          <Text>Loading initiative</Text>
        </Box>
      </Stack>
    )
  }

  return (
    <Stack gap={0.5}>
      <Heading
        title={initiative.name}
        highlightedBreadcrumbs
        breadCrumbs={[{ url: '/initiatives', title: 'Initiatives', icon: faFlagSwallowtail }]}
        actions={
          <Stack direction="row" alignItems="center" gap={1}>
            <ExportBaseline baseline={baseline} />
            <InitiativesMenu initiative={initiative} />
          </Stack>
        }
      />
      <Stack
        bgcolor="primary.paperMain"
        px={5.25}
        pb={5.75}
        pt={2}
        mx="-50px" // Background bleeds beyond the container
      >
        <Stack width="100%" alignItems="flex-end" mb={1}>
          <ScoreTypeSelector />
        </Stack>
        <ChartPanel />
      </Stack>
      <ScenarioGrid />
      {isDebugSplitEnabled && <DebugPanel />}
    </Stack>
  )
}
