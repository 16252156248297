import React, { FC } from 'react'
import { Area, AreaChart, ReferenceLine, XAxis, YAxis } from 'recharts'
import { theme } from '@howgood/design'
import { IndustryBenchmarkData } from '@/api/elastic/industryBenchmarkEsRepo'
import { createUseStyles } from 'react-jss'
import { IImpactMetricOption } from '@/records'
import { Loader } from '@/components'
import { roundBy } from '@/utils/numbers'
import { transformScoreToZeroToTenScale } from '@/utils/impactScoreHelpers'
import { RAW_METRIC_FIELDS } from '@/constants/impactScore'
import { CategoryType } from '@/components/ProductImpact/ExportProductOverview/CategoryType'

interface IndustryBenchmarkProps {
  data: IndustryBenchmarkData | null
  impactScore: number
  comparisonProductImpactScore: number
  rawImpact: number
  comparisonProducRawImpact: number
  productName: string
  comparisonProductName: string
  categoryLabel: string
  categoryType: CategoryType
  onReady?: () => void
  metric: IImpactMetricOption
  loading: boolean
  unit: string | null
  hidePortfolioLine?: boolean
  hideProductLine?: boolean
  hideIndustry?: boolean
  porfolioTitle?: string
}

const useStyles = createUseStyles({
  wrapper: {
    position: 'relative',
    backgroundColor: '#F6F6F6',
    borderRadius: '8px',
    padding: '16px 16px 0 16px',
    height: '359px',
  },
  bar: {
    width: '1250px',
    height: '8px',
    background: 'linear-gradient(90deg, #C36C60 0%, #E09E5F 25%, #F1D36B 50.44%, #A1B667 76.67%, #7D8C64 100%)',
    borderRadius: '16px',
  },
  barWrapper: {
    position: 'absolute',
    top: '316px',
    left: '36px',
    width: '1250px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  },
  barLabel: {
    fontFamily: theme.typography.display.fontFamily,
    fontWeight: theme.typography.display.fontWeight,
    paddingTop: '4px',
    fontSize: '14px',
  },
  regenerative: {
    extend: 'barLabel',
    color: '#7E8D65',
  },
  degenerative: {
    extend: 'barLabel',
    color: '#C46D61',
  },
  xAxisLabels: {
    position: 'absolute',
    top: '328px',
    left: '32px',
    width: '1250px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    color: '#BFBFBF',
    fontSize: '14px',
    fontFamily: theme.typography.display.fontFamily,
    fontWeight: theme.typography.display.fontWeight,
  },
  labelsOverlay: {
    position: 'absolute',
    display: 'flex',
    top: '86px',
    left: '36px',
    fontSize: '18px',
    fontFamily: theme.typography.fontFamily,
  },
  comparisonProductLine: {
    top: 64,
  },
  labelMid: {
    position: 'absolute',
    transform: 'translate(-50%, -5px)',
    whiteSpace: 'nowrap',
    textAlign: 'center',
  },
  labelRight: {
    position: 'absolute',
    transform: 'translate(0, 22px)',
    paddingLeft: '20px',
    whiteSpace: 'break-spaces',
  },
  labelLeft: {
    position: 'absolute',
    transform: 'translate(-100%, 22px)',
    paddingRight: '10px',
    whiteSpace: 'break-spaces',
    textAlign: 'right',
  },
  bold: {
    fontFamily: theme.typography.display.fontFamily,
    fontWeight: theme.typography.display.fontWeight,
  },
  header: {
    fontFamily: theme.typography.display.fontFamily,
    fontWeight: theme.typography.display.fontWeight,
    fontSize: '22px',
    lineHeight: '26px',
    marginBottom: '2px',
  },
  subheader: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '14px',
    marginBottom: '14px',
  },
  categoryLabel: {
    fontSize: '14px',
    position: 'relative',
    top: '-4px',
  },
  twoLinesLabelMid: {
    position: 'absolute',
    whiteSpace: 'nowrap',
    textAlign: 'center',
    transform: 'translate(-50%, -22px)',
  },
  yAxisLabel: {
    position: 'absolute',
    top: 200,
    left: -50,
    color: '#BFBFBF',
    transform: 'rotate(-90deg)',
    fontSize: '14px',
    fontFamily: theme.typography.fontFamily,
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
})

interface ReferenceLineProps {
  type: string
  impactScore: number
  className?: string
  labelOffset: number
  width?: number
  rawValue: number
  lineXPosition: number
  formattedValue: number
}
const compareImpactScore = (a: ReferenceLineProps, b: ReferenceLineProps) => a.impactScore - b.impactScore
const compareRawMetrics = (a: ReferenceLineProps, b: ReferenceLineProps) => b.rawValue - a.rawValue

const useChartData = (
  data: IndustryBenchmarkData,
  metric: IImpactMetricOption,
  rawImpact: number,
  impactScore: number,
  unit: string | null,
  classes: ReturnType<typeof useStyles>
) => {
  const { hgPortfolioAverage, avgRetailAverage } = data

  let precision = 0
  if (unit) {
    precision = 2
  } else if (metric.value !== 'howgood_one_metric') {
    precision = 1
  }

  const widthCoeff = 1250 / data.interval / 10 // chart is 1250px wide, calculate what value is 1px
  const labelOffset = -16 // adjusted for chart padding

  const productLabelOffset = impactScore * widthCoeff + labelOffset

  const scaledPortfolioScore = RAW_METRIC_FIELDS.includes(metric.value)
    ? transformScoreToZeroToTenScale(metric.value, hgPortfolioAverage.raw)
    : hgPortfolioAverage.score

  const scaledRetailScore = RAW_METRIC_FIELDS.includes(metric.value)
    ? transformScoreToZeroToTenScale(metric.value, avgRetailAverage.raw)
    : avgRetailAverage.score

  const referenceLines: ReferenceLineProps[] = [
    {
      type: 'product',
      impactScore: impactScore,
      labelOffset: productLabelOffset,
      rawValue: rawImpact,
      lineXPosition: impactScore,
      formattedValue: rawImpact,
    },
    {
      type: 'allFoodIndustry',
      impactScore: scaledRetailScore,
      labelOffset: scaledRetailScore * widthCoeff + labelOffset,
      rawValue: avgRetailAverage.raw,
      lineXPosition: scaledRetailScore,
      formattedValue: roundBy(avgRetailAverage.raw, precision),
    },
    {
      type: 'portfolio',
      impactScore: scaledPortfolioScore,
      labelOffset: scaledPortfolioScore * widthCoeff + labelOffset,
      rawValue: hgPortfolioAverage.raw,
      lineXPosition: scaledPortfolioScore,
      formattedValue: roundBy(hgPortfolioAverage.raw, precision),
    },
  ].sort(RAW_METRIC_FIELDS.includes(metric.value) ? compareRawMetrics : compareImpactScore)

  // raw metrics are scaled to 1-10 score resulting to integer values only, that would place them on the same spot in the chart. Following logic adjusts the position on tha chart based on RAW value
  if (RAW_METRIC_FIELDS.includes(metric.value)) {
    // in case first 2 metrics have same impact score but their raw value differs, move the lower metric to the left
    if (
      referenceLines[0].labelOffset === referenceLines[1].labelOffset &&
      referenceLines[0].impactScore > 0 &&
      referenceLines[0].rawValue !== referenceLines[1].rawValue
    ) {
      referenceLines[0].labelOffset = referenceLines[0].labelOffset - widthCoeff / 4
      referenceLines[0].lineXPosition = referenceLines[0].lineXPosition - 0.25
    }

    // in case second 2 metrics have same impact score but their raw value differs, move the higher metric to the right
    if (
      referenceLines[1].labelOffset === referenceLines[2].labelOffset &&
      referenceLines[1].impactScore < 10 &&
      referenceLines[1].rawValue !== referenceLines[2].rawValue
    ) {
      referenceLines[2].labelOffset = referenceLines[2].labelOffset + widthCoeff / 4
      referenceLines[2].lineXPosition = referenceLines[2].lineXPosition + 0.25
    }
  }
  // set properties for left-most label
  referenceLines[0].className = referenceLines[0].labelOffset >= 150 ? classes.labelLeft : classes.labelRight
  referenceLines[0].width = Math.max(referenceLines[0].labelOffset, 200)

  // set properties for middle label
  referenceLines[1].className = classes.labelMid

  // set properties for right-most label
  referenceLines[2].className = referenceLines[2].labelOffset <= 1100 ? classes.labelRight : classes.labelLeft
  referenceLines[2].width = Math.max(1250 - referenceLines[2].labelOffset, 200)

  const productSeriesProps = referenceLines.find((i) => i.type === 'product')
  const allIndustrySeriesProps = referenceLines.find((i) => i.type === 'allFoodIndustry')
  const portfolioSeriesProps = referenceLines.find((i) => i.type === 'portfolio')

  if (allIndustrySeriesProps.className === classes.labelMid) {
    allIndustrySeriesProps.className = classes.twoLinesLabelMid
  }

  return { productSeriesProps, allIndustrySeriesProps, portfolioSeriesProps }
}

export const IndustryBenchmark: FC<IndustryBenchmarkProps> = ({
  data,
  impactScore,
  comparisonProductImpactScore,
  rawImpact,
  comparisonProducRawImpact,
  productName,
  comparisonProductName,
  categoryLabel,
  categoryType,
  onReady,
  metric,
  loading,
  unit,
  hidePortfolioLine = false,
  hideProductLine = false,
  hideIndustry = false,
  porfolioTitle,
}) => {
  const classes = useStyles()

  // WORKAROUND - in order to trigger, onAnimationEnd event, we need to provide unique key each render, otherwise the export would work only once
  const animationKey = (Math.random() * 10000).toFixed(0)

  const { portfolioSeriesProps, productSeriesProps, allIndustrySeriesProps } = useChartData(
    data,
    metric,
    rawImpact,
    impactScore,
    unit,
    classes
  )
  const { productSeriesProps: comparisonProductSeriesProps } = useChartData(
    data,
    metric,
    comparisonProducRawImpact,
    comparisonProductImpactScore,
    unit,
    classes
  )

  const yMax = Math.max(
    ...data.distribution.map((v) => v.averageRetail),
    ...data.distribution.map((v) => v.hgPortfolio)
  )

  const handleIsReady = () => {
    if (onReady) {
      onReady()
    }
  }

  if (loading) {
    return (
      <div className={classes.wrapper}>
        <Loader />
      </div>
    )
  }

  let subheader = `See the distribution of our portfolio for ${metric.label}`
  if (!hideIndustry) {
    subheader = hideProductLine
      ? `See how our products' average ${metric.lowercaseTitle || metric.label} compares to the sales category average`
      : `See this product’s ${metric.lowercaseTitle || metric.label} compared to the sales category average`
    subheader += !hidePortfolioLine ? ' and portfolio average.' : '.'
  }

  return (
    <div className={classes.wrapper} data-testid="sustainability-scorecard-benchmarking">
      <div className={classes.header}>{hideIndustry ? 'Portfolio Distribution' : 'Industry Benchmarking'}</div>
      <div className={classes.subheader}>{subheader}</div>
      <AreaChart width={1250} height={280} data={data.distribution} margin={{ top: 34, right: 0, left: 0, bottom: 20 }}>
        <YAxis domain={[0, yMax + 0.2]} tick={false} tickLine={false} width={20} axisLine={{ stroke: '#BFBFBF' }} />
        {!hideIndustry && (
          <Area
            type="monotone"
            dataKey="averageRetail"
            stroke="#F3BE48"
            fillOpacity={0.1}
            fill="#F3BE48"
            strokeWidth="2"
            isAnimationActive={Boolean(onReady)}
            onAnimationEnd={handleIsReady}
          />
        )}
        {!hideProductLine && (
          <>
            <ReferenceLine x={productSeriesProps.lineXPosition} isFront={false} stroke="black" strokeWidth="2" />
            {comparisonProductName && (
              <ReferenceLine
                segment={[
                  {
                    x: comparisonProductSeriesProps.lineXPosition,
                    y: 0,
                  },
                  {
                    x: comparisonProductSeriesProps.lineXPosition,
                    y: yMax + 0.02,
                  },
                ]}
                isFront={false}
                stroke="black"
                strokeWidth="2"
              />
            )}
          </>
        )}
        <XAxis
          dataKey="score"
          domain={[0, 100]}
          ticks={[]}
          scale="linear"
          tickLine={false}
          tick={false}
          axisLine={{ strokeWidth: '0px' }}
        />
        {!hideIndustry && (
          <ReferenceLine
            x={allIndustrySeriesProps.lineXPosition}
            stroke="#F3BE48"
            strokeDasharray="3 3"
            strokeWidth="2"
          />
        )}
        {hidePortfolioLine ? null : (
          <>
            <Area
              key={`area-${animationKey}`}
              type="monotone"
              dataKey="hgPortfolio"
              stroke="#007A4C"
              fillOpacity={0.1}
              fill="#007A4C"
              strokeWidth="2"
              isAnimationActive={false}
            />
            <ReferenceLine
              x={portfolioSeriesProps.lineXPosition}
              stroke="#007A4C"
              strokeDasharray="3 3"
              strokeWidth="2"
            />
          </>
        )}
      </AreaChart>
      <div className={classes.labelsOverlay}>
        {!hideProductLine && (
          <>
            <div
              className={productSeriesProps.className}
              style={{ left: productSeriesProps.labelOffset, width: productSeriesProps.width }}
            >
              <div className={classes.bold}>
                {productName}:{' '}
                <span className={classes.noWrap}>
                  {productSeriesProps.formattedValue} {unit}
                </span>
              </div>
            </div>
            {comparisonProductName && (
              <div
                className={`${comparisonProductSeriesProps.className} ${classes.comparisonProductLine}`}
                style={{ left: comparisonProductSeriesProps.labelOffset, width: comparisonProductSeriesProps.width }}
              >
                <div className={classes.bold}>
                  {comparisonProductName}:{' '}
                  <span className={classes.noWrap}>
                    {comparisonProductSeriesProps.formattedValue} {unit}
                  </span>
                </div>
              </div>
            )}
          </>
        )}
        {!hidePortfolioLine && (
          <div
            className={portfolioSeriesProps.className}
            style={{ left: portfolioSeriesProps.labelOffset, width: portfolioSeriesProps.width }}
          >
            {porfolioTitle}{' '}
            <span className={classes.noWrap}>
              Average: <span className={classes.bold}>{portfolioSeriesProps.formattedValue} </span>
            </span>
            {unit}
          </div>
        )}
        {!hideIndustry && (
          <div
            className={allIndustrySeriesProps.className}
            style={{ left: allIndustrySeriesProps.labelOffset, width: allIndustrySeriesProps.width }}
          >
            <div>
              {categoryType === 'sales' ? 'Sales' : 'Ingredient'} Category{' '}
              <span className={classes.noWrap}>
                Average: <span className={classes.bold}>{allIndustrySeriesProps.formattedValue} </span>
              </span>
              {unit}
            </div>
            <div className={classes.categoryLabel}>{categoryLabel}</div>
          </div>
        )}
      </div>
      {!unit && (
        <div className={classes.xAxisLabels}>
          <div>{data.min}</div>
          <div>{(data.max - data.min) / 2}</div>
          <div>{data.max}</div>
        </div>
      )}
      <div className={classes.barWrapper}>
        <div className={classes.bar} />
        <div className={classes.degenerative}>Negative Impact</div>
        <div className={classes.regenerative}>Positive Impact</div>
      </div>
      <div className={classes.yAxisLabel}>Percentage of Products</div>
    </div>
  )
}
