import React, { FC, useState } from 'react'
import { Document } from '@contentful/rich-text-types'
import { Text, Stack, Accordion, AccordionSummary, AccordionDetails } from '@howgood/design'
import { faLink, faCheck } from '@fortawesome/pro-regular-svg-icons'
import { CollectVendorDataContent, DataPortalLink } from './CollectVendorDataContent'
import { DataPortalLinkCopyToClipboard } from './DataPortalLinkCopyToClipboard'

interface Props {
  expanded: boolean
  isCopiedFromCurrent: boolean
  handleDataPortalLinkTypeChange: () => void
  onChange: () => void
  handleCopy: () => void
  title: string
  content: Document
}

export const CollectVendorDataAccordion: FC<Props> = ({
  expanded,
  isCopiedFromCurrent,
  handleDataPortalLinkTypeChange,
  onChange,
  handleCopy,
  title,
  content,
}) => {
  const [dataPortalLinkType, setDataPortalLinkType] = useState<DataPortalLink>(null)

  let primaryButtonText = 'Select an option above'
  let primaryButtonIcon = faLink

  if (isCopiedFromCurrent) {
    primaryButtonText = 'Link copied'
    primaryButtonIcon = faCheck
  } else if (dataPortalLinkType) {
    primaryButtonText = 'Copy Link'
  }

  return (
    <Accordion type="secondary" expanded={expanded} onChange={onChange}>
      <AccordionSummary>
        <Text variant="subtitle1">{title}</Text>
      </AccordionSummary>
      <AccordionDetails>
        <CollectVendorDataContent
          dataPortalLinkType={dataPortalLinkType}
          handleDataPortalLinkTypeChange={(value: DataPortalLink) => {
            setDataPortalLinkType(value)
            handleDataPortalLinkTypeChange()
          }}
          content={content}
        />
        <Stack direction="row" alignItems="center" gap={2} sx={{ mt: 2 }}>
          <DataPortalLinkCopyToClipboard
            linkType={dataPortalLinkType}
            onCopy={handleCopy}
            buttonStartIcon={primaryButtonIcon}
            buttonText={primaryButtonText}
          />
          {dataPortalLinkType && <Text variant="body2">Send this URL to your suppliers</Text>}
        </Stack>
      </AccordionDetails>
    </Accordion>
  )
}
