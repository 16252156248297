import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  selectCarbonAccountingEmissionsActivities,
  selectCarbonAccountingReportActivities,
} from '@/state/carbonAccounting'
import { CategoryWithScore } from '../CarbonAccounting.types'

interface SheetData {
  name: string
  rows: string[][]
}
interface UseSummaryData {
  reportYear: number
  reportTitle: string
  categories: CategoryWithScore[]
  scope3Total: number
  flagTotal: number
  allEmissionsTotal: number
}

export function useSummaryData({
  reportYear,
  reportTitle,
  categories,
  scope3Total,
  flagTotal,
  allEmissionsTotal,
}: UseSummaryData): SheetData {
  const rows = useMemo(() => {
    const headerRow = ['Emissions category', 'Description', 'MT CO2e/year']
    const categoriesRows = categories.map((cat) => [cat.title, cat.subtitle, cat.score])
    const emptyRow = []
    const scope3TotalRow = ['Scope 3 Total', 'Total of Scope 3 Categories', scope3Total]
    const scope3FlagRow = ['Total Scope 3 FLAG Emissions', 'Forest, Land, and Agriculture emissions', flagTotal]
    const allEmissionsTotalRow = ['Total Annual Emissions', 'Scope 1 + Scope 2 + Scope 3 Total', allEmissionsTotal]
    const nameRow = ['Report name:', reportTitle]
    const yearRow = ['Report year:', reportYear]
    const generatedRow = ['Report generated:', new Date().toString()]
    const methodologyRow = ['Research methodology link:', 'https://latis-help.howgood.com/en/']
    const descriptionRow = [
      'Report description:',
      "HowGood's Organization-Level GHG Account Report provides an emissions breakdown for Scopes 1, 2, and 3, including a breakout of all Scope 3 Categories 1 through 15, and Scope 3 FLAG emissions.",
    ]
    const allRows = [
      headerRow,
      ...categoriesRows,
      emptyRow,
      scope3TotalRow,
      scope3FlagRow,
      allEmissionsTotalRow,
      emptyRow,
      nameRow,
      yearRow,
      generatedRow,
      methodologyRow,
      descriptionRow,
    ]

    // Add extra cell to start of each row for logo
    return allRows.map((row) => {
      row.unshift('')
      return row
    })
  }, [allEmissionsTotal, categories, flagTotal, reportTitle, reportYear, scope3Total])

  return { name: 'Summary', rows }
}

interface UseCategoryData {
  category: CategoryWithScore
}

export function useCategoryData({ category }: UseCategoryData): SheetData {
  const activities = useSelector(selectCarbonAccountingEmissionsActivities)
  const reportActivities = useSelector(selectCarbonAccountingReportActivities)

  const headerRow = ['Activity', 'Activity data', 'Units', 'Emissions (MT CO2e/year)', 'Notes']
  const activityRows = useMemo(() => {
    return activities
      .filter((activity) => activity.scope_category.toLowerCase() === category?.key)
      .map((activity) => {
        const reportActivity = reportActivities.find((rActivity) => rActivity.activity.id === activity.id)
        return [
          activity.label,
          reportActivity?.activity_value,
          activity.units,
          reportActivity?.score,
          reportActivity?.notes,
        ]
      })
  }, [activities, category?.key, reportActivities])
  const emptyRow = []
  const categoryRow = [`${category?.title} total emissions`, 'N/A', '', category?.score]
  const rows = [headerRow, ...activityRows, emptyRow, categoryRow]

  return { name: category?.title, rows }
}
