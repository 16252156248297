import React, { FC } from 'react'
import { Grid, Text } from '@howgood/design'
import { HighestImpactByVendor } from './HighestImpactByVendor'
import { VendorEngagementBreakdown } from './VendorEngagementBreakdown/VendorEngagementBreakdown'
import { ErrorBoundary } from '@sentry/react'

export const cardStyles = {
  px: 2,
  py: 1,
  overflow: 'auto',
  height: '100%',
}

export const Charts: FC = () => {
  return (
    <Grid container columnSpacing={1} height={280}>
      <Grid item xs={8}>
        <ErrorBoundary fallback={<Text>Error loading Highest Impact chart</Text>}>
          <HighestImpactByVendor />
        </ErrorBoundary>
      </Grid>
      <Grid item xs={4}>
        <ErrorBoundary fallback={<Text>Error loading Vendor Engagement Breakdown chart</Text>}>
          <VendorEngagementBreakdown />
        </ErrorBoundary>
      </Grid>
    </Grid>
  )
}
