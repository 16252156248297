import React, { ChangeEvent, FC, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { faCubes, faCube, faFlagPennant, faFlagSwallowtail } from '@fortawesome/pro-solid-svg-icons'
import { Box, Stack, TextField } from '@howgood/design'

import { selectDisplayedProductName, selectIsCreateNew, updateDraftProduct } from '@/state/productOverview'
import { DRAFT, selectPageView } from '@/state/pageSettings'
import { selectIsScenarioContext } from '@/state/productOverview'
import { selectDisplayedProduct } from '@/state/productOverview'
import { selectAllWorkspacesOptions } from '@/state/workspaces'
import {
  selectCurrentInitiative,
  selectCurrentInitiativeId,
  selectCurrentScenario,
  selectCurrentScenarioId,
  setScenarioPageTab,
} from '@/state/initiatives'
import { formulateListParam } from '@/utils/getUrl'
import { selectIsProcurement } from '@/state/router'
import { BreadCrumbs } from '@/components/Layout/BreadCrumbs'
import { Heading } from '@/components/Layout/Heading'
import { ScenarioProductSelector } from './ScenarioProductSelector'
import { selectMaterialIconAgpracticeSplit } from '@/state/splitio'
import { FormulaIconBadge } from '@/components/Shared/IngredientSearch/FormulaIconBadge'
import { useGetFormulaIconBadge } from '@/hooks/useFormulaIconBadge'

export const FormulaHeader: FC = () => {
  const dispatch = useDispatch()
  const workspaces = useSelector(selectAllWorkspacesOptions)
  const product = useSelector(selectDisplayedProduct)
  const scenarioContext = useSelector(selectIsScenarioContext)
  const currentScenarioId = useSelector(selectCurrentScenarioId)
  const currentInitiativeId = useSelector(selectCurrentInitiativeId)
  const isProcurement = useSelector(selectIsProcurement)
  const productName = useSelector(selectDisplayedProductName)
  const view = useSelector(selectPageView)
  const isCreateNew = useSelector(selectIsCreateNew)
  const isInitiative = useSelector(selectIsScenarioContext)
  const currentScenario = useSelector(selectCurrentScenario)
  const currentInitiative = useSelector(selectCurrentInitiative)
  const materialIconAgpracticeEnabled = useSelector(selectMaterialIconAgpracticeSplit)
  const getFormulaIconBadge = useGetFormulaIconBadge()

  const workspaceIds = workspaces.map((workspace) => workspace.id) // IDs of all the user's workspaces
  const badge = getFormulaIconBadge({
    workspaces: product.workspaces?.map((w) => w.id),
    material_types: product.material_types,
    isProduct: true,
  })

  // Get just the product workspaces the user has access to
  const availableWorkspaces = (product.workspaces || []).filter((workspace) => workspaceIds.includes(workspace.id))

  const workspacesList = useMemo(() => {
    if (!availableWorkspaces) return []
    return availableWorkspaces.slice(0).sort((a, b) => a.name.localeCompare(b.name))
  }, [availableWorkspaces])

  const handleClickWorkspaceLink = useCallback(() => {
    if (scenarioContext && currentScenarioId && currentInitiativeId) {
      dispatch(setScenarioPageTab('formulas'))
    }
  }, [scenarioContext, currentScenarioId, currentInitiativeId, dispatch])

  const workspaceUrl = useMemo(() => {
    const qString = formulateListParam(
      workspacesList.map((ws) => ws.id),
      'workspaces',
      ''
    )
    if (scenarioContext && currentScenarioId && currentInitiativeId) {
      return `/initiatives/${currentInitiativeId}/scenarios/${currentScenarioId}${qString}`
    } else if (isProcurement) {
      return `/materials${qString}`
    } else {
      return `/products${qString}`
    }
  }, [workspacesList, scenarioContext, currentScenarioId, currentInitiativeId, isProcurement])

  const breadCrumbs = useMemo(() => {
    if (isInitiative) {
      return [
        { title: 'Initiatives', url: '/initiatives', icon: faFlagSwallowtail },
        { title: currentInitiative?.name, url: `/initiatives/${currentInitiative.id}` },
        {
          title: currentScenario?.name,
          url: `/initiatives/${currentInitiative.id}/scenarios/${currentScenario.id}`,
          icon: faFlagPennant,
        },
        {
          title: workspacesList.map((ws) => ws.name).join(', '),
          url: workspaceUrl,
          icon: isProcurement ? faCubes : faCube,
          onClick: handleClickWorkspaceLink,
        },
      ]
    }
    return [
      {
        title: isProcurement ? 'Materials' : 'Products',
        icon: isProcurement ? faCubes : faCube,
        url: isProcurement ? '/materials' : '/products',
      },
      { title: workspacesList.map((ws) => ws.name).join(', '), url: workspaceUrl, onClick: handleClickWorkspaceLink },
    ]
  }, [
    workspacesList,
    workspaceUrl,
    isProcurement,
    handleClickWorkspaceLink,
    currentInitiative,
    currentScenario,
    isInitiative,
  ])

  const handleLeaveNameField = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.currentTarget.value
    dispatch(updateDraftProduct({ name }))
  }
  const sharedStyles = {
    fontSize: 26,
    mr: 1.5,
  }

  return (
    <Box height={70}>
      {view === DRAFT ? (
        <Stack gap={1}>
          {!isCreateNew && (
            <BreadCrumbs
              breadCrumbs={breadCrumbs}
              highlighted={isInitiative}
              id="workspaces-list"
              data-testid="workspaces-list"
              actions={isInitiative ? <ScenarioProductSelector /> : null}
            />
          )}
          <TextField
            id="name-field"
            disabled={view !== DRAFT}
            placeholder="Name your new formula..."
            value={productName}
            onChange={handleLeaveNameField}
            variant="standard"
            sx={{
              ...sharedStyles,
              mb: 3,
              mt: -0.25,
              width: '100%',
              '& .MuiInputBase-root.MuiInput-root.MuiInput-underline': {
                ...sharedStyles,
              },
              '& .MuiInputBase-input.MuiInput-input': {
                p: 0,
                textOverflow: 'ellipsis',
              },
            }}
          />
        </Stack>
      ) : (
        <Heading
          title={productName}
          breadCrumbs={breadCrumbs}
          data-testid="workspaces-list"
          highlightedBreadcrumbs={isInitiative}
          breadcrumbActions={isInitiative ? <ScenarioProductSelector /> : null}
          titleBadge={materialIconAgpracticeEnabled && <FormulaIconBadge noMargin badge={badge} />}
        />
      )}
    </Box>
  )
}
