import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button } from '@howgood/design'

import { selectisInitiativesLoading } from '@/state/initiatives'
import { CreateScenarioDialog } from './CreateScenarioDialog'

export const CreateScenario: FC = () => {
  const isLoading = useSelector(selectisInitiativesLoading)
  const [dialogOpen, setDialogOpen] = useState(false)

  const handleAddScenarioClicked = () => {
    setDialogOpen(true)
  }
  return (
    <>
      <Button color="primary" data-testid="add-scenario-button" disabled={isLoading} onClick={handleAddScenarioClicked}>
        Add new scenario
      </Button>
      {dialogOpen && <CreateScenarioDialog closeDialog={() => setDialogOpen(false)} />}
    </>
  )
}
