import React, { forwardRef } from 'react'
import MuiStack, { StackProps as MuiStackProps } from '@mui/material/Stack'

interface StackProps extends Omit<MuiStackProps, 'className'> {
  ['aria-label']?: string
  ['data-pendo-id']?: string
  ['data-testid']?: string
}

export const Stack = forwardRef<HTMLElement, StackProps>(
  (
    { ['aria-label']: ariaLabel, ['data-pendo-id']: dataPendoId, ['data-testid']: dataTestId, children, ...rest },
    ref
  ) => {
    return (
      <MuiStack ref={ref} aria-label={ariaLabel} data-pendo-id={dataPendoId} data-testid={dataTestId} {...rest}>
        {children}
      </MuiStack>
    )
  }
)

Stack.displayName = 'Stack'
