import React, { FC } from 'react'
import { selectValidationRequests } from '@/state/productOverview'
import { useSelector } from 'react-redux'
import { faClock, faCheck, faOctagonExclamation } from '@fortawesome/pro-regular-svg-icons'
import { theme, Stack, Tooltip, Icon } from '@howgood/design'
import { GridRowsProp, GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium'
import { CONFIRMED, DENIED, REQUESTED, ValidationRequestStatus } from '@/api'
import { validationText } from './validationCopy'
import { DateCell, DateCellProps } from './DateCellRenderer'
import { HistoryTable } from './HistoryTable'

interface StatusProps extends GridRenderCellParams {
  value: string
}

const StatusCell: FC<StatusProps> = ({ value }) => {
  const validationDisplayMap = {
    [REQUESTED]: {
      text: 'Requested',
      icon: faClock,
      color: theme.palette.info.light,
    },
    [CONFIRMED]: {
      text: 'Confirmed',
      icon: faCheck,
      color: theme.palette.success.main,
    },
    [DENIED]: {
      text: 'Denied',
      icon: faOctagonExclamation,
      color: theme.palette.error.main,
    },
  }

  if ([REQUESTED, CONFIRMED, DENIED].includes(value as ValidationRequestStatus)) {
    return (
      <Tooltip title={validationText[value]}>
        <Stack direction="row" alignItems="center" gap={1}>
          <Icon color={validationDisplayMap[value].color} size="sm" icon={validationDisplayMap[value].icon} />
          {validationDisplayMap[value].text}
        </Stack>
      </Tooltip>
    )
  }
  return null
}

const columns: GridColDef[] = [
  {
    field: 'requested',
    headerName: 'Requested',
    width: 110,
    renderCell: (params: DateCellProps) => <DateCell {...params} />,
  },
  { field: 'description', headerName: 'Description', flex: 1, sortable: false },
  {
    field: 'status',
    headerName: 'Status',
    width: 120,
    sortable: false,
    renderCell: (params: StatusProps) => <StatusCell {...params} />,
  },
  {
    field: 'updated',
    headerName: 'Updated',
    width: 100,
    renderCell: (params: DateCellProps) => <DateCell {...params} />,
  },
]

export const ValidationRequestHistory: FC = () => {
  const validationRequests = useSelector(selectValidationRequests)

  if (validationRequests.length === 0) {
    return null
  }

  const rows: GridRowsProp = validationRequests.map((request, i) => {
    return {
      id: i,
      requested: request.date_created,
      updated: request.date_modified,
      description: request.description,
      status: request.status,
    }
  })

  return <HistoryTable rows={rows} columns={columns} uniqueId="sharing" />
}
