import React, { FC } from 'react'
import { ScoreBadge, Stack } from '@howgood/design'
import { roundBy } from '@/utils/numbers'
import { getColorForMetric } from '@/utils/impactScoreHelpers'

interface ScoreBadgeProps {
  value: number
  metric: string
}

export const ImpactScoreBadge: FC<ScoreBadgeProps> = ({ value, metric }) => {
  const score = typeof value === 'number' ? roundBy(value, 0) : null
  const scoreFormatted = typeof score === 'number' ? score : null

  return (
    <Stack alignItems="center" justifyContent="center" height="100%">
      <ScoreBadge bgcolor={getColorForMetric(metric, score)} score={scoreFormatted} />
    </Stack>
  )
}
