import React, { FC } from 'react'
import { Drawer, Stack, Text } from '@howgood/design'
import { useContentfulMetric } from '@/contentfully/hooks'
import { RecipeIngredient, RecipeNestedIngredient } from '@/state/recipe'
import { NestedProductLink } from '../../IngredientName/NestedProductLink'
import { Sources } from './Sources'
import { ImpactSpectrum } from './ImpactSpectrum'
import { ImproveImpact } from './ImproveImpact'
import { IngredientSubstitution } from './IngredientSubstitution'
import { ChangeSourcingLocation } from './ChangeSourcingLocation'
import { WaterScarcity } from './WaterScarcity'
import { BLUE_WATER_PERMISSIONS, PERMISSIONS_TO_FIELDS_MAP } from '@/constants/impactScore'

interface Props {
  open: boolean
  onClose: () => void
  ingredient: RecipeIngredient | RecipeNestedIngredient
  score: string
  field: string
  backgroundColor: string
}

export const ScoreCellDrawer: FC<Props> = ({ open, onClose, ingredient, score, field, backgroundColor }) => {
  const metric = useContentfulMetric(field)
  const blueWaterFields = BLUE_WATER_PERMISSIONS.map((permission) => PERMISSIONS_TO_FIELDS_MAP[permission])

  if (!metric || !ingredient) return null

  const originLocationId = 'origin_location_id' in ingredient ? ingredient.origin_location_id : null
  const baseId = 'base_id' in ingredient ? ingredient.base_id : null

  return (
    <Drawer data-testid="cell-drawer" open={open} onClose={onClose}>
      <Stack width={632} gap={2}>
        <Text data-testid="cell-drawer-title" variant="h2">
          {metric.title}
        </Text>
        <Stack flexDirection="row" height={58} gap={0.25}>
          <Stack
            flexDirection="row"
            alignItems="center"
            gap={0.5}
            height="100%"
            flex={1}
            px={2}
            bgcolor="secondary.paperMain"
            borderRadius={1}
          >
            <Text data-testid="cell-drawer-ingredient" textTransform="capitalize">
              {ingredient.name}
            </Text>
            {'nested_product_id' in ingredient && <NestedProductLink productId={ingredient.nested_product_id} />}
          </Stack>
          <Stack
            data-testid="cell-drawer-score-units"
            justifyContent="center"
            alignItems="flex-end"
            height="100%"
            px={3}
            bgcolor={backgroundColor}
            borderRadius={1}
          >
            <Text data-testid="cell-drawer-score" variant="h3">
              {score}
            </Text>
            <Text data-testid="cell-drawer-units" variant="caption">
              {metric.units}
            </Text>
          </Stack>
        </Stack>
        <Text data-testid="cell-drawer-description">{metric.description}</Text>
        <ImpactSpectrum
          spectrumImage={metric.spectrumImage?.fields?.file?.url as string}
          negativeImpact={metric.negativeImpact}
          positiveImpact={metric.positiveImpact}
        />
        <ImproveImpact improveImpact={metric.improveImpact} />
        <IngredientSubstitution ingredientSubstitution={metric.ingredientSubstitution} />
        <ChangeSourcingLocation
          metric={metric}
          ingredient={ingredient}
          baseId={baseId}
          originLocationId={originLocationId}
          changeSourcingLocation={metric.changeSourcingLocation}
        />
        {blueWaterFields.includes(metric.key) && <WaterScarcity />}
        {metric.key === 'cf_processing_impact' && (
          <Text data-testid="grid-mix-year">
            Grid Mix Year: <b>2022</b>
          </Text>
        )}
        <Sources originLocationId={originLocationId} />
      </Stack>
    </Drawer>
  )
}
