import React, { FC, ReactNode } from 'react'
import { useSelector } from 'react-redux'
import { Box, Grid } from '@howgood/design'
import { DRAFT, selectPageView } from '@/state/pageSettings'

interface Props {
  xs: number
  header?: boolean
  children?: ReactNode
}

export const PackagingGridItem: FC<Props> = ({ xs, header, children }) => {
  const view = useSelector(selectPageView)

  return (
    <Grid data-testid="packaging-grid-item" item xs={xs}>
      <Box
        display="flex"
        alignItems={header ? 'flex-end' : 'center'}
        justifyContent={header || view !== DRAFT ? 'flex-start' : 'center'}
        width="100%"
        height="100%"
        p={header ? 0 : 1}
        pl={1}
        pb={header ? 1.25 : 1}
        borderRight={2}
        borderBottom={2}
        borderColor="white"
        bgcolor={header ? null : 'secondary.paperMain'}
      >
        {children}
      </Box>
    </Grid>
  )
}
