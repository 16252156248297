import React, { useEffect, FC, ReactNode } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getSplitNames, getTreatments, initSplitSdk } from '@splitsoftware/splitio-redux'
import { selectSplitAttributes, selectSplitIsReady } from '@/state/splitio'
import { DEFAULT_USER_ID } from '@/records'
import { selectUserId } from '@/state/user/user.selectors'

interface Props {
  children: ReactNode
}

export const SplitProvider: FC<Props> = ({ children }) => {
  const dispatch = useDispatch()
  const userId = useSelector(selectUserId)
  const splitAttributes = useSelector(selectSplitAttributes)
  const splitIsReady = useSelector(selectSplitIsReady)

  // Initialize split-io client
  useEffect(() => {
    if (userId !== DEFAULT_USER_ID) {
      dispatch(
        initSplitSdk({
          config: {
            core: {
              authorizationKey: process.env.REACT_APP_SPLIT_IO_KEY,
              key: userId.toString(),
            },
          },
        })
      )
    }
  }, [userId, dispatch])

  // Make treatments available in redux store
  useEffect(() => {
    if (splitIsReady) {
      const splitNames = getSplitNames()
      dispatch(getTreatments({ splitNames, attributes: splitAttributes }))
    }
  }, [splitIsReady, splitAttributes, dispatch])

  return <>{children}</>
}
