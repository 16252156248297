import React, { FC, useEffect, useState } from 'react'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faLink, faCheck } from '@fortawesome/pro-regular-svg-icons'
import { DataPortalLinkCopyToClipboard } from '@/components/Procurement/RequestVendorData/DataPortalLinkCopyToClipboard'
import { DataPortalLink } from '@/components/Procurement/RequestVendorData/CollectVendorDataContent'

interface Props {
  linkType: DataPortalLink
}

export const PurchasedMaterialDialogCopyToClipboard: FC<Props> = ({ linkType }) => {
  const [buttonText, setButtonText] = useState<string>('Select an option above')
  const [buttonStartIcon, setButtonStartIcon] = useState<IconDefinition>(faLink)

  useEffect(() => {
    setButtonText(linkType ? 'Copy Link' : 'Select an option above')
    setButtonStartIcon(faLink)
  }, [linkType])

  return (
    <DataPortalLinkCopyToClipboard
      linkType={linkType}
      onCopy={() => {
        setButtonText('Link copied')
        setButtonStartIcon(faCheck)
      }}
      buttonStartIcon={buttonStartIcon}
      buttonText={buttonText}
    />
  )
}
