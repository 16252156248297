import React, { useEffect } from 'react'
import MuiCircularProgress from '@mui/material/CircularProgress'
import { theme } from './theme'
import { Box } from './Box'
import { Text } from './Text'
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons'
import { Icon } from './Icon'

interface ProgressChipProps {
  percentFilled: number // 0-100
  colorize?: boolean // If true, use fillColor and textColor
  color?: string // Defaults to impactText palette
  animate?: boolean
  label?: string // Shows a label next to the circle
  labelPosition?: string // sets the label to the right or left of the circle
}

export const ProgressChip = React.forwardRef<HTMLElement, ProgressChipProps>(
  (
    {
      percentFilled: value,
      colorize = false,
      color = 'primary',
      animate = true,
      label,
      labelPosition = 'right',
      ...rest
    },
    ref
  ) => {
    const [progress, setProgress] = React.useState(0)

    useEffect(() => {
      let timeout = null
      if (animate) {
        timeout = setTimeout(() => {
          setProgress(() => {
            return value
          })
        }, 100)
      } else {
        setProgress(value)
      }
      return () => {
        if (timeout) clearTimeout(timeout)
      }
    }, [value, animate])

    const textColor = colorize ? theme.palette.impactText[Math.round(value / 10)] : color

    const order = labelPosition === 'left' ? -1 : 0

    return (
      <Box ref={ref} display="flex" alignItems="center" gap={0.5} {...rest}>
        <Box position="relative" height={18}>
          {progress >= 100 && (
            <Icon
              size="sm"
              color={colorize ? theme.palette.impact[10] : theme.palette.primary.main}
              icon={faCheckCircle}
            />
          )}

          {progress < 100 && (
            <>
              {/* Background full gray circle */}
              <MuiCircularProgress
                variant="determinate"
                size={18}
                thickness={8}
                aria-hidden
                sx={{
                  position: 'absolute',
                  color: theme.palette.secondary.paperMain,
                }}
                value={100}
              />

              {/* Foreground partial colored circle */}
              <MuiCircularProgress
                variant="determinate"
                size={18}
                thickness={8}
                value={progress}
                sx={{ color: colorize ? theme.palette.impact[Math.round(value / 10)] : null, display: 'block' }}
              />
            </>
          )}
        </Box>
        {label && (
          <Text variant="caption" order={order} color={textColor}>
            {label}
          </Text>
        )}
      </Box>
    )
  }
)

ProgressChip.displayName = 'ProgressChip'
