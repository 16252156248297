import React, { FC } from 'react'
import { Autocomplete, TextField, Tooltip } from '@howgood/design'

interface Props {
  value: string[]
  handleChange: (queries: string[]) => void
  searchType?: string
  id?: string
  disabled?: boolean
  autoFocus?: boolean
  fullWidth?: boolean
  dataPendoId?: string
  ariaLabel?: string
}

// Shared filter for usage in Formulations and Aggregated Vendors screens
export const NameQueriesFilter: FC<Props> = ({
  value,
  handleChange,
  searchType = '',
  id,
  disabled = false,
  autoFocus = false,
  fullWidth = false,
  dataPendoId = '',
  ariaLabel = '',
}) => {
  return (
    <Tooltip title={`Filter ${searchType}s by ${searchType} name`} placement="top">
      <Autocomplete
        data-pendo-id={dataPendoId}
        data-testid={id}
        id={id}
        aria-label={ariaLabel}
        options={[]}
        defaultValue={[]}
        value={value}
        freeSolo
        disabled={disabled}
        onChange={(_event: React.SyntheticEvent<Element, Event>, queryStrings: string[]) => handleChange(queryStrings)}
        renderInput={(params) => (
          <TextField placeholder={`Search and compare ${searchType}s`} autoFocus={autoFocus} {...params} />
        )}
        multiple
        fullWidth={fullWidth}
        componentsProps={{ popper: { style: { width: 'fit-content' }, placement: 'bottom-start' } }}
      />
    </Tooltip>
  )
}
