import React, { FC } from 'react'
import { theme, Icon, Stack, Text } from '@howgood/design'
import { faFolder } from '@fortawesome/pro-solid-svg-icons'
import { ProductInfo } from './ProductGrid'

interface ProductCellProps {
  value: ProductInfo
}

export const ProductCell: FC<ProductCellProps> = ({ value }) => {
  return (
    <Stack data-testid={`product-cell-${value.id}`} sx={{ pointerEvents: 'none' }}>
      <Text>{value.name}</Text>
      <Stack direction="row" alignItems="center" gap={1}>
        <Text variant="caption">
          {value.ingredientCount} {value.ingredientCount === 1 ? 'ingredient' : 'ingredients'}
        </Text>
        <Icon size="sm" color={theme.palette.secondary.light} icon={faFolder} />
        <Text variant="caption">{value.workspaces}</Text>
      </Stack>
    </Stack>
  )
}
