import React, { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { faArrowUpFromBracket } from '@fortawesome/pro-regular-svg-icons'
import { Button, Stack, Text } from '@howgood/design'

import { selectExportPaywallSplit } from '@/state/splitio'
import { setLiteUserPaywallDialogState } from '@/state/liteUserPaywallDialog'
import { Export } from './Export'
import { selectIsSupplierConnect } from '@/state/organization'

export const ExportButton: FC = () => {
  const dispatch = useDispatch()
  const exportPaywallEnabled = useSelector(selectExportPaywallSplit)
  const isSupplierConnect = useSelector(selectIsSupplierConnect)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const onClose = () => {
    setAnchorEl(null)
  }

  return (
    <Stack>
      <Button
        disabled={exportPaywallEnabled}
        data-pendo-id={exportPaywallEnabled ? 'exportpaywall' : 'Export'}
        startIcon={faArrowUpFromBracket}
        id="export-button"
        aria-label="Export product list"
        onClick={(event) => {
          if (isSupplierConnect) {
            dispatch(
              setLiteUserPaywallDialogState({
                open: true,
              })
            )
            return
          }
          setAnchorEl(event.currentTarget)
        }}
      >
        <Text>Export</Text>
      </Button>
      <Export onClose={onClose} anchorEl={anchorEl} />
    </Stack>
  )
}
