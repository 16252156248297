import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { Text } from '@howgood/design'
import { RecipeIngredient, RecipeNestedIngredient } from '@/state/recipe/recipe.state'
import { useGetFormulaIconBadge } from '@/hooks/useFormulaIconBadge'
import { FormulaIconBadge } from '@/components/Shared/IngredientSearch/FormulaIconBadge'

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
})
interface VendorProps extends GridRenderCellParams {
  value?: {
    ingredient: RecipeIngredient | RecipeNestedIngredient
  }
}

export const Vendor: FC<VendorProps> = ({ value: { ingredient } }) => {
  const classes = useStyles()
  const getFormulaIconBadge = useGetFormulaIconBadge()

  const badge = getFormulaIconBadge({
    workspaces: (ingredient as RecipeNestedIngredient).workspacesIds,
    material_types: (ingredient as RecipeNestedIngredient).material_types,
    isProduct: Boolean((ingredient as RecipeNestedIngredient).nested_product_id),
  })

  const isNestedProduct = 'nested_product_id' in ingredient

  let vendor
  if (isNestedProduct) {
    vendor = ingredient.vendorName || 'Unknown Vendor'
  }

  return (
    <div className={classes.container}>
      {badge && <FormulaIconBadge badge={badge} />}
      {isNestedProduct && <Text>{vendor}</Text>}
    </div>
  )
}
