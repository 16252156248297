import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { selectUser } from '@/state/user'
import { selectOrganization } from '@/state/organization'
import { selectSavedProductInfo } from '@/state/productOverview'

declare const pendo: any

export const usePendoTrack = () => {
  const org = useSelector(selectOrganization)
  const user = useSelector(selectUser)
  const product = useSelector(selectSavedProductInfo)

  return useCallback(
    (key: string, additionalData: Object = {}) => {
      pendo.track(key, {
        ...additionalData,
        productId: product.id || 'N/A',
        userOrgName: org.organizationName,
        userOrgId: org.organizationId,
        customerType: org.customerType,
        salesforce: org.salesforceAccountId,
        user: user.email,
      })
    },
    [org, user, product.id]
  )
}
