import { createAsyncThunk } from '@reduxjs/toolkit'
import { Workspace } from '@/records'
import { AppState } from '@/store'
import {
  fetchUpdateWorkspace,
  fetchWorkspaceData,
  AllWorkspaceScoresData,
  fetchAllWorkspaceScoresData,
} from './workspaces.requests'
import { selectEditableWorkspaces, selectWorkspaces, selectAllWorkspaceImpactScores } from './workspaces.selectors'
import { initialImpactScore } from '@/state/recipe/recipe.state'
import { handleThunkError } from '../messages/messages.thunk'
import { selectTagFilters } from '@/state/products/products.selectors'
import { Field } from '@/constants/impactScore'
import { selectIsVendors, selectIsPortfolio } from '../router'
import { selectVendorTags } from '../vendorManagement'

export const getWorkspaces = createAsyncThunk<Workspace[], void, { state: AppState }>(
  'workspaces/getWorkspaces',
  async () => fetchWorkspaceData()
)

export const updateWorkspace = createAsyncThunk<Workspace[], Partial<Workspace>, { state: AppState }>(
  'workspaces/updateWorkspace',
  async (updates, { getState }) => {
    const workspaces = selectWorkspaces(getState())
    const workspace = await fetchUpdateWorkspace(updates)
    return workspaces.map((ws) => {
      if (ws.id === workspace.id) {
        return {
          ...ws,
          ...workspace,
        }
      }
      return ws
    })
  }
)

export const getWorkspaceImpactScores = createAsyncThunk<AllWorkspaceScoresData, void, { state: AppState }>(
  'workspaces/getWorkspaceImpactScores',
  async (_, { getState }) => {
    const state = getState()
    const workspaces = selectWorkspaces(state)
    const tagProductsFilters = selectTagFilters(state)
    const tagAggregatedVendorsFilters = selectVendorTags(state)
    const isPortfolio = selectIsPortfolio(state)
    const isAggregatedVendors = selectIsVendors(state)
    const workspaceData = await fetchAllWorkspaceScoresData(
      (isAggregatedVendors ? tagAggregatedVendorsFilters : isPortfolio ? tagProductsFilters : []).map(
        (tag) => tag.label
      )
    )
    return workspaces.reduce((allScores, workspace) => {
      allScores[workspace.id] = workspaceData?.[workspace.id] || initialImpactScore
      return allScores
    }, {})
  }
)

export const setInitialPortfolioLevelGoal = createAsyncThunk<Workspace[], Field, { state: AppState }>(
  'workspaces/setInitialPortfolioLevelGoal',
  async (metric, { getState, dispatch }) => {
    const state = getState()
    const workspaces = selectWorkspaces(state)
    const editableWorkspaces = selectEditableWorkspaces(state)
    const allScores = selectAllWorkspaceImpactScores(state)
    if (!editableWorkspaces.length) {
      return workspaces
    }
    const workspaceUpdates = editableWorkspaces.map((ws) => {
      const currentScore = allScores[ws.id]?.[metric]
      const goals = { ...ws.goals, [metric]: currentScore ? Math.round(currentScore * 10) / 10 : 0 }
      return {
        id: ws.id,
        goals,
        name: ws.name,
      }
    })
    const updateResponses = await Promise.allSettled<Workspace>(workspaceUpdates.map((ws) => fetchUpdateWorkspace(ws)))
    const successfulUpdates = []
    const errors = []
    updateResponses.forEach((wsResponse, idx) => {
      if (wsResponse.status === 'fulfilled') {
        successfulUpdates.push(wsResponse.value)
      } else {
        const rejectedWorkspace = workspaceUpdates[idx]
        errors.push(rejectedWorkspace.name)
      }
    })
    if (errors.length) {
      const error = new Error(errors.join('. '))
      dispatch(
        handleThunkError({
          error,
          message: `There was an error updating workspace(s) ${errors.join(
            ', '
          )}, please review individual workspace goals below.`,
          actionType: 'workspaces/setInitialPortfolioLevelGoal',
          context: { metric },
        })
      )
    }
    return workspaces.map((ws) => {
      const updated = successfulUpdates.find((updatedWs) => updatedWs.id === ws.id)
      return {
        ...ws,
        ...updated,
      }
    })
  }
)
