import React, { ElementType, FC } from 'react'
import MuiCard, { CardProps as MuiCardProps } from '@mui/material/Card'
import MuiCardHeader, { CardHeaderProps as MuiCardHeaderProps } from '@mui/material/CardHeader'
import MuiCardContent, { CardContentProps as MuiCardContentProps } from '@mui/material/CardContent'
import { theme } from './theme'

interface CardHeaderProps extends Pick<MuiCardHeaderProps, 'title' | 'action' | 'subheader'> {}

export const CardHeader: FC<CardHeaderProps> = ({ title, action, subheader }) => {
  return <MuiCardHeader title={title} action={action} subheader={subheader} />
}

interface CardContentProps extends Pick<MuiCardContentProps, 'id' | 'children'> {}

export const CardContent: FC<CardContentProps> = ({ id, children }) => {
  return (
    <MuiCardContent
      id={id}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'end',
        flex: 1,
        padding: 0.75,
        paddingBottom: '0px !important',
      }}
    >
      {children}
    </MuiCardContent>
  )
}

interface CardProps extends Pick<MuiCardProps<ElementType>, 'id' | 'children' | 'sx'> {
  ['data-testid']?: string
  fullHeight?: boolean
  outlineDashed?: boolean
  highlight?: boolean
}

export const Card: FC<CardProps> = ({
  ['data-testid']: dataTestId,
  id,
  sx,
  outlineDashed,
  highlight,
  fullHeight,
  children,
}) => {
  return (
    <MuiCard
      data-testid={dataTestId}
      id={id}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: fullHeight ? '100%' : undefined,
        boxShadow: outlineDashed ? 'none' : undefined,
        border: outlineDashed
          ? '1px dashed rgba(0, 0, 0, 0.12)'
          : highlight
          ? `2px solid ${theme.palette.primary.main}`
          : undefined,
        overflow: 'unset',
        ...sx,
      }}
    >
      {children}
    </MuiCard>
  )
}
