import React, { FC, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { faAbacus, faPlus } from '@fortawesome/pro-regular-svg-icons'
import { Button, Stack } from '@howgood/design'
import { getCarbonAccountingAvailableYears, selectCarbonAccountingReports } from '@/state/carbonAccounting'
import { useCarbonAccountingReports } from '../CarbonAccounting.hooks'
import { CreateReportDialog } from '../CarbonAccountingDialog'
import { CarbonAccountingItem } from './CarbonAccountingItem'
import { Heading } from '@/components/Layout/Heading'
import { useLiteUserPaywallDialogForPages } from '@/components/LiteUserPaywallDialog/LiteUserPaywallDialog.hook'

interface Props {
  animate?: boolean
}

export const CarbonAccountingPage: FC<Props> = ({ animate = true }) => {
  useCarbonAccountingReports()
  useLiteUserPaywallDialogForPages()
  const dispatch = useDispatch()

  const [open, setOpen] = useState(false)
  const reports = useSelector(selectCarbonAccountingReports)

  function handleOpen() {
    setOpen(true)
    dispatch(getCarbonAccountingAvailableYears())
  }
  const createNewButton = (
    <Button
      data-testid="carbon-accounting-create-button"
      startIcon={faPlus}
      size="small"
      color="primary"
      onClick={handleOpen}
    >
      New report
    </Button>
  )

  return (
    <>
      <CreateReportDialog open={open} onClose={() => setOpen(false)} />
      <Heading
        data-testid="carbon-accounting-title"
        title="Organization-level Carbon Accounting"
        icon={faAbacus}
        actions={createNewButton}
      />
      <Stack gap={2}>
        {reports.map((report) => (
          <Stack key={report.id} data-testid="carbon-accounting-report">
            <CarbonAccountingItem report={report} animate={animate} />
          </Stack>
        ))}
      </Stack>
    </>
  )
}
