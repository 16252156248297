/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { FC, useEffect, useRef, useState } from 'react'
import * as d3 from 'd3'
import { theme, Stack, Text } from '../index'

/**
 * This component is based on https://observablehq.com/@d3/sunburst/2
 */

export interface D3HierarchyNode {
  name: string
  value: number // Must be 0 for nested items
  nodeInfo: any // Not used by the chart, but anything here is returned in the popover callback
  children: D3HierarchyNode[]
}

export interface SunburstChartProps {
  data: D3HierarchyNode
  width?: number
  fontSize?: number
  maxLabelLength?: number // Max length of label before truncation
  setPopoverData?: (data: any) => void // Optional callback to return data on hover
}

interface Rect {
  x0: number
  x1: number
  y0: number
  y1: number
  depth: number
}

// The zoomable sunburst chart requires additional `custom` and `target` nodes not on the standard D3 type
interface CustomHierarchyRectangularNode extends d3.HierarchyRectangularNode<D3HierarchyNode> {
  current?: Rect
  target?: Rect
}

export const SunburstChart: FC<SunburstChartProps> = ({
  data,
  width = 400,
  fontSize = 12,
  maxLabelLength = 13,
  setPopoverData,
}) => {
  const svgContainer = useRef(null)

  const [innerLabelVisible, setInnerLabelVisible] = useState(false)

  useEffect(() => {
    const height = width
    const radius = width / 6
    const fontSizeString = `${fontSize}px`

    // Reset the svg when the data changes
    const svg = d3.select(svgContainer.current)
    svg.selectAll('*').remove()
    svg.attr('viewBox', [-width / 2, -height / 2, width, width])

    // Create the color scale
    const color = d3.scaleOrdinal(d3.quantize(d3.interpolateRainbow, data.children.length + 1))

    // Create the D3 hierarchy layout
    const hierarchy = d3.hierarchy(data).sum((d) => d.value)

    // Sort so high impact items are at the purple end of the spectrum; low impact at the green end
    hierarchy.sort((a, b) => b.value - a.value)

    // Create the D3 partition layout
    const root: CustomHierarchyRectangularNode = d3
      .partition<D3HierarchyNode>()
      .size([2 * Math.PI, hierarchy.height + 1])(hierarchy)

    // Set the `current` node on each item (required to support click and zoom)
    root.each((d) => {
      return (d.current = {
        x0: d.x0,
        x1: d.x1,
        y0: d.y0,
        y1: d.y1,
        depth: d.depth,
      })
    })

    // Define the arc generator
    const arc = d3
      .arc<Rect>()
      .startAngle((d) => d.x0)
      .endAngle((d) => d.x1)
      .padAngle((d) => Math.min((d.x1 - d.x0) / 2, 0.005))
      .padRadius(radius * 1.5)
      .innerRadius((d) => d.y0 * radius)
      .outerRadius((d) => Math.max(d.y0 * radius, d.y1 * radius - 1))

    // Append the slice arcs to the svg
    const path = svg
      .append('g')
      .selectAll('path')
      .data(root.descendants().slice(1))
      .join('path')
      .attr('class', 'chart-slice')
      .attr('fill', (d) => {
        // eslint-disable-next-line no-param-reassign
        while (d.depth > 1) d = d.parent
        return color(d.data.name)
      })
      .attr('fill-opacity', (d) => (arcVisible(d.current) ? (d.children ? 0.6 : 0.4) : 0))
      .attr('pointer-events', (d) => (arcVisible(d.current) ? 'auto' : 'none'))
      .attr('d', (d) => arc(d.current))

    // Make items clickable if they have children (e.g. nested ingredients)
    path
      .filter((d) => d.children?.length > 0)
      .style('cursor', 'pointer')
      .on('click', clicked)

    path
      .on('mouseover', function (event: React.MouseEvent<HTMLElement>, d) {
        d3.select(this).attr('cursor', 'default')
        if (setPopoverData) {
          setPopoverData({
            anchorEl: event.currentTarget as HTMLElement,
            nodeInfo: d.data.nodeInfo,
          })
        }
      })
      .on('mouseout', () => {
        if (setPopoverData) {
          setPopoverData(null)
        }
      })

    // Create the labels
    const label = svg
      .append('g')
      .attr('text-anchor', 'middle')
      .attr('pointer-events', 'none')
      .selectAll('text')
      .data(root.descendants().slice(1))
      .join('text')
      .attr('class', 'chart-label')
      .attr('dy', '0.35em')
      .attr('font-family', theme.typography.fontFamily)
      .attr('fill', theme.palette.text.primary)
      .attr('font-size', fontSizeString)
      .attr('fill-opacity', (d) => (labelVisible(d.current) ? 1 : 0)) // Hides labels on small slices
      .attr('transform', (d) => labelTransform(d.current))
      .text((d) =>
        d.data.name.length > maxLabelLength ? `${d.data.name.slice(0, maxLabelLength - 3)}...` : d.data.name
      )

    const parent = svg
      .append('circle')
      .datum(root)
      .attr('r', radius)
      .attr('fill', 'none')
      .attr('pointer-events', 'all')
      .attr('id', 'center-circle')
      .on('click', clicked)

    // Handle zoom on click
    function clicked(_event, p: CustomHierarchyRectangularNode) {
      if (p.depth > 0) {
        setInnerLabelVisible(true)
      } else {
        setInnerLabelVisible(false)
      }

      parent.datum(p.parent || root)

      root.each((d) => {
        return (d.target = {
          x0: Math.max(0, Math.min(1, (d.x0 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
          x1: Math.max(0, Math.min(1, (d.x1 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
          y0: Math.max(0, d.y0 - p.depth),
          y1: Math.max(0, d.y1 - p.depth),
          depth: d.depth,
        })
      })

      const t = svg.transition().duration(750)

      // Transition the data on all arcs, even the ones that aren’t visible so if this transition is interrupted,
      // entering arcs will start the next transition from the desired position.
      path
        .transition(t)
        .tween('data', (d) => {
          const i = d3.interpolate(d.current, d.target)
          return (t) => (d.current = i(t))
        })
        .filter(function (this: SVGPathElement, d) {
          return +this.getAttribute('fill-opacity') > 0 || arcVisible(d.target)
        })
        .attr('fill-opacity', (d) => (arcVisible(d.target) ? (d.children ? 0.6 : 0.4) : 0))
        .attr('pointer-events', (d) => (arcVisible(d.target) ? 'auto' : 'none'))

        .attrTween('d', (d) => () => arc(d.current))

      label
        .filter(function (this: SVGPathElement, d) {
          return +this.getAttribute('fill-opacity') > 0 || labelVisible(d.target)
        })
        .transition(t)
        .attr('fill-opacity', (d) => +labelVisible(d.target))
        .attrTween('transform', (d) => () => labelTransform(d.current))
    }

    function arcVisible(d) {
      return d.y1 <= 3 && d.y0 >= 1 && d.x1 > d.x0
    }

    function labelVisible(d) {
      return d.y1 <= 3 && d.y0 >= 1 && (d.y1 - d.y0) * (d.x1 - d.x0) > 0.03
    }

    function labelTransform(d) {
      const x = (((d.x0 + d.x1) / 2) * 180) / Math.PI
      const y = ((d.y0 + d.y1) / 2) * radius
      return `rotate(${x - 90}) translate(${y},0) rotate(${x < 180 ? 0 : 180})`
    }
  }, [data, width, fontSize, maxLabelLength, setPopoverData])

  return (
    <Stack position="relative">
      <svg ref={svgContainer} data-testid="sunburst-chart" />
      {innerLabelVisible && <InnerLabel width={width} />}
    </Stack>
  )
}

const InnerLabel = ({ width }: { width: number }) => {
  return (
    <Stack
      position="absolute"
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      sx={{ pointerEvents: 'none' }}
    >
      <Stack width={width / 4}>
        <Text variant="caption">
          Click inside
          <br />
          to go back
        </Text>
      </Stack>
    </Stack>
  )
}
