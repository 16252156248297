export const formatNumber = (num: number | string, decimalPlaces: number = 2): string => {
  if (num == null || isNaN(+num)) {
    return null
  }
  const rawScore = typeof num === 'string' ? parseFloat(num) : num
  const rawScoreRounded = new Intl.NumberFormat('en-US', {
    notation: 'compact',
    maximumFractionDigits: decimalPlaces,
  }).format(rawScore)

  return rawScoreRounded === '0' && rawScore ? '>0' : rawScoreRounded
}
