import React, { FC } from 'react'
import { Stack, Text, Tooltip } from '@howgood/design'
import { ProductListRenderCellParams } from './ProductGridTypes'
import { formatNumber } from '@howgood/utils'

interface Props extends ProductListRenderCellParams {
  value: number
}

export const PricePerMtRenderer: FC<Props> = ({ value, id }) => {
  if (!value) {
    return null
  }
  const formattedValue = formatNumber(value) || null
  const localeScore = value != null ? value.toLocaleString('en-US') || '' : ''

  return (
    <Tooltip title={`$${localeScore}`}>
      <Stack
        data-testid={`price-per-mt-cell-${id}`}
        direction="row"
        height="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Text variant="body2">${formattedValue}</Text>
      </Stack>
    </Tooltip>
  )
}
