import { createSlice } from '@reduxjs/toolkit'
import { ProductNutrition } from '@/records/nutrition'
import { getNutrition } from './nutrition.thunk'

export interface State {
  productNutrition?: ProductNutrition
  isLoading: boolean
  isError: boolean
}

export const initialState: State = {
  productNutrition: {
    id: null,
    actual_nutrition: null,
    projected_nutrition: null,
    product_ingredients: [],
    nutrition_score: '',
  },
  isLoading: false,
  isError: false,
}

const nutritionSlice = createSlice({
  name: 'nutrition',
  initialState,
  reducers: {
    clearNutrition: (state) => {
      Object.keys(initialState).forEach((field) => {
        state[field] = initialState[field]
      })
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNutrition.pending, (state, action) => {
      state.isLoading = action.payload
      state.isError = false
    })

    builder.addCase(getNutrition.fulfilled, (state, action) => {
      state.isLoading = false
      state.isError = false
      state.productNutrition = action.payload
    })

    builder.addCase(getNutrition.rejected, (state, action) => {
      console.error(action.error)
      state.isLoading = false
      state.isError = true
      state.productNutrition = null
    })
  },
})

export const { clearNutrition } = nutritionSlice.actions

export const nutritionReducer = nutritionSlice.reducer
