import { useSelector } from 'react-redux'
import { HOWGOOD_STANDARD_INGREDIENTS_WORKSPACE_ID, HOWGOOD_WORKSPACE_ID } from '@/constants/common'
import { ContentfulFormulaIconKey, FORMULA_ICON_BADGE } from '@/contentfully'
import { selectHowgoodMaterialDirectorySplit, selectInternalMaterialSplit } from '@/state/splitio'
import { useCallback } from 'react'
import {
  MaterialType,
  MATERIAL_TYPE_HOWGOOD,
  MATERIAL_TYPE_PURCHASED,
  MATERIAL_TYPE_VENDOR_VERIFIED,
  MATERIAL_TYPE_VENDOR_VERIFIED_PLUS,
} from '@/api'

interface UseFormulaIconBadgeCallbackParams {
  workspaces: number[]
  material_types?: MaterialType[]
  isProduct?: boolean
}

export const useGetFormulaIconBadge = (): ((params: UseFormulaIconBadgeCallbackParams) => ContentfulFormulaIconKey) => {
  const isHgSupplierEnabled = useSelector(selectHowgoodMaterialDirectorySplit)
  const isInternalMaterialEnabled = useSelector(selectInternalMaterialSplit)
  return useCallback(
    ({ workspaces = [], material_types, isProduct }) => {
      const materialTypes = material_types || []
      const isHGStandardIngredientsWs = workspaces.includes(HOWGOOD_STANDARD_INGREDIENTS_WORKSPACE_ID)

      const hasHgSupplierBadge = isProduct && materialTypes.includes(MATERIAL_TYPE_HOWGOOD) && isHgSupplierEnabled
      const hasVendorVerifiedBadge = isProduct && materialTypes.includes(MATERIAL_TYPE_VENDOR_VERIFIED)
      const hasInternalMaterialBadge =
        isProduct && !workspaces.includes(HOWGOOD_WORKSPACE_ID) && isInternalMaterialEnabled
      const hasPurchasedMaterialBadge = isProduct && materialTypes.includes(MATERIAL_TYPE_PURCHASED)

      const hasVendorVerifiedPlusBadge = isProduct && materialTypes.includes(MATERIAL_TYPE_VENDOR_VERIFIED_PLUS)

      switch (true) {
        case isHGStandardIngredientsWs:
          return FORMULA_ICON_BADGE.standardIngredient
        case hasHgSupplierBadge:
          return FORMULA_ICON_BADGE.howgoodSupplier
        case hasVendorVerifiedPlusBadge:
          return FORMULA_ICON_BADGE.vendorVerifiedPlus
        case hasVendorVerifiedBadge:
          return FORMULA_ICON_BADGE.vendorVerified
        case hasPurchasedMaterialBadge:
          return FORMULA_ICON_BADGE.purchasedMaterial
        case hasInternalMaterialBadge:
          return FORMULA_ICON_BADGE.internalMaterial
        case !isProduct:
          return FORMULA_ICON_BADGE.standardIngredient
        default:
          return null
      }
    },
    [isHgSupplierEnabled, isInternalMaterialEnabled]
  )
}
