import React, { FC, useMemo } from 'react'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { MenuItem, Select, Text } from '@howgood/design'
import { useDispatch, useSelector } from 'react-redux'
import { selectSalesCategories } from '@/state/salesCategories'
import { updateWorkspace } from '@/state/workspaces/workspaces.thunks'
import { Workspace } from '@/records'
import { ALL_FOOD_INDUSTRY } from '@/constants/industryCategories'
import { SalesCategory } from '@/api'

const useSalesCategories = (): SalesCategory[] => {
  const salesCategories = useSelector(selectSalesCategories)

  return useMemo(() => [ALL_FOOD_INDUSTRY, ...salesCategories], [salesCategories])
}

interface ScoreCellParams extends GridRenderCellParams {
  value?: Workspace
}

export const CategorySelect: FC<ScoreCellParams> = ({ value: workspace }) => {
  const dispatch = useDispatch()
  const allSalesCategories = useSalesCategories()
  const workspaceSalesCategory = workspace.sales_category
  const salesCategory = workspaceSalesCategory?.id
    ? allSalesCategories.find((category) => category.id === workspaceSalesCategory?.id)
    : allSalesCategories.find((category) => category.id === 0)

  if (!workspace.edit) {
    return (
      <Text id={`sales-category-${workspace.id}`} data-testid={`sales-category-${workspace.id}`}>
        {salesCategory?.title}
      </Text>
    )
  }

  const handleSelectedCategoryChanged = async (categoryId) => {
    const category = allSalesCategories.find((cat) => cat.id === categoryId)
    dispatch(
      updateWorkspace({
        id: workspace.id,
        sales_category: category.id ? { id: category.id, title: category.title } : null,
      })
    )
  }

  return (
    <Select
      id={`sales-category-select-${workspace.id}`}
      data-testid={`sales-category-select-${workspace.id}`}
      aria-label={`Sales category for ${workspace.name}`}
      fullWidth
      value={salesCategory ? salesCategory.id : ''}
      renderValue={() => salesCategory?.title}
      onChange={(e) => handleSelectedCategoryChanged(e.target.value)}
      MenuProps={{ id: 'sales-category-menu' }}
      sx={{ fontSize: 14 }}
    >
      {allSalesCategories.map((option) => {
        return (
          <MenuItem key={option.id} value={option.id}>
            {option.title}
          </MenuItem>
        )
      })}
    </Select>
  )
}
