import { batch } from 'react-redux'
import { ThunkAction, unwrapResult, AnyAction } from '@reduxjs/toolkit'
import { clearProductOverview, getProduct, resetProductInfo } from '@/state/productOverview'
import { clearNutrition } from '@/state/nutrition'
import { clearRecipeReducer, resetRecipe } from '@/state/recipe'
import { setOriginLocations } from '@/state/originLocations'
import { SAVED, setView } from '@/state/pageSettings'
import { selectUser } from '@/state/user'
import { AppState } from '@/store'
import { selectFlagGoalsSplit } from '@/state/splitio'
import { selectSortedScenarios, setCurrentScenarioAndInitiative } from '@/state/initiatives'
import { addMessage } from '@/state/messages'
import { selectIsProcurement } from '@/state/router'
import { getProductImpactHistory } from '@/state/impactHistory/impactHistory.thunk'

declare const pendo: any

export const clearProductFormula = (): ThunkAction<Promise<void>, AppState, void, AnyAction> => async (dispatch) => {
  batch(() => {
    dispatch(clearRecipeReducer())
    dispatch(clearProductOverview())
    dispatch(setOriginLocations([]))
    dispatch(clearNutrition())
  })
}

// Called from FormulaProfile.tsx to begin the product load sequence
export const initializeProductFormula = (
  id: number,
  history: any,
  scenarioId?: number
): ThunkAction<Promise<void>, AppState, void, AnyAction> => async (dispatch, getState) => {
  const state = getState()
  const user = selectUser(state)
  const flagEnabled = selectFlagGoalsSplit(state)
  const scenarios = selectSortedScenarios(state)
  const isProcurement = selectIsProcurement(state)

  // Call `/v2/products/${id}` to get the product-level info
  // For third-party products, `getProduct` also writes locked claims data and `isThirdParty` to state
  const productData = await dispatch(getProduct({ id, scenario: scenarioId, history }))
  const response = unwrapResult(productData)
  dispatch(getProductImpactHistory(id))

  pendo.track('View Formula Profile', {
    productId: id,
    productOrg: response.product?.organization,
    userOrg: user?.organization,
  })

  if (isProcurement) {
    dispatch(setCurrentScenarioAndInitiative())
  } else if (flagEnabled) {
    const isValidProductScenario = !!response.product.scenarios.find((s) => s.id === scenarioId)
    const foundScenario = scenarios.find((s) => s.id === scenarioId)
    if (scenarioId && isValidProductScenario && foundScenario) {
      dispatch(setCurrentScenarioAndInitiative(scenarioId))
    } else if (response.product.formulation_status === 'scenario') {
      // If it is a scenario product and the user does not have access to the scenario, redirect to the home page
      dispatch(addMessage({ message: 'You do not have access to view this product.', severity: 'error' }))
      history.push('/')
    } else if (scenarioId && !foundScenario) {
      // no valid scenario found so remove cureent scenario and initiative and update url
      dispatch(setCurrentScenarioAndInitiative())
      window.history.pushState({}, '', `/products/${id}`)
    } else {
      // no scenario in url and this is a source product, so remove cureent scenario and initiative
      dispatch(setCurrentScenarioAndInitiative())
    }
  } else if (response.product.formulation_status === 'scenario') {
    // If it is a scenario product and FLAG is not enabled, redirect to the home page
    dispatch(addMessage({ message: 'You do not have access to view this product.', severity: 'error' }))
    history.push('/')
  }
}

export const resetProductToSaved = (): ThunkAction<Promise<void>, AppState, string, AnyAction> => async (dispatch) => {
  dispatch(resetRecipe())
  dispatch(resetProductInfo())
  dispatch(setView(SAVED))
}
