import React, { FC } from 'react'
import { Pie, PieChart, Cell, Tooltip } from 'recharts'
import { Stack, theme } from '@howgood/design'
import { formatNumber } from '@howgood/utils'
import { CategoryWithScore } from '../CarbonAccounting.types'
import { formatScore, scopeToFillMap } from '../CarbonAccounting.utils'

type Size = 'sm' | 'lg'
interface Sizes {
  chart: number
  innerRadius: number
  outerRadius: number
  font: typeof theme.typography.h1
}

const sizesMap: Record<Size, Sizes> = {
  sm: { chart: 210, innerRadius: 75, outerRadius: 105, font: theme.typography.h2 },
  lg: { chart: 280, innerRadius: 90, outerRadius: 130, font: theme.typography.h1 },
}

interface Props {
  categories: CategoryWithScore[]
  allEmissionsTotal: number
  size?: 'sm' | 'lg'
  animate?: boolean // disable in jest dom
}

export const CarbonAccountingChart: FC<Props> = ({ categories, allEmissionsTotal, size = 'lg', animate = true }) => {
  const noScores = categories.findIndex((category) => +category.score > 0) === -1
  const data = noScores ? [{ key: 'category', score: 1 }] : categories
  const fill = noScores ? theme.palette.divider : theme.palette.success.main
  const font = sizesMap[size].font

  return (
    <Stack data-testid="carbon-accounting-chart" alignItems="center" justifyContent="center">
      <PieChart width={sizesMap[size].chart} height={sizesMap[size].chart}>
        <text
          data-testid="carbon-accounting-chart-title"
          textAnchor="middle"
          x="50%"
          y="50%"
          dy={-(font.fontSize as number) / 2}
          fill={theme.palette.text.primary}
          fontFamily={theme.typography.body2.fontFamily}
          fontSize={theme.typography.body2.fontSize}
        >
          Total Annual Emissions
        </text>
        <text
          data-testid="carbon-accounting-chart-score"
          x="50%"
          y="50%"
          textAnchor="middle"
          dy={(font.fontSize as number) / 2}
          fill={theme.palette.text.primary}
          fontFamily={font.fontFamily}
          fontSize={font.fontSize}
          fontWeight={theme.typography.fontWeightBold}
        >
          {formatScore(allEmissionsTotal)}
        </text>
        <text
          data-testid="carbon-accounting-chart-units"
          x="50%"
          y="50%"
          textAnchor="middle"
          dy={font.fontSize as number}
          fill={theme.typography.caption.color}
          fontFamily={theme.typography.caption.fontFamily}
          fontSize={theme.typography.caption.fontSize}
        >
          MT CO2e/year
        </text>
        <Pie
          data={data}
          dataKey="score"
          innerRadius={sizesMap[size].innerRadius}
          outerRadius={sizesMap[size].outerRadius}
          fontSize={13}
          isAnimationActive={animate}
        >
          {data.map((d) => (
            <Cell key={d.key} fill={scopeToFillMap[d.key] || fill} />
          ))}
        </Pie>
        {!noScores && (
          <Tooltip formatter={(value: number, _name, { payload }) => [formatNumber(value), payload.title]} />
        )}
      </PieChart>
    </Stack>
  )
}
