import {
  VIEW_AGG_CRAVEABILITY,
  VIEW_AGG_SALES_POTENTIAL,
  VIEW_AGG_IMPACT_APPLEGATE_HEALTH_SCORE,
  VIEW_AGG_IMPACT_APPLEGATE,
  VIEW_AGG_IMPACT_HOWGOOD_TOTAL,
  VIEW_AGG_IMPACT_HOWGOOD_ONE_METRIC,
} from '@/constants/impactScore'

/**
 * APPLEGATE_FIX:
 * Until better solution is found around permission.  For applegate users only display applegate
 * product level metrics (even though there are more permissions available).
 * Those permissions are needed to calculate correct value on ingredient level for nested product
 */
export const applegateFixPredicate = (analysisPermissions: string[], metric: string): boolean => {
  const isApplegateProductPermission = [
    'view_agg_impact_applegate_human_health',
    'view_agg_impact_applegate_environmental_health',
    'view_agg_impact_applegate_animal_health',
    VIEW_AGG_CRAVEABILITY,
    VIEW_AGG_SALES_POTENTIAL,
    VIEW_AGG_IMPACT_APPLEGATE_HEALTH_SCORE,
  ].includes(metric)

  const isApplegateUserOnly =
    analysisPermissions.includes(VIEW_AGG_IMPACT_APPLEGATE) &&
    !analysisPermissions.includes(VIEW_AGG_IMPACT_HOWGOOD_TOTAL) &&
    !analysisPermissions.includes(VIEW_AGG_IMPACT_HOWGOOD_ONE_METRIC)

  return isApplegateUserOnly && !isApplegateProductPermission
}
