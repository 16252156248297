import React, { FC } from 'react'
import howgoodSupplierIconSmall from './assets/images/materialTypeIcons/howgood-supplier-small.png'
import internalMaterialIconSmall from './assets/images/materialTypeIcons/internal-small.png'
import standardIngredientIconSmall from './assets/images/materialTypeIcons/standard-small.png'
import purchasedMaterialIconSmall from './assets/images/materialTypeIcons/purchased-small.png'
import verifiedIconSmall from './assets/images/materialTypeIcons/verified-small.png'
import verifiedPlusIconSmall from './assets/images/materialTypeIcons/verified-plus-small.png'

import howgoodSupplierIconLarge from './assets/images/materialTypeIcons/howgood-supplier-large.png'
import internalMaterialIconLarge from './assets/images/materialTypeIcons/internal-large.png'
import standardIngredientIconLarge from './assets/images/materialTypeIcons/standard-large.png'
import purchasedMaterialIconLarge from './assets/images/materialTypeIcons/purchased-large.png'
import verifiedIconLarge from './assets/images/materialTypeIcons/verified-large.png'
import verifiedPlusIconLarge from './assets/images/materialTypeIcons/verified-plus-large.png'

export type MaterialTypeIconType =
  | 'vendor-verified'
  | 'vendor-verified-plus'
  | 'howgood-supplier'
  | 'purchased-material'
  | 'internal-material'
  | 'standard-ingredient'

export type MaterialTypeIconTypes = {
  vendorVerified: MaterialTypeIconType
  vendorVerifiedPlus: MaterialTypeIconType
  howgoodSupplier: MaterialTypeIconType
  purchasedMaterial: MaterialTypeIconType
  internalMaterial: MaterialTypeIconType
  standardIngredient: MaterialTypeIconType
}

export const MATERIAL_TYPE_ICON_TYPES: MaterialTypeIconTypes = {
  vendorVerified: 'vendor-verified',
  vendorVerifiedPlus: 'vendor-verified-plus',
  howgoodSupplier: 'howgood-supplier',
  purchasedMaterial: 'purchased-material',
  internalMaterial: 'internal-material',
  standardIngredient: 'standard-ingredient',
}

const FORMULA_ICON_TYPE_SMALL_TO_SOURCE_MAP = {
  [MATERIAL_TYPE_ICON_TYPES.vendorVerified]: verifiedIconSmall,
  [MATERIAL_TYPE_ICON_TYPES.vendorVerifiedPlus]: verifiedPlusIconSmall,
  [MATERIAL_TYPE_ICON_TYPES.howgoodSupplier]: howgoodSupplierIconSmall,
  [MATERIAL_TYPE_ICON_TYPES.purchasedMaterial]: purchasedMaterialIconSmall,
  [MATERIAL_TYPE_ICON_TYPES.internalMaterial]: internalMaterialIconSmall,
  [MATERIAL_TYPE_ICON_TYPES.standardIngredient]: standardIngredientIconSmall,
}

const FORMULA_ICON_TYPE_LARGE_TO_SOURCE_MAP = {
  [MATERIAL_TYPE_ICON_TYPES.vendorVerified]: verifiedIconLarge,
  [MATERIAL_TYPE_ICON_TYPES.vendorVerifiedPlus]: verifiedPlusIconLarge,
  [MATERIAL_TYPE_ICON_TYPES.howgoodSupplier]: howgoodSupplierIconLarge,
  [MATERIAL_TYPE_ICON_TYPES.purchasedMaterial]: purchasedMaterialIconLarge,
  [MATERIAL_TYPE_ICON_TYPES.internalMaterial]: internalMaterialIconLarge,
  [MATERIAL_TYPE_ICON_TYPES.standardIngredient]: standardIngredientIconLarge,
}

export interface MaterialTypeIconProps {
  'data-testid'?: string
  size?: 'small' | 'large'
  type: MaterialTypeIconType
}

export const MaterialTypeIcon: FC<MaterialTypeIconProps> = ({ ['data-testid']: dataTestId, size = 'small', type }) => {
  const isLarge = size === 'large'

  const style: React.CSSProperties = {
    verticalAlign: 'baseline',
    width: isLarge ? 34 : 28,
    minWidth: isLarge ? 34 : 28,
    height: isLarge ? 24 : 20,
  }

  const typeToSourceMap = isLarge ? FORMULA_ICON_TYPE_LARGE_TO_SOURCE_MAP : FORMULA_ICON_TYPE_SMALL_TO_SOURCE_MAP

  const src = typeToSourceMap[type]

  if (!src) {
    return null
  }

  return <img style={style} data-testid={dataTestId} src={src} />
}
