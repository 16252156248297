import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Stack, Text, Tooltip, theme } from '@howgood/design'

import { selectInitiativeWorkspacesById, selectSortedScenarios } from '@/state/initiatives'
import { InitiativeBasic } from '@/records/Initiatives'
import { ScenarioList } from './ScenarioList'
import { InitiativesMenu } from './InitiativesMenu'

/**
 * Component that displays a single initiative on the Initiatives page
 */

export const InitiativeItem: FC<{ initiative: InitiativeBasic }> = ({ initiative }) => {
  const workspaces = useSelector(selectInitiativeWorkspacesById(initiative.id))
  const scenarios = useSelector(selectSortedScenarios)

  return (
    <Stack
      data-testid={`initiative-item-${initiative.id}`}
      spacing={0.5}
      px={4}
      py={2}
      sx={{ '&:not(:last-of-type)': { borderBottom: `solid 1px ${theme.palette.divider}` } }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Link to={`/initiatives/${initiative.id}`} style={{ overflow: 'hidden' }}>
          <Text variant="h3">{initiative.name}</Text>
        </Link>
        <InitiativesMenu listView initiative={initiative} />
      </Stack>
      {initiative.workspaces.length > 0 && (
        <Tooltip title={workspaces.map((ws) => ws.name || ws.id).join(', ')}>
          <Text data-testid={`workspace-list-${initiative.id}`} width="fit-content" variant="caption">{`${
            workspaces.length
          } workspace${workspaces.length > 1 ? 's' : ''} included`}</Text>
        </Tooltip>
      )}
      {initiative.brands.length > 0 && (
        /**
         * For vendor initiatives, don't include a tooltip because only vendor ids are available in the `GET /initiatives` response.
         * We need to make a special request using `fetchBrandsByIds` to get the names,
         * but across all initiatives there could be more brand ids than can fit in the query string,
         * and kicking off a separate request for each initiative just so we can display names in the tooltip is overkill.
         */
        <Text data-testid={`vendor-list-${initiative.id}`} width="fit-content" variant="caption">{`${
          initiative.brands.length
        } vendor${initiative.brands.length > 1 ? 's' : ''} included`}</Text>
      )}
      <ScenarioList
        scenarios={scenarios.filter((scenario) => scenario.initiative.id === initiative.id)}
        initiative={initiative}
      />
    </Stack>
  )
}
