import { AnyAction } from 'redux'
import { batch } from 'react-redux'
import { createAsyncThunk, ThunkAction } from '@reduxjs/toolkit'
import { AppState } from '@/store'
import { selectAllWorkspacesOptions, selectThirdPartyWorkspaces, selectWorkspacesIds } from '../workspaces'
import { formulationStatuses, VendorInfo, SettableVendorInfo } from '@/records'
import {
  VendorManagementFilters,
  BucketAggs,
  initialState,
  resetVendorManagementList,
  setVendorBeingUpdated,
  setVendorJustUpdated,
  updateVendorManagementFilters,
  VendorManagementSettings,
} from './vendorManagement.slice'
import { fetchFormulationTagsByIds } from '@/utils/formulationTagsSearch'
import { createAggregatedVendorsSearchParams, getAggregatedVendorsFiltersParams } from '@/utils/getUrl'
import {
  selectConstructedFilters,
  selectVendorFormulationStatuses,
  selectVendorTags,
  selectVendorManufacturingTypeIds,
  selectVendorNames,
  selectVendorWorkspaceIds,
} from './vendorManagement.selectors'
import { selectManufacturingTypeOptions } from '../manufacturingTypes'
import { selectUserOrganizationId, selectUserVendorManagementSettings } from '../user'
import { patchVendorByBrand } from '@/api/brandApi'
import { fetchVendorData } from './vendorManagement.requests'
import { availableVendorMetrics } from './vendorManagement.metrics'

export const getVendorData = createAsyncThunk<
  { vendorData: VendorInfo[]; bucketAggs: BucketAggs },
  void,
  { state: AppState }
>('vendors/getVendorData', async (_, { getState }) => {
  const state = getState()
  const constructedFilters = selectConstructedFilters(state)
  const thirdPartyWorkspaces = selectThirdPartyWorkspaces(state)
  const orgId = selectUserOrganizationId(state)
  const { vendorData, bucketAggs } = await fetchVendorData({
    filters: constructedFilters,
    thirdPartyWorkspacesIds: thirdPartyWorkspaces.map((w) => w.id),
    orgId,
  })
  return { vendorData, bucketAggs }
})

export const initializeVendorManagementFilters = createAsyncThunk<
  Partial<VendorManagementFilters>,
  void,
  { state: AppState }
>('vendorManagement/initializeVendorManagementFilters', async (_, { getState }) => {
  const state = getState()
  const userWorkspaceOptions = selectAllWorkspacesOptions(state)
  const workspaceIds = selectWorkspacesIds(state)
  const manufacturingTypeOptions = selectManufacturingTypeOptions(state)

  const params = getAggregatedVendorsFiltersParams()
  const workspaces = userWorkspaceOptions.filter((uws) => params.workspaces.includes(uws.id.toString()))
  const manufacturingTypes = manufacturingTypeOptions.filter((mT) =>
    params.manufacturingTypes.includes(mT.id.toString())
  )
  const filteredStatuses = params.formulationStatuses?.length
    ? formulationStatuses.filter((st) => params.formulationStatuses.includes(st.value))
    : initialState.filters.formulationStatuses
  let { formulationTags } = initialState.filters

  if (params.formulationTags?.length) {
    formulationTags = await fetchFormulationTagsByIds({
      filters: {
        ids: params.formulationTags,
        workspaceIds,
      },
    })
  }

  return {
    workspaces,
    manufacturingTypes,
    vendorNameQueries: params.vendorNameQueries?.length
      ? params.vendorNameQueries
      : initialState.filters.vendorNameQueries,
    formulationStatuses: filteredStatuses,
    formulationTags,
  }
})

export const initializeVendorManagementPage = createAsyncThunk<VendorManagementSettings, void, { state: AppState }>(
  'vendorManagement/initializeVendorManagementPage',
  async (_, { dispatch, getState }) => {
    const vendorManagementSettings = selectUserVendorManagementSettings(getState())

    dispatch(resetVendorManagementList())
    await dispatch(initializeVendorManagementFilters())
    await dispatch(getVendorData())

    // The saved metric could be a metric that's no longer available
    const selectedMetric = Object.keys(availableVendorMetrics).includes(vendorManagementSettings?.selectedMetric)
      ? vendorManagementSettings.selectedMetric
      : Object.keys(availableVendorMetrics)[0]

    const savedColumnVisibility = vendorManagementSettings?.columnVisibilityModel || {}

    return {
      selectedMetric: selectedMetric,
      columnVisibilityModel: savedColumnVisibility,
    }
  }
)

export const updateFiltersUrlAndVendorData = ({
  updatedFilters,
}): ThunkAction<Promise<void>, AppState, { updatedFilters: Partial<VendorManagementFilters> }, AnyAction> => async (
  dispatch,
  getState
) => {
  // must dispatch this before getState
  dispatch(updateVendorManagementFilters(updatedFilters))
  const state = getState()
  const currentTagFilters = selectVendorTags(state)
  const formulationStatusFilters = selectVendorFormulationStatuses(getState())

  batch(() => {
    dispatch(getVendorData())
  })

  const workspaces = selectVendorWorkspaceIds(state)
  const manufacturingTypes = selectVendorManufacturingTypeIds(state)
  const vendorNameQueries = selectVendorNames(state)

  // Update the url
  window.history.pushState(
    {},
    '',
    createAggregatedVendorsSearchParams({
      workspaces,
      manufacturingTypes,
      vendorNameQueries,
      formulationTags: currentTagFilters.map((option) => option.id.toString()),
      formulationStatuses: formulationStatusFilters.map((option) => option.value),
    })
  )
}

export interface UpdateVendorByBrandInfo {
  brandId: number
  vendorInfo: Partial<SettableVendorInfo>
}

export const updateVendorByBrand = createAsyncThunk<
  UpdateVendorByBrandInfo,
  UpdateVendorByBrandInfo,
  { state: AppState }
>('vendors/updateVendorByBrand', async ({ brandId, vendorInfo }, { dispatch }) => {
  dispatch(setVendorBeingUpdated(brandId))
  await patchVendorByBrand(brandId, vendorInfo)
  // Set the last vendor updated so the UI can show a checkmark, then clear it after a few seconds
  dispatch(setVendorJustUpdated(brandId))
  setTimeout(() => {
    dispatch(setVendorJustUpdated(null))
  }, 2000)
  // A successful PATCH request doesn't return anything, so return the vendorInfo that was passed in
  return { brandId, vendorInfo }
})
