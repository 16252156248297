import { fetchHelperV2 } from '@/api'
import { Report } from '@/state/organization'

export const downloadBaseline = async (baselineId: number) => {
  const response = await fetchHelperV2<Report>({ url: `accounts/reports/${baselineId}/` })
  const link = document.createElement('a')
  link.setAttribute('href', response.s3_url)
  link.setAttribute('download', response.name)
  link.setAttribute('type', 'data:text/csv;charset=utf-8')
  link.style.display = 'none'
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
