import React, { ChangeEvent, FC, useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { InlineSelect, Box, Stack, Tabs, Tab, Text } from '@howgood/design'
import { selectDisplayedProductWorkspaces, selectIsProductLoading, updateDraftProduct } from '@/state/productOverview'
import { DRAFT, selectPageView } from '@/state/pageSettings'
import { selectDisplayedIngredientList } from '@/state/recipe'
import { getOriginLocations } from '@/state/originLocations'
import { selectDefaultWorkspaceIds, selectAllWorkspacesOptions } from '@/state/workspaces'
import { MATERIALS, PRODUCTS } from '@/records'

export const WorkspaceSelect: FC = () => {
  const dispatch = useDispatch()
  const displayedWorkspaces = useSelector(selectDisplayedProductWorkspaces)
  const view = useSelector(selectPageView)
  const ingredients = useSelector(selectDisplayedIngredientList)
  const isLoading = useSelector(selectIsProductLoading)
  const workspaces = useSelector(selectAllWorkspacesOptions)
  const defaultWorkspaces = useSelector(selectDefaultWorkspaceIds)

  const [showTab, setShowTab] = useState<number>(0)
  const hasProductWorkspace = workspaces.some((option) => option.workspace_type === PRODUCTS)
  const hasMaterialsWorkspace = workspaces.some((option) => option.workspace_type === MATERIALS)
  const color = !displayedWorkspaces.length && view === DRAFT ? 'error' : 'primary'

  const filteredOptions = useMemo(() => {
    if (!hasProductWorkspace || !hasMaterialsWorkspace) {
      return workspaces
    }
    return workspaces.filter((option) =>
      showTab === 0 ? option.workspace_type === PRODUCTS : option.workspace_type === MATERIALS
    )
  }, [workspaces, showTab, hasMaterialsWorkspace, hasProductWorkspace])

  const getWorkspace = useCallback(
    (name: string) => {
      const workspace = workspaces.find((w) => w.name === name)
      return workspace
    },
    [workspaces]
  )

  const handleWorkspaceChanged = (event: ChangeEvent) => {
    const name = event.target.textContent
    const workspace = getWorkspace(name)
    const currentIds = displayedWorkspaces.map((ws) => ws.id)
    if (currentIds.includes(workspace.id)) {
      return
    }
    dispatch(updateDraftProduct({ workspaces: [...displayedWorkspaces, workspace] }))
    const baseIds: number[] = ingredients.reduce((ids: number[], ingredient) => {
      if (ingredient.isTopLevel && 'base_id' in ingredient) {
        return [...ids, ingredient.base_id]
      }
      return ids
    }, [])
    if (baseIds.length) {
      // since the origin locations are filtered by workspaceId we need to update them here
      dispatch(getOriginLocations({ baseIds, workspaceIds: [workspace.id, ...defaultWorkspaces] }))
    }
  }

  return (
    <Stack direction="row" alignItems="center" mb={1}>
      <Box mr={0.25}>
        <Text color="text.disabled">Workspace:</Text>
      </Box>
      <Box mb={-0.25}>
        <InlineSelect
          id="workspace-select"
          data-testid="workspace-select"
          MenuListProps={{ id: 'workspace-select-menu' }}
          variant={color}
          disabled={view !== DRAFT || isLoading || !workspaces.length}
          // @ts-ignore it really is a ChangeEvent
          onChange={handleWorkspaceChanged}
          placeholder={displayedWorkspaces[0]?.name || 'Select a workspace'}
          items={filteredOptions.map((ws) => ({ label: ws.name, disabled: !ws.edit, value: ws.id }))}
          renderHeader={
            hasProductWorkspace && hasMaterialsWorkspace ? (
              <Stack flexDirection="row" justifyContent="space-between" alignItems="center" pr={2}>
                <Tabs value={showTab} variant="fullWidth" onChange={() => setShowTab(showTab === 0 ? 1 : 0)}>
                  <Tab label="Products" />
                  <Tab label="Materials" />
                </Tabs>
              </Stack>
            ) : null
          }
        />
      </Box>
    </Stack>
  )
}
