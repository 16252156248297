import { fetchHelperV2 } from '@/api'
import { ImpactHistory, ImpactScoreHistoryResponse } from './impactHistory.types'

export async function fetchProductImpactHistory(productId: number): Promise<ImpactScoreHistoryResponse> {
  return await fetchHelperV2({ url: `products/${productId}/impact-score-history/` })
}

export async function fetchVendorImpactHistory(brandId: number): Promise<ImpactHistory> {
  return await fetchHelperV2({ url: `products/brand-impact-score-history/${brandId}/` })
}
