import React from 'react'
import { GridColumnHeaderParams } from '@mui/x-data-grid-premium'
import { Tooltip, Text, Stack } from '@howgood/design'
import { useContentfulTooltip } from '@/contentfully/hooks'
import { RichText } from '@/contentfully'
import { BLOCKS } from '@contentful/rich-text-types'
import { TooltipRenderer } from '@/contentfully/renderers/TooltipRenderer'

export const EngagementStatusHeader = (params: GridColumnHeaderParams) => {
  const content = useContentfulTooltip('vendor-engagement-status')

  const title = (
    <Text variant="body2" fontWeight={600}>
      {params.colDef.headerName}
    </Text>
  )
  return (
    <Tooltip
      title={
        <Stack gap={1}>
          {title}
          <RichText
            document={content?.content}
            rendererOptions={{ renderNode: { [BLOCKS.PARAGRAPH]: TooltipRenderer } }}
          />
        </Stack>
      }
    >
      {title}
    </Tooltip>
  )
}
