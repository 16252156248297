import { createAsyncThunk } from '@reduxjs/toolkit'
import { ProductBrand, VendorProvidedReport } from '@/records'
import { AppState } from '@/store'
import { fetchBrandById, fetchVendorByBrand } from '@/api/brandApi'
import { getVendorImpactHistory } from '../impactHistory'

export interface VendorInitializationResults extends ProductBrand {
  reports: VendorProvidedReport[]
}

export const getVendorDetails = createAsyncThunk<VendorInitializationResults, number, { state: AppState }>(
  'vendor/getVendorDetails',
  async (id, { dispatch }) => {
    dispatch(getVendorImpactHistory(id))
    const brandResponse = await fetchBrandById(id)
    const vendorByBrandResponse = await fetchVendorByBrand(brandResponse.id)
    return { ...brandResponse, reports: vendorByBrandResponse?.reports || [] }
  }
)
