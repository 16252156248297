import { useSelector } from 'react-redux'
import { selectBasis, selectSelectedMetric } from '../state/portfolioDashboard'
import { useContentfulMetric } from '@/contentfully/hooks'
import { selectContentfulCustomMetricsData } from '@/state/contentfulData'

export const useUnits = () => {
  const basis = useSelector(selectBasis)
  const selectedMetric = useSelector(selectSelectedMetric)
  const customMetrics = useSelector(selectContentfulCustomMetricsData)
  const metricEntry = useContentfulMetric(selectedMetric)

  const units = selectedMetric?.startsWith('custom_')
    ? customMetrics.find((metric) => metric.key === selectedMetric)?.units || ''
    : metricEntry?.units || ''

  // CO2 emission metrics are in kg CO2e per kg
  // Land occupation is in hectares per metric ton (ha/mt)
  // Water usage is in cubic meters per metric ton (m3/mt)
  switch (basis) {
    case 'kg':
      return units.replace('/kg', '').replace('/mt', '')
    case 'inventories':
      return units
        .replace('kg', 'mt')
        .replace('/kg', '/yr')
        .replace('/mt', '/yr')
    case 'sales':
      return units.replace('/kg', '/yr').replace('/mt', '/yr')
    default:
      return ''
  }
}
