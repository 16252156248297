import React, { FC, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { Button, Text } from '@howgood/design'
import { getElasticDiscrepancies, Discrepancies, getIsNonNumeric } from './DebugTools.utils'

export const INVALID_SEARCH_TEXT = 'Invalid product ID!'
export const NO_DISCREPANCIES_TEXT = 'No discrepancies between Elastic Search and API for this product!'
export const NON_NUMERIC_TEXT = 'Non-numeric comparison'

const useStyles = createUseStyles({
  form: { display: 'flex', marginBottom: 30 },
  input: { marginRight: 10 },
  table: {
    border: '1px solid',

    '& th, & td': {
      border: '1px solid',
      padding: 10,
    },
  },
})

export const DebugTools: FC = () => {
  const classes = useStyles()
  const [productId, setProductId] = useState<string>(null)
  const [discrepancies, setDiscrepancies] = useState<Discrepancies>(null)
  const [error, setError] = useState<boolean>(false)

  async function handleSubmit() {
    const elasticDiscrepancies = await getElasticDiscrepancies(productId)
    setError(elasticDiscrepancies.error)
    setDiscrepancies(elasticDiscrepancies.discrepancies)
  }

  const noDiscrepancies = discrepancies !== null && Object.keys(discrepancies).length === 0
  const hasDiscrepancies = discrepancies !== null && Object.keys(discrepancies).length > 0

  return (
    <div data-testid="debug-tools-container">
      <div className={classes.form}>
        <input
          data-testid="debug-input"
          className={classes.input}
          placeholder="Enter Product ID"
          onChange={(e) => setProductId(e.currentTarget.value)}
        />
        <Button data-testid="debug-button" onClick={handleSubmit}>
          Search
        </Button>
      </div>
      {error && <Text>{INVALID_SEARCH_TEXT}</Text>}
      {noDiscrepancies && <Text>{NO_DISCREPANCIES_TEXT}</Text>}
      {hasDiscrepancies && (
        <table data-testid="debug-table" className={classes.table}>
          <thead>
            <tr>
              <th>Field</th>
              <th>ElasticSearch</th>
              <th>API</th>
              <th>Difference</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(discrepancies).map(([key, value]) => (
              <tr key={key}>
                <td>{key}</td>
                <td data-testid={`debug-elastic-cell-${key}`}>{value[0].toString()}</td>
                <td data-testid={`debug-api-cell-${key}`}>{value[1].toString()}</td>
                <td data-testid={`debug-diff-cell-${key}`}>
                  {getIsNonNumeric(value[0], value[1]) ? NON_NUMERIC_TEXT : Math.abs(+value[0] - +value[1])}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  )
}
