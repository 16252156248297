import { AppState } from '@/store'
import { View, CompareTo, ScoreType } from './pageSettings.slice'

export const selectPageView = (state: AppState): View => state.pageSettings.view

export const selectLastChange = (state: AppState): string => state.pageSettings.lastChange

export const selectExpanded = (state: AppState): string[] => state.pageSettings.expanded

export const selectUnsavedPromptDestination = (state: AppState): string => state.pageSettings.unsavedPromptDestination

export const selectCompareTo = (state: AppState): CompareTo => state.pageSettings.compareTo

export const selectLoadingOverlay = (state: AppState): string => state.pageSettings.loadingOverlay

export const selectIsReportToolkitOpen = (state: AppState): boolean => state.pageSettings.reportToolkitOpen

export const selectIsAbatementStrategiesOpen = (state: AppState): boolean => state.pageSettings.abatementStrategiesOpen

export const selectIsGenerateReport = (state: AppState): boolean => state.pageSettings.generateReport

export const selectScoreType = (state: AppState): ScoreType => state.pageSettings.scoreType

export const selectShowCarbonExtras = (state: AppState) => state.pageSettings.showCarbonExtras
