import React, { FC } from 'react'
import { selectSharingRequests } from '@/state/productOverview'
import { useSelector } from 'react-redux'
import { theme, Stack, Tooltip, Icon } from '@howgood/design'
import { GridRowsProp, GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium'
import { faClock, faCheck, faOctagonExclamation } from '@fortawesome/pro-regular-svg-icons'
import { SHARE_CONFIRMED, SHARE_DENIED, SHARE_REQUESTED, ShareRequestStatus } from '@/api'
import { sharingText } from './validationCopy'
import { DateCell, DateCellProps } from './DateCellRenderer'
import { HistoryTable } from './HistoryTable'

interface StatusProps extends GridRenderCellParams {
  value: string
}

const StatusCell: FC<StatusProps> = ({ value }) => {
  const shareDisplayMap = {
    [SHARE_REQUESTED]: {
      text: 'Requested',
      icon: faClock,
      color: theme.palette.info.light,
    },
    [SHARE_CONFIRMED]: {
      text: 'Confirmed',
      icon: faCheck,
      color: theme.palette.success.main,
    },
    [SHARE_DENIED]: {
      text: 'Denied',
      icon: faOctagonExclamation,
      color: theme.palette.error.main,
    },
  }

  if ([SHARE_REQUESTED, SHARE_CONFIRMED, SHARE_DENIED].includes(value as ShareRequestStatus)) {
    return (
      <Tooltip title={sharingText[value]}>
        <Stack direction="row" alignItems="center" gap={1}>
          <Icon color={shareDisplayMap[value].color} size="sm" icon={shareDisplayMap[value].icon} />
          {shareDisplayMap[value].text}
        </Stack>
      </Tooltip>
    )
  }
  return null
}

const columns: GridColDef[] = [
  {
    field: 'requested',
    headerName: 'Requested',
    width: 110,
    renderCell: (params: DateCellProps) => <DateCell {...params} />,
  },
  { field: 'description', headerName: 'Description', flex: 1, sortable: false },
  {
    field: 'status',
    headerName: 'Status',
    width: 120,
    sortable: false,
    renderCell: (params: StatusProps) => <StatusCell {...params} />,
  },
  {
    field: 'updated',
    headerName: 'Updated',
    width: 100,
    renderCell: (params: DateCellProps) => <DateCell {...params} />,
  },
]

export const SharingRequestHistory: FC = () => {
  const sharingRequests = useSelector(selectSharingRequests)

  if (sharingRequests.length === 0) {
    return null
  }

  const rows: GridRowsProp = sharingRequests.map((request) => {
    return {
      id: request.id,
      requested: request.date_created,
      updated: request.date_modified,
      description: request.description,
      status: request.status,
    }
  })

  return <HistoryTable rows={rows} columns={columns} uniqueId="validation" />
}
