import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { faFilePlus } from '@fortawesome/pro-regular-svg-icons'
import { MenuItem, ListItemIcon, ListItemText } from '@howgood/design'

import { selectIsCreateNew, selectIsScenarioContext } from '@/state/productOverview'

export const UseIngredient: FC<{ onClick: () => void }> = ({ onClick }) => {
  const createNew = useSelector(selectIsCreateNew)
  const showScenarioOptions = useSelector(selectIsScenarioContext)

  if (createNew || showScenarioOptions) {
    return null
  }

  return (
    <MenuItem id="use-in-new-formula-option" data-testid="use-in-new-formula-option" onClick={onClick}>
      <ListItemIcon icon={faFilePlus} />
      <ListItemText>Use as ingredient in new formula</ListItemText>
    </MenuItem>
  )
}
