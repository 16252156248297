import { AppState } from '@/store'
import { CardType, Preferences, GridSettings } from './preferences.slice'

export const selectPreferences = (state: AppState): Preferences => state.preferences.preferences

export const selectFavoriteTiles = (state: AppState): string[] => state.preferences.preferences.favoriteTiles

export const selectFavoriteCards = (state: AppState): CardType[] => state.preferences.preferences.favoriteCards

export const selectGridSettings = (state: AppState): GridSettings => state.preferences.preferences.gridSettings

export const selectMetricGridColumns = (state: AppState): string[] =>
  state.preferences.preferences.selectedMetricGridColumns

export const selectVendorManagementGridVisibleColumns = (state: AppState): string[] =>
  state.preferences.preferences.selectedVendorManagementGridColumns
