import useSWR from 'swr'
import { FormulationTag, IngredientsResponse } from './types'
import { fetchHelperV2, FetchV2PaginatedResponse, InventoriesResponse } from '@/api'
import { IProduct, PatchProductData } from '@/records'
import { getAnalysisImpactScore } from '@/utils/formulationSearch'
import { ProductResponse } from '@/state/productOverview/productOverview.state'
import { Goals } from '@/records/Workspace'

export const fetchProduct = async (id: number): Promise<ProductResponse> =>
  await fetchHelperV2({ url: `products/${id}/` })

export const useProduct = (id: string) => {
  const url = `products/${id}`
  const { data, error } = useSWR(url, () => fetchHelperV2<IProduct>({ url }))

  return {
    data,
    isError: Boolean(error),
    isLoading: !data && !error,
  }
}

interface UpdateProduct {
  id: number
  data: PatchProductData
  updateImpact?: boolean
}

export const updateProduct = async ({ id, data }: UpdateProduct): Promise<IProduct> => {
  const d = data.workspaces ? { ...data, workspaces: data.workspaces } : { ...data }
  const product = await fetchHelperV2({
    url: `products/${id}/`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(d),
  })
  return product
}

export const updateProductAndScores = async ({ id, data }: UpdateProduct): Promise<IProduct> => {
  const product = await updateProduct({ id, data })
  const impact_score = await getAnalysisImpactScore(id)
  return { ...product, impact_score, ...impact_score }
}

export const fetchProductIngredients = async (
  productId: string | number,
  nested = true
): Promise<IngredientsResponse> => {
  try {
    const ingredients = await fetchHelperV2<IngredientsResponse>({
      url: `products/${productId}/ingredients/${nested ? '?depth=full' : ''}`,
    })
    return ingredients
  } catch (e) {
    if (e.message.includes('foreign product')) {
      return []
    }
    throw e
  }
}

export const fetchFormulationTags = async (): Promise<FormulationTag[]> => {
  const response = await fetchHelperV2<FetchV2PaginatedResponse<FormulationTag>>({
    url: `products/formulation-tags/?size=100`,
  })
  return response?.items || []
}

export const fetchProductChangelog = async (productId: number) => {
  return await fetchHelperV2<string[]>({ url: `products/${productId}/changelog/` })
}

export const fetchProductInventories = async (
  id: number,
  isForeign: boolean = false
): Promise<InventoriesResponse[]> => {
  if (isForeign) return []
  return await fetchHelperV2({ url: `products/${id}/inventories/` })
}

interface Tag {
  id?: number
  name: string
}
export interface InventoryData {
  formulation_tags?: Tag[]
  mt_per_year?: number
  price_per_mt?: number
  goals?: Goals
}

export const patchProductInventory = async ({
  product_id,
  inventory_id,
  data,
  scenarioId,
}: {
  product_id: number
  inventory_id: number
  data: InventoryData
  scenarioId?: number
}): Promise<InventoriesResponse> =>
  await fetchHelperV2({
    url: scenarioId
      ? `scenarios/${scenarioId}/products/${product_id}/inventories/${inventory_id || ''}/`
      : `products/${product_id}/inventories/${inventory_id || ''}/`,
    method: 'PATCH',
    body: JSON.stringify({ ...data }),
  })

interface UseSources {
  data: string[]
  isLoading: boolean
  isError: boolean
}
export function useSources(productId: number): UseSources {
  const url = productId ? `products/${productId}/sources/` : null
  const { data, error } = useSWR(url, () => fetchHelperV2<string[]>({ url }))

  return {
    data,
    isLoading: !data && !error,
    isError: Boolean(error),
  }
}

type SourceType = 'greenhouse_gas_emissions_sources' | 'soc_source' | 'luc_ghg_emissions_sources' | 'yield_source'
export type Sources = Record<SourceType, string>
interface UseOriginLocationSources {
  data: Sources
  isLoading: boolean
  isError: boolean
}
export function useOriginLocationSources(originLocationId: number): UseOriginLocationSources {
  const url = originLocationId ? `ingredients/origin-locations/${originLocationId}/sources/` : null
  const { data, error } = useSWR(url, () => fetchHelperV2<Sources>({ url }))

  return {
    data,
    isLoading: !data && !error,
    isError: Boolean(error),
  }
}
