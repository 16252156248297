import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { IconButton, Menu, Stack, Text } from '@howgood/design'

import { selectSavedProductInfo } from '@/state/productOverview'
import { selectCurrentScenario } from '@/state/initiatives'
import { ScenarioProductVersions } from './ScenarioProductVersions'
import { faSort } from '@fortawesome/pro-solid-svg-icons'

export const ScenarioProductSelector: FC = () => {
  const productInfo = useSelector(selectSavedProductInfo)
  const currentScenario = useSelector(selectCurrentScenario)

  const [anchorEl, setAnchorEl] = useState(null)

  if (!currentScenario?.id && !productInfo.scenarios?.length) return null

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(e.currentTarget)
  }

  return (
    <>
      <IconButton
        data-testid="scenario-version-button"
        size="small"
        icon={faSort}
        color="inherit"
        onClick={handleButtonClick}
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        MenuListProps={{ 'data-testid': 'scenario-version-menu' }}
        sx={{ transform: 'translate(0, 5px)' }}
      >
        <Stack gap={1}>
          <Stack direction="row" ml={2} alignItems="center" justifyContent="space-between">
            <Text color="text.secondary">Choose a scenario version</Text>
          </Stack>
          <ScenarioProductVersions />
        </Stack>
      </Menu>
    </>
  )
}
