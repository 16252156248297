import React, { useState, useEffect, FC } from 'react'
import { createUseStyles } from 'react-jss'
import { LegacyDialog } from '@howgood/design'
import { faTrashCan } from '@fortawesome/pro-regular-svg-icons'
import { updateProduct } from '@/api'
import { useNavigate } from '@/hooks/useNavigate'
import { selectSavedProductInfo } from '@/state/productOverview'
import { useSelector } from 'react-redux'

const useNavigateAfterDelay = (route: string, delay: number = 2000): [boolean, (success: boolean) => void] => {
  const [success, setSuccess] = useState(false)
  const { safelyNavigateTo } = useNavigate()

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> = null
    if (success) {
      timeout = setTimeout(() => safelyNavigateTo(route), delay)
    }
    return () => {
      if (timeout) clearTimeout(timeout)
    }
  }, [success, route, delay, safelyNavigateTo])

  return [success, setSuccess]
}

const useStyles = createUseStyles({
  dialog: {
    backgroundColor: '#fff',
    '& .bp3-dialog-header': {
      fontWeight: 600,
      boxShadow: 'none',
    },
  },
  bold: { fontWeight: 700 },
  body: { padding: '0 20px' },
  text: { margin: '15px 0' },
  buttons: { display: 'flex' },
  button: { marginRight: 10 },
})

interface Props {
  onClose: () => void
}

export const ArchiveDialog: FC<Props> = ({ onClose }) => {
  const classes = useStyles()
  const savedProduct = useSelector(selectSavedProductInfo)
  const [success, setSuccess] = useNavigateAfterDelay('/')

  const handleArchive = async () => {
    await updateProduct({
      id: savedProduct.id,
      data: { formulation_status: 'archived' },
    })
    setSuccess(true)
  }

  return (
    <LegacyDialog
      title="Archive Product"
      onClose={onClose}
      open
      primaryButtonText="Archive"
      primaryButtonAction={handleArchive}
      primaryButtonColor="error"
      primaryButtonIcon={faTrashCan}
      primaryButtonAriaLabel="Archive permanently"
      secondaryButtonText="Cancel"
      secondaryButtonAction={onClose}
    >
      <div className={classes.body}>
        {success ? (
          <div aria-label="Archive successful" className={classes.text}>
            Success! Sending you back home...
          </div>
        ) : (
          <div className={classes.text}>
            Are you sure you want to archive <span className={classes.bold}>{savedProduct.name}</span>? This action
            cannot be undone.
          </div>
        )}
      </div>
    </LegacyDialog>
  )
}
