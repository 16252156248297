import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons'
import { Autocomplete, Icon, MenuItem, Stack, Text, TextField } from '@howgood/design'
import { FormulationTagSearchOption } from '@/records'
import { fetchFormulationTagsByQuery } from '@/utils/formulationTagsSearch'
import { selectWorkspacesIds } from '@/state/workspaces'

const ADD_TAG_OPTION: FormulationTagSearchOption = {
  raw: null,
  id: -1,
  value: -1,
  name: '',
  label: '',
  workspaces: [],
}

interface Props {
  selectedFormulationTags: FormulationTagSearchOption[]
  setSelectedFormulationTags: (formulationTags: FormulationTagSearchOption[]) => void
  fullWidth?: boolean
  addTagsEnabled?: boolean
}

export const FormulationTags: FC<Props> = ({
  selectedFormulationTags,
  setSelectedFormulationTags,
  fullWidth,
  addTagsEnabled = true,
}) => {
  const ownWorkspacesIds = useSelector(selectWorkspacesIds)
  const [search, setSearch] = useState<string>('')
  const [options, setOptions] = useState<FormulationTagSearchOption[]>([])

  const fetchFormulationTagsOptions = async (query: string): Promise<FormulationTagSearchOption[]> => {
    if (!query) {
      return new Promise(() => [])
    }
    return fetchFormulationTagsByQuery({
      filters: {
        search: query,
        workspaceIds: ownWorkspacesIds,
      },
    }).then((hits: object[]) => {
      const ops = hits.map((hit: any) => new FormulationTagSearchOption(hit))
      // Remove duplicate items and existing in selected tags
      return ops.filter((option: FormulationTagSearchOption) => {
        const firstMatched = ops.find(
          (opt: FormulationTagSearchOption) => opt.name.trim().toLowerCase() === option.name.trim().toLowerCase()
        )
        // will not work with cacheOptions
        const isInSelectedFormulationTags = !!selectedFormulationTags.find(
          (tag) => tag.name.trim().toLowerCase() === option.name.trim().toLowerCase()
        )
        return firstMatched === option && !isInSelectedFormulationTags
      })
    })
  }

  return (
    <Stack data-pendo-id="Filter>SearchTags">
      <Autocomplete
        data-testid="tag-search"
        options={options}
        value={selectedFormulationTags}
        onChange={(_event, ops) => setSelectedFormulationTags(ops)}
        filterSelectedOptions
        multiple
        fullWidth={fullWidth}
        renderInput={(params) => (
          <TextField
            {...params}
            id="tag-search"
            placeholder="Search Tags"
            onChange={async (event) => {
              const ops = await fetchFormulationTagsOptions(event.target.value)
              setOptions(ops)
              setSearch(event.target.value)
            }}
          />
        )}
        renderHeader={() => {
          const addTagOption: FormulationTagSearchOption = {
            ...ADD_TAG_OPTION,
            name: search,
            label: search,
          }
          const tagExists = options.findIndex((opt) => opt.label.toLowerCase() === search.toLowerCase()) !== -1
          if (!addTagsEnabled || tagExists) {
            return null
          }
          return (
            <MenuItem
              value={addTagOption as any}
              onClick={() => setSelectedFormulationTags([...selectedFormulationTags, addTagOption])}
            >
              <Stack flexDirection="row" alignItems="center" gap={1}>
                <Icon icon={faPlusCircle} /> <Text>{`Create "${search}"`}</Text>
              </Stack>
            </MenuItem>
          )
        }}
        renderOption={(props, option) => (
          <MenuItem
            {...props}
            data-testid="tag-search-option"
            key={option.value}
            value={option}
            onClick={() => setSelectedFormulationTags([...selectedFormulationTags, option])}
          >
            {option.label}
          </MenuItem>
        )}
        alwaysShowHeaderAndFooter
      />
    </Stack>
  )
}
