import useSWR from 'swr'
import { FORMULATIONS_SEARCH_PATH, INGREDIENTS_SEARCH_PATH } from '@/constants/config'
import { fetchElasticV2 } from '@/api'
import { constructProductsBoolQuery } from './productsEsRepo'
import { AggregationsAggregationContainer } from '@elastic/elasticsearch/api/types'
import { Field } from '@/constants/impactScore'
import { roundBy } from '@/utils/numbers'
import { CategoryType } from '@/components/ProductImpact/ExportProductOverview/CategoryType'
import { estypes } from '@elastic/elasticsearch'

interface FetchIndustryBenchmarkPayload {
  workspaceIds: number[]
  formulationTag: string
  category: string
  categoryType: CategoryType
  field: Field
}

export interface IndustryBenchmarkData {
  distribution: {
    score: number
    hgPortfolio: number
    averageRetail: number
  }[]
  hgPortfolioAverage: { raw: number; score: number }
  avgRetailAverage: { raw: number; score: number }
  min: number
  max: number
  interval: number
}

const constructFunctionalCategoryQuery = (category: string) => {
  return {
    filter: [
      {
        bool: {
          should: [
            {
              match_phrase: {
                'base.labels.keyword': category,
              },
            },
          ],
          minimum_should_match: 1,
        },
      },
    ],
  }
}

interface ConstructAggsPartParams {
  impactScorePath?: string
  impactMetric: string
  min: number
  max: number
  cardinalityField?: string
  field: string
  interval: number
}

const constructAggsPart = ({
  impactScorePath = 'impact_score.',
  impactMetric,
  min,
  max,
  cardinalityField = 'pk',
  field,
  interval,
}: ConstructAggsPartParams): Record<string, AggregationsAggregationContainer> => {
  return {
    score_histogram: {
      histogram: {
        field: `${impactScorePath}${impactMetric}`,
        interval,
        extended_bounds: {
          min,
          max,
        },
      },
      aggs: {
        card: {
          cardinality: {
            field: cardinalityField,
          },
        },
        percent_of_products: {
          normalize: {
            buckets_path: 'card',
            method: 'percent_of_sum',
            format: '00.00%',
          },
        },
      },
    },
    average: {
      avg: {
        field: `${impactScorePath}${field}`,
      },
    },
    impactAverage: {
      avg: {
        field: `${impactScorePath}${impactMetric}`,
      },
    },
  }
}

const fetchIndustryBenchmark = async ({
  workspaceIds,
  formulationTag,
  categoryType,
  category,
  field,
}: FetchIndustryBenchmarkPayload): Promise<IndustryBenchmarkData> => {
  const _source = []

  const portfolioQuery = constructProductsBoolQuery({
    workspaceIds,
    formulationTags: formulationTag ? [formulationTag] : [],
  })

  let industryQuery = {}
  if (category) {
    industryQuery =
      categoryType === 'sales'
        ? constructProductsBoolQuery({ salesCategories: [category] })
        : constructFunctionalCategoryQuery(category)
  }

  let min = 0,
    max,
    interval,
    impactMetric = field

  if (field === 'howgood_one_metric') {
    max = 100
    interval = 10
  } else {
    max = 10
    interval = 1
    switch (field) {
      case 'cf_processing_impact':
        impactMetric = 'processing_level_impact'
        break
      case 'raw_land_use_impact':
        impactMetric = 'land_use_impact'
        break
      case 'raw_greenhouse_gas_impact':
        impactMetric = 'greenhouse_gas_impact'
        break
      case 'raw_blue_water_usage_impact':
        impactMetric = 'blue_water_impact'
        break
      default:
      // NTBD
    }
  }

  const impactScorePath = categoryType === 'sales' ? 'impact_score.' : ''
  const retailSearchPath = categoryType === 'sales' ? FORMULATIONS_SEARCH_PATH : INGREDIENTS_SEARCH_PATH
  const cardinalityField = categoryType === 'sales' ? 'pk' : 'id'

  const retailAggs = constructAggsPart({
    impactScorePath,
    impactMetric,
    field,
    cardinalityField,
    min,
    max,
    interval,
  })
  const portfolioAggs = constructAggsPart({
    impactMetric,
    field,
    min,
    max,
    interval,
  })

  const howGoodPortfolio = await fetchElasticV2({
    url: FORMULATIONS_SEARCH_PATH,
    body: {
      size: 0,
      _source,
      aggs: portfolioAggs,
      query: { bool: portfolioQuery },
    },
  })

  const averageRetail = await fetchElasticV2({
    url: retailSearchPath,
    body: {
      size: 0,
      _source,
      aggs: retailAggs,
      query: { bool: industryQuery },
    },
  })
  const portfolioHistogram = howGoodPortfolio.aggregations
    .score_histogram as estypes.AggregationsMultiBucketAggregateBase<any>
  const retailHistogram = averageRetail.aggregations.score_histogram as estypes.AggregationsMultiBucketAggregateBase<
    any
  >

  const distribution = portfolioHistogram.buckets.map((obj) => {
    const averageRetailPct = retailHistogram.buckets.find((retailObj) => retailObj.key === obj.key)

    return {
      score: obj.key,
      hgPortfolio: obj.percent_of_products.value ?? 0,
      averageRetail: averageRetailPct.percent_of_products.value ?? 0,
    }
  })

  const portfolioAverage = howGoodPortfolio.aggregations.average as estypes.AggregationsCardinalityAggregate
  const portfolioImpactAverage = howGoodPortfolio.aggregations.impactAverage as estypes.AggregationsCardinalityAggregate
  const retailAverage = averageRetail.aggregations.average as estypes.AggregationsCardinalityAggregate
  const retailImpactAverage = averageRetail.aggregations.impactAverage as estypes.AggregationsCardinalityAggregate

  return {
    distribution,
    hgPortfolioAverage: {
      raw: portfolioAverage.value,
      score: portfolioImpactAverage.value,
    },
    avgRetailAverage: {
      raw: retailAverage.value,
      score: retailImpactAverage.value,
    },
    min,
    max,
    interval,
  }
}
interface FetchPortfolioAttributesPayload {
  workspaceIds: number[]
  formulationTag: string
  attributes: string[]
}

const fetchPortfolioAttributes = async ({
  workspaceIds,
  formulationTag,
  attributes,
}: FetchPortfolioAttributesPayload): Promise<Record<string, number>> => {
  const query = constructProductsBoolQuery({ workspaceIds, formulationTags: formulationTag ? [formulationTag] : [] })

  const aggs: Record<string, AggregationsAggregationContainer> = {}

  attributes.forEach((attribute) => {
    aggs[attribute] = {
      avg: {
        field: `impact_score.${attribute}`,
      },
    }
  })

  const elasticResponse = await fetchElasticV2({
    url: FORMULATIONS_SEARCH_PATH,
    body: {
      size: 0,
      query: { bool: query },
      aggs,
    },
  })

  const result: Record<string, number> = {}
  attributes.forEach((attribute) => {
    const att = elasticResponse.aggregations[attribute] as estypes.AggregationsCardinalityAggregate
    result[attribute] = roundBy(att.value * 100, 0)
  })

  return result
}

interface UseIndustryBenchmarkParams {
  workspaceIds: number[]
  formulationTag: string
  categoryType: CategoryType
  category: string
  field: Field
}

export const useIndustryBenchmark = ({
  workspaceIds,
  formulationTag,
  categoryType,
  category,
  field,
}: UseIndustryBenchmarkParams) => {
  const { data, error } = useSWR(
    { workspaceIds, formulationTag, categoryType, category, field },
    fetchIndustryBenchmark,
    {
      fallbackData: {
        distribution: [],
        hgPortfolioAverage: { raw: 0, score: 0 },
        avgRetailAverage: { raw: 0, score: 0 },
        min: 0,
        max: 0,
        interval: 0,
      },
    }
  )

  return {
    data,
    isLoading: !data.distribution.length && !error,
    isError: Boolean(error),
  }
}

export const usePortfolioAttributes = (workspaceIds: number[], formulationTag: string, attributes: string[]) => {
  const { data, error } = useSWR({ workspaceIds, formulationTag, attributes }, fetchPortfolioAttributes)

  return {
    data,
    isLoading: !data && !error,
    isError: Boolean(error),
  }
}
