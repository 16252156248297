import React, { FC } from 'react'
import { Document } from '@contentful/rich-text-types'
import { Text, Stack, Accordion, AccordionSummary, AccordionDetails } from '@howgood/design'
import { useUrlWithUseridParam } from '@/hooks/useUrlWithUseridParam'
import { RequestVendorDataRichText } from './RequestVendorDataRichText'
import { CopyOrOpenLinkActions } from './CopyOrOpenLinkActions'

const LINK_SRC = 'https://howgood.typeform.com/keypractices'

interface Props {
  ['data-testid']?: string
  expanded: boolean
  isCopiedFromCurrent: boolean
  onChange: () => void
  handleCopy: () => void
  title: string
  content: Document
}

export const CollectAgriculturalAndFarmingPracticeAccordion: FC<Props> = ({
  ['data-testid']: dataTestId,
  expanded,
  isCopiedFromCurrent,
  onChange,
  handleCopy,
  title,
  content,
}) => {
  const url = useUrlWithUseridParam(LINK_SRC)
  return (
    <Accordion type="secondary" expanded={expanded} onChange={onChange} data-testid={dataTestId}>
      <AccordionSummary>
        <Text variant="subtitle1">{title}</Text>
      </AccordionSummary>
      <AccordionDetails>
        <Stack gap={1}>
          <RequestVendorDataRichText document={content} />
        </Stack>
        <CopyOrOpenLinkActions url={url} isCopied={isCopiedFromCurrent} handleCopy={handleCopy} />
      </AccordionDetails>
    </Accordion>
  )
}
