import { useSelector } from 'react-redux'
import { useCallback, useMemo } from 'react'
import { GridExceljsProcessInput } from '@mui/x-data-grid-premium/hooks/features/export'
import { addHGLogo, formatHeaderRow, autoAdjustColWidths, mergeAndWrapCells } from '@/utils/excelUtils'
import { useExcelExportAggRows } from './useExcelExportAggRows'
import { useContentfulMethodology } from '@/contentfully/hooks'
import { selectIsProcurement } from '@/state/router'

export const useExcelPrePostProcess = (isScope3?: boolean) => {
  const isProcurement = useSelector(selectIsProcurement)
  const aggRowData = useExcelExportAggRows(isScope3)
  const methodology = useContentfulMethodology('scope-3-procurement')

  const procurementBoilerPlateRows = useMemo(
    () => [
      ['', 'Report Generated:', new Date().toLocaleString()],
      ['', 'Research Methodology Link:', methodology?.link],
      ['', `Disclaimer: ${methodology?.description}`],
    ],
    [methodology]
  )

  const exceljsPreProcess = async ({ worksheet }: GridExceljsProcessInput) => {
    worksheet.name = 'Portfolio Analysis'
  }
  const exceljsPostProcess = useCallback(
    async ({ worksheet: productListWorksheet, workbook }: GridExceljsProcessInput) => {
      // Format this worksheet
      formatHeaderRow(productListWorksheet, 1)
      autoAdjustColWidths(productListWorksheet)

      // Add a new worksheet for aggregations and add agg data
      const aggSheet = workbook.addWorksheet('Portfolio Aggregations')
      aggSheet.addRows(aggRowData)
      formatHeaderRow(aggSheet, 1)
      autoAdjustColWidths(aggSheet)
      addHGLogo({ worksheet: aggSheet, workbook })
      if (isProcurement) {
        // intentionally add these after the auto adjust widths
        aggSheet.addRows(procurementBoilerPlateRows)
        const lastRowIdx = aggSheet.lastRow.number.valueOf()
        aggSheet.getCell(`C${lastRowIdx - 1}`).value = {
          text: methodology?.link,
          hyperlink: methodology?.link,
        }
        // merge disclaimer description cells
        mergeAndWrapCells(aggSheet, `B${lastRowIdx}:D${lastRowIdx}`)
      }

      // reorder Sheets
      // @ts-ignore - TS doesn't know about the orderNo property
      productListWorksheet.orderNo = 2
      // @ts-ignore - TS doesn't know about the orderNo property
      aggSheet.orderNo = 1
    },
    [isProcurement, aggRowData, methodology, procurementBoilerPlateRows]
  )

  return { exceljsPreProcess, exceljsPostProcess }
}
