import React, { useEffect, FC, useCallback, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Box, CircularProgress, Text, Stack } from '@howgood/design'
import { clearProductFormula, initializeProductFormula } from '@/thunks'
import {
  DRAFT,
  PUBLISHED,
  selectPageView,
  selectUnsavedPromptDestination,
  setCompareTo,
  setView,
} from '@/state/pageSettings'
import {
  generateReport,
  selectHasImpactsOverriddenNestedProduct,
  selectIsCreateNew,
  selectIsImpactsOverridden,
  selectIsSupplierConnectCreateNew,
  selectIsProductLoading,
  selectIsThirdParty,
  selectShouldGenerateReport,
  updateDraftProduct,
} from '@/state/productOverview'
import { usePendoTrack, useQuery } from '@/hooks'
import { addInitialNestedProductById, selectIsFormulaOveriewError, selectIsRecipeLoading } from '@/state/recipe'
import { selectDebugToolsSplit, selectFlagGoalsSplit } from '@/state/splitio'
import { getCurrentFormulaId, getSearchParams } from '../../utils/getUrl'
import { Header } from './panels/Header'
import { Scores } from '@/components/Scores/Scores'
import { ProductInfo } from './panels/ProductInfo'
import { Ingredients } from './panels/Ingredients/Ingredients'
import { DebugPanel } from './panels/Debug/DebugPanel'
import { selectIsContentfulLoading } from '@/state/contentfulData'
import { getFormulationTags } from '@/state/formulationTags'
import { UnsavedChanges } from './UnsavedChanges'
import { LoadError } from './LoadError'
import { useScoreCardDataReady } from '@/components/Scores/ScoreCards'
import { ProductGhgImpactExport } from './export/ProductGhgImpactExport/ProductGhgImpactExport'
import { PDFExport } from '@progress/kendo-react-pdf'
import { drawDOM, exportPDF } from '@progress/kendo-drawing'
import { selectAllWorkspacesOptions } from '@/state/workspaces'
import { ImpactsOverriddenProductDisclaimer } from '@/components/Scores/ImpactsOverriddenProductDisclaimer'
import { useHistory } from 'react-router'
import { StaleProductAlert } from './StaleProductAlert'
import { PDF_EXPORT_SETTINGS } from '@/constants/pdfExportSettings'

const FormulaProfileComponent: FC<{ productId: number; scenarioId: number }> = ({ productId, scenarioId }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const pendoTrack = usePendoTrack()
  const pdfContentRef = useRef(null)
  const productGhgImpactExportRef = useRef<PDFExport>()
  const thirdPartyProduct = useSelector(selectIsThirdParty)
  const impactsOverriddenProduct = useSelector(selectIsImpactsOverridden)
  const hasImpactsOverriddenNestedProduct = useSelector(selectHasImpactsOverriddenNestedProduct)
  const isCreateNew = useSelector(selectIsCreateNew)
  const isSupplierConnectCreateNew = useSelector(selectIsSupplierConnectCreateNew)
  const loadError = useSelector(selectIsFormulaOveriewError)
  const isContentfulLoading = useSelector(selectIsContentfulLoading)
  const isProductLoading = useSelector(selectIsProductLoading)
  const isRecipeLoading = useSelector(selectIsRecipeLoading)
  const pageView = useSelector(selectPageView)
  const debugEnabled = useSelector(selectDebugToolsSplit)
  const isUnsavedPromptOpen = !!useSelector(selectUnsavedPromptDestination)
  const workspacesOptions = useSelector(selectAllWorkspacesOptions)
  const isScoreDataLoaded = useScoreCardDataReady()
  const shouldGenerateReport = useSelector(selectShouldGenerateReport)

  const searchParams = getSearchParams()
  const searchParamsWorkspaceId = searchParams.has('workspaceId') ? searchParams.get('workspaceId') : null
  const searchParamsNestedProductId = searchParams.has('nestedProductId') ? searchParams.get('nestedProductId') : null

  const handleCreateReport = useCallback(() => {
    pendoTrack('Formula: Auto generate report')
    // First create the GHG impact report PDF to include in the lock claims request payload
    // See https://www.telerik.com/kendo-react-ui/components/pdfprocessing/base64string/
    drawDOM(pdfContentRef.current, PDF_EXPORT_SETTINGS)
      .then((group) => {
        return exportPDF(group)
      })
      .then((dataUri) => {
        const base64String = dataUri.split(';base64,')[1]
        dispatch(generateReport(base64String))
      })
  }, [dispatch, pendoTrack])

  // Hack introduced previously to generate a report if the product is locked but has no reports
  useEffect(() => {
    if (!isCreateNew && shouldGenerateReport && !isRecipeLoading && !isProductLoading) {
      if (pageView !== PUBLISHED) {
        dispatch(setView(PUBLISHED))
      }
      handleCreateReport()
    }
  }, [isCreateNew, pageView, shouldGenerateReport, dispatch, handleCreateReport, isRecipeLoading, isProductLoading])

  const getWorkspaceName = useCallback(
    (id: number) => {
      const workspace = workspacesOptions.find((w) => w.id === id)
      return workspace.name
    },
    [workspacesOptions]
  )

  useEffect(() => {
    dispatch(clearProductFormula())
    dispatch(getFormulationTags())
    if (productId) {
      dispatch(initializeProductFormula(productId, history, scenarioId))
    } else {
      dispatch(setView(DRAFT))
      dispatch(setCompareTo('lastChange'))
    }
    if (searchParamsWorkspaceId !== null) {
      const workspaceName = getWorkspaceName(+searchParamsWorkspaceId)
      dispatch(updateDraftProduct({ workspaces: [{ id: +searchParamsWorkspaceId, name: workspaceName }] }))
    }
    if (searchParamsNestedProductId !== null) {
      dispatch(addInitialNestedProductById(parseInt(searchParamsNestedProductId, 10)))
    }
  }, [productId, dispatch, history, searchParamsNestedProductId, searchParamsWorkspaceId, getWorkspaceName, scenarioId])

  if (loadError) {
    return <LoadError productId={productId} />
  }

  if (isContentfulLoading || isProductLoading) {
    return (
      <Stack alignItems="center" mt={8}>
        <CircularProgress />
        <Box mt={2}>
          <Text>Loading data</Text>
        </Box>
      </Stack>
    )
  }

  return (
    <Stack direction="column" gap={3}>
      <StaleProductAlert productId={productId} />
      <Header />
      {isScoreDataLoaded && !isSupplierConnectCreateNew && (
        <Scores
          hideMetrics={pageView === PUBLISHED || thirdPartyProduct}
          showMenu
          showExportScorecard={!impactsOverriddenProduct && !hasImpactsOverriddenNestedProduct}
        />
      )}
      <ImpactsOverriddenProductDisclaimer />
      {!thirdPartyProduct && pageView !== PUBLISHED && (
        <>
          <Ingredients />
          {!impactsOverriddenProduct && <ProductInfo />}
          {debugEnabled && <DebugPanel />}
        </>
      )}
      {isUnsavedPromptOpen && <UnsavedChanges />}
      <ProductGhgImpactExport ref={productGhgImpactExportRef} pdfContentRef={pdfContentRef} />
    </Stack>
  )
}

export const FormulaProfile: FC = () => {
  const productId = getCurrentFormulaId()
  const query = useQuery()
  const scenarioIdParam = query.get('scenario')
  const scenarioId = scenarioIdParam ? +scenarioIdParam : null
  const flagEnabled = useSelector(selectFlagGoalsSplit)

  return <FormulaProfileComponent productId={productId} scenarioId={flagEnabled ? scenarioId : null} />
}
