import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getOriginLocations } from './originLocations.thunk'

export interface OriginLocationMapping {
  base_id: number
  origin_location_id: number
  region_name: string
  iso3_country_code: string
}

export interface State {
  originLocations: OriginLocationMapping[]
  isLoading: boolean
  isError: boolean
}
export const initialState: State = {
  originLocations: [],
  isLoading: false,
  isError: false,
}

const originLocationsSlice = createSlice({
  name: 'originLocations',
  initialState,
  reducers: {
    setOriginLocations: (state, action: PayloadAction<OriginLocationMapping[]>) => {
      state.originLocations = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOriginLocations.pending, (state) => {
      state.isLoading = true
      state.isError = false
    })
    builder.addCase(getOriginLocations.fulfilled, (state, action) => {
      state.isLoading = false
      state.isError = false
      state.originLocations = [...state.originLocations, ...action.payload]
    })
    builder.addCase(getOriginLocations.rejected, (state, action) => {
      console.error(action.error)
      state.isLoading = false
      state.isError = true
    })
  },
})

export const { setOriginLocations } = originLocationsSlice.actions

export const originLocationsReducer = originLocationsSlice.reducer
