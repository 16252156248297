import React, { forwardRef } from 'react'
import MuiChip, { ChipProps as MuiChipProps } from '@mui/material/Chip'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faCircleX } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export interface ChipProps
  extends Pick<MuiChipProps, 'id' | 'label' | 'aria-label' | 'variant' | 'color' | 'size' | 'onDelete'> {
  icon?: IconDefinition
}

export const Chip = forwardRef<HTMLDivElement, ChipProps>(
  ({ id, label, ['aria-label']: ariaLabel, variant, color, size, icon, onDelete, ...rest }, ref) => {
    return (
      <MuiChip
        ref={ref}
        id={id}
        aria-label={ariaLabel}
        label={label}
        variant={variant}
        color={color}
        size={size}
        icon={icon ? <FontAwesomeIcon icon={icon} style={{ fontSize: 'inherit' }} /> : null}
        onDelete={onDelete}
        deleteIcon={<FontAwesomeIcon data-testid="delete-icon" icon={faCircleX} style={{ fontSize: 'inherit' }} />}
        {...rest} // Required for tooltips
      />
    )
  }
)

Chip.displayName = 'Chip'
