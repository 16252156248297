import React, { FC, useState } from 'react'
import { Autocomplete, MenuItem, Tab, Tabs, TextField } from '@howgood/design'
import {
  PortfolioOption,
  allPortfolio,
  useScorecardPortfolioOptions,
} from '@/components/SustainabilityScorecard/useScorecardPortfolioOptions'

export type PortfolioType = 'workspace' | 'formulationTag'

interface PortfolioSelectProps {
  type: PortfolioType
  onTypeChange: (type: PortfolioType) => void
  value: PortfolioOption
  onChange: (option: PortfolioOption) => void
}

export const PortfolioSelect: FC<PortfolioSelectProps> = ({ type, onTypeChange, value, onChange }) => {
  const [inputValue, setInputValue] = useState<string>('')
  const { workspaceOptions, formulationTagOptions } = useScorecardPortfolioOptions(inputValue)
  const options = type === 'workspace' ? workspaceOptions : formulationTagOptions

  return (
    <Autocomplete
      aria-label="Search portfolio"
      options={options}
      value={value}
      placeholder={allPortfolio.label}
      isOptionEqualToValue={(option, val) => option.value === val.value}
      renderInput={(params) => <TextField {...params} onChange={(event) => setInputValue(event.target.value)} />}
      renderHeader={() => (
        <Tabs value={type} variant="fullWidth" onChange={(_event, val) => onTypeChange(val)}>
          <Tab label="Workspaces" value="workspace" />
          <Tab label="Tags" value="formulationTag" />
        </Tabs>
      )}
      renderOption={(props, option) => (
        <MenuItem {...props} key={option.value} value={option as any} onClick={() => onChange(option)}>
          {option.label}
        </MenuItem>
      )}
    />
  )
}
