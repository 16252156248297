import { createAsyncThunk } from '@reduxjs/toolkit'
import { fetchHelperV2 } from '@/api'
import { TransportationMode } from '@/records'

interface FetchModes {
  items: TransportationMode[]
}
export const getTransportationModes = createAsyncThunk<TransportationMode[]>(
  'recipe/getTransportationModes',
  async () => {
    const modes = await fetchHelperV2<FetchModes>({ url: 'transportation/modes/' })
    return modes.items
  }
)
