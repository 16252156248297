import { UnknownVendorOptionValue } from './BrandSearchOption'
import { estypes } from '@elastic/elasticsearch'

export type VendorEngagementStatus = 'Complete' | 'In Progress' | 'Not Started'
export const VENDOR_ENGAGEMENT_STATUS_COMPLETE = 'Complete' as VendorEngagementStatus
export const VENDOR_ENGAGEMENT_STATUS_IN_PROGRESS = 'In Progress' as VendorEngagementStatus
export const VENDOR_ENGAGEMENT_STATUS_NOT_STARTED = 'Not Started' as VendorEngagementStatus

export type SbtAction =
  | 'Commitment to set target' // 1
  | 'Target set' // 2
  | 'None' // 1000
  | 'Not Determined' // null - default
export const SBT_ACTION_COMMITMENT = 'Commitment to set target' as SbtAction
export const SBT_ACTION_TARGET_SET = 'Target set' as SbtAction
export const SBT_ACTION_NONE = 'None' as SbtAction
export const SBT_ACTION_NOT_DETERMINED = 'Not Determined' as SbtAction

export type SbtType =
  | 'Absolute' // 1
  | 'Engagement' // 2
  | 'Net-zero' // 3
  | 'Intensity' // 4
  | 'No-deforestation' // 5
  | 'Renewable Electricity' // 6
  | 'Other' // 7
  | 'None' // 1000
  | 'Not Determined' // null - default
export const SBT_TYPE_ABSOLUTE = 'Absolute' as SbtType
export const SBT_TYPE_ENGAGEMENT = 'Engagement' as SbtType
export const SBT_TYPE_NET_ZERO = 'Net-zero' as SbtType
export const SBT_TYPE_INTENSITY = 'Intensity' as SbtType
export const SBT_TYPE_NO_DEFORESTATION = 'No-deforestation' as SbtType
export const SBT_TYPE_RENEWABLE_ELECTRICITY = 'Renewable Electricity' as SbtType
export const SBT_TYPE_OTHER = 'Other' as SbtType
export const SBT_TYPE_NONE = 'None' as SbtType
export const SBT_TYPE_NOT_DETERMINED = 'Not Determined' as SbtType

export interface SettableVendorInfo {
  priority: number
  contact_info?: string
}

export interface VendorInfo extends SettableVendorInfo {
  name: string
  brandId?: number | UnknownVendorOptionValue // Added in prepareVendorDataFromBuckets()
  count: number
  mtPerYear: number
  manufacturingTypes: string[]
  engagementStatus: VendorEngagementStatus
  sbtAction: SbtAction
  sbtType: SbtType
  annual_carbon_footprint_farm_to_gate_impact: estypes.AggregationsStatsAggregate
  scope_3_category_1_impact: estypes.AggregationsStatsAggregate
  scope_3_category_4_impact: estypes.AggregationsStatsAggregate
  carbon_footprint_farm_to_gate_impact: estypes.AggregationsStatsAggregate
  raw_blue_water_usage_impact: estypes.AggregationsStatsAggregate
  scope3Contribution: number
}

export interface VendorProvidedReport {
  name: string
  url: string
}

export interface VendorByBrandResponse {
  date_created: string
  date_modified: string
  workflow_editor: number
  vendor_id: string
  contact_info: string
  priority: number
  brand: {
    id: number
  }
  organization: number
  reports: VendorProvidedReport[]
}
