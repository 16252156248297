import React, { FC } from 'react'
import SelectedIngredientRow from './SelectedIngredientRow'
import { createUseStyles } from 'react-jss'
import { IngredientSearchOption } from '../../../records'

interface IngredientsSelectorListProps {
  ingredients: IngredientSearchOption[]
  onRemove: (ingredient: IngredientSearchOption) => void
  onChange?: (ingredient: IngredientSearchOption, locations: any[], standards: any[]) => void
}

const useStyles = createUseStyles({
  ingredientsTable: {
    width: '100%',
  },
  ingredientColumn: {
    width: '40%',
    padding: '6px 10px 6px 0',
  },
  filterColumn: {
    width: '30%',
    padding: '6px 0px 6px 20px',
  },
})

export const IngredientsSelectorList: FC<IngredientsSelectorListProps> = ({ ingredients, onRemove, onChange }) => {
  const classes = useStyles()

  return (
    <table className={classes.ingredientsTable}>
      <thead>
        <tr>
          <th />
          <th className={classes.ingredientColumn}>Ingredient</th>
          <th className={classes.filterColumn}>Sourcing Standards</th>
          <th className={classes.filterColumn}>Locations</th>
        </tr>
      </thead>
      <tbody>
        {ingredients.map((ingredient) => (
          <SelectedIngredientRow
            key={ingredient.raw._id}
            ingredient={ingredient}
            onRemove={onRemove}
            onChange={onChange}
          />
        ))}
      </tbody>
    </table>
  )
}
