import React, { FC } from 'react'
import { Alert, AlertTitle, Stack } from '@howgood/design'
import {
  CollectVendorDataContent,
  DataPortalLink,
} from '@/components/Procurement/RequestVendorData/CollectVendorDataContent'
import { useContentfulRequestVendorData } from '@/contentfully/hooks'

interface Props {
  dataPortalLinkType: DataPortalLink
  handleDataPortalLinkTypeChange: (value: DataPortalLink) => void
}

export const PurchasedMaterialAlert: FC<Props> = ({ dataPortalLinkType, handleDataPortalLinkTypeChange }) => {
  const content = useContentfulRequestVendorData('request-vendor-data')

  return (
    <Stack marginTop={2} gap={1}>
      <Alert severity="warning">
        <AlertTitle>Does not contain primary data from your vendor.</AlertTitle>
      </Alert>
      <CollectVendorDataContent
        dataPortalLinkType={dataPortalLinkType}
        handleDataPortalLinkTypeChange={handleDataPortalLinkTypeChange}
        content={content.simpleVendorData || null}
      />
    </Stack>
  )
}
