import React, { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, IconButton, Text, Tooltip, Stack } from '@howgood/design'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { faChevronDown, faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { checkIfChild, selectIngredientWarnings } from '@/state/recipe'
import { RecipeIngredient, RecipeNestedIngredient } from '@/state/recipe/recipe.state'
import { selectExpanded, selectPageView, setCollapsed, setExpanded } from '@/state/pageSettings'
import { NestedProductLink } from './NestedProductLink'
import { AgPracticesDrawer } from '../AgPracticesDrawer/AgPracticesDrawer'
import { selectSplitTreatmentEnabled } from '@/state/splitio'

interface IngredientNameProps extends GridRenderCellParams {
  value?: {
    ingredient: RecipeIngredient | RecipeNestedIngredient
  }
}

export const IngredientName: FC<IngredientNameProps> = ({ value }) => {
  const dispatch = useDispatch()
  const { ingredient } = value
  const expanded = useSelector(selectExpanded)
  const ingredientErrors = useSelector(selectIngredientWarnings)
  const pageView = useSelector(selectPageView)
  const agPracticesSplitEnabled = useSelector(selectSplitTreatmentEnabled('agpractices_inputs'))

  const [agPracticesDrawerOpen, setAgPracticesDrawerOpen] = useState(false)

  // Red text if the ingredient is in the invalid list OR the ingredient
  // has a child in the invalid list
  const highlightRed =
    ingredientErrors.map((error) => error.id).includes(ingredient.id) ||
    ingredientErrors.some((error) => checkIfChild(ingredient, error))

  // Used to indent the ingredient name as needed to reflect its position in the tree
  const indent = 4 * (ingredient.flat_position.length - 1)

  const isNestedIngredient = 'nested_product_id' in ingredient
  const isThirdPartyProduct = isNestedIngredient && ingredient.is_foreign
  const isLockedNestedProduct = isNestedIngredient && ingredient.locked_claims_timestamp

  const agPracticesEnabled =
    agPracticesSplitEnabled && !isThirdPartyProduct && !isNestedIngredient && pageView === 'Draft'

  // If simple ingredient or third-party product, display just the name
  if (!isNestedIngredient || isThirdPartyProduct) {
    return (
      <>
        <Tooltip title={<Text textTransform="capitalize">{ingredient.name}</Text>}>
          <Box
            ml={indent}
            sx={{ cursor: agPracticesEnabled ? 'pointer' : null }}
            onClick={() => (agPracticesEnabled ? setAgPracticesDrawerOpen(true) : null)}
          >
            <Text data-testid="simple-ingredient-name" textTransform="capitalize" textOverflow="ellipsis" noWrap>
              {ingredient.name}
            </Text>
          </Box>
        </Tooltip>
        {agPracticesEnabled && agPracticesDrawerOpen && (
          <AgPracticesDrawer ingredient={ingredient} closeAgPracticesDrawer={() => setAgPracticesDrawerOpen(false)} />
        )}
      </>
    )
  }

  const dotPath = ingredient.dot_path
  const isExpanded = expanded.includes(dotPath)

  return (
    <Stack id="nested-ingredient-name" key={ingredient.id} direction="row" width="100%" pl={indent} alignItems="center">
      {!isLockedNestedProduct && (
        <IconButton
          id={`nested-ingredient-expand-collapse-${ingredient.index}`}
          aria-label="Expand or collapse nested ingredient"
          icon={isExpanded ? faChevronDown : faChevronRight}
          onClick={() => (isExpanded ? dispatch(setCollapsed(dotPath)) : dispatch(setExpanded(dotPath)))}
        />
      )}
      <Tooltip title={<Text textTransform="capitalize">{ingredient.name}</Text>}>
        <Box ml={1} sx={{ cursor: 'pointer' }} maxWidth="calc(100% - 54px)">
          <Text
            data-testid="nested-ingredient-name"
            textOverflow="ellipsis"
            color={highlightRed ? 'error.main' : null}
            noWrap
          >
            {ingredient.name}
          </Text>
        </Box>
      </Tooltip>
      <Tooltip title={<Text>Open in new tab to edit this product</Text>}>
        <Box ml={1}>
          {'nested_product_id' in ingredient && <NestedProductLink productId={ingredient.nested_product_id} />}
        </Box>
      </Tooltip>
    </Stack>
  )
}
