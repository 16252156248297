import React, { FC } from 'react'
import { Text, theme, Box } from '@howgood/design'

interface YearProps {
  year: number
  ['data-testid']?: string
  size?: 'small' | 'large'
}

export const Year: FC<YearProps> = ({ year, size = 'small', ['data-testid']: dataTestId }) => {
  return (
    <Box
      data-testid={dataTestId}
      px={size === 'large' ? 1.25 : 1}
      py={0.5}
      borderRadius={1}
      border={1}
      borderColor="divider"
    >
      <Text
        data-testid={`${dataTestId}-text`}
        fontWeight={size === 'large' ? theme.typography.h2.fontWeight : 600}
        variant={size === 'large' ? 'h2' : 'body1'}
      >
        {year}
      </Text>
    </Box>
  )
}
