import React, { FC, useMemo, useRef } from 'react'
import { Cell, Legend, Pie, PieChart } from 'recharts'
import { createUseStyles } from 'react-jss'
import { Stack, Text, Tooltip } from '@howgood/design'
import { formatNumber } from '@howgood/utils'
import { useChartHeight } from './CarbonFootprintBreakdown.hooks'

interface CarbonFootprintBreakdownChartProps {
  data: { name: string; value: number; pct: number }[]
  total: number
  units?: string
  legendValueField?: 'pct' | 'value'
  chartColors?: string[]
}

const chartColorsDefault = ['#FF6A74', '#FF9800', '#368FF8', '#38C87D', '#CE6CFF']

const useStyles = createUseStyles<string, { chartHeight: number }>({
  wrapper: {
    position: 'relative',
    minWidth: '350px',
    height: '230px',
    marginTop: -30,
  },
  metric: {
    position: 'absolute',
    top: ({ chartHeight }) => chartHeight / 2,
    transform: 'translateY(-50%)',
    left: 52,
    zIndex: 2,
  },
  legendItem: {
    color: 'black',
    fontSize: '12px',
    lineHeight: '20px',
  },
  chart: {
    height: ({ chartHeight }) => chartHeight,
  },
})

export const CarbonFootprintBreakdownChart: FC<CarbonFootprintBreakdownChartProps> = ({
  data,
  total,
  units,
  legendValueField = 'pct',
  chartColors = chartColorsDefault,
}) => {
  const containerRef = useRef()
  const chartData = useMemo(
    () => data.map((item, idx) => ({ ...item, color: chartColors[idx % chartColors.length] })),
    [data, chartColors]
  )
  const chartHeight = useChartHeight(containerRef)
  const classes = useStyles({ chartHeight })

  const scoreFormatted = formatNumber(total)

  return (
    <div ref={containerRef} className={classes.wrapper}>
      <div className={classes.metric}>
        <Text fontSize={32} variant="h3">
          {scoreFormatted}
        </Text>
        {units && (
          <Text fontSize={12} variant="body2">
            {units}
          </Text>
        )}
      </div>
      <div data-testid="pie-chart" className={classes.chart}>
        <PieChart width={390} height={chartHeight}>
          <Pie cx={100} dataKey="value" isAnimationActive={false} data={chartData} outerRadius={90} innerRadius={60}>
            {chartData.map((entry) => (
              <Cell key={`cell-${entry.name}`} fill={entry.color} />
            ))}
          </Pie>
          <Legend
            layout="vertical"
            verticalAlign="middle"
            width={175}
            align="right"
            iconType="square"
            iconSize={12}
            margin={{ right: 0 }}
            formatter={(value, _entry, idx) => (
              <Tooltip title={`${value}: ${data[idx][legendValueField]}${legendValueField === 'pct' ? '%' : ''}`}>
                <Stack direction="row" gap={0.25} width="90%" display="inline-flex">
                  <Text variant="legend" textWrap="nowrap" overflow="hidden">
                    {value}:
                  </Text>
                  <Text variant="legend" textWrap="nowrap">
                    {`${data[idx][legendValueField]}${legendValueField === 'pct' ? '%' : ''}`}
                  </Text>
                </Stack>
              </Tooltip>
            )}
          />
        </PieChart>
      </div>
    </div>
  )
}
