import React, { FC, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Tooltip, Menu, Text } from '@howgood/design'
import { updateProduct, selectSavedProductInfo, selectCanEditProduct } from '@/state/productOverview'
import { selectDisplayedIngredientList, selectDisplayedSalesAndDistribution } from '@/state/recipe'
import { PUBLISHED, selectPageView } from '@/state/pageSettings'
import { SaveAsDialog } from './SaveAsDialog'
import { MenuItems } from './MenuItems'
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons'
import { usePendoTrack } from '@/hooks'
import { selectIsSupplierConnect } from '@/state/organization'

export const SaveButton: FC<{ saveText?: string }> = ({ saveText = 'Save' }) => {
  const dispatch = useDispatch()
  const savedProduct = useSelector(selectSavedProductInfo)
  const ingredients = useSelector(selectDisplayedIngredientList)
  const view = useSelector(selectPageView)
  const salesDistributionInfo = useSelector(selectDisplayedSalesAndDistribution)
  const isSupplierConnect = useSelector(selectIsSupplierConnect)
  const pendoTrack = usePendoTrack()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const canEdit = useSelector(selectCanEditProduct)
  const canUpdate = savedProduct.id && canEdit

  // SupplierConnect requires manufacturing_type and manufacturing_region to be set
  const isMissingRequiredSupplierInfo =
    isSupplierConnect &&
    (!salesDistributionInfo.manufacturing_type?.id || !salesDistributionInfo.manufacturing_region?.id)

  const tooltipText = useMemo(() => {
    if (!canEdit) {
      return 'This formula is read-only - please duplicate to make changes'
    }
    if (ingredients.length === 0) {
      return 'Formula must have at least one ingredient'
    }
    if (isMissingRequiredSupplierInfo) {
      return "Manufacturing Type and Manufacturing Region are required. Add to 'Packaging and Distribution Information' section."
    }
    return null
  }, [canEdit, ingredients.length, isMissingRequiredSupplierInfo])

  const disabled = ingredients.length === 0 || isMissingRequiredSupplierInfo

  const handleSaveButtonClicked = (event: React.MouseEvent<HTMLElement>) => {
    // If new product, display the Save As dialog; otherwise, display the Save Changes/Save As New menu
    if (!canUpdate) {
      setDialogOpen(true)
    } else {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const handleSave = () => {
    pendoTrack('Formula: Save Changes')
    handleCloseMenu()
    dispatch(updateProduct())
  }

  const openSaveAsDialog = () => {
    handleCloseMenu()
    setDialogOpen(true)
  }

  if (view === PUBLISHED) {
    return null
  }

  return (
    <>
      <Tooltip childrenDisabled={disabled} title={tooltipText}>
        <Button
          id="save-button"
          disabled={disabled}
          color="primary"
          size="small"
          aria-label="Save changes"
          onClick={disabled ? null : handleSaveButtonClicked}
          endIcon={canUpdate ? faCaretDown : undefined}
        >
          <Text noWrap color="inherit">
            {saveText}
          </Text>
        </Button>
      </Tooltip>
      <Menu
        id="save-options-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        sx={{ li: { fontSize: 14 }, '#save-menu-list': { p: 0 } }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        MenuListProps={{ id: 'save-menu-list', 'aria-labelledby': 'toolbar-save-button' }}
      >
        <MenuItems isSaved={Boolean(savedProduct.id)} handleSave={handleSave} handleSaveAs={openSaveAsDialog} />
      </Menu>
      {dialogOpen && <SaveAsDialog closeDialog={() => setDialogOpen(false)} />}
    </>
  )
}
