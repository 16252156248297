import React from 'react'
import MuiTooltip, { TooltipProps as MuiTooltipProps } from '@mui/material/Tooltip'
import { theme } from './theme'

export type Placement =
  | 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top'

interface TooltipProps
  extends Pick<
    MuiTooltipProps,
    | 'children'
    | 'title'
    | 'placement'
    | 'leaveDelay'
    | 'enterDelay'
    | 'enterNextDelay'
    | 'disableInteractive'
    | 'open'
    | 'onClose'
    | 'disableFocusListener'
  > {
  maxWidth?: number
  childrenDisabled?: boolean
}

// TODO: Should be defined in theme but I don't know how and Erin is out!
const tooltipShadow =
  '0px 6px 24px 0px rgba(0, 0, 0, 0.14), 0px 14px 24px 0px rgba(0, 0, 0, 0.1), 0px 10px 11px -4px rgba(0, 0, 0, 0.08), 0px 0px 1px 0px rgba(0, 0, 0, 0.75)'

/**
 * The MUI tooltip won't work if wrapping an element that can't hold a ref,
 * See https://mui.com/material-ui/guides/composition/#caveat-with-refs
 *
 * Also, it is necessary to spread props on the child element, as the MUI
 * tooltip passes props to its children under the hood which are necessary
 * for tooltip effects.
 *
 * Prop `componentsProps` is an alias for the `slotProps` prop.
 * It's recommended to use the `slotProps` prop instead, as `componentsProps` will be deprecated in the future.
 */

export const Tooltip = React.forwardRef<HTMLElement, TooltipProps>(
  (
    {
      children,
      title,
      placement,
      disableInteractive = false,
      leaveDelay = 200,
      enterDelay = 400,
      enterNextDelay = 400,
      maxWidth = 300,
      open,
      onClose,
      childrenDisabled,
      ...rest
    },
    ref
  ) => {
    return (
      <MuiTooltip
        ref={ref}
        title={title}
        arrow
        placement={placement}
        leaveDelay={leaveDelay}
        enterDelay={enterDelay}
        enterNextDelay={enterNextDelay}
        disableInteractive={disableInteractive}
        open={open}
        onClose={onClose}
        slotProps={{
          tooltip: {
            sx: {
              maxWidth,
              fontFamily: 'Whitney, Arial, Helvetica, sans-serif',
              fontWeight: 500,
              fontSize: 14,
              background: theme.palette.common.white,
              padding: theme.spacing(1),
              color: theme.palette.text.primary,
              boxShadow: tooltipShadow,
              '& .MuiTooltip-arrow': {
                color: theme.palette.common.white,
                [`&::before`]: {
                  boxShadow: tooltipShadow,
                },
              },
            },
          },
        }}
        {...rest}
      >
        {childrenDisabled ? <span data-testid="disabled-children">{children}</span> : children}
      </MuiTooltip>
    )
  }
)

Tooltip.displayName = 'Tooltip'
