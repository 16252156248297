import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Grid } from '@howgood/design'
import { useCarbonLifecycleMetrics } from '@/hooks'
import { selectCarbonLifecycleImpactScore } from '@/selectors/selectScoresData'
import { PanelGrid } from '../PanelGrid'
import { ScoreCards } from '../ScoreCards/ScoreCards'
import { useScoreCardData } from '../ScoreCards/useScoreCardData'
import { LifecycleChart } from './LifecycleChart'
import { Flag } from './Flag'
import { selectHasImpactsOverriddenNestedProduct } from '@/state/productOverview'

export const CarbonPanel: FC = () => {
  const scoreCardData = useScoreCardData()
  const impactScore = useSelector(selectCarbonLifecycleImpactScore)
  const hasImpactsOverriddenNestedProduct = useSelector(selectHasImpactsOverriddenNestedProduct)
  const metrics = useCarbonLifecycleMetrics()

  const hasData = !!metrics.filter((metric) => impactScore[metric.field]).length

  return (
    <PanelGrid>
      <ScoreCards cards={scoreCardData.carbon.data} />
      <Grid item width="100%">
        <Flag />
      </Grid>
      {hasData && !hasImpactsOverriddenNestedProduct && (
        <Grid item width="100%">
          <LifecycleChart />
        </Grid>
      )}
    </PanelGrid>
  )
}
