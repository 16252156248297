import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MenuItem, Select, SelectChangeEvent, Tooltip } from '@howgood/design'
import { formulationStatuses } from '@/records'
import { selectDisplayedProductFormulationStatus, updateDraftProduct } from '@/state/productOverview'
import { DRAFT, selectPageView } from '@/state/pageSettings'
import { GridItemTitle } from '../GridItemTitle'
import { selectCurrentScenarioId } from '@/state/initiatives'

export const Status: FC = () => {
  const dispatch = useDispatch()
  const formulationStatus = useSelector(selectDisplayedProductFormulationStatus)
  const view = useSelector(selectPageView)
  const scenarioId = useSelector(selectCurrentScenarioId)

  const handleStatusChanged = (event: SelectChangeEvent) => {
    dispatch(updateDraftProduct({ formulation_status: event.target.value }))
  }

  return (
    <>
      <GridItemTitle title="Status" />
      <Tooltip title={view === DRAFT && scenarioId ? "You can't change the status on a scenario product" : ''}>
        <Select
          id="status-select"
          disabled={view !== DRAFT || !!scenarioId}
          value={formulationStatus}
          onChange={handleStatusChanged}
          fullWidth
        >
          {formulationStatuses.map((status) => (
            <MenuItem key={status.id} value={status.id}>
              {status.label}
            </MenuItem>
          ))}
        </Select>
      </Tooltip>
    </>
  )
}
