import { Workspace } from '@/records'
import { createSlice } from '@reduxjs/toolkit'
import { initialImpactScore } from '../recipe/recipe.state'
import { AllWorkspaceScoresData } from './workspaces.requests'
import {
  getWorkspaceImpactScores,
  getWorkspaces,
  updateWorkspace,
  setInitialPortfolioLevelGoal,
} from './workspaces.thunks'

export interface State {
  isLoading: boolean
  updatingMetric: string
  workspaces: Workspace[]
  workspaceScores: AllWorkspaceScoresData
}
export const initialState: State = {
  isLoading: false,
  updatingMetric: '',
  workspaces: [],
  workspaceScores: {},
}

const workspacesSlice = createSlice({
  name: 'workspaces',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getWorkspaces.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getWorkspaces.fulfilled, (state, action) => {
      state.workspaces = action.payload
      const initialScores = action.payload.reduce((allScores, workspace) => {
        allScores[workspace.id] = initialImpactScore
        return allScores
      }, {})
      state.workspaceScores = initialScores
      state.isLoading = false
    })
    builder.addCase(getWorkspaces.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(getWorkspaceImpactScores.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getWorkspaceImpactScores.fulfilled, (state, action) => {
      state.workspaceScores = action.payload
      state.isLoading = false
    })
    builder.addCase(getWorkspaceImpactScores.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(updateWorkspace.fulfilled, (state, action) => {
      state.workspaces = action.payload
    })
    builder.addCase(setInitialPortfolioLevelGoal.pending, (state, action) => {
      state.updatingMetric = action.meta.arg
    })
    builder.addCase(setInitialPortfolioLevelGoal.fulfilled, (state, action) => {
      state.workspaces = action.payload
      state.updatingMetric = ''
    })
    builder.addCase(setInitialPortfolioLevelGoal.rejected, (state) => {
      state.updatingMetric = ''
    })
  },
})

export const workspacesReducer = workspacesSlice.reducer
