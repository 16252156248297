export const ecoScoreToLetter = (score: number): string => {
  if (typeof score !== 'number') {
    return null
  }

  switch (true) {
    case score >= 80:
      return 'A'
    case score >= 60:
      return 'B'
    case score >= 40:
      return 'C'
    case score >= 20:
      return 'D'
    case score < 20:
      return 'E'
    default:
      return null
  }
}
