import { theme } from '@howgood/design'
import { isMetricGoalReductive } from '@/state/contentfulData/contentfulData.utils'

interface GetScoreColor {
  metricKey: string
  units?: string
  change?: number
}
function getScoreColor({ metricKey, units, change }: GetScoreColor): 'error' | 'success' {
  const isQuantitative = isMetricGoalReductive(metricKey, units)
  if (isQuantitative) {
    return change > 0 ? 'error' : 'success'
  }
  return change < 0 ? 'error' : 'success'
}

export const getScoreChangeColor = ({ metricKey, units, change }: GetScoreColor) => {
  if (!change) {
    return theme.palette.text.disabled
  }
  const color = getScoreColor({ metricKey, units, change })
  return theme.palette[color].dark
}

export const getGoalSpreadColor = ({ metricKey, units, change }: GetScoreColor) => {
  const color = getScoreColor({ metricKey, units, change })
  return theme.palette[color].main
}
