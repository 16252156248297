import { Stack } from '@howgood/design'
import React, { FC } from 'react'
import { VendorWorkspaceFilter } from './VendorWorkspaceFilter'
import { VendorNameFilter } from './VendorNameFilter'
import { VendorFormulationStatusFilter } from './VendorFormulationStatusFilter'
import { VendorTagsFilter } from './VendorTagsFilter'
import { VendorManufacturingTypeFilter } from './VendorManufacturingTypeFilter'

export const Filters: FC = () => {
  return (
    <Stack flexDirection="row" alignItems="center" justifyContent="stretch" gap={1} mb={2}>
      <VendorWorkspaceFilter />
      <VendorNameFilter />
      <VendorFormulationStatusFilter />
      <VendorTagsFilter />
      <VendorManufacturingTypeFilter />
    </Stack>
  )
}
