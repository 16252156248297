import React, { FC } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { DataPortalLink, DATA_PORTAL_LINK_CONSUMER } from './CollectVendorDataContent'
import { Button } from '@howgood/design'

interface Props {
  linkType: DataPortalLink
  onCopy: () => void
  buttonStartIcon: IconDefinition
  buttonText: string
}

export const DataPortalLinkCopyToClipboard: FC<Props> = ({ linkType, onCopy, buttonStartIcon, buttonText }) => {
  return (
    <CopyToClipboard
      text={
        linkType === DATA_PORTAL_LINK_CONSUMER
          ? 'https://howgood.com/sustainability-data-portal-ingredient-vendor/?utm_source=latis'
          : 'https://howgood.com/sustainability-data-portal-cpg-vendor/?utm_source=latis'
      }
      onCopy={onCopy}
    >
      <Button
        id="copy-sustainability-data-portal-link-button"
        color="primary"
        aria-label="Copy Link"
        disabled={!linkType}
        startIcon={buttonStartIcon}
      >
        {buttonText}
      </Button>
    </CopyToClipboard>
  )
}
