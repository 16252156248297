import React from 'react'
import debounce from 'lodash/debounce'

export const useGridWidth = (debounceTime = 500) => {
  const [size, setSize] = React.useState(0)

  React.useLayoutEffect(() => {
    const gridWrapper = document.getElementById('ingredients-grid-wrapper')

    const updateSize = debounce(() => {
      setSize(gridWrapper.clientWidth)
    }, debounceTime)

    window.addEventListener('resize', updateSize)

    updateSize()

    return () => window.removeEventListener('resize', updateSize)
  }, [debounceTime])

  return size
}
