import React, { FC, ReactNode } from 'react'
import { useSelector } from 'react-redux'
import { faCheck, faX } from '@fortawesome/pro-regular-svg-icons'
import { Icon, Stack, Text, theme } from '@howgood/design'
import { DRAFT, selectPageView } from '@/state/pageSettings'

interface Props {
  readyToSave: boolean
  children: ReactNode
}

export const SaveProductDisclaimer: FC<Props> = ({ readyToSave, children }) => {
  const view = useSelector(selectPageView)

  if (view !== DRAFT) {
    return null
  }

  return (
    <Stack data-testid="disclaimer" gap={1}>
      <Stack gap={1} flexDirection="row" alignItems="center">
        <Icon
          data-testid="disclaimer-icon"
          icon={readyToSave ? faCheck : faX}
          color={readyToSave ? theme.palette.success.main : theme.palette.error.main}
        />
        <Text data-testid="disclaimer-status">{readyToSave ? 'Ready to save' : 'Not ready to save'}</Text>
      </Stack>
      <Text data-testid="disclaimer-text" variant="body2">
        {children}
      </Text>
    </Stack>
  )
}
