import isEqual from 'lodash/isEqual'
import { createSelector } from 'reselect'
import { ImpactScore } from '@/api'
import { AppState } from '@/store'
import { ImpactData, initialImpactData, Recipe, RecipeIngredient, RecipeNestedIngredient } from './recipe.state'
import { DRAFT, PUBLISHED, selectPageView } from '../pageSettings'
import {
  selectIsCreateNew,
  selectIsProductLoading,
  selectIsThirdParty,
  selectProductErrorText,
  selectIsProductModified,
  selectIsImpactsOverridden,
  selectHasImpactsOverriddenNestedProduct,
  selectIsMainProductModified,
  selectIsProductScenarioModified,
  selectSavedInventories,
  selectDraftInventories,
} from '../productOverview/productOverview.selectors'
import { selectIsOriginLocationsLoading, selectOriginLocationsError } from '@/state/originLocations'
import { selectContentfulIngredientHeatmapData } from '@/state/contentfulData'
import { addScope3ImpactData, getAllChildren } from './recipe.utils'
import { selectIsRollup, selectPageType } from '../router'
import { selectCurrentScenarioId } from '../initiatives'

const getSaved = <T>(list: T[]): T => list[0]
const getDraft = <T>(list: T[]): T => list[list.length - 1]

export const selectRecipes = (state: AppState): Recipe[] => state.recipe.recipes

export const selectSavedRecipe = createSelector(selectRecipes, (recipes) => getSaved<Recipe>(recipes))

export const selectDraftRecipe = createSelector(selectRecipes, (recipes) => getDraft<Recipe>(recipes))

export const selectSavedPackaging = createSelector(selectSavedRecipe, (recipe) => recipe.packaging)

export const selectDraftPackaging = createSelector(selectDraftRecipe, (recipe) => recipe.packaging)

export const selectSavedTransportation = createSelector(selectSavedRecipe, (recipe) => recipe.transportation)

export const selectDraftTransportation = createSelector(selectDraftRecipe, (recipe) => recipe.transportation)

export const selectImpactDataList = (state: AppState): ImpactData[] => state.recipe.impactData

export const selectHasManyImpactDatas = createSelector(
  selectImpactDataList,
  (impactDataList) => impactDataList.length > 1
)

export const selectChangeLog = (state: AppState): string[] => state.recipe.changeLog

export const selectIsRecipeLoading = (state: AppState): boolean => state.recipe.isLoading

export const selectIsRebalancingIngredients = (state: AppState): boolean => state.recipe.isRebalancing

export const selectIsRecipeError = (state: AppState): string => state.recipe.error

export const selectIngredientsMoved = (state: AppState): boolean => state.recipe.ingredientsMoved

const selectRawSavedImpactData = createSelector(selectImpactDataList, (impactDataList) => getSaved(impactDataList))

const selectRawDraftImpactData = createSelector(selectImpactDataList, (impactDataList) => getDraft(impactDataList))

export const selectLockedImpactData = (state: AppState): ImpactData => state.recipe.lockedImpactData

export const selectSavedImpactData = createSelector(
  selectRawSavedImpactData,
  selectCurrentScenarioId,
  selectPageType,
  selectSavedInventories,
  (impactData, scenario, pageType, inventories) => {
    if (pageType === 'formulation' && !scenario) return impactData
    return { ...impactData, product: addScope3ImpactData(impactData.product, inventories) }
  }
)

export const selectDraftImpactData = createSelector(
  selectRawDraftImpactData,
  selectCurrentScenarioId,
  selectPageType,
  selectDraftInventories,
  (impactData, scenario, pageType, inventories) => {
    if (pageType === 'formulation' && !scenario) return impactData
    return { ...impactData, product: addScope3ImpactData(impactData.product, inventories) }
  }
)

export const selectDisplayedRecipe = createSelector(
  selectIsThirdParty,
  selectRecipes,
  selectPageView,
  (isForiegn, recipes, view) => {
    const savedRecipe = getSaved(recipes)
    if (isForiegn || recipes.length < 2) return savedRecipe

    const draftRecipe = getDraft(recipes)
    return view === DRAFT ? draftRecipe : savedRecipe
  }
)

// Returns the total weight of all ingredients, rounded to 1 decimal place
export const selectTotalWeight = createSelector(selectDisplayedRecipe, (recipe) => {
  const totalWeight = recipe.ingredients.reduce(
    (acc, ingredient) => (ingredient.isTopLevel ? acc + ingredient.weight : acc),
    0
  )
  return Math.round(totalWeight * 10) / 10
})

export const selectDisplayedImpactData = createSelector(
  selectIsThirdParty,
  selectSavedImpactData,
  selectDraftImpactData,
  selectLockedImpactData,
  selectPageView,
  (isForiegn, savedImpact, draftImpact, lockedClaims, view) => {
    if (isForiegn || view === PUBLISHED) return lockedClaims || initialImpactData
    return view === DRAFT ? draftImpact : savedImpact
  }
)

export const selectDisplayedProductImpactData = createSelector(
  selectDisplayedImpactData,
  (impactData) => impactData.product
)

export const selectIsRecipeModified = createSelector(
  selectSavedRecipe,
  selectDraftRecipe,
  (savedRecipe, draftRecipe) => {
    const saved = { ...savedRecipe }
    const draft = { ...draftRecipe }
    delete saved.timestamp
    delete draft.timestamp
    return !isEqual(savedRecipe, draftRecipe)
  }
)

export const selectDisplayedProductStandards = createSelector(selectDisplayedRecipe, (recipe) => recipe.standards)

export const selectDisplayedIngredientList = createSelector(selectDisplayedRecipe, (recipe) => recipe.ingredients)

export const selectDisplayedNonNestedIngredientList = createSelector(
  selectDisplayedIngredientList,
  (ingredientList) => {
    return ingredientList.filter((ingredient) => ingredient.hasOwnProperty('ingredient_id'))
  }
)

export const selectHasNoValidIngredients = createSelector(
  selectDisplayedIngredientList,
  selectIsThirdParty,
  (ingredients, thirdParty) => {
    if (thirdParty) return false
    return (
      !ingredients.length ||
      ingredients.every((ingredient) => {
        if ('base_id' in ingredient) {
          return !ingredient.base_id
        }
        return !ingredient.nested_product_id
      })
    )
  }
)

export const selectIngredientWarnings = createSelector(selectDisplayedIngredientList, (ingredients) => {
  return ingredients.filter((ingredient) => {
    // All (not third party and not locked) nested ingredients must have at least one child
    if ('nested_product_id' in ingredient) {
      const children = getAllChildren(ingredient, ingredients)
      return !children.length && !ingredient.is_foreign && !ingredient.locked_claims_timestamp
    }
    // all simple ingredients must have a base_id
    return 'base_id' in ingredient && !ingredient.base_id
  })
})

const selectImpactWarnings = createSelector(
  selectDisplayedImpactData,
  selectIsCreateNew,
  selectIsRollup,
  (impactData, createNew, isRollup) =>
    createNew || isRollup || impactData.nonNullFields.length ? '' : 'Unable to retrieve impact data for this formula.'
)

export const NO_VALID_INGREDIENTS =
  'This formula has no valid ingredients associated with it, or there was an issue fetching ingredient data.'
export const selectWarningText = createSelector(
  selectIsCreateNew,
  selectIsRecipeLoading,
  selectIsProductLoading,
  selectImpactWarnings,
  selectIngredientWarnings,
  selectHasNoValidIngredients,
  selectIsImpactsOverridden,
  selectHasImpactsOverriddenNestedProduct,
  selectIsRollup,
  (
    createNew,
    isRecipeLoading,
    isProductLoading,
    impactWarning,
    ingredientWarnings,
    noValid,
    isImpactsOverridden,
    hasImpactsOverriddenNestedProduct,
    isRollup
  ): string => {
    if (isRecipeLoading || isProductLoading || isImpactsOverridden || hasImpactsOverriddenNestedProduct || isRollup) {
      return ''
    }
    const allWarnings = []
    if (!createNew && noValid) {
      allWarnings.push(NO_VALID_INGREDIENTS)
    }
    if (ingredientWarnings.length) {
      ingredientWarnings.forEach((ingredient) => {
        allWarnings.push(
          `${ingredient.product?.name || 'A product'} (${ingredient.product?.id ??
            ''}) contains an invalid ingredient - ${ingredient.name} (${ingredient.product_ingredient_id}).`
        )
      })
      allWarnings.push('You can expand all ingredients for more information.')
    }
    if (impactWarning) {
      allWarnings.push(impactWarning)
    }
    return allWarnings.length
      ? `${allWarnings.join(' ')} Please refresh the page. If this doesn't work, contact support@howgood.com.`
      : ''
  }
)

export const selectDisplayedAgribalyze = createSelector(selectDisplayedRecipe, (recipe) => recipe.agribalyze_category)

export const selectDisplayedPackaging = createSelector(selectDisplayedRecipe, (recipe) => recipe.packaging)

export const selectDisplayedTransportation = createSelector(selectDisplayedRecipe, (recipe) => recipe.transportation)

export const selectDisplayedSalesAndDistribution = createSelector(
  selectDisplayedRecipe,
  (recipe) => recipe.sales_distribution
)

export const selectIsEditing = createSelector(
  selectIsRecipeModified,
  selectIsProductModified,
  (isRecipeModified, isProductModified) => {
    return isRecipeModified || isProductModified
  }
)

export const selectIsEditingScenarioOnly = createSelector(
  selectIsRecipeModified,
  selectIsMainProductModified,
  selectIsProductScenarioModified,
  (isRecipeModified, productModified, productScenarioModified) => {
    return productScenarioModified && !isRecipeModified && !productModified
  }
)

export const selectIsFormulaOveriewLoading = createSelector(
  selectIsOriginLocationsLoading,
  selectIsProductLoading,
  selectIsRecipeLoading,
  (originsLoading, productLoading, recipeLoading) => {
    return originsLoading || productLoading || recipeLoading
  }
)

export const selectIsFormulaOveriewError = createSelector(
  selectOriginLocationsError,
  selectProductErrorText,
  selectIsRecipeError,
  (originsError, productError, recipeError) => {
    if (originsError) {
      return 'There was an issue loading sourcing information.'
    }
    if (recipeError) {
      return recipeError
    }
    return productError
  }
)

const ingredientHasDataForMetric = (
  ingredientsImpactData: ImpactScore[],
  ingredient: RecipeIngredient | RecipeNestedIngredient,
  metric: string
) => {
  const data = ingredientsImpactData[ingredient.index]
  return data && Object.hasOwn(data, metric) && data[metric] !== null
}

// Selects only the contentful field items where the backend returns data for at least one ingredient
export const selectContentfulIngredientImpactData = createSelector(
  selectContentfulIngredientHeatmapData,
  selectDisplayedImpactData,
  selectDisplayedIngredientList,
  (contentfulItems, impactData, ingredients) => {
    return contentfulItems.filter((contentfulItem) => {
      // at least 1 ingredient has data for this metric
      return ingredients.some((ingredient) => {
        return ingredientHasDataForMetric(impactData.ingredients, ingredient, contentfulItem.key)
      })
    })
  }
)

export const selectIsFormulaModified = createSelector(
  selectIsRecipeModified,
  selectIsProductModified,
  (recipeModified, productModified) => {
    return recipeModified || productModified
  }
)

export const selectDisplayedWeightOption = createSelector(selectDisplayedRecipe, (recipe) => recipe.input_weight_unit)
