export const ELASTIC_SEARCH_LIMIT_DEFAULT = 5000
export const DEFAULT_PAGE_SIZE = 20
export const BASES_SEARCH_PATH = 'ingredients_v2/_search'
export const BASES_AND_TOKENS_SEARCH_PATH = 'ingredients_v2,ingredient_tokens/_search'
export const INGREDIENTS_SEARCH_PATH = 'ingredient_ingredients/_search?_source_excludes=products,brand_names'
export const PRODUCTS_SEARCH_PATH = 'products_v2/_search?_source_excludes=stores,brand_names,company_names'
export const FORMULATIONS_SEARCH_PATH = 'products_v2/_search'
export const PRODUCT_PATH = '/products/:formula'
export const MATERIAL_PATH = '/materials/:formula'
export const FORMULATION_TAGS_SEARCH_PATH = 'formulation_tags/_search'
export const VENDOR_PATH = '/vendors/:vendor'
