import React, { FC } from 'react'
import { Box, Stack, Text } from '@howgood/design'
import { WeightInput } from './WeightInput'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { RecipeIngredient, RecipeNestedIngredient } from '@/state/recipe/recipe.state'
import { useSelector } from 'react-redux'
import { selectPageView, DRAFT } from '@/state/pageSettings'
import { selectDisplayedIngredientList, selectTotalWeight, selectDisplayedWeightOption } from '@/state/recipe'
import { WeightOptions } from './WeightHeader'
import { PERCENTAGES, WeightOption } from '@/records'

interface WeightValueProps {
  flatWeight: number
  inputWeightUnit: WeightOption
}

const WeightValue: FC<WeightValueProps> = ({ flatWeight, inputWeightUnit }) => {
  const totalWeight = useSelector(selectTotalWeight)

  return (
    <Stack direction="row" ml={1} sx={{ opacity: 0.7 }} gap={0.5}>
      <Text fontSize={12}>{Math.round(flatWeight * 10) / 10}%</Text>
      {inputWeightUnit !== PERCENTAGES && (
        <Text fontSize={12}>
          ({Math.round((flatWeight / 100) * totalWeight)}
          {WeightOptions[inputWeightUnit]})
        </Text>
      )}
    </Stack>
  )
}

interface WeightCellProps extends GridRenderCellParams {
  value?: {
    ingredient: RecipeIngredient | RecipeNestedIngredient
  }
}

export const Weight: FC<WeightCellProps> = (params) => {
  const ingredient = params.value.ingredient
  const view = useSelector(selectPageView)
  const weightOption = useSelector(selectDisplayedWeightOption)

  // Can only edit top-level ingredients
  if (ingredient.isTopLevel) {
    if (view === DRAFT) {
      return <WeightInput ingredient={ingredient} />
    }
    return (
      <Box ml={1}>
        <Text>
          {Math.round(ingredient.weight * 10) / 10}
          {WeightOptions[weightOption]}
        </Text>
      </Box>
    )
  }

  // If it's a nested product, currently need to compute its flat_weight
  if ('nested_product_id' in ingredient) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return <NestedWeight ingredient={ingredient} />
  }

  // For non-nested ingredients further down the tree, display the ingredient's flat_weight
  return <WeightValue flatWeight={ingredient.flat_weight} inputWeightUnit={ingredient.input_weight_unit} />
}

interface NestedWeightProps {
  ingredient: RecipeNestedIngredient
}

const NestedWeight: FC<NestedWeightProps> = ({ ingredient }) => {
  const ingredients = useSelector(selectDisplayedIngredientList)

  // Compute `flat_weight` from parent if not present
  const parent = ingredients.find((item) => item.dot_path === ingredient.flat_position.slice(0, -1).join('.'))
  const flatWeight = ingredient.flat_weight ?? (parent ? (parent.weight * ingredient.weight) / 100 : ingredient.weight)

  return <WeightValue flatWeight={flatWeight} inputWeightUnit={ingredient.input_weight_unit} />
}
