import React, { FC } from 'react'
import { Bar, BarChart, CartesianGrid, ReferenceLine, ResponsiveContainer, XAxis, YAxis } from 'recharts'
import { theme } from '@howgood/design'
import { FlagEmissionsData, Highlight } from './types'
import { formatTick, getChartData } from './utils'

interface Props {
  data: FlagEmissionsData
  domain: [number, number]
  ticks: number[]
  setHighlight: (highlight: Highlight) => void
}

export const FlagEmissionsChart: FC<Props> = ({ data, domain, ticks, setHighlight }) => {
  const chartData = getChartData(data)

  return (
    <ResponsiveContainer width="100%" height={80}>
      <BarChart layout="vertical" width={300} height={80} data={[{ ...chartData, name: '' }]}>
        <CartesianGrid strokeDasharray="3 3" />
        <ReferenceLine x={0} stroke="#000" />
        <XAxis type="number" domain={domain} interval="preserveStartEnd" ticks={ticks} tickFormatter={formatTick} />
        <YAxis dataKey="name" type="category" hide />
        <Bar
          dataKey="cf_flag_annual_impact"
          fill={theme.palette.error.dark}
          name={data.cf_flag_annual_impact.title}
          onMouseOver={() => setHighlight('flag')}
          onMouseOut={() => setHighlight(null)}
        />
      </BarChart>
    </ResponsiveContainer>
  )
}
