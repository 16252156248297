import React, { FC } from 'react'
import { Box, Icon } from '@howgood/design'
import { useDispatch, useSelector } from 'react-redux'
import { ChartType, selectChartType, setChartType } from '../state/portfolioDashboard'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import ToggleButton from '@mui/material/ToggleButton'
import { faGrid, faList } from '@fortawesome/pro-regular-svg-icons'
import { faChartPie } from '@fortawesome/pro-solid-svg-icons'

export const ChartTypeToggle: FC = () => {
  const chartType = useSelector(selectChartType)
  const dispatch = useDispatch()

  const handleChangeView = (_event: React.MouseEvent<HTMLElement>, newChartType: string) => {
    dispatch(setChartType(newChartType as ChartType))
  }

  return (
    <ToggleButtonGroup
      onChange={handleChangeView}
      exclusive
      value={chartType}
      size="small"
      sx={{ '& .MuiToggleButton-root.MuiToggleButton-standard': { p: 0 }, '& .MuiIconButton-root': { p: 0 } }}
    >
      <ToggleButton value="bar" aria-label="Bar chart">
        <Box p={1}>
          <Icon icon={faList} />
        </Box>
      </ToggleButton>
      <ToggleButton data-testid="mosaic-chart-button" value="mosaic" aria-label="Mosaic">
        <Box p={1}>
          <Icon icon={faGrid} />
        </Box>
      </ToggleButton>
      <ToggleButton value="pie" aria-label="Pie">
        <Box p={1}>
          <Icon icon={faChartPie} />
        </Box>
      </ToggleButton>
    </ToggleButtonGroup>
  )
}
