import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { DataGridPremium, GridRowsProp, GridColDef } from '@mui/x-data-grid-premium'
import { Accordion, AccordionDetails, Box, Tabs, Tab, Text } from '@howgood/design'
import { selectChangeLog, selectDisplayedIngredientList } from '@/state/recipe'
import { AccordionCardHeader } from '@/components/Scores/AccordionCardHeader'
import { TabPanel } from '../TabPanel'

export const DebugPanel: FC = () => {
  const changeLog = useSelector(selectChangeLog)
  const ingredients = useSelector(selectDisplayedIngredientList)

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'name',
      width: 200,
    },
    ...[
      'is_nested',
      'index',
      'position',
      'flat_position',
      'dot_path',
      'flat_weight',
      'weight',
      'weight_unit',
      'is_foreign',
    ].map((item) => ({
      field: item,
      headerName: item,
    })),
  ]

  const rows: GridRowsProp = ingredients.map((ingredient) => {
    return {
      id: ingredient.index,
      name: ingredient.name,
      is_nested: 'nested_product_id' in ingredient ? 'true' : 'false',
      index: ingredient.index,
      position: ingredient.position,
      flat_position: JSON.stringify(ingredient.flat_position),
      dot_path: ingredient.dot_path,
      flat_weight: ingredient.flat_weight,
      weight: ingredient.weight,
      weight_unit: ingredient.input_weight_unit,
      is_foreign: ingredient.is_foreign === true ? 'true' : 'false',
    }
  })

  const [tabValue, setTabValue] = useState(0)

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  return (
    <Accordion defaultExpanded={false}>
      <AccordionCardHeader id="debug-panel-header" title="Debug Panel" />
      <AccordionDetails>
        <Box my={1} borderBottom={1} borderColor="divider">
          <Tabs value={tabValue} onChange={handleTabChange} aria-label="Product information tabs">
            <Tab label="Ingredients Model" />
            <Tab label="Change Log" />
          </Tabs>
        </Box>
        <TabPanel value={tabValue} index={0}>
          <Box
            height={500}
            width="100%"
            sx={{
              '& .MuiDataGrid-columnHeaderTitle': {
                fontWeight: 600,
              },
              '& .MuiDataGrid-columnHeaderTitleContainerContent': {
                width: '100%',
              },
            }}
          >
            <DataGridPremium
              rows={rows}
              columns={columns}
              disableRowSelectionOnClick
              initialState={{ density: 'compact' }}
              disableColumnMenu
              disableColumnFilter
            />
          </Box>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <Box maxHeight={200} overflow="auto">
            <Text>{JSON.stringify(changeLog)}</Text>
          </Box>
        </TabPanel>
      </AccordionDetails>
    </Accordion>
  )
}
