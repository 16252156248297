import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { IProductStandard } from '@/records'
import { DRAFT, selectPageView } from '@/state/pageSettings'
import { selectDisplayedProductStandards, updateProductStandards } from '@/state/recipe'
import { StandardsSelector } from '@/components/StandardsSelector'

export const Standards: FC = () => {
  const dispatch = useDispatch()
  const standards = useSelector(selectDisplayedProductStandards)
  const view = useSelector(selectPageView)

  const handleChangeStandards = (selected: IProductStandard[]) => {
    dispatch(updateProductStandards(selected))
  }

  return <StandardsSelector onChange={handleChangeStandards} selectedStandards={standards} disabled={view !== DRAFT} />
}
