import React, { FC } from 'react'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { RecipeIngredient, RecipeNestedIngredient } from '@/state/recipe/recipe.state'
import { MoveRow } from './MoveRow'

interface RowControlProps extends GridRenderCellParams {
  value?: {
    ingredient: RecipeIngredient | RecipeNestedIngredient
  }
}

export const RowControls: FC<RowControlProps> = ({ value }) => {
  const { ingredient } = value

  if (!ingredient.isTopLevel) {
    return null
  }

  return <MoveRow ingredient={ingredient} />
}
