import { fetchHelperV2 } from '@/api'

export interface AgribalyzeCategory {
  id: number
  name: string
  value?: number
  is_beverage?: boolean
}

export const searchAgribalyzeCategories = async (term: string): Promise<AgribalyzeCategory[]> => {
  const response = await fetchHelperV2<{ items: AgribalyzeCategory[] }>({
    url: `products/agribalyze-categories/?name=${term}`,
  })

  return response.items
}
