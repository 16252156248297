import React from 'react'
import classNames from 'classnames'
import { Icon } from '@howgood/design'
import logo from '@/assets/images/footer-logo.png'
import ecoScoreDWrapImage from '../../../assets/images/eco-score/eco-d-wrap.png'
import nutriScoreFullCImage from '../../../assets/images/nutriscore/nutriscore-full-C.png'
import { faX, faCheck } from '@fortawesome/pro-regular-svg-icons'
import { useProductOverviewExportContainerStyles } from './ProductOverviewExportContainer.styles'
import { useContentfulAttributes, useContentfulFormulaIcons } from '@/contentfully/hooks'
import { PERMISSIONS_TO_FIELDS_MAP, PRODUCT_ATTRIBUTE_PERMISSIONS } from '@/constants/impactScore'
import { ContentfulAttributeKey } from '@/contentfully'
import { Heading } from '@/components/Layout/Heading'

interface OwnProps {
  page: 2 | 3
}

const attributeFields = PRODUCT_ATTRIBUTE_PERMISSIONS.map((permission) => PERMISSIONS_TO_FIELDS_MAP[permission])

export const SustainabilityScorecardExplanation = ({ page }: OwnProps) => {
  const classes = useProductOverviewExportContainerStyles()

  const attributes = useContentfulAttributes(attributeFields)
  const attributeIcons = attributes.reduce(
    (acc, attribute) => ({
      ...acc,
      [attribute.key]: attribute.icon?.fields?.file?.url as string,
    }),
    {} as { [key in ContentfulAttributeKey]: string }
  )

  const ratings = useContentfulFormulaIcons(['hg-rating-snm', 'hg-rating-good', 'hg-rating-great', 'hg-rating-best'])
  const ratingIcons = ratings.map((rating) => rating.icon?.fields?.file?.url as string)

  return (
    <div className={classNames('page-break', classes.flexLayout)}>
      <div className={classNames(classes.flex1, classes.leftSide)}>
        {page === 2 && <div>FOR INTERNAL USE ONLY - DELETE IF SHARING EXTERNALLY</div>}
        <Heading title="HowGood Sustainability Scorecard Explained" />
        <p>
          HowGood is an independent research company with the world’s largest database on product sustainability in the
          food industry. With data and analysis for more than 33,000 ingredients, chemicals, and materials, HowGood
          helps leading food brands, retailers and investors improve their environmental and social impact.
        </p>
        <p>
          As consumer demand for sustainable food products increases, so has the need to clearly and quantitatively
          communicate product sustainability. The scorecard makes it easy for B2B buyers to understand a product’s
          impact, and make an informed decision that factors in environmental and social impact considerations.
        </p>
        <section>
          <p className={classes.bold}>PURPOSE OF THE SCORECARD:</p>
          <p>
            HowGood's Sustainability Scorecard provides sustainability insights for an individual product to support B2B
            sales conversations. If you would like to use the scorecard with end consumers (B2C), please reach out to
            your HowGood contact.
          </p>
          {page === 2 && (
            <div className={classes.box}>
              <div className={classes.innerBox}>
                <p className={classes.bold}>
                  <span className={classes.iconWrapper}>
                    <Icon icon={faCheck} color="green" /> CAN DO
                  </span>
                </p>
                <div>
                  <div className={classes.flexStart}>
                    <span>•</span> <div className={classes.listItemText}>Present to a B2B buyer</div>
                  </div>
                  <div className={classes.flexStart}>
                    <span>•</span>
                    <div className={classes.listItemText}>
                      Share with internal teams to understand product sustainability achievements and set goals
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.innerBox}>
                <p className={classes.bold}>
                  <span className={classes.iconWrapper}>
                    <Icon icon={faX} color="red" /> CANNOT DO
                  </span>
                </p>
                <div>
                  <div className={classes.flexStart}>
                    <span>•</span>{' '}
                    <div className={classes.listItemText}>
                      Put an eligible claim on the package of a product. To communicate claims on-pack, please reach out
                      to your customer success manager
                    </div>
                  </div>
                  <div className={classes.flexStart}>
                    <span>•</span>
                    <div className={classes.listItemText}>Share directly with consumers</div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </section>
        <section>
          <p className={classes.bold}>DEFINITIONS:</p>
          <div>
            <p>
              <span className={classes.bold}>Product Claims</span> - The sustainability claims your product has
              achieved. This could include: Eco-Score, Nutri-Score, Product Sustainability Ratings.
            </p>
            <p>
              <span className={classes.bold}>Product Attributes</span> - Attributes translate product impact assessments
              into consumer-friendly messaging to showcase product sustainability. Sustainability attributes your
              product has achieved could include: Climate Friendly, Clean Label, Minimally Processed, Water Smart, Fair
              Labor, Low Synthetic Inputs.
            </p>
            <p>
              <span className={classes.bold}>Clickable info icon (i)</span> - directs you to the Latis Help Center to
              learn more about each element on the scorecard. Icons are only clickable in PDF format, which is the
              recommended format for viewing your scorecard.
            </p>
            <p>
              <span className={classes.bold}>Industry Average</span> - The average score of all products assessed by
              HowGood for the metric you have chosen (e.g. Raw GHG Emissions).
            </p>
            <p>
              <span className={classes.bold}>Portfolio Average</span> - The average score of all products across all
              workspaces for the metric you have chosen.
            </p>
          </div>
        </section>
      </div>
      <div className={classNames(classes.flex1, classes.flexLayoutColumn, classes.rightSide)}>
        <section className={classes.flex1}>
          <div>
            <div className={classes.flexStart}>
              <div className={classes.attributeImageWrapper}>
                <img width="60px" src={attributeIcons.is_min_processed} />
              </div>
              <p>
                <span className={classes.bold}>Minimally Processed</span> products are made with lower-energy
                processing, which reduces environmental impact. Less ingredient processing required in production also
                tends to yield products with better ingredient quality.
              </p>
            </div>
            <div className={classes.flexStart}>
              <div className={classes.attributeImageWrapper}>
                <img width="60px" src={attributeIcons.is_climate_friendly} />
              </div>
              <p>
                <span className={classes.bold}>Climate Friendly</span> products have GHG emissions that are lower than
                70% of all products assessed by HowGood. HowGood has data on 1M+ products in the food system and has
                assessed a sample set of more than 280K products for the Climate Friendly attribute.
              </p>
            </div>
            <div className={classes.flexStart}>
              <div className={classes.attributeImageWrapper}>
                <img width="60px" src={attributeIcons.is_clean_label} />
              </div>
              <p>
                <span className={classes.bold}>Clean Label</span> products have a minimal number of minimally processed
                ingredients. A fewer number of ingredients that do not require high-energy processing yields products
                with a much lower impact on the environment. Products that qualify for Clean Label have a simple formula
                with seven or fewer ingredients that are all minimally processed.
              </p>
            </div>
            <div className={classes.flexStart}>
              <div className={classes.attributeImageWrapper}>
                <img width="60px" src={attributeIcons.is_water_smart} />
              </div>
              <p>
                <span className={classes.bold}>Water Smart</span> products have a relatively low footprint on freshwater
                resources in the regions where their ingredients are produced. It takes into account both the water used
                in growing the ingredients of a product and the water scarcity in those respective regions. In order for
                a product to receive the Water Smart attribute, a product&apos;s total blue water impact must be better
                than 80% of all products assessed by HowGood.
              </p>
            </div>

            <div className={classes.flexStart}>
              <div className={classes.attributeImageWrapper}>
                <div className={classes.flexLayoutColumn}>
                  <img width="60px" src={ratingIcons[3]} />
                  <img width="60px" src={ratingIcons[2]} />
                  <img width="60px" src={ratingIcons[1]} />
                </div>
              </div>
              <div>
                <p>
                  <span className={classes.bold}>HowGood Ratings</span> communicate the overall environmental and social
                  sustainability of a product. They provide consumers with a single rating that reflects a holistic
                  assessment of product sustainability, making it easier to compare and shop for the most sustainable
                  products.
                </p>
                <p className={classes.ratingDescription}>
                  <i>Best</i> - This product has an environmental and social impact better than 95% of food products
                  assessed by HowGood.
                </p>
                <p className={classes.ratingDescription}>
                  <i>Great</i> - better than 85%
                </p>
                <p className={classes.ratingDescription}>
                  <i>Good</i> - better than 70%.
                </p>
              </div>
            </div>
            <div className={classes.flexStart}>
              <div className={classes.attributeImageWrapper}>
                <img width="80px" src={ecoScoreDWrapImage} />
              </div>
              <p>
                <span className={classes.bold}>Eco-Score</span> - A European scoring system that awards grades on a
                scale of A to E based on 16 environmental impacts
              </p>
            </div>
            <div className={classes.flexStart}>
              <div className={classes.attributeImageWrapper}>
                <img width="80px" src={nutriScoreFullCImage} />
              </div>
              <p>
                <span className={classes.bold}>Nutri-Score</span> - A European scoring system that awards grades on a
                scale of A to E based on nutritional factors including total sugar, fat and sodium content.
              </p>
            </div>
          </div>
        </section>
        <div className={classes.footerLogoWrapper}>
          <a href="https://howgood.com/scorecard/">
            <img width="108px" src={logo} />
          </a>
        </div>
      </div>
    </div>
  )
}
