import { Document } from '@contentful/rich-text-types'
import { Field } from '@/constants/impactScore'
import { Asset, EntrySys, EntrySkeletonType } from 'contentful'
import { MaterialTypeIconType, MATERIAL_TYPE_ICON_TYPES } from '@howgood/design'

export type ContentType =
  | 'attribute'
  | 'carbonAccounting'
  | 'metric'
  | 'methodology'
  | 'dataHealthIndicator'
  | 'formulaIcon'
  | 'document'
  | 'requestVendorData'
  | 'news'
  | 'tooltip'
  | 'link'

/**
 * These "default" fields should exist for all present and future content types
 * in the contentful data model
 */
interface DefaultEntry extends EntrySys, EntrySkeletonType {
  title: string
}

export type ContentfulAttributeKey =
  | 'is_min_processed'
  | 'is_climate_friendly'
  | 'is_transparent_company'
  | 'is_clean_label'
  | 'is_water_smart'
  | 'is_fair_labor'
  | 'no_synthetic_input'
  | 'sdg_contributor'
  | 'soil_friendly'

/**
 * To see detailed explanations of what each field represents and how they
 * should be used, look at an entry in the contentful dashboard
 */
export interface AttributeEntry extends DefaultEntry {
  key: ContentfulAttributeKey
  permission: string
  icon: Asset
  trueDescription: string
  falseDescription: string
  longDescription: Document
}

export type ContentfulCarbonAccountingKey =
  | 'scope_1'
  | 'scope_2'
  | 'scope_3_category_1'
  | 'scope_3_category_2'
  | 'scope_3_category_3'
  | 'scope_3_category_4'
  | 'scope_3_category_5'
  | 'scope_3_category_6'
  | 'scope_3_category_7'
  | 'scope_3_category_8'
  | 'scope_3_category_9'
  | 'scope_3_category_10'
  | 'scope_3_category_11'
  | 'scope_3_category_12'
  | 'scope_3_category_13'
  | 'scope_3_category_14'
  | 'scope_3_category_15'

export interface CarbonAccountingEntry extends DefaultEntry {
  key: ContentfulCarbonAccountingKey
  title: string
  subtitle: string
  units: string
}

type ValuesMapKey = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10
export type ValuesMap = Record<ValuesMapKey, string>

export interface MetricEntry extends DefaultEntry {
  key: Field
  permission: string
  subtitle: string
  lowercaseTitle: string
  description: string
  longDescription: Document
  spectrumImage: Asset
  negativeImpact: string
  positiveImpact: string
  improveImpact: Document
  ingredientSubstitution: Document
  changeSourcingLocation: Document
  portfolioCoverage: string
  portfolioFullCoverage: string
  units: string
  unitsTotal: string
  helpText: string
  valuesMap: ValuesMap
}

export interface RequestVendorDataEntry extends DefaultEntry {
  key: ContentfulRequestVendorDataKey
  multipleVendorsDescription: string
  simpleVendorDataTitle: string
  simpleVendorData: Document
  agriculturalPracticeDataTitle: string
  agriculturalPracticeData: Document
  lcaDataTitle: string
  lcaData: Document
}

export interface MethodologyEntry extends DefaultEntry {
  key: ContentfulMethodologyKey
  description: string
  link: string
}

export interface DataHealthIndicatorEntry extends DefaultEntry {
  key: ContentfulDataHealthIndicatorKey
  provided: string
  notProvided: string
  low: string
  medium: string
  high: string
}

export interface FormulaIconEntry extends DefaultEntry {
  key: ContentfulFormulaIconKey
  icon: Asset
  label: string
  description: Document
}

export interface DocumentEntry extends DefaultEntry {
  key: ContentfulDocumentKey
  document: Document
  sources: Document
  disclaimer: Document
  versionHistory: Document
}

export interface TooltipEntry extends DefaultEntry {
  key: ContentfulTooltipKey
  content: Document
}

export interface LinkEntry extends DefaultEntry {
  key: ContentfulLinkKey
  url: string
}

export interface NewsEntry extends DefaultEntry {
  key: string
  article: Document
  image: Asset
  source: string
  actionUrl: string
}

export type Entry =
  | AttributeEntry
  | CarbonAccountingEntry
  | MetricEntry
  | MethodologyEntry
  | DataHealthIndicatorEntry
  | FormulaIconEntry
  | DocumentEntry
  | RequestVendorDataEntry
  | NewsEntry

export type ContentfulRequestVendorDataKey = 'request-vendor-data'

export type ContentfulMethodologyKey = 'scope-3-procurement'

export type ContentfulDataHealthIndicatorKey = 'has_default_weights' | 'default_origin_percent_impact' | 'date_modified'

export type ContentfulFormulaIconKey =
  | 'vendor-verified'
  | 'vendor-verified-plus'
  | 'howgood-supplier'
  | 'internal-material'
  | 'purchased-material'
  | 'standard-ingredient'
  | 'hg-rating-snm'
  | 'hg-rating-good'
  | 'hg-rating-great'
  | 'hg-rating-best'

type FormulaIconKey =
  | 'vendorVerified'
  | 'vendorVerifiedPlus'
  | 'howgoodSupplier'
  | 'purchasedMaterial'
  | 'internalMaterial'
  | 'standardIngredient'
  | 'hgRatingSnm'
  | 'hgRatingGood'
  | 'hgRatingGreat'
  | 'hgRatingBest'

export const FORMULA_ICON_BADGE: Record<FormulaIconKey, ContentfulFormulaIconKey> = {
  vendorVerified: 'vendor-verified',
  vendorVerifiedPlus: 'vendor-verified-plus',
  howgoodSupplier: 'howgood-supplier',
  purchasedMaterial: 'purchased-material',
  internalMaterial: 'internal-material',
  standardIngredient: 'standard-ingredient',
  hgRatingSnm: 'hg-rating-snm',
  hgRatingGood: 'hg-rating-good',
  hgRatingGreat: 'hg-rating-great',
  hgRatingBest: 'hg-rating-best',
}

export type ContentfulFormulaIconToMaterialTypeIcon = {
  ['vendor-verified']: MaterialTypeIconType
  ['vendor-verified-plus']: MaterialTypeIconType
  ['howgood-supplier']: MaterialTypeIconType
  ['purchased-material']: MaterialTypeIconType
  ['internal-material']: MaterialTypeIconType
  ['standard-ingredient']: MaterialTypeIconType
}

export const CONTENTFUL_FORMULA_ICON_TO_MATERIAL_TYPE_ICON: ContentfulFormulaIconToMaterialTypeIcon = {
  ['vendor-verified']: MATERIAL_TYPE_ICON_TYPES.vendorVerified,
  ['vendor-verified-plus']: MATERIAL_TYPE_ICON_TYPES.vendorVerifiedPlus,
  ['howgood-supplier']: MATERIAL_TYPE_ICON_TYPES.howgoodSupplier,
  ['purchased-material']: MATERIAL_TYPE_ICON_TYPES.purchasedMaterial,
  ['internal-material']: MATERIAL_TYPE_ICON_TYPES.internalMaterial,
  ['standard-ingredient']: MATERIAL_TYPE_ICON_TYPES.standardIngredient,
}

export type ContentfulDocumentKey = 'sources-and-methodology' | 'scope-3-methodology' | 'ghg-impact-report'

export type ContentfulFieldKey = Field | ContentfulFormulaIconKey | ContentfulDocumentKey | ContentfulMethodologyKey

export type ContentfulTooltipKey = 'sbti-target-type' | 'sbti-action' | 'vendor-engagement-status'

export type ContentfulLinkKey = 'bulk-data-upload-template'
