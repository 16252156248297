import React, { FC, MouseEvent, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'
import { Button, Menu, MenuItem, ListItemText } from '@howgood/design'
import { useHasFormulationsAccess } from '@/hooks/permissionHooks'
import { useNavigateToFormula } from '@/hooks/useNavigate'
import { selectIsProductOveriewLoading } from '@/state/productOverview'
import { selectIsRecipeLoading } from '@/state/recipe'
import { selectSplitTreatmentEnabled, selectUploadEmissionsSplit } from '@/state/splitio'
import { useUrlWithUseridParam } from '@/hooks/useUrlWithUseridParam'
import { LCA_SUBMISSION_LINK } from '@/constants/common'

export const useStyles = createUseStyles({
  topSection: { padding: '10px', boxSizing: 'border-box' },
})

export const CreateNewButton: FC = () => {
  const classes = useStyles()
  const createNewPaywallEnabled = useSelector(selectSplitTreatmentEnabled('retail-paywall'))
  const isProductOverviewLoading = useSelector(selectIsProductOveriewLoading)
  const isRecipeLoading = useSelector(selectIsRecipeLoading)
  const uploadEmissionsEnabled = useSelector(selectUploadEmissionsSplit)
  const navigateToFormula = useNavigateToFormula()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const lcaUploadUrl = useUrlWithUseridParam(LCA_SUBMISSION_LINK)

  const hasFormulationsAccess = useHasFormulationsAccess()

  const handleClose = () => setAnchorEl(null)

  const handleCreateNew = () => {
    navigateToFormula()
    handleClose()
  }

  const handleUploadLca = () => {
    handleClose()
  }

  const handleCreateNewFormulationClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    if (uploadEmissionsEnabled) {
      setAnchorEl(e.currentTarget)
    } else {
      handleCreateNew()
    }
  }

  if (!hasFormulationsAccess) {
    return null
  }

  return (
    <div className={classes.topSection}>
      <Button
        data-pendo-id={createNewPaywallEnabled ? 'retail-paywall' : 'Menu:CreateNew'}
        data-testid="create-new"
        aria-label="Create new"
        color="primary"
        size="small"
        onClick={handleCreateNewFormulationClick}
        fullWidth
        disabled={createNewPaywallEnabled || isProductOverviewLoading || isRecipeLoading}
      >
        Create New
      </Button>
      <Menu
        id="create-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{
          '& .MuiPaper-root': {
            overflow: 'unset',
          },
        }}
        MenuListProps={{ 'aria-labelledby': 'create-menu-button' }}
      >
        <a href={lcaUploadUrl} target="_blank" rel="noopener noreferrer">
          <MenuItem id="upload-lca-option" data-testid="upload-lca-option" onClick={handleUploadLca}>
            <ListItemText>Upload emissions data</ListItemText>
          </MenuItem>
        </a>
        <MenuItem id="build-new-product-option" data-testid="build-new-product-option" onClick={handleCreateNew}>
          <ListItemText>Calculate product emissions</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  )
}
