import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { IconDefinition, faAngleRight } from '@fortawesome/pro-regular-svg-icons'
import { Stack, Icon, Text, theme, Box, Tooltip } from '@howgood/design'

export type BreadCrumb = {
  title: string
  url: string
  icon?: IconDefinition
  onClick?: () => void
}

interface BreadCrumbProps {
  breadCrumbs: BreadCrumb[]
  id?: string
  highlighted?: boolean
  actions?: React.ReactNode
  ['aria-label']?: string
  ['data-testid']?: string
}

export const BreadCrumbs: FC<BreadCrumbProps> = ({
  breadCrumbs = [],
  id,
  highlighted,
  actions,
  ['aria-label']: ariaLabel,
  ['data-testid']: dataTestId,
}) => {
  const color = highlighted ? 'white' : 'text.secondary'

  return (
    <Stack
      id={id}
      direction="row"
      alignItems="center"
      data-testid={dataTestId || 'breadcrumbs'}
      aria-label={ariaLabel}
      gap={0.5}
      color={color}
      height="24px"
      maxWidth="fit-content"
      overflow="hidden"
      px={highlighted ? 0.5 : 0}
      borderRadius="2px"
      bgcolor={highlighted ? theme.palette.info.main : 'none'}
    >
      {breadCrumbs.map((crumb, index) => {
        return (
          <Stack
            key={index}
            direction="row"
            gap={0.25}
            flexWrap="nowrap"
            alignItems="center"
            onClick={crumb.onClick}
            color={color}
            overflow="hidden"
            data-testid={`${dataTestId}-${index}`}
            fontSize={14}
            minWidth={80}
          >
            {crumb.icon && (
              <Icon fontSize={13} containerSize="sm" icon={crumb.icon} data-testid={`${dataTestId}-icon-${index}`} />
            )}
            <Tooltip key={index} title={crumb.title}>
              <Box
                flexGrow={1}
                overflow="hidden"
                sx={{
                  cursor: 'pointer',
                  '&:hover': { textDecoration: 'underline' },
                }}
              >
                <Link to={crumb.url}>
                  <Text color={color} noWrap fontSize={14} lineHeight="18px" textOverflow="ellipsis">
                    {crumb.title}
                  </Text>
                </Link>
              </Box>
            </Tooltip>
            {index < breadCrumbs.length - 1 && (
              <Icon containerSize="xs" fontSize={11} icon={faAngleRight} color={color} />
            )}
          </Stack>
        )
      })}
      {actions && (
        <Stack alignItems="center" justifyContent="center" mr={-0.5} borderLeft="solid 1px white">
          {actions}
        </Stack>
      )}
    </Stack>
  )
}
