import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Autocomplete, MenuItem, TextField } from '@howgood/design'

import { WorkspaceType } from '@/records'
import { selectAvailableWorkspaceTypes } from '@/state/workspaces'
import {
  selectEditingFilter,
  selectWorkspaceProductFilters,
  selectWorkspaceTypeFilterOptions,
  setEditingFilter,
  updateFiltersUrlAndProducts,
} from '@/state/products'
import { selectIsProductFiltersLoading } from '@/selectors/selectIsProductFiltersLoading'

interface WorkspaceTypeOption {
  value: WorkspaceType
  label: string
}

export const WorkspaceTypeFilter: FC = () => {
  const dispatch = useDispatch()
  const isLoading = useSelector(selectIsProductFiltersLoading)
  const workspaceTypeOptions = useSelector(selectAvailableWorkspaceTypes)
  const workspaceProductFilters = useSelector(selectWorkspaceProductFilters)
  const selectedWorkspaceTypeFilterOptions = useSelector(selectWorkspaceTypeFilterOptions)
  const editingFilter = useSelector(selectEditingFilter)
  const id = 'workspace-type-search'

  const handleWorkspaceTypeChange = async (updatedWorkspaceTypes: WorkspaceTypeOption[]) => {
    const workspaceTypeValues = updatedWorkspaceTypes.map((wst) => wst.value)
    const updatedWorkspaces = updatedWorkspaceTypes.length
      ? workspaceProductFilters.filter((ws) => workspaceTypeValues.includes(ws.workspace_type))
      : workspaceProductFilters
    dispatch(
      updateFiltersUrlAndProducts({
        updatedFilters: {
          workspaceTypes: workspaceTypeValues,
          workspaces: updatedWorkspaces,
        },
      })
    )
    dispatch(setEditingFilter(id))
  }

  return (
    <Autocomplete
      data-pendo-id="SearchWorkspaceTypes"
      id={id}
      data-testid={id}
      options={workspaceTypeOptions}
      filterSelectedOptions
      isOptionEqualToValue={(option, val) => {
        return option.value === val.value
      }}
      onChange={(_event, ops: WorkspaceTypeOption[]) => handleWorkspaceTypeChange(ops)}
      value={selectedWorkspaceTypeFilterOptions}
      disabled={isLoading}
      renderInput={(params) => (
        <TextField {...params} autoFocus={editingFilter === id} placeholder="Workspace Type" fullWidth />
      )}
      renderOption={(props: React.HTMLAttributes<HTMLLIElement>, option: WorkspaceTypeOption) => (
        <MenuItem
          {...props}
          key={option.value}
          value={option.value}
          onClick={() => handleWorkspaceTypeChange([...selectedWorkspaceTypeFilterOptions, option])}
        >
          {option.label}
        </MenuItem>
      )}
      multiple
      fullWidth
      componentsProps={{ popper: { style: { width: 'fit-content' }, placement: 'bottom-start' } }}
    />
  )
}
