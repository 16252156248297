import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import * as d3 from 'd3'
import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium'
import { Box, Stack, Tooltip, Text } from '@howgood/design'
import { selectHideTagPrefix, selectPortfolioData } from '../state/portfolioDashboard'
import { dataGridStyles } from './BarChart'
import { useGridColumns } from './useGridColumns'

export const PieChartGrid: FC = () => {
  const data = useSelector(selectPortfolioData)
  const hideTagPrefix = useSelector(selectHideTagPrefix)
  const sharedDefs: GridColDef[] = useGridColumns()

  // Specify the color scale
  const color = d3.scaleOrdinal(
    data.map((d) => d.id),
    d3.schemeTableau10
  )

  const columns: GridColDef[] = [
    {
      field: 'color',
      headerName: '',
      width: 25,
      sortable: false,
      renderCell: (params) => (
        <Stack width="100%" alignItems="end">
          <Box width={16} minWidth={16} height={16} bgcolor={params.value} />
        </Stack>
      ),
    },
    {
      field: 'name',
      headerName: '',
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Text variant="body2">
            {hideTagPrefix && params.value.includes(':')
              ? params.value
                  .split(':')
                  .slice(1)
                  .join(': ')
              : params.value}
          </Text>
        </Tooltip>
      ),
    },
    ...sharedDefs.filter((def) => ['value', 'fillRate'].includes(def.field)),
  ]

  const rows = data.map((d, index) => ({
    ...d,
    color: index < 10 ? color(index) : '#DDD',
  }))

  return (
    <Stack width="100%" height="100%" overflow="auto">
      <DataGridPremium
        rows={rows}
        columns={columns}
        initialState={{ density: 'compact' }}
        columnHeaderHeight={20}
        rowHeight={35}
        rowSelection={false}
        pagination
        autoPageSize
        disableColumnFilter
        disableColumnMenu
        disableColumnReorder
        sx={dataGridStyles}
      />
    </Stack>
  )
}
