import { FormulationTag, InventoriesResponse, MaterialType, ShareRequestStatus } from '@/api/types'
import { HOWGOOD_SUPPLIERS_WORKSPACE_ID } from '@/constants/common'
import { Goals, IProductStandard, Workspace } from '@/records'
import { ImpactScore, AgribalyzeCategory, Region } from '@/api'
import { ProductResponse } from '@/state/productOverview/productOverview.state'
import { OrgUser } from '@/state/organization'
import { ESIngredient } from '@/state/products'

export const initialProduct: IProduct = {
  id: null,
  upc: null,
  name: '',
  date_modified: null,
  locked_claims_timestamp: null,
  status: '',
  formulation_status: '',
  impact_score: {},
  strategic_fit: null,
  past_sales_growth: null,
  gross_margin: null,
  howgood_total_impact: null,
  howgood_one_metric: null,
  applegate_impact: null,
  craveability_and_sales_potential_impact: null,
  craveability_impact: null,
  sales_potential_impact: null,
  applegate_health_score: null,
  workspaces: [],
  best_in_class: null,
  annual_sales_volume: null,
  weight_kg: null,
  agribalyze_category: null,
  is_min_processed: false,
  is_clean_label: false,
  is_water_smart: false,
  is_climate_friendly: false,
  is_fair_labor: false,
  is_transparent_company: false,
  no_synthetic_input: false,
  is_fair_trade: null,
  simple: null,
  flagged_for_labor: null,
  org: null,
  standards: [],
  nutrition_score: null,
  region: null,
  retail_region: null,
  storage_region: null,
  manufacturing_type: null,
  inventories: [],
  draftFormulationTags: [],
  customerType: null,
  requires_cold_storage: null,
  requires_cooking: null,
  has_default_weights: null,
  nutrition_added_sugars: null,
  nutrition_total_calcium: null,
  nutrition_total_dietary_fiber: null,
  nutrition_total_energy: null,
  nutrition_total_lipids: null,
  nutrition_total_nitrogen: null,
  nutrition_total_proteins: null,
  nutrition_total_saturated_fat_acid: null,
  nutrition_total_sodium: null,
  nutrition_total_sugars: null,
  sharing_requests: [],
  es_ingredients: [],
  reports: { items: [] },
}

export const coldStorageOptions = {
  F: 'Frozen',
  R: 'Refrigerated',
}
interface ManufacturingType {
  id: number
  title: string
  value: number
}

export type CustomerType = 'cpg' | 'supplier'
interface LockedClaims {
  product_attributes: Omit<ProductResponse, 'locked_claims'>
  impact_score: ImpactScore
}

export interface ProductBrand {
  name: string
  id: number
  website?: string
  email?: string
  phone?: string
  workflow_notes?: string
}

interface WorkflowTag {
  name?: string
  id?: number
}

export type SharingType = 'material_directory' | 'direct'

export interface SharingRequest {
  date_created: string
  date_modified: string
  id: number
  status: ShareRequestStatus
  type: SharingType
  description?: string
}

export type ClaimsReportsType = {
  items?: {
    date: string
    url: string
  }[]
}
export interface TransportationMode {
  id: number
  name: string
}
export interface RouteLeg {
  id?: string
  waypoint: Region
  mode: TransportationMode
  distance_override: number
}
export interface ProductRouteLegs {
  mtr: RouteLeg[] // manufacturing to retail
  mts: RouteLeg[] // manufacturing to storage
}
export interface IngredientRouteLegs {
  ftp: RouteLeg[] // farm to processing
  mtm: RouteLeg[] // manufacturing to manufacturing (nested ingredients)
  ptm: RouteLeg[] // processing to manufacturing
}

export type WeightOption = 'percentages' | 'grams'
export const PERCENTAGES = 'percentages' as WeightOption
export const GRAMS = 'grams' as WeightOption
export type ScenarioProductAttributes = {
  mt_per_year: number
  goals: Goals
}
interface ScenarioData {
  id: number
  product?: ScenarioProductAttributes
}
export interface ScenarioProduct extends ScenarioProductAttributes {
  id?: number
  scenario: {
    id: number
  }
  product: {
    id: number
  }
}

export type BeverageTemperature = 'H' | 'C' | 'I' // hot, cold, or iced

export interface IProduct {
  id?: number
  pk?: number
  name?: string
  date_modified?: string
  date_created?: string
  locked_claims_timestamp?: string
  locked_claims?: LockedClaims
  status?: string
  formulation_status?: string
  impact_score?: ImpactScore
  strategic_fit?: number
  past_sales_growth?: number
  gross_margin?: number
  howgood_total_impact?: number
  howgood_one_metric?: number
  applegate_impact?: number
  craveability_and_sales_potential_impact?: number
  applegate_health_score?: number
  craveability_impact?: number
  sales_potential_impact?: number
  organization?: number
  workspaces?: Workspace[]
  best_in_class?: boolean
  is_min_processed?: boolean
  is_clean_label?: boolean
  is_water_smart?: boolean
  is_climate_friendly?: boolean
  is_fair_labor?: boolean
  is_transparent_company?: boolean
  no_synthetic_input?: boolean
  is_fair_trade?: boolean
  simple?: boolean
  flagged_for_labor?: boolean
  org?: boolean
  weight_kg?: string
  annual_sales_volume?: number
  agribalyze_category?: AgribalyzeCategory
  standards?: IProductStandard[]
  nutrition_score?: number
  region?: Region
  storage_region?: Region
  retail_region?: Region
  manufacturing_type?: ManufacturingType
  inventories?: InventoriesResponse[]
  draftFormulationTags?: FormulationTag[]
  customerType?: CustomerType
  requires_cold_storage?: 'R' | 'F'
  requires_cooking?: boolean
  has_default_weights?: boolean
  impact_score_total?: number
  nutrition_added_sugars?: number
  nutrition_total_calcium?: number
  nutrition_total_dietary_fiber?: number
  nutrition_total_energy?: number
  nutrition_total_lipids?: number
  nutrition_total_nitrogen?: number
  nutrition_total_proteins?: number
  nutrition_total_saturated_fat_acid?: number
  nutrition_total_sodium?: number
  nutrition_total_sugars?: number
  craveability?: number
  sales_potential?: number
  enable_sumprod_in_metrics?: boolean
  upc?: string
  brand?: ProductBrand
  is_foreign?: boolean
  impacts_overridden?: boolean
  child_impacts_overridden?: boolean
  workflow_tags?: WorkflowTag[]
  sharing_requests?: SharingRequest[]
  reports?: ClaimsReportsType
  input_weight_unit?: WeightOption
  material_types?: MaterialType[]
  source_product_id?: number
  assignee?: OrgUser
  route_legs?: ProductRouteLegs
  es_ingredients?: ESIngredient[] // Product's ingredients as returned by Elasticsearch
  initiatives?: { id: number }[]
  scenarios?: ScenarioData[]
  scenario_products?: ScenarioProduct[]
  source_product?: { id: number } // For products copied as part of a scenario only
  custom_fields?: Record<string, number>
  temperature?: BeverageTemperature
}

export interface PatchProductData extends Partial<IProduct> {
  workspaceIds?: number[]
}

export type FormulationStatus = 'pipeline' | 'archived' | 'competitor' | 'scenario'
export interface FormulationStatusOption {
  value: FormulationStatus
  label: 'Pipeline' | 'Archived' | 'Competitor'
  id?: FormulationStatus
}

export const FORMULATION_STATUS_PIPELINE: FormulationStatusOption = {
  value: 'pipeline',
  label: 'Pipeline',
  id: 'pipeline',
}
const FORMULATION_STATUS_ARCHIVED: FormulationStatusOption = {
  value: 'archived',
  label: 'Archived',
  id: 'archived',
}
const FORMULATION_STATUS_COMPETITOR: FormulationStatusOption = {
  value: 'competitor',
  label: 'Competitor',
  id: 'competitor',
}

export const formulationStatuses: FormulationStatusOption[] = [
  FORMULATION_STATUS_PIPELINE,
  FORMULATION_STATUS_ARCHIVED,
  FORMULATION_STATUS_COMPETITOR,
]

export const defaultFormulationStatusesForAnalytics: FormulationStatusOption[] = [FORMULATION_STATUS_PIPELINE]

export const defaultFormulationStatusesForAggregatedVendors: FormulationStatusOption[] = [FORMULATION_STATUS_PIPELINE]

function getProductInventory(product: IProduct): InventoriesResponse | null {
  const inventories: InventoriesResponse[] = product?.inventories || []

  if (inventories.length === 1) {
    return inventories[0]
  }

  if (inventories.length > 1) {
    // for the time being the workspace id is hardcoded
    return inventories.find((inventory) => inventory.workspace.id !== HOWGOOD_SUPPLIERS_WORKSPACE_ID) || null
  }

  return null
}

function getDraftFormulationTags(product: IProduct) {
  return product.draftFormulationTags || []
}

export function getFormulationTags(product: IProduct): FormulationTag[] {
  return product.id ? getProductInventory(product)?.formulation_tags || [] : getDraftFormulationTags(product)
}

export function getInventoryIds(product: IProduct) {
  const inventories: InventoriesResponse[] = product?.inventories || []
  return inventories.reduce((ids, inventory) => {
    return inventory.workspace.id !== HOWGOOD_SUPPLIERS_WORKSPACE_ID ? [...ids, inventory.id] : ids
  }, [] as number[])
}

export const getInitiativeInventories = (product: IProduct, workspaces: Workspace[]) => {
  const inventories = product?.inventories || []
  return inventories.filter((inventory) => workspaces.some((workspace) => workspace.id === inventory.workspace.id))
}
