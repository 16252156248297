import React, { FC } from 'react'
import MuiSnackbar, { SnackbarProps as MuiSnackbarProps } from '@mui/material/Snackbar'

interface SnackbarProps extends Pick<MuiSnackbarProps, 'open' | 'anchorOrigin' | 'onClose' | 'children'> {
  ['data-testid']?: string
}

export const Snackbar: FC<SnackbarProps> = ({
  ['data-testid']: dataTestId,
  open,
  anchorOrigin = { vertical: 'top', horizontal: 'center' },
  onClose,
  children,
}) => {
  return (
    <MuiSnackbar
      data-testid={dataTestId}
      open={open}
      anchorOrigin={anchorOrigin}
      onClose={onClose}
      autoHideDuration={5000}
    >
      {children}
    </MuiSnackbar>
  )
}
