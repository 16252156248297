export const UNKNOWN_VENDOR_LABEL = 'Unknown Vendor'

export type UnknownVendorOptionValue = 'unknown vendor'
export interface IBrandSearchOption {
  value: number | UnknownVendorOptionValue
  label: string
}

export const UNKNOWN_VENDOR_OPTION_VALUE = 'unknown vendor' as UnknownVendorOptionValue

export const UNKNOWN_VENDOR_OPTION = {
  value: UNKNOWN_VENDOR_OPTION_VALUE,
  label: UNKNOWN_VENDOR_LABEL,
}
