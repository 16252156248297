import { ReactNode } from 'react'
import { createSelector } from 'reselect'

import { selectContentfulMetricData } from '@/state/contentfulData'
import { CardData, getContentData, initialCardCategories } from '@/records/scoreCardData'
import { selectFavoriteTiles } from '@/state/preferences'
import { MetricEntry } from '@/contentfully'
import { selectBenchmarkData } from '@/state/salesCategories'
import { ALL_FOOD_INDUSTRY } from '@/constants/industryCategories'
import { selectProductMetrics } from '@/state/productMetrics'
import { selectIsVendors, selectIsInitiative, selectIsProcurement } from '@/state/router'
import { Field, PROCUREMENT_CARBON_FIELDS } from '@/constants/impactScore'
import { selectShowCarbonExtras } from '@/state/pageSettings'

interface CardInfo {
  key: string
  benchmark?: number
  goal?: number
  contentfulEntry: MetricEntry
  category: string
  value: number
  sum: number
  avg: number
  categoryIndex: number
}

// For the given metric, configure all of the data to be displayed on the score tile
const generateRollUpCardData = ({ key, contentfulEntry, category, value, sum, avg, categoryIndex }: CardInfo) => {
  let displayedValue: ReactNode = null
  let score: number | string = null
  let avgScore: number = null
  let sumScore: number = null
  let size: 'sm' | 'md' | 'lg' = 'sm'
  if (value !== null || sum !== null || avg !== null) {
    const valueToUse = value === null ? (contentfulEntry?.units ? sum : avg) : value
    switch (key) {
      case 'retail_rating_impact':
        // This is now shown in the claims tile
        return []
      case 'howgood_total_impact':
      case 'howgood_one_metric':
      case 'starbucks_impact':
        score = Math.round(valueToUse)
        avgScore = Math.round(avg)
        sumScore = Math.round(sum)
        size = 'lg'
        break
      default:
        score = category === 'overview' ? Math.round(valueToUse) : valueToUse
        avgScore = category === 'overview' ? Math.round(avg) : avg
        sumScore = category === 'overview' ? Math.round(sum) : sum
    }
  }

  const cardData: CardData = {
    key,
    label: contentfulEntry?.title || key,
    category,
    categoryIndex,
    subTitle: contentfulEntry?.subtitle,
    value: displayedValue,
    score,
    avg: avgScore,
    sum: sumScore,
    contentfulEntry,
    action: null,
    size,
  }

  return [cardData]
}

export const selectRollupScoreCardData = createSelector(
  selectProductMetrics,
  selectBenchmarkData,
  selectContentfulMetricData,
  selectFavoriteTiles,
  selectIsProcurement,
  selectIsInitiative,
  selectIsVendors,
  selectShowCarbonExtras,
  (
    productMetrics,
    benchmarks,
    contentfulMetricData,
    favoriteTiles,
    isProcurement,
    isInitiative,
    isVendor,
    showExtraCarbonContent
  ) => {
    const metricKeys = Array.from(
      new Set(Object.keys(productMetrics).map((key) => key.replace('_sum', '').replace('_avg', '')))
    )
    metricKeys.sort()
    return metricKeys.reduce((acc, key) => {
      const value = productMetrics[key]
      const { category, contentfulEntry } = getContentData({
        key,
        contentfulMetricData,
        showProcurementCarbonContent: isProcurement,
        showAllFields: isInitiative || isVendor,
        showExtraCarbonContent,
      })

      // filter out null values
      if (
        (!value &&
          (typeof productMetrics[`${key}_sum`] !== 'number' || productMetrics[`${key}_count`] === 0) &&
          typeof productMetrics[`${key}_avg`] !== 'number') ||
        key === 'labor_risk_information'
      ) {
        return acc
      }

      // filter out non-procurement metrics if we are in procurement
      if (category === 'carbon' && isProcurement && !PROCUREMENT_CARBON_FIELDS.includes(key as Field)) {
        return acc
      }

      const cardsData = generateRollUpCardData({
        key,
        benchmark: benchmarks[ALL_FOOD_INDUSTRY.title]?.[key],
        goal: null,
        contentfulEntry,
        category,
        value,
        sum: productMetrics[`${key}_sum`],
        avg: productMetrics[`${key}_avg`],
        categoryIndex: acc[category].index,
      })
      const favCards = (cardsData as CardData[]).filter((card) => favoriteTiles.includes(card.key))

      const cards = {
        ...acc,
        [category]: {
          title: acc[category].title,
          data: [...acc[category].data, ...cardsData],
          index: acc[category].index,
        },
        favorites: {
          title: acc.favorites.title,
          data: [...acc.favorites.data, ...favCards],
          index: 0,
        },
      }

      return cards
    }, initialCardCategories)
  }
)
