import React, { FC } from 'react'
import { formatNumber } from '@howgood/utils'
import { faArrowDown, faArrowUp } from '@fortawesome/pro-solid-svg-icons'
import { Text } from '../Text'
import { Stack } from '../Stack'
import { Icon } from '../Icon'
import { theme } from '../theme'
import { Sign } from './types'

interface Props {
  change: number
  changeSign: Sign
  changeColor: string
  showChange: boolean
  noChangeText: string
}

export const MetricTileChange: FC<Props> = ({ change, changeSign, changeColor, showChange, noChangeText }) => {
  const changeFormatted = change ? formatNumber(Math.abs(change)) : null
  const noChange = showChange && !changeFormatted
  const changeText = noChangeText ? noChangeText : noChange ? 'No change' : changeFormatted
  const color = noChangeText ? theme.palette.warning.dark : noChange ? null : changeColor

  return (
    <Stack data-testid="metric-tile-change" flexDirection="row" alignItems="center" gap={0.5}>
      {changeText && !noChange && !noChangeText && (
        <Icon
          data-testid="metric-tile-change-direction-icon"
          icon={changeSign === 'negative' ? faArrowDown : faArrowUp}
          size="xs"
          color={color}
        />
      )}
      <Text
        data-testid="metric-tile-change-text"
        variant="caption"
        fontWeight={noChange || noChangeText ? null : 600}
        color={color}
        visibility={changeText ? 'visible' : 'hidden'}
      >
        {changeText || 'hidden'}
      </Text>
      {changeText && !noChange && !noChangeText && <Text variant="caption"> since last save</Text>}
    </Stack>
  )
}
