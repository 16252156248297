import { fetchElasticV2 } from '@/api'
import { FORMULATIONS_SEARCH_PATH } from '@/constants/config'
import { estypes } from '@elastic/elasticsearch'
import { Scope3Cat1Hit, Scope3Cat4Hit } from './AnalysisPanel'
import {
  generateMtPerYear,
  scope3Category1Annual,
  scope3Category4Annual,
  scope3Cat1Metric,
  scope3Cat4Metric,
} from '@/api/elastic/runtimeMappings'
import { SearchResponse } from '@elastic/elasticsearch/api/types'
import {
  generateKnownVendorsFilter,
  generateUnKnownVendorsFilter,
  generateVendorVerifiedFilter,
  vendorVerifiedPlusFilter,
} from '@/api/elastic/vendorGranularityFilters'
import { SCOPE_3_CATEGORY_1_FIELD, SCOPE_3_CATEGORY_4_FIELD } from '@/constants/impactScore'
import { ProductsBoolQuery } from '@/api/elastic/productsEsRepo'
import { UNKNOWN_VENDORS_LABEL } from '@/state/vendorManagement/vendorManagement.utils'

type AggregatedVendor = {
  doc_count: number
  key: string
  total_scope_3_category_1: { value: number }
  total_scope_3_category_4: { value: number }
}
type CarbonFootprintByVendorCategoryData = Record<string, number>
interface CarbonFootprintByVendor {
  scope_3_cat_1_annual: CarbonFootprintByVendorCategoryData
  scope_3_cat_4_annual: CarbonFootprintByVendorCategoryData
}

export const getESScope3Aggregations = async (boolQuery: ProductsBoolQuery, workspaceIds: number[]) => {
  if (!workspaceIds.length) {
    return {
      scope3Cat1Data: {
        hits: [],
        total: 0,
        count: 0,
      },
      scope3Cat4Data: {
        hits: [],
        total: 0,
        count: 0,
      },
      carbonFootprintByVendor: { scope_3_cat_1_annual: {}, scope_3_cat_4_annual: {} },
    }
  }
  const generateAggs = (includeHits = false) => {
    const aggs = {
      total_scope_3_category_1: {
        sum: {
          field: SCOPE_3_CATEGORY_1_FIELD,
        },
      },
      total_scope_3_category_4: {
        sum: {
          field: SCOPE_3_CATEGORY_4_FIELD,
        },
      },
    }
    if (includeHits) {
      return {
        ...aggs,
        top_4_scope_3_category_1: {
          top_hits: {
            sort: [
              {
                [SCOPE_3_CATEGORY_1_FIELD]: {
                  order: 'desc',
                },
              },
            ],
            _source: {
              includes: ['pk', 'name', 'impact_score', 'locked_claims'],
            },
            size: 4,
          },
        },
        count_scope_3_category_1: {
          value_count: {
            field: SCOPE_3_CATEGORY_1_FIELD,
          },
        },
        top_4_scope_3_category_4: {
          top_hits: {
            sort: [
              {
                [SCOPE_3_CATEGORY_4_FIELD]: {
                  order: 'desc',
                },
              },
            ],
            _source: {
              includes: ['pk', 'name', 'impact_score', 'locked_claims'],
            },
            size: 4,
          },
        },
        count_scope_3_category_4: {
          value_count: {
            field: SCOPE_3_CATEGORY_4_FIELD,
          },
        },
      }
    }
    return aggs
  }
  boolQuery.must = [
    {
      range: {
        mt_per_year: {
          gt: 0,
        },
      },
    },
  ]
  const esResponse = await fetchElasticV2({
    url: FORMULATIONS_SEARCH_PATH,
    body: {
      size: 0,
      query: {
        bool: boolQuery,
      },
      runtime_mappings: {
        mt_per_year: generateMtPerYear(workspaceIds),
        [SCOPE_3_CATEGORY_1_FIELD]: scope3Category1Annual,
        [SCOPE_3_CATEGORY_4_FIELD]: scope3Category4Annual,
      },
      fields: ['mt_per_year', SCOPE_3_CATEGORY_1_FIELD, SCOPE_3_CATEGORY_4_FIELD],
      aggs: {
        ...generateAggs(true),
        vendors: {
          terms: {
            size: 10000,
            field: 'brand.name.keyword',
            missing: UNKNOWN_VENDORS_LABEL,
          },
          aggs: generateAggs(),
        },
      } as Record<string, estypes.AggregationsAggregationContainer>,
    },
  })

  const aggregatedVendors = (esResponse?.aggregations?.vendors as estypes.AggregationsMultiBucketAggregateBase<any>)
    ?.buckets

  const carbonFootprintByVendor: CarbonFootprintByVendor = aggregatedVendors.reduce(
    (acc: CarbonFootprintByVendor, vendor: AggregatedVendor) => {
      acc.scope_3_cat_1_annual[vendor.key] = vendor.total_scope_3_category_1.value
      acc.scope_3_cat_4_annual[vendor.key] = vendor.total_scope_3_category_4.value
      return acc
    },
    { scope_3_cat_1_annual: {}, scope_3_cat_4_annual: {} }
  )

  // Configure required scope 3 category 1 data
  const scope3Cat1Total = esResponse?.aggregations?.total_scope_3_category_1 as estypes.AggregationsSumAggregate
  const scope3Cat1Count = esResponse?.aggregations?.count_scope_3_category_1 as estypes.AggregationsSumAggregate
  const topScope3Cat1 = esResponse?.aggregations?.top_4_scope_3_category_1 as estypes.AggregationsTopHitsAggregate
  const scope3Cat1Hits = topScope3Cat1?.hits?.hits.map((hit) => {
    const _source = hit._source
    return {
      id: _source.pk,
      name: _source.name,
      mt_per_year: hit.fields?.mt_per_year?.[0],
      [scope3Cat1Metric]: _source.locked_claims
        ? _source.locked_claims.impact_score.scope3Cat1Metric
        : _source.impact_score.scope3Cat1Metric,
      [SCOPE_3_CATEGORY_1_FIELD]: hit.fields?.[SCOPE_3_CATEGORY_1_FIELD]?.[0],
      percentage: (hit.fields?.[SCOPE_3_CATEGORY_1_FIELD]?.[0] / scope3Cat1Total.value) * 100,
    } as Scope3Cat1Hit
  })
  const scope3Cat1Data = { hits: scope3Cat1Hits, total: scope3Cat1Total.value, count: scope3Cat1Count.value }

  // Configure required scope 3 category 4 data
  const scope3Cat4Total = esResponse?.aggregations?.total_scope_3_category_4 as estypes.AggregationsSumAggregate
  const scope3Cat4Count = esResponse?.aggregations?.count_scope_3_category_4 as estypes.AggregationsSumAggregate
  const topScope3Cat4 = esResponse?.aggregations?.top_4_scope_3_category_4 as estypes.AggregationsTopHitsAggregate
  const scope3Cat4Hits = topScope3Cat4?.hits?.hits.map((hit) => {
    const _source = hit._source
    return {
      id: _source.pk,
      name: _source.name,
      mt_per_year: hit.fields?.mt_per_year?.[0],
      [scope3Cat4Metric]: _source.locked_claims
        ? _source.locked_claims.impact_score.scope3Cat4Metric
        : _source.impact_score.scope3Cat4Metric,
      [SCOPE_3_CATEGORY_4_FIELD]: hit.fields?.[SCOPE_3_CATEGORY_4_FIELD]?.[0],
      percentage: (hit.fields?.[SCOPE_3_CATEGORY_4_FIELD]?.[0] / scope3Cat4Total.value) * 100,
    } as Scope3Cat4Hit
  })
  const scope3Cat4Data = { hits: scope3Cat4Hits, total: scope3Cat4Total.value, count: scope3Cat4Count.value }

  return { scope3Cat1Data, scope3Cat4Data, carbonFootprintByVendor }
}

type ESVendorGranularityAggs = {
  vendor_granularity: {
    buckets: {
      vendorVerified: {
        doc_count: number
      }
      knownVendors: {
        doc_count: number
      }
      unknownVendors: {
        doc_count: number
      }
      vendorVerifiedPlus: {
        doc_count: number
      }
    }
  }
}
export const getESVendorGranularityAggregations = async (
  boolQuery: ProductsBoolQuery,
  thirdPartyWorkspaces: number[],
  workspaceIds: number[],
  includeVendorVerifiedPlus: boolean
): Promise<ESVendorGranularityAggs> => {
  if (!workspaceIds.length) {
    return {
      vendor_granularity: {
        buckets: {
          vendorVerified: {
            doc_count: 0,
          },
          knownVendors: {
            doc_count: 0,
          },
          unknownVendors: {
            doc_count: 0,
          },
          vendorVerifiedPlus: {
            doc_count: 0,
          },
        },
      },
    }
  }
  const aggs = {
    vendor_granularity: {
      filters: {
        filters: {
          knownVendors: generateKnownVendorsFilter(thirdPartyWorkspaces, includeVendorVerifiedPlus),
          unknownVendors: generateUnKnownVendorsFilter(thirdPartyWorkspaces, includeVendorVerifiedPlus),
          vendorVerified: generateVendorVerifiedFilter(thirdPartyWorkspaces),
          vendorVerifiedPlus: vendorVerifiedPlusFilter,
        },
      },
    },
  }

  const esResponse: SearchResponse = await fetchElasticV2({
    url: FORMULATIONS_SEARCH_PATH,
    body: {
      size: 0,
      query: {
        bool: boolQuery,
      },
      runtime_mappings: {
        mt_per_year: generateMtPerYear(workspaceIds),
      },
      aggs,
    },
  })

  return esResponse.aggregations as ESVendorGranularityAggs
}
