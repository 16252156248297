import React, { FC, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { faPaperPlane, faInfoCircle } from '@fortawesome/pro-regular-svg-icons'
import {
  Button,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Checkbox,
  Box,
  Text,
  Icon,
} from '@howgood/design'
import { addMessage } from '@/state/messages'
import { getCurrentFormulaId } from '@/utils/getUrl'
import { selectOrganization } from '@/state/organization'
import {
  ShareRequestInfo,
  lockProduct,
  requestSharing,
  selectIsThirdParty,
  selectProductIsLocked,
} from '@/state/productOverview'
import { drawDOM, exportPDF } from '@progress/kendo-drawing'
import { PDFExport } from '@progress/kendo-react-pdf'
import { usePendoTrack } from '@/hooks'
import { ProductGhgImpactExport } from '@/features/ProductPage/export/ProductGhgImpactExport/ProductGhgImpactExport'
import { PDF_EXPORT_SETTINGS } from '@/constants/pdfExportSettings'
import { fetchHelperV2 } from '@/api'
import * as Sentry from '@sentry/react'
import { selectPageView } from '@/state/pageSettings'
import { AttachmentNotificationDialog, ShareNotificationDialog } from './NotificationDialogs'

interface Props {
  onClose: () => void
}

export const SendMessageDialog: FC<Props> = ({ onClose }) => {
  const organization = useSelector(selectOrganization)
  const productId = getCurrentFormulaId()
  const isLocked = useSelector(selectProductIsLocked)
  const isThirdPartyProduct = useSelector(selectIsThirdParty)
  const pageView = useSelector(selectPageView)

  const dispatch = useDispatch()
  const pendoTrack = usePendoTrack()

  const productGhgImpactExportRef = useRef<PDFExport>()
  const pdfContentRef = useRef(null)

  const [recipients, setRecipients] = useState('')
  const [subject, setSubject] = useState(`${organization.organizationName} has shared a product with you`)
  const [message, setMessage] = useState('')

  const [attachmentSelected, setAttachmentSelected] = useState(true)
  const [shareSelected, setShareSelected] = useState(isThirdPartyProduct ? false : true)
  const [shareWith, setShareWith] = useState('')

  const [attachmentNotificationOpen, setAttachmentNotificationOpen] = useState(false)
  const [shareNotificationOpen, setShareNotificationOpen] = useState(false)

  const createReport = async () => {
    pendoTrack('Formula: Lock claims report created via Send Message')
    // First create the GHG impact report PDF to include in the lock claims request payload
    // See https://www.telerik.com/kendo-react-ui/components/pdfprocessing/base64string/
    const group = await drawDOM(pdfContentRef.current, PDF_EXPORT_SETTINGS)
    const dataUri = await exportPDF(group)
    const base64String = dataUri.split(';base64,')[1]

    // Lock the product
    await dispatch(lockProduct(base64String))
  }

  const shareProduct = async () => {
    const directRequest = { type: 'direct', text: `Share with organization ${shareWith}` } as ShareRequestInfo
    pendoTrack('Formula: Share Requested', { directRequest })
    dispatch(requestSharing(directRequest))
  }

  const handleSendClicked = async () => {
    // Validate the email addresses
    const recipientsArray = recipients.split(',').map((recipient) => recipient.trim())
    const regEx = /\S+@\S+\.\S+/
    const addressesAreValid = recipientsArray.every((recipient) => regEx.test(recipient))

    if (!addressesAreValid) {
      dispatch(addMessage({ message: 'Invalid email address', severity: 'error' }))
      return
    }
    onClose()

    if (!isLocked && (attachmentSelected || shareSelected)) {
      await createReport()
      dispatch(addMessage({ message: 'Product locked', severity: 'success' }))
    }

    // Locking throws an error if it fails, so won't reach this if locking fails
    if (shareSelected) {
      await shareProduct()
    }

    try {
      await fetchHelperV2({
        url: `products/${productId}/send-email/`,
        method: 'POST',
        body: JSON.stringify({
          recipients: [...recipientsArray],
          subject,
          message,
          organization_name: organization.organizationName,
          include_report: attachmentSelected,
        }),
        parse: false,
      })
      dispatch(addMessage({ message: 'Email sent', severity: 'success' }))
    } catch (error) {
      dispatch(addMessage({ message: 'Error sending email', severity: 'error' }))
      Sentry.captureException(error)
    }
  }

  return (
    <>
      <Dialog data-testid="send-message-dialog" maxWidth="sm" id="send-message-dialog" open onClose={onClose}>
        <DialogTitle>Send message</DialogTitle>
        <DialogContent>
          <Stack gap={2} pt={1}>
            <TextField
              placeholder="email@example.co, another@example.co"
              label="Recipients"
              value={recipients}
              onChange={(e) => setRecipients(e.target.value)}
            />
            <TextField
              placeholder="Subject"
              label="Subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
            <TextField
              placeholder="Enter message (optional)"
              label="Message"
              value={message}
              multiline
              rows={5}
              onChange={(e) => setMessage(e.target.value)}
            />
            <Checkbox
              id="attach-pcf-checkbox"
              size="small"
              checked={attachmentSelected}
              onChange={() => {
                if (!attachmentSelected && !isThirdPartyProduct && pageView !== 'Published') {
                  setAttachmentNotificationOpen(true)
                }
                setAttachmentSelected(!attachmentSelected)
              }}
              label="Attach product carbon footprint report"
            />
            <Stack direction="row" alignItems="center" gap={1} ml={3.5} mt={-1.5}>
              <Icon icon={faInfoCircle} />
              <Text>Product carbon footprint reports contain ingredient information</Text>
            </Stack>
            {!isThirdPartyProduct && (
              <>
                <Checkbox
                  id="share-directly-checkbox"
                  size="small"
                  checked={shareSelected}
                  onChange={() => {
                    if (!shareSelected) {
                      setShareNotificationOpen(true)
                    }
                    setShareSelected(!shareSelected)
                  }}
                  label="Share product with the recipient's organization in Latis"
                />
                <Box ml={3.5}>
                  <TextField
                    id="share-directly-textfield"
                    disabled={!shareSelected}
                    label={shareSelected ? 'Enter organization name (required when sharing is selected)' : ''}
                    value={shareWith}
                    onChange={(e) => setShareWith(e.target.value)}
                    fullWidth
                    size="small"
                  />
                </Box>
              </>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button id="send-message-dialog-cancel-button" onClick={onClose}>
            Cancel
          </Button>
          <Button
            startIcon={faPaperPlane}
            id="send-message-dialog-send-button"
            color="primary"
            disabled={!recipients || (shareSelected && !shareWith)}
            onClick={handleSendClicked}
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>
      <ProductGhgImpactExport ref={productGhgImpactExportRef} pdfContentRef={pdfContentRef} />
      {attachmentNotificationOpen && (
        <AttachmentNotificationDialog onClose={() => setAttachmentNotificationOpen(false)} />
      )}
      {shareNotificationOpen && <ShareNotificationDialog onClose={() => setShareNotificationOpen(false)} />}
    </>
  )
}
