import React, { FC, useState } from 'react'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Text } from '@howgood/design'

import { deleteReport } from '@/state/organization'
import { ReportWithDates } from './ReportHistory'
import { useDispatch } from 'react-redux'
import { faTrashXmark } from '@fortawesome/pro-regular-svg-icons'

export interface DeleteReportCellProps extends GridRenderCellParams {
  value: ReportWithDates
}

export const DeleteReportCell: FC<DeleteReportCellProps> = ({ value }) => {
  const { id, name } = value

  const dispatch = useDispatch()

  const [dialogOpen, setDialogOpen] = useState(false)

  const handleDeleteReportConfirmed = async () => {
    dispatch(deleteReport(id))
    setDialogOpen(false)
  }

  return (
    <>
      <IconButton
        data-testid={`delete-report-${id}-button`}
        icon={faTrashXmark}
        color="error"
        onClick={() => setDialogOpen(true)}
      />
      {dialogOpen && (
        <Dialog id="delete-report-dialog" open onClose={() => setDialogOpen(false)}>
          <DialogTitle>Delete report?</DialogTitle>
          <DialogContent>
            <Text>{`This action cannot be reversed. If you delete this report, it will be gone forever. Are you sure you want to delete ${name}?`}</Text>
          </DialogContent>
          <DialogActions>
            <Button id="delete-cancel-button" onClick={() => setDialogOpen(false)}>
              Cancel
            </Button>
            <Button id="delete-ok-button" color="error" onClick={handleDeleteReportConfirmed}>
              Delete report forever
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}
