import React from 'react'
import { NodeRenderer } from '@contentful/rich-text-react-renderer'

export const LinkRenderer: NodeRenderer = (node, children) => {
  return (
    <a href={node.data.uri} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  )
}
