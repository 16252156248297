import React, { FC, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Box, ImpactBar as Bar, Text, Tooltip, Stack, theme } from '@howgood/design'
import { MetricEntry } from '@/contentfully'
import { getImpactScoreValue, transformScoreToZeroToTenScale } from '@/utils/impactScoreHelpers'
import { DRAFT, selectPageView } from '@/state/pageSettings'
import { ContentfulTooltip } from '../../ContentfulTooltip'
import { selectWarningText } from '@/state/recipe'

export interface ImpactBarProps {
  content: MetricEntry
  value: number
  previous: number
}

export const ImpactBar: FC<{ impact: ImpactBarProps }> = ({ impact }) => {
  const view = useSelector(selectPageView)
  const dataWarning = useSelector(selectWarningText)

  const [progress, setProgress] = useState(0)

  const { value, previous, content } = impact
  const score = transformScoreToZeroToTenScale(content.key, value)
  const compareTo = transformScoreToZeroToTenScale(content.key, previous)
  const delta = compareTo === null ? 0 : score - compareTo

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress(() => {
        return Math.min(score * 10, 100)
      })
    }, 100)

    return () => {
      clearInterval(timer)
    }
  }, [score])

  const formattedValue = getImpactScoreValue({ score: value, field: content.key, valuesMap: content.valuesMap })
  const tooltipContent = content.units ? `${formattedValue} ${content.units}` : formattedValue

  return (
    <Stack id={`${content.key}-bar-wrapper`} direction="row" display="flex" alignItems="center" height={40}>
      <Stack width="40%" px={0.75} mt={0.5}>
        <ContentfulTooltip content={content}>
          <Box sx={{ cursor: 'pointer' }}>
            <Text fontSize={14} lineHeight={1.2}>
              {content.title}
            </Text>
          </Box>
        </ContentfulTooltip>
        <Box height={12} />
      </Stack>
      <Stack width="50%">
        <Tooltip title={tooltipContent}>
          <Bar
            id={`${content.key}-bar`}
            color={dataWarning ? 'text.disabled' : theme.palette.impact[Math.round(score)]}
            value={progress}
          />
        </Tooltip>
        <Stack
          id={`${content.key}-overlay-wrapper`}
          position="relative"
          width="100%"
          height={12}
          sx={{ visibility: delta && previous && view === DRAFT ? 'visible' : 'hidden' }}
        >
          <Box
            id={`${content.key}-change-indicator`}
            position="absolute"
            top={-16}
            ml={`${compareTo * 10}%`}
            height={24}
            width={2}
            zIndex={1}
            borderRight="2px dashed gray"
          />
          <Box id={`${content.key}-change-value`} ml={`calc(${compareTo * 10}% - 18px)`} width={36}>
            <Text fontSize={12} textAlign={delta > 0 ? 'right' : 'left'}>
              {delta > 0 ? `+${delta}` : delta}
            </Text>
          </Box>
        </Stack>
      </Stack>
      <Stack width="10%">
        <Box id={`${content.key}-impact-score`} ml={1}>
          <Text color={dataWarning ? 'text.disabled' : 'text.primary'} fontSize={14}>
            {score}/10
          </Text>
        </Box>
        <Box height={12} />
      </Stack>
    </Stack>
  )
}
