import React, { FC, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  Grid,
  Accordion,
  AccordionDetails,
  Box,
  Stack,
  Text,
  Button,
  Select,
  MenuItem,
  SelectChangeEvent,
  InputLabel,
  FormControl,
} from '@howgood/design'

import { AccordionCardHeader } from '../AccordionCardHeader'
import { selectIsVendors, selectIsRollup } from '@/state/router'
import {
  initializeInitiativeDetail,
  selectAllVendorInitiativesByVendorId,
  selectChartMetricKey,
  selectCurrentInitiativeGoalMetrics,
  selectCurrentInitiativeId,
  selectCurrentInitiativeName,
  setChartMetric,
} from '@/state/initiatives'
import { ChartTable } from '@/features/Initiative/ChartPanel/ChartTable'
import { InitiativeChart } from '@/features/Initiative/ChartPanel/InitiativeChart'
import { useNavigate } from '@/hooks'
import { getCurrentVendorId } from '@/utils/getUrl'
import { useHistory } from 'react-router'

export const InitiativeChartPanel: FC<{ isFavorites?: boolean }> = ({ isFavorites }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { safelyNavigateTo } = useNavigate()
  const isRollup = useSelector(selectIsRollup)
  const goalMetrics = useSelector(selectCurrentInitiativeGoalMetrics)
  const chartMetric = useSelector(selectChartMetricKey)
  const currentInitiative = useSelector(selectCurrentInitiativeId)
  const initiativeName = useSelector(selectCurrentInitiativeName)
  const isVendor = useSelector(selectIsVendors)
  const vendorId = getCurrentVendorId()
  const vendorInitiatives = useSelector(selectAllVendorInitiativesByVendorId(vendorId || 0))

  useEffect(() => {
    if (isVendor && isRollup && goalMetrics.length > 0 && !chartMetric) {
      dispatch(setChartMetric(goalMetrics[0].value))
    }
  }, [chartMetric, goalMetrics, dispatch, isVendor, isRollup])

  const handleChangeInitiative = (e: SelectChangeEvent) => {
    if (e.target.value) {
      dispatch(initializeInitiativeDetail(+e.target.value, history))
    }
  }

  const handleGoToInitiative = useCallback(() => {
    safelyNavigateTo(`/initiatives/${currentInitiative}`)
  }, [safelyNavigateTo, currentInitiative])

  if (!isRollup || !isVendor || !vendorInitiatives.length) {
    return null
  }

  return (
    <Grid item lg={12} md={12} xs={12} sm={12}>
      <Accordion id="initiative-chart-panel" data-testid="initiative-chart-panel" defaultExpanded>
        <AccordionCardHeader
          id="initiative-chart-panel-header"
          cardKey="initiativeChart"
          title="Goal Progress"
          favoritesTab={isFavorites}
        />
        <AccordionDetails>
          <Stack gap={2}>
            {vendorInitiatives.length === 1 ? (
              <Box sx={{ '&:hover': { textDecoration: 'underline' } }}>
                <Link to={`/initiatives/${currentInitiative}`}>
                  <Text variant="h3">{initiativeName}</Text>
                </Link>
              </Box>
            ) : (
              <Stack width="60%" direction="row" gap={1} alignItems="center">
                <FormControl>
                  <InputLabel id="vendor-initiative-select-label">Initiatives</InputLabel>
                  <Select
                    id="vendor-initiative-select"
                    labelId="vendor-initiative-select-label"
                    data-testid="vendor-initiative-select"
                    value={currentInitiative}
                    onChange={handleChangeInitiative}
                    label="Initiatives"
                    autoWidth
                  >
                    {vendorInitiatives.map((initiative) => (
                      <MenuItem
                        key={initiative.id}
                        data-testid={`initiative-option-${initiative.id}`}
                        value={initiative.id}
                      >
                        {initiative.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            )}
            {goalMetrics.length === 0 ? (
              <Stack alignItems="start" gap={1}>
                <Button size="small" color="primary" onClick={handleGoToInitiative}>
                  Go to Initiative to set goals
                </Button>
              </Stack>
            ) : (
              <Stack direction="row" width="100%" height={276} gap={2}>
                <InitiativeChart />
                <Stack alignItems="end" gap={1}>
                  <ChartTable />
                  <Button size="small" color="primary" onClick={handleGoToInitiative}>
                    See Initiative
                  </Button>
                </Stack>
              </Stack>
            )}
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Grid>
  )
}
