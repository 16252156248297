import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { formatNumber } from '@howgood/utils'
import {
  PERMISSIONS_TO_FIELDS_MAP,
  VIEW_PRODUCT_IMPACT_CF_FLAG,
  VIEW_AGG_IMPACT_CF_FTM_GATE_CT_VERIFIED,
  VIEW_AGG_IMPACT_CF_CRADLE_TO_SHELF_CT_VERIFIED,
  VIEW_PRODUCT_IMPACT_CF_BIOGENIC_CRADLE_TO_SHELF,
  VIEW_PRODUCT_IMPACT_CF_BIOGENIC_FTM_GATE,
} from '@/constants/impactScore'
import { selectDisplayedImpactData } from '@/state/recipe'
import { useSortedContentfulMetrics } from '@/contentfully/hooks'

const GHG_FOOTPRINT_IMPACT_PERMISSIONS = [
  VIEW_AGG_IMPACT_CF_FTM_GATE_CT_VERIFIED,
  VIEW_PRODUCT_IMPACT_CF_BIOGENIC_CRADLE_TO_SHELF,
  VIEW_PRODUCT_IMPACT_CF_BIOGENIC_FTM_GATE,
  VIEW_AGG_IMPACT_CF_CRADLE_TO_SHELF_CT_VERIFIED,
  VIEW_PRODUCT_IMPACT_CF_FLAG,
]

interface GhgFootprintImpactRowProps {
  score: number
  title: string
  units: string
}

const GhgFootprintImpactRow: FC<GhgFootprintImpactRowProps> = ({ score, title, units }) => {
  const roundedScore = formatNumber(score)

  if (!title || score === null) return null

  return (
    <p data-testid="ghg-impact-row" style={{ marginBottom: 0 }}>
      {title}:{' '}
      <b>
        {roundedScore} {units}
      </b>
    </p>
  )
}

interface GhgFootprintImpactProps {
  fromSupplierDialog?: boolean
}

export const GhgFootprintImpact: FC<GhgFootprintImpactProps> = ({ fromSupplierDialog = false }) => {
  const impactData = useSelector(selectDisplayedImpactData)

  const fields = GHG_FOOTPRINT_IMPACT_PERMISSIONS.map((permission) => PERMISSIONS_TO_FIELDS_MAP[permission])
  const metrics = useSortedContentfulMetrics(fields)
  const productImpactData = impactData.product

  // SupplierConnect users see only the cradle-to-manufacturing-gate impact
  const availableMetrics = fromSupplierDialog
    ? metrics.filter((metric) => metric.key === 'cf_ftm_gate_ct_verified_impact')
    : metrics

  if (!metrics) return null

  return (
    <>
      <h3 data-testid="ghg-impact-title">GHG Footprint Impact Results</h3>
      {availableMetrics.map((metric) => (
        <GhgFootprintImpactRow
          key={metric.key}
          score={productImpactData[metric.key]}
          title={metric.subtitle?.replace('Carbon Lifecycle', '')}
          units={metric.units || ''}
        />
      ))}
    </>
  )
}
