import React, { FC } from 'react'
import { formatNumber } from '@howgood/utils'
import { Box } from './Box'
import { Stack } from './Stack'
import { Tooltip } from './Tooltip'
import { Text } from './Text'
import { theme } from './theme'

export type ColorValue = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10

interface Props {
  score: number
  scoreRange?: string
  cardKey: string
  size?: 'S' | 'M' | 'L' | 'XL'
  colorValue?: ColorValue
  disabled?: boolean
}

const sizeMap = {
  fontSize: {
    XL: 80,
    L: 64,
    M: 32,
    S: 20,
  },
  lineHeight: {
    XL: '66px',
    L: '54px',
    M: '32px',
    S: '20px',
  },
}

export const NumericScore: FC<Props> = ({
  score,
  scoreRange,
  cardKey,
  size = 'M',
  colorValue = null,
  disabled = false,
}) => {
  const roundedScore = scoreRange || formatNumber(score)
  const localeScore = score != null ? scoreRange || formatNumber(score, 4) : ''

  return (
    <Stack gap={0}>
      <Tooltip data-testid="score-tooltip" title={localeScore}>
        <Box id={`${cardKey}-score`}>
          <Text
            data-testid="score-text"
            fontSize={sizeMap.fontSize[size]}
            fontWeight={700}
            lineHeight={sizeMap.lineHeight[size]}
            color={
              disabled
                ? theme.palette.text.disabled
                : colorValue === null
                ? theme.palette.text.primary
                : theme.palette.impactTextLarge[colorValue]
            }
          >
            {score !== null && !isNaN(score) ? roundedScore : '- -'}
          </Text>
        </Box>
      </Tooltip>
    </Stack>
  )
}
