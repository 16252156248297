import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Grid } from '@howgood/design'

import { panelStyles } from '../panelStyles'
import { Standards } from './Standards'
import { Agribalyze } from './Agribalyze'
import { Status } from './Status'
import { selectIsSupplierConnect } from '@/state/organization'

export const General: FC = () => {
  const isSupplierConnect = useSelector(selectIsSupplierConnect)
  return (
    <Grid container columnSpacing={4} rowSpacing={1} {...panelStyles.grid}>
      <Grid item xs={12}>
        <Standards />
      </Grid>
      {!isSupplierConnect && (
        <>
          <Grid item xs={6}>
            <Agribalyze />
          </Grid>
          <Grid item xs={6}>
            <Status />
          </Grid>
        </>
      )}
    </Grid>
  )
}
