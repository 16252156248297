import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Box, Stack } from '@howgood/design'

import { MetricsGrid } from '@/components/GridWithMetrics/MetricsGrid'
import { useWorkspaceColumns, useWorkspaceRows } from '@/components/GridWithMetrics/useGridHooks'
import { Toolbar } from '@/features/PortfolioPage/components/Toolbar/Toolbar'
import { selectIsWorkspacesLoading } from '@/state/workspaces'
import { selectCurrentScenario, selectInitiativeWorkspaces, selectisScenarioLoading } from '@/state/initiatives'
import { DebugPanel } from '../DebugPanel'
import { selectDebugToolsSplit } from '@/state/splitio'
import { selectAllMetricGridOptions } from '@/selectors/selectAllWorkspaceGridOptions'
import { useScenarioScores } from '@/state/initiatives/initiatives.requests'
import { useParams } from 'react-router'

export const WorkspacesTab: FC = () => {
  const params: { initiative: string; scenario: string } = useParams()
  const scenario = useSelector(selectCurrentScenario)
  const options = useSelector(selectAllMetricGridOptions)
  const workspaces = useSelector(selectInitiativeWorkspaces)
  const isLoading = useSelector(selectisScenarioLoading)
  const isWorkspacesLoading = useSelector(selectIsWorkspacesLoading)
  const isDebugSplitEnabled = useSelector(selectDebugToolsSplit)
  const { scores, scoresLoading, customFields } = useScenarioScores(scenario?.id || +params.scenario)
  const columns = useWorkspaceColumns(scenario, customFields)
  const rows = useWorkspaceRows(workspaces, scores.workspaceScores)

  return (
    <Stack gap={1}>
      <Toolbar />
      <Box height="500px">
        <MetricsGrid
          rows={rows}
          columns={columns}
          options={options}
          loading={isWorkspacesLoading || scoresLoading || isLoading}
        />
      </Box>
      {isDebugSplitEnabled && <DebugPanel />}
    </Stack>
  )
}
