import React, { FC } from 'react'
import { Stack } from '@howgood/design'
import { MetricSelect } from './MetricSelect'
import { BasisSelect } from './BasisSelect'
import { WorkspaceSelect } from './WorkspaceSelect'
import { RefreshButton } from './RefreshButton'

export const Toolbar: FC = () => {
  return (
    <Stack direction="row" alignItems="center" gap={1}>
      <WorkspaceSelect />
      <MetricSelect />
      <BasisSelect />
      <RefreshButton />
    </Stack>
  )
}
