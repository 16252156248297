import React, { forwardRef } from 'react'
import MuiInputLabel, { InputLabelProps as MuiInputLabelProps } from '@mui/material/InputLabel'

interface InputLabelProps extends Omit<MuiInputLabelProps, 'className'> {
  ['aria-label']?: string
  ['data-pendo-id']?: string
  ['data-testid']?: string
}

export const InputLabel = forwardRef<HTMLLabelElement, InputLabelProps>(
  ({ ['aria-label']: ariaLabel, ['data-pendo-id']: dataPendoId, ['data-testid']: dataTestId, ...rest }, ref) => {
    return (
      <MuiInputLabel ref={ref} aria-label={ariaLabel} data-pendo-id={dataPendoId} data-testid={dataTestId} {...rest} />
    )
  }
)

InputLabel.displayName = 'InputLabel'
