import { ContentType } from './types'

type TypeKeys =
  | 'ATTRIBUTE'
  | 'CARBON_ACCOUNTING'
  | 'METRIC'
  | 'METHODOLOGY'
  | 'DATA_HEALTH_INDICATOR'
  | 'FORMULA_ICON'
  | 'DOCUMENT'
  | 'REQUEST_VENDOR_DATA'
  | 'NEWS'
  | 'TOOLTIP'
  | 'LINK'

export const CONTENT_TYPES: Record<TypeKeys, ContentType> = {
  ATTRIBUTE: 'attribute',
  CARBON_ACCOUNTING: 'carbonAccounting',
  METRIC: 'metric',
  REQUEST_VENDOR_DATA: 'requestVendorData',
  METHODOLOGY: 'methodology',
  DATA_HEALTH_INDICATOR: 'dataHealthIndicator',
  FORMULA_ICON: 'formulaIcon',
  DOCUMENT: 'document',
  NEWS: 'news',
  TOOLTIP: 'tooltip',
  LINK: 'link',
}
