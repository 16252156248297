import React from 'react'
import { Tooltip, Text } from '@howgood/design'
import {
  AssigneeRenderer,
  TagListRenderer,
  MtPerYearRenderer,
  CopyLinkRenderer,
  PricePerMtRenderer,
  IngredientAndSourceRenderer,
  VendorRenderer,
  ReportDateRenderer,
  ReportCreatedRenderer,
  ShareStatusRenderer,
  ValidationStatusRenderer,
  RoundedValueRenderer,
  ProductsGridColDef,
} from '@/components/GridCellRenderers'
import { FORMULATION_TAGS } from '@/constants/common'
import { OrgUser } from '@/state/organization'

/**
 * A list of the column definitions for the non-metric/impact score columns in the product list.
 */
export const allNonMetricColumns: ProductsGridColDef[] = [
  {
    headerName: 'UPC/GTIN',
    field: 'upc',
    sortfield: 'upc.keyword',
  },
  {
    headerName: 'MT/Year',
    field: 'mtPerYear',
    sortfield: 'mt_per_year',
    renderCell: MtPerYearRenderer,
  },
  {
    headerName: 'Vendor',
    field: 'brand',
    sortfield: 'brand.name.keyword',
    renderCell: VendorRenderer,
  },
  {
    headerName: 'Workspaces',
    field: 'workspaceLabel',
    sortfield: 'workspaces.name.keyword',
  },
  {
    headerName: 'Certifications',
    field: 'certifications',
    // sortfield: 'standards.title.keyword',
    sortable: false,
    minWidth: 110,
    flex: 1,
    renderCell: (params) => (
      <Tooltip disableInteractive title={params.value}>
        <Text textOverflow="ellipsis" fontSize={14} lineHeight="16px" noWrap>
          {params.value}
        </Text>
      </Tooltip>
    ),
  },

  {
    headerName: 'Material ID',
    field: 'internalId',
    sortable: false, // currently fields on inventories are not sortable
  },
  {
    headerName: 'Production Location',
    field: 'productionLocation',
    sortfield: 'region.name.keyword',
  },
  {
    headerName: 'Price/mt',
    field: 'pricePerMt',
    sortable: false, // currently fields on inventories are not sortable
    // sortfield: 'inventories.price_per_mt',
    renderCell: PricePerMtRenderer,
  },
  {
    headerName: 'Packaging',
    field: 'packagingDescription',
    sortable: false, // currently fields on inventories are not sortable
    // sortfield: 'inventories.packaging_description.keyword',
  },
  {
    headerName: 'Buyer',
    field: 'buyer',
    // sortfield: 'inventories.buyer_name.keyword',
    valueFormatter: (value) => value ?? '',
    sortable: false, // currently fields on inventories are not sortable
  },
  {
    headerName: 'Ingredients',
    field: 'ingredients',
    sortable: false,
    renderCell: IngredientAndSourceRenderer,
    minWidth: 150,
    flex: 2,
  },
  {
    headerName: 'Sourcing Locations',
    field: 'sourcing_locations',
    sortable: false,
    renderCell: IngredientAndSourceRenderer,
    minWidth: 150,
    flex: 2,
  },
  {
    headerName: 'Report Created',
    field: 'locked_claims_timestamp',
    valueFormatter: (value) => !!value,
    renderCell: ReportCreatedRenderer,
  },
  {
    headerName: 'Report Date',
    field: 'reports',
    sortfield: 'locked_claims_timestamp',
    sortable: false,
    renderCell: ReportDateRenderer,
  },
  {
    headerName: 'Shared',
    field: 'sharing_requests',
    sortable: false,
    renderCell: ShareStatusRenderer,
  },
  {
    headerName: 'Validation',
    field: 'validation_request',
    sortable: false,
    renderCell: ValidationStatusRenderer,
  },
  {
    headerName: 'Status',
    field: 'statusLabel',
    sortfield: 'formulation_status.keyword',
  },
  {
    headerName: 'Tags',
    field: FORMULATION_TAGS,
    sortfield: 'inventories.formulation_tags.keyword',
    renderCell: TagListRenderer,
    minWidth: 80,
  },
  {
    headerName: 'Unit Size (kg)',
    field: 'weight_kg',
    valueFormatter: (value) => value ?? '--',
  },
  {
    headerName: 'Units Sold (annual)',
    field: 'annual_sales_volume',
    valueFormatter: (value) => value ?? '--',
    renderCell: (params) => (
      <Tooltip disableInteractive title={params.value}>
        <RoundedValueRenderer dataTestId={`annual_sales_volume-cell-${params.id}`} value={params.value} />
      </Tooltip>
    ),
  },
  {
    headerName: 'Link',
    field: 'link',
    sortable: false,
    renderCell: CopyLinkRenderer,
  },
  {
    headerName: 'Assignee',
    field: 'assignee',
    sortfield: 'assignee.email.keyword',
    renderCell: AssigneeRenderer,
    sortComparator: (a: OrgUser, b: OrgUser) => (a?.email || '').localeCompare(b?.email || ''),
  },
]
