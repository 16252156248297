import { v4 as uuidv4 } from 'uuid'
import isEqual from 'lodash/isEqual'
import { ImpactScoreResponse, IngredientsResponse, Ingredient, ImpactScore, InventoriesResponse } from '@/api'
import {
  Recipe,
  RecipeIngredient,
  RecipeNestedIngredient,
  ImpactData,
  PackagingResponse,
  ESRecipeIngredientSource,
  ESRecipeNestedIngredientSource,
  IngredientStandard,
} from './recipe.state'
import { ColdStorageType, ProductInfo } from '../productOverview/productOverview.state'
import { loadIngredients } from './recipe.requests'
import { PERCENTAGES, ProductRouteLegs, WeightOption, Workspace } from '@/records'
import { SCOPE_3_CATEGORY_1_FIELD, SCOPE_3_CATEGORY_4_FIELD } from '@/constants/impactScore'
import { scope3Cat1Metric, scope3Cat4Metric } from '@/api/elastic/runtimeMappings'

const generateId = (additional: string = '') => {
  return `${additional}${uuidv4()}`
}

interface ExtendedIngredient extends Ingredient {
  index: number
  input_weight_unit: WeightOption
}

// Converts an extended non-nested ElasticSearch ingredient into an internal RecipeIngredient
const transformSimpleIngredient = (
  ingredient: ExtendedIngredient,
  availableStandards: IngredientStandard[]
): RecipeIngredient => {
  return {
    id: generateId(`${ingredient.id}`),
    product_ingredient_id: ingredient.id,
    ingredient_id: ingredient.ingredient?.id,
    base_id: ingredient.ingredient?.base?.id,
    name: ingredient.ingredient?.base?.name,
    position: ingredient.position,
    flat_position: ingredient.flat_position,
    dot_path: ingredient.flat_position?.join('.'),
    isTopLevel: ingredient.flat_position?.length === 1,
    index: ingredient.index,
    origin_location_id: ingredient.ingredient?.origin_location?.id,
    origin_location_name: ingredient.ingredient?.origin_location?.region?.name,
    processing_location_id: ingredient.processing_location?.id,
    processing_location_name: ingredient.processing_location?.name,
    standards: ingredient.standards,
    weight: +ingredient.weight,
    flat_weight: +ingredient.flat_weight,
    isAutoWeight: false,
    is_foreign: ingredient.is_foreign,
    availableStandards: availableStandards,
    product: ingredient.product,
    input_weight_unit: ingredient.input_weight_unit,
    route_legs: ingredient.route_legs,
  }
}

// Converts an extended ElasticSearch ingredient into an internal RecipeIngredient
export const transformIngredient = (ingredient: ExtendedIngredient, availableStandards: IngredientStandard[] = []) => {
  if (ingredient.nested_product) {
    return {
      id: generateId(`${ingredient.id}`),
      product_ingredient_id: ingredient.id,
      name: ingredient.nested_product.name,
      position: ingredient.position,
      flat_position: ingredient.flat_position,
      dot_path: ingredient.flat_position?.join('.'),
      isTopLevel: ingredient.flat_position?.length === 1,
      index: ingredient.index,
      nested_product_id: ingredient.nested_product.id,
      weight: +ingredient.weight,
      flat_weight: +ingredient.flat_weight,
      standards: ingredient.standards,
      isAutoWeight: false,
      is_foreign: ingredient.is_foreign,
      vendorName: ingredient.nested_product.brand,
      workspacesIds: ingredient.nested_product.workspaces,
      locked_claims_timestamp: ingredient.nested_product.locked_claims_timestamp,
      workflow_tags: ingredient.nested_product.workflow_tags,
      product: ingredient.product,
      input_weight_unit: ingredient.nested_product.input_weight_unit,
      material_types: ingredient.nested_product.material_types,
    } as RecipeNestedIngredient
  }
  return transformSimpleIngredient(ingredient, availableStandards)
}

interface RecipeInputs {
  product: ProductInfo
  ingredients: IngredientsResponse
  packaging: PackagingResponse
  transportation: ProductRouteLegs
  availableStandards: IngredientStandard[][]
}

export const generateProductRecipeFields = (product: ProductInfo) => {
  return {
    org: product.org,
    sales_distribution: {
      weight_kg: +product.weight_kg,
      annual_sales_volume: product.annual_sales_volume,
      requires_cooking: product.requires_cooking,
      requires_cold_storage: product.requires_cold_storage || ('' as ColdStorageType),
      manufacturing_type: {
        title: product.manufacturing_type?.title,
        id: product.manufacturing_type?.id,
        value: product.manufacturing_type?.value,
      },
      manufacturing_region: { id: product.region?.id, name: product.region?.name },
      retail_region: { id: product.retail_region?.id, name: product.retail_region?.name },
      storage_region: { id: product.storage_region?.id, name: product.storage_region?.name },
    },
    sales_potential: product.sales_potential_impact,
    simple: product.simple,
    standards: product.standards,
    agribalyze_category: product.agribalyze_category,
    annual_sales_volume: product.annual_sales_volume || 0,
    craveability: product.craveability,
    input_weight_unit: product.input_weight_unit,
    temperature: product.temperature,
  }
}

export const generateRecipe = ({
  product,
  ingredients,
  packaging,
  transportation,
  availableStandards,
}: RecipeInputs): Recipe => {
  const transformedIngredients = ingredients.map((ingredient, index) => {
    // If it's a child ingredient, find its parent so we can add the correct input_weight_unit to the ingredient
    const parent =
      ingredient.flat_position.length > 1
        ? ingredients.find((item) => item.nested_product?.id === ingredient.product_id)
        : null
    const inputWeightUnit = parent?.nested_product?.input_weight_unit
    return transformIngredient({ ...ingredient, index, input_weight_unit: inputWeightUnit }, availableStandards[index])
  })
  const recipe = {
    ingredients: transformedIngredients,
    ...generateProductRecipeFields(product),
    packaging: packaging.map((item) => ({
      id: item.id,
      packaging_unit: item.packaging_unit,
      material: { id: item.material.id, name: item.material.name },
      shape: { id: item.shape.id, name: item.shape.name },
      consumer_units: item.consumer_units,
      region: { id: item.region?.id, name: item.region?.name },
      packaging_material_weight: item.packaging_material_weight,
      packaging_uses: item.packaging_uses,
    })),
    transportation: { mtr: transportation.mtr.map((item) => ({ ...item, id: generateId() })), mts: [] },
    timestamp: new Date().toString(),
  }

  return recipe
}

export const generateSimpleIngredient = (
  source: ESRecipeIngredientSource,
  position: number,
  index: number,
  weight: number,
  product: { id: number; name: string },
  isAutoWeight: boolean,
  inputWeightUnit: WeightOption
): RecipeIngredient => {
  const defaultOrigin = source.default_origin_location.id
  const defaultIngredient = source.ingredients.find((ing) => ing.origin_location_id === defaultOrigin)
  return {
    id: generateId(`${source.id}${defaultOrigin}${index}`), // unique id used for FE only
    product_ingredient_id: null,
    ingredient_id: defaultIngredient?.id || null,
    base_id: source.id,
    name: source.name,
    position: position,
    flat_position: [position],
    dot_path: [position].join('.'),
    isTopLevel: true,
    index: index,
    origin_location_id: defaultOrigin,
    origin_location_name: source.default_origin_location.region_name,
    processing_location_id: null,
    processing_location_name: null,
    weight: weight,
    flat_weight: weight,
    input_weight_unit: inputWeightUnit,
    isAutoWeight: isAutoWeight,
    is_foreign: false,
    standards: [], // These get added later
    availableStandards: [], // These also get added later
    product,
  }
}

// Returns true if the user does not have read or write access to the product's workspace
const checkForeignProduct = (toCheck: { workspaces?: Workspace[] }, userWorkspaces: Workspace[]) => {
  return (
    toCheck?.workspaces &&
    !toCheck.workspaces.some((pws) => userWorkspaces.some((ws) => pws.id === ws.id && (ws.edit || ws.read)))
  )
}

export const generateProductIngredientList = async (
  source: ESRecipeNestedIngredientSource,
  position: number,
  index: number,
  workspaces: Workspace[],
  weight: number,
  product: { id: number; name: string },
  isAutoWeight: boolean,
  inputWeightUnit: WeightOption
) => {
  // pk doesn't exist when loading nested product based on url search param
  const nestedProductId = source.pk || source.id
  const isThirdParty = checkForeignProduct({ workspaces: source.workspaces }, workspaces)

  // Get all the data for the nested ingredients
  const ingredients = await loadIngredients(nestedProductId, isThirdParty)

  const topIngredient = {
    id: generateId(`${nestedProductId}${index}`), // unique id used for FE only
    product_ingredient_id: null,
    name: source.name,
    position,
    flat_position: [position],
    dot_path: `${position}`,
    isTopLevel: true,
    index: index,
    nested_product_id: nestedProductId,
    weight: weight,
    flat_weight: weight,
    isAutoWeight: inputWeightUnit === PERCENTAGES ? isAutoWeight : false,
    input_weight_unit: inputWeightUnit,
    is_foreign: isThirdParty,
    workspacesIds: source.workspaces.map((w) => w.id),
    locked_claims_timestamp: source.locked_claims_timestamp,
    workflow_tags: source.workflow_tags,
    vendorName: source.brand?.name,
    product,
    material_types: source.material_types,
  } as RecipeNestedIngredient

  const nestedIngredients: (RecipeIngredient | RecipeNestedIngredient)[] = ingredients.map((ingredient, i) => {
    const extendedIngredient: ExtendedIngredient = {
      ...ingredient,
      flat_position: [position, ...ingredient.flat_position],
      index: index + i + 1,
      input_weight_unit: source.input_weight_unit, // Child ingredients inherit the parent's input_weight_unit
    }

    return transformIngredient(extendedIngredient)
  })
  return [topIngredient, ...nestedIngredients]
}

// Within the ingredients grid, need the max value in each column so we can round values according to the range
// Create a lookup the cell renderers can use to round the cell value as needed (3 decimals if all < 1; 2 otherwise)
export const getMaxImpactValues = (impactData: ImpactScoreResponse | ImpactData) => {
  const maxValues = impactData.ingredients.reduce((acc: ImpactScore, ingredient) => {
    Object.entries(ingredient).forEach(([impact, value]) => {
      if (value && (!(impact in acc) || (impact in acc && value > acc[impact]))) {
        acc[impact] = value
      }
    })
    return acc
  }, {})
  return maxValues
}

// Get list of names of impact fields with non-null values
export const getNonNullFields = (impactData: { product: ImpactScore }): string[] => {
  return Object.entries(impactData?.product || {}).reduce((acc, [key, value]) => {
    if (key === 'labor_risk_information') {
      // because of for impact overriden products all metrics are null except `cradle to manufacturing gate`, we should check `value?.length` instead of `value.length !== 0`
      return value?.length ? [...acc, key] : acc
    }
    return value !== null ? [...acc, key] : acc
  }, [])
}

export const isImpactScoreNull = (impactScore: ImpactScore) => {
  const nonNullFields = getNonNullFields({ product: impactScore })
  return nonNullFields.length === 0
}

export const getParentDotPath = (ingredient: RecipeIngredient | RecipeNestedIngredient) => {
  return ingredient.flat_position.length > 1 ? ingredient.flat_position.slice(0, -1).join('.') : 'ROOT'
}

export const addIngredientsToList = (
  ingredientsToAdd: (RecipeIngredient | RecipeNestedIngredient)[],
  ingredientList: (RecipeIngredient | RecipeNestedIngredient)[],
  insertIndex: number
) => {
  // Update index values as needed, add new item(s) to ingredients list, then sort by index
  return [
    ...ingredientList.map((ingredient) =>
      ingredient.index >= insertIndex
        ? { ...ingredient, index: ingredient.index + ingredientsToAdd.length }
        : ingredient
    ),
    ...ingredientsToAdd,
  ].sort((a, b) => a.index - b.index)
}

export const removeIngredientFromList = (
  ingredientToRemove: RecipeIngredient | RecipeNestedIngredient,
  ingredientList: (RecipeIngredient | RecipeNestedIngredient)[],
  reIndexOnly: boolean = false
) => {
  // Update index values as needed, remove item(s) from ingredients list
  return ingredientList
    .filter((item) => item.flat_position[0] !== ingredientToRemove.position)
    .map((item, newIndex) => {
      if (item.index > ingredientToRemove.index) {
        if (item.isTopLevel) {
          const newPosition = item.position - 1
          return {
            ...item,
            index: newIndex,
            ...(reIndexOnly
              ? {}
              : {
                  position: newPosition,
                  flat_position: [newPosition],
                  dot_path: `${newPosition}`,
                }),
          }
        } else {
          // For child ingredients, only the first item in the `flat_position` array changes
          const newFlatPosition = [item.flat_position[0] - 1, ...item.flat_position.slice(1)]
          return {
            ...item,
            index: newIndex,
            ...(reIndexOnly
              ? {}
              : {
                  flat_position: newFlatPosition,
                  dot_path: newFlatPosition.join('.'),
                }),
          }
        }
      }
      return item
    })
}

export const checkIfSibling = (
  ingredient1: RecipeIngredient | RecipeNestedIngredient,
  ingredient2: RecipeIngredient | RecipeNestedIngredient
) => {
  return isEqual(ingredient1.flat_position.slice(0, -1), ingredient2.flat_position.slice(0, -1))
}

export const checkIfChild = (
  parent: RecipeIngredient | RecipeNestedIngredient,
  child: RecipeIngredient | RecipeNestedIngredient
) => {
  return parent.flat_position.every((parentPosition, idx) => parentPosition === child.flat_position[idx])
}

export const getAllChildren = (
  parent: RecipeIngredient | RecipeNestedIngredient,
  ingredientList: (RecipeIngredient | RecipeNestedIngredient)[]
) => {
  return ingredientList.filter((ingredient) => {
    if (ingredient.id === parent.id) {
      return false
    }
    return checkIfChild(parent, ingredient)
  })
}

export const addScope3ImpactData = (impactScore: ImpactScore, inventories: InventoriesResponse[]) => {
  const mtPerYr = inventories.reduce((acc, inventory) => {
    if (inventory.mt_per_year) {
      return acc + inventory.mt_per_year
    }
    return acc
  }, 0)
  if (mtPerYr === 0) {
    return impactScore
  }
  return {
    ...impactScore,
    [SCOPE_3_CATEGORY_1_FIELD]: impactScore[scope3Cat1Metric] ? impactScore[scope3Cat1Metric] * mtPerYr : null,
    [SCOPE_3_CATEGORY_4_FIELD]: impactScore[scope3Cat4Metric] ? impactScore[scope3Cat4Metric] * mtPerYr : null,
  }
}
