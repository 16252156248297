import React, { FC } from 'react'
import { Alert, AlertTitle, Icon, Box } from '@howgood/design'
import { faPlus } from '@fortawesome/pro-regular-svg-icons'

export const VendorVerifiedPlusAlert: FC = () => {
  return (
    <Box marginTop={2}>
      <Alert
        severity="success"
        iconMapping={{
          success: <Icon color="success.dark" size="xs" icon={faPlus} />,
        }}
      >
        <AlertTitle>Contains agricultural practices data.</AlertTitle>
      </Alert>
    </Box>
  )
}
