import React, { forwardRef } from 'react'
import MuiSwitch, { SwitchProps as MuiSwitchProps } from '@mui/material/Switch'

interface SwitchProps extends Pick<MuiSwitchProps, 'id' | 'name' | 'size' | 'checked' | 'onChange'> {
  ['data-testid']?: string
}

export const Switch = forwardRef<HTMLButtonElement, SwitchProps>(
  ({ id, name, size = 'small', checked, onChange, ['data-testid']: dataTestId, ...rest }, ref) => {
    return (
      <MuiSwitch
        ref={ref}
        id={id}
        data-testid={dataTestId}
        name={name}
        size={size}
        checked={checked}
        onChange={onChange}
        {...rest}
      />
    )
  }
)

Switch.displayName = 'Switch'
