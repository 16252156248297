import React, { FC } from 'react'
import { ScoreBadge, Stack } from '@howgood/design'

import { ProductListRenderCellParams } from './ProductGridTypes'
import { Field } from '@/constants/impactScore'
import { getColorForMetric } from '@/utils/impactScoreHelpers'
import { ProductGoalRenderer } from './ProductGoalRenderer'

interface Props extends ProductListRenderCellParams {
  value?: number
}

export const ImpactScoreRenderer: FC<Props> = ({ value, colDef, id }) => {
  const field = colDef.field as Field
  const score = typeof value === 'number' ? Math.round(value) : null
  const scoreFormatted = typeof score === 'number' ? score : null

  return (
    <Stack data-testid={`impact-metric-${field}-${id}`} direction="row" alignItems="center" gap={1}>
      <ScoreBadge bgcolor={getColorForMetric(field, score)} score={scoreFormatted} />
      <ProductGoalRenderer productId={id} field={field} />
    </Stack>
  )
}
