import useSWR from 'swr'
import { fetchProductIsStale, ProductIsStaleResponse } from './StaleProductAlert.requests'

interface UseProductIsStale {
  data: ProductIsStaleResponse
}

export function useProductIsStale(productId: number): UseProductIsStale {
  const key = productId ? `${productId}` : null
  const { data } = useSWR(key, fetchProductIsStale)

  return { data }
}
