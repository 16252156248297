import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'
import scoreA from '../../../../assets/images/nutriscore/nutriscore-full-A.png'
import scoreB from '../../../../assets/images/nutriscore/nutriscore-full-B.png'
import scoreC from '../../../../assets/images/nutriscore/nutriscore-full-C.png'
import scoreD from '../../../../assets/images/nutriscore/nutriscore-full-D.png'
import scoreE from '../../../../assets/images/nutriscore/nutriscore-full-E.png'

interface Props {
  score: string
}

const scores = {
  A: scoreA,
  B: scoreB,
  C: scoreC,
  D: scoreD,
  E: scoreE,
}

const useStyles = createUseStyles({
  image: {
    width: 120,
  },
})

export const NutriScoreImageWrapped: FC<Props> = ({ score }) => {
  const classes = useStyles()

  if (!['A', 'B', 'C', 'D', 'E'].includes(score)) {
    return null
  }

  return <img width="102px" className={classes.image} src={scores[score]} alt={score} />
}
