import { matchPath } from 'react-router'
import { createSelector } from 'reselect'
import { AppState } from '@/store'
import { ProductListType } from '@/components/Products/utils/productListColDefs'
import { ReportType } from '../organization'

const routes = [
  {
    path: '/',
    exact: true,
    getTitle: () => 'Home',
  },
  { path: '/login', getTitle: () => 'Login' },
  { path: '/products', getTitle: () => 'Products' },
  { path: '/procurement', getTitle: () => 'Procurement' },
  { path: '/materials', getTitle: () => 'Materials' },
  { path: '/initiatives', getTitle: () => 'Initiatives' },
  { path: '/portfolio', getTitle: () => 'Portfolio Analysis' },
  { path: '/company', getTitle: () => 'Company Impact' },
]

const selectMatch = (state: AppState) => {
  let match = ''
  for (let route of routes) {
    if (
      matchPath(state.router?.location?.pathname, {
        path: route.path,
        exact: route.exact,
      })
    ) {
      match = route.getTitle()
      break
    }
  }
  return match
}

export const selectTitle = createSelector(selectMatch, (match): string => match || 'Formulation Impact Tool')

export const selectPath = (state: AppState) => state.router.location.pathname
const selectSearch = (state: AppState) => state.router.location.search

export const selectIsRollup = createSelector(selectPath, (path): boolean => {
  return !path.includes('/formulas') && !path.includes('/products/') && !path.includes('/materials/')
})

export const selectIsProducts = createSelector(selectPath, selectIsRollup, (path, isRollup): boolean => {
  return path.includes('/products') && isRollup
})

export const selectIsPortfolio = createSelector(selectPath, (path): boolean => {
  return path.includes('/portfolio') || path.includes('/company')
})

export const selectIsVendors = createSelector(selectPath, (path): boolean => {
  return path.includes('/vendor-management') || path.includes('/vendors')
})

export const selectIsInitiative = createSelector(
  selectPath,
  selectSearch,
  selectIsRollup,
  (path, search, isRollup): boolean => {
    // in the initiative view, or scenario context of formula profile
    return path.includes('/initiatives') || (isRollup && search.includes('scenario'))
  }
)

export const selectIsScenario = createSelector(selectPath, (path) => path.includes('/scenarios'))

export const selectIsImpact = createSelector(selectPath, (path): boolean => {
  return path.includes('/impact')
})

export const selectIsProcurement = createSelector(selectPath, (path): boolean => {
  return path.includes('/procurement') || path.includes('/materials')
})

export const selectPageType = createSelector(
  selectIsProcurement,
  selectIsInitiative,
  selectIsVendors,
  (isProcurement, isInitiative, isVendor): ProductListType => {
    return isProcurement ? 'procurement' : isInitiative ? 'initiative' : isVendor ? 'vendor' : 'formulation'
  }
)

export const selectReportType = createSelector(
  selectIsProcurement,
  (isProcurement): ReportType => {
    return isProcurement ? 'Procurement' : 'Formulation'
  }
)
