import { FormulationTag } from '@/api'
import { createSlice } from '@reduxjs/toolkit'
import { getFormulationTags } from './formulationTags.thunk'

export interface State {
  tags: FormulationTag[]
  isLoading: boolean
}
export const initialState: State = {
  tags: [],
  isLoading: false,
}

const formulationTagsSlice = createSlice({
  name: 'formulationTags',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFormulationTags.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getFormulationTags.fulfilled, (state, action) => {
      state.tags = action.payload
      state.isLoading = false
    })
    builder.addCase(getFormulationTags.rejected, (state, action) => {
      console.error(action.error)
      state.isLoading = false
    })
  },
})

export const formulationTagsReducer = formulationTagsSlice.reducer
