import { useEffect, useMemo } from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import {
  getCarbonAccountingReportActivities,
  getCarbonAccountingReports,
  getEmissionsActivites,
  selectCarbonAccountingEmissionsActivities,
  selectCarbonAccountingReportActivities,
  selectCarbonAccountingReportById,
} from '@/state/carbonAccounting'
import { selectContentfulCarbonAccountingData } from '@/state/contentfulData'
import { CategoryWithScore } from './CarbonAccounting.types'
import { selectIsSupplierConnect } from '@/state/organization'

const FLAG_EMISSIONS_ACTIVITY_ID = 4
const PROCURED_FOOD_MATERIALS_ID = 5
const UPSTREAM_TRANSPORTATION_ID = 6

// Warning text added to backend response for activities that require users to locate a value within Latis
const activitiesWithWarningText = [FLAG_EMISSIONS_ACTIVITY_ID, PROCURED_FOOD_MATERIALS_ID, UPSTREAM_TRANSPORTATION_ID]

export function useFlagEmissionsTotal() {
  const reportActivities = useSelector(selectCarbonAccountingReportActivities)

  return useMemo(
    () => reportActivities.find((activity) => activity.activity.id === FLAG_EMISSIONS_ACTIVITY_ID)?.activity_value,
    [reportActivities]
  )
}

export function useCarbonAccountingCategories(reportId: number) {
  const emissionsActivities = useSelector(selectCarbonAccountingEmissionsActivities)
  const carbonAccountingCategories = useSelector(selectContentfulCarbonAccountingData)
  const report = useSelector(selectCarbonAccountingReportById(reportId))

  return useMemo<CategoryWithScore[]>(() => {
    if (!report) {
      return []
    }
    return (
      Object.entries(report.scope_categories)
        .map(([key, category]) => {
          const contentfulCategory = carbonAccountingCategories.find((a) => a.key === key)
          // Activity scope_category is in all caps, e.g., SCOPE_3_CATEGORY_1
          const activities = emissionsActivities
            .filter((activity) => activity.scope_category.toLowerCase() === key)
            .map((activity) => ({
              ...activity,
              warning: activitiesWithWarningText.includes(activity.id)
                ? "Latis-tracked Materials values may change over time. Be sure to check that you're using the most up-to-date data."
                : null,
            }))
          const clickable = activities.length > 0
          if (!contentfulCategory) return null
          return {
            ...contentfulCategory,
            ...category,
            activities,
            clickable,
          }
        })
        // Account for any missing entries in Contentful
        .filter((category) => Boolean(category))
    )
  }, [report, carbonAccountingCategories, emissionsActivities])
}

export function useCarbonAccountingReports(reportYear?: number) {
  const dispatch = useDispatch()
  const isSupplierConnect = useSelector(selectIsSupplierConnect)

  useEffect(() => {
    batch(() => {
      if (!isSupplierConnect) {
        dispatch(getEmissionsActivites(reportYear))
      }
      dispatch(getCarbonAccountingReports())
    })
  }, [reportYear, dispatch, isSupplierConnect])
}

/**
 * Fetches all emissions activities, carbon accounting reports, and report activity values.
 *
 * @param reportId which report activity values should be fetched
 */
export function useCarbonAccountingData(reportId: number, reportYear: number) {
  const dispatch = useDispatch()
  useCarbonAccountingReports(reportYear)

  useEffect(() => {
    if (reportId) {
      dispatch(getCarbonAccountingReportActivities(reportId))
    }
  }, [reportId, dispatch])
}
