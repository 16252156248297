import { CustomerType } from '@/records'
import { Goals } from '@/records/Workspace'

export type MaterialType = 'purchased' | 'howgood' | 'vendor_verified' | 'vendor_verified_plus'

export const MATERIAL_TYPE_PURCHASED = 'purchased' as MaterialType
export const MATERIAL_TYPE_HOWGOOD = 'howgood' as MaterialType
export const MATERIAL_TYPE_VENDOR_VERIFIED = 'vendor_verified' as MaterialType
export const MATERIAL_TYPE_VENDOR_VERIFIED_PLUS = 'vendor_verified_plus' as MaterialType

export type ValidationRequestStatus =
  | 'Not Started'
  | 'Validation Requested'
  | 'Validation Confirmed'
  | 'Validation Denied'

export const NOT_STARTED = 'Not Started' as ValidationRequestStatus
export const REQUESTED = 'Validation Requested' as ValidationRequestStatus
export const CONFIRMED = 'Validation Confirmed' as ValidationRequestStatus
export const DENIED = 'Validation Denied' as ValidationRequestStatus

export type ShareRequestStatus = 'Share Not Started' | 'Share Requested' | 'Share Confirmed' | 'Share Denied'

export const SHARE_NOT_STARTED = 'Share Not Started' as ShareRequestStatus
export const SHARE_REQUESTED = 'Share Requested' as ShareRequestStatus
export const SHARE_CONFIRMED = 'Share Confirmed' as ShareRequestStatus
export const SHARE_DENIED = 'Share Denied' as ShareRequestStatus

export type ValidationRequest = {
  status: ValidationRequestStatus
  description: string
  date_created: string
  date_modified: string
}

export type FormulationTag = {
  date_created?: string
  date_modified?: string
  id?: number
  name: string
}

export interface InventoriesResponse {
  id: number
  date_created?: string
  date_modified?: string
  customer_type?: CustomerType
  internal_id: string
  buyer_name?: string
  buyer_role?: string
  notes?: string
  packaging_description?: string
  mt_per_year: number
  price_per_mt?: number
  producer_metadata?: {}
  workflow_editor?: number
  product?: { id: number }
  workspace: { id: number }
  formulation_tags: FormulationTag[]
  validation_requests: ValidationRequest[]
  goals: Goals
  workspace_id?: number // DOES NOT EXIST, but need for old procurement snapshot stuff
}
