import React, { FC } from 'react'
import { Box } from '@howgood/design'

import { ProductListRenderCellParams } from './ProductGridTypes'
import { HeatmapTableCell } from '../Layout'
import { getSDGScore } from '@/utils/products'
import { Field } from '@/constants/impactScore'

interface Props extends ProductListRenderCellParams {
  value?: number
}

export const SDGRenderer: FC<Props> = ({ value, colDef, id }) => {
  const field = colDef.field as Field
  const { score, label } = getSDGScore(value)

  return (
    <Box data-testid={`sdg-${field}-${id}`} p={0.25} width="100%" height="100%">
      <HeatmapTableCell label={label} score={score} field={field} hideTooltip />
    </Box>
  )
}
