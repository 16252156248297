import React, { FC, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import useSWR from 'swr'
import { Autocomplete, MenuItem, Stack, Tab, Tabs, Text, TextField, Tooltip } from '@howgood/design'
import { IngredientOption } from '@/components'
import {
  Ingredient,
  IngredientSearchOption,
  defaultFormulationStatusesForAnalytics,
  ElasticsearchIngredientHit,
  WorkspaceType,
  isDefaultWorkspace,
} from '@/records'
import { selectCanViewPublicIngredients } from '@/state/user'
import { selectDefaultWorkspaceIds, selectWorkspacesIds } from '@/state/workspaces'
import { normalizeIngredientName } from '@/utils/ingredients'
import {
  fetchIngredients,
  FetchIngredientsParams,
  fetchProductIngredients,
  FetchProductIngredientsParams,
} from '@/utils/ingredientSearch'
import { selectSplitTreatmentEnabled } from '@/state/splitio'
import { selectIsSupplierConnect } from '@/state/organization'

interface IngredientSearchProps {
  onChange: (option: IngredientSearchOption) => Promise<void> | void
  tempIngredient?: boolean
  ingredient?: Ingredient
  placeholderText?: string
  productId?: string
  autoFocus?: boolean
  disabled?: boolean
}

export const IngredientSearch: FC<IngredientSearchProps> = ({
  onChange,
  ingredient = null,
  placeholderText = '',
  productId = null,
  autoFocus = false,
  disabled = false,
}) => {
  const defaultStatuses = defaultFormulationStatusesForAnalytics.map((status) => status.value)
  const workspaceIds = useSelector(selectWorkspacesIds)
  const canViewPublicIngredients = useSelector(selectCanViewPublicIngredients)
  const defaultWorkspaceIds = useSelector(selectDefaultWorkspaceIds)
  const isSupplierConnect = useSelector(selectIsSupplierConnect)

  const [isFocused, setFocused] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>('')
  const ingredientsDisabled = useSelector(selectSplitTreatmentEnabled('hide_ingredients'))
  const [showOnlyIngredients, setShowOnlyIngredients] = useState<boolean>(!ingredientsDisabled || isSupplierConnect)

  const ingredientPayload = useMemo(
    () =>
      ({
        filters: {
          search: inputValue,
          workspaceIds,
          defaultWorkspaceIds,
          ownWorkspacesIds: workspaceIds,
          canViewPublicIngredients: canViewPublicIngredients,
          originLocationStatuses: ['published'],
          statuses: defaultStatuses,
        },
        size: 50,
      } as FetchIngredientsParams),
    [inputValue, workspaceIds, canViewPublicIngredients, defaultStatuses, defaultWorkspaceIds]
  )
  const productIngredientPayload = useMemo(
    () =>
      ({
        filters: {
          search: inputValue,
          ownWorkspacesIds: workspaceIds,
          statuses: defaultStatuses,
        },
        size: 50,
      } as FetchProductIngredientsParams),
    [inputValue, workspaceIds, defaultStatuses]
  )

  const { data: ingredientHits, error: ingredientError } = useSWR(ingredientPayload, fetchIngredients)
  const { data: productIngredientHits, error: productError } = useSWR(productIngredientPayload, fetchProductIngredients)

  const isLoading = useMemo(() => (!ingredientHits && !ingredientError) || (!productIngredientHits && !productError), [
    ingredientHits,
    ingredientError,
    productIngredientHits,
    productError,
  ])

  const options = useMemo(() => {
    if (!inputValue || !productIngredientHits || !ingredientHits) return []
    const results = [...productIngredientHits, ...ingredientHits]
      .map((hit) => new IngredientSearchOption(hit as ElasticsearchIngredientHit, inputValue))
      .filter((hit) => {
        if (hit.isProduct) {
          const id = hit.raw._source?.id || hit.raw._source?.pk
          // Exclude the product we're currently viewing
          if (productId && id && `${id}` === `${productId}`) return false
          // Exclude default workspace formulas
          return hit.raw?._source?.workspaces?.every(
            (w: { id: number; name: string; workspace_type: WorkspaceType }) => !isDefaultWorkspace(w.workspace_type)
          )
        }
        return true
      })
    return results
  }, [inputValue, productIngredientHits, ingredientHits, productId])

  const onChangeShowIngredients = (_event, val: number) => setShowOnlyIngredients(val === 0)

  const value = ingredient
    ? {
        label: !isFocused ? normalizeIngredientName(ingredient.name) || ingredient.token : '', // token needs for unmatched ingredient
        value: !isFocused ? ingredient.name || ingredient.token : '', // token needs for unmatched ingredient
      }
    : null

  return (
    <Tooltip placement="top" title={disabled ? <Text>Select a workspace to add ingredients</Text> : null}>
      <Autocomplete
        aria-label="Search ingredients"
        options={options}
        noOptionsText={inputValue ? 'No options' : 'Start typing to search ingredients'}
        loading={isLoading}
        filterOptions={(ops) => ops} // Override built-in Autocomplete filtering, which removes synonyms/etc
        value={value}
        autoFocus={autoFocus}
        openOnFocus={false}
        onFocus={() => setFocused(true)}
        onBlur={() => {
          setFocused(false)
          setInputValue('')
        }}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            data-testid="ingredient-search-input"
            placeholder={placeholderText || 'Search Ingredients'}
            onChange={(event) => setInputValue(event.target.value)}
          />
        )}
        slotProps={{ paper: { sx: { minWidth: 500 } } }}
        renderHeader={
          ingredientsDisabled
            ? null
            : () => (
                <Stack flexDirection="row" justifyContent="space-between" alignItems="center" pr={2}>
                  <Tabs value={showOnlyIngredients ? 0 : 1} variant="fullWidth" onChange={onChangeShowIngredients}>
                    <Tab label="Ingredients" />
                    {!isSupplierConnect && <Tab label="Materials" />}
                  </Tabs>
                  {!showOnlyIngredients && (
                    <Tooltip placement="bottom" title={<>This carbon footprint includes farm-to-manufacturing-gate.</>}>
                      <Text variant="caption">Carbon Footprint (kg CO2e/kg)</Text>
                    </Tooltip>
                  )}
                </Stack>
              )
        }
        renderOption={(props, option) => {
          // Show ingredients or materials (products) based on the selected tab
          return (option.isProduct && !showOnlyIngredients && !isSupplierConnect) ||
            (!option.isProduct && showOnlyIngredients) ? (
            <MenuItem {...props} key={option.value} value={option.value} onClick={() => onChange(option)}>
              <IngredientOption data={option} />
            </MenuItem>
          ) : null
        }}
      />
    </Tooltip>
  )
}
