import {
  NOT_STARTED,
  REQUESTED,
  CONFIRMED,
  DENIED,
  SHARE_NOT_STARTED,
  SHARE_REQUESTED,
  SHARE_CONFIRMED,
  SHARE_DENIED,
} from '@/api'

export const lockSummary = [
  "Generating a report creates a snapshot of this formula's underlying data and allows you to share it with third parties, or submit it for validation and make external claims.",
  "A product report's formula and metrics will not change even if the underlying research data gets updated.",
]

export const lockedText =
  'Impact metrics and underlying data for this formula have been locked. You can now validate and publish.'

export const lockAlerts = {
  notLocked:
    'By selecting "Create report" you verify that this information is correct and your sourcing information is accurate.',
  locked:
    'Impact metrics and underlying data for this formula have been locked. You can now share this formula or submit it for validation.',
}

export const lcaSubmittionAlert =
  'By selecting "Submit LCA" you can upload your LCA for review. HowGood supports reviewing your LCA (life cycle assessment) for a material you are purchasing from a vendor or for a final product that you are producing.'

export const lockFirstMessage = 'Unlock sharing, validation, and the claims assets by first creating a product report.'

export const validationSummary = [
  'When you request validation, the HowGood research team will review your formula, and either mark your request as validated, or reach out to you for further information.',
]

export const validationPaywallMessage =
  'Contact sales to get access to HowGood validation and the ability to generate claims.'

export const lateUserValidationPaywallMessage =
  'Contact sales to get access to HowGood claims generation and validation.'

export const validationText = {
  [NOT_STARTED]: 'This formula has not been submitted for validation.',
  [REQUESTED]:
    'This formula has been submitted for validation. The HowGood research team will update the status after they have reviewed this formula.',
  [CONFIRMED]: 'This formula has been validated.',
  [DENIED]:
    'We were unable to approve your validation request. Contact support@howgood.com for more information on why.',
}

export const sharingSummary =
  'Choose how to share this report with other organizations that use Latis. This view will update when the request has been completed.'

export const sharingText = {
  [SHARE_NOT_STARTED]: 'This formula has not been submitted for sharing.',
  [SHARE_REQUESTED]:
    'This formula has been submitted for sharing. The HowGood research team will update the status after they have reviewed your request.',
  [SHARE_CONFIRMED]: 'This formula has been shared.',
  [SHARE_DENIED]:
    'We were unable to approve your sharing request. Contact support@howgood.com for more information on why.',
}

export const materialDirDescr = 'Allows other organizations to use your product as a component in their formulas.'
