import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Text, TextField, Stack } from '@howgood/design'
import { formatNumber } from '@howgood/utils'
import { selectPageView, DRAFT } from '@/state/pageSettings'
import { updateDraftInventory } from '@/state/productOverview'
import { InventoriesResponse } from '@/api'
import { selectWorkspaces } from '@/state/workspaces'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'

// API returns `mt_per_year` as a fixed length string that often has trailing zeros, so remove them
const trimValue = (value: string) => {
  const numericValue = parseFloat(value)
  if (!Number.isNaN(numericValue)) {
    return numericValue.toString()
  }
  return value || ''
}

interface MTPerYearProps extends GridRenderCellParams {
  value?: InventoriesResponse
}

export const MTPerYear: FC<MTPerYearProps> = ({ value: inventory }) => {
  const dispatch = useDispatch()
  const view = useSelector(selectPageView)
  const workspaces = useSelector(selectWorkspaces)

  const [mtPerYear, setMtPerYear] = useState(inventory.mt_per_year ? trimValue(inventory.mt_per_year.toString()) : null)

  const workspace = workspaces.find((wkspace) => wkspace.id === inventory.workspace.id)

  // Handle toggling from draft to saved view, etc.
  useEffect(() => {
    setMtPerYear(trimValue(inventory.mt_per_year ? trimValue(inventory.mt_per_year.toString()) : null))
  }, [inventory.mt_per_year])

  const handleMtPerYearChanged = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const stringValue = e.currentTarget.value
    const numericValue = +e.currentTarget.value
    if (!Number.isNaN(numericValue)) {
      setMtPerYear(stringValue)
    }
  }

  const handleLeaveMtPerYear = () => {
    dispatch(updateDraftInventory({ id: inventory.id, fields: { mt_per_year: mtPerYear || null } }))
  }

  if (view !== DRAFT || !workspace.edit) {
    return <Text>{inventory.mt_per_year ? formatNumber(inventory.mt_per_year) : 'Not supplied'}</Text>
  }

  return (
    <Stack key={inventory.id} direction="row" alignItems="center">
      <TextField
        id="mt-per-year-field"
        value={mtPerYear}
        onChange={handleMtPerYearChanged}
        onBlur={handleLeaveMtPerYear}
        sx={{ maxWidth: 100 }}
      />
    </Stack>
  )
}
