import React, { FC, useEffect, useMemo } from 'react'
import { DataGridPremium, GridColDef, GridValidRowModel } from '@mui/x-data-grid-premium'
import { ProductCell } from './ProductCell'
import { ImpactCell } from './ImpactCell'
import { PercentageCell } from './PercentageCell'
import {
  ProductHit,
  selectBasis,
  selectAggregationsByMetric,
  selectIsLoadingProducts,
  selectSelectedMetric,
  setSelectedProductId,
  selectTopProductId,
  selectTopProducts,
  selectSelectedProductId,
} from '../state/productDashboard'
import { useDispatch, useSelector } from 'react-redux'
import { formatNumber } from '@howgood/utils'
import { CircularProgress, Stack, Text } from '@howgood/design'
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium'

export interface ProductInfo {
  id: number
  name: string
  ingredientCount: number
  workspaces: string
}

interface ProductGridRowsProp extends GridValidRowModel {
  id: number
  product: ProductInfo
  impact: { score: string; id: number }
  percentage: { value: string; id: number }
}

const columns: GridColDef[] = [
  {
    field: 'product',
    headerName: 'Product',
    flex: 1,
    renderCell: (params) => <ProductCell value={params.value} />,
  },
  { field: 'impact', headerName: 'Impact', width: 90, renderCell: (params) => <ImpactCell value={params.value} /> },
  {
    field: 'percentage',
    headerName: 'Percentage',
    width: 110,
    renderCell: (params) => <PercentageCell value={params.value} />,
  },
]

const dataGridStyles = {
  border: 0,
  '& .MuiDataGrid-columnHeaders': { border: 0 },
  '& .MuiDataGrid-row': {
    width: 'calc(100% - 4px)', // Required to prevent the right border from being cut off
    border: 1,
    borderColor: 'transparent',
    borderWidth: 2,
    borderRadius: 1,
    bgcolor: 'transparent',
    '&.Mui-selected': { borderColor: 'primary.main', bgcolor: 'transparent' },
  },
  '& .MuiDataGrid-cell': { border: 0, '&:focus': { outline: 'none' } },
}

interface ProductGridProps {
  gridApiRef: React.MutableRefObject<GridApiPremium>
}

export const ProductGrid: FC<ProductGridProps> = ({ gridApiRef }) => {
  const selectedMetric = useSelector(selectSelectedMetric)
  const aggregationsByMetric = useSelector(selectAggregationsByMetric)
  const topProducts = useSelector(selectTopProducts)
  const basis = useSelector(selectBasis)
  const isLoadingProducts = useSelector(selectIsLoadingProducts)
  const topProductId = useSelector(selectTopProductId)
  const selectedProductId = useSelector(selectSelectedProductId)
  const dispatch = useDispatch()

  // Workaround to avoid DataGrid console warning (from https://github.com/mui/mui-x/issues/6879)
  if (gridApiRef.current === null) {
    // @ts-expect-error {} is the initial value set by useGridApiRef
    gridApiRef.current = {}
  }

  // After changing the workspace, metric, or basis, or after refreshing the selected product may not be in the grid,
  // or may be in a different row. If it's there, select it; otherwise select the top product
  useEffect(() => {
    if (topProducts.find((product) => product.pk === selectedProductId)) {
      gridApiRef.current.selectRow(selectedProductId)
    } else if (topProductId) {
      gridApiRef.current.selectRow(topProductId)
    }
  }, [topProducts, topProductId, selectedProductId, gridApiRef])

  const rows: ProductGridRowsProp[] = useMemo(() => {
    if (aggregationsByMetric && selectedMetric in aggregationsByMetric) {
      const basisTotal = aggregationsByMetric[selectedMetric]?.totals[basis] // Total across all selected workspace products
      return topProducts.map((product: ProductHit) => {
        // For each product, get its score for the selected metric and compute its percentage of the total
        const score =
          basis === 'kg'
            ? product.impact_score[selectedMetric]
            : product.custom_fields?.[`${basis}.${selectedMetric}`]?.[0]
        const percentage = score && basisTotal ? formatNumber((score / basisTotal) * 100) : null
        return {
          id: product.pk,
          product: {
            id: product.pk,
            name: product.name,
            ingredientCount: product.ingredients?.length,
            workspaces: product.workspaces?.map((ws) => ws.name).join(', '),
          },
          impact: { score: formatNumber(score), id: product.pk },
          percentage: { value: percentage, id: product.pk },
        }
      })
    } else return []
  }, [aggregationsByMetric, topProducts, selectedMetric, basis]).filter((row) => row.impact)

  if (isLoadingProducts) {
    return (
      <Stack width="100%" alignItems="center" mt={4}>
        <CircularProgress size={40} />
      </Stack>
    )
  }

  if (!aggregationsByMetric || topProducts.length === 0) {
    return (
      <Stack width="100%" alignItems="center" mt={4}>
        <Text>{!aggregationsByMetric ? 'No data available' : 'No matching products'}</Text>
      </Stack>
    )
  }

  const handleSelectionsChanged = (ids: number[]) => {
    // Selection may be changed via the grid API, in which case `ids` is empty
    if (ids.length > 0) {
      dispatch(setSelectedProductId(ids[0]))
    }
  }

  return (
    <DataGridPremium
      apiRef={gridApiRef}
      rows={rows}
      columns={columns}
      density="comfortable"
      autoHeight
      columnHeaderHeight={0}
      hideFooter
      onRowSelectionModelChange={(ids) => handleSelectionsChanged(ids as number[])}
      sx={dataGridStyles}
    />
  )
}
