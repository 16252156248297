import React, { FC } from 'react'
import { Stack, Text } from '@howgood/design'
import { Country, Geo } from '../Country'
import { getFill } from './WaterScarcityCountry.utils'

interface Props {
  geo: Geo
  score: number
}

export const WaterScarcityCountry: FC<Props> = ({ geo, score }) => {
  const fill = getFill(score)

  return (
    <Country
      data-testid="water-scarcity-country"
      geo={geo}
      fill={fill}
      tooltipContent={
        <Stack data-testid="water-scarcity-country-tooltip">
          <Text fontWeight="bold" variant="body2">
            {geo.properties.name}
          </Text>
          {typeof score === 'number' && (
            <Text variant="body2">
              Blue Water Scarcity: <b>{score}</b>*
            </Text>
          )}
        </Stack>
      }
    />
  )
}
