import React, { FC } from 'react'
import { Text, Tooltip, Stack } from '@howgood/design'

interface HeaderRendererProps {
  title: string
  units: string
}
export const HeaderRenderer: FC<HeaderRendererProps> = ({ title, units }) => {
  return (
    <Tooltip title={<Text>{title}</Text>}>
      <Stack my={1} sx={{ cursor: 'grab', width: '100%' }}>
        <Text textAlign="center" textOverflow="ellipsis" noWrap>
          {title}
        </Text>
        <Text fontSize={12} textAlign="center" textOverflow="ellipsis" noWrap>
          {units}
        </Text>
      </Stack>
    </Tooltip>
  )
}
