import React, { FC, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Autocomplete, Stack, TextField, Tooltip } from '@howgood/design'
import { IBrandSearchOption, ProductBrand } from '@/records'
import { selectVendorFilters, updateFiltersUrlAndProducts } from '@/state/products'

interface VendorFilterProps {
  availableVendors: ProductBrand[]
}

export const ScenarioVendorFilter: FC<VendorFilterProps> = ({ availableVendors }) => {
  const vendorFilters = useSelector(selectVendorFilters)
  const dispatch = useDispatch()

  const selectedVendors = useMemo(
    () => vendorFilters.map((vendor) => ({ name: vendor.label, id: vendor.value } as ProductBrand)),
    [vendorFilters]
  )

  const handleChange = (selections: ProductBrand[]) => {
    const vendorOptions = selections.map((brand) => ({ label: brand.name, value: brand.id } as IBrandSearchOption))
    dispatch(updateFiltersUrlAndProducts({ updatedFilters: { vendors: vendorOptions } }))
  }

  return (
    <Stack minWidth={200}>
      <Tooltip title="Filter formulas by vendors" placement="top">
        <Autocomplete
          multiple
          id="scenario-vendor-filter"
          options={availableVendors}
          value={selectedVendors}
          getOptionLabel={(option: ProductBrand) => option.name}
          filterSelectedOptions
          renderInput={(params) => <TextField {...params} placeholder="Vendors" />}
          onChange={(_e, selections: ProductBrand[]) => handleChange(selections)}
        />
      </Tooltip>
    </Stack>
  )
}
