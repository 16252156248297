import { Workspace } from '@/records'
import { fetchProductImpactScore } from '../api/productImpactScoreApi'

export const getAnalysisImpactScore = async (productId: number) => {
  const impactScore = await fetchProductImpactScore(productId)
  return impactScore
}

export const checkLcaOverriddenProduct = (toCheck: { impacts_overridden?: boolean }) =>
  Boolean(toCheck?.impacts_overridden)

export const checkHasLcaOverriddenNestedProduct = (toCheck: { child_impacts_overridden?: boolean }) =>
  Boolean(toCheck?.child_impacts_overridden)

export const getEditWorkspace = (productWorkspaces: Workspace[], userWorkspaces: Workspace[]) => {
  return productWorkspaces.find((pws) => {
    const found = userWorkspaces.find((ws) => ws.id === pws.id)
    return found?.edit
  })
}

export const checkWorkspaceAccess = (workspaceId: number, userWorkspaces: Workspace[]) => {
  return userWorkspaces.some((ws) => workspaceId === ws.id && (ws.edit || ws.read || ws.foreign_read))
}
