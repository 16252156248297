import React, { FC } from 'react'
import { ComposableMap, Geographies, Graticule, Sphere, ZoomableGroup } from 'react-simple-maps'
import { Accordion, AccordionDetails, Stack, Text, theme } from '@howgood/design'
import { useAllRegions } from '@/api'
import { AccordionCardHeader } from '@/components/Scores/AccordionCardHeader'
import { Geo } from '../Country'
import { WaterScarcityCountry } from './WaterScarcityCountry'

export const WaterScarcity: FC = () => {
  const { data: regions } = useAllRegions()

  if (regions.length === 0) {
    return null
  }

  return (
    <Accordion data-testid="water-scarcity" defaultExpanded>
      <AccordionCardHeader title="Blue Water Scarcity" />
      <AccordionDetails>
        <Stack>
          <ComposableMap width={980}>
            <ZoomableGroup>
              <Sphere id="sphere" fill="white" stroke={theme.palette.divider} strokeWidth={1} />
              <Graticule stroke={theme.palette.divider} />
              <Geographies geography="/map.json">
                {({ geographies }: { geographies: Geo[] }) =>
                  geographies.map((geo) => {
                    const region = regions.find((r) => r.iso3_country_code === geo.id)
                    return <WaterScarcityCountry key={geo.id} geo={geo} score={region?.blue_water_scarcity} />
                  })
                }
              </Geographies>
            </ZoomableGroup>
          </ComposableMap>
          <Text data-testid="water-scarcity-footnote">
            * Blue Water Scarcity values represent the percentage of available blue water being used. Values &gt;1 mean
            more blue water is being used than can be renewed.
          </Text>
        </Stack>
      </AccordionDetails>
    </Accordion>
  )
}
