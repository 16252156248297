import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, CircularProgress, Stack, Text } from '@howgood/design'

import {
  selectHasInititives,
  selectInitiatives,
  selectisInitiativesLoading,
  setCurrentInitiativeAndChartMetric,
} from '@/state/initiatives'
import { PRODUCTS, MATERIALS } from '@/records'
import { InitiativeItem } from './InitiativeItem'
import { setShowLiveImpactData, updateProductFilters, initialState } from '@/state/products'
import { useLiteUserPaywallDialogForPages } from '@/components/LiteUserPaywallDialog/LiteUserPaywallDialog.hook'
import { Heading } from '@/components/Layout/Heading'
import { faFlagSwallowtail } from '@fortawesome/pro-solid-svg-icons'
import { CreateInitiative } from './CreateInitiative'

export const Initiatives = () => {
  const dispatch = useDispatch()
  const hasInitiatives = useSelector(selectHasInititives)
  const initiatives = useSelector(selectInitiatives)
  const isLoading = useSelector(selectisInitiativesLoading)
  useLiteUserPaywallDialogForPages()

  const sortedInitiatives = useMemo(() => {
    const sorted = [...initiatives]
    sorted.sort((a, b) => (a.name < b.name ? -1 : 1))
    return sorted
  }, [initiatives])

  useEffect(() => {
    dispatch(setShowLiveImpactData(true))
    dispatch(updateProductFilters({ ...initialState.productFilters, workspaceTypes: [PRODUCTS, MATERIALS] }))
    dispatch(setCurrentInitiativeAndChartMetric())
  }, [dispatch])

  if (isLoading) {
    return (
      <Stack alignItems="center" mt={8}>
        <CircularProgress />
        <Box mt={2}>
          <Text>Loading initiatives</Text>
        </Box>
      </Stack>
    )
  }

  return (
    <>
      <Heading title={'Initiatives'} icon={faFlagSwallowtail} actions={<CreateInitiative color="primary" />} />
      <Stack gap={2} mb={2}>
        {!hasInitiatives && (
          <Box mt={4}>
            <Text textAlign="center">
              You currently do not have any initiatives. If you are having trouble, please contact support@howgood.com.
            </Text>
          </Box>
        )}
        <Box boxShadow="0px 0px 1px 0px #0000008C" borderRadius={1}>
          {sortedInitiatives.map((initiative) => (
            <InitiativeItem key={initiative.id} initiative={initiative} />
          ))}
        </Box>
      </Stack>
    </>
  )
}
