import { ProductColFields } from '@/components/Products/utils/productListColDefs'
import { Field } from '@/constants/impactScore'
import { PortfolioImpactDashboardSettings } from '@/features/PortfolioDashboard/state/portfolioDashboard'
import { ProductDashboardSettings } from '@/features/ProductDashboard/state/productDashboard'
import { VendorManagementSettings } from '@/state/vendorManagement'

export const DEFAULT_USER_ID = 0

interface Permissions {
  products?: string[]
  ingredients?: string[]
  accounts?: string[]
}

interface UserGroup {
  id: number
  name: string
}

export type Provider = '' | 'auth0' | 'default'

export interface UserSettings {
  ignore_user_settings: boolean
  dashboards: {
    portfolio_impact: PortfolioImpactDashboardSettings
    product_impact: ProductDashboardSettings
  }
  productList: {
    selectedColumns: (Field | ProductColFields)[]
  }
  vendorManagement: VendorManagementSettings
}

export interface IUser {
  authenticated: boolean
  id: number
  email: string
  title: string
  first_name: string
  last_name: string
  permissions: Permissions
  groups: UserGroup[]
  provider: Provider
  auth0_organization: string
  organization: number
  user_settings: UserSettings
}

export function getUserName(user: IUser) {
  if (user.first_name && user.last_name) {
    return `${user.first_name} ${user.last_name}`
  }
  if (user.first_name) {
    return user.first_name
  }
  return null
}
