import React, { FC, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { DataGridPremium } from '@mui/x-data-grid-premium'
import {
  availableVendorMetrics,
  selectVendorBucketAggs,
  selectVendorData,
  selectVendorManagementSettings,
} from '@/state/vendorManagement'
import { dataGridStyles } from '@/features/PortfolioDashboard/Charts/BarChart'
import { UNKNOWN_VENDOR_OPTION_VALUE } from '@/records'
import { theme, Box, Stack, Text } from '@howgood/design'
import { formatNumber } from '@howgood/utils'
import { NameCell } from '../Grid/NameCell'

/**
 * Bar chart component built using a data grid.
 */

const chartColors = [
  theme.palette.error.dark,
  theme.palette.impact[1],
  theme.palette.impact[3],
  theme.palette.impact[5],
  theme.palette.impact[6],
]

const labelSize = 36

export const GridBarChart: FC = () => {
  const vendorData = useSelector(selectVendorData)
  const vendorManagementSettings = useSelector(selectVendorManagementSettings)
  const vendorBucketAggs = useSelector(selectVendorBucketAggs)

  const selectedMetric = vendorManagementSettings.selectedMetric
  const maxValue = vendorBucketAggs[`vendors_${selectedMetric}_stats`]?.max
  const aggType = availableVendorMetrics[selectedMetric].aggType

  const columns = useMemo(
    () => [
      {
        field: 'name',
        width: 180,
        sortable: false,
        renderCell: (params) => <NameCell {...params} />,
      },
      {
        field: 'bar',
        flex: 1,
        renderCell: (params) => {
          const id = params.value.id
          const absValue = params.value.value?.[id === -1 ? 'avg' : aggType]
          const percentValue = (absValue / maxValue) * 100

          return (
            <Stack
              direction="row"
              alignItems="center"
              gap={0.5}
              width={`calc(100% - ${labelSize}px)`}
              position="relative"
            >
              <Stack width={`${percentValue}%`} height={id === -1 ? 16 : 20} bgcolor={params.value.color} />
              <Box position="absolute" left={`${percentValue}%`} ml={0.5} width={labelSize - 4}>
                <Text fontSize={13}>{absValue ? formatNumber(absValue, 1) : ''}</Text>
              </Box>
            </Stack>
          )
        },
        sortable: false,
      },
    ],
    [aggType, maxValue]
  )

  const rows = useMemo(
    () =>
      [...vendorData]
        // Filter out unknown vendors and vendors with no data for the selected metric
        .filter((vendor) => vendor.brandId !== UNKNOWN_VENDOR_OPTION_VALUE && vendor[selectedMetric]?.count > 0)
        .sort((a, b) => b[selectedMetric]?.[aggType] - a[selectedMetric]?.[aggType])
        .slice(0, 5)
        .map((vendor, index) => ({
          id: vendor.brandId,
          name: vendor.name,
          bar: {
            id: vendor.brandId,
            value: vendor[selectedMetric],
            color: chartColors[index],
          },
        })),
    [aggType, selectedMetric, vendorData]
  )

  const averageRow = {
    id: -1,
    name: 'Average',
    bar: {
      id: -1,
      value: vendorBucketAggs[`vendors_${selectedMetric}_stats`],
      color: theme.palette.secondary.main,
    },
  }

  return (
    <DataGridPremium
      rows={[...rows, averageRow]}
      columns={columns}
      initialState={{ density: 'compact' }}
      columnHeaderHeight={0}
      rowHeight={42}
      hideFooter
      autoHeight
      pagination
      getRowClassName={(params) => (params.id === -1 ? 'average-row' : '')}
      sx={{
        ...dataGridStyles,
        '& .average-row': { borderTop: 1, borderColor: theme.palette.secondary.light, mt: 0.25 },
      }}
    />
  )
}
