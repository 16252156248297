import React from 'react'
import { Route, Switch } from 'react-router'
import App from '@/containers/App'
import Login from '@/containers/Login'
import { Dashboard } from '@/containers/Dashboard/Dashboard'
import { PrivateRoute } from '@/containers/PrivateRoute'

export default (
  <App>
    <Switch>
      <Route exact path="/login" component={Login} />
      <PrivateRoute component={Dashboard} />
    </Switch>
  </App>
)
