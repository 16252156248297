import React, { useEffect } from 'react'
import { Stack } from '@howgood/design'

import { WorkspaceList } from './components/WorkspaceList'
import { Scores } from '@/components/Scores/Scores'
import { useDispatch } from 'react-redux'
import { getWorkspaceImpactScores } from '@/state/workspaces'
import { resetProductMetricsState } from '@/state/productMetrics'
import { getProducts, initialState, setShowLiveImpactData, updateProductFilters } from '@/state/products'
import { WorkspaceTypeFilter } from '@/components/ProductsFilters/WorkspaceTypeFilter'
import { PRODUCTS, MATERIALS } from '@/records'
import { TagsFilter } from '@/components/ProductsFilters/TagsFilter'
import { Toolbar } from './components/Toolbar/Toolbar'
import { useLiteUserPaywallDialogForPages } from '@/components/LiteUserPaywallDialog/LiteUserPaywallDialog.hook'

export const PortfolioPage = () => {
  const dispatch = useDispatch()
  useLiteUserPaywallDialogForPages()

  useEffect(() => {
    dispatch(setShowLiveImpactData(false))
    dispatch(resetProductMetricsState())
    dispatch(updateProductFilters({ ...initialState.productFilters, workspaceTypes: [PRODUCTS, MATERIALS] }))
    dispatch(getProducts({ size: 0 }))
    dispatch(getWorkspaceImpactScores())
  }, [dispatch])

  return (
    <>
      <Stack flexDirection="row" alignItems="center" justifyContent="stretch" gap={1} mb={2}>
        <WorkspaceTypeFilter />
        <TagsFilter />
      </Stack>
      <Scores />
      <Toolbar />
      <WorkspaceList />
    </>
  )
}
