import React, { FC, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { theme } from '@howgood/design'

import { ImpactScore } from '@/api'
import { CarbonLifecycleMetrics } from '@/hooks'
import { Field } from '@/constants/impactScore'
import { getKeyWithoutAggregateOption } from '@/contentfully'
import { selectWarningText } from '@/state/recipe'
import { roundBy } from '@/utils/numbers'
import { LegendContent } from './LegendContent'
import { TooltipContent } from './TooltipContent'
import { selectIsRollup } from '@/state/router'

const useStyles = createUseStyles({
  fade: { opacity: 0.6 },
  updatedBarChart: { zIndex: 10, transform: 'translate(0, 10px)' },
  text: { fontSize: '14px' },
})

export type DataPct = Partial<Record<Field, string>>

interface CarbonLifecycleProps {
  impactScore: ImpactScore
  benchmarkImpactScore?: ImpactScore
  isInnovationMode?: boolean
  size?: 'small' | 'default'
}
export interface Props extends CarbonLifecycleProps {
  metrics: CarbonLifecycleMetrics
}

export const BAR_COLORS: Partial<Record<Field, string>> = {
  carbon_footprint_farm_to_gate_impact: '#009a63',
  cf_luc_impact: '#00aa33',
  cf_total_upstream_processing_impact: '#97cdae',
  cf_total_upstream_transportation_impact: '#3FC35D',
  cf_mtm_transportation_impact: '#687EC8',
  cf_manufacturing_impact: '#2D48A1',
  cf_packaging_total_impact: '#3790a2',
  cf_storage_impact: '#F9DFA4',
  cf_retail_storage_impact: '#ffab40',
  cf_consumption_impact: '#096375',
  cf_packaging_waste_impact: '#007c50',
  cf_mtr_transportation_impact: '#FF7040',
  cf_mts_transportation_impact: '#2c3540',
}

export const CarbonLifecycle: FC<Props> = ({
  metrics,
  impactScore,
  isInnovationMode,
  benchmarkImpactScore,
  size = 'default',
}) => {
  const isRollup = useSelector(selectIsRollup)
  const [hoveredBar, setHoveredBar] = useState<Field>()
  const fields = metrics.map((metric) => metric.field)
  const name = metrics.find((metric) => metric.field === hoveredBar)?.subtitle
  const classes = useStyles()
  const dataWarning = useSelector(selectWarningText)

  let total = 0
  let benchmarkTotal = 0
  fields.forEach((field) => {
    total += impactScore[field] ? +impactScore[field] : 0
    benchmarkTotal += benchmarkImpactScore?.[field] ? +benchmarkImpactScore[field] : 0
  })

  const dataPct: DataPct = {}
  const benchmarkDataPct: DataPct = {}

  fields.forEach((field) => {
    const val = impactScore[field] ? +impactScore[field] : 0
    const benchmarkVal = benchmarkImpactScore?.[field] ? +benchmarkImpactScore[field] : 0
    dataPct[field] = roundBy((val / total) * 100, 1)?.toString()
    benchmarkDataPct[field] = roundBy((benchmarkVal / benchmarkTotal) * 100, 1)?.toString()
  })

  if (total === 0) {
    return <>There are no values present</>
  }

  return (
    <>
      <LegendContent metrics={metrics} dataPct={dataPct} impactScores={impactScore} size={size} />
      {isInnovationMode && (
        <div aria-label="Updated lifecycle">
          <ResponsiveContainer width="100%" minHeight="50px">
            <BarChart data={[dataPct]} layout="vertical" className={classes.updatedBarChart}>
              <text aria-label="Updated lifecycle text" className={classes.text} fill="#4d4e54" x={15} y={10}>
                {isRollup ? 'Current Selection' : 'Updated'}
              </text>
              <XAxis
                type="number"
                domain={[0, 100]}
                ticks={[0, 25, 50, 75, 100]}
                padding={{ left: 10, right: 20 }}
                hide
              />
              <YAxis type="category" dataKey="name" hide />
              <Tooltip
                position={{ y: -95 }}
                cursor={{ fill: 'transparent' }}
                content={
                  <TooltipContent
                    field={hoveredBar}
                    name={name}
                    dataPct={dataPct}
                    benchmarkDataPct={benchmarkDataPct}
                    impactScores={impactScore}
                    benchmarkScores={benchmarkImpactScore}
                  />
                }
              />
              {metrics.map((metric) => {
                const fieldWithoutAggOption = getKeyWithoutAggregateOption(metric.field)
                return (
                  <Bar
                    key={metric.field}
                    dataKey={metric.field}
                    onMouseOver={() => setHoveredBar(metric.field)}
                    onMouseOut={() => setHoveredBar(null)}
                    stackId="stack"
                    name={metric.subtitle}
                    fill={BAR_COLORS[fieldWithoutAggOption]}
                    barSize={20}
                    opacity={dataWarning ? 0.5 : 1}
                  />
                )
              })}
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}
      <ResponsiveContainer width={size === 'small' ? 525 : '100%'} minHeight="80px">
        <BarChart data={[isInnovationMode ? benchmarkDataPct : dataPct]} layout="vertical">
          {isInnovationMode && (
            <text aria-label="Previous lifecycle text" className={classes.text} fill="#4d4e54" x={15} y={15}>
              {isRollup ? 'Industry Benchmark' : 'Previous'}
            </text>
          )}
          <XAxis
            type="number"
            domain={[0, 100]}
            unit="%"
            ticks={[0, 25, 50, 75, 100]}
            interval={0}
            padding={{ left: 10, right: 20 }}
            tick={size === 'small' ? { fontSize: '10px', fontFamily: theme.typography.fontFamily } : undefined}
            opacity={dataWarning ? 0.5 : 1}
          />
          <YAxis type="category" dataKey="name" hide />
          {!isInnovationMode && (
            <Tooltip
              cursor={{ fill: 'transparent' }}
              content={<TooltipContent field={hoveredBar} name={name} dataPct={dataPct} impactScores={impactScore} />}
            />
          )}
          {metrics.map((metric) => {
            const fieldWithoutAggOption = getKeyWithoutAggregateOption(metric.field)
            return (
              <Bar
                key={metric.field}
                dataKey={metric.field}
                onMouseOver={() => {
                  if (!isInnovationMode) {
                    setHoveredBar(metric.field)
                  }
                }}
                onMouseOut={() => setHoveredBar(null)}
                className={isInnovationMode && hoveredBar !== metric.field ? classes.fade : undefined}
                stackId="stack"
                name={metric.subtitle}
                fill={BAR_COLORS[fieldWithoutAggOption]}
                barSize={isInnovationMode || size === 'small' ? 10 : 20}
                opacity={dataWarning ? 0.5 : 1}
              />
            )
          })}
        </BarChart>
      </ResponsiveContainer>
    </>
  )
}
