import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { formatNumber } from '@howgood/utils'
import { selectDisplayedProductStandards, selectDisplayedPackaging } from '@/state/recipe'
import { selectDisplayedProductName, selectDisplayedProductWorkspaces } from '@/state/productOverview'
import { useScoreCardData } from '@/components/Scores/ScoreCards'
import { CardData } from '@/records/scoreCardData'
import { packagingUnitMap } from '../../ProductInfo/Packaging/PackagingUnit'

export const useProductDetailRows = (): string[][] => {
  const productName = useSelector(selectDisplayedProductName)
  const productWorkspaces = useSelector(selectDisplayedProductWorkspaces)
  const standards = useSelector(selectDisplayedProductStandards)
  const packaging = useSelector(selectDisplayedPackaging)

  return useMemo(() => {
    const productDetailRows = [
      ['Workspace', 'Name'],
      [productWorkspaces.map((pws) => pws.name).join(', '), productName],
    ]
    if (standards.length) {
      productDetailRows[0].push('Standards')
      productDetailRows[1].push(standards.map((standard) => standard.title).join(' & '))
    }
    if (packaging.length) {
      productDetailRows[0].push('Packaging')
      productDetailRows[1].push(
        packaging.map((p) => `${packagingUnitMap[p.packaging_unit]}: ${p.material.name}`).join(' & ')
      )
    }

    return productDetailRows
  }, [productWorkspaces, productName, standards, packaging])
}

const generateRowsFromCards = (cards: { title: string; data: CardData[]; index: number }) => {
  return cards.data.reduce((rowData, card) => {
    if (card.score === null || card.key.includes('-compare-against')) {
      return rowData
    }
    const fieldName = card.subTitle ? `${card.label} - ${card.subTitle}` : card.label
    const score = typeof card.score === 'string' ? card.score : formatNumber(card.score)
    const units = card.contentfulEntry?.units || ''
    return [...rowData, [fieldName, score, units]]
  }, [])
}

export const useProductScoreRows = (): (string | number)[][] => {
  const scoreCardData = useScoreCardData()

  return useMemo(() => {
    return [
      ['Metric', 'Score', 'Unit'],
      ...generateRowsFromCards(scoreCardData.overview),
      ...generateRowsFromCards(scoreCardData.carbon),
      ...generateRowsFromCards(scoreCardData.nature),
      ...generateRowsFromCards(scoreCardData.people),
    ]
  }, [scoreCardData])
}
