import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'
import { roundBy } from '@/utils/numbers'
import { ImpactScore } from '@/api'
import { CarbonLifecycleMetrics } from '@/hooks'
import { getKeyWithoutAggregateOption } from '@/contentfully'
import { LegendItem } from './LegendItem'
import { BAR_COLORS, DataPct } from './CarbonLifecycle'
import { formatNumber } from '@howgood/utils'

interface Props {
  dataPct: DataPct
  impactScores: ImpactScore
  metrics: CarbonLifecycleMetrics
  size?: 'small' | 'default'
}

const useStyles = createUseStyles<string, Pick<Props, 'size'>>({
  legend: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: ({ size }) => (size === 'small' ? 0 : 10),
    marginBottom: ({ size }) => (size === 'small' ? 0 : 10),
    fontSize: ({ size }) => (size === 'small' ? '8px' : '12px'),
    lineHeight: 'normal',
    paddingLeft: 10,
  },
})

export const LegendContent: FC<Props> = ({ dataPct, impactScores, metrics, size = 'default' }) => {
  const classes = useStyles({ size })

  return (
    <div className={classes.legend}>
      {metrics.map((metric) => {
        return (
          <LegendItem
            key={metric.field}
            title={metric.subtitle}
            units={metric.units}
            helpText={metric.helpText}
            actValue={formatNumber(impactScores[metric.field], 2)}
            pctValue={roundBy(dataPct[metric.field], 2)}
            color={BAR_COLORS[getKeyWithoutAggregateOption(metric.field)]}
            size={size}
          />
        )
      })}
    </div>
  )
}
