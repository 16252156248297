import React, { FC, MouseEvent, useEffect, useRef, useState } from 'react'
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons'
import { Box } from './Box'
import { Menu, MenuItem, MenuProps } from './Menu'
import { Text } from './Text'
import { theme } from './theme'
import { Icon } from './Icon'

export const SPACE_MARGIN = '0.25em'

interface Item {
  label: string
  disabled?: boolean
  onClick?: (e: MouseEvent<HTMLElement>) => void
}
export interface InlineSelectProps extends Pick<MenuProps, 'id' | 'aria-label' | 'MenuListProps'> {
  items: Item[]
  disabled?: boolean
  fontSize?: number
  lineHeight?: number | string
  placeholder?: string
  variant?: 'inherit' | 'primary' | 'error'
  hasSurroundingText?: boolean
  onChange?: (e: MouseEvent<HTMLElement>) => void
  ['data-testid']?: string
  renderHeader?: JSX.Element
}

export const InlineSelect: FC<InlineSelectProps> = ({
  id,
  ['aria-label']: ariaLabel,
  ['data-testid']: dataTestId,
  items,
  placeholder,
  disabled = false,
  variant = 'inherit',
  hasSurroundingText = false,
  MenuListProps,
  fontSize,
  lineHeight,
  onChange,
  renderHeader,
}) => {
  const defaultValue = placeholder ? placeholder : items.length > 0 ? items[0].label : null
  const anchorElRef = useRef()
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState(defaultValue)
  const color =
    variant === 'primary' || hasSurroundingText
      ? theme.palette.primary.main
      : variant === 'error'
      ? theme.palette.error.main
      : 'inherit'

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  if (!value) {
    return null
  }

  return (
    <>
      <Box
        ref={anchorElRef}
        id={id}
        aria-label={ariaLabel}
        onClick={() => {
          if (!disabled) setOpen(true)
        }}
        display="inline-flex"
        data-testid={dataTestId}
        alignItems="center"
        gap={SPACE_MARGIN}
        mx={hasSurroundingText ? SPACE_MARGIN : undefined}
        borderBottom="2px solid"
        borderColor="transparent"
        fontSize={fontSize}
        lineHeight={lineHeight}
        color={color}
        sx={{
          cursor: disabled ? 'not-allowed' : 'pointer',
          opacity: disabled ? theme.palette.action.disabledOpacity : 1,
          ':hover': {
            borderColor: disabled ? 'transparent' : color,
          },
        }}
      >
        <Text noWrap color="inherit" fontWeight="inherit" fontSize="inherit" lineHeight="inherit">
          {value}
        </Text>
        <Icon icon={faCaretDown} color="inherit" size="xs" />
      </Box>
      <Menu
        anchorEl={anchorElRef.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={open}
        onClose={() => setOpen(false)}
        MenuListProps={MenuListProps}
      >
        {renderHeader}
        {items.map(({ label, disabled: itemDisabled, onClick }) => (
          <MenuItem
            key={label}
            aria-label={label}
            disabled={itemDisabled}
            onClick={(e) => {
              setValue(e.currentTarget.innerText)
              setOpen(false)
              if (onClick) onClick(e)
              if (onChange) onChange(e)
            }}
          >
            {label}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
