import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { Goals, IImpactMetricOption } from '@/records'
import { updateScenario } from '@/state/initiatives'
import { GoalCell } from '@/components/GridWithMetrics/GoalCell'
import { GridScenario } from '@/records/Initiatives'
import { selectScoreType } from '@/state/pageSettings'

export interface ScenarioGoalCellValue {
  scenario: GridScenario
  count: number
  avgGoal: number
  sumGoal: number
  isChartTable: boolean
}
export interface ScenarioGoalCellParams extends GridRenderCellParams {
  metric: IImpactMetricOption
  value: ScenarioGoalCellValue
}

export const ScenarioGoalCell: FC<ScenarioGoalCellParams> = ({ metric, value }) => {
  const dispatch = useDispatch()
  const scoreSetting = useSelector(selectScoreType)
  if (!value) {
    return null
  }
  const { scenario, avgGoal, sumGoal, count, isChartTable } = value
  const onUpdateGoal = (updatedGoal: number) => {
    // If goal was cleared, remove it from the goals object
    if (updatedGoal == null) {
      const { [metric.value]: _, ...rest } = scenario.goals as any
      dispatch(
        updateScenario({
          id: scenario.id,
          goals: rest as Goals,
        })
      )
      return
    }
    const newAvgGoal = scoreSetting === 'displayedSum' ? updatedGoal / count : updatedGoal
    if (avgGoal !== newAvgGoal) {
      const updatedGoals = {
        ...scenario.goals,
        [metric.value]: newAvgGoal === null ? undefined : newAvgGoal,
      }
      dispatch(
        updateScenario({
          id: scenario.id,
          goals: updatedGoals,
        })
      )
    }
  }

  return (
    <GoalCell
      metric={metric}
      id={`${scenario.id}${isChartTable ? '-chart' : ''}`}
      name={scenario.name}
      metricGoal={scoreSetting === 'displayedSum' ? sumGoal : avgGoal}
      onUpdateGoal={onUpdateGoal}
      colored={isChartTable}
      editable={!isChartTable && !scenario.isBaseline}
    />
  )
}
