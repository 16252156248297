import React, { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { faFileInvoice } from '@fortawesome/pro-regular-svg-icons'
import { MenuItem, ListItemIcon, ListItemText } from '@howgood/design'

import { selectIsCreateNew, selectIsScenarioContext, selectIsThirdParty } from '@/state/productOverview'
import { DRAFT, selectPageView, setReportToolkitDrawer } from '@/state/pageSettings'
import { selectIsSupplierConnect } from '@/state/organization'
import { ProductCFReportDialog } from '@/components/ProductCFReportDialog/ProductCFReportDialog'

interface CreateCarbonFootprintReportProps {
  openReportDialog: () => void
  closeMenu: () => void
}

export const CreateCarbonFootprintReport: FC<CreateCarbonFootprintReportProps> = ({ openReportDialog, closeMenu }) => {
  const dispatch = useDispatch()
  const createNew = useSelector(selectIsCreateNew)
  const showScenarioOptions = useSelector(selectIsScenarioContext)
  const isSupplierConnect = useSelector(selectIsSupplierConnect)
  const view = useSelector(selectPageView)
  const isThirdPartyProduct = useSelector(selectIsThirdParty)

  const [dialogOpen, setDialogOpen] = useState(false)

  if (createNew || showScenarioOptions) {
    return null
  }

  const handleExportGhgImpact = () => {
    closeMenu()
    if (isSupplierConnect || isThirdPartyProduct) {
      openReportDialog()
    } else {
      dispatch(setReportToolkitDrawer(true))
    }
  }

  return (
    <>
      <MenuItem
        id="export-ghg-impact-report-option"
        data-testid="export-ghg-impact-report-option"
        onClick={handleExportGhgImpact}
        disabled={view === DRAFT}
      >
        <ListItemIcon icon={faFileInvoice} />
        <ListItemText>Create Product Carbon Footprint Report</ListItemText>
      </MenuItem>
      {dialogOpen && <ProductCFReportDialog closeDialog={() => setDialogOpen(false)} />}
    </>
  )
}
