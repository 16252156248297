import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Checkmark, Stack, Text } from '@howgood/design'
import { formatNumber } from '@howgood/utils'
import { RetailRatingImage } from '@/components'
import { selectSavedProductName } from '@/state/productOverview'
import { ImpactHistoryData } from './ImpactHistory.types'

export type Payload = [{ payload: ImpactHistoryData }]
interface Props {
  payload?: Payload // automatically passed with recharts Tooltip content prop
}

export const ImpactHistoryChartTooltip: FC<Props> = ({ payload }) => {
  const productName = useSelector(selectSavedProductName)

  const data = payload?.[0]?.payload
  if (!data) {
    return null
  }

  const { date, score, valuesMap, title, units, attributes, retailRating } = data

  return (
    <Stack gap={1} py={1} px={2} borderRadius={1} border={1} borderColor="divider" bgcolor="white" maxWidth={300}>
      <Text data-testid="tooltip-date" variant="caption" textAlign="right">
        {new Date(date).toLocaleDateString()}
      </Text>
      <Stack data-testid="tooltip-product-name" flexDirection="row" alignItems="center" gap={1}>
        {typeof retailRating === 'number' && <RetailRatingImage score={retailRating} width="40px" />}
        <Stack flex={1}>
          <Text fontWeight="bold">{productName}</Text>
        </Stack>
      </Stack>
      <Text data-testid="tooltip-score" variant="body2">
        {title}: <b>{valuesMap?.[score] || formatNumber(score)}</b> {units}
      </Text>
      <Stack data-testid="tooltip-attributes">
        {attributes.map((attribute) => (
          <Stack key={attribute.key} flexDirection="row" gap={1} alignItems="center">
            <Checkmark enabled={Boolean(attribute.values[date])} size="xs" />
            <Text variant="body2">{attribute.title}</Text>
          </Stack>
        ))}
      </Stack>
    </Stack>
  )
}
