import React, { FC } from 'react'
import { Box, IconButton, Text, Stack } from '@howgood/design'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { RecipeIngredient, RecipeNestedIngredient } from '@/state/recipe/recipe.state'
import { faChevronDown, faChevronRight } from '@fortawesome/pro-regular-svg-icons'

interface IngredientNameProps extends GridRenderCellParams {
  value: {
    ingredient: RecipeIngredient | RecipeNestedIngredient
    expanded: string[]
    setExpanded: (dotPath: string[]) => void
  }
}

export const IngredientName: FC<IngredientNameProps> = ({ value }) => {
  const { ingredient, expanded, setExpanded } = value

  const indent = 4 * (ingredient.flat_position.length - 1)

  if (!Object.hasOwn(ingredient, 'nested_product_id')) {
    return (
      <Box ml={indent}>
        <Text>{ingredient.name}</Text>
      </Box>
    )
  }

  const dotPath = ingredient.flat_position.join('.')
  const isExpanded = expanded.includes(dotPath)
  return (
    <Stack direction="row" pl={indent} alignItems="center">
      <Box ml={-1}>
        <IconButton
          icon={isExpanded ? faChevronDown : faChevronRight}
          onClick={() =>
            isExpanded
              ? setExpanded(expanded.filter((item) => item !== ingredient.dot_path))
              : setExpanded([...expanded, ingredient.dot_path])
          }
        />
      </Box>
      <Box maxWidth={280 - indent * 8} overflow="hidden">
        <Text textOverflow="ellipsis">{ingredient.name}</Text>
      </Box>
    </Stack>
  )
}
