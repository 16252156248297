import React, { FC, useState, useMemo } from 'react'
import { DataGridPremium, GridCellParams } from '@mui/x-data-grid-premium'
import { Box } from '@howgood/design'
import { useDispatch, useSelector } from 'react-redux'
import { updateIngredient, selectContentfulIngredientImpactData } from '@/state/recipe'
import { RecipeIngredient } from '@/state/recipe/recipe.state'
import { selectOriginLocations, OriginLocationMapping } from '@/state/originLocations'
import { ScoreInputs } from '../../../util/getScore'
import { useSourceGridColumns } from './useSourceGridColumns'
import { useSourceGridRows } from './useSourceGridRows'
import { IngredientImpactV2 } from '@/components'

interface SourceGridProps {
  ingredient: RecipeIngredient
  impactData: IngredientImpactV2[]
  handleClose: () => void
}

export const SourceGrid: FC<SourceGridProps> = ({ ingredient, impactData, handleClose }) => {
  const dispatch = useDispatch()
  const originLocations = useSelector(selectOriginLocations)
  const impactItems = useSelector(selectContentfulIngredientImpactData)

  const [searchText, setSearchText] = useState('')
  const [pinnedRowIds, setPinnedRowIds] = React.useState<number[]>([])

  // After selecting a source location, update the recipe
  const handleLocationClick = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    locationMapping: OriginLocationMapping
  ) => {
    e.stopPropagation()
    const locationId = locationMapping.origin_location_id
    const locationFieldsToUpdate = {
      origin_location_id: locationId,
      origin_location_name: originLocations.find((location) => location.origin_location_id === locationId)?.region_name,
    }
    dispatch(updateIngredient(ingredient, locationFieldsToUpdate))
    handleClose()
  }

  const handleLocationSearch = (search: string) => {
    setSearchText(search.toLowerCase())
  }

  const handlePinnedRowsChanged = (rowIds: number[]) => {
    setPinnedRowIds(rowIds)
  }

  const columns = useSourceGridColumns(handleLocationSearch, handleLocationClick, handlePinnedRowsChanged)
  const rows = useSourceGridRows({ ingredients: impactData }).filter(
    (row) => row.location.source?.region_name && row.location.source.region_name.toLowerCase().includes(searchText)
  )

  const pinnedRows = useMemo(() => rows.filter((row) => pinnedRowIds.includes(row.id)), [pinnedRowIds, rows])

  return (
    <Box
      data-testid="source-location-grid-container"
      id="source-location-grid-container"
      width="100%"
      height={rows.length * 36 + 40}
      maxHeight={500}
      sx={{
        '& .MuiDataGrid-columnHeader': { px: 1 },
        '& .MuiDataGrid-columnHeaderTitleContainerContent': {
          width: '100%',
        },
      }}
    >
      <DataGridPremium
        rows={rows}
        columns={columns}
        pinnedRows={{ top: rows.length > pinnedRows.length ? pinnedRows : [], bottom: [] }}
        disableRowSelectionOnClick
        disableColumnMenu
        hideFooter={rows.length <= 100}
        sx={{
          borderTop: 0,
          '& .MuiDataGrid-cell': {
            '&:not(.impact)': { px: 1 },
            '&.impact': { pl: 0, pr: '1px', '& .MuiTypography-root': { width: '100%', textAlign: 'center' } },
          },
          '& .MuiDataGrid-cell:focus': { outline: 'none' },
          '& .MuiDataGrid-cell:focus-within': { outline: 'none' },
        }}
        getCellClassName={(params: GridCellParams<ScoreInputs>) => {
          if (impactItems.map((item) => item.key as string).includes(params.field)) {
            return 'impact'
          }
          return ''
        }}
        initialState={{
          density: 'compact',
          pinnedColumns: {
            left: ['location'],
          },
          sorting: {
            sortModel: [{ field: 'location', sort: 'asc' }],
          },
        }}
      />
    </Box>
  )
}
