import React, { FC } from 'react'
import {
  DataGridPremium,
  GridExcelExportOptions,
  GridExceljsProcessInput,
  useGridApiRef,
} from '@mui/x-data-grid-premium'
import { faArrowUpFromBracket } from '@fortawesome/pro-regular-svg-icons'
import { Button } from '@howgood/design'
import { DataGridExportContainer } from '@/components'
import { useSheet1Data, useSheet2Data } from './ImpactHistoryExport.hooks'
import { useSelector } from 'react-redux'
import { selectIsVendors } from '@/state/router'

export const ImpactHistoryExport: FC = () => {
  const apiRef = useGridApiRef()
  const sheet1Data = useSheet1Data()
  const sheet2Data = useSheet2Data()
  const isVendorsPage = useSelector(selectIsVendors)

  async function exceljsPreProcess({ workbook, worksheet: sheet1 }: GridExceljsProcessInput) {
    sheet1.name = 'Summary'
    sheet1.addRows(sheet1Data)
    const sheet2 = workbook.addWorksheet('Details')
    sheet2.addRows(sheet2Data)
  }

  function exportData() {
    const exportApi = apiRef.current
    const options: GridExcelExportOptions = {
      fileName: isVendorsPage ? 'Vendor impact history report' : 'Product version history metrics report',
      exceljsPreProcess,
    }
    exportApi.exportDataAsExcel(options)
  }

  return (
    <>
      <Button startIcon={faArrowUpFromBracket} variant="text" size="small" onClick={exportData}>
        Export
      </Button>
      <DataGridExportContainer>
        <DataGridPremium apiRef={apiRef} columns={[]} />
      </DataGridExportContainer>
    </>
  )
}
