import { ImpactScore } from '@/api'

const initialProductMetrics: ImpactScore = {
  biodiversity_impact: 0,
  labor_impact: 0,
  processing_impact: 0,
  water_impact: 0,
  blue_water_impact: 0,
  raw_blue_water_usage_impact: null,
  raw_greenhouse_gas_impact: null,
  cf_processing_impact: null,
  animal_welfare_impact: 0,
  soc_impact: 0,
  raw_soc_impact: null,
  working_conditions_impact: 0,
  land_use_impact: 0,
  raw_land_use_impact: 0,
  hive_five_impact: 0,
  environmental_impact: 0,
  supply_chain_resilience_impact: 0,
  planetary_health_percentage_impact: 0,
  processing_level_impact: 0,
  animal_diet_impact: 0,
  applegate_human_health_impact: 0,
  applegate_environmental_health_impact: 0,
  applegate_animal_health_impact: 0,
  applegate_impact: 0,
  craveability_and_sales_potential_impact: 0,
  craveability_impact: 0,
  sales_potential_impact: 0,
  applegate_health_score: 0,
  howgood_total_impact: null,
  betterThan: 0,
  labor_risk_information: [],
  howgood_one_metric: null,
  biodiversity_impact_original: 0,
  labor_impact_original: 0,
  processing_impact_original: 0,
  water_impact_original: 0,
  raw_greenhouse_gas_impact_original: 0,
  animal_welfare_impact_original: 0,
  soc_impact_original: 0,
  working_conditions_impact_original: 0,
  land_use_impact_original: 0,
  raw_land_use_impact_original: 0,
  hive_five_impact_original: 0,
  environmental_impact_original: 0,
  supply_chain_resilience_impact_original: 0,
  planetary_health_percentage_impact_original: 0,
  processing_level_impact_original: 0,
  animal_diet_impact_original: 0,
  applegate_human_health_impact_original: 0,
  applegate_environmental_health_impact_original: 0,
  applegate_animal_health_impact_original: 0,
  applegate_impact_original: 0,
  craveability_and_sales_potential_impact_original: 0,
  craveability_impact_original: 0,
  sales_potential_impact_original: 0,
  applegate_health_score_original: 0,
  howgood_total_impact_original: 0,
  carbon_footprint_annual_impact_sum: null,
  carbon_footprint_annual_impact_avg: null,
  carbon_footprint_annual_impact_count: null,
  carbon_footprint_farm_to_gate_impact_sum: null,
  carbon_footprint_farm_to_gate_impact_avg: null,
  carbon_footprint_farm_to_gate_impact_count: null,
  carbon_footprint_on_farm_processing_ghgs_impact_sum: null,
  carbon_footprint_on_farm_processing_ghgs_impact_avg: null,
  carbon_footprint_on_farm_processing_ghgs_impact_count: null,
  eco_score_impact_sum: null,
  eco_score_impact_avg: null,
  eco_score_impact_count: null,
  raw_blue_water_usage_impact_avg: null,
  raw_blue_water_usage_impact_sum: null,
  raw_blue_water_usage_impact_count: null,
  raw_soc_impact_avg: null,
  raw_soc_impact_sum: null,
  raw_soc_impact_count: null,
  scope_3_category_1_impact_avg: null,
  scope_3_category_1_impact_sum: null,
  cf_flag_impact_avg: null,
  cf_flag_impact_sum: null,
  scope_3_category_4_impact_avg: null,
  scope_3_category_4_impact_sum: null,
  cf_biogenic_processing_impact_avg: null,
  cf_biogenic_processing_impact_sum: null,
  cf_ftm_gate_impact_avg: null,
  cf_ftm_gate_impact_sum: null,
  cf_ftm_gate_impact_count: null,
  cf_full_lifecycle_impact_avg: null,
  cf_full_lifecycle_impact_sum: null,
  cf_full_lifecycle_impact_count: null,
  cf_total_upstream_transportation_impact_avg: null,
  cf_total_upstream_transportation_impact_sum: null,
  cf_total_upstream_transportation_impact_count: null,
  cf_mtm_transportation_impact_avg: null,
  cf_mtm_transportation_impact_sum: null,
  cf_mtm_transportation_impact_count: null,
  cf_manufacturing_impact_avg: null,
  cf_manufacturing_impact_sum: null,
  cf_manufacturing_impact_count: null,
  cf_cradle_to_shelf_impact_avg: null,
  cf_cradle_to_shelf_impact_sum: null,
  cf_cradle_to_shelf_impact_count: null,
  cf_total_upstream_processing_impact_avg: null,
  cf_total_upstream_processing_impact_sum: null,
  cf_total_upstream_processing_impact_count: null,
  business_potential_impact_sum: null,
  business_potential_impact_avg: null,
  cf_ftm_gate_annual_impact_sum: null,
  cf_ftm_gate_annual_impact_avg: null,
  cf_ftm_gate_annual_impact_count: null,
  cf_full_lifecycle_annual_impact_sum: null,
  cf_full_lifecycle_annual_impact_avg: null,
  cf_full_lifecycle_annual_impact_count: null,
  cf_flag_impact_count: null,
  nutrition_score: null,
  nutrition_score_count: null,
  default_origin_percent_impact: null,
}

export interface State {
  isLoading: boolean
  productMetrics: ImpactScore
}

export const initialState: State = {
  isLoading: false,
  productMetrics: initialProductMetrics,
}
