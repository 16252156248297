import React, { forwardRef, useEffect, useState } from 'react'
import { LinearProgress, LinearProgressProps } from './LinearProgress'
import { theme } from './theme'
import { Box } from '@mui/material'

interface Props extends LinearProgressProps {
  ['data-testid']?: string
  bgcolor?: string
  animate?: boolean
  reverse?: boolean
}

export const ImpactBar = forwardRef<HTMLElement, Props>(
  (
    {
      ['data-testid']: dataTestId,
      value,
      animate,
      reverse,
      bgcolor = theme.palette.impact[0],
      color = theme.palette.impact[Math.round(value / 10)],
      ...rest
    },
    ref
  ) => {
    const [progress, setProgress] = useState(0)

    useEffect(() => {
      let timeout = null
      if (animate) {
        timeout = setTimeout(() => {
          setProgress(() => value)
        }, 100)
      } else {
        setProgress(value)
      }
      return () => {
        if (timeout) clearTimeout(timeout)
      }
    }, [value, animate])

    return (
      <Box ref={ref} data-testid={dataTestId} sx={{ transform: `rotate(${reverse ? 180 : 0}deg)` }} {...rest}>
        <LinearProgress
          value={progress}
          sx={{
            bgcolor,
            '& .MuiLinearProgress-barColorPrimary': { bgcolor: color },
          }}
        />
      </Box>
    )
  }
)

ImpactBar.displayName = 'ImpactBar'
