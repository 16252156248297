import { AppState } from '@/store'
import { createSelector } from 'reselect'
import { ProductBrand } from '@/records'

const selectVendor = (state: AppState): ProductBrand => state.vendor.vendor

export const selectVendorIsLoading = (state: AppState) => state.vendor.isLoading
export const selectVendorReports = (state: AppState) => state.vendor.vendor.reports

export const selectVendorName = createSelector(selectVendor, (vendor) => vendor.name)
export const selectVendorId = createSelector(selectVendor, (vendor) => vendor.id)

export const selectVendorNotes = createSelector(selectVendor, (vendor) => vendor.workflow_notes)
