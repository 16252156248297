import { IProductStandard } from '@/records'

export function getStandards(standards: IProductStandard[]): IProductStandard[] {
  return standards.map((standard) => ({
    ...standard,
    name: standard.title,
    label: standard.title,
    value: standard.id,
  }))
}
