import { createSlice } from '@reduxjs/toolkit'
import { AllContentfulData, getContentfulData } from './contentfulData.thunk'

export interface State {
  isLoading: boolean
  contentfulData: AllContentfulData
  isError: boolean
}
export const initialState: State = {
  isLoading: true, // Must be initialized to true to prevent the dashboard from loading on first render
  contentfulData: {
    attribute: [],
    carbonAccounting: [],
    metric: [],
    requestVendorData: [],
    methodology: [],
    dataHealthIndicator: [],
    formulaIcon: [],
    document: [],
    news: [],
    tooltip: [],
    link: [],
  },
  isError: false,
}

const contentfulDataSlice = createSlice({
  name: 'contentfulData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getContentfulData.pending, (state) => {
      state.isLoading = true
      state.isError = false
    })
    builder.addCase(getContentfulData.fulfilled, (state, action) => {
      state.isLoading = false
      state.contentfulData = action.payload
      state.isError = false
    })
    builder.addCase(getContentfulData.rejected, (state, action) => {
      console.error(action.error)
      state.isLoading = false
      state.contentfulData = initialState.contentfulData
      state.isError = true
    })
  },
})

export const contentfulDataReducer = contentfulDataSlice.reducer
