import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MetricTile } from '@howgood/design'
import { selectWarningText } from '@/state/recipe'
import { DRAFT, selectPageView, selectScoreType } from '@/state/pageSettings'
import { CardData } from '@/records/scoreCardData'
import { selectIsInitiative, selectIsRollup } from '@/state/router'
import { selectIsScorecardLoading } from '@/selectors/selectIsScorecardLoading'
import { selectFavoriteTiles, setFavoriteScores } from '@/state/preferences'
import { selectDisplayedProductGoals } from '@/state/productOverview'
import { getGoalSpreadColor, getScoreChangeColor } from '@/utils/getScoreChangeColor'
import { ContentfulTooltip } from '../ContentfulTooltip'
import { selectCurrentScenarioGoals } from '@/state/initiatives'
import { selectIsSupplierConnect } from '@/state/organization'

const hasRealValue = (val: number): boolean => !isNaN(val) && val !== null

interface ScoreCardProps {
  card: CardData
  isFavorites?: boolean
  highlight?: boolean
}

export const ScoreCard: FC<ScoreCardProps> = ({ card, isFavorites = false, highlight = false }) => {
  const dispatch = useDispatch()
  const favorites = useSelector(selectFavoriteTiles)
  const view = useSelector(selectPageView)
  const isRollup = useSelector(selectIsRollup)
  const isLoading = useSelector(selectIsScorecardLoading)
  const isInitiative = useSelector(selectIsInitiative)
  const warningText = useSelector(selectWarningText)
  const scoreTypeSetting = useSelector(selectScoreType)
  const productGoals = useSelector(selectDisplayedProductGoals)
  const rollupGoals = useSelector(selectCurrentScenarioGoals)
  const isSupplierConnect = useSelector(selectIsSupplierConnect)
  const noChanges = !card.changeSinceSaved
  const subtitle = card.subTitle ? card.subTitle.replace('Carbon Lifecycle ', '') : ''
  const favorite = favorites.includes(card.key)
  const showRollupSelector = Boolean(isRollup && (hasRealValue(card.avg) || hasRealValue(card.sum)))
  const scoreType = showRollupSelector
    ? card.contentfulEntry?.units && scoreTypeSetting === 'displayedAvg' && hasRealValue(card.avg)
      ? 'displayedAvg'
      : card.contentfulEntry?.units && scoreTypeSetting === 'displayedSum' && hasRealValue(card.sum)
      ? 'displayedSum'
      : 'value'
    : 'value'
  const score = scoreType === 'displayedAvg' ? card.avg : scoreType === 'displayedSum' ? card.sum : card.score
  const hasScore =
    ['number', 'string', 'boolean'].includes(typeof score) || Boolean(card.value) || Boolean(card.scoreRange)
  const disabled = view === DRAFT && !hasScore && !isRollup
  const goalScore = isRollup ? rollupGoals[card.contentfulEntry?.key] : productGoals[card.contentfulEntry?.key]
  const goalSpread = typeof goalScore === 'number' && typeof score === 'number' ? (score as number) - goalScore : null
  const changeColor = getScoreChangeColor({
    metricKey: card.key,
    units: card.contentfulEntry?.units,
    change: card.changeSinceSaved,
  })
  const goalSpreadColor = getGoalSpreadColor({
    metricKey: card.key,
    units: card.contentfulEntry?.units,
    change: goalSpread,
  })

  const handleFavoriteIconClicked = () => {
    const newFavorites = favorites.includes(card.key)
      ? favorites.filter((f) => f !== card.key)
      : [...favorites, card.key]
    dispatch(setFavoriteScores(newFavorites))
  }

  return (
    <MetricTile
      data-testid={`${card.key}-card`}
      category={isFavorites ? card.category : null}
      favorite={favorite}
      handleFavorite={handleFavoriteIconClicked}
      title={card.label}
      titleTooltip={({ children }) => <ContentfulTooltip content={card.contentfulEntry}>{children}</ContentfulTooltip>}
      subtitle={subtitle}
      score={score as number}
      scoreOverride={card.value}
      scoreRange={card.scoreRange}
      goalScore={goalScore}
      units={card.contentfulEntry?.units?.replace(' blue water', '')}
      change={noChanges || view !== DRAFT ? null : card.changeSinceSaved}
      changeSign={card.changeSinceSaved > 0 ? 'positive' : 'negative'}
      changeColor={changeColor}
      showChange={!noChanges && view === DRAFT}
      warningText={
        isLoading || !warningText
          ? null
          : `Displayed scores may not be accurate: ${warningText} Contact support (support@howgood.com) for more information.`
      }
      noChangeText={
        isRollup || view !== DRAFT || hasScore ? null : card.contentfulEntry?.helpText || 'Requires other details'
      }
      goalSpread={goalSpread}
      goalSpreadSign={goalSpread > 0 ? 'positive' : 'negative'}
      goalSpreadColor={goalSpreadColor}
      size={card.size}
      // scenario page scorecards do not update on filter change
      loading={isLoading && !isInitiative}
      disabled={disabled}
      hideFavoriteIcon={isSupplierConnect}
      highlight={highlight}
    />
  )
}
