import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MenuItem, Stack, Text } from '@howgood/design'

import { selectPageView, setView, View } from '@/state/pageSettings'

interface ViewSelectOptionProps {
  viewOption: View
  title: string
  description?: string[]
}

export const ViewSelectOption: FC<ViewSelectOptionProps> = ({ viewOption, title, description = [] }) => {
  const dispatch = useDispatch()
  const pageView = useSelector(selectPageView)

  return (
    <Stack>
      <MenuItem onClick={() => dispatch(setView(viewOption))}>
        <Stack>
          <Text variant="body1" color={pageView === viewOption ? 'primary.main' : null}>
            {title}
          </Text>
          {!!description.length &&
            description.map((descriptionText) => (
              <Stack key={descriptionText} mt={0.5}>
                <Text variant="caption" textWrap="wrap">
                  {descriptionText}
                </Text>
              </Stack>
            ))}
        </Stack>
      </MenuItem>
    </Stack>
  )
}
