import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  createInitiative,
  createInitiativeWithBaseline,
  createScenario,
  getScenarioImpactScores,
  getInitiatives,
  initializeScenarioPage,
  updateScenario,
  getScenarios,
  getInitiativeDetail,
  deleteScenario,
  getInitiativeScores,
  fetchAllClones,
  deleteInitiative,
} from './initiatives.thunk'
import { InitiativeBasic, Scenario } from '@/records/Initiatives'
import { Workspace } from '@/records'
import { GroupedImpactScores } from './initiatives.requests'

export type InitiativeGroupByMethod = 'workspaces' | 'vendors'

export interface NewInitiative {
  initiativeName: string
  scenarioName: string
  baselineYear: number
  targetYear: number
  workspaceIds?: number[]
  vendorIds?: number[]
}

export interface ProductClone {
  pk: number
  name: string
  scenarios: Scenario[] // Scenarios that include the clone - SHOULD BE JUST ONE!
  source_product: { id: number }
  workspaces: Workspace[]
}

export interface VersionData {
  sourceProductId: number
  scenarios: number[] // Scenarios that include the source product i.e. where it hasn't been cloned
  clones: ProductClone[] // Clones of the source product
}

export const scenarioTabs = ['workspaces', 'vendors', 'formulas'] as const
export type ScenarioTab = typeof scenarioTabs[number]

export interface State {
  isCreatingInitiative: boolean
  isLoading: boolean
  isScenarioLoading: boolean
  isScenarioScoresLoading: boolean
  currentInitiative: number
  initiatives: InitiativeBasic[]
  initiativeScores: GroupedImpactScores
  currentScenario: number
  scenarios: Scenario[]
  scenarioScores: GroupedImpactScores
  newInitiative: NewInitiative
  chartMetric: string
  scenarioPageTab: ScenarioTab
  sourceProductVersionData: VersionData
  createNewGroupByMethod: InitiativeGroupByMethod
}

export const initialState: State = {
  isCreatingInitiative: false,
  isLoading: true,
  isScenarioLoading: false,
  isScenarioScoresLoading: false,
  currentInitiative: null,
  initiatives: [],
  initiativeScores: {},
  currentScenario: null,
  scenarios: [],
  scenarioScores: {},
  newInitiative: null,
  chartMetric: '',
  scenarioPageTab: null,
  sourceProductVersionData: null,
  createNewGroupByMethod: 'workspaces',
}

const initiativesSlice = createSlice({
  name: 'initiatives',
  initialState,
  reducers: {
    setNewInitiative: (state, action: PayloadAction<NewInitiative>) => {
      state.newInitiative = action.payload
    },
    addNewInitiative: (state, action: PayloadAction<InitiativeBasic>) => {
      state.initiatives = [...state.initiatives, action.payload]
    },
    setCurrentInitiative: (state, action: PayloadAction<number>) => {
      state.currentInitiative = action.payload
    },
    setChartMetric: (state, action: PayloadAction<string>) => {
      state.chartMetric = action.payload
    },
    setCurrentScenario: (state, action: PayloadAction<number>) => {
      state.currentScenario = action.payload
    },
    setScenarioPageTab: (state, action: PayloadAction<ScenarioTab>) => {
      state.scenarioPageTab = action.payload
    },
    setCreateNewInitiativeGroupByMethod: (state, action: PayloadAction<InitiativeGroupByMethod>) => {
      state.createNewGroupByMethod = action.payload
    },
    // Called from `updateProduct` after creating a new clone so we can update the source product version data
    updateSourceProductVersionData: (state, action: PayloadAction<{ scenarioId: number; clone: ProductClone }>) => {
      // Remove the scenario id from the list of scenarios that don't yet have a clone
      state.sourceProductVersionData.scenarios = state.sourceProductVersionData.scenarios.filter(
        (id) => id !== action.payload.scenarioId
      )
      // Add the new clone to the list of clones
      state.sourceProductVersionData.clones.push(action.payload.clone)
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getInitiatives.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getInitiatives.fulfilled, (state, action) => {
      state.initiatives = action.payload
      state.isLoading = false
    })
    builder.addCase(getInitiatives.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(getInitiativeDetail.fulfilled, (state, action) => {
      state.initiatives = action.payload
    })
    builder.addCase(getInitiativeScores.pending, (state) => {
      state.isScenarioScoresLoading = true
    })
    builder.addCase(getInitiativeScores.fulfilled, (state, action) => {
      state.initiativeScores = action.payload
      state.isScenarioScoresLoading = false
    })
    builder.addCase(getInitiativeScores.rejected, (state) => {
      state.isScenarioScoresLoading = false
    })
    builder.addCase(getScenarios.pending, (state) => {
      state.isScenarioLoading = true
    })
    builder.addCase(getScenarios.fulfilled, (state, action) => {
      state.isScenarioLoading = false
      state.scenarios = action.payload
    })
    builder.addCase(getScenarios.rejected, (state) => {
      state.isScenarioLoading = false
    })
    builder.addCase(getScenarioImpactScores.pending, (state) => {
      state.isScenarioScoresLoading = true
    })
    builder.addCase(getScenarioImpactScores.fulfilled, (state, action) => {
      const { scenarioScores } = action.payload
      state.scenarioScores = scenarioScores
      state.isScenarioScoresLoading = false
    })
    builder.addCase(getScenarioImpactScores.rejected, (state) => {
      state.isScenarioScoresLoading = false
    })
    builder.addCase(createInitiativeWithBaseline.pending, (state) => {
      state.isCreatingInitiative = true
    })
    builder.addCase(createInitiativeWithBaseline.rejected, (state) => {
      state.isCreatingInitiative = false
      state.newInitiative = null
    })
    builder.addCase(createInitiative.fulfilled, (state, action) => {
      state.isCreatingInitiative = false
      state.newInitiative = null
      state.initiativeScores = action.payload
    })
    builder.addCase(createInitiative.rejected, (state) => {
      state.isCreatingInitiative = false
      state.newInitiative = null
    })
    builder.addCase(createScenario.fulfilled, (state, action) => {
      const { scenarios, scenarioScores } = action.payload
      state.scenarios = scenarios
      state.scenarioScores = scenarioScores
    })
    builder.addCase(initializeScenarioPage.fulfilled, (state, action) => {
      const { updatedScenarios, scenarioId, scenarioPageTab } = action.payload
      state.scenarios = updatedScenarios
      state.currentScenario = scenarioId
      state.scenarioPageTab = scenarioPageTab
    })
    builder.addCase(updateScenario.fulfilled, (state, action) => {
      state.scenarios = action.payload
    })
    builder.addCase(deleteScenario.fulfilled, (state, action) => {
      state.scenarios = state.scenarios.filter((scenario) => scenario.id !== action.payload)
    })

    builder.addCase(fetchAllClones.fulfilled, (state, action) => {
      state.sourceProductVersionData = action.payload
    })
    builder.addCase(fetchAllClones.rejected, (state) => {
      state.sourceProductVersionData = null
    })
    builder.addCase(deleteInitiative.fulfilled, (state, action) => {
      state.initiatives = action.payload
    })
  },
})

export const {
  setNewInitiative,
  addNewInitiative,
  setCurrentInitiative,
  setChartMetric,
  setCurrentScenario,
  setScenarioPageTab,
  setCreateNewInitiativeGroupByMethod,
  updateSourceProductVersionData,
} = initiativesSlice.actions

export const initiativesReducer = initiativesSlice.reducer
