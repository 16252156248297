import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { Text, Tooltip } from '@howgood/design'

import { createFormulationsSearchParams } from '@/utils/getUrl'
import { MATERIALS, Workspace } from '@/records'
import { selectCurrentInitiativeId, selectCurrentScenarioId, setScenarioPageTab } from '@/state/initiatives'
import { useNavigate } from '@/hooks'
import { updateFiltersUrlAndProducts } from '@/state/products'
import { createIWorkspaceOption } from '@/utils/workspace'

interface WorkspaceNameCellParams extends GridRenderCellParams {
  value?: Workspace
}

export const WorkspaceNameCell: FC<WorkspaceNameCellParams> = (params) => {
  const dispatch = useDispatch()
  const { value: workspace } = params
  const { name, id } = workspace
  const searchParams = createFormulationsSearchParams({ workspaces: [id] })
  const { navigateTo } = useNavigate()
  const currentScenarioId = useSelector(selectCurrentScenarioId)
  const currentInitiativeId = useSelector(selectCurrentInitiativeId)

  const handleClickName = () => {
    if (currentInitiativeId && currentScenarioId) {
      dispatch(updateFiltersUrlAndProducts({ updatedFilters: { workspaces: [createIWorkspaceOption(workspace)] } }))
      dispatch(setScenarioPageTab('formulas'))
    } else {
      navigateTo(`/${workspace.workspace_type === MATERIALS ? 'materials' : 'products'}${searchParams}`)
    }
  }

  return (
    <Tooltip title={name}>
      <Text
        onClick={handleClickName}
        variant="body2"
        overflow="hidden"
        textOverflow="ellipsis"
        fontWeight="bold"
        data-testid={`workspace-${name.replaceAll(' ', '')}`}
      >
        {name}
      </Text>
    </Tooltip>
  )
}
