import { useCallback, MouseEvent } from 'react'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { MuiEvent } from '@mui/x-data-grid-premium'
import { selectIsFormulaModified, selectIsFormulaOveriewError } from '@/state/recipe'
import { interceptUnsavedFormulaChanges } from '@/state/pageSettings'
import { clearProductOverview } from '@/state/productOverview'
import { selectIsProcurement } from '@/state/router'

export const useNavigate = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const isFormulaModified = useSelector(selectIsFormulaModified)
  const loadError = useSelector(selectIsFormulaOveriewError)

  const navigateTo = useCallback(
    (url: string) => {
      history.push(url)
    },
    [history]
  )

  /**
   * @param url The target URL
   * @param e (Optional) Pass the click event to support opening in a new tab via cmd+click or ctrl+click
   */
  const safelyNavigateTo = async (
    url: string,
    e: MouseEvent<HTMLAnchorElement> | MouseEvent<HTMLDivElement> | MuiEvent<MouseEvent<HTMLElement>> | null = null
  ) => {
    if (e?.ctrlKey || e?.metaKey) {
      window.open(url, '_blank')
      // only check modified if coming from formula page
    } else if (!loadError && isFormulaModified) {
      dispatch(interceptUnsavedFormulaChanges(url))
    } else {
      navigateTo(url)
      dispatch(clearProductOverview())
    }

    // Required for sidebar items with an <a> link `href` used for the browser's default context menu
    // If an <a> link has an `href` and an `onClick` handler, prevent the `href` (`onClick` is processed first)
    if (e) e.preventDefault()
  }

  return { navigateTo, safelyNavigateTo }
}

export const useNavigateToFormula = () => {
  const isProcurement = useSelector(selectIsProcurement)
  const { safelyNavigateTo } = useNavigate()
  return useCallback(
    (id: string = null, newTab: boolean = false, searchParams?: string) => {
      let url = id ? (isProcurement ? `/materials/${id}` : `/products/${id}`) : '/formulas'
      if (searchParams) {
        url += `/?${searchParams}`
      }
      if (newTab) {
        window.open(url, '_blank')
        return
      }
      safelyNavigateTo(url)
    },
    [safelyNavigateTo, isProcurement]
  )
}
