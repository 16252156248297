import React, { FC, ReactNode } from 'react'
import { formatNumber } from '@howgood/utils'
import { Text } from '../Text'
import { Stack } from '../Stack'
import { CircularProgress } from '../CircularProgress'
import { Sign, Size } from './types'
import { Tooltip } from '../Tooltip'

interface Props {
  score: number
  scoreOverride: ReactNode
  scoreRange: string
  units: string
  goal: boolean
  goalScore: number
  goalSpread: number
  goalSpreadSign: Sign
  goalSpreadColor: string
  size: Size
  loading: boolean
  warningText: string
  contentOverride?: ReactNode
}

const fontSizeMap: Record<Size, { fontSize: number; lineHeight: string }> = {
  xs: { fontSize: 24, lineHeight: '24px' },
  sm: { fontSize: 32, lineHeight: '32px' },
  md: { fontSize: 64, lineHeight: '54px' },
  lg: { fontSize: 80, lineHeight: '64px' },
}

export const MetricTileScore: FC<Props> = ({
  score,
  scoreOverride,
  scoreRange,
  units,
  goal,
  goalScore,
  goalSpread,
  goalSpreadSign,
  goalSpreadColor,
  size,
  loading,
  warningText,
  contentOverride,
}) => {
  const { fontSize, lineHeight } = fontSizeMap[size]
  const scoreFormatted = scoreRange || formatNumber(score)
  const goalScoreFormatted = formatNumber(goalScore)
  const goalSpreadFormatted = typeof goalSpread === 'number' ? formatNumber(Math.abs(goalSpread)) : null

  if (contentOverride) {
    return (
      <Stack width="100%">
        {loading ? (
          <Stack height={lineHeight} justifyContent="center">
            <CircularProgress data-testid="metric-tile-loading-icon" size={24} />
          </Stack>
        ) : (
          contentOverride
        )}
      </Stack>
    )
  }

  return (
    <Stack>
      <Stack flexDirection="row" justifyContent="space-between" alignItems="flex-end">
        {loading ? (
          <Stack height={lineHeight} justifyContent="center">
            <CircularProgress data-testid="metric-tile-loading-icon" size={24} />
          </Stack>
        ) : (
          <Tooltip title={warningText}>
            <Stack id="metric-tile-score" data-testid="metric-tile-score">
              {scoreOverride || (
                <Text fontSize={fontSize} lineHeight={lineHeight} fontWeight={700}>
                  {scoreFormatted || '- -'}
                </Text>
              )}
            </Stack>
          </Tooltip>
        )}
        {goal && (
          <Text
            data-testid="metric-tile-spread"
            fontSize={fontSizeMap.xs.fontSize}
            lineHeight={fontSizeMap.xs.lineHeight}
            fontWeight={700}
            color={goalSpread === 0 ? 'success.main' : goalSpreadColor}
          >
            {goalSpread === 0 ? '' : goalSpreadSign === 'negative' ? '-' : '+'}
            {goalSpreadFormatted}
          </Text>
        )}
      </Stack>
      <Stack flexDirection="row" justifyContent="space-between">
        <Stack width={goal ? '50%' : '100%'}>
          {scoreFormatted && size === 'sm' && (
            <Tooltip title={units}>
              <Text variant="caption" textOverflow="ellipsis" noWrap>
                {units}
              </Text>
            </Tooltip>
          )}
        </Stack>
        {goal && (
          <Stack data-testid="metric-tile-goal" width="50%" flexDirection="row" justifyContent="flex-end" gap={0.5}>
            <Text variant="caption">From goal of </Text>
            <Text variant="caption" fontWeight={600} color="info.main">
              {goalScoreFormatted}
            </Text>
          </Stack>
        )}
      </Stack>
    </Stack>
  )
}
