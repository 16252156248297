import React, { FC, useState } from 'react'
import isEqual from 'lodash/isEqual'
import { defaultFormulationStatusesForAnalytics, formulationStatuses, FormulationStatusOption } from '@/records'
import { CheckboxesDropdown, CheckboxOption } from '../CheckboxesDropdown/CheckboxesDropdown'

interface Props {
  filteredStatuses: FormulationStatusOption[]
  handleChange: (newStatuses: FormulationStatusOption[]) => void
}

export const FormulationStatusDropdownFilter: FC<Props> = ({ filteredStatuses, handleChange }) => {
  const [checkedStatuses, setCheckStatuses] = useState(filteredStatuses)

  const handleStatusOptionCheckChange = (option: CheckboxOption) => {
    let updatedStatuses = [...checkedStatuses]
    const formulationStatus = formulationStatuses.find((st) => st.value === option.value)
    if (checkedStatuses.length) {
      const matchStatus = checkedStatuses.find((status) => status.value === formulationStatus.value)
      updatedStatuses = matchStatus
        ? checkedStatuses.filter((st) => st.value !== formulationStatus.value)
        : checkedStatuses.concat(formulationStatus)
    } else if (!defaultFormulationStatusesForAnalytics.includes(formulationStatus)) {
      updatedStatuses = defaultFormulationStatusesForAnalytics.concat(formulationStatus)
    } else {
      updatedStatuses = defaultFormulationStatusesForAnalytics.filter((st) => st.value !== formulationStatus.value)
    }
    setCheckStatuses(updatedStatuses)
  }

  const onCloseStatusesDropdown = () => {
    if (!isEqual(filteredStatuses, checkedStatuses)) {
      handleChange(checkedStatuses)
    }
  }

  const statusCheckboxesDropdownOptions: CheckboxOption[] = formulationStatuses.map((formulationStatus) => ({
    key: formulationStatus.value,
    label: formulationStatus.label,
    value: formulationStatus.value,
    checked: checkedStatuses?.length
      ? !!checkedStatuses?.find((st) => st.value === formulationStatus.value)
      : defaultFormulationStatusesForAnalytics.includes(formulationStatus),
  }))

  return (
    <CheckboxesDropdown
      data-pendo-id="Status"
      options={statusCheckboxesDropdownOptions}
      onOptionCheckChange={handleStatusOptionCheckChange}
      label="Status"
      onCloseDropdown={onCloseStatusesDropdown}
    />
  )
}
