import { ImpactScore } from '@/api'

// Most of the `locked_claims` scores come in as strings, so convert to numbers
export const convertLockedScoresToNumbers = (lockedScores: ImpactScore): ImpactScore => {
  const scores = Object.entries(lockedScores || {}).reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key]: typeof value === 'string' ? +value : value,
    }
  }, {})
  return scores
}
