import React from 'react'
import { GridColumnHeaderParams } from '@mui/x-data-grid-premium'
import { Tooltip, Text } from '@howgood/design'

export const NonMetricHeader = (params: GridColumnHeaderParams) => {
  return (
    <Tooltip
      title={
        <Text variant="body2" fontWeight={600}>
          {params.colDef.headerName}
        </Text>
      }
    >
      <Text variant="body2" fontWeight={600}>
        {params.colDef.headerName}
      </Text>
    </Tooltip>
  )
}
