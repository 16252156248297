import React, { useMemo, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium'
import { faCheckSquare, faObjectsAlignTop } from '@fortawesome/pro-regular-svg-icons'
import { Box, IconButton, Input, Text, Tooltip, Stack } from '@howgood/design'
import { OriginLocationMapping } from '@/state/originLocations'
import { selectGridSettings } from '@/state/preferences'
import { selectContentfulIngredientImpactData } from '@/state/recipe'
import { getScore, ScoreInputs } from '../../../util/getScore'
import { ScoreCellParams, ScoreCell } from '../Score/ScoreCell'
import { HeaderRenderer } from './SourceGridHeader'

interface SourceGridRenderCellParams extends GridRenderCellParams {
  value?: { source: OriginLocationMapping }
}

export function useSourceGridColumns(
  handleLocationSearch: (searchText: string) => void,
  handleLocationClick: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>, source: OriginLocationMapping) => void,
  handlePinnedRowsChanged: (rowIds: number[]) => void
): GridColDef[] {
  const gridSettings = useSelector(selectGridSettings)
  const allImpactItems = useSelector(selectContentfulIngredientImpactData)
  const impactColumns = allImpactItems.filter((impact) => !gridSettings.hiddenCols.includes(impact.key))

  const [pinnedRowIds, setPinnedRowIds] = useState<number[]>([])

  const handleIconButtonClicked = useCallback(
    (rowId: number) => {
      const newPinned = pinnedRowIds.includes(rowId)
        ? pinnedRowIds.filter((region) => region !== rowId)
        : [...pinnedRowIds, rowId]
      setPinnedRowIds(newPinned)
      handlePinnedRowsChanged(newPinned)
    },
    [handlePinnedRowsChanged, pinnedRowIds]
  )

  return useMemo(
    () => [
      {
        field: 'location',
        headerName: 'Location',
        width: 165,
        minWidth: 165,
        renderHeader: () => (
          <Box mb={0.5}>
            <Input
              type="search"
              onChange={(e) => handleLocationSearch(e.target.value)}
              onClick={(e) => e.stopPropagation()}
              placeholder="Location search"
            />
          </Box>
        ),
        renderCell: (params: SourceGridRenderCellParams) => {
          const locationName = params.value.source.region_name
          return (
            <Stack
              direction="row"
              width="100%"
              alignItems="center"
              sx={{ cursor: 'pointer' }}
              onClick={(e) => handleLocationClick(e, params.value.source)}
            >
              <Tooltip title={locationName.length > 15 ? locationName : ''}>
                <Box flex={1}>
                  <Text textOverflow="ellipsis" noWrap>
                    {locationName}
                  </Text>
                </Box>
              </Tooltip>
              <Tooltip
                title={pinnedRowIds.includes(params.row.id) ? 'Unpin row' : 'Pin row to top to allow comparison'}
              >
                <IconButton
                  size="small"
                  icon={pinnedRowIds.includes(params.row.id) ? faCheckSquare : faObjectsAlignTop}
                  onClick={(e) => {
                    e.stopPropagation()
                    handleIconButtonClicked(params.row.id)
                  }}
                />
              </Tooltip>
            </Stack>
          )
        },
        sortComparator: (a, b) => a.source.region_name?.localeCompare(b.source.region_name),
      },
      ...impactColumns.map((impact) => {
        const title = impact.title === 'Carbon Footprint' ? impact.subtitle : impact.title
        return {
          field: impact.key,
          headerName: title,
          flex: 1,
          minWidth: 80,
          renderCell: (params: ScoreCellParams) => <ScoreCell {...params} />,
          renderHeader: () => <HeaderRenderer title={title} units={impact.units} />,
          sortComparator: (a: ScoreInputs, b: ScoreInputs) => getScore(a).rawScore - getScore(b).rawScore,
        }
      }),
    ],
    [impactColumns, handleLocationSearch, pinnedRowIds, handleLocationClick, handleIconButtonClicked]
  )
}
