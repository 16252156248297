import React, { FC } from 'react'
import { Stack, Text, Tooltip } from '@howgood/design'
import { ProductListRenderCellParams } from './ProductGridTypes'
import { formatNumber } from '@howgood/utils'

interface Props extends ProductListRenderCellParams {
  value: number
}

export const PercentageRenderer: FC<Props> = ({ value, id, colDef }) => {
  if (!value) {
    return null
  }

  return (
    <Stack
      data-testid={`percent-${colDef.field}-${id}`}
      direction="row"
      height="100%"
      alignItems="center"
      justifyContent="center"
    >
      <Tooltip title={`${formatNumber(value * 100, 3)}%`}>
        <Text variant="body2">{formatNumber(value * 100)}%</Text>
      </Tooltip>
    </Stack>
  )
}
