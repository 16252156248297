import { createAsyncThunk } from '@reduxjs/toolkit'
import { AppState } from '@/store'
import { fetchOptimalGhgPotential, OptimalGhgPotential } from './optimalGhgPotential.requests'

export const getOptimalGhgPotential = createAsyncThunk<OptimalGhgPotential, number, { state: AppState }>(
  'optimalGhgPotential/getOptimalGhgPotential',
  async (id) => {
    return await fetchOptimalGhgPotential(id)
  }
)
