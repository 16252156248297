import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectStatusFilters, updateFiltersUrlAndProducts } from '@/state/products'
import { FormulationStatusDropdownFilter } from '../Shared/Filters/FormulationStatusDropdownFilter'
import { FormulationStatusOption } from '@/records'

export const FormulationStatusProductFilter: FC = () => {
  const dispatch = useDispatch()
  const formulationStatusFilters = useSelector(selectStatusFilters)

  const handleChange = (newStatuses: FormulationStatusOption[]) => {
    dispatch(updateFiltersUrlAndProducts({ updatedFilters: { statuses: newStatuses } }))
  }

  return <FormulationStatusDropdownFilter handleChange={handleChange} filteredStatuses={formulationStatusFilters} />
}
