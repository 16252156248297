import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import useSWR from 'swr'
import { selectWorkspaces, selectWorkspacesIds } from '@/state/workspaces'
import { fetchFormulationTagsByQuery } from '@/utils/formulationTagsSearch'
import { isDefaultWorkspace } from '@/records'

export interface WorkspaceOption {
  label: string
  value: number | 'no' | null
}

export interface FormulationTagOption {
  label: string
  value: string | 'no' | null
}

export type PortfolioOption = WorkspaceOption | FormulationTagOption

export const allPortfolio: PortfolioOption = { label: 'All Products', value: null }
const noPortfolio: PortfolioOption = { label: 'No Portfolio Comparison', value: 'no' }

export const useScorecardPortfolioOptions = (query: string = '') => {
  const workspaces = useSelector(selectWorkspaces)
  const workspacesIds = useSelector(selectWorkspacesIds)

  const formulationTagsSearchPayload = useMemo(
    () => ({
      filters: {
        search: query,
        workspaceIds: workspacesIds,
      },
    }),
    [query, workspacesIds]
  )

  const { data: formulationTagsHits } = useSWR(formulationTagsSearchPayload, fetchFormulationTagsByQuery, {
    fallbackData: [],
  })

  const workspaceOptions = useMemo(
    () =>
      [
        allPortfolio,
        ...workspaces
          .filter((w) => !isDefaultWorkspace(w.workspace_type))
          .map((w) => ({
            value: w.id,
            label: w.name,
          })),
        noPortfolio,
      ].filter((w) => w.label.toLowerCase().includes(query.toLowerCase())),
    [workspaces, query]
  )

  const formulationTagOptions = useMemo(() => {
    const allOptions = formulationTagsHits.map((hit) => ({ label: hit._source.name, value: hit._source.id }))

    const options = allOptions.filter((option) => {
      const firstMatched = allOptions.find(
        (opt) => opt.label.trim().toLowerCase() === option.label.trim().toLowerCase()
      )
      return firstMatched === option
    })

    return [allPortfolio, ...options, noPortfolio].filter((tag) =>
      tag.label.toLowerCase().includes(query.toLowerCase())
    )
  }, [formulationTagsHits, query])

  return { workspaceOptions, formulationTagOptions }
}
