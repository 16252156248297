import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectAvailableTags } from '../state/portfolioDashboard'
import { Autocomplete, Chip, Stack, TextField, Tooltip, Text, Divider } from '@howgood/design'

interface TagAutocompleteProps {
  label: string
  selectedTags: string[]
  onChange: (inputs: string[]) => void
}

export const TagAutocomplete: FC<TagAutocompleteProps> = ({ label, selectedTags, onChange }) => {
  const availableTags = useSelector(selectAvailableTags)

  const [inputText, setInputText] = useState('')

  const handleChange = (inputs: string[]) => {
    if (inputs.find((input) => input === '*')) {
      alert("Wildcards (*) can't be used alone. Specify something like 're*' to specify tags that include 're'.")
      return
    }
    setInputText('')
    onChange(inputs)
  }

  const getTooltip = (wildcardTag: string, hint = false) => {
    const matchString = wildcardTag.replaceAll('*', '').toLowerCase()
    const matchingTags = availableTags.filter((tag) => tag.toLowerCase().includes(matchString))
    return (
      <Stack gap={0.25}>
        <Text variant="caption" fontWeight="bold">
          {matchingTags.length.toLocaleString()} tags match {wildcardTag}
        </Text>
        {hint && <Text variant="caption">Press Enter to use wildcard tag</Text>}
        <Divider />
        <Stack maxHeight={400} overflow="auto" gap={0.25}>
          {matchingTags.map((tag) => (
            <Text variant="caption" key={tag}>
              {tag}
            </Text>
          ))}
        </Stack>
      </Stack>
    )
  }

  return (
    <Stack width="100%">
      <Autocomplete
        fullWidth
        multiple
        id="filter-results-autocomplete"
        key="filter-results-autocomplete"
        options={availableTags}
        defaultValue={selectedTags}
        freeSolo
        onChange={(_event, inputs) => handleChange(inputs)}
        renderTags={(value: readonly string[], getTagProps) =>
          value.map((option: string, index: number) => (
            <Tooltip key={index} title={option.includes('*') ? getTooltip(option) : ''}>
              <Chip key={index} variant="outlined" label={option} {...getTagProps({ index })} />
            </Tooltip>
          ))
        }
        renderInput={(params) => (
          <Tooltip title={inputText.includes('*') ? getTooltip(inputText, true) : ''}>
            <TextField
              {...params}
              label={label}
              placeholder="Hint: Use * as a wildcard"
              onChange={(e) => setInputText(e.currentTarget.value)}
            />
          </Tooltip>
        )}
      />
    </Stack>
  )
}
