import React, { FC } from 'react'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { IconButton, Text } from '@howgood/design'

import { Report } from '@/state/organization'
import { fetchHelperV2 } from '@/api'
import { ReportWithDates } from './ReportHistory'
import { faArrowDownToBracket } from '@fortawesome/pro-regular-svg-icons'

export interface ReportHistoryCellProps extends GridRenderCellParams {
  value: ReportWithDates
  cellType: 'date' | 'name' | 'download'
}

export const ReportHistoryCell: FC<ReportHistoryCellProps> = ({ value, cellType }) => {
  const { date_created, id, name } = value

  const dateString = date_created.toLocaleTimeString('en-us', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  })

  const text = cellType === 'date' ? dateString : cellType === 'name' ? name : ''

  const handleDownload = async () => {
    const response = await fetchHelperV2<Report>({ url: `accounts/reports/${id}/` })

    const link = document.createElement('a')
    link.setAttribute('href', response.s3_url)
    link.setAttribute('download', response.name)
    link.setAttribute('type', 'data:text/csv;charset=utf-8')
    link.style.display = 'none'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <a href={null} key={`${cellType}-${id}`} onClick={handleDownload}>
      {cellType === 'download' ? <IconButton icon={faArrowDownToBracket} /> : <Text variant="body2">{text}</Text>}
    </a>
  )
}
