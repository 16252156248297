import React, { FC } from 'react'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { Tooltip, Text } from '@howgood/design'

export const ManufacturingTypesCell: FC<GridRenderCellParams> = ({ value }) => {
  if (!value.length) {
    return <>--</>
  }

  const manufacturingTypesString = value.join(', ')

  return (
    <Tooltip maxWidth={400} title={manufacturingTypesString}>
      <Text overflow="hidden" textOverflow="ellipsis" variant="body2">
        {manufacturingTypesString}
      </Text>
    </Tooltip>
  )
}
