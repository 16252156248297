import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { Stack } from '@howgood/design'

import { Products } from '@/components'
import { ContainsComponentFilter } from '@/components/ProductsFilters/ContainsComponentFilter'
import { FormulaNameFilter } from '@/components/ProductsFilters/FormulaNameFilter'
import { TagsFilter } from '@/components/ProductsFilters/TagsFilter'
import { IngredientFilter } from '@/components/ProductsFilters/IngredientFilter'
import { createIWorkspaceOption } from '@/utils/workspace'
import {
  selectCurrentInitiativeGroupByMethod,
  selectCurrentScenarioId,
  selectInitiativeBrands,
  selectInitiativeWorkspaces,
} from '@/state/initiatives'
import { Workspace } from '@/records'
import { WorkspaceProductFilter } from '@/components/ProductsFilters/WorkspaceProductFilter'
import { ScenarioVendorFilter } from './ScenarioVendorFilter'

const selectWorkspacesOptions = createSelector(selectInitiativeWorkspaces, (workspaces) => {
  return workspaces.map((w: Workspace) => createIWorkspaceOption(w))
})

export const FormulasTab: FC = () => {
  const workspaceOptions = useSelector(selectWorkspacesOptions)
  const scenarioId = useSelector(selectCurrentScenarioId)
  const groupByMethod = useSelector(selectCurrentInitiativeGroupByMethod) // 'workspaces' or 'vendors'
  const vendors = useSelector(selectInitiativeBrands)

  return (
    <>
      <Stack flexDirection="row" alignItems="center" justifyContent="stretch" gap={1} mb={2}>
        {groupByMethod === 'workspaces' && <WorkspaceProductFilter workspaceOptionOverride={workspaceOptions} />}
        {groupByMethod === 'vendors' && <ScenarioVendorFilter availableVendors={vendors} />}
        <FormulaNameFilter />
        <IngredientFilter />
        <ContainsComponentFilter />
        <TagsFilter />
      </Stack>
      <Products scenarioId={scenarioId} />
    </>
  )
}
