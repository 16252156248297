import React, { FC } from 'react'
import { Stack, Text, Tooltip } from '@howgood/design'
import { parseReportDateKey } from '@/utils/dates'
import { ClaimsReportsType } from '@/records'
import { ProductListRenderCellParams } from './ProductGridTypes'

interface Props extends ProductListRenderCellParams {
  value?: ClaimsReportsType
}

export const ReportDateRenderer: FC<Props> = ({ value: reports, id, row }) => {
  if (!reports || !reports.items?.length) {
    const lockedDate = row.locked_claims?.product_attributes?.locked_claims_timestamp
    if (lockedDate) {
      // handle the case where the product is locked but no report has been generated
      const displayDate = parseReportDateKey(lockedDate).toLocaleDateString()
      return (
        <Tooltip
          title={
            <Text variant="body2" data-testid={`report-date-tooltip-${id}`}>
              {displayDate}
            </Text>
          }
        >
          <Text variant="body2" overflow="hidden" textOverflow="ellipsis" data-testid={`report-date-${id}`}>
            {displayDate}
          </Text>
        </Tooltip>
      )
    }
    return null
  }
  const dateTimes = reports.items
    .map((item) => item.date)
    .map((date) => parseReportDateKey(date))
    .sort((a, b) => b.getTime() - a.getTime())

  return (
    <>
      <Tooltip
        // Don't display any more than 10 dates in the tooltip
        title={
          <Stack data-testid={`report-date-tooltip-${id}`}>
            {dateTimes.slice(0, 10).map((dateTime, i) => (
              <Text variant="body2" overflow="hidden" textOverflow="ellipsis" key={`${i}-${dateTime.getTime()}`}>
                {dateTime.toLocaleString()}
              </Text>
            ))}
          </Stack>
        }
      >
        <Text variant="body2" overflow="hidden" textOverflow="ellipsis" data-testid={`report-date-${id}`}>
          {dateTimes[0]?.toLocaleDateString() || ''}
        </Text>
      </Tooltip>
    </>
  )
}
