import React, { FC } from 'react'
import dayjs from 'dayjs'
import logo from '@/assets/images/footer-logo.png'
import latis from '@/assets/images/latis-footer.png'
import { PERMISSIONS_TO_FIELDS_MAP, VIEW_AGG_IMPACT_CPG_CF_F2MG_W_PKG } from '@/constants/impactScore'
import { ContentfulFieldKey } from '@/contentfully'
import { useProductOverviewExportContainerStyles } from './ProductOverviewExportContainer.styles'

interface Props {
  showGhgDisclaimer?: boolean
  displayField: ContentfulFieldKey
  isInProductComparisonScorecard?: boolean
}

export const ProductOverviewExportFooter: FC<Props> = ({
  showGhgDisclaimer,
  displayField,
  isInProductComparisonScorecard,
}) => {
  const classes = useProductOverviewExportContainerStyles()
  const currentDate = dayjs().format('M/D/YY')

  return (
    <div className={classes.footer}>
      <div className={classes.disclaimer}>
        {showGhgDisclaimer && (
          <p>
            * HowGood’s methodology for calculating GHG emissions is developed in accordance with the GHG Protocol, with
            special attention to the Product Life Cycle Accounting and Reporting Standard. This cradle to gate footprint
            includes stages from cradle to{' '}
            {displayField === PERMISSIONS_TO_FIELDS_MAP[VIEW_AGG_IMPACT_CPG_CF_F2MG_W_PKG]
              ? 'packaging'
              : 'manufacturing'}
            .
          </p>
        )}
        <p>
          The information in this report is provided “AS IS”. HowGood makes no guarantees and disclaims all warranties
          that the information provided in this report is accurate or complete. The information contained herein is
          based on assumptions, estimates, and best practices employed by HowGood's team, information you provided to
          us, as well as other information from our third party sources and other customers. HowGood shall not be
          responsible for any information provided by you, other customers, or third party sources. The information is
          intended solely for internal business purposes and for no other purpose, unless otherwise authorized by
          HowGood in writing. You release us from all liability for your use or inability to use any information
          contained in this report.
          {isInProductComparisonScorecard && (
            <>
              <br /> The underlying formula(s) for these product(s) were not created or reviewed by HowGood.
            </>
          )}
          <br /> This report was created on {currentDate}
        </p>
      </div>
      <div className={classes.separator} />
      <a href="https://howgood.com/scorecard/">
        <img className={classes.latisFooter} src={latis} />
        <img width="108px" className={classes.logoFooter} src={logo} />
      </a>
    </div>
  )
}

ProductOverviewExportFooter.displayName = 'ProductOverviewExportFooter'
