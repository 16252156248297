import React, { FC, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons'
import paywallImg from '@/assets/images/paywall-image.jpg'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ExternalLink,
  List,
  ListItem,
  Stack,
  Text,
  Icon,
} from '@howgood/design'
import {
  closeLiteUserPaywallDialog,
  selectIsLiteUserPaywallDialogOpen,
  selectLiteUserPaywallDialogRedirectPath,
} from '@/state/liteUserPaywallDialog'
import { useNavigate } from '@/hooks'

export const LiteUserPaywallDialog: FC = () => {
  const open = useSelector(selectIsLiteUserPaywallDialogOpen)
  const redirectPath = useSelector(selectLiteUserPaywallDialogRedirectPath)
  const dispatch = useDispatch()
  const { safelyNavigateTo } = useNavigate()

  const handleClose = useCallback(() => {
    dispatch(closeLiteUserPaywallDialog())
    if (redirectPath) {
      safelyNavigateTo(redirectPath)
    }
  }, [dispatch, safelyNavigateTo, redirectPath])

  if (!open) {
    return null
  }

  return (
    <Dialog
      data-testid="lite-user-paywall-dialog"
      maxWidth="sm"
      id="lite-user-paywall-dialog"
      open
      onClose={handleClose}
    >
      <DialogTitle>Unlock the world's largest product sustainability database</DialogTitle>
      <DialogContent>
        <Stack gap={1}>
          <img src={paywallImg} alt="Paywall" />
          <Text>
            Introduce ease and accuracy into your sustainability reporting and carbon reduction by leveraging HowGood's
            database of 90,000 agricultural emissions factors. The Latis platform offers out-of-the-box ingredient
            granularity, enabling food companies to more easily report on emissions, reduce carbon footprints, and make
            verified sustainability claims.
          </Text>
          <Stack>
            <Text>Enable your team with the full version of Latis to:</Text>
            <Box pl={0.8} my={-0.7}>
              <List listStyleType="disc">
                {[
                  'Automate your Product Carbon Footprinting',
                  'Discover effective abatement measures',
                  'Make 3rd party verified sustainability claims',
                ].map((item) => (
                  <ListItem key={item}>{item}</ListItem>
                ))}
              </List>
            </Box>
            <ExternalLink
              data-testid="industry-news-source"
              href="https://howgood.com"
              rel="noopener noreferrer"
              target="_blank"
              underline="hover"
            >
              <Stack flexDirection="row" alignItems="center" gap={0.5}>
                <Text color="inherit">Learn more about the full feature list</Text>
                <Icon icon={faArrowUpRightFromSquare} />
              </Stack>
            </ExternalLink>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" alignItems="center" gap={1}>
          <Button id="lite-user-paywall-dialog-close-button" onClick={handleClose}>
            Close
          </Button>
          <a href="https://www.howgood.com/contact" rel="noopener noreferrer" target="_blank">
            <Button id="lite-user-paywall-dialog-schedule-a-demo-button" color="primary">
              Schedule a demo
            </Button>
          </a>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}
