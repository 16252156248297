import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@howgood/design'
import { Basis, selectBasis, setBasis } from '../state/portfolioDashboard'

export const BasisSelect: FC = () => {
  const basis = useSelector(selectBasis)
  const dispatch = useDispatch()

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const selectedBasis = event.target.value as Basis
    dispatch(setBasis(selectedBasis))
  }

  return (
    <FormControl>
      <InputLabel id="portfolio-basis-select-label">Basis</InputLabel>
      <Select
        labelId="portfolio-basis-select-label"
        id="portfolio-basis-select"
        data-testid="portfolio-basis-select"
        value={basis}
        label="Basis"
        onChange={handleChange}
      >
        <MenuItem data-testid="portfolio-basis-avg" value="kg">
          Average Impact per Kilogram
        </MenuItem>
        <MenuItem data-testid="portfolio-basis-materials" value="inventories">
          Annual (Purchased Materials)
        </MenuItem>
        <MenuItem data-testid="portfolio-basis-sales" value="sales">
          Annual (Sales)
        </MenuItem>
      </Select>
    </FormControl>
  )
}
