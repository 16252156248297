import React, { forwardRef } from 'react'
import MuiBox, { BoxProps as MuiBoxProps } from '@mui/material/Box'

interface Props extends Omit<MuiBoxProps, 'className'> {
  ['aria-label']?: string
  ['data-pendo-id']?: string
  ['data-testid']?: string
}

export const Box = forwardRef<HTMLElement, Props>(
  (
    {
      ['aria-label']: ariaLabel,
      ['data-pendo-id']: dataPendoId,
      ['data-testid']: dataTestId,
      children,
      onClick,
      ...rest
    },
    ref
  ) => {
    return (
      <MuiBox
        ref={ref}
        aria-label={ariaLabel}
        data-pendo-id={dataPendoId}
        data-testid={dataTestId}
        onClick={onClick}
        {...rest}
      >
        {children}
      </MuiBox>
    )
  }
)

Box.displayName = 'Box'
