import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import * as Sentry from '@sentry/react'
import { faArrowUpFromBracket } from '@fortawesome/pro-regular-svg-icons'
import { Button } from '@howgood/design'

import { addMessage } from '@/state/messages'
import { downloadBaseline } from '../downloadBaseline'

export const ExportBaseline: FC<{ baseline: { id: number } }> = ({ baseline }) => {
  const dispatch = useDispatch()

  const handleDownloadBaseline = async () => {
    try {
      downloadBaseline(baseline.id)
    } catch (e) {
      dispatch(addMessage({ message: 'There was an error downloading this baseline report', severity: 'error' }))
      Sentry.captureException(e, { extra: { baseline } })
    }
  }

  if (!baseline) {
    return null
  }

  return (
    <Button
      id={`export-baseline-button-${baseline.id}`}
      data-testid={`export-baseline-button-${baseline.id}`}
      variant="contained"
      size="small"
      color="secondary"
      onClick={handleDownloadBaseline}
      startIcon={faArrowUpFromBracket}
    >
      Export Baseline Report
    </Button>
  )
}
