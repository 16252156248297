import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { IImpactMetricOption, Workspace } from '@/records'
import { GoalCell } from '@/components/GridWithMetrics/GoalCell'
import { Scenario } from '@/records/Initiatives'
import { selectScenarioWorkspaceGoalsByWorkspace, selectisScenarioLoading, updateScenario } from '@/state/initiatives'

interface CellParams extends GridRenderCellParams {
  metric: IImpactMetricOption
  value: { workspace: Workspace }
  scenario: Scenario
}

export const ScenarioWorkspaceGoalCell: FC<CellParams> = ({ metric, value, scenario }) => {
  const dispatch = useDispatch()
  const { workspace } = value
  const scenarioWorkspaceGoals = useSelector(selectScenarioWorkspaceGoalsByWorkspace(workspace.id, scenario.id))
  const isLoading = useSelector(selectisScenarioLoading)

  if (!workspace.edit || isLoading) {
    return null
  }

  const onUpdateGoal = (updatedGoal: number) => {
    const updatedGoals = {
      ...scenarioWorkspaceGoals,
      [metric.value]: updatedGoal === null ? undefined : updatedGoal,
    }
    // if this scenarioWorkspace is already in the list update it, otherwise add it
    const scenarioWorkspace = scenario.scenario_workspaces.find((sw) => sw.id === workspace.id)
    dispatch(
      updateScenario({
        id: scenario.id,
        scenario_workspaces: scenarioWorkspace
          ? scenario.scenario_workspaces.map((sw) => {
              if (sw.id === workspace.id) {
                return {
                  ...sw,
                  goals: updatedGoals,
                }
              }
              return sw
            })
          : [...scenario.scenario_workspaces, { id: workspace.id, goals: updatedGoals }],
      })
    )
  }

  return (
    <GoalCell
      metric={metric}
      id={workspace.id}
      name={workspace.name}
      metricGoal={scenarioWorkspaceGoals?.[metric.value] || null}
      onUpdateGoal={onUpdateGoal}
      editable={workspace.edit}
    />
  )
}
