import { createAsyncThunk } from '@reduxjs/toolkit'
import { AppState } from '@/store'
import { ProductDashboardSettings, ProductHit, defaultMetric } from './productDashboard.slice'
import { fetchProductData } from './productDashboard.requests'
import { estypes } from '@elastic/elasticsearch'
import { ImpactScoreResponse, fetchProductImpactScore } from '@/api'
import { Field } from '@/constants/impactScore'
import { selectAvailableMetrics, selectSelectedWorkspace } from './productDashboard.selectors'
import { selectContentfulIngredientHeatmapData } from '@/state/contentfulData'
import { selectProductDashboardSettings } from '@/state/user'
import { selectEditableWorkspaceIds } from '@/state/workspaces'

export interface ProductResults {
  productHits: ProductHit[]
  total: number
}

export interface MetricResults {
  top5: {
    kg: ProductHit[]
    inventories: ProductHit[]
    sales: ProductHit[]
  }
  totals: {
    kg: number
    inventories: number
    sales: number
  }
}

export type AggregationsByMetric = Partial<Record<Field, MetricResults>>

const createAggregationsByMetric = (aggregations: Record<string, estypes.AggregationsAggregate>, metrics: Field[]) => {
  const aggregationsByMetric: AggregationsByMetric = metrics.reduce((acc, metric) => {
    return {
      ...acc,
      [metric]: {
        top5: {
          kg: (aggregations[`top5.kg.${metric}`] as estypes.AggregationsTopHitsAggregate).hits.hits.map(
            (hit) => hit._source as ProductHit
          ),
          // For each product hit, combine the custom fields from the hit's `fields` object with the `_source` object
          inventories: (aggregations[`top5.inventories.${metric}`] as estypes.AggregationsTopHitsAggregate).hits.hits
            .filter((hit) => hit.fields) // Included only products that have inventory data
            .map((hit) => ({ ...hit._source, custom_fields: hit.fields } as ProductHit)),

          sales: (aggregations[`top5.sales.${metric}`] as estypes.AggregationsTopHitsAggregate).hits.hits
            .filter((hit) => hit.fields) // Included only products that have sales data
            .map((hit) => ({ ...hit._source, custom_fields: hit.fields } as ProductHit)),
        },

        totals: {
          kg: (aggregations[`totals.kg.${metric}`] as estypes.AggregationsSumAggregate).value as number,
          inventories: (aggregations[`totals.inventories.${metric}`] as estypes.AggregationsSumAggregate)
            .value as number,
          sales: (aggregations[`totals.sales.${metric}`] as estypes.AggregationsSumAggregate).value as number,
        },
      },
    }
  }, {})

  return aggregationsByMetric
}

interface TopProductsResults {
  workspaceProductCount: number
  aggregationsByMetric: Partial<Record<Field, MetricResults>>
}

interface InitializationResults extends TopProductsResults {
  availableMetrics: Field[]
  allWorkspaceIds: number[]
  dashboardSettings: ProductDashboardSettings
}

/**
 * Called once during dashboard initialization to get the top products for the initial metric
 */
export const initializeTopProducts = createAsyncThunk<InitializationResults, void, { state: AppState }>(
  'productDashboard/initializeTopProducts',
  async (_, { dispatch: __, getState }) => {
    const dashboardSettings = selectProductDashboardSettings(getState())
    const editableWorkspaceIds = selectEditableWorkspaceIds(getState())

    // Determine the metrics we'll make available via this dashboard (filter out those without units, e.g. biodiversity)
    const allMetrics = selectContentfulIngredientHeatmapData(getState())
    const availableMetrics = allMetrics
      // Filter out qualitative scores, but there are a couple with units defined in Contentful so filter these out too
      .filter((metric) => metric.units && !['blue_water_impact', 'processing_impact'].includes(metric.key))
      .map((metric) => metric.key as Field)

    const selectedMetric = availableMetrics.includes(dashboardSettings?.metric)
      ? dashboardSettings.metric
      : availableMetrics.includes(defaultMetric)
      ? defaultMetric
      : availableMetrics[0]

    // Get top products for the selected metric only, to minimize dashboard load time
    const { aggregations, productCount } = await fetchProductData({
      // If the workspace in the user_settings is valid, use it, otherwise use all workspaces
      workspaceIds: editableWorkspaceIds.includes(dashboardSettings?.workspace?.id)
        ? [dashboardSettings.workspace.id]
        : editableWorkspaceIds,
      // If the metric in the user_settings is valid, use it, otherwise use the default metric
      metrics: [selectedMetric],
    })

    const aggregationsByMetric = createAggregationsByMetric(aggregations, [selectedMetric])
    return {
      workspaceProductCount: productCount.value,
      availableMetrics,
      aggregationsByMetric,
      allWorkspaceIds: editableWorkspaceIds,
      dashboardSettings,
    }
  }
)

/**
 * Called when the selected workspace changes, the first time the user selects a metric, or clicks refresh
 * Since we fetch the top products for all metrics, subsequent selections won't require additional fetches
 */
export const getTopProductsAllMetrics = createAsyncThunk<TopProductsResults, void, { state: AppState }>(
  'productDashboard/getTopProductsAllMetrics',
  async (_, { dispatch: __, getState }) => {
    const metrics = selectAvailableMetrics(getState())
    const editableWorkspaceIds = selectEditableWorkspaceIds(getState())
    const selectedWorkspace = selectSelectedWorkspace(getState())

    const { aggregations, productCount } = await fetchProductData({
      workspaceIds: selectedWorkspace.id === -1 ? editableWorkspaceIds : [selectedWorkspace.id],
      metrics,
    })

    const aggregationsByMetric = createAggregationsByMetric(aggregations, metrics)
    return { aggregationsByMetric, workspaceProductCount: productCount.value }
  }
)

/**
 * Fetch the product and ingredient impact scores for the selected product
 */
export const getProductImpactScores = createAsyncThunk<ImpactScoreResponse, number, { state: AppState }>(
  'productDashboard/getProductImpactScores',
  async (productId) => {
    return await fetchProductImpactScore(productId) // Fetches the locked scores by default; live scores if no locked scores available
  }
)
