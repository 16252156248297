import React, { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Autocomplete, TextField } from '@howgood/design'
import { searchRegions } from '@/api'
import { selectDisplayedSalesAndDistribution, updateRecipeAndScores } from '@/state/recipe'
import { DRAFT, selectPageView } from '@/state/pageSettings'
import { panelStyles } from '../panelStyles'

interface LocationFieldProps {
  field: string
}

interface Location {
  id: number
  name: string
}

export const LocationAutocomplete: FC<LocationFieldProps> = ({ field }) => {
  const dispatch = useDispatch()
  const salesAndDistribution = useSelector(selectDisplayedSalesAndDistribution)
  const view = useSelector(selectPageView)

  const [locations, setLocations] = useState<Location[]>([])

  const selectedLocation = salesAndDistribution?.[field] || ''

  const handleTextChange = async (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const searchString = e.currentTarget.value
    if (searchString) {
      const response = await searchRegions(searchString)
      setLocations((response as Location[]).map((result) => ({ id: result.id, name: result.name })))
    } else {
      setLocations([])
    }
  }

  const handleLocationSelected = (_event: React.SyntheticEvent<Element, Event>, location: Location) => {
    const updatedLocation = location ?? { id: undefined, name: undefined }
    dispatch(
      updateRecipeAndScores({
        recipeUpdates: {
          sales_distribution: {
            ...salesAndDistribution,
            [field]: updatedLocation,
          },
        },
        change: location ? `Changed ${field} to ${location.name}` : `Cleared ${field}`,
      })
    )
  }

  return (
    <Autocomplete
      id={`${field}-autocomplete`}
      disabled={view !== DRAFT}
      options={locations}
      noOptionsText="Start typing"
      multiple={false}
      getOptionLabel={(option: Location) => option.name || ''}
      value={selectedLocation}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={(e, location: Location) => handleLocationSelected(e, location)}
      fullWidth
      ListboxProps={{ id: `${field}-menu` }}
      renderInput={(params) => <TextField {...params} onChange={handleTextChange} sx={panelStyles.autocomplete} />}
    />
  )
}
