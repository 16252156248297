import { batch } from 'react-redux'
import { AnyAction, createAsyncThunk, ThunkAction } from '@reduxjs/toolkit'
import isEqual from 'lodash/isEqual'

import {
  fetchHelperV2,
  fetchProduct,
  fetchProductImpactScore,
  fetchProductInventories,
  FormulationTag,
  InventoriesResponse,
  REQUESTED,
} from '@/api'
import { Workspace, SharingRequest, ScenarioProductAttributes } from '@/records'
import {
  checkHasLcaOverriddenNestedProduct,
  checkLcaOverriddenProduct,
  checkWorkspaceAccess,
  getEditWorkspace,
} from '@/utils/formulationSearch'
import { selectWorkspaces } from '@/state/workspaces'
import { setLockedProductInfo, setProductReports, updateDraftInventories } from './productOverview.slice'
import {
  selectDraftInventories,
  selectDraftProductInfo,
  selectSavedInventories,
  selectDisplayedProductGoals,
  selectSavedProductInfo,
  selectIsProductScenarioModified,
  selectDisplayedProductScenario,
} from './productOverview.selectors'
import {
  getRecipe,
  getV2IngredientsPayload,
  savePackagingToRecipe,
  saveProductError,
  saveProductIngredientError,
  saveRecipeToProduct,
  selectDraftPackaging,
  selectDraftRecipe,
  selectDraftTransportation,
  selectSavedImpactData,
  selectSavedPackaging,
  selectSavedRecipe,
  selectSavedTransportation,
  setIngredientsMoved,
  setLockedImpactData,
  updateRecipeAndScores,
} from '@/state/recipe'
import { RecipePackagingItem } from '@/state/recipe/recipe.state'
import { addMessage } from '@/state/messages'
import { AppState } from '@/store'
import { selectAbatementSplit } from '@/state/splitio'
import { PUBLISHED, SAVED, selectIsAbatementStrategiesOpen, setView } from '../pageSettings'
import { ProductResponse, ProductInfo, initialProductInfo } from './productOverview.state'
import {
  lockProductData,
  requestAddPackaging,
  requestProductSharing,
  requestDeletePackaging,
  requestProductValidation,
  requestUpdateInventory,
  generateProductReport,
  requestAddTransportation,
} from './productOverview.requests'
import {
  generateProductPayload,
  SaveAsInfo,
  generateTags,
  ShareRequestInfo,
  generateUpdatedScenarioProducts,
} from './productOverview.utils'
import { handleThunkError } from '../messages/messages.thunk'
import { selectFavoriteTiles, setFavoriteCards, setFavoriteScores } from '../preferences'
import { getNutrition } from '../nutrition'
import {
  fetchAllClones,
  ProductClone,
  selectSortedScenarios,
  updateSourceProductVersionData,
  selectCurrentScenarioId,
} from '../initiatives'
import { getOptimalGhgPotential } from '../optimalGhgPotential'
import { fetchUpdateScenarioProduct } from '../products/products.requests'
import { selectIsSupplierConnect } from '../organization'

// Create/update formula errors
const INVENTORY_ERROR =
  'There was an issue saving inventories for this formula. Please contact support@howgood.com to resolve this issue.'
const INGREDIENT_ERROR =
  'There was an issue saving ingredients for this formula. Please validate the ingredients are correct or contact support@howgood.com.'
export const FORMULA_ERROR =
  'There was an issue saving updates for this formula. Please review formula data or contact support@howgood.com.'

export const getProduct = createAsyncThunk<
  {
    product: ProductInfo
    inventories: InventoriesResponse[]
    lockedClaims: ProductInfo
  },
  { id: number; scenario: number; history: History },
  { state: AppState }
>('productOverview/getProduct', async ({ id, scenario = null, history }, { getState, dispatch, rejectWithValue }) => {
  const state = getState()
  let product: ProductResponse
  try {
    product = await fetchProduct(id)
  } catch (e) {
    if (e.message.includes('404') || e.message.includes('403')) {
      dispatch(
        addMessage({
          message: 'You do not have access to this formula or this formula does not exist.',
          severity: 'error',
        })
      )
      // @ts-ignore
      history.push('/')
      return { product: initialProductInfo, inventories: [], lockedClaims: initialProductInfo }
      // return rejectWithValue('You do not have access to this formula or this formula does not exist.')
    }
    throw e
  }
  const isThirdParty = product.is_foreign
  const favorites = selectFavoriteTiles(state)
  const workspaces = selectWorkspaces(state)
  const abatementEnabled = selectAbatementSplit(state)
  const isSupplierConnect = selectIsSupplierConnect(state)
  const abatementDrawerOpen = selectIsAbatementStrategiesOpen(state)

  if (isThirdParty && !product.locked_claims_timestamp) {
    return rejectWithValue('This published formula has not been shared with you.')
  }
  // If third-party product, we have everything we need at this point; otherwise, continue
  if (!isThirdParty) {
    batch(() => {
      // Call nutrition-score endpoint to get product's nutrition info
      dispatch(getNutrition(id))
      // Call packaging, impact-score, ingredients, and origin-locations endpoints, then generate the internal recipe
      dispatch(getRecipe(product))
      if (abatementEnabled && !isThirdParty) {
        dispatch(getOptimalGhgPotential(id))
      }
    })
  }

  const inventories = await fetchProductInventories(id, product.is_foreign)
  // For staff and superusers the BE returns all inventories, but we dont want to show them
  const filteredInventories = inventories.filter((inventory) =>
    checkWorkspaceAccess(inventory.workspace.id, workspaces)
  )
  const impactsOverriddenProduct = checkLcaOverriddenProduct(product)
  const hasLcaOverriddenNestedProduct = checkHasLcaOverriddenNestedProduct(product)

  // SupplierConnect user can only see the cradle to manufacturing gate and blue water usage (on farm) in Favorites tab
  if (isSupplierConnect) {
    dispatch(setFavoriteScores(['cf_ftm_gate_ct_verified_impact', 'raw_blue_water_usage_impact']))
    dispatch(setFavoriteCards([]))
  } else if (
    (impactsOverriddenProduct || hasLcaOverriddenNestedProduct) &&
    !favorites.includes('cf_ftm_gate_ct_verified_impact')
  ) {
    // Cradle to manufacturing gate is default favorited on formulas with an override
    dispatch(setFavoriteScores([...favorites, 'cf_ftm_gate_ct_verified_impact']))
  }

  if (scenario) {
    dispatch(fetchAllClones(product.source_product?.id || product.id)) // Fallback is uncloned source product
  }

  if (
    !scenario &&
    !abatementDrawerOpen &&
    product.formulation_status !== 'scenario' &&
    (isThirdParty ||
      impactsOverriddenProduct ||
      hasLcaOverriddenNestedProduct ||
      product.locked_claims?.product_attributes)
  ) {
    dispatch(setView(PUBLISHED))
  } else {
    dispatch(setView(SAVED))
  }

  const tags = isThirdParty ? [] : generateTags(filteredInventories, +id)

  // Don't save locked claims data with the product info; save only on productOverview.lockedProductInfo
  const { locked_claims, locked_claims_timestamp, ...productMinusLockedClaims } = product

  if (isThirdParty && locked_claims_timestamp) {
    // Fetch the locked claims impact scores for third-party products
    const lockedImpactData = await fetchProductImpactScore(id, 'locked')
    dispatch(
      setLockedImpactData({
        impactScore: lockedImpactData.product,
        time: locked_claims_timestamp,
      })
    )
  }

  return {
    product: {
      ...productMinusLockedClaims,
      workspaces: product.workspaces.filter((workspace: Workspace) => checkWorkspaceAccess(workspace.id, workspaces)),
      workspace: getEditWorkspace(product.workspaces, workspaces) || product.workspaces[0],
      tags: tags,
      date_modified: product.date_modified,
    },
    inventories: filteredInventories.map((inventory: InventoriesResponse) => ({
      ...inventory,
      validation_requests: inventory.validation_requests || [],
    })),
    // Here we're returning just the locked claims product attributes (if applicable); impact scores are fetched later
    lockedClaims: locked_claims?.product_attributes
      ? {
          ...locked_claims.product_attributes,
          workspace:
            getEditWorkspace(locked_claims.product_attributes.workspaces, workspaces) ||
            locked_claims.product_attributes.workspaces[0],
          date_modified: locked_claims_timestamp,
        }
      : initialProductInfo,
  }
})

export const updateRecipeScoresAndView = createAsyncThunk<void, boolean, { state: AppState }>(
  'productOverview/updateRecipeScoresAndView',
  async (hasError = false, { dispatch }) => {
    if (hasError) {
      await dispatch(updateRecipeAndScores({ change: 'Update/Save product with error' }))
    } else {
      dispatch(saveRecipeToProduct())
    }
    batch(() => {
      dispatch(setView(SAVED))
      dispatch(setIngredientsMoved(false))
    })
  }
)

type PromiseSettledResult = {
  status: 'fulfilled' | 'rejected'
  value?: any
  reason?: string
}
const handleProductSavedErrors = (
  ingredientsResponse: PromiseSettledResult,
  inventoriesResponse: PromiseSettledResult,
  productResponse?: PromiseSettledResult
): ThunkAction<boolean, AppState, void, AnyAction> => (dispatch, getState) => {
  const state = getState()
  const savedProduct = selectSavedProductInfo(state)
  const recipe = selectSavedRecipe(state)
  let hasError = false

  if (productResponse && productResponse.status === 'rejected') {
    dispatch(saveProductError(savedProduct))
    hasError = true
    dispatch(handleThunkError({ message: FORMULA_ERROR, error: new Error(productResponse.reason) }))
  }
  if (ingredientsResponse.status === 'rejected') {
    hasError = true
    dispatch(saveProductIngredientError(recipe.ingredients))
    dispatch(handleThunkError({ message: INGREDIENT_ERROR, error: new Error(ingredientsResponse.reason) }))
  }
  if (inventoriesResponse.status === 'rejected') {
    dispatch(handleThunkError({ message: INVENTORY_ERROR, error: new Error(inventoriesResponse.reason) }))
  }
  return hasError
}

const savePackaging = createAsyncThunk<
  void,
  { productId: number; scenarioId?: number; deleteExisting?: boolean },
  { state: AppState }
>('recipe/savePackaging', async ({ productId, scenarioId = null, deleteExisting = false }, { dispatch, getState }) => {
  const state = getState()
  let hasError = false
  const savedPackagingItems = selectSavedPackaging(state)
  const draftPackagingItems = selectDraftPackaging(state)

  if (deleteExisting && savedPackagingItems.length) {
    // Delete the product's existing packaging items from the server
    const deleteResponse = await requestDeletePackaging(savedPackagingItems, scenarioId)
    deleteResponse.forEach((res) => {
      if (res.status === 'rejected') {
        hasError = true
      }
    })
  }

  // Add new packaging items -- do this first so they are reflected in the new impact scores!
  const packagingResponses = await requestAddPackaging(draftPackagingItems, productId, scenarioId)
  const updatedPackaging = packagingResponses.reduce<RecipePackagingItem[]>((updated, res) => {
    if (res.status === 'fulfilled') {
      const packagingItem: RecipePackagingItem = {
        id: res.value.id,
        shape: { id: res.value.shape.id },
        material: { id: res.value.material.id, name: res.value.material.name },
        packaging_unit: res.value.packaging_unit,
        consumer_units: res.value.consumer_units,
        region: res.value.region ? { id: res.value.region.id, name: res.value.region.name } : null,
        packaging_material_weight: res.value.packaging_material_weight,
        packaging_uses: res.value.packaging_uses,
      }

      return [...updated, packagingItem]
    }
    hasError = true
    return updated
  }, [])

  // We need the new/updated ids to be in redux or else this can cause issues
  // with future updates of this product in this session
  dispatch(savePackagingToRecipe(updatedPackaging))

  if (hasError) {
    dispatch(
      addMessage({
        message:
          'There was an issue saving packaging for this formula. Please refresh and validate the packaging looks correct',
        severity: 'error',
      })
    )
  }
})

export const saveNewProduct = createAsyncThunk<
  { inventories: InventoriesResponse[]; product: ProductResponse; updatedTags: FormulationTag[] },
  SaveAsInfo,
  { state: AppState }
>('productOverview/saveNewProduct', async (saveAsInfo, { getState, dispatch }) => {
  const state = getState()
  const productDraft = selectDraftProductInfo(state)
  const draftInventories = selectDraftInventories(state)
  const recipe = selectDraftRecipe(state)
  const abatementEnabled = selectAbatementSplit(state)
  const productPayload = generateProductPayload(productDraft, recipe, saveAsInfo)
  const ingredientsPayload = getV2IngredientsPayload(recipe.ingredients)
  let hasError = false

  // Save the new product
  const product: ProductResponse = await fetchHelperV2<ProductResponse>({
    url: `products/`,
    method: 'POST',
    body: JSON.stringify(productPayload),
  })
  // Save all packaging items and associate them with the new product
  await dispatch(savePackaging({ productId: product.id }))

  const [inventoriesResponse, ingredientsResponse] = await Promise.allSettled([
    // Get the inventory the new product was assigned to (will be a list of one)
    fetchProductInventories(product.id, product.is_foreign),
    // Save all ingredients under the new product
    fetchHelperV2({
      url: `products/${product.id}/ingredients/`,
      method: 'PUT',
      body: JSON.stringify(ingredientsPayload),
    }),
  ])
  hasError = hasError || dispatch(handleProductSavedErrors(ingredientsResponse, inventoriesResponse))

  const inventories = []
  if (inventoriesResponse.status === 'fulfilled') {
    let inventory = inventoriesResponse.value[0]
    inventory.validation_requests = inventory.validation_requests || []
    try {
      inventory = await requestUpdateInventory({
        product_id: product.id,
        inventory_id: inventory.id,
        formulation_tags: saveAsInfo.copyTags ? draftInventories[0].formulation_tags : inventory.formulation_tags,
        mt_per_year: draftInventories[0].mt_per_year,
        internal_id: draftInventories[0].internal_id,
        goals: draftInventories[0].goals,
        workspace: inventory.workspace,
      })
    } catch (error) {
      dispatch(
        handleThunkError({
          error,
          message: INVENTORY_ERROR,
          actionType: 'productOverview/saveNewProduct',
          context: { saveAsInfo, ingredientsPayload, product },
        })
      )
    }
    inventories.push(inventory)
  }
  window.history.pushState({}, '', `/products/${product.id}`)
  const updatedTags = generateTags(inventories, product.id)
  await dispatch(updateRecipeScoresAndView(hasError))

  if (abatementEnabled) {
    dispatch(getOptimalGhgPotential(product.id))
  }

  return { product, inventories, updatedTags }
})

export const updateScenarioProductData = createAsyncThunk<
  { scenarioId: number; productId: number; data: ScenarioProductAttributes },
  ScenarioProductAttributes,
  { state: AppState }
>('productOverview/updateScenarioProductData', async (data, { getState }) => {
  const product = selectSavedProductInfo(getState())
  const scenarioId = selectCurrentScenarioId(getState())
  await fetchUpdateScenarioProduct(scenarioId, product.id, data)
  return { scenarioId, productId: product.id, data }
})

export interface UpdatedProduct {
  product: ProductResponse
  updatedInventories: InventoriesResponse[]
  updatedTags: FormulationTag[]
  isClonedScenarioProduct: boolean
}
export const updateProduct = createAsyncThunk<UpdatedProduct, number | void, { state: AppState }>(
  'productOverview/updateProduct',
  async (_scenarioId, { getState, dispatch, rejectWithValue }) => {
    const state = getState()
    const savedProduct = selectSavedProductInfo(state)
    const productDraft = selectDraftProductInfo(state)
    const draftInventories = selectDraftInventories(state)
    const savedInventories = selectSavedInventories(state)
    const savedPackagingItems = selectSavedPackaging(state)
    const draftPackagingItems = selectDraftPackaging(state)
    const savedTransportationItems = selectSavedTransportation(state)
    const draftTransportationItems = selectDraftTransportation(state)
    const recipe = selectDraftRecipe(state)
    const abatementEnabled = selectAbatementSplit(state)
    const scenarios = selectSortedScenarios(state)
    const isScenarioModified = selectIsProductScenarioModified(state)
    const productScenario = selectDisplayedProductScenario(state)

    const scenarioId = _scenarioId ? _scenarioId : null

    const productPayload = generateProductPayload(productDraft, recipe)
    const ingredientsPayload = getV2IngredientsPayload(recipe.ingredients)

    // The patch product must be done separately because when saving a cloned scenario product for the first time,
    // we need to use the product id from the first response to then save the ingredients and fetch the inventories
    let product: ProductResponse
    try {
      product = await fetchHelperV2<ProductResponse>({
        url: scenarioId ? `scenarios/${scenarioId}/products/${productDraft.id}/` : `products/${productDraft.id}/`,
        method: 'PATCH',
        body: JSON.stringify(productPayload),
      })
    } catch (e) {
      return rejectWithValue(
        scenarioId && !savedProduct.source_product ? 'Product duplication failed' : 'Product update failed'
      )
    }

    // If the product is a new scenario product, update the source product version data to indicate that a clone now exists
    if (scenarioId && !savedProduct.source_product) {
      const scenario = scenarios.find((_scenario) => _scenario.id === scenarioId)
      const clone: ProductClone = {
        pk: product.id,
        name: product.name,
        scenarios: [scenario],
        source_product: { id: productDraft.id },
        workspaces: product.workspaces,
      }
      dispatch(updateSourceProductVersionData({ scenarioId, clone }))
    }

    // Update the product's ingredients and fetch all inventories
    const [ingredientsResponse, inventoriesResponse] = await Promise.allSettled([
      fetchHelperV2({
        url: scenarioId
          ? `scenarios/${scenarioId}/products/${product.id}/ingredients/`
          : `products/${product.id}/ingredients/`,
        method: 'PUT',
        body: JSON.stringify(ingredientsPayload),
      }),
      fetchProductInventories(product.id),
    ])

    let hasError = dispatch(handleProductSavedErrors(ingredientsResponse, inventoriesResponse))

    // If packaging or transportation have changed, update them
    if (!isEqual(savedPackagingItems, draftPackagingItems)) {
      await dispatch(savePackaging({ productId: product.id, scenarioId: scenarioId, deleteExisting: true }))
    }
    if (!isEqual(savedTransportationItems, draftTransportationItems)) {
      await requestAddTransportation(product.id, draftTransportationItems, scenarioId)
    }

    const inventoriesHaveChanged = !isEqual(savedInventories, draftInventories)
    const isNewScenarioProduct = product.id !== savedProduct.id

    // Update each inventory with the tags, MT/year, goals, and workspace (from draft inventories)
    let updatedInventories = savedInventories
    if (inventoriesResponse.status === 'fulfilled' && (inventoriesHaveChanged || isNewScenarioProduct)) {
      try {
        if (!isNewScenarioProduct) {
          updatedInventories = await Promise.all(
            draftInventories
              // Only include inventories that still exist on the server
              .filter((inventory) => !!inventoriesResponse.value.find((item) => item.id === inventory.id))
              .map((inventory) =>
                requestUpdateInventory({
                  product_id: product.id,
                  inventory_id: inventory.id,
                  formulation_tags: inventory.formulation_tags,
                  mt_per_year: inventory.mt_per_year,
                  internal_id: inventory.internal_id,
                  goals: inventory.goals,
                  workspace: inventory.workspace,
                  scenarioId: scenarioId,
                })
              )
          )
        } else {
          // For new scenario products, updated the created inventories with the draft tags, MT/year, and goals
          updatedInventories = await Promise.all(
            inventoriesResponse.value.map((inventory, i) =>
              requestUpdateInventory({
                product_id: product.id,
                inventory_id: inventory.id,
                formulation_tags: draftInventories[i].formulation_tags,
                mt_per_year: draftInventories[i].mt_per_year,
                internal_id: draftInventories[i].internal_id,
                goals: draftInventories[i].goals,
                workspace: inventory.workspace,
                scenarioId: scenarioId,
              })
            )
          )
        }
      } catch (error) {
        dispatch(
          handleThunkError({
            error,
            message: INVENTORY_ERROR,
            actionType: 'productOverview/updateProduct',
            context: { draftInventories, product },
          })
        )
      }
    }

    // If we updated the inventory workspaces, fetch the product to get the new product workspaces
    if (inventoriesHaveChanged) {
      product = await fetchHelperV2<ProductResponse>({
        url: `products/${product.id}/`,
        method: 'GET',
      })
    }

    // if we have updated the scenario data, update the scenario product data
    if (isScenarioModified) {
      fetchUpdateScenarioProduct(
        scenarioId,
        product.id,
        { goals: productScenario.goals, mt_per_year: productScenario.mt_per_year } || ({} as ScenarioProductAttributes)
      )
      // upadte the product with the new scenario data
      product.scenario_products = generateUpdatedScenarioProducts(
        product.scenario_products,
        scenarioId,
        productScenario
      ).map((sp) => ({ ...sp, product: { id: product.id } }))
    }

    await dispatch(updateRecipeScoresAndView(hasError))

    if (abatementEnabled) {
      dispatch(getOptimalGhgPotential(product.id))
    }

    const updatedTags = generateTags(updatedInventories, product.id)
    return { product, updatedInventories, updatedTags, isClonedScenarioProduct: !!scenarioId }
  }
)

export const generateReport = createAsyncThunk<ProductResponse, string, { state: AppState }>(
  'productOverview/generateReport',
  async (base64String, { getState }) => {
    const state = getState()
    const product = selectSavedProductInfo(state)
    const generated = await generateProductReport(product.id, base64String)
    return generated
  }
)

export const lockProduct = createAsyncThunk<void, string, { state: AppState }>(
  'productOverview/lockProduct',
  async (base64String, { getState, dispatch }) => {
    const state = getState()
    const product = selectSavedProductInfo(state)
    const savedImpact = selectSavedImpactData(state)
    const lockedProduct = await lockProductData(product.id)
    if (lockedProduct.locked_claims_timestamp) {
      const productWithReport = await generateProductReport(product.id, base64String)
      batch(() => {
        dispatch(setLockedProductInfo(lockedProduct))
        dispatch(
          setLockedImpactData({
            impactScore: savedImpact.product,
            time: lockedProduct.locked_claims_timestamp,
          })
        )
        dispatch(setProductReports(productWithReport))
        dispatch(setView(PUBLISHED))
      })
    } else {
      throw new Error(`error locking scores for formula ${product.id}`)
    }
  }
)

export const requestValidation = createAsyncThunk<InventoriesResponse[], string, { state: AppState }>(
  'productOverview/requestValidation',
  async (requestText, { getState, dispatch }) => {
    const state = getState()
    const product = selectSavedProductInfo(state)
    const inventories = selectSavedInventories(state)
    await requestProductValidation(product.id, product.workspaces[0].id, requestText)
    dispatch(
      addMessage({
        message: 'Your validation request has been received',
        severity: 'success',
      })
    )
    const now = new Date()
    return inventories.map((inventory) => {
      if (inventory.workspace.id !== product.workspaces[0].id) {
        return inventory
      }
      return {
        ...inventory,
        validation_requests: [
          ...inventory.validation_requests,
          {
            status: REQUESTED,
            description: requestText,
            date_created: now.toLocaleString(),
            date_modified: now.toLocaleString(),
          },
        ],
      }
    })
  }
)

export const requestSharing = createAsyncThunk<SharingRequest[], ShareRequestInfo, { state: AppState }>(
  'productOverview/requestSharing',
  async ({ type, text }, { getState, dispatch }) => {
    const state = getState()
    const product = selectSavedProductInfo(state)
    const sharingResponse: ProductInfo = await requestProductSharing(product.id, type, text)
    dispatch(
      addMessage({
        message: 'Your sharing request has been received',
        severity: 'success',
      })
    )
    return sharingResponse.sharing_requests
  }
)

export const updateDraftProductGoals = (
  goal: number,
  metric: string
): ThunkAction<void, AppState, InventoriesResponse[], AnyAction> => (dispatch, getState) => {
  const state = getState()
  const draftInventories = selectDraftInventories(state)
  const goals = { ...selectDisplayedProductGoals(state) }
  if (isNaN(goal)) {
    if (Object.hasOwn(goals, metric)) {
      delete goals[metric]
    }
  } else {
    goals[metric] = goal
  }
  dispatch(updateDraftInventories(draftInventories.map((inventory) => ({ ...inventory, goals }))))
}
