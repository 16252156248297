import React, { FC, ReactNode } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons'
import { Box, Text, Stack, AccordionSummary, Tooltip, Icon } from '@howgood/design'
import { selectHasManyImpactDatas } from '@/state/recipe'
import { CardType, selectFavoriteCards, setFavoriteCards } from '@/state/preferences'
import { CompareToOptions } from './shared/CompareToOptions'
import { FavoriteIconButton } from './shared/FavoriteIconButton'

interface Props {
  title: string
  titleTooltip?: ReactNode
  id?: string
  cardKey?: CardType // provided if favoritable
  compareTo?: boolean // Used by the Impact Details panel
  favoritesTab?: boolean
  Action?: ReactNode
}

export const AccordionCardHeader: FC<Props> = ({
  title,
  titleTooltip,
  id,
  cardKey = null,
  compareTo = false,
  favoritesTab = false,
  Action,
}) => {
  const dispatch = useDispatch()
  const hasManyImpacts = useSelector(selectHasManyImpactDatas)
  const favorites = useSelector(selectFavoriteCards)

  const handleFavoriteIconClicked = () => {
    const newFavorites = favorites.includes(cardKey)
      ? favorites.filter((favorite) => favorite !== cardKey)
      : [...favorites, cardKey]
    dispatch(setFavoriteCards(newFavorites))
  }

  return (
    <AccordionSummary id={id}>
      <Stack direction="row" width="100%">
        {titleTooltip ? (
          <Tooltip title={<Stack data-testid="accordion-title-tooltip">{titleTooltip}</Stack>}>
            <Stack flexDirection="row" alignItems="center" gap={1}>
              <Text data-testid="accordion-title" variant="h3">
                {title}
              </Text>
              <Icon data-testid="accordion-title-icon" icon={faInfoCircle} size="sm" />
            </Stack>
          </Tooltip>
        ) : (
          <Text data-testid="accordion-title" variant="h3">
            {title}
          </Text>
        )}
        {Boolean(hasManyImpacts && compareTo) && <CompareToOptions />}
      </Stack>
      <Stack flexDirection="row" alignItems="center" gap={1} onClick={(e) => e.stopPropagation()}>
        {Action}
        {cardKey && (
          <Box mr={2}>
            <FavoriteIconButton
              id={`${cardKey}-favorite-button`}
              favorite={favorites.includes(cardKey)}
              isFavorites={favoritesTab}
              onClick={handleFavoriteIconClicked}
            />
          </Box>
        )}
      </Stack>
    </AccordionSummary>
  )
}
