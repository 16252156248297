import React, { FC } from 'react'
import { Popover, Stack, Table, TableBody, TableCell, TableHead, TableRow, Text } from '@howgood/design'
import { formatNumber } from '@howgood/utils'
import { PopoverData } from './ImpactChart'

interface IngredientPopoverProps {
  popoverData: PopoverData // The `nodeInfo` returned by the chart's onHover callback
}

export const IngredientPopover: FC<IngredientPopoverProps> = ({ popoverData }) => {
  return (
    <Popover
      id="attribute-popover"
      sx={{
        pointerEvents: 'none',
        '& .MuiPopover-paper': { maxWidth: 300, borderRadius: 1, p: 1 },
      }}
      open={popoverData ? true : false}
      anchorEl={popoverData?.anchorEl}
      anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Stack
        sx={{
          '& .MuiTableCell-root': { px: 0, py: 1, pr: 1, fontSize: 15 },
          '& .MuiTableRow-root': { '&:last-child td': { border: 0 } },
        }}
      >
        <Text fontWeight="bold">{popoverData?.nodeInfo.name}</Text>
        <Table>
          <TableHead />
          <TableBody>
            <TableRow>
              <TableCell>Contribution:</TableCell>
              <TableCell>{formatNumber(popoverData?.nodeInfo.contribution)}%</TableCell>
            </TableRow>
            {!popoverData?.nodeInfo.nested_product_id && (
              <>
                <TableRow>
                  <TableCell>Source:</TableCell>
                  <TableCell>{popoverData?.nodeInfo.ingredient_origin_location_region_name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Standards:</TableCell>
                  <TableCell>
                    {popoverData?.nodeInfo.standards?.map((standard) => standard.title).join(', ') || 'None'}
                  </TableCell>
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
      </Stack>
    </Popover>
  )
}
