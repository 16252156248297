import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'
import { faCheck } from '@fortawesome/pro-regular-svg-icons'
import { Icon } from './Icon'

export interface CheckmarkProps {
  enabled: boolean
  size?: 'xs' | 'sm'
  'data-testid'?: string
}
const useStyles = createUseStyles<string, CheckmarkProps>({
  checkmark: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: ({ size }) => (size === 'xs' ? 15 : 20),
    height: ({ size }) => (size === 'xs' ? 15 : 20),
    borderRadius: 20,
    background: ({ enabled }) => (enabled ? '#0f9960' : '#cfcec9'),
    color: '#ffffff',
  },
})

export const Checkmark: FC<CheckmarkProps> = ({ enabled, size = 'sm', ...rest }) => {
  const classes = useStyles({ enabled, size })

  return (
    <div aria-label="Checkmark" data-testid={rest['data-testid']} className={classes.checkmark}>
      <Icon icon={faCheck} size={size} />
    </div>
  )
}
