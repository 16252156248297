import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Text, Tooltip } from '@howgood/design'
import { formatNumber } from '@howgood/utils'
import { MetricEntry } from '@/contentfully'
import { selectGridSettings } from '@/state/preferences'
import { ScoreInfo, isRawScore } from '../../../util/getScore'

interface Props {
  score: ScoreInfo
  impactKey: string
  units: string
  valuesMap: MetricEntry['valuesMap']
  showValue: boolean
}
export const ScoreCellText: FC<Props> = ({ score, impactKey, units, valuesMap, showValue }) => {
  const gridSettings = useSelector(selectGridSettings)
  const showValues = gridSettings.showValues || showValue

  const textProps = {
    'data-testid': 'score-text',
    fontSize: 13,
    color: showValues ? null : 'transparent',
    textAlign: 'center',
  } as const

  if (isRawScore(impactKey)) {
    const fullValue = gridSettings.scoringMethod === 'perKilogram' ? score.rawScore : score.contribution
    const roundedValue = gridSettings.scoringMethod === 'perKilogram' ? score.scoreRounded : score.contributionRounded
    const scoreRange = score.scoreRange
    const tooltipValue =
      fullValue === null ? 'N/A' : scoreRange ? `${scoreRange} ${units}` : `${formatNumber(fullValue, 4)} ${units}`

    return (
      <Tooltip title={tooltipValue}>
        <Text {...textProps}>{scoreRange || roundedValue || '-'}</Text>
      </Tooltip>
    )
  }

  if (valuesMap) {
    // The tooltip copy will be something like 1001 - 1500 MJ/ton
    const copy = score.scoreOneToTen
      ? units
        ? `${valuesMap[score.scoreOneToTen]} ${units}`
        : `${valuesMap[score.scoreOneToTen]}`
      : 'N/A'

    // Filter out non-numeric tokens to create the value range displayed in the cell
    // Some impacts have just a string in copy.tsx, so `cellLabel` will be "", in which case we'll display the 1-10 score
    const cellLabel =
      copy === 'N/A'
        ? '-'
        : copy
            .split(' ')
            .filter((token) => !isNaN(parseFloat(token)))
            .join('-')

    return (
      <Tooltip title={copy}>
        <Text {...textProps}>{cellLabel || score.scoreOneToTen}</Text>
      </Tooltip>
    )
  }

  return (
    <Tooltip title={score.rawScore == null ? 'N/A' : `${Math.round(score.rawScore * 10000) / 10000}`}>
      <Text {...textProps}>{score.scoreOneToTen || '-'}</Text>
    </Tooltip>
  )
}
