import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'

import { useContentfulAttributes, useSortedContentfulMetrics } from '@/contentfully/hooks'
import { MetricEntry } from '@/contentfully'
import { selectIsVendors, selectIsInitiative, selectIsProcurement } from '@/state/router'
import {
  ColGroup,
  MetricGroupKey,
  carbon,
  lifecycle,
  fieldColMap,
  scope3,
} from '@/components/Products/utils/productListColDefs'
import {
  Tier2Renderer,
  AttributeRenderer,
  PercentageRenderer,
  SDGRenderer,
  HeatmapCellRendererWithScores,
  ImpactScoreRenderer,
  DataHealthRenderer,
  ProductsGridColDef,
} from '@/components/GridCellRenderers'
import { SCOPE_3_CONTRIBUTION_FIELD } from '@/constants/impactScore'
import { selectIsSupplierConnect } from '@/state/organization'

const generateCellHeaderText = (metric: MetricEntry) => {
  if (!metric) {
    return { headerName: '', minWidth: 180 }
  }
  const metricName = metric.title.replace('Carbon Footprint - ', '').replace('Carbon Lifecycle - ', '')
  const minWidth = metricName.length > 65 ? 210 : metricName.length > 50 ? 180 : metricName.length > 30 ? 140 : 100
  return { headerName: metricName, minWidth }
}

/**
 * Loops throught the fieldColMap and returns a list of columns for the given metricGroupKey
 * (aka column/cell treatment) and product list context (procurement vs formulation)
 *
 * @param metricGroupKey as MetricGroupKey (which cell treatment should be applied, which cell renderer and css)
 * @param isProcurement as boolean (product list context, procurement vs formulation)
 * @returns colList as { key: Field; colGroup: ColGroup }[]
 */
const useColList = (metricGroupKey: MetricGroupKey): { key: string; colGroup: ColGroup }[] => {
  const isProcurement = useSelector(selectIsProcurement)
  const isInitiative = useSelector(selectIsInitiative)
  const isVendor = useSelector(selectIsVendors)
  const isSupplierConnect = useSelector(selectIsSupplierConnect)
  const productListType = isProcurement
    ? 'procurement'
    : isVendor
    ? 'vendor'
    : isInitiative
    ? 'initiative'
    : 'formulation'
  return useMemo(
    () =>
      Object.entries(fieldColMap)
        .filter(
          ([_, value]) =>
            value.metricGroupKey === metricGroupKey &&
            value[productListType].colGroup &&
            (!isSupplierConnect || value.supplierConnect)
        )
        .map(([key, value]) => ({
          key,
          ...value[productListType],
        })),
    [productListType, metricGroupKey, isSupplierConnect]
  )
}

interface ProductListColumnData {
  colList: { key: string; colGroup: ColGroup }[]
  metrics: MetricEntry[]
}
/**
 * Returns the column data for the given metricGroupKey
 * @param metricGroupKey as MetricGroupKey (which cell treatment should be applied, which cell renderer and css)
 * @returns colList as { key: string; colGroup: ColGroup }[]
 * @returns metrics as MetricEntry[] (contentful data for the given metricGroupKey, filtered by user permissions)
 */
const useProductListColumnData = (metricGroupKey: MetricGroupKey): ProductListColumnData => {
  const colList = useColList(metricGroupKey)
  const metrics = useSortedContentfulMetrics(colList.map((col) => col.key))
  return useMemo(() => ({ colList, metrics }), [colList, metrics])
}

export const useImpactColumns = (): ProductsGridColDef[] => {
  const { colList, metrics } = useProductListColumnData('impact')

  return useMemo(
    () =>
      colList.reduce((columns: ProductsGridColDef[], colData) => {
        const field = colData.key
        const metric = metrics.find((m) => m.key === field)
        if (metric) {
          const { headerName, minWidth } = generateCellHeaderText(metric)
          columns.push({
            headerName,
            minWidth,
            field,
            sortfield: `impact_score.${field}`,
            align: 'center',
            renderCell: ImpactScoreRenderer,
          })
        }
        return columns
      }, []),
    [colList, metrics]
  )
}

const useCommonCarbonColumns = (): Record<ColGroup, ProductsGridColDef[]> => {
  const { colList, metrics } = useProductListColumnData('carbon')

  return useMemo(() => {
    return colList.reduce((columns, colData) => {
      const field = colData.key
      const metric = metrics.find((m) => m.key === field)
      if (metric) {
        const title = metric?.title ?? field
        const subTitle = metric?.subtitle
        const longName = subTitle
          ? `${title} - ${subTitle} (${metric?.units || 'kg'})`
          : `${title} (${metric?.units || 'kg'})`
        const { headerName, minWidth } = generateCellHeaderText({ ...metric, title: longName })
        const col: ProductsGridColDef = {
          headerName,
          field,
          minWidth,
          sortfield: `impact_score.${field}`,
          renderCell: field === SCOPE_3_CONTRIBUTION_FIELD ? PercentageRenderer : Tier2Renderer,
        }
        if (columns[colData.colGroup]) {
          columns[colData.colGroup].push(col)
        } else {
          columns[colData.colGroup] = [col]
        }
      }
      return columns
    }, {} as Record<ColGroup, ProductsGridColDef[]>)
  }, [colList, metrics])
}

export const useCarbonFootprintColumns = (): ProductsGridColDef[] => {
  const cols = useCommonCarbonColumns()
  return cols[carbon] || []
}

export const useCarbonLifecycleColumns = (): ProductsGridColDef[] => {
  const cols = useCommonCarbonColumns()
  return cols[lifecycle] || []
}

export const useScope3Columns = (): ProductsGridColDef[] => {
  const cols = useCommonCarbonColumns()
  return cols[scope3] || []
}

export const useHeatmapScoreColumns = (): ProductsGridColDef[] => {
  const { colList, metrics } = useProductListColumnData('heatMap')
  const isSupplierConnect = useSelector(selectIsSupplierConnect)

  return useMemo(
    () =>
      colList.reduce((columns: ProductsGridColDef[], colData) => {
        const field = colData.key
        const metric = metrics.find((m) => m.key === field)
        if (metric) {
          const { headerName } = generateCellHeaderText(metric)
          columns.push({
            field,
            sortfield: `impact_score.${field}`,
            headerName,
            minWidth: 120,
            renderCell: isSupplierConnect ? Tier2Renderer : HeatmapCellRendererWithScores,
          })
        }
        return columns
      }, []),
    [colList, isSupplierConnect, metrics]
  )
}

export const useTier2Columns = (): ProductsGridColDef[] => {
  const { colList, metrics } = useProductListColumnData('tier2')

  return useMemo(
    () =>
      colList.reduce((columns: ProductsGridColDef[], colData) => {
        const field = colData.key
        const metric = metrics.find((m) => m.key === field)
        if (metric) {
          const { headerName, minWidth } = generateCellHeaderText(metric)
          columns.push({
            headerName,
            minWidth,
            field,
            sortfield: field === 'nutrition_score' ? field : `impact_score.${field}`,
            renderCell: Tier2Renderer,
          })
        }
        return columns
      }, []),
    [colList, metrics]
  )
}

export const useAttributeColumns = (): ProductsGridColDef[] => {
  const colList = useColList('attribute')
  const attributes = useContentfulAttributes(colList.map((col) => col.key))

  return useMemo(
    () =>
      colList.reduce((columns: ProductsGridColDef[], colData) => {
        const field = colData.key
        const attribute = attributes.find((att) => att.key === field)
        if (attribute) {
          const headerName = attribute?.title
          columns.push({
            headerName,
            field,
            sortfield: `impact_score.${field}`,
            renderCell: AttributeRenderer,
          })
        }
        return columns
      }, []),
    [colList, attributes]
  )
}

export const useDataHealthColumns = (): ProductsGridColDef[] => {
  const { colList, metrics } = useProductListColumnData('dataHealth')

  return useMemo(
    () =>
      colList.reduce((columns: ProductsGridColDef[], colData) => {
        const field = colData.key
        const metric = metrics.find((m) => m.key === field)
        if (metric) {
          const headerName = metric?.title
          columns.push({
            headerName,
            field,
            sortfield: field === 'default_origin_percent_impact' ? `impact_score.${field}` : field,
            renderCell: DataHealthRenderer,
          })
        }
        return columns
      }, []),
    [colList, metrics]
  )
}

export const useSDGColumns = (): ProductsGridColDef[] => {
  const { colList, metrics } = useProductListColumnData('sdg')

  return useMemo(
    () =>
      colList.reduce((columns: ProductsGridColDef[], colData) => {
        const field = colData.key
        const metric = metrics.find((m) => m.key === field)
        if (metric) {
          const headerName = metric?.title
          columns.push({
            headerName,
            field,
            sortfield: `impact_score.${field}`,
            minWidth: 90,
            renderCell: SDGRenderer,
            description: documentToPlainTextString(metric?.longDescription),
          })
        }
        return columns
      }, []),
    [colList, metrics]
  )
}
