import { createSlice } from '@reduxjs/toolkit'
import { OptimalGhgPotential, OPTIMAL_GHG_POTENTIAL_DEFAULT } from './optimalGhgPotential.requests'
import { getOptimalGhgPotential } from './optimalGhgPotential.thunk'

export interface State {
  value: OptimalGhgPotential
  isLoading: boolean
  isError: boolean
}

export const initialState: State = {
  value: OPTIMAL_GHG_POTENTIAL_DEFAULT,
  isLoading: false,
  isError: false,
}

const optimalGhgPotentialSlice = createSlice({
  name: 'optimalGhgPotential',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOptimalGhgPotential.pending, (state, action) => {
      state.isLoading = action.payload
      state.isError = false
    })

    builder.addCase(getOptimalGhgPotential.fulfilled, (state, action) => {
      state.isLoading = false
      state.isError = false
      state.value = action.payload
    })

    builder.addCase(getOptimalGhgPotential.rejected, (state) => {
      state.isLoading = false
      state.isError = true
      state.value = OPTIMAL_GHG_POTENTIAL_DEFAULT
    })
  },
})

export const optimalGhgPotentialReducer = optimalGhgPotentialSlice.reducer
