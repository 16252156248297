import React, { FC } from 'react'
import { Text, Tooltip } from '@howgood/design'

export const SharedHeader: FC<{ title: string }> = ({ title }) => (
  <Tooltip title={<Text>{title}</Text>}>
    <Text textOverflow="ellipsis" noWrap>
      {title}
    </Text>
  </Tooltip>
)
