import React, { FC } from 'react'
import { Box, Text } from '@howgood/design'
import { useSelector } from 'react-redux'
import { selectIsFormulaOveriewError } from '@/state/recipe'

export const LoadError: FC<{ productId: number }> = ({ productId }) => {
  const loadError = useSelector(selectIsFormulaOveriewError)

  return (
    <Box mt={2}>
      <Text>{`Unable to load formula ${productId}`}</Text>
      <Text>&nbsp;</Text>
      <Text>{loadError || 'No additional information available'}</Text>
    </Box>
  )
}
