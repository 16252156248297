import React, { FC } from 'react'
import MuiTable, { TableProps as MuiTableProps } from '@mui/material/Table'
import MuiTableBody, { TableBodyProps as MuiTableBodyProps } from '@mui/material/TableBody'
import MuiTableHead, { TableHeadProps as MuiTableHeadProps } from '@mui/material/TableHead'
import MuiTableRow, { TableRowProps as MuiTableRowProps } from '@mui/material/TableRow'
import MuiTableCell, { TableCellProps as MuiTableCellProps } from '@mui/material/TableCell'

interface TableHeadProps extends Omit<MuiTableHeadProps, 'className' | 'sx'> {}
export const TableHead: FC<TableHeadProps> = ({ children, ...rest }) => {
  return <MuiTableHead {...rest}>{children}</MuiTableHead>
}

interface TableBodyProps extends Omit<MuiTableBodyProps, 'className' | 'sx'> {}
export const TableBody: FC<TableBodyProps> = ({ children, ...rest }) => {
  return <MuiTableBody {...rest}>{children}</MuiTableBody>
}

interface TableRowProps extends Omit<MuiTableRowProps, 'className' | 'sx'> {}
export const TableRow: FC<TableRowProps> = ({ children, ...rest }) => {
  return <MuiTableRow {...rest}>{children}</MuiTableRow>
}

interface TableCellProps extends Omit<MuiTableCellProps, 'className' | 'sx'> {}
export const TableCell: FC<TableCellProps> = ({ children, ...rest }) => {
  return <MuiTableCell {...rest}>{children}</MuiTableCell>
}

interface TableProps extends Omit<MuiTableProps, 'className' | 'sx'> {}
export const Table: FC<TableProps> = ({ children, ...rest }) => {
  return <MuiTable {...rest}>{children}</MuiTable>
}
