import React, { FC, ReactNode } from 'react'
import { createUseStyles } from 'react-jss'

type Size = 'L' | 'S'

export interface MetricBoxProps {
  title: string
  header?: JSX.Element
  footer?: JSX.Element
  body?: ReactNode
  size?: Size
}

const WIDTHS: Record<Size, string> = {
  L: '300px',
  S: '240px',
}

const HEIGHTS: Record<Size, string> = {
  L: '336px',
  S: '164px',
}

const HEADER_HEIGHTS: Record<Size, string> = {
  L: '58px',
  S: '40px',
}

const FOOTER_HEIGHTS: Record<Size, string> = {
  L: '58px',
  S: '20px',
}

const useStyles = createUseStyles<string, Pick<MetricBoxProps, 'size'>>({
  box: {
    fontFamily: 'Whitney',
    minWidth: ({ size }) => WIDTHS[size],
    width: ({ size }) => WIDTHS[size],
    height: ({ size }) => HEIGHTS[size],
  },
  innerBox: {
    minHeight: ({ size }) => HEIGHTS[size],
    background: '#fff',
    padding: '10px 15px',
    borderRadius: 6,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
  },
  header: {
    height: ({ size }) => HEADER_HEIGHTS[size],
  },
  body: {
    display: 'flex',
    flex: 1,
    whiteSpace: 'nowrap',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    height: ({ size }) => FOOTER_HEIGHTS[size],
    alignItems: 'flex-end',
  },
})

export const MetricBox: FC<MetricBoxProps> = ({ title, size = 'L', header, footer, body }) => {
  const classes = useStyles({ size })

  return (
    <div aria-label={`${title} metric box`} className={classes.box}>
      <div className={classes.innerBox}>
        <div className={classes.header}>{header}</div>
        <div className={classes.body}>{body}</div>
        <div className={classes.footer}>{footer}</div>
      </div>
    </div>
  )
}
