import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { faScroll } from '@fortawesome/pro-regular-svg-icons'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import { exportPDF, drawDOM } from '@progress/kendo-drawing'
import {
  theme,
  Alert,
  Text,
  Stack,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  AlertTitle,
  FormControlLabel,
  Checkbox,
  Box,
  Icon,
  ExternalLink,
} from '@howgood/design'

import {
  lockProduct,
  selectLockedClaimsTimeStamp,
  selectProductIsLocked,
  selectIsLockLoading,
  selectCurrentClaimsReport,
  selectLockedProductInfo,
} from '@/state/productOverview'
import { selectDisplayedIngredientList } from '@/state/recipe'
import { PUBLISHED, selectIsGenerateReport, selectPageView, setGenerateReport } from '@/state/pageSettings'
import { ProductGhgImpactExport } from '@/features/ProductPage/export/ProductGhgImpactExport/ProductGhgImpactExport'
import { PDFExport } from '@progress/kendo-react-pdf'
import { formatDateString, LONG } from '@/utils/dates'
import { usePendoTrack } from '@/hooks'
import { selectLcaOverrideSplit } from '@/state/splitio'
import { useUrlWithUseridParam } from '@/hooks/useUrlWithUseridParam'
import { LCA_SUBMISSION_LINK } from '@/constants/common'
import { lcaSubmittionAlert, lockAlerts, lockSummary } from './validationCopy'
import { ProductInfo } from './CreateReportProductInfo'
import { CreateReportConfirmationDialog } from './CreateReportConfirmationDialog'
import { ReportHistory } from './ReportHistory'
import { ProductCFReportDialog } from '@/components/ProductCFReportDialog/ProductCFReportDialog'
import { selectIsSupplierConnect } from '@/state/organization'
import { PDF_EXPORT_SETTINGS } from '@/constants/pdfExportSettings'
import { DownloadLink } from '@/components/Shared/DownloadLink'

export const CreateReportPanel: FC = () => {
  const dispatch = useDispatch()
  const pendoTrack = usePendoTrack()
  const product = useSelector(selectLockedProductInfo)
  const isLocked = useSelector(selectProductIsLocked)
  const isLockLoading = useSelector(selectIsLockLoading)
  const lockedClaimsTimeStamp = useSelector(selectLockedClaimsTimeStamp)
  const ingredients = useSelector(selectDisplayedIngredientList)
  const pageView = useSelector(selectPageView)
  const currentClaimsReport = useSelector(selectCurrentClaimsReport)
  const isGenerateReport = useSelector(selectIsGenerateReport)
  const lcaOverrideEnabled = useSelector(selectLcaOverrideSplit)
  const isSupplierConnect = useSelector(selectIsSupplierConnect)

  const productGhgImpactExportRef = useRef<PDFExport>()
  const pdfContentRef = useRef(null)

  const [confirmationDialogIsOpen, setConfirmationDialogIsOpen] = useState(false)
  const [ingredientsListOption, setIngredientsListOption] = useState(true)
  const [expanded, setExpanded] = useState(!isLocked || isGenerateReport)

  const [reportDialogOpen, setReportDialogOpen] = useState(false)

  const lcaSubmissionUrl = useUrlWithUseridParam(LCA_SUBMISSION_LINK)

  const handleConfirmationDialogClose = useCallback(() => {
    setConfirmationDialogIsOpen(false)
    dispatch(setGenerateReport(false))
  }, [dispatch])

  const handleCreateReport = useCallback(() => {
    pendoTrack('Formula: Lock claims report created')
    handleConfirmationDialogClose()
    // First create the GHG impact report PDF to include in the lock claims request payload
    // See https://www.telerik.com/kendo-react-ui/components/pdfprocessing/base64string/
    drawDOM(pdfContentRef.current, PDF_EXPORT_SETTINGS)
      .then((group) => {
        return exportPDF(group)
      })
      .then((dataUri) => {
        const base64String = dataUri.split(';base64,')[1]
        dispatch(lockProduct(base64String))
      })
  }, [dispatch, handleConfirmationDialogClose, pendoTrack])

  useEffect(() => {
    if (!isGenerateReport || isLockLoading) {
      return
    }
    if (isLocked) {
      setConfirmationDialogIsOpen(true)
    } else {
      handleCreateReport()
    }
  }, [isGenerateReport, handleCreateReport, isLocked, isLockLoading])

  const handleCreateReportClicked = () => {
    if (isLocked) {
      setConfirmationDialogIsOpen(true)
    } else {
      handleCreateReport()
    }
  }

  const handleDownloadReport = () => {
    pendoTrack('Formula: Export Product Report', { reportDate: lockedClaimsTimeStamp })
  }

  return (
    <>
      {/* If we apply defaultExpanded={!isLocked || isGenerateReport} it gives an MUI error `A component is changing the default expanded state of an uncontrolled Accordion after being initialized` */}
      <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
        <AccordionSummary>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Icon color={theme.palette.text.primary} size="lg" icon={faScroll} />
            <Text variant="h3" data-testid="create-report-accordion-title">
              Create product report
            </Text>
            {isLocked && <Icon color={theme.palette.success.main} size="lg" icon={faCheck} />}
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={1}>
            <Text>{lockSummary[0]}</Text>
            <Text>{lockSummary[1]}</Text>
            {pageView !== PUBLISHED && <ProductInfo />}
            <ReportHistory />
            {false && (
              <Stack spacing={0}>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={ingredientsListOption}
                      disabled
                      onChange={() => setIngredientsListOption(!ingredientsListOption)}
                    />
                  }
                  label="Include comma-separated list of ingredients in report"
                />
                <Box ml={3.5} mt={-1.5}>
                  <Text variant="caption">{`Ingredients: ${ingredients
                    .map((ingredient) => ingredient.name)
                    .join(', ')}`}</Text>
                </Box>
              </Stack>
            )}
            {!isLocked && (
              <Stack gap={2}>
                <Alert severity="info">{lockAlerts.notLocked}</Alert>
                {lcaOverrideEnabled && (
                  <Alert data-testid="submit-lca-alert" severity="info">
                    {lcaSubmittionAlert}
                  </Alert>
                )}
              </Stack>
            )}
            {isLocked && (
              <Alert severity="success">
                <AlertTitle>
                  <Stack direction="row" justifyContent="space-between">
                    <Text color="inherit" fontWeight="inherit">{`Report created on ${formatDateString({
                      type: LONG,
                      dateString: lockedClaimsTimeStamp,
                    })}`}</Text>
                    {currentClaimsReport && !isSupplierConnect && (
                      <DownloadLink
                        url={currentClaimsReport?.url}
                        filename={`${product.name} report (${currentClaimsReport?.date}).pdf`}
                        onDownload={handleDownloadReport}
                        data-testid="current-report-download"
                      >
                        <Text color="inherit" fontWeight="inherit">
                          Download
                        </Text>
                      </DownloadLink>
                    )}
                    {currentClaimsReport && isSupplierConnect && (
                      <ExternalLink onClick={() => setReportDialogOpen(true)}>
                        <Text color="inherit" fontWeight="inherit">
                          Download
                        </Text>
                      </ExternalLink>
                    )}
                  </Stack>
                </AlertTitle>
                {lockAlerts.locked}
              </Alert>
            )}
          </Stack>
          {pageView !== PUBLISHED && (
            <Stack direction="row" mt={2} justifyContent="end" gap={1}>
              {lcaOverrideEnabled && !isLocked && (
                <a href={lcaSubmissionUrl} target="_blank" rel="noopener noreferrer">
                  <Button color="secondary" id="submit-lca-button" data-testid="submit-lca-button">
                    Submit LCA
                  </Button>
                </a>
              )}
              <Button
                color="primary"
                id="create-report-button"
                data-testid="create-report-button"
                onClick={handleCreateReportClicked}
                loading={isLockLoading}
              >
                {isLocked ? 'Create another report' : 'Create report'}
              </Button>
            </Stack>
          )}
        </AccordionDetails>
      </Accordion>
      <CreateReportConfirmationDialog
        isOpen={confirmationDialogIsOpen}
        closeDialog={handleConfirmationDialogClose}
        createReport={() => handleCreateReport()}
      />
      <ProductGhgImpactExport ref={productGhgImpactExportRef} pdfContentRef={pdfContentRef} />
      {reportDialogOpen && <ProductCFReportDialog closeDialog={() => setReportDialogOpen(false)} />}
    </>
  )
}
