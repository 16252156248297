import { createSelector } from 'reselect'
import { AppState } from '@/store'
import { OriginLocationStandard } from './optimalGhgPotential.requests'

export const selectGhgPotential = (state: AppState): number => state.optimalGhgPotential.value.ghg_potential

export const selectOptGhgPotentialOriginLocationStandards = (state: AppState): OriginLocationStandard[] =>
  state.optimalGhgPotential.value.origin_location_standards

export const selectAbatementData = createSelector(
  selectOptGhgPotentialOriginLocationStandards,
  (originLocationStandards) => {
    return originLocationStandards.map((originLocationStandard) => {
      const location = originLocationStandard.origin_location.region?.name || '-'
      const originAvailableStandards = originLocationStandard.origin_location.origin?.available_standards || []

      const standards = originLocationStandard.standards
        .filter((st) => st.category === 'certification')
        .map((st) => st.title)
      const practices = originAvailableStandards.filter((st) => st.category === 'practice').map((st) => st.title)

      return {
        location,
        standards,
        practices,
      }
    })
  }
)
