import React, { FC } from 'react'
import { ExternalLink, Text, Stack } from '@howgood/design'
import { ProductBrand } from '@/records'

interface Props {
  brand: ProductBrand
}

export const VendorContact: FC<Props> = ({ brand }) => {
  const { website, email, phone } = brand

  return (
    <>
      {email && (
        <Stack direction="row" gap={0.5} mt={1.5} alignItems="center" justifyContent="space-between">
          <Text variant="body1" color="text.secondary">
            Email:
          </Text>
          <Text variant="body1">{email}</Text>
        </Stack>
      )}
      {phone && (
        <Stack direction="row" gap={0.5} mt={1.5} alignItems="center" justifyContent="space-between">
          <Text variant="body1" color="text.secondary">
            Phone:
          </Text>
          <Text variant="body1">{phone}</Text>
        </Stack>
      )}
      {website && (
        <Stack direction="row" gap={0.5} mt={1.5} alignItems="center" justifyContent="space-between">
          <Text variant="body1" color="text.secondary">
            Website:
          </Text>
          <ExternalLink
            variant="body1"
            color="primary.main"
            underline="hover"
            target="_blank"
            rel="noopener noreferrer"
            href={website}
          >
            {website}
          </ExternalLink>
        </Stack>
      )}
    </>
  )
}
