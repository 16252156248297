import React, { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import debounce from 'lodash/debounce'
import { Autocomplete, MenuItem, Placement, TextField, Tooltip } from '@howgood/design'
import { selectEditingFilter, setEditingFilter } from '@/state/products'
import { selectIsProductFiltersLoading } from '@/selectors/selectIsProductFiltersLoading'

const LOADING_OPTION = { value: -123, label: 'Loading...' }

interface Props {
  id: string
  selected: any[] | any
  filterType: string
  handleChangeFilter: (options: any[] | any) => void
  onUpdateQuery: (query: string) => Promise<any[]>
  additionalOptions?: any[]
  placeholder?: string
  shouldUpdateEditingFilter?: boolean
  tooltipTitle?: string
  multiple?: boolean
  optionPosition?: Placement
}

export const AutocompleteFilter: FC<Props> = ({
  id,
  selected,
  filterType,
  handleChangeFilter,
  onUpdateQuery,
  additionalOptions = [],
  placeholder,
  shouldUpdateEditingFilter = true,
  tooltipTitle = '',
  multiple = true,
  optionPosition = 'bottom-start',
}) => {
  const dispatch = useDispatch()
  const isLoading = useSelector(selectIsProductFiltersLoading)
  const editingFilter = useSelector(selectEditingFilter)
  const [options, setOptions] = useState([])

  const handleComponentChange = (updatedOptions: any[] | any) => {
    handleChangeFilter(updatedOptions)
    if (shouldUpdateEditingFilter) {
      dispatch(setEditingFilter(id))
    }
  }

  const fetchComponentOptions = async (query: string) => {
    if (query) {
      setOptions([...new Set([LOADING_OPTION])])
      const searchOptions = await onUpdateQuery(query)
      if (searchOptions?.length > 0) {
        setOptions([...searchOptions.filter((o) => o.value !== LOADING_OPTION.value), ...additionalOptions])
        return
      }
    } else {
      setOptions(additionalOptions)
    }
  }

  const handleQueryUpdate = debounce((query: string) => {
    fetchComponentOptions(query)
  }, 250)

  const capitalizedType = filterType[0].toUpperCase() + filterType.slice(1)

  return (
    <Tooltip title={tooltipTitle || `Filter formulas by ${filterType}`} placement="top">
      <Autocomplete
        data-pendo-id={`Search${capitalizedType}`}
        data-testid={id}
        id={id}
        options={options}
        isOptionEqualToValue={(option, val) => {
          return option.value === val.value
        }}
        value={selected}
        onChange={(_event, ops) => handleComponentChange(ops)}
        disabled={isLoading}
        placeholder={placeholder || capitalizedType}
        filterSelectedOptions
        filterOptions={(ops) => ops}
        renderInput={(params) => (
          <TextField
            placeholder={placeholder || capitalizedType}
            autoFocus={editingFilter === id}
            onChange={(event) => handleQueryUpdate(event.target.value)}
            fullWidth
            {...params}
          />
        )}
        renderOption={(props, option) => {
          return (
            <MenuItem
              {...props}
              key={option.value}
              value={option.value}
              onClick={() => handleComponentChange(multiple ? [...selected, option] : option)}
            >
              {option.label}
            </MenuItem>
          )
        }}
        multiple={multiple}
        fullWidth
        componentsProps={{ popper: { style: { width: 'fit-content' }, placement: optionPosition } }}
      />
    </Tooltip>
  )
}
