import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Stack, MetricTile, Text, Tooltip, Box } from '@howgood/design'
import { selectIsThirdParty } from '@/state/productOverview'
import { useContentfulMetric } from '@/contentfully/hooks'
import { selectSavedImpactData } from '@/state/recipe'
import { selectGhgPotential } from '@/state/optimalGhgPotential'
import { ContentfulTooltip } from '@/components/Scores/ContentfulTooltip'
import { formatNumber } from '@howgood/utils'

export const PotentialCarbonFootprint: FC = () => {
  const thirdPartyProduct = useSelector(selectIsThirdParty)
  const carbonReductionContentfulEntry = useContentfulMetric('carbon_reduction')
  const savedImpactData = useSelector(selectSavedImpactData)
  const ghgPotentialValue = useSelector(selectGhgPotential)
  const cradleToFarmGateValue = savedImpactData.product.carbon_footprint_farm_to_gate_impact

  let potentialCarbonFootprintValue = null
  if (cradleToFarmGateValue != null) {
    potentialCarbonFootprintValue = cradleToFarmGateValue - ghgPotentialValue
  }

  if (thirdPartyProduct) {
    return null
  }

  const potentialCarbonFootprintValueFormatted =
    potentialCarbonFootprintValue != null ? formatNumber(potentialCarbonFootprintValue) : null

  const ghgPotentialValueFormatted = ghgPotentialValue != null ? formatNumber(ghgPotentialValue) : null

  const cradleToFarmGateValueFormatted = cradleToFarmGateValue != null ? formatNumber(cradleToFarmGateValue) : null

  return (
    <Box width={320}>
      <MetricTile
        hideFavoriteIcon
        title={carbonReductionContentfulEntry?.title}
        subtitle={carbonReductionContentfulEntry?.subtitle}
        titleTooltip={({ children }) => (
          <ContentfulTooltip content={carbonReductionContentfulEntry}>{children}</ContentfulTooltip>
        )}
        scoreContentOverride={
          <Stack>
            <Stack flexDirection="row" justifyContent="space-between" alignItems="flex-end">
              <Stack>
                <Text fontSize={32} lineHeight="32px" fontWeight={700}>
                  {ghgPotentialValueFormatted || '- -'}
                </Text>
              </Stack>
              <Text fontSize={32} lineHeight={'32px'} fontWeight={700} color="success.main">
                {potentialCarbonFootprintValueFormatted}
              </Text>
            </Stack>
            <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
              <Stack>
                {potentialCarbonFootprintValueFormatted && (
                  <Tooltip title={carbonReductionContentfulEntry?.units}>
                    <Text variant="caption" textOverflow="ellipsis" noWrap>
                      {carbonReductionContentfulEntry?.units}
                    </Text>
                  </Tooltip>
                )}
              </Stack>
              {cradleToFarmGateValue !== ghgPotentialValue && !!cradleToFarmGateValueFormatted && (
                <Stack flexDirection="row" justifyContent="flex-end" gap={0.5} alignItems="center">
                  <Text variant="caption">
                    {ghgPotentialValue < cradleToFarmGateValue ? 'less' : 'more'} than current footprint of{' '}
                  </Text>
                  <Text fontSize={12} fontWeight={600}>
                    {cradleToFarmGateValueFormatted}
                  </Text>
                </Stack>
              )}
            </Stack>
          </Stack>
        }
      />
    </Box>
  )
}
