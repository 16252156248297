import React, { FC } from 'react'
import { formatNumber } from '@howgood/utils'
import { NumericInput, Text, theme } from '@howgood/design'
import { IImpactMetricOption } from '@/records'
import { useDispatch } from 'react-redux'
import { addMessage } from '@/state/messages'

interface CellParams {
  id: number | string
  metric: IImpactMetricOption
  name: string
  metricGoal: number
  onUpdateGoal: (goal: number) => void
  editable?: boolean
  colored?: boolean
}

export const GoalCell: FC<CellParams> = ({ metric, id, name, metricGoal, onUpdateGoal, editable, colored }) => {
  const dispatch = useDispatch()

  if (!editable) {
    return (
      <Text
        width="100%"
        textAlign="center"
        fontWeight={colored ? 600 : 'inherit'}
        color={colored ? theme.palette.info.light : theme.palette.secondary.main}
      >
        {formatNumber(metricGoal)}
      </Text>
    )
  }

  return (
    <NumericInput
      id={`goal-field-${id}-${metric.value}`}
      ariaLabel={`Goal for ${name} ${metric.label}`}
      numericValue={metricGoal}
      onUpdateValue={onUpdateGoal}
      onBadValue={(value) => {
        dispatch(addMessage({ severity: 'error', message: `${value} is not a valid numeric goal value.` }))
      }}
    />
  )
}
