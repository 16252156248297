import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Stack, Text, Tooltip } from '@howgood/design'
import { WorkspaceListCheckbox } from '@/state/user'
import { selectContentfulMetricData } from '@/state/contentfulData'
import { getContentData } from '@/records/scoreCardData'

interface MetricHeaderCellProps {
  metric: WorkspaceListCheckbox
}

export const MetricHeaderCell: FC<MetricHeaderCellProps> = ({ metric }) => {
  const contentfulMetricData = useSelector(selectContentfulMetricData)

  const { contentfulEntry } = getContentData({ key: metric.value, contentfulMetricData })

  return (
    <Stack width="100%" height="100%" pt={1}>
      <Tooltip title={contentfulEntry?.title || ''}>
        <Text
          variant="body2"
          lineHeight={1.3}
          overflow="hidden"
          textOverflow="ellipsis"
          color="unset !important"
          fontWeight="600"
        >
          {contentfulEntry?.title || ''}
        </Text>
      </Tooltip>
      <Tooltip title={contentfulEntry?.subtitle || ''}>
        <Text variant="caption" lineHeight={1.3} overflow="hidden" textOverflow="ellipsis">
          {contentfulEntry?.subtitle || ''}
        </Text>
      </Tooltip>
      <Text variant="caption" lineHeight={1.3} overflow="hidden" textOverflow="ellipsis">
        {contentfulEntry?.units || ''}
      </Text>
    </Stack>
  )
}
