import React, { ChangeEvent, FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@howgood/design'
import { createCarbonAccountingReport } from '@/state/carbonAccounting'
import { ReportFormFields } from './ReportFormFields'

interface Props {
  open: boolean
  onClose: () => void
}

export const CreateReportDialog: FC<Props> = ({ open, onClose }) => {
  const dispatch = useDispatch()
  const [year, setYear] = useState('')
  const [name, setName] = useState('')

  function createReport() {
    dispatch(createCarbonAccountingReport({ title: name, year: parseInt(year, 10) }))
    onClose()
  }

  return (
    <Dialog data-testid="carbon-accounting-create-dialog" open={open} onClose={onClose}>
      <DialogTitle data-testid="carbon-accounting-create-dialog-title">New carbon accounting report</DialogTitle>
      <DialogContent>
        <Stack pt={1} gap={2}>
          <ReportFormFields
            name={name}
            year={year}
            onChangeName={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
            onChangeYear={(e: ChangeEvent<HTMLInputElement>) => setYear(e.target.value)}
          />
          <Alert data-testid="info-box" severity="info">
            Report year cannot be changed later
          </Alert>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button data-testid="close-button" onClick={onClose}>
          Close
        </Button>
        <Button data-testid="create-button" color="primary" disabled={!year || !name} onClick={createReport}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  )
}
