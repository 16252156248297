import React, { FC } from 'react'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { Tooltip, Text } from '@howgood/design'

export const StandardsCell: FC<GridRenderCellParams> = ({ value }) => {
  if (!value.length) {
    return <>-</>
  }

  const standardsString = value.join(', ')

  return (
    <Tooltip maxWidth={100} title={standardsString}>
      <Text overflow="hidden" textOverflow="ellipsis" variant="body2">
        {standardsString}
      </Text>
    </Tooltip>
  )
}
