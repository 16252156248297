import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { faScroll } from '@fortawesome/pro-regular-svg-icons'
import { Alert, Button, CircularProgress, Grid, LinearProgress, Stack, Text } from '@howgood/design'
import { ContentfulCarbonAccountingKey } from '@/contentfully'
import {
  selectCarbonAccountingLoadingReports,
  selectCarbonAccountingReportById,
  selectCarbonAccountingReportsError,
} from '@/state/carbonAccounting'
import { CarbonAccountingCategory } from './CarbonAccountingCategory'
import { useCarbonAccountingData, useCarbonAccountingCategories } from '../CarbonAccounting.hooks'
import { CarbonAccountingChart } from '../CarbonAccountingChart'
import { CarbonAccountingDialog } from '../CarbonAccountingDialog'
import { CarbonAccountingExport } from '../CarbonAccountingExport'
import { CarbonAccountingTotals } from '../CarbonAccountingTotals'

interface Props {
  reportId: number
  reportYear: number
  reportTitle: string
  condensed?: boolean
  animate?: boolean
}

export const CarbonAccountingContent: FC<Props> = ({
  reportId,
  reportYear,
  reportTitle,
  condensed = false,
  animate = true,
}) => {
  useCarbonAccountingData(reportId, reportYear)
  const [dialogCategory, setDialogCategory] = useState<ContentfulCarbonAccountingKey>(null)
  const report = useSelector(selectCarbonAccountingReportById(reportId))
  const categories = useCarbonAccountingCategories(reportId)
  const reportsError = useSelector(selectCarbonAccountingReportsError)
  const loadingReports = useSelector(selectCarbonAccountingLoadingReports)
  const completeCategories = categories.reduce(
    (acc, category) => acc + (category.not_applicable || category.complete ? 1 : 0),
    0
  )
  const percentComplete = categories.length > 0 ? Math.round((completeCategories / categories.length) * 100) : 0

  const scope3Total = report?.scope_3_total
  const allEmissionsTotal = report?.all_scopes_total

  if (reportsError) {
    return (
      <Stack mt={2}>
        <Alert data-testid="carbon-accounting-error" severity="error">
          There was an error loading carbon accounting data. Please refresh the page. If this doesn't work, contact
          support@howgood.com.
        </Alert>
      </Stack>
    )
  }
  if (loadingReports) {
    return (
      <Stack mt={2}>
        <CircularProgress data-testid="carbon-accounting-loader" />
      </Stack>
    )
  }
  if (!reportId) {
    return (
      <Stack gap={2} mt={2}>
        <Alert data-testid="carbon-accounting-no-reports" severity="warning">
          This account does not have any reports yet.
        </Alert>
        <Link to="/carbon-accounting">
          <Button data-testid="carbon-accounting-no-reports-button" size="small">
            View/edit reports
          </Button>
        </Link>
      </Stack>
    )
  }

  return (
    <Stack data-testid="carbon-accounting-report" flex={1} pb={condensed ? 1 : 0}>
      <CarbonAccountingDialog
        reportId={reportId}
        category={categories.find((category) => category.key === dialogCategory)}
        onClose={() => setDialogCategory(null)}
      />
      <Stack flex={1} flexDirection="row">
        <Stack alignItems="center" width={condensed ? '100%' : undefined} mr={2}>
          <Stack data-testid="carbon-accounting-progress" gap={1} width="100%" p={1}>
            <LinearProgress value={Math.max(percentComplete, 1)} color="success" />
            <Text variant="body2">
              {percentComplete}% data completion ({completeCategories}/{categories.length} categories)
            </Text>
          </Stack>
          <Stack
            flex={1}
            flexDirection={condensed ? 'row' : 'column'}
            alignItems="center"
            justifyContent="center"
            gap={2}
            width="100%"
          >
            <CarbonAccountingChart
              categories={categories}
              allEmissionsTotal={allEmissionsTotal}
              size={condensed ? 'sm' : 'lg'}
              animate={animate}
            />
            <CarbonAccountingTotals reportId={reportId} />
          </Stack>
          {condensed && (
            <Stack width="100%" flexDirection="row" gap={1} justifyContent="flex-end">
              <Link to="/carbon-accounting">
                <Button size="small">View/edit reports</Button>
              </Link>
              <CarbonAccountingExport
                reportId={reportId}
                reportYear={reportYear}
                reportTitle={reportTitle}
                scope3Total={scope3Total}
                allEmissionsTotal={allEmissionsTotal}
              >
                {(onClick) => (
                  <Button
                    variant="contained"
                    startIcon={condensed ? undefined : faScroll}
                    color="primary"
                    size="small"
                    onClick={onClick}
                  >
                    Download report
                  </Button>
                )}
              </CarbonAccountingExport>
            </Stack>
          )}
        </Stack>
        {!condensed && (
          <Grid container>
            {categories.map((category) => (
              <Grid
                key={category.key}
                data-testid="carbon-accounting-category"
                item
                xs={12}
                md={6}
                onClick={() => setDialogCategory(category.key)}
              >
                <CarbonAccountingCategory
                  title={category.title}
                  subtitle={category.subtitle}
                  score={category.score}
                  units={category.units}
                  clickable={category.clickable}
                  complete={category.complete}
                  notApplicable={category.not_applicable}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Stack>
    </Stack>
  )
}
