import React, { FC, useState } from 'react'
import { Button } from '@howgood/design'
import { useDispatch, useSelector } from 'react-redux'
import { login, editEmail, selectUserError, selectLoginChallengeRequired, selectLoginPending } from '@/state/user'
import { selectUserEmail } from '@/state/user'
import { LoginChallenge } from './LoginChallenge'
import { usePendoTrack } from '@/hooks'

export const StandardForm: FC = () => {
  const dispatch = useDispatch()
  const pendoTrack = usePendoTrack()
  const [pass, setPass] = useState('')
  const storeEmail = useSelector(selectUserEmail)
  const hasError = useSelector(selectUserError)
  const loginChallengeRequired = !!useSelector(selectLoginChallengeRequired)
  const loginPending = useSelector(selectLoginPending)

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    pendoTrack('Login: Email')
    dispatch(login({ username: storeEmail, password: pass }))
  }

  const handleEmailChange = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()
    dispatch(editEmail())
  }

  const onPassChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPass(event.target.value)
  }

  return (
    <>
      <form className="login-form" onSubmit={onSubmit}>
        <div className="field">
          <div className="emailContainer">
            <p>{storeEmail}</p>
            <div className="link">
              <a href="/login" onClick={handleEmailChange}>
                Change
              </a>
            </div>
          </div>
        </div>

        <div className="field">
          <label htmlFor="text-password">Password</label>
          <div className="control">
            <input
              className="input"
              onChange={onPassChange}
              autoFocus
              value={pass}
              name="password"
              type="password"
              id="text-password"
            />
          </div>
        </div>
        <div className="field buttonsContainer">
          <div className="control LoginButton">
            <Button aria-label="Sign in" type="submit" color="primary" disabled={loginPending}>
              Sign In
            </Button>
          </div>
          <div className="link">
            <a href="https://ssl.howgood.com/accounts/reset-password/" target="_blank" rel="noopener noreferrer">
              Forgot password?
            </a>
          </div>
        </div>
        {hasError && (
          <article className="message is-danger">
            <div aria-label="Unable to sign in" className="message-header">
              <p>Unable to sign in</p>
            </div>
            <div className="message-body">Please try again or contact HowGood for help.</div>
          </article>
        )}
      </form>
      {loginChallengeRequired && <LoginChallenge password={pass} />}
    </>
  )
}
