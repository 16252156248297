import React, { FC, useCallback, useEffect, useState } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Text,
  Button,
  Stack,
  Icon,
  CircularProgress,
  Tooltip,
} from '@howgood/design'
import { fetchBrands } from '@/api/brandApi'
import { ProductBrand } from '@/records'
import { faCheck } from '@fortawesome/pro-regular-svg-icons'
import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium'

interface VendorConfirmationDialogProps {
  inputString: string
  cancelClicked: () => void
  okClicked: (vendorName: string, fromList: boolean) => void
  onClose: () => void
}

interface SelectedVendor {
  id: number
  name: string
}

const PAGESIZE = 100

export const VendorConfirmationDialog: FC<VendorConfirmationDialogProps> = ({
  inputString,
  cancelClicked,
  okClicked,
  onClose,
}) => {
  const [vendorResults, setVendorResults] = useState<ProductBrand[]>(null)
  const [selectedVendor, setSelectedVendor] = useState<SelectedVendor>(null)
  const [rowCount, setRowCount] = useState(0)

  const fetchData = useCallback(
    async (page: number) => {
      const response = await fetchBrands({
        search: inputString.split(' ')[0], // Search by first word to catch HowGood Inc versus HowGood Corp etc.
        page: page,
        size: PAGESIZE,
      })
      setVendorResults(response.items || [])
      setRowCount(response.total || 0)
    },
    [inputString]
  )

  useEffect(() => {
    fetchData(1) // Fetch page 1 of the fetchBrands results
  }, [fetchData])

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Stack direction="row" alignItems="center" gap={2} overflow="hidden">
          {params.value === selectedVendor?.name && <Icon icon={faCheck} fontSize={14} />}
          <Tooltip title={params.value}>
            <Text overflow="hidden" textOverflow="ellipsis">
              {params.value}
            </Text>
          </Tooltip>
        </Stack>
      ),
    },
    {
      field: 'website',
      headerName: 'Website',
      width: 150,
      sortable: false,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Text overflow="hidden" textOverflow="ellipsis">
            {params.value}
          </Text>
        </Tooltip>
      ),
    },
  ]

  const handleListItemSelected = (id: number) => {
    const name = vendorResults.find((vendor) => vendor.id === id)?.name
    if (name === selectedVendor?.name) {
      // Clear the curent selection
      setSelectedVendor(null)
    } else {
      setSelectedVendor({ id, name })
    }
  }

  const handleOkClicked = () => {
    if (selectedVendor) {
      okClicked(selectedVendor.name, true) // Send true since user selected from the list
    } else {
      okClicked(inputString, false) // Send false since the user didn't select from the list
    }
  }

  if (!vendorResults)
    // The null state while the initial fetch is pending, not the case where there are no matches
    return (
      <Dialog id="vendor-confirmation-dialog" open onClose={onClose}>
        <DialogContent>
          <Stack direction="row" alignItems="center" gap={2}>
            <CircularProgress size={20} />
            <Text>Searching for matching vendors...</Text>
          </Stack>
        </DialogContent>
      </Dialog>
    )

  return (
    <Dialog id="vendor-confirmation-dialog" open>
      <DialogTitle>{!selectedVendor ? `Confirm new vendor name: ${inputString}` : 'Confirm vendor'}</DialogTitle>
      <DialogContent>
        <Stack gap={1}>
          {vendorResults.length === 0 && (
            <Text>
              Are you sure you want to add a new vendor <b>{inputString}</b> to the system?
            </Text>
          )}
          {vendorResults.length > 0 && (
            <>
              <Text>
                Did you mean one of the following? If so, select the vendor from the list and click OK.{' '}
                {!selectedVendor ? 'Otherwise, click OK to add the new vendor.' : ''}
              </Text>
              <Text variant="caption">{selectedVendor?.name ? `Selected: ${selectedVendor.name}` : '\u00A0'}</Text>
              <Stack maxHeight={180} overflow="auto" border={1} data-testid="vendor-confirmation-grid">
                <DataGridPremium
                  density="compact"
                  columns={columns}
                  rows={vendorResults}
                  autoHeight
                  disableColumnMenu
                  disableMultipleRowSelection
                  onRowSelectionModelChange={(newRowSelectionModel) => {
                    if (newRowSelectionModel.length > 0) {
                      handleListItemSelected(+newRowSelectionModel[0])
                    }
                  }}
                  rowSelectionModel={[selectedVendor?.id.toString() || '']}
                  pagination
                  paginationMode="server"
                  rowCount={rowCount}
                  hideFooterSelectedRowCount
                  initialState={{
                    pagination: { paginationModel: { page: 0, pageSize: PAGESIZE } },
                  }}
                  onPaginationModelChange={(newPaginationModel) => {
                    fetchData(newPaginationModel.page + 1)
                  }}
                  pageSizeOptions={[PAGESIZE]}
                  sx={{
                    '& .MuiDataGrid-columnHeader:focus-within': { outline: 'none' },
                    '& .MuiDataGrid-cell:focus-within': { outline: 'none' },
                  }}
                />
              </Stack>
            </>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" alignItems="center" width="100%" gap={1}>
          <Stack flex={1} />
          <Button id="vendor-confirmation-dialog-cancel-button" onClick={cancelClicked}>
            Cancel
          </Button>
          <Button id="vendor-confirmation-dialog-ok-button" color="primary" onClick={handleOkClicked}>
            OK
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}
