import React, { FC, useMemo } from 'react'
import {
  DataGridPremium,
  GridExcelExportOptions,
  GridExceljsProcessInput,
  useGridApiRef,
} from '@mui/x-data-grid-premium'
import { useSelector } from 'react-redux'
import { Button, Stack } from '@howgood/design'
import { usePendoTrack } from '@/hooks'
import { selectExportPaywallSplit } from '@/state/splitio'
import { getDateParts } from '@/utils/dates'
import { transformScoreToZeroToTenScale } from '@/utils/impactScoreHelpers'
import { DataGridExportContainer } from '../DataGrid'
import { useDetailColumns, useTableColumns } from './ComparisonResults/ComparisonResults.hooks'
import { IngredientTableRow } from './types'
import { autoAdjustColWidths } from '@/utils/excelUtils'

interface Props {
  data: IngredientTableRow[]
}

export const Export: FC<Props> = ({ data }) => {
  const pendoTrack = usePendoTrack()
  const apiRef = useGridApiRef()
  const exportPaywallEnabled = useSelector(selectExportPaywallSplit)
  const tableColumns = useTableColumns()
  const detailColumns = useDetailColumns()
  const tableFields = useMemo(() => tableColumns.map((col) => col.field), [tableColumns])
  const detailFields = useMemo(() => detailColumns.map((col) => col.field), [detailColumns])

  function getRows(isOneToTen: boolean = false) {
    return data.map((d) =>
      tableFields.map((field) => {
        // impact scores like "blue_water_impact"
        if (field in d.impactScore) {
          const value = d.impactScore[field]
          if (isOneToTen && detailFields.includes(field)) {
            const transformedValue = transformScoreToZeroToTenScale(field, value)
            return transformedValue && !isNaN(transformedValue) ? transformedValue : null
          }
          return value
        }
        // non impact scores like "ingredient" or "standard"
        return d[field]
      })
    )
  }

  async function exceljsPreProcess({ workbook, worksheet: sheet1 }: GridExceljsProcessInput) {
    const sheet2 = workbook.addWorksheet()
    sheet1.name = 'Ingredient Comp (1-10)'
    sheet2.name = 'Ingredient Comp (Raw Values)'
    const headerRow = tableColumns.map((col) => col.headerName)
    const sheet1Rows = getRows(true)
    const sheet2Rows = getRows()
    sheet1.addRows([headerRow, ...sheet1Rows])
    sheet2.addRows([headerRow, ...sheet2Rows])
    workbook.worksheets.forEach((worksheet) => {
      autoAdjustColWidths(worksheet, 60)
    })
  }
  function exportData() {
    const { year, month, day } = getDateParts()
    const exportApi = apiRef.current
    const options: GridExcelExportOptions = {
      fileName: `Compare Ingredients_${month}.${day}.${year}`,
      exceljsPreProcess,
    }
    exportApi.exportDataAsExcel(options)
  }

  function handleExport() {
    pendoTrack('Ingredient Planning: Export')
    exportData()
  }

  return (
    <Stack>
      <Button
        disabled={exportPaywallEnabled}
        data-pendo-id={exportPaywallEnabled ? 'exportpaywall' : undefined}
        onClick={handleExport}
      >
        Export
      </Button>

      <DataGridExportContainer>
        <DataGridPremium apiRef={apiRef} columns={[]} />
      </DataGridExportContainer>
    </Stack>
  )
}
