import React, { FC, useState } from 'react'
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Stack, Text, TextField } from '@howgood/design'
import { batch, useDispatch, useSelector } from 'react-redux'
import { login, selectLoginChallengeRequired, selectUserEmail, setLoginChallenge } from '@/state/user'

interface LoginChallengeProps {
  password: string
}

export const LoginChallenge: FC<LoginChallengeProps> = ({ password }) => {
  const dispatch = useDispatch()
  const storeEmail = useSelector(selectUserEmail)
  const loginChallenge = useSelector(selectLoginChallengeRequired)

  const [otp, setOtp] = useState('')

  const handleButtonClicked = () => {
    batch(() => {
      dispatch(login({ username: storeEmail, password: password, otp: otp }))
      dispatch(setLoginChallenge(false)) // Closes the dialog
    })
  }

  const challengeText = {
    MFA_REQUIRED: `Enter the one-time password that was sent to ${storeEmail}. You may need to check your spam folder.`,
    APP_REQUIRED: `Enter the one-time password from your authentication app.`,
  }

  return (
    <Dialog data-testid="mfa-challenge-dialog" open onClose={() => dispatch(setLoginChallenge(false))}>
      <DialogTitle>Multi-factor authentication is enabled</DialogTitle>
      <form onSubmit={handleButtonClicked}>
        <DialogContent>
          <Stack gap={1}>
            <Text>{challengeText[loginChallenge]}</Text>
            <TextField value={otp} onChange={(e) => setOtp(e.target.value)} />
            {loginChallenge === 'MFA_REQUIRED' && (
              <>
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ '& .MuiButton-root': { '&:focus': { outline: 'none' } } }}
                >
                  <Text variant="body2">Didn&apos;t receive an email?</Text>
                  <Button
                    size="small"
                    variant="text"
                    color="primary"
                    onClick={() => dispatch(login({ username: storeEmail, password: password }))}
                  >
                    Resend
                  </Button>
                </Stack>
              </>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button id="mfa-ok-button" color="primary" type="submit" disabled={!otp}>
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
