import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Switch, FormControlLabel, Tooltip, Text } from '@howgood/design'
import { selectShowAtRiskOnly, toggleAtRisk } from '@/state/products'
import { selectCurrentScenarioId } from '@/state/initiatives'

export const ShowAtRiskToggle: FC = () => {
  const dispatch = useDispatch()
  const showAtRisk = useSelector(selectShowAtRiskOnly)
  const scenarioId = useSelector(selectCurrentScenarioId)

  if (scenarioId) {
    return null
  }

  const onToggle = () => {
    dispatch(toggleAtRisk())
  }

  return (
    <Tooltip title={<Text>Applies a filter that only shows formulas with at least one at-risk impact score</Text>}>
      <FormControlLabel
        data-testid="show-at-risk-toggle"
        control={<Switch checked={showAtRisk} onChange={onToggle} />}
        label="Show only at-risk formulas"
      />
    </Tooltip>
  )
}
