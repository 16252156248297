import React from 'react'
import { createUseStyles } from 'react-jss'
import { NodeRenderer } from '@contentful/rich-text-react-renderer'

const useStyles = createUseStyles({
  paragraph: {
    marginBottom: 20,
  },
})

export const ParagraphRenderer: NodeRenderer = (_node, children) => {
  const classes = useStyles()
  return <p className={classes.paragraph}>{children}</p>
}
