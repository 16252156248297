import { GridApi, GridColumnVisibilityModel } from '@mui/x-data-grid-premium'
import { IngredientTableRow } from '../types'

/**
 * Substitute for MUI's lack of gridApi.getColumnVisibilityModel method
 *
 * @param gridApi MUI Premium GridApi
 * @returns currently applied column visibility model
 */
export function getColumnVisibilityModel(gridApi: GridApi): GridColumnVisibilityModel {
  const allColumns = gridApi.getAllColumns()
  const visibleColumns = gridApi.getVisibleColumns()
  const hiddenColumns = allColumns.filter(
    (col) => visibleColumns.findIndex((visibleCol) => visibleCol.field === col.field) === -1
  )
  const visibleColumnsModel = visibleColumns.reduce((acc, col) => ({ ...acc, [col.field]: true }), {})
  const hiddenColumnsModel = hiddenColumns.reduce((acc, col) => ({ ...acc, [col.field]: false }), {})
  return { ...visibleColumnsModel, ...hiddenColumnsModel }
}

/**
 * Get a flattened list of selected rows and row groups
 *
 * @param gridApi MUI Premium GridApi
 * @returns selected rows as an array
 */
export function getSelectedRows(gridApi: GridApi): IngredientTableRow[] {
  const selectedRowsMap = gridApi.getSelectedRows()
  return [...selectedRowsMap].reduce((acc, [rowId, row]) => {
    if (row) {
      return [...acc, row]
    }
    // Grouped row selected
    const rowIds = gridApi.getRowGroupChildren({ groupId: rowId })
    const rows = rowIds.map((id) => gridApi.getRow(id))
    return [...acc, ...rows]
  }, [])
}
