import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { faWrench } from '@fortawesome/pro-regular-svg-icons'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { Box, IconButton, Stack, Text, Tooltip } from '@howgood/design'
import { DRAFT, selectPageView } from '@/state/pageSettings'
import { selectIngredientWarnings, RecipeIngredient, RecipeNestedIngredient } from '@/state/recipe'
import { ProcessingLocationInput } from './ProcessingLocationInput'
import { TransportationDialog } from './TransportationDialog'

interface ProcessingLocationProps extends GridRenderCellParams {
  value?: {
    ingredient: RecipeIngredient | RecipeNestedIngredient
  }
}

export const ProcessingLocation: FC<ProcessingLocationProps> = (params) => {
  const ingredient = params.value.ingredient
  const [transportationDialogOpen, setTransportationDialogOpen] = useState(false)
  const ingredientErrors = useSelector(selectIngredientWarnings)
  const view = useSelector(selectPageView)

  const invalidIngredient = ingredientErrors.map((error) => error.id).includes(ingredient.id)

  if (!('base_id' in ingredient)) {
    return null
  }

  if (ingredient.isTopLevel && view === DRAFT) {
    return (
      <Stack flexDirection="row" alignItems="center" width="100%">
        <ProcessingLocationInput ingredient={ingredient} isTableView />
        <IconButton
          data-testid="transportation-icon"
          icon={faWrench}
          onClick={() => setTransportationDialogOpen(true)}
        />
        <TransportationDialog
          open={transportationDialogOpen}
          onClose={() => setTransportationDialogOpen(false)}
          ingredient={ingredient}
        />
      </Stack>
    )
  }

  return (
    <Tooltip title={ingredient.processing_location_name}>
      <Box sx={{ cursor: 'pointer', opacity: view === DRAFT ? 0.7 : 1 }}>
        <Text color={invalidIngredient ? 'error.main' : null} textOverflow="ellipsis" noWrap>
          {ingredient.processing_location_name}
        </Text>
      </Box>
    </Tooltip>
  )
}
