import { selectProductMetricsLoading } from '@/state/productMetrics'
import { selectIsFormulaOveriewLoading } from '@/state/recipe'
import { selectIsRollup } from '@/state/router'
import { createSelector } from 'reselect'

export const selectIsScorecardLoading = createSelector(
  selectIsRollup,
  selectIsFormulaOveriewLoading,
  selectProductMetricsLoading,
  (isRollup, productLoading, rollupLoading) => (isRollup ? rollupLoading : productLoading)
)
