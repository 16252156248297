import React, { FC, MouseEventHandler, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router'
import { Link } from 'react-router-dom'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { Box, Icon, Stack } from '@howgood/design'

import { useNavigate } from '../../hooks/useNavigate'
import {
  initialState,
  selectWorkspaceProductFilters,
  updateFiltersUrlAndProducts,
  updateProductFilters,
} from '@/state/products'
import { faAngleDown, faAngleRight } from '@fortawesome/pro-solid-svg-icons'
import { WorkspaceType } from '@/records'
import { WorkspacesList } from './WorkspacesList'

interface WorkspaceSidebarItemProps {
  label: string
  ariaLabel?: string
  path: string
  icon: IconProp
  className?: string
  disabled?: boolean
  ['data-testid']?: string
  pendoId?: string
  workspaceType: WorkspaceType
}

export const WorkspaceSidebarItem: FC<WorkspaceSidebarItemProps> = ({
  label,
  ariaLabel,
  path,
  icon,
  disabled,
  ['data-testid']: dataTestId,
  pendoId,
  workspaceType,
}) => {
  const dispatch = useDispatch()
  const { safelyNavigateTo } = useNavigate()
  const match = useRouteMatch({ path })
  const workspaceFilters = useSelector(selectWorkspaceProductFilters)
  const [expanded, setExpanded] = useState(false)

  const onClickExpand: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation()
    setExpanded(!expanded)
  }

  const onClick: MouseEventHandler<HTMLDivElement> = (e) => {
    if (disabled) {
      return
    }
    if (!match?.isExact) {
      dispatch(updateProductFilters(initialState.productFilters))
      safelyNavigateTo(path, e)
    } else if (workspaceFilters.length) {
      dispatch(updateFiltersUrlAndProducts({ updatedFilters: { workspaces: [] } }))
    }
  }

  return (
    <>
      <Stack
        direction="row"
        width="100%"
        onClick={onClick}
        justifyContent="space-between"
        alignItems="center"
        p="3px 8px 3px 10px"
        sx={{
          fontWeight: 700,
          borderLeft: match ? '6px solid #1D9862' : 'unset',
          backgroundColor: match ? '#343F50' : 'unset',
          paddingLeft: match ? '4px' : '10px',
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: '#343F50',
          },
        }}
      >
        <Link to="#" aria-label={ariaLabel || label} data-pendo-id={pendoId} data-testid={dataTestId}>
          <Stack direction="row" alignItems="center" gap={2}>
            <Icon icon={icon} color="white" />
            {label}
          </Stack>
        </Link>
        <Box onClick={onClickExpand}>
          <Icon icon={expanded ? faAngleDown : faAngleRight} color="white" data-testid={`${dataTestId}-icon`} />
        </Box>
      </Stack>
      {expanded && <WorkspacesList workspaceType={workspaceType} onClick={onClick} path={path} />}
    </>
  )
}
