import React, { FC } from 'react'
import { Route, Redirect, RouteComponentProps } from 'react-router'
import { useSelector } from 'react-redux'
import { selectUserAuthenticated } from '@/state/user'

interface Props {
  component: FC<RouteComponentProps>
  path?: string
  exact?: boolean
}

export const PrivateRoute: FC<Props> = ({ component: Component, path, exact }) => {
  const isAuthenticated = useSelector(selectUserAuthenticated)

  return (
    <Route
      path={path}
      exact={exact}
      render={(renderProps) =>
        isAuthenticated ? (
          <Component {...renderProps} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: renderProps.location },
            }}
          />
        )
      }
    />
  )
}
