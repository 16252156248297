import { AppState } from '@/store'
import { createSelector } from 'reselect'
import { ManufacturingType, ManufacturingTypeOption } from './manufacturingTypes.slice'

export const selectManufacturingTypes = (state: AppState): ManufacturingType[] =>
  state.manufacturingTypes.manufacturingTypes

export const selectManufacturingTypeOptions = createSelector<AppState, ManufacturingType[], ManufacturingTypeOption[]>(
  selectManufacturingTypes,
  (manufacturingTypes) => {
    return manufacturingTypes
      .map((mT) => ({ id: mT.id, value: mT.id, label: mT.title }))
      .sort((a, b) => a.label.localeCompare(b.label))
  }
)
