import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { faCheckSquare } from '@fortawesome/pro-regular-svg-icons'
import { Button, CircularProgress, Stack, Text } from '@howgood/design'

import { IProduct } from '@/records'
import { selectActiveBulkJob, selectProductsWithSelectedImpactData } from '@/state/products'
import { EditTagsDialog } from '../EditTagsDialog'
import { selectCurrentScenarioId } from '@/state/initiatives'
import { selectIsProcurement } from '@/state/router'
import { UpdateStatusDialog } from '../UpdateStatusDialog'
import { ScenarioAddStandardsDialog } from './ScenarioAddStandardsDialog'

interface Props {
  showBulkActions: boolean
  handleToggleBulkActions: () => void
  selectedRowIds: number[]
  setSelectedRowIds: React.Dispatch<React.SetStateAction<number[]>>
}

export const BulkActions: FC<Props> = ({
  showBulkActions,
  handleToggleBulkActions,
  selectedRowIds,
  setSelectedRowIds,
}) => {
  const products = useSelector(selectProductsWithSelectedImpactData)
  const currentScenario = useSelector(selectCurrentScenarioId)
  const isProcurement = useSelector(selectIsProcurement)
  const activeBulkJob = useSelector(selectActiveBulkJob)

  const [isAddTagsDialogOpen, setIsAddTagsDialogOpen] = useState(false)
  const [isRemoveTagsDialogOpen, setIsRemoveTagsDialogOpen] = useState(false)
  const [isUpdateStatusDialogOpen, setIsUpdateStatusDialogOpen] = useState(false)
  const [isScenarioChangeStandardsDialogOpen, setIsScenarioChangeStandardsDialogOpen] = useState(false)

  const applyToSelection = (cb: (product: IProduct) => void) => {
    const selectedProducts = products.filter((product) => selectedRowIds.includes(product.id))

    selectedProducts.forEach(cb)
  }

  return (
    <>
      {!showBulkActions && (
        <Button
          data-testid="bulk-edit-button"
          data-pendo-id="BulkEdit"
          onClick={handleToggleBulkActions}
          startIcon={faCheckSquare}
        >
          Bulk edit
        </Button>
      )}
      {showBulkActions && (
        <>
          {currentScenario ? (
            <Button
              data-pendo-id="BulkEdit>ScenarioChangeStandards"
              disabled={!!activeBulkJob || selectedRowIds.length === 0}
              onClick={() => setIsScenarioChangeStandardsDialogOpen(true)}
            >
              {!activeBulkJob && <Text>Change standards</Text>}
              {activeBulkJob && (
                <Stack gap={2} direction="row" alignItems="center">
                  <Text>Updating</Text>
                  <CircularProgress size={16} />
                </Stack>
              )}
            </Button>
          ) : (
            <>
              <Button
                data-pendo-id="BulkEdit>Add"
                data-testid="bulk-edit-add-tags-button"
                disabled={selectedRowIds.length === 0}
                onClick={() => setIsAddTagsDialogOpen(true)}
              >
                Add tags
              </Button>
              <Button
                data-pendo-id="BulkEdit>Remove"
                data-testid="bulk-edit-remove-tags-button"
                disabled={selectedRowIds.length === 0}
                onClick={() => setIsRemoveTagsDialogOpen(true)}
              >
                Remove tags
              </Button>
              {!isProcurement && (
                <Button
                  data-testid="bulk-edit-update-status-button"
                  data-pendo-id="BulkEdit>UpdateStatus"
                  disabled={selectedRowIds.length === 0}
                  onClick={() => setIsUpdateStatusDialogOpen(true)}
                >
                  Update status
                </Button>
              )}
            </>
          )}
          <Button
            data-testid="bulk-edit-done-button"
            data-pendo-id="BulkEdit>Done"
            color="primary"
            onClick={() => {
              handleToggleBulkActions()
              setSelectedRowIds([])
            }}
          >
            Done
          </Button>
        </>
      )}
      {(isAddTagsDialogOpen || isRemoveTagsDialogOpen) && (
        <EditTagsDialog
          onClose={() => (isAddTagsDialogOpen ? setIsAddTagsDialogOpen(false) : setIsRemoveTagsDialogOpen(false))}
          applyToSelection={applyToSelection}
          title={isAddTagsDialogOpen ? 'Add tags' : 'Remove tags'}
          addTagsEnabled={isAddTagsDialogOpen}
        />
      )}
      {isUpdateStatusDialogOpen && (
        <UpdateStatusDialog onClose={() => setIsUpdateStatusDialogOpen(false)} applyToSelection={applyToSelection} />
      )}
      {isScenarioChangeStandardsDialogOpen && (
        <ScenarioAddStandardsDialog
          productIds={selectedRowIds}
          closeDialog={() => setIsScenarioChangeStandardsDialogOpen(false)}
        />
      )}
    </>
  )
}
