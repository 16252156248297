import React, { FC, MutableRefObject } from 'react'
import { faFileCsv, faFileExcel, faStore } from '@fortawesome/pro-solid-svg-icons'
import { GridApi } from '@mui/x-data-grid-premium'
import { Button, Stack } from '@howgood/design'
import { RequestVendorData } from '@/components/Procurement/RequestVendorData'
import { Heading } from '@/components/Layout/Heading'

interface Props {
  gridApiRef: MutableRefObject<GridApi>
}

export const VendorManagementHeader: FC<Props> = ({ gridApiRef }) => {
  return (
    <Heading
      title="Vendor Management"
      icon={faStore}
      aria-label="Vendor Management"
      actions={
        <Stack direction="row" alignItems="center" gap={1}>
          <RequestVendorData />
          <Button
            startIcon={faFileExcel}
            size="small"
            onClick={() => gridApiRef.current.exportDataAsExcel({ fileName: 'Vendors' })}
          >
            Download Excel
          </Button>
          <Button
            startIcon={faFileCsv}
            size="small"
            onClick={() => gridApiRef.current.exportDataAsCsv({ fileName: 'Vendors' })}
          >
            Download CSV
          </Button>
        </Stack>
      }
    />
  )
}
