import React, { FC } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Text } from '@howgood/design'
import { WorkspaceSelect } from './WorkspaceSelect'

interface ConfigureProps {
  closeDialog: () => void
}

export const ConfigureDialog: FC<ConfigureProps> = ({ closeDialog }) => {
  const handleOKClicked = () => {
    closeDialog()
  }

  return (
    <>
      <Dialog id="bulk-edit-dialog" open>
        <DialogTitle>Configure Dashboard</DialogTitle>
        <DialogContent>
          <Stack gap={2}>
            <Text>
              The default dashboard displays breakdown across all editable user workspaces, but for testing you might
              want to use a single workspace.
            </Text>
            <WorkspaceSelect />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button id="configure-dialog-cancel-button" onClick={closeDialog}>
            Cancel
          </Button>
          <Button id="configure-dialog-ok-button" color="primary" onClick={handleOKClicked}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
