import React, { forwardRef } from 'react'
import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faLoader } from '@fortawesome/pro-regular-svg-icons'

export interface ButtonProps
  extends Pick<MuiButtonProps, 'id' | 'type' | 'variant' | 'onClick' | 'disabled' | 'fullWidth' | 'children'> {
  color?: 'primary' | 'secondary' | 'error'
  size?: 'small' | 'medium'
  startIcon?: IconDefinition
  endIcon?: IconDefinition
  loading?: boolean
  ['aria-label']?: string
  ['data-pendo-id']?: string
  ['data-testid']?: string
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      id,
      variant = 'contained',
      color = 'secondary',
      size,
      fullWidth,
      type,
      onClick,
      startIcon,
      endIcon,
      disabled,
      loading,
      ['aria-label']: ariaLabel,
      ['data-pendo-id']: dataPendoId,
      ['data-testid']: dataTestId,
      children,
      ...rest
    },
    ref
  ) => {
    return (
      <MuiButton
        ref={ref}
        id={id}
        aria-label={ariaLabel}
        data-pendo-id={dataPendoId}
        data-testid={dataTestId || 'button'}
        variant={variant}
        color={color}
        size={size}
        fullWidth={fullWidth}
        type={type}
        onClick={onClick}
        startIcon={
          startIcon && (
            <FontAwesomeIcon
              data-testid="button-start-icon"
              icon={startIcon}
              visibility={loading ? 'hidden' : 'visible'}
              style={{ fontSize: 'inherit' }}
            />
          )
        }
        endIcon={
          endIcon && (
            <FontAwesomeIcon
              data-testid="button-end-icon"
              icon={endIcon}
              visibility={loading ? 'hidden' : 'visible'}
              style={{ fontSize: 'inherit' }}
            />
          )
        }
        disabled={disabled || loading}
        disableRipple
        {...rest} // For tooltip/popover
      >
        {loading && (
          <FontAwesomeIcon data-testid="button-loading-icon" icon={faLoader} style={{ position: 'absolute' }} spin />
        )}
        <div data-testid="button-text" style={{ visibility: loading ? 'hidden' : 'visible' }}>
          {children}
        </div>
      </MuiButton>
    )
  }
)

Button.displayName = 'Button'
