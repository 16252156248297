import React, { FC } from 'react'
import { MenuItem, Select, SelectChangeEvent } from '@howgood/design'
import { useDispatch, useSelector } from 'react-redux'
import { OriginLocationMapping, selectOriginLocations } from '@/state/originLocations'
import { RecipeIngredient, updateIngredient } from '@/state/recipe'

/**
 * This is a simplifed version of the source select used with SupplierConnect only
 */

interface SimpleSelectProps {
  ingredient: RecipeIngredient
  isInvalidSelection: boolean
}

export const SimpleSelect: FC<SimpleSelectProps> = ({ ingredient, isInvalidSelection }) => {
  const originLocations = useSelector(selectOriginLocations) // Available sources for all product ingredients
  const dispatch = useDispatch()

  if (!ingredient?.base_id || originLocations.length === 0) return null

  // Get the available sourcing locations for the specified ingredient
  const availableSources = originLocations
    .filter((mapping) => mapping.base_id === ingredient.base_id)
    .sort((a, b) => a.region_name.localeCompare(b.region_name))

  const ingredientSource = availableSources.find(
    (source) => source.origin_location_id === ingredient.origin_location_id
  )

  // After selecting a source location, update the recipe
  const handleLocationChanged = (event: SelectChangeEvent<unknown>) => {
    const locationMapping = event.target.value as OriginLocationMapping
    const locationFieldsToUpdate = {
      origin_location_id: locationMapping.origin_location_id,
      origin_location_name: locationMapping.region_name,
    }
    dispatch(updateIngredient(ingredient, locationFieldsToUpdate))
  }

  return (
    <Select
      id="simple-source-select"
      data-testid="simple-source-select"
      value={ingredientSource || ''}
      renderValue={(value) => {
        return (value as OriginLocationMapping).region_name
      }}
      onChange={handleLocationChanged}
      fullWidth
      sx={{
        '& .MuiSelect-select': {
          py: 0.25,
          bgcolor: 'white',
          border: isInvalidSelection ? 2 : 1,
          borderColor: isInvalidSelection ? 'error.main' : 'secondary.light',
        },
      }}
    >
      {availableSources.map((source) => (
        <MenuItem key={source.origin_location_id} value={source as any}>
          {source.region_name}
        </MenuItem>
      ))}
    </Select>
  )
}
