import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  getEmissionsActivites,
  getCarbonAccountingReports,
  getCarbonAccountingAvailableYears,
  createCarbonAccountingReport,
  getCarbonAccountingReportActivities,
  getCarbonAccountingReport,
  updateCarbonAccountingReport,
  removeCarbonAccountingReport,
  replaceCarbonAccountingReport,
} from './carbonAccounting.thunk'
import { CarbonAccountingReport, CarbonAccountingReportActivity, EmissionsActivity } from './carbonAccounting.types'

export interface State {
  loadingReports: boolean
  reportsError: boolean
  reports: CarbonAccountingReport[]
  emissionsActivities: EmissionsActivity[]
  editedEmissionsActivityIds: number[]
  reportActivities: CarbonAccountingReportActivity[]
  years: number[]
}

export const initialState: State = {
  loadingReports: false,
  reportsError: false,
  reports: [],
  emissionsActivities: [],
  editedEmissionsActivityIds: [],
  reportActivities: [],
  years: [],
}

const carbonAccountingSlice = createSlice({
  name: 'carbonAccounting',
  initialState,
  reducers: {
    changeEditedActivities: (state, action: PayloadAction<number[]>) => {
      state.editedEmissionsActivityIds = action.payload
    },
    setCarbonAccountingReportActivities: (state, action: PayloadAction<CarbonAccountingReportActivity[]>) => {
      state.reportActivities = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCarbonAccountingReports.pending, (state) => {
      state.loadingReports = true
    })
    builder.addCase(getCarbonAccountingReports.rejected, (state) => {
      state.loadingReports = false
      state.reportsError = true
    })
    builder.addCase(getCarbonAccountingReports.fulfilled, (state, action) => {
      state.reports = action.payload
      state.reportsError = false
      state.loadingReports = false
    })

    builder.addCase(getCarbonAccountingReport.pending, (state) => {
      state.loadingReports = true
      state.reportsError = false
    })
    builder.addCase(getCarbonAccountingReport.rejected, (state) => {
      state.loadingReports = false
      state.reportsError = true
    })
    builder.addCase(getCarbonAccountingReport.fulfilled, (state, action) => {
      state.reports[0] = action.payload
      state.loadingReports = false
      state.editedEmissionsActivityIds = []
    })

    builder.addCase(createCarbonAccountingReport.fulfilled, (state, action) => {
      state.reports = [action.payload, ...state.reports]
    })
    builder.addCase(updateCarbonAccountingReport.fulfilled, (state, action) => {
      const index = state.reports.findIndex((r) => r.id === action.payload.id)
      state.reports[index] = action.payload
    })
    builder.addCase(replaceCarbonAccountingReport.fulfilled, (state, action) => {
      const index = state.reports.findIndex((r) => r.id === action.payload.id)
      state.reports[index] = action.payload
    })
    builder.addCase(removeCarbonAccountingReport.fulfilled, (state, action: PayloadAction<number>) => {
      state.reports = state.reports.filter((r) => r.id !== action.payload)
    })

    builder.addCase(getEmissionsActivites.fulfilled, (state, action) => {
      state.emissionsActivities = action.payload
    })
    builder.addCase(getCarbonAccountingReportActivities.fulfilled, (state, action) => {
      state.reportActivities = action.payload
    })
    builder.addCase(getCarbonAccountingAvailableYears.fulfilled, (state, action) => {
      state.years = action.payload
    })
  },
})

export const { changeEditedActivities, setCarbonAccountingReportActivities } = carbonAccountingSlice.actions

export const carbonAccountingReducer = carbonAccountingSlice.reducer
