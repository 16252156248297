import React, { FC, useState } from 'react'
import { faHeart as faHeartBorder } from '@fortawesome/pro-regular-svg-icons'
import { faHeart, faHeartBroken } from '@fortawesome/pro-solid-svg-icons'
import { Box } from '../Box'
import { theme } from '../theme'
import { Icon } from '../Icon'

interface Props {
  favorite: boolean
  isFavorites?: boolean
  handleFavorite: () => void
}

export const FavoriteIcon: FC<Props> = ({ favorite, isFavorites = false, handleFavorite }) => {
  const [hover, setHover] = useState(false)
  const icon = isFavorites && hover ? faHeartBroken : favorite ? faHeart : faHeartBorder

  return (
    <Box
      data-testid="favorite-icon"
      color={isFavorites || !favorite ? 'divider' : theme.palette.error.light}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      sx={{
        cursor: 'pointer',
        '& :hover': { color: favorite ? theme.palette.error.light : theme.palette.text.secondary },
      }}
      onClick={handleFavorite}
    >
      <Icon data-testid="favorite-icon-button" data-icon={icon.iconName} size="sm" color="inherit" icon={icon} />
    </Box>
  )
}
