import React, { FC, MouseEvent, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Button, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip } from '@howgood/design'
import { faArrowUpFromBracket, faFileContract, faPaperPlane } from '@fortawesome/pro-regular-svg-icons'

import { SustainabilityScorecardDialog } from '@/components/ProductImpact/ExportProductOverview/SustainabilityScorecardDialog'
import { selectDisplayedProduct, selectIsThirdParty } from '@/state/productOverview'
import { selectDisplayedProductImpactData } from '@/state/recipe'
import { selectSendMessageSplit } from '@/state/splitio'
import { setLiteUserPaywallDialogState } from '@/state/liteUserPaywallDialog'
import { SendMessageDialog } from '../SendMessage/SendMessageDialog'
import { selectIsSupplierConnect } from '@/state/organization'
import { selectPageView } from '@/state/pageSettings'

export const ExportScorecard: FC = () => {
  const productData = useSelector(selectDisplayedProduct)
  const impactData = useSelector(selectDisplayedProductImpactData)
  const isSupplierConnect = useSelector(selectIsSupplierConnect)
  const sendMessageEnabled = useSelector(selectSendMessageSplit)
  const view = useSelector(selectPageView)
  const isThirdPartyProduct = useSelector(selectIsThirdParty)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const dispatch = useDispatch()

  const [exportScorecardOpen, setExportScorecardOpen] = useState(false)
  const [sendMessageOpen, setSendMessageOpen] = useState(false)

  const handleShareClick = (e: MouseEvent<HTMLElement>) => {
    if (isSupplierConnect) {
      dispatch(
        setLiteUserPaywallDialogState({
          open: true,
        })
      )
      return
    }
    if (sendMessageEnabled) {
      setAnchorEl(e.currentTarget)
    } else {
      setExportScorecardOpen(true)
    }
  }

  const handleMenuClose = () => setAnchorEl(null)

  const handleExportScorecard = () => {
    setExportScorecardOpen(true)
    handleMenuClose()
  }

  const handleSendMessage = () => {
    setSendMessageOpen(true)
    handleMenuClose()
  }

  return (
    <>
      <Button id="share-button" variant="text" size="small" onClick={handleShareClick} startIcon={faArrowUpFromBracket}>
        {sendMessageEnabled ? 'Share' : 'Export scorecard'}
      </Button>
      <Menu
        id="share-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{
          '& .MuiPaper-root': {
            overflow: 'unset',
          },
        }}
        MenuListProps={{ 'aria-labelledby': 'share-menu-button' }}
      >
        <MenuItem id="export-scorecard-option" data-testid="export-scorecard-option" onClick={handleExportScorecard}>
          <ListItemIcon icon={faFileContract} />
          <ListItemText>Export scorecard</ListItemText>
        </MenuItem>
        {!isThirdPartyProduct && (
          <Tooltip title={view === 'Draft' ? 'Exit Draft mode to use this feature' : ''}>
            <Box>
              <MenuItem
                id="send-message-option"
                data-testid="send-message-option"
                // Disable the Send Message option in Draft view since the "Share" option involves locking the product
                disabled={view === 'Draft'}
                onClick={handleSendMessage}
              >
                <ListItemIcon icon={faPaperPlane} />
                <ListItemText>Send message</ListItemText>
              </MenuItem>
            </Box>
          </Tooltip>
        )}
      </Menu>
      {exportScorecardOpen && (
        <SustainabilityScorecardDialog
          onClose={() => setExportScorecardOpen(false)}
          product={{
            ...productData,
            id: productData.id,
            pk: productData.pk, // IProduct has pk as string
            standards: [],
            name: productData.name || 'unsaved new product',
            requires_cold_storage: productData.requires_cold_storage || null, // IProduct doesn't support ''
          }}
          impactData={impactData}
        />
      )}
      {sendMessageOpen && <SendMessageDialog onClose={() => setSendMessageOpen(false)} />}
    </>
  )
}
