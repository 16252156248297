import React, { ErrorInfo, Component } from 'react'
import { connect } from 'react-redux'
import DocumentTitle from 'react-document-title'
import * as Sentry from '@sentry/browser'
import { LoadingOverlay } from '@howgood/design'
import { logout, selectUserAuthenticated } from '@/state/user'
import { getUser } from '@/state/user'
import { selectTitle } from '@/state/router'
import { AppMessages, Footer } from '@/components'
import { selectLoadingOverlay } from '@/state/pageSettings'
import { AppState } from '@/store'
import { Intercom } from './Intercom'

import './App.scss'

interface StateProps {
  isAuthenticated: boolean
  title: string
  isLoadingOverlay: string
}

interface DispatchProps {
  logout: typeof logout
  getUser: typeof getUser
}

interface OwnProps {
  children?: React.ReactNode
}

type Props = StateProps & DispatchProps & OwnProps

interface State {
  error: Error | null
  eventId: any
}

const LAST_ACTIVE_TIME = 'last-active-time'

class App extends Component<Props, State> {
  state: State = { error: null as any, eventId: null }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ error })
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo as any)
      const eventId = Sentry.captureException(error)
      this.setState({ eventId })
    })
  }

  clickHandler = () => {
    const lastActiveTimeStr = window.localStorage.getItem(LAST_ACTIVE_TIME)
    const lastActiveTime = parseInt(lastActiveTimeStr, 10)
    const currentTime = Date.now()
    const difference = currentTime - lastActiveTime
    if (!lastActiveTimeStr || difference < 60000 * 30) {
      window.localStorage.setItem(LAST_ACTIVE_TIME, currentTime.toString())
    } else {
      this.props.logout()
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.isAuthenticated && !this.props.isAuthenticated) {
      window.removeEventListener('click', this.clickHandler)
      window.localStorage.removeItem(LAST_ACTIVE_TIME)
    }
    if (!prevProps.isAuthenticated && this.props.isAuthenticated) {
      this.props.getUser()
    }
  }

  componentDidMount() {
    if (this.props.isAuthenticated) {
      const currentTime = Date.now()
      window.localStorage.setItem(LAST_ACTIVE_TIME, currentTime.toString())
      window.addEventListener('click', this.clickHandler)
      this.props.getUser()
    }
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.clickHandler)
    window.localStorage.removeItem(LAST_ACTIVE_TIME)
  }

  render() {
    if (this.state.error) {
      // render fallback UI
      return (
        <div className="snap" onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId })}>
          <p>We're sorry — something's gone wrong.</p>
          <p>Our team has been notified, but click here fill out a report.</p>
        </div>
      )
    }

    let title = 'HowGood'
    if (this.props.title) {
      title = `${title} | ${this.props.title}`
    }

    return (
      <DocumentTitle title={title}>
        <>
          <Intercom />
          <AppMessages />
          <div className="App__content">
            <LoadingOverlay open={!!this.props.isLoadingOverlay} loadText={this.props.isLoadingOverlay} />
            {this.props.children}
            {this.props.isAuthenticated && <Footer />}
          </div>
        </>
      </DocumentTitle>
    )
  }
}

export default connect(
  (state: AppState) => ({
    isAuthenticated: selectUserAuthenticated(state),
    title: selectTitle(state),
    isLoadingOverlay: selectLoadingOverlay(state),
  }),
  { logout, getUser },
  null,
  { pure: false }
  // @ts-ignore TODO: refactor to functional component
)(App)
