import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TextField } from '@howgood/design'

import { selectDisplayedSalesAndDistribution, updateRecipeAndScores } from '@/state/recipe'
import { DRAFT, selectPageView } from '@/state/pageSettings'
import { panelStyles } from '../panelStyles'
import { GridItemTitle } from '../GridItemTitle'

export const UnitsSold: FC = () => {
  const dispatch = useDispatch()
  const salesAndDistribution = useSelector(selectDisplayedSalesAndDistribution)
  const view = useSelector(selectPageView)
  const [unitsSold, setUnitsSold] = useState(salesAndDistribution.annual_sales_volume?.toString())

  // If user restores a previous snapshot, update the local value
  useEffect(() => {
    setUnitsSold(salesAndDistribution.annual_sales_volume?.toString())
  }, [salesAndDistribution.annual_sales_volume, salesAndDistribution.weight_kg])

  const handleUnitsSoldChange = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const stringValue = e.currentTarget.value
    const numericValue = +e.currentTarget.value
    if (!Number.isNaN(numericValue) && stringValue.length <= 16) {
      setUnitsSold(stringValue)
    }
  }

  const handleLeaveUnitsSold = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = +e.currentTarget.value
    dispatch(
      updateRecipeAndScores({
        recipeUpdates: {
          sales_distribution: {
            ...salesAndDistribution,
            annual_sales_volume: value,
          },
        },
        change: `Changed annual_sales_volume to ${value}`,
      })
    )
  }

  return (
    <>
      <GridItemTitle title="Units Sold (annual)" tooltipText="Required for annual carbon metrics" />
      <TextField
        id="units-sold-field"
        disabled={view !== DRAFT}
        value={unitsSold}
        onChange={handleUnitsSoldChange}
        onBlur={handleLeaveUnitsSold}
        sx={panelStyles.textField}
      />
    </>
  )
}
