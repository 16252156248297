import React, { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { faStamp } from '@fortawesome/pro-regular-svg-icons'
import { faPaperPlane } from '@fortawesome/pro-solid-svg-icons'
import {
  theme,
  Text,
  Stack,
  TextField,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Tooltip,
  Box,
  Alert,
  Icon,
} from '@howgood/design'
import {
  requestValidation,
  selectIsValidationLoading,
  selectProductIsLocked,
  selectValidationRequests,
} from '@/state/productOverview'
import { selectUserHasAddValidationAccess } from '@/state/user'
import { validationPaywallMessage, validationSummary } from './validationCopy'
import { ValidationRequestHistory } from './ValidationRequestHistory'
import { RequestCountRow } from './RequestCountRow'
import { usePendoTrack } from '@/hooks'
import { setLiteUserPaywallDialogState } from '@/state/liteUserPaywallDialog'
import { selectIsSupplierConnect } from '@/state/organization'

export const RequestValidationPanel: FC = () => {
  const dispatch = useDispatch()
  const pendoTrack = usePendoTrack()

  const validationRequests = useSelector(selectValidationRequests)
  const isLocked = useSelector(selectProductIsLocked)
  const isValidationLoading = useSelector(selectIsValidationLoading)
  const hasValidationAccess = useSelector(selectUserHasAddValidationAccess)
  const isSupplierConnect = useSelector(selectIsSupplierConnect)

  const [requestText, setRequestText] = useState('')

  const handleValidationClicked = () => {
    if (isSupplierConnect) {
      dispatch(
        setLiteUserPaywallDialogState({
          open: true,
        })
      )
      return
    }
    dispatch(requestValidation(requestText))
    pendoTrack('Formula: Validation Requested', { requestText })
    setRequestText('')
  }

  const handleContactSalesClicked = () => {
    window.open('https://howgood.com/contact-latis?utm_source=customer&utm_medium=latis&utm_campaign=upgrade')
  }

  let buttonDisabled = false
  let tooltip = ''
  if (hasValidationAccess && !requestText.trim()) {
    buttonDisabled = true
    tooltip = 'Enter your claim request information.'
  }

  return (
    <Accordion
      disabled={!isLocked || isSupplierConnect}
      defaultExpanded={isLocked && !isSupplierConnect}
      data-testid="validation-panel-accordion"
    >
      <AccordionSummary>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Icon color={theme.palette.text.primary} size="lg" icon={faStamp} />
          <Text variant="h3">Validate for public claims</Text>
          {hasValidationAccess && <RequestCountRow requests={validationRequests} />}
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        {hasValidationAccess && (
          <Stack spacing={1}>
            <Text>{validationSummary[0]}</Text>
            <ValidationRequestHistory />
            <Text variant="h4">Make a request</Text>
            <TextField
              label="What public claims would you like to make?"
              id="claim-textfield"
              data-testid="claim-textfield"
              multiline
              rows={4}
              value={requestText}
              onChange={(e) => setRequestText(e.target.value)}
              placeholder="Describe desired claims for this product"
              helperText="Include any helpful context or details"
              fullWidth
              size="medium"
            />
          </Stack>
        )}
        {!hasValidationAccess && <Alert severity="info">{validationPaywallMessage}</Alert>}
        <Stack direction="row" mt={2}>
          <Box flex={1} />
          <Tooltip title={tooltip}>
            <Box
              sx={{
                opacity: buttonDisabled ? 0.5 : 1,
                '& .MuiButton-root': { pointerEvents: buttonDisabled ? 'none' : 'auto' },
              }}
            >
              {hasValidationAccess && (
                <Button
                  color="primary"
                  id="request-validation-button"
                  data-testid={`request-validation-button-${buttonDisabled ? 'disabled' : 'enabled'}`}
                  onClick={buttonDisabled ? null : handleValidationClicked}
                  loading={isValidationLoading}
                >
                  Request validation
                </Button>
              )}
              {!hasValidationAccess && (
                <Button
                  color="secondary"
                  id="contact-button"
                  data-testid="contact-sales-button"
                  startIcon={hasValidationAccess ? null : faPaperPlane}
                  onClick={handleContactSalesClicked}
                >
                  Contact sales
                </Button>
              )}
            </Box>
          </Tooltip>
        </Stack>
      </AccordionDetails>
    </Accordion>
  )
}
