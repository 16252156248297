import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Tabs, Tab, Text, Stack } from '@howgood/design'
import { PUBLISHED, selectPageView } from '@/state/pageSettings'
import { selectDebugToolsSplit } from '@/state/splitio'
import { TabPanel } from '../../features/ProductPage/panels/TabPanel'
import { CarbonPanel } from './CarbonPanel/CarbonPanel'
import { OverviewPanel } from './OverviewPanel/OverviewPanel'
import { FavoritesPanel } from './FavoritesPanel'
import { NaturePanel } from './NaturePanel'
import { PeoplePanel } from './PeoplePanel'
import { OtherPanel } from './OtherPanel'
import { ExportScorecard } from './ExportScorecard'
import { MenuButton } from './MenuButton/MenuButton'
import { useScoreCardData } from './ScoreCards'
import { selectIsRollup } from '@/state/router'
import { ScoreTypeSelector } from './ScoreTypeSelector'
import { selectWarningText } from '@/state/recipe'
import { setLiteUserPaywallDialogState } from '@/state/liteUserPaywallDialog'
import { CarbonExtrasToggle } from './CarbonExtrasToggle'
import { selectIsSupplierConnect } from '@/state/organization'

const panels = {
  favorites: FavoritesPanel,
  overview: OverviewPanel,
  carbon: CarbonPanel,
  nature: NaturePanel,
  people: PeoplePanel,
  other: OtherPanel,
}

interface ScoresProps {
  hideMetrics?: boolean
  showMenu?: boolean
  showExportScorecard?: boolean
}

export const Scores: FC<ScoresProps> = ({ hideMetrics = false, showMenu = false, showExportScorecard = false }) => {
  const dispatch = useDispatch()
  const view = useSelector(selectPageView)
  const isSupplierConnect = useSelector(selectIsSupplierConnect)
  const debugEnabled = useSelector(selectDebugToolsSplit)
  const isRollup = useSelector(selectIsRollup)
  const formulaWarningText = useSelector(selectWarningText)
  const scoreCardData = useScoreCardData()
  const [tabValue, setTabValue] = useState(0)
  const [isCarbonTab, setIsCarbonTab] = useState(false)

  // If switching to published view, it's possible the currently selected tab isn't available, so switch to Impact tab
  useEffect(() => {
    if (view === PUBLISHED) {
      setTabValue(0)
    }
  }, [view])

  const shouldHideTab = (dataLength: number, category: string) => {
    if (category === 'favorites') {
      return false
    }
    const hide = (dataLength === 0 && category !== 'impact') || (category === 'metrics' && hideMetrics)
    return category === 'other' ? hide || !debugEnabled : hide
  }

  const panelKeys = Object.keys(panels).filter((key) => !shouldHideTab(scoreCardData[key].data.length, key))

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    const tabId = event.currentTarget.id
    setIsCarbonTab(tabId === 'carbon-tab')
    if (isSupplierConnect && ['overview-tab', 'carbon-tab', 'nature-tab', 'people-tab', 'other-tab'].includes(tabId)) {
      dispatch(
        setLiteUserPaywallDialogState({
          open: true,
        })
      )
      event.stopPropagation()
      return
    }
    setTabValue(newValue)
  }

  return (
    <>
      <Stack direction="row" ml={-2} display="flex" alignItems="center" gap={1}>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="score tabs"
          variant="scrollable"
          scrollButtons="auto"
        >
          {panelKeys.map((key) => (
            <Tab
              key={key}
              data-testid={`${key}-tab`}
              id={`${key}-tab`}
              label={
                <Text color="primary" textTransform="capitalize">
                  {key}
                </Text>
              }
            />
          ))}
        </Tabs>
        <Box flex={1} />
        {showExportScorecard && <ExportScorecard />}
        {showMenu && <MenuButton />}
      </Stack>
      <Stack
        // On the Formula Profile page, components are within a stack with gap={2}
        // On rollup pages, there is no gap between container components, so need to make vertical adjustments
        bgcolor="primary.paperMain"
        mx="-50px" // Background bleeds beyond the container
        px={6}
        pt={isRollup ? 2 : 5}
        pb={6}
        mt={isRollup ? 0 : -3}
      >
        <Stack flexDirection="row" justifyContent="flex-end" gap={1}>
          {isRollup && <ScoreTypeSelector />}
          {isCarbonTab && <CarbonExtrasToggle />}
        </Stack>
        {panelKeys.map((key, i) => {
          const Panel = panels[key]
          return (
            <TabPanel
              key={key}
              data-testid={`${key}-panel`}
              value={tabValue}
              index={i}
              warningText={formulaWarningText}
            >
              <Panel />
            </TabPanel>
          )
        })}
      </Stack>
    </>
  )
}
