import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { IImpactMetricOption, Workspace } from '@/records'
import { updateWorkspace } from '@/state/workspaces/workspaces.thunks'
import { GoalCell } from '@/components/GridWithMetrics/GoalCell'

interface CellParams extends GridRenderCellParams {
  metric: IImpactMetricOption
  value: { workspace: Workspace; goal: number }
}

export const WorkspaceGoalCell: FC<CellParams> = ({ metric, value }) => {
  const dispatch = useDispatch()
  const { workspace, goal } = value

  if (!workspace.edit) {
    return null
  }

  const onUpdateGoal = (updatedGoal: number) => {
    if (goal !== updatedGoal) {
      const updatedGoals = {
        ...workspace.goals,
        [metric.value]: updatedGoal === null ? undefined : updatedGoal,
      }
      dispatch(
        updateWorkspace({
          id: workspace.id,
          goals: updatedGoals,
        })
      )
    }
  }

  return (
    <GoalCell
      metric={metric}
      id={workspace.id}
      name={workspace.name}
      metricGoal={goal}
      onUpdateGoal={onUpdateGoal}
      editable={workspace.edit}
    />
  )
}
