import { createSelector } from 'reselect'
import { selectContentfulImpactDetailData } from '@/state/contentfulData'
import { selectCompareTo } from '@/state/pageSettings'
import { selectDisplayedImpactData, selectImpactDataList, selectSavedImpactData } from '@/state/recipe'
import { selectIsRollup } from '@/state/router'
import { ImpactBarProps } from '@/components/Scores/OverviewPanel/OverviewBars/ImpactBar'
import { getKeyWithoutAggregateOption, MetricEntry } from '@/contentfully'
import { selectProductMetrics } from '@/state/productMetrics/productMetrics.selectors'
import { selectAllFoodBenchmarkScores } from '@/state/salesCategories'

const selectScoresImpactScore = createSelector(
  selectIsRollup,
  selectDisplayedImpactData,
  selectProductMetrics,
  (isRollup, productDetailImpactData, rollupImpactScore) =>
    isRollup ? rollupImpactScore : productDetailImpactData.product
)

export const selectScoresImpactDataForOverview = createSelector(
  selectIsRollup,
  selectScoresImpactScore,
  selectImpactDataList,
  selectCompareTo,
  selectContentfulImpactDetailData,
  (isRollup, impactScore, impactDataList, compareTo, contentfulImpactData) => {
    return contentfulImpactData.reduce((impactList: ImpactBarProps[], impact: MetricEntry) => {
      const metricScore = impactScore?.[impact.key]
      if (!metricScore) {
        return impactList
      }
      const data = {
        content: impact,
        value: metricScore,
        previous: isRollup
          ? null
          : compareTo === 'saved'
          ? impactDataList[0]?.product?.[impact.key]
          : impactDataList.slice(-2)[0]?.product?.[impact.key],
      }
      return [...impactList, data]
    }, [] as ImpactBarProps[])
  }
)

export const selectLifecycleBenchmarkScores = createSelector(
  selectIsRollup,
  selectCompareTo,
  selectSavedImpactData,
  selectImpactDataList,
  selectAllFoodBenchmarkScores,
  (isRollup, compareTo, savedImpactData, impactDataList, rollupBenchmark) => {
    if (isRollup) {
      return rollupBenchmark
    }
    return compareTo === 'saved'
      ? savedImpactData.product
      : impactDataList.slice(-2)[0]?.product || savedImpactData.product
  }
)

export const selectCarbonLifecycleImpactScore = createSelector(
  selectIsRollup,
  selectDisplayedImpactData,
  selectProductMetrics,
  (isRollup, productDetailImpactData, rollupImpactScore) => {
    if (isRollup) {
      return Object.entries(rollupImpactScore).reduce((acc, [field, value]) => {
        if (field.includes('avg')) {
          acc[getKeyWithoutAggregateOption(field)] = value
        }
        return acc
      }, {})
    }
    return productDetailImpactData.product
  }
)
