import React, { FC } from 'react'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { getScenarioYear } from '@/state/initiatives/initiatives.utils'
import { GridScenario } from '@/records/Initiatives'
import { Text } from '@howgood/design'

export interface DateCellParams extends GridRenderCellParams {
  value: GridScenario
}

export const DateCell: FC<DateCellParams> = ({ value: scenario }) => {
  if (!scenario?.target_date) {
    return null
  }
  return (
    <Text data-testid={`scenario-date-cell-${scenario.id}`} variant="body2">
      {getScenarioYear(scenario.target_date)}
    </Text>
  )
}
