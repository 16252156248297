import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { faCube, faCubes, faFlagSwallowtail } from '@fortawesome/pro-solid-svg-icons'
import { Stack } from '@howgood/design'

import { PRODUCTS } from '@/records'
import { selectIsSupplierConnect } from '@/state/organization'
import { selectIsProcurement, selectPageType, selectIsProducts } from '@/state/router'
import { selectHasWorkspaceOfType } from '@/state/workspaces'
import { Heading } from '../Layout/Heading'
import { RequestVendorData } from '../Procurement/RequestVendorData'
import { ExportSustainabilityScoreboardButton } from '../ProductImpact/ExportProductOverview/ExportSustainabilityScorecardButton'
import { Scope3ExportButton } from '../Products/MUIExport/Scope3ExportButton'
import { OtherReportsButton } from '../Procurement/OtherReportsButton'
import { ReportsButton } from '../Reports/ReportsButton'

export const ProductListPageHeader: FC = () => {
  const isSupplierConnect = useSelector(selectIsSupplierConnect)
  const hasFormulationWorkspaces = useSelector(selectHasWorkspaceOfType(PRODUCTS))
  const isProducts = useSelector(selectIsProducts)
  const isProcurement = useSelector(selectIsProcurement)
  const pageType = useSelector(selectPageType)

  const showScorecardButton = isProducts && hasFormulationWorkspaces
  const showReportButton = !isSupplierConnect && !isProcurement

  const iconMap = {
    formulation: {
      icon: faCube,
      title: 'Products',
      breadCrumbs: [],
    },
    initiative: {
      icon: faFlagSwallowtail,
      title: 'Initiative',
      breadCrumbs: [],
    },
    procurement: {
      icon: faCubes,
      title: 'Materials',
      breadCrumbs: [],
    },
  }

  return (
    <Heading
      title={iconMap[pageType].title}
      icon={iconMap[pageType].icon}
      aria-label={iconMap[pageType].title}
      breadCrumbs={iconMap[pageType].breadCrumbs}
      actions={
        <Stack direction="row" alignItems="center" gap={1}>
          {showScorecardButton && <ExportSustainabilityScoreboardButton />}
          {isProcurement && <Scope3ExportButton />}
          {isProcurement && <RequestVendorData />}
          {isProcurement && <OtherReportsButton />}
          {showReportButton && <ReportsButton />}
        </Stack>
      }
    />
  )
}
