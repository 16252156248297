import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { FormControl, InputLabel, MenuItem, Select, Text } from '@howgood/design'
import { TransportationMode } from '@/records'
import { selectTransportationModes } from '@/state/transportation'
import { DRAFT, selectPageView } from '@/state/pageSettings'

interface Props {
  mode?: TransportationMode
  label?: string
  onChange: (mode: TransportationMode) => void
}

export const ModeSelect: FC<Props> = ({ mode, label = 'Mode', onChange }) => {
  const [value, setValue] = useState(mode)
  const modes = useSelector(selectTransportationModes)
  const view = useSelector(selectPageView)

  if (view !== DRAFT) {
    return (
      <Text data-testid="mode-text">
        Mode: <b>{mode?.name}</b>
      </Text>
    )
  }

  return (
    <FormControl fullWidth>
      <InputLabel id="mode-label" size="small" required>
        {label}
      </InputLabel>
      <Select
        data-testid="mode-select"
        labelId="mode-label"
        label={label}
        value={value?.id?.toString() || ''}
        renderValue={() => value.name}
        disabled={modes.length === 0}
        onChange={(event) => {
          const modeId = event.target.value
          const selectedMode = modes.find((m) => m.id.toString() === modeId.toString())
          setValue(selectedMode)
          onChange(selectedMode)
        }}
        fullWidth
        required
      >
        {modes.map((m) => (
          <MenuItem data-testid="mode-select-option" key={m.id} value={m.id.toString()}>
            {m.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
