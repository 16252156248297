import React, { forwardRef } from 'react'
import MuiDrawer, { DrawerProps as MuiDrawerProps } from '@mui/material/Drawer'
import { faXmark } from '@fortawesome/pro-regular-svg-icons'
import { Box } from './Box'
import { IconButton } from './IconButton'
import { Stack } from './Stack'

interface Props extends Pick<MuiDrawerProps, 'anchor' | 'open' | 'onClose' | 'PaperProps' | 'children'> {
  ['aria-label']?: string
  ['data-pendo-id']?: string
  ['data-testid']?: string
}

export const Drawer = forwardRef<HTMLDivElement, Props>(
  (
    {
      ['aria-label']: ariaLabel,
      ['data-pendo-id']: dataPendoId,
      ['data-testid']: dataTestId,
      anchor = 'right',
      open,
      onClose,
      children,
      ...rest
    },
    ref
  ) => {
    return (
      <MuiDrawer
        ref={ref}
        aria-label={ariaLabel}
        data-pendo-id={dataPendoId}
        data-testid={dataTestId}
        anchor={anchor}
        open={open}
        onClose={onClose}
        {...rest}
      >
        <Stack p={5} height="100%">
          {onClose && (
            <Box
              data-testid="close-drawer-button-container"
              position="absolute"
              top={0}
              left={anchor === 'left' ? null : 0}
              right={anchor === 'left' ? 0 : null}
              m={0.5}
            >
              <IconButton
                data-testid="close-drawer-button"
                icon={faXmark}
                size="small"
                onClick={onClose as () => void}
              />
            </Box>
          )}
          {children}
        </Stack>
      </MuiDrawer>
    )
  }
)

Drawer.displayName = 'Drawer'
