import { FetchV2PaginatedResponse, fetchAllV2, fetchHelperV2 } from '@/api'
import { CarbonAccountingReport, CarbonAccountingReportActivity, EmissionsActivity } from './carbonAccounting.types'

export async function fetchEmissionsActivities(reportYear?: number) {
  const url = reportYear ? `emissions-activities/?report_year=${reportYear}` : 'emissions-activities/'
  return await fetchAllV2<EmissionsActivity>({ url })
}

export async function fetchCarbonAccountingReports() {
  return await fetchAllV2<CarbonAccountingReport>({ url: 'accounts/carbon-accounting/' })
}

export async function fetchCarbonAccountingReport(reportId: number) {
  return await fetchHelperV2<CarbonAccountingReport>({ url: `accounts/carbon-accounting/${reportId}/` })
}

export async function fetchCarbonAccountingReportActivities(reportId: number) {
  return await fetchAllV2<CarbonAccountingReportActivity>({
    url: `accounts/carbon-accounting/${reportId}/activities/`,
  })
}

export async function fetchCarbonAccountingAvailableYears() {
  return await fetchAllV2<number>({
    url: 'accounts/carbon-accounting/available-years/',
  })
}

interface PostCarbonAccountingReportActivities {
  reportId: number
  body: CarbonAccountingReportActivity
}
export async function postCarbonAccountingReportActivities({ reportId, body }: PostCarbonAccountingReportActivities) {
  await fetchHelperV2<FetchV2PaginatedResponse<CarbonAccountingReportActivity>>({
    url: `accounts/carbon-accounting/${reportId}/activities/`,
    method: 'POST',
    body: JSON.stringify(body),
  })
}

interface PutCarbonAccountingReportActivities extends PostCarbonAccountingReportActivities {
  reportActivityId: number // unique number that binds activity to a specific report
}
export async function putCarbonAccountingReportActivities({
  reportId,
  reportActivityId,
  body,
}: PutCarbonAccountingReportActivities) {
  await fetchHelperV2<FetchV2PaginatedResponse<CarbonAccountingReportActivity>>({
    url: `accounts/carbon-accounting/${reportId}/activities/${reportActivityId}/`,
    method: 'PUT',
    body: JSON.stringify(body),
  })
}

export async function postCarbonAccountingReport(
  body: Partial<CarbonAccountingReport>
): Promise<CarbonAccountingReport> {
  return await fetchHelperV2<CarbonAccountingReport>({
    url: 'accounts/carbon-accounting/',
    method: 'POST',
    body: JSON.stringify(body),
  })
}

interface UpdateCarbonAccountingReport {
  reportId: number
  body: Partial<CarbonAccountingReport>
}

export async function putCarbonAccountingReport({
  reportId,
  body,
}: UpdateCarbonAccountingReport): Promise<CarbonAccountingReport> {
  return await fetchHelperV2<CarbonAccountingReport>({
    url: `accounts/carbon-accounting/${reportId}/`,
    method: 'PUT',
    body: JSON.stringify(body),
  })
}

export async function patchCarbonAccountingReport({
  reportId,
  body,
}: UpdateCarbonAccountingReport): Promise<CarbonAccountingReport> {
  const response = await fetchHelperV2<CarbonAccountingReport>({
    url: `accounts/carbon-accounting/${reportId}/`,
    method: 'PATCH',
    body: JSON.stringify(body),
  })
  return response
}

export async function deleteCarbonAccountingReport(reportId: number) {
  await fetchHelperV2<CarbonAccountingReport>({
    url: `accounts/carbon-accounting/${reportId}/`,
    method: 'DELETE',
    parse: false,
  })
}
