import useSWR from 'swr'
import { FetchV2PaginatedResponse, fetchAllV2, fetchHelperV2 } from '@/api'
import { IRegionOption } from '@/records'

export interface Region {
  id: number
  name: string
  iso3_country_code?: string
  blue_water_scarcity?: number
  category?: string
}

export const searchRegions = async (term: string): Promise<Region[]> => {
  const search = encodeURIComponent(term)
  const regions = await fetchHelperV2<{ items: Region[] }>({ url: `regions/?name=${search}` })

  return regions.items
}

const fetchRegionById = async (id: number): Promise<Region> => {
  return await fetchHelperV2<Region>({ url: `regions/${id}` })
}
export const fetchRegionsByIds = async (ids: number[]): Promise<IRegionOption[]> => {
  const regions = await Promise.all(ids.map(fetchRegionById))
  return regions.map((r) => ({ id: r.id, value: r.id, label: r.name }))
}

interface GetRegionsQueryString {
  workspaceIds?: number[]
  originIds?: number[]
  search?: string
}
export function getRegionsQueryString({ workspaceIds = [], originIds = [], search }: GetRegionsQueryString): string {
  let queryString = '?size=100'
  if (search) {
    queryString += `&name=${encodeURIComponent(search)}`
  }
  if (originIds.length > 0) {
    queryString += `&origin_ids=${originIds.join(',')}`
  }
  if (workspaceIds.length > 0) {
    queryString += `&workspace_ids=${workspaceIds.join(',')}`
  }
  return queryString
}

export async function fetchRegions(url: string): Promise<Region[]> {
  const result = await fetchHelperV2<FetchV2PaginatedResponse<Region>>({ url })
  return result.items
}

interface UseRegions {
  data: Region[]
  isError: boolean
  isLoading: boolean
}
export function useRegions({ workspaceIds, originIds, search }: GetRegionsQueryString): UseRegions {
  const queryString = getRegionsQueryString({ originIds, workspaceIds, search })
  const url = workspaceIds || originIds || search ? `regions/${queryString}` : null
  const { data, error } = useSWR(url, fetchRegions, { fallbackData: [] })
  return {
    data,
    isError: Boolean(error),
    isLoading: !data && !error,
  }
}

export function useAllRegions(): UseRegions {
  const url = 'regions/'
  const { data, error } = useSWR(
    url,
    () => fetchAllV2<Region>({ url }),
    { fallbackData: [] }
  )
  return {
    data,
    isError: Boolean(error),
    isLoading: !data && !error,
  }
}
