import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { createUseStyles } from 'react-jss'
import { Tooltip } from '@howgood/design'
import { selectWarningText } from '@/state/recipe/recipe.selectors'

interface Props {
  title: string
  helpText: string
  color: string
  pctValue: number
  actValue: string
  size?: 'small' | 'default'
  units: string
}

const useStyles = createUseStyles<
  string,
  {
    color: string
    dataWarning: string
    actValue: string
    size?: 'small' | 'default'
  }
>({
  item: {
    opacity: ({ dataWarning }) => (dataWarning ? 0.5 : 1),
    width: '45%',
    maxWidth: 450,
    minHeight: 25,
    maxHeight: 45,
    display: 'flex',
    marginRight: '5%',
    marginBottom: ({ size }) => (size === 'small' ? 5 : 15),
    alignItems: 'center',
  },
  color: {
    width: ({ size }) => (size === 'small' ? 20 : 32),
    height: ({ size }) => (size === 'small' ? 12 : 20),
    marginRight: 10,
    backgroundColor: ({ color }) => color,
  },
  title: {
    flex: 1,
  },
  value: {
    textAlign: 'right',
    justifyContent: 'flex-end',
    cursor: ({ actValue }) => (actValue === null ? 'default' : undefined),
  },
})

export const LegendItem: FC<Props> = ({ title, helpText, color, pctValue, actValue, size = 'default', units }) => {
  const dataWarning = useSelector(selectWarningText)
  const classes = useStyles({ color, size, actValue, dataWarning })
  const value = actValue !== null ? `${actValue} ${units} (${pctValue}%)` : '- -'

  return (
    <div className={classes.item} data-testid="legend-item-container">
      <div className={classes.color} />
      <div data-testid="legend-item-title" className={classes.title}>
        {title}
      </div>
      <Tooltip title={actValue === null ? helpText : null}>
        <div data-testid="legend-item-value" className={classes.value}>
          {value}
        </div>
      </Tooltip>
    </div>
  )
}
