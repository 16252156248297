import React, { FC } from 'react'
import { StatusItem } from '@howgood/design'
import { ProductListRenderCellParams } from './ProductGridTypes'
import { generateValidationStatusOption } from '@/utils/ValidationStatusUtils'
import { ValidationRequest } from '@/api'

interface Props extends ProductListRenderCellParams {
  value?: ValidationRequest
}

export const ValidationStatusRenderer: FC<Props> = ({ id, row, value }) => {
  const statusItem = generateValidationStatusOption(value, !!row.locked_claims?.impact_score, `-${id}`)

  return (
    <StatusItem
      key={statusItem.id}
      id={statusItem.id}
      status={statusItem.status}
      tooltipText={statusItem.tooltipText}
      statusText={statusItem.statusText}
      icon={statusItem.icon}
      isFirst
      isLast
    />
  )
}
