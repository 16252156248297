import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  defaultFormulationStatusesForAggregatedVendors,
  FormulationStatusOption,
  VendorInfo,
  WorkspaceOption,
} from '@/records'
import {
  getVendorData,
  initializeVendorManagementFilters,
  initializeVendorManagementPage,
  updateVendorByBrand,
  UpdateVendorByBrandInfo,
} from './vendorManagement.thunk'
import { FormulationTagFilterOption } from '../products'
import { ManufacturingTypeOption } from '../manufacturingTypes'
import { estypes } from '@elastic/elasticsearch'
import { GridColumnVisibilityModel } from '@mui/x-data-grid-premium'
import { availableVendorMetrics } from './vendorManagement.metrics'

export interface VendorManagementFilters {
  vendorNameQueries: string[]
  formulationStatuses: FormulationStatusOption[]
  workspaces: WorkspaceOption[]
  formulationTags: FormulationTagFilterOption[]
  manufacturingTypes: ManufacturingTypeOption[]
  editingFilter: string
}

export interface VendorManagementSettings {
  selectedMetric: string
  columnVisibilityModel: GridColumnVisibilityModel
}

export interface BucketAggs {
  [key: string]: estypes.AggregationsStatsAggregate
}

export interface State {
  isLoading: boolean
  vendorData: VendorInfo[]
  bucketAggs: BucketAggs
  vendorBeingUpdated: number
  vendorJustUpdated: number
  filters: VendorManagementFilters
  settings: VendorManagementSettings
}

export const initialState: State = {
  isLoading: false,
  vendorData: [],
  bucketAggs: {},
  vendorBeingUpdated: null,
  vendorJustUpdated: null,
  filters: {
    vendorNameQueries: [],
    formulationStatuses: defaultFormulationStatusesForAggregatedVendors,
    workspaces: [],
    formulationTags: [],
    manufacturingTypes: [],
    editingFilter: 'workspace-search-for-aggregated-vendors',
  },
  settings: {
    selectedMetric: Object.keys(availableVendorMetrics)[0],
    columnVisibilityModel: {},
  },
}

const vendorManagementSlice = createSlice({
  name: 'vendorManagement',
  initialState,
  reducers: {
    resetVendorManagementList: (state) => {
      state.vendorData = initialState.vendorData
      state.bucketAggs = initialState.bucketAggs
    },
    updateVendorManagementFilters: (state, action: PayloadAction<Partial<VendorManagementFilters>>) => {
      state.filters = { ...state.filters, ...action.payload }
    },
    setEditingVendorManagementFilters: (state, action: PayloadAction<string>) => {
      state.filters.editingFilter = action.payload
    },
    // The vendor in the process of being updated so we can show a spinner when updating the priority
    setVendorBeingUpdated: (state, action: PayloadAction<number>) => {
      state.vendorBeingUpdated = action.payload
    },
    // The vendor that was last updated so we can show a success message
    setVendorJustUpdated: (state, action: PayloadAction<number>) => {
      state.vendorJustUpdated = action.payload
    },
    setSelectedMetric: (state, action: PayloadAction<string>) => {
      state.settings.selectedMetric = action.payload
    },
    setGridColumnVisibilityModel: (state, action: PayloadAction<GridColumnVisibilityModel>) => {
      state.settings.columnVisibilityModel = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getVendorData.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getVendorData.fulfilled, (state, action) => {
      const { vendorData, bucketAggs } = action.payload
      state.isLoading = false
      state.vendorData = vendorData
      state.bucketAggs = bucketAggs
    })
    builder.addCase(getVendorData.rejected, (state) => {
      state.isLoading = false
    })

    builder.addCase(initializeVendorManagementPage.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(initializeVendorManagementPage.fulfilled, (state, action) => {
      state.isLoading = false
      state.settings = action.payload
    })
    builder.addCase(initializeVendorManagementPage.rejected, (state) => {
      state.isLoading = false
    })

    builder.addCase(initializeVendorManagementFilters.fulfilled, (state, action) => {
      state.filters = { ...state.filters, ...action.payload }
    })

    builder.addCase(updateVendorByBrand.fulfilled, (state, action: PayloadAction<UpdateVendorByBrandInfo>) => {
      const { brandId, vendorInfo: updatedVendorInfo } = action.payload
      state.vendorData = state.vendorData.map((vendor) =>
        vendor.brandId === brandId ? { ...vendor, ...updatedVendorInfo } : vendor
      )
      state.vendorBeingUpdated = null
    })
    builder.addCase(updateVendorByBrand.rejected, (state) => {
      state.vendorBeingUpdated = null
    })
  },
})

export const {
  resetVendorManagementList,
  updateVendorManagementFilters,
  setEditingVendorManagementFilters,
  setVendorBeingUpdated,
  setVendorJustUpdated,
  setSelectedMetric,
  setGridColumnVisibilityModel,
} = vendorManagementSlice.actions

export const vendorManagementReducer = vendorManagementSlice.reducer
