import React, { FC, useRef, useState } from 'react'
import { IFormulationSearchOption, IProduct } from '@/records'
import { Button, LegacyDialog, Select, MenuItem, Tabs, Tab, Stack } from '@howgood/design'
import { createUseStyles } from 'react-jss'
import useSWR from 'swr'
import { usePendoTrack } from '@/hooks'
import {
  ProductOverviewExportContainer,
  ProductOverviewExportContainerApi,
} from '@/components/ProductImpact/ExportProductOverview/ProductOverviewExportContainer'
import { ImpactScore, fetchProductImpactScore } from '@/api'
import { useScorecardMetricOptions } from '@/components/SustainabilityScorecard/useScorecardMetricOptions'
import { CategoryType } from '@/components/ProductImpact/ExportProductOverview/CategoryType'
import { PortfolioSelect, PortfolioType } from './PortfolioSelect'
import {
  FormulationTagOption,
  allPortfolio,
  PortfolioOption,
} from '@/components/SustainabilityScorecard/useScorecardPortfolioOptions'
import { ProductSelect } from './ProductSelect'
import { useSelector } from 'react-redux'
import { selectOrganization } from '@/state/organization'
import { initialImpactData } from '@/state/recipe/recipe.state'
import {
  ALL_FOOD_INDUSTRY_OPTION,
  ALL_FUNCTIONAL_CATEGORY_OPTION,
  FunctionalCategoryOption,
  NO_CATEGORY_OPTION,
  SalesCategoryOption,
} from '@/constants/industryCategories'
import { useFunctionalCategoryOptions, useSalesCategoryOptions } from './IndustryCategories.hooks'

interface DialogProps {
  product?: IProduct
  onClose: () => void
  enableClaims?: boolean
  impactData: ImpactScore
}

const useStyles = createUseStyles<string, { isProductComparisonScorecard: boolean }>({
  selectWrapper: {},
  header: {
    fontSize: '22px',
  },
  subHeader: {
    fontSize: '16px',
    lineHeight: '22px',
  },
  body: {
    width: '1150px',
    height: ({ isProductComparisonScorecard }) => (isProductComparisonScorecard ? '595px' : '570px'),
    padding: '24px 0',
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',
    overflow: 'visible',
  },
  controls: {
    width: '320px',
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
  },
  preview: {
    width: '830px',
    overflow: 'hidden',
    border: '1px solid #BFBFBF',
    borderRadius: '4px',
    '& > div': {
      transform: 'scale(58.7%) translate(-35.3%, -35.3%)',
    },
  },
  label: {
    color: '#707070',
    fontSize: '16px',
    lineHeight: '22px',
    marginBottom: '5px',
  },
  buttons: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-end',
    gap: '10px',
  },
  comparisonDescription: {
    fontSize: '14px',
    marginTop: '10px',
    wordWrap: 'normal',
  },
})

export const SustainabilityScorecardDialog: FC<DialogProps> = ({
  product,
  onClose,
  enableClaims = true,
  impactData,
}) => {
  const pendoTrack = usePendoTrack()
  const isProductComparisonScorecard = !!product
  const classes = useStyles({ isProductComparisonScorecard })
  const salesCategories = useSalesCategoryOptions()
  const functionalCategories = useFunctionalCategoryOptions()

  const [categoryType, setCategoryType] = useState<CategoryType>('sales')
  const [portfolioType, setPortfolioType] = useState<PortfolioType>('workspace')
  const [salesCategory, setSalesCategory] = useState<SalesCategoryOption>(ALL_FOOD_INDUSTRY_OPTION)
  const [functionalCategory, setFunctionalCategory] = useState<FunctionalCategoryOption>(ALL_FUNCTIONAL_CATEGORY_OPTION)

  const [portfolioWorkspace, setPortfolioWorkspace] = useState<PortfolioOption>(allPortfolio)
  const [portfolioFormulationTag, setPortfolioFormulationTag] = useState<PortfolioOption>(
    allPortfolio as FormulationTagOption
  )

  const [comparisonProduct, setComparisonProduct] = useState<IFormulationSearchOption>(null)

  const { data: comparisonProductImpact, error } = useSWR(comparisonProduct?.value?.toString(), () =>
    fetchProductImpactScore(comparisonProduct?.value)
  )
  const comparisonProductImpactData =
    !error && comparisonProductImpact?.product ? comparisonProductImpact.product : initialImpactData.product

  const organization = useSelector(selectOrganization)

  const { metric, setMetric, availableAnalysisAndProductMetricOptions } = useScorecardMetricOptions()

  const scorecardRef = useRef<ProductOverviewExportContainerApi>()

  const handleClose = () => {
    setSalesCategory(ALL_FOOD_INDUSTRY_OPTION)
    onClose()
  }
  const isSalesCategory = categoryType === 'sales'
  const isWorkspacePortfolio = portfolioType === 'workspace'

  const handleSelectCategory = (value: SalesCategoryOption | FunctionalCategoryOption) => {
    if (value === NO_CATEGORY_OPTION) {
      setSalesCategory(value as SalesCategoryOption)
      setFunctionalCategory(value as FunctionalCategoryOption)
    } else if (isSalesCategory) {
      setSalesCategory(value as SalesCategoryOption)
      setFunctionalCategory(ALL_FUNCTIONAL_CATEGORY_OPTION)
    } else {
      setSalesCategory(ALL_FOOD_INDUSTRY_OPTION)
      setFunctionalCategory(value as FunctionalCategoryOption)
    }
  }

  const handleSelectPortfolio = (value: PortfolioOption) => {
    if (isWorkspacePortfolio) {
      setPortfolioWorkspace(value)
      setPortfolioFormulationTag(allPortfolio)
    } else {
      setPortfolioWorkspace(allPortfolio)
      setPortfolioFormulationTag(value)
    }
  }

  const handleComparisonProductChange = (option: IFormulationSearchOption) => {
    setComparisonProduct(option)
  }

  const renderPreview = Boolean(metric?.value)

  const subtitle = isProductComparisonScorecard
    ? 'Compare this product`s metrics against industry averages, portfolios of your portfolio, and other products'
    : 'Compare your product`s metrics against the following'

  return (
    <LegacyDialog
      open
      onClose={handleClose}
      maxWidth="lg"
      overflow="visible"
      data-testid="export-sustainability-scorecard"
    >
      <div className={classes.body}>
        <div className={classes.controls}>
          <div className={classes.header}>Export Sustainability Scorecard</div>
          <div className={classes.subHeader}>{subtitle}</div>
          <div className={classes.selectWrapper}>
            <div className={classes.label}>Metric</div>
            <Select id="metric-search" placeholder="Metric" value={metric} fullWidth>
              {availableAnalysisAndProductMetricOptions.map((option) => (
                <MenuItem key={option.value} value={option as any} onClick={() => setMetric(option)}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className={classes.selectWrapper}>
            <div className={classes.label}>Industry</div>
            <Select value={isSalesCategory ? salesCategory : functionalCategory} fullWidth>
              <Stack>
                <Tabs value={categoryType} variant="fullWidth" onChange={(_event, value) => setCategoryType(value)}>
                  <Tab label="Sales Category" value="sales" />
                  <Tab label="Ingredient Category" value="ingredient" />
                </Tabs>
              </Stack>
              {(isSalesCategory ? salesCategories : functionalCategories).map((option) => (
                <MenuItem key={option.value} value={option as any} onClick={() => handleSelectCategory(option)}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className={classes.selectWrapper}>
            <div className={classes.label}>Portfolio Comparison</div>
            <PortfolioSelect
              type={portfolioType}
              onTypeChange={setPortfolioType}
              onChange={handleSelectPortfolio}
              value={isWorkspacePortfolio ? portfolioWorkspace : portfolioFormulationTag}
            />
            {!isProductComparisonScorecard && (
              <div className={classes.comparisonDescription}>
                Compare against all of your products in Latis or only those is specific a workspace or tag.
              </div>
            )}
          </div>
          {isProductComparisonScorecard && (
            <div className={classes.selectWrapper}>
              <div className={classes.label}>Comparison Product</div>
              <ProductSelect value={comparisonProduct} onChange={handleComparisonProductChange} />
            </div>
          )}
          <div className={classes.buttons}>
            <Button
              fullWidth
              color="primary"
              onClick={() => {
                pendoTrack('Formula: Export Scorecard')
                scorecardRef.current?.exportPdf()
              }}
            >
              Export
            </Button>
            <Button fullWidth onClick={onClose}>
              Cancel
            </Button>
          </div>
        </div>
        <div className={classes.preview}>
          {renderPreview && (
            <ProductOverviewExportContainer
              ref={scorecardRef}
              impactScore={product ? impactData : null}
              comparisonProduct={comparisonProduct}
              comparisonProductImpactScore={comparisonProduct ? comparisonProductImpactData : null}
              category={isSalesCategory ? salesCategory : functionalCategory}
              categoryType={categoryType}
              portfolio={isWorkspacePortfolio ? portfolioWorkspace : portfolioFormulationTag}
              portfolioType={portfolioType}
              product={product ?? null}
              metric={metric}
              title={comparisonProduct ? 'Product Comparison' : product?.name || organization.organizationName}
              enableClaims={enableClaims}
              organization={organization}
              isInProductComparisonScorecard={isProductComparisonScorecard}
            />
          )}
        </div>
      </div>
    </LegacyDialog>
  )
}
