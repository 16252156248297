import React, { FC, HTMLAttributes, forwardRef, useMemo } from 'react'
import MuiAutocomplete, {
  AutocompleteRenderOptionState as MuiAutocompleteRenderOptionState,
  AutocompleteProps as MuiAutocompleteProps,
  createFilterOptions,
} from '@mui/material/Autocomplete'
import { FilterOptionsState } from '@mui/material'

interface AutocompleteProps extends Omit<MuiAutocompleteProps<any, boolean, boolean, boolean>, 'className'> {
  ['data-testid']?: string
  renderHeader?: FC
  renderFooter?: FC
  alwaysShowHeaderAndFooter?: boolean
}

export const Autocomplete = forwardRef<HTMLSelectElement, AutocompleteProps>(
  (
    {
      ['data-testid']: dataTestId,
      filterOptions: filterOps,
      renderOption: renderOp,
      renderHeader: Header,
      renderFooter: Footer,
      alwaysShowHeaderAndFooter,
      size = 'small',
      ...rest
    },
    ref
  ) => {
    const filterOptions = useMemo(() => {
      if (Header || Footer) {
        return (options: any[], state: FilterOptionsState<any>) => {
          const optionsFiltered = filterOps ? filterOps(options, state) : createFilterOptions()(options, state)
          return optionsFiltered.length > 0 || alwaysShowHeaderAndFooter
            ? [{ label: 'header' }, ...optionsFiltered, { label: 'footer' }]
                .filter((option) => (Header ? true : option.label !== 'header'))
                .filter((option) => (Footer ? true : option.label !== 'footer'))
            : optionsFiltered
        }
      }
      return filterOps
    }, [filterOps, Header, Footer, alwaysShowHeaderAndFooter])

    const renderOption =
      Header || Footer
        ? (props: HTMLAttributes<HTMLLIElement>, option: any, state: MuiAutocompleteRenderOptionState) => {
            if (option.label === 'header') {
              return <Header key="header" {...props} />
            }
            if (option.label === 'footer') {
              return <Footer key="footer" {...props} />
            }
            return renderOp(props, option, state)
          }
        : renderOp

    return (
      <MuiAutocomplete
        ref={ref}
        data-testid={dataTestId}
        size={size}
        filterOptions={filterOptions}
        renderOption={renderOption}
        {...rest}
      />
    )
  }
)

Autocomplete.displayName = 'Autocomplete'
