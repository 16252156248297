import React, { FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@howgood/design'

import { FormulationTags } from '../Shared/Filters/FormulationTags'
import { getFormulationTags, getInventoryIds, IFormulationTagSearchOption, IProduct } from '@/records'
import { getUniqueTags } from '@/utils/formulationTagsSearch'
import { updateProductInventories } from '@/state/products'
import { addMessage } from '@/state/messages'

interface EditTagsDialogProps {
  onClose: () => void
  title: string
  applyToSelection: (cb: (product: IProduct) => void) => void
  addTagsEnabled?: boolean
}

export const EditTagsDialog: FC<EditTagsDialogProps> = ({
  onClose,
  title,
  applyToSelection,
  addTagsEnabled = true,
}) => {
  const dispatch = useDispatch()
  const [selectedFormulationTags, setSelectedFormulationTags] = useState<IFormulationTagSearchOption[]>([])

  const handleAddTags = (newTags: IFormulationTagSearchOption[]) => {
    applyToSelection((product) => {
      const uniformTags = newTags.map((tag) => (tag.id ? tag : { name: tag.label }))
      const dedupedTags = getUniqueTags([...uniformTags, ...getFormulationTags(product)])
      const data = {
        formulation_tags: dedupedTags,
      }
      const inventory_ids = getInventoryIds(product)
      if (inventory_ids.length) {
        dispatch(updateProductInventories({ product_id: product.id, inventory_ids, data, productName: product.name }))
      } else {
        dispatch(
          addMessage({
            severity: 'error',
            message: `You do not have access to add tags to ${product.name}. Please contact support@howgod.com if you believe this is a mistake.`,
          })
        )
      }
    })
    onClose()
  }

  const handleRemoveTags = (toBeRemovedTags: IFormulationTagSearchOption[]) => {
    applyToSelection((product) => {
      const actualTags = getFormulationTags(product)

      const newTags = actualTags.filter(
        (tag) => !toBeRemovedTags.find((tbrTag) => tbrTag.name.toLowerCase() === tag.name.toLowerCase())
      )

      const data = {
        formulation_tags: newTags,
      }
      const inventory_ids = getInventoryIds(product)
      if (inventory_ids.length) {
        dispatch(updateProductInventories({ product_id: product.id, inventory_ids, data, productName: product.name }))
      } else {
        dispatch(
          addMessage({
            severity: 'error',
            message: `You do not have access to remove tags from ${product.name}. Please contact support@howgod.com if you believe this is a mistake.`,
          })
        )
      }
    })
    onClose()
  }

  return (
    <Dialog onClose={onClose} open data-testid="edit-tags-dialog">
      <DialogTitle data-testid="edit-tags-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <FormulationTags
          selectedFormulationTags={selectedFormulationTags}
          setSelectedFormulationTags={setSelectedFormulationTags}
          fullWidth
          addTagsEnabled={addTagsEnabled}
        />
      </DialogContent>
      <DialogActions>
        <Button data-testid="edit-tags-cancel-button" onClick={onClose}>
          Cancel
        </Button>
        <Button
          data-pendo-id={addTagsEnabled ? 'BulkEdit>Add>Save' : 'BulkEdit>Remove>Save'}
          data-testid="edit-tags-save-button"
          onClick={() =>
            addTagsEnabled ? handleAddTags(selectedFormulationTags) : handleRemoveTags(selectedFormulationTags)
          }
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
