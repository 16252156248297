import React, { FC } from 'react'
import { DataGridPremium, GridColDef, GridRowsProp, GridSortModel } from '@mui/x-data-grid-premium'
import { Box, Text, Stack } from '@howgood/design'
import { useSelector } from 'react-redux'
import { selectIsVendors, selectReportType } from '@/state/router'

interface HistoryTableProps {
  rows: GridRowsProp
  columns: GridColDef<any, any, any>[]
  sortModel?: GridSortModel
}

export const HistoryTable: FC<HistoryTableProps> = ({
  rows,
  columns,
  sortModel = [{ field: 'date_created', sort: 'desc' }] as GridSortModel,
}) => {
  const formulationReportType = useSelector(selectReportType)
  const isVendorPage = useSelector(selectIsVendors)
  const reportType = isVendorPage ? 'vendor' : formulationReportType

  return (
    <Stack gap={1}>
      <Text>Reports saved to Latis</Text>
      <Box overflow="auto" data-testid={`${reportType}-report-history-table`}>
        <DataGridPremium
          rows={rows}
          columns={columns}
          autoHeight
          hideFooter
          disableColumnMenu
          disableRowSelectionOnClick
          initialState={{
            density: 'compact',
            sorting: { sortModel },
          }}
          slots={{
            noRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                <Text>No {reportType.toLowerCase()} reports created</Text>
              </Stack>
            ),
          }}
        />
      </Box>
    </Stack>
  )
}
