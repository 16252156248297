import { estypes } from '@elastic/elasticsearch'

import { ImpactScore, fetchElasticV2, fetchHelperV2 } from '@/api'
import { FORMULATIONS_SEARCH_PATH } from '@/constants/config'
import { ESProduct } from '@/state/products'
import { ProductsBoolQuery, SortOption, generateSort } from '@/api/elastic/productsEsRepo'
import { ScenarioProductAttributes } from '@/records'

const PRODUCT_SOURCE_FIELDS = [
  'id',
  'pk',
  'upc',
  'name',
  'date_modified',
  'workspaces',
  'formulation_status',
  'impact_score_total',
  'impact_score',
  'ingredients.id',
  'ingredients.name',
  'ingredients.standards',
  'ingredients.ingredient_origin_location_region_name',
  'nutrition_*',
  'inventories.id',
  'inventories.formulation_tags',
  'inventories.goals',
  'inventories.internal_id',
  'inventories.mt_per_year',
  'inventories.workspace_id',
  'inventories.validation_requests',
  'inventories.price_per_mt',
  'inventories.buyer_name',
  'inventories.buyer_role',
  'annual_sales_volume',
  'weight_kg',
  'has_default_weights',
  'locked_claims',
  'material_types',
  'sharing_requests',
  'input_weight_unit',
  'reports',
  'validation_requests',
  'brand',
  'assignee',
  'workflow_tags',
  'scenarios',
  'region',
]

interface FetchProductsAndRollupsParams {
  page?: number
  boolQuery?: ProductsBoolQuery
  sortOptions?: SortOption[]
  size: number
  runtimeMappings?: estypes.MappingRuntimeFields
  aggs?: Record<string, any>
}
export interface FetchProducts {
  hits?: ESProduct[]
  total?: number
  custom_aggs?: Record<string, number>
  rollups?: {
    metrics: ImpactScore
  }
}
// These can be really slow requests, no need to keep sending requests when
// a user makes a change and the initial request is no longer valid
let abortController = new AbortController()
export async function fetchProductsAndRollups({
  page = 1,
  boolQuery = {},
  sortOptions = [],
  size,
  runtimeMappings = {},
  aggs = {},
}: Partial<FetchProductsAndRollupsParams>): Promise<FetchProducts> {
  abortController.abort() // Cancel the previous request
  abortController = new AbortController()
  const sort = generateSort(sortOptions)
  const isNormalFormulations = (v: estypes.SearchResponse<ESProduct>) => !!(v && v.hits)
  let formulations: estypes.SearchResponse<ESProduct> = null

  try {
    const res = await fetchElasticV2<ESProduct>({
      url: FORMULATIONS_SEARCH_PATH,
      signal: abortController.signal,
      body: {
        size,
        from: (page - 1) * size,
        _source: PRODUCT_SOURCE_FIELDS,
        query: { bool: boolQuery },
        sort,
        runtime_mappings: runtimeMappings,
        // If we have runtime mappings, include them in the list of fields to be returned
        fields: Object.keys(runtimeMappings),
        // If we have runtime mappings, generate a `sum` aggregation for each one
        aggs: {
          ...aggs,
          ...Object.keys(runtimeMappings).reduce(
            (acc, key) => ({
              ...acc,
              [`custom_${key}`]: { sum: { field: key } },
            }),
            {}
          ),
        },
      },
    })

    if (isNormalFormulations(res)) {
      formulations = res
    }
  } catch (e) {
    console.error(e)
  } finally {
    const total = formulations?.hits?.total as estypes.SearchTotalHits
    const hits = formulations?.hits?.hits?.map((hit) => {
      // The results of runtime mappings are returned as a `fields` object on the hit, so move them to the _source,
      // under a new `custom_fields` key
      return {
        ...hit._source,
        custom_fields: Object.entries(hit.fields || {}).reduce((acc, [key, value]) => {
          // Custom field values, if assigned, are returned as arrays, so get the first value
          return { ...acc, [key]: value?.length ? value[0] : null }
        }, {}),
      }
    })
    const rollups = formulations?.aggregations
      ? Object.keys(formulations?.aggregations).reduce(
          (allData, metric) => {
            const aggregation = formulations?.aggregations[metric] as estypes.AggregationsCardinalityAggregate
            // for goals we want to include goals that === 0, exclude goals that === null and exclude counts that === 0
            if (!metric.includes('custom_')) {
              allData.metrics[metric] = aggregation.value
            }
            return allData
          },
          { metrics: {} }
        )
      : { metrics: {} }
    // eslint-disable-next-line no-unsafe-finally
    return {
      hits: hits || [],
      total: total?.value || 0,
      custom_aggs: Object.entries(formulations?.aggregations || {}).reduce((acc, [key, value]) => {
        if (key.includes('custom_')) {
          return { ...acc, [key.replace('custom_', '')]: (value as estypes.AggregationsSumAggregate).value }
        }
        return acc
      }, {}),
      rollups,
    }
  }
}

export const fetchUpdateScenarioProduct = async (
  scenarioId: number,
  productId: number,
  data: ScenarioProductAttributes
): Promise<string> => {
  return await fetchHelperV2<string>({
    url: `scenarios/${scenarioId}/products/${productId}/`,
    method: 'POST',
    body: JSON.stringify(data),
  })
}
