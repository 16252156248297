import { InventoriesResponse } from '@/api'
import { IProduct, WeightOption } from '@/records'
import { Goals } from '@/records/Workspace'

export type ColdStorageType = 'R' | 'F' | ''

// Fix a couple of things that are incorrect with IProduct
// Doing this here to avoid sweeping changes throughout the app
export interface ProductResponse extends Omit<IProduct, 'requires_cold_storage'> {
  requires_cold_storage?: ColdStorageType
}

// Locked claims data should exist only on productOverview.lockedProductInfo
// export interface ProductInfo extends ProductResponse {
export interface ProductInfo extends Omit<ProductResponse, 'locked_claims' | 'locked_claims_timestamp'> {
  weight_option?: WeightOption
  brand_name?: string
  notes?: string
}

export interface State {
  isLoadingProduct: boolean
  isLoadingLock: boolean
  isLoadingShare: boolean
  isLoadingValidationRequest: boolean
  productErrorText: string
  savedProductInfo: ProductInfo
  draftProductInfo: ProductInfo
  lockedProductInfo: ProductInfo
  savedInventories: InventoriesResponse[]
  draftInventories: InventoriesResponse[]
}

export const initialProductInfo: ProductInfo = {
  id: null,
  name: '',
  upc: '',
  formulation_status: 'pipeline',
  date_modified: '',
  org: false,
  organization: null,
  weight_kg: '',
  annual_sales_volume: null,
  requires_cooking: false,
  requires_cold_storage: null,
  manufacturing_type: null,
  region: null,
  retail_region: null,
  storage_region: null,
  sales_potential_impact: null,
  simple: false,
  agribalyze_category: null,
  craveability: null,
  sharing_requests: [],
  reports: { items: [] },
  brand_name: null,
  notes: '',
  workspaces: [],
  scenarios: [],
  scenario_products: [],
}

export const initialDraftInventory: InventoriesResponse = {
  id: null,
  date_created: null,
  date_modified: null,
  customer_type: null,
  internal_id: null,
  buyer_name: null,
  buyer_role: null,
  notes: null,
  packaging_description: null,
  mt_per_year: null,
  price_per_mt: null,
  producer_metadata: null,
  workflow_editor: null,
  product: null,
  workspace: { id: null },
  formulation_tags: [],
  validation_requests: [],
  goals: {} as Goals,
}

export const initialState: State = {
  isLoadingProduct: false,
  isLoadingLock: false,
  isLoadingShare: false,
  isLoadingValidationRequest: false,
  productErrorText: '',
  savedProductInfo: initialProductInfo,
  draftProductInfo: initialProductInfo,
  lockedProductInfo: initialProductInfo,
  savedInventories: [initialDraftInventory],
  draftInventories: [initialDraftInventory],
}
