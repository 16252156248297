import { estypes } from '@elastic/elasticsearch'
import {
  FormulationStatus,
  IFormulationSearchOption,
  IProduct,
  UnknownVendorOptionValue,
  UNKNOWN_VENDOR_OPTION_VALUE,
} from '@/records'
import { timeoutPromise } from '@/utils/promise'
import { fetchBulkElasticV2, fetchElasticV2, InventoriesResponse } from '@/api'
import { DEFAULT_PAGE_SIZE, FORMULATIONS_SEARCH_PATH } from '@/constants/config'
import { DataGranularOptionValue, ESProduct } from '@/state/products'
import {
  PERMISSIONS_TO_FIELDS_MAP,
  SCOPE_3_CATEGORY_1_FIELD,
  SCOPE_3_CATEGORY_4_FIELD,
  SCOPE_3_CONTRIBUTION_FIELD,
  SCOPE_3_FLAG_FIELD,
} from '@/constants/impactScore'
import { getThresholdValues } from '@/utils/impactScoreHelpers'
import {
  generateKnownVendorsFilter,
  generateUnKnownVendorsFilter,
  generateVendorVerifiedFilter,
  vendorVerifiedPlusFilter,
} from './vendorGranularityFilters'

export interface SortOption {
  [key: string]: { order: 'asc' | 'desc' }
}
export interface GetProductsFilters {
  statuses?: FormulationStatus[]
  query?: string[]
  ingredientIds?: string[]
  workspaceIds?: number[]
  sortOptions?: SortOption[]
  salesCategories?: string[]
  formulationTags?: string[]
  ids?: number[]
  components?: number[]
  vendors?: (number | UnknownVendorOptionValue)[]
  atRisk?: boolean
  productImpactPermissions?: string[]
  scenario?: number
  standardsIds?: number[]
  regions?: number[]
  dataGranulars?: DataGranularOptionValue[]
  thirdPartyWorkspaces?: number[]
  includeVendorVerifiedPlus?: boolean
}
interface GetProductsParams {
  filters: GetProductsFilters
  _source?: string[]
  page?: number
  size?: number
  signal?: AbortSignal
}
interface GetAllProductsParams {
  sortOptions?: SortOption[]
  _source?: string[]
  scrollId?: string
  sendCount?: (progress: { count: number; total: number }) => void
  signal?: AbortSignal
  batchSize?: number
  runtimeMappings?: estypes.MappingRuntimeFields
  boolQuery?: ProductsBoolQuery
}

const getDefaultProductFields = () => [
  'id',
  'pk',
  'upc',
  'name',
  'date_modified',
  'workspaces',
  'formulation_status',
  'impact_score_total',
  'impact_score',
  'ingredients',
  'best_in_class',
  'sales_category',
  'nutrition_*',
  'inventories',
  'annual_sales_volume',
  'weight_kg',
  'has_default_weights',
  'locked_claims',
  'material_types',
  'sharing_requests',
  'input_weight_unit',
  'reports',
  'validation_requests',
  'brand',
  'assignee',
  'workflow_tags',
  'scenarios',
  'region',
]

export interface ProductsBoolQuery extends Omit<estypes.QueryDslBoolQuery, 'filter'> {
  filter?: estypes.QueryDslQueryContainer[]
}
export const constructProductsBoolQuery = ({
  workspaceIds = [],
  query = [],
  statuses = [],
  ingredientIds = [],
  salesCategories = [],
  formulationTags = [],
  ids = [],
  components = [],
  vendors = [],
  atRisk = false,
  productImpactPermissions = [],
  scenario = null,
  standardsIds = [],
  regions = [],
  dataGranulars = [],
  thirdPartyWorkspaces = [],
  includeVendorVerifiedPlus = false,
}: GetProductsFilters) => {
  const boolQuery: ProductsBoolQuery = {
    filter: [],
  }

  if (scenario) {
    boolQuery.filter.push({
      terms: {
        'scenarios.id': [scenario],
      },
    })
  }

  if (workspaceIds.length) {
    boolQuery.filter.push({
      terms: {
        'workspaces.id': workspaceIds,
      },
    })
  }

  if (statuses.length) {
    boolQuery.filter.push({
      terms: {
        formulation_status: statuses,
      },
    })
  }

  if (formulationTags.length) {
    boolQuery.filter.push({
      terms: {
        'inventories.formulation_tags.keyword': formulationTags,
      },
    })
  }

  if (salesCategories.length) {
    boolQuery.filter.push({
      bool: {
        should: salesCategories.map((category: string) => ({
          match_phrase: { 'sales_category.title.keyword': category },
        })),
        minimum_should_match: 1,
      },
    })
  }

  if (ingredientIds.length) {
    boolQuery.filter.push({
      terms: {
        'ingredients.ingredient_id': ingredientIds,
      },
    })
  }

  if (ids.length) {
    boolQuery.filter.push({
      terms: { pk: ids },
    })
  }

  if (components.length) {
    boolQuery.filter.push({
      terms: {
        'ingredients.nested_product_id': components,
      },
    })
  }

  if (standardsIds.length) {
    boolQuery.filter.push({
      terms: {
        'ingredients.standards.id': standardsIds,
      },
    })
  }

  if (regions.length) {
    boolQuery.filter.push({
      terms: {
        'region.id': regions,
      },
    })
  }

  if (dataGranulars.length) {
    const dataGranularsShould = []
    dataGranulars.forEach((dataGranular: DataGranularOptionValue) => {
      if (dataGranular === 'unknown-vendors') {
        dataGranularsShould.push(generateUnKnownVendorsFilter(thirdPartyWorkspaces, includeVendorVerifiedPlus))
      } else if (dataGranular === 'known-vendors') {
        dataGranularsShould.push(generateKnownVendorsFilter(thirdPartyWorkspaces, includeVendorVerifiedPlus))
      } else if (dataGranular === 'vendor_verified') {
        dataGranularsShould.push(generateVendorVerifiedFilter(thirdPartyWorkspaces))
      } else if (dataGranular === 'vendor_verified_plus') {
        dataGranularsShould.push(vendorVerifiedPlusFilter)
      }
    })
    boolQuery.filter.push({
      bool: {
        should: dataGranularsShould,
        minimum_should_match: 1,
      },
    })
  }

  const includeUnknownVendors = vendors.includes(UNKNOWN_VENDOR_OPTION_VALUE)
  const knownVendorIds = includeUnknownVendors ? vendors.filter((v) => v !== UNKNOWN_VENDOR_OPTION_VALUE) : vendors

  if (!includeUnknownVendors && knownVendorIds.length) {
    boolQuery.filter.push({
      terms: {
        'brand.id': knownVendorIds,
      },
    })
  }

  if (includeUnknownVendors) {
    const vendorShould = []

    if (knownVendorIds.length) {
      vendorShould.push({
        bool: {
          must: {
            terms: {
              'brand.id': knownVendorIds,
            },
          },
        },
      })
    }

    // this is for filtering out products with unknown vendor
    vendorShould.push(
      ...[
        {
          bool: {
            must_not: {
              exists: {
                field: 'brand.name.keyword',
              },
            },
          },
        },
        {
          bool: {
            must: {
              term: {
                'brand.name.keyword': '',
              },
            },
          },
        },
      ]
    )
    boolQuery.filter.push({
      bool: {
        should: vendorShould,
        minimum_should_match: 1,
      },
    })
  }

  if (query?.length) {
    boolQuery.should = query.map((queryString) => ({
      multi_match: {
        query: queryString,
        type: 'phrase_prefix',
        fields: ['name', 'inventories.internal_id', 'upc'],
      },
    }))
    boolQuery.minimum_should_match = 1
  }

  if (atRisk && productImpactPermissions.length) {
    const fields = productImpactPermissions.map((permission) => PERMISSIONS_TO_FIELDS_MAP[permission])
    const impactScoreFilter = {
      bool: {
        should: fields.map((metric) => ({
          range: {
            [`impact_score.${metric}`]: getThresholdValues(metric),
          },
        })),
        minimum_should_match: 1,
      },
    }
    boolQuery.filter.push(impactScoreFilter)
  }

  return boolQuery
}

export const generateSort = (sortOptions) => {
  if (!sortOptions.length) {
    return [{ date_modified: { order: 'desc' } }]
  }
  return sortOptions.map((sortOption) => {
    if (Object.keys(sortOption).includes('reports')) {
      return {
        locked_claims_timestamp: {
          order: 'desc',
          missing: sortOption.reports.order === 'desc' ? '_first' : '_last',
        },
      }
    }
    // `assignee` is an object, so tell ES to sort by the `email` field
    if (Object.keys(sortOption).includes('assignee')) {
      return {
        ['assignee.email.keyword']: {
          order: sortOption.assignee.order,
          missing: '_last',
        },
      }
    }
    // `brand` is an object, so tell ES to sort by the `name` field
    if (Object.keys(sortOption).includes('brand')) {
      return {
        ['brand.name.keyword']: {
          order: sortOption.brand.order,
          missing: '_last',
        },
      }
    }
    // `productionLocation` is an object, so tell ES to sort by the `name` field
    if (Object.keys(sortOption).includes('productionLocation')) {
      return {
        ['region.name.keyword']: {
          order: sortOption.productionLocation.order,
          missing: '_last',
        },
      }
    }
    // `mtPerYear` uses a custom field, so tell ES to sort by the `mt_per_year` field
    if (Object.keys(sortOption).includes('mtPerYear')) {
      return {
        ['mt_per_year']: {
          order: sortOption.mtPerYear.order,
          missing: '_last',
        },
      }
    }
    // `SCOPE_3_CATEGORY_1_FIELD` uses a custom field, so tell ES to sort by the `SCOPE_3_CATEGORY_1_FIELD` field
    if (Object.keys(sortOption).includes(`impact_score.${SCOPE_3_CATEGORY_1_FIELD}`)) {
      return {
        [SCOPE_3_CATEGORY_1_FIELD]: {
          order: sortOption[`impact_score.${SCOPE_3_CATEGORY_1_FIELD}`].order,
          missing: '_last',
        },
      }
    }
    // `SCOPE_3_CATEGORY_4_FIELD` uses a custom field, so tell ES to sort by the `SCOPE_3_CATEGORY_4_FIELD` field
    if (Object.keys(sortOption).includes(`impact_score.${SCOPE_3_CATEGORY_4_FIELD}`)) {
      return {
        [SCOPE_3_CATEGORY_4_FIELD]: {
          order: sortOption[`impact_score.${SCOPE_3_CATEGORY_4_FIELD}`].order,
          missing: '_last',
        },
      }
    }
    // `SCOPE_3_FLAG_FIELD` uses a custom field, so tell ES to sort by the `SCOPE_3_FLAG_FIELD` field
    if (Object.keys(sortOption).includes(`impact_score.${SCOPE_3_FLAG_FIELD}`)) {
      return {
        [SCOPE_3_FLAG_FIELD]: {
          order: sortOption[`impact_score.${SCOPE_3_FLAG_FIELD}`].order,
          missing: '_last',
        },
      }
    }
    // `SCOPE_3_CONTRIBUTION_FIELD` uses a custom field, so tell ES to sort by the `SCOPE_3_CONTRIBUTION_FIELD` field
    if (Object.keys(sortOption).includes(`impact_score.${SCOPE_3_CONTRIBUTION_FIELD}`)) {
      return {
        ['scope_3_total_annual']: {
          order: sortOption[`impact_score.${SCOPE_3_CONTRIBUTION_FIELD}`].order,
          missing: '_last',
        },
      }
    }
    return sortOption
  })
}

export interface FetchProducts {
  hits: ESProduct[]
  total: number
  custom_aggs: Record<string, number>
}
export async function fetchProducts({
  _source = getDefaultProductFields(),
  page = 1,
  size = DEFAULT_PAGE_SIZE,
  signal = undefined,
  filters = {},
}: Partial<GetProductsParams>): Promise<FetchProducts> {
  const boolQuery = constructProductsBoolQuery(filters)
  let formulations: estypes.SearchResponse<ESProduct> = null
  const isNormalFormulations = (v: estypes.SearchResponse<ESProduct>) => !!(v && v.hits)
  try {
    const res = await timeoutPromise(
      fetchElasticV2<ESProduct>({
        url: FORMULATIONS_SEARCH_PATH,
        body: {
          size,
          from: (page - 1) * size,
          _source,
          query: { bool: boolQuery },
        },
        signal,
      })
    )
    if (isNormalFormulations(res)) {
      formulations = res
    }
  } catch (e) {
    console.error(e)
  } finally {
    const total = formulations?.hits?.total as estypes.SearchTotalHits
    const hits = formulations?.hits?.hits?.map((hit) => {
      // The results of runtime mappings are returned as a `fields` object on the hit, so move them to the _source,
      // under a new `custom_fields` key
      return {
        ...hit._source,
        custom_fields: Object.entries(hit.fields || {}).reduce((acc, [key, value]) => {
          // Custom field values, if assigned, are returned as arrays, so get the first value
          return { ...acc, [key]: value?.length ? value[0] : null }
        }, {}),
      }
    })
    // eslint-disable-next-line no-unsafe-finally
    return {
      hits: hits || [],
      total: total?.value || 0,
      custom_aggs: Object.entries(formulations?.aggregations || {}).reduce(
        (acc, [key, value]) => ({ ...acc, [key]: (value as estypes.AggregationsSumAggregate).value }),
        {}
      ),
    }
  }
}

interface FetchFormulationSearchOptions {
  workspaceIds?: number[]
  statuses?: FormulationStatus[]
  formulationTags?: string[]
  query: string[]
  size?: number
  page?: number
}

// These can be really slow requests, no need to keep sending requests when
// a user makes a change and the initial request is no longer valid
let abortController = new AbortController()
export async function fetchFormulationSearchOptions({
  workspaceIds,
  statuses,
  formulationTags,
  query,
  page = 1,
  size = 100,
}: FetchFormulationSearchOptions): Promise<IFormulationSearchOption[]> {
  abortController.abort() // Cancel the previous request
  abortController = new AbortController()
  const { hits } = await fetchProducts({
    filters: { workspaceIds, statuses, formulationTags, query },
    _source: ['id', 'pk', 'name'],
    size,
    page,
    signal: abortController.signal,
  })
  if (hits && hits.length) {
    return hits.map((hit) => ({
      value: hit.id || hit.pk,
      label: hit.name,
      query,
      raw: hit,
    }))
  }
  return []
}

export async function fetchFormulationSearchOptionsById(ids: number[]): Promise<IFormulationSearchOption[]> {
  const { hits } = await fetchProducts({
    filters: { ids },
    _source: ['id', 'pk', 'name', 'workspaces', 'formulation_status'],
  })
  if (hits && hits.length) {
    return hits.map((hit) => ({
      value: hit.id || hit.pk,
      label: hit.name,
      query: [],
    }))
  }
  return []
}

interface GetAllProducts {
  hits: IProduct[] | null
  custom_aggs: Record<string, number>
}

interface ElasticsearchProductSourceInventory extends InventoriesResponse {
  workspace_id: number
}
interface GetAllProductsSource extends IProduct {
  inventories: ElasticsearchProductSourceInventory[]
}
export async function fetchAllProducts({
  _source = getDefaultProductFields(),
  sendCount,
  signal,
  batchSize = 1000,
  runtimeMappings = {},
  boolQuery = {},
  sortOptions = [],
}: GetAllProductsParams): Promise<GetAllProducts> {
  const sort = generateSort(sortOptions)
  const formulations = await fetchBulkElasticV2({
    url: `${FORMULATIONS_SEARCH_PATH}`,
    body: {
      query: { bool: boolQuery },
      _source,
      sort,
      size: batchSize,
      runtime_mappings: runtimeMappings,
      // If we have runtime mappings, include them in the list of fields to be returned
      fields: Object.keys(runtimeMappings),
      // If we have runtime mappings, generate a `sum` aggregation for each one
      aggs: Object.keys(runtimeMappings).reduce(
        (acc, key) => ({
          ...acc,
          [key]: { sum: { field: key } },
        }),
        {}
      ),
    },
    sendCount: sendCount,
    signal: signal || null,
  })
  // `formulations.hits` is null if the request failed
  if (formulations.hits) {
    const transformedHits = formulations.hits.map((hit) => {
      const source = hit._source as GetAllProductsSource
      if (source.inventories) {
        source.inventories = source.inventories.map((inventory) => ({
          ...inventory,
          workspace: { id: inventory.workspace_id },
        }))
      }
      // The results of runtime mappings are returned as a `fields` object on the hit, so move them to the _source,
      // under a new `custom_fields` key
      return {
        ...source,
        custom_fields: Object.entries(hit.fields || {}).reduce((acc, [key, value]) => {
          // Custom field values, if assigned, are returned as arrays, so get the first value
          return { ...acc, [key]: value?.length ? value[0] : null }
        }, {}),
      }
    })
    return {
      hits: transformedHits,
      custom_aggs: Object.entries(formulations?.aggregations || {}).reduce(
        (acc, [key, value]) => ({ ...acc, [key]: (value as estypes.AggregationsSumAggregate).value }),
        {}
      ),
    }
  }
  // Use this to signal to the requestor that the request failed
  return { hits: null, custom_aggs: null }
}
