import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MenuItem, Select, Stack, Text } from '@howgood/design'
import { BeverageTemperature as BeverageTemperatureT } from '@/records'
import { DRAFT, selectPageView } from '@/state/pageSettings'
import { selectDisplayedRecipe, updateRecipeAndScores } from '@/state/recipe'

const options: BeverageTemperatureT[] = ['I', 'C', 'H']
const temperatureToLabelMap: Record<BeverageTemperatureT, string> = {
  I: 'Iced',
  C: 'Cold',
  H: 'Hot',
}

export const BeverageTemperature: FC = () => {
  const dispatch = useDispatch()
  const recipe = useSelector(selectDisplayedRecipe)
  const view = useSelector(selectPageView)

  return (
    <Stack data-testid="beverage-temperature" flexDirection="row" gap={1}>
      <Text data-testid="beverage-temperature-title">Beverage temperature:</Text>
      {view === DRAFT ? (
        <Select
          data-testid="beverage-temperature-select"
          value={recipe.temperature || ''}
          fullWidth
          onChange={(e) => {
            const temperature = e.target.value as BeverageTemperatureT
            dispatch(updateRecipeAndScores({ change: 'Update beverage temperature', recipeUpdates: { temperature } }))
          }}
        >
          {options.map((option) => (
            <MenuItem key={option} data-testid={`beverage-temperature-item-${option}`} value={option}>
              {temperatureToLabelMap[option]}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <Text data-testid="beverage-temperature-value" textTransform="capitalize">
          {temperatureToLabelMap[recipe.temperature]}
        </Text>
      )}
    </Stack>
  )
}
