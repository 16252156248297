import { useSelector } from 'react-redux'
import useSWR from 'swr'
import { selectConstructedFilters, selectShowAtRiskOnly } from '@/state/products'
import { selectProductMetricsPermissions } from '@/state/user'
import { constructProductsBoolQuery } from '@/api/elastic/productsEsRepo'
import { getESScope3Aggregations, getESVendorGranularityAggregations } from './requests'
import { selectThirdPartyWorkspaces, selectWorkspacesWithoutDefault } from '@/state/workspaces'
import { MATERIAL_DIRECTORY } from '@/records'
import { selectMaterialIconAgpracticeSplit } from '@/state/splitio'
import { selectProductsBoolQuery } from '@/selectors/selectProductsRequestItems'
import {
  MATERIAL_GRANULARITY_KNOWN_VENDORS,
  MATERIAL_GRANULARITY_STANDARD_INGREDIENTS,
  MATERIAL_GRANULARITY_UNKNOWN_VENDORS,
  MATERIAL_GRANULARITY_VENDOR_VERIFIED,
  MATERIAL_GRANULARITY_VENDOR_VERIFIED_PLUS,
} from '@/features/ProductPage/panels/ImpactReduction/ImpactReduction.hooks'

interface MaterialGranularityBreakdownData {
  totalCount: number
  vendors: {
    [MATERIAL_GRANULARITY_VENDOR_VERIFIED_PLUS]?: number
    [MATERIAL_GRANULARITY_VENDOR_VERIFIED]?: number
    [MATERIAL_GRANULARITY_KNOWN_VENDORS]?: number
    [MATERIAL_GRANULARITY_UNKNOWN_VENDORS]?: number
    [MATERIAL_GRANULARITY_STANDARD_INGREDIENTS]?: number
  }
}

export function useScope3AggregationData(): any {
  const { boolQuery, workspaceIds } = useSelector(selectProductsBoolQuery)
  const { data, error } = useSWR(boolQuery, () => getESScope3Aggregations(boolQuery, workspaceIds))

  return {
    data: {
      scope3Cat1Data: data?.scope3Cat1Data || {
        hits: [],
        total: null,
        count: null,
      },
      scope3Cat4Data: data?.scope3Cat4Data || {
        hits: [],
        total: null,
        count: null,
      },
      carbonFootprintByVendor: data?.carbonFootprintByVendor || { scope_3_cat_1_annual: {}, scope_3_cat_4_annual: {} },
    },
    error,
    isLoading: !data && !error,
  }
}

export function useVendorGranularityAggregationData(): {
  data: MaterialGranularityBreakdownData
  error: any
  isLoading: boolean
} {
  const includeVendorVerifiedPlus = useSelector(selectMaterialIconAgpracticeSplit)
  const thirdPartyWorkspaces = useSelector(selectThirdPartyWorkspaces)
  const userWorkspaces = useSelector(selectWorkspacesWithoutDefault)
  const constructedFilters = useSelector(selectConstructedFilters)
  const atRisk = useSelector(selectShowAtRiskOnly)
  const productImpactPermissions = useSelector(selectProductMetricsPermissions)
  // This matches what's used in products.thunk.ts to construct the bool query
  const { ingredient: _, workspaceIds, ...filters } = constructedFilters.filters
  // Remove material directory workspaces from the list of workspaces
  const materialDirWorkspaceIds = userWorkspaces
    .filter((workspace) => workspace.workspace_type === MATERIAL_DIRECTORY)
    .map((ws) => ws.id)
  const workspacesWithoutMatDir = workspaceIds.filter((id) => !materialDirWorkspaceIds.includes(id))
  const boolQuery = constructProductsBoolQuery({
    ...filters,
    workspaceIds: workspacesWithoutMatDir,
    atRisk,
    productImpactPermissions,
    thirdPartyWorkspaces: thirdPartyWorkspaces.map((w) => w.id),
    includeVendorVerifiedPlus,
  })

  const { data, error } = useSWR({ boolQuery, agg: 'dataGranularity' }, () =>
    getESVendorGranularityAggregations(
      boolQuery,
      thirdPartyWorkspaces.map((w) => w.id),
      workspacesWithoutMatDir,
      includeVendorVerifiedPlus
    )
  )

  if (!data) {
    return {
      data: {
        totalCount: 0,
        vendors: {},
      },
      error,
      isLoading: !error,
    }
  }

  const {
    vendor_granularity: {
      buckets: { knownVendors, unknownVendors, vendorVerified, vendorVerifiedPlus },
    },
  } = data

  const vendors = includeVendorVerifiedPlus
    ? {
        [MATERIAL_GRANULARITY_VENDOR_VERIFIED_PLUS]: vendorVerifiedPlus.doc_count,
        [MATERIAL_GRANULARITY_VENDOR_VERIFIED]: vendorVerified.doc_count,
        [MATERIAL_GRANULARITY_KNOWN_VENDORS]: knownVendors.doc_count,
        [MATERIAL_GRANULARITY_UNKNOWN_VENDORS]: unknownVendors.doc_count,
      }
    : {
        [MATERIAL_GRANULARITY_VENDOR_VERIFIED]: vendorVerified.doc_count,
        [MATERIAL_GRANULARITY_KNOWN_VENDORS]: knownVendors.doc_count,
        [MATERIAL_GRANULARITY_UNKNOWN_VENDORS]: unknownVendors.doc_count,
      }

  return {
    data: {
      vendors,
      totalCount:
        unknownVendors.doc_count +
        knownVendors.doc_count +
        vendorVerified.doc_count +
        (includeVendorVerifiedPlus ? vendorVerifiedPlus.doc_count : 0),
    },
    error,
    isLoading: !data && !error,
  }
}
