import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { Autocomplete, MenuItem, TextField, Tooltip } from '@howgood/design'
import { fetchFormulationTagsByQuery, generateFormulationTagFilterOption } from '@/utils/formulationTagsSearch'
import { ElasticsearchFormulationTagHit, FormulationTagSearchOption } from '@/records'
import { selectWorkspacesIds } from '@/state/workspaces'
import { FormulationTagFilterOption } from '@/state/products/products.state'

interface Props {
  value: FormulationTagFilterOption[]
  handleChange: (options: FormulationTagFilterOption[]) => void
  id?: string
  disabled?: boolean
  autoFocus?: boolean
  fullWidth?: boolean
}

// Shared filter for usage in Formulations and Aggregated Vendors screens
export const FormulationTagFilter: FC<Props> = ({
  value,
  handleChange,
  id,
  disabled = false,
  autoFocus = false,
  fullWidth = false,
}) => {
  const workspaceIds = useSelector(selectWorkspacesIds)
  const [options, setOptions] = useState([])

  const fetchOptions = async (tagQuery: string) => {
    if (!tagQuery) {
      return 
    }
    const hits: ElasticsearchFormulationTagHit[] = await fetchFormulationTagsByQuery({
      filters: {
        search: tagQuery,
        workspaceIds,
      },
    })
    const opts = hits.map((hit) => generateFormulationTagFilterOption(hit))
    const fetchedTags = opts.filter((option: FormulationTagSearchOption) => {
      const firstMatched = opts.find(
        (opt: FormulationTagSearchOption) => opt.name.trim().toLowerCase() === option.name.trim().toLowerCase()
      )
      return firstMatched === option
    })
    setOptions(fetchedTags)
  }

  return (
    <Tooltip title="Filter formulas by formulation tags" placement="top">
      <Autocomplete
        data-pendo-id="SearchFormulationTags"
        data-testid={id}
        id={id}
        options={options}
        filterSelectedOptions
        isOptionEqualToValue={(option, val) => option.value === val.value}
        onChange={(_event, ops) => handleChange(ops)}
        value={value}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            autoFocus={autoFocus}
            placeholder="Tags"
            fullWidth={fullWidth}
            onChange={(event) => fetchOptions(event.target.value)}
          />
        )}
        renderOption={(props, option) => (
          <MenuItem {...props} key={option.value} value={option.value} onClick={() => handleChange([...value, option])}>
            {option.label}
          </MenuItem>
        )}
        multiple
        fullWidth={fullWidth}
        componentsProps={{ popper: { style: { width: 'fit-content' }, placement: 'bottom-start' } }}
      />
    </Tooltip>
  )
}
