import React from 'react'
import { GridColumnHeaderParams } from '@mui/x-data-grid-premium'
import { Tooltip, Text, Stack } from '@howgood/design'
import { availableVendorMetrics } from '@/state/vendorManagement'
import { useContentfulMetric } from '@/contentfully/hooks'

export const useMetricHeader = (params: GridColumnHeaderParams) => {
  const contentfulEntry = useContentfulMetric(params.field)
  const fallbackUnits = availableVendorMetrics[params.field]?.units || ''

  return (
    <Tooltip
      title={
        <Stack gap={1}>
          <Text variant="body2" fontWeight={600}>
            {availableVendorMetrics[params.field]?.title} {contentfulEntry?.units || fallbackUnits}
          </Text>
          <Text variant="body2">{availableVendorMetrics[params.field]?.description || ''}</Text>
        </Stack>
      }
    >
      <Text variant="body2" fontWeight={600}>
        {availableVendorMetrics[params.field]?.title}
      </Text>
    </Tooltip>
  )
}
