import React, { FC, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@howgood/design'
import {
  CarbonAccountingReportActivity,
  ScopeCategoryStatus,
  changeEditedActivities,
  selectCarbonAccountingEditedEmissionsActivityIds,
  selectCarbonAccountingLoadingReports,
  selectCarbonAccountingReportActivities,
  updateCarbonAccountingReport,
  updateCarbonAccountingReportActivities,
} from '@/state/carbonAccounting'
import { FormActivityValues, CategoryWithScore } from '../CarbonAccounting.types'
import { CarbonAccountingDialogForm } from './CarbonAccountingDialogForm'

interface Props {
  reportId: number
  category: CategoryWithScore
  onClose: () => void
}

export const CarbonAccountingDialog: FC<Props> = ({ reportId, category, onClose }) => {
  const dispatch = useDispatch()
  const [formActivityValues, setFormActivityValues] = useState<FormActivityValues>({})
  const reportActivities = useSelector(selectCarbonAccountingReportActivities)
  const editedActivityIds = useSelector(selectCarbonAccountingEditedEmissionsActivityIds)
  const loadingReports = useSelector(selectCarbonAccountingLoadingReports)
  const defaultActivityValues = useMemo(() => {
    if (category?.activities) {
      return category.activities.reduce((acc, activity) => {
        const reportActivity = reportActivities.find((a) => a.activity.id === activity.id)
        return { ...acc, [activity.id]: reportActivity }
      }, {})
    }
    return {}
  }, [category?.activities, reportActivities])

  useEffect(() => {
    setFormActivityValues(defaultActivityValues)
  }, [defaultActivityValues])

  function onChange(value: Partial<CarbonAccountingReportActivity>, id: number) {
    const currentValue = formActivityValues[id]
    setFormActivityValues({ ...formActivityValues, [id]: { ...currentValue, ...value } })
    if (!editedActivityIds.includes(id)) {
      dispatch(changeEditedActivities([...editedActivityIds, id]))
    }
  }

  function onDiscardChanges() {
    setFormActivityValues(defaultActivityValues)
    dispatch(changeEditedActivities([]))
    onClose()
  }

  function onUpdateTotal() {
    const valuesEntries = Object.entries(formActivityValues)
    for (let [key, activity] of valuesEntries) {
      const activityId = +key
      if (editedActivityIds.includes(activityId)) {
        dispatch(
          updateCarbonAccountingReportActivities({
            reportId,
            activityId,
            entryId: activity?.emissions_factor_entry?.id,
            activityUpdate: activity,
          })
        )
      }
    }
  }

  function onChangeFormStatus(status: ScopeCategoryStatus) {
    dispatch(updateCarbonAccountingReport({ reportId, body: { scope_categories: { [category?.key]: status } } }))
  }

  return (
    <Dialog data-testid="carbon-accounting-dialog" open={Boolean(category?.clickable)} onClose={onDiscardChanges}>
      <DialogTitle subtitle={category?.subtitle}>{category?.title}</DialogTitle>
      <DialogContent>
        <CarbonAccountingDialogForm
          category={category}
          formActivityValues={formActivityValues}
          isStale={editedActivityIds.length > 0 || loadingReports}
          onChange={onChange}
        />
      </DialogContent>
      <DialogActions>
        <Stack width="100%" pt={1} flexDirection="row" justifyContent="space-between" alignItems="center">
          <Stack>
            <Checkbox
              label="Mark form as complete"
              checked={category?.complete}
              onChange={() =>
                onChangeFormStatus({ complete: !category?.complete, not_applicable: category?.not_applicable })
              }
            />
            <Checkbox
              label="Mark form not applicable"
              checked={category?.not_applicable}
              onChange={() =>
                onChangeFormStatus({ complete: category?.complete, not_applicable: !category?.not_applicable })
              }
            />
          </Stack>
          {editedActivityIds.length === 0 && !loadingReports ? (
            <Button data-testid="carbon-accounting-dialog-button" onClick={onClose}>
              Done
            </Button>
          ) : (
            <Stack flexDirection="row" gap={1}>
              {!loadingReports && (
                <Button data-testid="carbon-accounting-dialog-discard-button" onClick={onDiscardChanges}>
                  Discard changes
                </Button>
              )}
              <Button
                data-testid="carbon-accounting-dialog-update-button"
                color="primary"
                onClick={onUpdateTotal}
                loading={loadingReports}
              >
                Update total
              </Button>
            </Stack>
          )}
        </Stack>
      </DialogActions>
    </Dialog>
  )
}
