import React, { ReactNode, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { EcoScoreImage, NutriScoreImage } from '@/components'
import { MetricEntry } from '@/contentfully'
import { selectContentfulMetricData } from '@/state/contentfulData'
import { selectDisplayedImpactData, selectSavedImpactData } from '@/state/recipe'
import { selectFavoriteTiles } from '@/state/preferences'
import {
  selectHasImpactsOverriddenNestedProduct,
  selectIsImpactsOverridden,
  selectIsScenarioContext,
  selectIsThirdParty,
} from '@/state/productOverview'
import { ImpactData } from '@/state/recipe/recipe.state'
import { selectProductNutrition } from '@/state/nutrition'
import { selectPageView, PUBLISHED, DRAFT, selectShowCarbonExtras } from '@/state/pageSettings'
import { CompareAgainst } from './CompareAgainst'
import { ecoScoreToLetter } from '@/utils/ecoscore'
import { useContentfulMetric } from '@/contentfully/hooks'
import { selectIsInitiative, selectIsProcurement, selectIsRollup } from '@/state/router'
import { getScoreRange } from '@/features/ProductPage/util/getScore'
import { CardData, CardCategories, getContentData, initialCardCategories } from '@/records/scoreCardData'
import { selectRollupScoreCardData } from '@/selectors/selectWorkspaceScoreCardData'
import { Field, PROCUREMENT_CARBON_FIELDS } from '@/constants/impactScore'

const generateHGImpactScoreCards = (cardData: CardData, changeSinceSaved: number, isForeign: boolean) => {
  const overviewCard = {
    ...cardData,
    subTitle: '',
    changeSinceSaved,
  }

  if (['howgood_total_impact', 'howgood_one_metric'].includes(cardData.key) && !isForeign) {
    const compareCard = {
      ...cardData,
      key: `${cardData.key}-compare-against`,
      label: 'Compare against Impact',
      value: <CompareAgainst metric={cardData.key} />,
    }
    return [{ ...overviewCard, color: true }, compareCard]
  }

  return [overviewCard]
}

interface CardInfo {
  key: string
  previousImpactData?: ImpactData
  savedImpactData?: ImpactData
  contentfulEntry: MetricEntry
  category?: string
  value: number
  thirdPartyProduct?: boolean
  ecoScoreImpact?: number
  categoryIndex?: number
}

// For the given metric, configure all of the data to be displayed on the score tile
const generateCardData = ({
  key,
  savedImpactData,
  contentfulEntry,
  category,
  value: numericValue,
  thirdPartyProduct,
  ecoScoreImpact,
  categoryIndex,
}: CardInfo) => {
  const savedValue: number = savedImpactData?.product?.[key]

  let displayedValue: ReactNode = null
  let score: number | string = null
  let scoreRange: string = null
  let size: 'sm' | 'md' | 'lg' = 'sm'
  if (numericValue !== null) {
    switch (key) {
      case 'retail_rating_impact':
        // This is now shown in the claims tile
        return []
      case 'eco_score_impact':
        displayedValue = <EcoScoreImage score={ecoScoreImpact} />
        score = ecoScoreToLetter(typeof ecoScoreImpact === 'string' ? +ecoScoreImpact : ecoScoreImpact)
        break
      case 'howgood_total_impact':
      case 'howgood_one_metric':
      case 'starbucks_impact':
        score = Math.round(numericValue)
        size = 'lg'
        break
      case 'raw_soc_impact':
        scoreRange = getScoreRange(numericValue, key)
        score = numericValue
        break
      default:
        score = category === 'overview' ? Math.round(numericValue) : numericValue
    }
  }

  const cardData: CardData = {
    key,
    label: contentfulEntry?.title || key,
    category,
    categoryIndex,
    subTitle: contentfulEntry?.subtitle,
    value: displayedValue,
    score,
    scoreRange,
    contentfulEntry,
    changeSinceSaved: numericValue && savedValue ? Math.round((numericValue - savedValue) * 100) / 100 : null,
    action: null,
    size,
  }

  if (category === 'overview') {
    const changeSinceSaved = numericValue && savedValue ? Math.round(numericValue) - Math.round(savedValue) : null
    return generateHGImpactScoreCards(cardData, changeSinceSaved, thirdPartyProduct)
  }

  return [cardData]
}

export function useScoreCardData(): CardCategories {
  const contentfulMetricData = useSelector(selectContentfulMetricData)
  const favoriteTiles = useSelector(selectFavoriteTiles)
  const impactData = useSelector(selectDisplayedImpactData)
  const thirdPartyProduct = useSelector(selectIsThirdParty)
  const impactsOverriddenProduct = useSelector(selectIsImpactsOverridden)
  const hasImpactsOverriddenNestedProduct = useSelector(selectHasImpactsOverriddenNestedProduct)
  const savedImpactData = useSelector(selectSavedImpactData)
  const nutrition = useSelector(selectProductNutrition)
  const pageView = useSelector(selectPageView)
  const nutritionContentfulEntry = useContentfulMetric('nutrition_score')
  const rollupScoreCardData = useSelector(selectRollupScoreCardData)
  const isRollup = useSelector(selectIsRollup)
  const isProcurement = useSelector(selectIsProcurement)
  const isInitiative = useSelector(selectIsInitiative)
  const scenarioContext = useSelector(selectIsScenarioContext)
  const showExtraCarbonContent = useSelector(selectShowCarbonExtras)
  const showInitiativeFields = isInitiative || scenarioContext

  return useMemo(() => {
    if (isRollup) {
      return rollupScoreCardData
    }
    if (
      !impactData?.ingredients.length &&
      !thirdPartyProduct &&
      pageView !== PUBLISHED &&
      !impactsOverriddenProduct &&
      !hasImpactsOverriddenNestedProduct
    ) {
      return initialCardCategories
    }

    const productImpactData = impactData.product
    // For each item in the product impact data, generate the data needed to render the scorecard
    // This includes the metric's category (tab) and its Contentful entry
    const scoreCardsByCategory = Object.entries(productImpactData).reduce((acc, [key, value]) => {
      const { category, contentfulEntry } = getContentData({
        key,
        contentfulMetricData,
        showProcurementCarbonContent: isProcurement,
        showAllFields: showInitiativeFields,
        showExtraCarbonContent,
      })

      // Don't show metrics with no data (null values) unless in edit mode and filter carbon boundaries for procurement
      if (
        (typeof value !== 'number' && pageView !== DRAFT) ||
        (category === 'carbon' && isProcurement && !PROCUREMENT_CARBON_FIELDS.includes(key as Field))
      ) {
        return acc
      }

      const cardsData = generateCardData({
        key,
        savedImpactData,
        contentfulEntry,
        category,
        value,
        thirdPartyProduct,
        ecoScoreImpact: impactData.product?.eco_score_impact,
        categoryIndex: acc[category].index,
      })
      const favCards = (cardsData as CardData[]).filter((card) => favoriteTiles.includes(card.key))

      const cards = {
        ...acc,
        [category]: {
          title: acc[category].title,
          data: [...acc[category].data, ...cardsData],
          index: acc[category].index,
        },
        favorites: {
          title: acc.favorites.title,
          data: [...acc.favorites.data, ...favCards],
          index: 0,
        },
      }

      return cards
    }, initialCardCategories)

    // impactsOverriddenProduct is higher priority than hasImpactsOverriddenNestedProduct
    // Only show the overridden carbon cradle to manufacturing tile
    if (!impactsOverriddenProduct && hasImpactsOverriddenNestedProduct) {
      scoreCardsByCategory.carbon.data = scoreCardsByCategory.carbon.data.filter(
        (card) => card.key === 'cf_ftm_gate_ct_verified_impact'
      )
      scoreCardsByCategory.favorites.data = scoreCardsByCategory.favorites.data.filter(
        (card) => card.key === 'cf_ftm_gate_ct_verified_impact' || card.category !== 'carbon'
      )
    }

    if (!nutrition?.nutrition_score) {
      return scoreCardsByCategory
    }

    // If product has nutrition data, add a card for that
    const nutriScoreCard = {
      key: 'nutrition_score',
      label: 'Nutri-Score',
      category: 'people',
      categoryIndex: 4,
      value: <NutriScoreImage score={nutrition.nutrition_score} />,
      contentfulEntry: nutritionContentfulEntry,
      score: nutrition.nutrition_score,
    }

    return {
      ...scoreCardsByCategory,
      people: {
        ...scoreCardsByCategory.people,
        data: [...scoreCardsByCategory.people.data, nutriScoreCard],
      },
      favorites: {
        ...scoreCardsByCategory.favorites,
        data: favoriteTiles.includes('nutrition_score')
          ? [...scoreCardsByCategory.favorites.data, nutriScoreCard]
          : scoreCardsByCategory.favorites.data,
      },
    }
  }, [
    impactData?.ingredients.length,
    impactData.product,
    thirdPartyProduct,
    pageView,
    nutrition?.nutrition_score,
    nutritionContentfulEntry,
    favoriteTiles,
    contentfulMetricData,
    savedImpactData,
    isRollup,
    rollupScoreCardData,
    impactsOverriddenProduct,
    hasImpactsOverriddenNestedProduct,
    showExtraCarbonContent,
    isProcurement,
    showInitiativeFields,
  ])
}

export const useScoreCardDataReady = () => {
  const scoreCardData = useScoreCardData()
  const sectionsWithData = Object.values(scoreCardData).filter(
    (dataSection) => dataSection?.title !== 'Other' && dataSection?.data?.length > 0
  )
  return Boolean(sectionsWithData?.length && sectionsWithData?.length > 0)
}
