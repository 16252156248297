import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getSelectedMetricData,
  selectSelectedMetric,
  selectSelectedTags,
  setSelectedTags,
} from '../state/portfolioDashboard'
import { TagAutocomplete } from './TagAutocomplete'

export const ProductsFilter: FC = () => {
  const selectedTags = useSelector(selectSelectedTags)
  const selectedMetric = useSelector(selectSelectedMetric)
  const dispatch = useDispatch()

  const handleChange = (inputs: string[]) => {
    dispatch(setSelectedTags(inputs))
    dispatch(getSelectedMetricData(selectedMetric))
  }

  return <TagAutocomplete label="Filter portfolio by tag" selectedTags={selectedTags} onChange={handleChange} />
}
