import React from 'react'
import { useSelector } from 'react-redux'
import { selectBasis, selectHideTagPrefix, selectPortfolioData } from '../state/portfolioDashboard'
import { GridColDef } from '@mui/x-data-grid-premium'
import { Stack, Text, Tooltip } from '@howgood/design'
import { formatNumber } from '@howgood/utils'
import { useUnits } from './useUnits'

export const useGridColumns = (): GridColDef[] => {
  const data = useSelector(selectPortfolioData)
  const units = useUnits()
  const basis = useSelector(selectBasis)
  const hideTagPrefix = useSelector(selectHideTagPrefix)

  const maxPercentage = data.reduce((acc, d) => (d.percentage > acc ? d.percentage : acc), 0) as number

  return [
    {
      field: 'name',
      headerName: '',
      width: 130,
      sortable: false,
      renderCell: (params) => (
        <Tooltip title={params.value} data-testid={`${params.value}-cell`}>
          <Text variant="body2">
            {hideTagPrefix && params.value.includes(':')
              ? params.value
                  .split(':')
                  .slice(1)
                  .join(': ')
              : params.value}
          </Text>
        </Tooltip>
      ),
    },
    {
      field: 'percentage',
      headerName: '',
      flex: 1,
      renderCell: (params) => (
        <Stack direction="row" alignItems="center" gap={0.5} width="100%">
          <Stack width={`${(params.value / maxPercentage) * 100}%`} height={20} bgcolor="primary.light" />
          {params.value && basis !== 'kg' ? `${formatNumber(params.value, 1)}%` : ''}
        </Stack>
      ),
      valueFormatter: (value: number) => `${value?.toFixed(4)}%`,
      sortable: false,
    },
    {
      field: 'value',
      headerName: 'Value',
      width: 84,
      renderHeader: () => (
        <Tooltip
          title={
            basis === 'kg'
              ? `Average impact per 1kg of product`
              : `Sum of ${units} multiplied by ${basis} volume for each product`
          }
        >
          <Text overflow="hidden" textOverflow="ellipsis" variant="caption">
            {units}
          </Text>
        </Tooltip>
      ),
      renderCell: (params) => (
        <Tooltip
          title={typeof params.value === 'number' ? (Math.round(params.value * 100) / 100).toLocaleString() : ''}
        >
          <Text overflow="hidden" textOverflow="ellipsis" variant="body2">
            {formatNumber(params.value)}
          </Text>
        </Tooltip>
      ),
      valueFormatter: (value: number) => value?.toFixed(4),
      sortable: false,
    },
    {
      field: 'fillRate',
      headerName: 'Fill rate',
      width: 84,
      renderHeader: () => (
        <Tooltip
          title={
            basis === 'kg'
              ? `Number of products with a non-null value for the selected metric`
              : `Number of products with a non-null value for the selected metric AND ${basis} volume specified`
          }
        >
          <Text overflow="hidden" textOverflow="ellipsis" variant="caption">
            Fill rate
          </Text>
        </Tooltip>
      ),
      sortable: false,
      renderCell: (params) => (
        <Tooltip title={`${params.value} products have the required data`}>
          <Text overflow="hidden" textOverflow="ellipsis" variant="body2">
            {params.value}
          </Text>
        </Tooltip>
      ),
    },
  ]
}
