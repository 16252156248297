import React, { FC } from 'react'
import { Alert, AlertTitle } from '@howgood/design'
import { useSelector } from 'react-redux'
import { selectHasImpactsOverriddenNestedProduct, selectIsImpactsOverridden } from '@/state/productOverview'

export const IMPACT_OVERRIDDEN_PRODUCT_DISCLAIMER_MESSAGE =
  'Disclaimer: The cradle to manufacturing gate value that is shown has been calculated by a third party LCA value. To show other metrics please reach out to support@howgood.com'
export const HAS_IMPACT_OVERRIDDEN_NESTED_PRODUCT_DISCLAIMER_MESSAGE =
  'This formula contains an ingredient that uses a cradle to manufacturing value from a third party LCA. In order to see other metrics on this formula please reach out to support@howgood.com'

export const ImpactsOverriddenProductDisclaimer: FC = () => {
  const impactsOverriddenProduct = useSelector(selectIsImpactsOverridden)
  const hasImpactsOverriddenNestedProduct = useSelector(selectHasImpactsOverriddenNestedProduct)

  if (!impactsOverriddenProduct && !hasImpactsOverriddenNestedProduct) {
    return null
  }

  return (
    <Alert severity="info" data-testid="impacts-overridden-product-disclaimer">
      <AlertTitle>
        {impactsOverriddenProduct
          ? IMPACT_OVERRIDDEN_PRODUCT_DISCLAIMER_MESSAGE
          : HAS_IMPACT_OVERRIDDEN_NESTED_PRODUCT_DISCLAIMER_MESSAGE}
      </AlertTitle>
    </Alert>
  )
}
