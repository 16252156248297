import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type View = 'Draft' | 'Saved' | 'Published'

export const SAVED = 'Saved' as View
export const DRAFT = 'Draft' as View
export const PUBLISHED = 'Published' as View

export type CompareTo = 'saved' | 'lastChange'
export type ScoreType = 'value' | 'displayedAvg' | 'displayedSum'

export interface State {
  view: View
  lastChange: string
  expanded: string[] // List of `dotPath` strings
  unsavedPromptDestination: string
  compareTo: CompareTo // 'saved' or 'lastChange'
  productInfoPanelExpanded: boolean
  activeProductInfoTab: number
  loadingOverlay: string
  reportToolkitOpen: boolean
  abatementStrategiesOpen: boolean
  generateReport: boolean
  scoreType: ScoreType
  showCarbonExtras: boolean
}
export const initialState: State = {
  view: SAVED,
  lastChange: '',
  expanded: [],
  unsavedPromptDestination: '',
  compareTo: 'saved' as CompareTo,
  productInfoPanelExpanded: true,
  activeProductInfoTab: 0,
  loadingOverlay: '',
  reportToolkitOpen: false,
  abatementStrategiesOpen: false,
  generateReport: false,
  scoreType: 'displayedSum',
  showCarbonExtras: false,
}

const pageSettingsSlice = createSlice({
  name: 'pageSettings',
  initialState,
  reducers: {
    setView: (state, action: PayloadAction<View>) => {
      state.view = action.payload
    },
    setLastChange: (state, action: PayloadAction<string>) => {
      state.lastChange = action.payload
    },
    setExpanded: (state, action: PayloadAction<string>) => {
      state.expanded = [...state.expanded, action.payload]
    },
    setCollapsed: (state, action: PayloadAction<string>) => {
      state.expanded = state.expanded.filter((item) => !item.startsWith(action.payload))
    },
    setExpandAll: (state, action: PayloadAction<string[]>) => {
      state.expanded = action.payload
    },
    setCollapseAll: (state) => {
      state.expanded = []
    },
    interceptUnsavedFormulaChanges: (state, action: PayloadAction<string>) => {
      state.unsavedPromptDestination = action.payload
    },
    closeUnsavedChangesPrompt: (state) => {
      state.unsavedPromptDestination = ''
    },
    setCompareTo: (state, action: PayloadAction<CompareTo>) => {
      state.compareTo = action.payload
    },
    setLoadingOverlay: (state, action: PayloadAction<string>) => {
      state.loadingOverlay = action.payload
    },
    setReportToolkitDrawer: (state, action: PayloadAction<boolean>) => {
      state.reportToolkitOpen = action.payload
    },
    setAbatementStrategiesDrawer: (state, action: PayloadAction<boolean>) => {
      state.abatementStrategiesOpen = action.payload
    },
    setGenerateReport: (state, action: PayloadAction<boolean>) => {
      state.generateReport = action.payload
    },
    setScoreType: (state, action: PayloadAction<ScoreType>) => {
      state.scoreType = action.payload
    },
    setShowCarbonExtras: (state, action: PayloadAction<boolean>) => {
      state.showCarbonExtras = action.payload
    },
  },
})

export const {
  setView,
  setLastChange,
  setExpanded,
  setCollapsed,
  setExpandAll,
  setCollapseAll,
  interceptUnsavedFormulaChanges,
  closeUnsavedChangesPrompt,
  setCompareTo,
  setLoadingOverlay,
  setReportToolkitDrawer,
  setAbatementStrategiesDrawer,
  setGenerateReport,
  setScoreType,
  setShowCarbonExtras,
} = pageSettingsSlice.actions

export const pageSettingsReducer = pageSettingsSlice.reducer
