import uniqWith from 'lodash/uniqWith'
import { fetchElasticV2, FormulationTag } from '@/api'
import { FORMULATION_TAGS_SEARCH_PATH } from '@/constants/config'
import { unwrapElasticResults } from './unwrapElasticResults'
import { ElasticsearchFormulationTagHit } from '@/records'
import { FormulationTagFilterOption } from '@/state/products'

export const getUniqueTags = (tags: FormulationTag[]) =>
  uniqWith(tags, (a, b) => a.name.toLowerCase() === b.name.toLowerCase())

interface FetchFormulationTagsParams {
  filters?: {
    search?: string
    ids?: string[] | number[]
    workspaceIds?: number[] | string[]
  }
}

export const fetchFormulationTagsByQuery = async ({ filters = {} }: FetchFormulationTagsParams) => {
  const { search, workspaceIds } = filters
  let hits: any[] = []
  const query: any = {
    bool: {
      filter: [
        {
          terms: {
            'workspaces.id': workspaceIds,
          },
        },
      ],
    },
  }

  if (search) {
    query.bool.must = [
      {
        dis_max: {
          queries: [
            search.includes(' ')
              ? { match_phrase: { name: search.toLowerCase() } }
              : { wildcard: { name: `*${search.toLowerCase()}*` } },
          ],
        },
      },
    ]
  }

  try {
    const results = await fetchElasticV2({
      url: FORMULATION_TAGS_SEARCH_PATH,
      body: {
        size: 40,
        query,
      },
    })
    hits = unwrapElasticResults(results)
  } catch (e) {
    console.error(e)
  }

  return hits
}

export const generateFormulationTagFilterOption = (hit: ElasticsearchFormulationTagHit) =>
  ({
    name: hit._source.name,
    id: hit._source.id,
    workspaces: hit._source.workspaces || [],
    label: hit._source.name,
    value: hit._source.id,
  } as FormulationTagFilterOption)

export const fetchFormulationTagsByIds = async ({ filters = {} }: FetchFormulationTagsParams) => {
  const { ids, workspaceIds } = filters
  let hits: any[] = []
  try {
    const results = await fetchElasticV2({
      url: FORMULATION_TAGS_SEARCH_PATH,
      body: {
        size: 40,
        query: {
          bool: {
            must: [{ terms: { id: ids } }],
            filter: [
              {
                terms: {
                  'workspaces.id': workspaceIds,
                },
              },
            ],
          },
        },
      },
    })
    hits = unwrapElasticResults(results)
  } catch (e) {
    console.error(e)
  }

  return hits.map((hit) => generateFormulationTagFilterOption(hit))
}
