import { createSlice } from '@reduxjs/toolkit'
import { formatProductImpactScore } from '@/records'
import { initialState } from './productMetrics.state'
import { getProducts, initializeProductsPage, updateProductScenarioMTperYr } from '../products'

const productMetricsSlice = createSlice({
  name: 'productMetrics',
  initialState,
  reducers: {
    resetProductMetricsState: (state) => {
      state.isLoading = initialState.isLoading
      state.productMetrics = initialState.productMetrics
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProducts.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getProducts.fulfilled, (state, action) => {
      state.isLoading = false
      if (action.payload.rollups) {
        const { metrics } = action.payload.rollups
        state.productMetrics = formatProductImpactScore(metrics)
      }
    })
    builder.addCase(getProducts.rejected, (state) => {
      state.isLoading = false
      state.productMetrics = initialState.productMetrics
    })

    builder.addCase(initializeProductsPage.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(initializeProductsPage.fulfilled, (state) => {
      state.isLoading = false
    })
    builder.addCase(initializeProductsPage.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(updateProductScenarioMTperYr.fulfilled, (state, action) => {
      state.productMetrics = action.payload.updatedRollupData
    })
  },
})

export const { resetProductMetricsState } = productMetricsSlice.actions

export const productMetricsReducer = productMetricsSlice.reducer
