import { theme } from '@howgood/design'

interface GetFill {
  isCurrentLocation: boolean
  isLocationAvailable: boolean
  impactScore: number
}
export function getFill({ isCurrentLocation, isLocationAvailable, impactScore }: GetFill): string {
  switch (true) {
    case typeof impactScore === 'number':
      return theme.palette.impact[impactScore]
    case isCurrentLocation:
      return theme.palette.primary.dark
    case isLocationAvailable:
      return theme.palette.success.light
    default:
      return theme.palette.impact[0]
  }
}
