import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { faArrowLeftLongToLine } from '@fortawesome/pro-regular-svg-icons'
import { MenuItem, ListItemIcon, ListItemText } from '@howgood/design'

import { useNavigateToFormula } from '@/hooks/useNavigate'
import { selectSavedProductInfo, selectIsScenarioContext } from '@/state/productOverview'
import { usePendoTrack } from '@/hooks'
import { selectCurrentScenarioId } from '@/state/initiatives'

export const ViewSource: FC<{ onClick: () => void }> = ({ onClick }) => {
  const pendoTrack = usePendoTrack()
  const showScenarioOptions = useSelector(selectIsScenarioContext)
  const scenarioId = useSelector(selectCurrentScenarioId)
  const product = useSelector(selectSavedProductInfo)
  const navigateToFormula = useNavigateToFormula()

  if (!showScenarioOptions) {
    return null
  }

  const handleViewSource = () => {
    pendoTrack('Formula: View source formula', { scenarioId, product })
    onClick()
    const sourceId = product.formulation_status === 'scenario' ? product.source_product?.id : product.id
    navigateToFormula(sourceId ? `${sourceId}` : null)
  }

  return (
    <MenuItem data-testid="view-source" onClick={handleViewSource}>
      <ListItemIcon icon={faArrowLeftLongToLine} />
      <ListItemText>View source formula</ListItemText>
    </MenuItem>
  )
}
