import { createSelector } from 'reselect'
import {
  FormulationStatus,
  getWorkspaceTypes,
  IngredientFilterSearchOption,
  IProduct,
  MATERIAL_DIRECTORY,
  PageType,
  UnknownVendorOptionValue,
  Workspace,
  WorkspaceOption,
  WorkspaceType,
  workspaceTypeLabelMap,
} from '@/records'
import { AppState } from '@/store'
import { convertLockedScoresToNumbers } from '@/utils/convertLockedScoresToNumbers'
import { ActiveBulkJob, ProductFilters, ProductSortOption } from './products.state'
import { selectWorkspacesWithScores } from '@/state/workspaces/workspaces.selectors'
import { selectIsVendors, selectIsInitiative, selectIsPortfolio, selectIsProcurement } from '../router'
import { createIWorkspaceOption } from '@/utils/workspace'

export const selectProductsIsLoading = (state: AppState): boolean => state.products.isLoading
export const selectProductsHasError = (state: AppState) => state.products.hasError
export const selectTableProducts = (state: AppState): IProduct[] => state.products.tableProducts
export const selectAllProducts = (state: AppState) => state.products.allProducts
export const selectProductsTotal = (state: AppState): number => state.products.total
export const selectShowLiveImpactData = (state: AppState): boolean => state.products.showLiveImpactData
export const selectShowAtRiskOnly = (state: AppState): boolean => state.products.showAtRiskOnly
export const selectShowMaterialDirectory = (state: AppState): boolean =>
  !!state.products.productFilters.workspaceTypes.find((wType) => wType === MATERIAL_DIRECTORY)
export const selectActiveBulkJob = (state: AppState): ActiveBulkJob => state.products.activeBulkJob
const selectProductFilters = (state: AppState): ProductFilters => state.products.productFilters
export const selectCustomAggs = (state: AppState): Record<string, number> => state.products.customAggs

export const selectProductHitById = (productId: number) =>
  createSelector(selectTableProducts, (products) => products.find((prod) => prod.id === productId))

export const selectEditingFilter = createSelector(
  selectProductFilters,
  (productFilters) => productFilters.editingFilter
)

// In product lists we can show products with live scores or locked scores
export const selectProductsWithSelectedImpactData = createSelector(
  selectTableProducts,
  selectShowLiveImpactData,
  (products, showLiveData) => {
    if (showLiveData) {
      return products
    }
    return products.map((product) => {
      const lockedScores = product.locked_claims?.impact_score
        ? // Most of the `locked_claims` scores come in as strings, so convert to numbers
          convertLockedScoresToNumbers(product.locked_claims.impact_score)
        : null
      return {
        ...product,
        impact_score: lockedScores || product.impact_score,
      }
    })
  }
)

export const selectFormulaQuery = createSelector(selectProductFilters, (productFilters) => productFilters.query)

export const selectIngredientFilters = createSelector(
  selectProductFilters,
  (productFilters) => productFilters.ingredients
)

export const selectStatusFilters = createSelector(selectProductFilters, (productFilters) => productFilters.statuses)

export const selectDataGranularityFilters = createSelector(
  selectProductFilters,
  (productFilters) => productFilters.dataGranulars
)

export const selectTagFilters = createSelector(selectProductFilters, (productFilters) => productFilters.formulationTags)

export const selectComponentFilters = createSelector(
  selectProductFilters,
  (productFilters) => productFilters.components
)

export const selectVendorFilters = createSelector(selectProductFilters, (productFilters) => productFilters.vendors)

export const selectProductsSortOptions = createSelector(
  selectProductFilters,
  (productFilters) => productFilters.sortOptions
)

export const selectProductsPage = createSelector(selectProductFilters, (productFilters) => productFilters.page)

export const selectWorkspaceTypeFilters = createSelector(
  selectProductFilters,
  (productFilters) => productFilters.workspaceTypes
)

export interface WorkspaceTypeOption {
  value: WorkspaceType
  label: string
}
export const selectWorkspaceTypeFilterOptions = createSelector(selectWorkspaceTypeFilters, (workspaceTypes) =>
  workspaceTypes.map((ws) => ({ label: workspaceTypeLabelMap[ws], value: ws } as WorkspaceTypeOption))
)

export const selectWorkspaceProductFilters = createSelector(
  selectProductFilters,
  (productFilters) => productFilters.workspaces
)

export const selectWorkspaceProductFilterIds = createSelector(selectWorkspaceProductFilters, (workspaces) =>
  workspaces.map((ws) => ws.id)
)

export const selectStandardsFilters = createSelector(
  selectProductFilters,
  (productFilters) => productFilters.standardsIds
)

export const selectRegionsFilters = createSelector(selectProductFilters, (productFilters) => productFilters.regions)

export const selectscenarioFilter = createSelector(selectProductFilters, (productFilters) => productFilters.scenario)

// Filter all workspaces that have products with at least one non null impact score
// by workspace type (as set by the workspace type filter AND page context)
export const selectWorkspacesFilteredByType = createSelector<
  AppState,
  Workspace[],
  WorkspaceType[],
  boolean,
  boolean,
  boolean,
  boolean,
  Workspace[]
>(
  selectWorkspacesWithScores,
  selectWorkspaceTypeFilters,
  selectIsProcurement,
  selectIsPortfolio,
  selectIsInitiative,
  selectIsVendors,
  (workspaces, types, isProcurement, isPortfolio, isInitiative, isAggregatedVendors) => {
    let pageType: PageType
    if (isProcurement) pageType = 'Procurement'
    else if (isInitiative) pageType = 'Initiative'
    else if (isPortfolio) pageType = 'Portfolio'
    else if (isAggregatedVendors) pageType = 'Vendor Management'
    // If new procurement is enabled, don't include procurement workspaces in All Formulas; if not, include them
    else pageType = 'Formulation'

    const allowedTypes = getWorkspaceTypes(pageType)
    const filteredTypes = types.length ? types.filter((type) => allowedTypes.includes(type)) : allowedTypes

    return filteredTypes.length ? workspaces?.filter((ws) => filteredTypes.includes(ws.workspace_type)) : workspaces
  }
)

/**
 * Returns a list of workspace options
 */
export const selectWorkspacesOptionsForFilter = createSelector<AppState, Workspace[], WorkspaceOption[]>(
  selectWorkspacesFilteredByType,
  (workspaces) => {
    return workspaces.map((w: Workspace) => createIWorkspaceOption(w))
  }
)

export interface ConstructedFilters {
  filters: {
    statuses: FormulationStatus[]
    query: string[]
    ingredientIds: string[]
    workspaceIds: number[]
    ingredient: IngredientFilterSearchOption[]
    sortOptions: ProductSortOption[]
    salesCategories: string[]
    components: number[]
    vendors: (number | UnknownVendorOptionValue)[]
    formulationTags: string[]
    scenario: number
  }
  page: number
}
// Conversion of the redux product filters into the format we need
// to pass into the getProdicts thunk AND the product list component
export const selectConstructedFilters = createSelector<
  AppState,
  Workspace[],
  WorkspaceOption[],
  WorkspaceType[],
  ProductFilters,
  ConstructedFilters
>(
  selectWorkspacesFilteredByType,
  selectWorkspaceProductFilters,
  selectWorkspaceTypeFilters,
  selectProductFilters,
  (workspacesFilteredByType, workspaceFilters, workspaceTypes, productFilters) => {
    const { statuses, ingredients, formulationTags, components, vendors, regions, dataGranulars, page } = productFilters

    // workspaces are filtered by both type and workspace product filters
    const workspaces = workspaceFilters.length
      ? [...workspaceFilters].filter((ws) => !workspaceTypes.length || workspaceTypes.includes(ws.workspace_type))
      : workspacesFilteredByType

    let statusValues = statuses.map((val: any) => val.value)

    const filters = {
      ...productFilters,
      statuses: statusValues.length ? statusValues : [],
      workspaceIds: workspaces.map((ws) => ws.id),
      ingredient: ingredients,
      components: components.map((item) => item.value),
      vendors: vendors.map((item) => item.value),
      formulationTags: formulationTags.map((formulationTag) => formulationTag.name),
      regions: regions.map((region) => region.id),
      dataGranulars: dataGranulars.map((dataGranular) => dataGranular.value),
    }

    return { filters, page }
  }
)
