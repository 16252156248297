import React, { FC } from 'react'
import { Stack, Text } from '@howgood/design'
import { formatNumber } from '@howgood/utils'
import { ImpactScore } from '@/api'
import { MetricEntry } from '@/contentfully'
import { getNumericScore, getScoreRange } from '@/features/ProductPage/util/getScore'
import { OriginLocationMapping } from '@/state/originLocations'
import { transformScoreToZeroToTenScale } from '@/utils/impactScoreHelpers'
import { Country, Geo } from '../Country'
import { getFill } from './ChangeSourcingLocationCountry.utils'

interface Props {
  geo: Geo
  originLocations: OriginLocationMapping[]
  currentLocationId: string
  metric: MetricEntry
  impactScores: ImpactScore[]
}

export const ChangeSourcingLocationCountry: FC<Props> = ({
  geo,
  originLocations,
  currentLocationId,
  metric,
  impactScores,
}) => {
  const locationIndex = originLocations.findIndex((ol) => ol.iso3_country_code === geo.id)
  const isLocationAvailable = locationIndex !== -1
  const impactScore = impactScores[locationIndex] ? impactScores[locationIndex][metric.key] : null
  const numericScore = getNumericScore(impactScore)
  const scoreRange = getScoreRange(impactScore, metric.key)
  const scoreOneToTen = transformScoreToZeroToTenScale(metric.key, numericScore)
  const scoreRounded = formatNumber(numericScore)
  const score = scoreRange || scoreRounded
  const isCurrentLocation = geo.id === currentLocationId
  const fill = getFill({ isCurrentLocation, isLocationAvailable, impactScore: scoreOneToTen })
  const tooltipLocationText = isCurrentLocation
    ? 'Current sourcing location:'
    : isLocationAvailable
    ? 'Available sourcing location:'
    : null
  const tooltipScoreText = metric.valuesMap?.[scoreOneToTen]
    ? `${metric.valuesMap[scoreOneToTen]} ${metric.units || ''}`
    : score
    ? `${score} ${metric.units || ''}`
    : null

  return (
    <Country
      data-testid="sourcing-location-country"
      geo={geo}
      fill={fill}
      tooltipContent={
        <Stack data-testid="sourcing-location-country-tooltip">
          <Text variant="body2">
            {tooltipLocationText} <b>{geo.properties.name}</b>
          </Text>
          {tooltipScoreText && (
            <Text variant="body2">
              {metric.title}: <b>{tooltipScoreText}</b>
            </Text>
          )}
        </Stack>
      }
    />
  )
}
