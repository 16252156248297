import React, { FC, forwardRef } from 'react'
import MuiAccordion, { AccordionProps as MuiAccordionProps } from '@mui/material/Accordion'
import MuiAccordionDetails, { AccordionDetailsProps as MuiAccordionDetailsProps } from '@mui/material/AccordionDetails'
import MuiAccordionSummary, { AccordionSummaryProps as MuiAccordionSummaryProps } from '@mui/material/AccordionSummary'
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons'
import { Icon } from './Icon'

interface AccordionDetailsProps extends Pick<MuiAccordionDetailsProps, 'children'> {}

export const AccordionDetails: FC<AccordionDetailsProps> = ({ children }) => {
  return <MuiAccordionDetails>{children}</MuiAccordionDetails>
}

interface AccordionSummaryProps extends Pick<MuiAccordionSummaryProps, 'id' | 'children'> {}

export const AccordionSummary: FC<AccordionSummaryProps> = ({ id, children }) => {
  return (
    <MuiAccordionSummary id={id} expandIcon={<Icon icon={faChevronDown} />}>
      {children}
    </MuiAccordionSummary>
  )
}

interface AccordionProps
  extends Pick<MuiAccordionProps, 'id' | 'expanded' | 'defaultExpanded' | 'onChange' | 'children' | 'disabled' | 'sx'> {
  type?: 'primary' | 'secondary'
  ['data-testid']?: string
}

const disabledStyles = {
  border: '1px dashed rgba(0, 0, 0, 0.12)',
  boxShadow: 'none',
  '&.Mui-disabled': { backgroundColor: 'transparent' },
  '& .MuiAccordionSummary-expandIconWrapper': { display: 'none' },
}

export const Accordion = forwardRef<HTMLDivElement, AccordionProps>(
  (
    {
      ['data-testid']: dataTestId,
      id,
      expanded,
      defaultExpanded,
      disabled,
      onChange,
      type = 'primary',
      children,
      ...restProps
    },
    ref
  ) => {
    const isSecondary = type === 'secondary'

    const rootSx = isSecondary
      ? {
          boxShadow: 'none',
          border: 'none',
          borderRadius: 0,
          px: 0,
          py: 2,
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          '&:last-of-type': {
            borderBottom: 'none',
          },
          '&:first-of-type, &.MuiAccordion-gutters': {
            mt: 0,
          },
          '&.Mui-expanded': {
            px: 0,
            py: 2,
          },
          '& >.MuiAccordionSummary-root': {
            padding: 0,
            '&.Mui-expanded': {
              padding: 0,
            },
          },
        }
      : {
          '&.MuiAccordion-rounded': {
            mb: 3,
            '&:last-of-type': {
              mb: 0,
            },
          },
        }

    return (
      <MuiAccordion
        ref={ref}
        data-testid={dataTestId}
        id={id}
        expanded={expanded}
        disabled={disabled}
        defaultExpanded={defaultExpanded}
        onChange={onChange}
        square={isSecondary}
        sx={disabled ? { ...rootSx, ...disabledStyles } : rootSx}
        {...restProps}
      >
        {children}
      </MuiAccordion>
    )
  }
)

Accordion.displayName = 'Accordion'
