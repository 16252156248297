import React, { FC } from 'react'
import { Stack, Text, Tooltip } from '@howgood/design'
import { useSelector } from 'react-redux'
import { selectBasis, selectSelectedMetric } from '../state/productDashboard'
import { useContentfulMetric } from '@/contentfully/hooks'

interface ImpactCellProps {
  value: {
    score: string
    id: number
  }
}

export const ImpactCell: FC<ImpactCellProps> = ({ value: { score, id } }) => {
  const selectedMetric = useSelector(selectSelectedMetric)
  const basis = useSelector(selectBasis)

  const entry = useContentfulMetric(selectedMetric)

  // The Contentful entries assume per kg, so we need to adjust the units for inventories and sales
  let units = entry?.units || '\u00A0' // Use non-breaking space if units isn't defined
  if (basis === 'inventories') {
    units = units.replace('kg', 'mt').replace('/kg', '/yr')
  }
  if (basis === 'sales') {
    units = units.replace('/kg', '/yr')
  }

  return (
    <Stack data-testid={`impact-cell-${id}`} width="100%" sx={{ pointerEvents: 'none' }}>
      <Tooltip title={`${score} ${units}`}>
        <Stack alignItems="end" sx={{ '& .MuiTypography-root': { pointerEvents: 'auto', cursor: 'pointer' } }}>
          <Text fontWeight="bold">{score}</Text>
          <Text variant="caption">{units.replace(/\(.*?\)/, '')}</Text> {/* Remove anything in parentheses */}
        </Stack>
      </Tooltip>
    </Stack>
  )
}
