import React, { FC } from 'react'
import LogoImg from '../../assets/images/nav/howgood_logo_transparent.png'

interface Props {
  className?: string
}

const imageProps = {
  alt: 'HowGood logo',
  src: LogoImg,
  height: 48,
  width: 68,
}

export const Logo: FC<Props> = (props) => (
  <a href="https://howgood.com/" data-pendo-id="Menu:HowGoodLogo" rel="noopener noreferrer" target="_blank" {...props}>
    <img alt="HowGood" {...imageProps} />
  </a>
)
