import { fetchAllV2, fetchHelperV2, SalesCategory } from '@/api'
import { Field } from '@/constants/impactScore'

type Benchmark = { [K in Field]: number }
export type BenchmarkData = {
  [K in string]: Benchmark
}

export const fetchAllBenchmarkData = async (): Promise<BenchmarkData> =>
  await fetchHelperV2({
    url: 'products/benchmarks/',
  })

export const fetchAllSalesCategories = async (): Promise<SalesCategory[]> =>
  await fetchAllV2<SalesCategory>({ url: 'products/sales-categories/' })
