import React, { FC, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MenuItem, ListItemIcon, ListItemText } from '@howgood/design'
import { faFileChartColumn } from '@fortawesome/pro-regular-svg-icons'
import { selectDisplayedProductName } from '@/state/productOverview'
import { selectExportPaywallSplit } from '@/state/splitio'
import { useProductDetailRows, useProductScoreRows } from './useExportRows'
import { usePendoTrack } from '@/hooks'
import { setLiteUserPaywallDialogState } from '@/state/liteUserPaywallDialog'
import { selectIsSupplierConnect } from '@/state/organization'

export const ExportProductMetrics: FC = () => {
  const pendoTrack = usePendoTrack()
  const exportPaywallEnabled = useSelector(selectExportPaywallSplit)
  const productName = useSelector(selectDisplayedProductName)
  const isSupplierConnect = useSelector(selectIsSupplierConnect)
  const dispatch = useDispatch()

  const productDetailRows = useProductDetailRows()
  const productScoreRows = useProductScoreRows()

  const csvContent = useMemo(() => {
    const exportData = [...productDetailRows, [], [], ...productScoreRows, [], ['Report Generated'], [new Date()]]
    return exportData.map((row) => row.join(',') + '\n').join('')
  }, [productDetailRows, productScoreRows])

  const blob = useMemo(() => new Blob([csvContent], { type: 'text/csv;charset=utf-8,' }), [csvContent])
  const objUrl = useMemo(() => {
    return URL.createObjectURL(blob)
  }, [blob])

  const onExport = () => {
    if (isSupplierConnect) {
      dispatch(
        setLiteUserPaywallDialogState({
          open: true,
        })
      )
      return
    }
    pendoTrack('Formula: Export product metrics')
  }

  return (
    <MenuItem
      disabled={exportPaywallEnabled}
      id="export-option"
      data-testid="export-option"
      {...(exportPaywallEnabled ? { 'data-pendo-id': 'exportpaywall' } : {})}
    >
      <ListItemIcon icon={faFileChartColumn} />
      <ListItemText>
        <a
          href={isSupplierConnect ? null : objUrl}
          download={`${productName} Metrics`}
          onClick={onExport}
          style={{ color: 'inherit', textDecoration: 'none' }}
        >
          Export product metrics
        </a>
      </ListItemText>
    </MenuItem>
  )
}
