import { ImpactScore } from '@/api'
import { convertLockedScoresToNumbers } from '@/utils/convertLockedScoresToNumbers'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ProductInfo } from '../productOverview/productOverview.state'
import {
  RecipeIngredient,
  RecipeNestedIngredient,
  RecipePackagingItem,
  initialState,
  Recipe,
  ImpactData,
} from './recipe.state'
import { getRecipe, updateRecipeAndScores } from './recipe.thunk'
import { generateProductRecipeFields, getNonNullFields } from './recipe.utils'

const recipeSlice = createSlice({
  name: 'recipe',
  initialState,
  reducers: {
    clearRecipeReducer: (state) => {
      Object.keys(initialState).forEach((field) => {
        state[field] = initialState[field]
      })
    },
    setIsRebalancing: (state, action: PayloadAction<boolean>) => {
      state.isRebalancing = action.payload
    },
    initializeRecipe: (
      state,
      action: PayloadAction<{
        recipe: Recipe
        impactData: ImpactData
        change: string
      }>
    ) => {
      const { recipe, impactData, change } = action.payload
      state.recipes = [recipe]
      state.impactData = [impactData]
      state.changeLog = [change]
    },
    setLockedImpactData: (state, action: PayloadAction<{ impactScore: ImpactScore; time: string }>) => {
      // Most of the scores come in as strings, so convert to numbers
      // TODO: Can probably remove this now because we use the `/locked-claims` response which returns numbers
      // It's the `/save-report` response, which we no longer use, that returns strings
      const impact = convertLockedScoresToNumbers(action.payload.impactScore)
      const lockedClaimsData: ImpactData = {
        product: impact,
        ingredients: [],
        weights: [],
        maxValues: {},
        nonNullFields: getNonNullFields({ product: impact }),
        timestamp: action.payload.time,
      }
      state.lockedImpactData = lockedClaimsData
    },
    resetRecipe: (state) => {
      state.recipes = [state.recipes[0]]
      state.impactData = [state.impactData[0]]
      state.changeLog = ['Product reset']
      state.ingredientsMoved = false
    },
    saveProductIngredientError: (state, action: PayloadAction<(RecipeIngredient | RecipeNestedIngredient)[]>) => {
      state.recipes[state.recipes.length - 1].ingredients = action.payload
    },
    savePackagingToRecipe: (state, action: PayloadAction<RecipePackagingItem[]>) => {
      state.recipes[state.recipes.length - 1].packaging = action.payload
    },
    saveProductError: (state, action: PayloadAction<ProductInfo>) => {
      const product = action.payload
      const productRecipe = generateProductRecipeFields(product)
      Object.keys(productRecipe).forEach((field: string) => {
        state.recipes[state.recipes.length - 1][field] = productRecipe[field]
      })
    },
    saveRecipeToProduct: (state) => {
      state.recipes = [state.recipes[state.recipes.length - 1]]
      state.impactData = [state.impactData[state.impactData.length - 1]]
      state.changeLog = ['Product saved']
    },
    restoreSnapshot: (state, action: PayloadAction<number>) => {
      state.recipes = [...state.recipes, state.recipes[action.payload]]
      state.impactData = [...state.impactData, state.impactData[action.payload]]
      state.changeLog = [...state.changeLog, state.changeLog[action.payload]]
    },
    addRecipe: (state, action: PayloadAction<{ recipe: Recipe; change: string }>) => {
      state.recipes = [...state.recipes, action.payload.recipe]
      state.changeLog = [...state.changeLog, action.payload.change]
    },
    setIsAutoWeightField: (state, action: PayloadAction<{ dotPath: string; value: boolean }>) => {
      const { dotPath, value } = action.payload
      const currentRecipe = state.recipes.slice(-1)[0]
      const ingredient = currentRecipe.ingredients.find((item) => item.dot_path === dotPath)
      ingredient.isAutoWeight = value
    },
    setWeightFieldsToManual: (state) => {
      const currentRecipe = state.recipes.slice(-1)[0]
      currentRecipe.ingredients.forEach((ingredient) => (ingredient.isAutoWeight = false))
    },
    setIngredientsMoved: (state, action: PayloadAction<boolean>) => {
      state.ingredientsMoved = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRecipe.pending, (state) => {
      state.isLoading = true
      state.error = ''
    })
    builder.addCase(getRecipe.fulfilled, (state) => {
      state.isLoading = false
      state.error = ''
    })
    builder.addCase(getRecipe.rejected, (state, action) => {
      console.error(action.error)
      state.isLoading = false
      state.error = action?.error?.message ?? 'There was an error loading this formula recipe.'
    })
    builder.addCase(updateRecipeAndScores.pending, (state) => {
      state.isLoading = true
      state.error = ''
    })
    builder.addCase(updateRecipeAndScores.fulfilled, (state, action) => {
      const { impactData } = action.payload
      state.isLoading = false
      state.error = ''
      state.impactData = [...state.impactData, impactData]
    })
    builder.addCase(updateRecipeAndScores.rejected, (state, action) => {
      console.error(action.error)
      state.isLoading = false
      // remove the last recipe and change update
      state.recipes = state.recipes.slice(0, -1)
      state.changeLog = state.changeLog.slice(0, -1)
    })
  },
})

export const {
  clearRecipeReducer,
  setIsRebalancing,
  initializeRecipe,
  resetRecipe,
  saveRecipeToProduct,
  saveProductIngredientError,
  savePackagingToRecipe,
  saveProductError,
  restoreSnapshot,
  addRecipe,
  setIsAutoWeightField,
  setWeightFieldsToManual,
  setIngredientsMoved,
  setLockedImpactData,
} = recipeSlice.actions

export const recipeReducer = recipeSlice.reducer
