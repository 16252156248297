import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { DataGridPremium, GridRowsProp, GridColDef } from '@mui/x-data-grid-premium'
import { Accordion, AccordionDetails, Box, Tabs, Tab, Stack, Tooltip, IconButton, Text } from '@howgood/design'
import { AccordionCardHeader } from '@/components/Scores/AccordionCardHeader'
import { TabPanel } from '../ProductPage/panels/TabPanel'
import { selectCurrentInitiative, selectInitiatives, selectSortedScenarios } from '@/state/initiatives'
import { fetchProducts } from '@/api/elastic/productsEsRepo'
import { ESProduct } from '@/state/products'
import { formatNumber } from '@howgood/utils'
import { Workspace } from '@/records'
import { faArrowsRotate } from '@fortawesome/pro-regular-svg-icons'
import { Scenario } from '@/records/Initiatives'

export const DebugPanel: FC = () => {
  const currentInitiative = useSelector(selectCurrentInitiative)
  const scenarios = useSelector(selectSortedScenarios)
  const initiatives = useSelector(selectInitiatives)

  const [products, setProducts] = useState<ESProduct[]>([])

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      renderCell: (params) => (
        <Link to={`/products/${params.id}`} target="_blank" rel="noopener noreferrer">
          <Stack sx={{ '& .MuiTypography-root': { textDecoration: 'underline' } }}>
            <Text variant="body2">{params.id}</Text>
          </Stack>
        </Link>
      ),
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 180,
    },
    {
      field: 'status',
      headerName: 'Status',
    },
    {
      field: 'workspaces',
      headerName: 'Workspaces',
    },
    {
      field: 'scenarios',
      headerName: 'Scenarios',
      renderCell: (params) => {
        const fullScenarios: Scenario[] = params.value.map((scenario: Scenario) =>
          scenarios.find((s) => s.id === scenario.id)
        )
        return (
          <Stack direction="row" gap={1}>
            {fullScenarios.map((scenario) => {
              if (!scenario) return null
              return (
                <Tooltip
                  key={scenario.id}
                  title={
                    <Stack>
                      <Text>Scenario: {`${scenario.name} (${scenario.id})`}</Text>
                      <Text>
                        Initiative:{' '}
                        {`${initiatives.find((initiative) => initiative.id === scenario.initiative.id)?.name} (${
                          scenario.initiative.id
                        })`}
                      </Text>
                      <Text>Target date: {scenario.target_date.split('-').slice(0)[0]}</Text>
                    </Stack>
                  }
                >
                  <Stack sx={{ cursor: 'pointer', '& .MuiTypography-root': { textDecoration: 'underline' } }}>
                    <Text key={scenario.id} variant="body2">
                      {scenario.id}
                    </Text>
                  </Stack>
                </Tooltip>
              )
            })}
          </Stack>
        )
      },
    },
    {
      field: 'howgood_one_metric',
      headerName: 'HowGood Impact Score',
      width: 140,
    },
    {
      field: 'cf_ftm_gate_ct_verified_impact',
      headerName: 'Cradle-to-manufacturing-gate',
      width: 140,
    },
    {
      field: 'blue_water_usage',
      headerName: 'Blue Water Usage',
      width: 140,
    },
  ]

  const rows: GridRowsProp = products.map((product) => {
    return {
      id: product.pk,
      name: product.name,
      status: product.formulation_status === 'scenario' ? 'Clone' : 'Source',
      workspaces: product.workspaces.map((workspace) => workspace.name).join(', '),
      scenarios: product.scenarios || [],
      howgood_one_metric: formatNumber(product.impact_score?.howgood_one_metric, 1),
      cf_ftm_gate_ct_verified_impact: formatNumber(product.impact_score?.cf_ftm_gate_ct_verified_impact),
      blue_water_usage: formatNumber(product.impact_score?.raw_blue_water_usage_impact),
    }
  })

  const [tabValue, setTabValue] = useState(0)

  const getESProducts = async (workspaces: Workspace[]) => {
    try {
      const { hits } = await fetchProducts({
        filters: {
          workspaceIds: workspaces.map((workspace) => workspace.id),
          statuses: ['scenario', 'pipeline'],
        },
        _source: ['id', 'pk', 'name', 'workspaces', 'formulation_status', 'impact_score', 'scenarios'],
        size: 1000,
      })
      setProducts(hits)
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Error fetching products', e)
    }
  }

  const handleAccordionChange = (_event: React.SyntheticEvent, expanded: boolean) => {
    if (expanded && currentInitiative) {
      getESProducts(currentInitiative.workspaces)
    }
  }

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  const handleRefreshClicked = () => {
    if (currentInitiative) {
      getESProducts(currentInitiative.workspaces)
    }
  }

  return (
    <Stack mt={2}>
      <Accordion defaultExpanded={false} onChange={handleAccordionChange}>
        <AccordionCardHeader id="debug-panel-header" title="Debug Panel" />
        <AccordionDetails>
          <Stack direction="row" my={1} borderBottom={1} borderColor="divider" alignItems="center" gap={1}>
            <Tabs value={tabValue} onChange={handleTabChange} aria-label="Product information tabs">
              <Tab label="Initiative Products" />
            </Tabs>
            <Tooltip title="Refresh product list from Elasticsearch">
              <IconButton
                id="refresh-list-button"
                data-testid="refresh-list-button"
                aria-label="Refresh product list"
                onClick={handleRefreshClicked}
                icon={faArrowsRotate}
              />
            </Tooltip>
          </Stack>
          <TabPanel value={tabValue} index={0}>
            <Box
              height={500}
              width="100%"
              sx={{
                '& .MuiDataGrid-columnHeaderTitle': {
                  fontWeight: 600,
                },
                '& .MuiDataGrid-columnHeaderTitleContainerContent': {
                  width: '100%',
                },
              }}
            >
              <DataGridPremium
                rows={rows}
                columns={columns}
                disableRowSelectionOnClick
                disableColumnMenu
                disableColumnFilter
                initialState={{
                  density: 'compact',
                  sorting: {
                    sortModel: [
                      { field: 'name', sort: 'asc' },
                      { field: 'status', sort: 'desc' },
                    ],
                  },
                }}
              />
            </Box>
          </TabPanel>
        </AccordionDetails>
      </Accordion>
    </Stack>
  )
}
