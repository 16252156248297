import React, { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Autocomplete, Text, TextField } from '@howgood/design'
import { searchRegions } from '@/api'
import { selectDisplayedPackaging, updateRecipeAndScores } from '@/state/recipe'
import { RecipePackagingItem } from '@/state/recipe/recipe.state'
import { DRAFT, selectPageView } from '@/state/pageSettings'

interface Location {
  id: number
  name: string
}

interface Props {
  packagingItem: RecipePackagingItem
  setTempItem?: (item: RecipePackagingItem) => void
  index: number
}

export const PackagingRegion: FC<Props> = ({ packagingItem, setTempItem, index }) => {
  const dispatch = useDispatch()
  const packaging = useSelector(selectDisplayedPackaging)
  const view = useSelector(selectPageView)
  const [locations, setLocations] = useState<Location[]>([])
  const [selectedLocation, setSelectedLocation] = useState<Location>({
    id: packaging[index]?.region?.id,
    name: packaging[index]?.region?.name,
  })

  const handleTextChange = async (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const searchString = e.currentTarget.value
    if (searchString) {
      const response = await searchRegions(searchString)
      setLocations((response as Location[]).map((result) => ({ id: result.id, name: result.name })))
    } else {
      setLocations([])
    }
  }

  const handleLocationSelected = (_event: React.SyntheticEvent<Element, Event>, location: Location) => {
    const updatedLocation = location ?? { id: undefined, name: undefined }
    setSelectedLocation(updatedLocation)
    if (setTempItem) {
      setTempItem({
        ...packagingItem,
        region: { id: updatedLocation.id, name: updatedLocation.name },
      })
    } else {
      dispatch(
        updateRecipeAndScores({
          recipeUpdates: {
            packaging: packaging.map((item) => {
              if (item.id === packagingItem.id) {
                return {
                  ...item,
                  region: { id: updatedLocation.id, name: updatedLocation.name },
                }
              }
              return item
            }),
          },
          change: `Changed packaging item region to ${updatedLocation.name}`,
        })
      )
    }
  }

  if (view !== DRAFT) {
    return (
      <Text data-testid="region-text" id={`region-text-${index}`}>
        {packagingItem.region?.name}
      </Text>
    )
  }

  return (
    <Autocomplete
      data-testid="region-search"
      id={`region-select-${index}`}
      options={locations}
      noOptionsText="Start typing"
      getOptionLabel={(option: Location) => option.name || ''}
      value={selectedLocation}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={handleLocationSelected}
      fullWidth
      ListboxProps={{ id: 'region-menu' }}
      renderInput={(params) => <TextField {...params} onChange={handleTextChange} />}
    />
  )
}
