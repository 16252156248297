import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectEditingFilter, selectTagFilters, setEditingFilter, updateFiltersUrlAndProducts } from '@/state/products'
import { FormulationTagFilterOption } from '@/state/products/products.state'
import { selectIsProductFiltersLoading } from '@/selectors/selectIsProductFiltersLoading'
import { FormulationTagFilter } from '../Shared/Filters/FormulationTagFilter'

export const TagsFilter: FC = () => {
  const dispatch = useDispatch()
  const isLoading = useSelector(selectIsProductFiltersLoading)
  const formulationTags = useSelector(selectTagFilters)
  const editingFilter = useSelector(selectEditingFilter)
  const id = 'tag-search'

  const handleFormulationTagsChange = (options: FormulationTagFilterOption[]) => {
    dispatch(updateFiltersUrlAndProducts({ updatedFilters: { formulationTags: options } }))
    dispatch(setEditingFilter(id))
  }

  return (
    <FormulationTagFilter
      value={formulationTags}
      handleChange={handleFormulationTagsChange}
      id={id}
      disabled={isLoading}
      autoFocus={editingFilter === id}
      fullWidth
    />
  )
}
