import React, { FC, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Autocomplete, ListItem, Text, TextField, Tooltip } from '@howgood/design'
import { Region, useRegions } from '@/api'
import { updateIngredient } from '@/state/recipe'
import { DRAFT, selectPageView } from '@/state/pageSettings'
import { selectDraftWorkspaces } from '@/state/productOverview'
import { RecipeIngredient } from '@/state/recipe/recipe.state'
import { selectDefaultWorkspaceIds } from '@/state/workspaces'

interface ProcessingLocationInputProps {
  ingredient: RecipeIngredient
  label?: string
  isTableView?: boolean
  onChange?: (ingredient: Region) => void
}

export const ProcessingLocationInput: FC<ProcessingLocationInputProps> = ({
  ingredient,
  label,
  isTableView = false,
  onChange,
}) => {
  const dispatch = useDispatch()
  const view = useSelector(selectPageView)
  const draftWorkspaces = useSelector(selectDraftWorkspaces)
  const defaultWorkspaces = useSelector(selectDefaultWorkspaceIds)

  const [searchString, setSearchString] = useState(ingredient.processing_location_name || '')

  // User can only select regions associated with these workspaces
  const workspaceIds = useMemo(
    () => (draftWorkspaces?.length ? [...draftWorkspaces.map((ws) => ws.id), ...defaultWorkspaces] : defaultWorkspaces),
    [draftWorkspaces, defaultWorkspaces]
  )

  // Turn the ingredient's processing location attributes into a Region object
  const selectedLocation: Region = useMemo(
    () => ({
      id: ingredient.processing_location_id,
      name: ingredient.processing_location_name,
    }),
    [ingredient.processing_location_id, ingredient.processing_location_name]
  )

  // Get available regions for the selected workspace from `/v2/regions` (returns 100 max)
  // On initialization, if ingredient has a processing location, send this so we can confirm it's valid for selected workspace
  // If searchString is '', the API returns only the first 100 regions by ID, so don't display anything in the list
  // The hook ensures we make the API call only once for all ingredients where the processing location isn't set
  // The API doesn't process strings with commas, so if the search string has a comma, split it and only send the first part
  const { data: regionList } = useRegions({ search: searchString.split(',')[0], workspaceIds })
  const availableRegions = useMemo(
    () => (searchString ? regionList.sort((a, b) => a.name.localeCompare(b.name)) : []),
    [regionList, searchString]
  )

  // It's possible the saved product has an invalid processing region, or user changed workspace and region is no longer valid
  // The first condition ensures we only set the error text when the component is initialized and there is a processing location
  const invalidText = useMemo(() => {
    if (
      searchString === ingredient.processing_location_name &&
      availableRegions.length &&
      !availableRegions.find((region) => region.id === ingredient.processing_location_id)
    ) {
      return `The processing location (${ingredient.processing_location_name}) in the product definition is invalid. Select one of the available processing locations.`
    }
    return ''
  }, [availableRegions, ingredient.processing_location_id, ingredient.processing_location_name, searchString])

  const handleLocationSelected = (location: Region) => {
    setSearchString('')
    if (!location) return
    if (onChange) onChange(location)
    if (isTableView) {
      dispatch(
        updateIngredient(ingredient, {
          processing_location_id: location.id,
          processing_location_name: location.name,
        })
      )
    }
  }

  return (
    <Autocomplete
      id={`processing-location-${ingredient.index}`}
      aria-label="Select processing location using autocomplete"
      color="secondary"
      size={isTableView ? 'medium' : 'small'}
      disabled={view !== DRAFT}
      options={availableRegions}
      noOptionsText="Start typing"
      multiple={false}
      getOptionLabel={(option: Region) => option.name || ''}
      value={selectedLocation}
      isOptionEqualToValue={(option, val) => option.id === val.id}
      onChange={(_e, region: Region) => handleLocationSelected(region)}
      fullWidth
      renderInput={(params) => (
        <Tooltip
          placement={isTableView ? 'right' : 'bottom'}
          title={invalidText ? invalidText : selectedLocation.name ? <Text>{selectedLocation.name}</Text> : null}
        >
          <TextField
            {...params}
            data-testid="processing-location-input"
            label={label}
            size={isTableView ? 'medium' : 'small'}
            color="secondary"
            onKeyDown={(event) => event.stopPropagation()}
            onChange={(e) => {
              e.stopPropagation()
              e.preventDefault()
              setSearchString(e.currentTarget.value || '')
            }}
            sx={{
              mt: 0.25,
              bgcolor: 'white',
              '& .MuiInputBase-root': { py: 0 },
              '& .MuiOutlinedInput-root': { pr: '50px !important' },
              '& .MuiOutlinedInput-root .MuiAutocomplete-input': { py: 0.5, pl: 0 },
              '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                borderColor: invalidText ? 'error.light' : null,
              },
            }}
          />
        </Tooltip>
      )}
      renderOption={(props, option) => (
        <ListItem {...props} key={option.id}>
          {option.name}
        </ListItem>
      )}
      ListboxProps={{ id: 'processing-location-menu' }}
    />
  )
}
