import React, { FC } from 'react'
import { Stack } from '@howgood/design'

import { WorkspaceColumnSelect } from './WorkspaceColumnSelect'
import { CreateInitiative } from '../../../Initiative/Initiatives/CreateInitiative'
import { useSelector } from 'react-redux'
import { selectFlagGoalsSplit } from '@/state/splitio'
import { selectIsInitiative } from '@/state/router'

export const Toolbar: FC = () => {
  const flagSplitEnabled = useSelector(selectFlagGoalsSplit)
  const isInitiative = useSelector(selectIsInitiative)

  return (
    <Stack flexDirection="row" alignItems="center" justifyContent="right" gap={1} mt={2}>
      {flagSplitEnabled && !isInitiative && <CreateInitiative />}
      <WorkspaceColumnSelect />
    </Stack>
  )
}
