import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Grid } from '@howgood/design'
import { CardData } from '@/records/scoreCardData'
import { ScoreCard } from './ScoreCard'
import { DRAFT, selectPageView } from '@/state/pageSettings'
import { GenerateCFReportButton } from './GenerateCFReportButton'
import { selectCanLockProduct } from '@/state/productOverview'
import { selectIsSupplierConnect } from '@/state/organization'

interface ScoreCardsProps {
  cards: CardData[]
  isFavorites?: boolean
}

export const ScoreCards: FC<ScoreCardsProps> = ({ cards, isFavorites = false }) => {
  const isSupplierConnect = useSelector(selectIsSupplierConnect)
  const pageView = useSelector(selectPageView)
  const productLockable = useSelector(selectCanLockProduct)

  return (
    <>
      {cards.map((card) => {
        const applyGenerateReport =
          isSupplierConnect && card.key === 'cf_ftm_gate_ct_verified_impact' && pageView !== DRAFT && productLockable
        return (
          <Grid item xs={12} sm={6} md={4} lg={3} key={card.key}>
            <ScoreCard highlight={applyGenerateReport} card={card} isFavorites={isFavorites} />
            {applyGenerateReport && <GenerateCFReportButton />}
          </Grid>
        )
      })}
    </>
  )
}
