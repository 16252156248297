import React, { FC, MutableRefObject, useState } from 'react'
import {
  DataGridPremium,
  GridAggregationModel,
  GridColDef,
  GridApi,
  GridColumnVisibilityModel,
  GridRowGroupingModel,
} from '@mui/x-data-grid-premium'
import { IngredientTableRow } from '../types'

interface ComparisonResultTableProps {
  gridApiRef: MutableRefObject<GridApi>
  data: IngredientTableRow[]
  columns: GridColDef[]
}

export const defaultColumnVisibilityModel = { ingredient: false, standard: false, location: false }
export const defaultRowGroupingModel = ['ingredient', 'standard']

export const IngredientComparisonResultTable: FC<ComparisonResultTableProps> = ({ gridApiRef, data, columns }) => {
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>(
    defaultColumnVisibilityModel
  )
  const [rowGroupingModel, setRowGroupingModel] = useState<GridRowGroupingModel>(defaultRowGroupingModel)
  const aggregationModel = columns.reduce(
    (acc, column) => ({ ...acc, [column.field]: 'avg' }),
    {} as GridAggregationModel
  )

  return (
    <DataGridPremium
      apiRef={gridApiRef}
      columns={columns}
      rows={data}
      density="compact"
      aggregationRowsScope="filtered"
      aggregationModel={aggregationModel}
      columnVisibilityModel={columnVisibilityModel}
      onColumnVisibilityModelChange={(model) => setColumnVisibilityModel(model)}
      rowGroupingModel={rowGroupingModel}
      onRowGroupingModelChange={(model) => setRowGroupingModel(model)}
      groupingColDef={{
        headerName: 'Ingredient/Standard/Location',
        leafField: 'location',
        width: 220,
        hideDescendantCount: true,
      }}
      getAggregationPosition={() => 'inline'} // Do not show aggregation in footer
      localeText={{ noRowsLabel: 'There are no ingredients selected' }}
      hideFooter
      disableColumnMenu
      autoHeight
      checkboxSelection
    />
  )
}
