import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { GridColDef } from '@mui/x-data-grid-premium'
import { Stack } from '@howgood/design'

import { ShowLiveScoresToggle } from './ShowLiveScoresToggle'
import { ShowAtRiskToggle } from './ShowAtRiskToggle'
import { ShowMaterialDirectory } from './ShowMaterialDirectory'
import { ProductColumnSelect } from './ProductColumnSelect'
import { selectIsInitiative, selectIsProcurement, selectIsScenario } from '@/state/router'
import { BulkActions } from './BulkActions/BulkActions'
import { ExportButton } from './MUIExport/ExportButton'
import { selectIsSupplierConnect } from '@/state/organization'

interface Props {
  columns: GridColDef[]
  showBulkActions: boolean
  toggleBulkActions: () => void
  selectedRowIds: number[]
  setSelectedRowIds: React.Dispatch<React.SetStateAction<number[]>>
}

export const Toolbar: FC<Props> = ({
  columns,
  showBulkActions,
  toggleBulkActions,
  selectedRowIds,
  setSelectedRowIds,
}) => {
  const isSupplierConnect = useSelector(selectIsSupplierConnect)
  const isProcurement = useSelector(selectIsProcurement)
  const isInitiative = useSelector(selectIsInitiative)
  const isScenario = useSelector(selectIsScenario)

  const handleToggleBulkActions = () => {
    toggleBulkActions()
  }

  return (
    <Stack mt={2} flexDirection="row" justifyContent="flex-end" flex={1} gap={1}>
      {!isSupplierConnect && (
        <Stack flexDirection="row" gap={2} flex={1}>
          <BulkActions
            showBulkActions={showBulkActions}
            selectedRowIds={selectedRowIds}
            setSelectedRowIds={setSelectedRowIds}
            handleToggleBulkActions={handleToggleBulkActions}
          />
          {!isInitiative && !isProcurement && <ShowLiveScoresToggle />}
          <ShowAtRiskToggle />
          {isProcurement && <ShowMaterialDirectory />}
        </Stack>
      )}
      {isScenario && <ExportButton />}
      {!isSupplierConnect && <ProductColumnSelect columns={columns} />}
    </Stack>
  )
}
