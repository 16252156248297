import React, { FC, ReactNode } from 'react'
import { Box } from '@howgood/design'

interface Props {
  children: ReactNode
}

/**
 * Container for export-only data grids, which require a defined height and
 * width: https://mui.com/x/react-data-grid/layout/
 */
export const DataGridExportContainer: FC<Props> = ({ children }) => {
  return (
    <Box height={0} width={0} overflow="hidden">
      <Box height={100} width={100}>
        {children}
      </Box>
    </Box>
  )
}
