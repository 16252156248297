import React from 'react'
import { createUseStyles } from 'react-jss'
import { RenderText } from '@contentful/rich-text-react-renderer'

const useStyles = createUseStyles({
  bold: { fontWeight: 700 },
})

export const BoldRenderer: RenderText = (text) => {
  const classes = useStyles()
  return <span className={classes.bold}>{text}</span>
}
