import React, { FC, MouseEvent, useState } from 'react'
import { Box, IconButton, theme } from '@howgood/design'
import { faHeart, faHeartBroken } from '@fortawesome/pro-solid-svg-icons'
import { faHeart as faHeartBorder } from '@fortawesome/pro-regular-svg-icons'

interface Props {
  id?: string
  favorite: boolean
  isFavorites: boolean
  onClick: (e: MouseEvent<HTMLButtonElement>) => void
}
export const FavoriteIconButton: FC<Props> = ({ id, favorite, isFavorites, onClick }) => {
  const [hover, setHover] = useState(false)
  const icon = isFavorites && hover ? faHeartBroken : favorite ? faHeart : faHeartBorder

  return (
    <Box
      color={isFavorites || !favorite ? 'divider' : theme.palette.error.light}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      sx={{ '& :hover': { color: theme.palette.error.light } }}
    >
      <IconButton data-testid={id} size="small" id={id} color="inherit" icon={icon} onClick={onClick} />
    </Box>
  )
}
