import { Organization } from '@/records'
import { createSlice } from '@reduxjs/toolkit'
import { getOrganization, getReports, saveReport, deleteReport, getDetailReport } from './organization.thunk'

export interface OrgUser {
  id: number
  email: string
  first_name?: string
  last_name?: string
  label?: string // e.g. 'peter@howgood.com (Peter Henderson)', added in thunk
}

export type ReportType = 'Formulation' | 'Procurement' | 'baseline' | 'ProcurementSnapshot'

export interface Report {
  date_created: string
  date_modified: string
  file?: string
  id: number
  metadata?: Record<string, string | number> // Only available from `GET /reports/{id}`
  name: string
  report_type: ReportType
  workflow_editor?: number
  s3_url?: string // Only available from `GET /reports/{id}`
}

export interface OrganizationState {
  isLoading: boolean
  settings: Organization
  users: OrgUser[]
  reports: Report[]
}

export const initialState: OrganizationState = {
  isLoading: false,
  settings: {
    organizationId: 0,
    organizationName: '',
    salesforceAccountId: '',
    activeStatus: '',
    customerType: '',
    tier: '',
  },
  users: [],
  reports: [],
}

const organizationSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOrganization.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getOrganization.fulfilled, (state, action) => {
      const { organization, users } = action.payload
      state.isLoading = false
      state.settings = organization
      state.users = users
    })
    builder.addCase(getOrganization.rejected, (state, action) => {
      console.error(action.error)
      state.isLoading = false
      state.users = initialState.users
    })

    builder.addCase(getReports.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getReports.fulfilled, (state, action) => {
      state.isLoading = false
      state.reports = action.payload
    })
    builder.addCase(getReports.rejected, (state, action) => {
      console.error(action.error)
      state.isLoading = false
      state.reports = initialState.reports
    })

    builder.addCase(getDetailReport.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getDetailReport.fulfilled, (state, action) => {
      state.isLoading = false
      state.reports = action.payload
    })
    builder.addCase(getDetailReport.rejected, (state) => {
      state.isLoading = false
    })

    builder.addCase(saveReport.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(saveReport.fulfilled, (state, action) => {
      state.isLoading = false
      state.reports.push(action.payload)
    })
    builder.addCase(saveReport.rejected, (state, action) => {
      console.error(action.error)
      state.isLoading = false
    })

    builder.addCase(deleteReport.fulfilled, (state, action) => {
      const deletedReportIndex = state.reports.findIndex((report) => report.id === action.payload)
      state.reports.splice(deletedReportIndex, 1)
    })
  },
})

export const organizationReducer = organizationSlice.reducer
