import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Alert, Snackbar, Stack } from '@howgood/design'
import { removeMessages, selectHasMessages, selectMessages } from '@/state/messages'

export const AppMessages: FC = () => {
  const dispatch = useDispatch()
  const hasMessages = useSelector(selectHasMessages)
  const messages = useSelector(selectMessages)

  return (
    <Snackbar data-testid="app-messages" open={hasMessages} onClose={() => dispatch(removeMessages())}>
      <Stack flexDirection="column" gap={1}>
        {Object.entries(messages).map(([key, message]) => (
          <Alert data-testid="app-message" key={key} severity={message.severity}>
            {message.message}
          </Alert>
        ))}
      </Stack>
    </Snackbar>
  )
}
