import { createSelector } from 'reselect'
import { FunctionalCategory } from '@/api'
import { AppState } from '@/store'
import { FunctionalCategoryOption } from '@/constants/industryCategories'

const selectFunctionalCategories = (state: AppState): FunctionalCategory[] =>
  state.functionalCategories.functionalCategories

export const selectFunctionalCategoryOptions = createSelector<
  AppState,
  FunctionalCategory[],
  FunctionalCategoryOption[]
>(selectFunctionalCategories, (functionalCategories) => {
  return functionalCategories.map((r) => ({ value: r.id, label: r.name, name: r.name }))
})
