import React, { ChangeEvent, FC, MouseEvent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { faArrowUpFromBracket, faEllipsis, faPen, faTrash } from '@fortawesome/pro-regular-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { CircularProgress, Box, Icon, IconButton, Menu, MenuItem, Stack, Text, theme } from '@howgood/design'
import {
  removeCarbonAccountingReport,
  replaceCarbonAccountingReport,
  selectCarbonAccountingReportById,
  setCarbonAccountingReportActivities,
} from '@/state/carbonAccounting'
import { CarbonAccountingExport } from '../CarbonAccountingExport'
import { DeleteReportDialog, EditReportDialog } from '../CarbonAccountingDialog'
import { fetchCarbonAccountingReportActivities } from '@/state/carbonAccounting/carbonAccounting.requests'

interface OptionProps {
  icon: IconProp
  text: string
  color?: string
  loading?: boolean
}
const Option: FC<OptionProps> = ({ icon, text, color, loading = false }) => (
  <Stack flexDirection="row" alignItems="center" gap={1}>
    <Box width={12}>
      {loading ? (
        <CircularProgress data-testid="loading-icon" size={12} />
      ) : (
        <Icon icon={icon} color={color} size="xs" />
      )}
    </Box>
    <Text variant="body2" color={loading ? 'text-disabled' : color}>
      {text}
    </Text>
  </Stack>
)

type MenuOption = 'edit' | 'export' | 'delete'

interface Props {
  reportId: number
  showExport?: boolean
}

export const ReportMenu: FC<Props> = ({ reportId, showExport = true }) => {
  const dispatch = useDispatch()
  const report = useSelector(selectCarbonAccountingReportById(reportId))
  const [anchorEl, setAnchorEl] = useState(null)
  const [loadingActivities, setLoadingActivities] = useState(false)
  const [option, setOption] = useState<MenuOption>(null)
  const [name, setName] = useState(report?.title)

  useEffect(() => setName(report?.title), [report?.title])

  function closeMenu() {
    setAnchorEl(null)
  }
  function closeDialog() {
    setOption(null)
  }
  async function openMenu(e: MouseEvent<HTMLButtonElement>) {
    setAnchorEl(e.target)
    if (showExport) {
      setLoadingActivities(true)
      const reportActivities = await fetchCarbonAccountingReportActivities(reportId)
      dispatch(setCarbonAccountingReportActivities(reportActivities))
      setLoadingActivities(false)
    }
  }
  function saveReport() {
    dispatch(replaceCarbonAccountingReport({ reportId, body: { title: name } }))
    setOption(null)
  }
  function deleteReport() {
    dispatch(removeCarbonAccountingReport(reportId))
  }
  function handleMenuItemClick(op: MenuOption) {
    setOption(op)
    setAnchorEl(null)
  }

  return (
    // Prevent navigation
    <Stack onClick={(e) => e.preventDefault()}>
      <IconButton data-testid="report-options-button" icon={faEllipsis} onClick={openMenu} />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        MenuListProps={{ ['data-testid']: 'report-options-menu' }}
      >
        <MenuItem data-testid="report-option-edit" onClick={() => handleMenuItemClick('edit')}>
          <Option icon={faPen} text="Edit" />
        </MenuItem>
        {showExport && (
          <CarbonAccountingExport
            reportId={reportId}
            reportYear={report?.year}
            reportTitle={report?.title}
            scope3Total={report?.scope_3_total}
            allEmissionsTotal={report?.all_scopes_total}
          >
            {(onClick) => (
              <MenuItem data-testid="report-option-export" onClick={onClick} disabled={loadingActivities}>
                <Option icon={faArrowUpFromBracket} loading={loadingActivities} text="Export" />
              </MenuItem>
            )}
          </CarbonAccountingExport>
        )}
        <MenuItem data-testid="report-option-delete" onClick={() => handleMenuItemClick('delete')}>
          <Option icon={faTrash} text="Delete" color={theme.palette.error.main} />
        </MenuItem>
      </Menu>
      <EditReportDialog
        open={option === 'edit'}
        name={name}
        year={report?.year}
        saveDisabled={!name || name === report?.title}
        onClose={closeDialog}
        onSave={saveReport}
        onChangeName={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
      />
      <DeleteReportDialog
        open={option === 'delete'}
        name={name}
        year={report?.year}
        onClose={closeDialog}
        onDelete={deleteReport}
      />
    </Stack>
  )
}
