import React, { FC, useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  IconButton,
  Stack,
  Text,
  Tooltip,
} from '@howgood/design'
import { ProductsFilter } from './ProductsFilter'
import { ResultsFilter } from './ResultsFilter'
import { formatNumber } from '@howgood/utils'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectBasis,
  selectHideTagPrefix,
  selectPortfolioTotal,
  selectView,
  setHideTagPrefix,
} from '../state/portfolioDashboard'
import { useUnits } from '../Charts/useUnits'
import { faAngleDown, faAngleUp } from '@fortawesome/pro-regular-svg-icons'

export const TagFilters: FC = () => {
  const portfolioTotal = useSelector(selectPortfolioTotal)
  const view = useSelector(selectView)
  const hideTagPrefix = useSelector(selectHideTagPrefix)
  const basis = useSelector(selectBasis)
  const units = useUnits()
  const dispatch = useDispatch()

  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <Accordion
      expanded={isExpanded}
      onChange={(_, expanded) => setIsExpanded(expanded)}
      sx={{
        '&.MuiAccordion-root': { p: 0, boxShadow: 'none' },
        '& .MuiAccordionSummary-root': { p: 0 },
        '& .MuiAccordionSummary-expandIconWrapper': { display: 'none' },
        '& .MuiAccordionDetails-root': { mt: 1 },
      }}
    >
      <AccordionSummary id="tag-filtering-header">
        <Stack direction="row" alignContent="center" gap={1} flexGrow={1}>
          <Text variant="body2">{isExpanded ? 'Hide' : 'Show'} tag filters</Text>
          <IconButton
            icon={isExpanded ? faAngleUp : faAngleDown}
            size="small"
            onClick={() => setIsExpanded(!isExpanded)}
          />
          <Stack flexGrow={1} />
          <Stack direction="row" alignContent="center" gap={1}>
            <Text variant="body2" fontWeight="bold">
              {basis === 'kg' ? 'Portfolio average:' : 'Portfolio total:'}
            </Text>
            <Text variant="body2">
              {formatNumber(portfolioTotal)} {units}
            </Text>
          </Stack>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Stack
          direction="row"
          alignItems="center"
          gap={1}
          sx={{ '& .MuiFormControlLabel-label': { fontSize: 13, lineHeight: 1.2, textAlign: 'center' } }}
        >
          <ProductsFilter />
          {view === 'tags' && <ResultsFilter />}
          {view === 'tags' && (
            <Tooltip title="Works only if you used a colon (:) as the separator in the tag name.">
              <Box>
                <Checkbox
                  label="Hide&nbsp;tag prefix"
                  size="small"
                  checked={hideTagPrefix}
                  onChange={(e) => dispatch(setHideTagPrefix(e.currentTarget.checked))}
                />
              </Box>
            </Tooltip>
          )}
        </Stack>
      </AccordionDetails>
    </Accordion>
  )
}
