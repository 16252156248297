import React, { FC } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Text } from '@howgood/design'
import { RecipeIngredient, RecipeNestedIngredient } from '@/state/recipe/recipe.state'
import { Sources } from './ScoreCellDrawer/Sources'

interface Props {
  open: boolean
  title: string
  description: string
  units: string
  onClose: () => void
  ingredient?: RecipeIngredient | RecipeNestedIngredient
  score?: string
}
export const ScoreCellDialog: FC<Props> = ({ open, title, description, units, score, ingredient, onClose }) => {
  const originLocationId = ingredient && 'origin_location_id' in ingredient ? ingredient.origin_location_id : null

  return (
    <Dialog data-testid="score-dialog" open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Stack gap={2}>
          {description && <Text data-testid="score-dialog-description">{description}</Text>}
          {ingredient && (
            <Text data-testid="score-dialog-ingredient" fontWeight="bold">
              {`${ingredient.name}: ${score} ${units}`}
            </Text>
          )}
          {/* Only fetch sources when open */}
          {open && <Sources originLocationId={originLocationId} />}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button data-testid="close-score-dialog" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
