import React, { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Dialog, DialogActions, DialogContent, IconButton, Text, Tooltip } from '@howgood/design'
import { removeIngredient } from '@/state/recipe'
import { DRAFT, selectPageView, setCollapsed } from '@/state/pageSettings'
import { RecipeIngredient, RecipeNestedIngredient } from '@/state/recipe/recipe.state'
import { faTrashXmark } from '@fortawesome/pro-regular-svg-icons'

interface DeleteItemProps {
  ingredient: RecipeIngredient | RecipeNestedIngredient
}

export const DeleteItem: FC<DeleteItemProps> = ({ ingredient }) => {
  const dispatch = useDispatch()
  const view = useSelector(selectPageView)

  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false)

  const handleDeleteItem = () => {
    setDeleteAlertOpen(true)
  }

  const handleDeleteItemConfirmed = () => {
    dispatch(removeIngredient(ingredient))
    dispatch(setCollapsed(ingredient.dot_path))
    setDeleteAlertOpen(false)
  }

  const handleCloseDelete = () => {
    setDeleteAlertOpen(false)
  }

  if (!ingredient.isTopLevel) {
    return null
  }

  return (
    <>
      <Tooltip childrenDisabled={view !== DRAFT} title={<Text>Delete ingredient</Text>}>
        <IconButton
          id={`delete-ingredient-button-${ingredient.index}`}
          aria-label="Delete this ingredient"
          icon={faTrashXmark}
          disabled={view !== DRAFT}
          onClick={handleDeleteItem}
        />
      </Tooltip>
      <Dialog id="delete-ingredient-dialog" open={deleteAlertOpen} onClose={handleCloseDelete}>
        <DialogContent>
          <Text>{`Are you sure you want to delete ${ingredient.name}?`}</Text>
        </DialogContent>
        <DialogActions>
          <Button id="delete-cancel-button" onClick={handleCloseDelete}>
            Cancel
          </Button>
          <Button id="delete-ok-button" color="error" onClick={handleDeleteItemConfirmed}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
