import { createSlice, PayloadAction, ThunkAction } from '@reduxjs/toolkit'
import { AnyAction } from 'redux'
import { AppState } from '@/store'
import { selectPreferences } from './preferences.selectors'
import { getFavorites } from './preferences.utils'

export type CardType =
  | 'claims'
  | 'impacts'
  | 'lifecycle'
  | 'nutrition'
  | 'notes'
  | 'flag'
  | 'carbon-accounting'
  | 'impactReduction'
  | 'impactHistory'
  | 'initiativeChart'
export type ScoringMethod = 'perKilogram' | 'contributionWeighted'

interface GridColumnWidths {
  [key: string]: number
}

export interface GridSettings {
  showValues: boolean
  colWidths: GridColumnWidths
  hiddenCols: string[]
  scoringMethod: ScoringMethod
}

export interface Preferences {
  favoriteTiles: string[]
  favoriteCards: CardType[]
  gridSettings: GridSettings
  selectedMetricGridColumns: string[]
  selectedVendorManagementGridColumns: string[]
}

export interface State {
  preferences: Preferences
}

export const initialState: State = {
  preferences: getFavorites(),
}

const preferencesSlice = createSlice({
  name: 'preferences',
  initialState,
  reducers: {
    setPreferences: (state, action: PayloadAction<Preferences>) => {
      const preferences = action.payload
      localStorage.setItem('latis-preferences', JSON.stringify(preferences))
      state.preferences = preferences
    },
  },
})

const { setPreferences } = preferencesSlice.actions

export const preferencesReducer = preferencesSlice.reducer

export const setFavoriteCards = (favoriteCards: CardType[]): ThunkAction<void, AppState, string[], AnyAction> => async (
  dispatch,
  getState
) => {
  const state = getState()
  const preferences = selectPreferences(state)
  dispatch(setPreferences({ ...preferences, favoriteCards }))
}

export const setFavoriteScores = (favoriteTiles: string[]): ThunkAction<void, AppState, string[], AnyAction> => async (
  dispatch,
  getState
) => {
  const state = getState()
  const preferences = selectPreferences(state)
  dispatch(setPreferences({ ...preferences, favoriteTiles }))
}

export const setGridSettings = (gridSettings: GridSettings): ThunkAction<void, AppState, string[], AnyAction> => async (
  dispatch,
  getState
) => {
  const state = getState()
  const preferences = selectPreferences(state)
  dispatch(setPreferences({ ...preferences, gridSettings }))
}

export const setSelectedMetricGridColumns = (
  selectedMetricGridColumns: string[]
): ThunkAction<void, AppState, string[], AnyAction> => async (dispatch, getState) => {
  const state = getState()
  const preferences = selectPreferences(state)
  dispatch(setPreferences({ ...preferences, selectedMetricGridColumns }))
}

export const setSelectedVendorManagementGridColumns = (
  selectedVendorManagementGridColumns: string[]
): ThunkAction<void, AppState, string[], AnyAction> => async (dispatch, getState) => {
  const state = getState()
  const preferences = selectPreferences(state)
  dispatch(setPreferences({ ...preferences, selectedVendorManagementGridColumns }))
}
