import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MenuItem, Select, SelectChangeEvent, Text } from '@howgood/design'
import { selectDisplayedPackaging, updateRecipeAndScores } from '@/state/recipe'
import { DRAFT, selectPageView } from '@/state/pageSettings'
import { PackagingUnitKey, RecipePackagingItem } from '@/state/recipe/recipe.state'

type PackagingUnits = Record<PackagingUnitKey, string>
type PackagingUnitOption = Partial<PackagingUnits>
export const packagingUnitMap: PackagingUnits = {
  C: 'Consumer Unit',
  S: 'Selling Unit',
  TC: 'Tray/Crate',
  PT: 'Pallet/Transportation Unit',
}

export interface PackagingUnitProps {
  packagingItem: RecipePackagingItem
  index: number
  setTempItem?: (item: RecipePackagingItem) => void
}

export const PackagingUnit: FC<PackagingUnitProps> = ({ packagingItem, index, setTempItem }) => {
  const dispatch = useDispatch()
  const view = useSelector(selectPageView)
  const packaging = useSelector(selectDisplayedPackaging)
  const packagingUnitKey = packagingItem.packaging_unit
  const packagingUnit = packagingUnitMap[packagingUnitKey]
  const option: PackagingUnitOption = {
    [packagingUnitKey]: packagingUnit,
  }

  const handleChangePackaging = (event: SelectChangeEvent<PackagingUnitKey>) => {
    const key = event.target.value as PackagingUnitKey
    if (setTempItem) {
      setTempItem({
        ...packagingItem,
        packaging_unit: key,
      })
    } else {
      dispatch(
        updateRecipeAndScores({
          recipeUpdates: {
            packaging: packaging.map((item) => {
              if (item.id === packagingItem.id) {
                return {
                  ...item,
                  packaging_unit: key,
                }
              }
              return item
            }),
          },
          change: `Changed packaging item packaging_unit to ${packagingUnitMap[key]}`,
        })
      )
    }
  }

  if (view !== DRAFT) {
    return (
      <Text data-testid="packaging_unit-text" id={`packaging_unit-text-${index}`}>
        {packagingUnit}
      </Text>
    )
  }

  return (
    <Select
      data-testid="packaging_unit-select"
      id={`packaging_unit-select-${index}`}
      value={packagingUnitKey || ''}
      renderValue={() => option[packagingUnitKey]}
      onChange={handleChangePackaging}
      MenuProps={{ id: `packaging_unit-menu` }}
      fullWidth
      required
    >
      {Object.entries(packagingUnitMap).map(([key, value]) => (
        <MenuItem key={`packaging_unit-${key}`} value={key}>
          {value}
        </MenuItem>
      ))}
    </Select>
  )
}
