import React, { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Checkbox,
  Text,
  TextField,
  MenuItem,
  LegacyDialog,
  ListItemText,
  Grid,
  Select,
  SelectChangeEvent,
  Stack,
} from '@howgood/design'

import {
  SaveAsInfo,
  saveNewProduct,
  selectCanEditProduct,
  selectDraftProductInfo,
  selectSavedProductInfo,
} from '@/state/productOverview'
import { WorkspaceOption } from '@/records/Workspace'
import { FORMULATION_STATUS_PIPELINE, formulationStatuses, FormulationStatusOption } from '@/records'
import { selectAllWorkspacesOptions } from '@/state/workspaces'
import { usePendoTrack } from '@/hooks'
import { selectIsSupplierConnect } from '@/state/organization'

interface SaveAsDialogProps {
  closeDialog: () => void
}

interface SaveAsDialogInfo extends Omit<SaveAsInfo, 'workspaces'> {
  workspace: { id: number; name: string }
}

export const SaveAsDialog: FC<SaveAsDialogProps> = ({ closeDialog }) => {
  const dispatch = useDispatch()
  const pendoTrack = usePendoTrack()
  const savedProduct = useSelector(selectSavedProductInfo)
  const productDraft = useSelector(selectDraftProductInfo)
  const canEdit = useSelector(selectCanEditProduct)
  const isSupplierConnect = useSelector(selectIsSupplierConnect)

  const newProduct = !savedProduct.id

  const [saveAsInfo, setSaveAsInfo] = useState<SaveAsDialogInfo>({
    name: newProduct || productDraft.name !== savedProduct.name ? productDraft.name : `Copy of ${savedProduct.name}`,
    workspace: productDraft.workspaces?.length
      ? productDraft.workspaces[0]
      : {
          id: null,
          name: '',
        },
    status: FORMULATION_STATUS_PIPELINE,
    copyTags: true,
    copySalesVolume: true,
  })

  const workspaces = useSelector(selectAllWorkspacesOptions)

  const handleClose = () => {
    closeDialog()
  }

  const handleSaveClicked = () => {
    const formattedToSave = {
      name: saveAsInfo.name,
      workspaces: [saveAsInfo.workspace],
      status: saveAsInfo.status,
      copyTags: saveAsInfo.copyTags,
      copySalesVolume: saveAsInfo.copySalesVolume,
    }
    pendoTrack('Formula: Save', { ...formattedToSave, isNewProduct: !!saveAsInfo })
    dispatch(saveNewProduct(formattedToSave))
    closeDialog()
  }

  const handleWorkspaceChanged = (event: SelectChangeEvent<WorkspaceOption>) => {
    const value = event.target.value as WorkspaceOption
    setSaveAsInfo({
      ...saveAsInfo,
      workspace: {
        id: value.id,
        name: value.name,
      },
    })
  }

  const handleStatusChanged = (event: SelectChangeEvent<FormulationStatusOption>) => {
    const value = event.target.value as FormulationStatusOption
    setSaveAsInfo({
      ...saveAsInfo,
      status: value,
    })
  }

  const saveErrorMessage = !saveAsInfo.name.trim()
    ? 'Formula name is required'
    : !saveAsInfo.workspace.id
    ? 'Workspace is required'
    : ''

  return (
    <LegacyDialog
      maxWidth="sm"
      buttonOrientation="row-reverse"
      title={canEdit ? 'Save As New Formula' : 'Duplicate Formula'}
      open
      onClose={handleClose}
      primaryButtonText="Save"
      primaryButtonDisabled={!!saveErrorMessage}
      primaryButtonAriaLabel="Save"
      primaryButtonAction={handleSaveClicked}
      secondaryButtonText="Cancel"
      secondaryButtonAriaLabel="Cancel"
      secondaryButtonAction={handleClose}
    >
      <Grid container columnSpacing={4} rowSpacing={1}>
        <Grid item xs={6}>
          <Text>Name</Text>
          <TextField
            id="save-as-name-field"
            value={saveAsInfo.name}
            fullWidth
            onChange={(e) => setSaveAsInfo({ ...saveAsInfo, name: e.currentTarget.value })}
          />
        </Grid>
        {!isSupplierConnect && (
          <Grid item xs={6}>
            <Text>Status</Text>
            <Select
              id="save-as-status-select"
              fullWidth
              value={saveAsInfo.status}
              renderValue={() => saveAsInfo?.status.label}
              onChange={handleStatusChanged}
            >
              {formulationStatuses.map((status) => (
                <MenuItem key={status.id} value={status as any}>
                  <ListItemText primary={status.label} />
                </MenuItem>
              ))}
            </Select>
          </Grid>
        )}

        <Grid item xs={6}>
          <Text>Workspace</Text>
          <Select
            id="save-as-workspace-select"
            fullWidth
            value={saveAsInfo.workspace}
            renderValue={() => saveAsInfo.workspace?.name}
            onChange={handleWorkspaceChanged}
          >
            {workspaces.map((workspace) => (
              <MenuItem key={workspace.id} value={workspace as any} disabled={!workspace.edit}>
                <ListItemText primary={workspace.name} />
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={6} display={newProduct ? 'none' : 'block'}>
          <Checkbox
            id="copy-tags-option"
            checked={saveAsInfo.copyTags}
            onChange={() => setSaveAsInfo({ ...saveAsInfo, copyTags: !saveAsInfo.copyTags })}
            inputProps={{ 'aria-label': 'Copy tags' }}
            label="Copy tags to new formula"
          />
          <Checkbox
            id="copy-sales-volume-option"
            checked={saveAsInfo.copySalesVolume}
            onChange={() => setSaveAsInfo({ ...saveAsInfo, copySalesVolume: !saveAsInfo.copySalesVolume })}
            inputProps={{ 'aria-label': 'Copy sales volume' }}
            label="Copy unit size and units sold"
          />
        </Grid>
      </Grid>
      <Stack display={!saveErrorMessage ? 'none' : 'block'} mt={2}>
        <Text color="error.main">{saveErrorMessage}</Text>
      </Stack>
    </LegacyDialog>
  )
}
