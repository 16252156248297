import React, { FC, ReactNode } from 'react'
import { Card } from '../Card'
import { Stack } from '../Stack'
import { theme } from '../theme'
import { MetricTileHeader } from './MetricTileHeader'
import { MetricTileTitle } from './MetricTileTitle'
import { MetricTileScore } from './MetricTileScore'
import { MetricTileChange } from './MetricTileChange'
import { Sign, Size } from './types'

export interface MetricTileProps {
  ['data-testid']?: string
  category?: string
  favorite?: boolean
  handleFavorite?: () => void
  title?: string
  titleTooltip?: FC<{ children: ReactNode }>
  subtitle?: string
  score?: number
  scoreOverride?: ReactNode
  scoreRange?: string
  goalScore?: number
  units?: string
  change?: number
  changeSign?: Sign
  changeColor?: string
  showChange?: boolean
  noChangeText?: string
  warningText?: string
  goalSpread?: number
  goalSpreadSign?: Sign
  goalSpreadColor?: string
  size?: Size
  loading?: boolean
  disabled?: boolean
  hideFavoriteIcon?: boolean
  highlight?: boolean
  scoreContentOverride?: ReactNode
}

export const MetricTile: FC<MetricTileProps> = ({
  ['data-testid']: dataTestId,
  category,
  favorite,
  handleFavorite,
  title,
  titleTooltip,
  subtitle,
  score,
  scoreOverride,
  scoreRange,
  units,
  size = 'sm',
  change,
  changeSign = 'positive',
  changeColor = theme.palette.success.dark,
  noChangeText,
  warningText,
  showChange,
  goalScore,
  goalSpread,
  goalSpreadSign = 'positive',
  goalSpreadColor = theme.palette.error.main,
  loading,
  disabled,
  hideFavoriteIcon,
  highlight,
  scoreContentOverride,
}) => {
  const isGoal = typeof goalScore === 'number' && typeof goalSpread === 'number'

  return (
    <Card id={dataTestId} data-testid={dataTestId} fullHeight outlineDashed={disabled} highlight={highlight}>
      <MetricTileHeader
        category={category}
        goal={isGoal}
        handleFavorite={handleFavorite}
        hideFavoriteIcon={hideFavoriteIcon}
      />
      <Stack justifyContent="space-between" gap={1} height="100%" px={0.75} py={0.5}>
        <Stack gap={1} height="99px">
          <MetricTileTitle
            title={title}
            titleTooltip={titleTooltip}
            subtitle={subtitle}
            size={size}
            category={category}
            favorite={favorite}
            handleFavorite={handleFavorite}
            goal={isGoal}
            disabled={disabled}
            hideFavoriteIcon={hideFavoriteIcon}
          />
          <MetricTileScore
            score={score}
            scoreOverride={scoreOverride}
            scoreRange={scoreRange}
            goalScore={goalScore}
            units={units}
            goal={isGoal}
            goalSpread={goalSpread}
            goalSpreadSign={goalSpreadSign}
            goalSpreadColor={goalSpreadColor}
            size={size}
            loading={loading}
            warningText={warningText}
            contentOverride={scoreContentOverride}
          />
        </Stack>
        <MetricTileChange
          change={change}
          changeSign={changeSign}
          changeColor={changeColor}
          showChange={showChange}
          noChangeText={noChangeText}
        />
      </Stack>
    </Card>
  )
}
