import React, { FC, Fragment } from 'react'
import { Divider, Stack, Text } from '@howgood/design'
import { useOriginLocationSources, Sources as SourcesT } from '@/api'

const sourceTitles: SourcesT = {
  greenhouse_gas_emissions_sources: 'Data sources used to calculate greenhouse gas emissions',
  soc_source: 'Data sources used to calculate soil organic carbon',
  luc_ghg_emissions_sources: 'Data sources used to calculate land use change',
  yield_source: 'Data sources used to calculate yield',
}

interface Props {
  originLocationId: number
}
export const Sources: FC<Props> = ({ originLocationId }) => {
  const { data: sources } = useOriginLocationSources(originLocationId)

  if (!sources) {
    return null
  }

  return (
    <Stack data-testid="score-sources" gap={1}>
      <Divider />
      {Object.entries(sources)
        .filter(([key]) => Boolean(sourceTitles[key]))
        .map(([key, source]) => (
          <Fragment key={key}>
            <Text variant="body2">{sourceTitles[key]}:</Text>
            <Text variant="caption" lineBreak="anywhere">
              {source}
            </Text>
          </Fragment>
        ))}
    </Stack>
  )
}
