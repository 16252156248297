import React from 'react'
import { useSelector } from 'react-redux'
import { Box, Stack } from '@howgood/design'

import { MetricsGrid } from '@/components/GridWithMetrics/MetricsGrid'
import { useScenarioColumns, useScenarioRows } from '@/components/GridWithMetrics/useGridHooks'
import { selectisInitiativesLoading, selectisScenarioScoresLoading } from '@/state/initiatives'
import { selectAllMetricGridOptions } from '@/selectors/selectAllWorkspaceGridOptions'
import { WorkspaceColumnSelect } from '@/features/PortfolioPage/components/Toolbar/WorkspaceColumnSelect'
import { CreateScenario } from '@/features/Initiative/CreateScenario/CreateScenario'
import { useGridApiRef } from '@mui/x-data-grid-premium'
import { ExportButton } from './ExportButton'
import { selectOrgLoading } from '@/state/organization'

export const ScenarioGrid = () => {
  const options = useSelector(selectAllMetricGridOptions)
  const isLoading = useSelector(selectisInitiativesLoading)
  const isScoresLoading = useSelector(selectisScenarioScoresLoading)
  const isBaselineLoading = useSelector(selectOrgLoading)

  const columns = useScenarioColumns()
  const rows = useScenarioRows()
  const gridApiRef = useGridApiRef()

  return (
    <>
      <Stack flexDirection="row" alignItems="center" justifyContent="space-between" mt={2}>
        <CreateScenario />
        <Stack direction="row" gap={1}>
          <ExportButton gridApiRef={gridApiRef} />
          <WorkspaceColumnSelect />
        </Stack>
      </Stack>
      <Box height="500px">
        <MetricsGrid
          rows={rows}
          columns={columns}
          options={options}
          loading={isLoading || isScoresLoading || isBaselineLoading}
          gridApiRef={gridApiRef}
          initialSort={[{ field: 'date', sort: 'asc' }]}
        />
      </Box>
    </>
  )
}
