import { AppState } from '@/store'
import { Basis, ChartType, CustomMetric, PortfolioImpactDashboardSettings, View } from './portfolioDashboard.types'
import { Field } from '@/constants/impactScore'
import { createSelector } from 'reselect'
import { AggsByView, PortfolioTotals } from './portfolioDashboard.thunk'
import { Workspace } from '@/records'

export const selectAvailableMetrics = (state: AppState): Field[] => state.portfolioDashboard.availableMetrics
export const selectIsLoading = (state: AppState): boolean => state.portfolioDashboard.isLoading
export const selectAggregations = (state: AppState): AggsByView => state.portfolioDashboard.aggregations
export const selectTotals = (state: AppState): PortfolioTotals => state.portfolioDashboard.portfolioTotals
export const selectSelectedWorkspace = (state: AppState): Workspace => state.portfolioDashboard.selectedWorkspace
export const selectPortfolioImpactDashboardSettings = (state: AppState): PortfolioImpactDashboardSettings =>
  state.portfolioDashboard.settings

export const selectProductType = (state: AppState) => state.portfolioDashboard.settings.productType
export const selectView = (state: AppState): View => state.portfolioDashboard.settings.view
export const selectBasis = (state: AppState): Basis => state.portfolioDashboard.settings.basis
export const selectChartType = (state: AppState): ChartType => state.portfolioDashboard.settings.chartType
export const selectAvailableTags = (state: AppState): string[] => state.portfolioDashboard.availableTags
export const selectSelectedTags = (state: AppState): string[] => state.portfolioDashboard.selectedTags
export const selectResultsFilters = (state: AppState): string[] => state.portfolioDashboard.resultsFilters
export const selectSelectedMetric = (state: AppState): Field => state.portfolioDashboard.settings.metric
export const selectCustomMetrics = (state: AppState): CustomMetric[] => state.portfolioDashboard.customMetrics
export const selectHideTagPrefix = (state: AppState): boolean => state.portfolioDashboard.hideTagPrefix

/**
 * The tag filter list may include a mix of actual tags and wildcard tags that must be handled differently
 * This returns just actual tags, for example, returns ['recycled', 'renewable'] but not ['re*']
 */
export const selectActualTags = createSelector(selectSelectedTags, (selectedTags) => {
  return selectedTags.filter((item) => !item.includes('*'))
})

/**
 * Returns wildcard tags converted to actual tags
 * For example, ['re*'] would return ['recycled', 'renewable', etc.]
 */
export const selectWildcardTags = createSelector(
  selectAvailableTags,
  selectSelectedTags,
  (availableTags, selectedTags) => {
    const wildcardStrings = selectedTags
      .filter((item) => item.includes('*'))
      .map((item) => item.replaceAll('*', '').toLowerCase())
    const wildcardTags = availableTags.filter((tag) => wildcardStrings.some((item) => tag.toLowerCase().includes(item)))
    return wildcardTags
  }
)

interface DataElement {
  name: string // Workspace, tag, or category name
  id: number
  count: number // Number of products in the workspace, tag, or category
  value: number // Value of selected metric for the selected view and basis
  fillRate: string // The number of products that have a non-null value for the selected metric
  percentage: number // Value as a percentage of the workspace, tag, or category's total
}

export interface TreeElement extends DataElement {
  children: DataElement[]
}

interface TreeData {
  name: string
  index: number
  value: number
  percentage: number
  children: TreeElement[]
}

export const selectPortfolioData = createSelector(
  selectAggregations,
  selectTotals,
  selectView,
  selectBasis,
  selectSelectedMetric,
  selectActualTags,
  selectWildcardTags,
  selectResultsFilters,
  (aggregations, totals, view, basis, metric, actualTags, wildcardTags, resultFilters): DataElement[] => {
    if (!aggregations) return []

    // If the user specified tags, filter the tag aggregations to only include those tags
    // Do this because if a product has multiple tags, there will be buckets for unspecified tags
    // But if in "View by tag" mode, we want exclude those tags and display the rest
    const allTags = [...actualTags, ...wildcardTags]
    const tagFilteredAggs =
      allTags.length > 0
        ? {
            ...aggregations,
            tags:
              aggregations.tags?.filter((tag) =>
                view === 'tags' ? !allTags.includes(tag.key) : allTags.includes(tag.key)
              ) || [],
          }
        : aggregations

    const includeInResults = (name: string): boolean => {
      // If not in tags view or no result filters are set, always include the item
      if (view !== 'tags' || resultFilters.length === 0) return true

      // Include the item if its name matches all of the result filters (AND)
      return resultFilters.every((filter) => {
        if (filter.includes('*')) {
          return name.toLowerCase().includes(filter.replace('*', '').toLowerCase())
        }
        return name.toLowerCase() === filter.toLowerCase()
      })
    }

    const aggData: DataElement[] = [...tagFilteredAggs[view]] // Create a copy of the read-only array
      // Sort the array in descending order by the selected impact metric
      .sort((a, b) => {
        return b[basis]?.[metric]?.value - a[basis]?.[metric]?.value
      })
      .reduce((acc, item, i) => {
        const name = item.key
        if (!includeInResults(name)) return acc

        const value = item[basis]?.[metric]?.value || 0
        const metricFillCount = item[basis]?.[metric]?.count || 0
        const inventoryFillCount = item[basis]?.[metric]?.inventoryCount || 0
        const salesFillCount = item[basis]?.[metric]?.salesCount || 0
        const fillCount =
          basis === 'kg' ? metricFillCount : basis === 'inventories' ? inventoryFillCount : salesFillCount
        return [
          ...acc,
          {
            name: name,
            id: i,
            count: item.doc_count, // Number of products in this workspace/category/tag
            value: metricFillCount ? value : null, // Only display the value if at least one product has a non-null value
            percentage: (value / totals[basis]?.[metric]) * 100,
            fillRate: `${fillCount?.toLocaleString()}/${item.doc_count?.toLocaleString()}`,
          },
        ]
      }, [])

    return aggData
  }
)

/**
 * The portfolio data is flat, but the TreeMap component takes a hierarchical data structure,
 * so transform the flat data into a tree structure where each element has `children: []`.
 * Limit the number of data points to 100 to avoid performance issues.
 */
export const selectPortfolioDataAsTree = createSelector(
  selectPortfolioData,
  (portfolioData): TreeData => {
    return {
      name: 'root',
      index: -1,
      value: 0,
      percentage: 0,
      children: portfolioData.map((d) => ({ ...d, children: [] })),
    }
  }
)

export const selectPortfolioTotal = createSelector(
  selectTotals,
  selectBasis,
  selectSelectedMetric,
  (totals, basis, metric): number => {
    return totals?.[basis]?.[metric]
  }
)
