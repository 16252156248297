import React, { FC } from 'react'
import { StatusItem } from '@howgood/design'
import { ProductListRenderCellParams } from './ProductGridTypes'
import { generateShareStatusOption } from '@/utils/ValidationStatusUtils'
import { SharingRequest } from '@/records'
import { getMostRecentRequest } from '@/state/productOverview'

interface Props extends ProductListRenderCellParams {
  value?: SharingRequest[]
}

export const ShareStatusRenderer: FC<Props> = ({ value, id, row }) => {
  const recentRequest = getMostRecentRequest(value) as SharingRequest
  const statusItem = generateShareStatusOption(
    recentRequest,
    !!row.locked_claims?.product_attributes?.locked_claims_timestamp,
    `-${id}`
  )

  return (
    <StatusItem
      key={statusItem.id}
      id={statusItem.id}
      status={statusItem.status}
      tooltipText={statusItem.tooltipText}
      statusText={statusItem.statusText}
      icon={statusItem.icon}
      isFirst
      isLast
    />
  )
}
