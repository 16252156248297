import React, { FC, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium'
import { Stack, MenuItem, Select, Text, Box, SelectChangeEvent } from '@howgood/design'

import {
  selectChartMetric,
  selectCurrentInitiative,
  selectCurrentInitiativeGoalMetrics,
  setChartMetric,
} from '@/state/initiatives'
import {
  generateScenarioColumns,
  generateSingleMetricColumns,
  useScenarioRows,
} from '@/components/GridWithMetrics/useGridHooks'
import { selectScoreType } from '@/state/pageSettings'

export const ChartTable: FC = () => {
  const dispatch = useDispatch()
  const { metric, units } = useSelector(selectChartMetric)
  const selectedInitiative = useSelector(selectCurrentInitiative)
  const options = useSelector(selectCurrentInitiativeGoalMetrics)
  const scoreSetting = useSelector(selectScoreType)
  const rows = useScenarioRows(true)

  const columns: GridColDef[] = useMemo(() => {
    const { name, date } = generateScenarioColumns(selectedInitiative)
    if (!metric) {
      return [date, name]
    }
    const { score, goal, spread } = generateSingleMetricColumns({ metric, name, units, scoreSetting })
    return [date, name, score, goal, spread]
  }, [selectedInitiative, metric, units, scoreSetting])

  const handleChangeMetric = (e: SelectChangeEvent) => {
    dispatch(setChartMetric(e.target.value))
  }

  if (!metric?.value) {
    return null
  }

  return (
    <Stack overflow="auto" gap={0.5} width={'500px'}>
      <Select
        id="chart-metric-select"
        data-testid="chart-metric-select"
        value={metric.value}
        onChange={handleChangeMetric}
        variant="outlined"
      >
        {options.map((option) => (
          <MenuItem key={option.value} data-testid={`metric-option-${option.value}`} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      <Box>
        <DataGridPremium
          initialState={{
            sorting: {
              sortModel: [{ field: 'date', sort: 'asc' }],
            },
          }}
          sx={{ border: 'none' }}
          rows={rows}
          columns={columns}
          hideFooter
          autoHeight
          rowHeight={26}
        />
      </Box>
      <Text width={'100%'} variant="caption" textAlign="right">
        {units}
      </Text>
    </Stack>
  )
}
