import { ReactNode } from 'react'

import { MetricEntry } from '@/contentfully'

import {
  OVERVIEW_TAB,
  CARBON_TAB,
  NATURE_TAB,
  PEOPLE_TAB,
  PERMISSIONS_TO_FIELDS_MAP,
  Field,
  PROCUREMENT_CARBON_FIELDS,
  CARBON_TAB_PRIMARY,
} from '@/constants/impactScore'

export interface CardData {
  key: string
  label: string
  category: string
  categoryIndex: number
  subTitle?: string
  value: ReactNode
  score: number | string
  scoreRange?: string
  displayedAvg?: ReactNode
  displayedSum?: ReactNode
  avg?: number
  sum?: number
  contentfulEntry?: MetricEntry
  changeSinceSaved?: number
  action?: ReactNode
  color?: boolean
  size?: 'sm' | 'md' | 'lg'
}

export interface CardCategories {
  favorites: { title: string; data: CardData[]; index: number }
  overview: { title: string; data: CardData[]; index: number }
  carbon: { title: string; data: CardData[]; index: number }
  nature: { title: string; data: CardData[]; index: number }
  people: { title: string; data: CardData[]; index: number }
  other: { title: string; data: CardData[]; index: number }
}

export const initialCardCategories: CardCategories = {
  favorites: { title: 'Favorites', data: [], index: 0 },
  overview: { title: 'Overview', data: [], index: 1 },
  carbon: { title: 'Carbon', data: [], index: 2 },
  nature: { title: 'Nature', data: [], index: 3 },
  people: { title: 'People', data: [], index: 4 },
  other: { title: 'Other', data: [], index: 5 },
}

const overviewFields = OVERVIEW_TAB.map((permission) => PERMISSIONS_TO_FIELDS_MAP[permission])
const natureFields = NATURE_TAB.map((permission) => PERMISSIONS_TO_FIELDS_MAP[permission])
const peopleFields = PEOPLE_TAB.map((permission) => PERMISSIONS_TO_FIELDS_MAP[permission])

interface ContentData {
  category: string
  contentfulEntry: MetricEntry
}

export const getContentfulEntry = (key: Field, contentfulMetricData: MetricEntry[]): MetricEntry => {
  let contentfulEntry = contentfulMetricData.find((item) => item.key === key)
  if (!contentfulEntry) {
    const fieldName = key.replace('_annual', '_impact')
    contentfulEntry = contentfulMetricData.find((item) => item.key === fieldName)
    return { ...contentfulEntry, key }
  }
  return contentfulEntry
}

// For the given metric (key), assign it to the correct category (tab) and get its Contentful data
export function getContentData({
  key,
  contentfulMetricData,
  showProcurementCarbonContent = false,
  showAllFields = false,
  showExtraCarbonContent = false,
}: {
  key: string
  contentfulMetricData: MetricEntry[]
  showProcurementCarbonContent?: boolean
  showAllFields?: boolean
  showExtraCarbonContent?: boolean
}): ContentData {
  const contentfulEntry = getContentfulEntry(key as Field, contentfulMetricData)

  const formulationCarbonFields = CARBON_TAB.filter((permission) =>
    showExtraCarbonContent ? true : CARBON_TAB_PRIMARY.includes(permission)
  ).map((permission) => PERMISSIONS_TO_FIELDS_MAP[permission])

  const carbonFields = showProcurementCarbonContent
    ? PROCUREMENT_CARBON_FIELDS
    : showAllFields
    ? [...PROCUREMENT_CARBON_FIELDS, ...formulationCarbonFields]
    : formulationCarbonFields

  let category = 'other'
  if (overviewFields.includes(key as Field)) {
    category = 'overview'
  } else if (carbonFields.includes(key as Field)) {
    category = 'carbon'
  } else if (natureFields.includes(key as Field)) {
    category = 'nature'
  } else if (peopleFields.includes(key as Field)) {
    category = 'people'
  }

  return { category, contentfulEntry }
}
