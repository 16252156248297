import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons'
import { faCheck } from '@fortawesome/pro-regular-svg-icons'
import { theme } from './theme'
import { Icon } from './Icon'

const useStyles = createUseStyles<string, { icon: IconDefinition }>({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  toggle: {
    minWidth: 110,
    color: '#4D4E54',
    outline: 'none',
    boxShadow: 'none',
    '&:first-child': {
      borderRight: 'none',
      borderRadius: '4px 0 0 4px',
    },
    '&:last-child': {
      borderLeft: 'none',
      borderRadius: '0 4px 4px 0',
    },
  },
  active: {
    backgroundColor: '#009963',
    borderColor: '#e5e5e5',
    color: '#ffffff',
  },
  button: {
    userSelect: 'none',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 38,
    padding: '0 14px',
    color: '#4a4a4a',
    background: '#ffffff',
    boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.08)',
    borderRadius: 4,
    borderWidth: 1,
    borderColor: 'rgba(0, 0, 0, 0.3)',
    borderStyle: 'solid',
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    cursor: 'pointer',
    '&:focus': {
      outlineOffset: '2px',
      outlineStyle: 'solid',
      outlineWidth: 2,
      outlineColor: '#4382F7',
    },
    '&:hover': {
      borderColor: 'rgba(0, 0, 0, 0.5)',
      boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.24)',
    },
    '&:active': {
      background: '#F6F6F6',
      boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.24',
      transform: 'translateY(1px)',
    },
    '&:disabled': {
      opacity: 0.5,
      cursor: 'not-allowed',
      boxShadow: 'none !important',
      transform: 'none !important',
      borderColor: '#BFBFBF',
    },
  },
  content: {
    display: 'flex',
  },
  text: {
    marginLeft: ({ icon }) => (icon ? 6 : 0),
  },
})
interface ToggleOption {
  label: string
  active: boolean
  pendoId?: string
  onClick?: (option?: ToggleOption) => void
}
export type ToggleOptions = [ToggleOption, ToggleOption]
interface Props {
  options: ToggleOptions
  onToggle: () => void
}

export const Toggle: FC<Props> = ({ options, onToggle }) => {
  const classes = useStyles({ icon: faCheck })

  return (
    <div className={classes.root}>
      {options.map((option: ToggleOption) => (
        <div
          key={option.label}
          data-pendo-id={option.pendoId}
          aria-label={option.label}
          aria-checked={option.active}
          className={`${classes.button} ${classes.toggle} ${option.active && classes.active}`}
          onClick={onToggle}
        >
          {option.active && (
            <div className={classes.content}>
              <Icon icon={faCheck} />
            </div>
          )}
          <div className={`${classes.content} ${option.active ? classes.text : ''}`}>{option.label}</div>
        </div>
      ))}
    </div>
  )
}
