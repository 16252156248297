import React, { FC, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Stack, Text, theme } from '@howgood/design'
import { BarChart, Bar, Cell, XAxis, YAxis } from 'recharts'
import { transformScoreToZeroToTenScale } from '@/utils/impactScoreHelpers'
import { selectScoresImpactDataForOverview } from '@/selectors/selectScoresData'

const tickDefaultProps = { fontSize: 8, fontFamily: theme.typography.fontFamily }

export const ImpactChart: FC = () => {
  const impacts = useSelector(selectScoresImpactDataForOverview)

  const data = useMemo(
    () =>
      impacts.map(({ value, content }) => {
        const score = transformScoreToZeroToTenScale(content.key, value)
        const color = theme.palette.impact[Math.round(score)]
        return { name: content.title, value: score, color }
      }),
    [impacts]
  )

  return (
    <Stack
      padding="16px 16px 0 16px"
      height="360"
      borderRadius="8px"
      sx={{
        backgroundColor: '#F6F6F6',
      }}
    >
      <Stack gap={0.5} mb={1}>
        <Text variant="h3" fontWeight={theme.typography.display.fontWeight} fontSize="22">
          Impact Details
        </Text>
        <Text variant="body2">See this product's impact assessment across eight core metrics</Text>
      </Stack>
      <BarChart width={1300} height={280} data={data} margin={{ top: 34, right: 0, left: -32, bottom: 20 }}>
        <YAxis tick={tickDefaultProps} type="number" domain={[0, 10]} ticks={[0, 10]} />
        <XAxis tick={tickDefaultProps} dataKey="name" />
        <Bar dataKey="value">
          {data.map((d) => (
            <Cell key={d.name} fill={d.color} />
          ))}
        </Bar>
      </BarChart>
    </Stack>
  )
}
