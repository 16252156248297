import { useCallback, useEffect, useState } from 'react'
import { searchSalesCategoryAverageScore } from '../utils/productSearch'

export const useSalesCategoryAverageScore = (metric: string, category: string = null) => {
  const [avgScore, setAvgScore] = useState<number>(0)

  const checkAvgScore = useCallback(async () => {
    const adjustedMetric = metric === 'howgood_total_impact' ? 'impact_score_total' : metric

    if (metric) {
      const result = await searchSalesCategoryAverageScore(adjustedMetric, category)
      setAvgScore(Math.round(result))
    }
  }, [metric, category])

  useEffect(() => {
    checkAvgScore()
  }, [checkAvgScore])

  return avgScore
}
