import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'
import { theme } from './theme'

interface Props {
  width: number
  animate?: boolean
}
interface Styles {
  width: number
  backgroundColor: string
  animate: boolean
}

const useStyles = createUseStyles<string, Styles>({
  wrapper: {
    WebkitAppearance: 'none',
    border: 'none',
    display: ['-ms-flexbox', 'flex'],
    height: '100%',
    overflow: 'hidden',
    fontSize: '0.75rem',
    backgroundColor: theme.palette.impact[0],
    borderRadius: '20px',
    width: '100%',
  },
  bar: {
    width: ({ width }) => `${Math.min(width, 100)}%`,
    height: '100%',
    display: ['-ms-flexbox', 'flex'],
    msFlexDirection: 'column',
    flexDirection: 'column',
    msFlexPack: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    color: '#fff',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    transition: ({ animate }) => (animate ? 'width 0.6s ease' : 'none'),
    borderRadius: '20px',
    backgroundColor: ({ backgroundColor }) => backgroundColor,
  },
})

export const ProgressBar: FC<Props> = ({ width = 0, animate = false }) => {
  const backgroundColor = theme.palette.impact[Math.ceil(Math.min(width, 100) / 10)]
  const classes = useStyles({ animate, width, backgroundColor })

  return (
    <div className={classes.wrapper}>
      <div aria-label="Progress bar" className={classes.bar} />
    </div>
  )
}
