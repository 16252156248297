import queryString from 'query-string'
import { fetchHelper, fetchHelperV2 } from '@/api'
import { IUser, Workspace, Provider } from '@/records'
import { AuthPayload } from '@/state/user'
import { getSessionData, removeSessionData } from '@/utils/sessionData'

interface FetchUser extends Omit<IUser, 'permissions'> {
  permissions: string[]
  workspaces: Workspace[]
}
export async function fetchUser(): Promise<FetchUser> {
  const sessionData = getSessionData()
  // check to see if token is expired before hitting self endpoint
  if (sessionData?.expires && new Date(Date.now()) > new Date(sessionData.expires)) {
    removeSessionData()
    location.reload()
  }
  let user: FetchUser
  try {
    user = await fetchHelperV2<FetchUser>({ url: 'accounts/users/self/' })
  } catch (err) {
    if (err.message.includes('404') && 'auth' in localStorage) {
      removeSessionData()
      location.reload()
    } else {
      console.error(err)
      throw err
    }
  }

  return user
}

interface UserMeta {
  username?: string
  password?: string
  refresh_token?: string
  token?: string
  client_id?: string
  grant_type?: string
  otp?: string // MFA one-time password (OTP)
}

interface FetchToken {
  access_token: string
  expires_in: number
  token_type: string
  scope: string
  refresh_token: string
}
/**
 * If the user does not have MFA enabled, this function will return the access token.
 * If the user does have MFA enabled BUT does not have an authentication app (e.g. Google Authenticator) configured,
 * this function will return 'MFA_REQUIRED'. If the user does have MFA enabled AND has an authentication app configured,
 * this function will return 'APP_REQUIRED'.
 * @param meta (UserMeta)
 * @returns the access token OR 'MFA_REQUIRED' OR 'APP_REQUIRED'
 */
export const fetchToken = async (meta: UserMeta): Promise<FetchToken | 'MFA_REQUIRED' | 'APP_REQUIRED'> => {
  // If user is configured for MFA, the initial login request is designed to fail and return a 412 status code.
  // We use this to trigger the MFA challenge and then retry the login request with the one-time password.
  try {
    const res = await fetchHelper(
      `/o/token/`,
      'POST',
      { Authorization: null, 'Content-Type': 'application/x-www-form-urlencoded' },
      queryString.stringify(meta)
    )
    return res as FetchToken
  } catch (e) {
    console.error(e)
    if (e instanceof Error && e.message.includes('412')) {
      return e.message.includes('MFA') ? 'MFA_REQUIRED' : 'APP_REQUIRED'
    }
    throw e
  }
}

interface EmailCheck {
  auth_provider: Provider
  organization_id: string
}
export const emailCheck = async (username: string) => {
  return await fetchHelperV2<EmailCheck>({
    url: 'auth0/provider/',
    method: 'POST',
    headers: { Authorization: null, 'Content-Type': 'application/json' },
    body: JSON.stringify({ email: username }),
  })
}

interface TokenChangeProps {
  token: string
  client_id: string
}
export const tokenChange = async ({ token, client_id }: TokenChangeProps) =>
  await fetchHelperV2<AuthPayload>({
    url: 'auth0/token/',
    method: 'POST',
    headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
    body: JSON.stringify({ client_id }),
  })

export const revokeToken = async (meta: UserMeta): Promise<void> =>
  await fetchHelper(
    `/o/revoke_token/`,
    'POST',
    {
      Accept: null,
      Authorization: null,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    queryString.stringify(meta),
    false
  )

export const addTotp = async (): Promise<string> => {
  const res = await fetchHelperV2<Response>({ url: 'accounts/users/totp/', method: 'PUT', parse: false })
  return res.text()
}

export const deleteTotp = async () => {
  try {
    await fetchHelperV2({ url: 'accounts/users/totp/', method: 'DELETE', parse: false })
  } catch (e) {
    if (!e.message.includes('404')) {
      throw e
    }
  }
}
