import React, { FC, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box,
  CircularProgress,
  IconButton,
  Switch,
  Text,
  InlineSelect,
  Tooltip,
  Stack,
  FormControlLabel,
} from '@howgood/design'
import { faRotateLeft, faArrowDownBigSmall } from '@fortawesome/pro-regular-svg-icons'

import { selectCanEditProduct } from '@/state/productOverview'
import {
  selectDisplayedIngredientList,
  selectIsRebalancingIngredients,
  selectIsFormulaOveriewLoading,
} from '@/state/recipe'
import {
  selectExpanded,
  selectPageView,
  setExpandAll,
  setCollapseAll,
  DRAFT,
  SAVED,
  setAbatementStrategiesDrawer,
} from '@/state/pageSettings'
import { selectGridSettings, setGridSettings } from '@/state/preferences'
import { AddIngredientButton } from './AddIngredientButton'
import { ColumnSelect } from './ColumnSelect'
import { selectAbatementSplit } from '@/state/splitio'
import { AbatementStrategies } from '../../AbatementStrategies/AbatementStrategies'
import { selectIsSupplierConnect } from '@/state/organization'

export const Toolbar: FC = () => {
  const dispatch = useDispatch()
  const ingredients = useSelector(selectDisplayedIngredientList)
  const expanded = useSelector(selectExpanded)
  const canEdit = useSelector(selectCanEditProduct)
  const view = useSelector(selectPageView)
  const isRebalancing = useSelector(selectIsRebalancingIngredients)
  const isLoading = useSelector(selectIsFormulaOveriewLoading)
  const gridSettings = useSelector(selectGridSettings)
  const isSupplierConnect = useSelector(selectIsSupplierConnect)
  const abatementEnabled = useSelector(selectAbatementSplit)

  // List of `dotPath` values for all nested ingredients
  const nestedIngredients = useMemo(
    () =>
      ingredients.filter((ingredient) => 'nested_product_id' in ingredient).map((ingredient) => ingredient.dot_path),
    [ingredients]
  )

  const handleExpandAllChanged = (checked: boolean) => {
    if (checked) {
      dispatch(setExpandAll(nestedIngredients))
    } else {
      dispatch(setCollapseAll())
    }
  }

  return (
    <Stack direction="row" flexWrap="wrap" alignItems="center" gap={2} mx={1.5} mt={0} mb={1}>
      <Stack width={200}>{canEdit && view === DRAFT && <AddIngredientButton />}</Stack>
      <Stack
        direction="row"
        justifyContent="start"
        flexGrow={1}
        gap={1}
        sx={{ visibility: isRebalancing || isLoading ? 'visible' : 'hidden' }}
      >
        <CircularProgress size={20} />
        <Text>{isRebalancing ? 'Redistributing' : 'Fetching scores'}</Text>
      </Stack>
      {abatementEnabled && view === SAVED && (
        <>
          <FormControlLabel
            control={
              <Box mr={0.25}>
                <IconButton
                  size="small"
                  icon={faArrowDownBigSmall}
                  onClick={() => dispatch(setAbatementStrategiesDrawer(true))}
                />
              </Box>
            }
            label="Abatement strategies"
            labelPlacement="end"
          />
          <AbatementStrategies />
        </>
      )}
      {!isSupplierConnect && (
        <Box sx={{ fontSize: 16 }}>
          <InlineSelect
            placeholder={
              gridSettings.scoringMethod === 'perKilogram' ? 'Impact per Kilogram' : 'Impact by Contribution'
            }
            items={[
              {
                label: 'Impact per Kilogram',
                onClick: () =>
                  dispatch(
                    setGridSettings({
                      ...gridSettings,
                      scoringMethod: 'perKilogram',
                    })
                  ),
              },
              {
                label: 'Impact by Contribution',
                onClick: () =>
                  dispatch(
                    setGridSettings({
                      ...gridSettings,
                      scoringMethod: 'contributionWeighted',
                    })
                  ),
              },
            ]}
          />
        </Box>
      )}
      {nestedIngredients.length > 0 && (
        <FormControlLabel
          control={
            <Switch
              checked={expanded.length === nestedIngredients.length}
              onChange={(e) => handleExpandAllChanged(e.target.checked)}
            />
          }
          label={expanded.length < nestedIngredients.length ? 'Expand All' : 'Collapse All'}
          labelPlacement="start"
        />
      )}
      {!isSupplierConnect && (
        <>
          <FormControlLabel
            control={
              <Switch
                checked={gridSettings.showValues}
                onChange={() =>
                  dispatch(
                    setGridSettings({
                      ...gridSettings,
                      showValues: !gridSettings.showValues,
                    })
                  )
                }
              />
            }
            label="Show Values"
            labelPlacement="start"
          />
          <Box ml={3}>
            <ColumnSelect />
          </Box>
          <Tooltip title="Display all available impact types and reset columns to default widths">
            <FormControlLabel
              control={
                <Box ml={0.25} mr={1}>
                  <IconButton
                    size="small"
                    color="primary"
                    icon={faRotateLeft}
                    onClick={() => dispatch(setGridSettings({ ...gridSettings, colWidths: {}, hiddenCols: [] }))}
                  />
                </Box>
              }
              label="Reset Columns"
              labelPlacement="start"
            />
          </Tooltip>
        </>
      )}
    </Stack>
  )
}
