import React, { FC, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Checkbox, MenuItem, Select, SelectChangeEvent, Text, theme, Tooltip } from '@howgood/design'
import { selectDisplayedProductStandards, updateIngredient } from '@/state/recipe'
import { RecipeIngredient } from '@/state/recipe/recipe.state'

interface StandardsSelectProps {
  ingredient: RecipeIngredient
}

export const StandardsSelect: FC<StandardsSelectProps> = ({ ingredient }) => {
  const dispatch = useDispatch()
  const productStandards = useSelector(selectDisplayedProductStandards)
  const availableStandards = useMemo(() => ingredient.availableStandards ?? [], [ingredient.availableStandards])
  const availableStandardIds = availableStandards.map((standard) => standard.identifier)
  const invalidStandardsString = useMemo(
    () =>
      ingredient.standards
        .filter((standard) => !availableStandardIds.includes(standard.identifier))
        .map((standard) => standard.title)
        .join(', '),
    [availableStandardIds, ingredient.standards]
  )

  // On load, or when the location changes, see if all selected standards are valid for the selected location
  const allStandardsAreValid = useMemo(() => {
    return ingredient.standards
      .map((standard) => standard.identifier)
      .every((standard) => availableStandardIds.includes(standard))
  }, [ingredient.standards, availableStandardIds])

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const selectedIdentifiers = event.target.value as string[]
    // Update the ingredient only with standards that are valid for the selected location
    // There may be some that are invalid if the user switched the source location
    // If user clicked menu item to remove them, that item is ignored and we end up with just the valid items
    const updatedStandards = {
      standards: availableStandards.filter(
        (standard) =>
          selectedIdentifiers.includes(standard.identifier) &&
          availableStandards.find((item) => item.identifier === standard.identifier)
      ),
    }
    dispatch(updateIngredient(ingredient, updatedStandards))
  }

  // Includes product level standards
  const standardsString = useMemo(() => {
    const setByProduct = availableStandards.filter((available) =>
      productStandards.some((productStandard) => productStandard.identifier === available.identifier)
    )
    return [...ingredient.standards, ...setByProduct].map((standard) => standard.title).join(', ')
  }, [ingredient.standards, availableStandards, productStandards])

  return (
    <Tooltip
      title={availableStandards.length === 0 && allStandardsAreValid ? <Text>No available standards</Text> : null}
    >
      <Select
        displayEmpty
        id={`source-standard-select-${ingredient.index}`}
        aria-label="Select source standards"
        color="secondary"
        multiple
        disabled={availableStandards.length === 0 && allStandardsAreValid}
        value={ingredient.standards.map((standard) => standard.identifier)}
        renderValue={() => (
          <Tooltip title={<Text>{allStandardsAreValid ? standardsString : 'Includes invalid standards'}</Text>}>
            <Box sx={{ cursor: 'pointer' }}>
              <Text textOverflow="ellipsis" noWrap>
                {standardsString}
              </Text>
            </Box>
          </Tooltip>
        )}
        onChange={handleChange}
        fullWidth
        sx={{
          '& .MuiSelect-select': {
            py: 0.25,
            bgcolor: 'white',
            border: allStandardsAreValid ? 1 : 2,
            borderColor: allStandardsAreValid ? 'secondary.light' : 'error.main',
          },
        }}
        MenuProps={{
          id: 'source-standard-menu',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
      >
        {(allStandardsAreValid ? availableStandards : [...availableStandards, null]).map((standard) => {
          // If some standards are invalid, include a null in the list and add an option to clear invalid items
          if (!standard) {
            return (
              <MenuItem
                key="clear-invalid-standards"
                value="clear-invalid-standards"
                sx={{
                  pt: 1,
                  borderTop: availableStandards.length ? 1 : 0,
                  borderColor: 'secondary.light',
                  maxWidth: 300,
                  whiteSpace: 'normal',
                }}
              >
                <Text color={theme.palette.error.dark}>Clear invalid standards ({invalidStandardsString})</Text>
              </MenuItem>
            )
          }
          // When wrapping the tooltip around the menu item it no longer triggers the onChange for
          // the Select, that's the reason for writing it out like this twice
          return productStandards.some((item) => item.identifier === standard.identifier) ? (
            <Tooltip
              key={standard.id}
              title={<Text>This standard was set across the entire formula in Other Details.</Text>}
            >
              <div>
                <MenuItem key={standard.id} value={standard.identifier} sx={{ pl: 0.5, pr: 1, py: 0.25 }} disabled>
                  <Checkbox
                    id={`${standard.identifier}-checkbox`}
                    aria-label={standard.title}
                    color="secondary"
                    size="small"
                    checked
                  />
                  <Text color={invalidStandardsString.includes(standard.identifier) ? theme.palette.error.dark : null}>
                    {standard.title}
                  </Text>
                </MenuItem>
              </div>
            </Tooltip>
          ) : (
            <MenuItem key={standard.id} value={standard.identifier} sx={{ pl: 0.5, pr: 1, py: 0.25 }}>
              <Checkbox
                id={`${standard.identifier}-checkbox`}
                aria-label={standard.title}
                color="secondary"
                size="small"
                checked={ingredient.standards.some((item) => item.identifier === standard.identifier)}
              />
              <Text color={invalidStandardsString.includes(standard.identifier) ? theme.palette.error.dark : null}>
                {standard.title}
              </Text>
            </MenuItem>
          )
        })}
      </Select>
    </Tooltip>
  )
}
