import { Workspace } from '@/records'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Field } from '@/constants/impactScore'
import {
  AggsByView,
  fetchTags,
  getSelectedMetricData,
  PortfolioTotals,
  initializeDashboard,
} from './portfolioDashboard.thunk'
import {
  AVAILABLE_VIEWS,
  Basis,
  BASIS_TYPES,
  CHART_TYPES,
  ChartType,
  CustomMetric,
  PortfolioImpactDashboardSettings,
  PRODUCT_TYPES,
  ProductType,
  View,
} from './portfolioDashboard.types'
import { allWorkspacesItem } from '@/features/ProductDashboard/state/productDashboard'
import { customMetrics } from './metrics'

export interface State {
  availableMetrics: Field[]
  isLoading: boolean
  aggregations: AggsByView
  portfolioTotals: PortfolioTotals
  settings: PortfolioImpactDashboardSettings
  selectedWorkspace: Workspace
  availableTags: string[]
  selectedTags: string[]
  resultsFilters: string[]
  customMetrics: CustomMetric[]
  hideTagPrefix: boolean
}

export const initialState: State = {
  availableMetrics: [],
  isLoading: false,
  aggregations: null,
  portfolioTotals: null,
  settings: {
    productType: 'products',
    view: 'workspaces',
    metric: 'carbon_footprint_farm_to_gate_impact',
    basis: 'kg',
    chartType: 'bar',
  },
  selectedWorkspace: allWorkspacesItem,
  availableTags: [],
  selectedTags: [],
  resultsFilters: [],
  customMetrics: customMetrics,
  hideTagPrefix: false,
}

const portfolioDashboardSlice = createSlice({
  name: 'portfolioDashboard',
  initialState,
  reducers: {
    setSelectedMetric: (state, action: PayloadAction<Field>) => {
      state.settings.metric = action.payload
    },
    setProductType: (state, action: PayloadAction<ProductType>) => {
      state.settings.productType = action.payload
    },
    setView: (state, action: PayloadAction<View>) => {
      state.settings.view = action.payload
    },
    setBasis: (state, action: PayloadAction<Basis>) => {
      state.settings.basis = action.payload
    },
    setChartType: (state, action: PayloadAction<ChartType>) => {
      state.settings.chartType = action.payload
    },
    setSelectedTags: (state, action: PayloadAction<string[]>) => {
      state.selectedTags = action.payload
    },
    setResultsFilters: (state, action: PayloadAction<string[]>) => {
      state.resultsFilters = action.payload
    },
    setSelectedWorkspace: (state, action: PayloadAction<Workspace>) => {
      state.selectedWorkspace = action.payload
    },
    setHideTagPrefix: (state, action: PayloadAction<boolean>) => {
      state.hideTagPrefix = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(initializeDashboard.fulfilled, (state, action) => {
      const { dashboardSettings, availableMetrics } = action.payload
      state.settings = {
        ...dashboardSettings,
        productType: PRODUCT_TYPES.includes(dashboardSettings?.productType)
          ? dashboardSettings.productType
          : PRODUCT_TYPES[0],
        view: AVAILABLE_VIEWS.includes(dashboardSettings?.view) ? dashboardSettings.view : AVAILABLE_VIEWS[0],
        basis: BASIS_TYPES.includes(dashboardSettings?.basis) ? dashboardSettings.basis : BASIS_TYPES[0],
        chartType: CHART_TYPES.includes(dashboardSettings?.chartType) ? dashboardSettings.chartType : CHART_TYPES[0],
        // `metric` has already been confirmed in the `initializeDashboard` thunk
      }
      state.availableMetrics = availableMetrics
    })

    builder.addCase(getSelectedMetricData.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getSelectedMetricData.fulfilled, (state, action) => {
      state.isLoading = false
      const { aggregationsByView, totals } = action.payload
      state.aggregations = aggregationsByView
      state.portfolioTotals = totals
    })
    builder.addCase(getSelectedMetricData.rejected, (state, action) => {
      console.error(action.error)
      state.isLoading = false
    })

    builder.addCase(fetchTags.fulfilled, (state, action) => {
      state.availableTags = action.payload
    })
    builder.addCase(fetchTags.rejected, (state, action) => {
      console.error(action.error)
      state.availableTags = []
    })
  },
})

export const {
  setSelectedMetric,
  setProductType,
  setView,
  setBasis,
  setChartType,
  setSelectedTags,
  setResultsFilters,
  setSelectedWorkspace,
  setHideTagPrefix,
} = portfolioDashboardSlice.actions

export const portfolioDashboardReducer = portfolioDashboardSlice.reducer
