import React, { FC, ReactNode, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getUserName, DEFAULT_USER_ID } from '@/records'
import { selectOrganization } from '@/state/organization'
import { selectUser } from '@/state/user/user.selectors'

declare const pendo: any

export const getDomain = (email: string): string => {
  if (!email) {
    return null
  }
  return email
    .substring(email.lastIndexOf('@') + 1)
    .split('.')
    .slice(-2)
    .join('.')
}
interface Props {
  children: ReactNode
}
export const PendoProvider: FC<Props> = ({ children }) => {
  const user = useSelector(selectUser)
  const organization = useSelector(selectOrganization)
  const userFullName = getUserName(user)

  useEffect(() => {
    if (user.authenticated && user.id !== DEFAULT_USER_ID) {
      pendo.initialize()
      pendo.updateOptions({
        visitor: {
          id: user.id.toString(),
          email: user.email,
          full_name: userFullName,
        },
        account: {
          // Account id changed from domain to org name 12/1/22
          id: organization.organizationName,
          domain: getDomain(user.email),
          customerTier: organization.tier,
          ...organization,
        },
      })
    }
  }, [user.authenticated, user.id, user.email, userFullName, organization])

  return <>{children}</>
}
