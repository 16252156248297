import React, { FC } from 'react'
import MuiInput, { InputProps as MuiInputProps } from '@mui/material/Input'

interface InputProps
  extends Pick<
    MuiInputProps,
    'id' | 'type' | 'placeholder' | 'value' | 'error' | 'size' | 'fullWidth' | 'onChange' | 'onClick'
  > {
  color?: 'primary' | 'secondary'
}

export const Input: FC<InputProps> = ({
  id,
  type,
  placeholder,
  value,
  error,
  color,
  size,
  fullWidth,
  onChange,
  onClick,
}) => {
  return (
    <MuiInput
      id={id}
      type={type}
      placeholder={placeholder}
      value={value}
      error={error}
      color={color}
      size={size}
      fullWidth={fullWidth}
      onChange={onChange}
      onClick={onClick}
    />
  )
}
