import React, { FC } from 'react'
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons'
import { Stack, Icon, Text, Box } from '@howgood/design'
import { BreadCrumb, BreadCrumbs } from './BreadCrumbs'
import { Year } from './Year'

interface Props {
  title: string
  breadCrumbs?: BreadCrumb[]
  icon?: IconDefinition
  year?: number
  ['aria-label']?: string
  ['data-testid']?: string
  titleBadge?: React.ReactNode
  actions?: React.ReactNode
  breadcrumbActions?: React.ReactNode
  highlightedBreadcrumbs?: boolean
}

export const Heading: FC<Props> = ({
  title,
  icon,
  year,
  actions,
  titleBadge,
  ['aria-label']: ariaLabel,
  ['data-testid']: dataTestId,
  breadCrumbs = [],
  breadcrumbActions,
  highlightedBreadcrumbs,
}) => {
  const defaultTestId = title.replaceAll(' ', '-').toLowerCase()
  return (
    <Stack gap={1} data-testid={dataTestId || defaultTestId} aria-label={ariaLabel || title}>
      <BreadCrumbs
        highlighted={highlightedBreadcrumbs}
        breadCrumbs={breadCrumbs}
        actions={breadcrumbActions}
        data-testid={dataTestId ? `${dataTestId}-breadcrumb` : `${defaultTestId}-breadcrumb`}
      />
      <Stack direction="row" justifyContent="space-between" width="100%" mb={3}>
        <Stack direction="row" alignItems="center" gap={1} fontSize={26} overflow="hidden">
          {icon && (
            <Box mr={-0.5}>
              <Icon fontSize={20} data-testid="heading-icon" icon={icon} />
            </Box>
          )}
          {year && (
            <Year year={year} size="large" data-testid={dataTestId ? `${dataTestId}-year` : `${defaultTestId}-year`} />
          )}
          <Text
            data-testid={dataTestId ? `${dataTestId}-title` : `${defaultTestId}-title`}
            noWrap
            textWrap="elipses"
            fontSize={26}
            lineHeight="34px"
          >
            {title}
          </Text>
          {titleBadge && <Box>{titleBadge}</Box>}
        </Stack>
        {actions}
      </Stack>
    </Stack>
  )
}
