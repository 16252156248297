import React, { FC } from 'react'
import { ExternalLink } from '../../../../../packages/design/src'

interface Props {
  url: string
  filename: string
  children: React.ReactNode
  onDownload?: () => void
  ['data-testid']?: string
}

export const DownloadLink: FC<Props> = ({ url, filename, children, onDownload, ['data-testid']: dataTestId }) => {
  const handleDownload = async () => {
    const response = await fetch(url)
    const blob = await response.blob()
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = filename
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    if (onDownload) {
      onDownload()
    }
  }

  return (
    <ExternalLink data-testid={dataTestId} onClick={handleDownload} underline="hover">
      {children}
    </ExternalLink>
  )
}

DownloadLink.displayName = 'DownloadLink'
