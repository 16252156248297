import React, { FC, useEffect, useState } from 'react'
import { Button, Stack, Dialog, DialogTitle, DialogContent } from '@howgood/design'
import { faLink, faCheck } from '@fortawesome/pro-regular-svg-icons'
import {
  CollectVendorDataContent,
  DataPortalLink,
  DATA_PORTAL_LINK_CONSUMER,
  DATA_PORTAL_LINK_RETAILER,
} from './CollectVendorDataContent'
import { DataPortalLinkCopyToClipboard } from './DataPortalLinkCopyToClipboard'
import { useContentfulRequestVendorData } from '@/contentfully/hooks'

interface Props {
  onClose: () => void
}

export const RequestVendorDataDialog: FC<Props> = ({ onClose }) => {
  const [dataPortalLinkType, setDataPortalLinkType] = useState<DataPortalLink>(null)
  const [primaryButtonText, setPrimaryButtonText] = useState('Select an option above')
  const [primaryButtonIcon, setPrimaryButtonIcon] = useState(faLink)

  const content = useContentfulRequestVendorData('request-vendor-data')

  const handleCopy = () => {
    setPrimaryButtonText('Link copied')
    setPrimaryButtonIcon(faCheck)
  }

  useEffect(() => {
    if (dataPortalLinkType === DATA_PORTAL_LINK_CONSUMER) {
      setPrimaryButtonText('Copy link to send to ingredient vendors')
      setPrimaryButtonIcon(faLink)
    }
    if (dataPortalLinkType === DATA_PORTAL_LINK_RETAILER) {
      setPrimaryButtonText('Copy link to send to CPG brands')
      setPrimaryButtonIcon(faLink)
    }
  }, [dataPortalLinkType])

  if (!content) {
    return null
  }

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>Request Vendor Data</DialogTitle>
      <DialogContent>
        <CollectVendorDataContent
          dataPortalLinkType={dataPortalLinkType}
          handleDataPortalLinkTypeChange={(value: DataPortalLink) => {
            setDataPortalLinkType(value)
          }}
          content={content.simpleVendorData}
        />
        <Stack gap={1} sx={{ mb: 0.5, mt: 3 }}>
          <DataPortalLinkCopyToClipboard
            linkType={dataPortalLinkType}
            onCopy={handleCopy}
            buttonStartIcon={primaryButtonIcon}
            buttonText={primaryButtonText}
          />
          <Button id="close-button" aria-label="Close" onClick={onClose}>
            Close
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}
