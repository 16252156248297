import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-premium'
import { Stack } from '@howgood/design'
import { selectExportPaywallSplit } from '@/state/splitio'
import { usePendoTrack } from '@/hooks'

export const GridToolbar: FC = () => {
  const exportPaywallEnabled = useSelector(selectExportPaywallSplit)
  const pendoTrack = usePendoTrack()

  return (
    <Stack direction="row" alignItems="center" justifyContent="end">
      <GridToolbarContainer>
        <GridToolbarExport
          printOptions={{ disableToolbarButton: true }}
          csvOptions={{ disableToolbarButton: exportPaywallEnabled }}
          size="small"
          sx={{ fontSize: '16px' }}
          onClick={() => pendoTrack('Formula: Ingredient Export Clicked')}
        />
      </GridToolbarContainer>
    </Stack>
  )
}
