import React, { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { faArrowUpFromBracket } from '@fortawesome/pro-regular-svg-icons'
import { Button } from '@howgood/design'
import { SustainabilityScorecardDialog } from '@/components/ProductImpact/ExportProductOverview/SustainabilityScorecardDialog'
import { setLiteUserPaywallDialogState } from '@/state/liteUserPaywallDialog'
import { selectIsSupplierConnect } from '@/state/organization'

export const ExportSustainabilityScoreboardButton: FC = () => {
  const isSupplierConnect = useSelector(selectIsSupplierConnect)
  const dispatch = useDispatch()
  const [sustainabilityScoreboardDialogOpen, setSustainabilityScoreboardDialog] = useState(false)

  return (
    <>
      <Button
        data-pendo-id="ExportSustainabilityScoreboard"
        data-testid="export-sustainability-scorecard-button"
        startIcon={faArrowUpFromBracket}
        size="small"
        onClick={() => {
          if (isSupplierConnect) {
            dispatch(
              setLiteUserPaywallDialogState({
                open: true,
              })
            )
            return
          }
          setSustainabilityScoreboardDialog(!sustainabilityScoreboardDialogOpen)
        }}
      >
        Export Scorecard
      </Button>
      {sustainabilityScoreboardDialogOpen && (
        <SustainabilityScorecardDialog
          onClose={() => setSustainabilityScoreboardDialog(false)}
          enableClaims={false}
          impactData={null}
        />
      )}
    </>
  )
}
