import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Grid } from '@howgood/design'

import { GridItemTitle } from '../GridItemTitle'
import { panelStyles } from '../panelStyles'
import { LocationAutocomplete } from './LocationAutocomplete'
import { UnitSize } from './UnitSize'
import { UnitsSold } from './UnitsSold'
import { RequiresCooking } from './RequiresCooking'
import { RequiresColdStorage } from './RequiresColdStorage'
import { ManufacturingType } from './ManufacturingType'
import { selectIsSupplierConnect } from '@/state/organization'
import { selectIsProcurement } from '@/state/router'

export const SalesDistribution: FC = () => {
  const isSupplierConnect = useSelector(selectIsSupplierConnect)
  const isProcurement = useSelector(selectIsProcurement)
  return (
    <Grid container spacing={1} {...panelStyles.grid}>
      {!isSupplierConnect && (
        <>
          <Grid item xs={3}>
            <UnitSize />
          </Grid>
          <Grid item xs={3}>
            <UnitsSold />
          </Grid>
        </>
      )}
      <Grid item xs={3}>
        <RequiresCooking />
      </Grid>
      <Grid item xs={3}>
        <RequiresColdStorage />
      </Grid>
      <Grid item xs={3}>
        <ManufacturingType />
      </Grid>
      <Grid item xs={3}>
        <GridItemTitle title="Manufacturing Region" tooltipText="Required for cradle-to-gate carbon metrics" />
        <LocationAutocomplete field="manufacturing_region" />
      </Grid>
      {!isSupplierConnect && (
        <>
          <Grid item xs={3}>
            <GridItemTitle title="Warehouse Region" tooltipText="Required for cradle-to-grave carbon metrics" />
            <LocationAutocomplete field="storage_region" />
          </Grid>
          <Grid item xs={3}>
            <GridItemTitle
              title={`${isProcurement ? 'Material' : 'Product'} Destination Region`}
              tooltipText="Required for cradle-to-grave carbon metrics"
            />
            <LocationAutocomplete field="retail_region" />
          </Grid>
        </>
      )}
    </Grid>
  )
}
