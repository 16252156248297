import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { selectDisplayedIngredientList } from '@/state/recipe'
import { RecipeIngredient } from '@/state/recipe/recipe.state'

export const Ingredients: FC = () => {
  const ingredients = useSelector(selectDisplayedIngredientList)

  return (
    <>
      <h3>Ingredients</h3>
      <table>
        <tbody>
          <tr>
            <th style={{ width: '25%' }}>Ingredient Name</th>
            <th style={{ width: '25%' }}>Sourcing Standards</th>
            <th style={{ width: '25%' }}>Sourcing Location</th>
            <th style={{ width: '25%' }}>Processing Location</th>
          </tr>
          {Object.entries(ingredients).map(([_, ingredient], index) => {
            const ingredientName = ((ingredient.name ?? ingredient.token) || '').toLowerCase()
            const standards = 'base_id' in ingredient ? ingredient.standards?.map((opt) => opt.title).join(', ') : []
            return (
              <tr key={`${ingredientName}_${index}`}>
                <td>{ingredientName}</td>
                <td>{standards}</td>
                <td>{(ingredient as RecipeIngredient).origin_location_name}</td>
                <td>{(ingredient as RecipeIngredient).processing_location_name}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </>
  )
}
