import { batch } from 'react-redux'
import { AnyAction } from 'redux'
import { createAsyncThunk, ThunkAction } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'
import {
  InventoriesResponse as _InventoriesResponse,
  fetchProductImpactScore,
  fetchProduct,
  fetchHelperV2,
  Standard,
  STANDARD_CATEGORY_CERTIFICATION,
  fetchRegions,
  getRegionsQueryString,
} from '@/api'
import { addRecipe, initializeRecipe, setIngredientsMoved, setIsRebalancing, setLockedImpactData } from './recipe.slice'
import {
  generateRecipe,
  getMaxImpactValues,
  getNonNullFields,
  getParentDotPath,
  addIngredientsToList,
  generateProductIngredientList,
  generateSimpleIngredient,
  removeIngredientFromList,
  checkIfChild,
} from './recipe.utils'
import { ProductResponse, selectDraftWorkspaces, selectSavedProductInfo } from '@/state/productOverview'
import {
  Recipe,
  RecipeIngredient,
  RecipeNestedIngredient,
  ImpactData,
  ESIngredientSource,
  ESRecipeNestedIngredientSource,
  ESRecipeIngredientSource,
  IngredientStandard,
  initialImpactData,
} from './recipe.state'
import { selectOriginLocations } from '@/state/originLocations'
import { getOriginLocations } from '../originLocations/originLocations.thunk'
import {
  fetchImpactScores,
  fetchPackaging,
  fetchTransportation,
  loadIngredients,
  rebalanceWeights,
} from './recipe.requests'
import {
  selectDisplayedProductStandards,
  selectDisplayedRecipe,
  selectDraftImpactData,
  selectDraftRecipe,
  selectDisplayedWeightOption,
} from './recipe.selectors'
import { selectDefaultWorkspaceIds, selectWorkspaces } from '../workspaces'
import { addMessage } from '@/state/messages'
import { IProductStandard, PERCENTAGES, WeightOption } from '@/records'
import { AppState } from '@/store'
import { setCollapseAll } from '../pageSettings'
import { handleThunkError } from '../messages/messages.thunk'

export interface InventoriesResponse extends Omit<_InventoriesResponse, 'id'> {
  id: number
}

export const getRecipe = createAsyncThunk<Recipe, ProductResponse, { state: AppState }>(
  'recipe/getRecipe',
  async (product, { getState, dispatch }) => {
    const state = getState()
    const isThirdParty = product.is_foreign
    const draftWorkspaces = product.workspaces
    const defaultWorkspaces = selectDefaultWorkspaceIds(state)
    const productIsLocked = product.locked_claims_timestamp
    const id = product.id

    const [
      impactDataResponse,
      lockedImpactDataResponse,
      packagingResponse,
      transportationResponse,
      ingredientsResponse,
    ] = await Promise.allSettled([
      fetchProductImpactScore(id, 'live'),
      // Only fetch locked claims impact data if product is locked
      productIsLocked ? fetchProductImpactScore(id, 'locked') : Promise.resolve(null),
      fetchPackaging(id),
      fetchTransportation(id),
      loadIngredients(id, isThirdParty),
    ])

    const responses = [
      { response: impactDataResponse, type: 'live impact scores' },
      { response: lockedImpactDataResponse, type: 'locked impact scores' },
      { response: packagingResponse, type: 'packaging' },
      { response: transportationResponse, type: 'transportation' },
      { response: ingredientsResponse, type: 'ingredients' },
    ]

    responses.forEach(({ response, type }) => {
      if (response.status === 'rejected') {
        dispatch(
          handleThunkError({
            error: new Error(response.reason),
            message: `There was an issue fetching the ${type} for this formula. Please refresh the page or contact support (support@howgood.com) to resolve this issue.`,
            actionType: 'recipe/getRecipe',
            context: { productId: id, type },
          })
        )
      }
    })

    const packaging = packagingResponse.status === 'rejected' ? [] : packagingResponse.value
    const transportation =
      transportationResponse.status === 'rejected' ? { mtr: [], mts: [] } : transportationResponse.value
    const ingredients = ingredientsResponse.status === 'rejected' ? [] : ingredientsResponse.value
    const lockedImpactData = lockedImpactDataResponse.status === 'rejected' ? null : lockedImpactDataResponse.value
    const impactData =
      impactDataResponse.status === 'rejected'
        ? { ...initialImpactData }
        : {
            ...impactDataResponse.value,
            maxValues: isThirdParty || !product ? {} : getMaxImpactValues(impactDataResponse.value),
            nonNullFields: getNonNullFields(impactDataResponse.value),
            timestamp: new Date().toString(),
          }

    // Get available certification standards for all simple top-level ingredients based on the saved origin location
    let standardsResponses: Standard[][] = []
    try {
      standardsResponses = await Promise.all(
        ingredients.map((ingredient) => {
          if (!ingredient.nested_product && ingredient.ingredient) {
            if (!ingredient.ingredient.origin_location) {
              const ingredientRef = ingredient.ingredient.base?.name || ingredient.id
              dispatch(
                handleThunkError({
                  message: `No origin location exists on ingredient ${ingredientRef}. Please refresh the page or contact support (support@howgood.com) to resolve this issue.`,
                  error: new Error(`No origin location exists on ingredient ${ingredientRef}`),
                  actionType: 'recipe/getRecipe',
                  context: { ingredients },
                })
              )
              return Promise.resolve(null)
            }
            if (!ingredient.ingredient.base) {
              const ingredientRef = ingredient.id
              dispatch(
                handleThunkError({
                  message: `No base exists on ingredient ${ingredientRef}. Please refresh the page or contact support (support@howgood.com) to resolve this issue.`,
                  error: new Error(`No base exists on ingredient ${ingredientRef}`),
                  actionType: 'recipe/getRecipe',
                  context: { ingredients },
                })
              )
              return Promise.resolve(null)
            }
            return fetchHelperV2<Standard[]>({
              url: `standards/?base_id=${ingredient.ingredient.base.id}&origin_location_id=${ingredient.ingredient.origin_location.id}&category=${STANDARD_CATEGORY_CERTIFICATION}`,
            })
          }
          return Promise.resolve(null)
        })
      )
    } catch (error) {
      dispatch(
        handleThunkError({
          message:
            'There was an issue fetching one or more of the available ingredient standards. Please refresh the page or contact support (support@howgood.com) to resolve this issue.',
          error,
          actionType: 'recipe/getRecipe',
          context: { ingredients },
        })
      )
    }

    const availableStandards: IngredientStandard[][] = standardsResponses.map((response) => {
      if (response) {
        return response.map((standard) => {
          return {
            id: standard.id,
            title: standard.title,
            identifier: standard.identifier,
          }
        })
      }
      return null
    })

    const recipe = generateRecipe({
      product,
      packaging,
      transportation,
      ingredients,
      availableStandards,
    })

    batch(() => {
      const baseIds = ingredients.reduce((acc: number[], ingredient) => {
        // Only need this info for top level ingredients
        const baseId = ingredient.flat_position.length === 1 && ingredient.ingredient?.base?.id
        return baseId ? [...acc, baseId] : acc
      }, [])
      if (baseIds.length) {
        dispatch(
          getOriginLocations({
            baseIds,
            workspaceIds: draftWorkspaces?.length
              ? [...draftWorkspaces.map((ws) => ws.id), ...defaultWorkspaces]
              : defaultWorkspaces,
          })
        )
      }
      dispatch(
        initializeRecipe({
          recipe,
          impactData,
          change: 'Product loaded',
        })
      )

      if (lockedImpactData) {
        // Send the current date since we must send something, but it isn't used
        dispatch(setLockedImpactData({ impactScore: lockedImpactData.product, time: new Date().toString() }))
      }
    })

    return recipe
  }
)

export const updateRecipeAndScores = createAsyncThunk<
  { impactData: ImpactData },
  { recipeUpdates?: Partial<Recipe>; change: string },
  { state: AppState }
>(
  'recipe/updateRecipeAndScores',
  async ({ recipeUpdates = {}, change = 'No description provided' }, { dispatch, getState }) => {
    const state = getState()
    const currentRecipe = selectDisplayedRecipe(state)
    const recipe = { ...currentRecipe, ...recipeUpdates }
    // This recipe has already had weights updated if necessary
    // Update recipe here so the user sees the immediate update
    dispatch(addRecipe({ recipe, change }))

    const currentImpactData = selectDraftImpactData(state)
    const impactData = await fetchImpactScores(recipe)

    // Update list of names of impact fields with non-null values
    const nonNullFields = Object.entries(impactData.product).reduce(
      (acc, [key, value]) => {
        return value !== null && !acc.includes(key) ? [...acc, key] : acc
      },
      [...currentImpactData.nonNullFields]
    )

    const updatedImpactData = {
      ...impactData,
      maxValues: getMaxImpactValues(impactData),
      nonNullFields: [...new Set([...currentImpactData.nonNullFields, ...nonNullFields])],
      timestamp: new Date().toString(),
    }

    return { impactData: updatedImpactData }
  }
)

const rebalanceWeightsUpdateRecipe = (
  recipe: Recipe,
  change: string
): ThunkAction<void, AppState, Recipe, AnyAction> => async (dispatch) => {
  dispatch(setIsRebalancing(true))
  try {
    const newRecipe = await rebalanceWeights({ ...recipe, timestamp: new Date().toString() })
    dispatch(setIsRebalancing(false))
    dispatch(
      updateRecipeAndScores({
        recipeUpdates: newRecipe,
        change,
      })
    )
  } catch (e) {
    dispatch(setIsRebalancing(false))
    dispatch(addMessage({ message: 'There was an error redistributing ingredient weights', severity: 'error' }))
    dispatch(
      updateRecipeAndScores({
        recipeUpdates: { ...recipe, timestamp: new Date().toString() },
        change,
      })
    )
  }
}

interface AddIngredientAtIndex {
  source: ESIngredientSource
  position: number
  index: number
  ingredientList: (RecipeIngredient | RecipeNestedIngredient)[]
  weight?: number
  isNestedIngredient?: boolean
  isAutoWeight: boolean
  inputWeightUnit: WeightOption
}

export const addIngredientAtIndex = ({
  source,
  position,
  index,
  ingredientList,
  weight = 0,
  isNestedIngredient,
  isAutoWeight,
  inputWeightUnit,
}: AddIngredientAtIndex): ThunkAction<void, AppState, Recipe, AnyAction> => async (dispatch, getState) => {
  const state = getState()
  const product = selectSavedProductInfo(state)
  const originLocations = selectOriginLocations(state)
  const draftWorkspaces = selectDraftWorkspaces(state)
  const recipe = selectDraftRecipe(state)
  const defaultWorkspaces = selectDefaultWorkspaceIds(state)

  let ingredientsToAdd = []

  // pk exists on nested ingredients only
  // pk doesn't exist when loading nested product based on url search param
  if ('pk' in source || isNestedIngredient) {
    const workspaces = selectWorkspaces(state)
    try {
      const ingredients = await generateProductIngredientList(
        source as ESRecipeNestedIngredientSource,
        position,
        index,
        workspaces,
        weight,
        { id: product.id, name: product.name },
        isAutoWeight,
        inputWeightUnit
      )
      ingredientsToAdd = ingredients
    } catch (e) {
      dispatch(
        addMessage({
          message: `There was an error adding ${source.name ??
            'that ingredient'}. Please try again or contact support (support@howgood.com) to resolve this issue.`,
          severity: 'error',
        })
      )
      return
    }
  } else {
    const ingredient = generateSimpleIngredient(
      source as ESRecipeIngredientSource,
      position,
      index,
      weight,
      {
        id: product.id,
        name: product.name,
      },
      isAutoWeight,
      inputWeightUnit
    )

    // Get the available certification standards for this ingredient using the default origin location
    const standardsResponse = await fetchHelperV2<Standard[]>({
      url: `standards/?base_id=${ingredient.base_id}&origin_location_id=${ingredient.origin_location_id}&category=${STANDARD_CATEGORY_CERTIFICATION}`,
    })
    ingredient.availableStandards = standardsResponse.map((standard) => ({
      id: standard.id,
      title: standard.title,
      identifier: standard.identifier,
    }))

    ingredientsToAdd = [ingredient]

    // default processing location to ingredient sourcing location
    const addDefaultProcessingLocation = async (ingredientToAdd: RecipeIngredient) => {
      try {
        const queryString = getRegionsQueryString({
          workspaceIds: [...draftWorkspaces.map((ws) => ws.id), ...defaultWorkspaces],
          //  due to encoding we are going to assume before the ',' will be sufficient
          search: ingredientToAdd.origin_location_name.split(',')[0],
        })
        const url = `regions/${queryString}`
        const regions = await fetchRegions(url)
        if (regions.length && regions[0].id) {
          ingredientToAdd.processing_location_id = regions[0].id
          ingredientToAdd.processing_location_name = regions[0].name
        }
      } catch (e) {
        console.error(e)
      }
      return ingredientToAdd
    }
    ingredientsToAdd = await Promise.all(ingredientsToAdd.map(addDefaultProcessingLocation))

    if (!originLocations.find((location) => location.base_id === source.id)) {
      // If we don't already have origin locations for this ingredient, fetch and add to store
      dispatch(
        getOriginLocations({
          baseIds: [source.id],
          workspaceIds: [...draftWorkspaces.map((ws) => ws.id), ...defaultWorkspaces],
        })
      )
    }
  }

  const newIngredientsList = addIngredientsToList(ingredientsToAdd, ingredientList, index)
  batch(() => {
    dispatch(rebalanceWeightsUpdateRecipe({ ...recipe, ingredients: newIngredientsList }, `Added ${source.name}`))
  })
}

export const addIngredient = (
  source: ESIngredientSource,
  isNestedIngredient?: boolean
): ThunkAction<void, AppState, Recipe, AnyAction> => async (dispatch, getState) => {
  const state = getState()
  const recipe = selectDraftRecipe(state)
  const weightUnit = selectDisplayedWeightOption(state)

  const newItemIndex = recipe.ingredients.length
  const children = recipe.ingredients.filter((ingredient) => ingredient.isTopLevel)
  const lastChild = children.length ? children[children.length - 1] : null
  const newItemPosition = (lastChild?.position || 0) + 1

  dispatch(
    addIngredientAtIndex({
      source,
      position: newItemPosition,
      index: newItemIndex,
      ingredientList: recipe.ingredients,
      isNestedIngredient,
      isAutoWeight: weightUnit === PERCENTAGES ? true : false,
      inputWeightUnit: weightUnit,
    })
  )
}

export const replaceIngredient = (
  source: ESIngredientSource,
  toReplace: RecipeIngredient | RecipeNestedIngredient
): ThunkAction<void, AppState, Recipe, AnyAction> => async (dispatch, getState) => {
  const state = getState()
  const recipe = selectDraftRecipe(state)
  const filteredIngredients = removeIngredientFromList(toReplace, recipe.ingredients, true)
  const ingredientInfo = {
    source,
    position: toReplace.position,
    index: toReplace.index,
    ingredientList: filteredIngredients,
    weight: toReplace.weight,
    isAutoWeight: toReplace.isAutoWeight,
    inputWeightUnit: toReplace.input_weight_unit,
  }
  // If nested ingredients are involved, disable the change indicators as impact scores may no longer line up
  if ('pk' in source || 'nested_product_id' in toReplace) {
    batch(() => {
      dispatch(addIngredientAtIndex(ingredientInfo))
      dispatch(setIngredientsMoved(true))
    })
  } else {
    dispatch(addIngredientAtIndex(ingredientInfo))
  }
}

export const removeIngredient = (
  ingredient: RecipeIngredient | RecipeNestedIngredient
): ThunkAction<void, AppState, Recipe, AnyAction> => async (dispatch, getState) => {
  const state = getState()
  const recipe = selectDraftRecipe(state)

  // Remove the ingredient and its children, then reindex ingredients beneath it
  const newIngredients = removeIngredientFromList(ingredient, recipe.ingredients)
  batch(() => {
    dispatch(rebalanceWeightsUpdateRecipe({ ...recipe, ingredients: newIngredients }, `Removed ${ingredient.name}`))
    dispatch(setIngredientsMoved(true))
  })
}

export const updateIngredient = (
  ingredient: RecipeIngredient | RecipeNestedIngredient,
  fieldsToUpdate: { [key: string]: any }
): ThunkAction<void, AppState, Recipe, AnyAction> => async (dispatch, getState) => {
  const state = getState()
  const recipe = selectDraftRecipe(state)

  const newIngredient: RecipeIngredient | RecipeNestedIngredient = {
    ...ingredient,
    ...fieldsToUpdate,
  }

  // If updating the source location, update the list of available certification standards for this ingredient
  if ('origin_location_id' in fieldsToUpdate && 'base_id' in newIngredient) {
    const availableStandards = await fetchHelperV2<Standard[]>({
      url: `standards/?base_id=${newIngredient.base_id}&origin_location_id=${fieldsToUpdate.origin_location_id}&category=${STANDARD_CATEGORY_CERTIFICATION}`,
    })
    newIngredient.availableStandards = availableStandards
  }

  const newIngredients = [...recipe.ingredients]
  newIngredients[ingredient.index] = newIngredient

  const newRecipe = { ...recipe, ingredients: newIngredients }

  // If changing an ingredient weight, redistribute weights of other ingredients
  if (Object.keys(fieldsToUpdate).includes('weight')) {
    dispatch(rebalanceWeightsUpdateRecipe(newRecipe, `Changed weight of ${ingredient.name} and redistributed weights`))
  } else {
    dispatch(
      updateRecipeAndScores({
        recipeUpdates: newRecipe,
        change: `Changed ${Object.keys(fieldsToUpdate)} fields to ${Object.values(fieldsToUpdate)} on ${
          ingredient.name
        }`,
      })
    )
  }
}

// Called from FormulaProfile.tsx to add nested product based on search params
export const addInitialNestedProductById = createAsyncThunk<void, number, { state: AppState }>(
  'recipe/addInitialNestedProductById',
  async (nestedProductId, { dispatch }) => {
    const response = await fetchProduct(nestedProductId)

    // Handle mismatch between workflow_tags on ProductResponse and ESIngredientSource
    const productAsIngredient = {
      ...response,
      workflow_tags: response.workflow_tags.map((tag) => tag.name),
    }
    dispatch(addIngredient(productAsIngredient as ESIngredientSource, true))
  }
)

export const redistributeAllWeights = (): ThunkAction<void, AppState, Recipe, AnyAction> => async (
  dispatch,
  getState
) => {
  const state = getState()
  const recipe = selectDraftRecipe(state)
  const newRecipe = {
    ...recipe,
    ingredients: recipe.ingredients.map((ingredient) => ({
      ...ingredient,
      isAutoWeight: true,
    })),
  }
  dispatch(rebalanceWeightsUpdateRecipe(newRecipe, 'Redistributed all ingredient weights'))
}

export const redistributeAutoWeights = (): ThunkAction<void, AppState, Recipe, AnyAction> => async (
  dispatch,
  getState
) => {
  const state = getState()
  const recipe = selectDraftRecipe(state)
  dispatch(rebalanceWeightsUpdateRecipe(recipe, 'Redistributed auto ingredient weights'))
}

export const reorderIngredient = (
  direction: 'up' | 'down',
  ingredient: RecipeIngredient | RecipeNestedIngredient
): ThunkAction<void, AppState, Recipe, AnyAction> => async (dispatch, getState) => {
  const state = getState()
  const recipe = selectDisplayedRecipe(state)
  const parentDotPath = getParentDotPath(ingredient)
  const level = ingredient.flat_position.length - 1 // Level within the tree (root = 0)

  // The moveGroup contains the ingredient that was clicked plus its children
  const moveGroup = recipe.ingredients.filter((item) => item.id === ingredient.id || checkIfChild(ingredient, item))

  // The swapGroup contains the item below (for up) or above (for down) plus its children
  let swapItemDotPath: string
  if (ingredient.isTopLevel) {
    swapItemDotPath = (direction === 'up' ? ingredient.position - 1 : ingredient.position + 1).toString()
  } else {
    swapItemDotPath = `${parentDotPath}.${direction === 'up' ? ingredient.position - 1 : ingredient.position + 1}`
  }
  const swapGroup = recipe.ingredients.filter(
    (item) => item.dot_path === swapItemDotPath || item.dot_path.startsWith(`${swapItemDotPath}.`)
  )

  if (swapGroup.length === 0) {
    dispatch(
      addMessage({ message: 'Unable to move ingredient. Please save your changes and try again.', severity: 'error' })
    )
    Sentry.captureException(new Error('swapGroup empty when attempting to re-order ingredients'), {
      extra: { recipe: recipe, ingredient: ingredient, direction: direction },
    })
    return
  }

  // Move the moveGroup to the current swapGroup location (for up) or down by the length of the swapGroup (for down)
  const newMoveGroup = moveGroup.map((item, i) => {
    const newFlatPosition = [...item.flat_position]
    newFlatPosition[level] = swapGroup[0].flat_position[level]
    return {
      ...item,
      index: direction === 'up' ? swapGroup[0].index + i : moveGroup[i].index + swapGroup.length,
      position: i === 0 ? swapGroup[0].position : item.position,
      flat_position: newFlatPosition,
      dot_path: newFlatPosition.join('.'),
    }
  })

  // Move the swapGroup below the new moveGroup (for up) or to the original moveGroup location (for down)
  const newSwapGroup = swapGroup.map((item, i) => {
    const newFlatPosition = [...item.flat_position]
    newFlatPosition[level] = moveGroup[0].flat_position[level]
    return {
      ...item,
      index: direction === 'up' ? newMoveGroup[0].index + moveGroup.length + i : moveGroup[0].index + i,
      position: i === 0 ? moveGroup[0].position : item.position,
      flat_position: newFlatPosition,
      dot_path: newFlatPosition.join('.'),
    }
  })

  const movedItems = [...newSwapGroup, ...newMoveGroup].map((item) => item.index)
  const rest = recipe.ingredients.filter((item) => !movedItems.includes(item.index))
  const newIngredients = [...newSwapGroup, ...newMoveGroup, ...rest]
  newIngredients.sort((a, b) => a.index - b.index)

  // Since the dotPaths in the `expanded` array will be invalid, clear the array
  dispatch(setCollapseAll())

  dispatch(rebalanceWeightsUpdateRecipe({ ...recipe, ingredients: newIngredients }, `Moved ${ingredient.name}`))
  dispatch(setIngredientsMoved(true))
}

export const updateProductStandards = (
  selectedStandards: IProductStandard[]
): ThunkAction<void, AppState, Recipe, AnyAction> => async (dispatch, getState) => {
  const state = getState()
  const appliedProductStandards = selectDisplayedProductStandards(state)
  const recipe = selectDisplayedRecipe(state)
  const removed = appliedProductStandards.filter(
    (current) => !selectedStandards.some((selected) => selected.identifier === current.identifier)
  )
  let ingredients = recipe.ingredients
  if (removed.length) {
    // if a standard was removed, and it was inherited by the ingredient, let the user
    // know it has been removed from those ingredients
    const updatedIngredients = []
    recipe.ingredients.forEach((ingredient) => {
      if ('base_id' in ingredient) {
        // Is the standard available on this ingredient?
        const productStandardAvailable = !!ingredient.availableStandards.find(
          (available) => available.identifier === removed[0].identifier
        )
        // If available, was it inherited, or was it already applied?
        const standardInherited =
          productStandardAvailable &&
          !ingredient.standards.find((standard) => standard.identifier === removed[0].identifier)
        if (standardInherited) {
          updatedIngredients.push(ingredient.name)
        }
      }
    })
    if (updatedIngredients.length) {
      dispatch(
        addMessage({
          message: `${removed[0].title} standard removed from ${updatedIngredients.join(', ')}.`,
          severity: 'info',
        })
      )
    }
  }
  dispatch(
    updateRecipeAndScores({
      recipeUpdates: {
        ...recipe,
        ingredients,
        standards: selectedStandards,
      },
      change: `Changed product standards to ${JSON.stringify(selectedStandards.map((item) => item.title))}`,
    })
  )
}
