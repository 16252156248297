import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectVendorDataIsLoading,
  selectEditingVendorsFilter,
  selectVendorNames,
  setEditingVendorManagementFilters,
  updateFiltersUrlAndVendorData,
} from '@/state/vendorManagement'
import { NameQueriesFilter } from '@/components/Shared/Filters/NameQueriesFilter'

export const VendorNameFilter: FC = () => {
  const dispatch = useDispatch()
  const isLoading = useSelector(selectVendorDataIsLoading)
  const queries = useSelector(selectVendorNames)
  const editingFilter = useSelector(selectEditingVendorsFilter)
  const id = 'vendor-name-queries'

  const handleQueriesChange = (queryStrings: string[]) => {
    dispatch(updateFiltersUrlAndVendorData({ updatedFilters: { vendorNameQueries: queryStrings } }))
    dispatch(setEditingVendorManagementFilters(id))
  }

  return (
    <NameQueriesFilter
      value={queries}
      handleChange={handleQueriesChange}
      searchType="vendor"
      id={id}
      autoFocus={editingFilter === id}
      disabled={isLoading}
      fullWidth
      dataPendoId="SearchVendorNames"
      ariaLabel="SearchVendorNamesQuery"
    />
  )
}
