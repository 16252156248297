import React, { FC, useState } from 'react'
import { faArrowUpFromBracket, faEllipsis, faTrash } from '@fortawesome/pro-regular-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { Icon, IconButton, Menu, MenuItem, Stack, Text, theme } from '@howgood/design'
import { InitiativeBasic } from '@/records'
import { DeleteInitiativeDialog } from './DeleteInitiativeDialog'
import { useNavigate } from '@/hooks'

interface OptionProps {
  icon: IconProp
  text: string
  color?: string
}
const Option: FC<OptionProps> = ({ icon, text, color }) => (
  <Stack flexDirection="row" alignItems="center" gap={1}>
    <Icon icon={icon} color={color} size="xs" />
    <Text variant="body2" color={color}>
      {text}
    </Text>
  </Stack>
)

interface Props {
  listView?: boolean
  initiative: InitiativeBasic
}

export const InitiativesMenu: FC<Props> = ({ initiative, listView }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const { navigateTo } = useNavigate()

  function closeMenu() {
    setAnchorEl(null)
  }
  function closeDialog() {
    setDeleteDialogOpen(false)
  }
  function handleClickDelete() {
    setDeleteDialogOpen(true)
    setAnchorEl(null)
  }

  return (
    <Stack>
      <IconButton
        data-testid={`initiative-options-button-${initiative.id}`}
        icon={faEllipsis}
        onClick={(e) => setAnchorEl(e.target)}
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        MenuListProps={{ ['data-testid']: `initiative-options-menu-${initiative.id}` }}
      >
        {listView && (
          <MenuItem data-testid={`initiative-option-export-${initiative.id}`}>
            <Option icon={faArrowUpFromBracket} text="Export Baseline Report" />
          </MenuItem>
        )}
        <MenuItem data-testid={`initiative-option-delete-${initiative.id}`} onClick={() => handleClickDelete()}>
          <Option icon={faTrash} text="Delete..." color={theme.palette.error.dark} />
        </MenuItem>
      </Menu>
      <DeleteInitiativeDialog
        open={deleteDialogOpen}
        initiative={initiative}
        onClose={closeDialog}
        onDelete={() => {
          closeMenu()
          closeDialog()
          if (!listView) {
            navigateTo(`/initiatives`)
          }
        }}
      />
    </Stack>
  )
}
