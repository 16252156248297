import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'
import { Stack, Text } from '@howgood/design'
import { BLOCKS } from '@contentful/rich-text-types'
import { useContentfulFormulaIcons } from '@/contentfully/hooks'
import { NodeRenderer } from '@contentful/rich-text-react-renderer'
import { RichText } from '@/contentfully'

const ParagraphRenderer: NodeRenderer = (_node, children) => {
  return (
    <Text variant="caption" textAlign="center">
      {children}
    </Text>
  )
}

interface Props {
  score: number
  withDescription?: boolean
  variant?: 'regular' | 'medium' | 'small'
  description?: string
  width?: string
}

const useStyles = createUseStyles({
  scoreWrapper: {
    height: 61,
  },
  regular: {
    height: 70,
    transform: 'translateY(-5px)',
  },
  medium: {
    height: 70,
  },
  small: {
    height: 34,
  },
})

export const RetailRatingImage: FC<Props> = ({ score, variant = 'regular', withDescription, description, width }) => {
  const classes = useStyles()

  const ratings = useContentfulFormulaIcons(['hg-rating-snm', 'hg-rating-good', 'hg-rating-great', 'hg-rating-best'])
  const iconUrls = ratings.map((rating) => rating.icon?.fields?.file?.url as string)
  const alts = ratings.map((rating) => rating.title)
  const descriptions = ratings.map((rating) => rating.description)

  if (![0, 1, 2, 3].includes(score)) {
    return width ? (
      <Stack width={width} height="100%" justifyContent="center" alignItems="center" maxWidth="120px">
        <>--</>
        {withDescription && description && (
          <Text variant="caption" textAlign="center">
            {description}
          </Text>
        )}
      </Stack>
    ) : (
      <Stack width={width} height="100%" justifyContent="center">
        <>--</>
      </Stack>
    )
  }

  return (
    <Stack data-testid="retail-rating-image" width={width} gap={0.5} alignItems="center" maxWidth="120px">
      <div className={classes.scoreWrapper}>
        <img src={iconUrls[score]} alt={alts[score]} className={classes[variant]} />
      </div>
      {withDescription &&
        (description || descriptions[score]) &&
        (description ? (
          <Text variant="caption" textAlign="center">
            {description}
          </Text>
        ) : (
          <RichText
            document={descriptions[score]}
            rendererOptions={{ renderNode: { [BLOCKS.PARAGRAPH]: ParagraphRenderer } }}
          />
        ))}
    </Stack>
  )
}
