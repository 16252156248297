import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { faEllipsis } from '@fortawesome/pro-regular-svg-icons'
import { Menu, IconButton } from '@howgood/design'

import { ArchiveDialog } from '@/components/ProductImpact/ArchiveDialog'
import { selectHasImpactsOverriddenNestedProduct, selectIsImpactsOverridden } from '@/state/productOverview'
import { ExportProductMetrics } from '../../../features/ProductPage/panels/Header/ExportProductMetrics'
import { WorkspacesDialog } from './WorkspacesDialog'
import { HistoryDialog } from '../HistoryDialog'
import {
  ArchiveFormula,
  CreateCarbonFootprintReport,
  ExportCarbonFootprintMethodology,
  ExportProductImpact,
  UseIngredient,
  ViewHistory,
  ViewSource,
} from './'
import { ProductCFReportDialog } from '@/components/ProductCFReportDialog/ProductCFReportDialog'

export const MenuButton: FC = () => {
  const impactsOverriddenProduct = useSelector(selectIsImpactsOverridden)
  const hasImpactsOverriddenNestedProduct = useSelector(selectHasImpactsOverriddenNestedProduct)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [historyOpen, setHistoryOpen] = useState(false)
  const [archiveOpen, setArchiveOpen] = useState(false)
  const [workspacesDialogOpen, setWorkspacesDialogOpen] = useState(false)
  const [reportDialogOpen, setReportDialogOpen] = useState(false)

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const handleViewHistory = () => {
    handleCloseMenu()
    setHistoryOpen(true)
  }

  const handleArchiveFormula = () => {
    handleCloseMenu()
    setArchiveOpen(true)
  }

  const handleUseInNewFormula = () => {
    handleCloseMenu()
    setWorkspacesDialogOpen(true)
  }

  return (
    <>
      <IconButton
        data-testid="toolbar-menu-button"
        id="toolbar-menu-button"
        icon={faEllipsis}
        onClick={handleOpenMenu}
        size="small"
      />
      <Menu
        id="overflow-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          '& .MuiPaper-root': {
            overflow: 'unset',
          },
        }}
        MenuListProps={{ 'aria-labelledby': 'toolbar-menu-button' }}
      >
        {impactsOverriddenProduct || hasImpactsOverriddenNestedProduct
          ? [
              <ViewSource key="view-source" onClick={handleCloseMenu} />,
              <UseIngredient key="use-ingredient" onClick={handleUseInNewFormula} />,
              <ArchiveFormula key="archive" onClick={handleArchiveFormula} />,
            ]
          : [
              <ExportProductMetrics key="export-metrics" />,
              <CreateCarbonFootprintReport
                key="create-carbon-report"
                openReportDialog={() => setReportDialogOpen(true)}
                closeMenu={handleCloseMenu}
              />,
              <ExportCarbonFootprintMethodology key="export-methodology" onClick={handleCloseMenu} />,
              <ViewSource key="view-source" onClick={handleCloseMenu} />,
              <UseIngredient key="use-ingredient" onClick={handleUseInNewFormula} />,
              <ViewHistory key="history" onClick={handleViewHistory} />,
              <ExportProductImpact key="export-impact" onClick={handleCloseMenu} />,
              <ArchiveFormula key="archive" onClick={handleArchiveFormula} />,
            ]}
      </Menu>
      {archiveOpen && <ArchiveDialog onClose={() => setArchiveOpen(false)} />}
      <HistoryDialog open={historyOpen} onClose={() => setHistoryOpen(false)} />
      {workspacesDialogOpen && <WorkspacesDialog onClose={() => setWorkspacesDialogOpen(false)} />}
      {reportDialogOpen && <ProductCFReportDialog closeDialog={() => setReportDialogOpen(false)} />}
    </>
  )
}
