import React, { forwardRef } from 'react'
import { useSelector } from 'react-redux'
import { PDFExport } from '@progress/kendo-react-pdf'
import { Stack, Text, Box, theme } from '@howgood/design'
import { selectDisplayedProduct } from '@/state/productOverview'
import { selectDisplayedProductImpactData } from '@/state/recipe'

import { useExportStyles } from '@/styles'
import { selectOrganization } from '@/state/organization'
import { useScorecardMetricOptions } from '@/components/SustainabilityScorecard/useScorecardMetricOptions'
import { ProductOverviewExportFooter } from '@/components/ProductImpact/ExportProductOverview/ProductOverviewExportFooter'
import { ProductOverview } from '@/components/ProductImpact/ExportProductOverview/ProductOverview'
import { getDateParts } from '@/utils/dates'
import { selectWorkspacesIds } from '@/state/workspaces'
import { ImpactChart } from './ImpactChart'
import { useScorecardFields } from '@/components/SustainabilityScorecard/useScorecardFields'
import { ALL_FOOD_INDUSTRY_OPTION } from '@/constants/industryCategories'

export const ProductImpactExport = forwardRef<PDFExport>((_, ref) => {
  const exportClasses = useExportStyles()
  const productData = useSelector(selectDisplayedProduct)
  const impactData = useSelector(selectDisplayedProductImpactData)

  const organization = useSelector(selectOrganization)
  const workspaceIds = useSelector(selectWorkspacesIds)
  const { metric } = useScorecardMetricOptions()

  const product = {
    ...productData,
    id: productData.id,
    pk: productData.pk, // IProduct has pk as string
    standards: [],
    name: productData.name || 'unsaved new product',
    requires_cold_storage: productData.requires_cold_storage || null, // IProduct doesn't support ''
  }

  const title = product.name

  const { year, month, day } = getDateParts()
  const fileName = `${title} - Product Impact - ${month}.${day}.${year}`
  const pdfFileName = `${fileName}.pdf`

  const { mainField, displayField, showGhgDisclaimer } = useScorecardFields(metric, impactData)

  return (
    <div className={exportClasses.outerContainer}>
      <PDFExport ref={ref} paperSize="auto" margin="32" fileName={pdfFileName}>
        <Box
          padding={3}
          width={1392}
          height={842}
          color="#4d4e54"
          position="relative"
          fontFamily={theme.typography.fontFamily}
          sx={{
            backgroundColor: '#fff',
          }}
        >
          <Stack
            direction="column"
            gap={1}
            alignItems="flex-start"
            justifyContent="space-between"
            mb={3}
            pb={2}
            borderBottom="1px solid #EBEBEB"
          >
            <Text fontWeight={700}>Product Impact Assessment</Text>
            <Text variant="h1" fontSize={40}>
              {title}
            </Text>
          </Stack>
          <ProductOverview
            product={product}
            category={ALL_FOOD_INDUSTRY_OPTION}
            categoryType="sales"
            organization={organization}
            impactScore={impactData}
            workspaceIds={workspaceIds}
            formulationTag=""
            mainField={mainField}
          />
          <ImpactChart />
          <ProductOverviewExportFooter showGhgDisclaimer={showGhgDisclaimer} displayField={displayField} />
        </Box>
      </PDFExport>
    </div>
  )
})

ProductImpactExport.displayName = 'ProductImpactExport'
