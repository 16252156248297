import React, { FC } from 'react'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { ExternalLink, Text, Tooltip } from '@howgood/design'
import { useNavigate } from '@/hooks/useNavigate'
import { UNKNOWN_VENDOR_OPTION_VALUE } from '@/records'

export const NameCell: FC<GridRenderCellParams> = ({ value, id }) => {
  const { safelyNavigateTo } = useNavigate()

  if (id === UNKNOWN_VENDOR_OPTION_VALUE) {
    return value
  }

  if (id === -1) {
    return (
      <Tooltip title="Average impact across all vendors">
        <Text variant="body2" color="text.secondary" fontWeight="bold">
          Average Across All Vendors
        </Text>
      </Tooltip>
    )
  }

  return (
    <Tooltip title={value}>
      <ExternalLink color="inherit" onClick={() => safelyNavigateTo(`vendors/${id}`)} underline="hover">
        {value}
      </ExternalLink>
    </Tooltip>
  )
}
