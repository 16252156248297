import React, { FC } from 'react'
import { Stack, Text } from '@howgood/design'

interface Props {
  spectrumImage: string
  negativeImpact: string
  positiveImpact: string
}

export const ImpactSpectrum: FC<Props> = ({ spectrumImage, negativeImpact, positiveImpact }) => {
  if (!spectrumImage && !negativeImpact && !positiveImpact) {
    return null
  }

  return (
    <Stack
      data-testid="impact-spectrum"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-around"
      gap={2}
      py={1}
      px={2}
      borderRadius={2}
      border={1}
      borderColor="divider"
      minHeight={60}
    >
      {negativeImpact && (
        <Stack alignItems="center">
          <Text variant="h4">Negative Impact</Text>
          <Text data-testid="negative-impact" variant="caption" textAlign="center">
            {negativeImpact}
          </Text>
        </Stack>
      )}
      {spectrumImage && <img data-testid="spectrum-image" src={spectrumImage} width="auto" height="auto" />}
      {positiveImpact && (
        <Stack alignItems="center">
          <Text variant="h4">Positive Impact</Text>
          <Text data-testid="positive-impact" variant="caption" textAlign="center">
            {positiveImpact}
          </Text>
        </Stack>
      )}
    </Stack>
  )
}
