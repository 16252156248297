import { fetchElasticV2 } from '@/api'
import { PRODUCTS_SEARCH_PATH } from '@/constants/config'
import { estypes } from '@elastic/elasticsearch'

export async function searchBetterThan(
  score: number,
  field: string = 'impact_score_total',
  salesCategory: string = ''
) {
  let query = {}
  if (salesCategory) {
    query = {
      query: {
        term: {
          'sales_category.title.keyword': salesCategory,
        },
      },
    }
  }
  const results = await fetchElasticV2({
    url: PRODUCTS_SEARCH_PATH,
    body: {
      size: 0,
      ...query,
      aggs: {
        percentiles: {
          percentile_ranks: {
            field,
            values: [score],
          },
        },
      },
    },
  })
  try {
    const percentiles = results.aggregations.percentiles as estypes.AggregationsPercentileRanksAggregation
    const values = percentiles.values
    return values[Object.keys(values)[0]]
  } catch (e) {
    console.error(e)
  }
  return 0
}

export async function searchSalesCategoryAverageScore(field: string = 'impact_score_total', salesCategory: string) {
  let query = {}

  // Filter out products containing "not relevant" (base.id = 3388) and "Russian doll" (base.id 3387) ingredients
  if (salesCategory) {
    query = {
      query: {
        bool: {
          must: [],
          filter: [
            {
              match_phrase: {
                'sales_category.title.keyword': salesCategory,
              },
            },
          ],
          should: [],
          must_not: [
            {
              bool: {
                should: [
                  {
                    match_phrase: {
                      'ingredients.ingredient_base_id': '3388',
                    },
                  },
                  {
                    match_phrase: {
                      'ingredients.ingredient_base_id': '3387',
                    },
                  },
                ],
                minimum_should_match: 1,
              },
            },
          ],
        },
      },
    }
  }

  const results = await fetchElasticV2({
    url: PRODUCTS_SEARCH_PATH,
    body: {
      size: 0,
      ...query,
      aggs: {
        score: {
          avg: {
            field,
          },
        },
      },
    },
  })
  const score = results.aggregations.score as estypes.AggregationsCardinalityAggregate
  return score.value || 0
}
