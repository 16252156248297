import React, { FC } from 'react'
import MuiTab, { TabProps as MuiTabProps } from '@mui/material/Tab'
import MuiTabs, { TabsProps as MuiTabsProps } from '@mui/material/Tabs'

interface TabProps extends Pick<MuiTabProps, 'id' | 'label' | 'value'> {
  ['data-testid']?: string
}

export const Tab: FC<TabProps> = ({ ['data-testid']: dataTestId, id, label, value, ...rest }) => {
  return (
    <MuiTab
      data-testid={dataTestId}
      id={id}
      label={label}
      value={value}
      disableRipple
      {...rest} // Other props used internally to control tab state
    />
  )
}

interface TabsProps
  extends Pick<MuiTabsProps, 'id' | 'aria-label' | 'value' | 'onChange' | 'variant' | 'scrollButtons' | 'children'> {}

export const Tabs: FC<TabsProps> = ({
  ['aria-label']: ariaLabel,
  id,
  value,
  onChange,
  variant,
  scrollButtons,
  children,
}) => {
  return (
    <MuiTabs
      aria-label={ariaLabel}
      id={id}
      value={value}
      variant={variant}
      scrollButtons={scrollButtons}
      onChange={onChange}
    >
      {children}
    </MuiTabs>
  )
}
