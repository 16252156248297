import React, { FC, useMemo } from 'react'
import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium'

import { generateScenarioColumns } from '@/components/GridWithMetrics/useGridHooks'
import { InitiativeBasic, Scenario } from '@/records/Initiatives'
import { ScenarioListGoalsCell, ScenarioListGoalsCellParams } from './ScenarioListGoalsCell'

export const ScenarioList: FC<{ scenarios: Scenario[]; initiative: InitiativeBasic }> = ({ scenarios, initiative }) => {
  const columns = useMemo(() => {
    const { name, date, assignee } = generateScenarioColumns(initiative, false)
    const goalsColumn: GridColDef = {
      field: 'goals',
      headerName: 'Goals Set',
      flex: 2,
      renderCell: (params: ScenarioListGoalsCellParams) => <ScenarioListGoalsCell {...params} />,
      sortable: false,
    }
    return [name, date, goalsColumn, assignee]
  }, [initiative])
  const rows = scenarios.map((scenario) => {
    return {
      id: scenario.id,
      name: { scenario },
      date: scenario,
      assignee: scenario,
      goals: scenario,
    }
  })

  return (
    <DataGridPremium
      sx={{
        '.MuiDataGrid-cell.MuiDataGrid-cell': {
          border: 'none',
        },
      }}
      rows={rows}
      columns={columns}
      initialState={{ density: 'compact' }}
      hideFooter
      disableColumnMenu
      autoHeight
    />
  )
}
