import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Stack,
  Text,
  Tooltip,
} from '@howgood/design'

import { ChartTable } from './ChartTable'
import { InitiativeChart } from './InitiativeChart'
import {
  selectChartMetricKey,
  selectCurrentInitiativeGoalMetrics,
  selectCurrentInitiativeId,
  selectCurrentInitiativeScenarios,
  selectisScenarioScoresLoading,
  setChartMetric,
} from '@/state/initiatives'
import { selectOrgLoading } from '@/state/organization'

export const ChartPanel: FC = () => {
  const dispatch = useDispatch()
  const goalMetrics = useSelector(selectCurrentInitiativeGoalMetrics)
  const chartMetric = useSelector(selectChartMetricKey)
  const currentInitiative = useSelector(selectCurrentInitiativeId)
  const scenarios = useSelector(selectCurrentInitiativeScenarios)
  const isLoading = useSelector(selectisScenarioScoresLoading)
  const isBaselineLoading = useSelector(selectOrgLoading)

  useEffect(() => {
    if (goalMetrics.length > 0 && !chartMetric) {
      dispatch(setChartMetric(goalMetrics[0].value))
    }
  }, [chartMetric, goalMetrics, dispatch])

  if (goalMetrics.length === 0) {
    const userMessage = scenarios?.length
      ? 'Start setting goals to track progress'
      : 'Start adding scenarios to set goals'
    const tooltipText = scenarios?.length
      ? 'There currently are no goals set for the scenarios in this initiative. Start setting some goals to track progress.'
      : 'There currently are no scenarios in this initiative. Add a scenario to set goals.'
    return (
      <Tooltip title={tooltipText}>
        <Accordion expanded={false} disabled>
          <AccordionSummary>
            {isLoading || isBaselineLoading || !currentInitiative ? (
              <Stack gap={2} direction="row" alignItems="center">
                <CircularProgress size={20} />
                <Box>
                  <Text>Loading initiative scores</Text>
                </Box>
              </Stack>
            ) : (
              <Text variant="h3">{`Goal Progress - ${userMessage}`}</Text>
            )}
          </AccordionSummary>
        </Accordion>
      </Tooltip>
    )
  }

  return (
    <Accordion defaultExpanded>
      <AccordionSummary>
        <Stack data-testid="goal-accordion-header" direction="row" justifyContent="space-between" width="98%">
          <Text variant="h3">Goal Progress</Text>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Stack direction="row" width="100%" height={276} gap={2}>
          <InitiativeChart />
          <ChartTable />
        </Stack>
      </AccordionDetails>
    </Accordion>
  )
}
