import React, { FC } from 'react'
import { Box, Stack, Text } from '@howgood/design'
import { Scope, formatScore, scopeToFillMap } from '../CarbonAccounting.utils'

interface Props {
  scope?: Scope
  title: string
  score: number
}

export const CarbonAccountingTotal: FC<Props> = ({ scope, title, score }) => {
  return (
    <Stack flexDirection="row" justifyContent="space-between">
      <Stack flexDirection="row" alignItems="center" gap={1}>
        {scope && (
          <Box data-testid="carbon-accounting-total-color" width={10} height={10} bgcolor={scopeToFillMap[scope]} />
        )}
        <Text data-testid="carbon-accounting-total-title">{title}</Text>
      </Stack>
      <Text data-testid="carbon-accounting-total-score" fontWeight={600}>
        {formatScore(score)}
      </Text>
    </Stack>
  )
}
