import { createUseStyles } from 'react-jss'
import { theme } from '@howgood/design'

export const useExportStyles = createUseStyles({
  outerContainer: {
    position: 'absolute',
    left: '-10000px',
  },
  container: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 11,
    color: theme.palette.text.primary,
    '& p': {
      marginBottom: 10,
    },
    '& td p': {
      lineHeight: '15px',
      marginBottom: 0,
    },
    '& ol, & ul': {
      paddingLeft: 20,
      marginBottom: 20,
    },
    '& h1': {
      fontWeight: theme.typography.h1.fontWeight,
      lineHeight: theme.typography.h1.lineHeight,
      fontSize: 20,
      textAlign: 'center',
    },
    '& h2': {
      fontWeight: theme.typography.h2.fontWeight,
      lineHeight: theme.typography.h2.lineHeight,
      fontSize: 17,
    },
    '& h3': {
      fontWeight: theme.typography.h3.fontWeight,
      lineHeight: theme.typography.h3.lineHeight,
      fontSize: 14,
    },
    '& table': {
      width: '100%',
      marginBottom: 10,
      border: '0.5px solid',
      borderCollapse: 'collapse',
    },
    '& th, & td': {
      border: '0.5px solid',
      padding: 5,
    },
  },
})
