import { useEffect, useMemo, useState } from 'react'
import { IImpactMetricOption } from '@/records'
import { Field, PERMISSIONS_TO_FIELDS_MAP, VIEW_AGG_IMPACT_HOWGOOD_ONE_METRIC } from '@/constants/impactScore'
import { useAnalysisAndProductMetricsOptions } from '@/hooks'

const availableMetrics: Field[] = [
  'howgood_one_metric',
  'raw_greenhouse_gas_impact',
  'processing_impact',
  'raw_blue_water_usage_impact',
  'blue_water_impact',
  'soc_impact',
  'raw_soc_impact',
  'labor_impact',
  'biodiversity_impact',
  'animal_welfare_impact',
  'raw_land_use_impact',
  'cf_processing_impact',
]

export const useScorecardMetricOptions = () => {
  const analysisAndProductMetricsOptions = useAnalysisAndProductMetricsOptions()
  const [metric, setMetric] = useState<IImpactMetricOption | null>(null)

  const availableAnalysisAndProductMetricOptions = useMemo(() => {
    return analysisAndProductMetricsOptions.filter((m) => availableMetrics.includes(m?.value as Field) && m?.value)
  }, [analysisAndProductMetricsOptions])

  useEffect(() => {
    const metricOption =
      availableAnalysisAndProductMetricOptions.find(
        (option) => option.value === PERMISSIONS_TO_FIELDS_MAP[VIEW_AGG_IMPACT_HOWGOOD_ONE_METRIC]
      ) || availableAnalysisAndProductMetricOptions[0]
    if (metricOption) {
      setMetric(metricOption)
    }
  }, [availableAnalysisAndProductMetricOptions])

  return { metric, setMetric, availableAnalysisAndProductMetricOptions }
}
