import React, { ChangeEvent, FC } from 'react'
import { Document } from '@contentful/rich-text-types'
import { theme, Radio, RadioGroup, Text, FormControlLabel, Stack } from '@howgood/design'
import { RequestVendorDataRichText } from './RequestVendorDataRichText'

export type DataPortalLink = 'consumer' | 'retailer'

export const DATA_PORTAL_LINK_CONSUMER = 'consumer' as DataPortalLink
export const DATA_PORTAL_LINK_RETAILER = 'retailer' as DataPortalLink

interface Props {
  dataPortalLinkType: DataPortalLink
  handleDataPortalLinkTypeChange: (value: DataPortalLink) => void
  content: Document
}

export const CollectVendorDataContent: FC<Props> = ({
  dataPortalLinkType,
  handleDataPortalLinkTypeChange,
  content,
}) => {
  return (
    <Stack>
      <RequestVendorDataRichText document={content} />
      <Text variant="body1" color={theme.palette.text.secondary}>
        Which best describes you?
      </Text>
      <RadioGroup
        id="sustainability-data-portal-link-type"
        value={dataPortalLinkType}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          handleDataPortalLinkTypeChange(e.target.value as DataPortalLink)
        }}
        onClick={(event: React.MouseEvent<HTMLElement>) => event.stopPropagation()}
        sx={{
          '& .MuiRadio-root': { padding: '2px 6px' },
          '& .MuiFormControlLabel-root': { alignItems: 'flex-start', margin: '8px 0 0 0' },
        }}
      >
        <FormControlLabel
          value={DATA_PORTAL_LINK_CONSUMER}
          control={<Radio id="sustainability-data-portal-link-consumer" size="small" />}
          label="A consumer package good or food service or ingredient supplier company requesting information from ingredient suppliers/farm vendors."
        />
        <FormControlLabel
          value={DATA_PORTAL_LINK_RETAILER}
          control={<Radio id="sustainability-data-portal-link-retailer" size="small" />}
          label="A retailer or food service collecting data from a finished consumer package good vendor."
        />
      </RadioGroup>
    </Stack>
  )
}
