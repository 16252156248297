import React, { FC, useState } from 'react'
import { Button, Box } from '@howgood/design'
import { faScroll } from '@fortawesome/pro-regular-svg-icons'
import { ProductCFReportDialog } from '@/components/ProductCFReportDialog/ProductCFReportDialog'
import { selectCurrentClaimsReport } from '@/state/productOverview'
import { useSelector } from 'react-redux'

/**
 * This button is displayed only for SupplierConnect users but not in draft mode (see ScoreCards.tsx)
 */

export const GenerateCFReportButton: FC = () => {
  const currentClaimsReport = useSelector(selectCurrentClaimsReport)
  const [reportDialogOpen, setReportDialogOpen] = useState(false)

  return (
    <Box mt={0.5} sx={{ '& .MuiButton-root:focus': { outline: 'none' } }}>
      <Button
        data-testid="generate-carbon-footprint-report-btn"
        startIcon={faScroll}
        size="small"
        onClick={() => setReportDialogOpen(true)}
        fullWidth
      >
        {currentClaimsReport ? 'Download' : 'Generate'} Carbon Footprint Report
      </Button>
      {reportDialogOpen && <ProductCFReportDialog closeDialog={() => setReportDialogOpen(false)} />}
    </Box>
  )
}
