import React, { FC } from 'react'
import { StatusItem } from '@howgood/design'
import { ProductListRenderCellParams } from './ProductGridTypes'
import { generateReportStatusOption } from '@/utils/ValidationStatusUtils'

interface Props extends ProductListRenderCellParams {
  value?: boolean
}

export const ReportCreatedRenderer: FC<Props> = ({ value, id }) => {
  const statusItem = generateReportStatusOption(true, value, `-${id}`)

  return (
    <StatusItem
      key={statusItem.id}
      id={statusItem.id}
      status={statusItem.status}
      tooltipText={statusItem.tooltipText}
      statusText={statusItem.statusText}
      icon={statusItem.icon}
      isFirst
      isLast
    />
  )
}
