import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Grid, Stack, Text, Tooltip } from '@howgood/design'
import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons'
import { DRAFT, selectPageView } from '@/state/pageSettings'
import { selectDisplayedProduct } from '@/state/productOverview'
import { selectDisplayedPackaging, updateRecipeAndScores } from '@/state/recipe'
import { RecipePackagingItem } from '@/state/recipe/recipe.state'
import { panelStyles } from '../panelStyles'
import { PackagingItem } from './PackagingItem'
import { PackagingGridItem } from './PackagingGridItem'
import { SaveProductDisclaimer } from '../SaveProductDisclaimer'

export const Packaging: FC = () => {
  const dispatch = useDispatch()
  const view = useSelector(selectPageView)
  const packaging = useSelector(selectDisplayedPackaging)
  const product = useSelector(selectDisplayedProduct)

  const [tempItem, setTempItem] = useState<RecipePackagingItem>(null)

  useEffect(() => {
    if (tempItem?.material?.id && tempItem?.packaging_unit) {
      dispatch(
        updateRecipeAndScores({
          recipeUpdates: {
            packaging: [
              ...packaging,
              {
                id: tempItem.id,
                material: { id: tempItem.material.id, name: tempItem.material.name },
                shape: { id: tempItem.shape.id },
                product: { id: product.id },
                recycled_input_ratio: tempItem.recycled_input_ratio || 0,
                packaging_unit: tempItem.packaging_unit,
                consumer_units: tempItem.consumer_units,
                region: tempItem.region ? { id: tempItem.region.id, name: tempItem.region.name } : undefined,
                packaging_material_weight: tempItem.packaging_material_weight,
                packaging_uses: tempItem.packaging_uses,
              },
            ],
          },
          change: `Added ${tempItem.material.name} to packaging`,
        })
      )

      setTempItem(null)
    }
  }, [dispatch, packaging, product.id, tempItem])

  const handleAddPackaging = () => {
    const packagingId = packaging.length > 0 ? packaging.slice(-1)[0].id + 1 : 0
    setTempItem({
      id: packagingId,
      shape: { id: 2 }, // This is required by the backend, but unused in new impact score calculations
      packaging_unit: null,
      consumer_units: 1,
      packaging_material_weight: 0,
      packaging_uses: 1,
      region: null,
    })
  }

  return (
    <Stack gap={2} {...panelStyles.grid}>
      <Grid container columns={24}>
        {(packaging.length > 0 || tempItem) && (
          <>
            <PackagingGridItem header xs={1} />
            <PackagingGridItem header xs={5}>
              <Text color="secondary">Type (required*)</Text>
            </PackagingGridItem>
            <PackagingGridItem header xs={3}>
              <Text color="secondary">Consumer Units</Text>
            </PackagingGridItem>
            <PackagingGridItem header xs={4}>
              <Text color="secondary">Material (required*)</Text>
            </PackagingGridItem>
            <PackagingGridItem header xs={4}>
              <Text color="secondary">Region</Text>
            </PackagingGridItem>
            <PackagingGridItem header xs={4}>
              <Text color="secondary">Material Weight (kg/unit of packaging)</Text>
            </PackagingGridItem>
            <PackagingGridItem header xs={3}>
              <Text color="secondary">Uses</Text>
            </PackagingGridItem>
          </>
        )}
        {packaging.map((item, index) => (
          <PackagingItem key={`${item.id}-${index}`} packagingItem={item} index={index} />
        ))}
        {tempItem && <PackagingItem key={-1} packagingItem={tempItem} setTempItem={setTempItem} index={tempItem.id} />}
        {!tempItem && (
          <Grid item mt={2} xs={12}>
            <Tooltip
              title={
                view === DRAFT
                  ? 'Required for cradle-to-grave carbon metrics'
                  : 'Must be in edit mode to change packaging'
              }
              childrenDisabled={view !== DRAFT}
            >
              <Button
                data-testid="add-packaging-button"
                id="add-packaging-button"
                size="small"
                disabled={view !== DRAFT}
                startIcon={faPlusCircle}
                onClick={handleAddPackaging}
              >
                Add Packaging
              </Button>
            </Tooltip>
          </Grid>
        )}
      </Grid>
      <SaveProductDisclaimer readyToSave={tempItem ? Boolean(tempItem.packaging_unit && tempItem.material?.id) : true}>
        *To add or update packaging on this product, fill out <b>all required fields</b> before attempting to save.
      </SaveProductDisclaimer>
    </Stack>
  )
}
