import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { Text, TextField } from '@howgood/design'
import { DRAFT, selectPageView } from '@/state/pageSettings'

interface Props {
  distance: number
  onChange: (distance: number) => void
}
export const DistanceInput: FC<Props> = ({ distance, onChange }) => {
  const [value, setValue] = useState(distance)
  const view = useSelector(selectPageView)

  if (view !== DRAFT) {
    return (
      <Text data-testid="distance-text">
        Distance: <b>{distance}km</b>
      </Text>
    )
  }

  return (
    <TextField
      type="number"
      label="Distance (km)"
      value={value || ''}
      inputProps={{
        ['data-testid']: 'distance-input',
        min: 0,
        step: 1,
      }}
      onChange={(e) => setValue(parseFloat(e.target.value))}
      onBlur={(e) => onChange(parseFloat(e.target.value))}
      fullWidth
    />
  )
}
