import React, { FC } from 'react'
import { Props as LegendContentProps } from 'recharts/types/component/DefaultLegendContent'
import { Text, Stack, Box } from '@howgood/design'
import { VendorEngagementBreakdownChartData } from './VendorEngagementBreakdownChart'

interface VendorEngagementLegendProps extends LegendContentProps {
  data: VendorEngagementBreakdownChartData
}

export const VendorEngagementLegend: FC<VendorEngagementLegendProps> = ({ payload, data }) => {
  return (
    <Stack gap={1} ml={1}>
      {payload.map(({ value, color }, idx) => (
        <Stack direction="row" alignItems="center" key={value} gap={0.5}>
          <Box width={11} height={11} bgcolor={color} />
          <Text variant="caption" color="black">
            {value}: {data[idx].pct}%
          </Text>
        </Stack>
      ))}
    </Stack>
  )
}
