import React, { FC } from 'react'
import { Logo } from '@/components'
import './Footer.scss'

export const Footer: FC = () => {
  return (
    <div className="Footer">
      <Logo className="navbar-brand nav__logo" />
      <h6>&copy; {new Date().getFullYear()} HowGood, Inc. All Rights Reserved.</h6>
    </div>
  )
}
