import { createAsyncThunk } from '@reduxjs/toolkit'
import { fetchProductImpactHistory, fetchVendorImpactHistory } from './impactHistory.requests'
import { ImpactHistory, ImpactScoreHistoryValues } from './impactHistory.types'
import { AppState } from '@/store'

export const getProductImpactHistory = createAsyncThunk<ImpactHistory, number>(
  'impactHistory/getProductImpactHistory',
  async (productId) => {
    const impactScoreHistoryReponse = await fetchProductImpactHistory(productId)
    return Object.values(impactScoreHistoryReponse.data.metrics).reduce((metricsAcc, metric) => {
      const values = Object.entries(metric.values).reduce(
        (valuesAcc, [date, score]) => ({ ...valuesAcc, [date]: score ? parseFloat(score) : null }),
        {} as ImpactScoreHistoryValues
      )
      if (!metric.current_label) {
        return metricsAcc
      }
      return {
        ...metricsAcc,
        [metric.current_label]: values,
      }
    }, {} as ImpactHistory)
  }
)

export const getVendorImpactHistory = createAsyncThunk<ImpactHistory, number, { state: AppState }>(
  'impactHistory/getVendorImpactHistory',
  async (vendorId) => {
    const impactHistory = await fetchVendorImpactHistory(vendorId)
    return impactHistory
  }
)
