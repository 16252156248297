import React, { FC, ReactNode } from 'react'
import { Stack, Text, Tooltip, theme } from '@howgood/design'

import { useContentfulDataHealthIndicator } from '@/contentfully/hooks'
import { ContentfulDataHealthIndicatorKey } from '@/contentfully'
import { ProductListRenderCellParams } from './ProductGridTypes'
import { getDataHealthValue } from '@/utils/products'

const MIN_DAYS_STALE = 180
const MAX_DAYS_STALE = 360
const MS_PER_DAY = 1000 * 60 * 60 * 24
const MIN_TIME_STALE = MIN_DAYS_STALE * MS_PER_DAY
const MAX_TIME_STALE = MAX_DAYS_STALE * MS_PER_DAY

const LastModifiedRenderer: FC<{ value: number }> = ({ value }) => {
  const timeSinceModified = Date.now() - new Date(value).getTime()
  const backgroundColor =
    timeSinceModified > MAX_TIME_STALE ? 'red' : timeSinceModified > MIN_TIME_STALE ? 'yellow' : 'green'
  const textMap = {
    red: `> ${MAX_DAYS_STALE} days`,
    yellow: `${MIN_DAYS_STALE}-${MAX_DAYS_STALE} days`,
    green: `< ${MIN_DAYS_STALE} days`,
  }

  return (
    <Stack
      data-testid="data-health-last-modified"
      alignItems="center"
      justifyContent="center"
      borderRadius={'5px'}
      height={24}
      width={90}
      color={backgroundColor === 'yellow' ? 'black' : 'white'}
      sx={{ backgroundColor: theme.palette.dataHealth[backgroundColor] }}
    >
      <Text variant="productCell">{textMap[backgroundColor]}</Text>
    </Stack>
  )
}

interface PopoverProps {
  value: any
  field: ContentfulDataHealthIndicatorKey
  id: number
  children: ReactNode
}

const DataHealthPopover: FC<PopoverProps> = ({ field, value, children, id }) => {
  const content = useContentfulDataHealthIndicator(field)

  let tooltipText = ''
  if (field === 'default_origin_percent_impact') tooltipText = value !== 1 ? content?.provided : content?.notProvided
  else if (field === 'has_default_weights') tooltipText = value === false ? content?.provided : content?.notProvided
  else if (field === 'date_modified') {
    const timeSinceModified = Date.now() - new Date(value).getTime()
    tooltipText =
      timeSinceModified < MIN_DAYS_STALE * MS_PER_DAY
        ? `${content?.low} ${MIN_DAYS_STALE} days.`
        : timeSinceModified <= MAX_DAYS_STALE * MS_PER_DAY
        ? `${content?.medium} ${MIN_DAYS_STALE} and ${MAX_DAYS_STALE} days ago.`
        : `${content?.high} ${MAX_DAYS_STALE} days ago.`
  }

  return (
    <Tooltip title={<Text data-testid={`data-health-tooltip-${field}-${id}`}>{tooltipText}</Text>} disableInteractive>
      <Stack width="100%" height="100%" justifyContent="center" data-testid={`data-health-${field}-${id}`}>
        {children}
      </Stack>
    </Tooltip>
  )
}

interface Props extends ProductListRenderCellParams {
  value?: number
}

export const DataHealthRenderer: FC<Props> = ({ value, colDef, id }) => {
  const field = colDef.field as ContentfulDataHealthIndicatorKey

  if (field === 'date_modified') {
    return (
      <DataHealthPopover value={value} field={field} id={id}>
        <LastModifiedRenderer value={value} />
      </DataHealthPopover>
    )
  }

  return (
    <DataHealthPopover value={value} field={field} id={id}>
      {getDataHealthValue(field, value) || ''}
    </DataHealthPopover>
  )
}
