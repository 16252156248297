import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons'
import { Checkbox, Icon, MenuItem, Select } from '@howgood/design'
import { selectContentfulIngredientImpactData } from '@/state/recipe'
import { selectGridSettings, setGridSettings } from '@/state/preferences'
import { Field } from '@/constants/impactScore'

export const ColumnSelect: FC = () => {
  const gridSettings = useSelector(selectGridSettings)
  const impactColumns = useSelector(selectContentfulIngredientImpactData)
  const dispatch = useDispatch()

  const onSelectColumn = (e: React.MouseEvent<HTMLLIElement, MouseEvent>, impactKey: Field) => {
    e.preventDefault()
    e.stopPropagation()
    let unselected = [...gridSettings.hiddenCols]
    const selected = impactColumns.map((impact) => impact.key).filter((impact) => !unselected.includes(impact))
    if (unselected.includes(impactKey)) {
      unselected = unselected.filter((key) => key !== impactKey)
      // Require at least one column selected
    } else if (selected.length > 1) {
      unselected.push(impactKey)
    }
    dispatch(
      setGridSettings({
        ...gridSettings,
        hiddenCols: unselected,
      })
    )
  }

  return (
    <Select
      id="grid-column-select"
      data-testid="grid-column-select"
      aria-label="Select grid columns"
      color="secondary"
      variant="standard"
      multiple
      disableUnderline
      value={impactColumns.map((impact) => impact.key).filter((impact) => !gridSettings.hiddenCols.includes(impact))}
      renderValue={() => 'Select Columns'}
      sx={{
        mt: 0.25,
        borderBottom: 2,
        borderColor: 'transparent',
        '&:hover': { borderBottomColor: 'unset' },
        '& .MuiSelect-select': {
          py: 0,
          pr: '4px !important',
          bgcolor: 'white !important',
        },
      }}
      IconComponent={() => <Icon icon={faCaretDown} color="inherit" size="xs" />}
      MenuProps={{
        id: 'grid-column-select-menu',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      }}
    >
      {impactColumns.map((impact) => {
        const title = impact.title === 'Carbon Footprint' ? impact.subtitle : impact.title
        return (
          <MenuItem
            key={impact.key}
            data-testid="grid-column-select-item"
            value={impact.key}
            sx={{ pl: 0.5, pr: 1, py: 0 }}
            onClick={(e) => onSelectColumn(e, impact.key)}
          >
            <Checkbox
              id={`${impact.key}-checkbox`}
              data-testid={`${impact.key}-title`}
              aria-label={title}
              size="small"
              label={title}
              checked={!gridSettings.hiddenCols.includes(impact.key)}
            />
          </MenuItem>
        )
      })}
    </Select>
  )
}
