import React, { FC, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { DataGridPremium, GridColDef, GridSortModel, GridValidRowModel } from '@mui/x-data-grid-premium'
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium'
import { Box } from '@howgood/design'

import { generateColumnGroupFields, useColumnGroupingModel } from '@/components/GridWithMetrics/useGridHooks'
import { WorkspaceListCheckbox } from '@/state/user'
import { selectCurrentInitiativeGoalMetrics } from '@/state/initiatives'
import { selectMetricGridColumns } from '@/state/preferences'

interface Props {
  rows: readonly GridValidRowModel[] // Filtered portfolio workspaces
  columns: GridColDef[]
  options: WorkspaceListCheckbox[]
  loading?: boolean
  gridApiRef?: React.MutableRefObject<GridApiPremium>
  initialSort?: GridSortModel
}

export const MetricsGrid: FC<Props> = ({
  rows,
  columns,
  options,
  loading,
  gridApiRef,
  initialSort = [{ field: 'name', sort: 'asc' }],
}) => {
  const selectedColumns = useSelector(selectMetricGridColumns)
  const columnGroupingModel = useColumnGroupingModel()
  const goalMetrics = useSelector(selectCurrentInitiativeGoalMetrics)

  const columnVisibilityModel = useMemo(
    () =>
      options.reduce((visModel, option) => {
        const groupFields = generateColumnGroupFields(option.value)
        const visible = selectedColumns.find((item) => item === option.key)
        if (!visible) {
          if (option.value) {
            // For metric columns we need to hide ALL the columns in the group
            groupFields.forEach((field) => {
              visModel[field] = false
            })
          } else {
            visModel[option.key] = false
          }
          return visModel
        }
        return visModel
      }, {}),
    [options, selectedColumns]
  )

  return (
    <Box mt={1} overflow="auto" height="90%">
      <DataGridPremium
        apiRef={gridApiRef || null}
        key={`selected-${selectedColumns.length}_metrics-${goalMetrics.map((m) => m.value).join('.')}`} // Force state reset to prevent crashing on grouping model change
        rows={rows}
        columns={columns}
        columnVisibilityModel={columnVisibilityModel}
        hideFooter
        loading={loading}
        disableColumnMenu
        disableRowSelectionOnClick
        columnGroupingModel={columnGroupingModel}
        initialState={{
          sorting: {
            sortModel: initialSort,
          },
        }}
        sx={{
          borderColor: 'grey.300',
          '& .MuiDataGrid-columnHeader': { '&:focus-within': { outline: 'none' } },
          '& .MuiDataGrid-columnHeaderTitleContainer': { border: 'none !important' },
          '& .MuiDataGrid-columnHeaderTitleContainerContent': {
            height: '100%',
            '& .MuiTypography-root': { color: 'text.secondary' },
          },
          '& .MuiDataGrid-columnHeaderTitle': { fontWeight: 600, mt: 0.5 },
          '& .MuiDataGrid-cell': {
            borderRight: 1,
            borderColor: 'grey.300',
            '& .MuiTypography-root': { fontSize: 14 },
            '&:focus-within': { outline: 'none' },
          },
        }}
      />
    </Box>
  )
}
