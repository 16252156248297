import { ProductBrand, VendorByBrandResponse } from '@/records'
import { createSlice } from '@reduxjs/toolkit'
import { getVendorDetails } from './vendor.thunk'

export interface State {
  isLoading: boolean
  vendor: ProductBrand & Partial<VendorByBrandResponse>
}

export const initialState: State = {
  isLoading: false,
  vendor: {
    name: '',
    id: null,
    website: '',
    email: '',
    phone: '',
    workflow_notes: '',
    reports: [],
  },
}

const vendorSlice = createSlice({
  name: 'vendor',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getVendorDetails.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getVendorDetails.fulfilled, (state, action) => {
      state.isLoading = false
      state.vendor = action.payload
    })
    builder.addCase(getVendorDetails.rejected, (state, action) => {
      console.error(action.error)
      state.isLoading = false
      state.vendor = initialState.vendor
    })
  },
})

export const vendorReducer = vendorSlice.reducer
