import React, { useMemo, useEffect, FC, ReactNode } from 'react'
import { useSelector } from 'react-redux'
import { IntercomProps, useIntercom } from 'react-use-intercom'
import { selectUser, selectUserAuthenticated } from '@/state/user'
import { getUserName } from '@/records'

interface Props {
  children?: ReactNode
}
export const Intercom: FC<Props> = ({ children }) => {
  const { boot: initializeIntercom, update: updateIntercom, shutdown: shutdownIntercom } = useIntercom()
  const userRaw = useSelector(selectUser)
  const userAuthenticated = useSelector(selectUserAuthenticated)
  const user: IntercomProps = useMemo(
    () => ({
      userId: userRaw.id.toString(),
      email: userRaw.email,
      name: getUserName(userRaw),
    }),
    [userRaw]
  )

  useEffect(() => {
    if (userAuthenticated) {
      initializeIntercom()
    } else {
      shutdownIntercom()
    }
  }, [userAuthenticated, initializeIntercom, shutdownIntercom])

  useEffect(() => {
    updateIntercom(user)
  }, [user, updateIntercom])

  return <>{children}</>
}
