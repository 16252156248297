import React, { FC } from 'react'
import { Stack } from '@howgood/design'
import { CarbonFootprintBreakdown } from '@/components/Procurement/CarbonFootprintBreakdown/CarbonFootprintBreakdown'
import { SCOPE_3_CATEGORY_1_FIELD, SCOPE_3_CATEGORY_4_FIELD } from '@/constants/impactScore'
import { useScope3AggregationData, useVendorGranularityAggregationData } from './hooks'
import { MaterialGranularityBreakdown } from '@/components/Procurement/MaterialGranularityBreakdown/MaterialGranularityBreakdown'

interface Hit {
  id: number
  name: string
  mt_per_year: number // Runtime field
  percentage: number // Runtime field
}

export interface Scope3Cat1Hit extends Hit {
  cf_ftm_gate_ct_verified_impact: number
  [SCOPE_3_CATEGORY_1_FIELD]: number // Runtime field
}

export interface Scope3Cat4Hit extends Hit {
  cf_mtr_transportation_impact: number
  [SCOPE_3_CATEGORY_4_FIELD]: number // Runtime field
}

export const AnalysisPanel: FC = () => {
  const { data: vendorGranularityData, isLoading: vendorGranularityLoading } = useVendorGranularityAggregationData()
  const { data: scope3Data, isLoading: scope3Loading } = useScope3AggregationData()
  const { scope3Cat1Data, scope3Cat4Data, carbonFootprintByVendor } = scope3Data

  return (
    <Stack gap={2} mt={2}>
      <Stack direction="row" gap={2} flexWrap={'wrap'}>
        {!!(scope3Loading || (scope3Cat1Data?.total && scope3Cat4Data?.total)) && (
          <>
            <CarbonFootprintBreakdown
              headerPrefix="Scope 3 Emissions"
              data={{
                carbonFootprintByMaterial: scope3Cat1Data.hits
                  // It looks like there are instances of ES getting confused when there are two materials with the same name
                  // e.g. if one doesn't have an inventory, it returns null for the other's mt_per_year field? (e.g. HS Sugar - Regen)
                  // Filter out the hits where annual emissions wasn't calculated due to a null value
                  .filter((hit) => hit[SCOPE_3_CATEGORY_1_FIELD])
                  .reduce(
                    (acc, row) => {
                      return { ...acc, [row.name]: row[SCOPE_3_CATEGORY_1_FIELD] }
                    },
                    {
                      Other:
                        scope3Cat1Data.total -
                        scope3Cat1Data.hits.reduce((acc, row) => acc + row[SCOPE_3_CATEGORY_1_FIELD], 0),
                    }
                  ),
                carbonFootprintByVendor: carbonFootprintByVendor.scope_3_cat_1_annual,
                totalCarbonFootprint: scope3Cat1Data.total,
              }}
              field={SCOPE_3_CATEGORY_1_FIELD}
              isLoading={scope3Loading}
            />
            <CarbonFootprintBreakdown
              headerPrefix="Scope 3 Emissions"
              data={{
                carbonFootprintByMaterial: scope3Cat4Data.hits
                  // See comment above
                  .filter((hit) => hit[SCOPE_3_CATEGORY_4_FIELD])
                  .reduce(
                    (acc, row) => {
                      return { ...acc, [row.name]: row[SCOPE_3_CATEGORY_4_FIELD] }
                    },
                    {
                      Other:
                        scope3Cat4Data.total -
                        scope3Cat4Data.hits.reduce((acc, row) => acc + row[SCOPE_3_CATEGORY_4_FIELD], 0),
                    }
                  ),
                carbonFootprintByVendor: carbonFootprintByVendor.scope_3_cat_4_annual,
                totalCarbonFootprint: scope3Cat4Data.total,
              }}
              field={SCOPE_3_CATEGORY_4_FIELD}
              isLoading={scope3Loading}
            />
          </>
        )}
        {(vendorGranularityLoading || !!vendorGranularityData?.totalCount) && (
          <MaterialGranularityBreakdown
            data={vendorGranularityData}
            isLoading={vendorGranularityLoading}
            withCard
            withTitleTooltip
          />
        )}
      </Stack>
    </Stack>
  )
}
