import React, { FC, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { StatusIconBar, StatusItemT } from '@howgood/design'

import {
  selectIsCreateNew,
  selectMostRecentlyModifiedSharingRequest,
  selectMostRecentlyModifiedValidationRequest,
  selectProductIsLocked,
} from '@/state/productOverview'
import {
  generateReportStatusOption,
  generateShareStatusOption,
  generateValidationStatusOption,
} from '@/utils/ValidationStatusUtils'

export const ValidationStatusIcons: FC = () => {
  const isProductLocked = useSelector(selectProductIsLocked)
  const isSaved = !useSelector(selectIsCreateNew)

  const mostRecentlyModifiedValidationRequest = useSelector(selectMostRecentlyModifiedValidationRequest)
  const mostRecentlyModifiedSharingRequest = useSelector(selectMostRecentlyModifiedSharingRequest)

  const statusList = useMemo(() => {
    return [
      generateReportStatusOption(isSaved, isProductLocked),
      generateShareStatusOption(mostRecentlyModifiedSharingRequest, isProductLocked),
      generateValidationStatusOption(mostRecentlyModifiedValidationRequest, isProductLocked),
    ] as StatusItemT[]
  }, [isSaved, isProductLocked, mostRecentlyModifiedSharingRequest, mostRecentlyModifiedValidationRequest])

  return <StatusIconBar statusList={statusList} />
}
