import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Text, Stack } from '@howgood/design'
import { GridScenario } from '@/records/Initiatives'
import { deleteScenario } from '@/state/initiatives'

interface Props {
  scenario: GridScenario
  closeDialog: () => void
}

export const DeleteScenarioDialog: FC<Props> = ({ closeDialog, scenario }) => {
  const dispatch = useDispatch()

  const handleDelete = () => {
    dispatch(deleteScenario(scenario.id))
    closeDialog()
  }

  return (
    <Dialog maxWidth="xs" id="delete-scenario-dialog" data-testid="delete-scenario-dialog" open onClose={closeDialog}>
      <DialogTitle>{`Delete scenario "${scenario.name}"?`}</DialogTitle>
      <DialogContent>
        <Text>All changes made in this scenario will be lost. This action cannot be reversed.</Text>
      </DialogContent>
      <DialogActions>
        <Stack width="100%" direction="row" justifyContent="space-between">
          <Button id="delete-scenario-cancel-button" data-testid="delete-scenario-cancel-button" onClick={closeDialog}>
            Cancel
          </Button>
          <Button
            id="delete-scenario-ok-button"
            data-testid="delete-scenario-ok-button"
            color="error"
            onClick={handleDelete}
          >
            Delete scenario
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}
