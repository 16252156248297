import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'
import { ProgressBar } from '@howgood/design'
import { formatNumber } from '@howgood/utils'

interface Props {
  value: number
  showBar?: boolean
  centered?: boolean
}
const useStyles = createUseStyles<string, Pick<Props, 'centered'>>({
  progressBarWrapper: {
    marginLeft: 10,
    height: 10,
    width: 200,
  },
  impactScore: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: (props) => (props.centered ? 'center' : 'flex-start'),
  },
  value: {
    fontWeight: 600,
  },
})

export const ImpactScoreCell: FC<Props> = ({ value, showBar = true, centered }) => {
  const classes = useStyles({ centered })

  if (value === undefined || value === null) {
    return (
      <div className={classes.impactScore} style={{ justifyContent: 'center' }}>
        <div className={classes.value}>--</div>
      </div>
    )
  }

  return (
    <div className={classes.impactScore}>
      <div className={classes.value}>{formatNumber(value, 0)}</div>
      {showBar && (
        <div className={classes.progressBarWrapper}>
          <ProgressBar width={Math.round(value) || 0} />
        </div>
      )}
    </div>
  )
}
