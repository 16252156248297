import React, { FC, ReactNode } from 'react'
import { createUseStyles } from 'react-jss'
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons'
import { Heading } from './Heading'

const useStyles = createUseStyles<string>({
  container: {
    flex: 1,
    padding: '16px 50px',
    overflow: 'auto',
  },
})
interface Props {
  title?: string
  children: ReactNode
  icon?: IconDefinition
  actions?: ReactNode
  ['aria-label']?: string
  ['data-testid']?: string
}

export const Module: FC<Props> = ({
  title,
  children,
  icon,
  actions,
  ['aria-label']: ariaLabel,
  ['data-testid']: dataTestId,
}) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      {title && (
        <Heading title={title} icon={icon} aria-label={ariaLabel ?? title} data-testid={dataTestId} actions={actions} />
      )}
      {children}
    </div>
  )
}
