import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Cell, Pie, PieChart as RechartsPieChart } from 'recharts'
import * as d3 from 'd3'
import { Stack } from '@howgood/design'
import { selectPortfolioData } from '../state/portfolioDashboard'
import { PieChartGrid } from './PieChartGrid'

const height = 250

export const PieChart: FC = () => {
  const data = useSelector(selectPortfolioData)

  // Specify the color scale
  const color = d3.scaleOrdinal(
    data.map((d) => d.id),
    d3.schemeTableau10
  )

  return (
    <Stack direction="row" alignItems="center" gap={2} height="100%">
      <RechartsPieChart width={height} height={height}>
        <Pie data={data.slice(0, 200)} outerRadius={height / 2} dataKey="value" animationDuration={0}>
          {data.map((_entry, index) => (
            <Cell key={`cell-${index}`} fill={index < 10 ? color(index) : '#DDD'} />
          ))}
        </Pie>
      </RechartsPieChart>
      <PieChartGrid />
    </Stack>
  )
}
