import React, { FC, useState } from 'react'
import { faChartPie } from '@fortawesome/pro-solid-svg-icons'
import { Button, Stack } from '@howgood/design'
import { ExportDialog } from './ExportDialog'

export const Scope3ExportButton: FC = () => {
  const [dialogOpen, setDialogOpen] = useState(false)

  return (
    <Stack>
      <Button data-testid="scope-3-button" size="small" startIcon={faChartPie} onClick={() => setDialogOpen(true)}>
        Export Scope 3 Report
      </Button>
      <ExportDialog open={dialogOpen} exportType="download-scope3" onClose={() => setDialogOpen(false)} />
    </Stack>
  )
}
