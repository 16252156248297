import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Stack, Button } from '@howgood/design'
import { useNavigate } from '@/hooks'
import { setAbatementStrategiesDrawer } from '@/state/pageSettings'
import { selectSplitTreatmentEnabled } from '@/state/splitio'

export const ButtonActions: FC = () => {
  const abatementNextSteps = useSelector(selectSplitTreatmentEnabled('abatement_nextstep'))

  const dispatch = useDispatch()
  const { safelyNavigateTo } = useNavigate()
  const handleGoToInitiatives = () => {
    dispatch(setAbatementStrategiesDrawer(false))
    safelyNavigateTo('/initiatives')
  }

  return (
    <Stack direction="row" gap={2} justifyContent="end" flexShrink={1}>
      {abatementNextSteps && (
        <Button color="secondary" onClick={() => dispatch(setAbatementStrategiesDrawer(false))}>
          Suggest to Vendor
        </Button>
      )}
      {abatementNextSteps && (
        <Button color="secondary" onClick={handleGoToInitiatives}>
          Set Goal
        </Button>
      )}
      <Button color="primary" onClick={handleGoToInitiatives}>
        Create Initiative
      </Button>
    </Stack>
  )
}
