import React, { forwardRef } from 'react'
import MuiCircularProgress, { CircularProgressProps as MuiCircularProgressProps } from '@mui/material/CircularProgress'

export interface CircularProgressProps extends Omit<MuiCircularProgressProps, 'className' | 'sx'> {
  ['aria-label']?: string
  ['data-pendo-id']?: string
  ['data-testid']?: string
  color?: 'primary' | 'secondary' | 'inherit'
}

export const CircularProgress = forwardRef<HTMLElement, CircularProgressProps>(
  (
    {
      ['aria-label']: ariaLabel,
      ['data-pendo-id']: dataPendoId,
      ['data-testid']: dataTestId,
      color = 'secondary',
      ...rest
    },
    ref
  ) => {
    return (
      <MuiCircularProgress
        ref={ref}
        aria-label={ariaLabel}
        data-pendo-id={dataPendoId}
        data-testid={dataTestId}
        color={color}
        {...rest}
      />
    )
  }
)

CircularProgress.displayName = 'CircularProgress'
