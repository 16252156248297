import { IProduct, formulationStatuses, getFormulationTags, WorkspaceOption, SharingRequest } from '@/records'
import { FormulationTag, ImpactScore, InventoriesResponse, ValidationRequest } from '@/api'
import { ecoScoreToLetter } from './ecoscore'
import { isProjectedNutrition, nutritionScoreToLetter } from './nutritions'
import { shareLocation } from './getUrl'
import { QUALITATIVE_METRICS, transformScoreToZeroToTenScale } from '@/utils/impactScoreHelpers'
import { OrgUser } from '@/state/organization'
import { ESIngredient } from '@/state/products/products.utils'
import { formatNumber } from '@howgood/utils'
import { SCOPE_3_CONTRIBUTION_FIELD } from '@/constants/impactScore'
import { HOWGOOD_SUPPLIERS_WORKSPACE_ID } from '@/constants/common'
import { getMostRecentRequest } from '@/state/productOverview'
import { ContentfulDataHealthIndicatorKey } from '@/contentfully'
import { formatDateString, parseReportDateKey } from './dates'
import { generateShareStatusOption, generateValidationStatusOption } from './ValidationStatusUtils'

type ProductWithImpactScore = IProduct & ImpactScore
export interface ProductListDataRow extends ProductWithImpactScore {
  workspaceLabel: string
  statusLabel: string
  formulationTags: FormulationTag[]
  link?: string
  vendor?: string
  assignee?: OrgUser
  ingredients?: ESIngredient[]
  certifications?: string
  internalId: string
  pricePerMt: number
  packagingDescription: string
  mtPerYear: number
  buyer: string
  productionLocation: string
  validation_request: ValidationRequest
}

function getInventory(product: IProduct): InventoriesResponse {
  const inventories: InventoriesResponse[] = product.inventories

  if (inventories.length === 1) {
    return inventories[0]
  }

  if (inventories.length > 1) {
    return (
      inventories.find((inventory) => {
        const workspaceId = inventory.workspace_id || inventory.workspace?.id
        return workspaceId !== HOWGOOD_SUPPLIERS_WORKSPACE_ID
      }) || null
    )
  }

  return null
}

const getValidationRequest = (product: IProduct): ValidationRequest => {
  const inventory = getInventory(product)
  return inventory ? (getMostRecentRequest(inventory.validation_requests) as ValidationRequest) : null
}

function getProductionLocation(product: IProduct): string {
  return product.region?.name
}

function getPricePerMt(product: IProduct): number {
  const inventory = getInventory(product)
  return inventory ? inventory.price_per_mt : null
}

function getPackagingDescription(product: IProduct): string {
  const inventory = getInventory(product)
  return inventory ? inventory.packaging_description : ''
}

function getBuyer(product: IProduct): string {
  const inventory = getInventory(product)
  return inventory && inventory.buyer_name
    ? `${inventory.buyer_name}${inventory.buyer_role ? ` (${inventory.buyer_role})` : ''}`
    : ''
}

function getInternalId(product: IProduct): string {
  const inventories: InventoriesResponse[] = product.inventories

  return inventories.map((inventory) => inventory.internal_id).join(', ')
}

export const prepareProductsGridRow = (
  product: IProduct,
  userWorkspacesOptions: WorkspaceOption[],
  isProcurement: boolean = false,
  scenarioId?: number
): ProductListDataRow => {
  const productWorkspaces = product.workspaces || [] // All workspaces associated with this product
  const userWorkspaceIds = userWorkspacesOptions.map((workspace) => workspace.id) // IDs of all the user's workspaces

  // Get just the product workspaces the user has access to
  const workspaces = productWorkspaces.filter((workspace) => userWorkspaceIds.includes(workspace.id))

  const workspaceLabel = workspaces?.length
    ? workspaces
        .map((workspace) => workspace.name)
        .sort((a, b) => a.localeCompare(b))
        .join(', ')
    : ''
  const statusLabel = formulationStatuses.find((v) => v.value === product.formulation_status)?.label || ''

  return {
    ...product,
    locked_claims_timestamp: product.locked_claims?.product_attributes?.locked_claims_timestamp || null,
    workspaceLabel,
    statusLabel,
    formulationTags: getFormulationTags(product),
    certifications: [...new Set(product?.standards?.map((s) => s.title))].join(', '),
    ...product.impact_score,
    link: shareLocation(product.id, isProcurement, scenarioId),
    vendor: product.brand?.name,
    internalId: getInternalId(product),
    pricePerMt: getPricePerMt(product),
    packagingDescription: getPackagingDescription(product),
    mtPerYear: product.custom_fields?.mt_per_year || 0,
    buyer: getBuyer(product),
    productionLocation: getProductionLocation(product),
    validation_request: getValidationRequest(product),
    ...product.custom_fields,
  }
}

export interface ProductListDataRowExport
  extends Omit<
    ImpactScore,
    | 'nutrition_score'
    | 'eco_score_impact'
    | 'default_origin_percent_impact'
    | 'has_default_weights'
    | 'date_modified'
    | 'sdg_6_4_impact'
    | 'sdg_8_7_impact'
    | 'sdg_8_8_impact'
    | 'sdg_12_2_impact'
    | 'sdg_13_2_impact'
    | 'sdg_15_9_impact'
  > {
  nutrition_score: string
  eco_score_impact: string
  default_origin_percent_impact: string
  has_default_weights: string
  date_modified: string
  sdg_6_4_impact: string
  sdg_8_7_impact: string
  sdg_8_8_impact: string
  sdg_12_2_impact: string
  sdg_13_2_impact: string
  sdg_15_9_impact: string
  reports: string
  assignee: string
  formulationTags: string
  ingredients: string
  sourcing_locations: string
  custom_fields: Record<string, string | number>
  [SCOPE_3_CONTRIBUTION_FIELD]?: string
  certifications: string
  brand: string
  validation_request: string
  sharing_requests: string
  key: string
}

interface SDGScore {
  label: string
  score: number
}
export function getSDGScore(score: number): SDGScore {
  const defaultOutput = { label: '- -', score }

  switch (true) {
    case typeof score !== 'number':
      return defaultOutput
    case score < 5:
      return { label: 'Not aligned', score: 1 }
    case score < 7:
      return { label: 'Low', score: 3 }
    case score < 9:
      return { label: 'Medium', score: 5 }
    case score >= 9:
      return { label: 'High', score: 10 }
    default:
      return defaultOutput
  }
}

export const getDataHealthValue = (field: ContentfulDataHealthIndicatorKey, value: any): string => {
  switch (field) {
    case 'default_origin_percent_impact':
      return value !== 1 ? 'Provided' : null
    case 'has_default_weights':
      return value === false ? 'Provided' : null
    case 'date_modified':
      return value ? formatDateString({ dateString: value }) : null
    default:
      return null
  }
}

export const prepareProductsGridRowExport = (
  product: IProduct,
  userWorkspacesOptions: WorkspaceOption[]
): ProductListDataRowExport => {
  const gridRow = prepareProductsGridRow({ ...product, id: product.id || product.pk }, userWorkspacesOptions)
  QUALITATIVE_METRICS.forEach((metric) => (gridRow[metric] = transformScoreToZeroToTenScale(metric, gridRow[metric])))
  const isProjected = isProjectedNutrition(product)
  const ingredients = gridRow.ingredients || product.es_ingredients
  const standards = ingredients?.reduce((acc, ingredient) => {
    if (ingredient.standards) {
      return acc.concat(ingredient.standards)
    }
    return acc
  }, [])

  return {
    ...gridRow,
    key: `export-${gridRow.id}`,
    nutrition_score: isProjected ? null : nutritionScoreToLetter(gridRow.nutrition_score),
    eco_score_impact: ecoScoreToLetter(gridRow.eco_score_impact),
    default_origin_percent_impact: getDataHealthValue(
      'default_origin_percent_impact',
      gridRow.default_origin_percent_impact
    ),
    has_default_weights: getDataHealthValue('has_default_weights', gridRow.has_default_weights),
    brand: gridRow.brand?.name || '',
    date_modified: getDataHealthValue('date_modified', gridRow.date_modified),
    sdg_6_4_impact: getSDGScore(gridRow.sdg_6_4_impact).label,
    sdg_8_7_impact: getSDGScore(gridRow.sdg_8_7_impact).label,
    sdg_8_8_impact: getSDGScore(gridRow.sdg_8_8_impact).label,
    sdg_12_2_impact: getSDGScore(gridRow.sdg_12_2_impact).label,
    sdg_13_2_impact: getSDGScore(gridRow.sdg_13_2_impact).label,
    sdg_15_9_impact: getSDGScore(gridRow.sdg_15_9_impact).label,
    reports: gridRow.locked_claims_timestamp
      ? parseReportDateKey(gridRow.locked_claims_timestamp).toLocaleDateString()
      : '',
    validation_request: gridRow.validation_request
      ? generateValidationStatusOption(gridRow.validation_request, !!gridRow.locked_claims?.impact_score).status
      : '',
    sharing_requests: gridRow.sharing_requests
      ? generateShareStatusOption(
          getMostRecentRequest(gridRow.sharing_requests) as SharingRequest,
          !!gridRow.locked_claims?.impact_score
        ).status
      : '',
    certifications: [...new Set(standards?.map((s) => s.title))].join(', '),
    assignee: gridRow.assignee?.email || '',
    formulationTags: gridRow.formulationTags?.join(', ') || '',
    ingredients: gridRow.ingredients?.map((ingredient) => ingredient.name).join(', ') || '',
    sourcing_locations:
      gridRow.ingredients?.map((ingredient) => ingredient.ingredient_origin_location_region_name || '-- ').join(', ') ||
      '',
    custom_fields: gridRow.custom_fields?.[SCOPE_3_CONTRIBUTION_FIELD]
      ? {
          ...gridRow.custom_fields,
          // Format the contribution as a percentage
          [SCOPE_3_CONTRIBUTION_FIELD]: `${formatNumber(gridRow.custom_fields?.[SCOPE_3_CONTRIBUTION_FIELD] * 100)}%`,
        }
      : gridRow.custom_fields,
    [SCOPE_3_CONTRIBUTION_FIELD]: gridRow[SCOPE_3_CONTRIBUTION_FIELD]
      ? `${formatNumber(gridRow[SCOPE_3_CONTRIBUTION_FIELD] * 100)}%`
      : '',
  }
}
