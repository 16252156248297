import React, { FC, useState } from 'react'
import { Button, Text, Dialog, DialogTitle, DialogContent, DialogActions } from '@howgood/design'
import { CollectVendorDataAccordion } from './CollectVendorDataAccordion'
import { CollectAgriculturalAndFarmingPracticeAccordion } from './CollectAgriculturalAndFarmingPracticeAccordion'
import { HaveVendorDataAccordion } from './HaveVendorDataAccordion'
import { useContentfulRequestVendorData } from '@/contentfully/hooks'
import { useSelector } from 'react-redux'
import { selectLcaOverrideSplit, selectMaterialIconAgpracticeSplit } from '@/state/splitio'

interface Props {
  onClose: () => void
}

type VendorDataAccordion = 'collect-vendor-data' | 'collect-agricultural-farming-practice' | 'have-vendor-data'

const COLLECT_VENDOR_DATA = 'collect-vendor-data' as VendorDataAccordion
const COLLECT_AG_AND_FARM_PRACTICE = 'collect-agricultural-farming-practice' as VendorDataAccordion
const HAVE_VENDOR_DATA = 'have-vendor-data' as VendorDataAccordion

export const RequestMultipleVendorDataDialog: FC<Props> = ({ onClose }) => {
  const lcaOverrideEnabled = useSelector(selectLcaOverrideSplit)
  const materialIconAgpracticeEnabled = useSelector(selectMaterialIconAgpracticeSplit)

  const [expandedAccordion, setExpandedAccordion] = useState<VendorDataAccordion>(
    !lcaOverrideEnabled && !materialIconAgpracticeEnabled ? COLLECT_VENDOR_DATA : null
  )
  const [linkCopiedFromAccordion, setLinkCopiedFromAccordion] = useState<VendorDataAccordion>(null)

  const content = useContentfulRequestVendorData('request-vendor-data')

  if (!content) {
    return null
  }

  const handleCopy = (fromAccordion: VendorDataAccordion) => {
    setLinkCopiedFromAccordion(fromAccordion)
  }

  const handleAccordionToggle = (accordion: VendorDataAccordion) => {
    setExpandedAccordion(expandedAccordion !== accordion ? accordion : null)
  }

  return (
    <Dialog id="request-vendor-data-dialog" open maxWidth="sm" onClose={onClose}>
      <DialogTitle>{content.title}</DialogTitle>
      <DialogContent>
        <Text variant="body1">{content.multipleVendorsDescription}</Text>
        <CollectVendorDataAccordion
          expanded={expandedAccordion === COLLECT_VENDOR_DATA}
          isCopiedFromCurrent={linkCopiedFromAccordion === COLLECT_VENDOR_DATA}
          handleDataPortalLinkTypeChange={() => {
            if (linkCopiedFromAccordion === COLLECT_VENDOR_DATA) {
              // this need to not show Link Copied text when changing an option
              setLinkCopiedFromAccordion(null)
            }
          }}
          onChange={() => handleAccordionToggle(COLLECT_VENDOR_DATA)}
          handleCopy={() => handleCopy(COLLECT_VENDOR_DATA)}
          title={content.simpleVendorDataTitle}
          content={content.simpleVendorData}
        />
        {materialIconAgpracticeEnabled && (
          <CollectAgriculturalAndFarmingPracticeAccordion
            data-testid="agricultural-practice-data-accordion"
            expanded={expandedAccordion === COLLECT_AG_AND_FARM_PRACTICE}
            isCopiedFromCurrent={linkCopiedFromAccordion === COLLECT_AG_AND_FARM_PRACTICE}
            onChange={() => handleAccordionToggle(COLLECT_AG_AND_FARM_PRACTICE)}
            handleCopy={() => handleCopy(COLLECT_AG_AND_FARM_PRACTICE)}
            title={content.agriculturalPracticeDataTitle}
            content={content.agriculturalPracticeData}
          />
        )}
        {lcaOverrideEnabled && (
          <HaveVendorDataAccordion
            data-testid="have-vendor-data-accordion"
            expanded={expandedAccordion === HAVE_VENDOR_DATA}
            isCopiedFromCurrent={linkCopiedFromAccordion === HAVE_VENDOR_DATA}
            onChange={() => handleAccordionToggle(HAVE_VENDOR_DATA)}
            handleCopy={() => handleCopy(HAVE_VENDOR_DATA)}
            title={content.lcaDataTitle}
            content={content.lcaData}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button id="request-vendor-data-dialog-button" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
