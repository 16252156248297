import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IBrandSearchOption, UNKNOWN_VENDOR_OPTION } from '@/records'
import { selectWorkspacesIds } from '@/state/workspaces'
import { selectVendorFilters, updateFiltersUrlAndProducts } from '@/state/products'
import { fetchBrandsByQuery } from '@/api/elastic/brandsEsRepo'
import { AutocompleteFilter } from '../Shared/Filters/AutocompleteFilter'

export const VendorFilter: FC = () => {
  const dispatch = useDispatch()
  const workspaceIds = useSelector(selectWorkspacesIds)
  const vendors = useSelector(selectVendorFilters)

  const handleChange = (vendorOptions: IBrandSearchOption[]) => {
    dispatch(updateFiltersUrlAndProducts({ updatedFilters: { vendors: vendorOptions } }))
  }

  const fetchOptions = async (query: string) => {
    return await fetchBrandsByQuery({
      workspaceIds,
      search: query,
      cancelPrevious: true,
    })
  }

  return (
    <AutocompleteFilter
      id="vendors-search"
      selected={vendors}
      filterType="vendors"
      handleChangeFilter={handleChange}
      onUpdateQuery={fetchOptions}
      additionalOptions={[UNKNOWN_VENDOR_OPTION]}
    />
  )
}
