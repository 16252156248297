import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Stack, Card, Text } from '@howgood/design'
import { initializeProductsPage } from '@/state/products'
import { Products } from '@/components'
import { Scores } from '@/components/Scores/Scores'
import { ContainsComponentFilter } from '@/components/ProductsFilters/ContainsComponentFilter'
import { WorkspaceProductFilter } from '@/components/ProductsFilters/WorkspaceProductFilter'
import { FormulaNameFilter } from '@/components/ProductsFilters/FormulaNameFilter'
import { TagsFilter } from '@/components/ProductsFilters/TagsFilter'
import { IngredientFilter } from '@/components/ProductsFilters/IngredientFilter'
import { useScoreCardDataReady } from '../Scores/ScoreCards'
import { ProductListPageHeader } from './ProductListPageHeader'
import { VendorFilter } from '../ProductsFilters/VendorFilter'
import { FormulationStatusProductFilter } from '../ProductsFilters/FormulationStatusProductFilter'
import { PRODUCTS } from '@/records'
import { selectHasWorkspaceOfType } from '@/state/workspaces'
import { selectIsSupplierConnect } from '@/state/organization'

export const MyFormulations: FC = () => {
  const dispatch = useDispatch()
  const isScoreDataLoaded = useScoreCardDataReady()
  const isSupplierConnect = useSelector(selectIsSupplierConnect)
  const hasFormulationWorkspaces = useSelector(selectHasWorkspaceOfType(PRODUCTS))

  useEffect(() => {
    dispatch(initializeProductsPage({ workspaceTypes: [PRODUCTS] }))
  }, [dispatch])

  return (
    <Stack>
      <ProductListPageHeader />
      <Stack data-testid="product-list-with-filters">
        {!hasFormulationWorkspaces ? (
          <Card fullHeight outlineDashed>
            <Stack py={6} direction="row" justifyContent="center">
              <Text>No product workspaces to show. Contact support@howgood.com for setup help.</Text>
            </Stack>
          </Card>
        ) : (
          <>
            <Stack flexDirection="row" alignItems="center" justifyContent="stretch" gap={1} mb={2}>
              {!isSupplierConnect && <WorkspaceProductFilter />}
              <FormulaNameFilter />
              {!isSupplierConnect && (
                <>
                  <IngredientFilter />
                  <ContainsComponentFilter />
                  <VendorFilter />
                  <FormulationStatusProductFilter />
                  <TagsFilter />
                </>
              )}
            </Stack>
            {isScoreDataLoaded && !isSupplierConnect && <Scores />}
            <Products />
          </>
        )}
      </Stack>
    </Stack>
  )
}
