import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MenuItem, Select, SelectChangeEvent } from '@howgood/design'
import { selectDisplayedSalesAndDistribution, updateRecipeAndScores } from '@/state/recipe'
import { ColdStorageType } from '@/state/productOverview/productOverview.state'
import { DRAFT, selectPageView } from '@/state/pageSettings'
import { GridItemTitle } from '../GridItemTitle'

export const RequiresColdStorage: FC = () => {
  const dispatch = useDispatch()
  const salesAndDistribution = useSelector(selectDisplayedSalesAndDistribution)
  const view = useSelector(selectPageView)

  const handleColdStorageChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value
    dispatch(
      updateRecipeAndScores({
        recipeUpdates: {
          sales_distribution: {
            ...salesAndDistribution,
            requires_cold_storage: value === 'No' ? '' : (value as ColdStorageType),
          },
        },
        change: `Changed requires_cold_storage to ${value}`,
      })
    )
  }

  return (
    <>
      <GridItemTitle title="Requires Cold Storage" tooltipText="Required for cradle-to-grave carbon metrics" />
      <Select
        id="cold-storage-select"
        disabled={view !== DRAFT}
        value={salesAndDistribution.requires_cold_storage || 'No'}
        onChange={handleColdStorageChange}
        MenuProps={{ id: 'cold-storage-menu' }}
        fullWidth
      >
        <MenuItem value="No">No</MenuItem>
        <MenuItem value="R">Refrigerated</MenuItem>
        <MenuItem value="F">Frozen</MenuItem>
      </Select>
    </>
  )
}
