import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { selectIsVendors } from '@/state/router'
import { NotesAccordion } from '@/components/Scores/OverviewPanel/NotesAccordion'
import { selectVendorNotes } from '@/state/vendor'
import { selectSplitTreatmentEnabled } from '@/state/splitio'

export const VendorNotes: FC<{ isFavorites?: boolean }> = ({ isFavorites }) => {
  const hasVendorNotesSplit = useSelector(selectSplitTreatmentEnabled('vendor_notes'))
  const isVendor = useSelector(selectIsVendors)
  const displayedNotes = useSelector(selectVendorNotes)

  if (!isVendor || !hasVendorNotesSplit) {
    return null
  }

  return (
    <NotesAccordion
      isFavorites={isFavorites}
      id="vendor-notes-panel"
      displayedNotes={displayedNotes}
      title="Vendor Info"
    />
  )
}
