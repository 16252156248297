import React, { FC } from 'react'
import { Document } from '@contentful/rich-text-types'
import { ComposableMap, Geographies, Sphere, Graticule, ZoomableGroup } from 'react-simple-maps'
import { Accordion, AccordionDetails, Text, theme } from '@howgood/design'
import { MetricEntry, RichText } from '@/contentfully'
import { AccordionCardHeader } from '@/components/Scores/AccordionCardHeader'
import { RecipeIngredient, RecipeNestedIngredient } from '@/state/recipe'
import { Geo } from '../Country'
import { ChangeSourcingLocationCountry } from './ChangeSourcingLocationCountry'
import { useFetchOriginLocations, useFetchIngredientImpactScores } from './ChangeSourcingLocation.hooks'

interface Props {
  metric: MetricEntry
  ingredient: RecipeIngredient | RecipeNestedIngredient
  baseId: number
  originLocationId: number
  changeSourcingLocation: Document
}

export const ChangeSourcingLocation: FC<Props> = ({
  metric,
  ingredient,
  baseId,
  originLocationId,
  changeSourcingLocation,
}) => {
  const { data: originLocations } = useFetchOriginLocations(baseId)
  const { data: impactScores } = useFetchIngredientImpactScores(ingredient, originLocations)

  if (
    baseId === null ||
    originLocationId === null ||
    !changeSourcingLocation ||
    originLocations.length === 0 ||
    impactScores.length === 0
  ) {
    return null
  }

  const currentLocation = originLocations.find((ol) => ol.origin_location_id === originLocationId)

  return (
    <Accordion data-testid="change-sourcing-location" defaultExpanded>
      <AccordionCardHeader title="Improvement: Change sourcing location" />
      <AccordionDetails>
        {currentLocation && (
          <Text data-testid="current-sourcing-location">
            Current sourcing location: <b>{currentLocation.region_name}</b>
          </Text>
        )}
        <ComposableMap width={980}>
          <ZoomableGroup>
            <Sphere id="sphere" fill="white" stroke={theme.palette.divider} strokeWidth={1} />
            <Graticule stroke={theme.palette.divider} />
            <Geographies geography="/map.json">
              {({ geographies }: { geographies: Geo[] }) =>
                geographies.map((geo) => (
                  <ChangeSourcingLocationCountry
                    metric={metric}
                    key={geo.rsmKey}
                    geo={geo}
                    originLocations={originLocations}
                    currentLocationId={currentLocation?.iso3_country_code}
                    impactScores={impactScores}
                  />
                ))
              }
            </Geographies>
          </ZoomableGroup>
        </ComposableMap>
        <RichText document={changeSourcingLocation} />
      </AccordionDetails>
    </Accordion>
  )
}
