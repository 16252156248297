import { faArrowRightToBracket } from '@fortawesome/pro-regular-svg-icons'
import React, { FC } from 'react'
import { useRouteMatch } from 'react-router'
import { Box, Icon, Stack, Text } from '@howgood/design'
import { useNavigate } from '../../hooks/useNavigate'

interface SidebarSubItemProps {
  label: string
  path: string
  isThirdParty?: boolean
  active?: boolean
  'data-pendo-id'?: string
  ['data-testid']?: string
  handleClick?: (e: React.MouseEvent<HTMLDivElement>) => void
}

export const SidebarSubItem: FC<SidebarSubItemProps> = ({
  label,
  path,
  isThirdParty,
  active,
  handleClick,
  ['data-testid']: dataTestId,
  ...rest
}) => {
  const { safelyNavigateTo } = useNavigate()
  const match = useRouteMatch({ path })
  const color = match || active ? '#1D9862' : 'inherit'

  const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (handleClick) {
      handleClick(e)
    } else {
      safelyNavigateTo(path, e)
    }
  }

  return (
    <Stack
      aria-label={label}
      data-pendo-id={rest['data-pendo-id']}
      data-testid={dataTestId}
      borderLeft={match || active ? '6px solid #1D9862' : '6px solid #3b4959'}
      bgcolor={match || active ? '#343F50' : 'inherit'}
    >
      <Box onClick={onClick}>
        <Stack
          direction="row"
          p="3px 0 3px 34px"
          sx={{
            cursor: 'pointer',
            '&:hover': { backgroundColor: '#343F50' },
          }}
        >
          {isThirdParty && (
            <Box mr={1} data-testid={`third-party-icon-${label}`}>
              <Icon color={color} icon={faArrowRightToBracket} />
            </Box>
          )}
          <Text variant="body2" color={color}>
            {label}
          </Text>
        </Stack>
      </Box>
    </Stack>
  )
}
