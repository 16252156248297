import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Accordion,
  AccordionDetails,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Alert,
} from '@howgood/design'
import { AccordionCardHeader } from '@/components/Scores/AccordionCardHeader'
import { Field } from '@/constants/impactScore'
import { selectContentfulImpactHistoryData } from '@/state/contentfulData'
import { selectIsRollup, selectIsVendors } from '@/state/router'
import { selectSplitTreatmentEnabled } from '@/state/splitio'
import { ImpactHistoryChart } from './ImpactHistoryChart'
import { ImpactHistoryExport } from './ImpactHistoryExport'

/**
 * Used on the vendor detail page only.
 * This is temporary until we have a way to get impact history for filtered products from the backend.
 * Until then, display a message indicating that the impact history isn't available when the list is filtered.
 * Returns true if the user has modified the product filters from the default "all pipeline products" for the selected vendor.
 */
const vendorProductFiltersHaveChanged = () => {
  // Don't use the router `selectSearch` because it doesn't get updated after page initialization
  const urlSearchString = window.location?.search
  const urlSearchItems = urlSearchString ? urlSearchString.slice(1).split('&') : []

  // Exclude the `page` and `vendors` params, and the status if it's the default pipeline status
  const nonDefaultUrlItems = urlSearchItems.filter(
    (item) => !item.includes('page') && !item.includes('vendors') && item !== 'statuses[0]=pipeline'
  )

  // If the array has items left in it, then we have non-default filters in place
  return nonDefaultUrlItems.length ? true : false
}

interface Props {
  isFavorites?: boolean
}

export const ImpactHistory: FC<Props> = ({ isFavorites = false }) => {
  const [metricKey, setMetricKey] = useState<Field>('howgood_one_metric')
  const metrics = useSelector(selectContentfulImpactHistoryData)
  const isRollup = useSelector(selectIsRollup)
  const impactHistoryEnabled = useSelector(selectSplitTreatmentEnabled('impact-history'))
  const isVendorsPage = useSelector(selectIsVendors)

  const metricDropdownLabel = isVendorsPage ? 'Metric (average)' : 'Metric'

  if ((isRollup && !isVendorsPage) || !impactHistoryEnabled) {
    return null
  }

  if (isVendorsPage && vendorProductFiltersHaveChanged()) {
    return (
      <Grid item xs={12}>
        <Alert severity="warning">
          Product filters have changed. Impact history for the current set of products is unavailable.
        </Alert>
      </Grid>
    )
  }

  return (
    <Grid item xs={12}>
      <Accordion data-testid="impact-reduction-panel" defaultExpanded>
        <AccordionCardHeader
          id="impact-history-title"
          cardKey="impactHistory"
          title="Impact History"
          favoritesTab={isFavorites}
          Action={<ImpactHistoryExport />}
        />
        <AccordionDetails>
          <Stack gap={2}>
            <Stack width={250}>
              <FormControl fullWidth>
                <InputLabel id="metric-select-label" size="small">
                  {metricDropdownLabel}
                </InputLabel>
                <Select
                  data-testid="metric-select"
                  label={metricDropdownLabel}
                  labelId="metric-select-label"
                  value={metricKey}
                  fullWidth
                >
                  {metrics.map((m) => (
                    <MenuItem
                      key={m.key}
                      data-testid={`option-${m.key}`}
                      value={m.key}
                      onClick={() => setMetricKey(m.key)}
                    >
                      {m.title === 'Carbon Footprint' ? m.subtitle : m.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
            <ImpactHistoryChart metricKey={metricKey} />
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Grid>
  )
}
