import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectProductMetrics } from '@/state/productMetrics'
import { selectContentfulMetricData } from '@/state/contentfulData'
import { useProductListColumns } from './useProductListColumns'
import { getScope3ColGroupFields } from '../utils/productListColDefs'
import { scope3CarbonOrderMap } from '@/utils/getSortedImpacts'
import { selectProductListSelectedColumns } from '@/state/user'
import { ProductsGridColDef } from '@/components/GridCellRenderers'

const SCOPE_3_EXTRA_FIELDS = [
  'name',
  'mtPerYear',
  'vendor',
  'brand',
  'cf_flag_impact',
  'carbon_footprint_farm_to_gate_impact',
  'cf_total_upstream_transportation_impact',
  'cf_mtm_transportation_impact',
  'cf_total_upstream_processing_impact',
  'cf_manufacturing_impact',
  'cf_mtr_transportation_impact',
  'cf_ftm_gate_impact',
]

export const useScope3Columns = (): ProductsGridColDef[] => {
  const columns = useProductListColumns({ ignoreRenderCell: true })
  return useMemo(() => {
    return columns.filter((col) => {
      return [...getScope3ColGroupFields(), ...SCOPE_3_EXTRA_FIELDS].includes(col.field)
    })
  }, [columns])
}

export const useExcelExportAggRows = (isScope3: boolean = false): string[][] => {
  const selectedColumns = useSelector(selectProductListSelectedColumns)
  const productAggs = useSelector(selectProductMetrics)
  const contentfulData = useSelector(selectContentfulMetricData)
  const scope3Columns = useScope3Columns()

  const headerRow = ['', 'Metric', 'Score', 'Unit']

  const generateAggRowData = (scope3: boolean = false): string[][] => {
    const scope3ColFields = scope3Columns.map((col) => col.field)
    const filteredContentfulData = contentfulData.filter((entry) => scope3 || selectedColumns.includes(entry.key))
    const rows = filteredContentfulData.reduce((acc, entry) => {
      const aggKey = entry.key in productAggs ? entry.key : `${entry.key}_sum`
      const hasValue = productAggs[aggKey] || productAggs[aggKey] === 0
      // include metrics that have a value AND are in the scope3Columns array
      // IF this is a scope 3 report, OR include all metrics if not a scope 3 report
      return hasValue && (!scope3 || scope3ColFields.includes(entry.key))
        ? [
            ...acc,
            {
              rowData: [
                '',
                entry.subtitle ? `${entry.title} - ${entry.subtitle}` : entry.title,
                Math.round(productAggs[aggKey] * 10000) / 10000,
                entry.units || ' ',
              ],
              order: scope3CarbonOrderMap[entry.key] || 999,
            },
          ]
        : acc
    }, [])
    // enforce order requested by product team
    if (scope3) {
      rows.sort((a, b) => a.order - b.order)
    }
    return rows.map((row) => row.rowData)
  }

  return [headerRow, ...generateAggRowData(isScope3)]
}
