import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IconButton, Text, Tooltip } from '@howgood/design'
import { reorderIngredient, selectDisplayedIngredientList, getParentDotPath, checkIfSibling } from '@/state/recipe'
import { DRAFT, selectPageView } from '@/state/pageSettings'
import { RecipeIngredient, RecipeNestedIngredient } from '@/state/recipe/recipe.state'
import { faArrowUp, faArrowDown } from '@fortawesome/pro-regular-svg-icons'

interface MoveRowProps {
  ingredient: RecipeIngredient | RecipeNestedIngredient
}

export const MoveRow: FC<MoveRowProps> = ({ ingredient }) => {
  const dispatch = useDispatch()
  const ingredients = useSelector(selectDisplayedIngredientList)
  const view = useSelector(selectPageView)

  const parentDotPath = getParentDotPath(ingredient)
  const allSiblings =
    parentDotPath === 'ROOT'
      ? ingredients.filter((item) => item.isTopLevel)
      : ingredients.filter((item) => checkIfSibling(item, ingredient))
  const isFirstSibling = ingredient.position === 1
  const isLastSibling = ingredient.index === allSiblings.slice(-1)[0]?.index

  const handleMoveItem = (direction: 'up' | 'down') => {
    dispatch(reorderIngredient(direction, ingredient))
  }

  if (!ingredient.isTopLevel) {
    return null
  }

  return (
    <>
      <Tooltip
        childrenDisabled={view !== DRAFT || isFirstSibling}
        title={<Text>Move ingredient and its children up</Text>}
      >
        <IconButton
          id={`ingredient-up-button-${ingredient.index}`}
          aria-label="Move ingredient-up"
          icon={faArrowUp}
          disabled={view !== DRAFT || isFirstSibling}
          onClick={() => handleMoveItem('up')}
        />
      </Tooltip>
      <Tooltip
        childrenDisabled={view !== DRAFT || isLastSibling}
        title={<Text>Move ingredient and its children down</Text>}
      >
        <IconButton
          id={`ingredient-down-button-${ingredient.index}`}
          aria-label="Move ingredient-down"
          icon={faArrowDown}
          disabled={view !== DRAFT || isLastSibling}
          onClick={() => handleMoveItem('down')}
        />
      </Tooltip>
    </>
  )
}
