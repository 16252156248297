import { createAsyncThunk } from '@reduxjs/toolkit'
import { ProductNutrition } from '@/records/nutrition'
import { VIEW_PRODUCT_NUTRITION } from '@/constants/impactScore'
import { selectIsThirdParty } from '../productOverview'
import { selectUser } from '@/state/user'
import { AppState } from '@/store'
import { fetchNutritionScore } from './nutrition.requests'

export const getNutrition = createAsyncThunk<ProductNutrition, number, { state: AppState }>(
  'nutrition/getNutrition',
  async (id, { getState }) => {
    const isThirdParty = selectIsThirdParty(getState())
    const user = selectUser(getState())

    if (isThirdParty || !user.permissions.products.includes(VIEW_PRODUCT_NUTRITION)) {
      return null
    }

    return await fetchNutritionScore(id)
  }
)
