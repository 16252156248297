import { createSelector } from 'reselect'
import { MetricEntry } from '@/contentfully'
import { AppState } from '@/store'
import { selectContentfulMetricData } from '@/state/contentfulData'
import { selectMetricGridColumns } from '@/state/preferences'
import { selectIsVendors, selectIsInitiative } from '@/state/router'
import { CheckboxOption } from '@/components/Shared/CheckboxesDropdown/CheckboxesDropdown'
import {
  selectCarbonFootprintPermissions,
  selectImpactMetricPermissions,
  selectProductMetricsPermissions,
} from '@/state/user'
import {
  Field,
  PERMISSIONS_TO_FIELDS_MAP,
  SCOPE_3_CATEGORY_1_FIELD,
  SCOPE_3_CATEGORY_4_FIELD,
} from '@/constants/impactScore'
import { getContentfulEntry } from '@/records/scoreCardData'

export type WorkspacePermission = {
  key: string
  value: Field
  colGroup: string
}
export const selectWorkspaceListPermissions = createSelector(
  selectImpactMetricPermissions,
  selectProductMetricsPermissions,
  selectCarbonFootprintPermissions,
  selectIsInitiative,
  selectIsVendors,
  (impactPermissions, productMetricPermissions, carbonFootprintPermissions, isIinitiative, isVendor) => {
    const defaultItems = impactPermissions.map((permission) => ({
      key: permission,
      value: PERMISSIONS_TO_FIELDS_MAP[permission],
      colGroup: 'Default',
    }))
    const productMetric = productMetricPermissions.map((permission) => ({
      key: permission,
      value: PERMISSIONS_TO_FIELDS_MAP[permission],
      colGroup: 'Product Metrics',
    }))
    const carbonFootprint = carbonFootprintPermissions.map((permission) => ({
      key: permission,
      value: PERMISSIONS_TO_FIELDS_MAP[permission],
      colGroup: 'Carbon Footprint',
    }))
    const scope3Emissions =
      isIinitiative || isVendor
        ? [
            {
              key: SCOPE_3_CATEGORY_1_FIELD,
              value: SCOPE_3_CATEGORY_1_FIELD as Field,
              colGroup: 'Scope 3 Emissions',
            },
            {
              key: SCOPE_3_CATEGORY_4_FIELD,
              value: SCOPE_3_CATEGORY_4_FIELD as Field,
              colGroup: 'Scope 3 Emissions',
            },
          ]
        : []

    return [...defaultItems, ...scope3Emissions, ...carbonFootprint, ...productMetric]
  }
)

export interface MetricOption extends Omit<CheckboxOption, 'value'> {
  units?: string
  value?: string
}
/**
 * returns the list of metric options that can be displayed in the metrics grid
 */
export const selectMetricGridMetricsOptions = createSelector<
  AppState,
  WorkspacePermission[],
  MetricEntry[],
  string[],
  MetricOption[]
>(
  selectWorkspaceListPermissions,
  selectContentfulMetricData,
  selectMetricGridColumns,
  (workspaceListPermissions, contentfulMetricData, selected) => {
    return contentfulMetricData.length
      ? workspaceListPermissions
          .map((field) => {
            const contentfulData = getContentfulEntry(field.value, contentfulMetricData)
            if (!contentfulData) {
              return null
            }
            const units = contentfulData.units ? ` (${contentfulData.units})` : ''
            const subTitle = contentfulData.subtitle
              ? ` ${contentfulData.subtitle.replace('Carbon Lifecycle ', '')}`
              : ''
            return {
              ...field,
              label: `${contentfulData.title}${subTitle}${units}`,
              checked: !!selected.find((selectedItem) => selectedItem === field.key),
              units: contentfulData.units,
            }
          })
          .filter((item) => item)
      : []
  }
)

/**
 * returns the combined list of non metric and metric column options for a metrics grid
 */
export const selectAllMetricGridOptions = createSelector<AppState, MetricOption[], string[], boolean, MetricOption[]>(
  selectMetricGridMetricsOptions,
  selectMetricGridColumns,
  selectIsInitiative,
  (metricColumns, selected, isInitiative) => {
    const nonMetricColumns = [
      { key: 'name', colGroup: 'Default', label: 'Workspace', checked: true, disabled: true },
      {
        key: 'workspace_type',
        colGroup: 'Default',
        label: 'Workspace Type',
        checked: !!selected.find((item) => item === 'workspace_type'),
      },
      {
        key: 'category',
        colGroup: 'Default',
        label: 'Benchmark Category',
        checked: !!selected.find((item) => item === 'category'),
      },
    ]

    const assigneeColumn = {
      key: 'assignee',
      colGroup: 'Default',
      label: 'Assignee',
      checked: !!selected.find((item) => item === 'assignee'),
    }

    return isInitiative
      ? [
          { key: 'name', colGroup: 'Default', label: 'Scenario', checked: true, disabled: true },
          {
            key: 'date',
            colGroup: 'Default',
            label: 'Target Date',
            checked: !!selected.find((item) => item === 'date'),
          },
          assigneeColumn,
          ...metricColumns,
        ]
      : [...nonMetricColumns, ...metricColumns, assigneeColumn]
  }
)
