import React, { forwardRef } from 'react'
import MuiFormControl, { FormControlProps as MuiFormControlProps } from '@mui/material/FormControl'

interface FormControlProps extends Omit<MuiFormControlProps, 'className'> {
  ['aria-label']?: string
  ['data-pendo-id']?: string
  ['data-testid']?: string
}

export const FormControl = forwardRef<HTMLDivElement, FormControlProps>(
  ({ ['aria-label']: ariaLabel, ['data-pendo-id']: dataPendoId, ['data-testid']: dataTestId, ...rest }, ref) => {
    return (
      <MuiFormControl ref={ref} aria-label={ariaLabel} data-pendo-id={dataPendoId} data-testid={dataTestId} {...rest} />
    )
  }
)

FormControl.displayName = 'FormControl'
