import React, { FC, useState, MouseEvent } from 'react'
import { useSelector } from 'react-redux'
import { faChevronRight, faXmark } from '@fortawesome/pro-regular-svg-icons'
import { ExternalLink, Icon, IconButton, Snackbar, Stack, Text, theme } from '@howgood/design'
import { selectLatestContentfulNewsData } from '@/state/contentfulData'
import { IndustryNewsDialog } from './IndustryNewsDialog'

const CLOSED_INDUSTRY_NEWS_KEY = 'closed-industry-news'

export const IndustryNews: FC = () => {
  const content = useSelector(selectLatestContentfulNewsData)
  const closedIndustryNews = localStorage.getItem(CLOSED_INDUSTRY_NEWS_KEY) === content?.key
  const [snackbarOpen, setSnackbarOpen] = useState(!closedIndustryNews)
  const [dialogOpen, setDialogOpen] = useState(false)

  function closeSnackbar(e: MouseEvent<HTMLElement>) {
    e.stopPropagation()
    setSnackbarOpen(false)
    localStorage.setItem(CLOSED_INDUSTRY_NEWS_KEY, content?.key)
  }

  if (!content) {
    return null
  }

  return (
    <>
      <IndustryNewsDialog open={dialogOpen} closeDialog={() => setDialogOpen(false)} closeSnackbar={closeSnackbar} />
      <Snackbar open={snackbarOpen} anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}>
        <Stack
          data-testid="industry-news"
          bgcolor="white"
          borderRadius={1}
          boxShadow={theme.shadows[2]}
          width={204}
          onClick={() => setDialogOpen(true)}
          sx={{ cursor: 'pointer' }}
        >
          <Stack flexDirection="column" bgcolor="secondary.paperMain" p={1} gap={1}>
            <Stack flexDirection="row" justifyContent="space-between" width="100%">
              <Text data-testid="industry-news-header" variant="subtitle2">
                Industry News
              </Text>
              <IconButton data-testid="industry-news-close" icon={faXmark} onClick={closeSnackbar} />
            </Stack>
            <Stack gap={1}>
              <img data-testid="industry-news-thumbnail" src={content.image.fields.file.url as string} width="100%" />
              <Text data-testid="industry-news-title" variant="body2">
                {content.title}
              </Text>
            </Stack>
            <ExternalLink data-testid="industry-news-link" onClick={() => setDialogOpen(true)}>
              <Stack flexDirection="row" justifyContent="space-between" alignItems="center" width="100%">
                <Text color="inherit" variant="body2">
                  Learn more &amp; take action
                </Text>
                <Icon icon={faChevronRight} size="xs" />
              </Stack>
            </ExternalLink>
          </Stack>
        </Stack>
      </Snackbar>
    </>
  )
}
