import React from 'react'
import throttle from 'lodash/throttle'

export const useWidth = (debounceTime = 250, id: string) => {
  const [size, setSize] = React.useState(0)

  React.useLayoutEffect(() => {
    const gridWrapper = document.getElementById(id)

    const updateSize = throttle(() => {
      setSize(gridWrapper.clientWidth)
    }, debounceTime)

    window.addEventListener('resize', updateSize)

    updateSize()

    return () => window.removeEventListener('resize', updateSize)
  }, [debounceTime, id])

  return size
}
