import React, { FC } from 'react'
import { Text, Button, Dialog, DialogTitle, DialogActions, DialogContent } from '@howgood/design'

interface PanelProps {
  isOpen: boolean
  closeDialog: () => void
  createReport: () => void
}

export const CreateReportConfirmationDialog: FC<PanelProps> = ({ isOpen, closeDialog, createReport }) => {
  const handleCreateReportClicked = () => {
    createReport()
  }

  return (
    <Dialog data-testid="create-report-confirmation-dialog" open={isOpen} onClose={closeDialog}>
      <DialogTitle>
        <Text variant="h3">Overwrite previous report with a new one?</Text>
      </DialogTitle>
      <DialogContent>
        <Text>This will create a new report and you&apos;ll no longer be able to access the previous one.</Text>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={() => closeDialog()} variant="contained">
          Cancel
        </Button>
        <Button color="error" onClick={handleCreateReportClicked} variant="contained">
          Create new report
        </Button>
      </DialogActions>
    </Dialog>
  )
}
