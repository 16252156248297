import React, { FC, forwardRef } from 'react'
import MuiAlert, { AlertProps as MuiAlertProps } from '@mui/material/Alert'
import MuiAlertTitle, { AlertTitleProps as MuiAlertTitleProps } from '@mui/material/AlertTitle'
import {
  faCircleCheck,
  faOctagonExclamation,
  faCircleInfo,
  faTriangleExclamation,
} from '@fortawesome/pro-regular-svg-icons'
import { Icon } from './Icon'

interface AlertTitleProps extends Pick<MuiAlertTitleProps, 'children'> {}

export const AlertTitle: FC<AlertTitleProps> = ({ children }) => {
  return <MuiAlertTitle color="inherit">{children}</MuiAlertTitle>
}

export interface AlertProps extends Pick<MuiAlertProps, 'severity' | 'children' | 'iconMapping' | 'action'> {
  ['data-testid']?: string
}

const defaultIcons = {
  success: <Icon color="success.dark" size="sm" icon={faCircleCheck} />,
  info: <Icon color="info.light" size="sm" icon={faCircleInfo} />,
  warning: <Icon color="warning.main" size="sm" icon={faTriangleExclamation} />,
  error: <Icon color="error.main" size="sm" icon={faOctagonExclamation} />,
}

export const Alert = forwardRef<HTMLDivElement, AlertProps>(
  ({ ['data-testid']: dataTestId, severity, action, children, iconMapping, ...rest }, ref) => {
    return (
      <MuiAlert
        ref={ref}
        data-testid={dataTestId}
        action={action}
        severity={severity}
        iconMapping={{ ...defaultIcons, ...iconMapping }}
        {...rest}
      >
        {children}
      </MuiAlert>
    )
  }
)

Alert.displayName = 'Alert'
