import React, { FC } from 'react'
import { Document } from '@contentful/rich-text-types'
import { Accordion, AccordionDetails } from '@howgood/design'
import { AccordionCardHeader } from '@/components/Scores/AccordionCardHeader'
import { RichText } from '@/contentfully'

interface Props {
  ingredientSubstitution: Document
}

export const IngredientSubstitution: FC<Props> = ({ ingredientSubstitution }) => {
  if (!ingredientSubstitution) {
    return null
  }

  return (
    <Accordion data-testid="ingredient-substitution" defaultExpanded>
      <AccordionCardHeader title="Improvements: Ingredient substitution" />
      <AccordionDetails>
        <RichText document={ingredientSubstitution} />
      </AccordionDetails>
    </Accordion>
  )
}
