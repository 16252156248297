import React, { FC, useMemo } from 'react'
import { useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import { GridRowsProp, GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium'
import { Box, Text } from '@howgood/design'

import { usePendoTrack } from '@/hooks/usePendoTrack'
import { selectClaimsReports, selectLockedProductInfo } from '@/state/productOverview'
import { formatDateString, MEDIUM, parseReportDateKey } from '@/utils/dates'
import { HistoryTable } from './HistoryTable'
import { selectIsSupplierConnect } from '@/state/organization'
import { DownloadLink } from '@/components/Shared/DownloadLink'

interface ReportCellProps extends GridRenderCellParams {
  value: {
    dateTime: string
    reportUrl: string
  }
  downloadItem: boolean
}

const ReportCell: FC<ReportCellProps> = ({ value, downloadItem = false }) => {
  const pendoTrack = usePendoTrack()
  const product = useSelector(selectLockedProductInfo)
  const { dateTime, reportUrl } = value
  const date = formatDateString({ dateString: dateTime, type: MEDIUM, withTime: true })

  const handleDownload = () => {
    pendoTrack('Formula: Export Product Report', { reportDate: dateTime })
  }

  return (
    <Box key={downloadItem ? `download-${dateTime}` : dateTime}>
      <DownloadLink url={reportUrl} filename={`${product.name} report (${date}).pdf`} onDownload={handleDownload}>
        <Text variant="body2">{downloadItem ? 'Download' : date}</Text>
      </DownloadLink>
    </Box>
  )
}

const columns: GridColDef[] = [
  {
    field: 'created',
    headerName: 'Report Date',
    width: 500,
    renderCell: (params: ReportCellProps) => <ReportCell {...params} />,
    sortComparator: (a, b) => a.dateTime - b.dateTime,
  },
  {
    field: 'download',
    headerName: '',
    width: 80,
    renderCell: (params: ReportCellProps) => <ReportCell downloadItem {...params} />,
    sortable: false,
  },
]

export const ReportHistory: FC = () => {
  const claimsReports = useSelector(selectClaimsReports)
  const isSupplierConnect = useSelector(selectIsSupplierConnect)

  const rows: GridRowsProp = useMemo(() => {
    return isEmpty(claimsReports.items)
      ? []
      : claimsReports.items.map((item, i) => {
          const dateTime = parseReportDateKey(item.date)
          return {
            id: i,
            created: { dateTime, reportUrl: item.url },
            download: { dateTime, reportUrl: item.url },
          }
        })
  }, [claimsReports])

  // Don't let SupplierConnect users see the report history because it provides access to the full reports
  // SupplierConnect users can use the Download button to generate an "on-the-fly" report using the latest locked data
  if (isSupplierConnect || isEmpty(claimsReports)) {
    return null
  }

  return (
    <HistoryTable
      defaultExpanded
      rows={rows}
      columns={columns}
      uniqueId="report"
      titleText="Report history"
      sortModel={[{ field: 'created', sort: 'desc' }]}
    />
  )
}
