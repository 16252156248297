import React, { FC, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Text,
  TextField,
  Stack,
  Alert,
  AlertTitle,
  ToggleGroup,
  ToggleGroupOptions,
} from '@howgood/design'
import { WorkspaceFilter } from '@/components/Shared/Filters/WorkspaceFilter'
import { NewScenarioFields } from '@/features/Initiative/CreateScenario/NewScenarioFields'
import {
  InitiativeGroupByMethod,
  NewInitiative,
  selectCreateNewInitiativeGroupByMethod,
  setCreateNewInitiativeGroupByMethod,
  setNewInitiative,
} from '@/state/initiatives'
import {
  selectVendorFilters,
  selectWorkspaceProductFilters,
  selectWorkspacesOptionsForFilter,
  updateProductFilters,
} from '@/state/products'
import { VendorFilter } from './VendorFilter'

interface CreateInitiativeDialogProps {
  startFetchingData: () => void
  closeDialog: () => void
}

const baselineYear = new Date().getFullYear()
const yearFieldWidth = 190

export const CreateInitiativeDialog: FC<CreateInitiativeDialogProps> = ({ startFetchingData, closeDialog }) => {
  const dispatch = useDispatch()
  const workspaces = useSelector(selectWorkspacesOptionsForFilter)
  const selectedWorkspaces = useSelector(selectWorkspaceProductFilters)
  const selectedVendors = useSelector(selectVendorFilters)

  const groupByMethod = useSelector(selectCreateNewInitiativeGroupByMethod)

  const [initiativeName, setInitiativeName] = useState('')
  const [scenarioName, setScenarioName] = useState('')
  const [targetYear, setTargetYear] = useState(2030)

  const isGroupByWorkspaces = groupByMethod === 'workspaces'

  const toggleOptions: ToggleGroupOptions = useMemo(
    () => [
      {
        id: 'workspaces',
        label: 'Workspaces',
        active: isGroupByWorkspaces,
      },
      {
        id: 'vendors',
        label: 'Vendors',
        active: !isGroupByWorkspaces,
      },
    ],
    [isGroupByWorkspaces]
  )

  const handleClose = () => {
    dispatch(updateProductFilters({ workspaces: [], vendors: [] }))
    closeDialog()
  }

  const handleOKClicked = () => {
    const workspaceIds = selectedWorkspaces.map((w) => w.id)
    const vendorIds = selectedVendors.map((w) => w.value as number)
    const newInitiative: NewInitiative = {
      initiativeName,
      scenarioName,
      baselineYear,
      targetYear,
    }
    if (isGroupByWorkspaces) {
      newInitiative.workspaceIds = workspaceIds
    } else {
      newInitiative.vendorIds = vendorIds
    }
    // because of we may have selected workspaces and then choose group by vendor we should reset workspaces filter to not include those in selectProductsRuntimeMappings
    if (!isGroupByWorkspaces) {
      dispatch(updateProductFilters({ workspaces: [] }))
    } else {
      dispatch(updateProductFilters({ vendors: [] }))
    }

    dispatch(setNewInitiative(newInitiative))
    startFetchingData()
    closeDialog()
  }

  const groupByEmptyText = `Add one or more ${
    isGroupByWorkspaces ? 'workspaces' : 'vendors'
  } to determine which materials will be included in this initiative.`

  return (
    <>
      <Dialog maxWidth="xs" id="create-initiative-dialog" open onClose={handleClose}>
        <DialogTitle>Create a new initiative</DialogTitle>
        <DialogContent>
          <Stack gap={2}>
            <Text>
              Formulas can be grouped together for a new initiative by either their workspace or vendor. Which method of
              selection would you like to use?
            </Text>
            <ToggleGroup
              options={toggleOptions}
              size="medium"
              onChange={(groupBy: InitiativeGroupByMethod) => {
                dispatch(setCreateNewInitiativeGroupByMethod(groupBy))
              }}
              stretchChildren
            />
            <Text>
              {(isGroupByWorkspaces && selectedWorkspaces.length) || (!isGroupByWorkspaces && selectedVendors.length)
                ? `This will create a new initiative for the ${
                    (isGroupByWorkspaces ? selectedWorkspaces : selectedVendors).length
                  } selected ${
                    (isGroupByWorkspaces ? selectedWorkspaces : selectedVendors).length > 1
                      ? `${isGroupByWorkspaces ? 'workspaces' : 'vendors'}`
                      : `${isGroupByWorkspaces ? 'workspace' : 'vendor'}`
                  }.`
                : groupByEmptyText}
            </Text>
            {isGroupByWorkspaces ? (
              <WorkspaceFilter
                id="workspace-filter"
                options={workspaces}
                value={selectedWorkspaces}
                handleChange={(options) => dispatch(updateProductFilters({ workspaces: options }))}
                autoFocus
                fullWidth
                placeholder="Start typing a workspace name..."
              />
            ) : (
              <VendorFilter />
            )}
            <Stack direction="row" gap={2}>
              <TextField
                fullWidth
                id="initiative-name"
                label="Initiative name"
                value={initiativeName}
                onChange={(e) => setInitiativeName(e.target.value)}
              />
              <Stack width={yearFieldWidth}>
                <TextField disabled id="baseline-year" label="Baseline year" value={baselineYear} />
              </Stack>
            </Stack>
            <NewScenarioFields
              scenarioName={scenarioName}
              setScenarioName={setScenarioName}
              targetYear={targetYear}
              setTargetYear={setTargetYear}
            />
            <Alert severity="info">
              <AlertTitle>Initiatives use live data</AlertTitle>
              <Text>
                The baseline and initial scenario work with current metrics for selected formulas, ignoring product
                reports.
              </Text>
            </Alert>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Stack width="100%" direction="row" justifyContent="space-between">
            <Button id="create-initiative-cancel-button" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              id="create-initiative-ok-button"
              disabled={
                !initiativeName.trim() ||
                !scenarioName.trim() ||
                (isGroupByWorkspaces && !selectedWorkspaces.length) ||
                (!isGroupByWorkspaces && !selectedVendors.length)
              }
              color="primary"
              onClick={handleOKClicked}
            >
              Create initiative
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  )
}
