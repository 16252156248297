import React, { ChangeEvent, FC } from 'react'
import { useSelector } from 'react-redux'
import { FormControl, InputLabel, MenuItem, Select, Stack, TextField } from '@howgood/design'
import { selectCarbonAccountingYears } from '@/state/carbonAccounting'

interface Props {
  name: string
  year: string
  yearDisabled?: boolean
  onChangeName: (e: ChangeEvent<HTMLInputElement>) => void
  onChangeYear?: (e: ChangeEvent<HTMLInputElement>) => void
}

export const ReportFormFields: FC<Props> = ({ name, year, yearDisabled = false, onChangeName, onChangeYear }) => {
  const availableYears = useSelector(selectCarbonAccountingYears)

  return (
    <FormControl fullWidth>
      <Stack flexDirection="row" gap={1}>
        <Stack width={150}>
          <InputLabel id="year-select-label" size="small" disabled={yearDisabled}>
            Year
          </InputLabel>
          <Select
            data-testid="year-select"
            label="Year"
            labelId="year-select-label"
            value={year}
            fullWidth
            onChange={onChangeYear}
            disabled={yearDisabled}
          >
            {availableYears.map((y) => (
              <MenuItem key={y} data-testid="year-select-option" value={y}>
                {y}
              </MenuItem>
            ))}
          </Select>
        </Stack>
        <TextField
          label="Name"
          value={name}
          fullWidth
          inputProps={{ ['data-testid']: 'name-input' }}
          onChange={onChangeName}
        />
      </Stack>
    </FormControl>
  )
}
