import React, { FC, MouseEvent } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { NodeRenderer } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import { Button, Dialog, DialogActions, DialogContent, ExternalLink, Stack, Text } from '@howgood/design'
import { selectLatestContentfulNewsData } from '@/state/contentfulData'
import { RichText } from '@/contentfully'

interface Props {
  open: boolean
  closeDialog: () => void
  closeSnackbar: (e: MouseEvent<HTMLElement>) => void
}

const ParagraphRenderer: NodeRenderer = (_node, children) => <Text>{children}</Text>

export const IndustryNewsDialog: FC<Props> = ({ open, closeDialog, closeSnackbar }) => {
  const content = useSelector(selectLatestContentfulNewsData)

  function closeDialogAndSnackbar(e: MouseEvent<HTMLElement>) {
    closeDialog()
    closeSnackbar(e)
  }

  if (!content) {
    return null
  }

  return (
    <Dialog data-testid="industry-news-dialog" open={open} onClose={closeDialog}>
      <DialogContent>
        <Stack gap={2}>
          <img data-testid="industry-news-image" src={content.image.fields.file.url as string} width="100%" />
          <Text data-testid="industry-news-title" variant="h3">
            {content.title}
          </Text>
          <Stack data-testid="industry-news-article">
            <RichText
              document={content.article}
              rendererOptions={{ renderNode: { [BLOCKS.PARAGRAPH]: ParagraphRenderer } }}
            />
          </Stack>
          <ExternalLink data-testid="industry-news-source" href={content.source} target="_blank">
            Read the article
          </ExternalLink>
          <Text data-testid="industry-news-action-text">
            See how your portfolio is impacted by clicking “Take action” below.
          </Text>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack flexDirection="row" gap={1}>
          <Button data-testid="industry-news-dismiss" onClick={closeDialogAndSnackbar}>
            Dismiss
          </Button>
          <Link data-testid="industry-news-link" to={content.actionUrl}>
            <Button data-testid="industry-news-take-action" color="primary" onClick={closeDialog}>
              Take action
            </Button>
          </Link>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}
