import { fetchElasticV2 } from '@/api'
import * as Sentry from '@sentry/react'
import { FORMULATIONS_SEARCH_PATH } from '@/constants/config'
import { IBrandSearchOption } from '@/records'
import { estypes } from '@elastic/elasticsearch'

interface FetchBrandsParams {
  search: string
  workspaceIds: number[]
  cancelPrevious?: boolean
}

// These can be really slow requests, no need to keep sending requests when
// a user makes a change and the initial request is no longer valid
let abortController = new AbortController()
export const fetchBrandsByQuery = async ({
  search,
  workspaceIds,
  cancelPrevious = false,
}: FetchBrandsParams): Promise<IBrandSearchOption[]> => {
  if (cancelPrevious) {
    abortController.abort() // Cancel the previous request
    abortController = new AbortController()
  }
  let hits: IBrandSearchOption[]
  const value = search.toLowerCase()

  try {
    const results = await fetchElasticV2({
      url: FORMULATIONS_SEARCH_PATH,
      body: {
        size: 0,
        _source: ['id', 'name'],
        query: {
          bool: {
            should: [
              value.includes(' ')
                ? { match_phrase: { 'brand.name': { query: value, boost: 10 } } }
                : { wildcard: { 'brand.name': { value: `*${value}*`, boost: 10 } } },
              { fuzzy: { 'brand.name': { value, boost: 1, fuzziness: 'AUTO' } } },
            ],
            filter: [
              {
                terms: {
                  'workspaces.id': workspaceIds,
                },
              },
            ],
          },
        },
        aggs: {
          vendors: {
            terms: {
              size: 20,
              field: 'brand.name.keyword',
              order: {
                maxScore: 'desc',
              },
            },
            aggs: {
              maxScore: {
                max: {
                  script: '_score',
                },
              },
              doc: {
                top_hits: {
                  size: 1,
                  _source: ['brand.id'],
                },
              },
            },
          },
        },
      },
      signal: cancelPrevious ? abortController.signal : undefined,
    })
    const vendors = results?.aggregations?.vendors as estypes.AggregationsMultiBucketAggregateBase<any>

    hits =
      vendors?.buckets
        // API always returns 20 items, even if 19 have a `matchScore` of 0, so filter these out
        .filter((bucket) => bucket.maxScore.value > 0)
        .map((item: any) => ({
          value: item?.doc?.hits?.hits?.[0]?._source.brand?.id,
          label: item.key,
        })) ?? []
  } catch (e) {
    console.error(e)
  }

  return hits
}

export const fetchBrandsByIds = async (ids: number[]): Promise<IBrandSearchOption[]> => {
  let hits: IBrandSearchOption[] = []

  try {
    const results = await fetchElasticV2({
      url: FORMULATIONS_SEARCH_PATH,
      body: {
        size: 0,
        _source: ['id', 'name'],
        query: {
          bool: {
            should: [
              {
                bool: {
                  must: {
                    terms: {
                      'brand.id': ids,
                    },
                  },
                },
              },
            ],
            minimum_should_match: 1,
          },
        },
        aggs: {
          vendors: {
            terms: {
              size: 1000,
              field: 'brand.name.keyword',
            },
            aggs: {
              maxScore: {
                max: {
                  script: '_score',
                },
              },
              doc: {
                top_hits: {
                  size: 1,
                  _source: ['brand.id'],
                },
              },
            },
          },
        },
      },
    })
    const vendors = results?.aggregations?.vendors as estypes.AggregationsMultiBucketAggregateBase<any>

    hits =
      vendors?.buckets
        .filter((bucket) => bucket.maxScore.value > 0)
        .map((item: any) => ({
          value: item?.doc?.hits?.hits?.[0]?._source.brand?.id,
          label: item.key,
        })) ?? []
  } catch (e) {
    Sentry.captureException(e)
  }

  return hits
}
