import { createSlice } from '@reduxjs/toolkit'
import { getManufacturingTypes } from './manufacturingTypes.thunk'

export interface ManufacturingType {
  id: number
  title: string
  value: number
}

export interface ManufacturingTypeOption {
  id: number
  label: string
  value: number
}

export interface State {
  isLoading: boolean
  manufacturingTypes: ManufacturingType[]
}
export const initialState: State = {
  isLoading: false,
  manufacturingTypes: [],
}

const manufacturingTypesSlice = createSlice({
  name: 'manufacturingTypes',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getManufacturingTypes.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getManufacturingTypes.fulfilled, (state, action) => {
      state.isLoading = false
      state.manufacturingTypes = action.payload
    })
    builder.addCase(getManufacturingTypes.rejected, (state) => {
      state.isLoading = false
      state.manufacturingTypes = initialState.manufacturingTypes
    })
  },
})

export const manufacturingTypesReducer = manufacturingTypesSlice.reducer
