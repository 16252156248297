import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectSalesCategories } from '@/state/salesCategories'
import {
  ALL_FOOD_INDUSTRY_OPTION,
  ALL_FUNCTIONAL_CATEGORY_OPTION,
  FunctionalCategoryOption,
  NO_CATEGORY_OPTION,
  SalesCategoryOption,
} from '@/constants/industryCategories'
import { selectFunctionalCategoryOptions } from '@/state/functionalCategories'

export const useSalesCategoryOptions = (): SalesCategoryOption[] => {
  const salesCategories = useSelector(selectSalesCategories)

  return useMemo(
    () => [
      ALL_FOOD_INDUSTRY_OPTION,
      NO_CATEGORY_OPTION as SalesCategoryOption,
      ...salesCategories
        .map((cat) => ({
          value: cat.title,
          label: cat.title,
        }))
        .sort((a: SalesCategoryOption, b: SalesCategoryOption) =>
          a.label.toLowerCase() > b.label.toLowerCase() ? 1 : a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 0
        ),
    ],
    [salesCategories]
  )
}

export const useFunctionalCategoryOptions = (): FunctionalCategoryOption[] => {
  const functionalCategories = useSelector(selectFunctionalCategoryOptions)

  return useMemo(
    () => [
      ALL_FUNCTIONAL_CATEGORY_OPTION,
      NO_CATEGORY_OPTION as FunctionalCategoryOption,
      ...functionalCategories.sort((a: FunctionalCategoryOption, b: FunctionalCategoryOption) =>
        a.label.toLowerCase() > b.label.toLowerCase() ? 1 : a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 0
      ),
    ],
    [functionalCategories]
  )
}
