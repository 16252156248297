import React, { FC, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@howgood/design'
import {
  getSelectedMetricData,
  selectAvailableMetrics,
  selectSelectedMetric,
  setSelectedMetric,
} from '../state/portfolioDashboard'
import { useUnsortedContentfulMetrics } from '@/contentfully/hooks'
import { Field } from '@/constants/impactScore'
import { selectContentfulCustomMetricsData } from '@/state/contentfulData'

interface MetricMenuItem {
  key: Field
  menuText: string
}

export const MetricSelect: FC = () => {
  const standardMetrics = useSelector(selectAvailableMetrics)
  const customMetrics = useSelector(selectContentfulCustomMetricsData)
  const selectedMetric = useSelector(selectSelectedMetric)
  const dispatch = useDispatch()

  const metricEntries = useUnsortedContentfulMetrics(standardMetrics)

  // Construct standard metric menu items and sort alphabetically
  const standardMenuItems: MetricMenuItem[] = useMemo(
    () =>
      metricEntries
        .map((entry) => {
          return {
            key: entry.key,
            menuText: entry.subtitle ? `${entry.title} (${entry.subtitle})` : entry.title,
          }
        })
        .sort((a, b) => a.menuText.localeCompare(b.menuText)),
    [metricEntries]
  )

  // Construct standard metric menu items and sort alphabetically
  const customMenuItems: MetricMenuItem[] = useMemo(
    () =>
      customMetrics
        .map((customMetric) => ({
          key: customMetric.key as Field,
          menuText: customMetric.subtitle ? `${customMetric.title} (${customMetric.subtitle})` : customMetric.title,
        }))
        .sort((a, b) => a.menuText.localeCompare(b.menuText)),
    [customMetrics]
  )

  const selectedMenuItem =
    customMenuItems.find((item) => item.key === selectedMetric) ||
    standardMenuItems.find((item) => item.key === selectedMetric)

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const metric = event.target.value as MetricMenuItem
    dispatch(setSelectedMetric(metric.key))
    dispatch(getSelectedMetricData(metric.key))
  }

  // removing this for now, but leaving in code since I think it might come back soon
  // const hasBothMenuItems = customMenuItems.length > 0 && standardMenuItems.length > 0

  return (
    <FormControl>
      <InputLabel id="metric-select-label">Metric</InputLabel>
      <Select
        labelId="metric-select-label"
        id="metric-select"
        value={selectedMenuItem || ''}
        label="Metric"
        onChange={handleChange}
      >
        {/* {hasBothMenuItems && (
          <MenuItem disabled sx={{ '&.MuiMenuItem-root.Mui-disabled': { opacity: 1, fontWeight: 600 } }}>
            Custom Metrics
          </MenuItem>
        )} */}
        {customMenuItems.map((customMetric) => (
          <MenuItem key={customMetric.key} value={customMetric as any}>
            {customMetric.menuText}
          </MenuItem>
        ))}
        {/* {hasBothMenuItems && <Divider />}
        {hasBothMenuItems && (
          <MenuItem disabled sx={{ '&.MuiMenuItem-root.Mui-disabled': { opacity: 1, fontWeight: 600 } }}>
            Standard Metrics
          </MenuItem>
        )} */}
        {standardMenuItems.map((item) => (
          <MenuItem key={item.key} value={item as any}>
            {item.menuText}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
