import React, { FC, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NumericInput, Stack, Text } from '@howgood/design'
import {
  selectDisplayedProductScenario,
  selectDisplayedProductScenarios,
  updateDraftProduct,
  generateUpdatedScenarioProducts,
  selectTotalInventoryMTPerYr,
} from '@/state/productOverview'
import { DRAFT, selectPageView } from '@/state/pageSettings'
import { selectCurrentScenario } from '@/state/initiatives'
import { getScenarioYearAsNumber } from '@/state/initiatives/initiatives.utils'
import { addMessage } from '@/state/messages'
import { formatNumber } from '@howgood/utils'

export const ScenarioDetails: FC = () => {
  const dispatch = useDispatch()
  const view = useSelector(selectPageView)
  const allProductScenarios = useSelector(selectDisplayedProductScenarios)
  const productScenario = useSelector(selectDisplayedProductScenario)
  const scenario = useSelector(selectCurrentScenario)
  const inventoryMtPerYr = useSelector(selectTotalInventoryMTPerYr)
  const mtPerYr = useMemo(() => {
    return productScenario?.mt_per_year || undefined
  }, [productScenario])

  const onUpdateMTperYr = (updatedMtPerYr: number) => {
    if (mtPerYr === updatedMtPerYr) {
      return
    }
    dispatch(
      updateDraftProduct({
        scenario_products: generateUpdatedScenarioProducts(allProductScenarios, scenario.id, {
          ...productScenario,
          mt_per_year: updatedMtPerYr,
        }),
      })
    )
  }

  const mtPerYearDisplayValue = useMemo(() => (mtPerYr === null ? inventoryMtPerYr : mtPerYr) || undefined, [
    mtPerYr,
    inventoryMtPerYr,
  ])

  return (
    <Stack gap={2} data-testid="scenario-tab-panel" p={2}>
      <Stack gap={1} direction="row">
        <Text variant="h4">Name:</Text>
        <Text variant="body1">{scenario.name}</Text>
      </Stack>
      <Stack gap={1} direction="row">
        <Text variant="h4">Target Date:</Text>
        <Text variant="body1">{getScenarioYearAsNumber(scenario.target_date)}</Text>
      </Stack>
      <Stack gap={1} direction="row">
        <Text variant="h4">Metric tons per year ordered:</Text>
        {view === DRAFT ? (
          <NumericInput
            id={'MTperYr-field'}
            ariaLabel={'MT/yr'}
            numericValue={mtPerYearDisplayValue}
            onUpdateValue={onUpdateMTperYr}
            onBadValue={(badVal) => {
              dispatch(addMessage({ severity: 'error', message: `${badVal} is not a valid numeric value.` }))
            }}
            size="small"
          />
        ) : (
          <Text variant="body1" data-testid="MTperYr-display">
            {mtPerYearDisplayValue ? formatNumber(mtPerYearDisplayValue) : 'Not Supplied'}
          </Text>
        )}
      </Stack>
    </Stack>
  )
}
