import {
  HAS_ASC_STANDARDS,
  HAS_CAGE_FREE_STANDARD,
  HAS_CERTIFIED_HUMANE_STANDARD,
  HAS_GAP1_STANDARDS,
  HAS_GAP2_STANDARDS,
  HAS_GAP3_STANDARDS,
  HAS_GAP4_STANDARDS,
  HAS_GAP5_PLUS_STANDARDS,
  HAS_LAND_TO_MARKET_STANDARDS,
  HAS_MSC_STANDARDS,
  HAS_NO_ANTIBIOTICS_EVER_STANDARD,
  HAS_NON_GMO_STANDARDS,
  HAS_PEFC_SFI_FSC_STANDARDS,
  HAS_RAINFOREST_ALLIANCE_STANDARDS,
  HAS_RAIZ_STANDARD,
  HAS_RSPO_STANDARDS,
  HAS_RTRS_PROTERRA_CRS_STANDARDS,
  HAS_UTZ_STANDARDS,
  LABOR_STANDARDS,
  ORIGIN_CATEGORY_IS_ANIMAL_STANDARDS,
  RESP_GROWING_STANDARDS,
  STANDARD_OPTIONS,
  Ingredient,
  IOriginLocation,
  IProductStandard,
  StandardOption,
} from '@/records'

const isNonRelevantName = (name: string) => ['russian doll', 'not relevant'].includes(name?.toLowerCase())

export const normalizeIngredientName = (name: string) => {
  if (!isNonRelevantName(name)) {
    return name
  }
  return name.toLowerCase() === 'russian doll' ? 'Aggregate Ingredient' : 'Non-Relevant Ingredient Text'
}

function hasAsc(ingredient: Ingredient) {
  return ingredient.origin?.category_is_seafood
}

function hasMsc(ingredient: Ingredient) {
  return ingredient.origin?.category_is_seafood
}

function hasRainforestAlliance(ingredient: Ingredient) {
  return ingredient.origin?.category_is_plant
}

function hasRaiz(ingredient: Ingredient) {
  return ingredient.origin?.category_is_plant
}

function hasRspo(ingredient: Ingredient) {
  return ingredient.origin?.id && [310].includes(ingredient.origin?.id)
}

function hasPefcSfiFsc(ingredient: Ingredient) {
  return ingredient.origin?.id && [392, 206, 179].includes(ingredient.origin?.id) && ingredient.origin_location?.luc
}

function hasRtrsProterraCrs(ingredient: Ingredient) {
  return ingredient.origin?.id && [394].includes(ingredient.origin?.id) && ingredient.origin_location?.luc
}

function hasGap1(ingredient: Ingredient) {
  return ingredient.origin?.id && [40, 45, 58, 185, 244, 332, 426].includes(ingredient.origin?.id)
}

function hasGap2(ingredient: Ingredient) {
  return ingredient.origin?.id && [40, 58, 244, 332, 426].includes(ingredient.origin?.id)
}

function hasGap3(ingredient: Ingredient) {
  return ingredient.origin?.id && [58, 244, 332, 426].includes(ingredient.origin?.id)
}

function hasGap4(ingredient: Ingredient) {
  return ingredient.origin?.id && [40, 185, 241, 45, 58, 244, 332, 426].includes(ingredient.origin?.id)
}

function hasGap5Plus(ingredient: Ingredient) {
  return ingredient.origin?.id && [40, 185, 241, 45, 58, 244, 332, 426].includes(ingredient.origin?.id)
}

function hasCertifiedHumane(ingredient: Ingredient) {
  return ingredient.origin?.id && [185, 45, 40, 58, 332, 244, 426].includes(ingredient.origin?.id)
}

function hasNoAntibioticsEver(ingredient: Ingredient) {
  return ingredient.origin?.id && [40, 185, 244, 58, 332, 426].includes(ingredient.origin?.id)
}

function hasCageFree(ingredient: Ingredient) {
  return ingredient.origin?.id && [244].includes(ingredient.origin?.id)
}

function hasLandToMarket(ingredient: Ingredient) {
  // These ids come from the ticket:
  // https://github.com/howgood/pm/issues/941
  return ingredient.origin?.id && [40, 45, 132, 241].includes(ingredient.origin?.id)
}

function hasUtz(ingredient: Ingredient) {
  // These ids come from the ticket:
  // https://github.com/howgood/pm/issues/895
  return (
    ingredient.origin?.id &&
    [65, 208, 116, 569, 548, 567, 721, 568, 324, 566, 412, 576, 442].includes(ingredient.origin?.id)
  )
}
function hasNongmo(ingredient: Ingredient) {
  // These ids come from the ticket:
  // https://github.com/howgood/pm/issues/980
  return ingredient.origin?.id && [2, 9, 42, 123, 132, 359, 394].includes(ingredient.origin?.id)
}

function getOriginLocation(ingredient: Ingredient): IOriginLocation {
  return (
    (ingredient.ingredient_location &&
      ingredient.origin_locations.find((ol) => ol?.id === ingredient.ingredient_location.id)) ||
    ingredient.origin_locations.find((ol) => ol?.id === ingredient.default_origin_location.id) ||
    ingredient.default_origin_location
  )
}

export const getIngredientStandardsOptions = (
  ingredient: Ingredient,
  availableStandards: IProductStandard[]
): StandardOption[] => {
  let ingredientOptions = STANDARD_OPTIONS.filter((opt) => availableStandards.some((st) => st.id === opt.value))
  if (!ingredient.impacts_resp_growing) {
    ingredientOptions = ingredientOptions.filter((option) => !RESP_GROWING_STANDARDS.includes(option.value))
  }
  if (!getOriginLocation(ingredient)?.labor) {
    ingredientOptions = ingredientOptions.filter((option) => !LABOR_STANDARDS.includes(option.value))
  }
  if (!hasLandToMarket(ingredient)) {
    ingredientOptions = ingredientOptions.filter((option) => !HAS_LAND_TO_MARKET_STANDARDS.includes(option.value))
  }
  if (!hasUtz(ingredient)) {
    ingredientOptions = ingredientOptions.filter((option) => !HAS_UTZ_STANDARDS.includes(option.value))
  }
  if (!ingredient.origin?.category_is_animal) {
    ingredientOptions = ingredientOptions.filter(
      (option) => !ORIGIN_CATEGORY_IS_ANIMAL_STANDARDS.includes(option.value)
    )
  }
  if (!hasNongmo(ingredient)) {
    ingredientOptions = ingredientOptions.filter((option) => !HAS_NON_GMO_STANDARDS.includes(option.value))
  }
  if (!hasRspo(ingredient)) {
    ingredientOptions = ingredientOptions.filter((option) => !HAS_RSPO_STANDARDS.includes(option.value))
  }
  if (!hasPefcSfiFsc(ingredient)) {
    ingredientOptions = ingredientOptions.filter((option) => !HAS_PEFC_SFI_FSC_STANDARDS.includes(option.value))
  }
  if (!hasRtrsProterraCrs(ingredient)) {
    ingredientOptions = ingredientOptions.filter((option) => !HAS_RTRS_PROTERRA_CRS_STANDARDS.includes(option.value))
  }

  if (!hasGap1(ingredient)) {
    ingredientOptions = ingredientOptions.filter((option) => !HAS_GAP1_STANDARDS.includes(option.value))
  }
  if (!hasGap2(ingredient)) {
    ingredientOptions = ingredientOptions.filter((option) => !HAS_GAP2_STANDARDS.includes(option.value))
  }
  if (!hasGap3(ingredient)) {
    ingredientOptions = ingredientOptions.filter((option) => !HAS_GAP3_STANDARDS.includes(option.value))
  }
  if (!hasGap4(ingredient)) {
    ingredientOptions = ingredientOptions.filter((option) => !HAS_GAP4_STANDARDS.includes(option.value))
  }
  if (!hasGap5Plus(ingredient)) {
    ingredientOptions = ingredientOptions.filter((option) => !HAS_GAP5_PLUS_STANDARDS.includes(option.value))
  }
  if (!hasRainforestAlliance(ingredient)) {
    ingredientOptions = ingredientOptions.filter((option) => !HAS_RAINFOREST_ALLIANCE_STANDARDS.includes(option.value))
  }
  if (!hasRaiz(ingredient)) {
    ingredientOptions = ingredientOptions.filter((option) => !HAS_RAIZ_STANDARD.includes(option.value))
  }
  if (!hasAsc(ingredient)) {
    ingredientOptions = ingredientOptions.filter((option) => !HAS_ASC_STANDARDS.includes(option.value))
  }
  if (!hasMsc(ingredient)) {
    ingredientOptions = ingredientOptions.filter((option) => !HAS_MSC_STANDARDS.includes(option.value))
  }
  if (!hasCertifiedHumane(ingredient)) {
    ingredientOptions = ingredientOptions.filter((option) => !HAS_CERTIFIED_HUMANE_STANDARD.includes(option.value))
  }
  if (!hasNoAntibioticsEver(ingredient)) {
    ingredientOptions = ingredientOptions.filter((option) => !HAS_NO_ANTIBIOTICS_EVER_STANDARD.includes(option.value))
  }
  if (!hasCageFree(ingredient)) {
    ingredientOptions = ingredientOptions.filter((option) => !HAS_CAGE_FREE_STANDARD.includes(option.value))
  }
  return ingredientOptions
}
