import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'
import { theme } from '../theme'

export interface SwitchProps {
  'data-pendo-id'?: string
  label: string
  onChange: () => void
  labelAlign?: 'left' | 'right'
  checked: boolean
  disabled?: boolean
}

const useStyles = createUseStyles<string, Pick<SwitchProps, 'checked' | 'disabled'>>({
  container: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    fontFamily: theme.typography.fontFamily,
  },
  checkContainer: ({ checked, disabled }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 42,
    height: 18,
    borderRadius: 9,
    position: 'relative',
    textTransform: 'uppercase',
    color: 'white',
    fontSize: 12,
    paddingLeft: checked ? 4 : 0,
    paddingRight: checked ? 0 : 4,
    border: checked ? '1px solid #0f9960' : '1px solid darkgrey',
    background: checked ? '#0f9960' : '#BFBFBF',
    opacity: disabled ? 0.5 : 1,
    cursor: disabled ? 'not-allowed' : 'pointer',
  }),
  switch: {
    width: 14,
    height: 14,
    background: 'white',
    borderRadius: '50%',
  },
  label: {
    fontSize: '14px',
    color: '#4d4e54',
  },
  labelLeft: {
    marginRight: 10,
  },
  labelRight: {
    marginLeft: 10,
  },
})

export const Switch: FC<SwitchProps> = ({
  label,
  onChange,
  labelAlign = 'left',
  checked,
  ['data-pendo-id']: pendoId,
  disabled = false,
}) => {
  const classes = useStyles({ checked, disabled })

  return (
    <div data-pendo-id={pendoId} aria-label="Switch" className={classes.container} onClick={disabled ? null : onChange}>
      {labelAlign === 'left' && (
        <span aria-label="Left label" className={`${classes.label} ${classes.labelLeft}`}>
          {label}
        </span>
      )}
      <div aria-label="Checked" className={classes.checkContainer}>
        {!checked && <div className={classes.switch} />}
        {checked ? 'on' : 'off'}
        {checked && <div className={classes.switch} />}
      </div>
      {labelAlign === 'right' && (
        <span aria-label="Right label" className={`${classes.label} ${classes.labelRight}`}>
          {label}
        </span>
      )}
    </div>
  )
}
