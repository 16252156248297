import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Chip, Grid, Icon, theme } from '@howgood/design'

import { selectDisplayedTags, updateDraftInventoryTags } from '@/state/productOverview'
import { DRAFT, selectPageView } from '@/state/pageSettings'
import { FormulationTag } from '@/api'
import { getUniqueTags } from '@/utils/formulationTagsSearch'
import { AddTags } from './AddTags'
import { faHashtag } from '@fortawesome/pro-regular-svg-icons'

export const Tags: FC = () => {
  const dispatch = useDispatch()
  const productTags = useSelector(selectDisplayedTags)
  const view = useSelector(selectPageView)
  const canEdit = view === DRAFT
  const uniqueTags = getUniqueTags(productTags)

  const handleDelete = (tag: FormulationTag) => {
    if (canEdit) {
      dispatch(updateDraftInventoryTags(productTags.filter((pt) => pt !== tag)))
    }
  }

  return (
    <Grid container gap={0.5} alignItems="center" height={34}>
      {(uniqueTags.length || canEdit) && (
        <Icon fontSize={14} color={theme.palette.text.disabled} data-testid="header-icon" icon={faHashtag} />
      )}
      {uniqueTags.map((tag: FormulationTag, index: number) => (
        <Chip
          id={`tag-chip-${index}`}
          key={tag.name}
          label={tag.name}
          size="small"
          onDelete={canEdit ? () => handleDelete(tag) : undefined}
        />
      ))}
      {canEdit && (
        <Grid item>
          <AddTags />
        </Grid>
      )}
    </Grid>
  )
}
