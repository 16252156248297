import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Button,
  Text,
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  DialogActions,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Icon,
  ExternalLink,
} from '@howgood/design'
import { useNavigateToFormula } from '@/hooks/useNavigate'
import { selectIsProductOveriewLoading } from '@/state/productOverview'
import { selectIsRecipeLoading } from '@/state/recipe'
import {
  faAdd,
  faArrowUpRightFromSquare,
  faArrowUpFromBracket,
  faArrowDownToBracket,
} from '@fortawesome/pro-solid-svg-icons'
import { faSquarePollVertical, faCube, faTable } from '@fortawesome/pro-solid-svg-icons'
import { selectUserId } from '@/state/user'
import { useStyles } from './CreateNewButton'
import { useContentfulLink } from '@/contentfully/hooks'

export const SupplierConnectAddButton: FC = () => {
  const classes = useStyles() // Use same styles as Create New button
  const isProductOverviewLoading = useSelector(selectIsProductOveriewLoading)
  const isRecipeLoading = useSelector(selectIsRecipeLoading)
  const userId = useSelector(selectUserId)
  const navigateToFormula = useNavigateToFormula()
  const content = useContentfulLink('bulk-data-upload-template')

  const [dialogOpen, setDialogOpen] = useState(false)
  const [expandedAccordion, setExpandedAccordion] = React.useState<number>(null)

  const handleCreateNewClicked = () => {
    setDialogOpen(false)
    navigateToFormula()
  }

  const handleAccordionChange = (panel: number) => {
    setExpandedAccordion(expandedAccordion !== panel ? panel : null)
  }

  return (
    <div className={classes.topSection}>
      <Button
        data-pendo-id="Menu:SupplierConnectAddButton"
        data-testid="supplier-connect-add"
        aria-label="SupplierConnect Add"
        color="primary"
        size="small"
        onClick={() => setDialogOpen(true)}
        fullWidth
        disabled={isProductOverviewLoading || isRecipeLoading}
        startIcon={faAdd}
      >
        Add
      </Button>
      <Dialog
        id="supplier-connect-add-dialog"
        data-testid="supplier-connect-add-dialog"
        open={dialogOpen}
        maxWidth="sm"
      >
        <DialogTitle>What would you like to do?</DialogTitle>
        <DialogContent>
          <Stack gap={1} sx={{ '& .MuiLink-root': { textDecoration: 'none' } }}>
            <Accordion type="secondary" expanded={expandedAccordion === 0} onChange={() => handleAccordionChange(0)}>
              <AccordionSummary>
                <Stack direction="row" alignItems="center" gap={1}>
                  <Icon icon={faCube} size="lg" />
                  <Text variant="subtitle1">Calculate product emissions</Text>
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <Stack gap={2}>
                  <Text>
                    Calculating the footprint of your products via SupplierConnect is the easiest and most comprehensive
                    way to see and share the impact of your products with your customers.
                  </Text>
                  <Stack width={180}>
                    <Button
                      id="supplier-connect-calculate-emissions-btn"
                      color="primary"
                      onClick={handleCreateNewClicked}
                    >
                      Calculate emissions
                    </Button>
                  </Stack>
                </Stack>
              </AccordionDetails>
            </Accordion>

            <Accordion type="secondary" expanded={expandedAccordion === 1} onChange={() => handleAccordionChange(1)}>
              <AccordionSummary>
                <Stack direction="row" alignItems="center" gap={1}>
                  <Icon icon={faTable} size="lg" />
                  <Text variant="subtitle1">Calculate product emissions (Bulk upload)</Text>
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <Stack gap={2}>
                  <Text>
                    Use this option to upload product information in bulk to calculate and share product sustainability
                    data. HowGood&apos;s Research team will reach out to you via email once your data is uploaded and
                    shared with your customer.
                  </Text>
                  <Stack direction="row" gap={1} width={360}>
                    <ExternalLink href={content?.url}>
                      <Button
                        id="supplier-connect-add-download-template-btn"
                        startIcon={faArrowDownToBracket}
                        color="secondary"
                        onClick={() => setDialogOpen(false)}
                      >
                        Download template
                      </Button>
                    </ExternalLink>
                    <ExternalLink
                      href={`https://howgood.typeform.com/to/ERqu6PWp#user_id=${userId}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button
                        id="supplier-connect-add-upload-btn"
                        startIcon={faArrowUpFromBracket}
                        color="primary"
                        onClick={() => setDialogOpen(false)}
                      >
                        Upload
                      </Button>
                    </ExternalLink>
                  </Stack>
                </Stack>
              </AccordionDetails>
            </Accordion>

            <Accordion type="secondary" expanded={expandedAccordion === 2} onChange={() => handleAccordionChange(2)}>
              <AccordionSummary>
                <Stack direction="row" alignItems="center" gap={1}>
                  <Icon icon={faSquarePollVertical} size="lg" />
                  <Text variant="subtitle1">Submit emissions data</Text>
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <Stack gap={2}>
                  <Text>
                    Do you already have third party emissions data? You can submit your emissions data below. Once
                    submitted, our research team will review the data and reach out via email.
                  </Text>
                  <Stack width={250}>
                    <ExternalLink
                      href={`https://howgood.typeform.com/to/tc5z8DBO#user_id=${userId}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button
                        id="supplier-connect-add-submit-data-btn"
                        startIcon={faArrowUpRightFromSquare}
                        color="primary"
                        onClick={() => setDialogOpen(false)}
                      >
                        Submit data
                      </Button>
                    </ExternalLink>
                  </Stack>
                </Stack>
              </AccordionDetails>
            </Accordion>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Stack direction="row" alignItems="center" width="100%" gap={1}>
            <Stack flex={1} />
            <Button id="supplier-connect-add-close-button" onClick={() => setDialogOpen(false)}>
              Close
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </div>
  )
}
