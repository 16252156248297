import { theme } from '@howgood/design'
import { formatNumber } from '@howgood/utils'
import { ImpactScore } from '@/api'
import { MetricEntry } from '@/contentfully'
import { getScoreRange } from '@/features/ProductPage/util/getScore'

export const QUALITATIVE_METRICS = [
  'working_conditions_impact',
  'labor_impact',
  'biodiversity_impact',
  'environmental_impact',
  'supply_chain_resilience_impact',
  'processing_level_impact',
  'animal_diet_impact',
  'animal_welfare_impact',
  'applegate_human_health_impact',
  'applegate_environmental_health_impact',
  'applegate_animal_health_impact',
]

const parseRawLandUseScoreToTenth = (score: number): number => {
  switch (true) {
    case score >= 2:
      return 1
    case score >= 1 && score < 2:
      return 2
    case score >= 0.5 && score < 1:
      return 3
    case score >= 0.333 && score < 0.5:
      return 4
    case score >= 0.2 && score < 0.333:
      return 5
    case score >= 0.1 && score < 0.2:
      return 6
    case score >= 0.05 && score < 0.1:
      return 7
    case score >= 0.02 && score < 0.05:
      return 8
    case score >= 0.01 && score < 0.02:
      return 9
    case score > 0 && score < 0.01:
      return 10
    default:
      return null
  }
}

const parseRawGreenhouseGasScoreToTenth = (score?: number): number => {
  switch (true) {
    case score >= 35:
      return 1
    case score >= 10:
      return 2
    case score >= 5:
      return 3
    case score >= 1:
      return 4
    case score >= 0.5:
      return 5
    case score >= 0.1:
      return 6
    case score >= 0:
      return 7
    case score >= -0.5:
      return 8
    case score >= -0.1:
      return 9
    default:
      return null
  }
}

const parseRawWaterUsageScoreToTenth = (score?: number): number => {
  switch (true) {
    case score >= 3200:
      return 1
    case score >= 1600:
      return 2
    case score >= 1200:
      return 3
    case score >= 900:
      return 4
    case score >= 600:
      return 5
    case score >= 450:
      return 6
    case score >= 300:
      return 7
    case score >= 150:
      return 8
    case score >= 50:
      return 9
    case score >= 0:
      return 10
    default:
      return null
  }
}

const parseCarbonRemovalsScoreToTenth = (score: number): number => {
  // TODO: replace with impact scale when determined by research
  return score < 0 ? 10 : 0
}

const parseRawWaterConsumptionScoreToTenth = (_score?: number): number => {
  // TODO: replace with impact scale when determined by research
  return 0
}

const parseRawSocScoreToTenth = (score?: number): number => {
  switch (true) {
    case score === null:
      return null // in javascript, null > negative numbers
    case score > 4:
      return 10
    case score > 1:
      return 9
    case score > 0.2:
      return 8
    case score > 0.02:
      return 7
    case score > 0:
      return 6
    case score > -0.02:
      return 5
    case score > -0.2:
      return 4
    case score > -1:
      return 3
    case score > -4:
      return 2
    case score <= -4:
      return 1
    default:
      return null
  }
}

const parseApplegateImpactScoreMetricToTenth = (score: number): number => {
  return Math.round(score / 10)
}

const parsePlanetaryHealthPercentageScoreToTenth = (score: number): number => {
  switch (true) {
    case score >= 200:
      return 1
    case score >= 150 && score < 200:
      return 2
    case score >= 125 && score < 150:
      return 3
    case score >= 100 && score < 125:
      return 4
    case score >= 75 && score < 100:
      return 5
    case score >= 50 && score < 74:
      return 6
    case score >= 25 && score < 50:
      return 7
    case score > 0 && score < 25:
      return 8
    default:
      return null
  }
}

// TODO: apply scale
function parseRawFtmgateWaterWithdrawalScoreToTenth(_score: number): number {
  return null
}

function parseCfProcessingToTenth(score: number): number {
  switch (true) {
    case score === 0:
      return 10
    case score > 0 && score <= 0.01:
      return 9
    case score > 0.01 && score <= 0.05:
      return 8
    case score > 0.05 && score <= 0.1:
      return 7
    case score > 0.1 && score <= 0.3:
      return 6
    case score > 0.3 && score <= 0.5:
      return 5
    case score > 0.5 && score <= 0.7:
      return 4
    case score > 0.7 && score <= 0.8:
      return 3
    case score > 0.8 && score <= 1:
      return 2
    case score > 1:
      return 1
    default:
      return null
  }
}

function parseLandUseChangeToTenth(score: number): number {
  switch (true) {
    case score === 0:
      return 10
    case score <= 0.001:
      return 9
    case score <= 0.01:
      return 8
    case score <= 0.05:
      return 7
    case score <= 0.1:
      return 6
    case score <= 0.5:
      return 5
    case score <= 0.75:
      return 4
    case score <= 1:
      return 3
    case score <= 3:
      return 2
    case score > 3:
      return 1
    default:
      return null
  }
}

function getRiskInformationSorted(impactScore?: ImpactScore): string[] {
  if (!impactScore?.labor_risk_information) {
    return []
  }
  const riskInformationCopy = [...impactScore.labor_risk_information]
  return riskInformationCopy.sort((a, b) => b.value - a.value).map((risk) => risk.description)
}

function getWorkingConditionsText(impactScore: ImpactScore): string {
  const risks = getRiskInformationSorted(impactScore)
  return risks.length > 0 ? risks[0] : ''
}

function formatSmallNumber(score: number | string, decimalPlaces: number = 2): string {
  if (score == null || isNaN(+score)) {
    return null
  }

  // We know score is not NaN from above, but if
  // it is a string, we need to convert it
  const numericScore = typeof score === 'string' ? Number(score) : score

  const formattedScore = formatNumber(numericScore, decimalPlaces)

  const formatter = new Intl.NumberFormat('en-US', {
    notation: 'compact',
    maximumFractionDigits: decimalPlaces,
    minimumFractionDigits: decimalPlaces,
  })

  if (Number(numericScore.toFixed(decimalPlaces)) === 0) {
    const val = Math.pow(10, -decimalPlaces)
    const formattedVal = formatter.format(val)
    return `< ${formattedVal}`
  }

  return formattedScore
}

interface GetImpactScoreValue {
  score: number
  field: string
  valuesMap?: MetricEntry['valuesMap']
  impactScore?: ImpactScore
}
export const getImpactScoreValue = ({ score, field = '', valuesMap, impactScore }: GetImpactScoreValue): string => {
  switch (field) {
    case 'raw_soc_impact':
      return getScoreRange(score, field)
    case 'raw_greenhouse_gas_impact':
    case 'cf_processing_impact':
      return Number.isFinite(score) ? formatSmallNumber(score, 3) : null
    case 'raw_land_use_impact':
    case 'raw_blue_water_usage_impact':
    case 'raw_blue_water_consumption':
      return formatNumber(score) ? formatSmallNumber(score, 2) : null
    case 'working_conditions_impact':
      return getWorkingConditionsText(impactScore) || (score ? `${score}` : null)
    default:
      if (valuesMap) {
        return valuesMap[Math.round(score)] || null
      }
      return typeof score === 'number' ? formatNumber(score, 4) : score ? `${score}` : null
  }
}

export const transformScoreToZeroToTenScale = (metric: string, score: number): number => {
  if (isNaN(+score)) {
    return null
  }
  switch (metric) {
    case 'raw_greenhouse_gas_impact':
      return parseRawGreenhouseGasScoreToTenth(score)
    case 'raw_soc_impact':
      return parseRawSocScoreToTenth(score)
    case 'raw_blue_water_usage_impact':
      return parseRawWaterUsageScoreToTenth(score)
    case 'raw_blue_water_consumption':
      return parseRawWaterConsumptionScoreToTenth(score)
    case 'raw_land_use_impact':
      return parseRawLandUseScoreToTenth(score)
    case 'planetary_health_percentage_impact':
      return parsePlanetaryHealthPercentageScoreToTenth(score)
    case 'raw_ftmgate_water_withdrawal_impact':
      return parseRawFtmgateWaterWithdrawalScoreToTenth(score)
    case 'applegate_impact':
    case 'applegate_human_health_impact':
    case 'applegate_environmental_health_impact':
    case 'applegate_animal_health_impact':
    case 'applegate_health_score':
    case 'craveability_and_sales_potential_impact_original':
    case 'craveability_and_sales_potential_impact':
    case 'applegate_impact_original':
      return parseApplegateImpactScoreMetricToTenth(score)
    case 'cf_processing_impact':
      return parseCfProcessingToTenth(score)
    case 'cf_removals_impact':
      return parseCarbonRemovalsScoreToTenth(score)
    case 'cf_luc_impact':
      return parseLandUseChangeToTenth(score)
    case 'howgood_total_impact':
      return Math.ceil(+score / 8)
    case 'howgood_one_metric':
    case 'starbucks_impact':
      return Math.ceil(+score / 10)
    case 'animal_welfare_impact':
      return Math.round(+score)
    default:
      return Math.ceil(+score)
  }
}

export const getThresholdValues = (metric: string): { gt?: number; gte?: number; lte?: number } => {
  switch (metric) {
    case 'raw_greenhouse_gas_impact':
      return { gte: 10 }
    case 'raw_land_use_impact':
      return { gte: 1000 }
    case 'planetary_health_percentage_impact':
      return { gte: 150 }
    case 'applegate_impact':
    case 'applegate_human_health_impact':
    case 'applegate_environmental_health_impact':
    case 'applegate_animal_health_impact':
    case 'applegate_health_score':
    case 'craveability_and_sales_potential_impact_original':
    case 'craveability_and_sales_potential_impact':
    case 'applegate_impact_original':
      return { gte: 0, lte: 20 }
    case 'howgood_total_impact':
      return { gte: 0, lte: 16 }
    case 'howgood_one_metric':
    case 'starbucks_impact':
      return { gte: 0, lte: 20 }
    case 'raw_blue_water_usage_impact':
    case 'raw_blue_water_consumption':
      return { gte: 299.3 }
    case 'animal_welfare_impact':
      return { gt: 0, lte: 2 }
    case 'cf_processing_impact':
      return { gte: 0.8 }
    default:
      return { gte: 0, lte: 2 }
  }
}

export const getColorForMetric = (metric: string, score?: number) => {
  const level = transformScoreToZeroToTenScale(metric, score)
  return theme.palette.impact[level]
}

interface GetValueForColorCell {
  field: string
  score: number
  impactScore: ImpactScore
  isApplegate?: boolean
  units: string
}
export const getValueForColorCell = ({ field, score, impactScore, units }: GetValueForColorCell) => {
  if (score === null) return ''
  const formattedValMetrics = ['processing_impact', 'water_impact', 'blue_water_impact', 'raw_soc_impact']
  const scoreTransformed = transformScoreToZeroToTenScale(field, score)
  const scoreRounded = formatNumber(score)
  if (formattedValMetrics.includes(field)) {
    const scoreValue = getImpactScoreValue({
      score: scoreTransformed,
      field,
      impactScore,
    })
    return scoreValue ? scoreValue.replaceAll(units, '') : ''
  }
  if (QUALITATIVE_METRICS.includes(field)) {
    return scoreTransformed
  }
  return scoreRounded || ''
}
