import { Field } from '@/constants/impactScore'

export const PRODUCT_TYPES = ['products', 'materials'] as const
export type ProductType = typeof PRODUCT_TYPES[number]
export const AVAILABLE_VIEWS = ['workspaces', 'categories', 'tags'] as const
export type View = typeof AVAILABLE_VIEWS[number]
export const BASIS_TYPES = ['kg', 'sales', 'inventories'] as const
export type Basis = typeof BASIS_TYPES[number]
export const CHART_TYPES = ['bar', 'mosaic', 'pie'] as const
export type ChartType = typeof CHART_TYPES[number]

export interface PortfolioImpactDashboardSettings {
  productType: ProductType
  view: View
  metric: Field
  basis: Basis
  chartType: ChartType
}

export interface InitializationPayload {
  dashboardSettings: PortfolioImpactDashboardSettings
  availableMetrics: Field[]
}

export type CustomMetric = {
  key: string
  title?: string
  subtitle?: string
  units?: string
  metrics: { metric: string; weight: number }[]
}
