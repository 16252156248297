import React, { FC, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { Stack, Text } from '@howgood/design'
import { fetchProductChangelog } from '@/api'
import { useFetchList, useMainMetric } from '@/hooks'
import { IProduct } from '@/records'
import { ProductLogEntry } from './ProductLogEntry'
import { selectUsers } from '@/state/organization'

interface ProductChangeLogProps {
  product: IProduct
}

export const ProductChangeLog: FC<ProductChangeLogProps> = ({ product }) => {
  const fetchChangelog = useCallback(() => fetchProductChangelog(product.id), [product.id])
  const { data, isLoading } = useFetchList<any>(fetchChangelog)
  const metric = useMainMetric()

  const orgUsers = useSelector(selectUsers)

  const items = useMemo(() => {
    return data
      .map((item) => {
        let name = item.workflow_editor.email?.endsWith('howgood.com') ? 'HowGood' : 'an unknown user'
        const date = dayjs(item.timestamp)
        const orgUser = orgUsers.find((user) => user.id === item.workflow_editor.id)
        if (orgUser) {
          const displayName = orgUser.first_name ? `${orgUser.first_name} ${orgUser.last_name || ''}` : orgUser.email
          name = orgUser.email?.endsWith('howgood.com') ? 'HowGood' : displayName
        }
        return {
          score: null,
          timestamp: item.timestamp,
          date: date.format('MMMM D, YYYY'),
          message: `Change to formula by ${name} at ${date.format('h:mm A')} ET`,
          title: product.name,
        }
      })
      .reverse()
  }, [data, orgUsers, product.name])

  if (isLoading) {
    return <>Loading...</>
  }

  return (
    <Stack>
      {items.length > 0 ? (
        items.map((item) => <ProductLogEntry key={item.timestamp} logEntry={item} metric={metric} />)
      ) : (
        <Text>There are no entries in the changelog</Text>
      )}
    </Stack>
  )
}
