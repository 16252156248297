import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'
import { PERMISSIONS_TO_FIELDS_MAP } from '../../constants/impactScore'
import { ImpactScoreBadge } from '@/components'

interface ProductLogEntryProps {
  logEntry: any
  metric: string
}

const useStyles = createUseStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    color: '#4D4E54',
    padding: '0 10px',
  },
  header: {
    borderBottom: '1px solid #CFCFCF',
    textAlign: 'center',
  },
  body: {
    display: 'flex',
    padding: '10px 0 30px 0',
  },
  score: {
    display: 'none',
  },
  title: {
    fontWeight: 700,
  },
  entry: {
    display: 'flex',
    flexDirection: 'column',
  },
})

export const ProductLogEntry: FC<ProductLogEntryProps> = ({ logEntry, metric }) => {
  const classes = useStyles()
  const field = PERMISSIONS_TO_FIELDS_MAP[metric]

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>{logEntry.date}</div>
      <div className={classes.body}>
        <div className={classes.score}>
          <ImpactScoreBadge value={logEntry.score} metric={field} />
        </div>
        <div className={classes.entry}>
          <div className={classes.title}>{logEntry.title}</div>
          {logEntry.message}
        </div>
      </div>
    </div>
  )
}
