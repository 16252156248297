import { fetchElasticV2 } from '@/api'
import { FORMULATIONS_SEARCH_PATH, FORMULATION_TAGS_SEARCH_PATH } from '@/constants/config'
import { generateMtPerYear, kgPerYear } from '@/api/elastic/runtimeMappings'
import { estypes } from '@elastic/elasticsearch'
import { MappingRuntimeFieldType } from '@elastic/elasticsearch/api/types'
import { Field } from '@/constants/impactScore'
import { CustomMetric } from './portfolioDashboard.types'
import { unwrapElasticResults } from '@/utils/unwrapElasticResults'
import { FormulationTagFilterOption } from '@/state/products'

export interface PortfolioDataAggregations extends Record<string, estypes.AggregationsAggregate> {
  categories: estypes.AggregationsTermsAggregateBase<any>
  tags: estypes.AggregationsTermsAggregateBase<any>
  workspaces: estypes.AggregationsTermsAggregateBase<any>
}

interface ESPortfolioData {
  aggregations: PortfolioDataAggregations
}

interface SearchParams {
  workspaceIds: number[]
  actualTags: string[]
  wildcardTags: string[]
  metrics: Field[]
  customMetrics: CustomMetric[]
}

export async function fetchPortfolioData(searchParams: SearchParams): Promise<ESPortfolioData> {
  const { workspaceIds, actualTags, wildcardTags, metrics, customMetrics } = searchParams

  const customMetricKeys = customMetrics.map((metric) => metric.key)

  const runtimeMappings = {
    // Compute total mt_per_year for each product (will be null if no inventory has a non-null value)
    'user_inventories.mt_per_year': generateMtPerYear(workspaceIds),
    // Compute kg_per_year for each product (will be null if product has weight_kg == 0)
    'sales.kg_per_year': kgPerYear,

    // Compute metric * mt_per_year for each metric on each product
    ...metrics.reduce(
      (acc, metric) => ({
        ...acc,
        [`inventories.${metric}`]: {
          type: 'double' as MappingRuntimeFieldType,
          script: `
            if(doc['user_inventories.mt_per_year'].size() != 0 && doc.containsKey('impact_score.${metric}') && doc['impact_score.${metric}'].size() != 0) {
              emit(doc['user_inventories.mt_per_year'].value * doc['impact_score.${metric}'].value);
            }
          `,
        },
      }),
      {}
    ),

    // Compute metric * kg_per_year for each metric on each product
    ...metrics.reduce(
      (acc, metric) => ({
        ...acc,
        [`sales.${metric}`]: {
          type: 'double' as MappingRuntimeFieldType,
          script: `
            if(doc['sales.kg_per_year'].size() != 0 && doc.containsKey('impact_score.${metric}') && doc['impact_score.${metric}'].size() != 0) {
              emit(doc['sales.kg_per_year'].value * doc['impact_score.${metric}'].value);
            }
          `,
        },
      }),
      {}
    ),

    // Compute custom metrics for each product
    ...customMetrics.reduce(
      (acc, customMetric) => ({
        ...acc,
        [customMetric.key]: {
          type: 'double' as MappingRuntimeFieldType,
          script: `
            if(${customMetric.metrics
              .map(
                (item) =>
                  `doc.containsKey('impact_score.${item.metric}') && doc['impact_score.${item.metric}'].size() != 0`
              )
              .join(' && ')}) {
              emit(${customMetric.metrics
                .map((item) => `doc['impact_score.${item.metric}'].value * ${item.weight}`)
                .join(' + ')});
            }
          `,
        },
      }),
      {}
    ),
    ...customMetrics.reduce(
      (acc, customMetric) => ({
        ...acc,
        [`sales.${customMetric.key}`]: {
          type: 'double' as MappingRuntimeFieldType,
          // Only emit a value if the product has a non-zero annual sales volume * weight (can't check for nulls in script)
          script: `
            if(doc['sales.kg_per_year'].size() != 0 && ${customMetric.metrics
              .map(
                (item) =>
                  `doc.containsKey('impact_score.${item.metric}') && doc['impact_score.${item.metric}'].size() != 0`
              )
              .join(' && ')}) {
              if (doc['sales.kg_per_year'].value > 0) {
                double value = doc['sales.kg_per_year'].value * (${customMetric.metrics
                  .map((item) => `doc['impact_score.${item.metric}'].value * ${item.weight}`)
                  .join(' + ')});
                emit(value)
              }
            }
          `,
        },
      }),
      {}
    ),
    ...customMetrics.reduce(
      (acc, customMetric) => ({
        ...acc,
        [`inventories.${customMetric.key}`]: {
          type: 'double' as MappingRuntimeFieldType,
          script: `
            if(doc['user_inventories.mt_per_year'].size() != 0 && ${customMetric.metrics
              .map(
                (item) =>
                  `doc.containsKey('impact_score.${item.metric}') && doc['impact_score.${item.metric}'].size() != 0`
              )
              .join(' && ')}) {
                emit(doc['user_inventories.mt_per_year'].value * (${customMetric.metrics
                  .map((item) => `doc['impact_score.${item.metric}'].value * ${item.weight}`)
                  .join(' + ')}));
            }
          `,
        },
      }),
      {}
    ),
  }

  const getAggsForMetric = (metric: string) => ({
    [`kg.${metric}`]: {
      avg: {
        field: customMetricKeys.includes(metric) ? metric : `impact_score.${metric}`,
      },
    },
    [`inventories.${metric}`]: {
      sum: {
        field: `inventories.${metric}`,
      },
    },
    [`sales.${metric}`]: {
      sum: {
        field: `sales.${metric}`,
      },
    },
    [`count.${metric}`]: {
      value_count: {
        field: customMetricKeys.includes(metric) ? metric : `impact_score.${metric}`,
      },
    },
    ['count.mt_per_year']: {
      value_count: {
        field: 'user_inventories.mt_per_year',
      },
    },
    ['count.kg_per_year']: {
      value_count: {
        field: 'sales.kg_per_year',
      },
    },
    // Get the count of products with a non-null value for the metric AND a non-null value for mt_per_year
    [`count.inventories.${metric}`]: {
      filter: {
        bool: {
          must: [
            { exists: { field: customMetricKeys.includes(metric) ? metric : `impact_score.${metric}` } },
            { exists: { field: 'user_inventories.mt_per_year' } },
          ],
        },
      },
    },
    // Get the count of products with a non-null value for the metric AND a non-null value for kg_per_year
    [`count.sales.${metric}`]: {
      filter: {
        bool: {
          must: [
            { exists: { field: customMetricKeys.includes(metric) ? metric : `impact_score.${metric}` } },
            { exists: { field: 'sales.kg_per_year' } },
          ],
        },
      },
    },
  })

  const aggs = {
    ...[...metrics, ...customMetricKeys].reduce(
      (acc, metric) => {
        return {
          ...acc,
          // For each workspace and each metric, compute the workspace's total CO2e per kg and per year (inventories and sales)
          workspaces: {
            terms: {
              field: 'workspaces.name.keyword',
              size: 10000,
            },
            aggs: {
              ...acc.workspaces.aggs,
              ...getAggsForMetric(metric),
            },
          },
          // For each tag and each metric, compute the workspace's total CO2e per kg and per year (inventories and sales)
          tags: {
            terms: {
              field: 'inventories.formulation_tags.keyword',
              size: 10000,
            },
            aggs: {
              ...acc.tags.aggs,
              ...getAggsForMetric(metric),
            },
          },
          // For each category and each metric, compute the workspace's total CO2e per kg and per year (inventories and sales)
          categories: {
            terms: {
              field: 'manufacturing_type.title.keyword',
              size: 10000,
            },
            aggs: {
              ...acc.tags.aggs,
              ...getAggsForMetric(metric),
            },
          },
          // For each metric, compute the portfolio's total CO2e per kg and per year (inventories and sales)
          [`total.kg.${metric}`]: {
            avg: {
              field: customMetricKeys.includes(metric) ? metric : `impact_score.${metric}`,
            },
          },
          [`total.inventories.${metric}`]: {
            sum: {
              field: `inventories.${metric}`,
            },
          },
          [`total.sales.${metric}`]: {
            sum: {
              field: `sales.${metric}`,
            },
          },
        }
      },
      { workspaces: { aggs: {} }, tags: { aggs: {} }, categories: { aggs: {} } }
    ),
  } as Record<string, estypes.AggregationsAggregationContainer>

  // Always include workspace and pipeline filters; include tag filters if tags are provided
  const boolFilters: estypes.QueryDslQueryContainer[] = [
    { terms: { 'workspaces.id': workspaceIds } },
    { terms: { formulation_status: ['pipeline'] } },
  ]

  // For actual tags, push each term to AND tags
  if (actualTags.length > 0) {
    actualTags.forEach((tag) => {
      boolFilters.push({ terms: { 'inventories.formulation_tags.keyword': [tag] } })
    })
  }

  // For wildcard tags, push them as a single term to OR them
  if (wildcardTags.length > 0) {
    boolFilters.push({ terms: { 'inventories.formulation_tags.keyword': wildcardTags } })
  }

  const esResponse = await fetchElasticV2({
    url: FORMULATIONS_SEARCH_PATH,
    body: {
      size: 0,
      query: {
        bool: {
          filter: boolFilters,
        },
      },
      runtime_mappings: runtimeMappings,
      // Include all runtime mapping fields here
      fields: [
        'user_inventories.mt_per_year',
        'sales.kg_per_year',
        ...metrics.map((metric) => `inventories.${metric}`),
        ...metrics.map((metric) => `sales.${metric}`),
        ...customMetricKeys,
        ...customMetricKeys.map((item) => `inventories.${item}`),
        ...customMetricKeys.map((item) => `sales.${item}`),
      ],
      // Request the required aggregations
      aggs: aggs,
    },
  })

  const aggregations = esResponse?.aggregations as PortfolioDataAggregations

  return { aggregations }
}

export const fetchAllFormulationTags = async (workspaceIds: number[] = []) => {
  try {
    const results = await fetchElasticV2<FormulationTagFilterOption>({
      url: FORMULATION_TAGS_SEARCH_PATH,
      body: {
        size: 10000,
        query: {
          bool: {
            filter: [{ terms: { 'workspaces.id': workspaceIds } }],
          },
        },
      },
    })
    const hits = unwrapElasticResults(results)
    return hits.map((hit) => hit._source.name).sort((a, b) => a.localeCompare(b))
  } catch (e) {
    console.error(e)
  }
  return []
}
