import { AppState } from '@/store'
import { createSelector } from 'reselect'
import { selectPath } from '../router'
import { matchPath } from 'react-router'

export const selectCarbonAccountingEmissionsActivities = (state: AppState) => state.carbonAccounting.emissionsActivities

export const selectCarbonAccountingEditedEmissionsActivityIds = (state: AppState) =>
  state.carbonAccounting.editedEmissionsActivityIds

export const selectCarbonAccountingReportActivities = (state: AppState) => state.carbonAccounting.reportActivities

export const selectCarbonAccountingReports = (state: AppState) => state.carbonAccounting.reports

export const selectCarbonAccountingReportById = (reportId: number) => (state: AppState) =>
  state.carbonAccounting.reports.find((report) => report.id === reportId)

/**
 * If on a specific carbon accounting report page, returns that report
 * Falls back to the first report the user has access to
 */
export const selectCarbonAccountingCurrentReport = createSelector(
  selectCarbonAccountingReports,
  selectPath,
  (reports, path) => {
    const match = matchPath<{ report: string }>(path, { path: '/(carbon-accounting)?/:report' })
    const reportId = Number(match?.params?.report)
    return reports.find((report) => report.id === reportId) || reports[0]
  }
)

export const selectCarbonAccountingLoadingReports = (state: AppState) => state.carbonAccounting.loadingReports

export const selectCarbonAccountingReportsError = (state: AppState) => state.carbonAccounting.reportsError

export const selectCarbonAccountingYears = (state: AppState) => state.carbonAccounting.years
