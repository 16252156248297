import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { GridValidRowModel } from '@mui/x-data-grid-premium'
import {
  selectDisplayedImpactData,
  selectDisplayedIngredientList,
  selectSavedImpactData,
  selectContentfulIngredientImpactData,
} from '@/state/recipe'
import { selectExpanded } from '@/state/pageSettings'
import { ScoreInputs } from '../../util/getScore'

export function useGridRows(): GridValidRowModel[] {
  const ingredients = useSelector(selectDisplayedIngredientList)

  const impactData = useSelector(selectDisplayedImpactData)
  const savedImpactData = useSelector(selectSavedImpactData)

  const expanded = useSelector(selectExpanded)
  const contentfulItems = useSelector(selectContentfulIngredientImpactData)
  const impacts = impactData.ingredients
  const savedImpacts = savedImpactData.ingredients
  const maxImpactValues = impactData.maxValues

  return useMemo(() => {
    const gridRowData = ingredients
      // Filter out rows where the parent ingredient is not expanded and rows where we
      // don't yet have the impact data (e.g. immediately after adding an ingredient)
      .filter((ingredient) => {
        const isTopLevel = ingredient.flat_position.length === 1
        const parentIsExpanded =
          ingredient.flat_position.length > 1 && expanded.includes(ingredient.flat_position.slice(0, -1).join('.'))
        return isTopLevel || parentIsExpanded
      })
      .map((ingredient) => {
        // For each impact, create an object with the info needed by the score cell renderer
        const columnValues = contentfulItems.reduce((acc: { [key: string]: ScoreInputs }, contentfulItem) => {
          return {
            ...acc,
            [contentfulItem.key]: {
              impactKey: contentfulItem.key,
              currentScore: impacts?.[ingredient.index]?.[contentfulItem.key],
              savedScore: savedImpacts?.[ingredient.index]?.[contentfulItem.key],
              flatWeight: ingredient.flat_weight,
              maxImpactValues,
              units: contentfulItem.units || '',
            } as ScoreInputs,
          }
        }, {})

        return {
          id: ingredient.index, // This will be unique, versus the ingredient ID, which may not be
          isTopLevel: ingredient.isTopLevel,
          delete_replace: { ingredient },
          up_down: { ingredient },
          ingredient: { ingredient },
          vendor: { ingredient },
          sourcing_location: { ingredient },
          sourcing_standards: { ingredient },
          processing_location: { ingredient },
          weight: { ingredient },
          ...columnValues,
        }
      })
    return gridRowData
  }, [ingredients, expanded, contentfulItems, impacts, savedImpacts, maxImpactValues])
}
