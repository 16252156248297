import React, { FC } from 'react'
import { CircularProgress } from './CircularProgress'
import { Backdrop } from '@mui/material'
import { Text } from './Text'
import { Stack } from './Stack'

export interface LoadingOverlayProps {
  open: boolean
  color?: string
  loadText?: string
}

export const LoadingOverlay: FC<LoadingOverlayProps> = ({ open, color = '#fff', loadText = '' }) => {
  const size = loadText ? 60 : 80
  return (
    <Backdrop sx={{ color, zIndex: 1000000 }} open={open}>
      <Stack alignItems="center" gap={2} maxWidth="70%">
        <CircularProgress color="inherit" size={size} />
        {loadText && <Text color="#fff">{loadText}</Text>}
      </Stack>
    </Backdrop>
  )
}

LoadingOverlay.displayName = 'LoadingOverlay'
