import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'
import classNames from 'classnames'
import { formatNumber } from '@howgood/utils'
import { Tooltip } from './Tooltip'
import { theme } from './theme'

export interface MetricBoxScoreProps {
  score: number
  size?: 'L' | 'S'
  unit?: string
  precision?: number
}

const useStyles = createUseStyles<string, Pick<MetricBoxScoreProps, 'size'>>({
  scoreWrapper: {
    marginTop: '12px',
    height: ({ size }) => (size === 'L' ? '106px' : '48px'),
    display: 'flex',
    flexDirection: 'column',
  },
  impactScore: {
    fontFamily: theme.typography.display.fontFamily,
    fontWeight: theme.typography.display.fontWeight,
    fontSize: ({ size }) => (size === 'L' ? '80px' : '32px'),
    lineHeight: ({ size }) => (size === 'L' ? '88px' : '36px'),
  },
  unit: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '12px',
    lineHeight: '16px',
  },
})

export const ScoreIndicator: FC<MetricBoxScoreProps> = ({ score, precision, size = 'L', unit }) => {
  const classes = useStyles({ size })

  const roundedScore = formatNumber(score, precision)
  const localeScore = score != null ? score.toLocaleString('en-US') || '' : ''

  return (
    <div className={classNames(classes.scoreWrapper, { [classes.empty]: score === null })}>
      <span aria-label="Metric score" className={classes.impactScore}>
        {precision !== 0 && (
          <Tooltip title={localeScore}>
            <span>{score !== null && !isNaN(score) ? roundedScore : '- -'}</span>
          </Tooltip>
        )}
        {precision === 0 && <span>{score !== null && !isNaN(score) ? roundedScore : '- -'}</span>}
      </span>
      {unit && <span className={classes.unit}>{unit}</span>}
    </div>
  )
}
