import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { State, initialState } from './liteUserPaywallDialog.state'

const liteUserPaywallDialogSlice = createSlice({
  name: 'liteUserPaywallDialog',
  initialState,
  reducers: {
    setLiteUserPaywallDialogState: (state, { payload }: PayloadAction<State>) => {
      state.open = payload.open
      state.redirectPath = payload.redirectPath
    },
    closeLiteUserPaywallDialog: (state) => {
      state.open = false
      state.redirectPath = null
    },
  },
})

export const { setLiteUserPaywallDialogState, closeLiteUserPaywallDialog } = liteUserPaywallDialogSlice.actions

export const liteUserPaywallDialogReducer = liteUserPaywallDialogSlice.reducer
