import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { faScroll } from '@fortawesome/pro-solid-svg-icons'
import { Button } from '@howgood/design'

import { selectReportsByType } from '@/state/organization'
import { ReportsDrawer } from '@/components/Reports/ReportsDrawer'

export const VendorReportsButton: FC = () => {
  const hasReports = useSelector(selectReportsByType).length > 0

  const [reportsDrawerOpen, setReportsDrawerOpen] = React.useState(false)

  const handleButtonClicked = () => {
    setReportsDrawerOpen(true)
  }

  return (
    <>
      <Button
        id="vendor-reports-button"
        data-testid="vendor-reports-button"
        disabled={!hasReports}
        size="small"
        onClick={handleButtonClicked}
        startIcon={faScroll}
      >
        Reports
      </Button>
      <ReportsDrawer reportsDrawerOpen={reportsDrawerOpen} closeReportsDrawer={() => setReportsDrawerOpen(false)} />
    </>
  )
}
