import { Region } from '@/api'
import { IOriginLocation } from './OriginLocation'
import { IProcessingType } from './ProcessingType'
import { InventoriesResponse } from '@/api/types'

interface DefaultOption {
  label?: string
  disabled?: boolean
}

export interface StandardOption extends DefaultOption {
  value: number
}

// STANDARD OPTION
const NON_GMO_STANDARD: StandardOption = { label: 'Non-GMO', value: 12 }
const USDA_ORGANIC_STANDARD: StandardOption = { label: 'Certified Organic', value: 2 }
const GRASS_FED_STANDARD: StandardOption = { label: 'Grass Fed', value: 15 }
const REGENERATIVE_ORGANIC_STANDARD: StandardOption = {
  label: 'Regenerative Organic',
  value: 16,
}
const FAIR_TRADE_USA_STANDARD: StandardOption = { label: 'Fair Trade USA', value: 6 }
const FAIR_TRADE_INTERNATIONAL_STANDARD: StandardOption = {
  label: 'Fair Trade International',
  value: 7,
}
const FAIR_FOR_LIFE_STANDARD: StandardOption = { label: 'Fair For Life', value: 8 }
const LAND_TO_MARKET_STANDARD: StandardOption = { label: 'Land to Market', value: 14 }
const UTZ_STANDARD: StandardOption = { label: 'UTZ', value: 11 }
const RAINFOREST_ALLIANCE_STANDARD: StandardOption = {
  label: 'Rainforest Alliance',
  value: 13,
}
const RAIZ_STANDARD: StandardOption = {
  label: 'RAIZ',
  value: 24,
}
const RSPO_STANDARD: StandardOption = { label: 'RSPO (Preserved or Segregated)', value: 17 }
const FORESTRY_PEFC_SFI_FSC_STANDARD: StandardOption = {
  label: 'Forestry: PEFC - SFI - FSC',
  value: 19,
}
const RTRS_PROTERRA_CRS_STANDARD: StandardOption = {
  label: 'RTRS - ProTerra - CRS',
  value: 18,
}
const GAP4_STANDARD: StandardOption = { label: 'GAP 4', value: 20 }
const GAP5_PLUS_STANDARD: StandardOption = { label: 'GAP 5+', value: 21 }
const ASC_STANDARD: StandardOption = { label: 'ASC', value: 22 }
const MSC_STANDARD: StandardOption = { label: 'MSC', value: 23 }
const GAP1_STANDARD: StandardOption = { label: 'GAP 1', value: 25 }
const GAP2_STANDARD: StandardOption = { label: 'GAP 2', value: 26 }
const GAP3_STANDARD: StandardOption = { label: 'GAP 3', value: 27 }
const CERTIFIED_HUMANE_STANDARD: StandardOption = { label: 'Certified Humane', value: 28 }
const NO_ANTIBIOTICS_EVER_STANDARD: StandardOption = { label: 'No Antibiotics Ever', value: 29 }
const CAGE_FREE_STANDARD: StandardOption = { label: 'Cage-Free', value: 40 }

// STANDARDS
export const RESP_GROWING_STANDARDS: number[] = [USDA_ORGANIC_STANDARD.value, REGENERATIVE_ORGANIC_STANDARD.value]
export const LABOR_STANDARDS: number[] = [
  FAIR_TRADE_USA_STANDARD.value,
  FAIR_TRADE_INTERNATIONAL_STANDARD.value,
  FAIR_FOR_LIFE_STANDARD.value,
  RAINFOREST_ALLIANCE_STANDARD.value,
  RAIZ_STANDARD.value,
]
export const HAS_LAND_TO_MARKET_STANDARDS: number[] = [LAND_TO_MARKET_STANDARD.value]
export const HAS_UTZ_STANDARDS: number[] = [UTZ_STANDARD.value]
export const ORIGIN_CATEGORY_IS_ANIMAL_STANDARDS: number[] = [GRASS_FED_STANDARD.value]
export const HAS_NON_GMO_STANDARDS: number[] = [NON_GMO_STANDARD.value]
export const HAS_RAINFOREST_ALLIANCE_STANDARDS: number[] = [RAINFOREST_ALLIANCE_STANDARD.value]
export const HAS_RAIZ_STANDARD: number[] = [RAIZ_STANDARD.value]
export const HAS_RSPO_STANDARDS: number[] = [RSPO_STANDARD.value]
export const HAS_PEFC_SFI_FSC_STANDARDS: number[] = [FORESTRY_PEFC_SFI_FSC_STANDARD.value]
export const HAS_RTRS_PROTERRA_CRS_STANDARDS: number[] = [RTRS_PROTERRA_CRS_STANDARD.value]
export const HAS_GAP1_STANDARDS: number[] = [GAP1_STANDARD.value]
export const HAS_GAP2_STANDARDS: number[] = [GAP2_STANDARD.value]
export const HAS_GAP3_STANDARDS: number[] = [GAP3_STANDARD.value]
export const HAS_GAP4_STANDARDS: number[] = [GAP4_STANDARD.value]
export const HAS_GAP5_PLUS_STANDARDS: number[] = [GAP5_PLUS_STANDARD.value]
export const HAS_ASC_STANDARDS: number[] = [ASC_STANDARD.value]
export const HAS_MSC_STANDARDS: number[] = [MSC_STANDARD.value]
export const HAS_CERTIFIED_HUMANE_STANDARD: number[] = [CERTIFIED_HUMANE_STANDARD.value]
export const HAS_NO_ANTIBIOTICS_EVER_STANDARD: number[] = [NO_ANTIBIOTICS_EVER_STANDARD.value]
export const HAS_CAGE_FREE_STANDARD: number[] = [CAGE_FREE_STANDARD.value]

// STANDARDS OPTIONS
export const STANDARD_OPTIONS: StandardOption[] = [
  NON_GMO_STANDARD,
  USDA_ORGANIC_STANDARD,
  GRASS_FED_STANDARD,
  REGENERATIVE_ORGANIC_STANDARD,
  FAIR_TRADE_USA_STANDARD,
  FAIR_TRADE_INTERNATIONAL_STANDARD,
  FAIR_FOR_LIFE_STANDARD,
  LAND_TO_MARKET_STANDARD,
  UTZ_STANDARD,
  RAINFOREST_ALLIANCE_STANDARD,
  RAIZ_STANDARD,
  RSPO_STANDARD,
  RTRS_PROTERRA_CRS_STANDARD,
  FORESTRY_PEFC_SFI_FSC_STANDARD,
  GAP1_STANDARD,
  GAP2_STANDARD,
  GAP3_STANDARD,
  GAP4_STANDARD,
  GAP5_PLUS_STANDARD,
  ASC_STANDARD,
  MSC_STANDARD,
  CERTIFIED_HUMANE_STANDARD,
  NO_ANTIBIOTICS_EVER_STANDARD,
  CAGE_FREE_STANDARD,
]

interface Origin {
  category: string
  category_is_animal: boolean
  category_is_microorganism: boolean
  category_is_mineral: boolean
  category_is_petro: boolean
  category_is_plant: boolean
  category_is_seafood: boolean
  eco: boolean
  id: number
  name: string
  water_usage: number
}

interface FunctionalCategory {
  name: string
  id: number
}

export interface Ingredient {
  key?: string
  token?: string
  id?: number
  impacts_resp_growing?: boolean
  ingredients?: any[]
  name?: string
  synonyms?: string[]
  parent_products?: any[]
  default_origin_location?: IOriginLocation
  origin_locations?: IOriginLocation[]
  origin?: Origin
  processing_types?: IProcessingType[]
  functional_categories?: FunctionalCategory[]
  ingredient_location?: IOriginLocation
  is_nested_product?: boolean
  is_shown?: boolean
  is_expanded?: boolean
  is_foreign?: boolean
  flat_position_string: string
  parent_keys_with_self_key_string: string
  parent_keys_string: string
  parent_keys: string[]
  flat_position: any[]
  needs_redistribute: boolean
  hide_scores: boolean
  is_checked_for_weight_redistribution: boolean
  standards?: any[]
  origin_location?: IOriginLocation
  weight?: any
  flat_weight?: any
  inventories?: InventoriesResponse[]
  processing_location: Region
  unmatched?: boolean
}
