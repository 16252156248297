import React, { FC, useEffect, useState } from 'react'
import { GridApi } from '@mui/x-data-grid-premium'
import { faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons'
import { Tooltip, MenuItem, Menu, Button, Stack } from '@howgood/design'
import { defaultColumnVisibilityModel, defaultRowGroupingModel } from './IngredientComparisonResultTable'

interface GroupDropdownProps {
  gridApi: GridApi
  groupByNoneDisabled: boolean
}

export const GroupByDropdown: FC<GroupDropdownProps> = ({ gridApi, groupByNoneDisabled }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  useEffect(() => {
    gridApi.setRowGroupingModel(defaultRowGroupingModel)
  }, [gridApi])

  const onSelectOption = (group?: string[]) => {
    if (group) {
      gridApi.setColumnVisibilityModel(defaultColumnVisibilityModel)
      gridApi.setRowGroupingModel(group)
    } else {
      gridApi.setColumnVisibilityModel({}) // show all columns
      gridApi.setRowGroupingModel([]) // ungroup rows
    }
    setAnchorEl(null)
  }

  return (
    <Stack>
      <Button endIcon={anchorEl ? faCaretUp : faCaretDown} onClick={(e) => setAnchorEl(e.target)}>
        Group By...
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        <Tooltip
          placement="top"
          title={groupByNoneDisabled ? 'Grouping is required for lists of 2500 or more ingredients' : null}
        >
          <MenuItem disabled={groupByNoneDisabled} onClick={() => onSelectOption()}>
            None
          </MenuItem>
        </Tooltip>
        <MenuItem onClick={() => onSelectOption(['ingredient', 'standard', 'location'])}>Sourcing Standard</MenuItem>
        <MenuItem onClick={() => onSelectOption(['ingredient', 'location', 'standard'])}>Location</MenuItem>
      </Menu>
    </Stack>
  )
}
