import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { selectFormulaNotesSplit } from '@/state/splitio'
import { selectDisplayedProductNotes } from '@/state/productOverview'
import { DRAFT, selectPageView } from '@/state/pageSettings'
import { selectIsRollup } from '@/state/router'
import { NotesAccordion } from '@/components/Scores/OverviewPanel/NotesAccordion'

export const FormulaNotes: FC<{ isFavorites?: boolean }> = ({ isFavorites }) => {
  const formulaNotesEnabled = useSelector(selectFormulaNotesSplit)
  const displayedNotes = useSelector(selectDisplayedProductNotes)
  const isRollup = useSelector(selectIsRollup)
  const view = useSelector(selectPageView)

  if (!formulaNotesEnabled || view === DRAFT || isRollup) {
    return null
  }

  return <NotesAccordion isFavorites={isFavorites} id="formula-notes-panel" displayedNotes={displayedNotes} />
}
