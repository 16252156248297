import { createAsyncThunk } from '@reduxjs/toolkit'
import { fetchAllV2 } from '@/api'
import { AppState } from '@/store'
import { ManufacturingType } from './manufacturingTypes.slice'

export const getManufacturingTypes = createAsyncThunk<ManufacturingType[], void, { state: AppState }>(
  'manufacturingTypes/getManufacturingTypes',
  async () => {
    try {
      return await fetchAllV2<ManufacturingType>({ url: 'manufacturing-types/' })
    } catch (e) {
      return []
    }
  }
)
