import {
  ESVendorData,
  UNKNOWN_VENDORS_LABEL,
  createESQuery,
  prepareVendorData,
} from '@/state/vendorManagement/vendorManagement.utils'
import {
  generateMtPerYear,
  scope3TotalAnnual,
  generateIsVerified,
  getVendorPriority,
} from '@/api/elastic/runtimeMappings'
import { VendorInfo } from '@/records'
import { BucketAggs } from './vendorManagement.slice'
import { fetchElasticV2 } from '@/api'
import { FORMULATIONS_SEARCH_PATH } from '@/constants/config'
import * as Sentry from '@sentry/react'
import { availableVendorMetrics, customVendorMetrics } from './vendorManagement.metrics'

export interface VendorProductFilters {
  workspaceIds?: number[]
  manufacturingTypeIds?: number[]
  vendorNameQueries?: string[]
  formulationStatuses?: string[]
  formulationTags?: string[]
}

interface FetchVendorDataParams {
  filters: VendorProductFilters
  thirdPartyWorkspacesIds: number[]
  orgId: number
}

const getESFieldName = (field: string) => {
  return Object.keys(customVendorMetrics).includes(field) ? field : `impact_score.${field}`
}

export async function fetchVendorData({
  filters,
  thirdPartyWorkspacesIds,
  orgId,
}: FetchVendorDataParams): Promise<{ vendorData: VendorInfo[]; bucketAggs: BucketAggs }> {
  const esQuery = createESQuery(filters)
  const availableMetricKeys = Object.keys(availableVendorMetrics)

  try {
    const results = await fetchElasticV2({
      url: FORMULATIONS_SEARCH_PATH,
      body: {
        size: 0,
        query: esQuery,

        runtime_mappings: {
          // The mt_per_year field is used to calculate the scope 3 total and custom annual emissions fields
          // Just material workspaces are used in the mt_per_year calculation
          mt_per_year: generateMtPerYear(filters.workspaceIds),
          scope_3_total_impact: scope3TotalAnnual,
          verified: generateIsVerified(thirdPartyWorkspacesIds),
          priority: getVendorPriority(orgId),
          // Add runtime mappings for all custom annualized metrics
          ...Object.entries(customVendorMetrics).reduce(
            (acc, [metric, info]) => ({ ...acc, [metric]: info.runtimeMapping }),
            {}
          ),
        },

        fields: [
          'mt_per_year',
          'scope_3_total_impact',
          'verified',
          'priority',
          'brand.sbt_action',
          'brand.sbt_type',
          ...Object.keys(customVendorMetrics),
        ],

        aggs: {
          // Compute the sum, average, and count of each metric across all matching products
          ...availableMetricKeys.reduce((acc, field) => {
            const esField = getESFieldName(field)
            return {
              ...acc,
              [`products_${field}_stats`]: {
                stats: { field: esField },
              },
            }
          }, {}),
          // Compute scope 3 annual across all matching products (needed to compute contribution)
          scope_3_total_impact_sum: {
            sum: { field: 'scope_3_total_impact' },
          },
          // Create a bucket for each vendor
          vendors: {
            terms: {
              size: 5000,
              field: 'brand.name.keyword',
              missing: UNKNOWN_VENDORS_LABEL,
            },
            // Compute aggregates within each bucket
            aggs: {
              // Need this so we can get the brand id for each vendor bucket
              top_value_hits: { top_hits: { size: 1, _source: { include: ['brand.id'] } } },
              manufacturing_types: {
                terms: {
                  field: 'manufacturing_type.title.keyword',
                  size: 1000,
                },
              },
              // Priority and SBTi fields are the same across all products for a given vendor, so min will return the correct value
              priority: { min: { field: 'priority' } },
              sbt_action: { min: { field: 'brand.sbt_action' } },
              sbt_type: { min: { field: 'brand.sbt_type' } },
              verified: {
                terms: {
                  field: 'verified',
                  size: 1000,
                },
              },
              // Compute total mt/yr for each vendor bucket
              mt_per_year_sum: {
                sum: { field: 'mt_per_year' },
              },
              // Within each vendor bucket, compute the sum, average, and count of each metric
              ...[...availableMetricKeys, 'scope_3_total_impact'].reduce((acc, field) => {
                const esField = getESFieldName(field)
                return {
                  ...acc,
                  [field]: {
                    stats: { field: esField },
                  },
                }
              }, {}),
            },
          },
          // Across all vendor buckets, compute the sum and the average of each metric sum or average
          // https://www.elastic.co/guide/en/elasticsearch/reference/current/search-aggregations-pipeline-stats-bucket-aggregation.html
          ...availableMetricKeys.reduce(
            (acc, field) => ({
              ...acc,
              [`vendors_${field}_stats`]: {
                stats_bucket: {
                  buckets_path: `vendors>${field}.${availableVendorMetrics[field].aggType}`,
                },
              },
            }),
            {}
          ),
        },
      },
    })

    const { vendorData, bucketAggs } = prepareVendorData((results?.aggregations as unknown) as ESVendorData)
    return { vendorData, bucketAggs }
  } catch (e) {
    Sentry.captureException(e)
    return { vendorData: [], bucketAggs: {} }
  }
}
