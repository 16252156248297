import React, { FC, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, MenuItem, Select, Text, theme } from '@howgood/design'

import {
  selectHasImpactsOverriddenNestedProduct,
  selectIsImpactsOverridden,
  selectIsScenarioContext,
  selectLockedClaimsTimeStamp,
  selectProductIsLocked,
  selectSavedTimeStamp,
} from '@/state/productOverview'
import { DRAFT, PUBLISHED, SAVED, selectPageView } from '@/state/pageSettings'
import { selectIsEditing } from '@/state/recipe'
import { ViewSelectOption } from './ViewSelectOption'
import { formatDateString } from '@/utils/dates'

export const ViewSelect: FC = () => {
  const isProductLocked = useSelector(selectProductIsLocked)
  const [open, setOpen] = useState(false)
  const impactsOverriddenProduct = useSelector(selectIsImpactsOverridden)
  const hasImpactsOverriddenNestedProduct = useSelector(selectHasImpactsOverriddenNestedProduct)
  const view = useSelector(selectPageView)
  const lockedTime = useSelector(selectLockedClaimsTimeStamp)
  const savedTime = useSelector(selectSavedTimeStamp)
  const editing = useSelector(selectIsEditing)
  const showScenarioContext = useSelector(selectIsScenarioContext)

  const DRAFT_TEXT = editing ? 'Editing draft formula' : 'Draft formula'
  const LIVE_DATA = `${showScenarioContext ? 'Saved formula' : 'Live formula'} (saved ${formatDateString({
    dateString: savedTime,
  })})`
  const LOCK_TEXT = `Report view (created ${formatDateString({ dateString: lockedTime })})`

  const liveDescription = useMemo(() => {
    const description = [
      'Scores and metrics can change at any time based on edits to the formula or updates to the underlying research data.',
    ]
    if (!isProductLocked && !showScenarioContext) {
      description.push(
        'Create a product report to lock the underlying data and enable the ability to switch views between live formula and locked report data.'
      )
    }
    return description
  }, [isProductLocked, showScenarioContext])

  const displayText = view === PUBLISHED ? LOCK_TEXT : view === DRAFT ? DRAFT_TEXT : LIVE_DATA

  return (
    <Select
      open={open}
      onClick={() => setOpen(!open)}
      displayEmpty
      variant="standard"
      disableUnderline
      sx={{
        width: 265,
        color: theme.palette.text.primary,
        '&:before': {
          display: 'none',
        },
        svg: { fill: theme.palette.text.primary },
      }}
      renderValue={() => <Text textAlign="end">{displayText}</Text>}
    >
      <Box width={265} mr={1}>
        <MenuItem disabled sx={{ '&.Mui-disabled': { opacity: 1 }, p: 1, ml: 0.5 }}>
          <Text variant="body1" color={theme.palette.text.secondary}>
            Choose product view
          </Text>
        </MenuItem>
        {!impactsOverriddenProduct &&
          !hasImpactsOverriddenNestedProduct && [
            <ViewSelectOption key="view-draft" viewOption={DRAFT} title={DRAFT_TEXT} />,
            <ViewSelectOption key="view-saved" viewOption={SAVED} title={LIVE_DATA} description={liveDescription} />,
          ]}
        {isProductLocked && !showScenarioContext && (
          <ViewSelectOption
            viewOption={PUBLISHED}
            title={LOCK_TEXT}
            description={[
              'Underlying data is locked. This version can be shared outside your organization and submitted for validation.',
            ]}
          />
        )}
      </Box>
    </Select>
  )
}
