import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { OrgUser } from '@/state/organization'
import { AssigneeCell } from '@/components/GridWithMetrics/AssigneeCell'
import { GridScenario } from '@/records/Initiatives'
import { updateScenario } from '@/state/initiatives'

export interface ScenarioAssigneeCellParams extends GridRenderCellParams {
  value: GridScenario
}

export const ScenarioAssigneeCell: FC<ScenarioAssigneeCellParams> = (params) => {
  const { value: scenario } = params
  const dispatch = useDispatch()
  const setAssignee = (user: OrgUser) => {
    dispatch(
      updateScenario({
        id: scenario.id,
        assignee: user,
      })
    )
  }

  if (scenario.isBaseline) {
    return null
  }

  return <AssigneeCell item={scenario} assignWhat="scenario" setAssignee={setAssignee} />
}
