import React, { forwardRef, useState } from 'react'
import MuiTextField, { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField'
import { getHasValue } from './Select.utils'

export interface TextFieldProps extends Omit<MuiTextFieldProps, 'className'> {
  ['data-testid']?: string
  color?: 'error' | 'primary' | 'secondary' | 'info'
  onEnter?: (e?: React.KeyboardEvent<HTMLDivElement>) => void
}

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      ['data-testid']: dataTestId,
      variant,
      color = 'secondary',
      onEnter,
      onKeyDown,
      size = 'small',
      required,
      error,
      value,
      sx,
      ...rest
    },
    ref
  ) => {
    const [validationError, setValidationError] = useState(false)
    const hasValue = getHasValue(value)
    const hasError = validationError || error

    return (
      <MuiTextField
        ref={ref}
        data-testid={dataTestId}
        color={color}
        variant={variant}
        size={size}
        required={required}
        error={hasError}
        value={value}
        onFocus={() => {
          if (required) setValidationError(false)
        }}
        onBlur={() => {
          if (required) setValidationError(!hasValue)
        }}
        onKeyDown={(e) => {
          if (onKeyDown) {
            onKeyDown(e)
          }
          if (e.key === 'Enter' && onEnter) {
            onEnter(e)
          }
        }}
        sx={{ background: 'white', ...sx }}
        {...rest}
      />
    )
  }
)

TextField.displayName = 'TextField'
