import {
  WorkspaceOption,
  WorkspaceType,
  Workspace,
  getWorkspaceTypes,
  PageType,
  isDefaultWorkspace,
  workspaceTypeLabelMap,
} from '@/records'
import { AppState } from '@/store'
import { createIWorkspaceOption } from '@/utils/workspace'
import { createSelector } from 'reselect'
import { AllWorkspaceScoresData } from './workspaces.requests'
import { selectIsPortfolio, selectIsProcurement } from '../router'
import { isImpactScoreNull } from '../recipe/recipe.utils'
import { WorkspaceTypeOption } from '../products'

const selectAllWorkspaces = (state: AppState): Workspace[] => state.workspaces.workspaces

export const selectWorkspaces = createSelector<AppState, Workspace[], Workspace[]>(selectAllWorkspaces, (workspaces) =>
  workspaces.filter((w: Workspace) => w.edit || w.read || w.foreign_read)
)

export const selectIsWorkspacesLoading = (state: AppState): boolean => state.workspaces.isLoading

export const selectAllWorkspaceImpactScores = (state: AppState): AllWorkspaceScoresData =>
  state.workspaces.workspaceScores

export const selectWorkspacesByType = (workspaceType: WorkspaceType) =>
  createSelector<AppState, Workspace[], Workspace[]>(selectWorkspaces, (workspaces) =>
    workspaces.filter((w: Workspace) => w.workspace_type === workspaceType)
  )

export const selectHasWorkspaceOfType = (workspaceType: WorkspaceType) =>
  createSelector<AppState, Workspace[], boolean>(selectWorkspaces, (workspaces) =>
    workspaces.some((w: Workspace) => w.workspace_type === workspaceType)
  )

export const selectDefaultWorkspaceIds = createSelector<AppState, Workspace[], number[]>(
  selectWorkspaces,
  (workspaces) => workspaces.filter((w: Workspace) => isDefaultWorkspace(w.workspace_type)).map((ws) => ws.id)
)

export const selectWorkspacesWithoutDefault = createSelector<AppState, Workspace[], Workspace[]>(
  selectWorkspaces,
  (workspaces) => workspaces.filter((w: Workspace) => !isDefaultWorkspace(w.workspace_type))
)

// All third party workspaces
export const selectThirdPartyWorkspaces = createSelector<AppState, Workspace[], Workspace[]>(
  selectWorkspaces,
  (workspaces) => workspaces.filter((w: Workspace) => w.foreign_read && !w.edit && !w.read)
)

// non default, used in most locations
export const selectWorkspacesIds = createSelector<AppState, Workspace[], number[]>(
  selectWorkspacesWithoutDefault,
  (workspaces) => workspaces?.map((w) => w.id)
)

// non default
export const selectAllWorkspacesOptions = createSelector<AppState, Workspace[], WorkspaceOption[]>(
  selectWorkspacesWithoutDefault,
  (workspaces) => workspaces?.map((w: Workspace) => createIWorkspaceOption(w))
)

// In workspace lists we only want to dispaly workspaces that have at least one product with
// impacts scores. Becomes relative when we display workspace lists and apply product
// level filters, like tags on the portfolio page
export const selectWorkspacesWithScores = createSelector(
  selectWorkspacesWithoutDefault,
  selectAllWorkspaceImpactScores,
  selectIsPortfolio,
  (workspaces, workspaceScores, isPortfolio) => {
    if (!isPortfolio) {
      return workspaces
    }
    const withScores = workspaces.filter((ws) => {
      const scores = workspaceScores[ws.id]
      return !isImpactScoreNull(scores)
    })
    return withScores.length > 0 ? withScores : workspaces
  }
)

// Unique list of workspace types that exist in the users workspace list
export const selectAvailableWorkspaceTypes = createSelector<
  AppState,
  Workspace[],
  boolean,
  boolean,
  WorkspaceTypeOption[]
>(selectWorkspacesWithoutDefault, selectIsProcurement, selectIsPortfolio, (workspaces, isProcurement, isPortfolio) => {
  const pageType: PageType = isProcurement ? 'Procurement' : isPortfolio ? 'Portfolio' : 'Formulation'
  const initialTypes = getWorkspaceTypes(pageType)
  const allTypes = initialTypes.reduce((workspaceTypes, workspaceType) => {
    if (workspaces.some((ws) => ws.workspace_type === workspaceType)) {
      workspaceTypes.push(workspaceType)
    }
    return workspaceTypes
  }, [])
  return [...new Set(allTypes)].map((type) => ({ label: workspaceTypeLabelMap[type], value: type }))
})

export const selectEditableWorkspaces = createSelector<AppState, Workspace[], Workspace[]>(
  selectWorkspaces,
  (workspaces) => workspaces.filter((w) => w.edit)
)

export const selectEditableProcurementWorkspaces = createSelector<AppState, Workspace[], Workspace[]>(
  selectEditableWorkspaces,
  (workspaces) => workspaces.filter((w) => ['Procurement', 'Material Directory'].includes(w.workspace_type))
)

export const selectEditableProcurementWorkspaceIds = createSelector<AppState, Workspace[], number[]>(
  selectEditableProcurementWorkspaces,
  (workspaces) => workspaces.map((w) => w.id)
)

export const selectEditableWorkspaceIds = createSelector<AppState, Workspace[], number[]>(
  selectEditableWorkspaces,
  (workspaces) => workspaces.map((ws) => ws.id)
)

export const selectWorkspaceImpactScoresById = (id: number) =>
  createSelector(selectAllWorkspaceImpactScores, (workspaceScores) => workspaceScores[id])
