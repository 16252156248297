import React, { FC, ReactNode } from 'react'
import { ThemeProvider, createTheme } from '@mui/material/styles'

/**
 * Module augmentation so the theme's typings can accept custom values
 * See https://mui.com/customization/palette/#adding-new-colors
 * and https://mui.com/customization/typography/#adding-amp-disabling-variants
 */
declare module '@mui/material/styles' {
  // Add additional palette colors for use within `createTheme`
  interface SimplePaletteColorOptions {
    paperDark?: string
    paperMain?: string
    selected?: string
  }

  // Extend the PaletteColor interface (enables autocomplete and autoupdate of theme refs)
  interface PaletteColor {
    paperDark: string
    paperMain: string
    selected: string
  }

  type ImpactKey = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10
  interface Palette {
    impact: Record<ImpactKey, string>
    impactLight: Record<ImpactKey, string>
    impactTextLarge: Record<ImpactKey, string>
    impactText: Record<ImpactKey, string>
    category?: Record<ImpactKey, string>
    dataHealth?: Record<string, string>
  }
  interface PaletteOptions {
    impact?: Record<ImpactKey, string>
    impactLight?: Record<ImpactKey, string>
    impactTextLarge?: Record<ImpactKey, string>
    impactText?: Record<ImpactKey, string>
    category?: Record<ImpactKey, string>
    dataHealth?: Record<string, string>
  }

  // Extend the TypographyVariants interface (enables autocomplete and autoupdate of theme refs)
  interface TypographyVariants {
    display: React.CSSProperties
    productCell: React.CSSProperties
    legend: React.CSSProperties
  }

  // Add additional typography variants for use within `createTheme`
  interface TypographyVariantsOptions {
    display?: React.CSSProperties
    productCell?: React.CSSProperties
    legend?: React.CSSProperties
  }
}

// Add new variants to the Typography's `variant` prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    display: true
    productCell: true
    legend: true
  }
}

// Define colors locally that are used in multiple places within createTheme()
const colorTextPrimary = 'rgba(0, 0, 0, 0.75)'
const colorTextSecondary = 'rgba(0, 0, 0, 0.55)'
const boxShadow =
  '0px 0px 1px rgba(0, 0, 0, 0.55), 0px 2px 3px -1px rgba(0, 0, 0, 0.08), 0px 2px 8px rgba(0, 0, 0, 0.1)'

// First, define the basic design options.
export let theme = createTheme({
  palette: {
    primary: {
      dark: '#00665E',
      main: '#00857B',
      paperDark: 'rgba(0, 133, 123, 0.12)',
      paperMain: 'rgba(0, 133, 123, 0.08)',
      selected: 'rgba(0, 133, 123, 0.08)',
    },
    secondary: {
      dark: '#535459',
      main: '#65666D',
      light: 'rgb(131, 132, 138)',
      paperDark: 'rgba(101, 102, 109, 0.12)',
      paperMain: 'rgba(101, 102, 109, 0.08)',
    },
    error: {
      dark: '#D93A45',
      main: '#FF4552',
      light: '#FF6A74',
      paperDark: 'rgba(255, 69, 82, 0.12)',
      paperMain: 'rgba(255, 69, 82, 0.08)',
    },
    warning: {
      dark: '#CF4900',
      main: '#ED6C02',
      light: '#FF9800',
      paperDark: 'rgba(255, 152, 0, 0.12)',
      paperMain: 'rgba(255, 152, 0, 0.08)',
    },
    info: {
      dark: '#195EE2',
      main: '#1C6BFF',
      light: '#368FF8',
      paperDark: 'rgba(28, 146, 255, 0.12)',
      paperMain: 'rgba(28, 145, 255, 0.08)',
    },
    success: {
      dark: '#06884D',
      main: '#32B67A',
      light: '#38C87D',
      paperDark: 'rgba(50, 182, 114, 0.12)',
      paperMain: 'rgba(50, 182, 114, 0.08)',
    },
    text: {
      primary: colorTextPrimary,
      secondary: colorTextSecondary,
      disabled: 'rgba(0, 0, 0, 0.38)',
    },
    impact: {
      0: '#ECECEC',
      1: '#F5757B',
      2: '#F5757B',
      3: '#F1A169',
      4: '#F1A169',
      5: '#EDC755',
      6: '#DAE14E',
      7: '#8ADA58',
      8: '#8ADA58',
      9: '#39CE69',
      10: '#39CE69',
    },
    impactTextLarge: {
      0: '#FF4552',
      1: '#FF4552',
      2: '#FF4552',
      3: '#D75B47',
      4: '#D75B47',
      5: '#AE713C',
      6: '#848542',
      7: '#599659',
      8: '#599659',
      9: '#2EA871',
      10: '#2EA871',
    },
    impactText: {
      0: '#D93B46',
      1: '#D93B46',
      2: '#D93B46',
      3: '#BB513E',
      4: '#BB513E',
      5: '#9E6836',
      6: '#7A773A',
      7: '#4F7E49',
      8: '#4F7E49',
      9: '#248559',
      10: '#248559',
    },
    // Used in the History panel chart
    category: {
      0: '#1F77B4',
      1: '#FF7F0F',
      2: '#2AA02B',
      3: '#D62727',
      4: '#386CB0',
      5: '#8B554B',
      6: '#BE5B17',
      7: '#666666',
      8: '#BCBD21',
      9: '#15BECF',
      10: '#6A3D9A',
    },
    dataHealth: {
      green: '#7b8c61',
      yellow: '#f7d262',
      red: '#d0655e',
    },
    action: {
      disabledOpacity: 0.38,
    },
  },
  typography: {
    // Note: legacy Whitney-* font names are needed for PDF export with kendo in Latis
    fontFamily: 'Whitney, Whitney-Medium, Helvetica, Arial, sans-serif',
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    allVariants: {
      color: colorTextPrimary,
      fontFamily: 'Whitney, Whitney-Medium, Helvetica, Arial, sans-serif',
      fontSize: 16,
      fontWeight: 500,
      letterSpacing: 0,
    },
    display: {
      fontFamily: 'Whitney, Whitney-Bold, Helvetica, Arial, sans-serif',
      fontSize: 64,
      fontWeight: 700,
      lineHeight: '72px',
    },
    h1: {
      fontSize: 38,
      fontWeight: 500,
      lineHeight: '44px',
    },
    h2: {
      fontSize: 26,
      fontWeight: 500,
      lineHeight: '34px',
    },
    h3: {
      fontSize: 20,
      fontWeight: 600,
      lineHeight: '26px',
    },
    h4: {
      fontSize: 16,
      fontWeight: 700,
      lineHeight: '22px',
    },
    h5: {
      fontSize: 14,
      fontWeight: 700,
      lineHeight: '20px',
    },
    subtitle1: {
      fontSize: 18,
      lineHeight: '24px',
    },
    subtitle2: {
      color: colorTextSecondary,
      fontSize: 12,
      letterSpacing: '0.02em',
      lineHeight: '20px',
      textTransform: 'uppercase',
    },
    body1: {
      fontSize: 16,
      lineHeight: '22px',
    },
    body2: {
      fontSize: 14,
      lineHeight: '20px',
    },
    caption: {
      color: colorTextSecondary,
      fontSize: 12,
      lineHeight: '16px',
    },
    productCell: {
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '20px',
    },
    legend: {
      color: 'black',
      fontSize: '12px',
      lineHeight: '20px',
      textOverflow: 'ellipsis',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1024,
      lg: 1280,
      xl: 1600,
    },
  },
  transitions: {
    easing: {
      easeInOut: 'cubic-bezier(0.25, 0.1, 0.25, 1)',
    },
  },
})

/**
 * Then use these design options to compose other options
 * or to override the design of specific components.
 */

theme = createTheme(theme, {
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          gap: 1,
          boxShadow: boxShadow,
          borderRadius: '6px',
          '&.MuiAccordion-rounded': {
            margin: 0,
            padding: '24px 16px',
          },
          '&.Mui-expanded': {
            margin: 0,
            padding: '24px 16px',
          },
          '&:before': {
            display: 'none',
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          minHeight: 'unset',
          padding: '0 8px',
          '&.Mui-expanded': {
            padding: '0 8px',
            minHeight: 'unset',
          },
        },
        content: {
          margin: 0,
          alignItems: 'center',
          '&.Mui-expanded': {
            margin: 0,
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: 0,
          marginTop: 16,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          boxShadow: 'none',
          lineHeight: 1.1,
          '&:hover': {
            boxShadow: theme.shadows[0],
            transition: 'none',
          },
          '&:active': {
            boxShadow: theme.shadows[0],
            transition: 'none',
            transform: 'scale(0.96)',
          },
          '&:focus': {
            boxShadow: theme.shadows[0],
            outlineWidth: '2px',
            outlineOffset: '2px',
            outlineStyle: 'solid',
            outlineColor: theme.palette.info.light,
          },
          '&:disabled': {
            opacity: 0.5,
            pointerEvents: 'auto',
            cursor: 'not-allowed',
            transform: 'unset',
          },
        },
        sizeSmall: {
          height: '34px',
          fontSize: 14,
          padding: '0 12px',
          '.MuiButton-startIcon': {
            svg: {
              height: 14,
              fontSize: 14,
            },
          },
        },
        sizeMedium: {
          height: '44px',
          padding: '0 16px',
        },
        containedPrimary: {
          '&:disabled': {
            color: theme.palette.primary.contrastText,
            background: theme.palette.primary.main,
          },
        },
        containedSecondary: {
          border: 'none',
          color: theme.palette.secondary.dark,
          background: theme.palette.secondary.paperMain,
          '&:hover': {
            border: 'none',
            background: theme.palette.secondary.paperDark,
          },
          '&:disabled': {
            color: theme.palette.secondary.dark,
            background: theme.palette.secondary.paperMain,
          },
        },
        containedError: {
          border: 'none',
          color: theme.palette.error.dark,
          background: theme.palette.error.paperMain,
          '&:hover': {
            border: 'none',
            background: theme.palette.error.paperDark,
          },
          '&:disabled': {
            color: theme.palette.error.dark,
            background: theme.palette.error.paperMain,
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: { root: { p: 0.5 } },
    },
    MuiIconButton: {
      styleOverrides: {
        sizeSmall: {
          height: '24px',
          width: '24px',
          fontSize: '14px',
        },
        sizeMedium: {
          fontSize: '16px',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          padding: theme.spacing(0.5),
          boxShadow: boxShadow,
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          display: 'block',
          padding: 0,
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        colorPrimary: {
          color: theme.palette.primary.light,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        colorDefault: {
          color: theme.palette.secondary.main,
          backgroundColor: '#65666D14',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: { root: { lineHeight: 1.2 } },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.secondary.paperMain,
          borderRadius: 8,
        },
        barColorPrimary: {
          borderRadius: 8,
        },
      },
    },
    MuiTab: {
      styleOverrides: { root: { textTransform: 'initial' } },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          '&.MuiToggleButton-sizeSmall': {
            height: '28px',
          },
          '&.MuiToggleButton-sizeMedium': {
            height: '32px',
          },
          '&.MuiToggleButton-standard': {
            textTransform: 'none',
            padding: '4px 8px',
            border: '1px solid transparent',
            '&.Mui-selected': {
              backgroundColor: 'white',
              border: '1px solid rgba(0, 0, 0, 0.3)',
              '&:hover': {
                backgroundColor: 'white',
              },
            },
            '&:hover': {
              border: '1px solid rgba(0, 0, 0, 0.5)',
            },
          },
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.divider,
          color: theme.palette.text.primary,
          padding: 3,
          gap: 4,
          '& .MuiToggleButtonGroup-grouped:first-of-type': {
            borderRadius: 4,
          },
          '& .MuiToggleButtonGroup-grouped:last-of-type': {
            borderRadius: 4,
          },
          '& .MuiToggleButtonGroup-grouped:not(:last-of-type)': {
            borderRadius: 4,
          },
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          '& .MuiDataGrid-cell': {
            display: 'flex',
            alignItems: 'center',
          },
        },
      },
    },
  },
})

interface Props {
  children: ReactNode
}
export const MUIThemeProvider: FC<Props> = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}
