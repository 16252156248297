import {
  Field,
  SCOPE_3_CATEGORY_1_FIELD,
  SCOPE_3_CATEGORY_4_FIELD,
  SCOPE_3_CONTRIBUTION_FIELD,
  SCOPE_3_FLAG_FIELD,
} from '@/constants/impactScore'
import { FORMULATION_TAGS } from '@/constants/common'

// Reference for product list metrics organization can be found here:
// https://docs.google.com/spreadsheets/d/1IU0hHogqHoEBLF8opX5tP6NC1hCHT9msjcy7aaYkPVI/edit#gid=18260546

export type ColGroup =
  | 'Basics'
  | 'Metrics'
  | 'Carbon'
  | 'Lifecycle'
  | 'Scope 3 Emissions'
  | 'Sustainability Claims'
  | 'Data Health'
  | 'Details'
  | 'Workflow'
export const basics: ColGroup = 'Basics'
const metrics: ColGroup = 'Metrics'
export const carbon: ColGroup = 'Carbon'
export const lifecycle: ColGroup = 'Lifecycle'
export const scope3: ColGroup = 'Scope 3 Emissions'
const sustainability: ColGroup = 'Sustainability Claims'
const dataHealth: ColGroup = 'Data Health'
export const details: ColGroup = 'Details'
export const workflow: ColGroup = 'Workflow'

export type MetricGroupKey =
  | 'impact'
  | 'tier2'
  | 'carbon'
  | 'sdg'
  | 'attribute'
  | 'heatMap'
  | 'dataHealth'
  | 'custom'
  | 'nonMetric'
  | null

export type ProductListType = 'procurement' | 'formulation' | 'vendor' | 'initiative'
type ColMapInstanceEntry = Record<ProductListType, { colGroup: ColGroup | null; defaultSelected?: boolean }>
export interface ColMapInstance extends ColMapInstanceEntry {
  metricGroupKey?: MetricGroupKey // helps determine HOW the metric should be displayed and which coldef to use
  supplierConnect?: boolean // if the column should be shown in supplier connect
}
export type ProductColFields =
  | 'name'
  | 'upc'
  | 'mtPerYear'
  | 'brand'
  | 'workspaceLabel'
  | 'sourcing_locations'
  | 'ingredients'
  | 'reports'
  | 'report_date'
  | 'sharing_requests'
  | 'validation_request'
  | 'assignee'
  | 'statusLabel'
  | 'annual_sales_volume'
  | 'weight_kg'
  | 'link'
  | 'internalId'
  | 'productionLocation'
  | 'pricePerMt'
  | 'packagingDescription'
  | 'buyer'
  | 'certifications'
  | 'locked_claims_timestamp'
  | 'formulationTags'
type FieldColMap = Record<Field | ProductColFields, ColMapInstance>
/**
 * This is the mapping of fields/metrics to column groups and metricGroupKeys
 * This is used to dynamically generate the columns for the product list
 */
export const fieldColMap: FieldColMap = {
  name: {
    procurement: { colGroup: basics, defaultSelected: true },
    formulation: { colGroup: basics, defaultSelected: true },
    metricGroupKey: 'nonMetric',
    vendor: { colGroup: basics, defaultSelected: true },
    initiative: { colGroup: basics, defaultSelected: true },
    supplierConnect: true,
  },
  upc: {
    procurement: { colGroup: basics, defaultSelected: true },
    formulation: { colGroup: basics, defaultSelected: true },
    metricGroupKey: 'nonMetric',
    vendor: { colGroup: basics, defaultSelected: true },
    initiative: { colGroup: basics, defaultSelected: true },
    supplierConnect: true,
  },
  mtPerYear: {
    procurement: { colGroup: basics, defaultSelected: true },
    formulation: { colGroup: null, defaultSelected: false },
    metricGroupKey: 'nonMetric',
    vendor: { colGroup: basics, defaultSelected: false },
    initiative: { colGroup: basics, defaultSelected: false },
  },
  brand: {
    procurement: { colGroup: basics, defaultSelected: false },
    formulation: { colGroup: null, defaultSelected: false },
    metricGroupKey: 'nonMetric',
    vendor: { colGroup: basics, defaultSelected: false },
    initiative: { colGroup: basics, defaultSelected: false },
  },
  workspaceLabel: {
    procurement: { colGroup: basics, defaultSelected: false },
    formulation: { colGroup: basics, defaultSelected: false },
    metricGroupKey: 'nonMetric',
    vendor: { colGroup: basics, defaultSelected: false },
    initiative: { colGroup: basics, defaultSelected: false },
  },
  sourcing_locations: {
    procurement: { colGroup: details, defaultSelected: false },
    formulation: { colGroup: details, defaultSelected: false },
    metricGroupKey: 'nonMetric',
    vendor: { colGroup: details, defaultSelected: false },
    initiative: { colGroup: details, defaultSelected: false },
  },
  ingredients: {
    procurement: { colGroup: details, defaultSelected: false },
    formulation: { colGroup: details, defaultSelected: false },
    metricGroupKey: 'nonMetric',
    vendor: { colGroup: details, defaultSelected: false },
    initiative: { colGroup: details, defaultSelected: false },
  },
  reports: {
    procurement: { colGroup: workflow, defaultSelected: false },
    formulation: { colGroup: workflow, defaultSelected: false },
    metricGroupKey: 'nonMetric',
    vendor: { colGroup: workflow, defaultSelected: false },
    initiative: { colGroup: workflow, defaultSelected: false },
  },
  report_date: {
    procurement: { colGroup: workflow, defaultSelected: false },
    formulation: { colGroup: workflow, defaultSelected: false },
    metricGroupKey: 'nonMetric',
    vendor: { colGroup: workflow, defaultSelected: false },
    initiative: { colGroup: workflow, defaultSelected: false },
  },
  sharing_requests: {
    procurement: { colGroup: null, defaultSelected: false },
    formulation: { colGroup: workflow, defaultSelected: false },
    metricGroupKey: 'nonMetric',
    vendor: { colGroup: workflow, defaultSelected: false },
    initiative: { colGroup: workflow, defaultSelected: false },
    supplierConnect: true,
  },
  validation_request: {
    procurement: { colGroup: workflow, defaultSelected: false },
    formulation: { colGroup: workflow, defaultSelected: false },
    metricGroupKey: 'nonMetric',
    vendor: { colGroup: workflow, defaultSelected: false },
    initiative: { colGroup: workflow, defaultSelected: false },
  },
  assignee: {
    procurement: { colGroup: null, defaultSelected: false },
    formulation: { colGroup: workflow, defaultSelected: false },
    metricGroupKey: 'nonMetric',
    vendor: { colGroup: workflow, defaultSelected: false },
    initiative: { colGroup: workflow, defaultSelected: false },
  },
  statusLabel: {
    procurement: { colGroup: null, defaultSelected: false },
    formulation: { colGroup: workflow, defaultSelected: false },
    metricGroupKey: 'nonMetric',
    vendor: { colGroup: workflow, defaultSelected: false },
    initiative: { colGroup: workflow, defaultSelected: false },
  },
  annual_sales_volume: {
    procurement: { colGroup: null, defaultSelected: false },
    formulation: { colGroup: details, defaultSelected: false },
    metricGroupKey: 'nonMetric',
    vendor: { colGroup: details, defaultSelected: false },
    initiative: { colGroup: details, defaultSelected: false },
  },
  weight_kg: {
    procurement: { colGroup: null, defaultSelected: false },
    formulation: { colGroup: details, defaultSelected: false },
    metricGroupKey: 'nonMetric',
    vendor: { colGroup: details, defaultSelected: false },
    initiative: { colGroup: details, defaultSelected: false },
  },
  link: {
    procurement: { colGroup: null, defaultSelected: false },
    formulation: { colGroup: workflow, defaultSelected: false },
    metricGroupKey: 'nonMetric',
    vendor: { colGroup: workflow, defaultSelected: false },
    initiative: { colGroup: workflow, defaultSelected: false },
  },
  internalId: {
    procurement: { colGroup: basics, defaultSelected: false },
    formulation: { colGroup: basics, defaultSelected: false },
    metricGroupKey: 'nonMetric',
    vendor: { colGroup: basics, defaultSelected: false },
    initiative: { colGroup: basics, defaultSelected: false },
  },
  productionLocation: {
    procurement: { colGroup: details, defaultSelected: false },
    formulation: { colGroup: null, defaultSelected: false },
    metricGroupKey: 'nonMetric',
    vendor: { colGroup: null, defaultSelected: false },
    initiative: { colGroup: null, defaultSelected: false },
  },
  pricePerMt: {
    procurement: { colGroup: details, defaultSelected: false },
    formulation: { colGroup: null, defaultSelected: false },
    metricGroupKey: 'nonMetric',
    vendor: { colGroup: null, defaultSelected: false },
    initiative: { colGroup: null, defaultSelected: false },
  },
  packagingDescription: {
    procurement: { colGroup: null, defaultSelected: false },
    formulation: { colGroup: null, defaultSelected: false },
    metricGroupKey: 'nonMetric',
    vendor: { colGroup: null, defaultSelected: false },
    initiative: { colGroup: null, defaultSelected: false },
  },
  buyer: {
    procurement: { colGroup: details, defaultSelected: false },
    formulation: { colGroup: null, defaultSelected: false },
    metricGroupKey: 'nonMetric',
    vendor: { colGroup: null, defaultSelected: false },
    initiative: { colGroup: null, defaultSelected: false },
  },
  certifications: {
    procurement: { colGroup: details, defaultSelected: false },
    formulation: { colGroup: null, defaultSelected: false },
    metricGroupKey: 'nonMetric',
    vendor: { colGroup: basics, defaultSelected: false },
    initiative: { colGroup: null, defaultSelected: false },
  },
  locked_claims_timestamp: {
    procurement: { colGroup: null, defaultSelected: false },
    formulation: { colGroup: workflow, defaultSelected: false },
    metricGroupKey: 'nonMetric',
    vendor: { colGroup: workflow, defaultSelected: false },
    initiative: { colGroup: workflow, defaultSelected: false },
    supplierConnect: true,
  },
  [FORMULATION_TAGS]: {
    procurement: { colGroup: workflow, defaultSelected: false },
    formulation: { colGroup: workflow, defaultSelected: false },
    metricGroupKey: 'nonMetric',
    vendor: { colGroup: workflow, defaultSelected: false },
    initiative: { colGroup: workflow, defaultSelected: false },
  },
  processing_impact: {
    procurement: { colGroup: metrics, defaultSelected: false },
    formulation: { colGroup: metrics, defaultSelected: false },
    metricGroupKey: 'heatMap',
    vendor: { colGroup: metrics, defaultSelected: false },
    initiative: { colGroup: metrics, defaultSelected: false },
  },
  biodiversity_impact: {
    procurement: { colGroup: metrics, defaultSelected: false },
    formulation: { colGroup: metrics, defaultSelected: false },
    metricGroupKey: 'heatMap',
    vendor: { colGroup: metrics, defaultSelected: false },
    initiative: { colGroup: metrics, defaultSelected: false },
  },
  labor_impact: {
    procurement: { colGroup: metrics, defaultSelected: false },
    formulation: { colGroup: metrics, defaultSelected: false },
    metricGroupKey: 'heatMap',
    vendor: { colGroup: metrics, defaultSelected: false },
    initiative: { colGroup: metrics, defaultSelected: false },
  },
  water_impact: {
    procurement: { colGroup: metrics, defaultSelected: false },
    formulation: { colGroup: metrics, defaultSelected: false },
    metricGroupKey: 'heatMap',
    vendor: { colGroup: metrics, defaultSelected: false },
    initiative: { colGroup: metrics, defaultSelected: false },
  },
  blue_water_impact: {
    procurement: { colGroup: null, defaultSelected: false },
    formulation: { colGroup: metrics, defaultSelected: false },
    metricGroupKey: 'heatMap',
    vendor: { colGroup: metrics, defaultSelected: false },
    initiative: { colGroup: metrics, defaultSelected: false },
  },
  raw_blue_water_usage_impact: {
    procurement: { colGroup: metrics, defaultSelected: false },
    formulation: { colGroup: metrics, defaultSelected: true },
    metricGroupKey: 'heatMap',
    vendor: { colGroup: metrics, defaultSelected: false },
    initiative: { colGroup: metrics, defaultSelected: false },
    supplierConnect: true,
  },
  raw_blue_water_consumption: {
    procurement: { colGroup: metrics, defaultSelected: false },
    formulation: { colGroup: metrics, defaultSelected: false },
    metricGroupKey: 'heatMap',
    vendor: { colGroup: metrics, defaultSelected: false },
    initiative: { colGroup: metrics, defaultSelected: false },
  },
  greenhouse_gas_impact: {
    procurement: { colGroup: metrics, defaultSelected: false },
    formulation: { colGroup: metrics, defaultSelected: false },
    metricGroupKey: 'heatMap',
    vendor: { colGroup: metrics, defaultSelected: false },
    initiative: { colGroup: metrics, defaultSelected: false },
  },
  raw_greenhouse_gas_impact: {
    procurement: { colGroup: metrics, defaultSelected: false },
    formulation: { colGroup: metrics, defaultSelected: false },
    metricGroupKey: 'heatMap',
    vendor: { colGroup: metrics, defaultSelected: false },
    initiative: { colGroup: metrics, defaultSelected: false },
  },
  animal_welfare_impact: {
    procurement: { colGroup: metrics, defaultSelected: false },
    formulation: { colGroup: metrics, defaultSelected: false },
    metricGroupKey: 'heatMap',
    vendor: { colGroup: metrics, defaultSelected: false },
    initiative: { colGroup: metrics, defaultSelected: false },
  },
  animal_welfare_seafood: {
    procurement: { colGroup: null, defaultSelected: false },
    formulation: { colGroup: null, defaultSelected: false },
    vendor: { colGroup: null, defaultSelected: false },
    initiative: { colGroup: null, defaultSelected: false },
    metricGroupKey: undefined,
  },
  soc_impact: {
    procurement: { colGroup: metrics, defaultSelected: false },
    formulation: { colGroup: metrics, defaultSelected: false },
    metricGroupKey: 'heatMap',
    vendor: { colGroup: metrics, defaultSelected: false },
    initiative: { colGroup: metrics, defaultSelected: false },
  },
  raw_soc_impact: {
    procurement: { colGroup: metrics, defaultSelected: false },
    formulation: { colGroup: metrics, defaultSelected: false },
    metricGroupKey: 'heatMap',
    vendor: { colGroup: metrics, defaultSelected: false },
    initiative: { colGroup: metrics, defaultSelected: false },
  },
  working_conditions_impact: {
    procurement: { colGroup: metrics, defaultSelected: false },
    formulation: { colGroup: metrics, defaultSelected: false },
    metricGroupKey: 'heatMap',
    vendor: { colGroup: metrics, defaultSelected: false },
    initiative: { colGroup: metrics, defaultSelected: false },
  },
  planetary_health_percentage_impact: {
    procurement: { colGroup: null, defaultSelected: false },
    formulation: { colGroup: metrics, defaultSelected: false },
    metricGroupKey: 'heatMap',
    vendor: { colGroup: metrics, defaultSelected: false },
    initiative: { colGroup: metrics, defaultSelected: false },
  },
  raw_land_use_impact: {
    procurement: { colGroup: metrics, defaultSelected: false },
    formulation: { colGroup: metrics, defaultSelected: false },
    metricGroupKey: 'heatMap',
    vendor: { colGroup: metrics, defaultSelected: false },
    initiative: { colGroup: metrics, defaultSelected: false },
  },
  land_use_impact: {
    procurement: { colGroup: null, defaultSelected: false },
    formulation: { colGroup: null, defaultSelected: false },
    vendor: { colGroup: null, defaultSelected: false },
    initiative: { colGroup: null, defaultSelected: false },
    metricGroupKey: undefined,
  },
  environmental_impact: {
    procurement: { colGroup: null, defaultSelected: false },
    formulation: { colGroup: metrics, defaultSelected: false },
    metricGroupKey: 'heatMap',
    vendor: { colGroup: metrics, defaultSelected: false },
    initiative: { colGroup: metrics, defaultSelected: false },
  },
  supply_chain_resilience_impact: {
    procurement: { colGroup: null, defaultSelected: false },
    formulation: { colGroup: null, defaultSelected: false },
    metricGroupKey: 'heatMap',
    vendor: { colGroup: null, defaultSelected: false },
    initiative: { colGroup: null, defaultSelected: false },
  },
  processing_level_impact: {
    procurement: { colGroup: null, defaultSelected: false },
    formulation: { colGroup: null, defaultSelected: false },
    metricGroupKey: 'heatMap',
    vendor: { colGroup: null, defaultSelected: false },
    initiative: { colGroup: null, defaultSelected: false },
  },
  animal_diet_impact: {
    procurement: { colGroup: null, defaultSelected: false },
    formulation: { colGroup: null, defaultSelected: false },
    metricGroupKey: 'heatMap',
    vendor: { colGroup: null, defaultSelected: false },
    initiative: { colGroup: null, defaultSelected: false },
  },
  applegate_human_health_impact: {
    procurement: { colGroup: metrics, defaultSelected: false },
    formulation: { colGroup: metrics, defaultSelected: false },
    metricGroupKey: 'heatMap',
    vendor: { colGroup: metrics, defaultSelected: false },
    initiative: { colGroup: metrics, defaultSelected: false },
  },
  applegate_environmental_health_impact: {
    procurement: { colGroup: null, defaultSelected: false },
    formulation: { colGroup: metrics, defaultSelected: false },
    metricGroupKey: 'heatMap',
    vendor: { colGroup: metrics, defaultSelected: false },
    initiative: { colGroup: metrics, defaultSelected: false },
  },
  applegate_animal_health_impact: {
    procurement: { colGroup: metrics, defaultSelected: false },
    formulation: { colGroup: metrics, defaultSelected: false },
    metricGroupKey: 'heatMap',
    vendor: { colGroup: metrics, defaultSelected: false },
    initiative: { colGroup: metrics, defaultSelected: false },
  },
  craveability_impact: {
    procurement: { colGroup: null, defaultSelected: false },
    formulation: { colGroup: metrics, defaultSelected: false },
    metricGroupKey: 'heatMap',
    vendor: { colGroup: metrics, defaultSelected: false },
    initiative: { colGroup: metrics, defaultSelected: false },
  },
  sales_potential_impact: {
    procurement: { colGroup: metrics, defaultSelected: false },
    formulation: { colGroup: metrics, defaultSelected: false },
    metricGroupKey: 'heatMap',
    vendor: { colGroup: metrics, defaultSelected: false },
    initiative: { colGroup: metrics, defaultSelected: false },
  },
  applegate_impact: {
    procurement: { colGroup: metrics, defaultSelected: false },
    formulation: { colGroup: metrics, defaultSelected: true },
    metricGroupKey: 'impact',
    vendor: { colGroup: metrics, defaultSelected: true },
    initiative: { colGroup: metrics, defaultSelected: true },
  },
  craveability_and_sales_potential_impact: {
    procurement: { colGroup: null, defaultSelected: false },
    formulation: { colGroup: metrics, defaultSelected: true },
    metricGroupKey: 'heatMap',
    vendor: { colGroup: metrics, defaultSelected: true },
    initiative: { colGroup: metrics, defaultSelected: true },
  },
  applegate_health_score: {
    procurement: { colGroup: null, defaultSelected: false },
    formulation: { colGroup: metrics, defaultSelected: false },
    metricGroupKey: 'heatMap',
    vendor: { colGroup: metrics, defaultSelected: false },
    initiative: { colGroup: metrics, defaultSelected: false },
  },
  carbon_footprint_farm_to_gate_impact: {
    procurement: { colGroup: carbon, defaultSelected: false },
    formulation: { colGroup: carbon, defaultSelected: false },
    metricGroupKey: 'carbon',
    vendor: { colGroup: carbon, defaultSelected: false },
    initiative: { colGroup: carbon, defaultSelected: false },
  },
  carbon_footprint_annual_impact: {
    procurement: { colGroup: null, defaultSelected: false },
    formulation: { colGroup: carbon, defaultSelected: false },
    metricGroupKey: 'carbon',
    vendor: { colGroup: carbon, defaultSelected: false },
    initiative: { colGroup: carbon, defaultSelected: false },
  },
  cf_non_flag_annual_impact: {
    procurement: { colGroup: null, defaultSelected: false },
    formulation: { colGroup: carbon, defaultSelected: false },
    metricGroupKey: 'carbon',
    vendor: { colGroup: carbon, defaultSelected: false },
    initiative: { colGroup: carbon, defaultSelected: false },
  },
  cf_luc_annual_impact: {
    procurement: { colGroup: null, defaultSelected: false },
    formulation: { colGroup: carbon, defaultSelected: false },
    metricGroupKey: 'carbon',
    vendor: { colGroup: carbon, defaultSelected: false },
    initiative: { colGroup: carbon, defaultSelected: false },
  },
  raw_greenhouse_gas_annual_impact: {
    procurement: { colGroup: null, defaultSelected: false },
    formulation: { colGroup: carbon, defaultSelected: false },
    metricGroupKey: 'carbon',
    vendor: { colGroup: carbon, defaultSelected: false },
    initiative: { colGroup: carbon, defaultSelected: false },
  },
  cf_removals_annual_impact: {
    procurement: { colGroup: null, defaultSelected: false },
    formulation: { colGroup: carbon, defaultSelected: false },
    metricGroupKey: 'carbon',
    vendor: { colGroup: carbon, defaultSelected: false },
    initiative: { colGroup: carbon, defaultSelected: false },
  },
  cf_flag_annual_impact: {
    procurement: { colGroup: null, defaultSelected: false },
    formulation: { colGroup: carbon, defaultSelected: false },
    metricGroupKey: 'carbon',
    vendor: { colGroup: carbon, defaultSelected: false },
    initiative: { colGroup: carbon, defaultSelected: false },
  },
  carbon_footprint_unit_impact: {
    procurement: { colGroup: carbon, defaultSelected: false },
    formulation: { colGroup: carbon, defaultSelected: false },
    metricGroupKey: 'carbon',
    vendor: { colGroup: carbon, defaultSelected: false },
    initiative: { colGroup: carbon, defaultSelected: false },
  },
  eco_score_impact: {
    procurement: { colGroup: null, defaultSelected: false },
    formulation: { colGroup: metrics, defaultSelected: false },
    metricGroupKey: 'tier2',
    vendor: { colGroup: metrics, defaultSelected: false },
    initiative: { colGroup: metrics, defaultSelected: false },
  },
  nutrition_score: {
    procurement: { colGroup: null, defaultSelected: false },
    formulation: { colGroup: sustainability, defaultSelected: false },
    metricGroupKey: 'tier2',
    vendor: { colGroup: sustainability, defaultSelected: false },
    initiative: { colGroup: sustainability, defaultSelected: false },
  },
  cf_total_upstream_transportation_impact: {
    procurement: { colGroup: lifecycle, defaultSelected: false },
    formulation: { colGroup: lifecycle, defaultSelected: false },
    metricGroupKey: 'carbon',
    vendor: { colGroup: lifecycle, defaultSelected: false },
    initiative: { colGroup: lifecycle, defaultSelected: false },
  },
  cf_mtm_transportation_impact: {
    procurement: { colGroup: lifecycle, defaultSelected: false },
    formulation: { colGroup: lifecycle, defaultSelected: false },
    metricGroupKey: 'carbon',
    vendor: { colGroup: lifecycle, defaultSelected: false },
    initiative: { colGroup: lifecycle, defaultSelected: false },
  },
  cf_total_upstream_processing_impact: {
    procurement: { colGroup: lifecycle, defaultSelected: false },
    formulation: { colGroup: lifecycle, defaultSelected: false },
    metricGroupKey: 'carbon',
    vendor: { colGroup: lifecycle, defaultSelected: false },
    initiative: { colGroup: lifecycle, defaultSelected: false },
  },
  cf_manufacturing_impact: {
    procurement: { colGroup: lifecycle, defaultSelected: false },
    formulation: { colGroup: lifecycle, defaultSelected: false },
    metricGroupKey: 'carbon',
    vendor: { colGroup: lifecycle, defaultSelected: false },
    initiative: { colGroup: lifecycle, defaultSelected: false },
  },
  cf_packaging_total_impact: {
    procurement: { colGroup: null, defaultSelected: false },
    formulation: { colGroup: lifecycle, defaultSelected: false },
    metricGroupKey: 'carbon',
    vendor: { colGroup: lifecycle, defaultSelected: false },
    initiative: { colGroup: lifecycle, defaultSelected: false },
  },
  cf_mts_transportation_impact: {
    procurement: { colGroup: null, defaultSelected: false },
    formulation: { colGroup: lifecycle, defaultSelected: false },
    metricGroupKey: 'carbon',
    vendor: { colGroup: lifecycle, defaultSelected: false },
    initiative: { colGroup: lifecycle, defaultSelected: false },
  },
  cf_storage_impact: {
    procurement: { colGroup: null, defaultSelected: false },
    formulation: { colGroup: lifecycle, defaultSelected: false },
    metricGroupKey: 'carbon',
    vendor: { colGroup: lifecycle, defaultSelected: false },
    initiative: { colGroup: lifecycle, defaultSelected: false },
  },
  cf_mtr_transportation_impact: {
    procurement: { colGroup: lifecycle, defaultSelected: false },
    formulation: { colGroup: lifecycle, defaultSelected: false },
    metricGroupKey: 'carbon',
    vendor: { colGroup: lifecycle, defaultSelected: false },
    initiative: { colGroup: lifecycle, defaultSelected: false },
  },
  cf_retail_storage_impact: {
    procurement: { colGroup: null, defaultSelected: false },
    formulation: { colGroup: lifecycle, defaultSelected: false },
    metricGroupKey: 'carbon',
    vendor: { colGroup: lifecycle, defaultSelected: false },
    initiative: { colGroup: lifecycle, defaultSelected: false },
  },
  cf_biogenic_processing_impact: {
    procurement: { colGroup: null, defaultSelected: false },
    formulation: { colGroup: null, defaultSelected: false },
    vendor: { colGroup: null, defaultSelected: false },
    initiative: { colGroup: null, defaultSelected: false },
    metricGroupKey: undefined,
  },
  cf_biogenic_ftm_gate_impact: {
    procurement: { colGroup: carbon, defaultSelected: false },
    formulation: { colGroup: carbon, defaultSelected: false },
    metricGroupKey: 'carbon',
    vendor: { colGroup: carbon, defaultSelected: false },
    initiative: { colGroup: carbon, defaultSelected: false },
  },
  cf_biogenic_cradle_to_shelf_impact: {
    procurement: { colGroup: carbon, defaultSelected: false },
    formulation: { colGroup: carbon, defaultSelected: false },
    metricGroupKey: 'carbon',
    vendor: { colGroup: carbon, defaultSelected: false },
    initiative: { colGroup: carbon, defaultSelected: false },
  },
  cf_consumption_impact: {
    procurement: { colGroup: null, defaultSelected: false },
    formulation: { colGroup: lifecycle, defaultSelected: false },
    metricGroupKey: 'carbon',
    vendor: { colGroup: lifecycle, defaultSelected: false },
    initiative: { colGroup: lifecycle, defaultSelected: false },
  },
  cf_packaging_waste_impact: {
    procurement: { colGroup: null, defaultSelected: false },
    formulation: { colGroup: lifecycle, defaultSelected: false },
    metricGroupKey: 'carbon',
    vendor: { colGroup: lifecycle, defaultSelected: false },
    initiative: { colGroup: lifecycle, defaultSelected: false },
  },
  cf_cradle_to_shelf_impact: {
    procurement: { colGroup: carbon, defaultSelected: false },
    formulation: { colGroup: carbon, defaultSelected: false },
    metricGroupKey: 'carbon',
    vendor: { colGroup: carbon, defaultSelected: false },
    initiative: { colGroup: carbon, defaultSelected: false },
  },
  cf_luc_impact: {
    procurement: { colGroup: carbon, defaultSelected: false },
    formulation: { colGroup: carbon, defaultSelected: false },
    metricGroupKey: 'carbon',
    vendor: { colGroup: carbon, defaultSelected: false },
    initiative: { colGroup: carbon, defaultSelected: false },
  },
  cf_flag_impact: {
    procurement: { colGroup: carbon, defaultSelected: true },
    formulation: { colGroup: carbon, defaultSelected: true },
    metricGroupKey: 'carbon',
    vendor: { colGroup: carbon, defaultSelected: true },
    initiative: { colGroup: carbon, defaultSelected: true },
  },
  [SCOPE_3_FLAG_FIELD]: {
    procurement: { colGroup: lifecycle, defaultSelected: true },
    formulation: { colGroup: lifecycle, defaultSelected: false },
    metricGroupKey: 'carbon',
    vendor: { colGroup: lifecycle, defaultSelected: true },
    initiative: { colGroup: lifecycle, defaultSelected: true },
  },
  cf_removals_impact: {
    procurement: { colGroup: carbon, defaultSelected: false },
    formulation: { colGroup: carbon, defaultSelected: false },
    metricGroupKey: 'carbon',
    vendor: { colGroup: carbon, defaultSelected: false },
    initiative: { colGroup: carbon, defaultSelected: false },
  },
  cf_full_lifecycle_annual_impact: {
    procurement: { colGroup: null, defaultSelected: false },
    formulation: { colGroup: carbon, defaultSelected: false },
    metricGroupKey: 'carbon',
    vendor: { colGroup: carbon, defaultSelected: false },
    initiative: { colGroup: carbon, defaultSelected: false },
  },
  cf_ftm_gate_annual_impact: {
    procurement: { colGroup: null, defaultSelected: false },
    formulation: { colGroup: carbon, defaultSelected: true },
    metricGroupKey: 'carbon',
    vendor: { colGroup: carbon, defaultSelected: true },
    initiative: { colGroup: carbon, defaultSelected: true },
  },
  cf_processing_impact: {
    procurement: { colGroup: metrics, defaultSelected: false },
    formulation: { colGroup: metrics, defaultSelected: false },
    metricGroupKey: 'heatMap',
    vendor: { colGroup: metrics, defaultSelected: false },
    initiative: { colGroup: metrics, defaultSelected: false },
  },
  supplier_cf_ftm_gate_impact: {
    procurement: { colGroup: carbon, defaultSelected: false },
    formulation: { colGroup: carbon, defaultSelected: false },
    metricGroupKey: 'carbon',
    vendor: { colGroup: carbon, defaultSelected: false },
    initiative: { colGroup: carbon, defaultSelected: false },
  },
  cpg_cf_f2mg_w_pkg_impact: {
    procurement: { colGroup: carbon, defaultSelected: false },
    formulation: { colGroup: carbon, defaultSelected: false },
    metricGroupKey: 'carbon',
    vendor: { colGroup: carbon, defaultSelected: false },
    initiative: { colGroup: carbon, defaultSelected: false },
  },
  default_origin_percent_impact: {
    procurement: { colGroup: null, defaultSelected: false },
    formulation: { colGroup: dataHealth, defaultSelected: false },
    metricGroupKey: 'dataHealth',
    vendor: { colGroup: dataHealth, defaultSelected: false },
    initiative: { colGroup: dataHealth, defaultSelected: false },
  },
  is_min_processed: {
    procurement: { colGroup: sustainability, defaultSelected: false },
    formulation: { colGroup: sustainability, defaultSelected: false },
    metricGroupKey: 'attribute',
    vendor: { colGroup: sustainability, defaultSelected: false },
    initiative: { colGroup: sustainability, defaultSelected: false },
  },
  is_climate_friendly: {
    procurement: { colGroup: sustainability, defaultSelected: false },
    formulation: { colGroup: sustainability, defaultSelected: true },
    metricGroupKey: 'attribute',
    vendor: { colGroup: sustainability, defaultSelected: true },
    initiative: { colGroup: sustainability, defaultSelected: true },
  },
  is_transparent_company: {
    procurement: { colGroup: null, defaultSelected: false },
    formulation: { colGroup: sustainability, defaultSelected: false },
    metricGroupKey: 'attribute',
    vendor: { colGroup: sustainability, defaultSelected: false },
    initiative: { colGroup: sustainability, defaultSelected: false },
  },
  is_clean_label: {
    procurement: { colGroup: sustainability, defaultSelected: false },
    formulation: { colGroup: sustainability, defaultSelected: false },
    metricGroupKey: 'attribute',
    vendor: { colGroup: sustainability, defaultSelected: false },
    initiative: { colGroup: sustainability, defaultSelected: false },
  },
  is_water_smart: {
    procurement: { colGroup: sustainability, defaultSelected: false },
    formulation: { colGroup: sustainability, defaultSelected: false },
    metricGroupKey: 'attribute',
    vendor: { colGroup: sustainability, defaultSelected: false },
    initiative: { colGroup: sustainability, defaultSelected: false },
  },
  is_fair_labor: {
    procurement: { colGroup: null, defaultSelected: false },
    formulation: { colGroup: sustainability, defaultSelected: false },
    metricGroupKey: 'attribute',
    vendor: { colGroup: sustainability, defaultSelected: false },
    initiative: { colGroup: sustainability, defaultSelected: false },
  },
  no_synthetic_input: {
    procurement: { colGroup: sustainability, defaultSelected: false },
    formulation: { colGroup: sustainability, defaultSelected: false },
    metricGroupKey: 'attribute',
    vendor: { colGroup: sustainability, defaultSelected: false },
    initiative: { colGroup: sustainability, defaultSelected: false },
  },
  howgood_total_impact: {
    procurement: { colGroup: metrics, defaultSelected: false },
    formulation: { colGroup: metrics, defaultSelected: false },
    metricGroupKey: 'impact',
    vendor: { colGroup: metrics, defaultSelected: false },
    initiative: { colGroup: metrics, defaultSelected: false },
  },
  howgood_one_metric: {
    procurement: { colGroup: basics, defaultSelected: true },
    formulation: { colGroup: basics, defaultSelected: true },
    metricGroupKey: 'impact',
    vendor: { colGroup: basics, defaultSelected: true },
    initiative: { colGroup: basics, defaultSelected: true },
  },
  starbucks_impact: {
    procurement: { colGroup: basics, defaultSelected: true },
    formulation: { colGroup: basics, defaultSelected: true },
    metricGroupKey: 'impact',
    vendor: { colGroup: basics, defaultSelected: true },
    initiative: { colGroup: basics, defaultSelected: true },
  },
  raw_ftmgate_water_withdrawal_impact: {
    procurement: { colGroup: metrics, defaultSelected: true },
    formulation: { colGroup: metrics, defaultSelected: true },
    metricGroupKey: 'heatMap',
    vendor: { colGroup: metrics, defaultSelected: true },
    initiative: { colGroup: metrics, defaultSelected: true },
  },
  hive_five_impact: {
    procurement: { colGroup: null, defaultSelected: false },
    formulation: { colGroup: metrics, defaultSelected: true },
    metricGroupKey: 'impact',
    vendor: { colGroup: metrics, defaultSelected: true },
    initiative: { colGroup: metrics, defaultSelected: true },
  },
  cf_ftm_gate_ct_verified_impact: {
    procurement: { colGroup: carbon, defaultSelected: true },
    formulation: { colGroup: carbon, defaultSelected: true },
    metricGroupKey: 'carbon',
    vendor: { colGroup: carbon, defaultSelected: true },
    initiative: { colGroup: carbon, defaultSelected: true },
    supplierConnect: true,
  },
  cf_cradle_to_shelf_ct_verified_impact: {
    procurement: { colGroup: carbon, defaultSelected: false },
    formulation: { colGroup: carbon, defaultSelected: false },
    metricGroupKey: 'carbon',
    vendor: { colGroup: carbon, defaultSelected: false },
    initiative: { colGroup: carbon, defaultSelected: false },
  },
  cf_full_lifecycle_impact: {
    procurement: { colGroup: carbon, defaultSelected: false },
    formulation: { colGroup: carbon, defaultSelected: false },
    metricGroupKey: 'carbon',
    vendor: { colGroup: carbon, defaultSelected: false },
    initiative: { colGroup: carbon, defaultSelected: false },
  },
  business_potential_impact: {
    procurement: { colGroup: null, defaultSelected: false },
    formulation: { colGroup: metrics, defaultSelected: false },
    metricGroupKey: 'tier2',
    vendor: { colGroup: metrics, defaultSelected: false },
    initiative: { colGroup: metrics, defaultSelected: false },
  },
  carbon_footprint_on_farm_processing_ghgs_impact: {
    procurement: { colGroup: lifecycle, defaultSelected: false },
    formulation: { colGroup: lifecycle, defaultSelected: false },
    metricGroupKey: 'carbon',
    vendor: { colGroup: lifecycle, defaultSelected: false },
    initiative: { colGroup: lifecycle, defaultSelected: false },
  },
  has_default_weights: {
    procurement: { colGroup: null, defaultSelected: false },
    formulation: { colGroup: dataHealth, defaultSelected: false },
    metricGroupKey: 'dataHealth',
    vendor: { colGroup: dataHealth, defaultSelected: false },
    initiative: { colGroup: dataHealth, defaultSelected: false },
  },
  date_modified: {
    procurement: { colGroup: null, defaultSelected: false },
    formulation: { colGroup: dataHealth, defaultSelected: false },
    metricGroupKey: 'dataHealth',
    vendor: { colGroup: dataHealth, defaultSelected: false },
    initiative: { colGroup: dataHealth, defaultSelected: false },
  },
  nutrition_total_energy: {
    procurement: { colGroup: null, defaultSelected: false },
    formulation: { colGroup: null, defaultSelected: false },
    vendor: { colGroup: null, defaultSelected: false },
    initiative: { colGroup: null, defaultSelected: false },
    metricGroupKey: undefined,
  },
  nutrition_total_proteins: {
    procurement: { colGroup: null, defaultSelected: false },
    formulation: { colGroup: null, defaultSelected: false },
    vendor: { colGroup: null, defaultSelected: false },
    initiative: { colGroup: null, defaultSelected: false },
    metricGroupKey: undefined,
  },
  nutrition_total_saturated_fat_acid: {
    procurement: { colGroup: null, defaultSelected: false },
    formulation: { colGroup: null, defaultSelected: false },
    vendor: { colGroup: null, defaultSelected: false },
    initiative: { colGroup: null, defaultSelected: false },
    metricGroupKey: undefined,
  },
  nutrition_total_sugars: {
    procurement: { colGroup: null, defaultSelected: false },
    formulation: { colGroup: null, defaultSelected: false },
    vendor: { colGroup: null, defaultSelected: false },
    initiative: { colGroup: null, defaultSelected: false },
    metricGroupKey: undefined,
  },
  nutrition_total_sodium: {
    procurement: { colGroup: null, defaultSelected: false },
    formulation: { colGroup: null, defaultSelected: false },
    vendor: { colGroup: null, defaultSelected: false },
    initiative: { colGroup: null, defaultSelected: false },
    metricGroupKey: undefined,
  },
  nutrition_total_sodium_chloride: {
    procurement: { colGroup: null, defaultSelected: false },
    formulation: { colGroup: null, defaultSelected: false },
    vendor: { colGroup: null, defaultSelected: false },
    initiative: { colGroup: null, defaultSelected: false },
    metricGroupKey: undefined,
  },
  retail_rating_impact: {
    procurement: { colGroup: sustainability, defaultSelected: false },
    formulation: { colGroup: sustainability, defaultSelected: false },
    metricGroupKey: 'tier2',
    vendor: { colGroup: sustainability, defaultSelected: false },
    initiative: { colGroup: sustainability, defaultSelected: false },
  },
  deforestation_impact: {
    procurement: { colGroup: null, defaultSelected: false },
    formulation: { colGroup: metrics, defaultSelected: false },
    metricGroupKey: 'heatMap',
    vendor: { colGroup: metrics, defaultSelected: false },
    initiative: { colGroup: metrics, defaultSelected: false },
  },
  [SCOPE_3_CATEGORY_1_FIELD]: {
    procurement: { colGroup: scope3, defaultSelected: true },
    formulation: { colGroup: null, defaultSelected: false },
    metricGroupKey: 'carbon',
    vendor: { colGroup: scope3, defaultSelected: true },
    initiative: { colGroup: scope3, defaultSelected: true },
  },
  [SCOPE_3_CATEGORY_4_FIELD]: {
    procurement: { colGroup: scope3, defaultSelected: true },
    formulation: { colGroup: null, defaultSelected: false },
    metricGroupKey: 'carbon',
    vendor: { colGroup: scope3, defaultSelected: true },
    initiative: { colGroup: scope3, defaultSelected: true },
  },
  [SCOPE_3_CONTRIBUTION_FIELD]: {
    procurement: { colGroup: scope3, defaultSelected: true },
    formulation: { colGroup: null, defaultSelected: false },
    metricGroupKey: 'carbon',
    vendor: { colGroup: scope3, defaultSelected: true },
    initiative: { colGroup: scope3, defaultSelected: true },
  },
  sdg_6_4_impact: {
    procurement: { colGroup: metrics, defaultSelected: false },
    formulation: { colGroup: metrics, defaultSelected: false },
    metricGroupKey: 'sdg',
    vendor: { colGroup: metrics, defaultSelected: false },
    initiative: { colGroup: metrics, defaultSelected: false },
  },
  sdg_8_7_impact: {
    procurement: { colGroup: metrics, defaultSelected: false },
    formulation: { colGroup: metrics, defaultSelected: false },
    metricGroupKey: 'sdg',
    vendor: { colGroup: metrics, defaultSelected: false },
    initiative: { colGroup: metrics, defaultSelected: false },
  },
  sdg_8_8_impact: {
    procurement: { colGroup: metrics, defaultSelected: false },
    formulation: { colGroup: metrics, defaultSelected: false },
    metricGroupKey: 'sdg',
    vendor: { colGroup: metrics, defaultSelected: false },
    initiative: { colGroup: metrics, defaultSelected: false },
  },
  sdg_12_2_impact: {
    procurement: { colGroup: metrics, defaultSelected: false },
    formulation: { colGroup: metrics, defaultSelected: false },
    metricGroupKey: 'sdg',
    vendor: { colGroup: metrics, defaultSelected: false },
    initiative: { colGroup: metrics, defaultSelected: false },
  },
  sdg_13_2_impact: {
    procurement: { colGroup: metrics, defaultSelected: false },
    formulation: { colGroup: metrics, defaultSelected: false },
    metricGroupKey: 'sdg',
    vendor: { colGroup: metrics, defaultSelected: false },
    initiative: { colGroup: metrics, defaultSelected: false },
  },
  sdg_15_9_impact: {
    procurement: { colGroup: metrics, defaultSelected: false },
    formulation: { colGroup: metrics, defaultSelected: false },
    metricGroupKey: 'sdg',
    vendor: { colGroup: metrics, defaultSelected: false },
    initiative: { colGroup: metrics, defaultSelected: false },
  },
  raw_on_farm_scarcity_adjusted_water_withdrawal_impact: {
    procurement: { colGroup: null, defaultSelected: false },
    formulation: { colGroup: null, defaultSelected: false },
    vendor: { colGroup: null, defaultSelected: false },
    initiative: { colGroup: null, defaultSelected: false },
    metricGroupKey: undefined,
  },
  raw_processing_ghg_impact: {
    // ingredient level metric
    procurement: { colGroup: null, defaultSelected: false },
    formulation: { colGroup: null, defaultSelected: false },
    vendor: { colGroup: null, defaultSelected: false },
    initiative: { colGroup: null, defaultSelected: false },
    metricGroupKey: undefined,
  },
}

export const getScope3ColGroupFields = (): string[] =>
  Object.entries(fieldColMap)
    .filter(([_key, value]) => {
      return value.procurement.colGroup === scope3
    })
    .map(([key, _value]) => key)
