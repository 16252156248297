import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MenuItem, Select, SelectChangeEvent } from '@howgood/design'
import { selectDisplayedSalesAndDistribution, updateRecipeAndScores } from '@/state/recipe'
import { DRAFT, selectPageView } from '@/state/pageSettings'
import { GridItemTitle } from '../GridItemTitle'

export const RequiresCooking: FC = () => {
  const dispatch = useDispatch()
  const salesAndDistribution = useSelector(selectDisplayedSalesAndDistribution)
  const view = useSelector(selectPageView)

  const handleRequiresCookingChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value === 'true' ? true : false
    dispatch(
      updateRecipeAndScores({
        recipeUpdates: {
          sales_distribution: {
            ...salesAndDistribution,
            requires_cooking: value,
          },
        },
        change: `Changed requires_cooking to ${value}`,
      })
    )
  }

  return (
    <>
      <GridItemTitle title="Requires Cooking" tooltipText="Required for cradle-to-grave carbon metrics" />
      <Select
        id="requires-cooking-select"
        disabled={view !== DRAFT}
        value={salesAndDistribution.requires_cooking?.toString() || 'false'}
        onChange={handleRequiresCookingChange}
        MenuProps={{ id: 'requires-cooking-menu' }}
        fullWidth
      >
        <MenuItem value="true">Yes</MenuItem>
        <MenuItem value="false">No</MenuItem>
      </Select>
    </>
  )
}
