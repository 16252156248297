import React, { FC, useMemo } from 'react'
import { DataGridPremium, GridColDef, GridRowsProp } from '@mui/x-data-grid-premium'
import { Tooltip, Text, Stack, Box } from '@howgood/design'
import { ProductListRenderCellParams } from './ProductGridTypes'

/**
 * Renderer for both the Ingredients and Sourcing Locations columns
 * Using the same renderer allows them to share the same tooltip
 */

export const IngredientAndSourceRenderer: FC<ProductListRenderCellParams> = ({ row, colDef, id }) => {
  const { es_ingredients } = row

  const isSourcingLocations = colDef.field === 'sourcing_locations'

  const columns: GridColDef[] = [
    {
      field: 'name',
      type: 'string',
      headerName: 'Ingredient',
      width: 200,
      sortable: false,
      renderCell: (params) => (
        <Text data-testid={`name-${params.value.id}`} variant="body2" overflow="hidden" textOverflow="ellipsis">
          {params.value?.name || ''}
        </Text>
      ),
    },
    {
      field: 'source',
      type: 'string',
      headerName: 'Source',
      width: 200,
      sortable: false,
      renderCell: (params) => (
        <Text data-testid={`source-${params.value.id}`} variant="body2" overflow="hidden" textOverflow="ellipsis">
          {params.value?.ingredient_origin_location_region_name || ''}
        </Text>
      ),
    },
  ]

  const rows: GridRowsProp = useMemo(
    () =>
      es_ingredients.map((ingredient, index) => ({
        id: index,
        name: ingredient,
        source: ingredient,
      })),
    [es_ingredients]
  )

  return (
    <Stack height="100%" width="100%" justifyContent="center">
      <Tooltip
        data-testid={`${colDef.field}-tooltip-${id}`}
        maxWidth={450}
        title={
          <Box maxHeight={500} width={410} overflow="auto">
            <DataGridPremium
              initialState={{ density: 'compact' }}
              autoHeight
              hideFooter
              rows={rows}
              columns={columns}
              disableColumnMenu
            />
          </Box>
        }
      >
        <Text
          overflow="hidden"
          data-testid={`${colDef.field}-cell-${id}`}
          textOverflow="ellipsis"
          variant="body2"
          width="100%"
        >
          {es_ingredients
            .map((ingredient) =>
              isSourcingLocations ? ingredient.ingredient_origin_location_region_name || '-- ' : ingredient.name
            )
            .join(', ')}
        </Text>
      </Tooltip>
    </Stack>
  )
}
