import React, { FC, useMemo } from 'react'
import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from 'recharts'
import { Stack, theme } from '@howgood/design'
import { VendorEngagementLegend } from './VendorEngagementBreakdownChartLegendContent'

export type VendorEngagementBreakdownChartData = { name: string; value: number; pct: number | string }[] // prc can be '< 1'

interface VendorEngagementBreakdownChartProps {
  data: VendorEngagementBreakdownChartData
}

const chartColors = [theme.palette.impact[7], theme.palette.impact[5], theme.palette.impact[1]]

export const VendorEngagementBreakdownChart: FC<VendorEngagementBreakdownChartProps> = ({ data }) => {
  const chartData = useMemo(
    () => data.map((item, idx) => ({ ...item, color: chartColors[idx % chartColors.length] })),
    [data]
  )

  return (
    <Stack flexGrow={1}>
      <ResponsiveContainer width="100%" height="95%">
        <PieChart>
          <Pie cx="50%" dataKey="value" isAnimationActive={false} data={chartData} outerRadius="100%" innerRadius="70%">
            {chartData.map((entry) => (
              <Cell key={`cell-${entry.name}`} fill={entry.color} />
            ))}
          </Pie>
          <Legend
            layout="vertical"
            verticalAlign="middle"
            width={120}
            align="right"
            content={(props) => <VendorEngagementLegend {...props} data={data} />}
          />
        </PieChart>
      </ResponsiveContainer>
    </Stack>
  )
}
