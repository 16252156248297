import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { formatNumber } from '@howgood/utils'
import { selectDisplayedProduct } from '@/state/productOverview'
import { coldStorageOptions } from '@/records'
import { selectIsProcurement } from '@/state/router'

export const SalesAndDistribution: FC = () => {
  const product = useSelector(selectDisplayedProduct)
  const isProcurement = useSelector(selectIsProcurement)

  const { region, retail_region, storage_region, manufacturing_type, requires_cold_storage, requires_cooking } = product

  const data = {
    'Unit Size': +product.weight_kg ? `${formatNumber(product.weight_kg)}kg` : '',
    'Manufacturing Type': manufacturing_type?.title,
    'Manufacturing Location': region?.name,
    [`${isProcurement ? 'Material' : 'Product'} Destination Region`]: retail_region?.name,
    'Warehouse region': storage_region?.name,
    'Requires Cooking?': requires_cooking ? 'Yes' : 'No',
    'Requires Cold Storage?': coldStorageOptions[requires_cold_storage] ?? '',
  }
  return (
    <>
      <h3>Sales & Distribution</h3>
      <table>
        <tbody>
          {Object.entries(data).map(([key, value]) => (
            <tr key={key}>
              <td style={{ fontWeight: 'bold', width: '50%' }}>{key}</td>
              <td>{value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
}
