import React, { FC, useState } from 'react'
import { Stepper, StepButton, Step, Alert, Button, Stack, Text } from '@howgood/design'
import { StepPanel } from './StepPanel'
import { agPracticesSchema } from './agPracticesSchema'

interface AgPracticesStepperProps {
  closeDrawer: () => void
}

export const AgPracticesStepper: FC<AgPracticesStepperProps> = ({ closeDrawer }) => {
  const [activeStep, setActiveStep] = useState(0)
  const [completed, setCompleted] = useState<{ [k: number]: boolean }>({})

  const steps = agPracticesSchema.children

  const isLastStep = () => activeStep === steps.length - 1
  const allStepsCompleted = () => Object.keys(completed).length === steps.length

  const handleNext = () => {
    if (!isLastStep()) {
      setActiveStep(activeStep + 1)
    } else {
      if (!allStepsCompleted()) {
        // Go to the first uncompleted step
        const nextStep = steps.findIndex((_step, i) => !(i in completed))
        setActiveStep(nextStep)
      }
    }
  }

  const handleComplete = () => {
    setCompleted({ ...completed, [activeStep]: true })
    handleNext()
  }

  const handleSubmit = () => {
    closeDrawer()
  }

  return (
    <Stack id="stepper-container" maxHeight="calc(100% - 64px)" gap={2}>
      <Stepper id="ag-practices-stepper" nonLinear activeStep={activeStep}>
        {steps.map((step, index) => (
          <Step key={step.id} id={`ag-practices-step-${index}`} completed={completed[index]}>
            <StepButton id={`ag-practices-step-button-${index}`} onClick={() => setActiveStep(index)}>
              {step.name}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <Stack id="step-panel-container" overflow="auto">
        <StepPanel step={steps[activeStep]} index={activeStep} key={activeStep} handleComplete={handleComplete} />
      </Stack>
      {allStepsCompleted() && (
        <Alert severity="success">
          <Text fontWeight={600}>Your data is ready for submission</Text>
        </Alert>
      )}
      <Stack id="stepper-nav-buttons" direction="row" gap={1}>
        <Button disabled={activeStep === 0} onClick={() => setActiveStep(activeStep - 1)}>
          Previous
        </Button>
        <Stack flex={1} />
        {!isLastStep() && (
          <Button color={allStepsCompleted() ? 'secondary' : 'primary'} onClick={handleNext}>
            Next
          </Button>
        )}
        {(isLastStep() || allStepsCompleted()) && (
          <Button color="primary" onClick={handleSubmit} disabled={!allStepsCompleted()}>
            Submit
          </Button>
        )}
      </Stack>
    </Stack>
  )
}
