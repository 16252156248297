import React, { FC, useCallback, useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { selectUserEmail, selectUserAuthOrganization, exchangeToken } from '@/state/user'
import { addMessage } from '@/state/messages'
import { useDispatch, useSelector } from 'react-redux'
import { usePendoTrack } from '@/hooks'
import { CircularProgress, Stack, Text } from '@howgood/design'

export const Auth0Form: FC = () => {
  const pendoTrack = usePendoTrack()
  const storeEmail = useSelector(selectUserEmail)
  const organization = useSelector(selectUserAuthOrganization)
  const [tokenError, setTokenError] = React.useState<string>('')
  const dispatch = useDispatch()

  const { loginWithPopup, isAuthenticated: isAuth0Authenticated, error: auth0Error, getIdTokenClaims } = useAuth0()

  useEffect(() => {
    pendoTrack('Login: Auth0')
    loginWithPopup({ loginHint: storeEmail, organization: organization })
  }, [loginWithPopup, storeEmail, organization, pendoTrack])

  const authenticateToken = useCallback(async () => {
    if (isAuth0Authenticated) {
      try {
        const token = await getIdTokenClaims()
        // @ts-ignore
        await dispatch(exchangeToken({ token: token.__raw, username: storeEmail })).unwrap()
      } catch (e) {
        console.error(e)
        setTokenError(e?.message || 'There was an error getting or exchanging the token')
      }
    }
  }, [isAuth0Authenticated, getIdTokenClaims, dispatch, storeEmail])

  useEffect(() => {
    authenticateToken()
  }, [authenticateToken])

  useEffect(() => {
    if (auth0Error) {
      console.error(auth0Error)
      if (auth0Error.message.startsWith('Unable to open a popup')) {
        dispatch(addMessage({ message: 'Please enable popups for this site and try again', severity: 'error' }))
      }
    }
    if (tokenError) {
      dispatch(addMessage({ message: tokenError, severity: 'error' }))
    }
  }, [auth0Error, tokenError, dispatch])

  return (
    <Stack height="calc(100vh - 200px)">
      {!auth0Error && !tokenError && (
        <Stack direction="row" gap={2}>
          <CircularProgress size={20} />
          <Text>Signing on using your identity provider</Text>
        </Stack>
      )}
      {(auth0Error || tokenError) && (
        <Stack gap={2}>
          <Text>{auth0Error?.message || tokenError}</Text>
          <Text>Click the browser&apos;s Refresh button to try again</Text>
        </Stack>
      )}
    </Stack>
  )
}
