import React, { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Text } from '@howgood/design'
import { OrgUser, selectUsersSortedByEmail } from '@/state/organization'
import { SelectUserDialog } from '@/components/Shared/SelectUserDialog'
import { SelectAssignee } from '@/components/Shared/SelectAssignee'
import { ProductListRenderCellParams } from './ProductGridTypes'
import { selectEditableWorkspaceIds } from '@/state/workspaces'
import { updateAssignee } from '@/state/products'

interface Props extends ProductListRenderCellParams {
  value: OrgUser
}

export const AssigneeRenderer: FC<Props> = ({ value, row, id }) => {
  const dispatch = useDispatch()
  const [dialogOpen, setDialogOpen] = useState(false)
  const allOrgUsers = useSelector(selectUsersSortedByEmail)
  const editableWorkspaces = useSelector(selectEditableWorkspaceIds)

  const currentAssignee = allOrgUsers.find((user) => user.id === value?.id) || null
  const assigneeDisplayName = currentAssignee?.first_name
    ? `${currentAssignee.first_name} ${currentAssignee.last_name || ''}`
    : currentAssignee?.email

  const setAssignee = (user: OrgUser) => {
    dispatch(updateAssignee({ id, data: { assignee: user } }))
  }

  if (!row.workspaces.some((ws) => editableWorkspaces.includes(ws.id))) {
    return assigneeDisplayName ? <Text>{assigneeDisplayName}</Text> : null
  }
  return (
    <>
      <SelectAssignee
        dataTestId={`assignee-cell-${id}`}
        assignWhat="product"
        currentAssignee={currentAssignee}
        setDialogOpen={setDialogOpen}
      />
      {dialogOpen && (
        <SelectUserDialog
          allUsers={allOrgUsers}
          selectedUser={currentAssignee}
          message="Select the person in your organization responsible for this product."
          setUser={setAssignee}
          closeDialog={() => setDialogOpen(false)}
        />
      )}
    </>
  )
}
