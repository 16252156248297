import React, { FC, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useGridApiRef } from '@mui/x-data-grid-premium'
import { faChartBar, faTable } from '@fortawesome/pro-regular-svg-icons'
import { toPng } from 'html-to-image'
import { CircularProgress, Stack, Text, Button } from '@howgood/design'

import { updateDashboardSettings } from '@/state/user'
import { addMessage } from '@/state/messages'
import { Toolbar } from './Toolbar/Toolbar'
import {
  fetchTags,
  selectAggregations,
  selectBasis,
  selectChartType,
  selectIsLoading,
  selectSelectedMetric,
  selectView,
  initializeDashboard,
} from './state/portfolioDashboard'
import { ChartWrapper } from './Charts/ChartWrapper'

const height = 300

export const PortfolioDashboard: FC = () => {
  const dispatch = useDispatch()
  const gridApiRef = useGridApiRef()
  const chartRef = useRef<HTMLElement>(null)
  const aggregations = useSelector(selectAggregations)
  const chartType = useSelector(selectChartType)
  const view = useSelector(selectView)
  const basis = useSelector(selectBasis)
  const selectedMetric = useSelector(selectSelectedMetric)
  const isLoading = useSelector(selectIsLoading)

  function handleExportDataClicked() {
    if (gridApiRef.current) {
      gridApiRef.current.exportDataAsCsv()
    }
  }

  function handleExportChartClicked() {
    if (chartRef.current) {
      toPng(chartRef.current, { cacheBust: true, backgroundColor: 'white' })
        .then((dataUrl) => {
          const link = document.createElement('a')
          link.download = 'PortfolioImpact.png'
          link.href = dataUrl
          link.click()
        })
        .catch((err) => {
          dispatch(addMessage({ severity: 'error', message: err }))
        })
    }
  }

  useEffect(() => {
    if (!aggregations) {
      dispatch(initializeDashboard())
      dispatch(fetchTags())
    }
  }, [dispatch, aggregations])

  // This saves the user's dashboard settings when they change the view, basis, metric, or chart type
  useEffect(() => {
    dispatch(updateDashboardSettings())
  }, [dispatch, view, basis, selectedMetric, chartType])

  return (
    <Stack mt={2} gap={1.5} pb={1} sx={{ pointerEvents: isLoading ? 'none' : 'default', opacity: isLoading ? 0.7 : 1 }}>
      <Toolbar />
      {isLoading && (
        <Stack direction="row" alignItems="center" gap={1} width="100%" justifyContent="center" mt={8}>
          <CircularProgress size={20} />
          <Text>Analyzing your portfolio, please wait...</Text>
        </Stack>
      )}
      {!aggregations && !isLoading && (
        <Stack alignItems="center" gap={1} width="100%" justifyContent="center" mt={8}>
          <Text>Error loading portfolio data.</Text>
          <Text>Please refresh the page, or contact support@howgood.com if the problem persists.</Text>
        </Stack>
      )}
      {aggregations && !isLoading && (
        <>
          <ChartWrapper height={height} gridApiRef={gridApiRef} chartRef={chartRef} />
          <Stack width="100%" flexDirection="row" gap={1} justifyContent="flex-end">
            <Button
              variant="contained"
              color="secondary"
              size="small"
              startIcon={faChartBar}
              onClick={handleExportChartClicked}
            >
              Download chart
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={faTable}
              onClick={handleExportDataClicked}
            >
              Download data
            </Button>
          </Stack>
        </>
      )}
    </Stack>
  )
}
