import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LegacyDialog } from '@howgood/design'
import { useNavigate } from '@/hooks/useNavigate'
import { closeUnsavedChangesPrompt, SAVED, selectUnsavedPromptDestination, setView } from '@/state/pageSettings'
import { clearProductFormula } from '@/thunks'

export const UnsavedChanges: FC = () => {
  const dispatch = useDispatch()
  const destination = useSelector(selectUnsavedPromptDestination)

  const { navigateTo } = useNavigate()

  const handleExitWithoutSaving = () => {
    dispatch(clearProductFormula())
    dispatch(closeUnsavedChangesPrompt())
    dispatch(setView(SAVED))
    navigateTo(destination)
  }

  const cancel = () => {
    dispatch(closeUnsavedChangesPrompt())
  }

  return (
    <LegacyDialog
      title="Exit without saving?"
      open={!!destination}
      primaryButtonText="Yes, exit without saving"
      primaryButtonAction={handleExitWithoutSaving}
      primaryButtonColor="error"
      secondaryButtonText="No, go back"
      secondaryButtonAction={cancel}
      buttonOrientation="row"
      onClose={cancel}
    >
      This formula has unsaved edits. Are you sure you want to exit without saving your changes?
    </LegacyDialog>
  )
}
