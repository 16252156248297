import useSWR from 'swr'
import { useDispatch, useSelector } from 'react-redux'
import { ImpactScore, fetchElasticV2 } from '@/api'
import { FORMULATIONS_SEARCH_PATH } from '@/constants/config'
import { FetchV2PaginatedResponse, fetchHelperV2 } from '@/api'
import { InitiativeBasic, Scenario } from '@/records/Initiatives'
import { Field } from '@/constants/impactScore'
import { addMessage } from '../messages'
import { estypes } from '@elastic/elasticsearch'
import { ProductsBoolQuery } from '@/api/elastic/productsEsRepo'
import { selectInitiativeWorkspaces, selectInitiativeBrands } from './initiatives.selectors'
import { selectProductsAggregation, selectProductsRuntimeMappings } from '@/selectors/selectProductsRequestItems'

export const fetchInitiatives = async (): Promise<InitiativeBasic[]> => {
  const initiativesResponse = await fetchHelperV2<FetchV2PaginatedResponse<InitiativeBasic>>({
    url: 'initiatives/?size=100',
  })
  return initiativesResponse?.items || []
}

export const fetchInitiativeDetail = async (initiativeId: number): Promise<InitiativeBasic> => {
  return await fetchHelperV2<InitiativeBasic>({
    url: `initiatives/${initiativeId}/`,
  })
}

export const fetchDeleteInitiative = async (initiativeId: number): Promise<string> => {
  return await fetchHelperV2<string>({
    method: 'DELETE',
    parse: false,
    url: `initiatives/${initiativeId}/`,
  })
}

export const fetchScenarios = async (initiativeId?: number): Promise<Scenario[]> => {
  const scenariosResponse = await fetchHelperV2<FetchV2PaginatedResponse<Scenario>>({
    url: `scenarios/?size=100${initiativeId ? `&initiative=${initiativeId}` : ''}`,
  })
  return scenariosResponse?.items || []
}

export const fetchScenarioDetail = async (scenarioId: number): Promise<Scenario> => {
  return await fetchHelperV2<Scenario>({
    url: `scenarios/${scenarioId}/`,
  })
}

export const fetchDeleteScenario = async (scenarioId: number): Promise<void> => {
  return await fetchHelperV2<void>({
    method: 'DELETE',
    url: `scenarios/${scenarioId}/`,
    parse: false,
  })
}

interface CreateScenario extends Omit<Scenario, 'id'> {}
export const fetchCreateScenario = async (scenario: CreateScenario): Promise<Scenario> => {
  return await fetchHelperV2<Scenario>({
    url: `scenarios/`,
    method: 'POST',
    body: JSON.stringify(scenario),
  })
}

export const fetchUpdateScenario = async (scenario: Partial<Scenario>): Promise<Scenario> => {
  return await fetchHelperV2<Scenario>({
    url: `scenarios/${scenario.id}/`,
    method: 'PATCH',
    body: JSON.stringify(scenario),
  })
}

const constructBoolQueryForScenarios = ({
  workspaceIds = [],
  scenarios = [],
  initiatives = [],
  products = [],
  vendorIds = [],
}: any) => {
  const boolQuery: ProductsBoolQuery = {
    filter: [
      {
        terms: {
          formulation_status: ['pipeline', 'scenario'],
        },
      },
    ],
  }
  if (workspaceIds.length) {
    boolQuery.filter.push({
      terms: {
        'workspaces.id': workspaceIds,
      },
    })
  }
  if (vendorIds.length) {
    boolQuery.filter.push({
      terms: {
        'brand.id': vendorIds,
      },
    })
  }
  if (scenarios.length) {
    boolQuery.filter.push({
      terms: {
        'scenarios.id': scenarios,
      },
    })
  }
  if (initiatives.length) {
    boolQuery.filter.push({
      terms: {
        'initiatives.id': initiatives,
      },
    })
  }
  if (products.length) {
    boolQuery.filter.push({
      terms: { pk: products },
    })
  }
  return boolQuery
}

interface FetchImpactScoresByScenario {
  workspaceIds?: number[]
  vendorIds?: number[]
  scenarios?: number[]
  products?: number[]
  initiatives?: number[]
  runtimeMappings?: estypes.MappingRuntimeFields
  aggs: Record<string, any>
}
export const fetchInitiativeImpactScores = async ({
  workspaceIds,
  vendorIds,
  scenarios = [],
  initiatives = [],
  products = [],
  runtimeMappings = {}, // Mappings for custom fields e.g. mt/yr or scope 3 values
  aggs,
}: Partial<FetchImpactScoresByScenario>) => {
  const boolQuery: ProductsBoolQuery = constructBoolQueryForScenarios({
    workspaceIds,
    vendorIds,
    scenarios,
    products,
    initiatives,
  })

  const customFields = runtimeMappings
    ? Object.keys(runtimeMappings).reduce((acc, field) => {
        return {
          ...acc,
          [`${field}_sum`]: { sum: { field } },
          [`${field}_avg`]: { avg: { field } },
          [`${field}_count`]: { value_count: { field } },
        }
      }, {})
    : {}

  const { aggregations } = await fetchElasticV2({
    url: FORMULATIONS_SEARCH_PATH,
    body: {
      size: 0,
      query: {
        bool: boolQuery,
      },
      runtime_mappings: runtimeMappings,
      fields: Object.keys(runtimeMappings),
      aggs: {
        // Scores grouped by workspace
        workspaces: {
          terms: {
            field: 'workspaces.id',
            size: 10000,
          },
          aggs: { ...aggs, ...customFields },
        },
        // Scores grouped by vendor (brand)
        vendors: {
          terms: {
            field: 'brand.id',
            size: 10000,
          },
          aggs: { ...aggs, ...customFields },
        },
      },
    },
  })

  type ScenarioResponseScores = {
    [K in Field]: { value: number }
  }
  interface ScenarioData extends ScenarioResponseScores {
    key: number
    doc_count: number
  }

  const generateScores = (scenarioData: ScenarioData) => {
    return Object.entries(scenarioData).reduce((scores, [field, score]) => {
      if (['key', 'doc_count'].includes(field)) {
        return scores
      }
      scores[field] = score.value
      // TODO: temporary for backwards compatability
      if (field.includes('_avg')) {
        scores[field.replace('_avg', '')] = score.value
      }
      return scores
    }, {})
  }

  const formatAggregations = (buckets: ScenarioData[]) =>
    buckets?.length
      ? buckets.reduce((allScores, scenarioData) => {
          const scenarioScores = generateScores(scenarioData)
          allScores[scenarioData.key] = scenarioScores
          return allScores
        }, {})
      : {}

  return {
    // @ts-ignore
    workspaceScores: formatAggregations(aggregations.workspaces.buckets) as GroupedImpactScores,
    // @ts-ignore
    vendorScores: formatAggregations(aggregations.vendors.buckets) as GroupedImpactScores,
  }
}

export type GroupedImpactScores = { [K in number]: ImpactScore }

interface ScenarioScores {
  scores: {
    workspaceScores: GroupedImpactScores
    vendorScores: GroupedImpactScores
  }
  scoresLoading: boolean
  customFields: string[]
}

export function useScenarioScores(scenarioId: number): ScenarioScores {
  const dispatch = useDispatch()
  const workspaceIds = useSelector(selectInitiativeWorkspaces).map((workspace) => workspace.id)
  const vendorIds = useSelector(selectInitiativeBrands).map((vendor) => vendor.id)
  const runtimeMappings = useSelector(selectProductsRuntimeMappings(workspaceIds, scenarioId))
  const aggs = useSelector(selectProductsAggregation)

  const { data, error } = useSWR(`scenarioScores/${scenarioId}`, () =>
    fetchInitiativeImpactScores({
      scenarios: [scenarioId],
      runtimeMappings,
      vendorIds,
      aggs,
    })
  )
  if (error) {
    dispatch(
      addMessage({
        message: 'There was an error fetching scores for these scenarios.',
        severity: 'error',
      })
    )
  }
  return {
    scores: data || { workspaceScores: {}, vendorScores: {} },
    scoresLoading: !data && !error,
    customFields: Object.keys(runtimeMappings),
  }
}

interface FetchScenarioScores {
  scenario: number
  runtimeMappings?: estypes.MappingRuntimeFields
  aggs: Record<string, any>
}
export const fetchScenarioScores = async ({ scenario, runtimeMappings = {}, aggs }: FetchScenarioScores) => {
  const boolQuery: ProductsBoolQuery = constructBoolQueryForScenarios({
    scenarios: [scenario],
  })
  const customFields = runtimeMappings
    ? Object.keys(runtimeMappings).reduce((acc, field) => {
        return {
          ...acc,
          [`${field}_sum`]: { sum: { field } },
          [`${field}_count`]: { value_count: { field } },
        }
      }, {})
    : {}

  const { aggregations } = await fetchElasticV2({
    url: FORMULATIONS_SEARCH_PATH,
    body: {
      size: 0,
      query: {
        bool: boolQuery,
      },
      runtime_mappings: runtimeMappings,
      fields: Object.keys(runtimeMappings),
      aggs: { ...aggs, ...customFields },
    },
  })

  type ScenarioResponseScores = {
    [K in Field]: { value: number }
  }
  return Object.entries(aggregations as ScenarioResponseScores).reduce((allScores, [field, { value }]) => {
    return { ...allScores, [field]: value }
  }, {})
}

// must fetch by product ID here since ES will not have been updated yet
interface FetchNewScenarioScores {
  products?: number[]
  runtimeMappings?: estypes.MappingRuntimeFields
  vendorIds?: number[]
  aggs: Record<string, any>
}
export const fetchNewScenarioScores = async ({
  products = [],
  runtimeMappings = {},
  vendorIds = [],
  aggs,
}: Partial<FetchNewScenarioScores>) => {
  const boolQuery: ProductsBoolQuery = constructBoolQueryForScenarios({ products, vendorIds })
  const customFields = runtimeMappings
    ? Object.keys(runtimeMappings).reduce((acc, field) => {
        return {
          ...acc,
          [`${field}_sum`]: { sum: { field } },
          [`${field}_avg`]: { avg: { field } },
          [`${field}_count`]: { value_count: { field } },
        }
      }, {})
    : {}

  const { aggregations } = await fetchElasticV2({
    url: FORMULATIONS_SEARCH_PATH,
    body: {
      size: 0,
      query: {
        bool: boolQuery,
      },
      runtime_mappings: runtimeMappings,
      fields: Object.keys(runtimeMappings),
      aggs: { ...aggs, ...customFields },
    },
  })

  return Object.keys(aggregations).reduce((metrics, metric) => {
    const aggregation = aggregations[metric] as estypes.AggregationsCardinalityAggregate
    metrics[metric] = aggregation.value
    return metrics
  }, {})
}
