import React, { FC, useEffect, useState } from 'react'
import { TextField } from '.'
import { formatNumber } from '@howgood/utils'

interface CellParams {
  numericValue: number
  onUpdateValue: (value: number) => void
  id?: string
  ariaLabel?: string
  onBadValue?: (value: string) => void
  disabled?: boolean
  size?: 'small' | 'medium'
}

const parseNumericString = (val: string) => {
  // ASSUMPTION: we are assuming locale is always en-US or similar
  const base = parseFloat(val.replace(',', ''))
  if (val.toLowerCase().match(/k/)) {
    return Math.round(base * 1000)
  } else if (val.toLowerCase().match(/m/)) {
    return Math.round(base * 1000000)
  } else if (val.toLowerCase().match(/b/)) {
    return Math.round(base * 1000000000)
  } else if (val.toLowerCase().match(/t/)) {
    return Math.round(base * 1000000000000)
  }
  return base
}

export const NumericInput: FC<CellParams> = ({
  id = 'numeric-input',
  ariaLabel = '',
  disabled = false,
  size = 'medium',
  numericValue,
  onUpdateValue,
  onBadValue,
}) => {
  const [value, setValue] = useState(numericValue === null ? '' : formatNumber(numericValue))

  useEffect(() => {
    // show the correct goal when switching between sum and avg
    setValue(numericValue === null ? '' : formatNumber(numericValue))
  }, [numericValue])

  const onUpdate = () => {
    if (value === '' || value === null) {
      onUpdateValue(undefined)
      return
    }
    try {
      const numeric = parseNumericString(value)
      if ((!isNaN(numeric) || value === '') && numericValue !== numeric && numeric >= 0) {
        onUpdateValue(numeric)
        setValue(numeric === null ? '' : formatNumber(numeric))
      } else {
        setValue(numericValue === null ? '' : formatNumber(numericValue))
        if (onBadValue && numericValue !== numeric) {
          onBadValue(value)
        }
      }
    } catch (e) {
      console.error(e)
      if (onBadValue) {
        onBadValue(value)
      }
    }
  }
  return (
    <TextField
      id={id}
      data-testid={id}
      aria-label={ariaLabel}
      color="secondary"
      error={Boolean(value && isNaN(parseNumericString(value)))}
      disabled={disabled}
      onChange={(e) => {
        setValue(e.target.value)
      }}
      onBlur={onUpdate}
      onEnter={onUpdate}
      value={value || ''}
      sx={{
        '& .MuiInputBase-input': {
          px: 1,
          py: size === 'medium' ? 1 : 0.5,
          textAlign: 'right',
          fontSize: 14,
          backgroundColor: disabled ? '#ececec' : 'unset',
          cursor: disabled ? 'not-allowed' : 'unset',
        },
        '& .MuiOutlinedInput-notchedOutline': { borderColor: 'secondary.main' },
      }}
    />
  )
}
