import { AnyAction, combineReducers } from '@reduxjs/toolkit'
import { connectRouter } from 'connected-react-router'
import { splitReducer as splitio } from '@splitsoftware/splitio-redux'
import { vendorManagementReducer as vendorManagement } from '@/state/vendorManagement'
import { carbonAccountingReducer as carbonAccounting } from '@/state/carbonAccounting'
import { optimalGhgPotentialReducer as optimalGhgPotential } from '@/state/optimalGhgPotential'
import { contentfulDataReducer as contentfulData } from '@/state/contentfulData'
import { formulationTagsReducer as formulationTags } from '@/state/formulationTags'
import { functionalCategoriesReducer as functionalCategories } from '@/state/functionalCategories'
import { impactHistoryReducer as impactHistory } from '@/state/impactHistory'
import { initiativesReducer as initiatives } from '@/state/initiatives'
import { manufacturingTypesReducer as manufacturingTypes } from '@/state/manufacturingTypes'
import { messagesReducer as messages } from '@/state/messages'
import { nutritionReducer as nutrition } from '@/state/nutrition'
import { organizationReducer as organization } from '@/state/organization'
import { originLocationsReducer as originLocations } from '@/state/originLocations'
import { portfolioDashboardReducer as portfolioDashboard } from '@/features/PortfolioDashboard/state/portfolioDashboard'
import { productDashboardReducer as productDashboard } from '@/features/ProductDashboard/state/productDashboard'
import { productMetricsReducer as productMetrics } from '@/state/productMetrics'
import { productsReducer as products } from '@/state/products'
import { productStandardsReducer as productStandards } from '@/state/productStandards'
import { packagingReducer as packaging } from '@/state/packaging'
import { productOverviewReducer as productOverview } from '@/state/productOverview'
import { pageSettingsReducer as pageSettings } from '@/state/pageSettings'
import { preferencesReducer as preferences } from '@/state/preferences'
import { recipeReducer as recipe } from '@/state/recipe'
import { salesCategoriesReducer as salesCategories } from '@/state/salesCategories'
import { transportationReducer as transportation } from '@/state/transportation'
import { userReducer as user } from '@/state/user'
import { vendorReducer } from '@/state/vendor'
import { workspacesReducer as workspaces } from '@/state/workspaces'
import { liteUserPaywallDialogReducer as liteUserPaywallDialog } from '@/state/liteUserPaywallDialog'
import history from '@/utils/history'

const reducers = {
  vendorManagement,
  carbonAccounting,
  contentfulData,
  formulationTags,
  functionalCategories,
  impactHistory,
  initiatives,
  manufacturingTypes,
  messages,
  nutrition,
  optimalGhgPotential,
  organization,
  originLocations,
  packaging,
  pageSettings,
  portfolioDashboard,
  preferences,
  productDashboard,
  productMetrics,
  productOverview,
  productStandards,
  products,
  recipe,
  router: connectRouter(history),
  salesCategories,
  splitio,
  transportation,
  user,
  vendor: vendorReducer,
  workspaces,
  liteUserPaywallDialog,
}

const appReducer = combineReducers(reducers)
type State = ReturnType<typeof appReducer>

export const rootReducer = (state: State, action: AnyAction) => {
  // Reset state on logout
  const s = action.type === 'REDUX_RESET' ? ({} as State) : state
  return appReducer(s, action)
}
