import React, { FC, ReactNode } from 'react'
import { Box, Tooltip, Text, Stack } from '@howgood/design'

import { RichText, MetricEntry } from '@/contentfully'

interface Props {
  content: MetricEntry
  children: ReactNode
}

export const ContentfulTooltip: FC<Props> = ({ content, children }) => {
  if (!content?.longDescription) {
    return <>{children}</>
  }

  return (
    <Tooltip
      title={
        <Stack data-testid="contentful-tooltip-content">
          <RichText document={content.longDescription} />
          {content.spectrumImage && (
            <Stack direction="row" gap={1} alignItems="center">
              <Stack>
                <Text fontSize={12} lineHeight={1.2} textAlign="center" noWrap fontWeight={700}>
                  Negative Impact
                </Text>
                <Text fontSize={12} lineHeight={1.2} textAlign="center">
                  {content.negativeImpact}
                </Text>
              </Stack>
              <Box>
                <img src={content.spectrumImage?.fields?.file?.url as string} alt={`${content.title} description`} />
              </Box>
              <Stack>
                <Text fontSize={12} lineHeight={1.2} textAlign="center" noWrap fontWeight={700}>
                  Positive Impact
                </Text>
                <Text fontSize={12} lineHeight={1.2} textAlign="center">
                  {content.positiveImpact}
                </Text>
              </Stack>
            </Stack>
          )}
        </Stack>
      }
    >
      <Stack>{children}</Stack>
    </Tooltip>
  )
}
