import React, { FC, useState } from 'react'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { useGridApiRef } from '@mui/x-data-grid-premium'
import { faFlagSwallowtail } from '@fortawesome/pro-solid-svg-icons'
import { Button, Stack, Text, CircularProgress, ButtonProps } from '@howgood/design'

import { CreateInitiativeDialog } from './CreateInitiativeDialog'
import { createInitiativeWithBaseline, selectIsCreatingInitiative, selectNewInitiative } from '@/state/initiatives'
import { fetchAllProducts } from '@/api/elastic/productsEsRepo'
import { BaselineGrid } from '../../PortfolioPage/components/Toolbar/BaselineGrid'
import { selectAllWorkspacesOptions } from '@/state/workspaces'
import { getProducts, selectProductsSortOptions, selectShowLiveImpactData } from '@/state/products'
import { selectProductsBoolQuery, selectProductsRuntimeMappings } from '@/selectors/selectProductsRequestItems'
import { selectIsProcurement } from '@/state/router'
import { prepareLockedScoreData } from '@/components/Products/utils/prepareLockedScoreData'

const ESBATCHSIZE = 250

interface Props {
  color?: ButtonProps['color']
}

export const CreateInitiative: FC<Props> = ({ color }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const newInitiative = useSelector(selectNewInitiative)
  const isCreatingInitiative = useSelector(selectIsCreatingInitiative)
  const userWorkspacesOptions = useSelector(selectAllWorkspacesOptions)
  const { boolQuery, workspaceIds } = useSelector(selectProductsBoolQuery)
  const runtimeMappings = useSelector(selectProductsRuntimeMappings(workspaceIds))
  const sortOptions = useSelector(selectProductsSortOptions)
  const isProcurement = useSelector(selectIsProcurement)
  const showLiveData = useSelector(selectShowLiveImpactData)
  const [isLoading, setIsLoading] = useState(false)

  const apiRef = useGridApiRef()

  const [dialogOpen, setDialogOpen] = useState(false)
  const [progressCount, setProgressCount] = useState({ count: 0, total: 0 })

  const handleCreateInitiativeClicked = () => {
    setDialogOpen(true)
  }

  const startFetchingData = async () => {
    setIsLoading(true)
    setProgressCount({ count: -1, total: 0 })

    const [{ hits, custom_aggs }, _] = await Promise.all([
      fetchAllProducts({
        boolQuery,
        runtimeMappings,
        sortOptions,
        sendCount: setProgressCount,
        batchSize: ESBATCHSIZE,
      }),
      dispatch(getProducts({ size: 0 })),
    ])

    setProgressCount({ count: 0, total: 0 })
    const rows = prepareLockedScoreData(hits, custom_aggs, isProcurement, showLiveData, userWorkspacesOptions)
    apiRef.current.updateRows(rows)
    const csv = apiRef.current.getDataAsCsv()
    // Passing the navigation function as a callback so it can be called
    // after all the async actions are completed
    dispatch(createInitiativeWithBaseline({ csv, history }))
    setIsLoading(false)
  }

  return (
    <>
      <Stack direction="row" gap={2}>
        {progressCount.count !== 0 && (
          <Stack direction="row" gap={1} alignItems="center">
            <CircularProgress size={20} />
            <Stack direction="row" gap={1}>
              <Text variant="body2">Please wait until process completes...</Text>
              <Text variant="body2" color={progressCount.count <= 0 ? 'transparent' : undefined}>
                {`(fetching ${progressCount.count.toLocaleString()} of ${
                  progressCount.total === 10000 ? 'more than 10,000' : progressCount.total.toLocaleString()
                } products)`}
              </Text>
            </Stack>
          </Stack>
        )}
        {isCreatingInitiative && (
          <Stack direction="row" gap={1} alignItems="center">
            <CircularProgress size={20} />
            <Text variant="body2">Please wait until process completes... (uploading baseline data)</Text>
          </Stack>
        )}
        <Button
          color={color}
          startIcon={faFlagSwallowtail}
          disabled={isCreatingInitiative || isLoading}
          onClick={handleCreateInitiativeClicked}
          data-testid="create-initiative-button"
          size="small"
        >
          Create Initiative
        </Button>
      </Stack>
      {dialogOpen && (
        <CreateInitiativeDialog startFetchingData={startFetchingData} closeDialog={() => setDialogOpen(false)} />
      )}
      {newInitiative && <BaselineGrid apiRef={apiRef} />}
    </>
  )
}
