import React, { FC } from 'react'
import { Drawer, Stack, Text } from '@howgood/design'
import { AgPracticesStepper } from './AgPracticesStepper'
import { RecipeIngredient } from '@/state/recipe'

interface AgPracticesDrawerProps {
  ingredient: RecipeIngredient
  closeAgPracticesDrawer: () => void
}

export const AgPracticesDrawer: FC<AgPracticesDrawerProps> = ({ ingredient, closeAgPracticesDrawer }) => {
  const handleClose = () => {
    closeAgPracticesDrawer()
  }

  if (!ingredient) {
    handleClose()
    return null
  }

  return (
    <Drawer
      data-testid="ag-practices-drawer"
      open
      onClose={handleClose}
      PaperProps={{
        sx: { maxWidth: '60%' },
      }}
    >
      <Stack gap={2}>
        <Text variant="h2" textTransform="capitalize">
          {ingredient.name}
        </Text>
        <Text variant="h3">Custom agricultural practice data</Text>
        <AgPracticesStepper closeDrawer={handleClose} />
      </Stack>
    </Drawer>
  )
}
