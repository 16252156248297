import React, { FC, useMemo } from 'react'
import { Card, Stack, Text, Tooltip, ListItem, List, Box } from '@howgood/design'
import { roundBy } from '@/utils/numbers'
import { MaterialGranularityBreakdownChart } from './MaterialGranularityBreakdownChart'
import { RequestVendorData } from '../RequestVendorData'
import { Loader } from '@/components/Layout'
import {
  MATERIAL_GRANULARITY_KNOWN_VENDORS,
  MATERIAL_GRANULARITY_STANDARD_INGREDIENTS,
  MATERIAL_GRANULARITY_UNKNOWN_VENDORS,
  MATERIAL_GRANULARITY_VENDOR_VERIFIED,
  MATERIAL_GRANULARITY_VENDOR_VERIFIED_PLUS,
} from '@/features/ProductPage/panels/ImpactReduction/ImpactReduction.hooks'

export interface MaterialGranularityBreakdownData {
  totalCount: number
  vendors: {
    [MATERIAL_GRANULARITY_VENDOR_VERIFIED_PLUS]?: number
    [MATERIAL_GRANULARITY_VENDOR_VERIFIED]?: number
    [MATERIAL_GRANULARITY_KNOWN_VENDORS]?: number
    [MATERIAL_GRANULARITY_UNKNOWN_VENDORS]?: number
    [MATERIAL_GRANULARITY_STANDARD_INGREDIENTS]?: number
  }
}

interface MaterialGranularityBreakdownProps {
  data: MaterialGranularityBreakdownData
  withRequestVendorData?: boolean
  withCard?: boolean
  withTitleTooltip?: boolean
  titleVariant?: 'h4' | 'body1'
  titleColor?: 'secondary'
  subtitle?: string
  isLoading?: boolean
}

const TOOLTIP_LIST_ITEMS = [
  'Vendor Verified materials are the most granular because this is first party data directly from your vendors',
  'Known vendors shows that you know the vendor and possibly some simple data about the material',
  'Unknown vendors is the least granular because there is no insight into who the material was purchased from',
]

export const MaterialGranularityBreakdown: FC<MaterialGranularityBreakdownProps> = ({
  data,
  withRequestVendorData,
  withCard,
  withTitleTooltip,
  titleVariant = 'h4',
  titleColor,
  subtitle,
  isLoading = false,
}) => {
  const chartData = useMemo(() => {
    return Object.entries(data.vendors).map(([vendorName, vendorCount]) => ({
      name: vendorName,
      value: vendorCount,
      pct: vendorCount ? roundBy((vendorCount / data.totalCount) * 100, 0) || '< 1' : 0,
    }))
  }, [data])

  const title = (
    <Text variant={titleVariant} color={titleColor}>
      Data Granularity
    </Text>
  )

  const content = (
    <>
      {withTitleTooltip ? (
        <Tooltip
          maxWidth={390}
          title={
            <Stack sx={{ px: 0.5 }}>
              <Text variant="h6">
                This breakdown shows the granularity of the data you have for your purchased materials.
              </Text>
              <List listStyleType="disc">
                {TOOLTIP_LIST_ITEMS.map((item) => (
                  <ListItem key={item}>{item}</ListItem>
                ))}
              </List>
            </Stack>
          }
        >
          {title}
        </Tooltip>
      ) : (
        title
      )}
      {subtitle && (
        <Text fontSize={12} lineHeight={1.2}>
          {subtitle}
        </Text>
      )}
      {isLoading && (
        <Box height={270} width={390}>
          <Loader />
        </Box>
      )}
      {!!data.totalCount && <MaterialGranularityBreakdownChart data={chartData} />}
      {withRequestVendorData && <RequestVendorData />}
    </>
  )

  if (withCard) {
    return <Card sx={{ padding: '10px 20px' }}>{content}</Card>
  }

  return <Stack padding="10px 20px">{content}</Stack>
}
