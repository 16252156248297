import React, { FC } from 'react'
import { Field } from '@/constants/impactScore'

import './Profile.scss'
import { useContentfulMetric } from '@/contentfully/hooks'

interface ImpactMetricProps {
  field: Field
}

export const ImpactMetric: FC<ImpactMetricProps> = ({ field }) => {
  const metric = useContentfulMetric(field)

  return <div className="Profile__dialog__impact__metric">{metric?.title}</div>
}
