import React, { FC } from 'react'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'

import { useWorkspaceColumns, useWorkspaceRows } from '@/components/GridWithMetrics/useGridHooks'
import { MetricsGrid } from '@/components/GridWithMetrics/MetricsGrid'
import { selectAllMetricGridOptions } from '@/selectors/selectAllWorkspaceGridOptions'
import { selectSalesCategoriesLoading } from '@/state/salesCategories'
import { selectIsWorkspacesLoading, selectAllWorkspaceImpactScores } from '@/state/workspaces'
import { selectWorkspaceProductFilterIds, selectWorkspacesFilteredByType } from '@/state/products'
import { Workspace } from '@/records'
import { AppState } from '@/store'

const selectFilteredPortfolioWorkspaces = createSelector<AppState, Workspace[], number[], Workspace[]>(
  selectWorkspacesFilteredByType,
  selectWorkspaceProductFilterIds,
  (workspaces, workspaceFilters) => {
    return workspaceFilters.length ? workspaces.filter((ws) => workspaceFilters.includes(ws.id)) : workspaces
  }
)

export const WorkspaceList: FC = () => {
  const isWorkspacesLoading = useSelector(selectIsWorkspacesLoading)
  const isSalesCategoriesLoading = useSelector(selectSalesCategoriesLoading)
  const workspaces = useSelector(selectFilteredPortfolioWorkspaces)
  const options = useSelector(selectAllMetricGridOptions)
  const columns = useWorkspaceColumns()
  const workspaceImpactScores = useSelector(selectAllWorkspaceImpactScores)

  const rows = useWorkspaceRows(workspaces, workspaceImpactScores)

  return (
    <MetricsGrid
      rows={rows}
      columns={columns}
      options={options}
      loading={isWorkspacesLoading || isSalesCategoriesLoading}
    />
  )
}
