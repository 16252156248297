import React, { FC } from 'react'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Customized,
  Rectangle,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts'
import { theme } from '@howgood/design'
import { AllEmissionsData, Highlight } from './types'
import { formatTick, getChartData } from './utils'

interface Props {
  data: AllEmissionsData
  domain: [number, number]
  ticks: number[]
  highlight: Highlight
}

export const AllEmissionsChart: FC<Props> = ({ data, domain, ticks, highlight }) => {
  const chartData = getChartData(data)

  return (
    <ResponsiveContainer width="100%" height={80}>
      <BarChart layout="vertical" stackOffset="sign" width={300} height={80} data={[{ ...chartData, name: '' }]}>
        <CartesianGrid strokeDasharray="3 3" />
        <ReferenceLine x={0} stroke="#000" />
        <XAxis type="number" domain={domain} ticks={ticks} tickFormatter={formatTick} interval="preserveStartEnd" />
        <YAxis dataKey="name" type="category" hide />
        <Bar
          id="carbon-removals-bar"
          dataKey="cf_removals_annual_impact"
          stackId="all_emissions"
          fill={theme.palette.success.light}
          name={data.cf_removals_annual_impact.title}
        />
        <Bar
          id="land-management-emissions-bar"
          dataKey="raw_greenhouse_gas_annual_impact"
          stackId="all_emissions"
          fill={theme.palette.error.main}
          name={data.raw_greenhouse_gas_annual_impact.title}
        />
        <Bar
          id="luc-emissions-bar"
          dataKey="cf_luc_annual_impact"
          stackId="all_emissions"
          fill={theme.palette.error.light}
          name={data.cf_luc_annual_impact.title}
        />
        <Bar
          id="non-flag-emissions-bar"
          dataKey="cf_non_flag_annual_impact"
          stackId="all_emissions"
          fill={theme.palette.warning.light}
          name={data.cf_non_flag_annual_impact.title}
        />
        {/* Highlight rectangle */}
        <Customized
          component={({ formattedGraphicalItems }) => {
            const items =
              highlight === 'all'
                ? formattedGraphicalItems
                : formattedGraphicalItems.filter((item) => item.item.props.id !== 'non-flag-emissions-bar')
            const { x, y, height } = items[0].props.data[0]
            const width = items.reduce((acc, item) => acc + Math.abs(item.props.data[0].width), 0)
            return (
              <Rectangle
                id="highlight-rectangle"
                x={x + items[0].props.data[0].width}
                y={y}
                width={width}
                height={height}
                fill="transparent"
                stroke="red"
                strokeWidth={highlight ? 3 : 0}
              />
            )
          }}
        />
      </BarChart>
    </ResponsiveContainer>
  )
}
