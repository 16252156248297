import React, { FC } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ElasticsearchIngredientHit } from '@/records'
import { addIngredient } from '@/state/recipe'
import { IngredientSearch } from '@/components/Shared/IngredientSearch'
import { selectDisplayedProduct } from '@/state/productOverview'

export const AddIngredientButton: FC = () => {
  const dispatch = useDispatch()
  const product = useSelector(selectDisplayedProduct)

  const handleItemSelected = (item: ElasticsearchIngredientHit) => {
    dispatch(addIngredient(item.raw._source))
  }

  return (
    <IngredientSearch
      disabled={!product.workspaces.length}
      onChange={handleItemSelected}
      placeholderText="Add&nbsp;&nbsp;Ingredient"
      productId={`${product.id}`}
    />
  )
}
