import React, { FC, ReactNode } from 'react'
import { faFlagPennant } from '@fortawesome/pro-solid-svg-icons'
import { Text } from '../Text'
import { Stack } from '../Stack'
import { Icon } from '../Icon'
import { theme } from '../theme'
import { FavoriteIcon } from './FavoriteIcon'
import { Size } from './types'
import { Tooltip } from '../Tooltip'

interface Props {
  title: string
  titleTooltip: FC<{ children: ReactNode }>
  subtitle: string
  category: string
  size: Size
  favorite: boolean
  handleFavorite: () => void
  goal: boolean
  disabled: boolean
  hideFavoriteIcon?: boolean
}

export const MetricTileTitle: FC<Props> = ({
  title,
  titleTooltip: TitleTooltip,
  subtitle,
  category,
  size,
  favorite,
  handleFavorite,
  goal,
  disabled,
  hideFavoriteIcon,
}) => {
  const color = disabled ? 'text.disabled' : 'text.primary'

  return (
    <Stack flexDirection="row" justifyContent="space-between" zIndex={1}>
      <Stack overflow="hidden">
        <TitleTooltip>
          <Text data-testid="metric-tile-title" variant="body1" color={color} textOverflow="ellipsis" noWrap>
            {title}
          </Text>
        </TitleTooltip>
        {size !== 'lg' && (
          <Tooltip title={<Text data-testid="metric-tile-subtitle-tooltip">{subtitle}</Text>}>
            <Text
              data-testid="metric-tile-subtitle"
              variant="caption"
              color={color}
              textOverflow="ellipsis"
              noWrap
              visibility={subtitle ? 'visible' : 'hidden'}
            >
              {subtitle || 'hidden'}
            </Text>
          </Tooltip>
        )}
      </Stack>
      {!category && goal && (
        <Icon data-testid="metric-tile-goal-icon" icon={faFlagPennant} color={theme.palette.info.main} size="xs" />
      )}
      {!category && !goal && !hideFavoriteIcon && <FavoriteIcon favorite={favorite} handleFavorite={handleFavorite} />}
    </Stack>
  )
}
