import React, { FC } from 'react'
import { Text, Alert, AlertTitle, Box } from '@howgood/design'

export const VendorVerifiedAlert: FC = () => {
  return (
    <Box marginTop={2}>
      <Alert severity="warning">
        <AlertTitle>Does not contain agricultural practices data.</AlertTitle>
        <Text variant="body2">
          Want to gather more detailed agricultural practices information? Copy the link below to send to your vendor.
        </Text>
      </Alert>
    </Box>
  )
}
