import React, { forwardRef } from 'react'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import MuiIconButton, { IconButtonProps as MuiIconButtonProps } from '@mui/material/IconButton'
import { Icon } from './Icon'

export interface IconButtonProps extends Pick<MuiIconButtonProps, 'id' | 'onClick' | 'disabled'> {
  icon: IconDefinition
  color?: 'primary' | 'secondary' | 'error' | 'inherit'
  size?: 'small' | 'medium'
  ['aria-label']?: string
  ['data-pendo-id']?: string
  ['data-testid']?: string
}

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      id,
      icon,
      color,
      size,
      onClick,
      disabled,
      ['aria-label']: ariaLabel,
      ['data-pendo-id']: dataPendoId,
      ['data-testid']: dataTestId,
      ...rest
    },
    ref
  ) => {
    const sizePx = size === 'medium' ? 36 : 22

    return (
      <MuiIconButton
        ref={ref}
        id={id}
        aria-label={ariaLabel}
        data-pendo-id={dataPendoId}
        data-testid={dataTestId || 'icon-button'}
        color={color}
        size={size}
        onClick={onClick}
        disabled={disabled}
        disableRipple
        sx={{ padding: 0, width: sizePx, height: sizePx }}
        {...rest}
      >
        <Icon data-testid="icon-button-icon" icon={icon} fontSize={size === 'medium' ? 16 : 13} color={color} />
      </MuiIconButton>
    )
  }
)

IconButton.displayName = 'IconButton'
