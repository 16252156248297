import React, { FC, useRef, useState } from 'react'
import { PDFExport } from '@progress/kendo-react-pdf'
import { faEllipsis } from '@fortawesome/pro-regular-svg-icons'
import { faBook, faScroll } from '@fortawesome/pro-solid-svg-icons'
import { Icon, IconButton, Menu, MenuItem, Stack, Text } from '@howgood/design'
import { ReportsDrawer } from '../Reports/ReportsDrawer'
import { ExportMethodology } from './ExportMethodology'
import { downloadMethodology } from './OtherReportsButton.utils'

export const OtherReportsButton: FC = () => {
  const ref = useRef<PDFExport>()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>(null)
  const [reportsDrawerOpen, setReportsDrawerOpen] = useState(false)

  function onClose() {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton data-testid="other-reports-button" icon={faEllipsis} onClick={(e) => setAnchorEl(e.currentTarget)} />
      <Menu
        data-testid="other-reports-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          data-testid="other-reports-item"
          onClick={() => {
            setReportsDrawerOpen(true)
            onClose()
          }}
        >
          <Stack flexDirection="row" gap={0.5}>
            <Icon icon={faScroll} size="sm" />
            <Text variant="body2">Other reports</Text>
          </Stack>
        </MenuItem>
        <MenuItem
          data-testid="scope-3-methodology-item"
          onClick={() => {
            downloadMethodology(ref)
            onClose()
          }}
        >
          <Stack flexDirection="row" gap={0.5}>
            <Icon icon={faBook} size="sm" />
            <Text variant="body2">Download Scope 3 Methodology</Text>
          </Stack>
        </MenuItem>
      </Menu>
      <ReportsDrawer reportsDrawerOpen={reportsDrawerOpen} closeReportsDrawer={() => setReportsDrawerOpen(false)} />
      <ExportMethodology ref={ref} />
    </>
  )
}
