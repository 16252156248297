import React, { FC } from 'react'
import { Button, Stack, Text } from '@howgood/design'
import { AgPracticesSchemaChild } from './agPracticesSchema'

interface StepPanelProps {
  step: AgPracticesSchemaChild
  index: number
  handleComplete: () => void
}

export const StepPanel: FC<StepPanelProps> = ({ step, index, handleComplete }) => {
  const id = `ag-practices-step-panel-${index}`

  return (
    <Stack id={id} data-testid={id} p={1} gap={2} minHeight={400}>
      {step.children.map((child, idx) => (
        <Stack key={child.id}>
          <Text variant="h4">
            {idx + 1}. {child.name}
          </Text>
          <Text>{child.description || ''}</Text>
        </Stack>
      ))}
      <Stack flex={1} />
      <Button onClick={handleComplete}>Required Fields Complete</Button>
    </Stack>
  )
}
