import { Field } from '@/constants/impactScore'
import { ContentfulFieldKey } from './types'

export const getKeyWithoutAggregateOption = (key: string): ContentfulFieldKey => {
  if (!key) {
    return null
  }
  if (key.includes('_avg')) {
    return key.replace('_avg', '') as Field
  }
  if (key.includes('_sum')) {
    return key.replace('_sum', '') as Field
  }
  return key as ContentfulFieldKey
}
