import { batch } from 'react-redux'
import { ThunkAction } from 'redux-thunk'
import { AnyAction } from 'redux'
import { getSalesCategories } from '@/state/salesCategories/salesCategories.thunk'
import { getProductStandards } from '@/state/productStandards/productStandards.thunk'
import { getManufacturingTypes } from '@/state/manufacturingTypes/manufacturingTypes.thunk'
import { getPackagingMaterials } from '@/state/packaging/packaging.thunk'
import { AppState } from '@/store'
import { getReports } from '@/state/organization'
import { getTransportationModes } from '@/state/transportation'
import { getInitiatives, getScenarios } from '@/state/initiatives'
import { getFunctionalCategories } from '@/state/functionalCategories'

export const appInit = (): ThunkAction<void, AppState, void, AnyAction> => async (dispatch) => {
  dispatch(getScenarios())
  dispatch(getInitiatives())
  batch(() => {
    dispatch(getSalesCategories())
    dispatch(getProductStandards())
    dispatch(getManufacturingTypes())
    dispatch(getPackagingMaterials())
    dispatch(getReports())
    dispatch(getTransportationModes())
    dispatch(getFunctionalCategories())
  })
}
