import React, { FC, useState } from 'react'
import { Menu, MenuItem } from '@howgood/design'

import { ExportDialog } from './ExportDialog'
import { ExportType } from './ExportDialog.types'

interface Props {
  onClose: () => void
  anchorEl: HTMLElement
}

export const Export: FC<Props> = ({ onClose, anchorEl }) => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [exportType, setExportType] = useState<ExportType>(null)

  function handleItemClick(item: ExportType) {
    setExportType(item)
    setDialogOpen(true)
  }

  return (
    <>
      <Menu
        id="export-options-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        MenuListProps={{ id: 'export-menu-list' }}
      >
        <MenuItem id="download-excel-option" onClick={() => handleItemClick('download-excel')}>
          Download as Excel
        </MenuItem>
        <MenuItem id="download-csv-option" onClick={() => handleItemClick('download-csv')}>
          Download as CSV
        </MenuItem>
      </Menu>
      <ExportDialog
        open={dialogOpen}
        exportType={exportType}
        onClose={() => {
          setDialogOpen(false)
          onClose()
        }}
      />
    </>
  )
}
