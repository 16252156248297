import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@howgood/design'
import { Basis, selectBasis, setBasis } from '../state/productDashboard'

export const BasisSelect: FC = () => {
  const basis = useSelector(selectBasis)
  const dispatch = useDispatch()

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    dispatch(setBasis(event.target.value as Basis))
  }

  return (
    <FormControl>
      <InputLabel id="product-basis-select-label">Basis</InputLabel>
      <Select
        labelId="product-basis-select-label"
        id="product-basis-select"
        value={basis}
        label="Basis"
        onChange={handleChange}
      >
        <MenuItem value="kg">Per kg of Product</MenuItem>
        <MenuItem value="inventories">Annual (Purchased Materials)</MenuItem>
        <MenuItem value="sales">Annual (Sales)</MenuItem>
      </Select>
    </FormControl>
  )
}
