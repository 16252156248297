import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { IImpactMetricOption } from '@/records'
import { PERMISSIONS_TO_FIELDS_MAP } from '@/constants/impactScore'
import { selectUserAnalysisImpactPermissions, selectUserProductsImpactPermissions } from '@/state/user'
import { useSortedContentfulMetrics } from '@/contentfully/hooks'

export const useAnalysisAndProductMetricsOptions = (): IImpactMetricOption[] => {
  const productImpactPermissions = useSelector(selectUserProductsImpactPermissions)
  const analysisImpactPermissions = useSelector(selectUserAnalysisImpactPermissions)

  const allPermissions = useMemo(() => [...productImpactPermissions, ...analysisImpactPermissions], [
    productImpactPermissions,
    analysisImpactPermissions,
  ])

  const fields = useMemo(() => allPermissions.map((permission) => PERMISSIONS_TO_FIELDS_MAP[permission]), [
    allPermissions,
  ])

  const metrics = useSortedContentfulMetrics(fields)

  return useMemo(() => {
    if (!metrics) return []
    return metrics.map((metric) => {
      return {
        key: metric?.key,
        value: metric?.key,
        label: metric?.title,
        lowercaseTitle: metric?.lowercaseTitle,
        units: metric?.units || '',
      }
    })
  }, [metrics])
}
