import { useHistory } from 'react-router'
import { AnyAction, ThunkAction, createAsyncThunk } from '@reduxjs/toolkit'
import isEmpty from 'lodash/isEmpty'

import { ImpactScore, fetchElasticV2, fetchHelperV2 } from '@/api'
import { AppState } from '@/store'
import { Report, getDetailReport, saveReport, selectReports } from '../organization'
import {
  selectChartMetricKey,
  selectCurrentInitiativeGoalMetrics,
  selectNewInitiative,
  selectScenarioScores,
  selectInitiatives,
  selectSortedScenarios,
  selectCurrentInitiative,
  selectInitiativeScores,
  selectCreateNewInitiativeGroupByMethod,
  selectScenariosByInitiativeId,
  selectScenarioPageTab,
} from './initiatives.selectors'
import { InitiativeGroupByMethod, ScenarioTab, setCurrentInitiative } from './initiatives.slice'
import { WorkspaceScores } from '../workspaces'
import { InitiativeBasic, Scenario } from '@/records/Initiatives'
import {
  GroupedImpactScores,
  fetchCreateScenario,
  fetchDeleteInitiative,
  fetchDeleteScenario,
  fetchInitiativeDetail,
  fetchInitiatives,
  fetchNewScenarioScores,
  fetchScenarioDetail,
  fetchScenarioScores,
  fetchScenarios,
  fetchUpdateScenario,
} from './initiatives.requests'
import { handleThunkError } from '../messages/messages.thunk'
import {
  ProductClone,
  NewInitiative,
  VersionData,
  addNewInitiative,
  setChartMetric,
  setCurrentScenario,
  setScenarioPageTab,
} from './initiatives.slice'
import { selectProductMetrics } from '../productMetrics'
import { getProducts, initialState, setShowAtRiskOnly, setShowLiveImpactData, updateProductFilters } from '../products'
import { addMessage } from '../messages'
import { FORMULATIONS_SEARCH_PATH } from '@/constants/config'
import { selectProductsAggregation, selectProductsRuntimeMappings } from '@/selectors/selectProductsRequestItems'
import { fetchBrandsByIds } from '@/api/brandApi'
import { ProductBrand } from '@/records'

export const getScenarioImpactScores = createAsyncThunk<
  { scenarioScores: GroupedImpactScores },
  { scenarioIds: number[]; workspaceIds?: number[] },
  { state: AppState }
>('initiatives/getScenarioImpactScores', async ({ scenarioIds, workspaceIds }, { getState, dispatch }) => {
  dispatch(setShowLiveImpactData(true))
  const state = getState()
  const scenarioRuntimeMappings = scenarioIds.map((id) => ({
    id,
    runtimeMappings: selectProductsRuntimeMappings(workspaceIds, id)(state),
  }))
  const aggs = selectProductsAggregation(state)
  const existingScenarioScores = selectScenarioScores(state)
  let scenarioScores = {} as GroupedImpactScores
  try {
    const scoreResults = await Promise.all(
      scenarioRuntimeMappings.map(async ({ id, runtimeMappings }) => {
        const scores = await fetchScenarioScores({
          scenario: id,
          runtimeMappings,
          aggs,
        })
        return { id, scores }
      })
    )
    scenarioScores = scoreResults.reduce((acc, { id, scores }) => ({ ...acc, [id]: scores }), {})
  } catch (e) {
    dispatch(
      handleThunkError({
        error: e,
        message: 'There was an error getting scenario level scores for these initiatives.',
        actionType: 'initiatives/getScenarioImpactScores',
        context: { scenarioIds },
      })
    )
  }

  return {
    scenarioScores: { ...existingScenarioScores, ...scenarioScores },
  }
})

// current live scores for an initiative
export const getInitiativeScores = createAsyncThunk<GroupedImpactScores, InitiativeBasic, { state: AppState }>(
  'initiatives/getInitiativeScores',
  async (initiativeDetail, { getState }) => {
    const state = getState()
    const initiativeScores = selectInitiativeScores(state)
    const runtimeMappings = selectProductsRuntimeMappings(initiativeDetail.workspaces?.map((w) => w.id))(state)
    const aggs = selectProductsAggregation(state)

    if (initiativeScores[initiativeDetail.id]) {
      return initiativeScores
    }

    const scores = await fetchNewScenarioScores({
      products: initiativeDetail.products.map((p) => p.id),
      runtimeMappings,
      aggs,
    })

    return scores ? { ...initiativeScores, [initiativeDetail.id]: scores } : initiativeScores
  }
)

export const getInitiativeDetail = createAsyncThunk<InitiativeBasic[], number, { state: AppState }>(
  'initiatives/getInitiativeDetail',
  async (id, { getState, dispatch }) => {
    const initiatives = selectInitiatives(getState())
    const initiativeDetail = await fetchInitiativeDetail(id)

    // The initiative detail includes only the vendor (brand) ids
    // e.g. initiativeDetail.brands = [{ id: 1 }, { id: 2 }]
    // so fetch the full vendor details for each of the ids
    // e.g. [{ id: 1, name: 'Vendor 1' }, { id: 2, name: 'Vendor 2' }]
    const vendorIds = initiativeDetail.brands.map((brand) => brand.id)
    let vendors: ProductBrand[] = []
    if (vendorIds.length) {
      const response = await fetchBrandsByIds(vendorIds)
      // If we don't get a match on a vendor id, use the id as the name
      vendors = vendorIds.map(
        (vendorId) =>
          response.items.find((brand) => brand.id === vendorId) || { id: vendorId, name: vendorId.toString() }
      )
    }

    dispatch(getInitiativeScores(initiativeDetail))

    // Insert the full vendor info into the initiative detail
    return initiatives.map((initiative) => {
      return initiative.id === initiativeDetail.id ? { ...initiativeDetail, brands: vendors } : initiative
    })
  }
)

/**
 * @param initiativeId - (number)
 * This thunk will update the current initiative and fetch the baseline metadata for that initiative.
 * It will also update the chart metric if this initiative does not have goals set for that metric.
 * If the initiative is not provided, it will just set the current initiative to null.
 */
export const setCurrentInitiativeAndChartMetric = createAsyncThunk<number, number | void, { state: AppState }>(
  'initiatives/setCurrentInitiativeAndChartMetric',
  async (initiativeId, { getState, dispatch }) => {
    if (!initiativeId) {
      return null
    }
    // Must get set before the getState() so the chart metric is set correctly
    dispatch(setCurrentInitiative(initiativeId))
    await dispatch(getInitiativeDetail(initiativeId))
    const state = getState()
    const reports = selectReports(state)
    const initiatives = selectInitiatives(state)
    const initiative = initiatives.find((i) => i.id === initiativeId)
    if (!initiative) {
      return null
    }
    const baseline =
      initiative?.baseline && reports.length ? reports.find((report) => report.id === initiative.baseline.id) : null
    if (baseline && (!baseline?.metadata || isEmpty(baseline.metadata))) {
      // if we do not have that report's metadata, we must fetch it
      dispatch(getDetailReport(baseline.id))
    }
    const goalMetrics = selectCurrentInitiativeGoalMetrics(state)
    const currentChartMetric = selectChartMetricKey(state)
    // if the new initiative doesnt have goals set for the current chart metric,
    // we need to set it to a metric with goals
    if (goalMetrics.length && !goalMetrics.find((m) => m.value === currentChartMetric)) {
      dispatch(
        setChartMetric(goalMetrics.find((m) => m.value === 'cf_flag_impact') ? 'cf_flag_impact' : goalMetrics[0].value)
      )
    }
  }
)

export const setCurrentScenarioAndInitiative = (
  scenarioId?: number
): ThunkAction<void, AppState, number | void, AnyAction> => async (dispatch, getState) => {
  if (!scenarioId) {
    dispatch(setCurrentInitiativeAndChartMetric())
    dispatch(setCurrentScenario(null))
  }
  const state = getState()
  const scenarios = selectSortedScenarios(state)
  const scenario = scenarios.find((s) => s.id === scenarioId)
  if (!scenario?.initiative?.id) {
    return null
  }
  dispatch(setCurrentInitiativeAndChartMetric(scenario?.initiative?.id))
  dispatch(setCurrentScenario(scenarioId))
}

export const getScenarios = createAsyncThunk<Scenario[], void, { state: AppState }>(
  'initiatives/getScenarios',
  async () => {
    const scenarios = await fetchScenarios()
    return scenarios
  }
)

export const getInitiatives = createAsyncThunk<InitiativeBasic[], void, { state: AppState }>(
  'initiatives/getInitiatives',
  async () => await fetchInitiatives()
)

export const createScenario = createAsyncThunk<
  { scenarios: Scenario[]; scenarioScores: GroupedImpactScores },
  {
    name: string
    target_date: number
    initiativeId: number
    productIds: number[]
    workspaceIds?: number[]
    vendorIds?: number[]
  },
  { state: AppState }
>(
  'initiatives/createScenario',
  async (
    { name, target_date, initiativeId, productIds, workspaceIds = [], vendorIds = [] },
    { getState, dispatch }
  ) => {
    dispatch(setShowLiveImpactData(true))
    const state = getState()
    const currentScenarios = selectSortedScenarios(state)
    const existingScenarioScores = selectScenarioScores(state)
    const aggs = selectProductsAggregation(state)
    // when we choose vendors in create new inititiative dialog we don't have workspaceIds
    const runtimeMappings = selectProductsRuntimeMappings(workspaceIds)(state)
    dispatch(setScenarioPageTab(workspaceIds.length ? 'workspaces' : 'formulas'))

    const scenario = await fetchCreateScenario({
      name: name,
      initiative: {
        id: initiativeId,
      },
      target_date: `${target_date}-06-01`,
    })
    let scenarioScores = {} as WorkspaceScores
    try {
      if (!productIds?.length) {
        throw new Error('No products provided to request new scenario scores')
      }
      scenarioScores = await fetchNewScenarioScores({
        products: productIds,
        runtimeMappings,
        vendorIds,
        aggs,
      })
    } catch (e) {
      dispatch(
        handleThunkError({
          error: e,
          message:
            'There was an error getting scores for your newly created scenario. Please try refreshing in a few minutes.',
          actionType: 'initiatives/createScenario',
          context: { productIds, scenario, initiativeId },
        })
      )
    }
    return {
      scenarios: [...currentScenarios, scenario],
      scenarioScores: { ...existingScenarioScores, [scenario.id]: scenarioScores },
    }
  }
)

export const addNewScenario = (scenarioToCreate: {
  name: string
  target_date: number
}): ThunkAction<Promise<void>, AppState, { name: string; target_date: number }, AnyAction> => async (
  dispatch,
  getState
) => {
  const currentInitiative = selectCurrentInitiative(getState())
  dispatch(
    createScenario({
      ...scenarioToCreate,
      initiativeId: currentInitiative.id,
      productIds: currentInitiative.products?.map((p) => p.id),
      workspaceIds: currentInitiative.workspaces.map((ws) => ws.id),
      vendorIds: currentInitiative.brands.map((brand) => brand.id),
    })
  )
}

export const createInitiative = createAsyncThunk<
  GroupedImpactScores,
  { report: Report; history: ReturnType<typeof useHistory>; baselineMetrics: ImpactScore },
  { state: AppState }
>('initiatives/createInitiative', async ({ report, history, baselineMetrics }, { getState, dispatch }) => {
  const state = getState()
  const initiative: NewInitiative = selectNewInitiative(state)
  const initiativeScores = selectInitiativeScores(getState())
  const groupByMethod: InitiativeGroupByMethod = selectCreateNewInitiativeGroupByMethod(state)
  const isGroupByWorkspaces = groupByMethod === 'workspaces'

  const requestBody: {
    name: string
    baseline: {
      id: number
    }
    workspaces?: { id: number }[]
    brands?: { id: number }[]
  } = {
    name: initiative.initiativeName,
    baseline: { id: report.id },
  }

  if (isGroupByWorkspaces) {
    requestBody.workspaces = initiative.workspaceIds.map((id) => ({ id }))
  } else {
    requestBody.brands = initiative.vendorIds.map((id) => ({ id }))
  }

  // When we are creating from the initiatives page we will want to update the initiatives list here
  const createdInitiative = await fetchHelperV2<InitiativeBasic>({
    url: 'initiatives/',
    method: 'POST',
    body: JSON.stringify(requestBody),
  })
  dispatch(addNewInitiative(createdInitiative))
  history.push(`/initiatives/${createdInitiative.id}`)
  await dispatch(
    createScenario({
      name: initiative.scenarioName,
      initiativeId: createdInitiative.id,
      target_date: initiative.targetYear,
      productIds: createdInitiative.products.map((p) => p.id),
      workspaceIds: isGroupByWorkspaces ? initiative.workspaceIds : [],
      vendorIds: !isGroupByWorkspaces ? initiative.vendorIds : [],
    })
  )
  return { ...initiativeScores, [createdInitiative.id]: baselineMetrics }
})

export const createInitiativeWithBaseline = createAsyncThunk<
  void,
  { csv: string; history: ReturnType<typeof useHistory> },
  { state: AppState }
>('initiatives/createInitiativeWithBaseline', async ({ csv, history }, { getState, dispatch }) => {
  const state = getState()
  const initiative: NewInitiative = selectNewInitiative(state)
  const baselineMetrics = selectProductMetrics(state)

  const blob = new Blob([csv], { type: 'text/csv' })
  const reader = new FileReader()
  reader.onload = async (e) => {
    const report: Report = await dispatch(
      saveReport({
        file: (e.target.result as string).split(',')[1],
        report_type: 'baseline',
        name: `${initiative.initiativeName} Baseline.csv`,
        metadata: baselineMetrics,
      })
    ).unwrap()

    // again passing as a callback so we can wait to navigate until after the initiative has
    // been created. This can not be awaited from within the reader methods
    dispatch(createInitiative({ report, history, baselineMetrics }))
  }
  reader.readAsDataURL(blob)
})

const handleInvalidInitiativeOrScenario = (
  invalidType: 'initiative' | 'scenario',
  history: ReturnType<typeof useHistory>
): ThunkAction<Promise<void>, AppState, number, AnyAction> => async (dispatch) => {
  dispatch(
    addMessage({
      message: `This ${invalidType} id is invalid. If you believe this is an error, contact support@howgood.com`,
      severity: 'error',
    })
  )
  history.push('/initiatives')
}

export const initializeInitiativeDetail = (
  initiativeId: number,
  history: ReturnType<typeof useHistory>
): ThunkAction<Promise<void>, AppState, number, AnyAction> => async (dispatch, getState) => {
  dispatch(setShowLiveImpactData(true))
  const state = getState()
  const newInitiative = selectNewInitiative(state)
  const initiatives = selectInitiatives(state)
  const initiativeToSet = initiatives.find((i) => i.id === initiativeId)
  const scenarios = selectScenariosByInitiativeId(initiativeId)(state)
  const workspaceIds = []
  initiatives.forEach((i) => {
    workspaceIds.push(...i.workspaces.map((w) => w.id))
  })
  if (!newInitiative) {
    await dispatch(
      getScenarioImpactScores({
        scenarioIds: scenarios.map((s) => s.id),
        workspaceIds,
      })
    )
  }
  if (initiativeToSet) {
    await dispatch(setCurrentInitiativeAndChartMetric(initiativeId))
  } else {
    // This will happen if the user tries to navigate to an initiative that they don't have access to
    dispatch(handleInvalidInitiativeOrScenario('initiative', history))
  }
}

/**
 * This is a thunk that is used to initialize the scenario page. It will fetch the scenarios and validate the scenarioId
 * from the url. If the scenarioId is invalid it will redirect the user to the initiatives page. If the scenarioId is valid it will
 * fetch the scenario detail and set the current initiative. It will also set up the product filters and fetch the product list.
 */
export const initializeScenarioPage = createAsyncThunk<
  { updatedScenarios: Scenario[]; scenarioId: number; scenarioPageTab: ScenarioTab },
  { scenarioId: number; history: ReturnType<typeof useHistory> },
  { state: AppState }
>('initiatives/initializeScenarioPage', async ({ scenarioId, history }, { getState, dispatch }) => {
  dispatch(setShowLiveImpactData(true))
  // fetch scenarios and validate scenario id from url
  const state = getState()
  const initiatives = selectInitiatives(state)
  const scenarios = selectSortedScenarios(state)

  const foundScenario = scenarios.find((s) => s.id === scenarioId)
  if (!foundScenario) {
    dispatch(handleInvalidInitiativeOrScenario('scenario', history))
    return { updatedScenarios: scenarios, scenarioId: null, scenarioPageTab: null }
  }

  // get scenario initiative and set as current initiative, or handle if it doesn't exist
  const initiativeId = foundScenario.initiative.id
  const scenarioInitiative = initiatives.length ? initiatives.find((i) => i.id === initiativeId) : null
  if (!scenarioInitiative) {
    dispatch(handleInvalidInitiativeOrScenario('scenario', history))
    return { updatedScenarios: scenarios, scenarioId: null, scenarioPageTab: null }
  }

  await dispatch(setCurrentInitiativeAndChartMetric(initiativeId))

  // Set up filters and fetch product list
  dispatch(setShowAtRiskOnly(false))

  // Clear out url, all filters should be set to default initial for this page
  // This is necessary for the rollup tiles to initialize properly, unfiltered
  window.history.replaceState({}, '', `/initiatives/${initiativeId}/scenarios/${scenarioId}`)
  dispatch(updateProductFilters({ ...initialState.productFilters, statuses: [], scenario: scenarioId }))
  dispatch(getProducts({}))

  // get scenario detail info and update scenario list and currentScenario
  const scenarioDetail = await fetchScenarioDetail(scenarioId)
  const updatedScenarios = scenarios.map((scenario) => (scenario.id === scenarioDetail.id ? scenarioDetail : scenario))

  // It's possible the user switched from a workspaces scenario to a vendors scenario, or vice versa
  // so make sure the selected tab is valid and default to the "groupBy" method if not
  const selectedTab = selectScenarioPageTab(state)
  const groupByMethod = scenarioInitiative.brands.length ? 'vendors' : 'workspaces'
  const scenarioPageTab = [groupByMethod, 'formulas'].includes(selectedTab) ? selectedTab : groupByMethod

  // Fetch the scenario scores
  dispatch(
    getScenarioImpactScores({
      scenarioIds: [foundScenario.id],
      workspaceIds: scenarioInitiative.workspaces.map((w) => w.id),
    })
  )
  return { updatedScenarios, scenarioId, scenarioPageTab }
})

export const updateScenario = createAsyncThunk<Scenario[], Partial<Scenario>, { state: AppState }>(
  'initiatives/updateScenario',
  async (scenario, { getState, dispatch }) => {
    const allScenarios = selectSortedScenarios(getState())
    const updated = await fetchUpdateScenario(scenario)
    const original = allScenarios.find((s) => s.id === scenario.id)
    if ((!original.goals || isEmpty(original.goals)) && updated.goals && !isEmpty(updated.goals)) {
      dispatch(setChartMetric(Object.keys(updated.goals)[0]))
    }
    return allScenarios.map((s) => (s.id === scenario.id ? updated : s))
  }
)

export const deleteScenario = createAsyncThunk<number, number, { state: AppState }>(
  'initiatives/deleteScenario',
  async (id) => {
    await fetchDeleteScenario(id)
    return id
  }
)

export interface ScenarioHit {
  scenarios: Scenario[]
}

// Find all cloned scenario products derived from the specified source product
export const fetchAllClones = createAsyncThunk<VersionData, number, { state: AppState }>(
  'initiatives/fetchAllClones',
  async (sourceProductId, { dispatch }) => {
    // Get all scenarios that include the specified source product
    const [scenarioResults, cloneResults] = await Promise.allSettled([
      fetchElasticV2<ScenarioHit>({
        url: FORMULATIONS_SEARCH_PATH,
        body: {
          size: 10000,
          from: 0,
          _source: ['scenarios'],
          query: {
            match: {
              pk: sourceProductId,
            },
          },
        },
      }),
      // Get all cloned products with the same source product
      fetchElasticV2<ProductClone>({
        url: FORMULATIONS_SEARCH_PATH,
        body: {
          size: 10000,
          from: 0,
          _source: ['pk', 'name', 'scenarios', 'source_product', 'workspaces'],
          query: {
            match: {
              'source_product.id': sourceProductId,
            },
          },
        },
      }),
    ])

    if (scenarioResults.status === 'rejected' || cloneResults.status === 'rejected') {
      dispatch(addMessage({ message: 'Error fetching product versions', severity: 'error' }))
    }

    const versionData = {
      scenarios:
        scenarioResults.status === 'fulfilled'
          ? scenarioResults.value.hits.hits
              .map((hit) => {
                // If all scenarios already include a cloned version, `hit._source.scenarios` won't exist
                return (hit._source.scenarios || []).map((s) => s.id)
              })
              .flat()
          : [],
      clones:
        cloneResults.status === 'fulfilled'
          ? cloneResults.value.hits.hits.map((hit) => hit._source).filter((hit) => hit.scenarios?.length > 0)
          : [],
      sourceProductId: sourceProductId,
    } as VersionData
    return versionData
  }
)

export const deleteInitiative = createAsyncThunk<InitiativeBasic[], number, { state: AppState }>(
  'initiatives/deleteInitiative',
  async (id, { getState, dispatch }) => {
    const initiatives = selectInitiatives(getState())
    const initiative = initiatives.find((i) => i.id === id)
    try {
      await fetchDeleteInitiative(id)
      dispatch(
        addMessage({ message: `Successfully deleted initiative ${initiative?.name || ''}`, severity: 'success' })
      )
    } catch (e) {
      dispatch(
        addMessage({ message: `There was an error deleting initiative ${initiative?.name || ''}`, severity: 'error' })
      )
      throw e
    }
    return initiatives.filter((i) => i.id !== id)
  }
)
