import React, { forwardRef } from 'react'
import MuiStepper, { StepperProps as MuiStepperProps } from '@mui/material/Stepper'
import MuiStepButton, { StepButtonProps as MuiStepButtonProps } from '@mui/material/StepButton'
import MuiStep, { StepProps as MuiStepProps } from '@mui/material/Step'

interface StepperProps extends Omit<MuiStepperProps, 'className'> {}
interface StepButtonProps extends Omit<MuiStepButtonProps, 'className'> {}
interface StepProps extends Omit<MuiStepProps, 'className'> {}

export const Stepper = forwardRef<HTMLDivElement, StepperProps>(({ ...rest }, ref) => {
  return <MuiStepper ref={ref} {...rest} />
})

export const StepButton = forwardRef<HTMLButtonElement, StepButtonProps>(({ ...rest }, ref) => {
  return <MuiStepButton ref={ref} {...rest} />
})

export const Step = forwardRef<HTMLDivElement, StepProps>(({ ...rest }, ref) => {
  return <MuiStep ref={ref} {...rest} />
})

Stepper.displayName = 'Stepper'
StepButton.displayName = 'StepButton'
Step.displayName = 'Step'
