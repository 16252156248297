import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IUser, Provider, Organization, DEFAULT_USER_ID } from '@/records'
import {
  emailSubmit,
  exchangeToken,
  getUser,
  login,
  LoginType,
  logout,
  setIgnoreUserSettings,
  updateDashboardSettings,
  updateProductListSelectedColumns,
  updateVendorManagementSettings,
} from './user.thunk'

export interface State {
  isLoading: boolean
  isError: boolean
  user: IUser
  organization: Organization
  loginChallangeRequired: LoginType
  loginPending: boolean
}
export const initialState: State = {
  isLoading: false,
  isError: false,
  user: {
    authenticated: Boolean(localStorage.getItem('auth')),
    id: DEFAULT_USER_ID,
    email: '',
    first_name: '',
    last_name: '',
    title: '',
    permissions: { products: [], ingredients: [], accounts: [] },
    groups: [],
    provider: '',
    auth0_organization: '',
    organization: 0,
    user_settings: {},
  } as IUser,
  organization: {
    organizationId: 0,
    organizationName: '',
    salesforceAccountId: '',
    activeStatus: '',
    customerType: '',
  } as Organization,
  loginChallangeRequired: '',
  loginPending: false,
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    providerSelected: (
      state,
      action: PayloadAction<{ auth_provider: Provider; username: string; auth0_organization: string }>
    ) => {
      state.user.provider = action.payload.auth_provider
      state.user.email = action.payload.username
      state.user.auth0_organization = action.payload.auth0_organization
    },
    editEmail: (state) => {
      state.user = { ...initialState.user, email: state.user.email }
    },
    setLoginChallenge: (state, action) => {
      state.loginChallangeRequired = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.isError = false
      state.user = { ...state.user, ...action.payload, authenticated: true }
    })
    builder.addCase(getUser.rejected, (state) => {
      state.isError = true
      state.user.authenticated = false
    })
    builder.addCase(login.pending, (state) => {
      state.loginPending = true
    })
    builder.addCase(login.fulfilled, (state, action) => {
      state.isError = false
      if (!action.payload) {
        state.user.authenticated = true
      }
      state.loginChallangeRequired = action.payload
      state.loginPending = false
    })
    builder.addCase(login.rejected, (state) => {
      state.isError = true
      state.user.authenticated = false
      state.loginPending = false
    })
    builder.addCase(exchangeToken.fulfilled, (state) => {
      state.user.authenticated = true
    })
    builder.addCase(exchangeToken.rejected, (state) => {
      state.user.authenticated = false
    })
    builder.addCase(emailSubmit.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(emailSubmit.fulfilled, (state) => {
      state.isLoading = false
    })
    builder.addCase(emailSubmit.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(logout.fulfilled, (state) => {
      state.user.authenticated = false
    })
    builder.addCase(logout.rejected, (state) => {
      state.user.authenticated = false
    })

    builder.addCase(updateDashboardSettings.fulfilled, (state, action) => {
      if (action.payload) {
        state.user.user_settings = action.payload
      }
    })
    builder.addCase(updateDashboardSettings.rejected, (state) => {
      state.user.user_settings = initialState.user.user_settings
    })

    builder.addCase(setIgnoreUserSettings.fulfilled, (state, action) => {
      if (action.payload) {
        state.user.user_settings = action.payload
      }
    })
    builder.addCase(setIgnoreUserSettings.rejected, (state) => {
      state.user.user_settings = initialState.user.user_settings
    })

    builder.addCase(updateProductListSelectedColumns.fulfilled, (state, action) => {
      if (action.payload) {
        state.user.user_settings = action.payload
      }
    })

    builder.addCase(updateVendorManagementSettings.fulfilled, (state, action) => {
      if (action.payload) {
        state.user.user_settings = action.payload
      }
    })
    builder.addCase(updateVendorManagementSettings.rejected, (state) => {
      state.user.user_settings = initialState.user.user_settings
    })
  },
})

export const { providerSelected, editEmail, setLoginChallenge } = userSlice.actions

export const userReducer = userSlice.reducer
