import { createAsyncThunk } from '@reduxjs/toolkit'
import { AppState } from '@/store'
import { SalesCategory } from './salesCategories.slice'
import { BenchmarkData, fetchAllBenchmarkData, fetchAllSalesCategories } from './salesCategories.requests'

export const getSalesCategories = createAsyncThunk<
  { salesCategories: SalesCategory[]; benchmarkData: BenchmarkData },
  void,
  { state: AppState }
>('salesCategories/getSalesCategories', async () => {
  const [salesCategoriesResponse, benchmarkDataResponse] = await Promise.allSettled([
    fetchAllSalesCategories(),
    fetchAllBenchmarkData(),
  ])
  if (salesCategoriesResponse.status === 'rejected') {
    throw new Error('There was an error fetching sales categories')
  }
  const salesCategories = salesCategoriesResponse.value
  const benchmarkData = benchmarkDataResponse.status === 'fulfilled' ? benchmarkDataResponse.value : {}
  return { salesCategories, benchmarkData }
})
