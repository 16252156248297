import {
  AttributeEntry,
  ContentfulDataHealthIndicatorKey,
  ContentfulDocumentKey,
  ContentfulFormulaIconKey,
  ContentfulLinkKey,
  ContentfulMethodologyKey,
  ContentfulRequestVendorDataKey,
  ContentfulTooltipKey,
  DataHealthIndicatorEntry,
  DocumentEntry,
  FormulaIconEntry,
  LinkEntry,
  MethodologyEntry,
  MetricEntry,
  RequestVendorDataEntry,
  TooltipEntry,
} from './types'
import { useSelector } from 'react-redux'
import {
  selectContentfulAttributeData,
  selectContentfulDataHealthIndicatorData,
  selectContentfulDocumentData,
  selectContentfulFormulaIconData,
  selectContentfulLinkData,
  selectContentfulMethodologyData,
  selectContentfulMetricData,
  selectContentfulRequestVendorDataData,
  selectContentfulTooltipData,
} from '@/state/contentfulData'
import { useMemo } from 'react'
import { getKeyWithoutAggregateOption } from './utils'

export const useContentfulMetric = (fieldKey: string): MetricEntry => {
  const contentfulData = useSelector(selectContentfulMetricData)
  const key = getKeyWithoutAggregateOption(fieldKey)

  const contentfulEntry = useMemo(() => Object.values(contentfulData).find((metric) => metric.key === key), [
    contentfulData,
    key,
  ])
  return contentfulEntry
}

// Returns metric entries in the order requested, i.e. the order of the fieldKeys
export const useUnsortedContentfulMetrics = (fieldKeys: string[]): MetricEntry[] => {
  const contentfulData = useSelector(selectContentfulMetricData)

  const contentfulEntries = useMemo(
    () =>
      fieldKeys.reduce((acc: MetricEntry[], fieldKey) => {
        const key = getKeyWithoutAggregateOption(fieldKey)
        const entry = Object.values(contentfulData).find((metric) => metric.key === key)
        return entry ? [...acc, entry] : acc
      }, []),
    [contentfulData, fieldKeys]
  )
  return contentfulEntries
}

// Returns metric entries in the order specified in the contentfulData
export const useSortedContentfulMetrics = (fieldKeys: string[]): MetricEntry[] => {
  const contentfulData = useSelector(selectContentfulMetricData)

  return useMemo(
    () =>
      contentfulData.reduce((acc, entry) => {
        const fieldKey = fieldKeys.find((key) => getKeyWithoutAggregateOption(key) === entry.key)
        return fieldKey ? [...acc, entry] : acc
      }, []),
    [contentfulData, fieldKeys]
  )
}

export const useContentfulAttribute = (fieldKey: string): AttributeEntry => {
  const contentfulData = useSelector(selectContentfulAttributeData)

  const contentfulEntry = useMemo(() => Object.values(contentfulData).find((metric) => metric.key === fieldKey), [
    contentfulData,
    fieldKey,
  ])
  return contentfulEntry
}

export const useContentfulAttributes = (fieldKeys: string[]): AttributeEntry[] => {
  const contentfulData = useSelector(selectContentfulAttributeData)

  const contentfulEntries = useMemo(
    () =>
      fieldKeys.reduce((acc: AttributeEntry[], fieldKey) => {
        const entry = Object.values(contentfulData).find((attribute) => attribute.key === fieldKey)
        return entry ? [...acc, entry] : acc
      }, []),
    [contentfulData, fieldKeys]
  )
  return contentfulEntries
}

export const useContentfulDocument = (key: ContentfulDocumentKey): DocumentEntry => {
  const contentfulData = useSelector(selectContentfulDocumentData)

  const contentfulEntry = useMemo(() => Object.values(contentfulData).find((item) => item.key === key), [
    contentfulData,
    key,
  ])
  return contentfulEntry
}

export const useContentfulTooltip = (key: ContentfulTooltipKey): TooltipEntry => {
  const contentfulData = useSelector(selectContentfulTooltipData)

  const contentfulEntry = useMemo(() => Object.values(contentfulData).find((item) => item.key === key), [
    contentfulData,
    key,
  ])
  return contentfulEntry
}

export const useContentfulLink = (key: ContentfulLinkKey): LinkEntry => {
  const contentfulData = useSelector(selectContentfulLinkData)

  const contentfulEntry = useMemo(() => Object.values(contentfulData).find((item) => item.key === key), [
    contentfulData,
    key,
  ])
  return contentfulEntry
}

export const useContentfulRequestVendorData = (key: ContentfulRequestVendorDataKey): RequestVendorDataEntry => {
  const contentfulData = useSelector(selectContentfulRequestVendorDataData)

  const contentfulEntry = useMemo(() => Object.values(contentfulData).find((data) => data.key === key), [
    contentfulData,
    key,
  ])
  return contentfulEntry
}

export const useContentfulMethodology = (key: ContentfulMethodologyKey): MethodologyEntry => {
  const contentfulData = useSelector(selectContentfulMethodologyData)

  const contentfulEntry = useMemo(() => Object.values(contentfulData).find((methodology) => methodology.key === key), [
    contentfulData,
    key,
  ])
  return contentfulEntry
}

export const useContentfulDataHealthIndicator = (key: ContentfulDataHealthIndicatorKey): DataHealthIndicatorEntry => {
  const contentfulData = useSelector(selectContentfulDataHealthIndicatorData)

  const contentfulEntry = useMemo(
    () => Object.values(contentfulData).find((dataHealthIndicator) => dataHealthIndicator.key === key),
    [contentfulData, key]
  )
  return contentfulEntry
}

export const useContentfulDataHealthIndicators = (
  fieldKeys: ContentfulDataHealthIndicatorKey[]
): DataHealthIndicatorEntry[] => {
  const contentfulData = useSelector(selectContentfulDataHealthIndicatorData)

  const contentfulEntries = useMemo(
    () =>
      fieldKeys.reduce((acc: DataHealthIndicatorEntry[], fieldKey) => {
        const entry = Object.values(contentfulData).find((item) => item.key === fieldKey)
        return entry ? [...acc, entry] : acc
      }, []),
    [contentfulData, fieldKeys]
  )
  return contentfulEntries
}

export const useContentfulFormulaIcon = (key: ContentfulFormulaIconKey): FormulaIconEntry => {
  const contentfulData = useSelector(selectContentfulFormulaIconData)

  const contentfulEntry = useMemo(() => Object.values(contentfulData).find((item) => item.key === key), [
    contentfulData,
    key,
  ])
  return contentfulEntry
}

export const useContentfulFormulaIcons = (keys: ContentfulFormulaIconKey[]): FormulaIconEntry[] => {
  const contentfulData = useSelector(selectContentfulFormulaIconData)

  const contentfulEntries = useMemo(
    () =>
      keys.reduce((acc: FormulaIconEntry[], key) => {
        const entry = Object.values(contentfulData).find((item) => item.key === key)
        return entry ? [...acc, entry] : acc
      }, []),
    [contentfulData, keys]
  )
  return contentfulEntries
}
