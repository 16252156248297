import React, { ChangeEvent, FC } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@howgood/design'
import { ReportFormFields } from './ReportFormFields'

interface Props {
  open: boolean
  name: string
  year: number
  saveDisabled: boolean
  onClose: () => void
  onSave: () => void
  onChangeName: (e: ChangeEvent<HTMLInputElement>) => void
}

export const EditReportDialog: FC<Props> = ({ open, name, year, saveDisabled, onClose, onSave, onChangeName }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle data-testid="dialog-title">Edit report details</DialogTitle>
      <DialogContent>
        <Stack pt={1} gap={2}>
          <ReportFormFields name={name} year={year ? year.toString() : ''} yearDisabled onChangeName={onChangeName} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button data-testid="cancel-button" onClick={onClose}>
          Cancel
        </Button>
        <Button data-testid="save-button" color="primary" disabled={saveDisabled} onClick={onSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
