import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@howgood/design'
import { getTopProductsAllMetrics, selectSelectedWorkspace, setSelectedWorkspace } from '../state/productDashboard'
import { selectEditableWorkspaces } from '@/state/workspaces'
import { Workspace } from '@/records'

const allWorkspacesItem = { id: -1, name: 'All Workspaces' } as Workspace

export const WorkspaceSelect: FC = () => {
  const editableWorkspaces = useSelector(selectEditableWorkspaces)
  const selectedWorkspace = useSelector(selectSelectedWorkspace)
  const dispatch = useDispatch()

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const workspace = event.target.value as Workspace
    dispatch(setSelectedWorkspace(workspace))
    dispatch(getTopProductsAllMetrics())
  }

  const allWorkspaceOptions = [allWorkspacesItem, ...editableWorkspaces]
  const selectedWorkspaceOption = allWorkspaceOptions.find((workspace) => workspace.id === selectedWorkspace.id)

  return (
    <FormControl>
      <InputLabel id="workspace-select-label">Workspace</InputLabel>
      <Select
        labelId="workspace-select-label"
        id="workspace-select"
        value={selectedWorkspaceOption || ''}
        renderValue={(value) => (value as Workspace).name}
        label="Workspace"
        onChange={handleChange}
      >
        {[allWorkspacesItem, ...editableWorkspaces].map((workspace) => (
          <MenuItem key={workspace.id} value={workspace as any}>
            {workspace.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
