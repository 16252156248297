import React, { FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Text,
  TextField,
} from '@howgood/design'
import { InitiativeBasic } from '@/records'
import { deleteInitiative } from '@/state/initiatives'

interface Props {
  open: boolean
  initiative: InitiativeBasic
  onDelete?: () => void
  onClose: () => void
}

export const DeleteInitiativeDialog: FC<Props> = ({ open, initiative, onClose, onDelete }) => {
  const dispatch = useDispatch()
  const [inputText, setInputText] = useState('')

  const handleDelete = async () => {
    if (inputText !== 'delete') return
    await dispatch(deleteInitiative(initiative.id))
    if (onDelete) onDelete()
  }

  return (
    <Dialog open={open} onClose={onClose} data-testid="delete-initiative-dialog">
      <DialogTitle data-testid="delete-initiative-title">Delete this initiative?</DialogTitle>
      <DialogContent>
        <Stack pt={1} gap={2}>
          <Text variant="body1">
            <b>{initiative.name}</b> and all of its scenarios and scenario products will be permanently deleted.
          </Text>
          <TextField
            label="Type “delete” to permanently delete this initiative"
            color="primary"
            value={inputText}
            inputProps={{ 'data-testid': 'delete-input' }}
            onEnter={handleDelete}
            autoFocus
            fullWidth
            onChange={(e) => setInputText(e.target.value)}
          />
          <Alert data-testid="delete-warning" severity="warning">
            <Text fontWeight="bold" color="inherit">
              This cannot be undone
            </Text>
          </Alert>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button data-testid="cancel-button" onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={inputText !== 'delete'} data-testid="delete-button" color="error" onClick={handleDelete}>
          Delete Forever
        </Button>
      </DialogActions>
    </Dialog>
  )
}
