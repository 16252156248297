import React, { ChangeEvent, FC, ReactNode, useState } from 'react'
import { useSelector } from 'react-redux'
import { faCommentPen } from '@fortawesome/pro-regular-svg-icons'
import { Alert, IconButton, Stack, Text, TextField } from '@howgood/design'
import { formatScore } from '../CarbonAccounting.utils'
import { FormActivityValues, CategoryWithScore } from '../CarbonAccounting.types'
import { CarbonAccountingReportActivity, selectCarbonAccountingCurrentReport } from '@/state/carbonAccounting'

interface FormRowProps {
  children: ReactNode
}

const FormRow: FC<FormRowProps> = ({ children }) => {
  return (
    <Stack flexDirection="row" justifyContent="space-between" gap={2}>
      {children}
    </Stack>
  )
}

export interface Props {
  category: CategoryWithScore
  formActivityValues: FormActivityValues
  isStale: boolean
  onChange: (value: Partial<CarbonAccountingReportActivity>, id: number) => void
}

export const CarbonAccountingDialogForm: FC<Props> = ({ category, formActivityValues, isStale, onChange }) => {
  const report = useSelector(selectCarbonAccountingCurrentReport)
  const [editNotes, setEditNotes] = useState(false)

  if (!category?.clickable) {
    return null
  }

  return (
    <Stack gap={2}>
      <Text variant="body2" color="secondary">
        Enter values for the activities that apply to your organization. Minimum of one activity required.
      </Text>
      <Stack gap={1}>
        <FormRow>
          <Text color="secondary">Activity</Text>
          <Text color="secondary">Activity data</Text>
        </FormRow>
        {category.activities.map((activity) => {
          const emissionsFactor = formActivityValues[activity.id]
          return (
            <Stack key={activity.id} gap={1}>
              <FormRow key={activity.id}>
                <Stack mt={1}>
                  <Text>{activity.label}</Text>
                  <Text variant="body2" color="secondary">
                    {activity.description}
                  </Text>
                </Stack>
                <Stack flexDirection="row" minWidth={170} width={170}>
                  <Stack mt={1} mr={1}>
                    <IconButton data-testid="add-notes" icon={faCommentPen} onClick={() => setEditNotes(!editNotes)} />
                  </Stack>
                  <TextField
                    type="number"
                    name={activity.label}
                    value={emissionsFactor?.activity_value || ''}
                    helperText={activity.units}
                    inputProps={{
                      ['data-testid']: 'activity-input',
                      min: 0,
                      // Disable changing number input via scroll by unfocusing element
                      onWheel: (e) => e.currentTarget.blur(),
                    }}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      // If report has no entered emissions factors, use the activity's associated entry by year as a fallback
                      // If no entries have a year associated with this report, use the first entry as a fallback
                      const entryId =
                        emissionsFactor?.emissions_factor_entry?.id ||
                        activity.factor?.entries?.find((entry) => entry.year === report?.year)?.id ||
                        activity.factor?.entries?.[0]?.id
                      // Require positive number, fall back to 0 if value is removed
                      const activityValue = e.target.value ? Math.abs(parseInt(e.target.value, 10)) : 0
                      const payload = { activity_value: activityValue, emissions_factor_entry: { id: entryId } }
                      onChange(payload, activity.id)
                    }}
                  />
                </Stack>
              </FormRow>
              {activity.warning && (
                <Alert data-testid="carbon-accounting-activity-warning" severity="warning">
                  {activity.warning}
                </Alert>
              )}
              {editNotes ? (
                <Stack mb={4}>
                  <TextField
                    label="Notes"
                    value={emissionsFactor?.notes || ''}
                    inputProps={{ ['data-testid']: 'activity-notes-field' }}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      // Valid activity_value is required in request payload
                      const activityValue = emissionsFactor?.activity_value
                      if (typeof +activityValue === 'number') {
                        onChange({ notes: e.target.value }, activity.id)
                      }
                    }}
                  />
                </Stack>
              ) : (
                <Text data-testid="activity-notes" variant="body2" color="secondary">
                  {emissionsFactor?.notes ? `Notes: ${emissionsFactor?.notes}` : ''}
                </Text>
              )}
            </Stack>
          )
        })}
      </Stack>
      <Stack alignItems="flex-end">
        <Stack flexDirection="row" alignItems="center" justifyContent="space-between" width="100%">
          <Text fontWeight={600}>Total category emissions</Text>
          <Text
            data-testid="carbon-accounting-dialog-score"
            fontSize="32px"
            lineHeight="32px"
            fontWeight={700}
            color={isStale ? 'text.disabled' : 'text.primary'}
          >
            {formatScore(category.score)}
          </Text>
        </Stack>
        <Text variant="caption">{category.units}</Text>
      </Stack>
    </Stack>
  )
}
