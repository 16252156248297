import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Document } from '@contentful/rich-text-types'
import { useSources } from '@/api'
import { RichText } from '@/contentfully'
import { selectSavedProductInfo } from '@/state/productOverview'

interface Props {
  sources: Document
}

export const SourceList: FC<Props> = ({ sources }) => {
  const product = useSelector(selectSavedProductInfo)
  const { data: productSources } = useSources(product.id)

  if (!productSources || productSources.length === 0) {
    return <RichText document={sources} />
  }

  return (
    <>
      <RichText document={sources} />
      <h2>Sources used in assessment of {product.name}</h2>
      {productSources.map((source) => (
        <p key={source}>
          <a href={source}>{source}</a>
        </p>
      ))}
    </>
  )
}
