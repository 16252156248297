import { datadogRum } from '@datadog/browser-rum'
import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { IntercomProvider } from 'react-use-intercom'
import { SWRConfig } from 'swr'
import ReactGA from 'react-ga4'
import browserUpdate from 'browser-update'
import { ConnectedRouter } from 'connected-react-router'
import { Auth0Provider } from '@auth0/auth0-react'
import { LicenseInfo } from '@mui/x-license'
import { MUIThemeProvider } from '@howgood/design'
import history from './utils/history'
import routes from './routes'
import { ContentfulProvider } from './contentfully'
import pendoInit from './pendoInit'
import { PendoProvider } from './PendoProvider'
import { SplitProvider } from './SplitProvider'
import { store } from './store'

import './sass/index.scss'

// Install MUI license (for data-grid-premium)
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_PREMIUM_LICENSE_KEY)

// initialize pendo
pendoInit(process.env.REACT_APP_PENDO_API_KEY)

if (process.env.REACT_APP_ENVIRONMENT !== 'development') {
  // https://github.com/DataDog/browser-sdk/blob/1c27a/packages/rum/README.md
  datadogRum.init({
    applicationId: 'e2f490e5-2b8e-4cf7-97f0-d4db2807fdad',
    clientToken: 'pubcc1bc6bcd181f5c24aad00173fabf5b8',
    site: 'datadoghq.com',
    service: 'latis',
    env: process.env.REACT_APP_ENVIRONMENT,

    // TODO: Use the output of `git rev-parse --short HEAD` for the version number
    // version: '1.0.0',

    sessionSampleRate: 100,
    // 🚨 Replays Cost $$$ -- So be careful when increasing this number!
    // https://www.datadoghq.com/pricing/?product=real-user-monitoring--session-replay#real-user-monitoring--session-replay
    sessionReplaySampleRate: 0,

    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    // https://docs.datadoghq.com/real_user_monitoring/frustration_signals/
    trackFrustrations: true,
    defaultPrivacyLevel: 'mask-user-input',
    // Don't allow datadog corp to collect telemetry
    telemetrySampleRate: 0,
    useSecureSessionCookie: true,

    // Correlate with backend traces
    // https://docs.datadoghq.com/real_user_monitoring/connect_rum_and_traces/?tab=browserrum#overview
    allowedTracingUrls: [
      /https:\/\/[^/]+\.howgood\.com\/.*/, // production
      /https:\/\/[^/]+\.howgood\.io\/.*/, // staging
    ],
  })

  datadogRum.startSessionReplayRecording()
}

// Set up google analytics
if (process.env.REACT_APP_ENVIRONMENT === 'production') {
  ReactGA.initialize('G-NM6J2P8XWP')
}

browserUpdate({
  required: {
    e: 15,
    i: 11,
    f: 60,
    o: -3,
    s: -3,
    c: 60,
  },
  insecure: true,
  text: {
    msg: "You're browser is out of date.",
    msgmore: 'Please update your browser as this platform does not support past versions.',
  },
})

const renderApp = (appRoutes: React.ReactNode) => {
  const container = document.getElementById('root')
  const root = createRoot(container)
  return root.render(
    <Provider store={store}>
      <PendoProvider>
        <SplitProvider>
          <MUIThemeProvider>
            <IntercomProvider appId={process.env.REACT_APP_INTERCOM_APP_ID}>
              <ContentfulProvider>
                <SWRConfig value={{ revalidateOnFocus: false }}>
                  <Auth0Provider
                    domain={process.env.REACT_APP_AUTH0_DOMAIN}
                    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
                    redirectUri={window.location.origin}
                  >
                    {/* @ts-ignore No overload matches this call */}
                    <ConnectedRouter history={history}>{appRoutes}</ConnectedRouter>
                  </Auth0Provider>
                </SWRConfig>
              </ContentfulProvider>
            </IntercomProvider>
          </MUIThemeProvider>
        </SplitProvider>
      </PendoProvider>
    </Provider>
  )
}

renderApp(routes)
