import {
  VendorInfo,
  UNKNOWN_VENDOR_OPTION_VALUE,
  VendorEngagementStatus,
  VENDOR_ENGAGEMENT_STATUS_COMPLETE,
  VENDOR_ENGAGEMENT_STATUS_IN_PROGRESS,
  VENDOR_ENGAGEMENT_STATUS_NOT_STARTED,
  SbtAction,
  SBT_ACTION_NONE,
  SBT_ACTION_COMMITMENT,
  SBT_ACTION_TARGET_SET,
  SBT_ACTION_NOT_DETERMINED,
  SbtType,
  SBT_TYPE_ABSOLUTE,
  SBT_TYPE_ENGAGEMENT,
  SBT_TYPE_NONE,
  SBT_TYPE_INTENSITY,
  SBT_TYPE_NET_ZERO,
  SBT_TYPE_NO_DEFORESTATION,
  SBT_TYPE_RENEWABLE_ELECTRICITY,
  SBT_TYPE_OTHER,
  SBT_TYPE_NOT_DETERMINED,
} from '@/records'
import { BucketAggs } from './vendorManagement.slice'
import { estypes } from '@elastic/elasticsearch'
import { QueryDslQueryContainer } from '@elastic/elasticsearch/api/types'
import { VendorProductFilters } from './vendorManagement.requests'

export const UNKNOWN_VENDORS_LABEL = 'Unknown Vendors'

interface ESVendorBucket {
  key: string
  doc_count: number
  priority: { value: number }
  manufacturing_types: {
    buckets: { key: string }[]
  }
  sbt_action: { value: number }
  sbt_type: { value: number }
  verified: {
    buckets: { key: 0 | 1; doc_count: number }[] // key 0 or 1 is for boolean type
  }
  top_value_hits: {
    hits: {
      hits: { _source: { brand: { id: number } } }[]
    }
  }
  mt_per_year_sum: { value: number }
  annual_carbon_footprint_farm_to_gate_impact: estypes.AggregationsStatsAggregate
  scope_3_category_1_impact: estypes.AggregationsStatsAggregate
  scope_3_category_4_impact: estypes.AggregationsStatsAggregate
  scope_3_total_impact: estypes.AggregationsStatsAggregate
  // Include average for all supported metrics here
  carbon_footprint_farm_to_gate_impact: estypes.AggregationsStatsAggregate
  raw_blue_water_usage_impact: estypes.AggregationsStatsAggregate
}

export interface ESVendorData {
  scope_3_total_impact_sum: { value: number }
  vendors: { buckets: ESVendorBucket[] }
}

function getSbtAction(action: number): SbtAction {
  switch (action) {
    case 1:
      return SBT_ACTION_COMMITMENT
    case 2:
      return SBT_ACTION_TARGET_SET
    case 1000:
      return SBT_ACTION_NONE
    default:
      return SBT_ACTION_NOT_DETERMINED
  }
}

function getSbtType(type: number): SbtType {
  switch (type) {
    case 1:
      return SBT_TYPE_ABSOLUTE
    case 2:
      return SBT_TYPE_ENGAGEMENT
    case 3:
      return SBT_TYPE_NET_ZERO
    case 4:
      return SBT_TYPE_INTENSITY
    case 5:
      return SBT_TYPE_NO_DEFORESTATION
    case 6:
      return SBT_TYPE_RENEWABLE_ELECTRICITY
    case 7:
      return SBT_TYPE_OTHER
    case 1000:
      return SBT_TYPE_NONE
    default:
      return SBT_TYPE_NOT_DETERMINED
  }
}

export const prepareVendorData = (aggregations: ESVendorData): { vendorData: VendorInfo[]; bucketAggs: BucketAggs } => {
  const { scope_3_total_impact_sum, vendors, ...bucketAggs } = aggregations
  const scope3PortfolioTotal = scope_3_total_impact_sum?.value
  const buckets = vendors?.buckets || []

  const vendorData = buckets.reduce((acc: VendorInfo[], vendor) => {
    const brandId = vendor.top_value_hits?.hits?.hits?.[0]?._source?.brand?.id
    const manufacturingTypes = vendor.manufacturing_types?.buckets?.map((item) => item.key) || []

    const scope3TotalImpact = vendor.scope_3_total_impact?.sum
    const scope3Contribution = scope3TotalImpact / scope3PortfolioTotal

    const engagementStatusKeys = vendor.verified?.buckets?.map((item) => item.key) || []
    let engagementStatus: VendorEngagementStatus = VENDOR_ENGAGEMENT_STATUS_NOT_STARTED
    // engagementStatusKeys length can be max 2 (0 and 1)
    if (engagementStatusKeys.length === 2) {
      engagementStatus = VENDOR_ENGAGEMENT_STATUS_IN_PROGRESS
    } else if (engagementStatusKeys[0] === 1) {
      engagementStatus = VENDOR_ENGAGEMENT_STATUS_COMPLETE
    }

    const vendorInfo = {
      ...vendor,
      name: vendor.key,
      count: vendor.doc_count,
      mtPerYear: vendor.mt_per_year_sum?.value,
      priority: vendor.priority?.value,
      sbtAction: getSbtAction(vendor.sbt_action?.value),
      sbtType: getSbtType(vendor.sbt_type?.value),
      brandId,
      manufacturingTypes,
      engagementStatus,
      scope3Contribution,
    }

    // Combine Unknown Vendors with empty string value vendors
    if ([UNKNOWN_VENDORS_LABEL, ''].includes(vendor.key)) {
      const unknownVendorsBucket = acc.find((elem) => elem.name === UNKNOWN_VENDORS_LABEL)
      if (unknownVendorsBucket) {
        unknownVendorsBucket.count += vendor.doc_count
      } else {
        acc.push({
          ...vendorInfo,
          name: UNKNOWN_VENDORS_LABEL,
          brandId: UNKNOWN_VENDOR_OPTION_VALUE,
        })
      }
    } else {
      acc.push(vendorInfo)
    }
    return acc
  }, [])

  return { vendorData, bucketAggs }
}

// The toolbar filters map to these ES query fields
const filterTerms = [
  'workspaces.id',
  'brand.name',
  'manufacturing_type.id',
  'formulation_status',
  'inventories.formulation_tags.keyword',
]

export function createESQuery(vendorProductFilters: VendorProductFilters): QueryDslQueryContainer {
  const {
    workspaceIds = [],
    manufacturingTypeIds = [],
    vendorNameQueries: vendorNames = [],
    formulationStatuses: statusTypes = ['pipeline'],
    formulationTags: tags = [],
  } = vendorProductFilters

  // Handle terms except vendor name filters
  const terms = filterTerms.reduce((acc, field) => {
    if (field === 'workspaces.id' && workspaceIds.length > 0) {
      return [...acc, { terms: { [field]: workspaceIds } }]
    }
    if (field === 'formulation_status' && statusTypes.length > 0) {
      return [...acc, { terms: { [field]: statusTypes } }]
    }
    if (field === 'manufacturing_type.id' && manufacturingTypeIds.length > 0) {
      return [...acc, { terms: { [field]: manufacturingTypeIds } }]
    }
    if (field === 'inventories.formulation_tags.keyword' && tags.length > 0) {
      return [...acc, { terms: { [field]: tags } }]
    }
    return acc
  }, [])

  const query: QueryDslQueryContainer = {
    bool: {
      // Include only products with a brand name specified
      must: { exists: { field: 'brand.name.keyword' } },
      must_not: { term: { 'brand.name.keyword': '' } },
      // Include the filter terms
      filter: terms,
      // Handle vendor names, if any
      minimum_should_match: vendorNames.length > 0 ? 1 : undefined,
      should: vendorNames.length
        ? vendorNames.map((filter) => ({
            multi_match: {
              query: filter,
              type: 'phrase_prefix',
              fields: ['brand.name'],
            },
          }))
        : undefined,
    },
  }
  return query
}
