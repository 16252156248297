import React, { FC, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Autocomplete, MenuItem, TextField, Tooltip } from '@howgood/design'
import {
  DATA_GRANULAR_OPTIONS,
  DataGranularOption,
  selectDataGranularityFilters,
  selectEditingFilter,
  setEditingFilter,
  updateFiltersUrlAndProducts,
} from '@/state/products'
import { selectIsProductFiltersLoading } from '@/selectors/selectIsProductFiltersLoading'
import { selectMaterialIconAgpracticeSplit } from '@/state/splitio'
import { MATERIAL_TYPE_VENDOR_VERIFIED_PLUS } from '@/api'

export const DATA_GRANULAR_SEARCH_TEST_ID = 'data-granular-search'

export const DataGranularityProductFilter: FC = () => {
  const dispatch = useDispatch()
  const isLoading = useSelector(selectIsProductFiltersLoading)
  const filteredDataGranulars = useSelector(selectDataGranularityFilters)
  const materialIconAgpracticeEnabled = useSelector(selectMaterialIconAgpracticeSplit)
  const editingFilter = useSelector(selectEditingFilter)

  const handleDataGranularityChange = async (options: DataGranularOption[]) => {
    dispatch(updateFiltersUrlAndProducts({ updatedFilters: { dataGranulars: options } }))
    dispatch(setEditingFilter(DATA_GRANULAR_SEARCH_TEST_ID))
  }

  const options = useMemo(
    () =>
      materialIconAgpracticeEnabled
        ? DATA_GRANULAR_OPTIONS
        : DATA_GRANULAR_OPTIONS.filter((opt) => opt.value !== MATERIAL_TYPE_VENDOR_VERIFIED_PLUS),
    [materialIconAgpracticeEnabled]
  )

  return (
    <Tooltip title="Filter formulas by data granularity" placement="top">
      <Autocomplete
        id={DATA_GRANULAR_SEARCH_TEST_ID}
        data-testid={DATA_GRANULAR_SEARCH_TEST_ID}
        data-pendo-id="SearchDataGranularity"
        options={options}
        value={filteredDataGranulars}
        filterSelectedOptions
        multiple
        onChange={(_event, ops) => handleDataGranularityChange(ops)}
        disabled={isLoading}
        fullWidth
        componentsProps={{ popper: { style: { width: 'fit-content' }, placement: 'bottom-start' } }}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            autoFocus={editingFilter === DATA_GRANULAR_SEARCH_TEST_ID}
            placeholder="Data Granularity"
          />
        )}
        renderOption={(props, option) => (
          <MenuItem
            {...props}
            key={option.value}
            value={option}
            onClick={() => handleDataGranularityChange([...filteredDataGranulars, option])}
          >
            {option.label}
          </MenuItem>
        )}
      />
    </Tooltip>
  )
}
