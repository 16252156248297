import React, { FC } from 'react'
import { Document, BLOCKS } from '@contentful/rich-text-types'
import { NodeRenderer } from '@contentful/rich-text-react-renderer'
import { Text, Stack, List } from '@howgood/design'
import { RichText } from '@/contentfully'

const RVDParagraphRenderer: NodeRenderer = (_node, children) => {
  return <Text>{children}</Text>
}

const OrderedListRenderer: NodeRenderer = (_node, children) => {
  return (
    <Stack p="14px 0 14px 6px">
      <List listStyleType="decimal">{children}</List>
    </Stack>
  )
}

interface Props {
  document: Document
}

export const RequestVendorDataRichText: FC<Props> = ({ document }) => {
  return (
    <RichText
      document={document}
      rendererOptions={{
        renderNode: { [BLOCKS.PARAGRAPH]: RVDParagraphRenderer, [BLOCKS.OL_LIST]: OrderedListRenderer },
      }}
    />
  )
}
