import { createAsyncThunk } from '@reduxjs/toolkit'
import { AppState } from '@/store'
import { selectUserOrganizationId } from '../user'
import {
  postCarbonAccountingReport,
  patchCarbonAccountingReport,
  fetchCarbonAccountingReports,
  fetchEmissionsActivities,
  fetchCarbonAccountingReport,
  fetchCarbonAccountingReportActivities,
  postCarbonAccountingReportActivities,
  putCarbonAccountingReportActivities,
  deleteCarbonAccountingReport,
  putCarbonAccountingReport,
  fetchCarbonAccountingAvailableYears,
} from './carbonAccounting.requests'
import { CarbonAccountingReport, CarbonAccountingReportActivity, EmissionsActivity } from './carbonAccounting.types'
import { selectCarbonAccountingReportActivities, selectCarbonAccountingReportById } from './carbonAccounting.selectors'

export const getEmissionsActivites = createAsyncThunk<EmissionsActivity[], number>(
  'carbonAccounting/getEmissionsActivites',
  async (reportYear) => await fetchEmissionsActivities(reportYear)
)

export const createCarbonAccountingReport = createAsyncThunk<
  CarbonAccountingReport,
  Partial<CarbonAccountingReport>,
  { state: AppState }
>('carbonAccounting/createCarbonAccountingReport', async (body, { getState }) => {
  const state = getState()
  const organizationId = selectUserOrganizationId(state)
  return await postCarbonAccountingReport({ ...body, organization: { id: organizationId } })
})

interface UpdateCarbonAccountingReport {
  reportId: number
  body: Partial<CarbonAccountingReport>
}
export const updateCarbonAccountingReport = createAsyncThunk<CarbonAccountingReport, UpdateCarbonAccountingReport>(
  'carbonAccounting/updateCarbonAccountingReport',
  async ({ reportId, body }) => {
    return await patchCarbonAccountingReport({ reportId, body })
  }
)
interface ReplaceCarbonAccountingReport {
  reportId: number
  body: Partial<CarbonAccountingReport>
}
export const replaceCarbonAccountingReport = createAsyncThunk<
  CarbonAccountingReport,
  ReplaceCarbonAccountingReport,
  { state: AppState }
>('carbonAccounting/replaceCarbonAccountingReport', async ({ reportId, body }, { getState }) => {
  const state = getState()
  const organizationId = selectUserOrganizationId(state)
  const originalReport = selectCarbonAccountingReportById(reportId)(state)
  const updatedReport = { ...originalReport, ...body, organization: { id: organizationId } }
  return await putCarbonAccountingReport({
    reportId,
    body: updatedReport,
  })
})

export const removeCarbonAccountingReport = createAsyncThunk<number, number>(
  'carbonAccounting/removeCarbonAccountingReport',
  async (reportId) => {
    await deleteCarbonAccountingReport(reportId)
    return reportId
  }
)

export const getCarbonAccountingReports = createAsyncThunk<CarbonAccountingReport[], void, { state: AppState }>(
  'carbonAccounting/getCarbonAccountingReports',
  async () => {
    const reports = await fetchCarbonAccountingReports()
    const reportsWithScores = []
    for (let report of reports) {
      const reportWithScores = await fetchCarbonAccountingReport(report.id)
      reportsWithScores.push(reportWithScores)
    }
    return reportsWithScores
  }
)

export const getCarbonAccountingReport = createAsyncThunk<CarbonAccountingReport, number>(
  'carbonAccounting/getCarbonAccountingReport',
  async (reportId) => await fetchCarbonAccountingReport(reportId)
)

export const getCarbonAccountingReportActivities = createAsyncThunk<CarbonAccountingReportActivity[], number>(
  'carbonAccounting/getCarbonAccountingReportActivities',
  async (reportId) => await fetchCarbonAccountingReportActivities(reportId)
)

export const getCarbonAccountingAvailableYears = createAsyncThunk<number[]>(
  'carbonAccounting/getCarbonAccountingAvailableYears',
  async () => await fetchCarbonAccountingAvailableYears()
)

interface UpdateCarbonAccountingReportActivities {
  reportId: number
  activityId: number
  entryId: number
  activityUpdate: CarbonAccountingReportActivity
}
export const updateCarbonAccountingReportActivities = createAsyncThunk<
  void,
  UpdateCarbonAccountingReportActivities,
  { state: AppState }
>(
  'carbonAccounting/updateCarbonAccountingReportActivities',
  async ({ reportId, activityId, entryId, activityUpdate }, { getState, dispatch }) => {
    const state = getState()
    const reportActivities = selectCarbonAccountingReportActivities(state)
    const activity = reportActivities.find((a) => a.activity.id === activityId)
    const body = entryId
      ? {
          activity: { id: activityId },
          emissions_factor_entry: { id: entryId },
          ...activityUpdate,
        }
      : {
          activity: { id: activityId },
          ...activityUpdate,
        }
    if (activity) {
      await putCarbonAccountingReportActivities({
        reportId,
        reportActivityId: activity?.id,
        body,
      })
    } else {
      await postCarbonAccountingReportActivities({ reportId, body })
    }
    // Refresh current report
    dispatch(getCarbonAccountingReport(reportId))
    // Refresh current report activities
    dispatch(getCarbonAccountingReportActivities(reportId))
  }
)
