import { createAsyncThunk } from '@reduxjs/toolkit'
import { IProductStandard } from '@/records'
import { fetchHelperV2 } from '@/api'

export const getProductStandards = createAsyncThunk<IProductStandard[]>(
  'productStandards/getProductStandards',
  async () => {
    const standards = await fetchHelperV2<IProductStandard[]>({ url: `standards/` })
    return standards.filter((standard) => standard.identifier)
  }
)
