import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'
import { SearchOption } from '../types'

const useStyles = createUseStyles({
  option: {
    display: 'flex',
    alignItems: 'baseline',
    padding: '4px 10px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#DEEBFF',
    },
  },
  label: {
    paddingLeft: '4px',
  },
})

interface MultiSelectOptionProps {
  option: SearchOption
  onClick: () => void
  checked: boolean
}

export const MultiSelectOption: FC<MultiSelectOptionProps> = ({ checked, onClick, option }) => {
  const classes = useStyles()

  return (
    <div key={option.value} onClick={onClick} className={classes.option}>
      <input type="checkbox" checked={checked} />
      <div className={classes.label}>{option.label}</div>
    </div>
  )
}
