type ElasticsearchFormulationTagSource = Readonly<{
  [key: string]: any
  name: string
}>

export interface ElasticsearchFormulationTagHit extends Readonly<Record<string, any>> {
  _source?: ElasticsearchFormulationTagSource
}

export interface IFormulationTagSearchOption {
  raw: ElasticsearchFormulationTagHit
  name: string
  id: number
  label: string
  value: number
  workspaces: { id: number; name: string }[]
}

export class FormulationTagSearchOption {
  /**
   * An option used in the Formulation Tags autocomplete select, created using a single `hit`
   * from an Elasticsearch request.
   */

  raw: ElasticsearchFormulationTagHit
  name: string
  id: number
  label: string
  value: number
  workspaces: { id: number; name: string }[]

  constructor(hit: ElasticsearchFormulationTagHit) {
    this.raw = hit
    this.name = hit._source.name
    this.id = hit._source.id
    this.workspaces = hit._source.workspaces || []
    this.label = hit._source.name
    this.value = hit._source.id
  }
}
