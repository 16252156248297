import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  selectEditingFilter,
  selectRegionsFilters,
  setEditingFilter,
  updateFiltersUrlAndProducts,
} from '@/state/products'
import { selectIsProductFiltersLoading } from '@/selectors/selectIsProductFiltersLoading'
import { RegionsDropdownFilter } from '../Shared/Filters/RegionsDropdownFilter'
import { IRegionOption } from '@/records'

export const MaterialDestinationFilter: FC = () => {
  const dispatch = useDispatch()
  const isLoading = useSelector(selectIsProductFiltersLoading)
  const selectedRegions = useSelector(selectRegionsFilters)
  const editingFilter = useSelector(selectEditingFilter)
  const id = 'material-destination-search'

  const setSelectedRegions = (regions: IRegionOption[]) => {
    dispatch(updateFiltersUrlAndProducts({ updatedFilters: { regions } }))
    dispatch(setEditingFilter(id))
  }

  return (
    <RegionsDropdownFilter
      placeholder="Material Destination"
      setSelectedRegions={setSelectedRegions}
      selectedRegions={selectedRegions}
      disabled={isLoading}
      autoFocus={editingFilter === id}
      id={id}
    />
  )
}
