import {
  defaultFormulationStatusesForAnalytics,
  FormulationStatusOption,
  IBrandSearchOption,
  IFormulationSearchOption,
  IngredientFilterSearchOption,
  IProduct,
  IRegionOption,
  WorkspaceOption,
  WorkspaceType,
} from '@/records'
import { FormulationTag, MaterialType } from '@/api'
import { ProductListDataRowExport } from '@/utils/products'

export interface ProductSortOption {
  [key: string]: {
    order: 'asc' | 'desc'
  }
}

export type DataGranularOptionValue = MaterialType | 'known-vendors' | 'unknown-vendors'
export interface DataGranularOption {
  label: string
  value: DataGranularOptionValue
}
export interface FormulationTagFilterOption extends FormulationTag {
  label: string
  value: number
}
export interface ProductFilters {
  query: string[]
  statuses: FormulationStatusOption[]
  ingredients: IngredientFilterSearchOption[]
  workspaces: WorkspaceOption[]
  ingredientIds: string[]
  sortOptions: ProductSortOption[]
  formulationTags: FormulationTagFilterOption[]
  components: IFormulationSearchOption[]
  vendors: IBrandSearchOption[]
  salesCategories: string[]
  workspaceTypes: WorkspaceType[]
  page: number
  editingFilter: string
  scenario: number
  standardsIds: number[]
  regions: IRegionOption[]
  dataGranulars: DataGranularOption[]
}
export interface ActiveBulkJob {
  jobId: string
  productIds: number[]
}
export interface State {
  isLoading: boolean
  hasError: boolean
  tableProducts: IProduct[]
  allProducts: ProductListDataRowExport[]
  total: number
  showLiveImpactData: boolean
  showAtRiskOnly: boolean
  productFilters: ProductFilters
  activeBulkJob: ActiveBulkJob
  customAggs: Record<string, number> // Aggregations (sums) of any custom runtime mapping fields
}

const initialProductFilters = {
  query: [],
  statuses: defaultFormulationStatusesForAnalytics,
  ingredients: [],
  workspaces: [],
  ingredientIds: [],
  sortOptions: [],
  formulationTags: [],
  components: [],
  vendors: [],
  salesCategories: [],
  workspaceTypes: [],
  page: 1,
  editingFilter: '',
  scenario: null,
  standardsIds: [],
  regions: [],
  dataGranulars: [],
}

export const initialState: State = {
  isLoading: false,
  hasError: false,
  tableProducts: [],
  allProducts: [],
  total: 0,
  showLiveImpactData: false,
  showAtRiskOnly: false,
  productFilters: initialProductFilters,
  activeBulkJob: null,
  customAggs: {},
}
