import React, { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dialog, DialogTitle, DialogContent, IconButton, Text, Tooltip } from '@howgood/design'
import { replaceIngredient } from '@/state/recipe'
import { DRAFT, selectPageView } from '@/state/pageSettings'
import { IngredientSearch } from '@/components'
import { selectSavedProductInfo } from '@/state/productOverview'
import { ElasticsearchIngredientHit } from '@/records'
import { RecipeIngredient, RecipeNestedIngredient } from '@/state/recipe/recipe.state'
import { faArrowsRotate } from '@fortawesome/pro-regular-svg-icons'

interface ReplaceItemProps {
  ingredient: RecipeIngredient | RecipeNestedIngredient
}

export const ReplaceItem: FC<ReplaceItemProps> = ({ ingredient }) => {
  const dispatch = useDispatch()
  const view = useSelector(selectPageView)
  const product = useSelector(selectSavedProductInfo)

  const [replaceItemOpen, setReplaceItemOpen] = useState(false)

  const handleReplaceItem = () => {
    setReplaceItemOpen(true)
  }
  const handleCloseReplaceItem = () => {
    setReplaceItemOpen(false)
  }

  const handleItemSelected = (item: ElasticsearchIngredientHit) => {
    dispatch(replaceIngredient(item.raw._source, ingredient))
    setReplaceItemOpen(false)
  }

  if (!ingredient.isTopLevel) {
    return null
  }

  return (
    <>
      <Tooltip childrenDisabled={view !== DRAFT} title={<Text>Replace ingredient</Text>}>
        <IconButton
          id={`replace-ingredient-button-${ingredient.index}`}
          aria-label="Replace this ingredient"
          icon={faArrowsRotate}
          disabled={view !== DRAFT}
          onClick={handleReplaceItem}
        />
      </Tooltip>
      <Dialog id="replace-ingredient-dialog" open={replaceItemOpen} onClose={handleCloseReplaceItem}>
        <DialogTitle>{`Search to replace "${ingredient.name}"`}</DialogTitle>
        <DialogContent>
          <IngredientSearch
            onChange={handleItemSelected}
            placeholderText="Replace Ingredient"
            productId={`${product.id}`}
            autoFocus
          />
        </DialogContent>
      </Dialog>
    </>
  )
}
