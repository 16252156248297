import React, { FC, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, IconButton, Stack, Text, TextField, Tooltip } from '@howgood/design'
import { faPen, faWandMagicSparkles } from '@fortawesome/pro-regular-svg-icons'
import {
  updateIngredient,
  setIsAutoWeightField,
  redistributeAutoWeights,
  selectDisplayedWeightOption,
  RecipeIngredient,
  RecipeNestedIngredient,
} from '@/state/recipe'
import { DRAFT, selectPageView } from '@/state/pageSettings'
import { PERCENTAGES } from '@/records'

interface WeightInputProps {
  ingredient: RecipeIngredient | RecipeNestedIngredient
}

export const WeightInput: FC<WeightInputProps> = ({ ingredient }) => {
  const dispatch = useDispatch()
  const view = useSelector(selectPageView)
  const weightOption = useSelector(selectDisplayedWeightOption)

  const [weight, setWeight] = useState(ingredient.weight?.toString() || '0')

  const inputRef = useRef()

  const dotPath = ingredient.dot_path

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value: stringValue } = e.target
    const numericValue = +stringValue
    if (!Number.isNaN(numericValue)) {
      setWeight(stringValue)
    }
  }

  const handleIconButtonClicked = () => {
    if (ingredient.isAutoWeight) {
      dispatch(setIsAutoWeightField({ dotPath: dotPath, value: false }))
    } else {
      dispatch(setIsAutoWeightField({ dotPath: dotPath, value: true }))
      dispatch(redistributeAutoWeights())
    }
  }

  // After updating an ingredient weight, update the recipe and redistribute weights
  const handleOnBlur = async (fieldValue: string) => {
    if (!isNaN(parseFloat(fieldValue)) && fieldValue !== ingredient.weight?.toString()) {
      const updatedWeights = {
        flat_weight: +fieldValue,
        weight: +fieldValue,
      }
      dispatch(updateIngredient(ingredient, updatedWeights))
    }
  }

  useEffect(() => {
    setWeight(ingredient.weight?.toString() || '0')
  }, [ingredient.weight])

  return (
    <Stack direction="row" width="100%">
      {ingredient.isAutoWeight ? (
        <Box ml={1} flexGrow={1}>
          <Text>{weight}</Text>
        </Box>
      ) : (
        <TextField
          id={`weight-field-${ingredient.index}`}
          aria-label="Percentage weight for this ingredient"
          color="secondary"
          ref={inputRef}
          disabled={ingredient.isAutoWeight || view !== DRAFT}
          value={weight}
          onChange={handleOnChange}
          onBlur={(e) => handleOnBlur(e.currentTarget.value)}
          sx={{
            '& .MuiInputBase-root': { bgcolor: 'white' },
            '& .MuiInputBase-input': { px: 1, py: 0.5 },
          }}
        />
      )}
      {weightOption === PERCENTAGES && (
        <Tooltip
          title={
            <Text>
              {ingredient.isAutoWeight
                ? 'Click to enter weight for this ingredient manually.'
                : 'Click to enable automatic weight redistribution for this ingredient.'}
            </Text>
          }
        >
          <Box display="flex" alignItems="center" ml={0.5}>
            <IconButton
              id={`lock-weight-button-${ingredient.index}`}
              aria-label="Lock weight for this ingredient"
              size="small"
              icon={ingredient.isAutoWeight ? faPen : faWandMagicSparkles}
              onClick={handleIconButtonClicked}
            />
          </Box>
        </Tooltip>
      )}
    </Stack>
  )
}
