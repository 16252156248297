import { AnyAction } from 'redux'
import { ThunkAction } from '@reduxjs/toolkit'
import { AppState } from '@/store'
import { setGenerateReport, setReportToolkitDrawer } from './pageSettings.slice'

// need for auto generating report outside of drawer
export const autoGenerateReport = (): ThunkAction<void, AppState, void, AnyAction> => async (dispatch) => {
  dispatch(setReportToolkitDrawer(true))
  dispatch(setGenerateReport(true))
}
