import React, { FC, forwardRef } from 'react'
import MuiLink, { LinkProps as MuiLinkProps } from '@mui/material/Link'

interface ExternalLinkProps
  extends Pick<MuiLinkProps, 'href' | 'target' | 'rel' | 'color' | 'variant' | 'underline' | 'children' | 'onClick'> {
  ['data-testid']?: string
  disabled?: boolean
}

export const ExternalLink: FC<ExternalLinkProps> = forwardRef<HTMLAnchorElement, ExternalLinkProps>(
  (
    { ['data-testid']: dataTestId, href, target, rel, color, variant, underline, children, disabled, onClick, ...rest },
    ref
  ) => {
    return (
      <MuiLink
        data-testid={dataTestId}
        ref={ref}
        href={href}
        target={target}
        rel={rel}
        variant={variant}
        color={color}
        underline={underline}
        onClick={onClick}
        display="flex"
        alignItems="center"
        gap={1}
        sx={{
          cursor: 'pointer',
          pointerEvents: disabled ? 'none' : undefined,
          '&:hover': { color },
        }}
        {...rest}
      >
        {children}
      </MuiLink>
    )
  }
)

ExternalLink.displayName = 'ExternalLink'
