import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import {
  ColGroup,
  ColMapInstance,
  ProductColFields,
  basics,
  details,
  fieldColMap,
  workflow,
} from '../utils/productListColDefs'
import { selectIsProcurement, selectPageType } from '@/state/router'
import { Field } from '@/constants/impactScore'
import { allNonMetricColumns } from '../utils/ProductList.columns'
import { FormulaNameRenderer, ProductListRenderCellParams, ProductsGridColDef } from '@/components/GridCellRenderers'
import { selectIsSupplierConnect } from '@/state/organization'

// Header name changes based on page context
const useNameColumn = (showBulkActions: boolean, scenarioId?: number): ProductsGridColDef => {
  const isProcurement = useSelector(selectIsProcurement)

  return useMemo(
    () => ({
      headerName: isProcurement ? 'Material' : 'Formula',
      field: 'name',
      minWidth: 150,
      hideable: false,
      sortfield: 'name.keyword',
      renderCell: (params) => (
        <FormulaNameRenderer
          {...(params as ProductListRenderCellParams)}
          showBulkActions={showBulkActions}
          scenarioId={scenarioId}
        />
      ),
    }),
    [isProcurement, scenarioId, showBulkActions]
  )
}

/**
 * get all the non metric fields that are part of the provided colGroup
 * then return the corresponding columns
 * @param colGroup as ColGroup
 * @returns columns as ProductsGridColDef[]
 */
const useNonMetricColumns = (colGroup: ColGroup): ProductsGridColDef[] => {
  const productListType = useSelector(selectPageType)
  const isSupplierConnect = useSelector(selectIsSupplierConnect)

  return useMemo(() => {
    // get all the non metric fields that are part of the provided colGroup
    const columnFieldNames: (Field | ProductColFields)[] = Object.entries(fieldColMap)
      .filter(
        ([_field, data]: [Field | ProductColFields, ColMapInstance]) =>
          data.metricGroupKey === 'nonMetric' &&
          data[productListType].colGroup === colGroup &&
          (!isSupplierConnect || data.supplierConnect)
      )
      .map(([field, _data]: [Field | ProductColFields, ColMapInstance]) => field)
    return allNonMetricColumns.filter((col) => columnFieldNames.includes(col.field as Field | ProductColFields))
  }, [productListType, colGroup, isSupplierConnect])
}

export const useDefaultColumns = (showBulkActions: boolean, scenarioId?: number): ProductsGridColDef[] => {
  const nameColumn = useNameColumn(showBulkActions, scenarioId)
  const nonMetricColumns = useNonMetricColumns(basics)
  return [nameColumn, ...nonMetricColumns]
}

export const useDetailColumns = (): ProductsGridColDef[] => {
  return useNonMetricColumns(details)
}

export const useWorkflowColumns = (): ProductsGridColDef[] => {
  return useNonMetricColumns(workflow)
}
