import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Accordion, AccordionDetails, Box } from '@howgood/design'

import { CarbonLifecycle } from '@/components'
import { useCarbonLifecycleMetrics } from '@/hooks'
import { DRAFT, selectPageView } from '@/state/pageSettings'
import { AccordionCardHeader } from '../AccordionCardHeader'
import { selectIsRollup } from '@/state/router'
import { selectCarbonLifecycleImpactScore, selectLifecycleBenchmarkScores } from '@/selectors/selectScoresData'

interface Props {
  chartOnly?: boolean
  isFavorites?: boolean
  size?: 'small' | 'default'
}

export const LifecycleChart: FC<Props> = ({ chartOnly, isFavorites, size = 'default' }) => {
  const impactScore = useSelector(selectCarbonLifecycleImpactScore)
  const view = useSelector(selectPageView)
  const metrics = useCarbonLifecycleMetrics()
  const isRollup = useSelector(selectIsRollup)
  const benchmarkData = useSelector(selectLifecycleBenchmarkScores)

  const chart = (
    <CarbonLifecycle
      metrics={metrics}
      impactScore={impactScore}
      isInnovationMode={view === DRAFT || isRollup}
      benchmarkImpactScore={benchmarkData}
      size={size}
    />
  )

  if (chartOnly) {
    return chart
  }

  return (
    <Accordion id="carbon-lifecycle-panel" data-testid="carbon-lifecycle-panel" defaultExpanded>
      <AccordionCardHeader
        cardKey="lifecycle"
        id="carbon-lifecycle-panel-header"
        title="Carbon Lifecycle"
        compareTo={true}
        favoritesTab={isFavorites}
      />
      <AccordionDetails>
        <Box>{chart}</Box>
      </AccordionDetails>
    </Accordion>
  )
}
