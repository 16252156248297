import { theme } from '@howgood/design'
import { formatNumber } from '@howgood/utils'

export function formatScore(score: number): string {
  return typeof score === 'number' && score !== 0 ? formatNumber(score) : '- -'
}

export type Scope = 'scope_1' | 'scope_2' | 'scope_3'

export const scopeToFillMap: Record<Scope, string> = {
  scope_1: theme.palette.info.light,
  scope_2: theme.palette.warning.light,
  scope_3: theme.palette.success.light,
}
