import React, { forwardRef, useState } from 'react'
import MuiSelect, { SelectProps as MuiSelectProps } from '@mui/material/Select'
import { getHasValue } from './Select.utils'

export type { SelectChangeEvent } from '@mui/material/Select'

interface SelectProps extends Omit<MuiSelectProps, 'className'> {
  color?: 'error' | 'secondary' | 'primary' | 'info'
  'data-testid'?: string 
}

export const Select = forwardRef<HTMLSelectElement, SelectProps>(({ ['data-testid']: dataTestId, displayEmpty, error = false, required, size = 'small', value, children, sx, ...rest }, ref) => {
  const [validationError, setValidationError] = useState(error)
  const hasValue = getHasValue(value)
  const hasError = validationError || error

  return (
      <MuiSelect 
        data-testid={hasError ? `${dataTestId}-error` : dataTestId}
        ref={ref} 
        displayEmpty={displayEmpty} 
        size={size}
        value={value}
        error={hasError}
        required={required}
        onFocus={() => {
          if (required) setValidationError(false)
        }}
        onBlur={() => {
          if (required) setValidationError(!hasValue)
        }}
        sx={{ background: 'white', ...sx }}
        {...rest}
      >
        {children}
      </MuiSelect>
  )
})

Select.displayName = 'Select'
