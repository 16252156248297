import { useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import {
  selectUserAnalysisImpactPermissions,
  selectProductMetricsPermissions,
  selectUserProductsPermissions,
  selectCarbonLifecyclePermissions,
} from '@/state/user'
import {
  VIEW_AGG_IMPACT_HOWGOOD_ONE_METRIC,
  VIEW_AGG_IMPACT_HOWGOOD_TOTAL,
  VIEW_AGG_IMPACT_APPLEGATE,
  PERMISSIONS_TO_FIELDS_MAP,
  Field,
} from '@/constants/impactScore'
import { MetricEntry } from '@/contentfully'
import { useSortedContentfulMetrics } from '@/contentfully/hooks'

export const useProductMainMetrics = (): string[] => {
  const permissions = useSelector(selectUserAnalysisImpactPermissions, shallowEqual)

  return useMemo(
    () =>
      [
        VIEW_AGG_IMPACT_APPLEGATE,
        VIEW_AGG_IMPACT_HOWGOOD_TOTAL,
        VIEW_AGG_IMPACT_HOWGOOD_ONE_METRIC,
      ].filter((permission) => permissions.includes(permission)),
    [permissions]
  )
}

export const useProductDetailedMetrics = (): string[] => {
  return useSelector(selectProductMetricsPermissions)
}

interface PermissionMetric {
  title: string
  subtitle: string
  permission: string
  field: Field
  elasticField: string
  units: string
  helpText: string
}

export type CarbonLifecycleMetrics = PermissionMetric[]

const preparePermissionMetric = (permission: string, metrics: MetricEntry[]): PermissionMetric => {
  const field = PERMISSIONS_TO_FIELDS_MAP[permission]
  const metric = metrics.find((m) => m.key === field)
  return {
    permission,
    title: metric?.title,
    subtitle: metric?.subtitle,
    field: metric?.key,
    elasticField: `impact_score.${field}`,
    units: metric?.units,
    helpText: metric?.helpText,
  }
}

export const useCarbonLifecycleMetrics = (): CarbonLifecycleMetrics => {
  const permissions = useSelector(selectCarbonLifecyclePermissions, shallowEqual)
  const fields = useMemo(() => permissions.map((permission) => PERMISSIONS_TO_FIELDS_MAP[permission]), [permissions])
  const metrics = useSortedContentfulMetrics(fields)

  return useMemo(() => permissions.map((permission) => preparePermissionMetric(permission, metrics)), [
    permissions,
    metrics,
  ])
}

export const useMainMetric = (): string => {
  const permissions = useProductMainMetrics()

  if (permissions.length > 0) {
    return permissions[0]
  }

  return null
}

export const useHasFormulationsAccess = (): boolean => {
  const permissions = useSelector(selectUserProductsPermissions, shallowEqual)
  return permissions?.includes('view_formulation_ui') ?? false
}

export const useHasProcurementAccess = (): boolean => {
  const permissions = useSelector(selectUserProductsPermissions, shallowEqual)
  return permissions?.includes('view_procurement') ?? false
}

export const usePrimaryAnalysisMetric = () => {
  const analysisImpactPermissions = useSelector(selectUserAnalysisImpactPermissions)
  const permission = analysisImpactPermissions.includes(VIEW_AGG_IMPACT_HOWGOOD_ONE_METRIC)
    ? VIEW_AGG_IMPACT_HOWGOOD_ONE_METRIC
    : VIEW_AGG_IMPACT_HOWGOOD_TOTAL

  return PERMISSIONS_TO_FIELDS_MAP[permission]
}
