import React, { ChangeEvent, FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress, Radio, RadioGroup, Text, Stack, FormControlLabel } from '@howgood/design'

import { selectIsFormulaOveriewLoading } from '@/state/recipe'
import { selectCompareTo, setCompareTo } from '@/state/pageSettings'
import { selectIsCreateNew } from '@/state/productOverview'
import { selectIsSupplierConnect } from '@/state/organization'

export const CompareToOptions: FC = () => {
  const isLoading = useSelector(selectIsFormulaOveriewLoading)
  const dispatch = useDispatch()
  const compareTo = useSelector(selectCompareTo)
  const isCreateNew = useSelector(selectIsCreateNew)
  const isSupplierConnect = useSelector(selectIsSupplierConnect)

  return (
    <Stack direction="row" display="flex" justifyContent="flex-end" flexGrow={1}>
      {isLoading && (
        <Stack direction="row" justifyContent="center" flexGrow={1} gap={1}>
          <CircularProgress size={20} />
          <Text>Fetching impact scores</Text>
        </Stack>
      )}
      {!isSupplierConnect && (
        <RadioGroup
          id="compare-to-group"
          row
          value={compareTo}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            dispatch(setCompareTo(e.target.value as 'saved' | 'lastChange'))
          }
          onClick={(event: React.MouseEvent<HTMLElement>) => event.stopPropagation()}
        >
          <FormControlLabel
            value="saved"
            control={<Radio id="compare-to-saved" size="small" />}
            label="Compare to saved"
            disabled={isCreateNew}
          />
          <FormControlLabel
            value="lastChange"
            control={<Radio id="compare-to-last-change" size="small" />}
            label="Compare to last change"
          />
        </RadioGroup>
      )}
    </Stack>
  )
}
