import React, { FC } from 'react'
import { IconButton, Tooltip } from '@howgood/design'
import { useDispatch } from 'react-redux'
import { faArrowsRotate } from '@fortawesome/pro-regular-svg-icons'
import { getTopProductsAllMetrics } from '../state/productDashboard'

/**
 * If the user leaves the page to update a product, then returns to the page, the data may be stale.
 * Use this to fetch the latest data from Elasticsearch and display it using the selected workspace/metric/basis.
 */
export const RefreshButton: FC = () => {
  const dispatch = useDispatch()

  return (
    <Tooltip title="Refresh product data">
      <IconButton icon={faArrowsRotate} onClick={async () => dispatch(getTopProductsAllMetrics())} />
    </Tooltip>
  )
}
