import React, { FC, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Box, Text, Tooltip } from '@howgood/design'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { RecipeIngredient, IngredientStandard, RecipeNestedIngredient } from '@/state/recipe/recipe.state'
import { StandardsSelect } from './StandardsSelect'
import { DRAFT, selectPageView } from '@/state/pageSettings'
import { selectDisplayedProductStandards, selectIngredientWarnings } from '@/state/recipe'

interface StandardsProps extends GridRenderCellParams {
  value?: {
    ingredient: RecipeIngredient | RecipeNestedIngredient
    availableStandards: IngredientStandard[]
  }
}

export const Standards: FC<StandardsProps> = (params) => {
  const ingredient = params.value.ingredient
  const view = useSelector(selectPageView)
  const productStandards = useSelector(selectDisplayedProductStandards)
  const ingredientErrors = useSelector(selectIngredientWarnings)
  const invalidIngredient = ingredientErrors.map((error) => error.id).includes(ingredient.id)

  // Includes product level standards
  const standardsString = useMemo(() => {
    if (!('base_id' in ingredient)) {
      return ''
    }
    const setByProduct =
      ingredient.availableStandards?.filter((available) =>
        productStandards.some((productStandard) => productStandard.identifier === available.identifier)
      ) || []
    return [...ingredient.standards, ...setByProduct].map((standard) => standard.title).join(', ')
  }, [ingredient, productStandards])

  // If nested ingredient, don't display anything
  if (!('base_id' in ingredient)) {
    return null
  }

  // Can change standards on top-level ingredients only
  if (ingredient.isTopLevel && view === DRAFT) {
    return <StandardsSelect ingredient={ingredient} />
  }

  return (
    <Tooltip title={standardsString}>
      <Box sx={{ cursor: 'pointer', opacity: view === DRAFT ? 0.7 : 1 }}>
        <Text color={invalidIngredient ? 'error.main' : null} textOverflow="ellipsis" noWrap>
          {standardsString}
        </Text>
      </Box>
    </Tooltip>
  )
}
