import React, { FC } from 'react'
import { IntercomProvider } from 'react-use-intercom'
import { createUseStyles } from 'react-jss'
import { useDispatch } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'

import { logout } from '@/state/user'
import { useToggle } from '@/hooks/useToggle'
import { Profile } from '../Profile'

const useStyles = createUseStyles({
  section: {
    borderTop: '1px solid #fff',
    padding: '20px 0',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
  },
  menuItemBottom: {
    padding: '3px 0 3px 10px',
    display: 'flex',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#343F50',
    },
  },
})

export const SidebarFooter: FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [isProfileDialogOpen, toggleProfileDialog] = useToggle()
  const { logout: auth0Logout } = useAuth0()

  const handleLogout = () => {
    dispatch(logout(auth0Logout))
  }

  return (
    <>
      <div className={classes.section}>
        <a
          data-pendo-id="Menu:HelpCenter"
          className={classes.menuItemBottom}
          href="https://latis-help.howgood.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Help Center
        </a>
        <div data-pendo-id="Menu:Profile" className={classes.menuItemBottom} onClick={toggleProfileDialog}>
          Profile
        </div>
        <a
          data-pendo-id="Menu:PrivacyPolicy"
          className={classes.menuItemBottom}
          href="https://legal.howgood.com/#contract-s1ym-khba"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
        <a
          data-pendo-id="Menu:AboutHowGood"
          className={classes.menuItemBottom}
          href="https://howgood.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          About HowGood
        </a>
        <div
          aria-label="Sign out"
          data-pendo-id="Menu:SignOut"
          className={classes.menuItemBottom}
          onClick={handleLogout}
        >
          Sign Out
        </div>
      </div>
      {isProfileDialogOpen && (
        <IntercomProvider autoBoot appId={process.env.REACT_APP_INTERCOM_APP_ID}>
          <Profile onClose={toggleProfileDialog} />
        </IntercomProvider>
      )}
    </>
  )
}
