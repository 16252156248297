import { useEffect, useState, MutableRefObject } from 'react'

export const MIN_CHART_HEIGHT = 270

export function useChartHeight(containerRef: MutableRefObject<HTMLElement>): number {
  const [height, setHeight] = useState(MIN_CHART_HEIGHT)

  useEffect(() => {
    const container = containerRef?.current
    if (container) {
      const [legendWrapper] = container.getElementsByClassName('recharts-legend-wrapper')
      if (legendWrapper) {
        setHeight(legendWrapper.clientHeight)
      }
    }
  }, [containerRef])

  return Math.max(MIN_CHART_HEIGHT, height)
}
