import React, { FC, useMemo, useRef } from 'react'
import { Cell, Legend, Pie, PieChart } from 'recharts'
import { createUseStyles } from 'react-jss'
import { theme } from '@howgood/design'
import { useChartHeight } from '../CarbonFootprintBreakdown/CarbonFootprintBreakdown.hooks'
import { MaterialGranularityBreakdownChartLegendContent } from './MaterialGranularityBreakdownChartLegendContent'

export type MaterialGranularityBreakdownChartData = {
  name: string
  value: number
  pct: number | string
}[] // prc can be '< 1'

interface MaterialGranularityBreakdownChartProps {
  data: MaterialGranularityBreakdownChartData
}

const chartColors = [
  theme.palette.error.light,
  theme.palette.warning.light,
  theme.palette.info.light,
  theme.palette.success.light,
  theme.palette.success.dark,
]

const useStyles = createUseStyles<string, { chartHeight: number }>({
  wrapper: {
    position: 'relative',
    minWidth: '390px',
    height: '230px',
    marginTop: -10,
  },
  chart: {
    height: ({ chartHeight }) => chartHeight,
  },
})

export const MaterialGranularityBreakdownChart: FC<MaterialGranularityBreakdownChartProps> = ({ data }) => {
  const containerRef = useRef()
  const chartData = useMemo(
    () => data.map((item, idx) => ({ ...item, color: chartColors[idx % chartColors.length] })),
    [data]
  )
  const chartHeight = useChartHeight(containerRef)
  const classes = useStyles({ chartHeight })

  return (
    <div ref={containerRef} className={classes.wrapper}>
      <div data-testid="pie-chart" className={classes.chart}>
        <PieChart width={390} height={chartHeight}>
          <Pie cx={100} dataKey="value" isAnimationActive={false} data={chartData} outerRadius={90} innerRadius={60}>
            {chartData.map((entry) => (
              <Cell key={`cell-${entry.name}`} fill={entry.color} />
            ))}
          </Pie>
          <Legend
            layout="vertical"
            verticalAlign="middle"
            width={180}
            align="right"
            margin={{ right: 0 }}
            content={(props) => <MaterialGranularityBreakdownChartLegendContent {...props} data={data} />}
          />
        </PieChart>
      </div>
    </div>
  )
}
