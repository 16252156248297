import React, { FC } from 'react'
import { Text, Tooltip } from '@howgood/design'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { formatDateString, LONG, MEDIUM } from '@/utils/dates'

export interface DateCellProps extends GridRenderCellParams {
  value: string
}

export const DateCell: FC<DateCellProps> = ({ value }) => {
  const date = formatDateString({ type: MEDIUM, dateString: value })
  return (
    <Tooltip title={formatDateString({ type: LONG, dateString: value, withTime: true })}>
      <Text variant="body2">{date}</Text>
    </Tooltip>
  )
}
