import React, { FC } from 'react'
import { fetchFormulationSearchOptions } from '@/api/elastic/productsEsRepo'
import { IFormulationSearchOption } from '@/records'
import { AutocompleteFilter } from '@/components/Shared/Filters/AutocompleteFilter'

interface ProductSelectProps {
  value: IFormulationSearchOption
  onChange: (option: IFormulationSearchOption) => void
}

export const ProductSelect: FC<ProductSelectProps> = ({ value, onChange }) => {
  const fetchProductOptions = async (query: string): Promise<IFormulationSearchOption[]> => {
    return await fetchFormulationSearchOptions({
      query: [query],
      statuses: ['pipeline', 'competitor'],
    })
  }

  return (
    <AutocompleteFilter
      id="vendors-search"
      selected={value || ''}
      filterType="product"
      handleChangeFilter={onChange}
      onUpdateQuery={fetchProductOptions}
      multiple={false}
      optionPosition="top-start"
    />
  )
}
