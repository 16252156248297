import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { updateWorkspace } from '@/state/workspaces'
import { OrgUser } from '@/state/organization'
import { Workspace } from '@/records'
import { AssigneeCell } from '@/components/GridWithMetrics/AssigneeCell'

interface AssigneeCellParams extends GridRenderCellParams {
  value?: Workspace
}

export const WorkspaceAssigneeCell: FC<AssigneeCellParams> = (params) => {
  const { value: workspace } = params
  const dispatch = useDispatch()
  const setAssignee = (user: OrgUser) => {
    dispatch(
      updateWorkspace({
        id: workspace.id,
        assignee: user,
      })
    )
  }

  return <AssigneeCell item={workspace} assignWhat="workspace" setAssignee={setAssignee} />
}
