import React, { FC } from 'react'
import ReactPaginate from 'react-paginate'
import { DEFAULT_PAGE_SIZE } from '@/constants/config'
import './Paginator.css'

interface PaginatorProps {
  page: number
  totalItems: number
  rowsPerPage?: number
  onChangePage: (page: { selected: number }) => void
}

export const Paginator: FC<PaginatorProps> = ({ totalItems, page, rowsPerPage = DEFAULT_PAGE_SIZE, onChangePage }) => {
  const pageCount = Math.ceil(totalItems / rowsPerPage)

  return (
    pageCount > 1 && (
      <ReactPaginate
        previousLabel={' '}
        nextLabel={' '}
        breakLabel={'...'}
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={onChangePage}
        containerClassName={'Pagination'}
        subContainerClassName={''}
        activeClassName={'active'}
        forcePage={page > pageCount ? 0 : page - 1}
      />
    )
  )
}
