import { convertLockedScoresToNumbers } from '@/utils/convertLockedScoresToNumbers'
import { SCOPE_3_CONTRIBUTION_FIELD } from '@/constants/impactScore'
import { prepareProductsGridRowExport } from '@/utils/products'
import { IProduct, WorkspaceOption } from '@/records'

export const prepareLockedScoreData = (
  products: IProduct[],
  custom_aggs: Record<string, number>,
  isProcurement: boolean,
  showLiveData: boolean,
  userWorkspacesOptions: WorkspaceOption[]
) => {
  // The product data from ES has the locked_claims data, so use this if on the validation split
  // For procurement, also calculate the scope 3 contribution for each product
  const productData = showLiveData
    ? products
    : products.map((product) => {
        let productWithCorrectScores = product
        if (product.locked_claims?.impact_score) {
          productWithCorrectScores = {
            ...product,
            // Most of the `locked_claims` scores come in as strings, so convert to numbers
            // We have the same impact data on the root and the `impact_score` key, so do both
            ...convertLockedScoresToNumbers(product.locked_claims.impact_score),
            impact_score: convertLockedScoresToNumbers(product.locked_claims.impact_score),
          }
        }
        if (!isProcurement || !product.custom_fields?.scope_3_total_annual || !custom_aggs?.scope_3_total_annual) {
          return productWithCorrectScores
        }
        return {
          ...productWithCorrectScores,
          custom_fields: {
            ...product.custom_fields,
            [SCOPE_3_CONTRIBUTION_FIELD]: product.custom_fields.scope_3_total_annual / custom_aggs.scope_3_total_annual,
          },
        }
      })
  return productData.map((product) => prepareProductsGridRowExport(product, userWorkspacesOptions))
}
