import React, { FC, useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Stack } from '@howgood/design'
import {
  selectProductsWithSelectedImpactData,
  selectProductsTotal,
  updateFiltersUrlAndProducts,
  selectConstructedFilters,
} from '@/state/products'
import { prepareProductsGridRow, ProductListDataRow } from '@/utils/products'
import { useToggle } from '@/hooks'
import { Paginator } from './Paginator'
import { selectAllWorkspacesOptions } from '@/state/workspaces'
import { selectIsProcurement } from '@/state/router'
import { Toolbar } from './Toolbar'
import { ProductGrid } from './ProductGrid'
import { useProductListColumns } from './hooks/useProductListColumns'

interface Props {
  scenarioId?: number
}

export const Products: FC<Props> = ({ scenarioId = null }) => {
  const dispatch = useDispatch()
  const isProcurement = useSelector(selectIsProcurement)
  const constructedFilters = useSelector(selectConstructedFilters)
  const products = useSelector(selectProductsWithSelectedImpactData)
  const productsTotal = useSelector(selectProductsTotal)
  const userWorkspacesOptions = useSelector(selectAllWorkspacesOptions)
  const [showBulkActions, toggleBulkActions] = useToggle()
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([])
  const columns = useProductListColumns({ showBulkActions, scenarioId })

  const handleSort = useCallback(
    (key?: string, order?: 'asc' | 'desc') => {
      const sort = key
        ? [
            {
              [key]: { order },
            },
          ]
        : []

      dispatch(updateFiltersUrlAndProducts({ updatedFilters: { sortOptions: sort }, fetchRollups: false }))
    },
    [dispatch]
  )

  const handleGoToPage = (selectedPage: { selected: number }) => {
    dispatch(updateFiltersUrlAndProducts({ updatedFilters: { page: selectedPage.selected + 1 }, fetchRollups: false }))
  }

  const rowData: ProductListDataRow[] = useMemo(() => {
    return products
      ? products.map((product) => prepareProductsGridRow(product, userWorkspacesOptions, isProcurement, scenarioId))
      : []
  }, [products, userWorkspacesOptions, isProcurement, scenarioId])

  return (
    <Stack mt={2} gap={2}>
      <Toolbar
        columns={columns}
        toggleBulkActions={toggleBulkActions}
        showBulkActions={showBulkActions}
        selectedRowIds={selectedRowIds}
        setSelectedRowIds={setSelectedRowIds}
      />
      <ProductGrid
        rowData={rowData}
        handleSort={handleSort}
        showBulkActions={showBulkActions}
        scenarioId={scenarioId}
        selectedRowIds={selectedRowIds}
        setSelectedRowIds={setSelectedRowIds}
      />
      <Paginator totalItems={productsTotal} page={constructedFilters.page} onChangePage={handleGoToPage} />
    </Stack>
  )
}
