import React, { FC, useState } from 'react'
import { Button, Menu, Chip, Stack } from '@howgood/design'
import { SearchOption } from '../types'
import { MultiSelectListBody } from './MultiSelectListBody'

interface MultiSelectListProps {
  onChange: (options: SearchOption[]) => void
  options: SearchOption[]
  initialSelection?: SearchOption[]
}

export const MultiSelectList: FC<MultiSelectListProps> = ({ options, onChange }) => {
  const [selectedOptions, setSelectedOptions] = useState<SearchOption[]>([])
  const [anchorEl, setAnchorEl] = useState(null)

  const removeOption = (option: SearchOption) => {
    const newOptions = selectedOptions.filter((el) => el !== option)
    setSelectedOptions(newOptions)
    onChange(newOptions)
  }

  const handleChange = (options: SearchOption[]) => {
    setSelectedOptions(options)
    setAnchorEl(null)
    onChange(options)
  }

  return (
    <>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        <MultiSelectListBody onChange={handleChange} options={options} selected={selectedOptions} />
      </Menu>
      <Stack flexDirection="row" flexWrap="wrap" gap={1} mb={2}>
        {selectedOptions.map((option) => (
          <Chip key={option.value} label={option.label} onDelete={() => removeOption(option)} />
        ))}
      </Stack>
      <Button onClick={(e) => setAnchorEl(e.target)}>Add filter...</Button>
    </>
  )
}
