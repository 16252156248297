import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { createUseStyles } from 'react-jss'
import { GridColDef } from '@mui/x-data-grid-premium'
import { useProductMainMetrics, useIngredientImpactScoreHeatmapColumns, useImpactScoreBarColumns } from '@/hooks'
import { selectUserProductIngredientHeatmapPermissions } from '@/state/user'

const useStyles = createUseStyles({
  detailCell: {
    paddingLeft: '0 !important',
    paddingRight: '0 !important',
    border: '1px solid transparent !important',
    borderWidth: '1px !important',
  },
  hidden: {
    display: 'none !important',
  },
})

export const defaultColumns: GridColDef[] = [
  {
    headerName: 'Ingredient',
    field: 'ingredient',
  },
  {
    headerName: 'Standard',
    field: 'standard',
  },
  {
    headerName: 'Location',
    field: 'location',
  },
]

const useImpactColumns = (showAverages: boolean): GridColDef[] => {
  const allowedMetricColumns = useProductMainMetrics()
  const impactColumnsOriginal = useImpactScoreBarColumns(allowedMetricColumns)

  return useMemo(
    () =>
      impactColumnsOriginal.map((col) => ({
        ...col,
        availableAggregationFunctions: showAverages ? ['avg'] : [],
        aggFunc: showAverages ? 'avg' : null,
        cellClassRules: {
          horizontallyCentered: () => true,
        },
      })),
    [impactColumnsOriginal, showAverages]
  )
}

export const useDetailColumns = (showAverages: boolean = false): GridColDef[] => {
  const allowedIngredientsMetrics = useSelector(selectUserProductIngredientHeatmapPermissions)
  const classes = useStyles()
  const detailColumns = useIngredientImpactScoreHeatmapColumns(
    allowedIngredientsMetrics,
    showAverages,
    classes.detailCell
  )

  return detailColumns
}

export const useTableColumns = (showAverages: boolean = false) => {
  const impactColumns = useImpactColumns(showAverages)
  const detailColumns = useDetailColumns(showAverages)

  const columns: GridColDef[] = useMemo(() => {
    return [...defaultColumns, ...impactColumns, ...detailColumns]
  }, [impactColumns, detailColumns])

  return columns
}
