import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { faLock } from '@fortawesome/pro-regular-svg-icons'
import { Stack, Drawer, Box, Text, Alert, Icon, AlertTitle, ExternalLink } from '@howgood/design'

import { selectProductIsLocked } from '@/state/productOverview'
import { CreateReportPanel } from './CreateReportPanel'
import { RequestValidationPanel } from './ValidationPanel'
import { SharingPanel } from './SharingPanel'
import { lateUserValidationPaywallMessage, lockFirstMessage } from './validationCopy'
import { selectIsReportToolkitOpen, setReportToolkitDrawer } from '@/state/pageSettings'
import { setLiteUserPaywallDialogState } from '@/state/liteUserPaywallDialog'
import { selectIsSupplierConnect } from '@/state/organization'

export const MainDrawer: FC = () => {
  const dispatch = useDispatch()
  const isLocked = useSelector(selectProductIsLocked)
  const isOpen = useSelector(selectIsReportToolkitOpen)
  const isSupplierConnect = useSelector(selectIsSupplierConnect)

  const handleClose = () => {
    dispatch(setReportToolkitDrawer(false))
  }

  return (
    <Drawer open={isOpen} onClose={handleClose}>
      <Box width={632}>
        <Stack gap={2}>
          <Text variant="h2">Product Report Toolkit</Text>
          <CreateReportPanel />
          {!isLocked && (
            <Alert
              severity="warning"
              iconMapping={{
                warning: <Icon color="warning.main" size="sm" icon={faLock} />,
              }}
            >
              {lockFirstMessage}
            </Alert>
          )}
          <SharingPanel />
          {isLocked && isSupplierConnect && (
            <Alert
              severity="warning"
              iconMapping={{
                warning: <Icon color="warning.main" size="sm" icon={faLock} />,
              }}
            >
              <AlertTitle>
                <Stack direction="row" justifyContent="space-between" alignItems="center" gap={7}>
                  <Text variant="body2">{lateUserValidationPaywallMessage}</Text>
                  <ExternalLink
                    color="inherit"
                    href={null}
                    onClick={() => {
                      dispatch(
                        setLiteUserPaywallDialogState({
                          open: true,
                        })
                      )
                    }}
                  >
                    <Text color="inherit" fontWeight="inherit">
                      Learn more
                    </Text>
                  </ExternalLink>
                </Stack>
              </AlertTitle>
            </Alert>
          )}
          <RequestValidationPanel />
        </Stack>
      </Box>
    </Drawer>
  )
}
