import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { OrgUser, selectUsersSortedByEmail } from '@/state/organization'
import { SelectUserDialog } from '@/components/Shared/SelectUserDialog'
import { SelectAssignee } from '@/components/Shared/SelectAssignee'
import { Workspace } from '@/records'
import { Scenario } from '@/records/Initiatives'

interface Props {
  item: Workspace | Scenario
  assignWhat: 'workspace' | 'product' | 'scenario'
  setAssignee: (user: OrgUser) => void
}

export const AssigneeCell: FC<Props> = ({ item, assignWhat, setAssignee }) => {
  const allOrgUsers = useSelector(selectUsersSortedByEmail)
  // It's possible the assignee is not in your org, but you should be able to see who's assigned
  // We only display the assignee if the user has edit access to the workspace, so this seems reasonable
  const assignee = allOrgUsers.find((user) => user.id === item.assignee?.id) || item.assignee
  const [dialogOpen, setDialogOpen] = React.useState(false)

  return (
    <>
      <SelectAssignee assignWhat={assignWhat} currentAssignee={assignee} setDialogOpen={setDialogOpen} />
      {dialogOpen && (
        <SelectUserDialog
          allUsers={allOrgUsers}
          selectedUser={assignee}
          message={`Select the person in your organization responsible for this ${assignWhat}.`}
          setUser={setAssignee}
          closeDialog={() => setDialogOpen(false)}
        />
      )}
    </>
  )
}
