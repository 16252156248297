import React, { FC, useState } from 'react'
import { ErrorBoundary } from '@sentry/react'
import { useSelector } from 'react-redux'
import { faGaugeLow } from '@fortawesome/pro-regular-svg-icons'
import { Card, CardContent, Grid, Icon, Stack, Text } from '@howgood/design'
import { ProductDashboard } from '@/features/ProductDashboard/ProductDashboard'
import { PortfolioDashboard } from '@/features/PortfolioDashboard/PortfolioDashboard'
import { CarbonAccountingContent } from '@/features/CarbonAccounting/CarbonAccountingContent'
import { SettingsMenu } from '@/features/PortfolioDashboard/Toolbar/SettingsMenu'
import { selectCarbonAccountingCurrentReport } from '@/state/carbonAccounting'
import { selectUser } from '@/state/user'

export const DashboardHome: FC = () => {
  const [showSettings, setShowSettings] = useState(false)
  const currentReport = useSelector(selectCarbonAccountingCurrentReport)
  const user = useSelector(selectUser)

  return (
    <Stack
      data-testid="dashboard-home"
      sx={{ '& .MuiCard-root': { minHeight: 420 }, '& .MuiCardContent-root': { justifyContent: 'start' } }}
    >
      <Stack direction="row" alignItems="center" mt={4} mb={3} gap={0.5}>
        <Icon fontSize={20} data-testid="heading-icon" icon={faGaugeLow} />
        <Text variant="h2">Welcome back{user.email ? `, ${user.first_name || user.email}.` : ''}</Text>
      </Stack>
      <Grid container spacing={2}>
        <Grid
          data-testid="portfolio-dashboard-container"
          item
          xs={12}
          lg={6}
          onMouseOver={() => setShowSettings(true)}
          onMouseOut={() => setShowSettings(false)}
        >
          {/* render SettingsMenu (cog) outside the document flow, relative to this container */}
          <Card sx={{ position: 'relative' }}>
            <CardContent>
              <SettingsMenu showSettings={showSettings} />
              <Text variant="h3">Assess your portfolio</Text>
              <Text variant="body2">
                Quickly assess your portfolio impact against any metric and export the results
              </Text>
              <ErrorBoundary fallback={<Text>Error loading Portfolio Impact dashboard</Text>}>
                <PortfolioDashboard />
              </ErrorBoundary>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card fullHeight>
            <CardContent>
              <Text variant="h3">Measure your corporate carbon footprint</Text>
              <Text variant="body2">Measure your Scope 1, 2, 3 and FLAG emissions and create a report</Text>
              <ErrorBoundary fallback={<Text>Error loading Carbon Footprint dashboard</Text>}>
                <CarbonAccountingContent
                  reportId={currentReport?.id}
                  reportYear={currentReport?.year}
                  reportTitle={currentReport?.title}
                  condensed
                />
              </ErrorBoundary>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Text variant="h3">Reduce your product impact</Text>
              <Text variant="body2">Identify your top 5 highest impact products and explore abatement strategies</Text>
              <ErrorBoundary fallback={<Text>Error loading Product Impact dashboard</Text>}>
                <ProductDashboard />
              </ErrorBoundary>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Stack>
  )
}
