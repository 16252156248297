import { FormulationTag, InventoriesResponse } from '@/api'
import { FormulationStatusOption, ScenarioProduct } from '@/records'
import { Recipe } from '../recipe/recipe.state'
import { getV2Payload } from '../recipe/recipe.requests'
import { ProductInfo } from './productOverview.state'

export const generateTags = (inventories: InventoriesResponse[], id?: number): FormulationTag[] => {
  if (!inventories.length) {
    return []
  }
  return inventories.reduce((acc, inventory) => {
    if (!id || inventory.product.id === id) {
      return [...acc, ...inventory.formulation_tags]
    }
    return acc
  }, [])
}

export interface SaveAsInfo {
  name: string
  workspaces: {
    id: number
    name: string
  }[]
  status: FormulationStatusOption
  copyTags: boolean
  copySalesVolume: boolean
}

export interface ShareRequestInfo {
  type: 'material_directory' | 'direct'
  text?: string
}

export const generateProductPayload = (draft: ProductInfo, recipe: Recipe, saveAsInfo?: SaveAsInfo) => {
  const basePayload = getV2Payload(recipe)
  const productId = saveAsInfo ? null : draft.id
  const workspaces = saveAsInfo ? saveAsInfo.workspaces : draft.workspaces
  const payload = {
    ...basePayload,
    name: saveAsInfo ? saveAsInfo.name : draft.name,
    formulation_status: saveAsInfo ? saveAsInfo.status?.value : draft.formulation_status,
    is_private: draft.id ? undefined : true,
    // Don't include unit size and units sold if saving a copy with "Copy sales volume" unchecked
    weight_kg: saveAsInfo?.copySalesVolume === false ? undefined : basePayload.weight_kg,
    annual_sales_volume: saveAsInfo?.copySalesVolume === false ? undefined : basePayload.annual_sales_volume || 0,
    agribalyze_category: recipe.agribalyze_category?.id ? { id: recipe.agribalyze_category?.id } : undefined,
    // For new products only, send the selected workspace as it's needed for initial setup
    // For existing products, don't send the workspaces or the associated inventories will get blown away
    workspaces: productId ? undefined : workspaces.map((ws) => ({ id: ws.id })),
    brand_name: draft.brand_name,
    source_product_id: saveAsInfo ? draft.id : undefined,
    upc: draft.upc || null,
  }
  return payload
}

export const generateUpdatedScenarioProducts = (
  scenarioProducts: ScenarioProduct[],
  scenarioId: number,
  updatedProductScenario: ScenarioProduct
) => {
  const found = scenarioProducts.findIndex((sp) => sp.scenario.id === scenarioId)
  if (found === -1) {
    return [...scenarioProducts, updatedProductScenario]
  } else {
    return scenarioProducts.map((sp) => (sp.scenario.id === scenarioId ? updatedProductScenario : sp))
  }
}
