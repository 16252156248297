import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { theme } from '@howgood/design'

import { XAxis, YAxis, LineChart, Line, CartesianGrid, ResponsiveContainer, Legend } from 'recharts'
import { formatNumber } from '@howgood/utils'
import { selectChartMetric, selectInitiativeChartData } from '@/state/initiatives'
import { selectScoreType } from '@/state/pageSettings'
import { generateScoreLabel } from '@/state/initiatives/initiatives.utils'

export const InitiativeChart: FC = () => {
  const { units } = useSelector(selectChartMetric)
  const { chartData, hasGoals } = useSelector(selectInitiativeChartData)
  const scoreType = useSelector(selectScoreType)
  const scoreLabel = generateScoreLabel(units, scoreType)

  return (
    <ResponsiveContainer width="50%" height="100%">
      <LineChart
        data={chartData}
        margin={{
          top: 20,
          right: 40,
          left: 20,
          bottom: 0,
        }}
      >
        <CartesianGrid stroke="#f5f5f5" />
        <Legend verticalAlign="bottom" height={36} iconType="plainline" iconSize={20} />
        <XAxis allowDecimals={false} type="number" dataKey="year" domain={['dataMin', 'dataMax']} />
        <YAxis
          tick={(d) => (
            <text x={d.x} y={d.y} dx={-4} dy={4} fontSize={12} fill={theme.palette.text.primary} textAnchor="end">
              {formatNumber(d.payload.value)}
            </text>
          )}
          domain={['auto', 'auto']}
          label={{
            value: units,
            angle: -90,
            position: 'left',
            fontSize: 12,
            fill: theme.palette.text.secondary,
          }}
        />
        <Line
          type="linear"
          connectNulls
          dataKey="Baseline"
          dot={{ r: 0 }}
          stroke="black"
          strokeDasharray="2 2"
          isAnimationActive={false}
        />
        <Line
          type="linear"
          connectNulls
          dataKey={scoreLabel}
          dot={{ stroke: 'transparent', fill: 'black', r: 6 }}
          stroke="black"
          isAnimationActive={false}
        />
        {hasGoals && (
          <Line
            type="linear"
            connectNulls
            dataKey="Goal"
            dot={{ stroke: 'transparent', fill: theme.palette.info.light, r: 6 }}
            stroke={theme.palette.info.light}
            strokeDasharray="2 2"
            isAnimationActive={false}
          />
        )}
      </LineChart>
    </ResponsiveContainer>
  )
}
