import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MultiSelectWithCheckboxes } from '@howgood/design'

import { WorkspaceOption } from '@/records'
import {
  selectEditingFilter,
  selectWorkspaceProductFilters,
  selectWorkspacesOptionsForFilter,
  setEditingFilter,
  updateFiltersUrlAndProducts,
} from '@/state/products'

export const WorkspaceProductFilter: FC<{ workspaceOptionOverride?: WorkspaceOption[] }> = ({
  workspaceOptionOverride = null,
}) => {
  const dispatch = useDispatch()
  const userWorkspacesOptions = useSelector(selectWorkspacesOptionsForFilter)
  const workspaceOptions = workspaceOptionOverride ? workspaceOptionOverride : userWorkspacesOptions
  const workspaces = useSelector(selectWorkspaceProductFilters)
  const editingFilter = useSelector(selectEditingFilter)
  const id = 'workspace-search'

  const handleWorkspaceChange = async (updatedWorkspaces: WorkspaceOption[]) => {
    dispatch(updateFiltersUrlAndProducts({ updatedFilters: { workspaces: updatedWorkspaces } }))
    dispatch(setEditingFilter(id))
  }

  return (
    <MultiSelectWithCheckboxes
      id={id}
      handleChange={handleWorkspaceChange}
      options={workspaceOptions}
      values={workspaces}
      itemType="workspace"
      autoFocus={editingFilter === id}
    />
  )
}
