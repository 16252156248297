import { AppState } from '@/store'
import { createSelector } from 'reselect'
import cloneDeep from 'lodash/cloneDeep'

import { Report, selectReports } from '../organization'
import { WorkspaceScores, selectWorkspaces } from '../workspaces'
import { Goals } from '@/records/Workspace'
import { IImpactMetricOption, ProductBrand, Workspace } from '@/records'
import { InitiativeBasic, InitiativeHydrated, Scenario } from '@/records/Initiatives'
import { selectContentfulMetricData } from '../contentfulData'
import { MetricEntry } from '@/contentfully'
import { getContentData, getContentfulEntry } from '@/records/scoreCardData'
import { generateScoreLabel, getScenarioYearAsNumber, getScoreValues } from './initiatives.utils'
import { ScoreType, selectScoreType } from '../pageSettings'
import { ImpactScore } from '@/api'
import { WorkspacePermission, selectWorkspaceListPermissions } from '@/selectors/selectAllWorkspaceGridOptions'
import { VersionData, InitiativeGroupByMethod, ScenarioTab } from './initiatives.slice'
import { GroupedImpactScores } from './initiatives.requests'

/**
 * returns the scores with values for both avg and sum for ALL metrics
 * since we dont get sum scores for qualitative metrics and want a
 * consistent data structure
 * @argument 
 * scores: {
    <scenarioId || initiativeId>: {
        cf_flag_annual_impact_avg: 131864591.45287654,
        cf_flag_annual_impact_sum: 121051694953.74066,
        cf_flag_annual_impact_count: 918,
        howgood_one_metric_avg: 59.3298490190506,
    }
  }
  @returns 
  formattedScores {
    <scenarioId || initiativeId>: {
        cf_flag_annual_impact_avg: 131864591.45287654,
        cf_flag_annual_impact_sum: 121051694953.74066,
        cf_flag_annual_impact_count: 918,
        howgood_one_metric_avg: 59.3298490190506,
        howgood_one_metric_sum: 59.3298490190506,
        howgood_one_metric_count: 1
    }
  }
 */
const generateScoresWithSums = (scores: GroupedImpactScores) => {
  // clone here because we cannot update the immmutable scores object
  const formattedScores = cloneDeep(scores)
  Object.entries(formattedScores).forEach(([scenarioId, scenarioScores]) => {
    Object.entries(scenarioScores).forEach(([avgMetric, score]) => {
      // if we have an avg score and do NOT have a sum score for the same metric, use the avg
      if (typeof score === 'number' && avgMetric.includes('_avg')) {
        const metric = avgMetric.replace('_avg', '')
        if (!Object.hasOwn(formattedScores[scenarioId], `${metric}_sum`)) {
          formattedScores[scenarioId][`${metric}_sum`] = score
          formattedScores[scenarioId][`${metric}_count`] = 1
        }
      }
    })
  })
  return formattedScores
}

export const selectisInitiativesLoading = (state: AppState): boolean => state.initiatives.isLoading

export const selectisScenarioLoading = (state: AppState): boolean => state.initiatives.isScenarioLoading

export const selectisScenarioScoresLoading = (state: AppState): boolean => state.initiatives.isScenarioScoresLoading

export const selectInitiatives = (state: AppState): InitiativeBasic[] => state.initiatives.initiatives

export const selectCurrentInitiativeId = (state: AppState): number => state.initiatives.currentInitiative
const selectCurrentBasicInitiative = createSelector<AppState, InitiativeBasic[], number, InitiativeBasic>(
  selectInitiatives,
  selectCurrentInitiativeId,
  (initiatives, id) => (id ? initiatives.find((i) => i.id === id) : null)
)

export const selectCurrentInitiativeName = createSelector<AppState, InitiativeBasic, string>(
  selectCurrentBasicInitiative,
  (initiative) => initiative?.name
)

export const selectAllVendorInitiativesByVendorId = (vendorId: number) =>
  createSelector<AppState, InitiativeBasic[], InitiativeBasic[]>(selectInitiatives, (initiatives) => {
    return (
      initiatives.filter((i) => {
        const vendorIds = i.brands?.map((b) => b.id) || []
        return vendorIds.includes(vendorId)
      }) || []
    )
  })

export const selectInitiativeByVendorId = (vendorId: number) =>
  createSelector<AppState, InitiativeBasic[], InitiativeBasic>(selectInitiatives, (initiatives) => {
    return initiatives.find((i) => {
      const vendorIds = i.brands?.map((b) => b.id) || []
      return vendorIds.includes(vendorId)
    })
  })

export const selectCurrentInitiativeWorkspaceIds = createSelector<AppState, InitiativeBasic, number[]>(
  selectCurrentBasicInitiative,
  (initiative) => initiative?.workspaces?.map((w) => w.id) || []
)

export const selectInitiativeScores = (state: AppState): GroupedImpactScores => state.initiatives.initiativeScores
/**
 * returns the initiative score with values for both avg and sum for ALL metrics
 * since we dont get sum scores for qualitative metrics
 */
const selectInitiativeScoresWithAllSums = createSelector<AppState, GroupedImpactScores, number, GroupedImpactScores>(
  selectInitiativeScores,
  selectCurrentInitiativeId,
  (scores, initiativeId) => {
    return scores?.[initiativeId] ? generateScoresWithSums(scores) : {}
  }
)

export const selectScenarioPageTab = (state: AppState): ScenarioTab => state.initiatives.scenarioPageTab

export const selectSourceProductVersionData = (state: AppState): VersionData =>
  state.initiatives.sourceProductVersionData

const selectScenarios = (state: AppState): Scenario[] => state.initiatives.scenarios
/**
 * returns ALL the scenarios sorted by target_date
 */
export const selectSortedScenarios = createSelector<AppState, Scenario[], Scenario[]>(selectScenarios, (scenarios) =>
  [...scenarios].sort((a, b) => getScenarioYearAsNumber(a.target_date) - getScenarioYearAsNumber(b.target_date))
)

export const selectScenarioScores = (state: AppState): GroupedImpactScores => state.initiatives.scenarioScores
/**
 * returns the scenario score with values for both avg and sum for ALL metrics
 * since we dont get sum scores for qualitative metrics
 */
export const selectScenarioScoresWithAllSums = createSelector<AppState, GroupedImpactScores, GroupedImpactScores>(
  selectScenarioScores,
  (scores) => generateScoresWithSums(scores)
)

export const selectIsCreatingInitiative = (state: AppState) => state.initiatives.isCreatingInitiative

export const selectNewInitiative = (state: AppState) => state.initiatives.newInitiative

export const selectChartMetricKey = (state: AppState): string => state.initiatives.chartMetric

export const selectCreateNewInitiativeGroupByMethod = (state: AppState): InitiativeGroupByMethod =>
  state.initiatives.createNewGroupByMethod

export type ChartMetric = {
  metric: IImpactMetricOption
  units?: string
}
export const selectChartMetric = createSelector<AppState, string, MetricEntry[], ChartMetric>(
  selectChartMetricKey,
  selectContentfulMetricData,
  (metricKey, contentfulMetricData) => {
    if (!metricKey) return { units: '', metric: null }
    const { contentfulEntry } = getContentData({
      key: metricKey,
      contentfulMetricData,
      showProcurementCarbonContent: true,
    })
    return {
      metric: {
        value: contentfulEntry.key,
        label: contentfulEntry.subtitle || contentfulEntry.title,
        key: contentfulEntry.key,
      },
      units: contentfulEntry?.units,
    }
  }
)

export const selectHasInititives = createSelector<AppState, InitiativeBasic[], boolean>(
  selectInitiatives,
  (initiatives) => !!initiatives.length
)

export const selectCurrentInitiativeScores = createSelector<AppState, GroupedImpactScores, number, WorkspaceScores>(
  selectInitiativeScoresWithAllSums,
  selectCurrentInitiativeId,
  (initiativeScores, id) => initiativeScores[id] || {}
)

export const selectCurrentScenarioId = (state: AppState): number => state.initiatives.currentScenario
export const selectCurrentScenario = createSelector<AppState, Scenario[], number, Scenario>(
  selectSortedScenarios,
  selectCurrentScenarioId,
  (scenarios, id) => (id && scenarios.length ? scenarios.find((i) => i.id === id) : null)
)
export const selectCurrentScenarioScores = createSelector<AppState, number, GroupedImpactScores, ImpactScore>(
  selectCurrentScenarioId,
  selectScenarioScores,
  (scenario, scores) => generateScoresWithSums(scores)[scenario] || {}
)

export const selectScenariosByInitiativeId = (initiativeId: number) =>
  createSelector<AppState, Scenario[], Scenario[]>(selectSortedScenarios, (scenarios) => {
    const initiativeScenarios =
      scenarios.length && initiativeId ? scenarios.filter((scenario) => scenario.initiative.id === initiativeId) : []
    return initiativeScenarios.sort(
      (a, b) => getScenarioYearAsNumber(a.target_date) - getScenarioYearAsNumber(b.target_date)
    )
  })

export const selectCurrentInitiativeScenarios = createSelector<AppState, Scenario[], Scenario[]>(
  (state) => selectScenariosByInitiativeId(state.initiatives.currentInitiative)(state),
  (scenarios) => scenarios
)

export const selectCurrentInitiativeBaselineDate = createSelector<AppState, InitiativeBasic, string>(
  selectCurrentBasicInitiative,
  (initiative) => (initiative?.baseline ? initiative.baseline_date || initiative.date_created : '')
)

export const selectCurrentInitiativeBaseline = createSelector<AppState, InitiativeBasic, Report[], Report>(
  selectCurrentBasicInitiative,
  selectReports,
  (initiative, reports) =>
    initiative?.baseline && reports.length ? reports.find((report) => report.id === initiative.baseline.id) : null
)

/**
 * Returns the current initiative along with its scenarios and baseline
 */
export const selectCurrentInitiative = createSelector<
  AppState,
  InitiativeBasic,
  Scenario[],
  Report,
  InitiativeHydrated
>(
  selectCurrentBasicInitiative,
  selectCurrentInitiativeScenarios,
  selectCurrentInitiativeBaseline,
  (initiative, scenarios, baseline) => {
    return { ...initiative, scenarios, baseline }
  }
)

export const selectCurrentInitiativeProductCount = createSelector<AppState, InitiativeHydrated, number>(
  selectCurrentInitiative,
  (initiative) => {
    const initiativeProductList = initiative?.products?.map((product) => product.id) || []
    return initiativeProductList.length
  }
)

export const selectCurrentScenarioGoals = createSelector<
  AppState,
  Scenario,
  GroupedImpactScores,
  number,
  ScoreType,
  Goals
>(
  selectCurrentScenario,
  selectScenarioScoresWithAllSums,
  selectCurrentInitiativeProductCount,
  selectScoreType,
  (scenario, scenarioImpactScores, productCount, scoreType) => {
    if (!scenario?.goals) {
      return {}
    }
    const scores = scenarioImpactScores[scenario.id] || {}
    if (scoreType === 'displayedSum') {
      const sumGoals = { ...scenario.goals }
      Object.keys(scenario.goals).forEach((metric) => {
        const { sumGoal } = getScoreValues(metric, scoreType, scores, scenario.goals, productCount)
        sumGoals[metric] = sumGoal
      })
      return sumGoals
    }
    return scenario.goals
  }
)

/**
 * Returns the current initiative's "groupBy" method ('workspaces' or 'vendors')
 */
export const selectCurrentInitiativeGroupByMethod = createSelector(
  selectCurrentBasicInitiative,
  (currentInitiative): InitiativeGroupByMethod => {
    return currentInitiative.brands.length ? 'vendors' : 'workspaces'
  }
)

export const selectScenarioById = (id: number) =>
  createSelector<AppState, Scenario[], Scenario>(selectCurrentInitiativeScenarios, (scenarios) =>
    scenarios?.length ? scenarios.find((s) => s.id === id) || null : null
  )

const getInitiativeWorkspaces = (initiative: InitiativeBasic, workspaces: Workspace[]) => {
  return initiative?.workspaces?.length
    ? initiative.workspaces.reduce((initiativeWorkspaces, iws) => {
        const found = workspaces.find((ws) => iws.id === ws.id)
        if (found) {
          initiativeWorkspaces.push(found)
        }
        return initiativeWorkspaces
      }, [])
    : []
}

/**
 *
 * @param initiativeId number
 * @returns complete user workspace objects associated with the provided initiative (Workspace[])
 */
export const selectInitiativeWorkspacesById = (initiativeId: number) =>
  createSelector<AppState, InitiativeBasic[], Workspace[], Workspace[]>(
    selectInitiatives,
    selectWorkspaces,
    (initiatives, workspaces) => {
      const initiative = initiatives.find((i) => i.id === initiativeId)
      if (!workspaces?.length) {
        return initiative?.workspaces || []
      }
      return getInitiativeWorkspaces(initiative, workspaces)
    }
  )

/**
 * @returns the user workspace objects associated with the current initiative (Workspace[])
 */
export const selectInitiativeWorkspaces = createSelector<AppState, InitiativeBasic, Workspace[], Workspace[]>(
  selectCurrentBasicInitiative,
  selectWorkspaces,
  (initiative, workspaces) => {
    return getInitiativeWorkspaces(initiative, workspaces)
  }
)

export const selectInitiativeBrands = createSelector<AppState, InitiativeBasic, ProductBrand[]>(
  selectCurrentBasicInitiative,
  (initiative) => {
    return initiative.brands
  }
)

export interface InitiativeChartSeries {
  year: number
  name: string
  Baseline: number
  Score?: number
  Total?: number
  Average?: number
  Goal?: number
}
export const selectInitiativeChartData = createSelector<
  AppState,
  ChartMetric,
  Report,
  ImpactScore,
  Scenario[],
  GroupedImpactScores,
  ScoreType,
  string,
  number,
  { chartData: InitiativeChartSeries[]; hasGoals: boolean }
>(
  selectChartMetric,
  selectCurrentInitiativeBaseline,
  selectCurrentInitiativeScores,
  selectCurrentInitiativeScenarios,
  selectScenarioScores,
  selectScoreType,
  selectCurrentInitiativeBaselineDate,
  selectCurrentInitiativeProductCount,
  ({ metric, units }, baseline, currentScores, scenarios, scenarioScores, scoreType, baselineDate, productCount) => {
    const chartData = [] as InitiativeChartSeries[]
    if (!metric) {
      return { chartData, hasGoals: false }
    }
    const { score: baselineScore } = getScoreValues(
      metric.key,
      scoreType,
      baseline?.metadata as WorkspaceScores,
      {},
      productCount
    )
    const { score: currentScore } = getScoreValues(metric.key, scoreType, currentScores, {}, productCount)
    const scoreLabel = generateScoreLabel(units, scoreType)
    let hasGoals = false

    if (baselineScore) {
      chartData.push({
        year: getScenarioYearAsNumber(baselineDate),
        name: baseline.name,
        Baseline: baselineScore,
        [scoreLabel]: baselineScore,
        Goal: baselineScore,
      })
    }
    if (currentScore) {
      chartData.push({
        year: new Date().getFullYear(),
        name: 'Current Score',
        Baseline: baselineScore,
        [scoreLabel]: currentScore,
      })
    }
    scenarios.forEach((scenario) => {
      const { score, goal } = getScoreValues(
        metric.key,
        scoreType,
        scenarioScores[scenario.id] || null,
        scenario.goals,
        productCount
      )
      if (score) {
        chartData.push({
          year: getScenarioYearAsNumber(scenario.target_date),
          name: scenario.name,
          Baseline: baselineScore,
          [scoreLabel]: score,
          Goal: goal,
        })
      }
      if (goal) {
        hasGoals = true
      }
    })
    return { chartData, hasGoals }
  }
)

type MetricOption = {
  key: string
  value: string
  units?: string
  label: string
}
/**
 * returns a list of metrics (MetricOption[]) that have both scores and
 * goals set for at least one scenario in the current initiative
 */
export const selectCurrentInitiativeGoalMetrics = createSelector<
  AppState,
  WorkspacePermission[],
  MetricEntry[],
  Scenario[],
  GroupedImpactScores,
  MetricOption[]
>(
  selectWorkspaceListPermissions,
  selectContentfulMetricData,
  selectCurrentInitiativeScenarios,
  selectScenarioScores,
  (workspaceListPermissions, contentfulMetricData, scenarios, scenarioScores) => {
    const metricsWithScoresAndGoals = workspaceListPermissions.filter((metric) => {
      let hasScore = false
      let hasGoal = false
      scenarios.forEach((scenario) => {
        if (
          scenarioScores[scenario.id]?.[`${metric.value}_avg`] ||
          scenarioScores[scenario.id]?.[`${metric.value}_sum`] ||
          scenarioScores[scenario.id]?.[`${metric.value}`]
        ) {
          hasScore = true
        }
        if (scenario.goals[metric.value]) {
          hasGoal = true
        }
      })
      return hasScore && hasGoal
    })
    return contentfulMetricData.length
      ? metricsWithScoresAndGoals.map((field) => {
          const contentfulData = getContentfulEntry(field.value, contentfulMetricData)
          const subTitle = contentfulData?.subtitle
            ? ` ${contentfulData.subtitle.replace('Carbon Lifecycle ', '')}`
            : ''
          return {
            ...field,
            label: `${contentfulData.title}${subTitle}`,
            units: contentfulData.units,
          }
        })
      : []
  }
)

export const selectScenarioWorkspaceGoalsByWorkspace = (workspaceId: number, scenarioId: number) =>
  createSelector<AppState, Scenario[], Goals>(selectCurrentInitiativeScenarios, (scenarios) => {
    const scenario = scenarios.find((s) => s.id === scenarioId)
    if (!scenario) {
      return {}
    }
    const workspaceData = scenario.scenario_workspaces
      ? scenario.scenario_workspaces.find((sws) => sws.id === workspaceId)
      : null
    return workspaceData?.goals || {}
  })

export const selectScenarioVendorGoalsByVendor = (vendorId: number, scenarioId: number) =>
  createSelector<AppState, Scenario[], Goals>(selectCurrentInitiativeScenarios, (scenarios) => {
    const scenario = scenarios.find((s) => s.id === scenarioId)
    if (!scenario) {
      return {}
    }
    const vendorData = scenario.scenario_brands
      ? scenario.scenario_brands.find((vendor) => vendor.id === vendorId)
      : null
    return vendorData?.goals || {}
  })
