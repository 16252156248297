import { useSelector } from 'react-redux'
import { ContentfulFieldKey } from '@/contentfully'
import { IImpactMetricOption } from '@/records'
import { ImpactScore } from '@/api'
import { selectCarbonFootprintPermissions } from '@/state/user'
import { selectScorecardCarbonFootprintSplit } from '@/state/splitio'
import {
  Field,
  GHG_RAW_GHG_EMISSIONS_FIELDS,
  PERMISSIONS_TO_FIELDS_MAP,
  VIEW_AGG_IMPACT_CPG_CF_F2MG_W_PKG,
  VIEW_AGG_IMPACT_SUPPLIER_CF_FTM_GATE,
} from '@/constants/impactScore'

export const useScorecardFields = (metric: IImpactMetricOption, impactScore: ImpactScore | null) => {
  const cfPermissions = useSelector(selectCarbonFootprintPermissions)
  const isCarbonFootprintOn = useSelector(selectScorecardCarbonFootprintSplit)

  const hasCarbonFootprintPermission = cfPermissions.includes(VIEW_AGG_IMPACT_SUPPLIER_CF_FTM_GATE)
  const hasCarbonFootprintCpgPermission = cfPermissions.includes(VIEW_AGG_IMPACT_CPG_CF_F2MG_W_PKG)

  const carbonFootprintEnabled =
    isCarbonFootprintOn && (hasCarbonFootprintCpgPermission || hasCarbonFootprintPermission)
  let mainField: ContentfulFieldKey = 'howgood_one_metric'
  if (metric?.value === 'raw_blue_water_usage_impact') {
    mainField = 'raw_blue_water_usage_impact'
  } else if (carbonFootprintEnabled) {
    if (
      hasCarbonFootprintCpgPermission &&
      hasCarbonFootprintPermission &&
      impactScore?.[PERMISSIONS_TO_FIELDS_MAP[VIEW_AGG_IMPACT_CPG_CF_F2MG_W_PKG]] == null
    ) {
      mainField = PERMISSIONS_TO_FIELDS_MAP[VIEW_AGG_IMPACT_SUPPLIER_CF_FTM_GATE]
    } else {
      mainField = hasCarbonFootprintCpgPermission
        ? PERMISSIONS_TO_FIELDS_MAP[VIEW_AGG_IMPACT_CPG_CF_F2MG_W_PKG]
        : PERMISSIONS_TO_FIELDS_MAP[VIEW_AGG_IMPACT_SUPPLIER_CF_FTM_GATE]
    }
  }

  const displayField: ContentfulFieldKey = impactScore?.[mainField] != null ? mainField : 'howgood_one_metric'

  const isDisplayFieldCarbonFootprint: boolean = ['supplier_cf_ftm_gate_impact', 'cpg_cf_f2mg_w_pkg_impact'].includes(
    displayField
  )

  const showGhgDisclaimer: boolean =
    GHG_RAW_GHG_EMISSIONS_FIELDS.includes(metric?.value as Field) ||
    (carbonFootprintEnabled && isDisplayFieldCarbonFootprint)

  return { mainField, displayField, showGhgDisclaimer }
}
