import React, { FC } from 'react'
import { Box, IconButton, Stack, Text, Tooltip } from '@howgood/design'
import { faUserPen, faUserPlus } from '@fortawesome/pro-regular-svg-icons'
import { OrgUser } from '@/state/organization'

/**
 * Shared component used by the AssigneeCell (for workspace assignment) and
 * AssigneeRenderer (for product assignment) to display the current assignee's
 * name or email and the icon that opens the Select User dialog
 */

interface SelectAssigneeProps {
  assignWhat: 'workspace' | 'product' | 'scenario'
  currentAssignee: OrgUser
  setDialogOpen: (open: boolean) => void
  dataTestId?: string
}

export const SelectAssignee: FC<SelectAssigneeProps> = ({ assignWhat, currentAssignee, setDialogOpen, dataTestId }) => {
  return (
    <>
      <Stack data-testid={dataTestId} direction="row" alignItems="center" gap={1} width="100%" height="100%">
        <Tooltip title={currentAssignee ? 'Change assignee' : `Assign ${assignWhat}`}>
          <IconButton
            icon={currentAssignee ? faUserPen : faUserPlus}
            size="small"
            onClick={() => setDialogOpen(true)}
          />
        </Tooltip>
        <Tooltip title={currentAssignee?.label}>
          <Box overflow="hidden" sx={{ '& .MuiTypography-root': { overflow: 'hidden' } }}>
            <Text variant="body2" data-testid={'assignee-text'} textOverflow="ellipsis">
              {currentAssignee?.email
                ? currentAssignee.first_name || currentAssignee.last_name
                  ? [currentAssignee.first_name, currentAssignee.last_name].filter((name) => name).join(' ')
                  : `${currentAssignee.email}`
                : ''}
            </Text>
          </Box>
        </Tooltip>
      </Stack>
    </>
  )
}
