import React, { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { faSort } from '@fortawesome/pro-solid-svg-icons'
import { MenuItem, IconButton, Menu } from '@howgood/design'
import { selectCurrentInitiative, selectCurrentScenario, setCurrentScenario } from '@/state/initiatives'
import { useHistory } from 'react-router'

export const ScenarioSelector: FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const currentInitiative = useSelector(selectCurrentInitiative)
  const currentScenario = useSelector(selectCurrentScenario)
  const [anchorEl, setAnchorEl] = useState(null)

  if (!currentScenario) return null

  const handleScenarioChanged = (scenarioId: number) => {
    dispatch(setCurrentScenario(scenarioId))
    history.push(`/initiatives/${currentInitiative.id}/scenarios/${scenarioId}`)
  }

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(e.currentTarget)
  }

  if (currentInitiative.scenarios.length === 1) {
    return null
  }

  return (
    <>
      <IconButton
        data-testid="scenario-version-button"
        size="medium"
        icon={faSort}
        color="inherit"
        onClick={handleButtonClick}
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        MenuListProps={{ 'data-testid': 'scenario-version-menu' }}
      >
        {currentInitiative.scenarios.map((scenario) => (
          <MenuItem
            key={scenario.id}
            sx={{ backgroundColor: currentScenario.id === scenario.id ? '#ECECEC' : 'unset' }}
            value={scenario.id}
            onClick={() => handleScenarioChanged(scenario.id)}
          >
            {scenario.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
