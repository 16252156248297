import React, { FC, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Card, Text, Stack } from '@howgood/design'
import { roundBy } from '@/utils/numbers'
import { VendorEngagementBreakdownChart, VendorEngagementBreakdownChartData } from './VendorEngagementBreakdownChart'
import { selectVendorData } from '@/state/vendorManagement'
import {
  VENDOR_ENGAGEMENT_STATUS_NOT_STARTED,
  VENDOR_ENGAGEMENT_STATUS_IN_PROGRESS,
  VENDOR_ENGAGEMENT_STATUS_COMPLETE,
} from '@/records'
import { cardStyles } from '../Charts'

export const VendorEngagementBreakdown: FC = () => {
  const vendorData = useSelector(selectVendorData)

  const chartData = useMemo(() => {
    const allVendorsCount = vendorData.length
    return vendorData.reduce(
      (accum, vendor) => {
        switch (vendor.engagementStatus) {
          case VENDOR_ENGAGEMENT_STATUS_COMPLETE:
            accum[0].value++
            accum[0].pct = roundBy((accum[0].value / allVendorsCount) * 100, 0) || '< 1'
            break
          case VENDOR_ENGAGEMENT_STATUS_IN_PROGRESS:
            accum[1].value++
            accum[1].pct = roundBy((accum[1].value / allVendorsCount) * 100, 0) || '< 1'
            break
          default:
            accum[2].value++
            accum[2].pct = roundBy((accum[2].value / allVendorsCount) * 100, 0) || '< 1'
        }
        return accum
      },
      [
        { name: VENDOR_ENGAGEMENT_STATUS_COMPLETE, value: 0, pct: 0 },
        { name: VENDOR_ENGAGEMENT_STATUS_IN_PROGRESS, value: 0, pct: 0 },
        { name: VENDOR_ENGAGEMENT_STATUS_NOT_STARTED, value: 0, pct: 0 },
      ] as VendorEngagementBreakdownChartData
    )
  }, [vendorData])

  return (
    <Card sx={cardStyles}>
      <Stack gap={0.4} flexGrow={1}>
        <Text variant="h4">Vendor Engagement Breakdown</Text>
        <Text fontSize={12} lineHeight={1.2}>
          The percentage of your vendors with <b>Vendor Verified</b> data.
        </Text>
        {!!vendorData.length && <VendorEngagementBreakdownChart data={chartData} />}
      </Stack>
    </Card>
  )
}
