import { createSlice } from '@reduxjs/toolkit'
import { PackagingMaterial } from '@/records/PackagingMaterial'
import { getPackagingMaterials } from './packaging.thunk'

export interface State {
  materials?: PackagingMaterial[]
  isLoading: boolean
}

export const initialState: State = {
  materials: [],
  isLoading: false,
}

const packagingSlice = createSlice({
  name: 'packaging',
  initialState,
  reducers: {
    resetProductPackaging(state) {
      Object.keys(initialState).forEach((field) => {
        if (!['materials', 'shapes'].includes(field)) {
          state[field] = initialState[field]
        }
      })
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPackagingMaterials.pending, (state, action) => {
      state.isLoading = action.payload
    })

    builder.addCase(getPackagingMaterials.fulfilled, (state, action) => {
      state.isLoading = false
      state.materials = action.payload
    })

    builder.addCase(getPackagingMaterials.rejected, (state, action) => {
      console.error(action.error)
      state.isLoading = false
      state.materials = []
    })
  },
})

export const packagingReducer = packagingSlice.reducer

export const { resetProductPackaging } = packagingSlice.actions
