import { ImpactScoreResponse, fetchProductImpactScore } from '@/api'
import { FetchProducts, fetchProducts } from '@/api/elastic/productsEsRepo'

type Score = number | string | boolean

export function getIsNonNumeric(esScore: Score, apiScore: Score): boolean {
  if (isNaN(+esScore) || isNaN(+apiScore)) {
    return true
  }
  if (typeof esScore === 'boolean' || typeof apiScore === 'boolean') {
    return true
  }
  return false
}

export function checkScoresMatch(esScore: Score, apiScore: Score): boolean {
  if (esScore === apiScore) {
    return true
  }
  if (getIsNonNumeric(esScore, apiScore)) {
    return false
  }
  return +esScore - +apiScore === 0
}

// Typically numerical values, but impact scores can also be strings/booleans
export type Discrepancies = Record<string, [Score, Score]>
interface GetElasticDiscrepancies {
  error: boolean
  discrepancies: Discrepancies
}
export async function getElasticDiscrepancies(productIdStr: string): Promise<GetElasticDiscrepancies> {
  let esProducts: FetchProducts = null
  let product: ImpactScoreResponse = null
  const productId = parseInt(productIdStr, 10)
  if (isNaN(productId) || typeof productId !== 'number') {
    return { error: true, discrepancies: null }
  }
  try {
    esProducts = await fetchProducts({ filters: { ids: [productId] } })
    product = await fetchProductImpactScore(productId)
  } catch (_e) {
    return { error: true, discrepancies: null }
  }
  const apiImpactScores = product.product

  const esImpactScores = esProducts.hits[0].impact_score
  const discrepancies: Discrepancies = {}

  Object.entries(apiImpactScores).forEach(([key, value]) => {
    const compareableTypes: Score[] = ['string', 'number', 'boolean']
    const scoresMatch = checkScoresMatch(esImpactScores[key], value)
    const acceptedType = compareableTypes.includes(typeof value)
    if (key in esImpactScores && !scoresMatch && acceptedType) {
      discrepancies[key] = [esImpactScores[key], value]
    }
  })

  return { error: false, discrepancies }
}
