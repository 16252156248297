import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormControlLabel, Switch, Text } from '@howgood/design'
import { selectShowCarbonExtras, setShowCarbonExtras } from '@/state/pageSettings'
import { selectIsInitiative, selectIsProcurement } from '@/state/router'

export const CarbonExtrasToggle: FC = () => {
  const dispatch = useDispatch()
  const showCarbonExtras = useSelector(selectShowCarbonExtras)
  const isProcurement = useSelector(selectIsProcurement)
  const isInitiatives = useSelector(selectIsInitiative)

  if (isProcurement || isInitiatives) {
    return null
  }

  return (
    <FormControlLabel
      control={
        <Switch
          data-testid="carbon-extras-toggle"
          checked={showCarbonExtras}
          onChange={(e) => dispatch(setShowCarbonExtras(e.target.checked))}
        />
      }
      label={
        <Text data-testid="carbon-extras-toggle-label" color="primary" variant="body2">
          Show all carbon metrics
        </Text>
      }
      labelPlacement="start"
    />
  )
}
