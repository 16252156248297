import React, { FC, useMemo } from 'react'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { Text } from '@howgood/design'
import { IImpactMetricOption } from '@/records'
import { formatNumber } from '@howgood/utils'
import { getScoreChangeColor } from '@/utils/getScoreChangeColor'
import { GridScenario } from '@/records/Initiatives'

export interface SpreadCellValue {
  scenario: GridScenario
  spread: number
  isChartTable: boolean
}
export interface SpreadCellParams extends GridRenderCellParams {
  metric: IImpactMetricOption
  units?: string
  value: SpreadCellValue
}

export const SpreadCell: FC<SpreadCellParams> = ({
  metric,
  value = { scenario: { id: null }, spread: null, isChartTable: false },
  units,
}) => {
  const { scenario, spread, isChartTable } = value
  const color = useMemo(() => getScoreChangeColor({ metricKey: metric.value, units, change: spread }), [
    spread,
    metric.value,
    units,
  ])

  if (spread === null) {
    return null
  }

  return (
    <Text
      id={`spread-${scenario.id}-${metric.value}${isChartTable ? '-chart' : ''}`}
      width="100%"
      textAlign="center"
      color={color}
      fontWeight={isChartTable ? 600 : 'inherit'}
      data-testid={`spread-${scenario.id}-${metric.value}${isChartTable ? '-chart' : ''}`}
    >
      {formatNumber(Math.abs(spread), 1)}
    </Text>
  )
}
