import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { faLocationArrow } from '@fortawesome/pro-solid-svg-icons'
import { Tooltip, Stack, Box, Icon } from '@howgood/design'

import { ProductListRenderCellParams } from './ProductGridTypes'
import { addMessage } from '@/state/messages'

interface Props extends ProductListRenderCellParams {
  value: string
}

export const CopyLinkRenderer: FC<Props> = ({ value, colDef, id }) => {
  const dispatch = useDispatch()

  return (
    <Tooltip title={value} disableInteractive>
      <Stack
        data-testid={`copy-link-cell-${colDef.field}-${id}`}
        alignItems="center"
        width="100%"
        height="100%"
        justifyContent="center"
        sx={{
          cursor: 'pointer',
        }}
      >
        <CopyToClipboard
          text={value}
          onCopy={(text: string) => dispatch(addMessage({ message: `Copied: ${text}`, severity: 'info' }))}
        >
          <Box my={1}>
            <Icon icon={faLocationArrow} color="#4D4E54" />
          </Box>
        </CopyToClipboard>
      </Stack>
    </Tooltip>
  )
}
