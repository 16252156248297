import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'
import { Stack, Tooltip, theme } from '@howgood/design'
import { ImpactScore } from '@/api'
import { Field } from '@/constants/impactScore'
import { getImpactScoreValue, transformScoreToZeroToTenScale, getValueForColorCell } from '@/utils/impactScoreHelpers'
import { useContentfulMetric } from '@/contentfully/hooks'
import { ProductGoalRenderer } from '@/components/GridCellRenderers/ProductGoalRenderer'

export interface HeatmapTableCellProps {
  score: number
  benchmark?: number
  field: Field
  label?: string
  showScores?: boolean
  hideValue?: boolean
  hideTooltip?: boolean
  impactScore?: ImpactScore
  includeGoal?: boolean
  productId?: number
}
interface StyleProps {
  diff: number
  score: number
}

const useStyles = createUseStyles<string, StyleProps>({
  cell: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    position: 'relative',
    minWidth: '40px',
    backgroundColor: ({ score }) => (typeof score === 'number' ? theme.palette.impact[score] : theme.palette.impact[0]),
  },
  diff: {
    position: 'absolute',
    bottom: 5,
    right: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 20,
    height: 20,
    background: 'rgba(255, 255, 255, 0.4)',
    borderRadius: '50%',
    fontWeight: 600,
    fontSize: '12px',
    color: ({ diff }) => (diff > 0 ? theme.palette.impact[10] : theme.palette.impact[1]),
  },
})

interface GetTooltipContent {
  formattedValue: string
  units: string
  hideTooltip: boolean
}
function getTooltipContent({ formattedValue, units, hideTooltip }: GetTooltipContent): string {
  if (hideTooltip) return null
  if (!formattedValue) return 'N/A'
  return units ? `${formattedValue} ${units}` : formattedValue
}

export const HeatmapTableCell: FC<HeatmapTableCellProps> = ({
  score,
  benchmark,
  field,
  showScores = false,
  hideValue = false,
  hideTooltip = false,
  label,
  impactScore,
  includeGoal = false,
  productId = null,
}) => {
  const content = useContentfulMetric(field)

  const scoreTransformed = hideValue ? 0 : transformScoreToZeroToTenScale(field, score)
  const benchmarkTransformed = hideValue ? 0 : transformScoreToZeroToTenScale(field, benchmark)
  const benchmarkDiff = typeof benchmark === 'number' ? Math.round(scoreTransformed - benchmarkTransformed) : 0
  const diff = Math.abs(benchmarkDiff)
  const diffSign = benchmarkDiff > 0 ? '+' : '-'
  const diffWithSign = `${diffSign}${diff}`
  const formattedValue = getImpactScoreValue({
    score,
    field,
    impactScore,
    valuesMap: content?.valuesMap,
  })

  const tooltipContent = getTooltipContent({
    formattedValue,
    units: content?.units,
    hideTooltip,
  })
  const classes = useStyles({ diff: benchmarkDiff, score: scoreTransformed })

  return (
    <Tooltip title={tooltipContent} disableInteractive>
      <div data-testid={'heatmap-cell'} className={classes.cell}>
        <Stack direction="row" alignItems="center" gap={1}>
          {showScores ? getValueForColorCell({ field, score, impactScore, units: content?.units }) : label || ''}
          {includeGoal && <ProductGoalRenderer productId={productId} field={field} />}
        </Stack>
        {score > 0 && diff > 0 && (
          <div aria-label="Ingredient benchmark" className={classes.diff}>
            {diffWithSign}
          </div>
        )}
      </div>
    </Tooltip>
  )
}
