import React, { FC, useMemo } from 'react'
import MuiDialog, { DialogProps as MuiDialogProps } from '@mui/material/Dialog'
import MuiDialogTitle from '@mui/material/DialogTitle'
import MuiDialogContent from '@mui/material/DialogContent'
import MuiDialogActions from '@mui/material/DialogActions'
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons'
import { Button, ButtonProps } from '../Button'
import { Stack } from '../Stack'
import { Text } from '../Text'
import { Icon } from '../Icon'

interface Props extends Pick<MuiDialogProps, 'id' | 'open' | 'onClose' | 'maxWidth' | 'children'> {
  title?: string
  titleIcon?: IconDefinition
  primaryButton?: React.ReactNode
  secondaryButton?: React.ReactNode
  primaryButtonText?: string
  primaryButtonDisabled?: boolean
  primaryButtonAction?: () => void
  primaryButtonColor?: ButtonProps['color']
  primaryButtonIcon?: IconDefinition
  primaryButtonAriaLabel?: string
  secondaryButtonText?: string
  secondaryButtonDisabled?: boolean
  secondaryButtonAction?: () => void
  secondaryButtonColor?: ButtonProps['color']
  secondaryButtonIcon?: IconDefinition
  secondaryButtonAriaLabel?: string
  buttonOrientation?: 'row' | 'column' | 'row-reverse' | 'column-reverse'
  overflow?: string
  ['data-testid']?: string
}

const createStyles = (overflow: string) => ({
  dialogTitle: {
    fontFamily: 'Whitney',
    fontWeight: 600,
    fontSize: 22,
    px: 3,
    pt: 3,
    pb: 1,
  },
  dialogContent: {
    px: 3,
    py: 0,
    overflowY: overflow,
    overflowX: overflow,
  },
  dialogActions: {
    gap: 1,
    px: 3,
    py: 3,
    justifyContent: 'end',
  },
})

export const Dialog: FC<Props> = ({
  id,
  open,
  title,
  titleIcon,
  primaryButton,
  secondaryButton,
  primaryButtonText,
  primaryButtonDisabled,
  primaryButtonAction,
  primaryButtonColor,
  primaryButtonIcon,
  primaryButtonAriaLabel,
  secondaryButtonText,
  secondaryButtonDisabled,
  secondaryButtonAction,
  secondaryButtonColor,
  secondaryButtonIcon,
  secondaryButtonAriaLabel,
  onClose,
  buttonOrientation = 'column',
  maxWidth = 'xs',
  children,
  overflow = 'auto',
  ['data-testid']: dataTestId,
}) => {
  const styles = useMemo(() => createStyles(overflow), [overflow])

  const showActions = Boolean(primaryButton || secondaryButton || primaryButtonAction || secondaryButtonAction)

  return (
    <MuiDialog
      id={id || 'alert-dialog'}
      data-testid={dataTestId}
      open={open}
      onClose={onClose}
      maxWidth={maxWidth}
      aria-labelledby="alert-dialog-title"
      fullWidth
    >
      {(title || titleIcon) && (
        <MuiDialogTitle id="alert-dialog-title" sx={styles.dialogTitle}>
          <Stack direction="row" spacing={0.5}>
            {titleIcon && <Icon icon={titleIcon} />}
            {title && <Text variant="h3">{title}</Text>}
          </Stack>
        </MuiDialogTitle>
      )}
      <MuiDialogContent sx={styles.dialogContent}>{children}</MuiDialogContent>
      {showActions && (
        <MuiDialogActions
          sx={{
            ...styles.dialogActions,
            flexDirection: buttonOrientation,
            '& button': {
              width: ['column', 'column-reverse'].includes(buttonOrientation) ? '100%' : null,
              mx: '0 !important',
            },
          }}
        >
          {primaryButton ||
            (primaryButtonAction && (
              <Button
                aria-label={primaryButtonAriaLabel}
                startIcon={primaryButtonIcon}
                color={primaryButtonColor}
                onClick={primaryButtonAction}
                disabled={primaryButtonDisabled}
              >
                {primaryButtonText || 'OK'}
              </Button>
            ))}
          {secondaryButton ||
            (secondaryButtonAction && (
              <Button
                aria-label={secondaryButtonAriaLabel}
                startIcon={secondaryButtonIcon}
                color={secondaryButtonColor}
                onClick={secondaryButtonAction}
                disabled={secondaryButtonDisabled}
              >
                {secondaryButtonText || 'Cancel'}
              </Button>
            ))}
        </MuiDialogActions>
      )}
    </MuiDialog>
  )
}
