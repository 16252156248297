import React from 'react'
import { Tooltip, Text, Stack, Chip, Box } from '@howgood/design'
import { RichText } from '@/contentfully'
import { useContentfulTooltip } from '@/contentfully/hooks'
import { BLOCKS } from '@contentful/rich-text-types'
import { TooltipRenderer } from '@/contentfully/renderers/TooltipRenderer'

export const SBTiTargetTypeHeader = () => {
  const content = useContentfulTooltip('sbti-target-type')

  return (
    <Tooltip
      title={
        <Stack gap={1} maxHeight={400} overflow="auto">
          <Box width={60}>
            <Chip label="BETA" size="small" color="primary" />
          </Box>
          <RichText
            document={content?.content}
            rendererOptions={{ renderNode: { [BLOCKS.PARAGRAPH]: TooltipRenderer } }}
          />
        </Stack>
      }
    >
      <Text variant="body2" fontWeight={600}>
        {content?.title ?? 'SBTi Target Type'}
      </Text>
    </Tooltip>
  )
}

export const SBTiActionHeader = () => {
  const content = useContentfulTooltip('sbti-action')

  return (
    <Tooltip
      title={
        <Stack gap={1} maxHeight={400} overflow="auto">
          <Box width={60}>
            <Chip label="BETA" size="small" color="primary" />
          </Box>
          <RichText
            document={content?.content}
            rendererOptions={{ renderNode: { [BLOCKS.PARAGRAPH]: TooltipRenderer } }}
          />
        </Stack>
      }
    >
      <Text variant="body2" fontWeight={600}>
        {content?.title ?? 'SBTi Action'}
      </Text>
    </Tooltip>
  )
}
