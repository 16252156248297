import { createSelector } from 'reselect'
import {
  AttributeEntry,
  CarbonAccountingEntry,
  ContentfulDataHealthIndicatorKey,
  DataHealthIndicatorEntry,
  DocumentEntry,
  FormulaIconEntry,
  LinkEntry,
  MethodologyEntry,
  MetricEntry,
  NewsEntry,
  RequestVendorDataEntry,
  TooltipEntry,
} from '@/contentfully'
import {
  Field,
  NON_PERMISSION_FIELDS,
  PERMISSIONS_TO_FIELDS_MAP,
  PRODUCT_INGREDIENT_HEATMAP_PERMISSIONS,
  PRODUCT_IMPACT_DETAIL_PERMISSIONS,
  CARBON_FOOTPRINT_PERMISSIONS,
  CARBON_LIFECYCLE_PERMISSIONS,
  IMPACT_HISTORY_PERMISSIONS,
  IMPACT_HISTORY_ATTRIBUTE_PERMISSIONS,
} from '@/constants/impactScore'
import { AppState } from '@/store'
import {
  selectDataHealthFields,
  selectUserIngredientsPermissions,
  selectUserProductsPermissions,
} from '../user/user.selectors'
import { getSortedImpacts } from '@/utils/getSortedImpacts'
import { selectCustomMetrics } from '@/features/PortfolioDashboard/state/portfolioDashboard/portfolioDashboard.selectors'
import { CustomMetric } from '@/features/PortfolioDashboard/state/portfolioDashboard/portfolioDashboard.types'
import { selectIsProcurement } from '../router'

const selectAllContentfulMetricData = (state: AppState): MetricEntry[] => state.contentfulData.contentfulData.metric

const filterAndSortContentfulData = (
  contentfulData: MetricEntry[],
  userPermissions: string[],
  dataHealthFields: ContentfulDataHealthIndicatorKey[],
  isProcurement: boolean = false
) => {
  const userPermittedFields = userPermissions
    .map((permission) => PERMISSIONS_TO_FIELDS_MAP[permission])
    .filter((field) => Boolean(field)) // remove undefined values (not included in PERMISSIONS_TO_FIELDS_MAP)
  const filteredEntries = contentfulData
    .filter((metric) =>
      [...new Set(userPermittedFields), ...NON_PERMISSION_FIELDS, ...dataHealthFields].includes(metric.key)
    )
    // update suptitle for cf_mtr_transportation_impact as the subtitle depends on the context
    .map((entry) =>
      entry.key === 'cf_mtr_transportation_impact'
        ? { ...entry, subtitle: `${isProcurement ? 'Material' : 'Product'} Transportation` }
        : entry
    )
  return getSortedImpacts(filteredEntries)
}

export const selectContentfulMetricData = createSelector(
  selectAllContentfulMetricData,
  selectDataHealthFields,
  selectUserProductsPermissions,
  selectIsProcurement,
  (metricData, dataHealthFields, userPermissions, isProcurement) => {
    return filterAndSortContentfulData(metricData, userPermissions, dataHealthFields, isProcurement)
  }
)

export const selectIngredientContentfulMetricData = createSelector(
  selectAllContentfulMetricData,
  selectDataHealthFields,
  selectUserProductsPermissions,
  selectUserIngredientsPermissions,
  selectIsProcurement,
  (metricData, dataHealthFields, userPermissions, userIngredientPermissions, isProcurement) => {
    return filterAndSortContentfulData(
      metricData,
      [...userPermissions, ...userIngredientPermissions],
      dataHealthFields,
      isProcurement
    )
  }
)

const selectAllContentfulAttributeData = (state: AppState): AttributeEntry[] =>
  state.contentfulData.contentfulData.attribute
export const selectContentfulAttributeData = createSelector(
  selectAllContentfulAttributeData,
  selectUserProductsPermissions,
  (attributeData, userPermissions) => {
    const userPermittedFields = userPermissions.map((permission) => PERMISSIONS_TO_FIELDS_MAP[permission])
    return attributeData.filter((metric) => userPermittedFields.includes(metric.key as Field))
  }
)

export const selectContentfulCarbonAccountingData = (state: AppState): CarbonAccountingEntry[] =>
  state.contentfulData.contentfulData.carbonAccounting

export const selectContentfulDocumentData = (state: AppState): DocumentEntry[] =>
  state.contentfulData.contentfulData.document

export const selectContentfulTooltipData = (state: AppState): TooltipEntry[] =>
  state.contentfulData.contentfulData.tooltip

export const selectContentfulLinkData = (state: AppState): LinkEntry[] => state.contentfulData.contentfulData.link

export const selectContentfulRequestVendorDataData = (state: AppState): RequestVendorDataEntry[] =>
  state.contentfulData.contentfulData.requestVendorData

export const selectContentfulMethodologyData = (state: AppState): MethodologyEntry[] =>
  state.contentfulData.contentfulData.methodology

export const selectContentfulDataHealthIndicatorData = (state: AppState): DataHealthIndicatorEntry[] =>
  state.contentfulData.contentfulData.dataHealthIndicator

export const selectContentfulFormulaIconData = (state: AppState): FormulaIconEntry[] =>
  state.contentfulData.contentfulData.formulaIcon

export const selectContentfulNewsData = (state: AppState): NewsEntry[] => state.contentfulData.contentfulData.news

// Return data for metrics that map to impact scores
export const selectContentfulImpactDetailData = createSelector(selectContentfulMetricData, (contentfulData) => {
  const impactScoreFields = PRODUCT_IMPACT_DETAIL_PERMISSIONS.map((permission) => PERMISSIONS_TO_FIELDS_MAP[permission])
  return contentfulData.filter((item) => impactScoreFields.includes(item.key))
})

/**
 * Return custom metrics with contentful data merged in if available
 */
export const selectContentfulCustomMetricsData = createSelector(
  selectCustomMetrics,
  selectContentfulMetricData,
  (customMetrics, contentfulData) => {
    return customMetrics.map((metric) => {
      const contentfulMetric = contentfulData.find((data) => data.key === metric.key.replace('custom_', ''))
      return contentfulMetric ? ({ ...metric, ...contentfulMetric } as CustomMetric) : metric
    })
  }
)

// Return data for metrics that map to non-annualized product impact scores
export const selectContentfulProductImpactData = createSelector(selectContentfulMetricData, (contentfulData) => {
  const productImpactScoreFields = [
    ...CARBON_FOOTPRINT_PERMISSIONS,
    ...CARBON_LIFECYCLE_PERMISSIONS,
    ...PRODUCT_IMPACT_DETAIL_PERMISSIONS,
  ]
    .filter((permission) => !permission.includes('_annual'))
    .map((permission) => PERMISSIONS_TO_FIELDS_MAP[permission])
  return contentfulData.filter((item) => productImpactScoreFields.includes(item.key))
})

// Return metrics that are specified in metric tons e.g. blue water usage (m3/mt) and land occupation (ha/mt)
// Don't use `endsWith('/mt') because there may be something like (scarcity adjusted) appended
export const selectContentfulMetricTonsMetrics = createSelector(selectContentfulImpactDetailData, (contentfulData) => {
  const metricTonsFields = contentfulData.filter((item) => item.units?.includes('/mt')).map((entry) => entry.key)
  return metricTonsFields as Field[]
})

export const selectContentfulIngredientHeatmapData = createSelector(
  selectIngredientContentfulMetricData,
  (contentfulData) => {
    const impactScoreFields = PRODUCT_INGREDIENT_HEATMAP_PERMISSIONS.map(
      (permission) => PERMISSIONS_TO_FIELDS_MAP[permission]
    )
    return contentfulData.filter((item) => impactScoreFields.includes(item.key))
  }
)

export const selectLatestContentfulNewsData = createSelector(selectContentfulNewsData, (news) => {
  if (news.length === 0) return null
  const newsCopy = [...news]
  const newsSorted = newsCopy.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
  return newsSorted[0]
})

export const selectContentfulImpactHistoryData = createSelector(selectContentfulMetricData, (contentfulData) => {
  const impactScoreFields = IMPACT_HISTORY_PERMISSIONS.map((permission) => PERMISSIONS_TO_FIELDS_MAP[permission])
  return contentfulData.filter((item) => impactScoreFields.includes(item.key))
})

export const selectContentfulImpactHistoryAttributesData = createSelector(
  selectContentfulAttributeData,
  (contentfulData) => {
    const attributeFields = IMPACT_HISTORY_ATTRIBUTE_PERMISSIONS.map(
      (permission) => PERMISSIONS_TO_FIELDS_MAP[permission]
    )
    return contentfulData.filter((item) => attributeFields.includes(item.key as Field))
  }
)

export const selectIsContentfulLoading = (state: AppState): boolean => state.contentfulData.isLoading

export const selectIsContentfulError = (state: AppState): boolean => state.contentfulData.isError

export const selectContentfulMetricByKey = (metricKey: string) =>
  createSelector(selectContentfulMetricData, (contentfulData) => {
    return contentfulData.find((data) => data.key === metricKey)
  })
