import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Stack } from '@howgood/design'

import { selectIsEditing } from '@/state/recipe'
import { DRAFT, selectPageView } from '@/state/pageSettings'
import { selectCanLockProduct } from '@/state/productOverview'
import { ViewSelect } from './ViewSelect'
import { ValidationStatusIcons } from './ValidationStatusIcons/ValidationStatusIcons'
import { EditButton } from '../EditButton'
import { ResetButton } from '../ResetButton'
import { SaveButton } from '../SaveButton'
import { MainButton } from './MainButton'
import { selectCurrentScenarioId } from '@/state/initiatives'

export const ValidationControls: FC = () => {
  const editing = useSelector(selectIsEditing)
  const pageView = useSelector(selectPageView)
  const productLockable = useSelector(selectCanLockProduct)
  const selectedScenario = useSelector(selectCurrentScenarioId)

  if (selectedScenario) {
    return null
  }

  return (
    <Stack alignItems="end">
      <ViewSelect />
      <ValidationStatusIcons />
      <Stack spacing={1} mt={1} direction="row" justifyContent="end" width="max-content">
        <EditButton buttonText={editing ? 'Resume editing formula' : 'Edit formula details'} />
        <ResetButton />
        {pageView === DRAFT && <SaveButton />}
        {productLockable && <MainButton />}
      </Stack>
    </Stack>
  )
}
