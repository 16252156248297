import React, { FC } from 'react'
import { Stack } from '@howgood/design'

import { BasisSelect } from './BasisSelect'
import { ViewSelect } from './ViewSelect'
import { MetricSelect } from './MetricSelect'
import { ChartTypeToggle } from './ChartTypeToggle'
import { TagFilters } from './TagFilters'

export const Toolbar: FC = () => {
  return (
    <Stack gap={1}>
      <Stack direction="row" alignItems="center" gap={1}>
        <ViewSelect />
        <MetricSelect />
        <BasisSelect />
        <ChartTypeToggle />
      </Stack>
      <TagFilters />
    </Stack>
  )
}
