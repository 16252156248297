import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import { createSelector } from 'reselect'
import { selectEditableWorkspaceIds, selectWorkspaces } from '@/state/workspaces'
import { DRAFT, PUBLISHED, selectPageView } from '../pageSettings'
import { initialDraftInventory, ProductInfo } from './productOverview.state'
import { AppState } from '@/store'
import { InventoriesResponse, ValidationRequest } from '@/api'
import { SharingRequest, ScenarioProduct, ClaimsReportsType } from '@/records'
import { checkHasLcaOverriddenNestedProduct, checkLcaOverriddenProduct } from '@/utils/formulationSearch'
import { generateTags } from './productOverview.utils'
import { selectCurrentScenarioId, selectSortedScenarios } from '../initiatives/initiatives.selectors'
import { parseReportDateKey } from '@/utils/dates'
import { selectIsSupplierConnect } from '../organization'

export const selectSavedProductInfo = (state: AppState): ProductInfo => state.productOverview.savedProductInfo

export const selectDraftProductInfo = (state: AppState): ProductInfo => state.productOverview.draftProductInfo

export const selectLockedProductInfo = (state: AppState): ProductInfo => state.productOverview.lockedProductInfo

export const selectDraftWorkspaces = (state: AppState): { id: number; name: string }[] =>
  state.productOverview.draftProductInfo.workspaces

export const selectSavedProductName = createSelector(selectSavedProductInfo, (productInfo) => productInfo.name)

export const selectLockedClaimsTimeStamp = createSelector(
  selectLockedProductInfo,
  (lockedClaims) => lockedClaims?.date_modified
)

export const selectIsInitiativeSourceProduct = createSelector(
  selectSavedProductInfo,
  (productInfo) => productInfo.initiatives?.length > 0 && !productInfo.source_product // No source_product id
)

export const selectProductScenarios = createSelector(
  selectSavedProductInfo,
  selectSortedScenarios,
  (product, scenarios) =>
    product?.scenarios?.length && scenarios.length
      ? product.scenarios.reduce((productScenarios, productScenario) => {
          const found = scenarios.find((scenario) => scenario.id === productScenario.id)
          if (found) {
            return [...productScenarios, found]
          }
          return productScenarios
        }, [])
      : []
)

export const selectIsScenarioContext = createSelector(
  selectSavedProductInfo,
  selectCurrentScenarioId,
  (product, scenario) => Boolean(scenario && product?.id)
)

export const selectSavedTimeStamp = createSelector(
  selectSavedProductInfo,
  (product) => product.date_modified || product.date_created
)

export const selectClaimsReports = createSelector(selectSavedProductInfo, (product) => product.reports)

export const selectCurrentClaimsReport = createSelector<
  AppState,
  ClaimsReportsType,
  { date: string; url: string } | null
>(selectClaimsReports, (reports) => {
  if (isEmpty(reports) || isEmpty(reports.items)) {
    return null
  }

  const reportList = [...(reports as ClaimsReportsType).items]
  reportList.sort((a, b) => +b.date - +a.date)
  return { date: reportList[0].date, url: reportList[0].url }
})

export const selectShouldGenerateReport = createSelector(
  selectCurrentClaimsReport,
  selectLockedClaimsTimeStamp,
  (report, lockedTime) => {
    const reportDateTime = report?.date ? parseReportDateKey(report.date) : null
    const lockedDateTime = lockedTime ? new Date(lockedTime) : null

    const lockedWithNoReport = lockedDateTime && !reportDateTime
    const lockedWithStaleReport =
      lockedDateTime && reportDateTime && lockedDateTime.toString() !== reportDateTime.toString()
    return lockedWithNoReport || lockedWithStaleReport
  }
)

export const selectSavedInventories = (state: AppState): InventoriesResponse[] => state.productOverview.savedInventories

export const selectDraftInventories = (state: AppState): InventoriesResponse[] => state.productOverview.draftInventories

export const selectIsProductLoading = (state: AppState): boolean => state.productOverview.isLoadingProduct

export const selectIsLockLoading = (state: AppState): boolean => state.productOverview.isLoadingLock

export const selectIsShareLoading = (state: AppState): boolean => state.productOverview.isLoadingShare

export const selectIsValidationLoading = (state: AppState): boolean => state.productOverview.isLoadingValidationRequest

/**
 * Returns true if any of the product, lock request, share request, or validation requests are loading
 */
export const selectIsProductOveriewLoading = createSelector(
  selectIsProductLoading,
  selectIsLockLoading,
  selectIsShareLoading,
  selectIsValidationLoading,
  (product, lock, share, validation) => product || lock || share || validation
)

export const selectProductErrorText = (state: AppState): string => state.productOverview.productErrorText

export const selectIsCreateNew = createSelector(selectSavedProductInfo, (product) => !product.id)

export const selectIsSupplierConnectCreateNew = createSelector(
  selectIsCreateNew,
  selectIsSupplierConnect,
  (isCreateNew, isSupplierConnect) => isCreateNew && isSupplierConnect
)

export const selectIsImpactsOverridden = createSelector(selectSavedProductInfo, (product) =>
  checkLcaOverriddenProduct(product)
)

export const selectHasImpactsOverriddenNestedProduct = createSelector(selectSavedProductInfo, (product) =>
  checkHasLcaOverriddenNestedProduct(product)
)

export const selectIsThirdParty = createSelector(selectSavedProductInfo, selectIsCreateNew, (product, isCreateNew) => {
  if (isCreateNew) return false // This is for add new mode
  return product.is_foreign
})

export const selectDisplayedProduct = createSelector(
  selectIsThirdParty,
  selectSavedProductInfo,
  selectDraftProductInfo,
  selectLockedProductInfo,
  selectPageView,
  (isForeign, saved, draft, locked, view) => {
    // There are some fields that either are impossible to store in locked claims or
    // do not make sense there, these we get from the saved product
    const fullLockedProd: ProductInfo = {
      ...locked,
      reports: saved.reports,
      sharing_requests: saved.sharing_requests,
      brand: saved.brand,
      is_foreign: saved.is_foreign,
      workspaces: saved.workspaces,
    }
    if (isForeign) return fullLockedProd
    return view === DRAFT ? draft : view === PUBLISHED ? fullLockedProd : saved
  }
)

const selectArchivedFormulationStatus = createSelector(selectSavedProductInfo, (product) => {
  return product.formulation_status === 'archived'
})

const selectIsProductOwner = createSelector(selectSavedProductInfo, selectWorkspaces, (product, workspaces) => {
  if (!product?.id) return true // This is for add new mode
  return product.workspaces.some((pws) => workspaces.some((ws) => pws.id === ws.id && ws.edit))
})

export const selectCanEditProduct = createSelector(
  selectIsCreateNew,
  selectIsProductOwner,
  selectArchivedFormulationStatus,
  (createNew, isOwner, closedFormulation) => {
    if (createNew) return true // This is for add new mode
    return !closedFormulation && isOwner
  }
)

export const selectProductIsLocked = createSelector(selectLockedProductInfo, (lockedProduct): boolean => {
  return !!lockedProduct.id
})

export const selectCanLockProduct = createSelector(
  selectSavedProductInfo,
  selectIsProductOwner,
  (savedProduct, isOwner) => {
    return savedProduct.id && isOwner
  }
)

export const selectDisplayedInventories = createSelector(
  selectIsThirdParty,
  selectSavedInventories,
  selectDraftInventories,
  selectPageView,
  (isForeign, saved, draft, view) => {
    if (isForeign) return saved
    return view === DRAFT ? draft : saved
  }
)

/**
 * returns the sum of mt per year for the editable inventories
 */
export const selectTotalInventoryMTPerYr = createSelector(
  selectDisplayedInventories,
  selectEditableWorkspaceIds,
  (inventories, workspaces) => {
    let mtPerYr = null
    inventories.forEach((inventory) => {
      if (workspaces.includes(inventory.workspace.id)) {
        if (!mtPerYr) {
          mtPerYr = inventory.mt_per_year
        } else {
          mtPerYr += inventory.mt_per_year
        }
      }
    })
    return mtPerYr
  }
)

export const selectDisplayedProductId = createSelector(
  selectDisplayedProduct,
  (displayedProduct) => displayedProduct.id
)

export const selectDisplayedProductName = createSelector(
  selectDisplayedProduct,
  (displayedProduct) => displayedProduct.name || ''
)

export const selectDisplayedProductUPC = createSelector(
  selectDisplayedProduct,
  (displayedProduct) => displayedProduct.upc || ''
)

export const selectDisplayedProductWorkspaces = createSelector(selectDisplayedProduct, (product) => product.workspaces)

export const selectDisplayedProductFormulationStatus = createSelector(
  selectDisplayedProduct,
  (product) => product.formulation_status
)

export const selectDisplayedProductBrandName = createSelector(selectDisplayedProduct, (product) => product.brand_name)

export const selectDisplayedProductNotes = createSelector(selectDisplayedProduct, (product) => product.notes)

const selectDisplayedWorkspaceInventory = createSelector(
  selectDisplayedProductWorkspaces,
  selectDisplayedInventories,
  (workspaces, inventories) =>
    (workspaces.length && inventories.find((inventory) => inventory.workspace.id === workspaces[0].id)) ||
    initialDraftInventory
)

export const selectDisplayedProductGoals = createSelector(
  selectDisplayedWorkspaceInventory,
  (inventory) => inventory.goals
)

export const getMostRecentRequest = (requests: ValidationRequest[] | SharingRequest[]) => {
  if (requests.length > 0) {
    // Sort requests by modified_date (descending)
    const sortedByDate = requests
      .slice(0)
      .sort((a, b) => new Date(b.date_modified).getTime() - new Date(a.date_modified).getTime())
    return sortedByDate[0]
  }
  return null
}

export const selectValidationRequests = createSelector(
  selectDisplayedWorkspaceInventory,
  (inventory) => inventory.validation_requests
)

export const selectMostRecentlyModifiedValidationRequest = createSelector(
  selectValidationRequests,
  (validation_requests) => getMostRecentRequest(validation_requests) as ValidationRequest
)

export const selectSharingRequests = createSelector(selectDisplayedProduct, (product) => product.sharing_requests || [])

export const selectMostRecentlyModifiedSharingRequest = createSelector(
  selectSharingRequests,
  (requests) => getMostRecentRequest(requests) as SharingRequest
)

export const selectDisplayedTags = createSelector(selectDisplayedInventories, (inventories) => {
  return generateTags(inventories)
})

export const selectDisplayedProductScenarios = createSelector(selectDisplayedProduct, (product) => {
  return product.scenario_products || []
})
const defaultProductScenario = (productId: number, scenarioId: number) =>
  ({
    scenario: {
      id: scenarioId,
    },
    product: {
      id: productId,
    },
    mt_per_year: null,
    goals: {},
  } as ScenarioProduct)
export const selectDisplayedProductScenario = createSelector(
  selectDisplayedProduct,
  selectCurrentScenarioId,
  (product, currentScenario) => {
    const foundScenario = product.scenario_products?.find((sp) => sp.scenario.id === currentScenario)
    return foundScenario || defaultProductScenario(product.id, currentScenario)
  }
)

export const selectIsProductScenarioModified = createSelector(
  selectSavedProductInfo,
  selectDraftProductInfo,
  (savedProduct, draftProduct) => {
    return (
      !isEqual(draftProduct.scenarios, savedProduct.scenarios) ||
      !isEqual(draftProduct.scenario_products, savedProduct.scenario_products)
    )
  }
)

export const selectIsMainProductModified = createSelector(
  selectSavedProductInfo,
  selectDraftProductInfo,
  selectSavedInventories,
  selectDraftInventories,
  (savedProduct, draftProduct, savedInventories, draftInventories) => {
    return (
      draftProduct.name !== savedProduct.name ||
      !isEqual(draftProduct.workspaces, savedProduct.workspaces) ||
      draftProduct.formulation_status !== savedProduct.formulation_status ||
      draftProduct.input_weight_unit !== savedProduct.input_weight_unit ||
      draftProduct.brand_name !== savedProduct.brand_name ||
      !isEqual(draftInventories, savedInventories) ||
      draftProduct.upc !== savedProduct.upc
    )
  }
)

export const selectIsProductModified = createSelector(
  selectIsProductScenarioModified,
  selectIsMainProductModified,
  (productScenarioModified, productModified) => {
    return productScenarioModified || productModified
  }
)
