import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Text } from '@howgood/design'
import { useContentfulDataHealthIndicators } from '@/contentfully/hooks'
import { selectSavedProductInfo } from '@/state/productOverview'
import { selectDisplayedImpactData } from '@/state/recipe'
import { selectDataHealthFields } from '@/state/user'
import { getDataHealthValue } from '@/utils/products'

export const DataHealth: FC = () => {
  const dataHealthFields = useSelector(selectDataHealthFields)
  const impactData = useSelector(selectDisplayedImpactData)
  const savedProduct = useSelector(selectSavedProductInfo)
  const contentfulData = useContentfulDataHealthIndicators(dataHealthFields)

  return (
    <>
      {dataHealthFields.map((field) => {
        const content = contentfulData.find((item) => item.key === field)
        const value =
          field === 'default_origin_percent_impact'
            ? impactData.product?.default_origin_percent_impact
            : savedProduct[field]
        return (
          <Text key={field}>
            {content.title}: {getDataHealthValue(field, value) || 'N/A'}
          </Text>
        )
      })}
    </>
  )
}
