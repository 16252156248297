import React, { FC } from 'react'
import { Box, Text, Tooltip, Stack, Icon } from '@howgood/design'
import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons'

import { panelStyles } from './panelStyles'

interface Props {
  title: string
  tooltipText?: string
}

export const GridItemTitle: FC<Props> = ({ title, tooltipText = null }) => {
  return (
    <Stack direction="row" sx={panelStyles.labelStack}>
      <Text>{title}</Text>
      {tooltipText && (
        <Tooltip title={tooltipText}>
          <Box ml={1}>
            <Icon icon={faQuestionCircle} />
          </Box>
        </Tooltip>
      )}
    </Stack>
  )
}
