import React, { FC, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { DataGridPremium, useGridApiRef } from '@mui/x-data-grid-premium'
import { Box } from '@howgood/design'

import { ProductListDataRow } from '@/utils/products'
import { selectProductsIsLoading, selectProductsSortOptions } from '@/state/products'
import { useColumnVisibilityModel, useProductListColumns } from '@/components/Products/hooks/useProductListColumns'
import { selectProductListSelectedColumns } from '@/state/user'
import { ProductsGridColDef } from '../GridCellRenderers'

// these fields can get very long and we don't want to autosize them
const disabledAutosizeFields = ['certifications', 'ingredients', 'sourcing_locations']

const getColSortField = (field: string, columns: ProductsGridColDef[]) => {
  const found = columns.find((col: ProductsGridColDef) => col.field === field)
  return found?.sortfield ?? field
}

const getColField = (sortfield: string, columns: ProductsGridColDef[]) => {
  const found = columns.find((col: ProductsGridColDef) => col.sortfield === sortfield || col.field === sortfield)
  return found?.field ?? sortfield
}

interface FormulationListProps {
  rowData: ProductListDataRow[]
  handleSort: (key?: string, order?: 'asc' | 'desc') => void
  showBulkActions: boolean
  scenarioId?: number
  selectedRowIds: number[]
  setSelectedRowIds: React.Dispatch<React.SetStateAction<number[]>>
}

export const ProductGrid: FC<FormulationListProps> = ({
  rowData,
  handleSort,
  showBulkActions,
  scenarioId,
  selectedRowIds,
  setSelectedRowIds,
}) => {
  const apiRef = useGridApiRef()
  const isFetching = useSelector(selectProductsIsLoading)
  const selectedColumns = useSelector(selectProductListSelectedColumns)
  const columns = useProductListColumns({ showBulkActions, scenarioId })
  const columnVisibilityModel = useColumnVisibilityModel(columns)
  const sortOptions = useSelector(selectProductsSortOptions)

  const muiSortOptions = useMemo(() => {
    if (!sortOptions?.length || !columns) return []
    return sortOptions.map((sort) => {
      const field = getColField(Object.keys(sort)[0], columns)
      return {
        field,
        sort: Object.values(sort)[0].order,
      }
    })
  }, [sortOptions, columns])

  useEffect(() => {
    apiRef.current.autosizeColumns({
      expand: true,
      columns: columns.filter((col) => !disabledAutosizeFields.includes(col.field)).map((col) => col.field),
    })
  }, [columns, selectedColumns, rowData, isFetching, apiRef])

  useEffect(() => {
    apiRef.current.autosizeColumns({
      expand: true,
      columns: columns.filter((col) => !disabledAutosizeFields.includes(col.field)).map((col) => col.field),
    })
  }, [columns, selectedColumns, rowData, isFetching, apiRef])

  return (
    <Box data-testid="mui-product-list" height="778px">
      <DataGridPremium
        key={`product-grid-${selectedColumns.join('.')}`} // Force state reset to prevent crashing on column selections
        apiRef={apiRef}
        rows={rowData}
        columns={columns}
        columnVisibilityModel={columnVisibilityModel}
        autoHeight
        autosizeOnMount
        autosizeOptions={{
          expand: true,
          columns: columns.filter((col) => !disabledAutosizeFields.includes(col.field)).map((col) => col.field),
        }}
        hideFooter
        initialState={{ density: 'compact' }}
        disableColumnMenu
        disableColumnFilter
        disableRowSelectionOnClick
        loading={isFetching}
        disableVirtualization
        columnHeaderHeight={80}
        checkboxSelection={showBulkActions}
        rowSelectionModel={selectedRowIds}
        onRowSelectionModelChange={(ids) => setSelectedRowIds(ids as number[])}
        sortingMode="server"
        sortModel={muiSortOptions}
        onSortModelChange={(newSortModel) => {
          if (!newSortModel.length) {
            handleSort(null)
            return
          }
          const sortfield = getColSortField(newSortModel[0].field, columns)
          handleSort(sortfield, newSortModel[0].sort)
        }}
        sx={{
          '& .MuiDataGrid-columnHeaderTitle': {
            textWrap: 'wrap',
            lineHeight: 1,
            fontWeight: 600,
          },
          '& .MuiDataGrid-cell': {
            py: 0.125,
            px: 0.5,
          },
        }}
      />
    </Box>
  )
}
