import React, { FC } from 'react'
import MuiList, { ListProps as MuiListProps } from '@mui/material/List'
import MuiListItem, { ListItemProps as MuiListItemProps } from '@mui/material/ListItem'
import MuiListItemText, { ListItemTextProps as MuiListItemTextProps } from '@mui/material/ListItemText'
import MuiListItemIcon from '@mui/material/ListItemIcon'
import MuiListSubheader, { ListSubheaderProps as MuiListSubheaderProps } from '@mui/material/ListSubheader'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { Icon } from './Icon'

type ListStyleType =
  | 'disc'
  | 'circle'
  | 'square'
  | 'decimal'
  | 'decimal-leading-zero'
  | 'disclosure-closed'
  | 'disclosure-open'
  | 'none'

export const LIST_STYLE_TYPE_NONE = 'none' as ListStyleType

interface ListItemIconProps {
  icon: IconDefinition
}

export const ListItemIcon: FC<ListItemIconProps> = ({ icon }) => {
  return (
    <MuiListItemIcon>
      <Icon icon={icon} />
    </MuiListItemIcon>
  )
}

interface ListItemTextProps extends Pick<MuiListItemTextProps, 'primary' | 'children'> {}

export const ListItemText: FC<ListItemTextProps> = ({ primary, children }) => {
  return <MuiListItemText primary={primary}>{children}</MuiListItemText>
}

interface ListItemProps extends Pick<MuiListItemProps, 'children'> {}

export const ListItem: FC<ListItemProps> = ({ children, ...rest }) => {
  return <MuiListItem {...rest}>{children}</MuiListItem>
}

interface ListSubheaderProps extends Pick<MuiListSubheaderProps, 'children'> {}
export const ListSubheader: FC<ListSubheaderProps> = ({ children, ...rest }) => {
  return <MuiListSubheader {...rest}>{children}</MuiListSubheader>
}

interface ListProps extends Pick<MuiListProps, 'children'> {
  listStyleType?: ListStyleType
  dataTestId?: string
}

export const List: FC<ListProps> = ({ dataTestId, listStyleType = LIST_STYLE_TYPE_NONE, children, ...rest }) => {
  // if listStyleType isn't `none` then styling like HTML list
  const styles =
    listStyleType === LIST_STYLE_TYPE_NONE
      ? null
      : {
          listStyleType,
          pl: 2,
          '& .MuiListItem-root': {
            display: 'list-item',
            py: 0,
            pl: 0,
          },
        }

  return (
    <MuiList data-testid={dataTestId} sx={styles} {...rest}>
      {children}
    </MuiList>
  )
}
