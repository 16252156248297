import { transformScoreToZeroToTenScale } from '@/utils/impactScoreHelpers'
import { ImpactScore, LaborRiskInformation } from '@/api'
import { formatNumber } from '@howgood/utils'

export interface ScoreInputs {
  impactKey: string
  currentScore: number | string | LaborRiskInformation[]
  savedScore: number | string | LaborRiskInformation[]
  flatWeight: number
  maxImpactValues?: ImpactScore // Max value of each impact type across all ingredients
  units: string
}

export interface ScoreInfo {
  rawScore: number
  scoreRange: string
  scoreRounded: string
  scoreOneToTen: number
  changeSinceSaved: number
  contribution: number
  contributionRounded: string
  contributionOneToTen: number
  contributionChangeSinceSaved: number
}

export const getNumericScore = (score: number | string | LaborRiskInformation[]): number => {
  if (score === 0) {
    return 0
  }
  return score && ['number', 'string'].includes(typeof score) ? +score : undefined
}

function getRawSOCScoreRange(score: number | string | LaborRiskInformation[], precision: number) {
  if (typeof score !== 'number') {
    return null
  }
  const tenPercent = Math.abs(score * 0.1)
  const lowerBound = score - tenPercent
  const upperBound = score + tenPercent
  const lowerBoundFormatted = lowerBound < 0 ? `(${lowerBound.toFixed(precision)})` : lowerBound.toFixed(precision)
  const upperBoundFormatted = upperBound < 0 ? `(${upperBound.toFixed(precision)})` : upperBound.toFixed(precision)
  return `${lowerBoundFormatted}-${upperBoundFormatted}`
}

export function getScoreRange(
  score: number | string | LaborRiskInformation[],
  impactKey: string,
  precision: number = 3
): string {
  switch (impactKey) {
    case 'raw_soc_impact':
      return getRawSOCScoreRange(score, precision)
    default:
      return null
  }
}

export function isRawScore(impactKey: string): boolean {
  return impactKey.startsWith('raw_') || impactKey === 'cf_processing_impact' || impactKey === 'cf_luc_impact'
}

export const getScore = (scoreInputs: ScoreInputs): ScoreInfo => {
  const { impactKey, currentScore, savedScore, maxImpactValues, flatWeight } = scoreInputs

  // Scores should be numbers, but handle case where there's a string or something else (e.g. `labor_risk_information`))
  const currentNumericScore = getNumericScore(currentScore)
  const savedNumericScore = getNumericScore(savedScore)
  const scoreRange = getScoreRange(currentScore, impactKey)

  if (currentNumericScore === undefined) {
    return {
      rawScore: null,
      scoreRange: null,
      scoreRounded: '',
      scoreOneToTen: null,
      changeSinceSaved: null,
      contribution: null,
      contributionRounded: '',
      contributionOneToTen: null,
      contributionChangeSinceSaved: null,
    }
  }

  // If max column value < 1, round to 3 decimals; otherwise, round to 2 decimals
  const maxColumnValue = maxImpactValues?.[impactKey] || 0
  const rawScoreRounded = formatNumber(currentNumericScore, maxColumnValue < 1 ? 3 : 2)

  const scoreOneToTen = transformScoreToZeroToTenScale(impactKey, currentNumericScore) || null
  const savedScoreOneToTen = transformScoreToZeroToTenScale(impactKey, savedNumericScore) || null

  const contribution = (flatWeight / 100) * currentNumericScore
  const contributionRounded = formatNumber(contribution, 2)
  const contributionOneToTen = transformScoreToZeroToTenScale(impactKey, contribution) || null

  const savedContribution = (flatWeight / 100) * savedNumericScore
  const savedContributionOneToTen = transformScoreToZeroToTenScale(impactKey, savedContribution) || null

  return {
    rawScore: currentNumericScore,
    scoreRange,
    scoreRounded: rawScoreRounded,
    scoreOneToTen: scoreOneToTen,
    changeSinceSaved: scoreOneToTen && savedScoreOneToTen ? scoreOneToTen - savedScoreOneToTen : null,
    contribution,
    contributionRounded,
    contributionOneToTen: contributionOneToTen,
    contributionChangeSinceSaved:
      contributionOneToTen && savedContributionOneToTen ? contributionOneToTen - savedContributionOneToTen : null,
  }
}
