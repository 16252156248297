import React, { FC, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { faShareNodes } from '@fortawesome/pro-regular-svg-icons'
import {
  theme,
  Text,
  Stack,
  TextField,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Box,
  Tooltip,
  Icon,
  Alert,
} from '@howgood/design'

import { HOWGOOD_SUPPLIERS_WORKSPACE_ID } from '@/constants/common'
import {
  requestSharing,
  selectIsShareLoading,
  selectProductIsLocked,
  selectSavedInventories,
  selectSavedProductInfo,
  selectSharingRequests,
  ShareRequestInfo,
} from '@/state/productOverview'
import { sharingSummary, materialDirDescr } from './validationCopy'
import { SharingRequestHistory } from './SharingRequestHistory'
import { RequestCountRow } from './RequestCountRow'
import { usePendoTrack } from '@/hooks'

export const SharingPanel: FC = () => {
  const dispatch = useDispatch()
  const pendoTrack = usePendoTrack()
  const sharingRequests = useSelector(selectSharingRequests)
  const isLocked = useSelector(selectProductIsLocked)
  const isShareLoading = useSelector(selectIsShareLoading)
  const inventories = useSelector(selectSavedInventories)
  const product = useSelector(selectSavedProductInfo)

  // Determine if product is already in the material directory
  const isInMaterialDir = useMemo(() => {
    const workspaces = product?.workspaces || []
    return !!workspaces.find((workspace) => workspace.id === HOWGOOD_SUPPLIERS_WORKSPACE_ID)
  }, [product?.workspaces])

  const _isInMaterialDir = useMemo(
    () => !!inventories.find((inventory) => inventory.workspace.id === HOWGOOD_SUPPLIERS_WORKSPACE_ID),
    [inventories]
  )

  // Determine if there's already a request to add to the material directory
  const materialDirAlreadyRequested = useMemo(
    () => !!sharingRequests.find((request) => request.type === 'material_directory'),
    [sharingRequests]
  )

  const [materialDirSelected, setMaterialDirSelected] = useState(isInMaterialDir)
  const [shareDirectlySelected, setShareDirectlySelected] = useState(false)
  const [requestText, setRequestText] = useState('')

  const title = 'Share with third parties'

  const handleShareClicked = () => {
    let requests = []
    if (materialDirSelected && !materialDirAlreadyRequested) {
      const request = { type: 'material_directory', text: 'Share to HowGood Material Directory' } as ShareRequestInfo
      dispatch(requestSharing(request))
      requests.push(request)
    }
    if (shareDirectlySelected) {
      const directRequest = { type: 'direct', text: requestText } as ShareRequestInfo
      dispatch(requestSharing(directRequest))
      requests.push(directRequest)
    }
    pendoTrack('Formula: Share Requested', { requests })
    setShareDirectlySelected(false)
    setRequestText('')
  }

  let buttonDisabled = false
  let tooltip = ''
  if (!(materialDirSelected || shareDirectlySelected)) {
    buttonDisabled = true
    tooltip = 'Select one of the available options.'
  } else if (shareDirectlySelected && !requestText.trim()) {
    buttonDisabled = true
    tooltip = "Describe how you'd like to share this product."
  } else if (materialDirAlreadyRequested && !shareDirectlySelected) {
    buttonDisabled = true
    tooltip = 'Sharing to the HowGood Material Directory has been requested already.'
  }

  return (
    <Accordion disabled={!isLocked} defaultExpanded={isLocked} data-testid="sharing-panel-accordion">
      <AccordionSummary>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Icon color={theme.palette.text.primary} size="lg" icon={faShareNodes} />
          <Text variant="h3">{title}</Text>
          <RequestCountRow requests={sharingRequests} />
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={1}>
          <Text>{sharingSummary}</Text>
          <SharingRequestHistory />
          {isLocked && !product?.brand?.name && (
            <Alert severity="info">
              Reminder to add the vendor name for this formula under "Other Details" before sharing this formula. This
              allows you to clearly communicate the owner of the formula.
            </Alert>
          )}
          <Text variant="h4">Make a request</Text>
          <Stack spacing={0} pl={1}>
            <Tooltip
              title={
                materialDirAlreadyRequested
                  ? 'Sharing to the HowGood Material Directory has been requested already.'
                  : ''
              }
            >
              <Box>
                <Checkbox
                  id="material-directory-checkbox"
                  size="small"
                  checked={materialDirSelected}
                  disabled={isInMaterialDir || materialDirAlreadyRequested}
                  onChange={() => setMaterialDirSelected(!materialDirSelected)}
                  label="The HowGood Material Directory"
                />
              </Box>
            </Tooltip>
            <Box ml={3.5} mt={-1.5}>
              <Text variant="caption">{materialDirDescr}</Text>
            </Box>
          </Stack>
          <Stack spacing={0} pl={1}>
            <Checkbox
              id="share-directly-checkbox"
              size="small"
              checked={shareDirectlySelected}
              onChange={() => setShareDirectlySelected(!shareDirectlySelected)}
              label="Share directly with another organization that uses Latis"
            />
            {shareDirectlySelected && (
              <Box ml={3.5}>
                <TextField
                  id="share-directly-textfield"
                  label="Describe how you'd like to share this product"
                  multiline
                  rows={2}
                  value={requestText}
                  onChange={(e) => setRequestText(e.target.value)}
                  fullWidth
                  size="medium"
                />
              </Box>
            )}
          </Stack>
        </Stack>
        <Stack direction="row" mt={2}>
          <Box flex={1} />
          <Tooltip title={tooltip}>
            <Box
              sx={{
                opacity: buttonDisabled ? 0.5 : 1,
                '& .MuiButton-root': { pointerEvents: buttonDisabled ? 'none' : 'auto' },
              }}
            >
              <Button
                color="primary"
                id="request-sharing-button"
                data-testid={`request-sharing-button-${buttonDisabled ? 'disabled' : 'enabled'}`}
                onClick={buttonDisabled ? null : handleShareClicked}
                loading={isShareLoading}
              >
                Submit for sharing
              </Button>
            </Box>
          </Tooltip>
        </Stack>
      </AccordionDetails>
    </Accordion>
  )
}
