import { createSelector } from 'reselect'
import { IProductStandard } from '@/records'
import { AppState } from '@/store'
import { STANDARD_CATEGORY_CERTIFICATION } from '@/api'

const selectProductStandards = (state: AppState): IProductStandard[] => state.productStandards.productStandards

export const selectProductCertificationStandards = createSelector(selectProductStandards, (productStandards) =>
  productStandards.filter((st) => st.category === STANDARD_CATEGORY_CERTIFICATION)
)
