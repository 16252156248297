import React, { FC } from 'react'
import { Field, VIEW_AGG_IMPACT_RETAIL_RATING } from '@/constants/impactScore'
import { RetailRatingImage } from '@/components'
import { Stack } from '@howgood/design'
import { ProductAttribute } from '@/components/ProductImpact/ExportProductOverview/ProductAttribute'

interface HowgoodPoweredClaimsProps {
  permissions: string[]
  showHowgoodRating?: boolean
  withHowgoodRatingDescription?: boolean
  howgoodRatingDescription?: string
  howgoodRatingWidth?: string
  howgoodRating: number | null
  attributes: { field: Field; value: boolean }[]
  withAttributeDescription?: boolean
  attributeWidth?: string
  portfolioPercentage?: Partial<Record<Field, number>>
  hideEmptyAttributes?: boolean
}

export const getVisibleAttributes = (
  attributes: { field: Field; value: boolean }[],
  portfolioPercentage: Partial<Record<Field, number>>,
  hideEmptyAttributes: boolean
) => (hideEmptyAttributes ? attributes.filter((attribute) => portfolioPercentage?.[attribute.field]) : attributes)

export const HowgoodPoweredClaims: FC<HowgoodPoweredClaimsProps> = ({
  attributes,
  showHowgoodRating,
  withHowgoodRatingDescription = true,
  howgoodRatingWidth,
  howgoodRatingDescription,
  withAttributeDescription = true,
  attributeWidth,
  permissions,
  howgoodRating,
  portfolioPercentage,
  hideEmptyAttributes,
}) => {
  const visibleAttributes = getVisibleAttributes(attributes, portfolioPercentage, hideEmptyAttributes)

  return (
    <Stack direction="row" gap={2} sx={{ alignItems: 'baseline' }}>
      {permissions.includes(VIEW_AGG_IMPACT_RETAIL_RATING) && (showHowgoodRating || !!howgoodRating) && (
        <RetailRatingImage
          withDescription={withHowgoodRatingDescription}
          description={howgoodRatingDescription}
          score={howgoodRating}
          variant="regular"
          width={howgoodRatingWidth}
        />
      )}
      {visibleAttributes.map((attribute) => (
        <ProductAttribute
          key={attribute.field}
          attribute={attribute}
          portfolioPercentage={portfolioPercentage?.[attribute.field]}
          withDescription={withAttributeDescription}
          width={attributeWidth}
        />
      ))}
    </Stack>
  )
}
