import { theme } from '@howgood/design'

export function getFill(score: number): string {
  switch (true) {
    case typeof score !== 'number':
      return theme.palette.impact[0]
    case score > 5:
      return theme.palette.impact[1]
    case score > 4:
      return theme.palette.impact[2]
    case score > 3:
      return theme.palette.impact[3]
    case score > 2.25:
      return theme.palette.impact[4]
    case score > 1.5:
      return theme.palette.impact[5]
    case score > 1:
      return theme.palette.impact[6]
    case score > 0.75:
      return theme.palette.impact[7]
    case score > 0.5:
      return theme.palette.impact[8]
    case score > 0.25:
      return theme.palette.impact[9]
    case score <= 0.25:
      return theme.palette.impact[10]
    default:
      return theme.palette.impact[0]
  }
}
