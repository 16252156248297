import React, { forwardRef } from 'react'
import { PDFExport } from '@progress/kendo-react-pdf'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { RichText } from '@/contentfully'
import { useContentfulDocument } from '@/contentfully/hooks'
import { selectDisplayedProduct, selectIsThirdParty } from '@/state/productOverview'
import { useExportStyles } from '@/styles'
import { getDateParts } from '@/utils/dates'
import { selectUserHasAccessToCarbonFootprint } from '@/state/user'
import { selectOrganization } from '@/state/organization'
import { LifecycleChart } from '@/components/Scores/CarbonPanel/LifecycleChart'
import { GhgFootprintImpact } from './GhgFootprintImpact'
import { useProductGhgImpactExportStyles } from './ProductGhgImpactExport.styles'
import { FormulaDetailSection } from './FormulaDetailSection'
import { EmissionsFactorsTable } from './EmissionsFactorsTable'

import logo from '@/assets/images/footer-logo.png'
import latis from '@/assets/images/latis-footer.png'
import { Stack } from '@howgood/design'
import { MATERIAL_TYPE_VENDOR_VERIFIED, MATERIAL_TYPE_VENDOR_VERIFIED_PLUS } from '@/api/types/products'
import verifiedIcon from '@howgood/design/assets/images/materialTypeIcons/verified-small.png'
import verifiedPlusIcon from '@howgood/design/assets/images/materialTypeIcons/verified-plus-small.png'

interface Props {
  pdfContentRef?: React.MutableRefObject<any>
  fromSupplierConnect?: boolean // SupplierConnect users see only limited impact data in the report
}

export const ProductGhgImpactExport = forwardRef<PDFExport, Props>(
  ({ pdfContentRef, fromSupplierConnect = false }, ref) => {
    const exportClasses = useExportStyles()
    const classes = useProductGhgImpactExportStyles()
    const hasCarbonFootprintAccess = useSelector(selectUserHasAccessToCarbonFootprint)
    const content = useContentfulDocument('ghg-impact-report')
    const product = useSelector(selectDisplayedProduct)
    const organization = useSelector(selectOrganization)
    const isThirdPartyProduct = useSelector(selectIsThirdParty)

    const { year, month, day } = getDateParts()
    const currentDate = dayjs().format('M/D/YY')

    const productName = product.name
    const fileName = `${productName}_GHGImpactReport_${month}.${day}.${year}.pdf`

    if (!content) {
      return null
    }

    return (
      <div className={exportClasses.outerContainer}>
        <PDFExport ref={ref} paperSize="A4" margin={40} fileName={fileName} forcePageBreak=".page-break">
          <div className={exportClasses.container} ref={pdfContentRef} id="pdf-container">
            <div className={classes.reportTitleRow}>
              <h1>
                {(organization.organizationName && `${organization.organizationName} `) || ''}
                {content.title}
              </h1>
              <img width="70px" src={logo} />
            </div>
            <h2>Product Name: {productName}</h2>
            {product.brand_name && (
              <Stack direction="row" alignItems="center" gap={0.5}>
                <p>Product Vendor: {product.brand_name}</p>
                {(product.material_types || []).some((type) =>
                  [MATERIAL_TYPE_VENDOR_VERIFIED, MATERIAL_TYPE_VENDOR_VERIFIED_PLUS].includes(type)
                ) ? (
                  <img
                    src={
                      product.material_types.includes(MATERIAL_TYPE_VENDOR_VERIFIED_PLUS)
                        ? verifiedPlusIcon
                        : verifiedIcon
                    }
                    width={18}
                    height={18}
                    style={{ marginBottom: 10 }}
                  />
                ) : null}
              </Stack>
            )}
            <p>Completed by HowGood, Inc on {currentDate}</p>
            <RichText document={content.document} />
            <div className="page-break" />
            <GhgFootprintImpact fromSupplierDialog={fromSupplierConnect} />
            {hasCarbonFootprintAccess && !fromSupplierConnect && (
              <div className={classes.lifecycleChart}>
                <h3>GHG Lifecycle Stages</h3>
                <LifecycleChart size="small" chartOnly />
              </div>
            )}
            {!fromSupplierConnect && <div className="page-break" />}
            {!isThirdPartyProduct && (
              <>
                <FormulaDetailSection />
                <div className="page-break" />
              </>
            )}
            <RichText document={content.sources} />
            <div className="page-break" />
            <EmissionsFactorsTable />
            <RichText document={content.versionHistory} />
            <div className={classes.disclaimer}>
              <RichText document={content.disclaimer} />
            </div>
            <div className={classes.footerLogoWrapper}>
              <img className={classes.footerLatisLogo} width="150px" src={latis} />
              <img width="70px" src={logo} />
            </div>
          </div>
        </PDFExport>
      </div>
    )
  }
)

ProductGhgImpactExport.displayName = 'ProductGhgImpactExport'
