import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, Stack, Text } from '@howgood/design'
import { initializeProductsPage } from '@/state/products'
import { Products } from '@/components'
import { Scores } from '@/components/Scores/Scores'
import { WorkspaceProductFilter } from '@/components/ProductsFilters/WorkspaceProductFilter'
import { FormulaNameFilter } from '@/components/ProductsFilters/FormulaNameFilter'
import { TagsFilter } from '@/components/ProductsFilters/TagsFilter'
import { IngredientFilter } from '@/components/ProductsFilters/IngredientFilter'
import { useScoreCardDataReady } from '@/components/Scores/ScoreCards'
import { ProductListPageHeader } from '@/components/MyFormulations/ProductListPageHeader'
import { MATERIALS } from '@/records'
import { VendorFilter } from '@/components/ProductsFilters/VendorFilter'
import { AnalysisPanel } from './AnalysisPanel/AnalysisPanel'
import { FormulationStatusProductFilter } from '@/components/ProductsFilters/FormulationStatusProductFilter'
import { StandardsFilter } from '@/components/ProductsFilters/StandardsFilter'
import { MaterialDestinationFilter } from '@/components/ProductsFilters/MaterialDestinationFilter'
import { useLiteUserPaywallDialogForPages } from '@/components/LiteUserPaywallDialog/LiteUserPaywallDialog.hook'
import { DataGranularityProductFilter } from '@/components/ProductsFilters/DataGranularityProductFilter'
import { selectHasWorkspaceOfType } from '@/state/workspaces'

export const ProcurementPage: FC = () => {
  const dispatch = useDispatch()
  useLiteUserPaywallDialogForPages()
  const isScoreDataLoaded = useScoreCardDataReady()
  const hasProcurementWorkspaces = useSelector(selectHasWorkspaceOfType(MATERIALS))
  const [isInitialized, setIsInitialized] = useState(false)

  useEffect(() => {
    const initializeData = async () => {
      await dispatch(initializeProductsPage({ workspaceTypes: [MATERIALS] }))
    }
    initializeData()
    setIsInitialized(true)
  }, [dispatch])

  return (
    <Stack>
      <ProductListPageHeader />
      <Stack data-testid="product-list-with-filters">
        {!hasProcurementWorkspaces ? (
          <Card fullHeight outlineDashed>
            <Stack py={6} direction="row" justifyContent="center">
              <Text>No material workspaces to show. Contact support@howgood.com for setup help.</Text>
            </Stack>
          </Card>
        ) : (
          <>
            <Stack flexDirection="row" alignItems="center" justifyContent="stretch" gap={1} mb={2}>
              <WorkspaceProductFilter />
              <FormulaNameFilter searchType="material" />
              <VendorFilter />
              <IngredientFilter />
              <FormulationStatusProductFilter />
              <TagsFilter />
              <StandardsFilter />
              <MaterialDestinationFilter />
              <DataGranularityProductFilter />
            </Stack>
            {isScoreDataLoaded && <Scores />}
            {isInitialized && <AnalysisPanel />}
            <Products />
          </>
        )}
      </Stack>
    </Stack>
  )
}
