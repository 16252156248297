import React, { FC } from 'react'
import { formatNumber } from '@howgood/utils'
import { Tooltip, Text, Stack } from '@howgood/design'

export const RoundedValueRenderer: FC<{ value: number; dataTestId?: string }> = ({ value, dataTestId }) => {
  if (!value) {
    return null
  }

  const formattedValue = formatNumber(value) || '--'
  const localeScore = value != null ? value.toLocaleString('en-US') || '' : ''

  return (
    <Stack data-testid={dataTestId} direction="row" height="100%" alignItems="center" justifyContent="center">
      <Tooltip title={localeScore}>
        <Text variant="body2">{formattedValue}</Text>
      </Tooltip>
    </Stack>
  )
}
