import React, { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntercom } from 'react-use-intercom'
import { Button, Checkbox, Dialog, DialogContent, DialogTitle, Stack } from '@howgood/design'
import {
  selectIgnoreUserSettings,
  selectUser,
  selectUserProductsImpactPermissions,
  setIgnoreUserSettings,
} from '@/state/user'
import { selectWorkspaces } from '@/state/workspaces'
import { PERMISSIONS_TO_FIELDS_MAP } from '@/constants/impactScore'
import { ImpactMetric } from './ImpactMetric'

import './Profile.scss'
import { selectSplitTreatmentEnabled } from '@/state/splitio'
import { addTotp, deleteTotp } from '@/api'
import { addMessage } from '@/state/messages'

// While the dashboard is under development, allow users to ignore their saved settings
// in case there's a problem reading them.
const IgnoreUserSettings: FC = () => {
  const dispatch = useDispatch()
  const ignoreUserSettings = useSelector(selectIgnoreUserSettings)
  const dashboardEnabled = useSelector(selectSplitTreatmentEnabled('dashboard-home'))

  if (!dashboardEnabled) return null

  return (
    <>
      <Checkbox
        checked={ignoreUserSettings}
        label="Ignore saved user settings"
        onChange={(e) => dispatch(setIgnoreUserSettings(e.currentTarget.checked))}
      />
      <hr />
    </>
  )
}

interface ProfileProps {
  onClose: () => void
}

export const Profile: FC<ProfileProps> = ({ onClose }) => {
  const dispatch = useDispatch()
  const { show } = useIntercom()
  const workspaces = useSelector(selectWorkspaces)
  const user = useSelector(selectUser)
  const impactPermissions = useSelector(selectUserProductsImpactPermissions)
  const [qrCode, setQrCode] = useState<string | null>(null)

  const renderField = (label: string, value: string) => {
    return (
      <div className="Profile__dialog__field">
        <div className="Profile__dialog__field__label title__text">{`${label}:`}</div>
        <div className="Profile__dialog__field__text">{value}</div>
      </div>
    )
  }

  const renderImpactMetricsSection = () => {
    const leftPartPermissions = impactPermissions.slice(0, Math.ceil(impactPermissions.length / 2))
    const rightPartPermissions = impactPermissions.slice(Math.ceil(impactPermissions.length / 2))
    return (
      <div className="Profile__dialog__section">
        <div className="Profile__dialog__field__label title__text">Your Impact Metrics:</div>
        <div className="Profile__dialog__section__body Profile__dialog__impact__metrics__section__body">
          <div className="Profile__dialog__flex1">
            {leftPartPermissions.map((permission) => (
              <ImpactMetric key={permission} field={PERMISSIONS_TO_FIELDS_MAP[permission]} />
            ))}
          </div>
          <div className="Profile__dialog__flex1">
            {rightPartPermissions.map((permission) => (
              <ImpactMetric key={permission} field={PERMISSIONS_TO_FIELDS_MAP[permission]} />
            ))}
          </div>
        </div>
      </div>
    )
  }

  const openMessanger = () => {
    show()
  }

  const renderSupportTeamSection = () => {
    return (
      <div className="Profile__dialog__section">
        <div className="Profile__dialog__field__label title__text">Your HowGood Support Team:</div>
        <div className="Profile__dialog__section__body Profile__dialog__support__team__body">
          <div className="Profile__dialog__flex1" />
          <div className="Profile__dialog__person">
            <div className="Profile__dialog__person__image Profile__dialog__person1__image" />
            <div className="Profile__dialog__person__name">Julia Buckley</div>
          </div>
          <div className="Profile__dialog__person">
            <div className="Profile__dialog__person__image Profile__dialog__person2__image" />
            <div className="Profile__dialog__person__name">Kelsey Halling</div>
          </div>
          <div className="Profile__dialog__person">
            <div className="Profile__dialog__person__image Profile__dialog__person3__image" />
            <div className="Profile__dialog__person__name">Shannon Kenny</div>
          </div>
          <div className="Profile__dialog__person">
            <div className="Profile__dialog__person__image Profile__dialog__person4__image" />
            <div className="Profile__dialog__person__name">Victoria Peltonen</div>
          </div>
          <div className="Profile__dialog__flex1" />
        </div>
      </div>
    )
  }

  const renderActions = () => {
    return (
      <div className="Profile__dialog__actions">
        <button className="button" onClick={openMessanger}>
          Ask a question
        </button>
      </div>
    )
  }

  const renderDialog = () => {
    return (
      <DialogContent>
        <div>
          <div>
            {Boolean(user.first_name || user.last_name) &&
              renderField('Full name', `${user.first_name || ''} ${user.last_name || ''}`)}
            {renderField('Workspaces', workspaces.map((workspace) => workspace.name).join('; '))}
            {renderField('Username/Email', user.email)}
            <Stack direction="row" gap={2} alignItems="center">
              <Stack gap={1} width="150px">
                <a href="https://ssl.howgood.com/accounts/reset-password/" target="_blank" rel="noopener noreferrer">
                  Change password
                </a>
                <Button
                  size="small"
                  onClick={async () => {
                    try {
                      const base64 = await addTotp()
                      setQrCode(`data:image/png;base64,${base64}`)
                    } catch (e) {
                      console.error(e)
                      dispatch(
                        addMessage({
                          severity: 'error',
                          message:
                            'Unable to retrieve QR code, please contact support@howgood.com for additional assistance.',
                        })
                      )
                    }
                  }}
                >
                  Set up MFA app
                </Button>
                <Button
                  size="small"
                  onClick={async () => {
                    try {
                      await deleteTotp()
                    } catch (e) {
                      dispatch(
                        addMessage({
                          severity: 'error',
                          message:
                            'Unable to remove MFA app, please contact support@howgood.com for additional assistance.',
                        })
                      )
                    }
                  }}
                >
                  Remove MFA app
                </Button>
              </Stack>
              {!!qrCode && <img width="100" height="100" src={qrCode} />}
            </Stack>
            <hr />
            <IgnoreUserSettings />
            {renderImpactMetricsSection()}
            <hr />
            {renderSupportTeamSection()}
            {renderActions()}
          </div>
        </div>
      </DialogContent>
    )
  }

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>Profile</DialogTitle>
      {renderDialog()}
    </Dialog>
  )
}
