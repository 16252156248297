import React, { FC, useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { DataGridPremium, GridRowsProp, GridColDef } from '@mui/x-data-grid-premium'
import { Accordion, AccordionDetails } from '@howgood/design'

import { NUTRITION_FIELDS } from '@/constants/impactScore'
import { useSortedContentfulMetrics } from '@/contentfully/hooks'
import { selectDisplayedIngredientList } from '@/state/recipe'
import { selectProductNutrition } from '@/state/nutrition'
import { IngredientName } from './IngredientName'
import { AccordionCardHeader } from '../AccordionCardHeader'
import { selectIsRollup } from '@/state/router'

export const Nutrition: FC<{ isFavorites?: boolean }> = ({ isFavorites }) => {
  const ingredients = useSelector(selectDisplayedIngredientList)
  const nutrition = useSelector(selectProductNutrition)
  const contentfulData = useSortedContentfulMetrics(NUTRITION_FIELDS)
  const isRollup = useSelector(selectIsRollup)

  const nutritionData = nutrition.product_ingredients

  // Keeps track of which nested ingredients are expanded to display their children
  const [expanded, setExpanded] = useState<string[]>([])

  const availableColumns = useMemo(() => {
    if (!contentfulData) return []
    return contentfulData.map((entry) => ({
      key: entry.key,
      title: entry.title,
    }))
  }, [contentfulData])

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'ingredient',
        headerName: 'Ingredient',
        width: 350,
        renderCell: IngredientName,
      },
      ...availableColumns.map((column) => ({
        field: column.key,
        headerName: column.title,
        flex: 1,
      })),
    ],
    [availableColumns]
  )

  const rows: GridRowsProp = useMemo(() => {
    return ingredients.reduce((transformedRows, ingredient, i) => {
      const skip = !(
        ingredient.isTopLevel ||
        (ingredient.flat_position.length > 1 && expanded.includes(ingredient.flat_position.slice(0, -1).join('.')))
      )
      if (skip) {
        return transformedRows
      }
      const columnValues = availableColumns.reduce((acc, impact) => {
        const score = nutritionData[i]?.actual_nutrition?.[impact.key]
        return { ...acc, [impact.key]: score }
      }, {})

      return [
        ...transformedRows,
        {
          id: ingredient.id,
          isNested: 'nested_product_id' in ingredient,
          ingredient: { ingredient, expanded, setExpanded },
          ...columnValues,
        },
      ]
    }, [])
  }, [ingredients, availableColumns, expanded, nutritionData])

  if (rows.length === 0 || isRollup) {
    return null
  }

  return (
    <Accordion id="nutrition-panel" defaultExpanded>
      <AccordionCardHeader
        cardKey="nutrition"
        title="Nutrition"
        id="nuntrition-panel-header"
        favoritesTab={isFavorites}
      />
      <AccordionDetails>
        <DataGridPremium
          rows={rows}
          columns={columns}
          autoHeight
          disableRowSelectionOnClick
          initialState={{ density: 'compact' }}
          disableColumnMenu
          sx={{
            '& .MuiDataGrid-cell:focus': { outline: 'none' },
            '& .MuiDataGrid-cell:focus-within': { outline: 'none' },
          }}
        />
      </AccordionDetails>
    </Accordion>
  )
}
