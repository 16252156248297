import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Stack, Text, Card, Select, MenuItem, FormControl, InputLabel } from '@howgood/design'
import {
  availableVendorMetrics,
  selectVendorData,
  selectVendorManagementSettings,
  setSelectedMetric,
} from '@/state/vendorManagement'
import { updateVendorManagementSettings } from '@/state/user'
import { GridBarChart } from './GridBarChart'
import { cardStyles } from './Charts'

export const HighestImpactByVendor: FC = () => {
  const vendorData = useSelector(selectVendorData)
  const vendorManagementSettings = useSelector(selectVendorManagementSettings)
  const dispatch = useDispatch()

  const selectedMetric = vendorManagementSettings.selectedMetric

  return (
    <Card fullHeight sx={cardStyles}>
      <Stack gap={2} minHeight={265}>
        <Text variant={'h4'}>Highest Impact Vendors</Text>
        <FormControl fullWidth>
          <InputLabel id="select-label">Metric</InputLabel>
          <Select
            size="small"
            label="Metric"
            value={selectedMetric}
            onChange={(e) => {
              dispatch(setSelectedMetric(e.target.value as string))
              dispatch(updateVendorManagementSettings())
            }}
            sx={{ fontSize: 14 }}
          >
            {Object.keys(availableVendorMetrics).map((metric) => (
              <MenuItem key={metric} value={metric}>
                {availableVendorMetrics[metric]?.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {!!vendorData.length && <GridBarChart />}
      </Stack>
    </Card>
  )
}
