import { ISessionData } from '@/records'
import { AuthPayload } from '@/state/user'

const DEFAULT_SESSION_DATA = {
  username: '',
  access_token: '',
  refresh_token: '',
  expires_in: null,
  expires: null,
}

export function removeSessionData() {
  localStorage.removeItem('auth')
}

interface SessionDataPayload extends AuthPayload {
  username: string
  expires: Date
}
export function storeSessionData(auth: SessionDataPayload) {
  localStorage.setItem('auth', JSON.stringify(auth))
}

function getAuth(): string {
  return localStorage.getItem('auth')
}

export function getSessionData(): ISessionData {
  const auth = getAuth()
  if (auth) {
    return JSON.parse(auth)
  }
  return DEFAULT_SESSION_DATA
}
