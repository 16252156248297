import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormulationStatusOption } from '@/records'
import { selectVendorFormulationStatuses, updateFiltersUrlAndVendorData } from '@/state/vendorManagement'
import { FormulationStatusDropdownFilter } from '@/components/Shared/Filters/FormulationStatusDropdownFilter'

export const VendorFormulationStatusFilter: FC = () => {
  const dispatch = useDispatch()
  const formulationStatusFilters = useSelector(selectVendorFormulationStatuses)

  const handleChange = (newStatuses: FormulationStatusOption[]) => {
    dispatch(updateFiltersUrlAndVendorData({ updatedFilters: { formulationStatuses: newStatuses } }))
  }

  return <FormulationStatusDropdownFilter handleChange={handleChange} filteredStatuses={formulationStatusFilters} />
}
