import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { ExternalLink, Icon, Stack, Text } from '@howgood/design'
import { useGetFormulaIconBadge } from '@/hooks/useFormulaIconBadge'
import { selectDisplayedProduct } from '@/state/productOverview'
import { VendorInfoDialog } from './VendorInfoDialog/VendorInfoDialog'
import { FORMULA_ICON_BADGE } from '@/contentfully'
import { selectMaterialIconAgpracticeSplit } from '@/state/splitio'
import { faStore } from '@fortawesome/pro-solid-svg-icons'

export const Vendor: FC = () => {
  const getFormulaIconBadge = useGetFormulaIconBadge()
  const [infoDialogOpen, setInfoDialogOpen] = useState(false)
  const product = useSelector(selectDisplayedProduct)
  const materialIconAgpracticeEnabled = useSelector(selectMaterialIconAgpracticeSplit)

  const badge = getFormulaIconBadge({
    workspaces: product.workspaces?.map((w) => w.id),
    material_types: product.material_types,
    isProduct: true,
  })

  const { name: vendor, email, phone, website } = product?.brand || {}

  const hasContactInformation = Boolean(email || phone || website)

  const hasInfoDialog = materialIconAgpracticeEnabled
    ? [
        FORMULA_ICON_BADGE.howgoodSupplier,
        FORMULA_ICON_BADGE.vendorVerified,
        FORMULA_ICON_BADGE.vendorVerifiedPlus,
        FORMULA_ICON_BADGE.purchasedMaterial,
      ].includes(badge)
    : hasContactInformation || badge === FORMULA_ICON_BADGE.purchasedMaterial

  const handleClick = () => {
    if (hasInfoDialog) {
      setInfoDialogOpen(true)
    }
  }

  const handleInfoDialogClose = () => {
    setInfoDialogOpen(false)
  }

  if (!vendor) {
    return null
  }

  const vendorDisplay = (
    <Stack direction="row" alignItems="center" gap={0.5} color="text.disabled">
      <Icon fontSize={14} data-testid="header-icon" icon={faStore} />
      <Text fontSize={14} noWrap data-testid="vendor-name" color="text.secondary">
        {vendor}
      </Text>
    </Stack>
  )

  return (
    <>
      {hasInfoDialog ? (
        <ExternalLink data-testid="vendor-name" onClick={handleClick} color="text.secondary" underline="hover">
          {vendorDisplay}
        </ExternalLink>
      ) : (
        vendorDisplay
      )}
      {infoDialogOpen && (
        <VendorInfoDialog
          brand={product.brand}
          onClose={handleInfoDialogClose}
          badge={badge}
          productId={product.id || product.pk}
        />
      )}
    </>
  )
}
