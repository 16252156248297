import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
  menuItem: {
    padding: '3px 0 3px 10px',
    display: 'flex',
    cursor: 'pointer',
    fontWeight: 700,
    '&:hover': {
      backgroundColor: '#343F50',
    },
  },
  active: {
    borderLeft: '6px solid #1D9862',
    backgroundColor: '#343F50',
    boxSizing: 'border-box',
    paddingLeft: '4px',
  },
  disabled: {
    opacity: 0.5,
    cursor: 'not-allowed',
    '& *': {
      cursor: 'not-allowed !important',
    },
  },
  menuItemIcon: {
    backgroundRepeat: 'no-repeat',
    marginRight: '10px',
    cursor: 'pointer',
    width: '20px',
    height: '20px',
    backgroundSize: '20px',
    userSelect: 'none', // Suppress the right-click highlighting
  },
  aLink: {
    display: 'flex',
  },
  sidebar: {
    width: '220px',
    background: '#3b4959',
    color: '#fff',
    overflow: 'auto',
    minWidth: '220px',
    fontSize: '14px',

    '& a': { color: '#fff !important', textDecoration: 'none !important' },
  },
  header: { backgroundColor: '#333f50', padding: '20px', fontWeight: 600 },
  spacer: { marginBottom: 10 },
  latis: {
    cursor: 'pointer',
  },
  section: {
    borderTop: '1px solid #fff',
    padding: '20px 0',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
  },
})
