import { AppState } from '@/store'
import { createSelector } from 'reselect'
import { OrgUser, Report, ReportType } from './organization.slice'
import { Organization } from '@/records'
import { selectIsProcurement } from '../router'

export const selectOrganization = (state: AppState): Organization => state.organization.settings

export const selectUsers = (state: AppState): OrgUser[] => state.organization.users

export const selectOrgLoading = (state: AppState): boolean => state.organization.isLoading

export const selectUsersSortedByEmail = createSelector(selectUsers, (users) =>
  [...users].sort((a, b) => a.email.localeCompare(b.email))
)

export const selectReports = (state: AppState): Report[] => state.organization.reports

export const selectReportsByType = createSelector(selectReports, selectIsProcurement, (reports, isProcurement) => {
  const reportTypes: ReportType[] = isProcurement ? ['Procurement', 'ProcurementSnapshot'] : ['Formulation']
  return reports.filter((report) => reportTypes.includes(report.report_type))
})

export const selectIsSupplierConnect = createSelector(selectOrganization, (org) => org.tier === 'SupplierConnect')
