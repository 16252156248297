import { createSelector } from 'reselect'
import { AppState } from '@/store'
import { State } from './liteUserPaywallDialog.state'

export const selectLiteUserPaywallDialog = (state: AppState): State => state.liteUserPaywallDialog

export const selectIsLiteUserPaywallDialogOpen = createSelector(selectLiteUserPaywallDialog, (st) => st.open)

export const selectLiteUserPaywallDialogRedirectPath = createSelector(
  selectLiteUserPaywallDialog,
  (st) => st.redirectPath
)
