import { createSelector } from 'reselect'
import { ISplitState } from '@splitsoftware/splitio-redux/types/types'
import SplitIO from '@splitsoftware/splitio/types/splitio'
import { selectOrganization } from '@/state/organization'
import { IUser, Organization } from '@/records'
import { selectUser, selectUserId } from '@/state/user/user.selectors'
import { AppState } from '@/store'

const selectSplitIO = (state: AppState): ISplitState => state.splitio

export const selectSplitIsReady = createSelector(selectSplitIO, (split) => split.isReady)

export const selectSplitIsTimedOut = createSelector(selectSplitIO, (split) => split.isTimedout)

const selectSplitTreatments = createSelector(selectSplitIO, (split) => split.treatments)

export const selectSplitTreatment = (splitName: string) =>
  createSelector(
    selectSplitTreatments,
    selectUserId,
    (treatments, userId) => treatments?.[splitName]?.[userId]?.treatment
  )

export const selectSplitTreatmentEnabled = (splitName: string) =>
  createSelector(selectSplitTreatment(splitName), (treatment) => treatment === 'on')

export const selectExportPaywallSplit = createSelector(
  selectSplitTreatmentEnabled('export_paywall'),
  (enabled) => enabled
)

export const selectLcaOverrideSplit = createSelector(selectSplitTreatmentEnabled('LCA_OVERRIDE'), (enabled) => enabled)

export const COPY = 'copy'
export const COPY_W_HEADERS = 'copyWithHeaders'
export const COPY_W_GRP_HEADERS = 'copyWithGroupHeaders'
export const EXPORT = 'export'
export const selectContextMenuItems = createSelector(selectExportPaywallSplit, (enabled) =>
  enabled ? [COPY, COPY_W_HEADERS, COPY_W_GRP_HEADERS] : [COPY, COPY_W_HEADERS, COPY_W_GRP_HEADERS, EXPORT]
)

export const selectFormulaNotesSplit = createSelector(
  selectSplitTreatmentEnabled('formula_notes'),
  (enabled) => enabled
)

export const selectImpactReductionSplit = createSelector(
  selectSplitTreatmentEnabled('impact_reduction'),
  (enabled) => enabled
)

export const selectImpactReductionPracticesSplit = createSelector(
  selectSplitTreatmentEnabled('impactreduction_practices'),
  (enabled) => enabled
)

export const selectMaterialIconAgpracticeSplit = createSelector(
  selectSplitTreatmentEnabled('material_icon_agpractice'),
  (enabled) => enabled
)

export const selectScorecardCarbonFootprintSplit = createSelector(
  selectSplitTreatmentEnabled('Scorecard_Carbon_Footprint'),
  (enabled) => enabled
)

export const selectHowgoodMaterialDirectorySplit = createSelector(
  selectSplitTreatmentEnabled('howgood_material_directory'),
  (enabled) => enabled
)

export const selectInternalMaterialSplit = createSelector(
  selectSplitTreatmentEnabled('internal_material'),
  (enabled) => enabled
)

export const selectInputWeightUnitsSplit = createSelector(
  selectSplitTreatmentEnabled('input-weight-units'),
  (enabled) => enabled
)

export const selectGoalSettingSplit = createSelector(selectSplitTreatmentEnabled('goal-setting'), (enabled) => enabled)

export const selectDebugToolsSplit = createSelector(selectSplitTreatmentEnabled('debug-tools'), (enabled) => enabled)

export const selectFlagGoalsSplit = createSelector(selectSplitTreatmentEnabled('flag-goals'), (enabled) => enabled)

export const selectSendMessageSplit = createSelector(selectSplitTreatmentEnabled('send_message'), (enabled) => enabled)

export const selectUploadEmissionsSplit = createSelector(
  selectSplitTreatmentEnabled('upload_emissions'),
  (enabled) => enabled
)

export const selectAbatementSplit = createSelector(selectSplitTreatmentEnabled('abatement'), (enabled) => enabled)

export const selectCarbonTrustPcfSplit = createSelector(
  selectSplitTreatmentEnabled('carbon-trust-pcf'),
  (enabled) => enabled
)

interface SplitUser extends SplitIO.Attributes {
  id: number
  email: string
  groups: string[]
  productPermissions: string[]
  ingredientPermissions: string[]
}
type SplitAttributes = SplitUser & Organization

export const selectSplitAttributes = createSelector<AppState, IUser, Organization, SplitAttributes>(
  [selectUser, selectOrganization],
  (user, organization) => ({
    id: user.id,
    email: user.email,
    groups: user.groups?.map((group) => group.name),
    productPermissions: user.permissions?.products,
    ingredientPermissions: user.permissions?.ingredients,
    ...organization,
  })
)
