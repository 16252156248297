import React, { FC } from 'react'

import { PanelGrid } from '../PanelGrid'
import { ScoreCards } from '../ScoreCards/ScoreCards'
import { useScoreCardData } from '../ScoreCards/useScoreCardData'
import { OverviewBars } from './OverviewBars/OverviewBars'
import { ClaimsCard } from '../ClaimsCard'
import { useSelector } from 'react-redux'
import { selectHasImpactsOverriddenNestedProduct, selectIsImpactsOverridden } from '@/state/productOverview'
import { FormulaNotes } from '@/features/ProductPage/panels/FormulaNotes/FormulaNotes'
import { ImpactReduction } from '@/features/ProductPage/panels/ImpactReduction/ImpactReduction'
import { ImpactHistory } from '@/features/ProductPage/panels/ImpactHistory'
import { VendorNotes } from './VendorNotes'
import { InitiativeChartPanel } from './InitiativeChartPanel'

export const OverviewPanel: FC = () => {
  const scoreCardData = useScoreCardData()
  const impactsOverriddenProduct = useSelector(selectIsImpactsOverridden)
  const hasImpactsOverriddenNestedProduct = useSelector(selectHasImpactsOverriddenNestedProduct)

  return (
    <PanelGrid>
      <ScoreCards cards={scoreCardData.overview.data} />
      {!impactsOverriddenProduct && !hasImpactsOverriddenNestedProduct && <ClaimsCard />}
      <ImpactHistory />
      {!hasImpactsOverriddenNestedProduct && <OverviewBars />}
      <ImpactReduction />
      <FormulaNotes />
      <VendorNotes />
      <InitiativeChartPanel />
    </PanelGrid>
  )
}
