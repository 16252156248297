import React, { FC, Fragment } from 'react'
import { useSelector } from 'react-redux'
import { MenuItem, Stack, Tooltip, Text } from '@howgood/design'

import {
  selectCurrentScenario,
  selectInitiatives,
  selectSortedScenarios,
  selectSourceProductVersionData,
} from '@/state/initiatives'
import { useNavigateToFormula } from '@/hooks'
import { selectEditableWorkspaceIds } from '@/state/workspaces'
import { getScenarioYear } from '@/state/initiatives/initiatives.utils'

interface ProductVersion {
  id: number
  scenarioId: number
  scenarioName: string
  scenarioTargetYear: string
  initiativeName: string
  edited?: boolean
}

export const ScenarioProductVersions: FC = () => {
  const allScenarios = useSelector(selectSortedScenarios)
  const allInitiatives = useSelector(selectInitiatives)
  const currentScenario = useSelector(selectCurrentScenario)
  const editableWorkspaceIds = useSelector(selectEditableWorkspaceIds)
  const versionData = useSelector(selectSourceProductVersionData)
  const navigateToFormula = useNavigateToFormula()

  if (!versionData || !currentScenario) return null

  const clones = versionData.clones.reduce((acc, hit) => {
    const scenario = allScenarios.find((s) => s.id === hit.scenarios[0].id)
    const initiative = allInitiatives.find((i) => i.id === scenario?.initiative?.id)

    if (!scenario || !initiative) return acc

    const version: ProductVersion = {
      id: hit.pk,
      scenarioId: scenario.id,
      scenarioName: scenario.name,
      scenarioTargetYear: getScenarioYear(scenario.target_date),
      initiativeName: initiative.name,
      edited: true,
    }

    // If the version is not in an editable workspace, don't show include it
    const workspaceIds = hit.workspaces.map((workspace) => workspace.id)
    if (!workspaceIds.some((id) => editableWorkspaceIds.includes(id))) return acc

    return {
      ...acc,
      [version.initiativeName]: version.initiativeName in acc ? [...acc[version.initiativeName], version] : [version],
    }
  }, {} as Record<string, ProductVersion[]>)

  // Get the ids of scenarios that already have clones, so we can create versions for those that don't
  const scenariosWithClones = Object.values(clones)
    .map((versions) => versions.map((version) => version.scenarioId))
    .flat()

  const allVersions = versionData.scenarios.reduce((acc, scenarioId) => {
    if (scenariosWithClones.includes(scenarioId)) return acc

    const scenario = allScenarios.find((s) => s.id === scenarioId)

    // It's possible the scenario no longer exists
    if (!scenario) return acc

    const version: ProductVersion = {
      id: versionData.sourceProductId,
      scenarioId: scenarioId,
      scenarioName: scenario.name,
      scenarioTargetYear: getScenarioYear(scenario.target_date),
      initiativeName: allInitiatives.find((i) => i.id === scenario.initiative.id)?.name || '',
    }

    return {
      ...acc,
      [version.initiativeName]: version.initiativeName in acc ? [...acc[version.initiativeName], version] : [version],
    }
  }, clones)

  const handleItemClick = (scenarioId: number, productId: number) => {
    const searchParams = scenarioId ? `scenario=${scenarioId}` : ''
    navigateToFormula(productId.toString(), false, searchParams)
  }

  return (
    <Stack mr={1} sx={{ '& .MuiMenuItem-root.Mui-disabled': { opacity: 1 } }}>
      {Object.entries(allVersions).map(([initiativeName, versions]) => {
        return (
          <Fragment key={initiativeName}>
            <MenuItem disabled>
              <Text variant="body2" color="text.secondary">
                {initiativeName}
              </Text>
            </MenuItem>
            {versions.map((version, index) => {
              return (
                <MenuItem
                  sx={{ ml: 1, color: version.scenarioId === currentScenario.id ? 'primary.main' : null }}
                  key={`menu-item-${index}`}
                  selected={version.scenarioId === currentScenario.id}
                  onClick={() => handleItemClick(version.scenarioId, version.id)}
                >
                  <Tooltip key={`tooltip-${index}`} title={`products/${version.id}/?scenario=${version.scenarioId}`}>
                    <Stack key={`stack-${index}`} direction="row" gap={1} alignItems="center" width="100%">
                      {version.scenarioTargetYear}: {version.scenarioName}
                      {version.edited && (
                        <Text variant="body2" width="100%" textAlign="right" color="secondary.light">
                          Edited
                        </Text>
                      )}
                    </Stack>
                  </Tooltip>
                </MenuItem>
              )
            })}
          </Fragment>
        )
      })}
    </Stack>
  )
}
