import React, { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { DataGridPremium } from '@mui/x-data-grid-premium'
import { selectVendorData, selectVendorDataIsLoading, selectVendorManagementSettings } from '@/state/vendorManagement'
import { useGridColumns } from './GridColumns'
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium'

interface VendorGridProps {
  gridApiRef: React.MutableRefObject<GridApiPremium>
}

export const VendorGrid: FC<VendorGridProps> = ({ gridApiRef }) => {
  const vendorData = useSelector(selectVendorData)
  const isLoadingVendorManagementData = useSelector(selectVendorDataIsLoading)
  const vendorManagementSettings = useSelector(selectVendorManagementSettings)
  const gridColumns = useGridColumns()

  const { selectedMetric, columnVisibilityModel } = vendorManagementSettings

  // If the selected metric is visible, sort by it; otherwise sort by name
  useEffect(() => {
    const isColumnVisible = columnVisibilityModel[selectedMetric] !== false
    gridApiRef.current?.setSortModel([
      {
        field: isColumnVisible ? selectedMetric : 'name',
        sort: isColumnVisible ? 'desc' : 'asc',
      },
    ])
  }, [columnVisibilityModel, gridApiRef, selectedMetric])

  // Don't load the grid until the data is ready or the initial sort column will be the default, not the selected metric
  if (isLoadingVendorManagementData) {
    return null
  }

  return (
    <DataGridPremium
      apiRef={gridApiRef}
      rows={vendorData}
      columns={gridColumns}
      getRowId={(row) => row.brandId}
      columnVisibilityModel={columnVisibilityModel}
      density="compact"
      hideFooter
      disableRowSelectionOnClick
      disableColumnMenu
      sx={{
        '& .MuiDataGrid-columnHeader': { '&:focus-within': { outline: 'none' } },
        '& .MuiDataGrid-cell:focus-within': { outline: 'none' },
      }}
    />
  )
}
