import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Box, Tooltip, Text } from '@howgood/design'
import { selectCompareTo } from '@/state/pageSettings'
import { ScoreInfo } from '../../../util/getScore'
import { selectIngredientsMoved } from '@/state/recipe'
import { selectGridSettings } from '@/state/preferences'

interface ChangeIndicatorParams {
  score: ScoreInfo
}

export const ChangeIndicator: FC<ChangeIndicatorParams> = ({ score }) => {
  const compareTo = useSelector(selectCompareTo)
  const ingredientsMoved = useSelector(selectIngredientsMoved)
  const gridSettings = useSelector(selectGridSettings)

  const change =
    gridSettings.scoringMethod === 'perKilogram' ? score.changeSinceSaved : score.contributionChangeSinceSaved

  if (change === null || change === 0 || ingredientsMoved) {
    return null
  }

  return (
    <Tooltip title={`1-10 score changed by ${change} since ${compareTo === 'saved' ? 'saved' : 'last change'}`}>
      <Box
        display="flex"
        alignItems="center"
        borderRadius="8px"
        width={16}
        height={16}
        position="absolute"
        bottom={3}
        right={1}
        bgcolor="white"
        sx={{
          opacity: 0.7,
          '&:hover': { bgcolor: 'white' },
        }}
      >
        <Text fontSize={10} color={change < 0 ? 'error.dark' : 'success.dark'}>
          {change > 0 ? `+${change}` : change}
        </Text>
      </Box>
    </Tooltip>
  )
}
