import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  selectEditingFilter,
  selectFormulaQuery,
  setEditingFilter,
  updateFiltersUrlAndProducts,
} from '@/state/products'
import { selectIsProductFiltersLoading } from '@/selectors/selectIsProductFiltersLoading'
import { NameQueriesFilter } from '../Shared/Filters/NameQueriesFilter'

export const FormulaNameFilter: FC<{ searchType?: string }> = ({ searchType = 'formula' }) => {
  const dispatch = useDispatch()
  const isLoading = useSelector(selectIsProductFiltersLoading)
  const queries = useSelector(selectFormulaQuery)
  const editingFilter = useSelector(selectEditingFilter)
  const id = 'formulation-queries'

  const handleQueriesChange = (queryStrings: string[]) => {
    dispatch(updateFiltersUrlAndProducts({ updatedFilters: { query: queryStrings } }))
    dispatch(setEditingFilter(id))
  }

  return (
    <NameQueriesFilter
      value={queries}
      handleChange={handleQueriesChange}
      searchType={searchType}
      id={id}
      autoFocus={editingFilter === id}
      disabled={isLoading}
      fullWidth
      dataPendoId="SearchFormulations"
      ariaLabel="SearchFormulationsQuery"
    />
  )
}
