import React, { FC } from 'react'
import { formatNumber } from '@howgood/utils'
import { Tooltip, Text, Stack } from '@howgood/design'

import { EcoScoreImage, RetailRatingImage, NutriScoreImage } from '@/components'
import { isProjectedNutrition, nutritionScoreToLetter } from '@/utils/nutritions'
import { ProductGoalRenderer } from './ProductGoalRenderer'
import { ProductListRenderCellParams } from './ProductGridTypes'
import { useContentfulMetric } from '@/contentfully/hooks'

interface Tier2RendererParams extends ProductListRenderCellParams {
  value: number
}

export const Tier2Renderer: FC<Tier2RendererParams> = ({ value, colDef, row, id }) => {
  const { field } = colDef
  const metric = useContentfulMetric(field)
  const units = metric ? metric?.units : ''

  switch (field) {
    case 'eco_score_impact':
      return (
        <Stack height="100%" width="100%" alignItems="center" justifyContent="center">
          <EcoScoreImage score={value} variant="small" />
        </Stack>
      )
    case 'retail_rating_impact':
      return (
        <Stack data-testid={`retail-rating-cell-${id}`} height="100%" width="100%" alignItems="center">
          <RetailRatingImage score={value} variant="small" />
        </Stack>
      )
    case 'nutrition_score':
      return (
        <Stack height="100%" width="100%" alignItems="center" justifyContent="center">
          <NutriScoreImage
            score={nutritionScoreToLetter(value)}
            variant="small"
            isProjected={isProjectedNutrition(row)}
          />
        </Stack>
      )
    default:
      break
  }

  const formattedValue = formatNumber(value) ?? '--'
  const localeScore = value != null ? value.toLocaleString('en-US') || '' : ''

  return (
    <Stack
      data-testid="t2-cell-renderer"
      direction="row"
      alignItems="center"
      justifyContent="center"
      overflow="hidden"
      textOverflow="ellipsis"
      whiteSpace="nowrap"
      height="100%"
      width="100%"
      gap={1}
    >
      <Tooltip disableInteractive title={localeScore ? `${localeScore} ${units}` : 'No data'}>
        <Text fontSize={14}>{formattedValue}</Text>
      </Tooltip>
      <ProductGoalRenderer productId={id as number} field={field} />
    </Stack>
  )
}
