import React, { FC, useEffect, useState } from 'react'
import { Autocomplete, CircularProgress, Stack, Text, TextField, Tooltip, theme } from '@howgood/design'
import { useDispatch, useSelector } from 'react-redux'
import { DRAFT, selectPageView } from '@/state/pageSettings'
import { selectDisplayedProductBrandName, updateDraftProduct } from '@/state/productOverview'
import { selectCurrentScenarioId } from '@/state/initiatives'
import { ProductBrand } from '@/records'
import { fetchBrands } from '@/api/brandApi'
import debounce from 'lodash/debounce'
import { VendorConfirmationDialog } from './VendorConfirmation'
import * as Sentry from '@sentry/react'

/**
 * Generally, the user will select an existing vendor using the autocomplete dropdown.
 * However, if the user types in a vendor name, we'll ask them to confirm the new vendor.
 */

let abortController = new AbortController()

export const VendorDetails: FC = () => {
  const dispatch = useDispatch()
  const view = useSelector(selectPageView)
  const storedVendorName = useSelector(selectDisplayedProductBrandName)
  const scenarioId = useSelector(selectCurrentScenarioId)

  const [options, setOptions] = useState<ProductBrand[]>([])
  const [inputString, setInputString] = useState('')
  const [optionWasSelected, setOptionWasSelected] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [searching, setSearching] = useState(false)

  // Do this in useEffect in case the saved vendor name is null on the initial render
  useEffect(() => {
    setInputString(storedVendorName || '')
  }, [storedVendorName])

  const handleAbort = () => {
    abortController.abort()
    setSearching(false)
  }

  // Search for matching vendors while typing
  const handleInputChanged = debounce(async (input: string) => {
    handleAbort() // Abort any previous search request
    setOptionWasSelected(false)
    setInputString(input)
    setOptions([])
    if (input.trim()) {
      setSearching(true)
      abortController = new AbortController()
      const response = await fetchBrands({ search: input, signal: abortController.signal })
      if (response?.aborted) {
        return
      } else {
        setOptions(response?.items || [])
        setSearching(false)
      }
    }
  }, 250)

  // User selected a matching vendor from the list
  const handleOptionSelected = (selection: ProductBrand) => {
    setOptionWasSelected(true)
    setInputString(selection?.name || '')
    setOptions([])
  }

  // User left the field or pressed Enter
  const handleLeaveInputField = () => {
    abortController.abort() // Abort any previous search request
    setSearching(false)

    // The user may have typed an existing vendor name but not selected it from the list
    const isItemFromList = options.find((option) => option.name === inputString)
    if (isItemFromList) {
      setOptionWasSelected(true)
    }

    // We got a Sentry error on `inputString.trim()` previously before changing it to `inputString?.trim()`
    // `inputString` should never be null or undefined, so adding this so we can see if it happens again (8-6-24)
    if (inputString == null) {
      Sentry.captureMessage('VendorDetails: inputString is null or undefined')
    }

    // Don't display the confirmation dialog if user selected from the list, the field was cleared, or it's the stored name
    if (optionWasSelected || isItemFromList || !inputString?.trim() || inputString === storedVendorName) {
      dispatch(updateDraftProduct({ brand_name: inputString }))
    } else {
      setDialogOpen(true)
    }
    setOptions([])
  }

  // The user may confirm the new vendor name, or choose an existing name from a list of matches
  const handleNewVendorConfirmed = (vendorName: string, fromList: boolean) => {
    dispatch(updateDraftProduct({ brand_name: vendorName }))
    setInputString(vendorName || '') // The user may have chosen a match from a list, so update inputString
    setOptionWasSelected(fromList)
    setDialogOpen(false)
  }

  // Revert to the saved vendor name
  const handleNewVendorCanceled = () => {
    setInputString(storedVendorName || '')
    setDialogOpen(false)
  }

  const handleOnClose = () => {
    setDialogOpen(false)
    handleAbort()
  }

  return (
    <>
      <Stack direction="row" gap={1} alignItems="center">
        <Text>Vendor&nbsp;Details:</Text>
        {view === DRAFT && !scenarioId ? (
          <Autocomplete
            id="vendor-details-autocomplete"
            data-testid="vendor-details-autocomplete"
            fullWidth
            options={options}
            value={inputString}
            getOptionLabel={(option: ProductBrand | string) => (typeof option === 'string' ? option : option.name)}
            freeSolo
            onChange={(_event, input) => handleOptionSelected(input)}
            renderInput={(params) => (
              <Tooltip
                disableFocusListener
                title={inputString === storedVendorName ? storedVendorName : optionWasSelected ? inputString : ''}
              >
                <TextField
                  {...params}
                  id="vendor-details-textfield"
                  label={
                    searching ? (
                      <Stack direction="row" alignItems="center" gap={1} pl={0.5}>
                        <CircularProgress size={16} />
                        Searching...
                      </Stack>
                    ) : null
                  }
                  data-testid="vendor-details-textfield"
                  placeholder="Start typing a vendor name"
                  onChange={(e) => handleInputChanged(e.currentTarget.value)}
                  onBlur={handleLeaveInputField}
                  onEnter={handleLeaveInputField}
                />
              </Tooltip>
            )}
          />
        ) : (
          <Tooltip
            title={view === DRAFT && scenarioId ? "You can't change the vendor details on a scenario product" : ''}
          >
            <Text
              color={storedVendorName ? theme.palette.text.primary : theme.palette.text.secondary}
              data-testid="brand-name-text"
            >
              {storedVendorName || 'Unknown Vendor'}
            </Text>
          </Tooltip>
        )}
      </Stack>
      {dialogOpen && (
        <VendorConfirmationDialog
          inputString={inputString}
          cancelClicked={handleNewVendorCanceled}
          okClicked={handleNewVendorConfirmed}
          onClose={handleOnClose}
        />
      )}
    </>
  )
}
