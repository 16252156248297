import React, { FC } from 'react'
import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core'
import { faCheck, faClock, faOctagonExclamation } from '@fortawesome/pro-solid-svg-icons'
import { theme, Tooltip, Stack, Text, Icon } from '.'

export const StatusIcon: FC<{ status: 'disabled' | 'none' | 'pending' | 'denied' | 'confirmed'; size?: SizeProp }> = ({
  status,
  size = 'lg',
}) => {
  if (status === 'pending') {
    return (
      <Icon
        id="requested-icon"
        data-testid="requested-icon"
        color={theme.palette.info.light}
        size={size}
        icon={faClock}
      />
    )
  }
  if (status === 'denied') {
    return (
      <Icon
        id="denied-icon"
        data-testid="denied-icon"
        color={theme.palette.error.light}
        size={size}
        icon={faOctagonExclamation}
      />
    )
  }
  if (status === 'confirmed') {
    return (
      <Icon
        id="confirmed-icon"
        data-testid="confirmed-icon"
        color={theme.palette.success.light}
        size={size}
        icon={faCheck}
      />
    )
  }
  return null
}

export interface StatusItemT {
  id: string
  status: 'disabled' | 'none' | 'pending' | 'denied' | 'confirmed'
  tooltipText?: string
  icon?: IconProp
  statusText?: string
  isFirst?: boolean
  isLast?: boolean
}

export const StatusItem: FC<StatusItemT> = ({ id, icon, status, tooltipText = '', statusText = '' }) => {
  const statusMap = {
    disabled: {
      color: theme.palette.text.disabled,
    },
    none: {
      color: 'text.primary',
    },
    pending: {
      color: theme.palette.info.light,
    },
    denied: {
      color: theme.palette.error.light,
    },
    confirmed: {
      color: theme.palette.success.light,
    },
  }

  return (
    <Tooltip title={<Text data-testid={`tooltip-${id}`}>{tooltipText}</Text>}>
      <Stack data-testid={id} direction="row" spacing={0.5} sx={{ alignItems: 'center', p: 1 }}>
        {!!icon && <Icon size="sm" icon={icon} color={statusMap[status].color} />}
        <StatusIcon status={status} size="sm" />
        {!!statusText && <Text color={statusMap[status].color}>{statusText}</Text>}
      </Stack>
    </Tooltip>
  )
}

export interface StatusIconBarProps {
  statusList: StatusItemT[]
}

export const StatusIconBar: FC<StatusIconBarProps> = ({ statusList }) => {
  return (
    <Stack px={1} direction="row" border={`solid 1px ${theme.palette.divider}`} borderRadius="26px">
      {statusList.map((statusItem, idx) => (
        <StatusItem
          key={statusItem.id}
          id={statusItem.id}
          status={statusItem.status}
          tooltipText={statusItem.tooltipText}
          statusText={statusItem.statusText}
          icon={statusItem.icon}
          isFirst={idx === 0}
          isLast={idx === statusList.length - 1}
        />
      ))}
    </Stack>
  )
}
