import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'
import { Tooltip } from '@howgood/design'

import scoreA from '@/assets/images/nutriscore/nutriscore-A-ltr.png'
import scoreB from '@/assets/images/nutriscore/nutriscore-B-ltr.png'
import scoreC from '@/assets/images/nutriscore/nutriscore-C-ltr.png'
import scoreD from '@/assets/images/nutriscore/nutriscore-D-ltr.png'
import scoreE from '@/assets/images/nutriscore/nutriscore-E-ltr.png'

interface Props {
  score: string
  isProjected?: boolean
  variant?: 'regular' | 'small'
}

const scores = {
  A: scoreA,
  B: scoreB,
  C: scoreC,
  D: scoreD,
  E: scoreE,
}

const useStyles = createUseStyles<string, Pick<Props, 'variant' | 'isProjected'>>({
  image: {
    width: ({ variant }) => (variant === 'regular' ? 100 : 75),
    height: ({ variant }) => (variant === 'regular' ? 40 : 30),
    filter: ({ isProjected }) => (isProjected ? 'grayscale(1)' : 'none'),
  },
})

const tooltipContent = (
  <>
    Your product formula's overall nutritional values are projected rather than actual.
    <br />
    <br />
    This may occur while either innovating on a formula or if the nutritional values
    <br /> are calculated based on ingredient-level data.
  </>
)

export const NutriScoreImage: FC<Props> = ({ score, isProjected = false, variant = 'regular' }) => {
  const classes = useStyles({ variant, isProjected })

  if (!['A', 'B', 'C', 'D', 'E'].includes(score)) {
    return <>--</>
  }

  if (isProjected) {
    return (
      <Tooltip title={tooltipContent}>
        <img className={classes.image} src={scores[score]} alt={score} />
      </Tooltip>
    )
  }

  return <img className={classes.image} src={scores[score]} alt={score} />
}
