import React, { FC, useState } from 'react'
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItem,
  Text,
  TextField,
} from '@howgood/design'
import { OrgUser } from '@/state/organization'

interface SelectUserDialogProps {
  allUsers: OrgUser[]
  selectedUser: OrgUser
  message?: string
  setUser: (user: OrgUser) => void
  closeDialog: () => void
}

export const SelectUserDialog: FC<SelectUserDialogProps> = ({
  allUsers,
  selectedUser,
  message,
  setUser,
  closeDialog,
}) => {
  const [newUser, setNewUser] = useState<OrgUser>(selectedUser)

  return (
    <Dialog id="select-user-dialog" open>
      <DialogTitle>Select user</DialogTitle>
      <DialogContent>
        <Text>{message}</Text>
        <Box mt={2}>
          <Autocomplete
            id="user-autocomplete"
            aria-label="Select user"
            options={allUsers}
            value={selectedUser || ''}
            getOptionLabel={(option: OrgUser) => option.label || option.email || ''}
            isOptionEqualToValue={(option, val) => option.id === val.id}
            renderInput={(params) => <TextField {...params} />}
            renderOption={(props, option: OrgUser) => (
              <ListItem {...props} key={option.id}>
                {option.label || option.email}
              </ListItem>
            )}
            onChange={(_e, user: OrgUser) => setNewUser(user)}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button id="select-user-cancel-button" onClick={closeDialog}>
          Cancel
        </Button>
        <Button
          id="select-user-ok-button"
          color="primary"
          onClick={() => {
            setUser(newUser)
            closeDialog()
          }}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}
