import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { selectContentfulMetricData } from '@/state/contentfulData'
import { selectDisplayedImpactData } from '@/state/recipe'
import { selectUserHasEmissionsFactorsAccess } from '@/state/user'

type QualitativeScore = 'Fair' | 'Medium' | 'Good'
function getQualitativeScore(score: number): QualitativeScore {
  switch (true) {
    case typeof score !== 'number':
      return null
    case score < 40:
      return 'Fair'
    case score < 70:
      return 'Medium'
    default:
      return 'Good'
  }
}

export const EmissionsFactorsTable: FC = () => {
  const displayedImpactData = useSelector(selectDisplayedImpactData)
  const metricData = useSelector(selectContentfulMetricData)
  const hasEmissionsFactorsAccess = useSelector(selectUserHasEmissionsFactorsAccess)

  if (!hasEmissionsFactorsAccess) {
    return null
  }
  const productImpactData = displayedImpactData.product
  const carbonLifecycleData = [
    {
      field: 'dqs_on_farm_emissions_factor',
      clStage: 'carbon_footprint_farm_to_gate_impact',
      dataQualityYear: `${productImpactData.dqs_on_farm_year} (*note - see below)`,
    },
    {
      field: 'dqs_luc_emissions_factor',
      clStage: 'cf_luc_impact',
      dataQualityYear: 2021,
    },
    {
      field: 'dqs_transportation_emissions_factor',
      clStage: 'cf_total_upstream_transportation_impact',
      dataQualityYear: 2019,
    },
    {
      field: 'dqs_upstream_processing_emissions_factor',
      clStage: 'cf_total_upstream_processing_impact',
      dataQualityYear: 2021,
    },
    {
      field: 'dqs_transportation_emissions_factor',
      clStage: 'cf_mtm_transportation_impact',
      dataQualityYear: 2019,
    },
    {
      field: 'dqs_manufacturing_type',
      clStage: 'cf_manufacturing_impact',
      dataQualityYear: 2021,
    },
    {
      field: 'dqs_packaging_emissions_factor',
      clStage: 'cf_packaging_total_impact',
      dataQualityYear: 2021,
    },
    {
      field: 'dqs_transportation_emissions_factor',
      clStage: 'cf_mts_transportation_impact',
      dataQualityYear: 2019,
    },
    {
      field: 'dqs_storage_emissions_factor',
      clStage: 'cf_storage_impact',
      dataQualityYear: 2021,
    },
    {
      field: 'dqs_transportation_emissions_factor',
      clStage: 'cf_mtr_transportation_impact',
      dataQualityYear: 2019,
    },
    {
      field: 'dqs_retailer_emissions_factor',
      clStage: 'cf_retail_storage_impact',
      dataQualityYear: 2021,
    },
  ]
  const totalsData = [
    {
      field: 'dqs_cf_ftm_gate_ct_verified_emissions_factor',
      clStage: 'cf_ftm_gate_ct_verified_impact',
    },
    {
      field: 'dqs_biogenic_ftm_gate_emissions_factor',
      clStage: 'cf_biogenic_ftm_gate_impact',
    },
    {
      field: 'dqs_cradle_to_shelf_emissions_factor',
      clStage: 'cf_cradle_to_shelf_ct_verified_impact',
    },
    {
      field: 'dqs_biogenic_cradle_to_shelf_emissions_factor',
      clStage: 'cf_biogenic_cradle_to_shelf_impact',
    },
  ]

  return (
    <>
      <h3 data-testid="emissions-factors-title">Emissions Factors Data quality ratings and Data source years</h3>
      <table data-testid="carbon-lifecycle-table">
        <thead>
          <tr>
            <th>Carbon Life Cycle stage</th>
            <th>Data quality rating</th>
            <th>Data source year</th>
          </tr>
        </thead>
        <tbody>
          {carbonLifecycleData.map((data) => {
            const metric = metricData.find((m) => m.key === data.clStage)
            return (
              <tr key={`${data.field}-${data.clStage}`}>
                <td>{metric?.subtitle}</td>
                <td>{getQualitativeScore(productImpactData[data.field])}</td>
                <td>{data.dataQualityYear}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
      <table data-testid="totals-table">
        <tbody>
          {totalsData.map((data) => {
            const metric = metricData.find((m) => m.key === data.clStage)
            const title = metric?.subtitle?.replace('Carbon Lifecycle ', '')
            return (
              <tr key={data.field}>
                <td>
                  <b>{title} Data quality rating:</b>
                </td>
                <td>{getQualitativeScore(productImpactData[data.field])}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
      <p data-testid="emissions-factors-note">
        *Note - New and novel studies on cradle-to-farm-gate GHG emissions for specific agriculture practices may not be
        published with regularity. Conventional growing practices do not change rapidly for most crop location
        combinations. A more recent source year therefore doesn't necessarily infer a better quality data source. It is
        more important when assessing the quality of data behind the cradle-to-farm-gate stage to consider the listed
        data quality rating of the source, and scoring includes recency of publication.
      </p>
    </>
  )
}
