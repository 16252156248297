import React, { FC } from 'react'
import { BLOCKS, INLINES, MARKS, Document } from '@contentful/rich-text-types'
import { documentToReactComponents, Options } from '@contentful/rich-text-react-renderer'
import {
  BoldRenderer,
  LinkRenderer,
  OrderedListRenderer,
  ParagraphRenderer,
  ImageRenderer,
  UnorderedListRenderer,
} from '../renderers'

interface Props {
  document: Document
  rendererOptions?: Pick<Options, 'renderNode' | 'renderMark'>
}

export const RichText: FC<Props> = ({ document, rendererOptions }) => {
  const richTextRenderOptions = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: ParagraphRenderer,
      [BLOCKS.EMBEDDED_ASSET]: ImageRenderer,
      [INLINES.HYPERLINK]: LinkRenderer,
      [BLOCKS.OL_LIST]: OrderedListRenderer,
      [BLOCKS.UL_LIST]: UnorderedListRenderer,
    },
    renderMark: {
      [MARKS.BOLD]: BoldRenderer,
    },
  }

  if (rendererOptions) {
    Object.entries(rendererOptions).forEach(([key, option]) => {
      Object.entries(option).forEach(([optionKey, renderer]) => {
        richTextRenderOptions[key][optionKey] = renderer
      })
    })
  }

  return <>{documentToReactComponents(document, richTextRenderOptions)}</>
}
