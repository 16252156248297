import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchFormulationSearchOptions } from '@/api/elastic/productsEsRepo'
import { IFormulationSearchOption } from '@/records'
import { selectWorkspacesIds } from '@/state/workspaces'
import { selectComponentFilters, selectStatusFilters, updateFiltersUrlAndProducts } from '@/state/products'
import { AutocompleteFilter } from '../Shared/Filters/AutocompleteFilter'

export const ContainsComponentFilter: FC = () => {
  const dispatch = useDispatch()
  const workspaceIds = useSelector(selectWorkspacesIds)
  const statuses = useSelector(selectStatusFilters)
  const components = useSelector(selectComponentFilters)

  const handleComponentChange = (componentOptions: IFormulationSearchOption[]) => {
    dispatch(updateFiltersUrlAndProducts({ updatedFilters: { components: componentOptions } }))
  }

  const fetchComponentOptions = async (query: string) => {
    return await fetchFormulationSearchOptions({
      workspaceIds,
      statuses: statuses.map((s) => s.value),
      query: [query],
    })
  }

  return (
    <AutocompleteFilter
      id="components-search"
      selected={components}
      filterType="components"
      handleChangeFilter={handleComponentChange}
      onUpdateQuery={fetchComponentOptions}
    />
  )
}
