import { useEffect, useState } from 'react'

export const useFetchList = <R>(fetch: () => Promise<any[]>): { data: R[]; isLoading: boolean } => {
  const [data, setData] = useState<R[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    const doApiCall = async () => {
      setIsLoading(true)
      try {
        const result = await fetch()
        setData(result)
      } finally {
        setIsLoading(false)
      }
    }

    doApiCall()
  }, [fetch])

  return { data, isLoading }
}
