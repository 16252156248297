import React, { FC } from 'react'
import { IngredientInfo, selectSelectedProductTree } from '../state/productDashboard'
import { IngredientPopover } from './IngredientPopover'
import { useSelector } from 'react-redux'
import { SunburstChart, Text } from '@howgood/design'

export interface PopoverData {
  anchorEl: HTMLElement
  nodeInfo: IngredientInfo
}

interface ImpactChartProps {
  width: number
}

export const ImpactChart: FC<ImpactChartProps> = ({ width }) => {
  const data = useSelector(selectSelectedProductTree)

  const [popoverData, setPopoverData] = React.useState<PopoverData>(null)

  return (
    <>
      <SunburstChart data={data} width={width} setPopoverData={setPopoverData} />
      <IngredientPopover popoverData={popoverData} />
      <Text textAlign="center">Ingredients by Impact</Text>
    </>
  )
}
