import { InventoriesResponse, fetchHelperV2, FormulationTag } from '@/api'
import { SharingType, ProductRouteLegs } from '@/records'
import { RecipePackagingItem } from '../recipe/recipe.state'
import { Goals } from '@/records/Workspace'
import { ProductInfo, ProductResponse } from './productOverview.state'

export const lockProductData = async (id: number): Promise<ProductResponse> =>
  await fetchHelperV2({
    url: `products/${id}/lock-claims/`,
    method: 'PUT',
  })

export const generateProductReport = async (id: number, base64String: string): Promise<ProductResponse> =>
  await fetchHelperV2({
    url: `products/${id}/save-report/`,
    method: 'PUT',
    body: JSON.stringify({ ghg_report: base64String }),
  })

export const requestProductValidation = async (id: number, workspaceId: number, validationText: string) =>
  await fetchHelperV2({
    url: `products/${id}/request-validation/`,
    method: 'POST',
    body: JSON.stringify({ workspace_id: workspaceId, free_text: validationText }),
  })

export const requestProductSharing = async (id: number, type: SharingType, requestText: string) =>
  await fetchHelperV2<ProductInfo>({
    url: `products/${id}/request-sharing/`,
    method: 'POST',
    body: JSON.stringify({ type: type, free_text: requestText }),
  })

type RequestUpdateInventory = {
  product_id: number
  inventory_id: number
  formulation_tags: FormulationTag[]
  mt_per_year: number
  internal_id: string
  goals: Goals
  workspace: {
    id: number
  }
  scenarioId?: number
}
export const requestUpdateInventory = async ({
  product_id,
  inventory_id,
  formulation_tags,
  mt_per_year,
  internal_id,
  goals,
  workspace,
  scenarioId = null,
}: RequestUpdateInventory) => {
  const updateInventory = await fetchHelperV2<InventoriesResponse>({
    url: scenarioId
      ? `scenarios/${scenarioId}/products/${product_id}/inventories/${inventory_id}/`
      : `products/${product_id}/inventories/${inventory_id}/`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      formulation_tags,
      mt_per_year,
      internal_id: internal_id != null ? internal_id : undefined, // Backend crashes if internal_id is null
      goals,
      workspace,
    }),
  })
  return {
    ...updateInventory,
    validation_requests: updateInventory.validation_requests || [],
  }
}

export const requestAddPackaging = async (
  packagingList: RecipePackagingItem[],
  productId: number,
  scenarioId: number = null
) => {
  return await Promise.allSettled<RecipePackagingItem>(
    packagingList.map((item) =>
      fetchHelperV2({
        url: scenarioId ? `scenarios/${scenarioId}/packaging/` : 'packaging/',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          packaging_unit: item.packaging_unit,
          material: { id: item.material.id },
          shape: { id: item.shape.id },
          product: { id: productId },
          consumer_units: item.consumer_units,
          region: item.region?.id ? { id: item.region.id } : undefined,
          packaging_material_weight: item.packaging_material_weight,
          packaging_uses: item.packaging_uses,
        }),
      })
    )
  )
}

export const requestDeletePackaging = async (packagingList: RecipePackagingItem[], scenarioId: number = null) => {
  return await Promise.allSettled<RecipePackagingItem>(
    packagingList.map((item) =>
      fetchHelperV2({
        url: scenarioId ? `scenarios/${scenarioId}/packaging/${item.id}` : `packaging/${item.id}`,
        method: 'DELETE',
        parse: false,
      })
    )
  )
}

interface Multiroute {
  id: number
  date_created: string
  date_modified: string
  workflow_editor: number
  name: string
  routes: { id: number }[]
}
export async function requestAddTransportation(
  productId: number,
  productTransportation: ProductRouteLegs,
  scenarioId: number = null
): Promise<number> {
  const multiroute = await fetchHelperV2<Multiroute>({
    url: scenarioId ? `scenarios/${scenarioId}/products/${productId}/routes/` : `products/${productId}/routes/`,
    method: 'PUT',
    body: JSON.stringify({
      mtr: productTransportation.mtr.map((item) => ({
        waypoint: { id: item.waypoint.id },
        mode: { id: item.mode.id },
        distance_override: item.distance_override,
      })),
      mts: [],
    }),
  })

  return multiroute.id
}
