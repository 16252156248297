import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'
import { PageTemplateProps } from '@progress/kendo-react-pdf'
import { theme } from '@howgood/design'

const useStyles = createUseStyles({
  footerContainer: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 11,
    color: theme.palette.text.primary,
    position: 'absolute',
    bottom: '0.5cm',
    right: '1.5cm',
  },
  footerText: {
    textAlign: 'right',
    color: theme.palette.text.secondary,
  },
})

export const PageFooter: FC<PageTemplateProps> = ({ pageNum, totalPages: _totalPages }) => {
  const classes = useStyles()

  return (
    <div className={classes.footerContainer}>
      <p className={classes.footerText}>Property of HowGood, Inc {pageNum}</p>
    </div>
  )
}
