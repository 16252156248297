import React, { FC, ReactNode } from 'react'
import { Grid } from '@howgood/design'

interface Props {
  children: ReactNode
}

export const PanelGrid: FC<Props> = ({ children }) => {
  return (
    <Grid container alignItems="stretch" columnSpacing={2} rowSpacing={3} mt={-2} pl="1px">
      {children}
    </Grid>
  )
}
