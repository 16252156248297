import React, { FC } from 'react'
import { Document } from '@contentful/rich-text-types'
import { Text, Accordion, AccordionSummary, AccordionDetails } from '@howgood/design'
import { RequestVendorDataRichText } from './RequestVendorDataRichText'
import { CopyOrOpenLinkActions } from './CopyOrOpenLinkActions'
import { useUrlWithUseridParam } from '@/hooks/useUrlWithUseridParam'
import { LCA_SUBMISSION_LINK } from '@/constants/common'

interface Props {
  ['data-testid']: string
  expanded: boolean
  isCopiedFromCurrent: boolean
  onChange: () => void
  handleCopy: () => void
  title: string
  content: Document
}

export const HaveVendorDataAccordion: FC<Props> = ({
  ['data-testid']: dataTestId,
  expanded,
  isCopiedFromCurrent,
  onChange,
  handleCopy,
  title,
  content,
}) => {
  const url = useUrlWithUseridParam(LCA_SUBMISSION_LINK)
  return (
    <Accordion type="secondary" expanded={expanded} onChange={onChange} data-testid={dataTestId}>
      <AccordionSummary>
        <Text variant="subtitle1">{title}</Text>
      </AccordionSummary>
      <AccordionDetails>
        <RequestVendorDataRichText document={content} />
        <CopyOrOpenLinkActions
          url={url}
          copyLinkText="Copy Upload Portal Link"
          openLinkText="Open Upload Portal"
          copyLinkWidth={230}
          isCopied={isCopiedFromCurrent}
          handleCopy={handleCopy}
        />
      </AccordionDetails>
    </Accordion>
  )
}
