import React, { forwardRef } from 'react'
import MuiBadge, { BadgeProps as MuiBadgeProps } from '@mui/material/Badge'

interface Props extends Omit<MuiBadgeProps, 'className'> {
  ['aria-label']?: string
  ['data-pendo-id']?: string
  ['data-testid']?: string
  fontSize?: 'sm' | 'md' | 'lg'
}

export const Badge = forwardRef<HTMLElement, Props>(
  (
    {
      ['aria-label']: ariaLabel,
      ['data-pendo-id']: dataPendoId,
      ['data-testid']: dataTestId,
      fontSize = 'md',
      children,
      ...rest
    },
    ref
  ) => {
    const fontMap = {
      sm: 9,
      md: 12,
      lg: 16,
    }
    return (
      <MuiBadge
        ref={ref}
        aria-label={ariaLabel}
        data-pendo-id={dataPendoId}
        data-testid={dataTestId}
        sx={{
          '& .MuiBadge-badge': { fontSize: fontMap[fontSize] },
        }}
        {...rest}
      >
        {children}
      </MuiBadge>
    )
  }
)

Badge.displayName = 'Badge'
