import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { faMinusCircle } from '@fortawesome/pro-regular-svg-icons'
import { IconButton } from '@howgood/design'
import { selectDisplayedPackaging, updateRecipeAndScores } from '@/state/recipe'
import { RecipePackagingItem } from '@/state/recipe/recipe.state'
import { DRAFT, selectPageView } from '@/state/pageSettings'
import { PackagingMaterial } from './PackagingMaterial'
import { PackagingGridItem } from './PackagingGridItem'
import { PackagingInput } from './PackagingInput'
import { PackagingRegion } from './PackagingRegion'
import { PackagingUnit } from './PackagingUnit'

interface PackagingItemProps {
  packagingItem: RecipePackagingItem
  setTempItem?: (item: RecipePackagingItem) => void
  index: number
}

export const PackagingItem: FC<PackagingItemProps> = ({ packagingItem, setTempItem, index }) => {
  const dispatch = useDispatch()
  const view = useSelector(selectPageView)
  const packaging = useSelector(selectDisplayedPackaging)

  const handleRemoveItem = () => {
    if (setTempItem) {
      setTempItem(null)
    } else {
      dispatch(
        updateRecipeAndScores({
          recipeUpdates: {
            packaging: packaging.filter((item) => item.id !== packagingItem.id),
          },
          change: `Removed ${packagingItem.id} from packaging`,
        })
      )
    }
  }

  return (
    <>
      <PackagingGridItem xs={1}>
        <IconButton
          data-testid="remove-packaging-button"
          id={`remove-packaging-button-${index}`}
          disabled={view !== DRAFT}
          icon={faMinusCircle}
          onClick={handleRemoveItem}
        />
      </PackagingGridItem>
      <PackagingGridItem xs={5}>
        <PackagingUnit packagingItem={packagingItem} index={index} setTempItem={setTempItem} />
      </PackagingGridItem>
      <PackagingGridItem xs={3}>
        <PackagingInput field="consumer_units" packagingItem={packagingItem} setTempItem={setTempItem} integer />
      </PackagingGridItem>
      <PackagingGridItem xs={4}>
        <PackagingMaterial packagingItem={packagingItem} index={index} setTempItem={setTempItem} />
      </PackagingGridItem>
      <PackagingGridItem xs={4}>
        <PackagingRegion packagingItem={packagingItem} setTempItem={setTempItem} index={index} />
      </PackagingGridItem>
      <PackagingGridItem xs={4}>
        <PackagingInput field="packaging_material_weight" packagingItem={packagingItem} setTempItem={setTempItem} />
      </PackagingGridItem>
      <PackagingGridItem xs={3}>
        <PackagingInput field="packaging_uses" packagingItem={packagingItem} setTempItem={setTempItem} integer />
      </PackagingGridItem>
    </>
  )
}
