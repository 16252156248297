import React, { FC, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Grid } from '@howgood/design'
import { FormulaNotes } from '@/features/ProductPage/panels/FormulaNotes/FormulaNotes'
import { CardData } from '@/records/scoreCardData'
import { selectProductNutrition } from '@/state/nutrition'
import { selectFavoriteCards } from '@/state/preferences'
import {
  selectDisplayedProductGoals,
  selectHasImpactsOverriddenNestedProduct,
  selectIsImpactsOverridden,
} from '@/state/productOverview'
import { selectIsRollup } from '@/state/router'
import { ImpactReduction } from '@/features/ProductPage/panels/ImpactReduction/ImpactReduction'
import { ImpactHistory } from '@/features/ProductPage/panels/ImpactHistory'
import { useScoreCardData } from './ScoreCards/useScoreCardData'
import { ClaimsCard } from './ClaimsCard'
import { ScoreCards } from './ScoreCards/ScoreCards'
import { OverviewBars } from './OverviewPanel/OverviewBars/OverviewBars'
import { PanelGrid } from './PanelGrid'
import { LifecycleChart } from './CarbonPanel/LifecycleChart'
import { Nutrition } from './Nutrition/Nutrition'
import { Flag } from './CarbonPanel/Flag'
import { selectCurrentScenarioGoals } from '@/state/initiatives'
import { VendorNotes } from './OverviewPanel/VendorNotes'
import { InitiativeChartPanel } from './OverviewPanel/InitiativeChartPanel'

interface Categorized {
  overview?: CardData[]
  carbon?: CardData[]
  nature?: CardData[]
  people?: CardData[]
  other?: CardData[]
}

export const FavoritesPanel: FC = () => {
  const scoreCardData = useScoreCardData()
  const favoriteCards = useSelector(selectFavoriteCards)
  const nutrition = useSelector(selectProductNutrition)
  const impactsOverriddenProduct = useSelector(selectIsImpactsOverridden)
  const hasImpactsOverriddenNestedProduct = useSelector(selectHasImpactsOverriddenNestedProduct)
  const isRollup = useSelector(selectIsRollup)
  const productGoals = useSelector(selectDisplayedProductGoals)
  const rollupGoals = useSelector(selectCurrentScenarioGoals)
  const goals = isRollup ? rollupGoals : productGoals
  const goalCards = useMemo(() => {
    const cards = []
    Object.values(scoreCardData).forEach((scoreCard) => {
      scoreCard.data.forEach((card) => {
        if (Object.keys(goals).includes(card.key)) {
          cards.push(card)
        }
      })
    })
    return cards
  }, [goals, scoreCardData])

  const categorizedFavorites = useMemo<Categorized>(() => {
    const allFavorites = new Set<CardData>([...scoreCardData.favorites.data, ...goalCards])
    return [...allFavorites].reduce(
      (categorized, cardData) => {
        return {
          ...categorized,
          [cardData.category]: categorized[cardData.category]
            ? [...categorized[cardData.category], cardData]
            : [cardData],
        }
      },
      {
        overview: [],
        carbon: [],
        nature: [],
        people: [],
        other: [],
      } as Categorized
    )
  }, [scoreCardData.favorites.data, goalCards])

  return (
    <PanelGrid>
      <ScoreCards cards={categorizedFavorites.overview} isFavorites />
      {favoriteCards.includes('claims') && !impactsOverriddenProduct && !hasImpactsOverriddenNestedProduct && (
        <ClaimsCard isFavorites />
      )}
      <ScoreCards cards={categorizedFavorites.carbon} isFavorites />
      <ScoreCards cards={categorizedFavorites.nature} isFavorites />
      <ScoreCards cards={categorizedFavorites.people} isFavorites />
      <ScoreCards cards={categorizedFavorites.other} isFavorites />
      {favoriteCards.includes('impacts') && !hasImpactsOverriddenNestedProduct && <OverviewBars isFavorites />}
      {favoriteCards.includes('impactReduction') && <ImpactReduction isFavorites />}
      {favoriteCards.includes('impactHistory') && <ImpactHistory isFavorites />}
      {favoriteCards.includes('notes') && <FormulaNotes isFavorites />}
      {favoriteCards.includes('notes') && <VendorNotes isFavorites />}
      {favoriteCards.includes('initiativeChart') && <InitiativeChartPanel isFavorites />}
      {favoriteCards.includes('flag') && (
        <Grid item width="100%">
          <Flag isFavorites />
        </Grid>
      )}
      {favoriteCards.includes('lifecycle') && (
        <Grid item width="100%">
          <LifecycleChart isFavorites />
        </Grid>
      )}
      {favoriteCards.includes('nutrition') && nutrition && (
        <Grid item width="100%">
          <Nutrition isFavorites />
        </Grid>
      )}
    </PanelGrid>
  )
}
