import React, { FC } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Button, Stack, Box } from '@howgood/design'
import { faLink, faCheck, faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons'

interface Props {
  url: string
  copyLinkText?: string
  openLinkText?: string
  copyLinkWidth?: number
  isCopied: boolean
  handleCopy: () => void
}

export const CopyOrOpenLinkActions: FC<Props> = ({
  url,
  copyLinkText = 'Copy Link',
  openLinkText = 'Open link',
  copyLinkWidth = 130,
  isCopied,
  handleCopy,
}) => {
  return (
    <Stack direction="row" alignItems="center" gap={2} sx={{ mt: 2 }}>
      <CopyToClipboard text={url} onCopy={handleCopy}>
        <Box width={copyLinkWidth}>
          <Button
            data-testid="copy-link-button"
            color="primary"
            aria-label="Copy Link"
            startIcon={isCopied ? faCheck : faLink}
            fullWidth
          >
            {isCopied ? 'Link copied' : copyLinkText}
          </Button>
        </Box>
      </CopyToClipboard>
      <a href={url} target="_blank" rel="noopener noreferrer">
        <Button data-testid="open-link-button" aria-label="Open Link" startIcon={faArrowUpRightFromSquare}>
          {openLinkText}
        </Button>
      </a>
    </Stack>
  )
}
