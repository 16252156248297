import { createSlice } from '@reduxjs/toolkit'
import { BenchmarkData } from './salesCategories.requests'
import { getSalesCategories } from './salesCategories.thunk'

export interface SalesCategory {
  date_created: string
  date_modified: string
  id: number
  title: string
  workflow_editor: number
}

export interface State {
  isLoading: boolean
  salesCategories: SalesCategory[]
  benchmarkData: BenchmarkData
}
export const initialState: State = {
  isLoading: false,
  salesCategories: [],
  benchmarkData: {},
}

const salesCategoriesSlice = createSlice({
  name: 'salesCategories',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSalesCategories.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getSalesCategories.fulfilled, (state, action) => {
      const { salesCategories, benchmarkData } = action.payload
      state.isLoading = false
      state.salesCategories = salesCategories
      state.benchmarkData = benchmarkData
    })
    builder.addCase(getSalesCategories.rejected, (state, action) => {
      console.error(action.error)
      state.isLoading = false
      state.salesCategories = initialState.salesCategories
      state.benchmarkData = initialState.benchmarkData
    })
  },
})

export const salesCategoriesReducer = salesCategoriesSlice.reducer
