import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setLiteUserPaywallDialogState } from '@/state/liteUserPaywallDialog'
import { selectIsSupplierConnect } from '@/state/organization'

export const useLiteUserPaywallDialogForPages = () => {
  const dispatch = useDispatch()
  const isSupplierConnect = useSelector(selectIsSupplierConnect)

  useEffect(() => {
    if (isSupplierConnect) {
      dispatch(
        setLiteUserPaywallDialogState({
          open: true,
          redirectPath: '/',
        })
      )
    }
  }, [isSupplierConnect, dispatch])
}
