import React, { FC, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Box, Card, ProgressChip, Stack, Text, theme } from '@howgood/design'
import { CarbonAccountingReport, ScopeCategories } from '@/state/carbonAccounting'
import { selectContentfulCarbonAccountingData } from '@/state/contentfulData'
import { ReportMenu } from '../../ReportMenu'
import { scopeToFillMap } from '../../CarbonAccounting.utils'
import { CarbonAccountingTotal } from '../../CarbonAccountingTotals'
import { CarbonAccountingItemChart } from './CarbonAccountingItemChart'
import { Year } from '@/components/Layout/Year'

interface Props {
  report: CarbonAccountingReport
  animate?: boolean
}

const EMPTY_DATA = [{ key: '', title: '', score: 1, fill: theme.palette.divider }]

function getPercentComplete(scopeCategories: ScopeCategories): number {
  const completeCategories = Object.values(scopeCategories).reduce(
    (acc, category) => (category.complete || category.not_applicable ? acc + 1 : acc),
    0
  )
  const totalCategories = Object.keys(scopeCategories).length
  return Math.round((completeCategories / totalCategories) * 100)
}

export const CarbonAccountingItem: FC<Props> = ({ report, animate = true }) => {
  const percentComplete = getPercentComplete(report.scope_categories)
  const categories = useSelector(selectContentfulCarbonAccountingData)
  const noScores = Object.values(report.scope_categories).findIndex((category) => category.score !== null) === -1

  const data = useMemo(
    () =>
      categories.map((category) => ({
        key: category.key,
        title: category.title,
        score: report.scope_categories[category.key].score,
        fill: scopeToFillMap[category.key] || theme.palette.success.main,
      })),
    [categories, report.scope_categories]
  )
  const scope1And2Data = useMemo(
    () => (noScores ? EMPTY_DATA : data.filter((d) => ['scope_1', 'scope_2'].includes(d.key))),
    [data, noScores]
  )
  const scope3Data = useMemo(() => (noScores ? EMPTY_DATA : data.filter((d) => d.key.includes('scope_3'))), [
    data,
    noScores,
  ])

  return (
    <Link to={`/carbon-accounting/${report.id}`}>
      <Card sx={{ cursor: 'pointer', '&:hover': { bgcolor: theme.palette.grey[100] } }}>
        <Stack data-testid="carbon-accounting-item" p={2} flexDirection="row" alignItems="flex-start" gap={2}>
          <Year year={report.year} data-testid="report-year" />
          <Stack flex={1} alignItems="flex-start" gap={2} pt={0.5}>
            <Stack flexDirection="row" alignItems="center" gap={2} width="100%">
              <Box flex={1}>
                <Text data-testid="report-title" fontWeight={600}>
                  {report.title}
                </Text>
              </Box>
              <Stack data-testid="report-progress" flexDirection="row" alignItems="center" gap={1}>
                <ProgressChip percentFilled={percentComplete} />
                <Text variant="caption">{percentComplete}% complete</Text>
              </Stack>
              <ReportMenu reportId={report?.id} />
            </Stack>
            <Stack flexDirection="row" alignItems="center" gap={4} width="100%">
              <Stack data-testid="report-charts" flexDirection="row" gap={2}>
                <CarbonAccountingItemChart title="Scope 1-2" data={scope1And2Data} innerRadius={55} animate={animate} />
                <CarbonAccountingItemChart title="Scope 3" data={scope3Data} innerRadius={40} animate={animate} />
              </Stack>
              <Stack data-testid="report-totals" flex={1}>
                <CarbonAccountingTotal scope="scope_1" title="Scope 1" score={report.scope_categories.scope_1.score} />
                <CarbonAccountingTotal scope="scope_2" title="Scope 2" score={report.scope_categories.scope_2.score} />
                <CarbonAccountingTotal scope="scope_3" title="Scope 3" score={report.scope_3_total} />
                <CarbonAccountingTotal title="Total Annual Emissions" score={report.all_scopes_total} />
                <Stack data-testid="report-totals-units" alignItems="flex-end">
                  <Text variant="caption">MT CO2e/year</Text>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Card>
    </Link>
  )
}
