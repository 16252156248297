import React, { FC, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PDFExport } from '@progress/kendo-react-pdf'
import { faFileChartColumn } from '@fortawesome/pro-regular-svg-icons'
import { MenuItem, ListItemIcon, ListItemText } from '@howgood/design'

import { usePendoTrack } from '@/hooks'
import { ProductImpactExport } from '../ProductImpactExport'
import { setLiteUserPaywallDialogState } from '@/state/liteUserPaywallDialog'
import { selectIsSupplierConnect } from '@/state/organization'

export const ExportProductImpact: FC<{ onClick: () => void }> = ({ onClick }) => {
  const isSupplierConnect = useSelector(selectIsSupplierConnect)
  const dispatch = useDispatch()
  const pendoTrack = usePendoTrack()
  const productImpactPdfExportRef = useRef<PDFExport>()

  const handleExportProductImpact = () => {
    if (isSupplierConnect) {
      dispatch(
        setLiteUserPaywallDialogState({
          open: true,
        })
      )
      return
    }
    pendoTrack('Formula: Export product impact')
    onClick()
    if (productImpactPdfExportRef.current) {
      productImpactPdfExportRef.current.save()
    }
  }

  return (
    <>
      <ProductImpactExport ref={productImpactPdfExportRef} />
      <MenuItem
        id="export-product-impact-option"
        data-testid="export-product-impact-option"
        onClick={handleExportProductImpact}
      >
        <ListItemIcon icon={faFileChartColumn} />
        <ListItemText>Export product impact</ListItemText>
      </MenuItem>
    </>
  )
}
