import React, { FC, useCallback, useEffect, useRef } from 'react'
import { Text, Stack, Dialog, DialogContent, DialogTitle, DialogActions, Button } from '@howgood/design'
import { ProductGhgImpactExport } from '@/features/ProductPage/export/ProductGhgImpactExport/ProductGhgImpactExport'
import { PDFExport } from '@progress/kendo-react-pdf'
import { drawDOM, exportPDF } from '@progress/kendo-drawing'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentClaimsReport, selectDisplayedProduct } from '@/state/productOverview'
import { usePendoTrack } from '@/hooks'
import { PUBLISHED, SAVED, selectPageView, setView } from '@/state/pageSettings'
import { autoGenerateReport } from '@/state/pageSettings/pageSettings.thunk'
import { PDF_EXPORT_SETTINGS } from '@/constants/pdfExportSettings'
import { selectIsSupplierConnect } from '@/state/organization'

/**
 * Dialog for generating a carbon footprint report for SupplierConnect and third party products.
 * The button to display the dialog is not displayed when in draft mode, and the menu item is disabled
 */

interface ProductCFReportDialogProps {
  closeDialog: () => void
}

export const ProductCFReportDialog: FC<ProductCFReportDialogProps> = ({ closeDialog }) => {
  const view = useSelector(selectPageView)
  const product = useSelector(selectDisplayedProduct)
  const currentClaimsReport = useSelector(selectCurrentClaimsReport)
  const isSupplierConnect = useSelector(selectIsSupplierConnect)
  const pendoTrack = usePendoTrack()
  const dispatch = useDispatch()

  const productGhgImpactExportRef = useRef<PDFExport>()
  const pdfContentRef = useRef(null)

  // Initialize the PDF processor (otherwise first export will be blank pages)
  useEffect(() => {
    drawDOM(pdfContentRef.current, { paperSize: 'A4' })
  }, [])

  const lockAndAutoGenerateReport = () => {
    closeDialog()
    pendoTrack('Formula: Lock and auto generate report')
    dispatch(autoGenerateReport())
  }

  const switchToReportView = () => {
    // Leave the dialog open so user can generate report
    pendoTrack('Formula: Switch to Report view')
    dispatch(setView(PUBLISHED))
  }

  const generateReport = useCallback(() => {
    closeDialog()
    pendoTrack('Formula: Generate supplier report')
    // See https://www.telerik.com/kendo-react-ui/components/pdfprocessing/
    drawDOM(pdfContentRef.current, {
      ...PDF_EXPORT_SETTINGS,
      // See https://www.telerik.com/kendo-react-ui/components/knowledge-base/grid-pdf-export-watermark/
      template: () =>
        isSupplierConnect
          ? `<div style='position: absolute; left: 0; top: 3in; width: 100%; text-align: center;
      font-size: 48px; opacity: 0.2; transform: rotate(45deg); transform-origin: center'>
      <b>Internal Use Only</b>
      </div>`
          : '',
    })
      .then((group) => {
        return exportPDF(group)
      })
      .then((dataUri) => {
        const link = document.createElement('a')
        link.download = `Product Carbon Footprint Report _ ${product.name}.pdf`
        link.href = dataUri
        link.click()
      })
  }, [closeDialog, isSupplierConnect, pendoTrack, product.name])

  // If the product is not locked, open the drawer and automatically lock it, creating a report
  if (!currentClaimsReport) {
    return (
      <Dialog id="generate-cf-report-dialog" open>
        <DialogContent>
          <Stack gap={2}>
            <Text>
              This will create a snapshot of this product&apos;s underlying data that you can share with third parties,
              or submit for validation to make external claims.
            </Text>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button id="cf-report-dialog-cancel-button" onClick={closeDialog}>
            Cancel
          </Button>
          <Button id="cf-report-dialog-ok-button" color="primary" onClick={lockAndAutoGenerateReport}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  // If viewing the live version, we can't generate an "on-the-fly" report because you can only generate one using locked data
  if (view === SAVED) {
    return (
      <Dialog id="generate-cf-report-dialog" open>
        <DialogContent>
          <Stack gap={2}>
            <Text>
              Carbon footprint reports are only available from Report view. Do you want to switch to Report view?
            </Text>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button id="cf-report-dialog-cancel-button" onClick={closeDialog}>
            Cancel
          </Button>
          <Button id="cf-report-dialog-ok-button" color="primary" onClick={switchToReportView}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  // If product is already locked and we're in report/published view, or is a material, generate an "on the fly" report
  return (
    <>
      <Dialog id="generate-cf-report-dialog" open>
        <DialogTitle>
          {currentClaimsReport && isSupplierConnect ? 'Download' : 'Generate'} carbon footprint report
        </DialogTitle>
        <DialogContent>
          <Stack gap={2}>
            {isSupplierConnect && (
              <Text>
                This will {currentClaimsReport ? 'download' : 'generate'} a product carbon footprint report for the
                version of the product you have shared.
              </Text>
            )}
            {!isSupplierConnect && <Text>Create a product carbon footprint report for this material?</Text>}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button id="cf-report-dialog-cancel-button" onClick={closeDialog}>
            Cancel
          </Button>
          <Button id="cf-report-dialog-ok-button" color="primary" onClick={generateReport}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <ProductGhgImpactExport
        ref={productGhgImpactExportRef}
        pdfContentRef={pdfContentRef}
        fromSupplierConnect={isSupplierConnect}
      />
    </>
  )
}
