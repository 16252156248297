import React, { forwardRef } from 'react'
import { PDFExport } from '@progress/kendo-react-pdf'
import { createUseStyles } from 'react-jss'
import { Logo } from '@/components'
import { RichText } from '@/contentfully'
import { useExportStyles } from '@/styles'
import { SourceList } from './SourceList'
import { PageFooter } from './PageFooter'
import { useContentfulDocument } from '@/contentfully/hooks'

const useStyles = createUseStyles({
  logo: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
})

export const MethodologyExport = forwardRef<PDFExport>((_, ref) => {
  const exportClasses = useExportStyles()
  const classes = useStyles()
  const content = useContentfulDocument('sources-and-methodology')

  if (!content) {
    return null
  }

  return (
    <div data-testid="methodology-export-container" className={exportClasses.outerContainer}>
      <PDFExport
        ref={ref}
        paperSize="A4"
        margin="2cm"
        fileName="HowGood Sources and Methodology"
        pageTemplate={PageFooter}
      >
        <div className={exportClasses.container}>
          <div className={classes.logo}>
            <Logo />
          </div>
          <RichText document={content.document} />
          <SourceList sources={content.sources} />
          <RichText document={content.versionHistory} />
        </div>
      </PDFExport>
    </div>
  )
})

MethodologyExport.displayName = 'MethodologyExport'
