import React, { FC } from 'react'
import { TextField, Stack, FormControl, Select, MenuItem, InputLabel } from '@howgood/design'

interface Props {
  scenarioName: string
  setScenarioName: React.Dispatch<React.SetStateAction<string>>
  targetYear: number
  setTargetYear: React.Dispatch<React.SetStateAction<number>>
  yearOptions?: number[]
}

export const NewScenarioFields: FC<Props> = ({
  scenarioName,
  setScenarioName,
  targetYear,
  setTargetYear,
  yearOptions = Array.from({ length: 15 }, (_value, index) => new Date().getFullYear() + 1 + index),
}) => {
  return (
    <Stack direction="row" gap={2}>
      <TextField
        fullWidth
        id="scenario-name"
        data-testid="scenario-name"
        label="Scenario name"
        value={scenarioName}
        onChange={(e) => setScenarioName(e.target.value)}
      />
      <Stack width={190}>
        <FormControl fullWidth>
          <InputLabel id="target-year-select-label">Target year</InputLabel>
          <Select
            labelId="target-year-select-label"
            id="target-year-select"
            value={targetYear}
            label="Target year"
            onChange={(e) => setTargetYear(e.target.value as number)}
          >
            {yearOptions.map((year) => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
    </Stack>
  )
}
