import React, { FC } from 'react'
import MuiCheckbox, { CheckboxProps as MuiCheckboxProps } from '@mui/material/Checkbox'
import { FormControlLabel } from './FormControlLabel'

export interface CheckboxProps
  extends Pick<
    MuiCheckboxProps,
    'id' | 'aria-label' | 'size' | 'checked' | 'onChange' | 'inputProps' | 'disabled' | 'indeterminate'
  > {
  color?: 'primary' | 'secondary' | 'info'
  label?: string
  ['data-testid']?: string
}

export const Checkbox: FC<CheckboxProps> = ({
  id,
  ['aria-label']: ariaLabel,
  color,
  size,
  checked,
  onChange,
  inputProps,
  disabled,
  indeterminate,
  label,
  ['data-testid']: dataTestId,
}) => {
  return (
    <FormControlLabel
      label={label}
      labelPlacement="end"
      data-testid={dataTestId}
      sx={{ m: 0 }}
      control={
        <MuiCheckbox
          id={id}
          aria-label={ariaLabel}
          color={color}
          size={size}
          checked={checked}
          disabled={disabled}
          onChange={onChange}
          inputProps={inputProps}
          disableRipple
          indeterminate={indeterminate}
          sx={{ p: 0, pr: 1 }}
        />
      }
    />
  )
}
