import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MenuItem, Select, SelectChangeEvent, Text } from '@howgood/design'
import { selectDisplayedPackaging, updateRecipeAndScores } from '@/state/recipe'
import { RecipePackagingItem } from '@/state/recipe/recipe.state'
import { DRAFT, selectPageView } from '@/state/pageSettings'
import { selectPackagingMaterials } from '@/state/packaging'

interface Props {
  packagingItem: RecipePackagingItem
  setTempItem: (item: RecipePackagingItem) => void
  index: number
}

export const PackagingMaterial: FC<Props> = ({ packagingItem, setTempItem, index }) => {
  const dispatch = useDispatch()
  const packagingOptions = useSelector(selectPackagingMaterials)
  const view = useSelector(selectPageView)
  const packaging = useSelector(selectDisplayedPackaging)

  const option = {
    id: packagingItem.material?.id,
    name: packagingItem.material?.name,
  }

  const handleChangePackaging = (event: SelectChangeEvent<string>) => {
    const valueId = event.target.value
    const value = packagingOptions.find((m) => m.id.toString() === valueId)
    if (setTempItem) {
      setTempItem({
        ...packagingItem,
        material: { id: value.id, name: value.name },
      })
    } else {
      dispatch(
        updateRecipeAndScores({
          recipeUpdates: {
            packaging: packaging.map((item) => {
              if (item.id === packagingItem.id) {
                return {
                  ...item,
                  material: { id: value.id, name: value.name },
                }
              }
              return item
            }),
          },
          change: `Changed packaging item material to ${value.name}`,
        })
      )
    }
  }

  if (view !== DRAFT) {
    return (
      <Text data-testid="material-text" id={`material-text-${index}`}>
        {packagingItem.material?.name}
      </Text>
    )
  }

  return (
    <Select
      id={`material-select-${index}`}
      data-testid="material-select"
      value={option?.id?.toString() || ''}
      renderValue={() => option.name}
      onChange={handleChangePackaging}
      MenuProps={{ id: 'material-menu' }}
      fullWidth
      required
    >
      {packagingOptions.map((packagingOption) => (
        <MenuItem key={`material-${packagingOption.id}`} value={packagingOption.id.toString()}>
          {packagingOption.name}
        </MenuItem>
      ))}
    </Select>
  )
}
