import React, { FC } from 'react'
import { Text, Stack, List, ListItem, Divider, Box } from '@howgood/design'
import { useSelector } from 'react-redux'
import { selectImpactReductionPracticesSplit } from '@/state/splitio'

const labels = [
  'Cover Cropping',
  'No Till',
  'Rain fed',
  'Enclosing your farm with contiguous native vegetation',
  'All wetland and riparian areas on your farm buffered by at least 50 feet of perennial vegetation',
  '[Broilers only] Provide enrichments for perching and pecking',
  '[Broilers only] Chickens have continuous access to the outdoors?',
  '[Beef only] Disbudding occurs prior to 6 weeks of age and pain relief is provided.',
  '[Beef only] Dehorning is prohibited',
  '[Beef only] Castration Occurs prior to 3 months of age',
]

export const AgriculturalPractices: FC = () => {
  const impactReductionPracticiesEnabled = useSelector(selectImpactReductionPracticesSplit)

  if (!impactReductionPracticiesEnabled) {
    return null
  }

  return (
    <Stack direction="row" flex={1} data-testid="agricultural-practices">
      <Box py={1.5}>
        <Divider orientation="vertical" />
      </Box>
      <Stack padding="10px 20px">
        <Text color="secondary">Agricultural Practices</Text>
        <Text fontSize={12} lineHeight={1.2}>
          The following practices lower the impact of your material
        </Text>
        <List listStyleType="disc">
          {labels.map((item) => (
            <ListItem key={item}>
              <Text variant="body2">{item}</Text>
            </ListItem>
          ))}
        </List>
      </Stack>
    </Stack>
  )
}
