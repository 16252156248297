import React, { FC } from 'react'
import { GridColDef } from '@mui/x-data-grid-premium'
import { ExternalLink } from '@howgood/design'

import { Report, selectReportsByType } from '@/state/organization'
import { HistoryTable } from './HistoryTable'
import { ReportHistoryCell, ReportHistoryCellProps } from './ReportHistoryCell'
import { DeleteReportCell, DeleteReportCellProps } from './DeleteReportCell'
import { useSelector } from 'react-redux'
import { selectVendorReports } from '@/state/vendor'
import { selectIsVendors } from '@/state/router'

/**
 * Handles the content of the Reports drawer from the Products page and also the Vendor page.
 */

export interface ReportWithDates extends Omit<Report, 'date_created' | 'date_modified'> {
  date_created: Date
  date_modified: Date
}

const vendorColumns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
  },
  {
    field: 'view',
    headerName: '',
    width: 100,
    renderCell: (params) => (
      <ExternalLink href={params.value?.url} target="_blank">
        View report
      </ExternalLink>
    ),
    sortable: false,
  },
]

const formulationColumns: GridColDef[] = [
  {
    field: 'date_created',
    headerName: 'Report Date',
    width: 200,
    renderCell: (params: ReportHistoryCellProps) => <ReportHistoryCell cellType="date" {...params} />,
    sortComparator: (a: ReportWithDates, b: ReportWithDates) => a.date_created.getTime() - b.date_created.getTime(),
  },
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
    renderCell: (params: ReportHistoryCellProps) => <ReportHistoryCell cellType="name" {...params} />,
    sortComparator: (a: ReportWithDates, b: ReportWithDates) => a.name.localeCompare(b.name),
  },
  {
    field: 'download',
    headerName: '',
    width: 15,
    renderCell: (params: ReportHistoryCellProps) => <ReportHistoryCell cellType="download" {...params} />,
    sortable: false,
  },
  {
    field: 'delete',
    headerName: '',
    width: 15,
    renderCell: (params: DeleteReportCellProps) => <DeleteReportCell {...params} />,
    sortable: false,
  },
]

export const ReportHistory: FC = () => {
  const formulationReports = useSelector(selectReportsByType)
  const isVendorPage = useSelector(selectIsVendors)
  const vendorReports = useSelector(selectVendorReports)

  const rows = isVendorPage
    ? vendorReports.map((report, index) => {
        return {
          ...report,
          id: index,
          name: report.name,
          view: report,
        }
      })
    : formulationReports.map((report) => {
        // Convert the date strings to Date objects so we can use the default sort comparator
        const reportWithDates: ReportWithDates = {
          ...report,
          date_created: new Date(report.date_created),
          date_modified: new Date(report.date_modified),
        }
        // Pass the full report object to each cell type so we have the file download link available in each
        return {
          id: report.id,
          date_created: reportWithDates,
          name: reportWithDates,
          download: reportWithDates,
          delete: reportWithDates,
        }
      })

  return <HistoryTable rows={rows} columns={isVendorPage ? vendorColumns : formulationColumns} />
}
