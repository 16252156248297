import { SalesCategory } from '@/state/salesCategories'

// the difference with FunctionalCategory is that SalesCategory contains title instead of name.
export const ALL_FOOD_INDUSTRY: SalesCategory = {
  date_created: null,
  date_modified: null,
  id: 0,
  title: 'All Food Industry',
  workflow_editor: null,
}

export interface SalesCategoryOption {
  value: string
  label: string
}

export interface FunctionalCategoryOption {
  value: number
  label: string
  name: string
}

export const ALL_FOOD_INDUSTRY_OPTION: SalesCategoryOption = {
  value: '',
  label: ALL_FOOD_INDUSTRY.title,
}

export const ALL_FUNCTIONAL_CATEGORY_OPTION: FunctionalCategoryOption = {
  value: 0,
  label: 'All Ingredient Categories',
  name: 'All Ingredient Categories',
}

export const NO_CATEGORY_OPTION: SalesCategoryOption | FunctionalCategoryOption = {
  value: null,
  label: 'No Category',
}
