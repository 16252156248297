import { getDateParts } from '@/utils/dates'
import { ExportType, ExportState } from './ExportDialog.types'

export function getDialogTitle(exportType: ExportType, exportStatus: ExportState): string {
  switch (exportStatus) {
    case 'Failed':
      return 'Export failed?'
    case 'Exporting':
      return exportType === 'download-scope3'
        ? 'Exporting Scope 3 Report'
        : exportType === 'save'
        ? 'Saving report'
        : 'Exporting product list'
    case 'Complete':
      return exportType === 'download-scope3'
        ? 'Report created'
        : exportType === 'save'
        ? 'Report saved'
        : 'Export complete'
    case 'Not started':
    default:
      return exportType === 'download-scope3'
        ? 'Export Scope 3 Report'
        : exportType === 'save'
        ? 'Save a report in Latis'
        : 'Export product list?'
  }
}

interface GetIntroText {
  exportType: ExportType
  productCount: number
  isProcurement: boolean
}

export function getIntroText({ exportType, productCount, isProcurement }: GetIntroText): string {
  const productType = isProcurement ? 'materials' : 'products'
  const prompt =
    exportType === 'save'
      ? 'Reports are saved as CSV (.csv) files and can be downloaded after creation.'
      : 'Do you want to continue?'
  return `You're about to export ${
    productCount === 10000 ? 'more than ' : ''
  } ${productCount.toLocaleString()} ${productType} based on the filters selected.
  ${productCount > 5000 ? 'This may take a few minutes. ' : ''}
  ${prompt}`
}

export function getExportButtonText(exportType: ExportType, exportStatus: ExportState): string {
  if (exportType === 'download-scope3') {
    return exportStatus === 'Not started' ? 'Export' : 'OK'
  }
  if (exportType === 'save') {
    return exportStatus === 'Not started' ? 'Save' : 'Done'
  }
  return 'OK'
}

export function getDefaultFileName(exportType?: ExportType): string {
  const { year, month, day } = getDateParts()
  return exportType === 'download-scope3'
    ? `Latis Scope 3 Report_${month}.${day}.${year}`
    : `Latis Portfolio Analysis_${month}.${day}.${year}`
}
