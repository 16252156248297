import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Dialog, DialogContent } from '@howgood/design'
import { selectSavedProductInfo } from '@/state/productOverview'
import { ProductChangeLog } from '../ProductChangeLog'

interface Props {
  open: boolean
  onClose: () => void
}

export const HistoryDialog: FC<Props> = ({ open, onClose }) => {
  const savedProduct = useSelector(selectSavedProductInfo)

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <ProductChangeLog product={{ id: savedProduct.id }} />
      </DialogContent>
    </Dialog>
  )
}
