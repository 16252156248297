import React, { FC, ReactNode } from 'react'
import {
  DataGridPremium,
  GridExcelExportOptions,
  GridExceljsProcessInput,
  useGridApiRef,
} from '@mui/x-data-grid-premium'
import { DataGridExportContainer } from '@/components'
import { addHGLogo, autoAdjustColWidths, formatHeaderRow, wrapText } from '@/utils/excelUtils'
import { useCarbonAccountingCategories, useFlagEmissionsTotal } from '../CarbonAccounting.hooks'
import { useCategoryData, useSummaryData } from './CarbonAccountingExport.hooks'

interface Props {
  reportId: number
  reportYear: number
  reportTitle: string
  scope3Total: number
  allEmissionsTotal: number
  children: (onClick: () => void) => ReactNode
}

export const CarbonAccountingExport: FC<Props> = ({
  reportId,
  reportYear,
  reportTitle,
  scope3Total,
  allEmissionsTotal,
  children,
}) => {
  const apiRef = useGridApiRef()

  const flagTotal = useFlagEmissionsTotal()
  const categories = useCarbonAccountingCategories(reportId)
  const summaryData = useSummaryData({
    reportYear,
    reportTitle,
    categories,
    scope3Total,
    flagTotal,
    allEmissionsTotal,
  })
  const scope1Data = useCategoryData({ category: categories[0] })
  const scope2Data = useCategoryData({ category: categories[1] })
  const scope3Category1Data = useCategoryData({ category: categories[2] })
  const scope3Category2Data = useCategoryData({ category: categories[3] })
  const scope3Category3Data = useCategoryData({ category: categories[4] })
  const scope3Category4Data = useCategoryData({ category: categories[5] })
  const scope3Category5Data = useCategoryData({ category: categories[6] })
  const scope3Category6Data = useCategoryData({ category: categories[7] })
  const scope3Category7Data = useCategoryData({ category: categories[8] })
  const scope3Category8Data = useCategoryData({ category: categories[9] })
  const scope3Category9Data = useCategoryData({ category: categories[10] })
  const scope3Category10Data = useCategoryData({ category: categories[11] })
  const scope3Category11Data = useCategoryData({ category: categories[12] })
  const scope3Category12Data = useCategoryData({ category: categories[13] })
  const scope3Category13Data = useCategoryData({ category: categories[14] })
  const scope3Category14Data = useCategoryData({ category: categories[15] })
  const scope3Category15Data = useCategoryData({ category: categories[16] })
  const detailSheetsData = [
    scope1Data,
    scope2Data,
    scope3Category1Data,
    scope3Category2Data,
    scope3Category3Data,
    scope3Category4Data,
    scope3Category5Data,
    scope3Category6Data,
    scope3Category7Data,
    scope3Category8Data,
    scope3Category9Data,
    scope3Category10Data,
    scope3Category11Data,
    scope3Category12Data,
    scope3Category13Data,
    scope3Category14Data,
    scope3Category15Data,
  ]

  async function exceljsPreProcess({ workbook, worksheet: sheet1 }: GridExceljsProcessInput) {
    sheet1.name = summaryData.name
    sheet1.addRows(summaryData.rows)
    detailSheetsData.forEach((sheetData) => {
      const sheet = workbook.addWorksheet(sheetData.name)
      sheet.addRows(sheetData.rows)
    })
    workbook.worksheets.forEach((worksheet) => {
      formatHeaderRow(worksheet, 1)
      autoAdjustColWidths(worksheet, 60)
      wrapText(worksheet)
    })
    addHGLogo({ worksheet: sheet1, workbook })
  }
  function exportData() {
    const exportApi = apiRef.current
    const options: GridExcelExportOptions = { fileName: 'Carbon Accounting Report', exceljsPreProcess }
    exportApi.exportDataAsExcel(options)
  }

  if (categories.length === 0) {
    return null
  }

  return (
    <>
      {children(exportData)}
      <DataGridExportContainer>
        <DataGridPremium apiRef={apiRef} columns={[]} />
      </DataGridExportContainer>
    </>
  )
}
