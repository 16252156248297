import React, { ChangeEventHandler, FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Text, TextField } from '@howgood/design'
import { RecipePackagingItem } from '@/state/recipe/recipe.state'
import { selectDisplayedPackaging, updateRecipeAndScores } from '@/state/recipe'
import { DRAFT, selectPageView } from '@/state/pageSettings'

function isValid(value: number | '', integer: boolean): boolean {
  return value !== '' && !isNaN(value) && value >= 0 && !(integer && value === 0)
}

export interface PackagingInputProps {
  field: 'consumer_units' | 'packaging_material_weight' | 'packaging_uses'
  packagingItem: RecipePackagingItem
  setTempItem: (item: RecipePackagingItem) => void
  integer?: boolean
}
export const PackagingInput: FC<PackagingInputProps> = ({ field, packagingItem, setTempItem, integer }) => {
  const dispatch = useDispatch()
  const packaging = useSelector(selectDisplayedPackaging)
  const view = useSelector(selectPageView)
  const [value, setValue] = useState<number | ''>(packagingItem[field] ? packagingItem[field] : integer ? 1 : 0)

  const handleBlurPackaging = (parsedValue: number | '') => {
    // Invalid entry
    if (!isValid(parsedValue, integer)) return

    if (setTempItem) {
      setTempItem({
        ...packagingItem,
        [field]: parsedValue,
      })
    } else {
      dispatch(
        updateRecipeAndScores({
          recipeUpdates: {
            packaging: packaging.map((item) => {
              if (item.id === packagingItem.id) {
                return {
                  ...item,
                  [field]: parsedValue,
                }
              }
              return item
            }),
          },
          change: `Changed packaging item ${field} to ${parsedValue}`,
        })
      )
    }
  }

  const handleChangePackaging: ChangeEventHandler<HTMLInputElement> = (e) => {
    const parsedValue = integer ? parseInt(e.target.value, 10) : parseFloat(e.target.value)

    // Clear field
    if (e.target.value === '') setValue('')
    // Invalid entry
    if (!isValid(parsedValue, integer)) return

    setValue(parsedValue)
  }

  if (view !== DRAFT) {
    return <Text data-testid="number-text">{packagingItem[field]}</Text>
  }

  return (
    <TextField
      type="number"
      value={value}
      color={isValid(value, integer) ? null : 'error'}
      onChange={handleChangePackaging}
      inputProps={{
        ['data-testid']: 'number-input',
        min: integer ? 1 : 0,
        step: integer ? 1 : 0.1,
        onBlur: () => handleBlurPackaging(value),
      }}
    />
  )
}
