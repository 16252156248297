import React, { FC } from 'react'
import { Icon, theme } from '@howgood/design'
import { createUseStyles } from 'react-jss'
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons'

interface ScorecardOverviewTitleProps {
  title: string
  url?: string
  width?: string
}

const useStyles = createUseStyles({
  overviewTitle: {
    fontFamily: theme.typography.display.fontFamily,
    fontWeight: theme.typography.display.fontWeight,
    fontSize: '22px',
    lineHeight: '26px',
    marginBottom: '12px',
  },
  link: {
    color: '#4d4e54',
    textDecoration: 'none',
  },
})

export const ScorecardOverviewTitle: FC<ScorecardOverviewTitleProps> = ({ title, url, width = 'unset' }) => {
  const classes = useStyles()

  return (
    <div className={classes.overviewTitle} style={{ width }}>
      {title}
      {url && (
        <a className={classes.link} href={url} target="_blank" rel="noreferrer">
          <Icon icon={faCircleInfo} />
        </a>
      )}
    </div>
  )
}
