import React, { FC } from 'react'
import { Card, Stack, Grid, Text } from '@howgood/design'

import { GridItemTitle } from '../GridItemTitle'
import { panelStyles } from '../panelStyles'
import { RequiresCooking } from '../SalesDistribution/RequiresCooking'
import { RequiresColdStorage } from '../SalesDistribution/RequiresColdStorage'
import { ManufacturingType } from '../SalesDistribution/ManufacturingType'
import { LocationAutocomplete } from '../SalesDistribution/LocationAutocomplete'
import { Standards } from './Standards'
import { UPC } from './UPC'
import { Workspaces } from '../Workspaces'

export const SupplierGeneral: FC = () => {
  return (
    <Grid container columnSpacing={1} rowSpacing={2} {...panelStyles.grid}>
      <Grid item xs={6}>
        <ManufacturingType />
      </Grid>
      <Grid item xs={6}>
        <GridItemTitle title="Manufacturing Region" tooltipText="Required for cradle-to-gate carbon metrics" />
        <LocationAutocomplete field="manufacturing_region" />
      </Grid>
      <Grid item xs={6}>
        <RequiresCooking />
      </Grid>
      <Grid item xs={6}>
        <RequiresColdStorage />
      </Grid>
      <Grid item xs={6}>
        <Standards />
      </Grid>
      <Grid item xs={6}>
        <UPC />
      </Grid>
      <Grid item xs={12}>
        <Card id="internal-ids-card">
          <Stack direction="row" alignItems="center" ml={1} mt={1}>
            <Text>Internal IDs</Text>
          </Stack>
          <Workspaces />
        </Card>
      </Grid>
    </Grid>
  )
}
