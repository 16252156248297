import React, { FC, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { Button, Icon } from '@howgood/design'
import { faEllipsis } from '@fortawesome/pro-regular-svg-icons'

interface LabelButtonsProps {
  labels: string[]
  onSelect: (label: string) => void
}

const useStyles = createUseStyles({
  wrapper: {
    display: 'flex',
    marginBottom: 10,
  },
  labelButton: {
    marginRight: 8,
  },
})

const LabelButtons: FC<LabelButtonsProps> = ({ labels, onSelect }) => {
  const classes = useStyles()

  const [isExpanded, setExpanded] = useState(false)

  return (
    <div className={classes.wrapper}>
      {labels.slice(0, isExpanded ? undefined : 3).map((label) => (
        <div key={label} className={classes.labelButton}>
          <Button onClick={() => onSelect(label)}>Add all {label}s</Button>
        </div>
      ))}
      {!isExpanded && labels.length > 3 && (
        <div className={classes.labelButton}>
          <Button onClick={() => setExpanded(true)}>
            <Icon icon={faEllipsis} />
          </Button>
        </div>
      )}
    </div>
  )
}

export default LabelButtons
