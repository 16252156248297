import { AnyAction } from 'redux'
import { batch } from 'react-redux'
import { createAsyncThunk, ThunkAction } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'
import isEqual from 'lodash/isEqual'
import {
  InventoryData,
  updateProductAndScores,
  InventoriesResponse,
  fetchIngredientsByBaseIds,
  patchProductInventory,
  updateProduct,
  fetchHelperV2,
  fetchRegionsByIds,
  ImpactScore,
  MATERIAL_TYPE_VENDOR_VERIFIED_PLUS,
  MATERIAL_TYPE_VENDOR_VERIFIED,
} from '@/api'
import {
  formulationStatuses,
  IngredientSearchOption,
  IProduct,
  IProductStandard,
  MATERIAL_DIRECTORY,
  PatchProductData,
  ScenarioProductAttributes,
  UNKNOWN_VENDOR_OPTION,
  UNKNOWN_VENDOR_OPTION_VALUE,
  WorkspaceType,
} from '@/records'
import { AppState } from '@/store'
import { fetchAllProducts, fetchFormulationSearchOptionsById } from '@/api/elastic/productsEsRepo'
import getPageNo from '@/utils/getPageNo'
import {
  selectDefaultWorkspaceIds,
  selectWorkspacesIds,
  selectAllWorkspacesOptions,
} from '../workspaces/workspaces.selectors'
import { createFormulationsSearchParams, getFormulationsFiltersParams } from '@/utils/getUrl'
import {
  ActiveBulkJob,
  DataGranularOption,
  DataGranularOptionValue,
  initialState,
  ProductFilters,
} from './products.state'
import { resetTableProducts, setShowAtRiskOnly, setShowLiveImpactData, updateProductFilters } from './products.slice'
import {
  selectFormulaQuery,
  selectWorkspaceProductFilterIds,
  selectStatusFilters,
  selectComponentFilters,
  selectTagFilters,
  selectIngredientFilters,
  selectProductsSortOptions,
  selectProductsPage,
  selectWorkspaceTypeFilters,
  selectShowAtRiskOnly,
  selectShowMaterialDirectory,
  selectWorkspaceProductFilters,
  selectVendorFilters,
  selectStandardsFilters,
  selectRegionsFilters,
  selectDataGranularityFilters,
  selectTableProducts,
  selectShowLiveImpactData,
} from './products.selectors'
import { fetchBaseByIds } from '@/utils/ingredientSearch'
import { selectCanViewPublicIngredients } from '../user/user.selectors'
import { fetchFormulationTagsByIds } from '@/utils/formulationTagsSearch'
import { resetProductMetricsState, selectProductMetrics } from '../productMetrics'
import { selectIsInitiative, selectIsPortfolio, selectIsProcurement } from '../router'
import { OrgUser } from '../organization'
import { getWorkspaceImpactScores } from '../workspaces'
import { selectCurrentScenarioId, setCurrentScenarioAndInitiative } from '../initiatives'
import { fetchBrandsByIds } from '@/api/elastic/brandsEsRepo'
import { addMessage } from '../messages'
import { FetchProducts, fetchProductsAndRollups, fetchUpdateScenarioProduct } from './products.requests'
import {
  selectProductsAggregation,
  selectProductsBoolQuery,
  selectProductsRuntimeMappings,
} from '@/selectors/selectProductsRequestItems'
import { DEFAULT_PAGE_SIZE } from '@/constants/config'
import { SCOPE_3_CATEGORY_1_FIELD, SCOPE_3_CATEGORY_4_FIELD, SCOPE_3_CONTRIBUTION_FIELD } from '@/constants/impactScore'
import { scope3Cat1Metric, scope3Cat4Metric } from '@/api/elastic/runtimeMappings'
import {
  MATERIAL_GRANULARITY_KNOWN_VENDORS,
  MATERIAL_GRANULARITY_UNKNOWN_VENDORS,
  MATERIAL_GRANULARITY_VENDOR_VERIFIED,
} from '@/features/ProductPage/panels/ImpactReduction/ImpactReduction.hooks'
import { ProductListDataRowExport } from '@/utils/products'
import { prepareLockedScoreData } from '@/components/Products/utils/prepareLockedScoreData'
import { Dispatch, SetStateAction } from 'react'

export const DATA_GRANULAR_VALUE_KNOWN_VENDORS = 'known-vendors' as DataGranularOptionValue
const DATA_GRANULAR_VALUE_UNKNOWN_VENDORS = 'unknown-vendors' as DataGranularOptionValue

export const DATA_GRANULAR_OPTIONS: DataGranularOption[] = [
  { label: 'Vendor Verified (with ag practices)', value: MATERIAL_TYPE_VENDOR_VERIFIED_PLUS },
  { label: MATERIAL_GRANULARITY_VENDOR_VERIFIED, value: MATERIAL_TYPE_VENDOR_VERIFIED },
  { label: MATERIAL_GRANULARITY_KNOWN_VENDORS, value: DATA_GRANULAR_VALUE_KNOWN_VENDORS },
  { label: MATERIAL_GRANULARITY_UNKNOWN_VENDORS, value: DATA_GRANULAR_VALUE_UNKNOWN_VENDORS },
]

interface GetAllProducts {
  signal: AbortSignal
  sendCount: Dispatch<SetStateAction<{ count: number; total: number }>>
}
export const getAllProducts = createAsyncThunk<ProductListDataRowExport[], GetAllProducts, { state: AppState }>(
  'products/getAllProducts',
  async ({ signal, sendCount }, { getState }) => {
    const state = getState()
    const isProcurement = selectIsProcurement(state)
    const showLiveData = selectShowLiveImpactData(state)
    const scenarioId = selectCurrentScenarioId(state)
    const userWorkspacesOptions = selectAllWorkspacesOptions(state)
    const { boolQuery } = selectProductsBoolQuery(state)
    const runtimeMappings = selectProductsRuntimeMappings([], scenarioId)(state)
    const sortOptions = selectProductsSortOptions(state)

    const payload = {
      boolQuery,
      runtimeMappings,
      sortOptions,
      signal,
      sendCount,
      batchSize: 250,
    }
    const data = await fetchAllProducts(payload)

    if (!data || !data.hits) {
      return []
    }
    const { hits: productsForExport, custom_aggs } = data

    return prepareLockedScoreData(productsForExport, custom_aggs, isProcurement, showLiveData, userWorkspacesOptions)
  }
)

export const getProducts = createAsyncThunk<
  FetchProducts,
  { size?: number; fetchRollups?: boolean },
  { state: AppState }
>(
  'products/getProducts',
  async (
    { size = DEFAULT_PAGE_SIZE, fetchRollups = true } = { size: DEFAULT_PAGE_SIZE, fetchRollups: true },
    { getState }
  ) => {
    const state = getState()
    const { boolQuery, workspaceIds, scenario } = selectProductsBoolQuery(state)
    const runtimeMappings = selectProductsRuntimeMappings([], scenario)(state)
    const sortOptions = selectProductsSortOptions(state)
    const page = selectProductsPage(state)
    const aggs = selectProductsAggregation(state)

    if (!workspaceIds.length && !scenario) {
      return { rollups: { metrics: {}, goals: {} }, total: 0, hits: [], custom_aggs: {} }
    }
    // Specify each of the required aggregations and TODO get the goals from the inventories
    const customFields = runtimeMappings
      ? Object.keys(runtimeMappings).reduce((acc, field) => {
          return {
            ...acc,
            [`${field}_sum`]: { sum: { field } },
            [`${field}_avg`]: { avg: { field } },
            [`${field}_count`]: { value_count: { field } },
          }
        }, {})
      : {}

    let products = await fetchProductsAndRollups({
      page,
      boolQuery,
      runtimeMappings,
      sortOptions,
      aggs: fetchRollups ? { ...aggs, ...customFields } : {},
      size: size as number,
    })

    // Calculate the scope 3 contribution for each product and insert it here
    const productsWithCustomFields = {
      ...products,
      hits: products.hits.map((hit) => ({
        ...hit,
        custom_fields: {
          ...hit.custom_fields,
          [SCOPE_3_CONTRIBUTION_FIELD]:
            hit.custom_fields?.scope_3_total_annual && products.custom_aggs?.scope_3_total_annual
              ? hit.custom_fields.scope_3_total_annual / products.custom_aggs.scope_3_total_annual
              : 0,
        },
      })),
    }
    products = productsWithCustomFields

    if (!size) {
      // in this case we are just fetching the agregate data
      return { rollups: products.rollups }
    }
    if (!fetchRollups) {
      // in this case we are just fetching the product list
      return { total: products.total, hits: products.hits, custom_aggs: products.custom_aggs }
    }
    return products
  }
)

interface RedirectToProduct {
  id: string
}
/**
 * Temporarily written as an async thunk to expose `<thunk>.typePrefix`
 * for the legacy `fetching` reducer.
 *
 * TODO: rewrite as utility function
 */
export const redirectToProduct = createAsyncThunk<void, RedirectToProduct>(
  'products/redirectToProduct',
  async ({ id }) => {
    const page = getPageNo()
    let pagePath = ''
    if (page && page === 'formulations') {
      pagePath = 'formulations'
    } else if (page) {
      pagePath = `step-${getPageNo()}`
    }
    window.history.pushState({}, '', `/products/${id}/${pagePath}`)
  }
)

interface UpdateProductInventories {
  product_id: number
  inventory_ids: number[]
  data: InventoryData
  scenarioId?: number
  productName?: string
}

interface UpdateProductInventoriesResponse {
  productId: number
  updatedInventories: InventoriesResponse[]
}

// Makes a PATCH request to update each of the specified inventories. To update a single inventory, send a list with one ID.
export const updateProductInventories = createAsyncThunk<UpdateProductInventoriesResponse, UpdateProductInventories>(
  'products/updateProductInventories',
  async ({ product_id, inventory_ids, data, scenarioId = null, productName = null }, { dispatch }) => {
    const responses = await Promise.allSettled(
      inventory_ids.map((inventory_id) => {
        return patchProductInventory({ product_id, inventory_id, data, scenarioId })
      })
    )
    const updatedInventories = [] as InventoriesResponse[]
    let hasError = false
    responses.forEach((inventoryResponse, idx) => {
      if (inventoryResponse.status === 'fulfilled') {
        updatedInventories.push(inventoryResponse.value)
      } else {
        hasError = true
        Sentry.captureException(new Error('Issue updating product inventory'), {
          extra: { product_id, err: inventoryResponse.reason, inventory: inventory_ids[idx] },
        })
      }
    })
    if (hasError) {
      dispatch(
        addMessage({
          severity: 'error',
          message: `There was an issue updating ${productName ? productName : 'the product inventory'}.`,
        })
      )
    }
    return { productId: product_id, updatedInventories }
  }
)

interface UpdateProductScenarioData {
  product: IProduct
  data: ScenarioProductAttributes
  scenarioId: number
}
export const updateProductScenarioData = createAsyncThunk<IProduct[], UpdateProductScenarioData, { state: AppState }>(
  'products/updateProductScenarioData',
  async ({ product, data, scenarioId }, { getState }) => {
    const products = selectTableProducts(getState())
    await fetchUpdateScenarioProduct(scenarioId, product.id, data)
    return products.map((p) => {
      if (p.id === product.id) {
        return {
          ...p,
          scenarios: p.scenarios.map((s) => {
            if (s.id === scenarioId) {
              return {
                ...s,
                product: data,
              }
            }
            return s
          }),
        }
      }
      return p
    })

    // TODO: we need to do this is we reintroduce rollup goals
    // if (!isEmpty(rollupGoals)) {
    //   // get current sum goal before updating count
    //   const sumGoal = rollupGoals[`${field}_count`] * rollupGoals[field]
    //   if (!current && current !== 0) {
    //     // If we're updating the goal for the first time, we need to increment the product count
    //     rollupGoals[`${field}_count`]++
    //   } else if (!data.goals[field] && data.goals[field] !== 0) {
    //     // If we're removing the goal, we need to decrement the product count
    //     rollupGoals[`${field}_count`]--
    //   }
    //   const currentProductGoal = current || 0
    //   const updatedProdGoal = data.goals[field] || 0
    //   const newSumGoal = sumGoal - currentProductGoal + updatedProdGoal
    //   const rollupGoal = newSumGoal / rollupGoals[`${field}_count`]
    //   rollupGoals[field] = rollupGoal
    //   dispatch(setProductGoals(rollupGoals))
    // }
  }
)

const fieldsThatRelyOnMtPerYr = [SCOPE_3_CATEGORY_1_FIELD, SCOPE_3_CATEGORY_4_FIELD]
export const updateProductScenarioMTperYr = createAsyncThunk<
  { updatedProducts: IProduct[]; updatedRollupData: ImpactScore },
  { productId: number; data: ScenarioProductAttributes; scenarioId: number },
  { state: AppState }
>('products/updateProductScenarioMTperYr', async ({ productId, data, scenarioId }, { getState }) => {
  const products = selectTableProducts(getState())
  const rollupData = selectProductMetrics(getState())
  await fetchUpdateScenarioProduct(scenarioId, productId, data)
  const updatedRollupData = { ...rollupData }
  let previous_mt_per_yr = null
  let updatedProducts = products.map((p) => {
    if (p.id === productId) {
      const custom_fields = { ...p.custom_fields }
      previous_mt_per_yr = p.custom_fields.mt_per_year
      // update the product with the new mt_per_year
      const scenarios = p.scenarios.map((s) => {
        if (s.id === scenarioId) {
          // get the most recent mt_per_year value, from custom field OR from product scenario
          previous_mt_per_yr =
            s.product?.mt_per_year !== null && s.product?.mt_per_year !== undefined
              ? s.product?.mt_per_year
              : previous_mt_per_yr
          return {
            ...s,
            product: data,
          }
        }
        return s
      })
      // if updating a product that already had mtperyr set
      if (previous_mt_per_yr) {
        fieldsThatRelyOnMtPerYr.forEach((field) => {
          if (p.custom_fields && Object.hasOwn(p.custom_fields, field)) {
            // update Scope3 fields in product table
            custom_fields[field] = (p.custom_fields[field] / previous_mt_per_yr) * data.mt_per_year
            // update scope3 fields in rollup data
            const newRollupSum = (rollupData[`${field}_sum`] || 0) - p.custom_fields[field] + custom_fields[field]
            const newRollupAvg = rollupData[`${field}_count`]
              ? newRollupSum / rollupData[`${field}_count`]
              : newRollupSum
            updatedRollupData[`${field}_sum`] = newRollupSum
            updatedRollupData[`${field}_avg`] = newRollupAvg
            updatedRollupData[`${field}_count`] = rollupData[`${field}_count`] ?? 1
          }
        })
      } else {
        // if adding MtperYr for the first time, calculate new scope3 values from impact score metric values
        custom_fields[SCOPE_3_CATEGORY_1_FIELD] = data.mt_per_year * p.impact_score?.[scope3Cat1Metric] || 0
        custom_fields[SCOPE_3_CATEGORY_4_FIELD] = data.mt_per_year * p.impact_score?.[scope3Cat4Metric] || 0

        fieldsThatRelyOnMtPerYr.forEach((field) => {
          // update scope3 fields in rollup data
          const newRollupSum = (rollupData[`${field}_sum`] || 0) + custom_fields[field]
          const newRollupAvg = rollupData[`${field}_count`] ? newRollupSum / rollupData[`${field}_count`] : newRollupSum
          updatedRollupData[`${field}_sum`] = newRollupSum
          updatedRollupData[`${field}_avg`] = newRollupAvg
          updatedRollupData[`${field}_count`] = rollupData[`${field}_count`] ?? 1
        })
      }
      // lastly now that we know the updated rollup and product scores, update the total anual and contribution %
      custom_fields.scope_3_total_annual =
        custom_fields[SCOPE_3_CATEGORY_1_FIELD] + custom_fields[SCOPE_3_CATEGORY_4_FIELD]

      updatedRollupData.scope_3_total_annual_sum =
        updatedRollupData[`${SCOPE_3_CATEGORY_1_FIELD}_sum`] + updatedRollupData[`${SCOPE_3_CATEGORY_4_FIELD}_sum`]

      updatedRollupData.scope_3_total_annual_count =
        updatedRollupData[`${SCOPE_3_CATEGORY_1_FIELD}_count`] +
          updatedRollupData[`${SCOPE_3_CATEGORY_4_FIELD}_count`] || 1

      updatedRollupData.scope_3_total_annual_avg =
        updatedRollupData.scope_3_total_annual_sum / updatedRollupData.scope_3_total_annual_count

      custom_fields[SCOPE_3_CONTRIBUTION_FIELD] =
        custom_fields.scope_3_total_annual && updatedRollupData.scope_3_total_annual_sum
          ? custom_fields.scope_3_total_annual / updatedRollupData.scope_3_total_annual_sum
          : 0

      return {
        ...p,
        custom_fields,
        scenarios,
      }
    }
    return p
  })
  return {
    updatedProducts: updatedProducts.map((p) => {
      // Since the total scope3 has changed, we need to update the contribution % for each product
      const contribution =
        p.custom_fields.scope_3_total_annual && updatedRollupData.scope_3_total_annual_sum
          ? p.custom_fields.scope_3_total_annual / updatedRollupData.scope_3_total_annual_sum
          : 0
      return {
        ...p,
        custom_fields: { ...p.custom_fields, [SCOPE_3_CONTRIBUTION_FIELD]: contribution },
      }
    }),
    updatedRollupData,
  }
})

interface PatchProduct {
  id: number
  data: PatchProductData
}
export const patchProduct = createAsyncThunk<void, PatchProduct, { state: AppState }>(
  'products/patchProduct',
  async ({ id, data }) => {
    await updateProductAndScores({ id, data })
  }
)

interface UpdateAssignee {
  id: number
  data: PatchProductData
}

export const updateAssignee = createAsyncThunk<{ id: number; assignee: OrgUser }, UpdateAssignee, { state: AppState }>(
  'products/updateAssignee',
  async ({ id, data }) => {
    await updateProduct({ id, data })
    // Don't use the assignee from the response because it's not the full user object
    return { id, assignee: data.assignee }
  }
)

export const initializeProductFilters = createAsyncThunk<Partial<ProductFilters>, number | void, { state: AppState }>(
  'products/initializeProductFilters',
  async (scenarioId = null, { getState }) => {
    const state = getState()
    const canViewPublicIngredients = selectCanViewPublicIngredients(state)
    const userWorkspaceOptions = selectAllWorkspacesOptions(state)
    const workspaceIds = selectWorkspacesIds(state)
    const defaultWorkspaces = selectDefaultWorkspaceIds(state)

    const params = getFormulationsFiltersParams()
    const workspaces = userWorkspaceOptions.filter((uws) => params.workspaces.includes(uws.id.toString()))
    const statuses = params.statuses?.length
      ? formulationStatuses.filter((st) => params.statuses.includes(st.value))
      : initialState.productFilters.statuses
    let { components, vendors, ingredientIds, ingredients, formulationTags, regions } = initialState.productFilters

    if (params.components?.length) {
      const componentIds = params.components.map((id) => parseInt(id, 10))
      components = await fetchFormulationSearchOptionsById(componentIds)
    }

    if (params.vendors?.length) {
      const includeUnknownVendors = params.vendors.some((v) => v === UNKNOWN_VENDOR_OPTION_VALUE)
      let { vendors: paramVendors } = params
      if (includeUnknownVendors) {
        paramVendors = paramVendors.filter((v) => v !== UNKNOWN_VENDOR_OPTION_VALUE)
      }
      const vendorIds = paramVendors.map((id) => parseInt(id, 10))
      vendors = await fetchBrandsByIds(vendorIds)
      if (includeUnknownVendors) {
        vendors = [...vendors, UNKNOWN_VENDOR_OPTION]
      }
    }

    if (params.ingredients?.length) {
      const hits: object[] = await fetchBaseByIds({
        ids: params.ingredients,
        defaultWorkspaceIds: defaultWorkspaces,
        ownWorkspacesIds: workspaceIds,
        canViewPublicIngredients,
      })

      ingredients = hits.map((hit) => {
        const ingredient = new IngredientSearchOption(hit).raw._source
        return { ...ingredient, label: ingredient.name, value: ingredient.id }
      })

      const ingredientsTmp = await fetchIngredientsByBaseIds(params.ingredients)
      ingredientIds = ingredientsTmp.map((ingredient) => ingredient._source.id)
    }

    if (params.formulationTags?.length) {
      formulationTags = await fetchFormulationTagsByIds({
        filters: {
          ids: params.formulationTags,
          workspaceIds,
        },
      })
    }

    if (params.regionIds?.length) {
      regions = await fetchRegionsByIds(params.regionIds)
    }

    return {
      workspaces,
      query: params.query?.length ? params.query : initialState.productFilters.query,
      statuses,
      components,
      vendors,
      formulationTags,
      ingredients,
      ingredientIds,
      salesCategories: params.salesCategories.length
        ? params.salesCategories
        : initialState.productFilters.salesCategories,
      workspaceTypes: params.workspaceTypes?.length
        ? params.workspaceTypes
        : initialState.productFilters.workspaceTypes,
      sortOptions: params.sortOptions ? params.sortOptions : initialState.productFilters.sortOptions,
      page: +params.page || initialState.productFilters.page,
      scenario: scenarioId ? scenarioId : null,
      standardsIds: params.standardsIds.length ? params.standardsIds : initialState.productFilters.standardsIds,
      regions: regions.length ? regions : initialState.productFilters.regions,
      dataGranulars: params.dataGranularValues.length
        ? params.dataGranularValues.map((value) => {
            const label = DATA_GRANULAR_OPTIONS.find((option) => option.value === value)
            return label ? label : ({ label: value, value } as DataGranularOption)
          })
        : initialState.productFilters.dataGranulars,
    }
  }
)

export const initializeProductsPage = createAsyncThunk<void, Partial<ProductFilters> | void, { state: AppState }>(
  'products/initializeProductsPage',
  async (filterUpdates, { dispatch }) => {
    batch(() => {
      dispatch(resetTableProducts())
      dispatch(resetProductMetricsState())
      dispatch(setCurrentScenarioAndInitiative())
      dispatch(setShowLiveImpactData(false))
    })
    await dispatch(initializeProductFilters())
    if (filterUpdates) {
      dispatch(updateProductFilters(filterUpdates))
    }
    dispatch(getProducts({}))
  }
)

export const updateFiltersUrlAndProducts = ({
  updatedFilters,
  fetchRollups = true,
}): ThunkAction<
  Promise<void>,
  AppState,
  { updatedFilters: Partial<ProductFilters>; fetchRollups: boolean },
  AnyAction
> => async (dispatch, getState) => {
  // must dispatch this before getState
  const previousTagFilters = selectTagFilters(getState())
  if (!updatedFilters.page) {
    updatedFilters.page = 1
  }
  dispatch(updateProductFilters(updatedFilters))
  const state = getState()
  const page = selectProductsPage(state)
  const isPortfolio = selectIsPortfolio(state)
  const currentTagFilters = selectTagFilters(state)
  const isInitiative = selectIsInitiative(state)

  // Fetch products and rollup productMetrics
  batch(() => {
    // Optimization to only re-fetch scores if needed
    if (isPortfolio && !isEqual(currentTagFilters, previousTagFilters)) {
      dispatch(getWorkspaceImpactScores())
    }
    dispatch(getProducts({ size: isPortfolio ? 0 : undefined, fetchRollups: fetchRollups && !isInitiative }))
  })

  if (!isPortfolio) {
    const workspaces = selectWorkspaceProductFilterIds(state)
    const queryStrings = selectFormulaQuery(state)
    const statuses = selectStatusFilters(state)
    const components = selectComponentFilters(state)
    const vendors = selectVendorFilters(state)
    const formulationTags = selectTagFilters(state)
    const ingredients = selectIngredientFilters(state)
    const sortOptions = selectProductsSortOptions(state)
    const workspaceTypes = selectWorkspaceTypeFilters(state)
    const standardsIds = selectStandardsFilters(state)
    const regions = selectRegionsFilters(state)
    const dataGranulars = selectDataGranularityFilters(state)

    // Update the url
    window.history.pushState(
      {},
      '',
      createFormulationsSearchParams({
        workspaces: workspaces,
        statuses: statuses.map((status: { value: any }) => status.value),
        query: queryStrings,
        ingredients: ingredients?.length ? ingredients.map((option) => `${option.id}`) : null,
        components: components?.length ? components.map((option) => option.value) : undefined,
        vendors: vendors?.length ? vendors.map((option) => option.value) : undefined,
        page: page,
        sortOptions: sortOptions,
        formulationTags: formulationTags.length ? formulationTags.map((option) => option.id.toString()) : null,
        workspaceTypes,
        standardsIds,
        regions: regions.map((region) => region.id),
        dataGranulars: dataGranulars.map((granular) => granular.value),
      })
    )
  }
}

export const toggleAtRisk = (): ThunkAction<void, AppState, void, AnyAction> => (dispatch, getState) => {
  const state = getState()
  const showAtRisk = selectShowAtRiskOnly(state)
  dispatch(setShowAtRiskOnly(!showAtRisk))
  dispatch(getProducts({}))
}

export const toggleMaterialDirectory = (): ThunkAction<
  void,
  AppState,
  { updatedFilters: Partial<ProductFilters> },
  AnyAction
> => (dispatch, getState) => {
  const state = getState()
  const showMaterialDirectory = selectShowMaterialDirectory(state)
  const workspaceFilters = selectWorkspaceProductFilters(state)
  const workspaceTypeFilters = selectWorkspaceTypeFilters(state)

  let updatedWorkspaceTypes: WorkspaceType[]
  if (showMaterialDirectory) {
    updatedWorkspaceTypes = workspaceTypeFilters.filter((wType) => wType !== MATERIAL_DIRECTORY)
  } else {
    updatedWorkspaceTypes = workspaceTypeFilters.concat(MATERIAL_DIRECTORY)
  }
  const updatedWorkspaces = workspaceFilters.filter((ws) => updatedWorkspaceTypes.includes(ws.workspace_type))

  dispatch(
    updateFiltersUrlAndProducts({
      updatedFilters: { workspaceTypes: updatedWorkspaceTypes, workspaces: updatedWorkspaces },
    })
  )
}

export interface UpdateScenarioProductStandards {
  productIds: number[]
  standards: IProductStandard[]
  scenarioId: number
}

export const updateScenarioProductStandards = createAsyncThunk<
  ActiveBulkJob,
  UpdateScenarioProductStandards,
  { state: AppState }
>('products/updateScenarioProductStandards', async ({ productIds, standards, scenarioId }) => {
  const response = await fetchHelperV2<string>({
    url: `scenarios/${scenarioId}/products/standards/`,
    method: 'POST',
    body: JSON.stringify({
      products: productIds.map((id) => ({ id })),
      standards: standards,
    }),
  })
  return {
    productIds,
    jobId: response,
  }
})
