import React, { FC } from 'react'
import { Stack, Tooltip, Text, MaterialTypeIcon, Box } from '@howgood/design'
import { ContentfulFormulaIconKey, CONTENTFUL_FORMULA_ICON_TO_MATERIAL_TYPE_ICON, RichText } from '@/contentfully'
import { useContentfulFormulaIcon } from '@/contentfully/hooks'

interface FormulaIconBadgeProps {
  badge: ContentfulFormulaIconKey
  noMargin?: boolean
  noTooltip?: boolean
}

export const FormulaIconBadge: FC<FormulaIconBadgeProps> = ({ badge, noMargin, noTooltip }) => {
  const data = useContentfulFormulaIcon(badge)

  if (!data) {
    return null
  }

  const icon = (
    <Box mx={noMargin ? 0 : 0.5} display="flex" alignItems="center">
      <MaterialTypeIcon type={CONTENTFUL_FORMULA_ICON_TO_MATERIAL_TYPE_ICON[badge]} />
    </Box>
  )

  return !noTooltip ? (
    <Tooltip
      maxWidth={360}
      title={
        <Stack gap={1} direction="row">
          <MaterialTypeIcon type={CONTENTFUL_FORMULA_ICON_TO_MATERIAL_TYPE_ICON[badge]} size="large" />
          <Stack
            sx={{
              '& > p:last-child': {
                marginBottom: 0, // this needs for RichText last paragraph
              },
            }}
          >
            <Text variant="body2" fontWeight={600}>
              {data.title}
            </Text>
            <RichText document={data.description} />
          </Stack>
        </Stack>
      }
    >
      {icon}
    </Tooltip>
  ) : (
    icon
  )
}
