const vendorVerifiedWorkflowTags = ['vendor_verified']
const vendorVerifiedPlusWorkflowTags = ['agpractice', 'keypractices']

// On the procurement page ALL products are in procurement workspaces. A purchased
// product in general is a product that is either in a procurement workspace
// OR has a non null MT/yr. Additionally it cannot have the below workflow_tags
//  NOT a third party workspace, not vender verified and not vender verified plus.
const generatePurchaseProductsBoolQuery = (thirdPartyWorkspaces: number[], includeVendorVerifiedPlus: boolean) => ({
  must_not: [
    {
      terms: {
        workflow_tags: includeVendorVerifiedPlus
          ? [...vendorVerifiedWorkflowTags, ...vendorVerifiedPlusWorkflowTags]
          : vendorVerifiedWorkflowTags,
      },
    },
    {
      terms: {
        'workspaces.id': thirdPartyWorkspaces,
      },
    },
  ],
})

// Purchased product with brand field defined
export const generateKnownVendorsFilter = (thirdPartyWorkspaces: number[], includeVendorVerifiedPlus: boolean) => {
  const purchaseProductsBoolQuery = generatePurchaseProductsBoolQuery(thirdPartyWorkspaces, includeVendorVerifiedPlus)
  return {
    bool: {
      ...purchaseProductsBoolQuery,
      must: [
        {
          exists: {
            field: 'brand.id',
          },
        },
      ],
      must_not: [
        ...purchaseProductsBoolQuery.must_not,
        {
          match_phrase: {
            'brand.name.keyword': 'unknown',
          },
        },
        {
          terms: {
            'brand.name.keyword': ['', 'unknown'],
          },
        },
      ],
    },
  }
}

// Purchased product with brand field undefined (null or '' or 'unknown')
export const generateUnKnownVendorsFilter = (thirdPartyWorkspaces: number[], includeVendorVerifiedPlus: boolean) => {
  const purchaseProductsBoolQuery = generatePurchaseProductsBoolQuery(thirdPartyWorkspaces, includeVendorVerifiedPlus)
  return {
    bool: {
      ...purchaseProductsBoolQuery,
      should: [
        {
          bool: {
            must_not: {
              exists: {
                field: 'brand.name.keyword',
              },
            },
          },
        },
        {
          bool: {
            must: {
              terms: {
                'brand.name.keyword': ['', 'unknown'],
              },
            },
          },
        },
        {
          match_phrase: {
            'brand.name.keyword': 'unknown',
          },
        },
      ],
      minimum_should_match: 1,
    },
  }
}

//  third party workspace OR 'vendor_verified' workflow tag is attached
export const generateVendorVerifiedFilter = (thirdPartyWorkspaces: number[]) => ({
  bool: {
    should: [
      {
        terms: {
          workflow_tags: vendorVerifiedWorkflowTags,
        },
      },
      {
        terms: {
          'workspaces.id': thirdPartyWorkspaces,
        },
      },
    ],
    minimum_should_match: 1,
  },
})

//  'agpractice' OR 'keypractices' workflow tag is attached
export const vendorVerifiedPlusFilter = {
  bool: {
    must: [
      {
        terms: {
          workflow_tags: vendorVerifiedPlusWorkflowTags,
        },
      },
    ],
  },
}
