import React, { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Text,
  Stack,
  Alert,
  AlertTitle,
} from '@howgood/design'
import { AppState } from '@/store'
import {
  addNewScenario,
  selectCurrentInitiative,
  selectCurrentInitiativeBaseline,
  selectCurrentInitiativeScenarios,
} from '@/state/initiatives'
import { NewScenarioFields } from './NewScenarioFields'
import { Report } from '@/state/organization'
import { Scenario } from '@/records/Initiatives'
import { getScenarioYearAsNumber } from '@/state/initiatives/initiatives.utils'

// currently blocking a user from creating 2 scenarios with the same year.
// ALSO want the default year to be 1 year after the last in the scenario for now
const selectYearOptions = createSelector<AppState, Report, Scenario[], { yearOptions: number[]; defaultYear: number }>(
  selectCurrentInitiativeBaseline,
  selectCurrentInitiativeScenarios,
  (baseline, scenarios) => {
    const allYears = Array.from({ length: 15 }, (_value, index) => new Date().getFullYear() + 1 + index)
    const currentScenarioDates = baseline?.date_created ? [getScenarioYearAsNumber(baseline.date_created)] : []
    scenarios.forEach((scenario) => {
      currentScenarioDates.push(getScenarioYearAsNumber(scenario.target_date))
    })
    const lastScenarioYear = currentScenarioDates[currentScenarioDates.length - 1]
    const yearOptions = allYears.filter((yr) => !currentScenarioDates.includes(yr))
    const defaultYear = yearOptions.includes(lastScenarioYear + 1)
      ? lastScenarioYear + 1
      : yearOptions[yearOptions.length - 1]
    return { yearOptions, defaultYear }
  }
)

interface Props {
  closeDialog: () => void
}

export const CreateScenarioDialog: FC<Props> = ({ closeDialog }) => {
  const dispatch = useDispatch()
  const initiative = useSelector(selectCurrentInitiative)
  const { yearOptions, defaultYear } = useSelector(selectYearOptions)

  const [name, setName] = useState('')
  const [targetYear, setTargetYear] = useState(defaultYear)

  const handleOKClicked = () => {
    dispatch(
      addNewScenario({
        name,
        target_date: targetYear,
      })
    )
    closeDialog()
  }

  return (
    <Dialog maxWidth="xs" id="create-scenario-dialog" data-testid="create-scenario-dialog" open onClose={closeDialog}>
      <DialogTitle>Create a new Scenario</DialogTitle>
      <DialogContent>
        <Stack gap={2}>
          <Text>
            This will create a new scenario within initiative <b>{initiative.name}</b>
          </Text>
          <NewScenarioFields
            scenarioName={name}
            setScenarioName={setName}
            targetYear={targetYear}
            setTargetYear={setTargetYear}
            yearOptions={yearOptions}
          />
          <Alert severity="info">
            <Stack gap={1}>
              <AlertTitle>Scenario products use live formula data</AlertTitle>
              <Text>
                Creating a new scenario reflects current live formula metrics, which may differ from the baseline if
                your product formulas have changed since the initiative&apos;s creation.
              </Text>
            </Stack>
          </Alert>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack width="100%" direction="row" justifyContent="space-between">
          <Button id="create-scenario-cancel-button" onClick={closeDialog}>
            Cancel
          </Button>
          <Button
            id="create-scenario-ok-button"
            data-testid="create-scenario-ok-button"
            disabled={!name.trim()}
            color="primary"
            onClick={handleOKClicked}
          >
            Add scenario
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}
