import React, { FC, useState } from 'react'
import { Button } from '@howgood/design'
import { useDispatch, useSelector } from 'react-redux'
import { selectHasErrors } from '@/state/messages'
import { selectUserLoading, selectUserEmail, emailSubmit } from '@/state/user'

export const EmailForm: FC = () => {
  const storeEmail = useSelector(selectUserEmail)
  const isUserLoading = useSelector(selectUserLoading)
  const hasError = useSelector(selectHasErrors)

  const [email, setEmail] = useState(storeEmail)
  const dispatch = useDispatch()

  const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
  }

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    dispatch(emailSubmit({ username: email }))
  }

  return (
    <form className="login-form" onSubmit={onSubmit}>
      <div className="field">
        <label htmlFor="text-input">Username or Email</label>
        <div className="control">
          <input
            className="input"
            type="email"
            name="username"
            onChange={onEmailChange}
            value={email}
            id="text-input"
            autoFocus
            disabled={isUserLoading}
          />
        </div>
      </div>

      <div className="field buttonsContainer">
        <div className="control LoginButton">
          <Button aria-label="Next" type="submit" color="primary">
            Next
          </Button>
        </div>
      </div>
      {hasError && !isUserLoading && (
        <article className="message is-danger">
          <div aria-label="Unable to sign in" className="message-header">
            <p>Unable to sign in</p>
          </div>
          <div className="message-body">Please try again or contact HowGood for help.</div>
        </article>
      )}
    </form>
  )
}
