import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  getProduct,
  lockProduct,
  requestValidation,
  requestSharing,
  saveNewProduct,
  updateProduct,
  generateReport,
  updateScenarioProductData,
} from './productOverview.thunk'
import { initialProductInfo, initialState, ProductInfo, ProductResponse } from './productOverview.state'
import { FormulationTag, InventoriesResponse } from '@/api'

interface UpdateDraftInventoriesPayload {
  id: number
  fields: {
    mt_per_year?: string
    workspace?: { id: number }
    internal_id?: string
  }
}

const productOverviewSlice = createSlice({
  name: 'productOverview',
  initialState,
  reducers: {
    clearProductOverview: (state) => {
      Object.keys(initialState).forEach((field) => {
        state[field] = initialState[field]
      })
    },
    setLockedProductInfo: (state, action: PayloadAction<ProductResponse>) => {
      state.lockedProductInfo = {
        ...action.payload.locked_claims.product_attributes,
        is_foreign: state.savedProductInfo.is_foreign,
        workspaces: state.lockedProductInfo.workspaces,
        date_modified: action.payload.locked_claims_timestamp,
        brand_name: action.payload.brand?.name,
      }
    },
    setProductReports: (state, action: PayloadAction<ProductResponse>) => {
      state.savedProductInfo.reports = action.payload.reports
      state.draftProductInfo.reports = action.payload.reports
      state.lockedProductInfo.reports = action.payload.reports
    },
    updateDraftProduct: (state, action: PayloadAction<Partial<ProductInfo>>) => {
      Object.keys(action.payload).forEach((field) => {
        state.draftProductInfo[field] = action.payload[field]
      })
    },
    updateDraftInventory: (state, action: PayloadAction<UpdateDraftInventoriesPayload>) => {
      const inventory = state.draftInventories.find((item) => item.id === action.payload.id)
      Object.keys(action.payload.fields).forEach((field) => {
        inventory[field] = action.payload.fields[field]
      })
    },
    updateDraftInventoryTags: (state, action: PayloadAction<FormulationTag[]>) => {
      state.draftInventories.forEach((di) => {
        di.formulation_tags = action.payload
      })
    },
    updateDraftInventories: (state, action: PayloadAction<InventoriesResponse[]>) => {
      state.draftInventories = action.payload
    },
    resetProductInfo: (state) => {
      state.draftProductInfo = {
        ...state.savedProductInfo,
      }
      state.draftInventories = state.savedInventories
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProduct.pending, (state) => {
      state.isLoadingProduct = true
      state.productErrorText = ''
    })
    builder.addCase(getProduct.fulfilled, (state, action) => {
      const { product, lockedClaims, inventories } = action.payload
      state.isLoadingProduct = false
      state.productErrorText = ''
      state.savedProductInfo = { ...product, brand_name: product.brand?.name }
      state.draftProductInfo = { ...product, brand_name: product.brand?.name }
      // Here we're setting the locked product info; locked impact scores are set via the recipe's setLockedImpactData()
      state.lockedProductInfo = {
        ...lockedClaims,
        material_types: product.material_types,
        brand_name: lockedClaims?.brand?.name,
      }
      state.savedInventories = inventories
      state.draftInventories = inventories
    })
    builder.addCase(getProduct.rejected, (state, action) => {
      console.error(action.error)
      state.isLoadingProduct = false
      state.productErrorText = action.payload ? `${action.payload}` : 'There was an issue loading this formula.'
      state.savedProductInfo = initialState.savedProductInfo
    })
    builder.addCase(updateScenarioProductData.fulfilled, (state) => {
      state.isLoadingProduct = false
      state.productErrorText = ''
      state.savedProductInfo = state.draftProductInfo
    })
    builder.addCase(updateProduct.pending, (state) => {
      state.isLoadingProduct = true
      state.productErrorText = ''
    })
    builder.addCase(updateProduct.fulfilled, (state, action) => {
      const { product, updatedInventories, isClonedScenarioProduct } = action.payload
      const productInfo = {
        ...product,
        workspace: product.workspaces[0],
        brand_name: product.brand?.name,
      }
      state.isLoadingProduct = false
      state.productErrorText = ''
      state.savedInventories = updatedInventories
      state.draftInventories = updatedInventories
      state.savedProductInfo = productInfo
      state.draftProductInfo = productInfo
      if (isClonedScenarioProduct) {
        state.lockedProductInfo = initialProductInfo
      }
    })
    builder.addCase(updateProduct.rejected, (state, action) => {
      console.error(action.error)
      state.isLoadingProduct = false
      state.productErrorText = 'Unable to update product'
    })

    builder.addCase(saveNewProduct.pending, (state) => {
      state.isLoadingProduct = true
      state.productErrorText = ''
    })
    builder.addCase(saveNewProduct.fulfilled, (state, action) => {
      const { product, inventories } = action.payload
      state.isLoadingProduct = false
      state.productErrorText = ''
      state.savedInventories = inventories
      state.draftInventories = inventories
      state.savedProductInfo = {
        ...product,
        sharing_requests: [],
        brand_name: product.brand?.name,
      }
      state.draftProductInfo = {
        ...product,
        sharing_requests: [],
        brand_name: product.brand?.name,
      }
      state.lockedProductInfo = initialProductInfo
    })
    builder.addCase(saveNewProduct.rejected, (state) => {
      state.isLoadingProduct = false
    })
    builder.addCase(lockProduct.pending, (state) => {
      state.isLoadingLock = true
    })
    builder.addCase(lockProduct.fulfilled, (state) => {
      state.isLoadingLock = false
    })
    builder.addCase(lockProduct.rejected, (state) => {
      state.isLoadingLock = false
    })
    builder.addCase(generateReport.fulfilled, (state, action) => {
      state.savedProductInfo.reports = action.payload.reports
      state.draftProductInfo.reports = action.payload.reports
      state.lockedProductInfo.reports = action.payload.reports
    })
    builder.addCase(requestValidation.pending, (state) => {
      state.isLoadingValidationRequest = true
    })
    builder.addCase(requestValidation.fulfilled, (state, action) => {
      state.isLoadingValidationRequest = false
      state.savedInventories = action.payload
      state.draftInventories = action.payload
    })
    builder.addCase(requestValidation.rejected, (state) => {
      state.isLoadingValidationRequest = false
    })
    builder.addCase(requestSharing.pending, (state) => {
      state.isLoadingShare = true
    })
    builder.addCase(requestSharing.fulfilled, (state, action) => {
      state.isLoadingShare = false
      state.draftProductInfo.sharing_requests = action.payload
      state.savedProductInfo.sharing_requests = action.payload
      state.lockedProductInfo.sharing_requests = action.payload
    })
    builder.addCase(requestSharing.rejected, (state) => {
      state.isLoadingShare = false
    })
  },
})

export const {
  clearProductOverview,
  setLockedProductInfo,
  setProductReports,
  updateDraftProduct,
  updateDraftInventory,
  updateDraftInventories,
  resetProductInfo,
  updateDraftInventoryTags,
} = productOverviewSlice.actions

export const productOverviewReducer = productOverviewSlice.reducer
