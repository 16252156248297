import React, { FC } from 'react'
import { Box } from '@howgood/design'
import { Loader } from '@/components'

export const DashboardLoader: FC = () => {
  return (
    <Box data-testid="dashboard-loader" display="flex" overflow="auto" flex={1} alignItems="center">
      <Loader />
    </Box>
  )
}
