import React, { FC, ReactNode } from 'react'
import { createUseStyles } from 'react-jss'

interface Props {
  children: ReactNode
}

const useStyles = createUseStyles({
  card: {
    padding: '10px 20px',
    backgroundColor: '#fff',
    borderRadius: 6,
    boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.55), 0px 2px 3px -1px rgba(0, 0, 0, 0.08), 0px 2px 8px rgba(0, 0, 0, 0.1)',
  },
})

export const Card: FC<Props> = ({ children }) => {
  const classes = useStyles()

  return <div className={classes.card}>{children}</div>
}
