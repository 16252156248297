import { ImpactScore } from '@/api/types'
import { roundBy } from '@/utils/numbers'

export const formatProductImpactScore = (m: ImpactScore) => {
  return {
    ...m,
    biodiversity_impact: m.biodiversity_impact || 0,
    labor_impact: m.labor_impact || 0,
    processing_impact: m.processing_impact || 0,
    water_impact: m.water_impact || 0,
    blue_water_impact: m.blue_water_impact || 0,
    planetary_health_percentage_impact: m.planetary_health_percentage_impact || 0,
    animal_welfare_impact: m.animal_welfare_impact || 0,
    soc_impact: m.soc_impact || 0,
    working_conditions_impact: m.working_conditions_impact || 0,
    land_use_impact: m.land_use_impact || 0,
    raw_land_use_impact: m.raw_land_use_impact || 0,
    hive_five_impact: m.hive_five_impact || 0,
    environmental_impact: m.environmental_impact || 0,
    supply_chain_resilience_impact: m.supply_chain_resilience_impact || 0,
    processing_level_impact: m.processing_level_impact || 0,
    animal_diet_impact: m.animal_diet_impact || 0,
    applegate_human_health_impact: m.applegate_human_health_impact || 0,
    applegate_animal_health_impact: m.applegate_animal_health_impact || 0,
    applegate_environmental_health_impact: m.applegate_environmental_health_impact || 0,
    applegate_impact: m.applegate_impact || 0,
    craveability_and_sales_potential_impact: m.craveability_and_sales_potential_impact || 0,
    craveability_impact: m.craveability_impact || 0,
    sales_potential_impact: m.sales_potential_impact || 0,
    applegate_health_score: m.applegate_health_score || 0,
    howgood_total_impact: m.howgood_total_impact != null ? m.howgood_total_impact : null,
    biodiversity_impact_original: m.biodiversity_impact || 0,
    labor_impact_original: m.labor_impact || 0,
    processing_impact_original: m.processing_impact || 0,
    water_impact_original: m.water_impact || 0,
    raw_greenhouse_gas_impact_original: m.raw_greenhouse_gas_impact || 0,
    planetary_health_percentage_impact_original: m.planetary_health_percentage_impact || 0,
    animal_welfare_impact_original: m.animal_welfare_impact || 0,
    soc_impact_original: m.soc_impact || 0,
    working_conditions_impact_original: m.working_conditions_impact || 0,
    land_use_impact_original: m.land_use_impact || 0,
    raw_land_use_impact_original: m.raw_land_use_impact || 0,
    hive_five_impact_original: m.hive_five_impact || 0,
    environmental_impact_original: m.environmental_impact || 0,
    supply_chain_resilience_impact_original: m.supply_chain_resilience_impact || 0,
    processing_level_impact_original: m.processing_level_impact || 0,
    animal_diet_impact_original: m.animal_diet_impact || 0,
    applegate_human_health_impact_original: m.applegate_human_health_impact || 0,
    applegate_animal_health_impact_original: m.applegate_animal_health_impact || 0,
    applegate_environmental_health_impact_original: m.applegate_environmental_health_impact || 0,
    applegate_impact_original: m.applegate_impact || 0,
    craveability_and_sales_potential_impact_original: m.craveability_and_sales_potential_impact || 0,
    craveability_impact_original: m.craveability_impact || 0,
    sales_potential_impact_original: m.sales_potential_impact || 0,
    applegate_health_score_original: m.applegate_health_score || 0,
    howgood_total_impact_original: m.howgood_total_impact || 0,
    deforestation_impact: Math.round(m.deforestation_impact) || 0,
    carbon_footprint_annual_impact_sum: roundBy(m.carbon_footprint_annual_impact_sum, 2),
    carbon_footprint_annual_impact_avg: roundBy(m.carbon_footprint_annual_impact_avg, 2),
    carbon_footprint_farm_to_gate_impact_sum: roundBy(m.carbon_footprint_farm_to_gate_impact_sum, 2),
    carbon_footprint_farm_to_gate_impact_avg: roundBy(m.carbon_footprint_farm_to_gate_impact_avg, 2),
    carbon_footprint_on_farm_processing_ghgs_impact_sum: roundBy(
      m.carbon_footprint_on_farm_processing_ghgs_impact_sum,
      2
    ),
    carbon_footprint_on_farm_processing_ghgs_impact_avg: roundBy(
      m.carbon_footprint_on_farm_processing_ghgs_impact_avg,
      2
    ),
    cf_ftm_gate_annual_impact_sum: roundBy(m.cf_ftm_gate_annual_impact_sum, 2),
    cf_ftm_gate_annual_impact_avg: roundBy(m.cf_ftm_gate_annual_impact_avg, 2),
    cf_full_lifecycle_annual_impact_sum: roundBy(m.cf_full_lifecycle_annual_impact_sum, 2),
    cf_full_lifecycle_annual_impact_avg: roundBy(m.cf_full_lifecycle_annual_impact_avg, 2),
    scope_3_category_1_impact_avg: roundBy(m.scope_3_category_1_impact_avg, 2),
    scope_3_category_1_impact_sum: roundBy(m.scope_3_category_1_impact_sum, 2),
    cf_flag_impact_avg: roundBy(m.cf_flag_impact_avg, 2),
    cf_flag_impact_sum: roundBy(m.cf_flag_impact_sum, 2),
    scope_3_category_4_impact_avg: roundBy(m.scope_3_category_4_impact_avg, 2),
    scope_3_category_4_impact_sum: roundBy(m.scope_3_category_4_impact_sum, 2),
    cf_biogenic_processing_impact_avg: roundBy(m.cf_biogenic_processing_impact_avg, 2),
    cf_biogenic_processing_impact_sum: roundBy(m.cf_biogenic_processing_impact_sum, 2),
    cf_ftm_gate_impact_avg: roundBy(m.cf_ftm_gate_impact_avg, 2),
    cf_ftm_gate_impact_sum: roundBy(m.cf_ftm_gate_impact_sum, 2),
    cf_full_lifecycle_impact_avg: roundBy(m.cf_full_lifecycle_impact_avg, 2),
    cf_full_lifecycle_impact_sum: roundBy(m.cf_full_lifecycle_impact_sum, 2),
    cf_total_upstream_transportation_impact_avg: roundBy(m.cf_total_upstream_transportation_impact_avg, 2),
    cf_total_upstream_transportation_impact_sum: roundBy(m.cf_total_upstream_transportation_impact_sum, 2),
    cf_mtm_transportation_impact_avg: roundBy(m.cf_mtm_transportation_impact_avg, 2),
    cf_mtm_transportation_impact_sum: roundBy(m.cf_mtm_transportation_impact_sum, 2),
    cf_manufacturing_impact_avg: roundBy(m.cf_manufacturing_impact_avg, 2),
    cf_manufacturing_impact_sum: roundBy(m.cf_manufacturing_impact_sum, 2),
    cf_cradle_to_shelf_impact_avg: roundBy(m.cf_cradle_to_shelf_impact_avg, 2),
    cf_cradle_to_shelf_impact_sum: roundBy(m.cf_cradle_to_shelf_impact_sum, 2),
    cf_total_upstream_processing_impact_avg: roundBy(m.cf_total_upstream_processing_impact_avg, 2),
    cf_total_upstream_processing_impact_sum: roundBy(m.cf_total_upstream_processing_impact_sum, 2),
    business_potential_impact_sum: roundBy(m.business_potential_impact_sum, 2),
    business_potential_impact_avg: roundBy(m.business_potential_impact_avg, 2),
  }
}
