import React, { FC, ReactNode } from 'react'
import { createClient } from 'contentful/dist/contentful.browser.min.js'
import { ContentfulContext } from './ContentfulContext'

const client = createClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
  environment: process.env.REACT_APP_CONTENTFUL_ENVIRONMENT_ID,
})

interface Props {
  children: ReactNode
}
export const ContentfulProvider: FC<Props> = ({ children }) => {
  return <ContentfulContext.Provider value={client}>{children}</ContentfulContext.Provider>
}
