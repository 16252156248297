import React, { FC } from 'react'
import { Ingredients } from './Ingredients'
import { SalesAndDistribution } from './SalesAndDistribution'
import { Packaging } from './Packaging'

export const FormulaDetailSection: FC = () => {
  return (
    <>
      <h3>Formula Detail</h3>
      <p>All GHG Impact Reports are based on assumptions. These are the assumptions used in this report.</p>
      <Ingredients />
      <SalesAndDistribution />
      <Packaging />
    </>
  )
}
