import { createSelector } from 'reselect'
import { AppState } from '@/store'
import { AppMessages, AppMessage } from './messages.state'

export const selectMessages = (state: AppState): AppMessages => state.messages

export const selectHasMessages = createSelector(selectMessages, (messages) => Object.keys(messages).length > 0)

export const selectHasErrors = createSelector(selectMessages, (messages) =>
  Object.values(messages).some((message: AppMessage) => message.severity === 'error')
)
