import { fetchElasticV2 } from '@/api'
import { INGREDIENTS_SEARCH_PATH } from '../constants/config'
import { unwrapElasticResults } from '../utils/unwrapElasticResults'

export const fetchIngredientsByBaseIds = async (ids: (string | number)[]) => {
  const results = await fetchElasticV2<{ id: string }>({
    url: INGREDIENTS_SEARCH_PATH,
    body: {
      post_filter: { terms: { 'base.id': ids } },
    },
  })
  return unwrapElasticResults<{ id: string }>(results)
}
