import React, { FC, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Text } from '@howgood/design'
import { useDispatch, useSelector } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import {
  updateProduct,
  UpdatedProduct,
  selectSavedProductName,
  selectDisplayedProduct,
  updateScenarioProductData,
} from '@/state/productOverview'
import { EditButton } from './EditButton'
import { ResetButton } from './ResetButton'
import { selectCurrentInitiative, selectCurrentScenario, selectCurrentScenarioId } from '@/state/initiatives'
import { selectIsEditing, selectIsEditingScenarioOnly } from '@/state/recipe'
import { ScenarioProductAttributes } from '@/records'

export const ScenarioEditControls: FC = () => {
  const dispatch = useDispatch()
  const editing = useSelector(selectIsEditing)
  const editingProductScenario = useSelector(selectIsEditingScenarioOnly)
  const scenarioId = useSelector(selectCurrentScenarioId)
  const scenario = useSelector(selectCurrentScenario)
  const initiative = useSelector(selectCurrentInitiative)
  const savedProductName = useSelector(selectSavedProductName)
  const productInfo = useSelector(selectDisplayedProduct)
  const [dialogIsOpen, setDialogIsOpen] = useState(false)

  const handleSaveAsNew = async () => {
    setDialogIsOpen(false)
    const result: any = await dispatch(updateProduct(scenarioId))
    const productResponse: UpdatedProduct = unwrapResult(result)
    window.history.pushState({}, '', `/products/${productResponse.product.id}?scenario=${scenarioId}`)
  }

  const handleSaveChanges = () => {
    if (!productInfo.scenarios || productInfo.scenarios.length === 0) {
      alert("This cloned product is not in any scenarios. This shouldn't happen.") // Temporary
    } else if (productInfo.scenarios.length > 1) {
      alert("This cloned product is in multiple scenarios. This shouldn't happen.") // Temporary
    } else {
      dispatch(updateProduct(scenarioId))
    }
  }

  const handleClickSave = () => {
    if (editingProductScenario) {
      const productScenario = productInfo.scenario_products?.find((s) => s.scenario.id === scenarioId)
      dispatch(
        updateScenarioProductData(
          { mt_per_year: productScenario.mt_per_year, goals: productScenario.goals } ||
            ({} as ScenarioProductAttributes)
        )
      )
    } else if (!productInfo.source_product) {
      setDialogIsOpen(true)
    } else {
      handleSaveChanges()
    }
  }

  return (
    <>
      <Stack direction="row" gap={1} ml={2} alignItems="end">
        <EditButton buttonText={editing ? 'Resume editing formula' : 'Edit formula details'} />
        <ResetButton />
        {editing && (
          <Button color="primary" size="small" onClick={handleClickSave}>
            Save
          </Button>
        )}
      </Stack>
      {initiative && scenario && dialogIsOpen && (
        <Dialog id="clone-product-dialog" open>
          <DialogTitle>Create scenario version?</DialogTitle>
          <DialogContent>
            <Stack gap={2}>
              <Text>
                This will create a new version of <b>{savedProductName}</b> for scenario <b>{scenario?.name}</b> in the{' '}
                <b>{initiative?.name}</b> initiative.
              </Text>
              <Text>{`Any future changes you make to the source product will not be reflected in the new version, nor will changes you make to the 
              new version be reflected in the source product.`}</Text>
              <Text>{`Do you want to continue?`}</Text>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button id="clone-product-cancel-button" onClick={() => setDialogIsOpen(false)}>
              Cancel
            </Button>
            <Button id="clone-product-ok-button" color="primary" onClick={handleSaveAsNew}>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}
