import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Box, Stack } from '@howgood/design'

import { MetricsGrid } from '@/components/GridWithMetrics/MetricsGrid'
import { useVendorColumns, useVendorRows } from '@/components/GridWithMetrics/useGridHooks'
import { Toolbar } from '@/features/PortfolioPage/components/Toolbar/Toolbar'
import { selectCurrentScenario, selectInitiativeBrands, selectisScenarioLoading } from '@/state/initiatives'
import { DebugPanel } from '../DebugPanel'
import { selectDebugToolsSplit } from '@/state/splitio'
import { selectAllMetricGridOptions } from '@/selectors/selectAllWorkspaceGridOptions'
import { useScenarioScores } from '@/state/initiatives/initiatives.requests'
import { useParams } from 'react-router'

export const VendorsTab: FC = () => {
  const params: { initiative: string; scenario: string } = useParams()
  const scenario = useSelector(selectCurrentScenario)
  const options = useSelector(selectAllMetricGridOptions)
  const isLoading = useSelector(selectisScenarioLoading)
  const isDebugSplitEnabled = useSelector(selectDebugToolsSplit)
  const vendors = useSelector(selectInitiativeBrands)

  const { scores, scoresLoading } = useScenarioScores(scenario?.id || +params.scenario)
  const columns = useVendorColumns(scenario)
  const rows = useVendorRows(vendors, scores.vendorScores)

  return (
    <Stack gap={1}>
      <Toolbar />
      <Box height="500px">
        <MetricsGrid rows={rows} columns={columns} options={options} loading={scoresLoading || isLoading} />
      </Box>
      {isDebugSplitEnabled && <DebugPanel />}
    </Stack>
  )
}
