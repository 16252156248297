import { ALL_FOOD_INDUSTRY } from '@/constants/industryCategories'
import { AppState } from '@/store'
import { createSelector } from 'reselect'
import { BenchmarkData } from './salesCategories.requests'
import { SalesCategory } from './salesCategories.slice'

export const selectSalesCategories = (state: AppState): SalesCategory[] => state.salesCategories.salesCategories

export const selectSalesCategoriesLoading = (state: AppState): boolean => state.salesCategories.isLoading

export const selectBenchmarkData = (state: AppState): BenchmarkData => state.salesCategories.benchmarkData

// For the given sales category and metric (e.g. `howgood_one_metric`), return the benchmark value
export const selectAllFoodBenchmarkScores = createSelector(
  selectBenchmarkData,
  (benchmarks) => benchmarks[ALL_FOOD_INDUSTRY.title]
)
