import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'
import { theme } from '@howgood/design'
import { roundBy } from '@/utils/numbers'
import { ImpactScore } from '@/api'
import { Field } from '@/constants/impactScore'
import { DataPct } from './CarbonLifecycle'

export interface TooltipContentProps {
  field: Field
  name: string
  active?: boolean
  dataPct: DataPct
  impactScores: ImpactScore
  benchmarkScores?: ImpactScore
  benchmarkDataPct?: DataPct
}
interface StyleProps {
  diff: number
}

const useStyles = createUseStyles<string, StyleProps>({
  legend: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: 10,
    marginBottom: 40,
    fontSize: '12px',
  },
  item: {
    minWidth: 450,
    height: 25,
    flexGrow: 0,
    display: 'flex',
    margin: '5px 0',
    whiteSpace: 'nowrap',
  },
  color: {
    width: 32,
    height: 20,
    marginRight: 10,
  },
  value: {
    textAlign: 'right',
    flexGrow: 1,
    paddingRight: 50,
  },
  tooltipWrapper: {
    padding: 16,
    backgroundColor: 'white',
    boxShadow: '0px 6px 24px rgba(0, 0, 0, 0.18)',
    borderRadius: 4,
  },
  benchmark: {
    color: ({ diff }) => (diff > 0 ? theme.palette.impact[10] : theme.palette.impact[1]),
  },
  bold: { fontWeight: 700 },
  italic: { fontStyle: 'italic' },
})

export const TooltipContent: FC<TooltipContentProps> = ({
  field,
  name,
  active,
  dataPct,
  benchmarkDataPct,
  impactScores,
  benchmarkScores,
}) => {
  const actVal = impactScores[field] ?? 0
  const actValRounded = roundBy(actVal, 2)
  const actValPct = dataPct[field]
  const benchmarkVal = benchmarkScores ? benchmarkScores[field] ?? 0 : 0
  const benchmarkValPct = benchmarkDataPct ? benchmarkDataPct[field] : 0
  const benchmarkValRounded = benchmarkScores ? roundBy(benchmarkVal, 2) : 0
  const benchmarkDiff = benchmarkScores ? benchmarkVal - actVal : 0
  const diffRounded = roundBy(Math.abs(benchmarkDiff), 2)

  const classes = useStyles({ diff: benchmarkDiff })

  return field && active ? (
    <div className={classes.tooltipWrapper}>
      <div aria-label="Lifecycle name" className={classes.bold}>
        {name}
      </div>
      <div aria-label="Lifecycle value">
        {actValRounded} kg CO2e ({actValPct}%)
      </div>
      {Math.abs(benchmarkDiff) > 0 ? (
        <div aria-label="Lifecycle benchmark value" className={classes.benchmark}>
          <span className={classes.bold}>
            {benchmarkDiff > 0 ? 'Down' : 'Up'} {Math.abs(benchmarkDiff) < 0.01 ? '<0.01' : diffRounded} kg
          </span>{' '}
          from {benchmarkValRounded} kg ({benchmarkValPct}%)
        </div>
      ) : (
        benchmarkScores && (
          <span aria-label="Lifecycle no change value" className={classes.italic}>
            No change
          </span>
        )
      )}
    </div>
  ) : null
}
