import { createAsyncThunk } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'
import { emailCheck, fetchToken, revokeToken, tokenChange, fetchUser, fetchHelperV2 } from '@/api'
import { IUser, UserSettings, Workspace } from '@/records'
import { AppState } from '@/store'
import { getSessionData, removeSessionData, storeSessionData } from '@/utils/sessionData'
import { providerSelected } from './user.slice'
import { getWorkspaces } from '../workspaces/workspaces.thunks'
import { getOrganization } from '../organization'
import { LogoutOptions } from '@auth0/auth0-react'
import { selectUserSettings } from './user.selectors'
import { selectPortfolioImpactDashboardSettings } from '@/features/PortfolioDashboard/state/portfolioDashboard'
import { selectProductDashboardSettings } from '@/features/ProductDashboard/state/productDashboard'
import { selectVendorManagementSettings } from '../vendorManagement/vendorManagement.selectors'
import { Field } from '@/constants/impactScore'
import { ProductColFields } from '@/components/Products/utils/productListColDefs'

export interface AuthPayload {
  access_token: string
  expires_in: number
  token_type: string
  scope: string
  refresh_token: string
}
async function authenticate(authPayload: AuthPayload, username: string): Promise<void> {
  const expires = new Date(Date.now() + authPayload.expires_in * 1000)
  storeSessionData({ ...authPayload, expires, username })
}

type GetUserPayload = IUser & { workspaces: Workspace[] }

export const getUser = createAsyncThunk<GetUserPayload>('user/getUser', async (_, { dispatch }) => {
  const user = await fetchUser()
  const products = []
  const ingredients = []
  const accounts = []
  user.permissions.forEach((permission: string) => {
    if (permission.startsWith('products')) {
      products.push(permission.replace('products.', ''))
    } else if (permission.startsWith('ingredients')) {
      ingredients.push(permission.replace('ingredients.', ''))
    } else if (permission.startsWith('accounts')) {
      accounts.push(permission.replace('accounts.', ''))
    }
  })
  Sentry.setUser({
    id: user?.id?.toString(),
    organizationId: user?.organization,
    email: user?.email,
  })

  if (user?.organization) {
    await dispatch(getOrganization(user?.organization))
  }

  await dispatch(getWorkspaces())
  return { ...user, permissions: { products, ingredients, accounts } }
})

interface Login {
  username: string
  password: string
  otp?: string
}
export type LoginType = 'MFA_REQUIRED' | '' | 'APP_REQUIRED'
export const login = createAsyncThunk<LoginType, Login>('user/login', async ({ username, password, otp = null }) => {
  const loginInfo = {
    username,
    password,
    client_id: process.env.REACT_APP_CLIENT_ID,
    grant_type: 'password',
  }

  const authPayload = await fetchToken(otp ? { ...loginInfo, otp: otp } : loginInfo)

  if (authPayload === 'MFA_REQUIRED' || authPayload === 'APP_REQUIRED') {
    return authPayload
  }
  authenticate(authPayload, username)
  return ''
})

interface ExchangeToken {
  token: string
  username: string
}
export const exchangeToken = createAsyncThunk<AuthPayload, ExchangeToken>(
  'user/exchangeToken',
  async ({ token, username }) => {
    const authPayload = await tokenChange({ token, client_id: process.env.REACT_APP_CLIENT_ID })
    authenticate(authPayload, username)
    return authPayload
  }
)

export const logout = createAsyncThunk<void, void | ((options?: LogoutOptions) => void), { state: AppState }>(
  'user/logout',
  async (auth0Logout, { getState, dispatch }) => {
    const state = getState()
    const provider = state.user.user.provider
    const { access_token: token } = getSessionData()
    if (provider === 'auth0' && auth0Logout) {
      auth0Logout({ federated: true })
    }
    if (token) {
      dispatch({ type: 'REDUX_RESET' })
      await revokeToken({ token, client_id: process.env.REACT_APP_CLIENT_ID })
    }
    removeSessionData()
  }
)

interface EmailSubmit {
  username: string
}
export const emailSubmit = createAsyncThunk<void, EmailSubmit, { state: AppState }>(
  'user/emailSubmit',
  async ({ username }, { dispatch }) => {
    const data = await emailCheck(username)
    dispatch(
      providerSelected({ username, auth_provider: data.auth_provider, auth0_organization: data.organization_id })
    )
  }
)

// Dashboards call this to save the user settings to the backend
// For each dashboard, get its settings from state and include them on the `dashboards` object
export const updateDashboardSettings = createAsyncThunk<UserSettings, void, { state: AppState }>(
  'user/updateDashboardSettings',
  async (_, { getState }) => {
    const state = getState()
    const userSettings = selectUserSettings(state)
    const portfolioDashboardSettings = selectPortfolioImpactDashboardSettings(state)
    const productDashboardSettings = selectProductDashboardSettings(state)
    const user = await fetchHelperV2<IUser>({
      url: 'accounts/users/self/',
      method: 'PATCH',
      body: JSON.stringify({
        user_settings: {
          ...userSettings,
          dashboards: {
            ...userSettings.dashboards,
            portfolio_impact: portfolioDashboardSettings,
            product_impact: productDashboardSettings,
          },
        },
      }),
    })
    return user.user_settings
  }
)

export const setIgnoreUserSettings = createAsyncThunk<UserSettings, boolean, { state: AppState }>(
  'user/setIgnoreUserSettings',
  async (ignoreUserSettings, { dispatch: __, getState }) => {
    const userSettings = selectUserSettings(getState())
    const user = await fetchHelperV2<IUser>({
      url: 'accounts/users/self/',
      method: 'PATCH',
      body: JSON.stringify({
        user_settings: {
          ...userSettings,
          ignore_user_settings: ignoreUserSettings,
        },
      }),
    })
    return user.user_settings
  }
)

export const updateProductListSelectedColumns = createAsyncThunk<
  UserSettings,
  (Field | ProductColFields)[],
  { state: AppState }
>('user/updateProductListSelectedColumns', async (selectedColumns, { dispatch: __, getState }) => {
  const userSettings = selectUserSettings(getState())
  const updated = {
    ...userSettings,
    productList: {
      selectedColumns,
    },
  }
  fetchHelperV2<IUser>({
    url: 'accounts/users/self/',
    method: 'PATCH',
    body: JSON.stringify({
      user_settings: updated,
    }),
  })
  return updated
})

export const updateVendorManagementSettings = createAsyncThunk<UserSettings, void, { state: AppState }>(
  'user/updateVendorManagementSettings',
  async (_, { dispatch: __, getState }) => {
    const userSettings = selectUserSettings(getState())
    const vendorManagementSettings = selectVendorManagementSettings(getState())
    const updated = {
      ...userSettings,
      vendorManagement: vendorManagementSettings,
    }
    fetchHelperV2<IUser>({
      url: 'accounts/users/self/',
      method: 'PATCH',
      body: JSON.stringify({
        user_settings: updated,
      }),
    })
    return updated
  }
)
