import React, { FC } from 'react'
import { faColumns } from '@fortawesome/pro-regular-svg-icons'

import { CheckboxesDropdown } from '@/components'
import { WorkspaceListCheckbox } from '@/state/user'
import { CheckboxOption } from '@/components/Shared/CheckboxesDropdown/CheckboxesDropdown'

interface Props {
  selected: string[]
  options: CheckboxOption[]
  onChange: (updated: string[]) => void
  dataPendoId?: string
  dataTestId?: string
}

export const ColumnSelect: FC<Props> = ({ selected, options, dataPendoId, dataTestId, onChange }) => {
  const handleOptionCheckChange = async (option: WorkspaceListCheckbox) => {
    let updatedSelected: string[] = []
    if (option.checked) {
      updatedSelected = selected.filter((item) => item !== option.key)
    } else {
      updatedSelected = [...selected, option.key]
    }
    onChange(updatedSelected)
  }

  const handleMultiOptionsCheck = async (multiOptions: WorkspaceListCheckbox[]) => {
    const filtered = multiOptions.filter((opt) => !opt.disabled)
    const isChecked = filtered.every((opt) => opt.checked)
    if (!isChecked) {
      const updated = [...new Set([...selected, ...filtered.map((opt) => opt.key)])]
      onChange(updated)
    } else {
      const updated = selected.filter((item) => !filtered.some((opt) => opt.key === item))
      onChange(updated)
    }
  }

  return (
    <CheckboxesDropdown
      icon={faColumns}
      label="Column options"
      data-pendo-id={dataPendoId}
      data-testid={dataTestId}
      options={options}
      onOptionCheckChange={handleOptionCheckChange}
      onOptionsCheckChange={handleMultiOptionsCheck}
      applySelectDeselectAll
    />
  )
}
