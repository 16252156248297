import React, { FC } from 'react'
import { Checkmark, Tooltip, Text, Stack } from '@howgood/design'

import { useContentfulAttribute } from '@/contentfully/hooks'
import { ProductListRenderCellParams } from './ProductGridTypes'

interface Props extends ProductListRenderCellParams {
  value: boolean
}

export const AttributeRenderer: FC<Props> = ({ value, colDef, id }) => {
  const content = useContentfulAttribute(colDef.field)

  return (
    <Tooltip title={<Text>{value ? content?.trueDescription : content?.falseDescription}</Text>} disableInteractive>
      <Stack data-testid={`attribute-cell-${colDef.field}-${id}`} alignItems="center" width="100%">
        <Checkmark data-testid={`attribute-check-${colDef.field}-${id}`} enabled={value} />
      </Stack>
    </Tooltip>
  )
}
