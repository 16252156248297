import React, { FC } from 'react'
import { Document } from '@contentful/rich-text-types'
import { Accordion, AccordionDetails } from '@howgood/design'
import { AccordionCardHeader } from '@/components/Scores/AccordionCardHeader'
import { RichText } from '@/contentfully'

interface Props {
  improveImpact: Document
}

export const ImproveImpact: FC<Props> = ({ improveImpact }) => {
  if (!improveImpact) {
    return null
  }

  return (
    <Accordion data-testid="improve-impact" defaultExpanded>
      <AccordionCardHeader title="How do I improve impact as a product developer?" />
      <AccordionDetails>
        <RichText document={improveImpact} />
      </AccordionDetails>
    </Accordion>
  )
}
