import React, { FC } from 'react'
import MuiRadio, { RadioProps as MuiRadioProps } from '@mui/material/Radio'
import MuiRadioGroup, { RadioGroupProps as MuiRadioGroupProps } from '@mui/material/RadioGroup'

interface RadioGroupProps
  extends Pick<MuiRadioGroupProps, 'id' | 'row' | 'value' | 'onChange' | 'onClick' | 'sx' | 'children'> {}

export const RadioGroup: FC<RadioGroupProps> = ({ id, row, value, onChange, onClick, sx, children, ...restProps }) => {
  return (
    <MuiRadioGroup id={id} row={row} value={value} onChange={onChange} onClick={onClick} sx={sx} {...restProps}>
      {children}
    </MuiRadioGroup>
  )
}

/**
 * It is necessary to spread props on the child element, as the MUI
 * Radio passes props to its children under the hood which are necessary
 * for correct working.
 */

interface RadioProps extends Pick<MuiRadioProps, 'id' | 'size'> {}

export const Radio: FC<RadioProps> = ({ id, size, ...restProps }) => {
  return <MuiRadio id={id} size={size} {...restProps} />
}
