import React, { FC } from 'react'
import { theme, Icon, Stack, Text } from '@howgood/design'
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons'

interface NoMatchProps {
  error?: string
  message?: string
}

const NoMatch: FC<NoMatchProps> = ({ error = 404, message = 'Page not found' }) => {
  return (
    <Stack width="100%" height="100%" alignItems="center" justifyContent="center" gap={2}>
      <Icon icon={faCircleExclamation} size="4x" color={theme.palette.grey[500]} />
      <Text variant="h3">{error}</Text>
      <Text>{message}</Text>
    </Stack>
  )
}

export default NoMatch
