import {
  generateAnnualInventoryEmissions,
  scope3Category1Annual,
  scope3Category4Annual,
} from '@/api/elastic/runtimeMappings'
import { MappingRuntimeField } from '@elastic/elasticsearch/api/types'

type AggregationType = 'sum' | 'avg'

interface VendorMetric {
  title: string
  aggType: AggregationType
  colGroup: string
  description: string
  units?: string
  runtimeMapping?: MappingRuntimeField // Required for annualized metrics
}

interface VendorMetrics {
  [key: string]: VendorMetric
}

// You can add raw (per kg) metrics here and they will be picked up throughout the feature
export const standardVendorMetrics: VendorMetrics = {
  carbon_footprint_farm_to_gate_impact: {
    title: 'Carbon Intensity (Cradle-to-farm-gate (Land management))',
    aggType: 'avg',
    colGroup: 'Intensity',
    description: 'The average cradle-to-farm-gate footprint per material associated with this vendor',
  },
  raw_blue_water_usage_impact: {
    title: 'Blue Water Intensity',
    aggType: 'avg',
    colGroup: 'Intensity',
    description: 'The average raw blue water usage per material associated with this vendor.',
  },
}

// You can add annualized metrics here and they will be picked up too, but include the required runtime mapping for each
export const customVendorMetrics: VendorMetrics = {
  annual_carbon_footprint_farm_to_gate_impact: {
    title: 'Absolute Cradle-to-farm-gate (Land management)',
    runtimeMapping: generateAnnualInventoryEmissions('carbon_footprint_farm_to_gate_impact'),
    aggType: 'sum',
    colGroup: 'Absolute',
    description: '',
    units: 'MT CO2e/year', // This one we can't get from Contentful
  },
  scope_3_category_1_impact: {
    title: 'Scope 3 Emissions - Category 1: Purchased Goods and Services',
    runtimeMapping: scope3Category1Annual,
    aggType: 'sum',
    colGroup: 'Scope 3 Emissions',
    description: '',
  },
  scope_3_category_4_impact: {
    title: 'Scope 3 Emissions - Category 4: Upstream Transportation',
    runtimeMapping: scope3Category4Annual,
    aggType: 'sum',
    colGroup: 'Scope 3 Emissions',
    description: '',
  },
}

export const availableVendorMetrics = { ...standardVendorMetrics, ...customVendorMetrics }
