import React, { FC, useState } from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import {
  Text,
  Stack,
  Select,
  MenuItem,
  ListItemText,
  SelectChangeEvent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Tooltip,
} from '@howgood/design'
import { selectPageView, DRAFT } from '@/state/pageSettings'
import {
  selectDisplayedInventories,
  selectDisplayedProductWorkspaces,
  selectSavedProductInfo,
  updateDraftInventory,
  updateDraftProduct,
} from '@/state/productOverview'
import { InventoriesResponse } from '@/api'
import { selectAllWorkspacesOptions } from '@/state/workspaces'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { Workspace } from '@/records'
import { selectCurrentScenarioId } from '@/state/initiatives'
import { selectIsSupplierConnect } from '@/state/organization'

interface NameProps extends GridRenderCellParams {
  value?: { inventory: InventoriesResponse; workspace: Workspace }
}

export const Name: FC<NameProps> = ({ value }) => {
  const { inventory, workspace } = value

  const dispatch = useDispatch()
  const view = useSelector(selectPageView)
  const availableWorkspaces = useSelector(selectAllWorkspacesOptions)
  const displayedInventories = useSelector(selectDisplayedInventories)
  const productWorkspaces = useSelector(selectDisplayedProductWorkspaces)
  const savedProducts = useSelector(selectSavedProductInfo)
  const scenarioId = useSelector(selectCurrentScenarioId)
  const isSupplierConnect = useSelector(selectIsSupplierConnect)

  const inventoryWorkspaceIds = displayedInventories.map((inv) => inv.workspace.id)
  const savedProductWorkspaceIds = savedProducts.workspaces.map((wkspace) => wkspace.id)

  const [selectedWorkspace, setSelectedWorkspace] = useState<Workspace>(workspace)
  const [dialogOpen, setDialogOpen] = useState(false)

  const changeWorkspace = () => {
    const currentWorkspaceIndex = productWorkspaces.findIndex((wkspace) => wkspace.id === workspace.id)
    const newWorkspaceList = [...productWorkspaces]
    newWorkspaceList[currentWorkspaceIndex] = selectedWorkspace
    batch(() => {
      dispatch(updateDraftProduct({ workspaces: newWorkspaceList }))
      // When you change the workspace, update the associated inventory to reference the new workspace
      dispatch(
        updateDraftInventory({
          id: inventory.id,
          fields: { workspace: { id: selectedWorkspace.id } },
        })
      )
    })
    setDialogOpen(false)
  }

  const handleWorkspaceChanged = (e: SelectChangeEvent<Workspace>) => {
    const newWorkspace = e.target.value as Workspace
    setSelectedWorkspace(newWorkspace)
    setDialogOpen(true)
  }

  if (view !== DRAFT || isSupplierConnect) {
    return <Text>{workspace.name}</Text>
  }

  return (
    <>
      <Stack alignItems="center" width="100%">
        <Tooltip title={scenarioId ? "You can't change the workspace on a scenario product" : ''}>
          <Select
            id={`workspace-select-${workspace.id}`}
            fullWidth
            value={workspace.name}
            renderValue={() => workspace.name}
            onChange={handleWorkspaceChanged}
            disabled={!!scenarioId}
          >
            {availableWorkspaces
              // If product is in multple inventories, don't include any workspace already in the draft inventories or on the saved product
              // This is to avoid an error condition on the backend where we end up with two inventories mapping the same product and workspace
              .filter((ws) =>
                displayedInventories.length > 1
                  ? !(inventoryWorkspaceIds.includes(ws.id) || savedProductWorkspaceIds.includes(ws.id))
                  : ws.edit
              )
              .map((wkspace) => (
                <MenuItem data-testid={`workspace-${wkspace.id}-menu-item`} key={wkspace.id} value={wkspace as any}>
                  <ListItemText primary={wkspace.name} />
                </MenuItem>
              ))}
          </Select>
        </Tooltip>
      </Stack>
      <Dialog data-testid="create-report-confirmation-dialog" open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>
          <Text variant="h3">Move product to new workspace?</Text>
        </DialogTitle>
        <DialogContent>
          <Text>{`Are you sure you want to move this product from ${workspace.name} to ${selectedWorkspace.name}?`}</Text>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={() => setDialogOpen(false)}>
            Cancel
          </Button>
          <Button color="primary" onClick={changeWorkspace}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
