import React, { FC, useState, useMemo, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useGridApiRef } from '@mui/x-data-grid-premium'
import {
  Stack,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Text,
  CircularProgress,
  TextField,
} from '@howgood/design'
import { saveReport } from '@/state/organization'
import {
  selectProductsIsLoading,
  selectProductsHasError,
  selectProductsTotal,
  getAllProducts,
  resetAllProducts,
} from '@/state/products'
import { selectIsProcurement } from '@/state/router'
import { useExcelPrePostProcess } from '../hooks/useExcelPrePostProcess'
import { ExportType, ExportState } from './ExportDialog.types'
import { getDefaultFileName, getDialogTitle, getExportButtonText, getIntroText } from './ExportDialog.utils'
import { ExportProductsGrid } from './ExportProductsGrid'

interface ExportDialogProps {
  open: boolean
  exportType: ExportType
  onClose: () => void
}

let retryTimer: ReturnType<typeof setTimeout> = null
let abortController = new AbortController()

export const ExportDialog: FC<ExportDialogProps> = ({ open, exportType, onClose }) => {
  const dispatch = useDispatch()
  const gridApiRef = useGridApiRef()
  const isProcurement = useSelector(selectIsProcurement)
  const productCount = useSelector(selectProductsTotal)
  const isFetching = useSelector(selectProductsIsLoading)
  const hasError = useSelector(selectProductsHasError)

  const { exceljsPreProcess, exceljsPostProcess } = useExcelPrePostProcess(exportType === 'download-scope3')

  const defaultFileName = getDefaultFileName(exportType)

  const [fileName, setFileName] = useState(defaultFileName)
  const [confirmed, setConfirmed] = useState(false)
  const [progressCount, setProgressCount] = useState({ count: 0, total: 0 })

  function save() {
    // Using `btoa()` was the obvious way to create the required base64 string, but it may fail with this error:
    // Failed to execute 'btoa' on 'Window': The string to be encoded contains characters outside of the Latin1 range
    // So instead, use the FileReader API to create the base64 string
    const blob = new Blob([gridApiRef.current.getDataAsCsv()], { type: 'text/csv' })
    const reader = new FileReader()
    reader.onload = (e) => {
      dispatch(
        saveReport({
          file: (e.target.result as string).split(',')[1],
          report_type: isProcurement ? 'Procurement' : 'Formulation',
          name: `${fileName}.csv`,
          metadata: {},
        })
      )
    }
    reader.readAsDataURL(blob)
  }

  const closeDialog = useCallback(() => {
    onClose()
    setConfirmed(false)
    setFileName(defaultFileName)
    setProgressCount({ count: 0, total: 0 })
    // Clear out any large product lists that have been saved to the store
    dispatch(resetAllProducts())
  }, [onClose, defaultFileName, dispatch])

  const excelExport = useCallback(() => {
    gridApiRef.current.exportDataAsExcel({ fileName, exceljsPreProcess, exceljsPostProcess })
  }, [gridApiRef, fileName, exceljsPreProcess, exceljsPostProcess])

  const csvExport = useCallback(() => {
    gridApiRef.current.exportDataAsCsv({ fileName })
  }, [gridApiRef, fileName])

  const cancelExport = useCallback(() => {
    closeDialog()
    abortController.abort()
    abortController = new AbortController()
    if (retryTimer) clearTimeout(retryTimer)
  }, [closeDialog])

  // Cleanup timer on unmount
  useEffect(() => {
    return () => {
      if (retryTimer) clearTimeout(retryTimer)
    }
  }, [])

  function handleExport() {
    const readyForExport = !isFetching && !hasError && gridApiRef?.current?.getRowsCount?.() > 0
    // If data is not yet ready, try again in 200ms
    if (!readyForExport) {
      retryTimer = setTimeout(handleExport, 200)
      return
    }

    switch (exportType) {
      case 'download-excel':
      case 'download-scope3':
        excelExport()
        break
      case 'download-csv':
        csvExport()
        break
      case 'save':
        save()
        break
      default:
        break
    }
  }

  function prepareExport() {
    setConfirmed(true)
    dispatch(getAllProducts({ signal: abortController.signal, sendCount: setProgressCount }))
    handleExport()
  }

  const exportStatus: ExportState = useMemo(() => {
    if (hasError) return 'Failed'
    if (!confirmed) return 'Not started'
    if (isFetching) return 'Exporting'
    return progressCount.count === progressCount.total ? 'Complete' : 'Not started'
  }, [isFetching, hasError, progressCount, confirmed])

  const fileType = useMemo(() => (exportType === 'download-excel' ? 'xlsx' : 'csv'), [exportType])

  const introText = getIntroText({ exportType, productCount, isProcurement })

  return (
    <>
      <ExportProductsGrid gridApiRef={gridApiRef} exportType={exportType} />
      <Dialog id="export-dialog" open={open} onClose={closeDialog}>
        <DialogTitle>{getDialogTitle(exportType, exportStatus)}</DialogTitle>
        <DialogContent>
          <Stack gap={2}>
            {exportStatus === 'Exporting' && (
              <Stack direction="row" gap={1}>
                <CircularProgress size={20} />
                <Text>
                  {progressCount.count === 0
                    ? 'Export started...'
                    : `Fetched ${progressCount.count?.toLocaleString()} of ${
                        progressCount.total === 10000 ? 'more than 10,000' : progressCount.total?.toLocaleString()
                      } ${isProcurement ? 'materials' : 'products'}`}
                </Text>
              </Stack>
            )}
            {exportStatus === 'Complete' && (
              <Text>
                {exportType === 'save'
                  ? 'Reports can be downloaded from the Reports sidebar.'
                  : 'Download complete. Check your downloads folder.'}
              </Text>
            )}
            {exportStatus === 'Not started' && (
              <Stack gap={4}>
                <Text>{introText}</Text>
                {exportType !== 'download-scope3' && (
                  <TextField
                    data-testid="export-filename-input"
                    value={fileName}
                    onChange={(e) => setFileName(e.target.value)}
                    fullWidth
                    label={`Enter the filename (.${fileType} will be added automatically)`}
                  />
                )}
              </Stack>
            )}
            {exportStatus === 'Failed' && <Text>Error fetching export data. Please try again.</Text>}
          </Stack>
        </DialogContent>
        <DialogActions>
          {exportStatus === 'Not started' ||
            (exportStatus === 'Exporting' && (
              <Button id="export-cancel-button" onClick={cancelExport}>
                Cancel
              </Button>
            ))}
          <Button
            disabled={exportStatus === 'Exporting'}
            id="export-ok-button"
            color="primary"
            onClick={exportStatus === 'Not started' ? prepareExport : closeDialog}
          >
            {getExportButtonText(exportType, exportStatus)}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
