import React, { FC, useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { NumericScore, MenuItem, Select, SelectChangeEvent, Stack } from '@howgood/design'
import { selectSalesCategories } from '@/state/salesCategories'
import { useSalesCategoryAverageScore } from '@/hooks/useSalesCategoryAverageScore'
import { selectWarningText } from '@/state/recipe'
import { ALL_FOOD_INDUSTRY_OPTION, SalesCategoryOption } from '@/constants/industryCategories'

const useSalesCategoryOptions = (): SalesCategoryOption[] => {
  const salesCategories = useSelector(selectSalesCategories)

  return useMemo(
    () => [
      { value: ALL_FOOD_INDUSTRY_OPTION.label, label: ALL_FOOD_INDUSTRY_OPTION.label },
      ...salesCategories.map((cat) => ({
        value: cat.title,
        label: cat.title,
      })),
    ],
    [salesCategories]
  )
}

interface CompareAgainstProps {
  metric: string
}

export const CompareAgainst: FC<CompareAgainstProps> = ({ metric }) => {
  const salesCategoryOptions = useSalesCategoryOptions()
  const dataWarning = useSelector(selectWarningText)

  const [selectedCategory, setSelectedCategory] = useState(ALL_FOOD_INDUSTRY_OPTION.label)

  const avgScore = useSalesCategoryAverageScore(
    metric,
    selectedCategory !== ALL_FOOD_INDUSTRY_OPTION.label ? selectedCategory : null
  )

  const handleChangeCategory = (e: SelectChangeEvent) => {
    const value = e.target.value
    setSelectedCategory(value)
  }

  return (
    <Stack width="100%" mt={-0.5}>
      <Stack direction="row" alignItems="center" width="100%">
        <Select
          id={`${metric}-compare-against`}
          value={selectedCategory}
          onChange={handleChangeCategory}
          MenuProps={{ id: 'compare-against-menu' }}
          sx={{
            '& .MuiSelect-select': { padding: 0 },
            '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
            width: '100%',
            fontSize: 12,
            height: '16px',
          }}
        >
          {salesCategoryOptions.map((category) => (
            <MenuItem key={category.label} value={category.value}>
              {category.label}
            </MenuItem>
          ))}
        </Select>
      </Stack>
      <NumericScore cardKey="compare-against" score={avgScore} size="L" disabled={!!dataWarning} />
    </Stack>
  )
}
