import React, { FC, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { DataGridPremium } from '@mui/x-data-grid-premium'
import { selectAbatementData } from '@/state/optimalGhgPotential'
import { StandardsCell } from './StandardsCell'
import { selectDisplayedNonNestedIngredientList } from '@/state/recipe'
import { selectSplitTreatmentEnabled } from '@/state/splitio'

const columns = [
  {
    field: 'ingredientName',
    headerName: 'Ingredient',
  },
  { field: 'location', headerName: 'Sourcing' },
  {
    field: 'standards',
    headerName: `Standards`,
    renderCell: (params) => <StandardsCell {...params} />,
  },
  {
    field: 'practices',
    headerName: `Practices`,
    renderCell: (params) => <StandardsCell {...params} />,
  },
].map((col) => ({ ...col, flex: 1, sortable: false }))

export const AbatementList: FC = () => {
  const nonNestedIngredients = useSelector(selectDisplayedNonNestedIngredientList)
  const originLocationStandards = useSelector(selectAbatementData)
  const abatementNextSteps = useSelector(selectSplitTreatmentEnabled('abatement_nextstep'))

  const rows = useMemo(() => {
    if (nonNestedIngredients.length !== originLocationStandards.length) {
      return []
    }
    return originLocationStandards.map((v, index) => ({
      ingredientName: nonNestedIngredients[index].name,
      ...v,
      id: index,
    }))
  }, [nonNestedIngredients, originLocationStandards])

  return (
    <DataGridPremium
      rows={rows}
      columns={columns}
      checkboxSelection={abatementNextSteps}
      initialState={{ density: 'compact' }}
      hideFooter
      disableRowSelectionOnClick
      disableColumnMenu
      autoHeight
      sx={{
        border: 'none',
        '& .MuiDataGrid-cell': {
          border: 'none',
        },
      }}
    />
  )
}
