import React, { FC, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Accordion, AccordionDetails, Stack, Text } from '@howgood/design'
import { FLAG_PERMISSIONS, PERMISSIONS_TO_FIELDS_MAP } from '@/constants/impactScore'
import { selectContentfulMetricData } from '@/state/contentfulData'
import { selectProductMetrics } from '@/state/productMetrics'
import { selectDisplayedProductImpactData } from '@/state/recipe'
import { selectIsPortfolio, selectIsRollup } from '@/state/router'
import { selectFlagGoalsSplit } from '@/state/splitio'
import { selectFlagPermissions } from '@/state/user'
import { AccordionCardHeader } from '../../AccordionCardHeader'
import { AllEmissionsChart } from './AllEmissionsChart'
import { FlagEmissionsChart } from './FlagEmissionsChart'
import { ProductCarbonFootprintChart } from './ProductCarbonFootprintChart'
import { Legend } from './Legend'
import { FlagData, Highlight } from './types'

interface Props {
  isFavorites?: boolean
}

export const Flag: FC<Props> = ({ isFavorites }) => {
  const productMetrics = useSelector(selectProductMetrics)
  const impactData = useSelector(selectDisplayedProductImpactData)
  const flagSplitEnabled = useSelector(selectFlagGoalsSplit)
  const flagPermissions = useSelector(selectFlagPermissions)
  const contentfulData = useSelector(selectContentfulMetricData)
  const isRollup = useSelector(selectIsRollup)
  const isPortfolio = useSelector(selectIsPortfolio)
  const [highlight, setHighlight] = useState<Highlight>()
  const flagFields = useMemo(() => flagPermissions.map((permission) => PERMISSIONS_TO_FIELDS_MAP[permission]), [
    flagPermissions,
  ])
  const flagData = useMemo<FlagData>(() => {
    return flagFields.reduce((acc, field) => {
      const flagEntry = contentfulData.find((entry) => entry.key === field)
      const score = isRollup ? productMetrics[`${field}_sum`] : impactData[field]
      if (flagEntry) {
        return {
          ...acc,
          [field]: { title: flagEntry.subtitle, score },
        }
      }
      return acc
    }, {} as FlagData)
  }, [isRollup, flagFields, contentfulData, productMetrics, impactData])

  const hasData = Object.values(flagData).findIndex((d) => Math.abs(d.score) > 0) !== -1
  const hasPermissions = flagFields.length === FLAG_PERMISSIONS.length
  const isNonPortfolioRollup = isRollup && !isPortfolio
  const flagEnabled = flagSplitEnabled && hasData && hasPermissions && !isNonPortfolioRollup

  if (!flagEnabled) {
    return null
  }

  const allEmissionsData = {
    cf_removals_annual_impact: flagData.cf_removals_annual_impact,
    raw_greenhouse_gas_annual_impact: flagData.raw_greenhouse_gas_annual_impact,
    cf_luc_annual_impact: flagData.cf_luc_annual_impact,
    cf_non_flag_annual_impact: flagData.cf_non_flag_annual_impact,
  }
  const allEmissionsTotal = Object.values(allEmissionsData).reduce(
    (acc, d) => (typeof d.score === 'number' ? acc + d.score : acc),
    0
  )
  const flagEmissionsData = { cf_flag_annual_impact: flagData.cf_flag_annual_impact }
  const productCarbonFootprintData = { cf_ftm_gate_annual_impact: flagData.cf_ftm_gate_annual_impact }
  const domainBuffer = Math.max(flagData.cf_ftm_gate_annual_impact.score / 10, flagData.cf_removals_annual_impact.score)
  const domainMin = flagData.cf_removals_annual_impact.score - domainBuffer
  const domainMax =
    Math.max(flagData.cf_ftm_gate_annual_impact.score, allEmissionsTotal) -
    flagData.cf_removals_annual_impact.score +
    domainBuffer
  const domain: [number, number] = [domainMin, domainMax]
  const ticks = [...new Set([domain[0], 0, ...Object.values(flagData).map((d) => d.score), domain[1]])]

  return (
    <Accordion data-testid="flag" defaultExpanded>
      <AccordionCardHeader title="Science Based Targets (SBTi)" cardKey="flag" favoritesTab={isFavorites} />
      <AccordionDetails>
        <Stack flexDirection="row" gap={4}>
          <Stack flex={1} width="65%" gap={2}>
            <Text fontWeight="bold">All Emissions and Removals</Text>
            <Stack data-testid="all-emissions-chart">
              <AllEmissionsChart data={allEmissionsData} domain={domain} ticks={ticks} highlight={highlight} />
            </Stack>
            <Stack>
              <Text fontWeight="bold">FLAG Emissions</Text>
              <Text variant="caption">
                {flagData.raw_greenhouse_gas_annual_impact.title}
                {` + `}
                {flagData.cf_luc_annual_impact.title}
                {` - `}
                {flagData.cf_removals_annual_impact.title}
              </Text>
            </Stack>
            <Stack data-testid="flag-emissions-chart">
              <FlagEmissionsChart data={flagEmissionsData} domain={domain} ticks={ticks} setHighlight={setHighlight} />
            </Stack>
            <Stack>
              <Text fontWeight="bold">Product Carbon Footprint</Text>
              <Text variant="caption">
                {flagData.raw_greenhouse_gas_annual_impact.title}
                {` + `}
                {flagData.cf_luc_annual_impact.title}
                {` + `}
                {flagData.cf_non_flag_annual_impact.title}
                {` - `}
                {flagData.cf_removals_annual_impact.title}
              </Text>
            </Stack>
            <Stack data-testid="product-carbon-footprint-chart">
              <ProductCarbonFootprintChart
                data={productCarbonFootprintData}
                domain={domain}
                ticks={ticks}
                setHighlight={setHighlight}
              />
            </Stack>
          </Stack>
          <Stack width="35%" gap={2}>
            <Legend data={flagData} />
          </Stack>
        </Stack>
      </AccordionDetails>
    </Accordion>
  )
}
