import useSWR from 'swr'
import { fetchHelperV2 } from '@/api'
import { ImpactScoreResponse } from './types'

type ScoreType = 'auto' | 'locked' | 'live'

export async function fetchProductImpactScore(id: number, scoreType: ScoreType = 'auto'): Promise<ImpactScoreResponse> {
  return await fetchHelperV2({
    url: `products/${id}/impact-score/?score_type=${scoreType}`,
  })
}

interface UseProductImpact {
  data: ImpactScoreResponse
  isLoading: boolean
  isError: boolean
}

export function useProductImpact(productId: string): UseProductImpact {
  const url = productId ? `products/${productId}/impact-score/` : null
  const { data, error } = useSWR(url, () => fetchHelperV2<ImpactScoreResponse>({ url }))

  return {
    data,
    isLoading: !data && !error,
    isError: Boolean(error),
  }
}
