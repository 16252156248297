import { base64HgLogo } from '@/components/Shared/excelHowgoodLogo/base64HgLogo'
import { GridExceljsProcessInput } from '@mui/x-data-grid-premium/hooks/features/export'

type Worksheet = GridExceljsProcessInput['worksheet']

/**
 * Provide the worksheet and this method will loop through each column and
 * adjust the width to fit the longest value in the column.
 * This method mutates the worksheet object
 * @param worksheet
 * @param maxLength: cap cell width (character length)
 */
export const autoAdjustColWidths = (worksheet: Worksheet, maxLength?: number) => {
  worksheet.columns.forEach((column) => {
    const lengths = column.values.map((v) => v.toString().length)
    const length = Math.max(...lengths.filter((v) => typeof v === 'number'))
    column.width = maxLength ? Math.min(maxLength, length) : length
  })
}

/**
 * Wrap text for all columns in a worksheet, or select columns specified by a
 * list of column indices
 * This method mutates the worksheet object
 * @param worksheet
 * @param columnIndices: (optional) specific column indices to wrap
 */
export const wrapText = (worksheet: Worksheet, columnIndices?: number[]) => {
  const columns = columnIndices ? columnIndices.map((column) => worksheet.getColumn(column)) : worksheet.columns
  columns.forEach((column) => {
    column.alignment = { wrapText: true }
  })
  // Do not wrap header text
  worksheet.getRow(1).alignment = { wrapText: false }
}

/**
 * Provide the worksheet and row index and this method will apply the header styling to that row.
 * Styles include bold font and a light green fill color.
 * NOTE: the row index is 1-based.
 * This method mutates the worksheet object
 * @param worksheet
 * @param rowIndex (number) - The row index to apply the header row color to
 */
export const formatHeaderRow = (worksheet: Worksheet, rowIndex: number) => {
  worksheet.getRow(rowIndex).font = { bold: true }
  worksheet.getRow(rowIndex).fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'E1EFDA' },
  }
}

/**
 * This will add the logo to the _first_ column and _second_ row of the worksheet.
 * This method mutates the worksheet object
 * @param { worksheet, workbook }: GridExceljsProcessInput
 */
export const addHGLogo = ({ worksheet, workbook }: GridExceljsProcessInput) => {
  const imageId = workbook.addImage({
    base64: base64HgLogo,
    extension: 'jpeg',
  })
  worksheet.addImage(imageId, {
    tl: { col: 0, row: 1 },
    ext: { width: 200, height: 200 },
  })
  worksheet.columns[0].width = 27
}

/**
 * This method mutates the worksheet object
 * @param worksheet
 * @param cellRange (string) - The cell range to merge (e.g. 'A1:B1')
 */
export const mergeAndWrapCells = (worksheet: Worksheet, cellRange: string) => {
  worksheet.mergeCells(cellRange)
  worksheet.getCell(cellRange).alignment = { wrapText: true }
}
