import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { v1 as uuidv1 } from 'uuid'
import { deleteReport, saveReport } from '../organization'
import { FORMULA_ERROR, lockProduct, requestSharing, requestValidation, saveNewProduct } from '../productOverview'
import {
  initializeProductFilters,
  updateProductScenarioData,
  updateProductScenarioMTperYr,
  updateScenarioProductStandards,
} from '../products'
import { updateRecipeAndScores } from '../recipe'
import { getWorkspaceImpactScores } from '../workspaces'
import { State, AppMessage } from './messages.state'
import { createInitiativeWithBaseline, deleteInitiative, deleteScenario, getInitiatives } from '../initiatives'
import { initializeVendorManagementFilters, updateVendorByBrand } from '../vendorManagement'
import { getCarbonAccountingReportActivities, updateCarbonAccountingReportActivities } from '../carbonAccounting'
import { getVendorDetails } from '../vendor'
import { exchangeToken, getUser } from '../user'
import { getVendorImpactHistory } from '../impactHistory'

/**
 * Verify that message does not already exist in the slice
 */
function noMatch(state: State, message: string): boolean {
  return Object.values(state).findIndex((val: AppMessage) => val.message === message) === -1
}

const messagesSlice = createSlice({
  name: 'messages',
  initialState: {} as State,
  reducers: {
    addMessage: (state, { payload }: PayloadAction<AppMessage>) => {
      if (noMatch(state, payload.message)) {
        state[uuidv1()] = payload
      }
    },
    removeMessages: (state) => {
      Object.keys(state).forEach((key) => {
        delete state[key]
      })
    },
  },
  extraReducers: (builder) => {
    builder.addCase(saveReport.rejected, (state) => {
      const message = 'There was an issue saving the report. Please contact support@howgood.com.'
      if (noMatch(state, message)) {
        state[uuidv1()] = { message, severity: 'error' }
      }
    })
    builder.addCase(deleteReport.rejected, (state) => {
      const message = 'Unable to delete the selected report. Please contact support@howgood.com.'
      if (noMatch(state, message)) {
        state[uuidv1()] = { message, severity: 'error' }
      }
    })
    builder.addCase(saveNewProduct.rejected, (state) => {
      const message = FORMULA_ERROR
      if (noMatch(state, message)) {
        state[uuidv1()] = { message, severity: 'error' }
      }
    })
    builder.addCase(lockProduct.rejected, (state) => {
      const message = 'There was an issue locking this formula. Please contact support@howgood.com.'
      if (noMatch(state, message)) {
        state[uuidv1()] = { message, severity: 'error' }
      }
    })
    builder.addCase(requestValidation.rejected, (state) => {
      const message = 'There was an issue sending your validation request. Please contact support@howgood.com.'
      if (noMatch(state, message)) {
        state[uuidv1()] = { message, severity: 'error' }
      }
    })
    builder.addCase(requestSharing.rejected, (state) => {
      const message = 'There was an issue sending your sharing request. Please contact support@howgood.com.'
      if (noMatch(state, message)) {
        state[uuidv1()] = { message, severity: 'error' }
      }
    })
    builder.addCase(initializeProductFilters.rejected, (state) => {
      const message = 'There was an issue initializing filter from the url parameters.'
      if (noMatch(state, message)) {
        state[uuidv1()] = { message, severity: 'error' }
      }
    })
    builder.addCase(initializeVendorManagementFilters.rejected, (state) => {
      const message = 'There was an issue initializing filter from the url parameters.'
      if (noMatch(state, message)) {
        state[uuidv1()] = { message, severity: 'error' }
      }
    })
    builder.addCase(updateRecipeAndScores.rejected, (state) => {
      const message = 'Something went wrong – please try again or contact support@howgood.com'
      if (noMatch(state, message)) {
        state[uuidv1()] = { message, severity: 'error' }
      }
    })
    builder.addCase(getWorkspaceImpactScores.rejected, (state) => {
      const message =
        'There was an issue fetching workspace level impact scores. Please refresh the page or contact support (support@howgood.com) to resolve this issue.'
      if (noMatch(state, message)) {
        state[uuidv1()] = { message, severity: 'error' }
      }
    })
    builder.addCase(createInitiativeWithBaseline.rejected, (state) => {
      const message = 'Unable to create new initiative. Please try again or contact support@howgood.com'
      if (noMatch(state, message)) {
        state[uuidv1()] = { message, severity: 'error' }
      }
    })
    builder.addCase(getInitiatives.rejected, (state) => {
      const message = 'Unable to retrieve initiatives. Please try again or contact support@howgood.com'
      if (noMatch(state, message)) {
        state[uuidv1()] = { message, severity: 'error' }
      }
    })
    builder.addCase(deleteScenario.rejected, (state) => {
      const message = 'There was an issue deleting this scenario. Please try again or contact support@howgood.com'
      if (noMatch(state, message)) {
        state[uuidv1()] = { message, severity: 'error' }
      }
    })
    builder.addCase(updateScenarioProductStandards.rejected, (state) => {
      const message = 'There was problem adding standards. Please try again or contact support@howgood.com'
      if (noMatch(state, message)) {
        state[uuidv1()] = { message, severity: 'error' }
      }
    })
    builder.addCase(getCarbonAccountingReportActivities.rejected, (state) => {
      const message =
        "There was a problem loading this category's emissions activities. Please try again or contact support@howgood.com"
      if (noMatch(state, message)) {
        state[uuidv1()] = { message, severity: 'error' }
      }
    })
    builder.addCase(updateCarbonAccountingReportActivities.rejected, (state) => {
      const message =
        "There was a problem updating this category's emissions activities. Please try again or contact support@howgood.com"
      if (noMatch(state, message)) {
        state[uuidv1()] = { message, severity: 'error' }
      }
    })
    builder.addCase(updateProductScenarioData.rejected, (state) => {
      const message = 'There was a problem updating this product. Please try again or contact support@howgood.com'
      if (noMatch(state, message)) {
        state[uuidv1()] = { message, severity: 'error' }
      }
    })
    builder.addCase(updateProductScenarioMTperYr.rejected, (state) => {
      const message =
        'There was a problem updating the sales volume for this product. Please try again or contact support@howgood.com'
      if (noMatch(state, message)) {
        state[uuidv1()] = { message, severity: 'error' }
      }
    })
    builder.addCase(getVendorDetails.rejected, (state) => {
      const message =
        'There was a problem loading the vendor details for this vendor. Please try again or contact support@howgood.com'
      if (noMatch(state, message)) {
        state[uuidv1()] = { message, severity: 'error' }
      }
    })
    builder.addCase(updateVendorByBrand.rejected, (state) => {
      const message = 'There was a problem updating this vendor. Please try again or contact support@howgood.com'
      if (noMatch(state, message)) {
        state[uuidv1()] = { message, severity: 'error' }
      }
    })
    builder.addCase(deleteInitiative.rejected, (state) => {
      const message = 'There was a problem deleting this initiative. Please try again or contact support@howgood.com'
      if (noMatch(state, message)) {
        state[uuidv1()] = { message, severity: 'error' }
      }
    })
    builder.addCase(exchangeToken.rejected, (state, action) => {
      const message = action.error.message
      if (noMatch(state, message)) {
        state[uuidv1()] = { message, severity: 'error' }
      }
    })
    builder.addCase(getUser.rejected, (state, action) => {
      const message = action.error.message
      if (noMatch(state, message)) {
        state[uuidv1()] = { message, severity: 'error' }
      }
    })
    builder.addCase(getVendorImpactHistory.rejected, (state) => {
      const message =
        'There was a problem loading the vendor impact history. Please try again or contact support@howgood.com'
      if (noMatch(state, message)) {
        state[uuidv1()] = { message, severity: 'error' }
      }
    })
  },
})

export const { addMessage, removeMessages } = messagesSlice.actions

export const messagesReducer = messagesSlice.reducer
