import { useMemo } from 'react'
import { CheckboxOption } from './CheckboxesDropdown'

interface OptionGroup {
  name: string
  options: CheckboxOption[]
}

/**
 * @param options list of selectable items
 * @returns items grouped by category (e.g., Carbon Lifecycle columns)
 */
function useOptionSubGroups(options: CheckboxOption[]): OptionGroup[] {
  return useMemo(() => {
    const groups = []
    options.forEach((option) => {
      const group = groups.find((g) => g.name === option.colGroup)
      if (group) {
        group.options.push(option)
      } else {
        groups.push({
          name: option.colGroup,
          options: [option],
        })
      }
    })
    return groups
  }, [options])
}

/**
 * @param options list of selectable items
 * @param itemsPerColumn number of selectable items per column
 * @returns list of items grouped into columns
 */
export function useOptionGroups(options: CheckboxOption[], itemsPerColumn): OptionGroup[][] {
  const optionSubGroups = useOptionSubGroups(options)

  return useMemo(() => {
    const colGroups = []
    let groupCount = 0
    let itemCount = 0
    optionSubGroups.forEach((group) => {
      const numItems = group.options.length
      if (groupCount > 0 && itemCount + numItems < itemsPerColumn) {
        colGroups[groupCount - 1].push(group)
        itemCount += numItems
      } else {
        colGroups.push([group])
        itemCount = numItems
        groupCount++
      }
    })
    return colGroups
  }, [optionSubGroups, itemsPerColumn])
}
