import React, { FC } from 'react'

import { PanelGrid } from './PanelGrid'
import { ScoreCards } from './ScoreCards/ScoreCards'
import { useScoreCardData } from './ScoreCards/useScoreCardData'

export const NaturePanel: FC = () => {
  const scoreCardData = useScoreCardData()

  return (
    <PanelGrid>
      <ScoreCards cards={scoreCardData.nature.data} />
    </PanelGrid>
  )
}
