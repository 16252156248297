import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { Box, theme } from '@howgood/design'
import { MetricEntry } from '@/contentfully'
import { selectGridSettings } from '@/state/preferences'
import { RecipeIngredient, RecipeNestedIngredient } from '@/state/recipe/recipe.state'
import { selectSplitTreatmentEnabled } from '@/state/splitio'
import { ScoreInputs, getScore, isRawScore } from '../../../util/getScore'
import { ChangeIndicator } from './ChangeIndicator'
import { ScoreCellText } from './ScoreCellText'
import { ScoreCellDialog } from './ScoreCellDialog'
import { ScoreCellDrawer } from './ScoreCellDrawer'

export interface ScoreCellParams extends GridRenderCellParams {
  title?: string
  description?: string
  valuesMap?: MetricEntry['valuesMap']
  value?: ScoreInputs
  ingredient?: RecipeIngredient | RecipeNestedIngredient
}

export const ScoreCell: FC<ScoreCellParams> = ({ title, description, valuesMap, value, ingredient }) => {
  const [hover, setHover] = useState(false)
  const [open, setOpen] = useState(false)
  const gridSettings = useSelector(selectGridSettings)
  const sourcesEnabled = useSelector(selectSplitTreatmentEnabled('show-data-sources'))
  const drawerEnabled = useSelector(selectSplitTreatmentEnabled('ingredient-drawer'))

  const score = getScore(value)
  const scoreFormatted = score.scoreRange || score.scoreRounded
  const { impactKey, units } = value
  const showValue = title && hover
  const isRaw = isRawScore(impactKey)

  // If it's a raw score, color the cell based on the selected scoring method
  // If it's a 1-10 impact score, always color the cell based on the actual 1-10 score
  const backgroundColor =
    theme.palette.impact[
      isRaw && gridSettings.scoringMethod === 'contributionWeighted'
        ? score.contributionOneToTen
        : score.scoreOneToTen || 0
    ]

  return (
    <>
      {drawerEnabled && (
        <ScoreCellDrawer
          open={open}
          ingredient={ingredient}
          onClose={() => setOpen(false)}
          score={scoreFormatted}
          field={impactKey}
          backgroundColor={backgroundColor}
        />
      )}
      {!drawerEnabled && (
        <ScoreCellDialog
          open={open}
          ingredient={ingredient}
          score={scoreFormatted}
          title={title}
          description={description}
          units={units}
          onClose={() => setOpen(false)}
        />
      )}
      <Box
        width="100%"
        height="100%"
        display="flex"
        alignItems="center"
        position="relative"
        bgcolor={backgroundColor}
        onClick={() => {
          if (sourcesEnabled) setOpen(true)
        }}
        onMouseOver={() => {
          if (sourcesEnabled) setHover(true)
        }}
        onMouseOut={() => setHover(false)}
        sx={{ cursor: 'pointer' }}
      >
        <Box
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height="100%"
          bgcolor="white"
          sx={{
            opacity: showValue || (!isRaw && gridSettings.scoringMethod === 'contributionWeighted') ? 0.4 : 0,
            pointerEvents: !isRaw && gridSettings.scoringMethod === 'contributionWeighted' ? 'none' : undefined,
          }}
        />
        <Box display="flex" alignItems="center" width="100%" height="100%" zIndex={2}>
          <ScoreCellText
            score={score}
            impactKey={impactKey}
            units={units}
            valuesMap={valuesMap}
            showValue={showValue}
          />
        </Box>
        <ChangeIndicator score={score} />
      </Box>
    </>
  )
}
