import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectResultsFilters, setResultsFilters } from '../state/portfolioDashboard'
import { TagAutocomplete } from './TagAutocomplete'

export const ResultsFilter: FC = () => {
  const resultsFilters = useSelector(selectResultsFilters)
  const dispatch = useDispatch()

  const handleChange = (inputs: string[]) => {
    dispatch(setResultsFilters(inputs))
  }

  return <TagAutocomplete label="Filter results by tag" selectedTags={resultsFilters} onChange={handleChange} />
}
