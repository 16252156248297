import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { faLink, faCheck } from '@fortawesome/pro-regular-svg-icons'
import { Text, Stack, Button, Dialog, DialogTitle, DialogActions, DialogContent, Box } from '@howgood/design'
import { BLOCKS } from '@contentful/rich-text-types'
import { ContentfulFormulaIconKey, FORMULA_ICON_BADGE, RichText } from '@/contentfully'
import { useContentfulFormulaIcon } from '@/contentfully/hooks'
import { NodeRenderer } from '@contentful/rich-text-react-renderer'
import { ProductBrand } from '@/records'
import { FormulaIconBadge } from '@/components/Shared/IngredientSearch/FormulaIconBadge'
import { VendorContact } from './VendorContact'
import { selectMaterialIconAgpracticeSplit } from '@/state/splitio'
import { VendorVerifiedAlert } from './VendorVerifiedAlert'
import { VendorVerifiedPlusAlert } from './VendorVerifiedPlusAlert'
import { useUrlWithUseridParam } from '@/hooks/useUrlWithUseridParam'
import { DataGranularitySpectrum } from './DataGranularitySpectrum'
import { PurchasedMaterialAlert } from './PurchasedMaterialAlert'
import { DataPortalLink } from '@/components/Procurement/RequestVendorData/CollectVendorDataContent'
import { PurchasedMaterialDialogCopyToClipboard } from './PurchasedMaterialDialogCopyToClipboard'

const LINK_SRC = 'https://howgood.typeform.com/keypractices'

const ParagraphRenderer: NodeRenderer = (_node, children) => {
  return <Text variant="body2">{children}</Text>
}

interface Props {
  brand: ProductBrand
  productId: number
  badge: ContentfulFormulaIconKey
  onClose: () => void
}

export const VendorInfoDialog: FC<Props> = ({ brand, productId, onClose, badge }) => {
  const data = useContentfulFormulaIcon(badge)
  const url = `${useUrlWithUseridParam(LINK_SRC)}&product_id=${productId}`
  const materialIconAgpracticeEnabled = useSelector(selectMaterialIconAgpracticeSplit)
  const [dataPortalLinkType, setDataPortalLinkType] = useState<DataPortalLink>(null)
  const [isCopied, setIsCopied] = useState(false)
  const { name: vendorName } = brand

  const showPurchasedMaterialAlert = badge === FORMULA_ICON_BADGE.purchasedMaterial
  const showVendorVerifiedAlert = materialIconAgpracticeEnabled && badge === FORMULA_ICON_BADGE.vendorVerified
  const showVendorVerifiedPlusAlert = materialIconAgpracticeEnabled && badge === FORMULA_ICON_BADGE.vendorVerifiedPlus
  const showVendorContact = !showVendorVerifiedAlert && !showVendorVerifiedPlusAlert && !showPurchasedMaterialAlert

  const showDataGranularitySpectrum =
    showVendorVerifiedAlert || showVendorVerifiedPlusAlert || showPurchasedMaterialAlert

  return (
    <Dialog id="vendor-info-dialog" open onClose={onClose}>
      <DialogTitle>{`Vendor${vendorName ? `: ${vendorName}` : ''}`}</DialogTitle>
      <DialogContent>
        <Stack direction="row" gap={0.5} mb={0.5}>
          <FormulaIconBadge badge={badge} noMargin noTooltip />
          <Text variant="body1">{data?.title}</Text>
        </Stack>
        <RichText
          document={data?.description}
          rendererOptions={{ renderNode: { [BLOCKS.PARAGRAPH]: ParagraphRenderer } }}
        />
        {showVendorContact && <VendorContact brand={brand} />}
        {showVendorVerifiedAlert && <VendorVerifiedAlert />}
        {showVendorVerifiedPlusAlert && <VendorVerifiedPlusAlert />}
        {showPurchasedMaterialAlert && (
          <PurchasedMaterialAlert
            dataPortalLinkType={dataPortalLinkType}
            handleDataPortalLinkTypeChange={(value: DataPortalLink) => {
              setDataPortalLinkType(value)
            }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Stack gap={1} flex={1}>
          <Stack direction="row" display="flex" justifyContent="flex-end" gap={1}>
            {showVendorVerifiedAlert && (
              <CopyToClipboard text={url} onCopy={() => setIsCopied(true)}>
                <Box width={140}>
                  <Button
                    id="copy-key-practices-link-button"
                    color="primary"
                    aria-label="Copy Link"
                    startIcon={isCopied ? faCheck : faLink}
                    fullWidth
                  >
                    {isCopied ? 'Link copied' : 'Copy Link'}
                  </Button>
                </Box>
              </CopyToClipboard>
            )}
            {showPurchasedMaterialAlert && <PurchasedMaterialDialogCopyToClipboard linkType={dataPortalLinkType} />}
            <Button id="close-vendor-info-dialog-button" onClick={onClose}>
              Close
            </Button>
          </Stack>
          {showDataGranularitySpectrum && <DataGranularitySpectrum />}
        </Stack>
      </DialogActions>
    </Dialog>
  )
}
