import React, { FC } from 'react'
import { Box, Divider, Stack, Text } from '@howgood/design'
import { formatNumber } from '@howgood/utils'
import { FlagData } from './types'

interface LegendItemProps {
  title: string
  subtitle?: string
  score: string
  color: string
}
const LegendItem: FC<LegendItemProps> = ({ title, subtitle, score, color }) => {
  return (
    <Stack flexDirection="row" justifyContent="space-between">
      <Stack flexDirection="row" gap={1}>
        <Box width={16} height={16} mt={0.5} bgcolor={color} borderRadius={1} border={1} borderColor="divider" />
        <Stack>
          <Text>{title}</Text>
          <Text variant="caption">{subtitle}</Text>
        </Stack>
      </Stack>
      <Text fontWeight="bold">{score}</Text>
    </Stack>
  )
}

interface Props {
  data: FlagData
}

export const Legend: FC<Props> = ({ data }) => {
  return (
    <Stack data-testid="flag-legend" gap={1}>
      <LegendItem
        title={data.cf_luc_annual_impact.title}
        subtitle="Land use change GHGs"
        score={formatNumber(data.cf_luc_annual_impact.score)}
        color="error.light"
      />
      <LegendItem
        title={data.raw_greenhouse_gas_annual_impact.title}
        subtitle="On-farm GHGs"
        score={formatNumber(data.raw_greenhouse_gas_annual_impact.score)}
        color="error.main"
      />
      <LegendItem
        title={data.cf_removals_annual_impact.title}
        score={formatNumber(data.cf_removals_annual_impact.score)}
        color="success.light"
      />
      <Divider />
      <LegendItem
        title="Total Net FLAG Emissions and Removals"
        score={formatNumber(data.cf_flag_annual_impact.score)}
        color="error.dark"
      />
      <LegendItem
        title={data.cf_non_flag_annual_impact.title}
        subtitle="Transportation, Processing, & Manufacturing"
        score={formatNumber(data.cf_non_flag_annual_impact.score)}
        color="warning.light"
      />
      <Divider />
      <LegendItem
        title="Total Product Carbon Footprint"
        score={formatNumber(data.cf_ftm_gate_annual_impact.score)}
        color="info.light"
      />
      <Text variant="caption" textAlign="right">
        MT CO2e/year
      </Text>
    </Stack>
  )
}
