import React, { FC } from 'react'
import { CircularProgress, Box } from '@howgood/design'

export const Loader: FC = () => {
  return (
    <Box p="32px 0" width="100%" textAlign="center">
      <CircularProgress color="inherit" size={50} />
    </Box>
  )
}
