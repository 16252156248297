import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { GridColDef } from '@mui/x-data-grid-premium'

import { Source } from './Source/Source'
import { Standards } from './Standards/Standards'
import { ProcessingLocation } from './ProcessingLocation/ProcessingLocation'
import { RowControls } from './RowControls/RowControls'
import { DeleteReplace } from './DeleteReplace/DeleteReplace'
import { Weight } from './Weight/Weight'
import { WeightHeader } from './Weight/WeightHeader'
import { ScoreCell } from './Score/ScoreCell'
import { ScoreHeader } from './Score/ScoreHeader'
import { ScoreInputs, getScore } from '../../util/getScore'
import { IngredientName } from './IngredientName/IngredientName'
import { selectContentfulIngredientImpactData } from '@/state/recipe'
import { IngredientStandard, RecipeIngredient, RecipeNestedIngredient } from '@/state/recipe/recipe.state'
import { selectPageView, DRAFT } from '@/state/pageSettings'
import { SharedHeader } from './SharedHeader'
import { selectGridSettings } from '@/state/preferences'
import { useGridWidth } from './useGridWidth'
import { Vendor } from './Vendor/Vendor'
import { OriginLocationMapping } from '@/state/originLocations'
import { selectIsSupplierConnect } from '@/state/organization'

const defaultColWidths = {
  delete_replace: { saved: 0, draft: 54 },
  ingredient: { saved: 140, draft: 100 },
  vendor: { saved: 30, draft: 30 },
  sourcing_location: { saved: 66, draft: 100 },
  sourcing_standards: { saved: 66, draft: 106 },
  processing_location: { saved: 66, draft: 118 },
  up_down: { saved: 0, draft: 40 },
  weight: { saved: 100, draft: 140 },
}

interface GridCellIngredient extends ScoreInputs {
  ingredient: RecipeIngredient | RecipeNestedIngredient
  sources: OriginLocationMapping[]
  availableStandards: IngredientStandard[]
}
interface GridCellValue {
  ingredient: GridCellIngredient
}
type ColDef = GridColDef<GridCellValue, GridCellIngredient, string | number>

function useImpactColumns(): ColDef[] {
  const allImpactItems = useSelector(selectContentfulIngredientImpactData)
  const gridSettings = useSelector(selectGridSettings)
  const view = useSelector(selectPageView)
  const impactColumns = allImpactItems.filter((impact) => !gridSettings.hiddenCols.includes(impact.key))
  const gridWidth = useGridWidth()

  return useMemo<ColDef[]>(() => {
    // Calculate the combined width of the pinned columns using their actual or default widths
    const pinnedColsWidth = Object.entries(defaultColWidths).reduce(
      (acc, [field, widths]) =>
        field in gridSettings.colWidths
          ? acc + gridSettings.colWidths[field]
          : acc + widths[view === DRAFT ? 'draft' : 'saved'],
      view === DRAFT ? 10 : 0
    )

    // Calculate minWidth for each impact column that doesn't have a specified colWidth
    const minWidth = impactColumns.length ? Math.max((gridWidth - pinnedColsWidth) / impactColumns.length, 70) : 70 // Value based on 1440px screen with the nine standard impact columns
    return impactColumns.map((impact) => {
      const title = impact.title === 'Carbon Footprint' ? impact.subtitle : impact.title
      return {
        field: impact.key,
        headerName: title,
        sortable: false,
        hideable: true,
        renderCell: (params) => (
          <ScoreCell
            {...params}
            title={title}
            description={impact.description}
            valuesMap={impact.valuesMap}
            ingredient={params.row.ingredient.ingredient}
          />
        ),
        valueFormatter: (value) => {
          const score = getScore(value)
          const fullValue = gridSettings.scoringMethod === 'perKilogram' ? score.rawScore : score.contribution
          return score.scoreRange || fullValue
        },
        // `flex` doesn't work together with `width`. If you set flex and width, flex will override width.
        // So if user has resized a column, need to disable flex, or the size won't stick
        flex: Object.keys(gridSettings.colWidths).length ? 0 : 1,
        width: impact.key in gridSettings.colWidths ? gridSettings.colWidths[impact.key] : null,
        minWidth: impact.key === 'raw_soc_impact' ? 110 : minWidth,
        renderHeader: () => <ScoreHeader title={title} description={impact.description} units={impact.units} />,
      }
    })
  }, [gridSettings.colWidths, gridSettings.scoringMethod, gridWidth, impactColumns, view])
}

export function useGridColumns(): ColDef[] {
  const gridSettings = useSelector(selectGridSettings)
  const isSupplierConnect = useSelector(selectIsSupplierConnect)
  const view = useSelector(selectPageView)
  const impactColumns = useImpactColumns()

  return useMemo<ColDef[]>(
    () => [
      {
        field: 'delete_replace',
        sortable: false,
        hideable: false,
        resizable: false,
        disableExport: true,
        headerName: '',
        width: defaultColWidths.delete_replace[view === DRAFT ? 'draft' : 'saved'],
        maxWidth: defaultColWidths.delete_replace[view === DRAFT ? 'draft' : 'saved'],
        renderCell: (params) => <DeleteReplace {...params} />,
      },
      {
        field: 'ingredient',
        sortable: false,
        hideable: false,
        // as impactColumns and vendor columns are hidden for latis LITE user we can flex some columns
        // `flex` doesn't work together with `width`. If you set flex and width, flex will override width.
        // So if user has resized a column, need to disable flex, or the size won't stick
        flex: isSupplierConnect ? 1 : 0,
        width: isSupplierConnect
          ? null
          : 'ingredient' in gridSettings.colWidths
          ? gridSettings.colWidths.ingredient
          : defaultColWidths.ingredient[view === DRAFT ? 'draft' : 'saved'],
        renderCell: (params) => <IngredientName {...params} />,
        valueFormatter: (value: GridCellIngredient) => value.ingredient.name,
        renderHeader: () => <SharedHeader title="Ingredient" />,
        colSpan: (value) => ('nested_product_id' in value.ingredient ? 4 : 1),
      },
      {
        field: 'sourcing_location',
        sortable: false,
        renderCell: (params) => <Source {...params} />,
        valueFormatter: (value: GridCellIngredient) =>
          'origin_location_name' in value.ingredient ? value.ingredient.origin_location_name : null,
        renderHeader: () => <SharedHeader title="Sourcing Location" />,
        flex: isSupplierConnect ? 1 : 0,
        width: isSupplierConnect
          ? null
          : 'sourcing_location' in gridSettings.colWidths
          ? gridSettings.colWidths.sourcing_location
          : defaultColWidths.sourcing_location[view === DRAFT ? 'draft' : 'saved'],
        minWidth: view === DRAFT ? 70 : null,
      },
      {
        field: 'sourcing_standards',
        sortable: false,
        renderHeader: () => <SharedHeader title="Standards" />,
        renderCell: (params) => <Standards {...params} />,
        valueFormatter: (value: GridCellIngredient) =>
          'standards' in value.ingredient
            ? value.ingredient.standards.map((standard) => standard.title).join(', ')
            : null,
        minWidth: view === DRAFT ? 64 : null,
        flex: isSupplierConnect ? 1 : 0,
        width: isSupplierConnect
          ? null
          : 'sourcing_standards' in gridSettings.colWidths
          ? gridSettings.colWidths.sourcing_standards
          : defaultColWidths.sourcing_standards[view === DRAFT ? 'draft' : 'saved'],
      },
      {
        field: 'processing_location',
        sortable: false,
        renderHeader: () => <SharedHeader title="Processing Location" />,
        renderCell: (params) => <ProcessingLocation {...params} />,
        valueFormatter: (value: GridCellIngredient) =>
          'processing_location_name' in value.ingredient ? value.ingredient.processing_location_name : null,
        minWidth: view === DRAFT ? 74 : null,
        flex: isSupplierConnect ? 1 : 0,
        width: isSupplierConnect
          ? null
          : 'processing_location' in gridSettings.colWidths
          ? gridSettings.colWidths.processing_location
          : defaultColWidths.processing_location[view === DRAFT ? 'draft' : 'saved'],
      },
      ...(!isSupplierConnect
        ? [
            {
              field: 'vendor',
              sortable: false,
              width:
                'vendor' in gridSettings.colWidths
                  ? gridSettings.colWidths.vendor
                  : defaultColWidths.vendor[view === DRAFT ? 'draft' : 'saved'],
              minWidth: 36,
              renderCell: (params) => <Vendor {...params} />,
              valueFormatter: (value: GridCellIngredient) =>
                'nested_product_id' in value.ingredient ? value.ingredient.vendorName || 'Unknown Vendor' : '',
              renderHeader: () => <SharedHeader title="Vendor" />,
            },
            {
              field: 'up_down',
              sortable: false,
              hideable: false,
              disableExport: true,
              resizable: false,
              headerName: '',
              width: defaultColWidths.up_down[view === DRAFT ? 'draft' : 'saved'],
              maxWidth: defaultColWidths.up_down[view === DRAFT ? 'draft' : 'saved'],
              renderCell: (params) => <RowControls {...params} />,
            },
          ]
        : []), // hide vendor and up_down columns for SupplierConnect user
      {
        field: 'weight',
        sortable: false,
        resizable: false,
        headerName: 'Weight',
        renderCell: (params) => <Weight {...params} />,
        valueFormatter: (value: GridCellIngredient) => value.ingredient.weight,
        width: defaultColWidths.weight[view === DRAFT ? 'draft' : 'saved'],
        renderHeader: () => <WeightHeader />,
      },
      ...(!isSupplierConnect ? impactColumns : []), // hide impact columns for SupplierConnect user
    ],
    [gridSettings.colWidths, impactColumns, view, isSupplierConnect]
  )
}
