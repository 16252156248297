import React, { FC, ReactNode, useState } from 'react'
import { useSelector } from 'react-redux'
import { Accordion, AccordionDetails, Box, Tabs, Tab } from '@howgood/design'

import { selectIsSupplierConnectCreateNew, selectIsProductLoading } from '@/state/productOverview'
import { AccordionCardHeader } from '@/components/Scores/AccordionCardHeader'
import { Packaging } from './Packaging'
import { SupplierGeneral } from './General/SupplierGeneral'

interface TabPanelProps {
  children?: ReactNode
  index: number
  value: number
}

const TabPanel: FC<TabPanelProps> = ({ children, value, index }) => {
  return (
    <div role="tabpanel" hidden={value !== index} id={`product-info-tabpanel-${index}`}>
      {value === index && <>{children}</>}
    </div>
  )
}

export const ProductInfoSupplier: FC = () => {
  const isLoading = useSelector(selectIsProductLoading)
  const isSupplierConnectCreateNew = useSelector(selectIsSupplierConnectCreateNew)
  const [tabValue, setTabValue] = useState(0)

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  const tabPanels = [
    <TabPanel value={tabValue} index={0} key={0}>
      <SupplierGeneral />
    </TabPanel>,
    <TabPanel value={tabValue} index={1} key={1}>
      <Packaging />
    </TabPanel>,
  ]

  return (
    <Accordion defaultExpanded={!isSupplierConnectCreateNew} data-testid="supplier-product-information-panel">
      <AccordionCardHeader
        id="supplier-product-information-card-header"
        title={isSupplierConnectCreateNew ? 'Add Packaging and Distribution Information' : 'Packaging and Distribution'}
      />
      {!isLoading && (
        <AccordionDetails>
          <Box p={1} border="1px solid lightgray" borderRadius={1}>
            <Box borderBottom={1} borderColor="divider" mb={1}>
              <Tabs
                id="supplier-product-info-tabs"
                value={tabValue}
                onChange={handleTabChange}
                aria-label="Product information tabs"
              >
                <Tab id="supplier-general-tab" label="General" />
                <Tab id="supplier-packaging-tab" label="Packaging" />
              </Tabs>
            </Box>
            {tabPanels}
          </Box>
        </AccordionDetails>
      )}
    </Accordion>
  )
}
