import React, { FC } from 'react'
import { Surface, Symbols } from 'recharts'
import { Props as LegendContentProps } from 'recharts/types/component/DefaultLegendContent'
import { Text, Stack } from '@howgood/design'
import { FormulaIconBadge } from '@/components/Shared/IngredientSearch/FormulaIconBadge'
import { MaterialGranularityBreakdownChartData } from './MaterialGranularityBreakdownChart'
import { FORMULA_ICON_BADGE } from '@/contentfully'
import {
  MATERIAL_GRANULARITY_STANDARD_INGREDIENTS,
  MATERIAL_GRANULARITY_VENDOR_VERIFIED,
  MATERIAL_GRANULARITY_VENDOR_VERIFIED_PLUS,
} from '@/features/ProductPage/panels/ImpactReduction/ImpactReduction.hooks'

interface MaterialGranularityBreakdownChartLegendContentProps extends LegendContentProps {
  data: MaterialGranularityBreakdownChartData
}

export const MaterialGranularityBreakdownChartLegendContent: FC<MaterialGranularityBreakdownChartLegendContentProps> = ({
  payload,
  data,
}) => {
  return (
    <Stack gap={1.5}>
      <Text variant="caption" color="black">
        Most Granular
      </Text>
      {payload.map(({ value, color }, idx) => (
        <Stack direction="row" alignItems="center" key={value}>
          <Surface
            width={12}
            height={12}
            style={{
              minWidth: 12,
            }}
            viewBox={{
              x: 0,
              y: 0,
              width: 12,
              height: 12,
            }}
          >
            <Symbols cx={6} cy={6} type="square" min={120} size={120} fill={color} />
          </Surface>
          <FormulaIconBadge
            badge={
              value === MATERIAL_GRANULARITY_VENDOR_VERIFIED_PLUS
                ? FORMULA_ICON_BADGE.vendorVerifiedPlus
                : value === MATERIAL_GRANULARITY_VENDOR_VERIFIED
                ? FORMULA_ICON_BADGE.vendorVerified
                : value === MATERIAL_GRANULARITY_STANDARD_INGREDIENTS
                ? FORMULA_ICON_BADGE.standardIngredient
                : FORMULA_ICON_BADGE.purchasedMaterial
            }
          />
          <Text variant="legend">
            {value}: {data[idx].pct}%
          </Text>
        </Stack>
      ))}
      <Text variant="caption" color="black">
        Least Granular
      </Text>
    </Stack>
  )
}
