import React, { FC, useMemo } from 'react'
import { Autocomplete, MenuItem, TextField, Tooltip } from '@howgood/design'
import { IProductStandard } from '@/records'
import { useDispatch, useSelector } from 'react-redux'
import { selectProductCertificationStandards } from '@/state/productStandards'
import {
  selectEditingFilter,
  selectStandardsFilters,
  setEditingFilter,
  updateFiltersUrlAndProducts,
} from '@/state/products'
import { selectIsProductFiltersLoading } from '@/selectors/selectIsProductFiltersLoading'

interface StandardOption extends IProductStandard {
  value: number
}

export const StandardsFilter: FC = () => {
  const dispatch = useDispatch()
  const isLoading = useSelector(selectIsProductFiltersLoading)
  const availableStandards = useSelector(selectProductCertificationStandards)
  const selectedStandards = useSelector(selectStandardsFilters)
  const editingFilter = useSelector(selectEditingFilter)
  const id = 'standards-search'

  const value = useMemo(() => availableStandards.filter((s) => selectedStandards.includes(s.id)) || [], [
    availableStandards,
    selectedStandards,
  ])

  const handleChange = async (updatedStandards: StandardOption[]) => {
    dispatch(updateFiltersUrlAndProducts({ updatedFilters: { standardsIds: updatedStandards.map((s) => s.id) } }))
    dispatch(setEditingFilter(id))
  }

  return (
    <Tooltip title="Filter by formula certification" placement="top">
      <Autocomplete
        data-pendo-id="SearchCertifications"
        id={id}
        data-testid={id}
        aria-label="Product standards"
        disabled={isLoading}
        multiple
        value={value}
        onChange={(_event, ops) => handleChange(ops)}
        options={availableStandards}
        filterSelectedOptions
        isOptionEqualToValue={(option, val) => option.id === val.id}
        renderInput={(params) => (
          <TextField
            {...params}
            autoFocus={editingFilter === id}
            placeholder={selectedStandards.length ? '' : 'Certification'}
            fullWidth
          />
        )}
        renderOption={(props, option) => (
          <MenuItem {...props} key={option.id} value={option.id} onClick={() => handleChange([...value, option])}>
            {option.label}
          </MenuItem>
        )}
        fullWidth
        componentsProps={{ popper: { style: { width: 'fit-content' }, placement: 'bottom-start' } }}
      />
    </Tooltip>
  )
}
