import React from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import { Box, Tab, Tabs } from '@howgood/design'

import {
  scenarioTabs,
  selectCurrentInitiativeGroupByMethod,
  selectScenarioPageTab,
  setScenarioPageTab,
} from '@/state/initiatives'
import { TabPanel } from '../../ProductPage/panels/TabPanel'
import { WorkspacesTab } from './WorkspacesTab'
import { FormulasTab } from './FormulasTab'
import { VendorsTab } from './VendorsTab'
import { updateFiltersUrlAndProducts } from '@/state/products'

export const ScenarioTabs = () => {
  const dispatch = useDispatch()
  const selectedTab = useSelector(selectScenarioPageTab)
  const groupByMethod = useSelector(selectCurrentInitiativeGroupByMethod) // 'workspaces' or 'vendors'

  const tabValue = scenarioTabs.indexOf(selectedTab)

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    const newTab = scenarioTabs[newValue]
    batch(() => {
      dispatch(setScenarioPageTab(newTab))
      dispatch(updateFiltersUrlAndProducts({ updatedFilters: { vendors: [] } }))
    })
  }

  return (
    <>
      <Box borderBottom={1} borderColor="divider" mb={1}>
        <Tabs id="scenario-tabs" value={tabValue} onChange={handleTabChange} aria-label="Scenario detail tabs">
          {groupByMethod === 'workspaces' && <Tab value={0} id="workspaces-tab" label="Workspaces" />}
          {groupByMethod === 'vendors' && <Tab value={1} id="vendors-tab" label="Vendors" />}
          <Tab value={2} id="forumulas-tab" label="Formulas" />
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={0}>
        <WorkspacesTab />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <VendorsTab />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <FormulasTab />
      </TabPanel>
    </>
  )
}
