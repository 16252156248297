import React, { FC, ReactNode } from 'react'
import { Alert, AlertTitle, Box, Text, Stack } from '@howgood/design'

interface Props {
  children?: ReactNode
  index: number
  value: number
  ['data-testid']?: string
  warningText?: string
}

export const TabPanel: FC<Props> = ({ ['data-testid']: dataTestId, children, value, index, warningText }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`impact-tabpanel-${index}`}
      aria-labelledby={`impact-tab-${index}`}
    >
      {value === index && (
        <Stack data-testid={dataTestId}>
          {warningText && (
            <Box mb={2}>
              <Alert severity="warning">
                <AlertTitle>Warning - Scores may not be accurate</AlertTitle>
                <Text
                  data-testid="formula-warning-text"
                  fontSize={14}
                  lineHeight="20px"
                  color="inherit"
                >{`${warningText}`}</Text>
              </Alert>
            </Box>
          )}
          <Box>{children}</Box>
        </Stack>
      )}
    </div>
  )
}
