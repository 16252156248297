import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'
import { ecoScoreToLetter } from '@/utils/ecoscore'

import ecoA from '@/assets/images/eco-score/eco-a.png'
import ecoB from '@/assets/images/eco-score/eco-b.png'
import ecoC from '@/assets/images/eco-score/eco-c.png'
import ecoD from '@/assets/images/eco-score/eco-d.png'
import ecoE from '@/assets/images/eco-score/eco-e.png'
import ecoAFull from '@/assets/images/eco-score/eco-a-full.png'
import ecoBFull from '@/assets/images/eco-score/eco-b-full.png'
import ecoCFull from '@/assets/images/eco-score/eco-c-full.png'
import ecoDFull from '@/assets/images/eco-score/eco-d-full.png'
import ecoEFull from '@/assets/images/eco-score/eco-e-full.png'
import ecoAWrap from '@/assets/images/eco-score/eco-a-wrap.png'
import ecoBWrap from '@/assets/images/eco-score/eco-b-wrap.png'
import ecoCWrap from '@/assets/images/eco-score/eco-c-wrap.png'
import ecoDWrap from '@/assets/images/eco-score/eco-d-wrap.png'
import ecoEWrap from '@/assets/images/eco-score/eco-e-wrap.png'

type EcoScoreVariant = 'small' | 'full' | 'wrapped'
interface EcoScoreVariantData {
  images: Record<'A' | 'B' | 'C' | 'D' | 'E', string>
  width: string
}
interface EcoScoreImageProps {
  score: number
  variant?: EcoScoreVariant
}

const variants: Record<EcoScoreVariant, EcoScoreVariantData> = {
  full: {
    images: {
      A: ecoAFull,
      B: ecoBFull,
      C: ecoCFull,
      D: ecoDFull,
      E: ecoEFull,
    },
    width: '90px',
  },
  small: {
    images: {
      A: ecoA,
      B: ecoB,
      C: ecoC,
      D: ecoD,
      E: ecoE,
    },
    width: '25px',
  },
  wrapped: {
    images: {
      A: ecoAWrap,
      B: ecoBWrap,
      C: ecoCWrap,
      D: ecoDWrap,
      E: ecoEWrap,
    },
    width: '120px',
  },
}

const useStyles = createUseStyles<string, Pick<EcoScoreImageProps, 'variant'>>({
  scoreWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  score: {
    width: ({ variant }) => variants[variant].width,
  },
})

export const EcoScoreImage: FC<EcoScoreImageProps> = ({ score: scoreRaw, variant = 'full' }) => {
  const score = typeof scoreRaw === 'string' ? +scoreRaw : scoreRaw
  const classes = useStyles({ variant })
  const level = ecoScoreToLetter(score)
  const image = variants[variant].images[level]

  return (
    <div className={classes.scoreWrapper}>
      {typeof score === 'number' ? <img src={image} alt={level} className={classes.score} /> : '--'}
    </div>
  )
}
