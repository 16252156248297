import React, { FC } from 'react'
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import { Stack } from './Stack'

export interface IconProps
  extends Pick<FontAwesomeIconProps, 'id' | 'icon' | 'size' | 'fontSize' | 'color' | 'spin' | 'visibility'> {
  ['data-testid']?: string
  ['data-pendo-id']?: string
  containerSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
}

export const Icon: FC<IconProps> = ({
  id,
  ['data-testid']: dataTestId,
  ['data-pendo-id']: dataPendoId,
  icon,
  fontSize,
  size,
  color,
  spin,
  visibility,
  containerSize,
}) => {
  const sizeMap = {
    xs: 14,
    sm: 16,
    md: 20,
    lg: 24,
    xl: 28,
  }
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      width={containerSize ? sizeMap[containerSize] : 'unset'}
      height={containerSize ? sizeMap[containerSize] : 'unset'}
      flex={'0 0 auto'}
    >
      <FontAwesomeIcon
        id={id}
        data-testid={dataTestId}
        data-pendo-id={dataPendoId}
        icon={icon}
        size={size}
        fontSize={fontSize}
        color={color}
        spin={spin}
        visibility={visibility}
      />
    </Stack>
  )
}
