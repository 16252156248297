import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { faScroll } from '@fortawesome/pro-solid-svg-icons'
import { Button } from '@howgood/design'

import { ReportsDrawer } from './ReportsDrawer'
import { selectIsSupplierConnect } from '@/state/organization'
import { setLiteUserPaywallDialogState } from '@/state/liteUserPaywallDialog'

export const ReportsButton: FC = () => {
  const isSupplierConnect = useSelector(selectIsSupplierConnect)
  const dispatch = useDispatch()

  const [reportsDrawerOpen, setReportsDrawerOpen] = React.useState(false)

  const handleButtonClicked = () => {
    if (isSupplierConnect) {
      dispatch(
        setLiteUserPaywallDialogState({
          open: true,
        })
      )
      return
    }
    setReportsDrawerOpen(true)
  }

  return (
    <>
      <Button
        id="reports-button"
        data-testid="reports-button"
        size="small"
        startIcon={faScroll}
        onClick={handleButtonClicked}
      >
        Reports
      </Button>
      <ReportsDrawer reportsDrawerOpen={reportsDrawerOpen} closeReportsDrawer={() => setReportsDrawerOpen(false)} />
    </>
  )
}
