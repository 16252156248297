import React, { ChangeEvent, FC, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { Button } from '@howgood/design'
import { SearchOption } from '../types'
import { MultiSelectOption } from './MultiSelectOption'

interface MultiSelectListBodyProps {
  onChange: (options: SearchOption[]) => void
  options: SearchOption[]
  selected: SearchOption[]
}

const useStyles = createUseStyles({
  input: {
    width: '100%',
    marginTop: 14,
    marginBottom: 4,
  },
  empty: {
    padding: '4px 10px',
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '10px 5px',
    borderTop: '1px solid #E0DCDC',

    '& > button': {
      margin: '0 5px',
    },
  },
  header: {
    padding: '10px',
  },
  tag: {
    margin: '0 3px',
  },
})

export const MultiSelectListBody: FC<MultiSelectListBodyProps> = ({ options, onChange, selected = [] }) => {
  const classes = useStyles()
  const [selectedOptions, setSelectedOptions] = useState<SearchOption[]>(selected)
  const [inputValue, setInputValue] = useState('')

  const handleInputChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setInputValue(value)
  }

  const isChecked = (option: SearchOption) => selectedOptions.some((o) => o.value === option.value)

  const handleSelectOption = (option: SearchOption) => {
    if (isChecked(option)) {
      setSelectedOptions(selectedOptions.filter((el) => el.value !== option.value))
    } else {
      setSelectedOptions([...selectedOptions, option])
    }
  }

  const clearSelection = () => {
    setSelectedOptions([])
  }

  const compareSelection = () => {
    setInputValue('')
    onChange(selectedOptions)
  }

  const selectAll = () => {
    setSelectedOptions(options)
  }

  const filterOptions = (option: SearchOption) => {
    const needle = inputValue.toLowerCase()
    const haystack = option.label.toLowerCase()

    return haystack.includes(needle)
  }

  return (
    <>
      <div className={classes.header}>
        <input
          className="bp3-input bp3-fill"
          onChange={handleInputChange}
          value={inputValue}
          autoFocus
          placeholder="Search options..."
        />
      </div>
      {!options.length && !inputValue && <div className={classes.empty}>Type to search for formulations</div>}
      {!options.length && inputValue && <div className={classes.empty}>No formulations found</div>}
      {options.filter(filterOptions).map((option: SearchOption) => (
        <MultiSelectOption
          key={option.value}
          checked={isChecked(option)}
          onClick={() => handleSelectOption(option)}
          option={option}
        />
      ))}
      {!!options.length && (
        <div className={classes.footer}>
          <Button onClick={selectAll}>Select All</Button>
          <Button onClick={clearSelection}>Clear selection</Button>
          <Button onClick={compareSelection} color="primary">
            Apply
          </Button>
        </div>
      )}
    </>
  )
}
