import React, { FC } from 'react'
import { faUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons'
import { IconButton } from '@howgood/design'
import { useNavigateToFormula } from '@/hooks'

interface Props {
  productId: number
}

export const NestedProductLink: FC<Props> = ({ productId }) => {
  const navigateToFormula = useNavigateToFormula()

  const handleOpenProduct = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.stopPropagation()
    navigateToFormula(productId.toString(), true)
  }

  return (
    <IconButton data-testid="nested-product-link" size="small" onClick={handleOpenProduct} icon={faUpRightFromSquare} />
  )
}
