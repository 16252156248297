import { ImpactScore, fetchProductIngredients, fetchHelperV2 } from '@/api'
import { ProductRouteLegs } from '@/records'
import { searchBetterThan } from '@/utils/productSearch'
import {
  Recipe,
  ImpactData,
  NestedImpactResponse,
  NestedIngredientResponse,
  NestedProductResponse,
  RecipeIngredient,
  RecipeNestedIngredient,
  PackagingResponse,
} from './recipe.state'

const getFlattenedImpacts = (
  nestedIngredientImpacts: (NestedIngredientResponse | NestedProductResponse)[]
): (NestedIngredientResponse | Omit<NestedProductResponse, 'impact_scores'>)[] => {
  const results = nestedIngredientImpacts.map((ingredient) => {
    if ('impact_scores' in ingredient) {
      const { ['impact_scores']: ingredients, ...productScores } = ingredient
      const ingredientScores = getFlattenedImpacts(ingredients)
      return [productScores, ...ingredientScores]
    } else {
      return ingredient
    }
  })
  return results.flat() // Unpack the array of nested ingredients
}

export const getV2IngredientsPayload = (ingredients: (RecipeIngredient | RecipeNestedIngredient)[]) => {
  return ingredients
    .filter((ingredient) => ingredient.isTopLevel)
    .map((ingredient) => {
      const shared = {
        id: ingredient.product_ingredient_id,
        name: ingredient.name,
        // Since saving only top-level ingredients, positions will be unique
        position: ingredient.position,
        weight: ingredient.weight,
        route_legs: ingredient.route_legs,
      }
      if ('nested_product_id' in ingredient) {
        return {
          ...shared,
          nested_product: {
            id: ingredient.nested_product_id,
          },
        }
      }
      return {
        ...shared,
        ingredient: {
          id: ingredient.ingredient_id,
          base: {
            id: ingredient.base_id,
          },
          origin_location: {
            id: ingredient.origin_location_id,
          },
        },
        processing_location: ingredient.processing_location_id ? { id: ingredient.processing_location_id } : undefined,
        standards: (ingredient.standards || [])
          .filter((standard) =>
            ingredient.availableStandards
              .map((item) => item.identifier)
              .filter((x) => x)
              .includes(standard.identifier)
          )
          .map((standard) => ({ identifier: standard.identifier })),
      }
    })
}

export const getV2Payload = (recipe: Recipe) => {
  return {
    // sim, TODO
    // org, TODO
    // enable_sumprod_in_metrics, TODO
    // id, // If product ID is specified, you can send only attributes that have changed (not implemented yet on backend)
    agribalyze_category_value: recipe.agribalyze_category?.value ?? undefined,
    agribalyze_category_is_beverage: recipe.agribalyze_category?.is_beverage ?? undefined,
    annual_sales_volume: recipe.sales_distribution.annual_sales_volume || 0,
    // craveability: null, // TODO
    manufacturing_type: recipe.sales_distribution.manufacturing_type?.id
      ? { id: recipe.sales_distribution.manufacturing_type?.id }
      : undefined,
    region: recipe.sales_distribution.manufacturing_region?.id
      ? { id: recipe.sales_distribution.manufacturing_region?.id }
      : undefined,
    requires_cold_storage: recipe.sales_distribution.requires_cold_storage ?? undefined,
    requires_cooking: recipe.sales_distribution.requires_cooking ?? undefined,
    retail_region: recipe.sales_distribution.retail_region?.id
      ? { id: recipe.sales_distribution.retail_region?.id }
      : undefined,
    storage_region: recipe.sales_distribution.storage_region?.id
      ? { id: recipe.sales_distribution.storage_region?.id }
      : undefined,
    input_weight_unit: recipe.input_weight_unit,
    weight_kg: recipe.sales_distribution.weight_kg,
    standards: (recipe.standards || []).map((standard) => ({ identifier: standard.identifier })),
    packaging: (recipe.packaging || []).map((item) => ({
      id: item.id,
      material: { id: item.material?.id },
      shape: { id: item.shape?.id },
      packaging_unit: item.packaging_unit,
      consumer_units: item.consumer_units,
      region: item.region?.id ? { id: item.region?.id, name: item.region?.name } : null,
      packaging_material_weight: item.packaging_material_weight,
      packaging_uses: item.packaging_uses,
    })),
    route_legs: {
      mtr: (recipe.transportation.mtr || []).map((item) => ({
        waypoint: { id: item.waypoint.id },
        mode: { id: item.mode.id },
        distance_override: item.distance_override,
      })),
      mts: [],
    },
    ingredients: getV2IngredientsPayload(recipe.ingredients),
    temperature: recipe.temperature,
  }
}

export const fetchImpactScores = async (recipe: Recipe): Promise<ImpactData> => {
  const v2Payload = getV2Payload(recipe)

  const v2ImpactResponse = await fetchHelperV2<ImpactScore>({
    url: 'products/impact-score/',
    method: 'POST',
    body: JSON.stringify(v2Payload),
  })

  if (v2ImpactResponse) {
    if (Object.hasOwn(v2ImpactResponse, 'howgood_total_impact') && v2ImpactResponse.howgood_total_impact !== null) {
      v2ImpactResponse.betterThan = await searchBetterThan(v2ImpactResponse.howgood_total_impact)
    }

    const { ['impact_scores']: ingredientImpacts, ...productImpacts } = v2ImpactResponse as NestedImpactResponse
    const flattenedImpacts = getFlattenedImpacts(ingredientImpacts)

    return {
      product: productImpacts,
      ingredients: flattenedImpacts,
      weights: (v2ImpactResponse as NestedImpactResponse).weights, // THESE WEIGHTS (TOP-LEVEL ONLY) ARE NOT USED
      maxValues: {}, // Max values are calculated in the reducer
      nonNullFields: [], // Fields are determined in the reducer
      timestamp: new Date().toString(),
    }
  }

  // Return null if the API call failed
  return null
}

// Compute the total weight of all direct children of this ingredient
function getTotalWeightOfChildren(
  ingredient: RecipeNestedIngredient,
  allIngredients: (RecipeIngredient | RecipeNestedIngredient)[]
) {
  // We can use the nested product id to identify the children, but the same nested ingredient may exist
  // more than once at the top level, so use the dotPath to select only direct children
  const totalWeight = allIngredients.reduce((total, recipeItem) => {
    return recipeItem.product.id === ingredient.nested_product_id &&
      recipeItem.dot_path.startsWith(`${ingredient.dot_path}.`)
      ? total + recipeItem.weight
      : total
  }, 0)
  return totalWeight
}

export const rebalanceWeights = async (recipe: Recipe): Promise<Recipe> => {
  // Create array of top-level ingredientweights, where fields with isAutoWeight have weight set to null
  const weights = recipe.ingredients
    .filter((ingredient) => ingredient.isTopLevel)
    .map((ingredient) => (ingredient.isAutoWeight ? null : ingredient.weight))

  // Send the top-level weights array to the API for redistribution (non-null values are unchanged)
  const distributedWeights = await fetchHelperV2<number[]>({
    url: 'products/calculate-weights',
    method: 'POST',
    body: JSON.stringify(weights),
  })

  // Backend returns a tiny value rather than zero and may return a negative value, so round and make >= zero
  const refactoredWeights = distributedWeights.map((weight) => Math.max(Math.round(weight * 10000) / 10000, 0))

  const refactoredWeightsTotal = refactoredWeights.reduce((total, weight) => total + weight, 0)

  // Turn the array of weight values into percentages for the top level ingredient flat_weights
  const rebalancedFlatWeights = refactoredWeights.map((weight) => (weight / refactoredWeightsTotal) * 100)

  let weightIndex = 0 // Used to index the array of redistributed top-level weights
  let currentDepth = 1 // Indicates the depth of the current ingredient in the tree
  const nestedProductInfo: { parentFlatWeight: number; totalChildWeight: number }[] = [] // Stack for retrieving flat_weight of parent
  const weightAdjustedIngredients = recipe.ingredients.map((ingredient) => {
    if (ingredient.isTopLevel) {
      currentDepth = 1
      const weight = refactoredWeights[weightIndex]
      const flatWeight = rebalancedFlatWeights[weightIndex]
      weightIndex++

      // If it's a nested ingredient, push nested product info onto the stack for use by children
      if ('nested_product_id' in ingredient) {
        // Get total weight of all direct children of this ingredient so we can calculate new flat weights
        const totalChildWeight = getTotalWeightOfChildren(ingredient, recipe.ingredients)
        nestedProductInfo.push({ parentFlatWeight: flatWeight, totalChildWeight: totalChildWeight })
      }

      return {
        ...ingredient,
        weight: weight,
        flat_weight: flatWeight,
      }
    } else {
      // If current ingredient is at higher level than previous, unwind the stack as far as needed
      if (ingredient.flat_position.length < currentDepth) {
        for (let i = 0; i < currentDepth - ingredient.flat_position.length; i++) {
          nestedProductInfo.pop()
        }
      }

      // Update the current depth for use next time around
      currentDepth = ingredient.flat_position.length

      // Compute child ingredient's flat_weight based on the parent's flat_weight and sibling weights
      const { parentFlatWeight, totalChildWeight } = nestedProductInfo.slice(-1)[0]
      const ingredientFlatWeight = (ingredient.weight / totalChildWeight) * parentFlatWeight

      // If it's a nested ingredient, push its nested product info onto the stack for use by children
      if ('nested_product_id' in ingredient) {
        // Get total weight of all direct children of this ingredient so we can calculate new flat weights
        const totalWeight = getTotalWeightOfChildren(ingredient, recipe.ingredients)
        nestedProductInfo.push({ parentFlatWeight: ingredientFlatWeight, totalChildWeight: totalWeight })
      }

      return {
        ...ingredient,
        flat_weight: ingredientFlatWeight,
      }
    }
  })

  return {
    ...recipe,
    ingredients: weightAdjustedIngredients,
  }
}

export const loadIngredients = async (id: string | number, isThirdParty: boolean) => {
  if (isThirdParty) {
    return []
  }
  try {
    return await fetchProductIngredients(id)
  } catch (e) {
    return []
  }
}

export async function fetchPackaging(id: number) {
  return await fetchHelperV2<PackagingResponse>({ url: `products/${id}/packaging/`, method: 'GET' })
}

export async function fetchTransportation(id: number): Promise<ProductRouteLegs> {
  return await fetchHelperV2<ProductRouteLegs>({
    url: `products/${id}/routes/`,
    method: 'GET',
  })
}
