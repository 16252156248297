import { createUseStyles } from 'react-jss'
import { theme } from '@howgood/design'
export const useProductOverviewExportContainerStyles = createUseStyles({
  container: {
    fontFamily: theme.typography.fontFamily,
    position: 'relative',
    color: '#4d4e54',
    zIndex: 500,
    width: '1392px',
    height: '842px',
    backgroundColor: 'white',
    padding: 24,
    '& p': {
      marginBottom: 10,
    },
    '& ul': {
      marginBottom: 20,
      paddingLeft: 20,
      fontSize: '14px',
    },
  },
  scorecardFirstPage: {
    paddingBottom: 56,
  },
  attributesWrapper: {
    flex: 1,
    textAlign: 'right',
    minHeight: '180px',
  },
  footer: {
    marginTop: '60px',
    zIndex: -10,
    position: 'relative',
    height: '120px',
  },
  logoFooter: {
    position: 'absolute',
    right: 0,
    top: '-72px',
  },
  latisFooter: {
    position: 'absolute',
    top: '10px',
    right: '110px',
    width: '198px',
  },
  disclaimer: {
    width: '900px',
    position: 'absolute',
    top: '8px',
    fontSize: '12px',
    lineHeight: '16px',
    color: '#417C81',
    whiteSpace: 'normal',

    '& > p': {
      padding: '5px 0',
    },
  },
  separator: {
    position: 'absolute',
    zIndex: 10,
    borderTop: '1px solid #417C81',
    width: '100%',
  },
  bold: {
    fontFamily: theme.typography.display.fontFamily,
    fontWeight: theme.typography.display.fontWeight,
  },
  section: {
    display: 'flex',
    marginBottom: 20,
  },
  box: {
    display: 'flex',
    padding: 15,
    background: '#eee',
    marginBottom: 10,
  },
  innerBox: {
    width: '50%',
    padding: '0 5px',
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
  },
  icon: {
    marginRight: 5,
  },
  flexLayout: {
    display: 'flex',
  },
  flex1: {
    flex: 1,
  },
  flexStart: {
    display: 'flex',
    alignItems: 'start',
  },
  listItemText: {
    flex: 1,
    paddingLeft: 4,
  },
  attributeImageWrapper: {
    width: 100,
    minWidth: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  flexLayoutColumn: { display: 'flex', flexDirection: 'column' },
  ratingDescription: {
    paddingLeft: 8,
  },
  footerLogoWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  leftSide: {
    paddingRight: 6,
  },
  rightSide: {
    paddingLeft: 6,
  },
})
