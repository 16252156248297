import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { faWandMagicSparkles } from '@fortawesome/pro-regular-svg-icons'
import { Stack, Drawer, Box, Text, Icon } from '@howgood/design'
import { selectIsAbatementStrategiesOpen, setAbatementStrategiesDrawer } from '@/state/pageSettings'
import { AbatementList } from './AbatementList/AbatementList'
import { PotentialCarbonFootprint } from './PotentialCarbonFootprint'
import { selectSplitTreatmentEnabled } from '@/state/splitio'
import { ButtonActions } from './ButtonActions'

export const AbatementStrategies: FC = () => {
  const dispatch = useDispatch()
  const isOpen = useSelector(selectIsAbatementStrategiesOpen)
  const abatement = useSelector(selectSplitTreatmentEnabled('abatement'))
  const abatementNextSteps = useSelector(selectSplitTreatmentEnabled('abatement_nextstep'))

  const handleClose = () => {
    dispatch(setAbatementStrategiesDrawer(false))
  }

  return (
    <Drawer open={isOpen} onClose={handleClose}>
      <Stack width={632} gap={3} height="100%" justifyContent="space-between">
        <Stack p={0.25} gap={3} flexShrink={2} overflow="auto">
          <Stack gap={-2}>
            <Text variant="h2">Abatement Strategies</Text>
            <Stack direction="row" alignItems="center" gap={0.5}>
              <Text variant="body2">
                Optimize your product’s carbon footprint based on the AI-powered recommendations below
              </Text>
              <Icon icon={faWandMagicSparkles} size="sm" />
            </Stack>
          </Stack>
          <PotentialCarbonFootprint />
          <Stack>
            <Text variant="h2">Recommendations</Text>
            <Text variant="body2">
              These sourcing locations, standards, and agricultural practices will yield the lowest carbon footprint for
              each of the ingredients in your product.
            </Text>
            <Box overflow="auto">
              <AbatementList />
            </Box>
          </Stack>
        </Stack>
        {(abatement || abatementNextSteps) && <ButtonActions />}
      </Stack>
    </Drawer>
  )
}
