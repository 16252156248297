import React, { FC } from 'react'
import { ImpactScore } from '@/api'
import { Stack, theme, Text } from '@howgood/design'
import { createUseStyles } from 'react-jss'
import { ScorecardOverviewTitle } from '@/components/ProductImpact/ExportProductOverview/ScorecardOverviewTitle'
import { ContentfulFieldKey } from '@/contentfully'
import { roundBy } from '@/utils/numbers'
import { useContentfulMetric } from '@/contentfully/hooks'

interface ScorecardImpactScoreProps {
  impactScore: ImpactScore
  field: ContentfulFieldKey
  isCarbonFootprint?: boolean
  isBlueWaterUsage?: boolean
  hideTitle?: boolean
  width?: string
  minWidth?: string
}

const useStyles = createUseStyles({
  overviewTitle: {
    fontFamily: theme.typography.display.fontFamily,
    fontWeight: theme.typography.display.fontWeight,
    fontSize: '22px',
    lineHeight: '26px',
    marginBottom: '12px',
  },
  impactScore: {
    fontFamily: theme.typography.display.fontFamily,
    fontWeight: theme.typography.display.fontWeight,
    fontSize: '64px',
    lineHeight: '74px',
    marginTop: '-8px',
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '5px',
    gap: '5px',
  },
  wrapper: {
    display: 'flex',
    gap: '12px',
  },
})

export const ScorecardImpactScore: FC<ScorecardImpactScoreProps> = ({
  impactScore,
  field,
  isCarbonFootprint,
  isBlueWaterUsage,
  hideTitle,
  width,
  minWidth,
}) => {
  const classes = useStyles()

  const content = useContentfulMetric(field)
  if (!content) {
    return null
  }

  const { title, units, subtitle, lowercaseTitle } = content
  const url =
    !isCarbonFootprint && !isBlueWaterUsage && 'https://latis-help.howgood.com/en/articles/5595721-howgood-impact-score'

  const score = impactScore[field]

  const scoreFormatted = isCarbonFootprint ? roundBy(score, 2) : roundBy(score, 0)
  const titleFormatted = isCarbonFootprint ? title + '*' : title

  return (
    <Stack width={width} minWidth={minWidth}>
      {!hideTitle && <ScorecardOverviewTitle title={titleFormatted} url={url} />}
      <div className={classes.wrapper}>
        <div className={classes.impactScore}>{scoreFormatted}</div>
        {isCarbonFootprint && (
          <div className={classes.info}>
            <div>{units}</div>
            <div>{lowercaseTitle || subtitle}</div>
          </div>
        )}
        {isBlueWaterUsage && (
          <div className={classes.info}>
            <div>m3/ton</div>
            <div>scarcity adjusted blue water usage</div>
          </div>
        )}
      </div>
      {isBlueWaterUsage && (
        <Text variant="subtitle1">Total liters of on-farm blue water required to produce 1kg of ingredient</Text>
      )}
    </Stack>
  )
}
