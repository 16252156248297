import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, CircularProgress, Stack, Text } from '@howgood/design'

import {
  initializeScenarioPage,
  selectCurrentInitiative,
  selectCurrentScenario,
  selectCurrentScenarioId,
  selectisInitiativesLoading,
  selectisScenarioLoading,
} from '@/state/initiatives'
import { useHistory, useParams } from 'react-router'
import { Scores } from '@/components/Scores/Scores'
import { ScenarioTabs } from './ScenarioTabs'
import { Heading } from '@/components/Layout/Heading'
import { getScenarioYearAsNumber } from '@/state/initiatives/initiatives.utils'
import { faFlagSwallowtail } from '@fortawesome/pro-solid-svg-icons'
import { ScenarioSelector } from './ScenarioSelector'

export const Scenario = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const params: { initiative: string; scenario: string } = useParams()
  const scenarioId = +params.scenario
  const currentScenarioId = useSelector(selectCurrentScenarioId)
  const scenario = useSelector(selectCurrentScenario)
  const initiative = useSelector(selectCurrentInitiative)
  const initiativesLoading = useSelector(selectisInitiativesLoading)
  const scenarioLoading = useSelector(selectisScenarioLoading)

  useEffect(() => {
    dispatch(initializeScenarioPage({ scenarioId, history }))
  }, [dispatch, scenarioId, history])

  if (initiativesLoading || scenarioLoading || !currentScenarioId) {
    return (
      <Stack alignItems="center" mt={8}>
        <CircularProgress />
        <Box mt={2}>
          <Text>Loading scenario</Text>
        </Box>
      </Stack>
    )
  }

  return (
    <>
      <Heading
        title={scenario.name}
        year={getScenarioYearAsNumber(scenario.target_date)}
        highlightedBreadcrumbs
        titleBadge={<ScenarioSelector />}
        breadCrumbs={[
          { title: 'Initiatives', url: '/initiatives', icon: faFlagSwallowtail },
          { title: initiative?.name, url: `/initiatives/${initiative?.id}` },
        ]}
      />
      <Scores />
      <ScenarioTabs />
    </>
  )
}
