import React, { FC } from 'react'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { Text } from '@howgood/design'
import { IImpactMetricOption } from '@/records'
import { formatNumber } from '@howgood/utils'

export interface ScoreCellValue {
  id: number
  score: string
  isChartTable: boolean
}
export interface ScoreCellParams extends GridRenderCellParams {
  metric: IImpactMetricOption
  value: ScoreCellValue
}

export const ScoreCell: FC<ScoreCellParams> = ({ metric, value }) => {
  if (!value) {
    return null
  }
  const { id, score, isChartTable } = value

  return (
    <Text
      id={`score-${id}-${metric.value}${isChartTable ? '-chart' : ''}`}
      width="100%"
      textAlign="center"
      data-testid={`score-${id}-${metric.value}${isChartTable ? '-chart' : ''}`}
    >
      {formatNumber(score)}
    </Text>
  )
}
