import { NutritionScore } from '../records/nutrition'

export const nutritionScoreToLetter = (score: number): string => {
  if (typeof score !== 'number') {
    return null
  }

  switch (true) {
    case score <= -1:
      return 'A'
    case score <= 2:
      return 'B'
    case score <= 10:
      return 'C'
    case score <= 18:
      return 'D'
    case score > 18:
      return 'E'
    default:
      return null
  }
}

export const isProjectedNutrition = (nutrition: NutritionScore<number>) => {
  return (
    nutrition?.nutrition_total_energy == null ||
    nutrition?.nutrition_total_proteins == null ||
    nutrition?.nutrition_total_saturated_fat_acid == null ||
    nutrition?.nutrition_total_lipids == null ||
    // temporarily disabled due to the backend bug
    // nutrition?.nutrition_added_sugars == null ||
    nutrition?.nutrition_total_sugars == null ||
    nutrition?.nutrition_total_calcium == null ||
    nutrition?.nutrition_total_sodium === null
  )
}
