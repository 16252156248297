import { ImpactScore, ImpactScoreResponse, InventoriesResponse, MaterialType } from '@/api'
import { IProductStandard, Workspace } from '@/records'
import { OrgUser } from '@/state/organization'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  AggregationsByMetric,
  initializeTopProducts,
  getTopProductsAllMetrics,
  getProductImpactScores,
} from './productDashboard.thunk'
import { Field } from '@/constants/impactScore'

export interface ProductDashboardSettings {
  workspace: Workspace
  metric: Field
  basis: Basis
}

export const BASIS_TYPES = ['kg', 'sales', 'inventories'] as const
export type Basis = typeof BASIS_TYPES[number]

export const allWorkspacesItem = { id: -1, name: 'All Workspaces' } as Workspace

export interface ESIngredient {
  flat_weight: number
  weight: number
  id: number
  ingredient_origin_location_region_name: string
  standards: IProductStandard[]
  name: string
  nested_product_id: number
  product_id: number // The parent's nested_product_id
  impact_score: Record<Field, number>
}

export interface ProductHit {
  date_modified: string
  impact_score: ImpactScore
  ingredients: ESIngredient[]
  material_types: MaterialType[] // Always empty?
  name: string
  pk: number
  standards: IProductStandard[]
  assignee: OrgUser
  inventories: InventoriesResponse[]
  workspaces: Workspace[]
  custom_fields: Record<string, number> // e.g. sales.raw_greenhouse_gas_impact: 1234, where 1234 is sales volume * impact
}

export type ESAggs = Record<string, { value: number }>

export interface State {
  availableMetrics: Field[]
  allWorkspaceIds: number[]
  isLoadingProducts: boolean
  isFetchingImpactData: boolean
  aggregationsByMetric: AggregationsByMetric
  workspaceProductCount: number
  productImpactData: ImpactScoreResponse
  selectedProductId: number
  settings: ProductDashboardSettings
}

export const defaultMetric = 'raw_greenhouse_gas_impact'

export const initialState: State = {
  availableMetrics: [],
  allWorkspaceIds: [],
  isLoadingProducts: false,
  isFetchingImpactData: false,
  aggregationsByMetric: null,
  workspaceProductCount: 0,
  productImpactData: null,
  selectedProductId: null,
  settings: {
    workspace: allWorkspacesItem,
    metric: defaultMetric,
    basis: 'kg',
  },
}

const productDashboardSlice = createSlice({
  name: 'productDashboard',
  initialState,
  reducers: {
    setSelectedWorkspace: (state, action: PayloadAction<Workspace>) => {
      state.settings.workspace = action.payload
    },
    setSelectedMetric: (state, action: PayloadAction<Field>) => {
      state.settings.metric = action.payload
    },
    setBasis: (state, action: PayloadAction<Basis>) => {
      state.settings.basis = action.payload
    },
    setSelectedProductId: (state, action: PayloadAction<number>) => {
      state.selectedProductId = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(initializeTopProducts.pending, (state) => {
      state.isLoadingProducts = true
    })
    builder.addCase(initializeTopProducts.fulfilled, (state, action) => {
      state.isLoadingProducts = false
      const {
        availableMetrics,
        aggregationsByMetric,
        workspaceProductCount,
        allWorkspaceIds,
        dashboardSettings,
      } = action.payload
      state.availableMetrics = availableMetrics
      state.aggregationsByMetric = aggregationsByMetric
      state.workspaceProductCount = workspaceProductCount
      state.allWorkspaceIds = allWorkspaceIds
      state.settings = {
        ...dashboardSettings,
        workspace: allWorkspaceIds.includes(dashboardSettings?.workspace?.id)
          ? dashboardSettings.workspace
          : allWorkspacesItem,
        basis: BASIS_TYPES.includes(dashboardSettings?.basis) ? dashboardSettings.basis : BASIS_TYPES[0],
        metric: availableMetrics.includes(dashboardSettings?.metric) ? dashboardSettings.metric : availableMetrics[0],
      }
    })
    builder.addCase(initializeTopProducts.rejected, (state, action) => {
      console.error(action.error)
      state.isLoadingProducts = false
      state.aggregationsByMetric = initialState.aggregationsByMetric
      state.workspaceProductCount = initialState.workspaceProductCount
      state.allWorkspaceIds = initialState.allWorkspaceIds
    })

    builder.addCase(getTopProductsAllMetrics.pending, (state) => {
      state.isLoadingProducts = true
    })
    builder.addCase(getTopProductsAllMetrics.fulfilled, (state, action) => {
      state.isLoadingProducts = false
      const { aggregationsByMetric, workspaceProductCount } = action.payload
      state.aggregationsByMetric = aggregationsByMetric
      state.workspaceProductCount = workspaceProductCount
    })
    builder.addCase(getTopProductsAllMetrics.rejected, (state, action) => {
      console.error(action.error)
      state.isLoadingProducts = false
      state.aggregationsByMetric = initialState.aggregationsByMetric
      state.workspaceProductCount = initialState.workspaceProductCount
    })

    builder.addCase(getProductImpactScores.pending, (state) => {
      state.isFetchingImpactData = true
    })
    builder.addCase(getProductImpactScores.fulfilled, (state, action) => {
      state.isFetchingImpactData = false
      state.productImpactData = action.payload
    })
    builder.addCase(getProductImpactScores.rejected, (state, action) => {
      console.error(action.error)
      state.isFetchingImpactData = false
      state.productImpactData = initialState.productImpactData
    })
  },
})

export const { setSelectedWorkspace, setSelectedMetric, setBasis, setSelectedProductId } = productDashboardSlice.actions

export const productDashboardReducer = productDashboardSlice.reducer
