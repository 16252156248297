import { SelectProps as MuiSelectProps } from '@mui/material/Select'

export function getHasValue(value: MuiSelectProps['value']) {
  if (Array.isArray(value)) {
    return value?.length > 0
  }
  // value 0 is allowed
  if (typeof value === 'number') {
    return true
  }
  return Boolean(value)
}
