import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TextField } from '@howgood/design'
import { selectDisplayedProductUPC, updateDraftProduct } from '@/state/productOverview'
import { GridItemTitle } from '../GridItemTitle'
import { DRAFT, selectPageView } from '@/state/pageSettings'

export const UPC: FC = () => {
  const upc = useSelector(selectDisplayedProductUPC)
  const view = useSelector(selectPageView)
  const dispatch = useDispatch()

  return (
    <>
      <GridItemTitle title="UPC" />
      <TextField
        id="upc-field"
        disabled={view !== DRAFT}
        value={upc}
        fullWidth
        onChange={(e) => dispatch(updateDraftProduct({ upc: e.currentTarget.value }))}
      />
    </>
  )
}
