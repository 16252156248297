import React from 'react'
import { createUseStyles } from 'react-jss'
import { NodeRenderer } from '@contentful/rich-text-react-renderer'
import { theme } from '@howgood/design'

const useStyles = createUseStyles({
  ul: {
    paddingLeft: theme.spacing(3),
    listStyleType: 'disc',
  },
})

export const UnorderedListRenderer: NodeRenderer = (_node, children) => {
  const classes = useStyles()
  return <ul className={classes.ul}>{children}</ul>
}
