import React, { FC, Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuid } from 'uuid'
import { faMinusCircle, faPlusCircle } from '@fortawesome/pro-regular-svg-icons'
import { Button, Grid, IconButton, Stack, Text } from '@howgood/design'
import { RouteLeg } from '@/records'
import { DRAFT, selectPageView } from '@/state/pageSettings'
import { selectDisplayedTransportation, updateRecipeAndScores } from '@/state/recipe'
import { RegionSelect } from './RegionSelect'
import { ModeSelect } from './ModeSelect'
import { SaveProductDisclaimer } from '../SaveProductDisclaimer'
import { DistanceInput } from './DistanceInput'
import { selectIsProcurement } from '@/state/router'

export const Transportation: FC = () => {
  const dispatch = useDispatch()
  const view = useSelector(selectPageView)
  const transportationLegs = useSelector(selectDisplayedTransportation)
  const [legs, setLegs] = useState(transportationLegs.mtr)
  const [addMode, setAddMode] = useState(false)
  const isProcurement = useSelector(selectIsProcurement)

  // Must disable add mode and reset legs when changing from draft to live/report views
  useEffect(() => {
    setAddMode(false)
    setLegs(transportationLegs.mtr)
  }, [view, transportationLegs.mtr])

  function addTransportationLeg() {
    setAddMode(true)
    const newLegs = [
      { id: uuid(), waypoint: null, mode: legs.length === 0 ? { id: 2, name: 'Rail' } : null, distance_override: null },
    ]
    // Each leg consists of at least 2 waypoints
    if (legs.length === 0) {
      newLegs.push({ id: uuid(), waypoint: null, mode: null, distance_override: null })
    }
    setLegs([...legs, ...newLegs])
  }
  function updateRecipeTransportation(newLegs: RouteLeg[], change: string) {
    setLegs(newLegs)
    if (newLegs.every((leg) => leg.waypoint !== null && leg.mode !== null)) {
      setAddMode(false)
      dispatch(updateRecipeAndScores({ recipeUpdates: { transportation: { mtr: newLegs, mts: [] } }, change }))
    }
  }
  function removeTransportationLeg(ids: string[]) {
    const newLegs = legs.filter((leg) => !ids.includes(leg.id))
    setLegs(newLegs)
    const changeText = `Removed transportation legs ${ids.join(', ')}`
    updateRecipeTransportation(newLegs, changeText)
  }
  function changeTransportationLeg(id: string, change: Partial<RouteLeg>) {
    const newLegs = legs.map((leg) => (leg.id === id ? { ...leg, ...change } : leg))
    setLegs(newLegs)
    const changeText = `Changed transportation leg ${id}`
    updateRecipeTransportation(newLegs, changeText)
  }

  return (
    <Stack data-testid="transportation-panel" gap={2} py={1} px={2}>
      <Grid container columns={24} rowSpacing={2}>
        <Grid item xs={24}>
          <Text data-testid="transportation-title" fontWeight="bold">
            {`${isProcurement ? 'Material' : 'Product'} Transportation`}
          </Text>
        </Grid>
        {legs.map((leg, i) => (
          <Fragment key={leg.id}>
            <Grid item xs={1}>
              {i !== 1 && (
                <IconButton
                  data-testid="delete-transportation-button"
                  disabled={i === 0 ? view !== DRAFT || legs.length > 2 : view !== DRAFT}
                  icon={faMinusCircle}
                  onClick={() => {
                    const legsToRemove = i === 0 ? [legs[0].id, legs[1].id] : [leg.id]
                    removeTransportationLeg(legsToRemove)
                  }}
                />
              )}
            </Grid>
            {i !== 0 && (
              <>
                <Grid item xs={23}>
                  <ModeSelect mode={leg.mode} onChange={(mode) => changeTransportationLeg(leg.id, { mode })} />
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={23}>
                  <DistanceInput
                    distance={leg.distance_override}
                    onChange={(distance) => changeTransportationLeg(leg.id, { distance_override: distance })}
                  />
                </Grid>
                <Grid item xs={1} />
              </>
            )}
            <Grid item xs={23}>
              <RegionSelect
                type={i === 0 ? 'manufacturing' : i === legs.length - 1 ? 'retail' : 'transfer'}
                region={leg.waypoint}
                onChange={(region) => changeTransportationLeg(leg.id, { waypoint: region })}
              />
            </Grid>
          </Fragment>
        ))}
        {legs.length > 0 && <Grid item xs={1} />}
        <Grid item mt={1}>
          <Button
            data-testid="add-transportation-button"
            size="small"
            disabled={view !== DRAFT || addMode}
            startIcon={faPlusCircle}
            onClick={addTransportationLeg}
          >
            Add transportation leg
          </Button>
        </Grid>
      </Grid>
      {legs.length > 1 && (
        <SaveProductDisclaimer readyToSave={legs.every((leg) => leg.waypoint !== null && leg.mode !== null)}>
          *To add or update transportation on this product, fill out <b>all required fields</b> for each leg before
          attempting to save.
        </SaveProductDisclaimer>
      )}
    </Stack>
  )
}
