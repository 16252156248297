import { createSelector } from 'reselect'
import {
  TIER_2_PERMISSIONS,
  VIEW_PRODUCT_NUTRITION,
  VIEW_HIVE_FIVE_IMPACT,
  VIEW_AGG_IMPACT_CF_FTM_GATE_CT_VERIFIED,
  VIEW_AGG_IMPACT_CF_FULL_LIFECYCLE,
  CARBON_LIFECYCLE_PERMISSIONS,
  PRODUCT_IMPACT_SCORE_PERMISSIONS,
  ANALYSIS_IMPACT_SCORE_PERMISSIONS,
  BLUE_WATER_PERMISSIONS,
  VIEW_AGG_IMPACT_RETAIL_RATING,
  CARBON_FOOTPRINT_PERMISSIONS,
  VIEW_AGG_ECO_SCORE,
  VIEW_AGG_IMPACT_CF_BIOGENIC_PROCESSING,
  PRODUCT_ATTRIBUTE_PERMISSIONS,
  APPLEGATE_ANALYSIS_PERMISSIONS,
  ADD_VALIDATION_REQUEST,
  IMPACT_METRIC_PERMISSIONS,
  FLAG_PERMISSIONS,
  PROCUREMENT_BETA_CARBON_LIFECYCLE_PERMISSIONS,
  PRODUCT_INGREDIENT_HEATMAP_PERMISSIONS,
  EMISSIONS_FACTORS_PERMISSIONS,
} from '@/constants/impactScore'
import { ContentfulDataHealthIndicatorKey } from '@/contentfully'
import { IUser, Provider, UserSettings } from '@/records'
import { AppState } from '@/store'
import { applegateFixPredicate } from '@/utils/applegateFixPredicate'
import { CheckboxOption } from '@/components/Shared/CheckboxesDropdown/CheckboxesDropdown'
import { selectIsProcurement, selectPageType } from '../router'
import { PortfolioImpactDashboardSettings } from '@/features/PortfolioDashboard/state/portfolioDashboard'
import { ProductDashboardSettings } from '@/features/ProductDashboard/state/productDashboard'
import { fieldColMap } from '@/components/Products/utils/productListColDefs'
import { LoginType } from './user.thunk'
import { VendorManagementSettings } from '../vendorManagement'

export const selectUser = (state: AppState): IUser => state.user.user

export const selectUserLoading = (state: AppState): boolean => state.user.isLoading

export const selectUserError = (state: AppState): boolean => state.user.isError

export const selectUserId = (state: AppState): number => state.user.user?.id

export const selectUserProvider = (state: AppState): Provider => state.user.user?.provider

export const selectUserEmail = (state: AppState): string => state.user.user?.email

export const selectUserOrganizationId = (state: AppState): number => state.user.user?.organization

export const selectUserAuthOrganization = (state: AppState): string => state.user.user?.auth0_organization

export const selectUserAuthenticated = (state: AppState): boolean => state.user.user?.authenticated

export const selectUserIngredientsPermissions = (state: AppState): string[] => state.user.user.permissions.ingredients

export const selectUserProductsPermissions = (state: AppState): string[] => state.user.user.permissions.products

const selectUserAccountsPermissions = createSelector(selectUser, (user) => user.permissions.accounts)

export const selectUserHasCarbonAccountingAccess = createSelector(
  selectUserAccountsPermissions,
  (permissions) => permissions.findIndex((p) => p.includes('carbonaccountingreport')) !== -1
)

export const selectLoginChallengeRequired = (state: AppState): LoginType => state.user.loginChallangeRequired

export const selectLoginPending = (state: AppState): boolean => state.user.loginPending

export const selectUserSettings = (state: AppState): UserSettings => state.user.user.user_settings

export const selectIgnoreUserSettings = (state: AppState): boolean =>
  state.user.user.user_settings?.ignore_user_settings

export const selectPortfolioImpactDashboardSettings = (state: AppState): PortfolioImpactDashboardSettings =>
  state.user.user.user_settings.ignore_user_settings
    ? null
    : state.user.user.user_settings?.dashboards?.portfolio_impact

export const selectProductDashboardSettings = (state: AppState): ProductDashboardSettings => {
  return state.user.user.user_settings.ignore_user_settings
    ? null
    : state.user.user.user_settings?.dashboards?.product_impact
}

export const selectProductListSelectedColumns = createSelector(
  selectUserSettings,
  selectIgnoreUserSettings,
  selectPageType,
  (userSettings, ignoreSetings, pageType) => {
    // Identify default columns based on page type
    const defaultColumns = Object.entries(fieldColMap)
      .filter(
        ([
          _field,
          {
            [pageType]: { defaultSelected },
          },
        ]) => defaultSelected
      )
      .map(([field]) => field)
    if (ignoreSetings) {
      return defaultColumns
    }
    return userSettings?.productList?.selectedColumns || defaultColumns
  }
)

export const selectUserVendorManagementSettings = (state: AppState): VendorManagementSettings => {
  return state.user.user.user_settings.ignore_user_settings ? null : state.user.user.user_settings?.vendorManagement
}

export const selectUserAnalysisImpactPermissions = (state: AppState): string[] => {
  const productsPermissions = selectUserProductsPermissions(state)
  return ANALYSIS_IMPACT_SCORE_PERMISSIONS.filter((permission) => productsPermissions.includes(permission))
}

export const selectUserProductsImpactPermissions = (state: AppState): string[] => {
  const productsPermissions = selectUserProductsPermissions(state)
  const analysisPermissions = selectUserAnalysisImpactPermissions(state)
  return PRODUCT_IMPACT_SCORE_PERMISSIONS.filter((permission) => productsPermissions.includes(permission)).filter(
    (permission) => !applegateFixPredicate(analysisPermissions, permission) // APPLEGATE_FIX: filter extra permissions
  )
}

export const selectUserProductIngredientHeatmapPermissions = (state: AppState): string[] => {
  const productsPermissions = selectUserProductsPermissions(state)
  return PRODUCT_INGREDIENT_HEATMAP_PERMISSIONS.filter((permission) => productsPermissions.includes(permission))
}

export const selectUserBlueWaterPermissions = (state: AppState): string[] => {
  const productsPermissions = selectUserProductsPermissions(state)
  return BLUE_WATER_PERMISSIONS.filter((permission) => productsPermissions.includes(permission))
}

export const selectFlagPermissions = (state: AppState): string[] => {
  const productsPermissions = selectUserProductsPermissions(state)
  return FLAG_PERMISSIONS.filter((permission) => productsPermissions.includes(permission))
}

export const selectCarbonFootprintPermissions = (state: AppState): string[] => {
  const productsPermissions = selectUserProductsPermissions(state)
  return CARBON_FOOTPRINT_PERMISSIONS.filter((permission) => productsPermissions.includes(permission))
}

const selectUserTier2MetricsPermissions = (state: AppState): string[] => {
  const productsPermissions = selectUserProductsPermissions(state)
  return TIER_2_PERMISSIONS.filter((permission) => productsPermissions.includes(permission))
}

export const selectUserHasAccessToCarbonFootprint = (state: AppState): boolean => {
  const productsPermissions = selectUserProductsPermissions(state)
  const carbonFootprintPermissions = [VIEW_AGG_IMPACT_CF_FULL_LIFECYCLE, VIEW_AGG_IMPACT_CF_FTM_GATE_CT_VERIFIED]
  return productsPermissions.findIndex((p) => carbonFootprintPermissions.includes(p)) !== -1
}

const selectUserHasAccessToHiveFive = (state: AppState): boolean => {
  return selectUserProductsPermissions(state).includes(VIEW_HIVE_FIVE_IMPACT)
}

export const selectCanViewPublicIngredients = (state: AppState): boolean =>
  selectUserIngredientsPermissions(state).includes('view_public_ingredients')

export const selectUserHasAccessToClaims = (state: AppState): boolean => {
  const permissions = selectUserProductsPermissions(state)

  return (
    permissions.includes(VIEW_AGG_ECO_SCORE) ||
    permissions.includes(VIEW_PRODUCT_NUTRITION) ||
    permissions.includes(VIEW_AGG_IMPACT_RETAIL_RATING)
  )
}

export const selectUserHasAddValidationAccess = (state: AppState): boolean => {
  const permissions = selectUserProductsPermissions(state)

  return permissions.includes(ADD_VALIDATION_REQUEST)
}

export const selectCarbonLifecyclePermissions = (state: AppState): string[] => {
  const permissions = selectUserProductsPermissions(state)
  const isProcurement = selectIsProcurement(state)
  const carbonPermissions = isProcurement ? PROCUREMENT_BETA_CARBON_LIFECYCLE_PERMISSIONS : CARBON_LIFECYCLE_PERMISSIONS
  return carbonPermissions.filter((permission) => permissions.includes(permission))
}

const selectCDPPermissions = (state: AppState): string[] => {
  const permissions = selectUserProductsPermissions(state)
  return [VIEW_AGG_IMPACT_CF_BIOGENIC_PROCESSING].filter((permission) => permissions.includes(permission))
}

export const selectAttributePermissions = (state: AppState): string[] => {
  const permissions = selectUserProductsPermissions(state)
  return PRODUCT_ATTRIBUTE_PERMISSIONS.filter((permission) => permissions.includes(permission))
}

export const selectDataHealthFields = (_state: AppState): ContentfulDataHealthIndicatorKey[] => {
  return ['default_origin_percent_impact', 'has_default_weights', 'date_modified']
}

export const selectImpactMetricPermissions = createSelector(
  selectUserAnalysisImpactPermissions,
  selectUserHasAccessToHiveFive,
  (analysisImpactPermissions, hasAccessToHiveFive) => {
    return IMPACT_METRIC_PERMISSIONS.filter(
      (permission) =>
        analysisImpactPermissions.includes(permission) ||
        // ToDo remove this when hive_five_impact permission will be available in analysisImpactPermissions
        (permission === VIEW_HIVE_FIVE_IMPACT && hasAccessToHiveFive)
    )
  }
)

export const selectUserHasEmissionsFactorsAccess = createSelector(
  selectUserProductsPermissions,
  (productPermissions) => {
    return !EMISSIONS_FACTORS_PERMISSIONS.some((permission) => !productPermissions.includes(permission))
  }
)

export const selectProductMetricsPermissions = createSelector(selectUserProductsImpactPermissions, (permissions) => {
  return PRODUCT_IMPACT_SCORE_PERMISSIONS.filter(
    (permission) => permissions.includes(permission) && !APPLEGATE_ANALYSIS_PERMISSIONS.includes(permission)
  )
})

export interface WorkspaceListCheckbox extends Omit<CheckboxOption, 'value'> {
  units?: string
  value?: string
}

export const selectProductMetricsPermissionGroups = createSelector(
  selectUserAnalysisImpactPermissions,
  selectUserProductsImpactPermissions,
  selectUserTier2MetricsPermissions,
  selectCarbonFootprintPermissions,
  selectCarbonLifecyclePermissions,
  selectUserBlueWaterPermissions,
  selectCDPPermissions,
  selectFlagPermissions,
  selectProductMetricsPermissions,
  (
    analysisImpactPermissions,
    impactPermissions,
    t2Permissions,
    carbonFootprintPermissions,
    carbonLifecyclePermissions,
    blueWaterPermissions,
    cdpPermissions,
    flagPermissions,
    productImpactPermissions
  ) => {
    const secondTierPermissions = [
      ...t2Permissions,
      ...carbonFootprintPermissions,
      ...carbonLifecyclePermissions,
      ...blueWaterPermissions,
      ...cdpPermissions,
      ...flagPermissions,
    ]
    return { impactPermissions, analysisImpactPermissions, secondTierPermissions, productImpactPermissions }
  }
)
