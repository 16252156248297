import { InventoriesResponse } from '@/api'
import { IProduct, IProductStandard, ClaimsReportsType, SharingRequest } from '@/records'

interface ESInventoriesResponse extends Omit<InventoriesResponse, 'formulation_tags' | 'workspace'> {
  formulation_tags: string[]
  workspace_id: number
}

export interface ESIngredient {
  id: number
  ingredient_origin_location_region_name: string
  name: string
  standards: IProductStandard[]
}

export interface ESProduct extends Omit<IProduct, 'inventories' | 'custom_fields'> {
  inventories: ESInventoriesResponse[]
  reports: ClaimsReportsType
  sharing_requests: SharingRequest[]
  ingredients: ESIngredient[]
  custom_fields?: Record<string, number> // Added by the frontend in `fetchProducts()`
}

export const prepareProductsFromHits = (hits: ESProduct[]): IProduct[] => {
  return hits.map((data) => {
    const impact_score = data.impact_score || {}

    const {
      id,
      pk,
      upc,
      name,
      date_modified,
      locked_claims_timestamp,
      formulation_status,
      impact_score_total,
      workspaces = [],
      annual_sales_volume,
      weight_kg,
      is_min_processed,
      is_clean_label,
      is_water_smart,
      is_climate_friendly,
      is_fair_labor,
      is_transparent_company,
      no_synthetic_input,
      has_default_weights,
      inventories,
      nutrition_score,
      nutrition_added_sugars,
      nutrition_total_calcium,
      nutrition_total_dietary_fiber,
      nutrition_total_energy,
      nutrition_total_lipids,
      nutrition_total_nitrogen,
      nutrition_total_proteins,
      nutrition_total_saturated_fat_acid,
      nutrition_total_sodium,
      nutrition_total_sugars,
      locked_claims,
      reports,
      sharing_requests,
      material_types,
      brand,
      assignee,
      ingredients,
      scenarios,
      region,
      custom_fields,
    } = data || {}

    const {
      applegate_impact,
      craveability_and_sales_potential_impact,
      craveability_impact,
      sales_potential_impact,
      applegate_health_score_impact,
    } = impact_score

    const standards = ingredients?.reduce((accum, ingredient) => accum.concat(ingredient?.standards || []), []) ?? []

    return {
      id: id || pk,
      upc,
      name,
      date_modified,
      locked_claims_timestamp,
      locked_claims,
      formulation_status,
      howgood_total_impact: impact_score_total,
      workspaces,
      impact_score,
      annual_sales_volume,
      weight_kg,
      craveability_and_sales_potential_impact,
      applegate_impact,
      craveability_impact,
      sales_potential_impact,
      applegate_health_score: applegate_health_score_impact,
      is_min_processed,
      is_clean_label,
      is_water_smart,
      is_climate_friendly,
      is_fair_labor,
      is_transparent_company,
      no_synthetic_input,
      has_default_weights,
      inventories: inventories.map((inventory) => ({
        ...inventory,
        formulation_tags: inventory.formulation_tags.map((tag) => ({ name: tag })),
        workspace: { id: inventory.workspace_id },
        validation_requests: inventory.validation_requests || [],
      })),
      nutrition_score,
      nutrition_added_sugars,
      nutrition_total_calcium,
      nutrition_total_dietary_fiber,
      nutrition_total_energy,
      nutrition_total_lipids,
      nutrition_total_nitrogen,
      nutrition_total_proteins,
      nutrition_total_saturated_fat_acid,
      nutrition_total_sodium,
      nutrition_total_sugars,
      reports: reports || { items: [] },
      sharing_requests: sharing_requests || [],
      material_types,
      brand,
      assignee,
      es_ingredients: ingredients || [],
      scenarios,
      standards,
      region,
      custom_fields,
    }
  })
}
