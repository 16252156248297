export const VIEW_AGG_IMPACT_APPLEGATE = 'view_agg_impact_applegate'
const VIEW_AGG_CRAVEABILITY_AND_SALES_POTENTIAL = 'view_agg_craveability_and_sales_potential'
export const VIEW_AGG_CRAVEABILITY = 'view_agg_craveability'
export const VIEW_AGG_SALES_POTENTIAL = 'view_agg_sales_potential'
export const VIEW_AGG_IMPACT_HOWGOOD_TOTAL = 'view_agg_impact_howgood_total'
export const VIEW_AGG_IMPACT_HOWGOOD_ONE_METRIC = 'view_agg_impact_howgood_one_metric'
export const VIEW_AGG_IMPACT_APPLEGATE_HEALTH_SCORE = 'view_agg_impact_applegate_health_score'
const VIEW_AGG_IMPACT_RAW_GHG = 'view_agg_impact_raw_ghg'
const VIEW_AGG_IMPACT_PROCESSING = 'view_agg_impact_processing'
const VIEW_AGG_IMPACT_WATER = 'view_agg_impact_water'
const VIEW_AGG_IMPACT_BLUE_WATER = 'view_agg_impact_blue_water'
export const VIEW_AGG_IMPACT_RAW_BLUE_WATER_USAGE = 'view_agg_impact_raw_blue_water_usage'
const VIEW_PRODUCT_RAW_BLUE_WATER_CONSUMPTION = 'view_product_raw_blue_water_consumption'
const VIEW_INGREDIENT_RAW_BLUE_WATER_CONSUMPTION = 'view_ingredient_raw_blue_water_consumption'
const VIEW_AGG_IMPACT_WORKING_CON = 'view_agg_impact_working_con'
const VIEW_AGG_IMPACT_LABOR = 'view_agg_impact_labor'
const VIEW_AGG_IMPACT_BIODIVERSITY = 'view_agg_impact_biodiversity'
const VIEW_AGG_IMPACT_LAND_USE = 'view_agg_impact_land_use'
const VIEW_AGG_IMPACT_ENVIRONMENTAL = 'view_agg_impact_environmental'
const VIEW_AGG_IMPACT_SUPPLY_CHAIN_RESILIENCE = 'view_agg_impact_supply_chain_resilience'
const VIEW_AGG_IMPACT_PLANETARY_HEALTH_PERCENTAGE = 'view_agg_impact_planetary_health_percentage'
const VIEW_AGG_IMPACT_PROCESSING_LEVEL = 'view_agg_impact_processing_level'
const VIEW_AGG_IMPACT_ANIMAL_DIET = 'view_agg_impact_animal_diet'
const VIEW_AGG_IMPACT_ANIMAL_WELFARE = 'view_agg_impact_animal_welfare'
const VIEW_AGG_IMPACT_APPLEGATE_HUMAN_HEALTH = 'view_agg_impact_applegate_human_health'
const VIEW_AGG_IMPACT_APPLEGATE_ENVIRONMENTAL_HEALTH = 'view_agg_impact_applegate_environmental_health'
const VIEW_AGG_IMPACT_APPLEGATE_ANIMAL_HEALTH = 'view_agg_impact_applegate_animal_health'
export const VIEW_AGG_IMPACT_CARBON_FOOTPRINT_FARM_TO_GATE = 'view_agg_impact_carboon_footprint_farm_to_gate'
const VIEW_PRODUCT_CARBON_FOOTPRINT_UNIT = 'view_product_carbon_footprint_unit'
const VIEW_AGG_IMPACT_CARBON_FOOTPRINT_ANNUAL = 'view_agg_carbon_footprint_annual'
const VIEW_PRODUCT_CF_NON_FLAG_ANNUAL = 'view_product_cf_non_flag_annual_impact'
const VIEW_PRODUCT_CF_LUC_ANNUAL = 'view_product_cf_luc_annual_impact'
const VIEW_PRODUCT_RAW_GREENHOUSE_GAS_ANNUAL = 'view_product_raw_greenhouse_gas_annual_impact'
const VIEW_PRODUCT_CF_REMOVALS_ANNUAL = 'view_product_cf_removals_annual_impact'
const VIEW_PRODUCT_CF_FLAG_ANNUAL = 'view_product_cf_flag_annual_impact'
const VIEW_AGG_IMPACT_CF_FTM_GATE_ANNUAL = 'view_agg_impact_cf_ftm_gate_annual'
const VIEW_AGG_CF_FULL_LIFECYCLE_ANNUAL = 'view_agg_cf_full_lifecycle_annual'
const VIEW_AGG_IMPACT_CARBON_FOOTPRINT_ON_FARM_PROCESSING = 'view_agg_impact_carbon_footprint_on_farm_processing_ghgs'
const VIEW_INGREDIENT_RAW_PROCESSING_GHG = 'view_impact_raw_processing_ghg'
const VIEW_AGG_IMPACT_BUSINESS_POTENTIAL = 'view_agg_business_potential'
export const VIEW_AGG_IMPACT_CF_FTM_GATE_CT_VERIFIED = 'view_agg_impact_cf_ftm_gate_ct_verified'
export const VIEW_AGG_IMPACT_CF_CRADLE_TO_SHELF_CT_VERIFIED = 'view_agg_impact_cf_cradle_to_shelf_ct_verified'
export const VIEW_AGG_IMPACT_CF_BIOGENIC_PROCESSING = 'view_agg_biogenic_processing'
export const VIEW_PRODUCT_IMPACT_CF_BIOGENIC_FTM_GATE = 'view_product_impact_cf_biogenic_ftm_gate'
export const VIEW_PRODUCT_IMPACT_CF_BIOGENIC_CRADLE_TO_SHELF = 'view_product_impact_cf_biogenic_cradle_to_shelf'
export const VIEW_AGG_IMPACT_SUPPLIER_CF_FTM_GATE = 'view_agg_impact_supplier_cf_ftm_gate'
export const VIEW_AGG_IMPACT_CPG_CF_F2MG_W_PKG = 'view_product_cpg_cf_f2mg_w_pkg_impact'
export const VIEW_AGG_IMPACT_CF_FULL_LIFECYCLE = 'view_agg_impact_cf_full_lifecycle'
const VIEW_AGG_IMPACT_CF_MANUFACTURING = 'view_agg_impact_cf_manufacturing'
const VIEW_AGG_IMPACT_CF_PACKAGING_TOTAL = 'view_agg_impact_cf_packaging_total'
const VIEW_AGG_IMPACT_CF_MTS_TRANSPORTATION = 'view_agg_impact_cf_mts_transportation'
const VIEW_AGG_IMPACT_CF_8_STORAGE = 'view_agg_impact_cf_8_storage'
export const VIEW_AGG_IMPACT_CF_MTR_TRANSPORTATION = 'view_agg_impact_cf_mtr_transportation'
const VIEW_AGG_IMPACT_CF_RETAIL_STORAGE = 'view_agg_impact_cf_retail_storage'
const VIEW_AGG_IMPACT_CF_CONSUMPTION = 'view_agg_impact_cf_consumption'
const VIEW_AGG_IMPACT_CF_PACKAGING_WASTE = 'view_agg_impact_cf_packaging_waste'
const VIEW_AGG_IMPACT_CF_CRADLE_TO_SHELF = 'view_agg_impact_cf_cradle_to_shelf'
export const VIEW_PRODUCT_IMPACT_CF_LUC = 'view_product_cf_luc_impact'
const VIEW_PRODUCT_IMPACT_CF_LUC_ANNUAL = 'view_product_cf_luc_annual_impact'
const VIEW_PRODUCT_IMPACT_RAW_GREENHOUSE_GAS_ANNUAL = 'view_product_raw_greenhouse_gas_annual_impact'
const VIEW_PRODUCT_IMPACT_CF_REMOVALS_ANNUAL = 'view_product_cf_removals_annual_impact'
export const VIEW_PRODUCT_IMPACT_STARBUCKS = 'view_product_impact_starbucks'
export const VIEW_PRODUCT_IMPACT_RAW_FTMGATE_WATER_WITHDRAWAL = 'view_product_raw_ftmgate_water_withdrawal_impact'
const VIEW_INGREDIENT_IMPACT_RAW_ON_FARM_SCARCITY_ADJUSTED_WATER_WITHDRAWAL =
  'view_ingredient_raw_on_farm_scarcity_adjusted_water_withdrawal_impact'
export const VIEW_PRODUCT_IMPACT_CF_FLAG = 'view_product_cf_flag_impact'
const VIEW_PRODUCT_CF_REMOVALS = 'view_product_cf_removals_impact'
const VIEW_AGG_IMPACT_SDG_6_4 = 'view_agg_impact_sdg_6_4'
const VIEW_AGG_IMPACT_SDG_8_7 = 'view_agg_impact_sdg_8_7'
const VIEW_AGG_IMPACT_SDG_8_8 = 'view_agg_impact_sdg_8_8'
const VIEW_AGG_IMPACT_SDG_12_2 = 'view_agg_impact_sdg_12_2'
const VIEW_AGG_IMPACT_SDG_13_2 = 'view_agg_impact_sdg_13_2'
const VIEW_AGG_IMPACT_SDG_15_9 = 'view_agg_impact_sdg_15_9'
const VIEW_AGG_IMPACT_CF_TOTAL_UPSTREAM_PROCESSING = 'view_product_impact_cf_total_upstream_processing_impact'
export const VIEW_AGG_IMPACT_CF_TOTAL_UPSTREAM_TRANSPORTATION =
  'view_product_impact_cf_total_upstream_transportation_impact'
const VIEW_AGG_IMPACT_CF_MTM_TRANSPORTATION = 'view_agg_impact_cf_mtm_transportation'
const VIEW_AGG_IMPACT_CF_PROCESSING = 'view_agg_impact_cf_processing'
const VIEW_IMPACT_CF_PROCESSING = 'view_impact_cf_processing'
export const VIEW_PRODUCT_NUTRITION = 'view_product_nutrition'
export const VIEW_AGG_ECO_SCORE = 'view_agg_eco_score'
export const VIEW_HIVE_FIVE_IMPACT = 'view_hive_five_impact'
const VIEW_MINIMALLY_PROCESSED_ATTRIBUTE = 'view_agg_is_min_processed'
const VIEW_NO_SYNTHETIC_INPUTS_ATTRIBUTE = 'view_agg_no_synthetic_input'
const VIEW_CLIMATE_FRIENDLY_ATTRIBUTE = 'view_agg_is_climate_friendly'
const VIEW_IS_FAIR_LABOR_ATTRIBUTE = 'view_agg_is_fair_labor'
const VIEW_TRANSPARENT_COMPANY_ATTRIBUTE = 'view_agg_is_transparent_company'
const VIEW_CLEAN_LABEL_ATTRIBUTE = 'view_agg_is_clean_label'
const VIEW_WATER_SMART_ATTRIBUTE = 'view_agg_is_water_smart'
export const VIEW_AGG_IMPACT_RETAIL_RATING = 'view_agg_impact_retail_rating'
const VIEW_PRODUCT_DEFORESTATION_IMPACT = 'view_product_deforestation_impact'
const VIEW_PRODUCT_SOC_IMPACT = 'view_product_impact_soc'
const VIEW_PRODUCT_RAW_SOC_IMPACT = 'view_product_impact_raw_soc'
const VIEW_INGREDIENT_DEFORESTATION_IMPACT = 'view_ingredient_deforestation_impact'
const VIEW_IMPACT_RAW_GHG = 'view_impact_raw_ghg'
export const ADD_VALIDATION_REQUEST = 'add_validationrequest'
const VIEW_PRODUCT_DQS_STORAGE_EMISSIONS_FACTOR = 'view_product_impact_dqs_storage_emissions_factor'
const VIEW_PRODUCT_DQS_TRANSPORTATION_EMISSIONS_FACTOR = 'view_product_impact_dqs_transportation_emissions_factor'
const VIEW_PRODUCT_DQS_RETAILER_EMISSIONS_FACTOR = 'view_product_impact_dqs_retailer_emissions_factor'
const VIEW_PRODUCT_DQS_CRADLE_TO_SHELF_EMISSIONS_FACTOR = 'view_product_dqs_cradle_to_shelf_emissions_factor'
const VIEW_PRODUCT_DQS_CF_FTM_GATE_CT_VERIFIED_EMISSIONS_FACTOR =
  'view_product_dqs_cf_ftm_gate_ct_verified_emissions_factor'
const VIEW_PRODUCT_DQS_ON_FARM_EMISSIONS_FACTOR = 'view_product_dqs_on_farm_emissions_factor'
const VIEW_PRODUCT_DQS_MANUFACTURING_TYPE = 'view_product_dqs_manufacturing_type'
const VIEW_PRODUCT_DQS_LUC_EMISSIONS_FACTOR = 'view_product_impact_dqs_luc_emissions_factor'
const VIEW_PRODUCT_DQS_ON_FARM_YEAR = 'view_product_dqs_on_farm_year'
const VIEW_PRODUCT_DQS_UPSTREAM_PROCESSING_EMISSIONS_FACTOR =
  'view_product_impact_dqs_upstream_processing_emissions_factor'
const VIEW_PRODUCT_DQS_BIOGENIC_FTM_GATE_EMISSIONS_FACTOR = 'view_product_dqs_biogenic_ftm_gate_emissions_factor'
const VIEW_PRODUCT_DQS_BIOGENIC_CRADLE_TO_SHELF_EMISSIONS_FACTOR =
  'view_product_dqs_biogenic_cradle_to_shelf_emissions_factor'
const VIEW_PRODUCT_DQS_PACKAGING_EMISSIONS_FACTOR = 'view_product_impact_dqs_packaging_emissions_factor'

export type Field =
  | 'processing_impact'
  | 'biodiversity_impact'
  | 'labor_impact'
  | 'water_impact'
  | 'blue_water_impact'
  | 'raw_blue_water_usage_impact'
  | 'raw_blue_water_consumption'
  | 'greenhouse_gas_impact'
  | 'raw_greenhouse_gas_impact'
  | 'animal_welfare_impact'
  | 'animal_welfare_seafood' // Front-end and Contentful only, set when ingredient.is_category_seafood
  | 'soc_impact'
  | 'raw_soc_impact'
  | 'working_conditions_impact'
  | 'planetary_health_percentage_impact'
  | 'raw_land_use_impact'
  | 'land_use_impact'
  | 'environmental_impact'
  | 'supply_chain_resilience_impact'
  | 'processing_level_impact'
  | 'animal_diet_impact'
  | 'applegate_human_health_impact'
  | 'applegate_environmental_health_impact'
  | 'applegate_animal_health_impact'
  | 'craveability_impact'
  | 'sales_potential_impact'
  | 'applegate_impact'
  | 'craveability_and_sales_potential_impact'
  | 'applegate_health_score'
  | 'carbon_footprint_farm_to_gate_impact'
  | 'carbon_footprint_annual_impact'
  | 'cf_non_flag_annual_impact'
  | 'cf_luc_annual_impact'
  | 'raw_greenhouse_gas_annual_impact'
  | 'cf_removals_annual_impact'
  | 'cf_flag_annual_impact'
  | 'carbon_footprint_unit_impact'
  | 'eco_score_impact'
  | 'nutrition_score'
  | 'cf_total_upstream_transportation_impact'
  | 'cf_mtm_transportation_impact'
  | 'cf_total_upstream_processing_impact'
  | 'cf_manufacturing_impact'
  | 'cf_packaging_total_impact'
  | 'cf_mts_transportation_impact'
  | 'cf_storage_impact'
  | 'cf_mtr_transportation_impact'
  | 'cf_retail_storage_impact'
  | 'cf_biogenic_processing_impact'
  | 'cf_biogenic_ftm_gate_impact'
  | 'cf_biogenic_cradle_to_shelf_impact'
  | 'cf_consumption_impact'
  | 'cf_packaging_waste_impact'
  | 'cf_cradle_to_shelf_impact'
  | 'cf_luc_impact'
  | 'cf_luc_annual_impact'
  | 'raw_greenhouse_gas_annual_impact'
  | 'cf_removals_annual_impact'
  | 'cf_non_flag_annual_impact'
  | 'cf_flag_annual_impact'
  | 'cf_flag_impact'
  | 'scope_3_flag_impact'
  | 'cf_removals_impact'
  | 'cf_full_lifecycle_annual_impact'
  | 'cf_ftm_gate_annual_impact'
  | 'cf_processing_impact'
  | 'supplier_cf_ftm_gate_impact'
  | 'cpg_cf_f2mg_w_pkg_impact'
  | 'default_origin_percent_impact'
  | 'is_min_processed'
  | 'is_climate_friendly'
  | 'is_transparent_company'
  | 'is_clean_label'
  | 'is_water_smart'
  | 'is_fair_labor'
  | 'no_synthetic_input'
  | 'howgood_total_impact'
  | 'howgood_one_metric'
  | 'starbucks_impact'
  | 'raw_ftmgate_water_withdrawal_impact'
  | 'raw_on_farm_scarcity_adjusted_water_withdrawal_impact'
  | 'hive_five_impact'
  | 'cf_ftm_gate_ct_verified_impact'
  | 'cf_cradle_to_shelf_ct_verified_impact'
  | 'cf_biogenic_cradle_to_shelf_impact'
  | 'cf_biogenic_ftm_gate_impact'
  | 'cf_full_lifecycle_impact'
  | 'business_potential_impact'
  | 'carbon_footprint_on_farm_processing_ghgs_impact'
  | 'raw_processing_ghg_impact'
  | 'has_default_weights'
  | 'date_modified'
  | 'nutrition_total_energy'
  | 'nutrition_total_proteins'
  | 'nutrition_total_saturated_fat_acid'
  | 'nutrition_total_sugars'
  | 'nutrition_total_sodium'
  | 'nutrition_total_sodium_chloride'
  | 'retail_rating_impact'
  | 'deforestation_impact'
  | 'scope_3_category_1_impact'
  | 'scope_3_category_4_impact'
  | 'scope_3_contribution'
  | 'sdg_6_4_impact'
  | 'sdg_8_7_impact'
  | 'sdg_8_8_impact'
  | 'sdg_12_2_impact'
  | 'sdg_13_2_impact'
  | 'sdg_15_9_impact'

export const NUTRITION_FIELDS: Field[] = [
  'nutrition_total_energy',
  'nutrition_total_proteins',
  'nutrition_total_saturated_fat_acid',
  'nutrition_total_sugars',
  'nutrition_total_sodium',
]

type FieldsMap = Record<string, Field>

export const PERMISSIONS_TO_FIELDS_MAP: FieldsMap = {
  [VIEW_AGG_IMPACT_BLUE_WATER]: 'blue_water_impact',
  [VIEW_AGG_IMPACT_RAW_BLUE_WATER_USAGE]: 'raw_blue_water_usage_impact',
  [VIEW_PRODUCT_RAW_BLUE_WATER_CONSUMPTION]: 'raw_blue_water_consumption',
  [VIEW_INGREDIENT_RAW_BLUE_WATER_CONSUMPTION]: 'raw_blue_water_consumption',
  [VIEW_AGG_IMPACT_CARBON_FOOTPRINT_FARM_TO_GATE]: 'carbon_footprint_farm_to_gate_impact',
  [VIEW_PRODUCT_CARBON_FOOTPRINT_UNIT]: 'carbon_footprint_unit_impact',
  [VIEW_AGG_IMPACT_CF_TOTAL_UPSTREAM_TRANSPORTATION]: 'cf_total_upstream_transportation_impact',
  [VIEW_AGG_IMPACT_CF_MTM_TRANSPORTATION]: 'cf_mtm_transportation_impact',
  [VIEW_AGG_IMPACT_CF_TOTAL_UPSTREAM_PROCESSING]: 'cf_total_upstream_processing_impact',
  [VIEW_AGG_IMPACT_CF_MANUFACTURING]: 'cf_manufacturing_impact',
  [VIEW_AGG_IMPACT_CF_PACKAGING_TOTAL]: 'cf_packaging_total_impact',
  [VIEW_AGG_IMPACT_CF_MTS_TRANSPORTATION]: 'cf_mts_transportation_impact',
  [VIEW_AGG_IMPACT_CF_8_STORAGE]: 'cf_storage_impact',
  [VIEW_AGG_IMPACT_CF_MTR_TRANSPORTATION]: 'cf_mtr_transportation_impact',
  [VIEW_AGG_IMPACT_CF_RETAIL_STORAGE]: 'cf_retail_storage_impact',
  [VIEW_AGG_IMPACT_CF_BIOGENIC_PROCESSING]: 'cf_biogenic_processing_impact',
  [VIEW_PRODUCT_IMPACT_CF_BIOGENIC_FTM_GATE]: 'cf_biogenic_ftm_gate_impact',
  [VIEW_PRODUCT_IMPACT_CF_BIOGENIC_CRADLE_TO_SHELF]: 'cf_biogenic_cradle_to_shelf_impact',
  [VIEW_AGG_IMPACT_CF_CONSUMPTION]: 'cf_consumption_impact',
  [VIEW_AGG_IMPACT_CF_PACKAGING_WASTE]: 'cf_packaging_waste_impact',
  [VIEW_AGG_IMPACT_CF_CRADLE_TO_SHELF]: 'cf_cradle_to_shelf_impact',
  [VIEW_PRODUCT_IMPACT_CF_LUC]: 'cf_luc_impact',
  [VIEW_PRODUCT_IMPACT_CF_LUC_ANNUAL]: 'cf_luc_annual_impact',
  [VIEW_PRODUCT_IMPACT_RAW_GREENHOUSE_GAS_ANNUAL]: 'raw_greenhouse_gas_annual_impact',
  [VIEW_PRODUCT_IMPACT_CF_REMOVALS_ANNUAL]: 'cf_removals_annual_impact',
  [VIEW_PRODUCT_CF_NON_FLAG_ANNUAL]: 'cf_non_flag_annual_impact',
  [VIEW_PRODUCT_CF_FLAG_ANNUAL]: 'cf_flag_annual_impact',
  [VIEW_PRODUCT_IMPACT_CF_FLAG]: 'cf_flag_impact',
  [VIEW_PRODUCT_CF_REMOVALS]: 'cf_removals_impact',
  [VIEW_MINIMALLY_PROCESSED_ATTRIBUTE]: 'is_min_processed',
  [VIEW_NO_SYNTHETIC_INPUTS_ATTRIBUTE]: 'no_synthetic_input',
  [VIEW_CLIMATE_FRIENDLY_ATTRIBUTE]: 'is_climate_friendly',
  [VIEW_IS_FAIR_LABOR_ATTRIBUTE]: 'is_fair_labor',
  [VIEW_TRANSPARENT_COMPANY_ATTRIBUTE]: 'is_transparent_company',
  [VIEW_CLEAN_LABEL_ATTRIBUTE]: 'is_clean_label',
  [VIEW_WATER_SMART_ATTRIBUTE]: 'is_water_smart',
  [VIEW_AGG_IMPACT_RAW_GHG]: 'raw_greenhouse_gas_impact',
  [VIEW_AGG_IMPACT_PROCESSING]: 'processing_impact',
  [VIEW_AGG_IMPACT_CF_PROCESSING]: 'cf_processing_impact',
  [VIEW_IMPACT_CF_PROCESSING]: 'cf_processing_impact',
  [VIEW_AGG_IMPACT_WATER]: 'water_impact',
  [VIEW_AGG_IMPACT_WORKING_CON]: 'working_conditions_impact',
  [VIEW_AGG_IMPACT_LABOR]: 'labor_impact',
  [VIEW_AGG_IMPACT_BIODIVERSITY]: 'biodiversity_impact',
  [VIEW_AGG_IMPACT_LAND_USE]: 'raw_land_use_impact',
  [VIEW_AGG_IMPACT_ENVIRONMENTAL]: 'environmental_impact',
  [VIEW_AGG_IMPACT_SUPPLY_CHAIN_RESILIENCE]: 'supply_chain_resilience_impact',
  [VIEW_AGG_IMPACT_PLANETARY_HEALTH_PERCENTAGE]: 'planetary_health_percentage_impact',
  [VIEW_AGG_IMPACT_PROCESSING_LEVEL]: 'processing_level_impact',
  [VIEW_AGG_IMPACT_ANIMAL_DIET]: 'animal_diet_impact',
  [VIEW_AGG_IMPACT_ANIMAL_WELFARE]: 'animal_welfare_impact',
  [VIEW_AGG_IMPACT_APPLEGATE_HUMAN_HEALTH]: 'applegate_human_health_impact',
  [VIEW_AGG_IMPACT_APPLEGATE_ENVIRONMENTAL_HEALTH]: 'applegate_environmental_health_impact',
  [VIEW_AGG_IMPACT_APPLEGATE_ANIMAL_HEALTH]: 'applegate_animal_health_impact',
  [VIEW_AGG_CRAVEABILITY]: 'craveability_impact',
  [VIEW_AGG_SALES_POTENTIAL]: 'sales_potential_impact',
  [VIEW_AGG_IMPACT_APPLEGATE_HEALTH_SCORE]: 'applegate_health_score',
  [VIEW_AGG_IMPACT_APPLEGATE]: 'applegate_impact',
  [VIEW_AGG_CRAVEABILITY_AND_SALES_POTENTIAL]: 'craveability_and_sales_potential_impact',
  [VIEW_AGG_IMPACT_HOWGOOD_TOTAL]: 'howgood_total_impact',
  [VIEW_AGG_IMPACT_HOWGOOD_ONE_METRIC]: 'howgood_one_metric',
  [VIEW_PRODUCT_IMPACT_STARBUCKS]: 'starbucks_impact',
  [VIEW_PRODUCT_IMPACT_RAW_FTMGATE_WATER_WITHDRAWAL]: 'raw_ftmgate_water_withdrawal_impact',
  [VIEW_HIVE_FIVE_IMPACT]: 'hive_five_impact',
  [VIEW_PRODUCT_NUTRITION]: 'nutrition_score',
  [VIEW_AGG_IMPACT_CARBON_FOOTPRINT_ANNUAL]: 'carbon_footprint_annual_impact',
  [VIEW_AGG_IMPACT_CF_FTM_GATE_ANNUAL]: 'cf_ftm_gate_annual_impact',
  [VIEW_AGG_CF_FULL_LIFECYCLE_ANNUAL]: 'cf_full_lifecycle_annual_impact',
  [VIEW_AGG_IMPACT_SUPPLIER_CF_FTM_GATE]: 'supplier_cf_ftm_gate_impact',
  [VIEW_AGG_IMPACT_CF_FTM_GATE_CT_VERIFIED]: 'cf_ftm_gate_ct_verified_impact',
  [VIEW_AGG_IMPACT_CF_CRADLE_TO_SHELF_CT_VERIFIED]: 'cf_cradle_to_shelf_ct_verified_impact',
  [VIEW_AGG_IMPACT_CPG_CF_F2MG_W_PKG]: 'cpg_cf_f2mg_w_pkg_impact',
  [VIEW_AGG_IMPACT_CF_FULL_LIFECYCLE]: 'cf_full_lifecycle_impact',
  [VIEW_AGG_ECO_SCORE]: 'eco_score_impact',
  [VIEW_AGG_IMPACT_BUSINESS_POTENTIAL]: 'business_potential_impact',
  [VIEW_AGG_IMPACT_CARBON_FOOTPRINT_ON_FARM_PROCESSING]: 'carbon_footprint_on_farm_processing_ghgs_impact',
  [VIEW_INGREDIENT_RAW_PROCESSING_GHG]: 'raw_processing_ghg_impact',
  [VIEW_AGG_IMPACT_SDG_6_4]: 'sdg_6_4_impact',
  [VIEW_AGG_IMPACT_SDG_8_7]: 'sdg_8_7_impact',
  [VIEW_AGG_IMPACT_SDG_8_8]: 'sdg_8_8_impact',
  [VIEW_AGG_IMPACT_SDG_12_2]: 'sdg_12_2_impact',
  [VIEW_AGG_IMPACT_SDG_13_2]: 'sdg_13_2_impact',
  [VIEW_AGG_IMPACT_SDG_15_9]: 'sdg_15_9_impact',
  [VIEW_AGG_IMPACT_RETAIL_RATING]: 'retail_rating_impact',
  [VIEW_IMPACT_RAW_GHG]: 'raw_greenhouse_gas_impact',
  view_impact_soc: 'soc_impact',
  view_impact_raw_soc: 'raw_soc_impact',
  view_impact_processing: 'processing_impact',
  view_impact_water: 'water_impact',
  view_impact_blue_water: 'blue_water_impact',
  view_impact_raw_blue_water_usage: 'raw_blue_water_usage_impact',
  view_impact_working_con: 'working_conditions_impact',
  view_impact_labor: 'labor_impact',
  view_impact_biodiversity: 'biodiversity_impact',
  view_impact_land_use: 'raw_land_use_impact',
  view_impact_environmental: 'environmental_impact',
  view_impact_supply_chain_resilience: 'supply_chain_resilience_impact',
  view_impact_planetary_health_percentage: 'planetary_health_percentage_impact',
  view_impact_processing_level: 'processing_level_impact',
  view_impact_animal_diet: 'animal_diet_impact',
  view_impact_animal_welfare: 'animal_welfare_impact',
  view_impact_applegate_human_health: 'applegate_human_health_impact',
  view_impact_applegate_environmental_health: 'applegate_environmental_health_impact',
  view_impact_applegate_animal_health: 'applegate_animal_health_impact',
  [VIEW_PRODUCT_DEFORESTATION_IMPACT]: 'deforestation_impact',
  [VIEW_INGREDIENT_DEFORESTATION_IMPACT]: 'deforestation_impact',
  [VIEW_PRODUCT_SOC_IMPACT]: 'soc_impact',
  [VIEW_PRODUCT_RAW_SOC_IMPACT]: 'raw_soc_impact',
  [VIEW_INGREDIENT_IMPACT_RAW_ON_FARM_SCARCITY_ADJUSTED_WATER_WITHDRAWAL]:
    'raw_on_farm_scarcity_adjusted_water_withdrawal_impact',
}

export const ANALYSIS_IMPACT_SCORE_PERMISSIONS = [
  VIEW_AGG_IMPACT_APPLEGATE,
  VIEW_AGG_CRAVEABILITY_AND_SALES_POTENTIAL,
  VIEW_AGG_CRAVEABILITY,
  VIEW_AGG_SALES_POTENTIAL,
  VIEW_AGG_IMPACT_APPLEGATE_HEALTH_SCORE,
  VIEW_AGG_IMPACT_HOWGOOD_TOTAL,
  VIEW_AGG_IMPACT_HOWGOOD_ONE_METRIC,
  VIEW_HIVE_FIVE_IMPACT,
  VIEW_PRODUCT_NUTRITION,
]

export const IMPACT_METRIC_PERMISSIONS = [
  VIEW_AGG_CRAVEABILITY_AND_SALES_POTENTIAL,
  VIEW_AGG_IMPACT_APPLEGATE,
  VIEW_AGG_IMPACT_HOWGOOD_TOTAL,
  VIEW_AGG_IMPACT_HOWGOOD_ONE_METRIC,
  VIEW_HIVE_FIVE_IMPACT,
  VIEW_AGG_IMPACT_RETAIL_RATING,
]

export const APPLEGATE_ANALYSIS_PERMISSIONS = [
  VIEW_AGG_SALES_POTENTIAL,
  VIEW_AGG_CRAVEABILITY,
  VIEW_AGG_IMPACT_APPLEGATE_HEALTH_SCORE,
  VIEW_AGG_CRAVEABILITY_AND_SALES_POTENTIAL,
  VIEW_AGG_IMPACT_APPLEGATE,
]

export const CARBON_FOOTPRINT_PERMISSIONS = [
  VIEW_AGG_IMPACT_CARBON_FOOTPRINT_FARM_TO_GATE,
  VIEW_PRODUCT_CARBON_FOOTPRINT_UNIT,
  VIEW_AGG_IMPACT_CARBON_FOOTPRINT_ANNUAL,
  VIEW_AGG_IMPACT_CF_FTM_GATE_ANNUAL,
  VIEW_AGG_CF_FULL_LIFECYCLE_ANNUAL,
  VIEW_AGG_IMPACT_CARBON_FOOTPRINT_ON_FARM_PROCESSING,
  VIEW_AGG_IMPACT_CF_FTM_GATE_CT_VERIFIED,
  VIEW_AGG_IMPACT_CF_CRADLE_TO_SHELF_CT_VERIFIED,
  VIEW_PRODUCT_IMPACT_CF_BIOGENIC_CRADLE_TO_SHELF,
  VIEW_AGG_IMPACT_CPG_CF_F2MG_W_PKG,
  VIEW_AGG_IMPACT_SUPPLIER_CF_FTM_GATE,
  VIEW_AGG_IMPACT_CF_FULL_LIFECYCLE,
  VIEW_AGG_IMPACT_CF_CRADLE_TO_SHELF,
  VIEW_PRODUCT_IMPACT_CF_LUC,
  VIEW_PRODUCT_IMPACT_CF_FLAG,
  VIEW_PRODUCT_CF_REMOVALS,
  VIEW_PRODUCT_IMPACT_CF_LUC_ANNUAL,
  VIEW_PRODUCT_IMPACT_RAW_GREENHOUSE_GAS_ANNUAL,
  VIEW_PRODUCT_IMPACT_CF_REMOVALS_ANNUAL,
  VIEW_PRODUCT_CF_NON_FLAG_ANNUAL,
  VIEW_PRODUCT_CF_FLAG_ANNUAL,
]

export const TIER_2_PERMISSIONS = [
  VIEW_AGG_IMPACT_BUSINESS_POTENTIAL,
  VIEW_AGG_ECO_SCORE,
  VIEW_AGG_IMPACT_RETAIL_RATING,
  VIEW_PRODUCT_NUTRITION,
]

export const PRODUCT_IMPACT_DETAIL_PERMISSIONS = [
  VIEW_AGG_IMPACT_RAW_GHG,
  VIEW_AGG_IMPACT_PROCESSING,
  VIEW_AGG_IMPACT_RAW_BLUE_WATER_USAGE,
  VIEW_PRODUCT_RAW_SOC_IMPACT,
  VIEW_AGG_IMPACT_LABOR,
  VIEW_AGG_IMPACT_BIODIVERSITY,
  VIEW_AGG_IMPACT_LAND_USE,
  VIEW_AGG_IMPACT_ANIMAL_WELFARE,
]

export const PRODUCT_IMPACT_SCORE_PERMISSIONS = [
  VIEW_AGG_IMPACT_PROCESSING,
  VIEW_AGG_IMPACT_RAW_BLUE_WATER_USAGE,
  VIEW_PRODUCT_RAW_BLUE_WATER_CONSUMPTION,
  VIEW_AGG_IMPACT_LAND_USE,
  VIEW_PRODUCT_RAW_SOC_IMPACT,
  VIEW_PRODUCT_SOC_IMPACT,
  VIEW_AGG_IMPACT_BIODIVERSITY,
  VIEW_AGG_IMPACT_LABOR,
  VIEW_AGG_IMPACT_ANIMAL_WELFARE,
  VIEW_PRODUCT_DEFORESTATION_IMPACT,
  VIEW_AGG_IMPACT_RAW_GHG,
  VIEW_AGG_IMPACT_WATER,
  VIEW_AGG_IMPACT_BLUE_WATER,
  VIEW_AGG_IMPACT_WORKING_CON,
  VIEW_AGG_IMPACT_ENVIRONMENTAL,
  VIEW_AGG_IMPACT_SUPPLY_CHAIN_RESILIENCE,
  VIEW_AGG_IMPACT_PLANETARY_HEALTH_PERCENTAGE,
  VIEW_AGG_IMPACT_PROCESSING_LEVEL,
  VIEW_AGG_IMPACT_ANIMAL_DIET,
  VIEW_AGG_IMPACT_APPLEGATE_HUMAN_HEALTH,
  VIEW_AGG_IMPACT_APPLEGATE_ENVIRONMENTAL_HEALTH,
  VIEW_AGG_IMPACT_APPLEGATE_ANIMAL_HEALTH,
  VIEW_AGG_CRAVEABILITY,
  VIEW_AGG_SALES_POTENTIAL,
  VIEW_AGG_IMPACT_APPLEGATE_HEALTH_SCORE,
]
export const PRODUCT_INGREDIENT_HEATMAP_PERMISSIONS = [
  ...PRODUCT_IMPACT_SCORE_PERMISSIONS,
  VIEW_PRODUCT_IMPACT_CF_LUC,
  VIEW_PRODUCT_CF_REMOVALS,
  VIEW_INGREDIENT_IMPACT_RAW_ON_FARM_SCARCITY_ADJUSTED_WATER_WITHDRAWAL,
  VIEW_INGREDIENT_RAW_PROCESSING_GHG,
]
export const PRODUCT_ATTRIBUTE_PERMISSIONS = [
  VIEW_MINIMALLY_PROCESSED_ATTRIBUTE,
  VIEW_CLIMATE_FRIENDLY_ATTRIBUTE,
  VIEW_IS_FAIR_LABOR_ATTRIBUTE,
  VIEW_TRANSPARENT_COMPANY_ATTRIBUTE,
  VIEW_CLEAN_LABEL_ATTRIBUTE,
  VIEW_WATER_SMART_ATTRIBUTE,
  VIEW_NO_SYNTHETIC_INPUTS_ATTRIBUTE,
]
export const BLUE_WATER_PERMISSIONS = [
  VIEW_AGG_IMPACT_BLUE_WATER,
  VIEW_AGG_IMPACT_RAW_BLUE_WATER_USAGE,
  VIEW_PRODUCT_RAW_BLUE_WATER_CONSUMPTION,
]
export const CARBON_LIFECYCLE_PERMISSIONS = [
  VIEW_AGG_IMPACT_CARBON_FOOTPRINT_FARM_TO_GATE,
  VIEW_PRODUCT_IMPACT_CF_LUC,
  VIEW_AGG_IMPACT_CF_TOTAL_UPSTREAM_TRANSPORTATION,
  VIEW_AGG_IMPACT_CF_TOTAL_UPSTREAM_PROCESSING,
  VIEW_AGG_IMPACT_CF_MTM_TRANSPORTATION,
  VIEW_AGG_IMPACT_CF_MANUFACTURING,
  VIEW_AGG_IMPACT_CF_PACKAGING_TOTAL,
  VIEW_AGG_IMPACT_CF_MTS_TRANSPORTATION,
  VIEW_AGG_IMPACT_CF_8_STORAGE,
  VIEW_AGG_IMPACT_CF_MTR_TRANSPORTATION,
  VIEW_AGG_IMPACT_CF_RETAIL_STORAGE,
  VIEW_AGG_IMPACT_CF_CONSUMPTION,
  VIEW_AGG_IMPACT_CF_PACKAGING_WASTE,
]

export const SCOPE_3_CATEGORY_1_FIELD = 'scope_3_category_1_impact'
export const SCOPE_3_CATEGORY_4_FIELD = 'scope_3_category_4_impact'
export const SCOPE_3_FLAG_FIELD = 'scope_3_flag_impact'
export const SCOPE_3_CONTRIBUTION_FIELD = 'scope_3_contribution'

export const RAW_METRIC_FIELDS = [
  PERMISSIONS_TO_FIELDS_MAP[VIEW_IMPACT_CF_PROCESSING],
  PERMISSIONS_TO_FIELDS_MAP[VIEW_IMPACT_RAW_GHG],
  'raw_blue_water_usage_impact',
  'raw_land_use_impact',
  'raw_soc_impact',
  VIEW_PRODUCT_RAW_BLUE_WATER_CONSUMPTION,
  VIEW_INGREDIENT_RAW_BLUE_WATER_CONSUMPTION,
]

export const GHG_RAW_GHG_EMISSIONS_FIELDS = [PERMISSIONS_TO_FIELDS_MAP[VIEW_IMPACT_RAW_GHG]]

export const FLAG_PERMISSIONS = [
  VIEW_PRODUCT_CF_NON_FLAG_ANNUAL,
  VIEW_PRODUCT_CF_LUC_ANNUAL,
  VIEW_PRODUCT_RAW_GREENHOUSE_GAS_ANNUAL,
  VIEW_PRODUCT_CF_REMOVALS_ANNUAL,
  VIEW_PRODUCT_CF_FLAG_ANNUAL,
  VIEW_AGG_IMPACT_CF_FTM_GATE_ANNUAL,
]

export const EMISSIONS_FACTORS_PERMISSIONS = [
  VIEW_PRODUCT_DQS_STORAGE_EMISSIONS_FACTOR,
  VIEW_PRODUCT_DQS_TRANSPORTATION_EMISSIONS_FACTOR,
  VIEW_PRODUCT_DQS_RETAILER_EMISSIONS_FACTOR,
  VIEW_PRODUCT_DQS_CRADLE_TO_SHELF_EMISSIONS_FACTOR,
  VIEW_PRODUCT_DQS_CF_FTM_GATE_CT_VERIFIED_EMISSIONS_FACTOR,
  VIEW_PRODUCT_DQS_ON_FARM_EMISSIONS_FACTOR,
  VIEW_PRODUCT_DQS_MANUFACTURING_TYPE,
  VIEW_PRODUCT_DQS_LUC_EMISSIONS_FACTOR,
  VIEW_PRODUCT_DQS_ON_FARM_YEAR,
  VIEW_PRODUCT_DQS_UPSTREAM_PROCESSING_EMISSIONS_FACTOR,
  VIEW_PRODUCT_DQS_BIOGENIC_FTM_GATE_EMISSIONS_FACTOR,
  VIEW_PRODUCT_DQS_BIOGENIC_CRADLE_TO_SHELF_EMISSIONS_FACTOR,
  VIEW_PRODUCT_DQS_PACKAGING_EMISSIONS_FACTOR,
]

export const IMPACT_HISTORY_PERMISSIONS = [
  VIEW_AGG_IMPACT_CARBON_FOOTPRINT_FARM_TO_GATE,
  VIEW_AGG_IMPACT_CF_FTM_GATE_CT_VERIFIED,
  VIEW_AGG_IMPACT_CF_FULL_LIFECYCLE,
  VIEW_AGG_IMPACT_RAW_BLUE_WATER_USAGE,
  VIEW_AGG_IMPACT_PROCESSING,
  VIEW_AGG_IMPACT_LABOR,
  VIEW_PRODUCT_RAW_SOC_IMPACT,
  VIEW_AGG_IMPACT_ANIMAL_WELFARE,
  VIEW_AGG_IMPACT_BIODIVERSITY,
  VIEW_AGG_IMPACT_LAND_USE,
  VIEW_AGG_IMPACT_HOWGOOD_ONE_METRIC,
  VIEW_PRODUCT_IMPACT_STARBUCKS,
]
export const IMPACT_HISTORY_ATTRIBUTE_PERMISSIONS = [
  VIEW_CLEAN_LABEL_ATTRIBUTE,
  VIEW_IS_FAIR_LABOR_ATTRIBUTE,
  VIEW_MINIMALLY_PROCESSED_ATTRIBUTE,
  VIEW_CLIMATE_FRIENDLY_ATTRIBUTE,
  VIEW_WATER_SMART_ATTRIBUTE,
  VIEW_NO_SYNTHETIC_INPUTS_ATTRIBUTE,
]

/**
 * GROUPINGS BELOW ARE TO SUPPORT THE SCORECARD ORGANIZATION ON THE NEW FORMULA PROFILE PAGE
 */

export const OVERVIEW_TAB = [
  VIEW_AGG_IMPACT_HOWGOOD_TOTAL,
  VIEW_AGG_IMPACT_HOWGOOD_ONE_METRIC,
  VIEW_PRODUCT_IMPACT_STARBUCKS,
  VIEW_HIVE_FIVE_IMPACT,
  VIEW_AGG_CRAVEABILITY,
  VIEW_AGG_SALES_POTENTIAL,
  VIEW_AGG_IMPACT_RETAIL_RATING, // Howgood rating
  VIEW_AGG_IMPACT_APPLEGATE_ENVIRONMENTAL_HEALTH,
  VIEW_AGG_IMPACT_APPLEGATE_HEALTH_SCORE,
  VIEW_AGG_IMPACT_APPLEGATE,
  VIEW_AGG_IMPACT_APPLEGATE_HUMAN_HEALTH,
  VIEW_AGG_IMPACT_APPLEGATE_ANIMAL_HEALTH,
]

export const CARBON_TAB_PRIMARY = [
  VIEW_AGG_IMPACT_CF_CRADLE_TO_SHELF_CT_VERIFIED,
  VIEW_AGG_IMPACT_CF_CRADLE_TO_SHELF,
  VIEW_PRODUCT_CF_FLAG_ANNUAL,
  VIEW_PRODUCT_IMPACT_CF_FLAG,
  VIEW_AGG_IMPACT_CF_FTM_GATE_CT_VERIFIED,
  VIEW_AGG_IMPACT_CF_FTM_GATE_ANNUAL,
]
const CARBON_TAB_EXTRAS = [
  VIEW_AGG_IMPACT_CARBON_FOOTPRINT_FARM_TO_GATE,
  VIEW_AGG_IMPACT_CARBON_FOOTPRINT_ANNUAL,
  VIEW_PRODUCT_CF_NON_FLAG_ANNUAL,
  VIEW_PRODUCT_CF_LUC_ANNUAL,
  VIEW_PRODUCT_RAW_GREENHOUSE_GAS_ANNUAL,
  VIEW_PRODUCT_CF_REMOVALS_ANNUAL,
  VIEW_AGG_CF_FULL_LIFECYCLE_ANNUAL,
  VIEW_AGG_IMPACT_CARBON_FOOTPRINT_ON_FARM_PROCESSING,
  VIEW_PRODUCT_IMPACT_CF_BIOGENIC_CRADLE_TO_SHELF,
  VIEW_AGG_IMPACT_CPG_CF_F2MG_W_PKG,
  VIEW_AGG_IMPACT_SUPPLIER_CF_FTM_GATE,
  VIEW_AGG_IMPACT_CF_FULL_LIFECYCLE,
  VIEW_PRODUCT_CARBON_FOOTPRINT_UNIT,
  VIEW_PRODUCT_IMPACT_CF_LUC,
  VIEW_PRODUCT_CF_REMOVALS,
  VIEW_PRODUCT_IMPACT_CF_LUC_ANNUAL,
  VIEW_PRODUCT_IMPACT_RAW_GREENHOUSE_GAS_ANNUAL,
  VIEW_PRODUCT_IMPACT_CF_REMOVALS_ANNUAL,
  VIEW_PRODUCT_CF_NON_FLAG_ANNUAL,
]
export const CARBON_TAB = [...CARBON_TAB_PRIMARY, ...CARBON_TAB_EXTRAS]

export const NATURE_TAB = [
  VIEW_AGG_IMPACT_BLUE_WATER,
  VIEW_AGG_IMPACT_RAW_BLUE_WATER_USAGE,
  VIEW_AGG_IMPACT_WATER,
  VIEW_AGG_IMPACT_BLUE_WATER,
  VIEW_AGG_IMPACT_RAW_BLUE_WATER_USAGE,
  VIEW_PRODUCT_RAW_BLUE_WATER_CONSUMPTION,
  VIEW_PRODUCT_SOC_IMPACT,
  VIEW_PRODUCT_RAW_SOC_IMPACT,
  VIEW_AGG_IMPACT_BIODIVERSITY,
  VIEW_AGG_IMPACT_LAND_USE,
  VIEW_AGG_IMPACT_ENVIRONMENTAL,
  VIEW_AGG_IMPACT_PLANETARY_HEALTH_PERCENTAGE,
  VIEW_AGG_IMPACT_ANIMAL_DIET,
  VIEW_AGG_IMPACT_ANIMAL_WELFARE,
  VIEW_PRODUCT_DEFORESTATION_IMPACT,
  VIEW_AGG_ECO_SCORE,
  VIEW_PRODUCT_IMPACT_RAW_FTMGATE_WATER_WITHDRAWAL,
]

export const PEOPLE_TAB = [
  VIEW_AGG_IMPACT_PROCESSING_LEVEL,
  VIEW_AGG_IMPACT_WORKING_CON,
  VIEW_AGG_IMPACT_LABOR,
  VIEW_AGG_IMPACT_SUPPLY_CHAIN_RESILIENCE,
  VIEW_PRODUCT_NUTRITION,
]

/**
 * GROUPINGS BELOW ARE TO SUPPORT THE NEW PROCUREMENT PAGE
 */
// used for carbon lifecycle chart on procurement page
export const PROCUREMENT_BETA_CARBON_LIFECYCLE_PERMISSIONS = [
  VIEW_AGG_IMPACT_CARBON_FOOTPRINT_FARM_TO_GATE, // carbon_footprint_farm_to_gate_impact - Cradle-to-farm-gate (Land management)
  VIEW_PRODUCT_IMPACT_CF_LUC, // cf_luc_impact - Land Use Change (sLUC)
  VIEW_AGG_IMPACT_CF_TOTAL_UPSTREAM_TRANSPORTATION, // cf_total_upstream_transportation_impact - Transportation to Processing
  VIEW_AGG_IMPACT_CF_TOTAL_UPSTREAM_PROCESSING, // cf_total_upstream_processing_impact - Upstream Processing
  VIEW_AGG_IMPACT_CF_MTM_TRANSPORTATION, // cf_mtm_transportation_impact - Transportation to Manufacturing
  VIEW_AGG_IMPACT_CF_MANUFACTURING, // cf_manufacturing_impact - Manufacturing
  VIEW_AGG_IMPACT_CF_MTR_TRANSPORTATION, // cf_mtr_transportation_impact - Transportation to Retail
  VIEW_PRODUCT_CF_REMOVALS, // cf_removals_impact - Carbon Removals
]

// NON Permission based fields
export const NON_PERMISSION_FIELDS = [
  'animal_welfare_seafood',
  SCOPE_3_CATEGORY_1_FIELD,
  SCOPE_3_FLAG_FIELD,
  SCOPE_3_CATEGORY_4_FIELD,
  SCOPE_3_CONTRIBUTION_FIELD,
  'carbon_reduction',
]

const PROCUREMENT_CARBON_PERMISSIONS = CARBON_TAB_PRIMARY.filter((permission) => !permission.includes('annual'))
// used for scorecards on procurement page
export const PROCUREMENT_CARBON_FIELDS = [
  ...PROCUREMENT_CARBON_PERMISSIONS.map((permission) => PERMISSIONS_TO_FIELDS_MAP[permission]),
  SCOPE_3_CATEGORY_1_FIELD,
  SCOPE_3_CATEGORY_4_FIELD,
]
