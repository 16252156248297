import { Field } from '@/constants/impactScore'
import { OrgUser } from '@/state/organization'

export type Goals = { [K in Field]?: number }
type WorkspaceSalesCategory = {
  id: number
  title: string
}

export type WorkspaceType = 'HowGood' | 'Formulation' | 'Procurement' | 'Material Directory'
export const HOWGOOD = 'HowGood' as WorkspaceType
export const PRODUCTS = 'Formulation' as WorkspaceType
export const MATERIALS = 'Procurement' as WorkspaceType
export const MATERIAL_DIRECTORY = 'Material Directory' as WorkspaceType
export const workspaceTypeLabelMap = {
  [HOWGOOD]: HOWGOOD,
  [PRODUCTS]: 'Products',
  [MATERIALS]: 'Materials',
  [MATERIAL_DIRECTORY]: 'Material Directory',
}
const allWorkspaceTypes = [HOWGOOD, PRODUCTS, MATERIALS, MATERIAL_DIRECTORY]

export type PageType =
  | 'Formulation'
  | 'Procurement'
  | 'Portfolio'
  | 'Initiative'
  | 'Vendor Management'
  | 'Formulation including Procurement Workspaces'
export const getWorkspaceTypes = (pageType?: PageType) => {
  switch (pageType) {
    case 'Procurement':
      return [MATERIALS, MATERIAL_DIRECTORY]
    case 'Formulation':
      return [PRODUCTS]
    case 'Vendor Management':
      return [MATERIALS]
    case 'Portfolio':
      return [PRODUCTS, MATERIALS, MATERIAL_DIRECTORY]
    case 'Initiative':
      return [PRODUCTS, MATERIALS]
    case 'Formulation including Procurement Workspaces':
      return [PRODUCTS, MATERIALS]
    default:
      return allWorkspaceTypes
  }
}

interface WorkspacePermissions {
  edit?: boolean
  read?: boolean
  foreign_read?: boolean
  ownership?: boolean
}

export interface Workspace extends WorkspacePermissions {
  id: number
  name: string
  workspace_type: WorkspaceType
  assignee: OrgUser
  is_published?: boolean
  goals?: Goals
  sales_category?: WorkspaceSalesCategory | null
}

export interface WorkspaceOption extends WorkspacePermissions {
  value: number
  label: string
  id: number
  name: string
  is_published?: boolean
  workspace_type: WorkspaceType
}

const DEFAULT_WORKSPACE_TYPE = HOWGOOD
export function isDefaultWorkspace(workspaceType: WorkspaceType) {
  return workspaceType === DEFAULT_WORKSPACE_TYPE
}
