import React, { forwardRef } from 'react'
import MuiLinearProgress, { LinearProgressProps as MuiLinearProgressProps } from '@mui/material/LinearProgress'

export interface LinearProgressProps extends Pick<MuiLinearProgressProps, 'id' | 'variant' | 'color' | 'value' | 'sx'> {
  ['data-testid']?: string
}

export const LinearProgress = forwardRef<HTMLElement, LinearProgressProps>(
  ({ id, ['data-testid']: dataTestId, value, variant = 'determinate', color, sx, ...rest }, ref) => {
    return (
      <MuiLinearProgress
        ref={ref}
        data-testid={dataTestId}
        id={id}
        value={value}
        variant={variant}
        color={color}
        sx={{ height: 8, ...sx }}
        {...rest}
      />
    )
  }
)

LinearProgress.displayName = 'LinearProgress'
