import {
  IProductStandard,
  Workspace,
  WeightOption,
  PERCENTAGES,
  IngredientRouteLegs,
  ProductRouteLegs,
  BeverageTemperature,
} from '@/records'
import { SCOPE_3_CATEGORY_1_FIELD, SCOPE_3_CATEGORY_4_FIELD } from '@/constants/impactScore'
import { ImpactScore, ImpactScoreResponse, AgribalyzeCategory, MaterialType } from '@/api'
import { ColdStorageType } from '../productOverview/productOverview.state'

interface PackagingShape {
  id: number
  name: string
  ratio?: number
}

interface PackagingMaterial {
  id: number
  is_non_recyclable_plastic?: boolean
  name: string
  score?: number
}

interface PackagingResponseItem {
  id: number
  material: PackagingMaterial
  shape: PackagingShape
  packaging_unit: PackagingUnitKey
  consumer_units: number
  region: { id: number; name: string }
  packaging_material_weight: number
  packaging_uses: number
  recycled_input_ratio: number
}
export type PackagingResponse = PackagingResponseItem[]

export type ImpactData = Omit<ImpactScoreResponse, 'howgood_one_metric' | 'total_impact'> & {
  maxValues: ImpactScore
  nonNullFields: string[]
  timestamp: string
}

export interface IngredientStandard {
  id: number
  title: string
  identifier?: string
}

interface ManufacturingType {
  id: number
  title: string
  value: number
}
export type PackagingUnitKey = 'C' | 'S' | 'TC' | 'PT'

// Included in the recipe, the `POST v2.0/products/impact-score`, and the `POST v2.0/packaging` request bodies
export interface RecipePackagingItem {
  id?: number // Internal use only
  product?: { id: number; name?: string }
  material?: { id: number; name?: string }
  shape?: { id: number; name?: string }
  recycled_input_ratio?: number
  packaging_unit?: PackagingUnitKey
  consumer_units?: number
  region?: { id: number; name?: string }
  packaging_material_weight?: number
  packaging_uses?: number
}

export interface SalesDistributionInfo {
  weight_kg: number // "Unit Size"
  annual_sales_volume: number // "Units Sold"
  requires_cooking: boolean
  requires_cold_storage: ColdStorageType
  manufacturing_type: ManufacturingType
  manufacturing_region: {
    id: number
    name: string
  }
  retail_region: {
    id: number
    name: string
  }
  storage_region: {
    id: number
    name: string
  }
}

export interface RecipeIngredientBase {
  id: string // unique FE only id, since product_ingredient_id is null for newly added ingredients
  product_ingredient_id: number // product_ingredient_id
  name: string
  token?: string
  position: number // Not unique, since child items get same `position` as parent
  flat_position: number[] // e.g. [1, 1] means first child of first top-level item
  dot_path: string // Concatentation of `flat_position` items
  index: number // Absolute position in flattened ingredient list (unique)
  weight: number // Percentage relative to its parent (as entered by user)
  flat_weight: number // Percentage relative to the product (computed by backend on save)
  isTopLevel: boolean
  isAutoWeight: boolean
  is_foreign: boolean
  product: { id: number; name: string }
  route_legs?: IngredientRouteLegs
}

interface ESIngredient {
  id: number
  origin_location_id: number
}
export interface ESRecipeIngredientSource {
  id: number // base_id
  name: string
  default_origin_location: {
    id: number // origin_location_id
    region_name: string // origin_location_name
  }
  ingredients: ESIngredient[]
}
export interface RecipeIngredient extends RecipeIngredientBase {
  base_id: number
  ingredient_id: number
  origin_location_id: number
  origin_location_name: string
  processing_location_id: number
  processing_location_name: string
  standards: IngredientStandard[]
  availableStandards: IngredientStandard[]
  input_weight_unit?: WeightOption // Only for children of nested ingredients
}

export interface ESRecipeNestedIngredientSource {
  pk: number // nested_product_id
  id?: number
  name: string
  workspaces: Workspace[]
  brand: {
    [key: string]: any
    name: string
  }
  locked_claims_timestamp: string
  workflow_tags: string[]
  material_types: MaterialType[]
  input_weight_unit: WeightOption
}
export interface RecipeNestedIngredient extends RecipeIngredientBase {
  nested_product_id: number
  vendorName: string
  workspacesIds: number[]
  locked_claims_timestamp: string
  workflow_tags: string[]
  input_weight_unit: WeightOption // Required on nested ingredients
  material_types: MaterialType[]
}

export type ESIngredientSource = ESRecipeIngredientSource | ESRecipeNestedIngredientSource

// This interface matches the shape of the `POST v1.1/products/impact-score` request body
export interface Recipe {
  ingredients: (RecipeIngredient | RecipeNestedIngredient)[]
  org: boolean
  packaging: RecipePackagingItem[]
  transportation: ProductRouteLegs
  sales_distribution: SalesDistributionInfo
  sales_potential: number
  simple: boolean
  standards: IProductStandard[]
  agribalyze_category: AgribalyzeCategory
  annual_sales_volume: number
  craveability: number
  timestamp: string // Used internally for the editing history
  input_weight_unit: WeightOption
  temperature: BeverageTemperature
}

export interface State {
  error: string
  isLoading: boolean
  isRebalancing: boolean
  recipes: Recipe[]
  impactData: ImpactData[]
  lockedImpactData: ImpactData
  changeLog: string[]
  ingredientsMoved: boolean // Set to true if user moved or deleted an ingredient
}

export const initialImpactScore: ImpactScore = {
  biodiversity_impact: null,
  labor_impact: null,
  processing_impact: null,
  water_impact: null,
  blue_water_impact: null,
  raw_blue_water_usage_impact: null,
  raw_greenhouse_gas_impact: null,
  animal_welfare_impact: null,
  soc_impact: null,
  raw_soc_impact: null,
  working_conditions_impact: null,
  land_use_impact: null,
  raw_land_use_impact: null,
  hive_five_impact: null,
  environmental_impact: null,
  supply_chain_resilience_impact: null,
  planetary_health_percentage_impact: null,
  processing_level_impact: null,
  animal_diet_impact: null,
  applegate_human_health_impact: null,
  applegate_environmental_health_impact: null,
  applegate_animal_health_impact: null,
  applegate_health_score: null,
  applegate_impact: null,
  howgood_total_impact: null,
  howgood_one_metric: null,
  starbucks_impact: null,
  raw_ftmgate_water_withdrawal_impact: null,
  carbon_footprint_farm_to_gate_impact: null,
  carbon_footprint_on_farm_processing_ghgs_impact: null,
  carbon_footprint_annual_impact: null,
  betterThan: null,
  cf_ftm_gate_ct_verified_impact: null,
  cf_flag_impact: null,
  cf_removals_impact: null,
  cf_total_upstream_transportation_impact: null,
  cf_mtm_transportation_impact: null,
  cf_manufacturing_impact: null,
  cf_packaging_total_impact: null,
  cf_total_upstream_processing_impact: null,
  cf_packaging_impact: null,
  cf_mts_transportation_impact: null,
  cf_storage_impact: null,
  cf_mtr_transportation_impact: null,
  cf_retail_storage_impact: null,
  cf_biogenic_processing_impact: null,
  cf_consumption_impact: null,
  cf_packaging_waste_impact: null,
  cf_cradle_to_shelf_impact: null,
  cf_full_lifecycle_impact: null,
  cf_processing_impact: null,
  labor_risk_information: [],
  nutrition_score: null,
  eco_score_impact: null,
  default_origin_percent_impact: null,
  retail_rating_impact: null,
  [SCOPE_3_CATEGORY_1_FIELD]: null,
  [SCOPE_3_CATEGORY_4_FIELD]: null,
}

const initialSalesDistribution: SalesDistributionInfo = {
  weight_kg: 0,
  annual_sales_volume: 0,
  requires_cooking: false,
  requires_cold_storage: '',
  manufacturing_type: null,
  manufacturing_region: null,
  retail_region: null,
  storage_region: null,
}

export const initialImpactData: ImpactData = {
  ingredients: [],
  weights: [],
  product: initialImpactScore,
  maxValues: initialImpactScore,
  nonNullFields: [],
  timestamp: null,
}
const initialRecipeData: Recipe = {
  ingredients: [],
  org: false,
  packaging: [],
  transportation: { mtr: [], mts: [] },
  sales_distribution: initialSalesDistribution,
  sales_potential: 0,
  simple: false,
  standards: [],
  agribalyze_category: null,
  annual_sales_volume: 0,
  craveability: 0,
  timestamp: null,
  input_weight_unit: PERCENTAGES,
  temperature: null,
}

export const initialState: State = {
  error: '',
  isLoading: false,
  isRebalancing: false,
  recipes: [initialRecipeData],
  impactData: [initialImpactData],
  lockedImpactData: initialImpactData,
  changeLog: ['Empty Defaults'],
  ingredientsMoved: false,
}

// Interfaces for the v2 POST /impact-scores response
export interface NestedImpactResponse extends ImpactScore {
  impact_scores?: (NestedIngredientResponse | NestedProductResponse)[]
  weights: number[]
}

export interface NestedIngredientResponse extends ImpactScore {
  flat_weight: number
  id: number
  position: number
  weight: number
}

export interface NestedProductResponse extends ImpactScore {
  flat_weight: number
  impact_scores: (NestedIngredientResponse | NestedProductResponse)[]
  weight: number
  weights: number[]
}
