/* eslint-disable no-param-reassign */
import { matchPath } from 'react-router'
import { MATERIAL_PATH, PRODUCT_PATH, VENDOR_PATH } from '@/constants/config'
import { FORMULATION_TAGS } from '@/constants/common'
import queryString from 'query-string'
import { UnknownVendorOptionValue, WorkspaceType } from '@/records'

interface FormulationsSearchParamsForUrlConstruct {
  workspaces?: any[]
  statuses?: string[]
  query?: string[]
  ingredients?: string[] | null
  components?: number[]
  vendors?: (number | UnknownVendorOptionValue)[]
  page?: number
  sortOptions?: any[]
  formulationTags?: string[] | null
  workspaceTypes?: WorkspaceType[]
  standardsIds?: number[]
  regions?: number[]
  dataGranulars?: string[]
}

interface FormulationsSearchParamsFromUrl {
  workspaces?: any[]
  statuses?: string[]
  query?: string[]
  ingredients?: string[]
  components?: string[]
  vendors?: string[]
  page?: string
  sortOptions?: any[]
  salesCategories?: string[]
  formulationTags?: string[]
  workspaceTypes?: WorkspaceType[]
  standardsIds?: number[]
  regionIds: number[]
  dataGranularValues: string[]
}

interface AggregatedVendorsSearchParamsForUrlConstruct {
  workspaces?: any[]
  manufacturingTypes?: number[]
  vendorNameQueries?: string[]
  formulationTags?: string[] | null
  formulationStatuses?: string[]
}

interface AggregatedVendorsSearchParamsFromUrl {
  workspaces?: string[]
  manufacturingTypes?: string[]
  vendorNameQueries?: string[]
  formulationTags?: string[]
  formulationStatuses?: string[]
}

export const shareLocation = (id: number, isProcurement: boolean = false, scenarioId: number = null) => {
  let path = `${window.location.origin}/${isProcurement ? 'materials' : 'products'}/${id}`
  if (scenarioId) {
    path += `/?scenario=${scenarioId}`
  }
  return path
}

export const formulateListParam = (itemList: any[], itemName: string, qString: string) => {
  if (!itemList.length) {
    return qString
  }
  qString = qString || '?'
  qString = itemList.reduce(
    (accum: string, item: any, index: number) =>
      (accum += `${accum.endsWith('?') ? '' : '&'}${itemName}[${index}]=${encodeURIComponent(item)}`),
    qString
  )
  return qString
}

export const createAggregatedVendorsSearchParams = ({
  workspaces = [],
  manufacturingTypes = [],
  vendorNameQueries = [],
  formulationTags = [],
  formulationStatuses = [],
}: AggregatedVendorsSearchParamsForUrlConstruct = {}) => {
  let qString = ''
  if (workspaces.length) {
    qString = formulateListParam(workspaces, 'workspaces', qString)
  }
  if (manufacturingTypes.length) {
    qString = formulateListParam(manufacturingTypes, 'manufacturingTypes', qString)
  }
  if (vendorNameQueries.length) {
    qString = formulateListParam(vendorNameQueries, 'vendorNameQueries', qString)
  }
  if (formulationStatuses.length) {
    qString = formulateListParam(formulationStatuses, 'formulationStatuses', qString)
  }
  if (formulationTags.length) {
    qString = formulateListParam(formulationTags, 'formulationTags', qString)
  }

  return qString
}

export const createFormulationsSearchParams = (params: FormulationsSearchParamsForUrlConstruct = {}) => {
  let qString = ''
  const {
    workspaces,
    statuses,
    query,
    ingredients,
    components,
    vendors,
    page,
    sortOptions,
    formulationTags,
    workspaceTypes,
    standardsIds,
    regions,
    dataGranulars,
  } = params
  if (workspaces && workspaces.length) {
    qString = formulateListParam(workspaces, 'workspaces', qString)
  }
  if (statuses && statuses.length) {
    qString = formulateListParam(statuses, 'statuses', qString)
  }
  if (workspaceTypes && workspaceTypes.length) {
    qString = formulateListParam(workspaceTypes, 'workspaceTypes', qString)
  }
  if (sortOptions && sortOptions.length) {
    qString = qString || '?'
    const [option] = sortOptions
    qString += `${qString.endsWith('?') ? '' : '&'}sortField=${encodeURIComponent(
      Object.keys(option)[0]
    )}&sortDirection=${encodeURIComponent((Object.values(option)[0] as { order: string }).order)}`
  }
  if (query && query.length) {
    qString = qString || '?'
    qString = query.reduce(
      (reducer: string, queryItem: string, index: number) =>
        (reducer += `${reducer.endsWith('?') ? '' : '&'}query[${index}]=${encodeURIComponent(queryItem)}`),
      qString
    )
  }
  if (page) {
    qString = qString || '?'
    qString += `${qString.endsWith('?') ? '' : '&'}page=${encodeURIComponent(page)}`
  }
  if (ingredients && ingredients.length) {
    qString = formulateListParam(ingredients, 'ingredients', qString)
  }
  if (components && components.length) {
    qString = formulateListParam(components, 'components', qString)
  }
  if (vendors && vendors.length) {
    qString = formulateListParam(vendors, 'vendors', qString)
  }
  if (formulationTags && formulationTags.length) {
    qString = formulateListParam(formulationTags, 'formulationTags', qString)
  }
  if (standardsIds && standardsIds.length) {
    qString = formulateListParam(standardsIds, 'standardsIds', qString)
  }
  if (regions && regions.length) {
    qString = formulateListParam(regions, 'regions', qString)
  }
  if (dataGranulars && dataGranulars.length) {
    qString = formulateListParam(dataGranulars, 'dataGranulars', qString)
  }

  return qString
}

export const getFormulationsFiltersParams = (): FormulationsSearchParamsFromUrl => {
  const params = queryString.parse(window.location.search)
  const entries = Object.entries(params)
  const workspaces = entries.filter((entry) => entry[0].includes('workspaces[')).map((entry) => entry[1])
  const standardsIds = entries.filter((entry) => entry[0].includes('standardsIds[')).map((entry) => +entry[1])
  const regionIds = entries.filter((entry) => entry[0].includes('regions[')).map((entry) => +entry[1])
  const dataGranularValues = entries
    .filter((entry) => entry[0].includes('dataGranulars['))
    .map((entry) => entry[1]) as string[]
  const salesCategories = entries
    .filter((entry) => entry[0].includes('sales-categories['))
    .map((entry) => entry[1]) as string[]
  const statuses = entries.filter((entry) => entry[0].includes('statuses[')).map((entry) => entry[1]) as string[]
  const workspaceTypes = entries
    .filter((entry) => entry[0].includes('workspaceTypes['))
    .map((entry) => entry[1]) as WorkspaceType[]
  const queryArray = entries.filter((entry) => entry[0].includes('query')).map((entry) => entry[1]) as string[]
  const pageArray = entries.filter((entry) => entry[0].includes('page')).map((entry) => entry[1]) as string[]
  const ingredients = entries.filter((entry) => entry[0].includes('ingredients')).map((entry) => entry[1]) as string[]
  const components = entries.filter((entry) => entry[0].includes('components')).map((entry) => entry[1]) as string[]
  const vendors = entries.filter((entry) => entry[0].includes('vendors')).map((entry) => entry[1]) as string[]
  const formulationTags = entries
    .filter((entry) => entry[0].includes(FORMULATION_TAGS))
    .map((entry) => entry[1]) as string[]
  const query = queryArray.length ? queryArray : []
  const page = pageArray.length ? pageArray.pop() : '1'
  return {
    salesCategories,
    workspaces,
    statuses,
    workspaceTypes,
    query,
    ingredients,
    components,
    vendors,
    page,
    formulationTags,
    standardsIds,
    regionIds,
    dataGranularValues,
    sortOptions:
      params.sortDirection && params.sortField
        ? [{ [params.sortField as string]: { order: params.sortDirection } }]
        : [],
  }
}

export const getAggregatedVendorsFiltersParams = (): AggregatedVendorsSearchParamsFromUrl => {
  const params = queryString.parse(window.location.search)
  const entries = Object.entries(params)
  const workspaces = entries.filter((entry) => entry[0].includes('workspaces[')).map((entry) => entry[1]) as string[]
  const manufacturingTypes = entries
    .filter((entry) => entry[0].includes('manufacturingTypes['))
    .map((entry) => entry[1]) as string[]
  const vendorNameQueries = entries
    .filter((entry) => entry[0].includes('vendorNameQueries'))
    .map((entry) => entry[1]) as string[]
  const formulationTags = entries
    .filter((entry) => entry[0].includes(FORMULATION_TAGS))
    .map((entry) => entry[1]) as string[]
  const formulationStatuses = entries
    .filter((entry) => entry[0].includes('formulationStatuses['))
    .map((entry) => entry[1]) as string[]
  return {
    workspaces,
    manufacturingTypes,
    vendorNameQueries,
    formulationTags,
    formulationStatuses,
  }
}

const matchFormulaUrl = (path: string) =>
  matchPath<{ formula: string }>(window.location.pathname, {
    path,
  })

export const getCurrentFormulaId = () => {
  const match = matchFormulaUrl(PRODUCT_PATH) || matchFormulaUrl(MATERIAL_PATH)
  if (match && match.params && match.params.formula) {
    return parseInt(match.params.formula, 10)
  }
  return null
}

const matchVendorUrl = (path: string) =>
  matchPath<{ vendor: string }>(window.location.pathname, {
    path,
  })
export const getCurrentVendorId = () => {
  const match = matchVendorUrl(VENDOR_PATH)
  if (match && match.params && match.params.vendor) {
    return parseInt(match.params.vendor, 10)
  }
  return null
}

export const getSearchParams = () => {
  const { search } = window.location
  return new URLSearchParams(search)
}
