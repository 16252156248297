import React, { FC } from 'react'
import { Field } from '@/constants/impactScore'
import { useContentfulAttribute } from '@/contentfully/hooks'
import { Stack, Text } from '@howgood/design'

interface ProductAttributeProps {
  attribute: { field: Field; value: boolean }
  portfolioPercentage: number | null
  withDescription?: boolean
  width?: string
}

export const ProductAttribute: FC<ProductAttributeProps> = ({
  attribute,
  portfolioPercentage,
  withDescription = true,
  width,
}) => {
  const content = useContentfulAttribute(attribute?.field)

  const contentfulData = useContentfulAttribute(attribute?.field)
  const iconUrl = contentfulData.icon?.fields?.file?.url as string

  return (
    <Stack width={width} gap={0.5} sx={{ padding: '4px 0', alignItems: 'center', maxWidth: '120px' }}>
      {iconUrl && <img width="80px" src={iconUrl} style={{ opacity: attribute?.value ? 1 : 0.3 }} />}
      {withDescription && !!content?.trueDescription && (
        <Text variant="caption" textAlign="center">
          {content.trueDescription}
        </Text>
      )}
      {portfolioPercentage != null && (
        <Text fontWeight="bold" variant="body1">
          {portfolioPercentage}%
        </Text>
      )}
    </Stack>
  )
}
