import { createSlice } from '@reduxjs/toolkit'
import { getFunctionalCategories } from './functionalCategories.thunk'
import { FunctionalCategory } from '@/api'

export interface State {
  isLoading: boolean
  functionalCategories: FunctionalCategory[]
}
export const initialState: State = {
  isLoading: false,
  functionalCategories: [],
}

const functionalCategoriesSlice = createSlice({
  name: 'functionalCategories',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFunctionalCategories.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getFunctionalCategories.fulfilled, (state, action) => {
      state.isLoading = false
      state.functionalCategories = action.payload
    })
    builder.addCase(getFunctionalCategories.rejected, (state) => {
      state.isLoading = false
      state.functionalCategories = initialState.functionalCategories
    })
  },
})

export const functionalCategoriesReducer = functionalCategoriesSlice.reducer
