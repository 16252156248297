import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Stack } from '@howgood/design'
import { selectChartType, selectPortfolioDataAsTree } from '../state/portfolioDashboard'
import { TreeMap } from './TreeMap'
import { BarChart } from './BarChart'
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium'
import { PieChart } from './PieChart'

interface ChartWrapperProps {
  height: number
  gridApiRef: React.MutableRefObject<GridApiPremium>
  chartRef: React.MutableRefObject<any>
}

export const ChartWrapper: FC<ChartWrapperProps> = ({ height, gridApiRef, chartRef }) => {
  const chartType = useSelector(selectChartType)
  const treeData = useSelector(selectPortfolioDataAsTree)

  return (
    <Stack gap={1} ref={chartRef} height={height} width="100%" data-testid="portfolio-dashboard-chart-wrapper">
      {/* Don't attempt to render the mosaic/pie if there are no workspaces, tags, or categories */}
      {chartType === 'mosaic' && treeData.children.length > 0 && <TreeMap height={height} />}
      {chartType === 'pie' && treeData.children.length > 0 && <PieChart />}
      {/* Always render the bar chart as it's needed to support the export function */}
      <BarChart height={height} gridApiRef={gridApiRef} />
    </Stack>
  )
}
