import React, { FunctionComponent, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Text,
  MenuItem,
  Select,
  SelectChangeEvent,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@howgood/design'
import { selectAllWorkspacesOptions } from '@/state/workspaces'
import { useNavigateToFormula } from '@/hooks'
import { getCurrentFormulaId } from '@/utils/getUrl'

interface Props {
  onClose: () => void
}

export const WorkspacesDialog: FunctionComponent<Props> = ({ onClose }) => {
  const [workspaceId, setWorkspaceId] = useState('')
  const workspaces = useSelector(selectAllWorkspacesOptions)
  const navigateToFormula = useNavigateToFormula()
  const productId = getCurrentFormulaId()

  const handleWorkspaceChange = (event: SelectChangeEvent) => {
    setWorkspaceId(event.target.value)
  }

  const handleNavigateToNewFormula = () => {
    const params = new URLSearchParams()
    params.set('workspaceId', workspaceId)
    params.set('nestedProductId', productId.toString())
    const paramsString = params.toString()
    navigateToFormula(null, false, paramsString)
  }

  return (
    <Dialog id="workspaces-dialog" open onClose={onClose}>
      <DialogTitle>Select a workspace</DialogTitle>
      <DialogContent>
        <Text>Workspace</Text>
        <Select id="workspace-select" fullWidth onChange={handleWorkspaceChange} value={workspaceId}>
          {workspaces.map((w) => (
            <MenuItem key={w.id} value={w.id} disabled={!w.edit}>
              {w.label}
            </MenuItem>
          ))}
        </Select>
      </DialogContent>
      <DialogActions>
        <Button id="cancel-use-as-ingredient-button" onClick={onClose}>
          Cancel
        </Button>
        <Button
          id="continue-use-as-ingredient-button"
          color="primary"
          disabled={!workspaceId}
          onClick={handleNavigateToNewFormula}
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  )
}
