import React, { FC, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { faColumns } from '@fortawesome/pro-regular-svg-icons'
import { CheckboxOption } from '@/components/Shared/CheckboxesDropdown/CheckboxesDropdown'
import { CheckboxesDropdown } from '@/components'
import { selectGridColumnVisibilityModel, setGridColumnVisibilityModel } from '@/state/vendorManagement'
import { updateVendorManagementSettings } from '@/state/user'
import { useGridColumns } from '../Grid/GridColumns'
import { GridColumnVisibilityModel } from '@mui/x-data-grid-premium'

export const ColumnOptionsButton: FC = () => {
  const dispatch = useDispatch()
  const availableColumns = useGridColumns()
  const columnVisibilityModel = useSelector(selectGridColumnVisibilityModel)

  // It's possible the columnVisibilityModel has keys that are no longer in the availableColumns list
  // because we removed a column or changed the available vendor metrics. This function filters out those keys.
  const filterModel = (visibilityModel: GridColumnVisibilityModel) => {
    const filteredModel = Object.entries(visibilityModel).reduce((acc, [key, value]) => {
      return availableColumns.find((column) => column.field === key) ? { ...acc, [key]: value } : acc
    }, {})
    return filteredModel
  }

  // Create list of checkbox options, settings all options to `checked` unless false in the columnVisibilityModel
  const options: CheckboxOption[] = useMemo(
    () =>
      availableColumns.map((option) => ({
        ...option,
        key: option.field,
        label: option.headerName,
        checked: columnVisibilityModel[option.field] === false ? false : true,
        disabled: option.checkBoxDisabled,
      })),
    [availableColumns, columnVisibilityModel]
  )

  // The `option` is the checkbox that was clicked with its PREVIOUS true/false value
  const handleOptionCheckChange = (option: CheckboxOption) => {
    // Remove the option that was clicked from the columnVisibilityModel
    const { [option.key]: _clicked, ...rest } = columnVisibilityModel

    // Add it back only if it was previously true (columns are visible unless set to false)
    const newColumnVisibilityModel = option.checked ? { ...rest, [option.key]: false } : rest
    dispatch(setGridColumnVisibilityModel(filterModel(newColumnVisibilityModel)))
  }

  const handleMultiOptionsCheck = (multiOptions: CheckboxOption[]) => {
    const enabledOptions = multiOptions.filter((option) => !option.disabled)
    const allGroupOptionsChecked = enabledOptions.every((option) => option.checked)

    // Get columns from the columnVisibilityModel that aren't in the selected options group
    const rest = Object.entries(columnVisibilityModel).reduce((acc, [key, value]) => {
      return !multiOptions.find((option) => option.key === key) ? { ...acc, [key]: value } : acc
    }, {})

    // If all options in the group are checked, set all enabled options to false; otherwise exclude them from the columnVisibilityModel
    const newColumnVisibilityModel = allGroupOptionsChecked
      ? enabledOptions.reduce((acc, option) => ({ ...acc, [option.key]: false }), rest)
      : rest
    dispatch(setGridColumnVisibilityModel(filterModel(newColumnVisibilityModel)))
  }

  return (
    <CheckboxesDropdown
      icon={faColumns}
      label="Column options"
      data-pendo-id="VendorManagementColumnOptions"
      options={options}
      onOptionCheckChange={handleOptionCheckChange}
      onOptionsCheckChange={handleMultiOptionsCheck}
      onCloseDropdown={() => dispatch(updateVendorManagementSettings())}
      applySelectDeselectAll
    />
  )
}
