import { createSelector } from 'reselect'
import {
  FormulationStatus,
  FormulationStatusOption,
  getWorkspaceTypes,
  VendorInfo,
  Workspace,
  WorkspaceOption,
} from '@/records'
import { AppState } from '@/store'
import { VendorManagementFilters, BucketAggs, VendorManagementSettings } from './vendorManagement.slice'
import { selectWorkspacesWithScores } from '../workspaces'
import { GridColumnVisibilityModel } from '@mui/x-data-grid-premium'
import { ManufacturingTypeOption } from '../manufacturingTypes'
import { FormulationTagFilterOption } from '../products'

interface ConstructedFilters {
  formulationStatuses: FormulationStatus[]
  vendorNameQueries: string[]
  workspaceIds: number[]
  formulationTags: string[]
  manufacturingTypeIds: number[]
}

export const selectVendorData = (state: AppState): VendorInfo[] => state.vendorManagement.vendorData
export const selectVendorBucketAggs = (state: AppState): BucketAggs => state.vendorManagement.bucketAggs
export const selectVendorDataIsLoading = (state: AppState): boolean => state.vendorManagement.isLoading
export const selectIsUpdatingVendor = (state: AppState): number => state.vendorManagement.vendorBeingUpdated
export const selectVendorJustUpdated = (state: AppState): number => state.vendorManagement.vendorJustUpdated

export const selectVendorManagementSettings = (state: AppState): VendorManagementSettings =>
  state.vendorManagement.settings
export const selectGridColumnVisibilityModel = (state: AppState): GridColumnVisibilityModel =>
  state.vendorManagement.settings.columnVisibilityModel

const selectVendorFilters = (state: AppState): VendorManagementFilters => state.vendorManagement.filters
export const selectEditingVendorsFilter = (state: AppState): string => state.vendorManagement.filters.editingFilter
export const selectVendorWorkspaces = (state: AppState): WorkspaceOption[] => state.vendorManagement.filters.workspaces
export const selectVendorManufacturingTypes = (state: AppState): ManufacturingTypeOption[] =>
  state.vendorManagement.filters.manufacturingTypes
export const selectVendorTags = (state: AppState): FormulationTagFilterOption[] =>
  state.vendorManagement.filters.formulationTags
export const selectVendorFormulationStatuses = (state: AppState): FormulationStatusOption[] =>
  state.vendorManagement.filters.formulationStatuses
export const selectVendorNames = (state: AppState): string[] => state.vendorManagement.filters.vendorNameQueries

export const selectVendorWorkspaceIds = createSelector(selectVendorWorkspaces, (workspaces) =>
  workspaces.map((ws) => ws.id)
)

export const selectVendorManufacturingTypeIds = createSelector(selectVendorManufacturingTypes, (types) =>
  types.map((type) => type.id)
)

// Filter all workspaces that have products with at least one non-null impact score
// by workspace type (as set by the workspace type filter AND page context)
export const selectAllowedVendorWorkspaces = createSelector<AppState, Workspace[], Workspace[]>(
  selectWorkspacesWithScores,
  (workspaces) => {
    const allowedTypes = getWorkspaceTypes('Vendor Management')

    return workspaces?.filter((ws) => allowedTypes.includes(ws.workspace_type))
  }
)

// Conversion of the redux aggregated vendors filters into the format we need
// to pass into the vendorManagement thunk AND the vendorManagement list component
export const selectConstructedFilters = createSelector<
  AppState,
  Workspace[],
  WorkspaceOption[],
  VendorManagementFilters,
  ConstructedFilters
>(
  selectAllowedVendorWorkspaces,
  selectVendorWorkspaces,
  selectVendorFilters,
  (allowedWorkspaces, workspacesFilters, vendorsFilters) => {
    const { vendorNameQueries: names, formulationStatuses, formulationTags, manufacturingTypes } = vendorsFilters

    const workspaces = workspacesFilters.length ? workspacesFilters : allowedWorkspaces
    const statusValues = formulationStatuses.map((val) => val.value)

    const filters = {
      formulationStatuses: statusValues.length ? statusValues : [],
      vendorNameQueries: names,
      workspaceIds: workspaces.map((ws) => ws.id),
      manufacturingTypeIds: manufacturingTypes.map((type) => type.id),
      formulationTags: formulationTags.map((tag) => tag.name),
    }

    return filters
  }
)
