import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { createUseStyles } from 'react-jss'
import { faX } from '@fortawesome/pro-regular-svg-icons'
import { IconButton } from '@howgood/design'
import { IngredientSearchOption } from '@/records'
import { fetchOriginLocations } from '@/state/originLocations/originLocations.requests'
import { getIngredientStandardsOptions } from '@/utils/ingredients'
import { MultiSelectList } from '../MultiSelectList'
import { SearchOption } from '../types'
import { selectProductCertificationStandards } from '@/state/productStandards'

interface SelectedIngredientRowProps {
  ingredient: IngredientSearchOption
  onRemove: (ingredient: IngredientSearchOption) => void
  onChange?: (ingredient: IngredientSearchOption, locations: SearchOption[], sourcingStandards: SearchOption[]) => void
}

const useStyles = createUseStyles({
  removeIcon: {
    marginRight: 18,
  },
  ingredientColumn: {
    lineHeight: '36px',
    padding: '6px 10px 6px 0',
  },
  filterColumn: {
    padding: '6px 0px 6px 20px',
  },
})

const SelectedIngredientRow: FC<SelectedIngredientRowProps> = ({ ingredient, onRemove, onChange }) => {
  const classes = useStyles()
  const certificationStandards = useSelector(selectProductCertificationStandards)

  const ingredientSource = {
    ...ingredient.raw._source,
    origin_locations: ingredient.raw._source.origin_locations || [],
  }
  const defaultLocation = {
    label: ingredient.raw._source.default_origin_location?.region_name,
    value: ingredient.raw._source.default_origin_location?.id,
    isDefault: true,
  }

  const [availableLocations, setAvailableLocations] = useState<SearchOption[]>([defaultLocation])
  const availableStandards = [
    { label: 'None', value: 0, isDefault: true },
    ...(getIngredientStandardsOptions(ingredientSource as any, certificationStandards) as SearchOption[]),
  ]

  const [selectedLocations, setSelectedLocations] = useState<SearchOption[]>([defaultLocation])
  const [selectedStandards, setSelectedStandards] = useState<SearchOption[]>(availableStandards)

  useEffect(() => {
    async function apiFetchLocations() {
      const regions = await fetchOriginLocations([ingredient.raw._source.id])
      const locations = regions.map((region) => ({
        label: region.region_name,
        value: region.origin_location_id,
        isDefault: defaultLocation.value === region.origin_location_id,
      }))
      const defaultLocationIdx = locations.findIndex((item: SearchOption) => item.value === defaultLocation.value)
      if (defaultLocationIdx === -1) {
        locations.push(defaultLocation)
      }
      setAvailableLocations(locations)
      setSelectedLocations(locations)
      onChange(ingredient, locations, availableStandards)
    }

    apiFetchLocations()
    // eslint-disable-next-line
  }, [ingredient])

  const handleSelectStandards = (value: SearchOption[]) => {
    const v = value.length ? value.map((s) => ({ ...s, explicitlySelected: true })) : availableStandards
    setSelectedStandards(v)
    onChange(ingredient, selectedLocations.length ? selectedLocations : availableLocations, v)
  }

  const handleSelectLocations = (value: SearchOption[]) => {
    setSelectedLocations(value)
    const v = value.length ? value : availableLocations
    onChange(ingredient, v, selectedStandards.length ? selectedStandards : availableStandards)
  }

  return (
    <tr>
      <td className={classes.ingredientColumn}>
        <IconButton icon={faX} onClick={() => onRemove(ingredient)} />
      </td>
      <td className={classes.ingredientColumn}>{ingredient.raw._source.name}</td>
      <td className={classes.filterColumn}>
        <MultiSelectList options={availableStandards} onChange={handleSelectStandards} />
      </td>
      <td className={classes.filterColumn}>
        <MultiSelectList options={availableLocations} onChange={handleSelectLocations} />
      </td>
    </tr>
  )
}

export default SelectedIngredientRow
