import { fetchHelperV2 } from '@/api'
import { IBrandSearchOption, ProductBrand, SettableVendorInfo, VendorByBrandResponse } from '@/records'
import * as Sentry from '@sentry/react'

interface BrandsResponse {
  items: ProductBrand[]
  page?: number
  pages?: number
  size?: number
  total?: number
  aborted?: boolean
}

// Version used by VendorFilter.tsx (returns list of { value: number, label: string })
export const fetchBrandOptions = async (search: string = ''): Promise<IBrandSearchOption[]> => {
  if (!search.trim()) {
    return []
  }

  let data: BrandsResponse = { items: [] }

  try {
    data = await fetchHelperV2({ url: `orgs/brands/?name=${search}&page=1&size=40` })
  } catch (error) {
    console.log(error)
  }

  return data.items.map((item) => ({ value: item.id, label: item.name }))
}

interface FetchBrandsParams {
  search: string
  show_all?: boolean
  page?: number
  size?: number
  signal?: AbortSignal
}

/**
 * Fetches a list of brands based on the search string.
 * @param params.search The search string (required)
 * @param params.show_all true to fetch all brands; false to fetch only brands associated with products owned by the current user (the default)
 * @param params.page: The page number (default is 1)
 * @param params.size The page size (default is 40)
 * @param params.signal An optional AbortSignal to cancel the request
 * @returns The GET /orgs/brands response
 */
export const fetchBrands = async (params: FetchBrandsParams): Promise<BrandsResponse> => {
  const { search, show_all = false, page = 1, size = 40, signal = null } = params

  if (!search.trim()) return { items: [] }

  const queryString = `name=${search}&page=${page}&size=${size}${show_all ? '&show_all=true' : ''}`

  try {
    return await fetchHelperV2<BrandsResponse>({ url: `orgs/brands/?${queryString}`, signal: signal })
  } catch (error) {
    if (signal?.aborted) {
      return { items: [], aborted: true }
    }
    Sentry.captureException(error)
    return { items: [] }
  }
}

export const fetchBrandById = async (id: number): Promise<ProductBrand> => {
  return await fetchHelperV2<ProductBrand>({ url: `orgs/brands/${id}/` })
}

// For the supplied brand ids, return the matching brand objects
export const fetchBrandsByIds = async (ids: number[]): Promise<BrandsResponse> => {
  const queryString = ids.map((id) => `ids=${id}`).join('&')
  return await fetchHelperV2<BrandsResponse>({ url: `orgs/brands/?${queryString}` })
}

/**
 * Updates a vendor (an org-specific brand) with the supplied vendor info (e.g. priority)
 * If a vendor doesn't exist for the user's org, the backend creates one for the given brand
 * @param brandId The brand id
 * @param vendorInfo An object with settable vendor info, e.g. { priority: 2 }
 * @returns If the request succeeds, the vendor info that was passed in is returned
 */
export const patchVendorByBrand = async (
  brandId: number,
  vendorInfo: Partial<SettableVendorInfo>
): Promise<Partial<SettableVendorInfo>> => {
  await fetchHelperV2<void>({
    url: `orgs/vendors/by-brand/${brandId}/`,
    method: 'PATCH',
    body: JSON.stringify(vendorInfo),
    parse: false, // Successful PATCH request returns an empty 204 response
  })
  // Sccessful PATCH requests doesn't return anything, so return the vendor info that was passed in
  // Failed requests won't reach here as they'll throw an error and display a message to the user
  return vendorInfo
}

export const fetchVendorByBrand = async (id: number): Promise<VendorByBrandResponse> => {
  return await fetchHelperV2<VendorByBrandResponse>({ url: `orgs/vendors/by-brand/${id}/` })
}
