import { createAsyncThunk } from '@reduxjs/toolkit'
import { AppState } from '@/store'
import { fetchAllV2, fetchHelperV2 } from '@/api'
import { OrgUser, Report, ReportType } from './organization.slice'
import { Organization } from '@/records'
import { selectReports } from './organization.selectors'

interface OrganizationData {
  id: number
  name: string
  salesforce_account_id: string
  active_status: string
  customer_type: string
  tier: string
}

export const getOrganization = createAsyncThunk<{ organization: Organization; users: OrgUser[] }, number>(
  'user/getOrganization',
  async (organizationId) => {
    const data = await fetchHelperV2<OrganizationData>({ url: `accounts/organizations/${organizationId}/` })
    const users = await fetchAllV2<Omit<OrgUser, 'label'>>({ url: 'accounts/users/' })
    // Insert a display label field e.g. 'peter@howgood.com (Peter Henderson)' into each user record
    const displayUsers = users.length
      ? users.map((user) => ({
          ...user,
          label:
            user.first_name || user.last_name
              ? `${user.email} (${[user.first_name, user.last_name].filter((name) => name).join(' ')})`
              : user.email,
        }))
      : []
    return {
      organization: {
        organizationId: data.id,
        organizationName: data.name,
        salesforceAccountId: data.salesforce_account_id,
        activeStatus: data.active_status,
        customerType: data.customer_type,
        tier: data.tier,
      },
      users: displayUsers,
    }
  }
)

export const fetchReports = async () => await fetchAllV2<Report>({ url: 'accounts/reports/' })
export const getReports = createAsyncThunk<Report[], void, { state: AppState }>(
  'reports/getReports',
  async () => await fetchReports()
)

export const fetchDetailReport = async (id: number) => await fetchHelperV2<Report>({ url: `accounts/reports/${id}` })
export const getDetailReport = createAsyncThunk<Report[], number, { state: AppState }>(
  'reports/getDetailReport',
  async (id, { getState }) => {
    const allReports = [...selectReports(getState())]
    const detailReport = await fetchDetailReport(id)
    const reportIdx = allReports.findIndex((report) => report.id === id)
    if (reportIdx > -1) {
      allReports[reportIdx] = detailReport
    } else {
      allReports.push(detailReport)
    }
    return allReports
  }
)

interface ReportData {
  file: string
  report_type: ReportType
  name: string
  metadata: object
}

export const saveReport = createAsyncThunk<Report, ReportData, { state: AppState }>(
  'organization/saveReport',
  async ({ file, name, metadata, report_type }) => {
    return await fetchHelperV2({
      url: `accounts/reports/`,
      method: 'POST',
      body: JSON.stringify({ file, metadata, report_type, name }),
    })
  }
)

export const deleteReport = createAsyncThunk<number, number, { state: AppState }>(
  'organization/deleteReport',
  async (id) => {
    await fetchHelperV2({
      url: `accounts/reports/${id}/`,
      method: 'DELETE',
      parse: false,
    })
    return id
  }
)
