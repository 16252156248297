import React, { FC } from 'react'
import MuiMenu, { MenuProps as MuiMenuProps } from '@mui/material/Menu'
import MuiMenuItem, { MenuItemProps as MuiMenuItemProps } from '@mui/material/MenuItem'

interface MenuItemProps
  extends Pick<
    MuiMenuItemProps,
    'id' | 'aria-label' | 'onClick' | 'disabled' | 'divider' | 'value' | 'selected' | 'children' | 'sx'
  > {
  'data-pendo-id'?: string
  'data-testid'?: string
}
export const MenuItem: FC<MenuItemProps> = ({
  id,
  ['data-pendo-id']: dataPendoId,
  ['data-testid']: dataTestId,
  ['aria-label']: ariaLabel,
  onClick,
  disabled,
  value,
  divider,
  selected,
  children,
  sx,
}) => {
  return (
    <MuiMenuItem
      id={id}
      data-pendo-id={dataPendoId}
      data-testid={dataTestId}
      aria-label={ariaLabel}
      onClick={onClick}
      divider={divider}
      value={value}
      disabled={disabled}
      selected={selected}
      sx={sx}
    >
      {children}
    </MuiMenuItem>
  )
}

type MuiMenuListProps = MuiMenuProps['MenuListProps']
interface MenuListPropsT extends MuiMenuListProps {
  'data-testid'?: string
}
export interface MenuProps
  extends Pick<
    MuiMenuProps,
    'id' | 'aria-label' | 'open' | 'anchorEl' | 'onClose' | 'anchorOrigin' | 'transformOrigin' | 'sx' | 'children'
  > {
  'data-testid'?: string
  MenuListProps?: MenuListPropsT
}

export const Menu: FC<MenuProps> = ({
  id,
  ['aria-label']: ariaLabel,
  ['data-testid']: dataTestId,
  anchorEl,
  anchorOrigin,
  transformOrigin,
  open,
  onClose,
  MenuListProps,
  sx,
  children,
}) => {
  return (
    <MuiMenu
      id={id}
      aria-label={ariaLabel}
      data-testid={dataTestId}
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      open={open}
      onClose={onClose}
      MenuListProps={MenuListProps}
      sx={sx}
    >
      {children}
    </MuiMenu>
  )
}
