import React, { FC, useCallback, useEffect, useState } from 'react'
import { GridColDef, GridApi } from '@mui/x-data-grid-premium'
import { faColumns } from '@fortawesome/pro-regular-svg-icons'
import { CheckboxesDropdown } from '@/components/Shared'
import { CheckboxOption } from '@/components/Shared/CheckboxesDropdown/CheckboxesDropdown'

interface Props {
  gridApi: GridApi
  columns: GridColDef[]
}

export const ColumnsFilter: FC<Props> = ({ gridApi, columns }) => {
  const [columnOptions, setColumnOptions] = useState<CheckboxOption[]>([])

  const updateColumnOptions = useCallback(() => {
    const newOptions = columns.map((column) => ({
      key: column.field,
      label: column.headerName,
      checked: gridApi.getVisibleColumns().findIndex((col) => col.field === column.field) !== -1,
    }))
    setColumnOptions(newOptions)
  }, [columns, gridApi])

  useEffect(() => {
    gridApi.subscribeEvent('columnVisibilityModelChange', () => updateColumnOptions())
    if (columnOptions.length === 0) updateColumnOptions()
  }, [columnOptions.length, updateColumnOptions, gridApi])

  function onOptionCheckChange(column: CheckboxOption) {
    gridApi.setColumnVisibility(column.key, !column.checked)
  }
  function onOptionsCheckChange(cols: CheckboxOption[]) {
    cols.forEach((column) => {
      gridApi.setColumnVisibility(column.key, !column.checked)
    })
  }

  return (
    <CheckboxesDropdown
      icon={faColumns}
      label="Column options"
      data-pendo-id="ColumnOptions"
      data-testid="column-options"
      options={columnOptions}
      onOptionCheckChange={onOptionCheckChange}
      onOptionsCheckChange={onOptionsCheckChange}
      applySelectDeselectAll
    />
  )
}
