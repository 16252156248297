import React, { FC, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Autocomplete, MenuItem, Stack, TextField } from '@howgood/design'
import { IngredientOption } from '@/components/Shared'
import { IngredientSearchOption } from '@/records'
import { selectCanViewPublicIngredients } from '@/state/user'
import { selectDefaultWorkspaceIds, selectWorkspacesIds } from '@/state/workspaces'
import { fetchIngredients } from '@/utils/ingredientSearch'
import { IngredientWithStandardsAndLocations, SearchOption } from '../types'
import { IngredientsSelectorList } from './IngredientsSelectorList'
import LabelButtons from './LabelButtons'
import { setLoadingOverlay } from '@/state/pageSettings'

interface IngredientsSelectorProps {
  onChange: (ingredient: IngredientWithStandardsAndLocations) => void
  onAdd: (ingredient: IngredientWithStandardsAndLocations) => void
  onRemove: (ingredient: IngredientWithStandardsAndLocations) => void
  onAddMultiple: (ids: string[]) => void
}

const useIngredientsSelector = ({ onChange, onRemove, onAdd, onAddMultiple }: IngredientsSelectorProps) => {
  const dispatch = useDispatch()
  // stores list of ingredients with list of selected locations and standards
  const [ingredientWithStandardsAndLocations, setIngredientWithStandardsAndLocations] = useState<
    IngredientWithStandardsAndLocations[]
  >([])

  // stores list of selected ingredients
  const [selectedIngredients, setSelectedIngredients] = useState<IngredientSearchOption[]>([])
  const ownWorkspacesIds = useSelector(selectWorkspacesIds)
  const canViewPublicIngredients = useSelector(selectCanViewPublicIngredients)
  const defaultWorkspaceIds = useSelector(selectDefaultWorkspaceIds)

  const fetchIngredientOptions = async (query: string): Promise<IngredientSearchOption[]> => {
    if (!query) {
      return new Promise(() => [])
    }
    const hits = await fetchIngredients({
      filters: {
        defaultWorkspaceIds,
        search: query,
        ownWorkspacesIds,
        canViewPublicIngredients,
        originLocationStatuses: ['published'],
      },
      onlyIngredients: true,
      size: 20,
    })

    return hits.map((hit: any) => new IngredientSearchOption(hit, query))
  }

  const handleSelectIngredient = async (value: IngredientSearchOption) => {
    setSelectedIngredients([...selectedIngredients, value])
  }

  const handleRemoveIngredient = (value: IngredientSearchOption) => {
    setSelectedIngredients(selectedIngredients.filter((item) => item !== value))
    const removedIngredient = ingredientWithStandardsAndLocations.find((item) => item.id === value.raw._id)
    const ingredientsWithStandardsAndLocationsCopy = ingredientWithStandardsAndLocations.filter(
      (item) => item.id !== value.raw._id
    )

    setIngredientWithStandardsAndLocations(ingredientsWithStandardsAndLocationsCopy)
    onRemove(removedIngredient)
  }

  const handleUpdateComparisonParams = (
    ingredientSearchOption: IngredientSearchOption,
    locations: SearchOption[],
    standards: SearchOption[]
  ) => {
    const ingredient = {
      id: ingredientSearchOption.raw._id,
      name: ingredientSearchOption.raw._source.name,
      baseId: ingredientSearchOption.raw._source.id,
    }

    setIngredientWithStandardsAndLocations((current) => {
      const idx = current.findIndex((item: any) => item.id === ingredientSearchOption?.raw?._id)
      const ingredientWithStandardsAndLocations = { ...ingredient, locations, standards }
      if (idx === -1) {
        current.push(ingredientWithStandardsAndLocations)
        onAdd(ingredientWithStandardsAndLocations)
      } else {
        current[idx] = ingredientWithStandardsAndLocations
        onChange(ingredientWithStandardsAndLocations)
      }

      return current
    })
  }

  const addByLabel = async (label: string) => {
    dispatch(setLoadingOverlay(`Loading all ${label || 'selected'} ingredients. This can take a few minutes.`))
    const hits = await fetchIngredients({
      filters: {
        defaultWorkspaceIds,
        search: '',
        ownWorkspacesIds,
        canViewPublicIngredients: canViewPublicIngredients,
        labels: [label],
      },
      onlyIngredients: true,
    })

    const result = hits.map((hit: any) => new IngredientSearchOption(hit))
    const mergedIngredients = [...selectedIngredients, ...result]
    const deduped = mergedIngredients.filter(
      (item, index, self) => index === self.findIndex((foundItem) => foundItem.value === item.value)
    )
    onAddMultiple(deduped.map((item) => item.value))
    setSelectedIngredients(deduped)
  }

  return {
    fetchIngredientOptions,
    selectedIngredients,
    handleSelectIngredient,
    handleRemoveIngredient,
    handleUpdateComparisonParams,
    addByLabel,
  }
}

export const IngredientsSelector: FC<IngredientsSelectorProps> = (props) => {
  const [options, setOptions] = useState<IngredientSearchOption[]>([])

  const {
    fetchIngredientOptions,
    selectedIngredients,
    handleSelectIngredient,
    handleRemoveIngredient,
    handleUpdateComparisonParams,
    addByLabel,
  } = useIngredientsSelector(props)

  const labels = useMemo(() => {
    // extract all labels, flatten array, filter out empty values and duplicated
    return selectedIngredients
      .map((ingredient) => ingredient.raw._source.labels)
      .flat()
      .filter((label) => !!label)
      .filter((label: string, index, self) => index === self.findIndex((foundItem) => foundItem === label))
  }, [selectedIngredients])

  return (
    <>
      <IngredientsSelectorList
        ingredients={selectedIngredients}
        onRemove={handleRemoveIngredient}
        onChange={handleUpdateComparisonParams}
      />
      <Stack width="100%" my={2}>
        <Autocomplete
          options={options}
          value={null}
          // Override built-in Autocomplete filtering, which removes synonyms/etc
          filterOptions={(ops) => ops}
          disableCloseOnSelect
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Search Ingredients"
              onChange={async (event) => {
                const ops = await fetchIngredientOptions(event.target.value)
                setOptions(ops)
              }}
            />
          )}
          renderOption={(props, option) => (
            <MenuItem {...props} key={option.value} value={option} onClick={() => handleSelectIngredient(option)}>
              <IngredientOption data={option} />
            </MenuItem>
          )}
        />
      </Stack>
      <LabelButtons labels={labels} onSelect={(label) => addByLabel(label)} />
    </>
  )
}
