import React, { FC, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Alert, Stack } from '@howgood/design'
import { selectDisplayedInventories, selectDisplayedProductWorkspaces } from '@/state/productOverview'
import { selectWorkspaces } from '@/state/workspaces'
import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium'
import { MTPerYear } from './MTPerYear'
import { Name } from './Name'
import { DRAFT, selectPageView } from '@/state/pageSettings'
import { Workspace } from '@/records'
import { InventoriesResponse } from '@/api'
import { InternalId } from './InternalID'
import { selectIsSupplierConnect } from '@/state/organization'

/**
 * A product can be in multiple workspaces, where a workspace is a collection of products with no inventory associations.
 * An inventory is associated with a single product and a single workspace.
 * So you can go from an inventory to a workspace, but not directly from a workspace to an inventory.
 */

const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Workspace',
    width: 200,
    sortable: false,
    renderCell: (params) => <Name {...params} />,
  },
  {
    field: 'internal_id',
    headerName: 'Internal ID',
    sortable: false,
    width: 200,
    renderCell: (params) => <InternalId {...params} />,
  },
  {
    field: 'mt_per_year',
    headerName: 'Metric tons/year ordered',
    width: 200,
    sortable: false,
    renderCell: (params) => <MTPerYear {...params} />,
  },
]

interface GridRow {
  id: number
  name: { inventory: InventoriesResponse; workspace: Workspace }
  internal_id: string
  mt_per_year: InventoriesResponse
}

export const Workspaces: FC = () => {
  const productWorkspaces = useSelector(selectDisplayedProductWorkspaces)
  const userWorkspaces = useSelector(selectWorkspaces)
  const displayedInventories = useSelector(selectDisplayedInventories)
  const view = useSelector(selectPageView)
  const isSupplierConnect = useSelector(selectIsSupplierConnect)

  const rows = useMemo(
    () =>
      productWorkspaces.reduce((acc, prodWorkspace) => {
        // Workspace info on the product has only a partial set of fields, so need to get the full workspace info
        const workspace = userWorkspaces.find((wkspace) => wkspace.id === prodWorkspace.id)

        // Get the inventory associated with this workspace
        const inventory = displayedInventories.find((inv) => workspace.id === inv.workspace.id)

        // There should be an inventory associated with each workspace, but check just in case
        if (inventory) {
          return [
            ...acc,
            {
              id: workspace.id,
              name: { inventory, workspace },
              internal_id: inventory,
              mt_per_year: inventory,
            },
          ]
        }
        return acc
      }, [] as GridRow[]),
    [displayedInventories, productWorkspaces, userWorkspaces]
  )

  if (rows.length === 0) {
    return (
      <Stack m={1} data-testid="workspaces-tab-panel">
        <Alert severity="warning">To add an internal ID, save the product first.</Alert>
      </Stack>
    )
  }

  return (
    <Stack data-testid="workspaces-tab-panel">
      <DataGridPremium
        rows={rows}
        columns={columns}
        autoHeight
        density={view === DRAFT ? 'standard' : 'compact'}
        hideFooter
        disableRowSelectionOnClick
        disableColumnMenu
        columnVisibilityModel={{
          mt_per_year: !isSupplierConnect,
        }}
        sx={{
          border: 'none',
          '& .MuiDataGrid-columnHeaders': { border: 'none' },
          '& .MuiDataGrid-columnHeaderTitle': { fontSize: 16, mt: view === DRAFT ? 2 : 1 },
          '& .MuiDataGrid-cell': { border: 'none' },
        }}
      />
    </Stack>
  )
}
