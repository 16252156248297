import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { DataGridPremium } from '@mui/x-data-grid-premium'
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium'
import { Stack } from '@howgood/design'
import { selectChartType, selectPortfolioData } from '../state/portfolioDashboard'
import { useGridColumns } from './useGridColumns'

/**
 * Bar chart component built using a data grid.
 */

export const dataGridStyles = {
  border: 0,
  '& .MuiDataGrid-topContainer': {
    '&:after': { display: 'none' },
  },
  '& .MuiDataGrid-columnHeaders': { border: 0 },
  '& .MuiDataGrid-row': {
    bgcolor: 'transparent',
    '&.Mui-selected': { bgcolor: 'transparent' },
    '&:hover': { bgcolor: 'transparent' },
  },
  '& .MuiDataGrid-cell': { border: 0, '&:focus': { outline: 'none' } },
  '& .MuiDataGrid-footerContainer': { minHeight: 20 },
  '& .MuiTablePagination-toolbar': { minHeight: '20px !important' },
}

interface BarChartProps {
  height: number
  gridApiRef: React.MutableRefObject<GridApiPremium>
}

export const BarChart: FC<BarChartProps> = ({ height, gridApiRef }) => {
  const data = useSelector(selectPortfolioData)
  const chartType = useSelector(selectChartType)

  const columns = useGridColumns()

  return (
    <Stack width="100%" height={height} overflow="auto" display={chartType === 'bar' ? 'flex' : 'none'}>
      <DataGridPremium
        rows={data}
        columns={columns}
        apiRef={gridApiRef}
        initialState={{ density: 'compact' }}
        columnHeaderHeight={20}
        rowHeight={42}
        hideFooter={data.length < 100}
        autoHeight
        pagination
        disableColumnFilter
        disableColumnMenu
        disableColumnReorder
        sx={dataGridStyles}
      />
    </Stack>
  )
}
