import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Button, Stack } from '@howgood/design'
import { selectCarbonAccountingCurrentReport } from '@/state/carbonAccounting'
import { CarbonAccountingContent } from '../CarbonAccountingContent'
import { ReportMenu } from '../ReportMenu'
import { CarbonAccountingExport } from '../CarbonAccountingExport'
import { faAbacus, faArrowUpFromBracket } from '@fortawesome/pro-regular-svg-icons'
import { Heading } from '@/components/Layout/Heading'
import { useLiteUserPaywallDialogForPages } from '@/components/LiteUserPaywallDialog/LiteUserPaywallDialog.hook'

export const CarbonAccountingReportPage: FC = () => {
  const report = useSelector(selectCarbonAccountingCurrentReport)
  useLiteUserPaywallDialogForPages()

  const cta = (
    <Stack direction="row" gap={1} alignItems="center">
      <CarbonAccountingExport
        reportId={report?.id}
        reportYear={report?.year}
        reportTitle={report?.title}
        scope3Total={report?.scope_3_total}
        allEmissionsTotal={report?.all_scopes_total}
      >
        {(onClick) => (
          <Button data-testid="report-export-button" startIcon={faArrowUpFromBracket} size="small" onClick={onClick}>
            Export
          </Button>
        )}
      </CarbonAccountingExport>
      <ReportMenu reportId={report?.id} showExport={false} />
    </Stack>
  )

  return (
    <>
      <Heading
        title={report?.title || ''}
        year={report?.year}
        actions={cta}
        data-testid="carbon-accounting"
        breadCrumbs={[{ url: '/carbon-accounting', title: 'Carbon Accounting', icon: faAbacus }]}
      />
      <CarbonAccountingContent reportId={report?.id} reportYear={report?.year} reportTitle={report?.title} />
    </>
  )
}
