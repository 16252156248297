import React, { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { faCog } from '@fortawesome/pro-regular-svg-icons'
import { IconButton, Menu, MenuItem, Box, Tooltip, Stack } from '@howgood/design'

import { ConfigureDialog } from './ConfigureDialog'
import { getSelectedMetricData, selectSelectedMetric, selectSelectedWorkspace } from '../state/portfolioDashboard'
import { selectDebugToolsSplit } from '@/state/splitio'

interface Props {
  showSettings: boolean
}
export const SettingsMenu: FC<Props> = ({ showSettings }) => {
  const selectedWorkspace = useSelector(selectSelectedWorkspace)
  const debugEnabled = useSelector(selectDebugToolsSplit)
  const selectedMetric = useSelector(selectSelectedMetric)
  const dispatch = useDispatch()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [configureDialogOpen, setConfigureDialogOpen] = useState(false)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      {showSettings && (
        <Stack position="absolute" top={0} right={0} p={0.5}>
          <IconButton data-testid="settings-button" icon={faCog} onClick={handleClick} />
        </Stack>
      )}
      <Menu
        id="portfolio-impact-dashboard-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {debugEnabled && (
          <Tooltip title={`Portfolio: ${selectedWorkspace.name}`} placement="top">
            <Box>
              <MenuItem
                onClick={() => {
                  setConfigureDialogOpen(true)
                  handleClose()
                }}
              >
                Configure
              </MenuItem>
            </Box>
          </Tooltip>
        )}
        <MenuItem
          onClick={() => {
            dispatch(getSelectedMetricData(selectedMetric))
            handleClose()
          }}
        >
          Refresh data
        </MenuItem>
      </Menu>
      {configureDialogOpen && <ConfigureDialog closeDialog={() => setConfigureDialogOpen(false)} />}
    </>
  )
}
