import { Goals } from '@/records'
import { ScoreType } from '../pageSettings'
import { WorkspaceScores } from '../workspaces'

export const getScenarioYearAsNumber = (date: string) => new Date(date).getFullYear()

export const getScenarioYear = (date: string) => getScenarioYearAsNumber(date).toString()

export const generateScoreLabel = (units: string, scoreType: string) =>
  !units ? 'Score' : scoreType === 'displayedSum' ? 'Total' : 'Average'

/**
 * Extracts the score values and goals for a metric based on the displayScoreType
 * Used for displaying scores and goals for scenarios and initiatives
 * Accounts for the difference between quantitative and qualitative metrics
 * using the initiative product count to convert between sum and avg scores
 *
 * @param metric as string
 * @param displayScoreType as ScoreType
 * @param scores as WorkspaceScores (ImpactScore)
 * @param goals as Goals
 * @param productCount as number (number of products in the initiative)
 * @returns score as number, goal as number, sumGoal as number, avgGoal as number, count as number
 */
export const getScoreValues = (
  metric: string,
  displayScoreType: ScoreType,
  scores: WorkspaceScores,
  goals: Goals,
  productCount: number
) => {
  if (!scores || !metric) {
    return { score: null, goal: null }
  }
  const isQuantitative = Boolean(scores?.[`${[metric]}_sum`])
  const scoreType = displayScoreType === 'displayedSum' ? 'sum' : 'avg'
  let score: number = scores?.[`${[metric]}_${scoreType}`] || scores?.[metric] || null
  if (isQuantitative && scoreType === 'avg') {
    // We want to use the product count for the base in average score calcs for initiatives
    score = scores[`${[metric]}_sum`] / productCount
  }
  if (!score && scoreType === 'sum') {
    // Qulitative metrics do not have values for sum, so we display the avg
    score = scores[`${[metric]}_avg`]
  }
  const count = isQuantitative ? productCount : 1
  const goal: number = goals[metric] || null
  const sumGoal = goal ? goal * count : null
  return { score, goal: displayScoreType === 'displayedSum' ? sumGoal : goal, sumGoal, avgGoal: goal, count }
}
