import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Text, Switch, FormControlLabel } from '@howgood/design'
import { selectScoreType, setScoreType } from '@/state/pageSettings'

export const ScoreTypeSelector: FC = () => {
  const dispatch = useDispatch()
  const scoreType = useSelector(selectScoreType)

  const handleSwitchChanged = (showSums: boolean) => {
    dispatch(setScoreType(showSums ? 'displayedSum' : 'displayedAvg'))
  }

  return (
    <FormControlLabel
      control={
        <Switch
          data-testid="score-type-toggle"
          checked={scoreType === 'displayedSum'}
          onChange={(e) => handleSwitchChanged(e.target.checked)}
        />
      }
      label={
        <Text color="primary" variant="body2">
          Show sums for annual metrics
        </Text>
      }
      labelPlacement="start"
    />
  )
}
