import React, { FC } from 'react'
import MuiGrid, { GridProps as MuiGridProps } from '@mui/material/Grid'

interface GridProps extends Omit<MuiGridProps, 'className' | 'sx'> {
  ['data-testid']?: string
}

export const Grid: FC<GridProps> = ({ ['data-testid']: dataTestId, children, ...rest }) => {
  return (
    <MuiGrid data-testid={dataTestId} {...rest}>
      {children}
    </MuiGrid>
  )
}
