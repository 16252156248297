import { formatNumber } from '@howgood/utils'
import { AllEmissionsData, FlagChartData, FlagEmissionsData, ProductCarbonFootprintData } from './types'

export function formatTick(num: number): string {
  return formatNumber(num, 1)
}

export function getChartData(data: AllEmissionsData | FlagEmissionsData | ProductCarbonFootprintData): FlagChartData {
  return Object.entries(data).reduce((acc, [key, d]) => ({ ...acc, [key]: d.score }), {} as FlagChartData)
}
