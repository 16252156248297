import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { Workspace, WorkspaceOption } from '@/records'
import { AppState } from '@/store'
import { createIWorkspaceOption } from '@/utils/workspace'
import {
  selectVendorDataIsLoading,
  selectAllowedVendorWorkspaces,
  selectVendorWorkspaces,
  setEditingVendorManagementFilters,
  updateFiltersUrlAndVendorData,
} from '@/state/vendorManagement'
import { WorkspaceFilter } from '@/components/Shared/Filters/WorkspaceFilter'

const selectWorkspacesOptionsFilteredByType = createSelector<
  AppState,
  Workspace[],
  WorkspaceOption[],
  WorkspaceOption[]
>(selectAllowedVendorWorkspaces, selectVendorWorkspaces, (workspaces, currentWorkspaceFilters) => {
  return workspaces
    .filter((ws) => !currentWorkspaceFilters.map((wsFilter) => wsFilter.id).includes(ws.id))
    .map((w: Workspace) => createIWorkspaceOption(w))
})

export const VendorWorkspaceFilter: FC = () => {
  const dispatch = useDispatch()
  const isLoading = useSelector(selectVendorDataIsLoading)
  const workspaceOptions = useSelector(selectWorkspacesOptionsFilteredByType)
  const workspaces = useSelector(selectVendorWorkspaces)
  const id = 'workspace-search-for-aggregated-vendors'

  const handleWorkspaceChange = async (updatedWorkspaces: WorkspaceOption[]) => {
    dispatch(updateFiltersUrlAndVendorData({ updatedFilters: { workspaces: updatedWorkspaces } }))
    dispatch(setEditingVendorManagementFilters(id))
  }

  return (
    <WorkspaceFilter
      options={workspaceOptions}
      value={workspaces}
      handleChange={handleWorkspaceChange}
      id={id}
      disabled={isLoading}
      fullWidth
    />
  )
}
