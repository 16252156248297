import React, { FC } from 'react'
import { Cell, Pie, PieChart, Tooltip } from 'recharts'
import { theme } from '@howgood/design'
import { formatNumber } from '@howgood/utils'

interface Data {
  key: string
  title: string
  score: number
  fill: string
}
interface Props {
  title: string
  data: Data[]
  innerRadius: number
  animate?: boolean
}

export const CarbonAccountingItemChart: FC<Props> = ({ title, data, innerRadius, animate = true }) => {
  return (
    <PieChart width={150} height={150}>
      <text
        data-testid="carbon-accounting-chart-title"
        textAnchor="middle"
        x="50%"
        y="50%"
        dy={(theme.typography.body2.fontSize as number) / 2}
        fill={theme.palette.text.secondary}
        fontFamily={theme.typography.body2.fontFamily}
        fontSize={theme.typography.body2.fontSize}
      >
        {title}
      </text>
      <Pie
        data={data}
        dataKey="score"
        innerRadius={innerRadius}
        outerRadius={75}
        fontSize={13}
        isAnimationActive={animate}
      >
        {data.map((d) => (
          <Cell key={d.key} fill={d.fill} />
        ))}
      </Pie>
      {/* Do not render tooltip in empty state */}
      {data.length > 1 && (
        <Tooltip formatter={(value: number, _name, { payload }) => [formatNumber(value), payload.title]} />
      )}
    </PieChart>
  )
}
