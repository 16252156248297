import React, { FC, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ColumnSelect } from '@/components/ColumnSelect/ColumnSelect'
import { selectPageType } from '@/state/router'
import { ProductColFields, fieldColMap } from './utils/productListColDefs'
import { CheckboxOption } from '../Shared/CheckboxesDropdown/CheckboxesDropdown'
import { selectProductListSelectedColumns, updateProductListSelectedColumns } from '@/state/user'
import { Field } from '@/constants/impactScore'
import { ProductsGridColDef } from '../GridCellRenderers'
import { selectProductsSortOptions } from '@/state/products'

export const ProductColumnSelect: FC<{ columns: ProductsGridColDef[] }> = ({ columns }) => {
  const dispatch = useDispatch()
  const productListType = useSelector(selectPageType)
  const selected = useSelector(selectProductListSelectedColumns)
  const sortOptions = useSelector(selectProductsSortOptions)

  const columnOptions: CheckboxOption[] = useMemo(() => {
    return columns.map((col) => {
      const isSortField = Boolean(sortOptions?.length && col.sortfield === Object.keys(sortOptions[0])[0])
      return {
        key: col.field as string,
        label: col.headerName as string,
        checked: Boolean(col.field === 'name' || selected.includes(col.field as string) || isSortField),
        disabled: Boolean(col.field === 'name' || isSortField),
        colGroup: fieldColMap[col.field][productListType].colGroup,
      }
    })
  }, [columns, productListType, selected, sortOptions])

  return (
    <ColumnSelect
      dataPendoId="ProductColumnOptions"
      dataTestId="product-column-select"
      selected={selected}
      options={columnOptions}
      onChange={(updated) => dispatch(updateProductListSelectedColumns(updated as (Field | ProductColFields)[]))}
    />
  )
}
