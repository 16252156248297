import React, { FC, useMemo, useState } from 'react'
import { useGridApiRef } from '@mui/x-data-grid-premium'
import { Accordion, Button, FormControlLabel, Stack, Switch } from '@howgood/design'
import { useToggle } from '@/hooks/useToggle'
import { QuickFilter } from '@/components/Shared/QuickFilter'
import { Export } from '../Export'
import { IngredientImpact, IngredientTableRow } from '../types'
import { ColumnsFilter } from './ColumnsFilter'
import { useTableColumns } from './ComparisonResults.hooks'
import { getColumnVisibilityModel, getSelectedRows } from './ComparisonResults.utils'
import { GroupByDropdown } from './GroupByDropdown'
import {
  IngredientComparisonResultTable,
  defaultColumnVisibilityModel,
  defaultRowGroupingModel,
} from './IngredientComparisonResultTable'

interface ComparisonResultsProps {
  ingredients: IngredientImpact[]
}

export const ComparisonResults: FC<ComparisonResultsProps> = ({ ingredients = [] }) => {
  const gridApiRef = useGridApiRef()
  const gridApiReady = Boolean(gridApiRef.current.instanceId)

  const data: IngredientTableRow[] = useMemo(
    () =>
      ingredients.map((ingredient) => {
        const standardsKey = ingredient.standards.map((s) => s.value).join('-')
        const id = `${ingredient.baseId}--${ingredient.location.value}--${standardsKey}`
        return {
          id,
          ingredient: ingredient.name,
          location: ingredient.location.label,
          standard: ingredient.standards.map((s) => s.label).join(', '),
          ...ingredient.impactScore,
          impactScore: ingredient.impactScore,
        }
      }),
    [ingredients]
  )

  const [isFiltered, setFiltered] = useState(false)
  const [showAverages, toggleAverages] = useToggle(true)
  const columns = useTableColumns(showAverages)

  function compareSelected() {
    if (gridApiRef.current) {
      // only show selected rows
      const selectedRows = getSelectedRows(gridApiRef.current)
      gridApiRef.current.setRows(selectedRows)
      // ungroup (expand) selected rows, remove group column
      gridApiRef.current.setRowGroupingModel([])
      // show ingredient, standard, and location columns
      const columnVisibilityModel = getColumnVisibilityModel(gridApiRef.current)
      const defaultColumnsModel = Object.keys(defaultColumnVisibilityModel).reduce(
        (acc, field) => ({ ...acc, [field]: true }),
        {}
      )
      gridApiRef.current.setColumnVisibilityModel({ ...columnVisibilityModel, ...defaultColumnsModel })
      // show return to full list button
      setFiltered(true)
    }
  }

  function resetSelection() {
    if (gridApiRef.current) {
      // restore original row data
      gridApiRef.current.setRows(data)
      // deselect rows
      gridApiRef.current.setRowSelectionModel([])
      // reset row grouping
      gridApiRef.current.setRowGroupingModel(defaultRowGroupingModel)
      // hide ingredient, standard, and location columns
      const columnVisibilityModel = getColumnVisibilityModel(gridApiRef.current)
      gridApiRef.current.setColumnVisibilityModel({ ...columnVisibilityModel, ...defaultColumnVisibilityModel })
      // hide return to full list button
      setFiltered(false)
    }
  }

  return (
    <Stack gap={2}>
      {gridApiReady && (
        <Stack flexDirection="row" alignItems="center" gap={1}>
          <QuickFilter gridApi={gridApiRef.current} />
          {isFiltered && <Button onClick={resetSelection}>Return to full list</Button>}
          <Button onClick={compareSelected}>Compare selected</Button>
          <ColumnsFilter columns={columns} gridApi={gridApiRef.current} />
          <Export data={data} />
          <GroupByDropdown gridApi={gridApiRef.current} groupByNoneDisabled={ingredients.length > 2500} />
          <FormControlLabel
            control={<Switch checked={showAverages} onChange={toggleAverages} />}
            label="Show averages"
          />
        </Stack>
      )}
      <Accordion>
        <IngredientComparisonResultTable gridApiRef={gridApiRef} data={data} columns={columns} />
      </Accordion>
    </Stack>
  )
}
