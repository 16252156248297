import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { ContentfulAttributeKey } from '@/contentfully'
import { useContentfulFormulaIcons } from '@/contentfully/hooks'
import { selectSavedProductName } from '@/state/productOverview'
import {
  selectContentfulImpactHistoryAttributesData,
  selectContentfulImpactHistoryData,
  selectContentfulMetricByKey,
} from '@/state/contentfulData'
import { ImpactHistory, selectImpactHistory } from '@/state/impactHistory'
import { getAttributeValue } from './ImpactHistoryExport.utils'
import { selectVendorName } from '@/state/vendor'
import { selectIsVendors } from '@/state/router'

export function useSheet1Data() {
  const productName = useSelector(selectSavedProductName)
  const vendorName = useSelector(selectVendorName)
  const isVendorsPage = useSelector(selectIsVendors)

  return useMemo(
    () =>
      isVendorsPage
        ? [
            ['Vendor name', vendorName],
            ['Vendor URL', window.location.href],
            ['Report generated', new Date().toLocaleDateString()],
            ['Report description', "This report contains the impact history of this vendor's products."],
          ]
        : [
            ['Product name', productName],
            ['Product URL', window.location.href],
            ['Report generated', new Date().toLocaleDateString()],
            [
              'Report description',
              "This report contains this product's version history in Latis. Anytime changes or updates are made to this product (via the user or via Latis scheduled updates), a record of key product metrics and attributes is recorded and can be viewed here.",
            ],
          ],
    [isVendorsPage, productName, vendorName]
  )
}

export function useSheet2Data(): (string | number)[][] {
  const ratings = useContentfulFormulaIcons(['hg-rating-snm', 'hg-rating-good', 'hg-rating-great', 'hg-rating-best'])
  const ratingsMap = useMemo(() => ratings.reduce((acc, rating, i) => ({ ...acc, [i]: rating.label }), {}), [ratings])
  const impactHistory = useSelector(selectImpactHistory)
  const metricData = useSelector(selectContentfulImpactHistoryData)
  const attributeData = useSelector(selectContentfulImpactHistoryAttributesData)
  const retailRating = useSelector(selectContentfulMetricByKey('retail_rating_impact'))

  const headerRow = [
    'Metric / attribute',
    ...metricData.map((metric) => (metric.title === 'Carbon Footprint' ? metric.subtitle : metric.title)),
    retailRating?.title,
    ...attributeData.map((attribute) => attribute.title),
  ]

  /**
   * Filtered down list of impact history, only available metrics to this module
   *
   * {
   *   processing_impact: { [date1]: 10, [date2]: 8, ... },
   *   animal_welfare_impact: { [date1]: 5, [date2]: 6, ... },
   *   is_climate_friendly: { [date1]: false, [date2]: true, ... },
   * }
   */
  const historyByMetric: ImpactHistory = useMemo(
    () =>
      [...metricData, retailRating, ...attributeData].reduce((acc, metric) => {
        const history = impactHistory?.[metric?.key]
        if (history) {
          return { ...acc, [metric?.key]: impactHistory?.[metric?.key] }
        }
        return acc
      }, {}),
    [attributeData, metricData, impactHistory, retailRating]
  )

  // List of all dates, arbitrarily taken from the howgood_one_metric. E.g., [date1, date2, date3]
  const dates = useMemo(
    () => (historyByMetric?.howgood_one_metric ? Object.keys(historyByMetric?.howgood_one_metric) : []),
    [historyByMetric?.howgood_one_metric]
  )

  const rows = dates.map((date) => {
    const dateFormatted = new Date(date).toLocaleDateString()
    const metricValues = Object.entries(historyByMetric).map(([key, h]) => {
      const attributes = attributeData.map((attribute) => attribute.key)
      const metric = metricData.find((m) => m.key === key)
      const isAttribute = attributes.includes(key as ContentfulAttributeKey)
      const isRating = key === 'retail_rating_impact'
      const rawValue = h[date]
      const formattedValue = metric?.valuesMap?.[rawValue] || rawValue
      const value = isRating ? ratingsMap[rawValue] : isAttribute ? getAttributeValue(rawValue) : formattedValue
      return value
    })
    return [dateFormatted, ...metricValues]
  })

  return [headerRow, ...rows]
}
