import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { faFileCsv, faFileExcel } from '@fortawesome/pro-solid-svg-icons'
import { faStore } from '@fortawesome/pro-regular-svg-icons'
import { Button, Stack } from '@howgood/design'
import { Heading } from '@/components/Layout/Heading'
import { RequestVendorData } from '@/components/Procurement/RequestVendorData'
import { selectVendorIsLoading, selectVendorName, selectVendorReports } from '@/state/vendor'
import { ExportDialog } from '@/components/Products/MUIExport/ExportDialog'
import { ExportType } from '@/components/Products/MUIExport/ExportDialog.types'
import { VendorReportsButton } from './VendorReportsButton'

export const VendorDetailHeader: FC = () => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [exportType, setExportType] = useState<ExportType>()
  const vendorName = useSelector(selectVendorName)
  const vendorIsLoading = useSelector(selectVendorIsLoading)
  const vendorReports = useSelector(selectVendorReports)
  const showVendorReportsButton = !vendorIsLoading && vendorReports.length > 0

  function onClick(type: ExportType) {
    setExportType(type)
    setDialogOpen(true)
  }

  return (
    <>
      <ExportDialog open={dialogOpen} exportType={exportType} onClose={() => setDialogOpen(false)} />
      <Heading
        data-testid="vendor-detail-header"
        title={vendorName || 'Vendor Detail'}
        breadCrumbs={[{ title: 'Vendors', icon: faStore, url: '/vendors' }]}
        actions={
          <Stack direction="row" alignItems="center" gap={1}>
            <RequestVendorData />
            <Button
              data-testid="vendor-detail-excel"
              startIcon={faFileExcel}
              size="small"
              onClick={() => onClick('download-excel')}
            >
              Download Excel
            </Button>
            <Button
              data-testid="vendor-detail-csv"
              startIcon={faFileCsv}
              size="small"
              onClick={() => onClick('download-csv')}
            >
              Download CSV
            </Button>
            {showVendorReportsButton && <VendorReportsButton />}
          </Stack>
        }
      />
    </>
  )
}
