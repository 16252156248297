import React, { FC, useState } from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import {
  Box,
  IconButton,
  Text,
  Tooltip,
  Stack,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  MenuItem,
  Icon,
  theme,
} from '@howgood/design'
import { faCheck, faPen, faWandMagicSparkles } from '@fortawesome/pro-regular-svg-icons'
import {
  redistributeAllWeights,
  selectDisplayedIngredientList,
  selectTotalWeight,
  setWeightFieldsToManual,
  selectIsFormulaOveriewLoading,
  updateRecipeAndScores,
  selectDisplayedWeightOption,
} from '@/state/recipe'
import { selectPageView, DRAFT } from '@/state/pageSettings'
import { GRAMS, PERCENTAGES, WeightOption } from '@/records'
import { selectInputWeightUnitsSplit } from '@/state/splitio'

export const WeightOptions = {
  [PERCENTAGES]: '%',
  [GRAMS]: 'g',
}

export const WeightHeader: FC = () => {
  const dispatch = useDispatch()
  const isLoading = useSelector(selectIsFormulaOveriewLoading)
  const ingredients = useSelector(selectDisplayedIngredientList)
  const totalWeight = useSelector(selectTotalWeight)
  const weightOption = useSelector(selectDisplayedWeightOption)
  const pageView = useSelector(selectPageView)
  const weightUnitsEnabled = useSelector(selectInputWeightUnitsSplit)

  const [redistributeDialogOpen, setRedistributeDialogOpen] = useState(false)
  const [weightOptionDialogOpen, setWeightOptionDialogOpen] = useState(false)
  const [newWeightOption, setNewWeightOption] = useState<WeightOption>(null)

  const displayWand = weightOption === PERCENTAGES

  const allFieldsManual = ingredients.length > 0 && ingredients.every((ingredient) => !ingredient.isAutoWeight)
  const allFieldsAuto = ingredients.length > 0 && ingredients.every((ingredient) => ingredient.isAutoWeight)

  const handleRedistributeWeights = () => {
    setRedistributeDialogOpen(false)
    dispatch(redistributeAllWeights())
  }

  const handleSetWeightsToManual = () => {
    dispatch(setWeightFieldsToManual())
  }

  const handleWeightOptionConfirmed = () => {
    setWeightOptionDialogOpen(false)
    batch(() => {
      dispatch(
        updateRecipeAndScores({
          recipeUpdates: { input_weight_unit: newWeightOption },
          change: 'Input weight unit updated',
        })
      )
      if (newWeightOption !== PERCENTAGES) {
        dispatch(setWeightFieldsToManual())
      }
    })
  }

  const handleWeightOptionChanged = (option: WeightOption) => {
    setNewWeightOption(option)
    setWeightOptionDialogOpen(true)
  }

  return (
    <>
      <Stack direction="row" alignItems="center" width="100%">
        <Stack flexGrow={1} alignItems="center">
          <Box flexGrow={1}>
            <Text>Weight</Text>
          </Box>
          <Stack
            direction="row"
            display={totalWeight ? 'flex' : 'none'}
            fontSize={12}
            color={weightOption === PERCENTAGES && totalWeight !== 100 ? 'error.main' : null}
          >
            <Text fontSize="inherit" color="inherit">
              Total: {totalWeight}
            </Text>
            {weightUnitsEnabled && pageView === DRAFT && (
              <Select
                id="weight-option-select"
                aria-label="Select weight option"
                size="small"
                variant="standard"
                disableUnderline
                value={weightOption}
                renderValue={() => WeightOptions[weightOption]}
                onChange={(e) => handleWeightOptionChanged(e.target.value as WeightOption)}
                sx={{
                  fontSize: 'inherit',
                  color: 'inherit',
                  '&:hover': { borderBottomColor: 'transparent' },
                  '& .MuiSelect-select': { py: 0, bgcolor: 'transparent !important' },
                  '& .MuiInput-input': { pr: '16px !important' },
                  '& .MuiTypography-root': { fontSize: 'inherit', color: 'inherit' },
                }}
              >
                {Object.keys(WeightOptions).map((option) => {
                  return (
                    <MenuItem key={option} value={option} sx={{ px: 1, py: 0.25 }}>
                      <Stack direction="row" alignItems="center" gap={1}>
                        <Icon
                          icon={faCheck}
                          size="sm"
                          color={option === weightOption ? theme.palette.primary.main : 'transparent'}
                        />
                        <Text>{option}</Text>
                      </Stack>
                    </MenuItem>
                  )
                })}
              </Select>
            )}
            {(!weightUnitsEnabled || pageView !== DRAFT) && (
              <Text fontSize="inherit" color="inherit">
                {WeightOptions[weightOption]}
              </Text>
            )}
          </Stack>
        </Stack>
        {pageView === DRAFT && allFieldsManual && !isLoading && displayWand && (
          <Tooltip
            title={
              <Text>Set all ingredient weight fields to auto and redistribute weights according to their position</Text>
            }
          >
            <IconButton
              id="redistribute-weights-button"
              aria-label="Redistribute ingredient weights"
              icon={faWandMagicSparkles}
              onClick={() => setRedistributeDialogOpen(true)}
            />
          </Tooltip>
        )}
        {pageView === DRAFT && allFieldsAuto && !isLoading && displayWand && (
          <Tooltip title={<Text>Set all ingredient weight fields to manual</Text>}>
            <IconButton
              id="manual-weights-button"
              aria-label="Set weights to manual"
              size="small"
              icon={faPen}
              onClick={handleSetWeightsToManual}
            />
          </Tooltip>
        )}
      </Stack>
      <Dialog
        data-testid="redistribute-weights-dialog"
        open={redistributeDialogOpen}
        onClose={() => setRedistributeDialogOpen(false)}
      >
        <DialogTitle>Redistribute weights</DialogTitle>
        <DialogContent>
          <Text>
            This will set all ingredient weight fields to &quot;auto&quot; and redistribute the weights according to
            their position. This will override all existing weight values. Are you sure you want to do this?
          </Text>
        </DialogContent>
        <DialogActions>
          <Button
            id="redistribute-weights-cancel-button"
            color="secondary"
            variant="contained"
            onClick={() => setRedistributeDialogOpen(false)}
          >
            Cancel
          </Button>
          <Button
            id="redistribute-weights-ok-button"
            color="primary"
            variant="contained"
            onClick={handleRedistributeWeights}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        data-testid="weight-option-dialog"
        open={weightOptionDialogOpen}
        onClose={() => setWeightOptionDialogOpen(false)}
      >
        <DialogTitle>Change input weight type for this product?</DialogTitle>
        <DialogContent>
          <Text>{`You're changing the input weight type for this formula from ${weightOption} to ${newWeightOption}.`}</Text>
          <Text>
            Please confirm all ingredient weights are specified using the correct unit for accurate impact metrics.
          </Text>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" variant="contained" onClick={() => setWeightOptionDialogOpen(false)}>
            Cancel
          </Button>
          <Button color="primary" variant="contained" onClick={handleWeightOptionConfirmed}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
