import { SCOPE_3_CATEGORY_1_FIELD, SCOPE_3_CATEGORY_4_FIELD } from '@/constants/impactScore'
import { MappingRuntimeFieldType } from '@elastic/elasticsearch/api/types'

/**
 * Elasticsearch runtime mappings required by the Procurement page
 * IMPORTANT: The metric names must exist in the ES index or the request will fail
 * with no information to help with debugging
 * Also, the scripts use locked impact data when it exists, or unlocked data if it doesn't
 */

// Scope 3 FLAG includes just one metric
export const scope3FlagMetric = 'cf_flag_impact'

// Scope 3 category 1 includes just one metric
export const scope3Cat1Metric = 'cf_ftm_gate_ct_verified_impact'

// Scope 3 category 4 includes just one metric
export const scope3Cat4Metric = 'cf_mtr_transportation_impact'

// Script string used in multiple mappings
export const calculateMtPerYear = (workspaceIds: number[]) => `
  double mt_per_year = 0;
  int count = 0;
  for (item in doc['inventories.workspace_mt_per_year']) {
    def split = item.splitOnToken(";");
    if (split.length > 1) {
      int workspace_id = Integer.parseInt(split[0]);
      double workspace_mt_per_year= Double.parseDouble(split[1]);
      if([${workspaceIds.join(', ')}].contains(workspace_id)) {
        mt_per_year += workspace_mt_per_year;
        count += 1;
      }
    }
  }
`

// Only emit if there is at least one non-null value (i.e. don't emit 0 unless it really is 0)
const inventoryMtPerYrScript = (workspaceIds: number[]) => `
  if(doc.containsKey('inventories.workspace_mt_per_year') && doc['inventories.workspace_mt_per_year'].size() != 0) {
    ${calculateMtPerYear(workspaceIds)}
    if (count > 0) {
      emit(mt_per_year);
    }
  }
`

const generateMtPerYearScript = (workspaceIds: number[], scenario: number = null) => {
  if (scenario) {
    return `
      if(params._source.containsKey('scenarios') && params._source.scenarios.size() != 0) {
        for (item in params._source.scenarios) {
          if (item.id == ${scenario} && item.containsKey('product') && item.product != null && item.product.containsKey('mt_per_year') && item.product.mt_per_year != null) {
              emit(item.product.mt_per_year);
              return;
          }
        }
      }
      ${inventoryMtPerYrScript(workspaceIds)}
    `
  }
  return inventoryMtPerYrScript(workspaceIds)
}

export const generateIsVerified = (thirdPartyWorkspaces: number[]) => ({
  type: 'boolean' as MappingRuntimeFieldType,
  script: `
  if(params._source.containsKey('workspaces') && params._source.workspaces.size() != 0) {
    for (item in params._source.workspaces) {
      if([${thirdPartyWorkspaces.join(', ')}].contains(item.id)) {
          emit(true);
          return;
      }
    }
  }
  if(params._source.containsKey('workflow_tags') && params._source.workflow_tags.size() != 0) {
    for (item in params._source.workflow_tags) {
      if(item == 'vendor_verified' || item == 'agpractice' || item == 'keypractices') {
          emit(true);
          return;
      }    
    }
  }
  emit(false)
`,
})

// Compute total mt_per_year for each product by summing the values on each product inventory
export const generateMtPerYear = (workspaceIds: number[], scenario: number = null) => ({
  type: 'double' as MappingRuntimeFieldType,
  script: generateMtPerYearScript(workspaceIds, scenario),
})

// Compute total kg_per_year for each product by multiplying annual_sales_volume and weight_kg
// Only emit if the weight_kg is greater than 0 (having annual_sales_volume of 0 is valid)
export const kgPerYear = {
  type: 'double' as MappingRuntimeFieldType,
  script: `
  if(doc['annual_sales_volume'].size() != 0 && doc['weight_kg'].size() != 0) {
    if (doc['weight_kg'].value > 0) {
      double value = doc['annual_sales_volume'].value * doc['weight_kg'].value;
      emit(value)
    }
  }
`,
}

// Script that multiplies each product's inventory mt_per_year by its per kg impact score
// Uses the locked impact score if it exists, otherwise uses the unlocked score
const generateAnnualInventoryEmissionsScript = (field: string) => {
  return `
    if(doc['mt_per_year'].size() != 0 && doc['mt_per_year'].value > 0) {
      if(doc['locked_claims.impact_score.${field}'].size() != 0) {
        emit(doc['mt_per_year'].value * doc['locked_claims.impact_score.${field}'].value);
      }
      else if(doc['impact_score.${field}'].size() != 0) {
        emit(doc['mt_per_year'].value * doc['impact_score.${field}'].value);
      }
    }
  `
}

// Compute annual FLAG category 4 emissions for each product using the summed metrics and mt_per_year
export const scope3FlagAnnual = {
  type: 'double' as MappingRuntimeFieldType,
  script: generateAnnualInventoryEmissionsScript(scope3FlagMetric),
}

// Compute annual scope 3 category 1 emissions for each product using the summed metrics and mt_per_year
export const scope3Category1Annual = {
  type: 'double' as MappingRuntimeFieldType,
  script: generateAnnualInventoryEmissionsScript(scope3Cat1Metric),
}

// Compute annual scope 3 category 4 emissions for each product using the summed metrics and mt_per_year
export const scope3Category4Annual = {
  type: 'double' as MappingRuntimeFieldType,
  script: generateAnnualInventoryEmissionsScript(scope3Cat4Metric),
}

// Compute total scope 3 emissions for each product using the summed metrics and mt_per_year
export const scope3TotalAnnual = {
  type: 'double' as MappingRuntimeFieldType,
  script: `
  if(doc['${SCOPE_3_CATEGORY_1_FIELD}'].size() != 0 && doc['${SCOPE_3_CATEGORY_4_FIELD}'].size() != 0) {
    emit(doc['${SCOPE_3_CATEGORY_1_FIELD}'].value + doc['${SCOPE_3_CATEGORY_4_FIELD}'].value);
  }
  `,
}

// Compute total annual emissions using metric and mt_per_year for the given workspaces
export const generateTotalEmission = (workspaceIds: number[], metric: string) => ({
  type: 'double' as MappingRuntimeFieldType,
  script: `
    if(doc.containsKey('impact_score.${metric}') && doc['impact_score.${metric}'].size() != 0) {
      ${calculateMtPerYear(workspaceIds)}
      if (mt_per_year > 0) {
        emit(mt_per_year * doc['impact_score.${metric}'].value);
      }
    }
  `,
})

// Compute annual inventory emissions using metric's per kg impact score and runtime `mt_per_year` field
// The ES request's mappings must include the `generateMtPerYear` script to create the required doc['mt_per_year'] field
export const generateAnnualInventoryEmissions = (metric: string) => ({
  type: 'double' as MappingRuntimeFieldType,
  script: generateAnnualInventoryEmissionsScript(metric),
})

/**
 * Each product has a list of vendors associated with it, e.g:
 * "vendors" : [
 *   {
 *     "organization" : {
 *       "id" : 85
 *     },
 *     "id": 1870,
 *     "priority" : 1000
 *   }
 * ]
 * For the user's organization, get the vendor priority for each product
 * This gets distilled down to a single priority for each vendor in the aggregations
 */
export const getVendorPriority = (orgId: number) => ({
  type: 'double' as MappingRuntimeFieldType,
  script: `
    if(params._source.containsKey('vendors') && params._source.vendors.size() != 0) {
      for (item in params._source.vendors) {
        if(item.organization.id == ${orgId} && item.containsKey('priority')) {
            emit(item.priority);
        }    
      }
    }  `,
})
