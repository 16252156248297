import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createUseStyles } from 'react-jss'
import { Grid, MetricTile, Stack, Tooltip } from '@howgood/design'

import { selectDisplayedImpactData } from '@/state/recipe'
import { CardType, selectFavoriteCards, setFavoriteCards } from '@/state/preferences'
import { selectAttributePermissions } from '@/state/user'
import { PERMISSIONS_TO_FIELDS_MAP, PRODUCT_ATTRIBUTE_PERMISSIONS } from '@/constants/impactScore'
import { RichText } from '@/contentfully'
import { useContentfulAttributes, useContentfulFormulaIcons, useContentfulMetric } from '@/contentfully/hooks'
import { selectIsRollup } from '@/state/router'

const useStyles = createUseStyles({
  img: {
    height: '72px',
    width: '72px',
  },
})

const attributeFields = PRODUCT_ATTRIBUTE_PERMISSIONS.map((permission) => PERMISSIONS_TO_FIELDS_MAP[permission])

export const ClaimsCard: FC<{ isFavorites?: boolean }> = ({ isFavorites }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const favorites = useSelector(selectFavoriteCards)
  const permissions = useSelector(selectAttributePermissions)
  const impactData = useSelector(selectDisplayedImpactData)

  const attributes = useContentfulAttributes(attributeFields)
  const retailImages = useContentfulFormulaIcons([
    'hg-rating-snm',
    'hg-rating-good',
    'hg-rating-great',
    'hg-rating-best',
  ])
  const isRollup = useSelector(selectIsRollup)

  const cards = permissions.reduce((acc, permission) => {
    const claim = attributes.find((attribute) => attribute.key === PERMISSIONS_TO_FIELDS_MAP[permission])
    return claim
      ? [
          ...acc,
          {
            ...claim,
            value: impactData.product[claim.key],
            icon: claim?.icon?.fields?.file?.url as string,
          },
        ]
      : acc
  }, [])

  const handleFavoriteIconClicked = () => {
    const key: CardType = 'claims'
    const newFavorites = favorites.includes(key)
      ? favorites.filter((favorite) => favorite !== key)
      : [...favorites, key]
    dispatch(setFavoriteCards(newFavorites))
  }

  const retailRatingCard = useContentfulMetric('retail_rating_impact')
  const retailImage = retailImages[impactData.product.retail_rating_impact]?.icon?.fields?.file?.url as string

  if (!cards.length && !impactData.product && typeof impactData.product.retail_rating_impact === 'number') {
    return null
  }

  let cardCount = cards.length
  if (impactData.product && typeof impactData.product.retail_rating_impact === 'number') {
    cardCount++
  }

  if (isRollup) {
    return null
  }

  return (
    <Grid
      item
      xs={12}
      sm={cardCount > 1 ? 12 : 6}
      md={cardCount > 4 ? 12 : cardCount > 2 ? 9 : 4}
      lg={cardCount > 6 ? 9 : cardCount > 2 ? 6 : 3}
      xl={cardCount > 5 ? 6 : cardCount > 2 ? 4 : 2}
    >
      <MetricTile
        category={isFavorites ? 'Overview' : undefined}
        favorite={favorites.includes('claims')}
        handleFavorite={handleFavoriteIconClicked}
        title={retailRatingCard?.title}
        titleTooltip={({ children }) => <>{children}</>}
        size="lg"
        scoreOverride={
          <Stack direction="row" spacing={0.5}>
            {retailRatingCard && impactData.product && typeof impactData.product.retail_rating_impact === 'number' && (
              <Tooltip
                key={retailRatingCard.key}
                title={
                  <Stack>
                    <RichText document={retailRatingCard.longDescription} />
                  </Stack>
                }
              >
                <img src={retailImage} alt={retailRatingCard.title} className={classes.img} />
              </Tooltip>
            )}
            {cards.map((card) => (
              <Tooltip
                key={card.key}
                title={
                  <Stack>
                    <RichText document={card.longDescription} />
                  </Stack>
                }
              >
                <img
                  src={card.icon}
                  alt={card.title}
                  className={classes.img}
                  style={{ opacity: card.value ? 1 : 0.3 }}
                />
              </Tooltip>
            ))}
          </Stack>
        }
      />
    </Grid>
  )
}
