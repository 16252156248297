import React, { FC, ReactNode, forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchOriginLocations } from '@/state/originLocations/originLocations.requests'
import { setLoadingOverlay } from '@/state/pageSettings'
import { IngredientsSelector } from './IngredientsSelector'
import { ComparisonResults } from './ComparisonResults'
import { IngredientImpact, IngredientImpactV2, IngredientWithStandardsAndLocations } from './types'
import { Accordion, AccordionDetails } from '@howgood/design'
import { AccordionCardHeader } from '../Scores/AccordionCardHeader'
import { ImpactScoreResponse, fetchHelperV2 } from '@/api'
import { selectProductCertificationStandards } from '@/state/productStandards'
import { useLiteUserPaywallDialogForPages } from '../LiteUserPaywallDialog/LiteUserPaywallDialog.hook'

interface Ref {
  setExpanded: (expanded: boolean) => void
}

const IngredientPlanningHandle = forwardRef<Ref, { children: ReactNode }>(({ children }, ref) => {
  const [expanded, setExpanded] = useState<boolean>(true)
  useImperativeHandle(ref, () => ({ setExpanded }))

  return (
    <Accordion ref={ref as any} expanded={expanded} onChange={() => setExpanded(!expanded)}>
      <AccordionCardHeader title="Select ingredients to compare" />
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  )
})

IngredientPlanningHandle.displayName = 'IngredientPlanningHandle'

// Turn the V2 ingredient/impact-score response into a V1.1 response
// TODO: Currently handling before and after Tobi's 3-27-24 change
const convertResponse = (value: IngredientWithStandardsAndLocations, response: IngredientImpactV2[]) => {
  const v11Response = response
    // Filter out the combined standards item; this is used in the formula profile source selection UI, but not here
    .filter((item) => item.standards.length <= 1)
    .map((item) => {
      const standard = item.standards[0] // Get the standard from the `standards` array
      return {
        name: value.name,
        baseId: item.base.id.toString(),
        impactScore: item.impact_score as ImpactScoreResponse,
        location: {
          value: item.origin_location.id,
          label: item.origin_location.name,
        },
        standards: [
          {
            value: standard?.id || 0,
            label: standard?.title || 'None',
          },
        ],
      } as IngredientImpact
    })
  return v11Response
}

export const IngredientPlanning: FC = () => {
  const dispatch = useDispatch()
  const [ingredients, setIngredients] = useState<IngredientImpact[]>([])
  const accordionRef = useRef<Ref>()
  const productStandards = useSelector(selectProductCertificationStandards)
  useLiteUserPaywallDialogForPages()

  /**
   * We need to store 3 refs, for better control over rendering
   * when adding multiple ingredients at once, we need to run separate API requests, but we want to
   * trigger only single rerender to the ag grid
   *
   * We also want to split loading of impact scores into multiple chunks,
   * so the single request doesn't contain hundreds of ingredients
   *
   *  - batch - stores set of "to be loaded" ids
   *  - impactScoresBuffer - stores already loaded impact scores waiting to be flushed after whole batch is processed
   *  - loadedIngredientsIds - list of the ids that are already loaded in the ag grid, serves to skip i
   *      ngredients from the batch load that are already loaded
   *
   */
  const batch = useRef<Set<string>>(new Set())
  const impactScoresBuffer = useRef<IngredientImpact[]>([])
  const loadedIngredientsIds = useRef<string[]>([])

  const handleAddIngredient = useCallback(
    async (value: IngredientWithStandardsAndLocations) => {
      if (loadedIngredientsIds.current.includes(value.id)) {
        batch.current.delete(value.id)
        return
      }

      loadedIngredientsIds.current.push(value.id)

      try {
        const response = await fetchHelperV2<IngredientImpactV2[]>({
          url: `ingredients/bases/${value.baseId}/impact-score/`,
        })
        // Turn the V2 response into a V1.1 response
        const result = convertResponse(value, response)
        const originLocations = await fetchOriginLocations([value.baseId])
        const filteredResults = result.filter(
          (r) => originLocations.findIndex((ol) => ol.origin_location_id === r.location.value) !== -1
        )
        // buffers result, in case multiple ingredients are loaded at once - we want to populate ag-grid only once
        const curr = impactScoresBuffer.current
        impactScoresBuffer.current = [...curr, ...filteredResults]
      } catch (e) {
        // show console error
        console.error('Unable to load impact score')
      } finally {
        // mark ingredient as processed (remove it from the batch)
        batch.current.delete(value.id)
      }

      // when whole batch is processed write buffer
      if (!batch.current?.size) {
        // force re-render of the ag-grid only after whole batch has been processed
        setIngredients((current) => [...current, ...impactScoresBuffer.current])
        // empty buffer
        impactScoresBuffer.current = []
        dispatch(setLoadingOverlay(''))
      }
    },
    [dispatch]
  )

  // updates ingredient that is already loaded (applies standard and location filters)
  const handleUpdateIngredient = useCallback(
    async (value: IngredientWithStandardsAndLocations) => {
      const baseId = value.baseId.toString()
      batch.current.delete(value.id)
      try {
        const standardsList = value.standards.reduce((acc, standard) => {
          // The standards select component returns all standards when nothing is selected,
          // so include only standards that are explicitly selected (the backend defaults to "All" if there's no query param)
          if (!standard.explicitlySelected) return acc

          // Map the standard's value to its identifier (e.g. 16 => REGEN_ORGANIC)
          // The list returned by the API doesn't include "NONE", so add it to the list
          const productStandard = [{ id: 0, identifier: 'NONE' }, ...productStandards].find(
            (prodStd) => prodStd.id === standard.value
          )
          return productStandard ? [...acc, productStandard.identifier] : acc
        }, [])

        const standardsParam = standardsList.length ? `standard=${standardsList.join('&standard=')}` : ''

        const locationsList = value.locations.map((location) => location.value)
        const locationsParam = locationsList.length
          ? `origin_location_id=${locationsList.join('&origin_location_id=')}`
          : ''

        const queryParams = [standardsParam, locationsParam].filter((param) => param).join('&')

        const response = await fetchHelperV2<IngredientImpactV2[]>({
          url: `ingredients/bases/${value.baseId}/impact-score/${queryParams ? `?${queryParams}` : ''}`,
        })

        // Turn the V2 response into a V1.1 response
        const result = convertResponse(value, response)
        setIngredients((current) => {
          const idx = current.findIndex((item) => item.baseId.toString() === baseId)
          const newArr = current.filter((item) => item.baseId.toString() !== value.baseId.toString())
          newArr.splice(idx, 0, ...result)

          return newArr
        })
      } catch (e) {
        // show console error
        console.error('Unable to load impact score')
      }
    },
    [productStandards]
  )

  const handleRemoveIngredient = useCallback((value: IngredientWithStandardsAndLocations) => {
    loadedIngredientsIds.current = loadedIngredientsIds.current.filter((id) => id !== value.id)
    setIngredients((ingredients) =>
      ingredients.filter((ingredient) => ingredient.baseId.toString() !== value.baseId.toString())
    )
  }, [])

  const handleAddAll = (ids: string[]) => {
    const batchIds = ids.filter((id) => !loadedIngredientsIds.current.includes(id))

    batch.current = new Set(batchIds)

    if (accordionRef.current) {
      accordionRef.current.setExpanded(false)
    }
  }

  return (
    <>
      <IngredientPlanningHandle ref={accordionRef}>
        <IngredientsSelector
          onChange={handleUpdateIngredient}
          onAdd={handleAddIngredient}
          onRemove={handleRemoveIngredient}
          onAddMultiple={handleAddAll}
        />
      </IngredientPlanningHandle>
      <ComparisonResults ingredients={ingredients} />
    </>
  )
}
