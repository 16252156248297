import React, { FC } from 'react'
import { Box, Button, Checkbox, ListItemText, Stack, Autocomplete, TextField, Chip } from './'

export interface Option {
  value: number
  label: string
  id?: number
  name?: string
}

export interface MultiSelectProps {
  handleChange: (updatedValues: Option[]) => void
  options: Option[]
  values: Option[]
  itemType?: string
  id?: string
  autoFocus?: boolean
  fullWidth?: boolean
}

export const MultiSelectWithCheckboxes: FC<MultiSelectProps> = ({
  handleChange,
  options,
  values,
  itemType = 'item',
  id = 'multiple-checkbox-select',
  autoFocus,
  fullWidth = true,
}) => {
  const areAllSelected = options.every((opt) => values.some((val) => val.value === opt.value))
  const handleAddAll = () => {
    handleChange(areAllSelected ? [] : options)
  }

  return (
    <Autocomplete
      data-pendo-id={`Search${itemType}s`}
      id={id}
      data-testid={id}
      multiple
      fullWidth={fullWidth}
      options={options}
      value={values}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      limitTags={1}
      disableCloseOnSelect
      onChange={(_event, newValue) => handleChange(newValue)}
      renderInput={(params) => (
        <TextField
          {...params}
          autoFocus={autoFocus}
          placeholder={values.length ? '' : `${itemType.charAt(0).toUpperCase() + itemType.slice(1)}s`}
          fullWidth={fullWidth}
          onKeyDown={(event) => {
            // prevent backspce from removing selected options
            if (event.key === 'Backspace' || event.key === 'Delete') {
              event.stopPropagation()
            }
          }}
          sx={{
            paddingRight: 0,
            minWidth: 210,
            '& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorSecondary.MuiInputBase-fullWidth.Mui-focused.MuiInputBase-formControl': {
              paddingRight: values.length ? 8 : 3,
            },
          }}
        />
      )}
      renderTags={(selected, getTagProps, ownerState) => {
        if (ownerState?.focused && selected.length) {
          return (
            <Stack direction="row" gap={1} alignItems="center">
              <Chip size="small" label={`${selected.length} selected`} />
            </Stack>
          )
        }
        return (
          <Stack direction="row" gap={1} alignItems="center">
            {selected.slice(0, 1).map((option, index) => (
              <Chip size="small" {...getTagProps({ index })} key={index} label={option.label} />
            ))}

            {selected.length > 1 && ` +${selected.length - 1}`}
          </Stack>
        )
      }}
      renderHeader={() => (
        <Box m={1}>
          <Button color="secondary" size="small" fullWidth onClick={handleAddAll}>
            {areAllSelected ? `Remove all ${itemType}s` : `Add all ${itemType}s`}
          </Button>
        </Box>
      )}
      renderOption={(props, opt, { selected }) => (
        <li {...props} key={opt.value} style={{ margin: '8px', padding: 0 }}>
          <Stack direction="row" width="100%" margin={0.5}>
            <Checkbox checked={selected} />
            <ListItemText primary={opt.label} />
          </Stack>
        </li>
      )}
      sx={{
        '& .MuiFormControl-root': {
          '& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorSecondary.MuiInputBase-fullWidth.MuiInputBase-formControl': {
            paddingRight: 3,
            flexWrap: 'nowrap',
            minWidth: 125,
          },
        },
      }}
      slotProps={{
        popper: { style: { width: 'fit-content' }, placement: 'bottom-start' },
      }}
    />
  )
}
