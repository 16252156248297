import React, { FC, ReactNode, useState } from 'react'
import { faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { Box, Button, Menu } from '@howgood/design'

interface DropdownButtonProps {
  label: string
  icon?: IconDefinition
  onClose?: () => void
  children: ReactNode
  'data-pendo-id'?: string
  'data-testid'?: string
}

export const ControlPanelDropdown: FC<DropdownButtonProps> = ({ label, icon, onClose, children, ...rest }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const isOpen = Boolean(anchorEl)

  return (
    <Box display="flex" alignItems="center" justifyContent="stretch">
      <Button
        data-pendo-id={rest['data-pendo-id']}
        data-testid={rest['data-testid'] || 'control-panel-dropdown'}
        startIcon={icon}
        endIcon={isOpen ? faCaretUp : faCaretDown}
        onClick={(e) => setAnchorEl(e.target)}
      >
        {label}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={isOpen}
        onClose={() => {
          setAnchorEl(null)
          if (onClose) {
            onClose()
          }
        }}
      >
        {children}
      </Menu>
    </Box>
  )
}
