import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Stack } from '@howgood/design'

import {
  selectCanLockProduct,
  selectIsCreateNew,
  selectIsScenarioContext,
  selectSavedProductInfo,
} from '@/state/productOverview'
import { Toolbar } from './Toolbar'
import { FormulaHeader } from './FormulaHeader'
import { Tags } from './Tags/Tags'
import { Vendor } from './Vendor'
import { ValidationControls } from './ValidationFlow'
import { ScenarioEditControls } from './ScenarioProductEditControls'
import { selectIsSupplierConnect } from '@/state/organization'
import { WorkspaceSelect } from './WorkspaceSelect'
import { ViewSelect } from './ValidationFlow/ViewSelect'
import { ScenarioViewSourceButton } from './ScenarioViewSourceButton'

export const Header: FC = () => {
  const productLockable = useSelector(selectCanLockProduct)
  const productInfo = useSelector(selectSavedProductInfo)
  const showScenarioContext = useSelector(selectIsScenarioContext)
  const isSupplierConnect = useSelector(selectIsSupplierConnect)
  const isCreateNew = useSelector(selectIsCreateNew)

  return (
    <Stack>
      <Stack mb={-1} direction="row" justifyContent="space-between" alignItems="stretch">
        <Stack overflow="hidden" flexGrow={1}>
          {isCreateNew && <WorkspaceSelect />}
          <FormulaHeader />
          <Stack direction="row" gap={3} id="product-tags-block">
            <Vendor />
            {!isSupplierConnect && <Tags />}
          </Stack>
        </Stack>

        {/* This is the view selector and the edit/save and report buttons on the right
         * Need a special version of this control group for managing non-lockable products
         * and another version for managing initiative products
         */}
        {productLockable && !showScenarioContext && <ValidationControls />}
        {!productLockable && !showScenarioContext && <Toolbar />}
        <Stack gap={1} justifyContent="start" alignItems="end">
          {showScenarioContext && (
            <Stack direction="row" gap={1}>
              <ViewSelect />
              {productInfo.source_product?.id && <ScenarioViewSourceButton />}
            </Stack>
          )}
          {showScenarioContext && <ScenarioEditControls />}
        </Stack>
      </Stack>
    </Stack>
  )
}
