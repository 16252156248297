import { IngredientRouteLegs, WeightOption } from '@/records'
import { IProcessingType } from '@/records/ProcessingType'
import { MaterialType } from './products'

export type StandardCategoryType = 'certification' | 'practice'
export const STANDARD_CATEGORY_CERTIFICATION: StandardCategoryType = 'certification'
export const STANDARD_CATEGORY_PRACTICE: StandardCategoryType = 'practice'

export interface Standard {
  id: number
  identifier: string
  title: string
  category?: StandardCategoryType
}

export interface Ingredient {
  id: number
  name: string
  date_created: string
  date_modified: string
  workflow_editor: {
    url: string
    id: number
    email: string
  }
  product_id: number
  ingredient: {
    id: number
    url: string
    internal_name: string
    is_relevant: boolean
    base: {
      id: number
      url: string
      name: string
      human_safety: string
      transparency: boolean
    }
    origin_location: {
      id: number
      url: string
      region: {
        id: number
        name: string
        kg_co2_per_mj: string
        url: string
      }
    }
  }
  processing_location: {
    id: number
    name: string
    url: string
  }
  nested_product: {
    id: number
    name: string
    input_weight_unit: WeightOption
    brand?: string
    material_types?: MaterialType[]
    locked_claims_timestamp?: string
    workspaces?: number[]
    workflow_tags: string[]
  }
  product?: { id: number; name: string }
  standards: Standard[]
  position: number
  flat_position: number[]
  token: string
  weight: string
  key?: string
  is_nested_product?: boolean
  is_expanded?: boolean
  is_shown?: boolean
  flat_position_string?: string
  parent_keys?: string[]
  parent_keys_string?: string
  parent_keys_with_self_key_string?: string
  workflow_tags: string[]
  labels: string[]
  synonyms: string[]
  processing_types?: IProcessingType[]
  workflow_editor_id: number
  is_foreign?: boolean
  flat_weight?: string
  is_checked_for_weight_redistribution?: boolean
  needs_redistribute?: boolean
  hide_scores?: boolean
  route_legs?: IngredientRouteLegs
}

export type IngredientsResponse = Ingredient[]
