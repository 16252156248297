import { createUseStyles } from 'react-jss'
import { theme } from '@howgood/design'

export const useProductGhgImpactExportStyles = createUseStyles({
  sectionTitle: {
    fontSize: '26px',
    lineHeight: '34px',
    marginBottom: 14,
  },
  heading: {
    fontFamily: theme.typography.display.fontFamily,
    fontWeight: theme.typography.display.fontWeight,
    fontSize: '20px',
    marginBottom: 6,
  },
  reportTitleRow: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  reportTitle: {
    flex: 1,
    fontSize: '38px',
    lineHeight: '44px',
  },
  lifecycleChart: {
    marginTop: 10,
  },
  disclaimer: {
    marginTop: 30,
    color: theme.palette.primary.main,
    fontSize: '8px',
  },
  footerLatisLogo: {
    marginTop: 40,
  },
  footerLogoWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    borderTop: '1px solid #417C81',
    marginTop: 16,
    paddingTop: 16,
  },
  subsectionSubtitle: {
    fontSize: 18,
    display: 'block',
    fontWeight: 700,
  },
  textBlocked: {
    display: 'block',
  },
})
