import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { faBoxArchive } from '@fortawesome/pro-regular-svg-icons'
import { MenuItem, ListItemIcon, ListItemText } from '@howgood/design'

import { selectCanEditProduct, selectIsCreateNew, selectIsScenarioContext } from '@/state/productOverview'
import { PUBLISHED, selectPageView } from '@/state/pageSettings'

export const ArchiveFormula: FC<{ onClick: () => void }> = ({ onClick }) => {
  const pageView = useSelector(selectPageView)
  const createNew = useSelector(selectIsCreateNew)
  const showScenarioOptions = useSelector(selectIsScenarioContext)
  const canEdit = useSelector(selectCanEditProduct)

  if (!canEdit || createNew || showScenarioOptions || pageView === PUBLISHED) {
    return null
  }

  return (
    <MenuItem id="archive-option" data-testid="archive-option" onClick={onClick}>
      <ListItemIcon icon={faBoxArchive} />
      <ListItemText>Archive formula</ListItemText>
    </MenuItem>
  )
}
