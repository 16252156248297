import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'
import { Accordion, AccordionDetails, Text, Grid } from '@howgood/design'
import { AccordionCardHeader } from '@/components/Scores/AccordionCardHeader'

const useStyles = createUseStyles({
  pre: {
    backgroundColor: 'transparent',
    padding: 0,
  },
})

interface Props {
  isFavorites?: boolean
  id: string
  displayedNotes: string
  title?: string
}

export const NotesAccordion: FC<Props> = ({ isFavorites, id, displayedNotes, title = 'Notes' }) => {
  const classes = useStyles()

  if (!displayedNotes) {
    return null
  }

  return (
    <Grid item lg={12} md={12} xs={12} sm={12}>
      <Accordion id={id} data-testid={id} defaultExpanded>
        <AccordionCardHeader id={`${id}-header`} cardKey="notes" title={title} favoritesTab={isFavorites} />
        <AccordionDetails>
          <pre className={classes.pre}>
            <Text>{displayedNotes}</Text>
          </pre>
        </AccordionDetails>
      </Accordion>
    </Grid>
  )
}
