import React, { FC, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Autocomplete, TextField } from '@howgood/design'
import { selectManufacturingTypes } from '@/state/manufacturingTypes'
import { selectDisplayedSalesAndDistribution, updateRecipeAndScores } from '@/state/recipe'
import { DRAFT, selectPageView } from '@/state/pageSettings'
import { panelStyles } from '../panelStyles'
import { GridItemTitle } from '../GridItemTitle'
import { ManufacturingType as MfrType } from '@/state/manufacturingTypes'

export const ManufacturingType: FC = () => {
  const dispatch = useDispatch()
  const salesAndDistribution = useSelector(selectDisplayedSalesAndDistribution)
  const view = useSelector(selectPageView)
  const manufacturingTypes = useSelector(selectManufacturingTypes)
  const sortedManufacturingTypes = useMemo(() => {
    const copy = [...manufacturingTypes]
    copy.sort((a, b) => a.title.localeCompare(b.title))
    return copy
  }, [manufacturingTypes])

  const handleManufacturingTypeSelected = (_event: React.SyntheticEvent<Element, Event>, type) => {
    dispatch(
      updateRecipeAndScores({
        recipeUpdates: {
          sales_distribution: {
            ...salesAndDistribution,
            manufacturing_type: type,
          },
        },
        change: `Changed manufacturing_type to ${type?.title || null}`,
      })
    )
  }

  return (
    <>
      <GridItemTitle title="Manufacturing Type" tooltipText="Required for cradle-to-gate carbon metrics" />
      <Autocomplete
        id="manufacturing-type-autocomplete"
        aria-label="Select manufacturing type using autocomplete"
        disabled={view !== DRAFT}
        options={sortedManufacturingTypes}
        getOptionLabel={(option: MfrType) => option.title || ''}
        value={salesAndDistribution.manufacturing_type}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        onChange={(e, type: MfrType) => handleManufacturingTypeSelected(e, type)}
        renderInput={(params) => <TextField {...params} sx={panelStyles.autocomplete} />}
        ListboxProps={{ id: 'manufacturing-type-menu' }}
        fullWidth
      />
    </>
  )
}
