import React, { FC } from 'react'
import { DataGridPremium, GridColDef, GridRowsProp, GridSortModel } from '@mui/x-data-grid-premium'
import { Box, Text, Accordion, AccordionSummary, AccordionDetails } from '@howgood/design'

interface HistoryTableProps {
  rows: GridRowsProp
  columns: GridColDef<any, any, any>[]
  uniqueId: string
  sortModel?: GridSortModel
  titleText?: string
  defaultExpanded?: boolean
}

export const HistoryTable: FC<HistoryTableProps> = ({
  rows,
  columns,
  uniqueId,
  sortModel = [{ field: 'requested', sort: 'desc' }] as GridSortModel,
  titleText = 'Request history',
  defaultExpanded = false,
}) => {
  return (
    <Accordion type="secondary" data-testid={`${uniqueId}-history-accordion`} defaultExpanded={defaultExpanded}>
      <AccordionSummary>
        <Text data-testid={`${uniqueId}-history-title`} variant="h4">
          {titleText}
        </Text>
      </AccordionSummary>
      <AccordionDetails>
        <Box maxHeight={285} overflow="auto" data-testid={`${uniqueId}-history-table`}>
          <DataGridPremium
            rows={rows}
            columns={columns}
            autoHeight
            hideFooter
            disableColumnMenu
            disableRowSelectionOnClick
            initialState={{
              density: 'compact',
              sorting: { sortModel },
            }}
          />
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}
