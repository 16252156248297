import { AnyAction } from 'redux'
import { ThunkAction } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'
import { AppState } from '@/store'
import { addMessage } from '@/state/messages'

interface HandleThunkErrorAndAddMessage {
  error: Error
  message?: string
  actionType?: string
  context?: Object
}

export const handleThunkError = ({
  error,
  message = '',
  actionType = '',
  context = {},
}: HandleThunkErrorAndAddMessage): ThunkAction<void, AppState, void, AnyAction> => async (dispatch) => {
  if (message) {
    dispatch(addMessage({ message, severity: 'error' }))
  }
  Sentry.captureException(error, {
    extra: { action: actionType, userMessage: message, ...context },
  })
}
