import React, { FC } from 'react'
import { faFlagPennant } from '@fortawesome/pro-solid-svg-icons'
import { Text } from '../Text'
import { Stack } from '../Stack'
import { Icon } from '../Icon'
import { FavoriteIcon } from './FavoriteIcon'

interface Props {
  category: string
  goal: boolean
  handleFavorite: (key: string) => void
  hideFavoriteIcon?: boolean
}

export const MetricTileHeader: FC<Props> = ({ category, goal, handleFavorite, hideFavoriteIcon }) => {
  if (!category) {
    return null
  }

  const text = goal ? category.concat(' goal') : category

  return (
    <Stack
      data-testid="metric-tile-header"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      minHeight="24px"
      px={0.75}
      borderRadius={0.5}
      bgcolor={goal ? 'info.main' : 'secondary.paperMain'}
    >
      <Text
        data-testid="metric-tile-header-text"
        variant="subtitle2"
        fontWeight={goal ? 500 : 600}
        color={goal ? 'white' : 'text.disabled'}
      >
        {text.toUpperCase()}
      </Text>
      {goal && <Icon data-testid="metric-tile-header-flag-icon" icon={faFlagPennant} color="white" size="xs" />}
      {!goal && !hideFavoriteIcon && (
        <FavoriteIcon handleFavorite={handleFavorite as () => void} favorite isFavorites />
      )}
    </Stack>
  )
}
