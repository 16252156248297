import React, { forwardRef } from 'react'
import MuiTypography, { TypographyProps as MuiTypographyProps } from '@mui/material/Typography'

export interface TextProps
  extends Pick<
    MuiTypographyProps,
    | 'id'
    | 'color'
    | 'width'
    | 'minWidth'
    | 'variant'
    | 'order'
    | 'fontSize'
    | 'fontWeight'
    | 'textAlign'
    | 'textOverflow'
    | 'visibility'
    | 'overflow'
    | 'lineHeight'
    | 'noWrap'
    | 'textTransform'
    | 'onClick'
    | 'children'
  > {
  'data-testid'?: string
  lineBreak?: string
  textWrap?: string
}

export const Text = forwardRef<HTMLSpanElement, TextProps>(
  (
    {
      color,
      variant,
      order,
      width,
      minWidth,
      fontSize,
      fontWeight,
      textAlign,
      textOverflow,
      lineHeight,
      lineBreak,
      visibility,
      noWrap,
      textWrap,
      textTransform,
      onClick,
      children,
      ['data-testid']: dataTestId,
      ...rest
    },
    ref
  ) => {
    return (
      <MuiTypography
        ref={ref}
        data-testid={dataTestId}
        variant={variant}
        color={color}
        width={width}
        minWidth={minWidth}
        order={order}
        fontSize={fontSize}
        fontWeight={fontWeight}
        textAlign={textAlign}
        textOverflow={textOverflow}
        visibility={visibility}
        lineHeight={lineHeight}
        noWrap={noWrap}
        textTransform={textTransform}
        onClick={onClick}
        sx={{ lineBreak, textWrap, cursor: onClick ? 'pointer' : 'inherit' }}
        {...rest}
      >
        {children}
      </MuiTypography>
    )
  }
)

Text.displayName = 'Text'
