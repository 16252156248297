import React, { FC } from 'react'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { Text } from '@howgood/design'
import { WorkspaceType, workspaceTypeLabelMap } from '@/records'

interface WorkspaceTypeCellParams extends GridRenderCellParams {
  value?: WorkspaceType
}

export const WorkspaceTypeCell: FC<WorkspaceTypeCellParams> = (params) => {
  const { value: workspace_type, id } = params

  return <Text data-testid={`workspace-type-${id}`}>{workspaceTypeLabelMap[workspace_type]}</Text>
}
