import React, { FC } from 'react'
import { Stack } from './Stack'
import { Text } from './Text'

interface Props {
  bgcolor: string
  score: number | null
}
export const ScoreBadge: FC<Props> = ({ bgcolor, score }) => {
  const scoreFormatted = score === null ? '--' : score
  const fontWeight = score === null ? undefined : 700

  return (
    <Stack
      data-testid="score-badge"
      alignItems="center"
      justifyContent="center"
      width={40}
      height={26}
      borderRadius={1}
      bgcolor={bgcolor}
    >
      <Text data-testid="score-badge-text" fontSize={20} fontWeight={fontWeight}>
        {scoreFormatted}
      </Text>
    </Stack>
  )
}
