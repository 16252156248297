import { useSelector } from 'react-redux'
import React, { FC } from 'react'
import { Stack } from '@howgood/design'

import { selectIsCreateNew } from '@/state/productOverview'
import { SaveButton } from './SaveButton'
import { ProductViewToggle } from './ProductViewToggle'

export const Toolbar: FC = () => {
  const isCreateNew = useSelector(selectIsCreateNew)
  const saveText = isCreateNew ? 'Save new formula' : 'Save'

  return (
    <Stack flexDirection="row" gap={1}>
      <SaveButton saveText={saveText} />
      {!isCreateNew && <ProductViewToggle />}
    </Stack>
  )
}
