import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Grid, Text } from '@howgood/design'
import { panelStyles } from '../panelStyles'
import { DataHealth } from './DataHealth'
import { ProductData } from './ProductData'
import { selectIsSupplierConnect } from '@/state/organization'

export const OtherInfo: FC = () => {
  const isSupplierConnect = useSelector(selectIsSupplierConnect)

  return (
    <Grid container columnSpacing={4} rowSpacing={1} {...panelStyles.grid}>
      <Grid item xs={4}>
        <Text fontWeight="bold">Product Data</Text>
        <ProductData />
      </Grid>
      {!isSupplierConnect && (
        <Grid item xs={4}>
          <Text fontWeight="bold">Data Health</Text>
          <DataHealth />
        </Grid>
      )}
    </Grid>
  )
}
