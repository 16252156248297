import React, { FC, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router'
import { selectDisplayedProductWorkspaces } from '@/state/productOverview'
import { selectWorkspacesByType } from '@/state/workspaces'
import { createFormulationsSearchParams } from '@/utils/getUrl'
import { SidebarSubItem } from './SidebarSubItem'
import { Workspace, WorkspaceOption, WorkspaceType } from '@/records'
import { selectWorkspaceProductFilters, updateFiltersUrlAndProducts } from '@/state/products'
import { MATERIAL_PATH, PRODUCT_PATH } from '@/constants/config'
import { createIWorkspaceOption } from '@/utils/workspace'
import { selectIsRollup } from '@/state/router'

export const WorkspacesList: FC<{
  workspaceType: WorkspaceType
  path: string
  onClick: React.MouseEventHandler<HTMLDivElement>
}> = ({ workspaceType, path, onClick }) => {
  const dispatch = useDispatch()
  const isRollup = useSelector(selectIsRollup)
  const userWorkspaces = useSelector(selectWorkspacesByType(workspaceType))
  const userWorkspacesOptions: WorkspaceOption[] = userWorkspaces.map((w: Workspace) => createIWorkspaceOption(w))
  const workspaces = useSelector(selectDisplayedProductWorkspaces)
  const selectedWorkspaces = useSelector(selectWorkspaceProductFilters)

  const list = useMemo(() => {
    return userWorkspacesOptions.sort((a, b) => a.label.localeCompare(b.label))
  }, [userWorkspacesOptions])

  const isProductActive = useRouteMatch({ path: [MATERIAL_PATH, PRODUCT_PATH] })
  const isItemActive = (id: string): boolean => {
    if (selectedWorkspaces.length === 1) {
      return selectedWorkspaces[0].id.toString() === id
    }
    if (isProductActive) {
      return workspaces.some((workspace) => workspace.id.toString() === id)
    }
    return false
  }

  return (
    <>
      {!!userWorkspacesOptions &&
        list.map((ws: WorkspaceOption) => {
          const { id, label, edit, read } = ws
          const thirdParty = !edit && !read
          const addWorkspaceFilter = isRollup
            ? (e: React.MouseEvent<HTMLDivElement>) => {
                onClick(e)
                dispatch(
                  updateFiltersUrlAndProducts({
                    updatedFilters: { workspaces: [ws] },
                  })
                )
              }
            : undefined
          return (
            <SidebarSubItem
              data-testid={`menu-workspaces-${id}`}
              key={id}
              label={label}
              isThirdParty={thirdParty}
              path={`${path}/${createFormulationsSearchParams({
                workspaces: [id],
              })}`}
              active={isItemActive(id.toString())}
              handleClick={addWorkspaceFilter}
            />
          )
        })}
    </>
  )
}
