import React, { FC, useState } from 'react'
import { Autocomplete, MenuItem, TextField, Tooltip } from '@howgood/design'
import { searchRegions, Region } from '@/api'
import { IRegionOption } from '@/records/Region'

interface Props {
  setSelectedRegions: (regions: IRegionOption[]) => void
  id?: string
  autoFocus?: boolean
  selectedRegions: IRegionOption[]
  placeholder?: string
  disabled?: boolean
}

export const RegionsDropdownFilter: FC<Props> = ({
  selectedRegions,
  setSelectedRegions,
  placeholder = 'Production Location',
  disabled,
  autoFocus,
  id = 'regions-search',
}) => {
  const [options, setOptions] = useState<IRegionOption[]>([])

  const fetchOptions = async (query: string): Promise<IRegionOption[]> => {
    if (!query) {
      return new Promise(() => [])
    }

    const hits: Region[] = await searchRegions(query)
    const regions: IRegionOption[] = hits.map((r) => ({ id: r.id, value: r.id, label: r.name }))

    // Remove duplicate items and existing in selected regions
    return regions.filter((option: IRegionOption) => {
      const firstMatched = regions.find((opt: IRegionOption) => opt.value === option.value)
      // will not work with cacheOptions
      const isInSelectedLocations = !!selectedRegions.find((region) => region.value === option.value)
      return firstMatched === option && !isInSelectedLocations
    })
  }

  return (
    <Tooltip title={`Filter by ${placeholder}`} placement="top">
      <Autocomplete
        id={id}
        data-testid={id}
        data-pendo-id="SearchRegions"
        aria-label={placeholder}
        options={options}
        filterSelectedOptions
        value={selectedRegions}
        onChange={(_event, ops) => setSelectedRegions(ops)}
        openOnFocus={false}
        fullWidth
        disabled={disabled}
        multiple
        renderInput={(params) => (
          <TextField
            {...params}
            id="region-search"
            autoFocus={autoFocus}
            placeholder={placeholder}
            onChange={async (event) => {
              const tagOptions = await fetchOptions(event.target.value)
              setOptions(tagOptions)
            }}
          />
        )}
        renderOption={(props, option) => (
          <MenuItem
            {...props}
            key={option.value}
            value={option}
            onClick={() => setSelectedRegions([...selectedRegions, option])}
          >
            {option.label}
          </MenuItem>
        )}
      />
    </Tooltip>
  )
}
