import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TextField } from '@howgood/design'

import { selectDisplayedSalesAndDistribution, updateRecipeAndScores } from '@/state/recipe'
import { DRAFT, selectPageView } from '@/state/pageSettings'
import { panelStyles } from '../panelStyles'
import { GridItemTitle } from '../GridItemTitle'

export const UnitSize: FC = () => {
  const dispatch = useDispatch()
  const salesAndDistribution = useSelector(selectDisplayedSalesAndDistribution)
  const view = useSelector(selectPageView)
  const [unitSize, setUnitSize] = useState(salesAndDistribution.weight_kg?.toString())

  // If user restores a previous snapshot, update the local value
  useEffect(() => {
    setUnitSize(salesAndDistribution.weight_kg?.toString())
  }, [salesAndDistribution.weight_kg])

  const handleUnitSizeChange = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const stringValue = e.currentTarget.value
    const numericValue = +e.currentTarget.value
    if (!Number.isNaN(numericValue) && numericValue < 10000) {
      setUnitSize(stringValue)
    }
  }

  const handleLeaveUnitSize = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = +e.currentTarget.value
    dispatch(
      updateRecipeAndScores({
        recipeUpdates: {
          sales_distribution: {
            ...salesAndDistribution,
            weight_kg: value,
          },
        },
        change: `Changed weight_kg to ${value}`,
      })
    )
  }

  return (
    <>
      <GridItemTitle title="Unit Size (kg)" tooltipText="Required for annual carbon metrics" />
      <TextField
        id="unit-size-field"
        disabled={view !== DRAFT}
        value={unitSize}
        onChange={handleUnitSizeChange}
        onBlur={handleLeaveUnitSize}
        sx={panelStyles.textField}
      />
    </>
  )
}
