import React, { FC } from 'react'
import { Stack, Text, ToggleGroup } from '@howgood/design'
import { Region } from '@/api'
import { TransportationMode } from '@/records'
import { ModeSelect } from '../../../ProductInfo/Transportation/ModeSelect'
import { DistanceInput } from '../../../ProductInfo/Transportation/DistanceInput'
import { RegionSelect } from '../../../ProductInfo/Transportation/RegionSelect'

export type TransportationType = 'latis-default' | 'custom'
export interface CustomTransportation {
  mode: TransportationMode
  distance_override: number
  waypoint: Region
}
interface Props {
  type: TransportationType
  mode: TransportationMode
  distance: number
  region: Region
  onChange: (change: Partial<CustomTransportation>) => void
  onChangeType: (type: TransportationType) => void
}

export const TransportationData: FC<Props> = ({ type, mode, distance, region, onChange, onChangeType }) => {
  return (
    <Stack data-testid="transportation-data" gap={2}>
      <Stack flexDirection="row" justifyContent="space-between" alignItems="center" width="100%">
        <Text data-testid="transportation-data-label">Transportation Data</Text>
        <ToggleGroup
          data-testid="transportation-data-toggle"
          options={[
            { id: 'latis-default', label: 'Latis Default', active: type === 'latis-default' },
            { id: 'custom', label: 'Custom', active: type === 'custom' },
          ]}
          onChange={(t: TransportationType) => onChangeType(t)}
        />
      </Stack>
      {type === 'custom' && (
        <Stack gap={2}>
          <Stack flexDirection="row" gap={1}>
            <ModeSelect mode={mode} label="Transportation Mode" onChange={(m) => onChange({ mode: m })} />
            <DistanceInput distance={distance} onChange={(d) => onChange({ distance_override: d })} />
          </Stack>
          <Stack gap={0.25}>
            <RegionSelect
              type="manufacturing"
              labelOverride="Product Manufacturing Region"
              region={region}
              onChange={(w) => onChange({ waypoint: w })}
            />
            <Text variant="caption">Changing this will change it for all ingredients in this formula</Text>
          </Stack>
        </Stack>
      )}
    </Stack>
  )
}
