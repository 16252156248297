import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Box, Text, Tooltip } from '@howgood/design'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { RecipeIngredient, RecipeNestedIngredient } from '@/state/recipe/recipe.state'
import { SourceSelect } from './SourceSelect'
import { DRAFT, selectPageView } from '@/state/pageSettings'
import { selectIngredientWarnings } from '@/state/recipe'

interface SourceProps extends GridRenderCellParams {
  value?: {
    ingredient: RecipeIngredient | RecipeNestedIngredient
  }
}

export const Source: FC<SourceProps> = (params) => {
  const ingredient = params.value?.ingredient
  const view = useSelector(selectPageView)
  const ingredientErrors = useSelector(selectIngredientWarnings)
  const invalidIngredient = ingredientErrors.map((error) => error.id).includes(ingredient.id)

  if (!('base_id' in ingredient)) {
    return null
  }
  if (ingredient.isTopLevel && view === DRAFT) {
    return <SourceSelect ingredient={ingredient} />
  }
  return (
    <Tooltip title={ingredient.origin_location_name}>
      <Box sx={{ cursor: 'pointer', opacity: view === DRAFT ? 0.7 : 1 }}>
        <Text color={invalidIngredient ? 'error.main' : null} textOverflow="ellipsis" noWrap>
          {ingredient.origin_location_name}
        </Text>
      </Box>
    </Tooltip>
  )
}
