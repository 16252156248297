import classNames from 'classnames'
import React, { FC, MouseEventHandler } from 'react'
import { useDispatch } from 'react-redux'
import { useRouteMatch } from 'react-router'
import { Link } from 'react-router-dom'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { Icon, Stack } from '@howgood/design'

import { useNavigate } from '../../hooks/useNavigate'
import { initialState, updateProductFilters } from '@/state/products'
import { useStyles } from './sidebarStyles'

interface SidebarItemProps {
  label: string
  ariaLabel?: string
  path: string
  icon: IconProp
  className?: string
  disabled?: boolean
  onClickOverride?: MouseEventHandler<HTMLDivElement>
  ['data-testid']?: string
  pendoId?: string
  exactMatch?: boolean
}

export const SidebarItem: FC<SidebarItemProps> = ({
  label,
  ariaLabel,
  path,
  icon,
  className,
  disabled,
  onClickOverride,
  ['data-testid']: dataTestId,
  pendoId,
  exactMatch = false,
}) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { safelyNavigateTo } = useNavigate()
  const match = useRouteMatch({ path, exact: exactMatch })

  const onClick: MouseEventHandler<HTMLDivElement> = (e) => {
    if (disabled) {
      return
    }
    if (onClickOverride) {
      onClickOverride(e)
    } else {
      dispatch(updateProductFilters(initialState.productFilters))
      safelyNavigateTo(path, e)
    }
  }

  return (
    <Link
      to={path}
      aria-label={ariaLabel || label}
      data-pendo-id={pendoId}
      data-testid={dataTestId}
      className={
        className ||
        classNames([classes.aLink, classes.menuItem, disabled ? classes.disabled : match ? classes.active : ''])
      }
      onClick={onClick}
    >
      <Stack direction="row" alignItems="center" gap={2}>
        <Icon icon={icon} color="white" />
        {label}
      </Stack>
    </Link>
  )
}
