import { Preferences } from './preferences.slice'
import {
  VIEW_AGG_IMPACT_CF_FTM_GATE_CT_VERIFIED,
  VIEW_AGG_IMPACT_HOWGOOD_ONE_METRIC,
  VIEW_AGG_IMPACT_RAW_BLUE_WATER_USAGE,
  VIEW_PRODUCT_IMPACT_STARBUCKS,
} from '@/constants/impactScore'

const defaultMericGridColumns = [
  VIEW_AGG_IMPACT_HOWGOOD_ONE_METRIC,
  VIEW_PRODUCT_IMPACT_STARBUCKS,
  VIEW_AGG_IMPACT_CF_FTM_GATE_CT_VERIFIED,
  VIEW_AGG_IMPACT_RAW_BLUE_WATER_USAGE,
  'name',
  'category',
  'assignee',
  'date',
]

const defaultVendorManagementGridColumns = [
  'name',
  'priority',
  'count',
  'mtPerYear',
  'carbon_footprint_farm_to_gate_impact',
  'annual_carbon_footprint_farm_to_gate_impact',
  'scope_3_category_1_impact',
  'scope_3_category_4_impact',
  'scope_3_contribution',
]

export function getFavorites(): Preferences {
  const localPreferences = localStorage.getItem('latis-preferences')
  if (!localPreferences) {
    return {
      favoriteTiles: [
        'howgood_one_metric',
        'starbucks_impact',
        'raw_ftmgate_water_withdrawal_impact',
        'carbon_footprint_on_farm_processing_ghgs_impact',
        'carbon_footprint_annual_impact',
        'retail_rating_impact',
        'eco_score_impact',
        'nutrition_score',
      ],
      favoriteCards: ['claims', 'impacts'],
      gridSettings: {
        showValues: false,
        colWidths: {},
        hiddenCols: [],
        scoringMethod: 'perKilogram',
      },
      selectedMetricGridColumns: defaultMericGridColumns,
      selectedVendorManagementGridColumns: defaultVendorManagementGridColumns,
    }
  }
  // Changed the name of favorites to favoriteTiles, so handling legacy
  // TODO: should be able to remove this after a bit
  const preferences = JSON.parse(localPreferences)
  if (preferences.favorites) {
    return {
      favoriteTiles: preferences.favorites,
      favoriteCards: [],
      gridSettings: {
        showValues: false,
        colWidths: {},
        hiddenCols: [],
        scoringMethod: 'perKilogram',
      },
      selectedMetricGridColumns: preferences.selectedMetricGridColumns || defaultMericGridColumns,
      selectedVendorManagementGridColumns:
        preferences.selectedVendorManagementGridColumns || defaultVendorManagementGridColumns,
    }
  }
  return {
    favoriteTiles: preferences.favoriteTiles,
    favoriteCards: preferences.favoriteCards,
    gridSettings: {
      showValues: preferences.gridSettings?.showValues || false,
      colWidths: preferences.gridSettings?.colWidths || {},
      hiddenCols: preferences.gridSettings?.hiddenCols || [],
      scoringMethod: preferences.gridSettings?.scoringMethod || 'perKilogram',
    },
    selectedMetricGridColumns: preferences.selectedMetricGridColumns || defaultMericGridColumns,
    selectedVendorManagementGridColumns:
      preferences.selectedVendorManagementGridColumns || defaultVendorManagementGridColumns,
  }
}
