import React, { useMemo } from 'react'
import { GridColDef } from '@mui/x-data-grid-premium'
import { HeatmapTableCell, ImpactScoreCell } from '@/components'
import { Field, PERMISSIONS_TO_FIELDS_MAP } from '@/constants/impactScore'
import { useSortedContentfulMetrics } from '@/contentfully/hooks'
import { Stack } from '@howgood/design'

export const useImpactScoreBarColumns = (permissions: string[]): GridColDef[] => {
  const fields = permissions.map((permission) => PERMISSIONS_TO_FIELDS_MAP[permission])
  const metrics = useSortedContentfulMetrics(fields)

  return useMemo(() => {
    if (!metrics) return []
    return metrics.map((metric) => ({
      colId: metric?.key,
      field: metric?.key,
      headerName: metric?.title,
      cellRenderer: 'impactScoreRenderer',
      width: 200,
      renderCell: (params) => (
        <Stack width={180}>
          <ImpactScoreCell value={params.value} />
        </Stack>
      ),
    }))
  }, [metrics])
}

export const useIngredientImpactScoreHeatmapColumns = (
  permissions: string[],
  showAverages: boolean,
  cellClass: string = ''
): GridColDef[] => {
  const fields = permissions.map((permission) => PERMISSIONS_TO_FIELDS_MAP[permission])
  const metrics = useSortedContentfulMetrics(fields)

  return useMemo(() => {
    if (!metrics) return []
    return metrics.map((metric) => {
      const field = metric?.key
      const headerName = metric?.title?.includes('Carbon Footprint') ? metric.subtitle : metric?.title
      return {
        colId: field,
        field,
        tooltipField: field,
        headerName,
        headerTooltip: headerName,
        cellRenderer: 'heatmapRenderer',
        renderCell: (params) => {
          return (
            <HeatmapTableCell
              score={params.value}
              field={params.field as Field}
              impactScore={{ [params.field]: params.value }}
            />
          )
        },
        width: 130,
        columnGroupShow: 'open',
        aggFunc: showAverages ? 'avg' : null,
        availableAggregationFunctions: showAverages ? ['avg'] : [],
        cellClass,
        cellClassName: cellClass,
        cellClassRules: {
          horizontallyCentered: () => true,
        },
      }
    })
  }, [metrics, cellClass, showAverages])
}
