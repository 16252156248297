import { createAsyncThunk } from '@reduxjs/toolkit'
import { ContentfulClientApi, EntryCollection } from 'contentful'
import {
  ContentType,
  CONTENT_TYPES,
  Entry,
  AttributeEntry,
  CarbonAccountingEntry,
  MetricEntry,
  MethodologyEntry,
  DataHealthIndicatorEntry,
  FormulaIconEntry,
  DocumentEntry,
  RequestVendorDataEntry,
  NewsEntry,
  TooltipEntry,
  LinkEntry,
} from '@/contentfully'
import { AppState } from '@/store'

// Keys are `attribute`, `metric`, etc.
export type AllContentfulData = {
  attribute: AttributeEntry[]
  carbonAccounting: CarbonAccountingEntry[]
  metric: MetricEntry[]
  requestVendorData: RequestVendorDataEntry[]
  methodology: MethodologyEntry[]
  dataHealthIndicator: DataHealthIndicatorEntry[]
  formulaIcon: FormulaIconEntry[]
  document: DocumentEntry[]
  news: NewsEntry[]
  tooltip: TooltipEntry[]
  link: LinkEntry[]
}

// Fetch all Contentful data and store it in Redux for use throughout the app
export const getContentfulData = createAsyncThunk<
  AllContentfulData,
  { contentfulClient: ContentfulClientApi<undefined> },
  { state: AppState }
>('contentfulData/getContentfulData', async ({ contentfulClient }) => {
  // Fetch all Contentful data for each content type (metric, attribute, etc.)
  const contentfulResponses = await Promise.all(
    Object.keys(CONTENT_TYPES).map((type) =>
      contentfulClient
        .getEntries({ content_type: CONTENT_TYPES[type], limit: 1000 })
        .then((entries: EntryCollection<Entry>) => {
          return {
            contentType: CONTENT_TYPES[type] as ContentType,
            data: entries.items.map((entry) => ({ ...entry.fields, ...entry.sys })),
          }
        })
    )
  )

  // Combine the response into a single object
  const contentfulDataByType = contentfulResponses.reduce(
    (acc, type) => ({ ...acc, [type.contentType]: type.data }),
    {} as AllContentfulData
  )

  return contentfulDataByType
})
