import React, { FC } from 'react'

import { ProductListRenderCellParams } from './ProductGridTypes'
import { HeatmapTableCell } from '../Layout'
import { Field } from '@/constants/impactScore'

interface Props extends ProductListRenderCellParams {
  value?: number
}

export const HeatmapCellRendererWithScores: FC<Props> = ({ colDef, id, row }) => {
  return (
    <HeatmapTableCell
      score={row[colDef.field]}
      field={colDef.field as Field}
      impactScore={row.impact_score}
      showScores
      includeGoal={true}
      productId={id}
    />
  )
}
