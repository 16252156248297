import { ImpactScore, fetchAllV2, fetchHelperV2 } from '@/api'
import { Workspace } from '@/records'

export const fetchWorkspaceData = async (): Promise<Workspace[]> => {
  const workspaces = await fetchAllV2<Workspace>({ url: 'accounts/workspaces/' })
  return workspaces.map((workspace) => {
    if (workspace.assignee) {
      // Insert a display label field e.g. 'peter@howgood.com (Peter Henderson)' into each user record
      return {
        ...workspace,
        assignee: {
          ...workspace.assignee,
          label:
            workspace.assignee?.first_name || workspace.assignee?.last_name
              ? `${workspace.assignee?.email} (${workspace.assignee?.first_name || ''} ${
                  workspace.assignee?.last_name || ''
                })`
              : workspace.assignee?.email,
        },
      }
    }
    return workspace
  })
}

export const fetchUpdateWorkspace = async (data: Partial<Workspace>): Promise<Workspace> => {
  return await fetchHelperV2<Workspace>({
    url: `accounts/workspaces/${data.id}/`,
    method: 'PATCH',
    body: JSON.stringify(data),
  })
}

export type WorkspaceScores = ImpactScore
export type AllWorkspaceScoresData = { [K in number]: WorkspaceScores }
export const fetchAllWorkspaceScoresData = async (formulationTags: string[] = []) => {
  const params = formulationTags.length ? `?formulation_tags=${formulationTags.join('&formulation_tags=')}` : ''
  return await fetchHelperV2<AllWorkspaceScoresData>({
    url: `products/workspace-scores/${params}`,
  })
}
