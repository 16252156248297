import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectMaterialIconAgpracticeSplit } from '@/state/splitio'
import { useGetFormulaIconBadge } from '@/hooks/useFormulaIconBadge'
import { FORMULA_ICON_BADGE } from '@/contentfully'
import { RecipeNestedIngredient, selectDisplayedIngredientList } from '@/state/recipe'
import { MaterialGranularityBreakdownData } from '@/components/Procurement/MaterialGranularityBreakdown/MaterialGranularityBreakdown'

export const MATERIAL_GRANULARITY_VENDOR_VERIFIED_PLUS = 'Vendor Verified (with ag practice data)'
export const MATERIAL_GRANULARITY_VENDOR_VERIFIED = 'Vendor Verified'
export const MATERIAL_GRANULARITY_KNOWN_VENDORS = 'Known Vendors'
export const MATERIAL_GRANULARITY_UNKNOWN_VENDORS = 'Unknown Vendors'
export const MATERIAL_GRANULARITY_STANDARD_INGREDIENTS = 'Standard Ingredients'

export function useFormulaDataGranularityBreakdown(): MaterialGranularityBreakdownData {
  const ingredients = useSelector(selectDisplayedIngredientList)
  const getFormulaIconBadge = useGetFormulaIconBadge()
  const materialIconAgpracticeEnabled = useSelector(selectMaterialIconAgpracticeSplit)

  const data = useMemo(
    () =>
      ingredients.reduce(
        (accum, ingredient) => {
          if (ingredient.isTopLevel) {
            const iconBadge = getFormulaIconBadge({
              workspaces: (ingredient as RecipeNestedIngredient).workspacesIds,
              material_types: (ingredient as RecipeNestedIngredient).material_types,
              isProduct: 'nested_product_id' in ingredient,
            })
            if (
              [
                FORMULA_ICON_BADGE.vendorVerified,
                FORMULA_ICON_BADGE.purchasedMaterial,
                FORMULA_ICON_BADGE.vendorVerifiedPlus,
                FORMULA_ICON_BADGE.standardIngredient,
              ].includes(iconBadge)
            ) {
              const vendorName = (ingredient as RecipeNestedIngredient).vendorName
              accum.totalCount++
              switch (true) {
                case iconBadge === FORMULA_ICON_BADGE.standardIngredient:
                  accum.vendors[MATERIAL_GRANULARITY_STANDARD_INGREDIENTS]++
                  break
                case iconBadge === FORMULA_ICON_BADGE.vendorVerifiedPlus && materialIconAgpracticeEnabled:
                  accum.vendors[MATERIAL_GRANULARITY_VENDOR_VERIFIED_PLUS]++
                  break
                case iconBadge === FORMULA_ICON_BADGE.vendorVerified:
                  accum.vendors[MATERIAL_GRANULARITY_VENDOR_VERIFIED]++
                  break
                case vendorName && !vendorName.toLowerCase().includes('unknown'):
                  accum.vendors[MATERIAL_GRANULARITY_KNOWN_VENDORS]++
                  break
                default:
                  accum.vendors[MATERIAL_GRANULARITY_UNKNOWN_VENDORS]++
              }
            }
          }
          return accum
        },
        {
          totalCount: 0,
          vendors: {
            [MATERIAL_GRANULARITY_VENDOR_VERIFIED_PLUS]: 0,
            [MATERIAL_GRANULARITY_VENDOR_VERIFIED]: 0,
            [MATERIAL_GRANULARITY_KNOWN_VENDORS]: 0,
            [MATERIAL_GRANULARITY_UNKNOWN_VENDORS]: 0,
            [MATERIAL_GRANULARITY_STANDARD_INGREDIENTS]: 0,
          },
        }
      ),
    [ingredients, getFormulaIconBadge, materialIconAgpracticeEnabled]
  )

  if (!materialIconAgpracticeEnabled) {
    delete data.vendors[MATERIAL_GRANULARITY_VENDOR_VERIFIED_PLUS]
  }

  return data
}
