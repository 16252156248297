import React, { FC } from 'react'
import { DataGridPremium } from '@mui/x-data-grid-premium'
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium'
import { useProductListColumns } from '@/components/Products/hooks/useProductListColumns'

/**
 * The grid is used only to create the baseline report and is not visible in the UI
 */

interface BaselineGridProps {
  apiRef: React.MutableRefObject<GridApiPremium>
}

export const BaselineGrid: FC<BaselineGridProps> = ({ apiRef }) => {
  // Include all available columns in the baseline report
  const columns = useProductListColumns({ ignoreRenderCell: true })

  // The grid is empty initially. It gets populated in the useEffect once we have all the data from Elasticsearch
  return (
    <DataGridPremium sx={{ display: 'none' }} rows={[]} columns={columns} apiRef={apiRef} getRowId={(row) => row.pk} />
  )
}
