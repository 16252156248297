import { createAsyncThunk } from '@reduxjs/toolkit'
import { AppState } from '@/store'
import { fetchAllFormulationTags, fetchPortfolioData } from './portfolioDashboard.requests'
import { estypes } from '@elastic/elasticsearch'
import { AVAILABLE_VIEWS, BASIS_TYPES, Basis, PortfolioImpactDashboardSettings } from './portfolioDashboard.types'
import { Field } from '@/constants/impactScore'
import { selectPortfolioImpactDashboardSettings } from '@/state/user'
import {
  selectContentfulCustomMetricsData,
  selectContentfulMetricTonsMetrics,
  selectContentfulProductImpactData,
} from '@/state/contentfulData'
import { selectEditableProcurementWorkspaceIds, selectEditableWorkspaces } from '@/state/workspaces'
import {
  selectActualTags,
  selectProductType,
  selectSelectedWorkspace,
  selectWildcardTags,
} from './portfolioDashboard.selectors'

// The data returned by the ES request is transformed into this structure in the initializeDashboard thunk
export interface PortfolioResults {
  aggregationsByView: AggsByView
  totals: PortfolioTotals
}

// There's an example of this data structure in the thunk code below
export interface AggsByView {
  workspaces: AggsByBasis[]
  tags: AggsByBasis[]
  categories: AggsByBasis[]
}

export interface AggsByBasis {
  key: string // Workspace, tag, or category name
  doc_count: number
  kg: Partial<Record<Field, MetricData>>
  inventories: Partial<Record<Field, MetricData>>
  sales: Partial<Record<Field, MetricData>>
}

interface MetricData {
  value: number
  count: number
  salesCount: number
  inventoryCount: number
}

type Total = Partial<Record<Field, number>>
export interface PortfolioTotals {
  kg: Total
  inventories: Total
  sales: Total
}

/** Here's an example aggregations object returned by the ES request that gets transformed here:
 * workspaces: {
 *   buckets: [
 *     {
 *       doc_count: 123,
 *       key: 'HG Innovation',
 *       'kg.raw_greenhouse_gas_impact': { value: 123 },
 *       'inventories.raw_greenhouse_gas_impact': { value: 123 },
 *       'sales.raw_greenhouse_gas_impact': { value: 123 },
 *     }
 *   ]
 * },
 * tags: {
 *   buckets: [
 *     {
 *       doc_count: 123,
 *       key: 'Sweetners',
 *       'kg.raw_greenhouse_gas_impact': { value: 123 },
 *       'inventories.raw_greenhouse_gas_impact': { value: 123 },
 *       'sales.raw_greenhouse_gas_impact': { value: 123 },
 *     }
 *   ]
 * },
 * 'total.kg.raw_greenhouse_gas_impact': { value: 123 },
 * 'total.inventories.raw_greenhouse_gas_impact': { value: 123 },
 * 'total.sales.raw_greenhouse_gas_impact': { value: 123 },
 */
export const getSelectedMetricData = createAsyncThunk<PortfolioResults, Field, { state: AppState }>(
  'portfolioDashboard/getSelectedMetricData',
  async (selectedMetric, { getState }) => {
    const state = getState()
    const editableWorkspaces = selectEditableWorkspaces(state)
    const productType = selectProductType(state)
    const editableWorkspaceIds = editableWorkspaces.map((w) => w.id)
    const editableWorkspacesNames = editableWorkspaces.map((w) => w.name)
    const procurementWorkspaceIds = selectEditableProcurementWorkspaceIds(state)
    const nonProcurementWorkspaceIds = editableWorkspaceIds.filter((id) => !procurementWorkspaceIds.includes(id))
    const selectedWorkspace = selectSelectedWorkspace(state)
    const customMetrics = selectContentfulCustomMetricsData(state)
    const actualTags = selectActualTags(state)
    const wildcardTags = selectWildcardTags(state)
    const metricTonsMetrics = selectContentfulMetricTonsMetrics(state)

    // For testing, the Configure dialog lets you select a single workspace; outside of that use all editable workspaces
    const productsWorkspaceIds = nonProcurementWorkspaceIds.find((id) => id === selectedWorkspace.id)
      ? [selectedWorkspace.id]
      : nonProcurementWorkspaceIds
    const materialsWorkspaceIds = procurementWorkspaceIds.find((id) => id === selectedWorkspace.id)
      ? [selectedWorkspace.id]
      : procurementWorkspaceIds

    const selectedCustomMetric = customMetrics.find((metric) => metric.key === selectedMetric)

    const searchParams = {
      workspaceIds: productType === 'products' ? productsWorkspaceIds : materialsWorkspaceIds,
      actualTags: actualTags,
      wildcardTags: wildcardTags,
      metrics: [selectedMetric],
      customMetrics: selectedCustomMetric ? [selectedCustomMetric] : [],
    }
    const { aggregations } = await fetchPortfolioData(searchParams)

    aggregations.workspaces.buckets = aggregations.workspaces.buckets.filter((ws) =>
      editableWorkspacesNames.includes(ws.key)
    )

    /**
     * `aggregationsByView` after transformation:
     * workspaces: [
     *   {
     *     doc_count: 123,
     *     key: 'HG Innovation',
     *     kg: {
     *       raw_greenhouse_gas_impact': { value: 123, count: 55 }
     *     },
     *     inventories: {
     *       raw_greenhouse_gas_impact': { value: 123, count: 55 }
     *     },
     *     sales: {
     *       raw_greenhouse_gas_impact': { value: 123, count: 55 }
     *     }
     *   }
     * ],
     * tags: [...], // SAME AS ABOVE WHERE KEY IS TAG NAME
     * categories: [...], // SAME AS ABOVE WHERE KEY IS MANUFACTURING TYPE
     */
    const aggregationsByView: AggsByView = AVAILABLE_VIEWS.reduce((acc, view) => {
      const viewAggs = aggregations[view] as estypes.AggregationsTermsAggregateBase<any>
      return {
        ...acc,
        [view]: viewAggs
          ? viewAggs.buckets.map((bucket: estypes.AggregationsMultiTermsBucket) =>
              Object.entries(bucket).reduce(
                (views, [key, value]) => {
                  // `doc_count` and `key` are set in the reduce's initial value
                  if (key === 'doc_count' || key === 'key') return views

                  // Extract the basis and metric name from the key, and confirm the basis is valid
                  const [basis, metric] = key.split('.')
                  if (!BASIS_TYPES.includes(basis as Basis)) return views // This filters out the count aggs as well

                  return {
                    ...views,
                    [basis]: {
                      ...views[basis],
                      [metric as Field]: {
                        // eslint-disable-next-line @typescript-eslint/no-use-before-define
                        value: getMetricValue(basis, metric as Field, value, metricTonsMetrics),
                        // `count` is the number of products with a non-null value for the metric
                        count: (bucket[`count.${metric}`] as estypes.AggregationsValueCountAggregate)?.value || 0,
                        // `salesVolumeCount` and `inventoryVolumeCount` are the number of products with a sales/inventory volume specified
                        // AND a non-null value for the metric
                        salesCount: (bucket[`count.sales.${metric}`] as any)?.doc_count || 0,
                        inventoryCount: (bucket[`count.inventories.${metric}`] as any)?.doc_count || 0,
                      },
                    },
                  }
                },
                {
                  doc_count: bucket.doc_count,
                  key: bucket.key,
                }
              )
            )
          : [],
      }
    }, {} as AggsByView)

    /**
     * `totals` after transformation:
     * {
     *   "kg": {
     *       "raw_greenhouse_gas_impact": 6929.899818469974,
     *       ...
     *   },
     *   "inventories": {
     *       "raw_greenhouse_gas_impact": 3861383.018313325,
     *       ...
     *   },
     *   "sales": {
     *       "raw_greenhouse_gas_impact": 112800129747.37616,
     *      ...
     *   }
     * }
     */
    const totals = Object.entries(aggregations).reduce((acc, [key, value]) => {
      if (key.startsWith('total.')) {
        const [basis, metric] = key.replace('total.', '').split('.')
        if (!BASIS_TYPES.includes(basis as Basis)) return acc
        return {
          ...acc,
          [basis]: {
            ...acc[basis],
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            [metric as Field]: getMetricValue(basis, metric as Field, value, metricTonsMetrics),
          },
        }
      }
      return acc
    }, {} as PortfolioTotals)

    return {
      aggregationsByView,
      totals,
    }
  }
)

const getMetricValue = (
  basis: string,
  metric: Field,
  value: string | number | estypes.AggregationsAggregate | (string | number)[],
  metricTonsMetrics: Field[]
) => {
  const metricValue = typeof value === 'object' ? (value as estypes.AggregationsSumAggregate).value : +value

  // For metrics specified in metric tons, sales volume (kg) * base value is 1000x too large, so need to adjust
  // This applies at the product level as well as the portfolio total level
  if (basis === 'sales' && metricTonsMetrics.includes(metric)) {
    return metricValue / 1000
  }
  return metricValue
}

export const fetchTags = createAsyncThunk<string[], void, { state: AppState }>(
  'portfolioDashboard/fetchTags',
  async (_, { dispatch: __, getState }) => {
    const editableWorkspaces = selectEditableWorkspaces(getState())
    const editableWorkspaceIds = editableWorkspaces.map((w) => w.id)

    return await fetchAllFormulationTags(editableWorkspaceIds)
  }
)

export interface InitializationResults {
  dashboardSettings: PortfolioImpactDashboardSettings
  availableMetrics: Field[]
}

export const initializeDashboard = createAsyncThunk<InitializationResults, void, { state: AppState }>(
  'portfolioDashboard/initializeDashboard',
  async (_, { dispatch, getState }) => {
    const allMetrics = selectContentfulProductImpactData(getState())
    const dashboardSettings = selectPortfolioImpactDashboardSettings(getState())
    const customMetrics = selectContentfulCustomMetricsData(getState())

    const availableMetrics = allMetrics.filter((metric) => metric.units).map((metric) => metric.key)

    // The saved metric could be a standard metric or a custom metric, or a metric that's no longer available
    const selectedMetric =
      availableMetrics.includes(dashboardSettings?.metric) ||
      customMetrics.find((metric) => metric.key === dashboardSettings?.metric)
        ? dashboardSettings.metric
        : availableMetrics[0]

    dispatch(getSelectedMetricData(selectedMetric))

    return {
      dashboardSettings: {
        ...dashboardSettings,
        metric: selectedMetric,
      },
      availableMetrics,
    }
  }
)
