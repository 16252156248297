import { fetchBulkElasticV2, fetchElasticV2 } from '@/api'
import { estypes } from '@elastic/elasticsearch'
import { BASES_AND_TOKENS_SEARCH_PATH, BASES_SEARCH_PATH, FORMULATIONS_SEARCH_PATH } from '@/constants/config'
import { unwrapElasticResults } from './unwrapElasticResults'
import { ElasticsearchIngredientHit } from '@/records'
import { ProductsBoolQuery } from '@/api/elastic/productsEsRepo'

const ingredientSourceFields = [
  'id',
  'pk',
  'name',
  'default_origin_location',
  'inventories.internal_id',
  'inventories.workspace_id',
  'material_types',
  'workspaces',
  'ingredients.origin_location_id',
  'impact_score.cf_ftm_gate_ct_verified_impact',
  'brand.name',
  'locked_claims_timestamp',
  'workflow_tags',
  'input_weight_unit',
]

const russianDoll: estypes.QueryDslQueryContainer = {
  bool: {
    should: [
      {
        match_phrase: {
          name: 'russian doll',
        },
      },
      {
        match_phrase: {
          name: 'not relevant',
        },
      },
    ],
    minimum_should_match: 1,
  },
}

export function getWorkspaceIdsToQuery(
  ownWorkspacesIds: (number | string)[],
  defaultWorkspaceIds: number[],
  canViewPublicIngredients: boolean,
  workspaceIds: (number | string)[] = null
): (number | string)[] {
  if (workspaceIds) {
    return canViewPublicIngredients ? [...workspaceIds, ...defaultWorkspaceIds] : workspaceIds
  }
  return canViewPublicIngredients ? [...ownWorkspacesIds, ...defaultWorkspaceIds] : ownWorkspacesIds
}

export function buildShouldWorkspaceIds(
  ownWorkspacesIds: number[] | string[],
  defaultWorkspaceIds: number[],
  canViewPublicIngredients: boolean,
  workspaceIds: number[] | string[] = null
) {
  const shouldWorkspaceIds: estypes.QueryDslQueryContainer[] = [
    {
      bool: {
        must: [
          {
            terms: {
              'ingredients.workspaces.id': getWorkspaceIdsToQuery(
                ownWorkspacesIds,
                defaultWorkspaceIds,
                canViewPublicIngredients,
                workspaceIds
              ),
            },
          },
        ],
      },
    },
  ]

  if (canViewPublicIngredients) {
    shouldWorkspaceIds.push({
      bool: {
        must_not: {
          exists: {
            field: 'ingredients.workspaces.id',
          },
        },
      },
    })
  }

  return shouldWorkspaceIds
}

export function buildIngredientsQueryFromIds(ids: string[]): estypes.QueryDslQueryContainer {
  return {
    bool: {
      must: [{ match_phrase: { workflow_tags: { query: 'FOOD' } } }, { terms: { id: ids } }],
      must_not: [russianDoll],
    },
  }
}

interface FetchBaseByIds {
  ids: string[]
  defaultWorkspaceIds: number[]
  ownWorkspacesIds: number[]
  canViewPublicIngredients: boolean
}

export const fetchBaseByIds = async ({
  ids,
  defaultWorkspaceIds,
  ownWorkspacesIds = [],
  canViewPublicIngredients = false,
}: FetchBaseByIds) => {
  let ingredientsQuery = buildIngredientsQueryFromIds(ids)

  const shouldWorkspaceIds = buildShouldWorkspaceIds(ownWorkspacesIds, defaultWorkspaceIds, canViewPublicIngredients)

  ingredientsQuery.bool.filter = [
    {
      nested: {
        path: 'ingredients',
        query: {
          bool: {
            should: shouldWorkspaceIds,
          },
        },
      },
    },
  ]

  const should = [ingredientsQuery]

  const results = await fetchElasticV2({
    url: BASES_SEARCH_PATH,
    body: {
      size: 20,
      highlight: {
        pre_tags: [''],
        post_tags: [''],
        fragment_size: 200,
        no_match_size: 0,
        fields: {
          name: { number_of_fragments: 1 },
          synonyms: { number_of_fragments: 1 },
        },
      },
      query: {
        bool: {
          should,
        },
      },
    },
  })

  return unwrapElasticResults(results)
}

export interface FetchIngredientsParams {
  filters?: {
    defaultWorkspaceIds: number[]
    search?: string
    workspaceIds?: number[] | string[]
    ownWorkspacesIds?: number[] | string[]
    canViewPublicIngredients?: boolean
    labels?: string[]
    statuses?: string[]
    originLocationStatuses?: string[]
  }
  onlyIngredients?: boolean
  size?: number
  cancelPrevious?: boolean
}
// These can be really slow requests, no need to keep sending requests when
// a user makes a change and the initial request is no longer valid
let abortController = new AbortController()
export const fetchIngredients = async ({
  filters = { defaultWorkspaceIds: [] },
  onlyIngredients = false,
  size,
  cancelPrevious = false,
}: FetchIngredientsParams) => {
  if (cancelPrevious) {
    abortController.abort() // Cancel the previous request
    abortController = new AbortController()
  }
  const {
    defaultWorkspaceIds,
    search,
    workspaceIds,
    ownWorkspacesIds = [],
    canViewPublicIngredients = false,
    labels = [],
    statuses,
    originLocationStatuses = [],
  } = filters

  if (!search) {
    return []
  }

  let ingredientsQuery: estypes.QueryDslQueryContainer = search
    ? {
        bool: {
          should: [
            { match: { name: { query: search, boost: 10 } } },
            { match: { synonyms: search } },
            { match: { 'name.autocomplete': { query: search } } },
            { match: { 'synonyms.autocomplete': { query: search } } },
            { fuzzy: { synonyms: { value: search, boost: 2 } } },
            {
              span_first: {
                match: {
                  span_term: { name: search },
                },
                end: 1,
              },
            },
          ],
          minimum_should_match: 1,
          must: [{ match_phrase: { workflow_tags: { query: 'FOOD' } } }, { match: { workflow_status: 'published' } }],
          must_not: [russianDoll],
        },
      }
    : { bool: {} }

  const shouldWorkspaceIds = buildShouldWorkspaceIds(
    ownWorkspacesIds,
    defaultWorkspaceIds,
    canViewPublicIngredients,
    workspaceIds
  )

  ingredientsQuery.bool.filter = [
    {
      nested: {
        path: 'ingredients',
        query: {
          bool: {
            should: shouldWorkspaceIds,
            minimum_should_match: 1,
          },
        },
      },
    },
  ]

  if (originLocationStatuses.length > 0) {
    ingredientsQuery.bool.filter.push({
      terms: {
        'default_origin_location.workflow_status': ['published'],
      },
    })
  }

  if (labels.length > 0) {
    ingredientsQuery.bool.filter.push({
      bool: {
        should: labels.map((label) => ({
          match_phrase: { labels: label },
        })),
        minimum_should_match: 1,
      },
    })
  }

  const should: estypes.QueryDslQueryContainer[] = [ingredientsQuery]

  if (!onlyIngredients) {
    const tokensQuery: estypes.QueryDslQueryContainer = {
      bool: {
        should: [
          { match: { input_text: { query: search, boost: 20 } } },
          { match: { 'input_text.autocomplete': { query: search } } },
          { fuzzy: { input_text: { value: search, boost: 2 } } },
          {
            span_first: {
              match: {
                span_term: { input_text: search },
              },
              end: 1,
            },
          },
        ],
        must_not: [russianDoll],
        filter: [],
      },
    }
    const filter = tokensQuery.bool.filter as estypes.QueryDslQueryContainer[]
    if (statuses) {
      filter.push({
        terms: {
          formulation_status: statuses,
        },
      })
    }
    if (workspaceIds) {
      filter.push({
        terms: {
          'workspace.id': workspaceIds,
        },
      })
    } else if (!canViewPublicIngredients) {
      filter.push({
        terms: {
          'workspace.id': ownWorkspacesIds,
        },
      })
    }
    should.push(tokensQuery)
  }

  let searchPath = BASES_SEARCH_PATH
  if (!onlyIngredients) {
    searchPath = BASES_AND_TOKENS_SEARCH_PATH
  }

  const body = {
    _source: ingredientSourceFields,
    highlight: {
      pre_tags: [''],
      post_tags: [''],
      fragment_size: 200,
      no_match_size: 0,
      fields: {
        name: { number_of_fragments: 1 },
        synonyms: { number_of_fragments: 1 },
      },
    },
    query: {
      bool: {
        should,
      },
    },
  } as estypes.SearchRequest['body']

  if (size) {
    const results = await fetchElasticV2({
      url: searchPath,
      body: { ...body, size },
      signal: cancelPrevious ? abortController.signal : undefined,
    })
    return unwrapElasticResults(results) as ElasticsearchIngredientHit[]
  }
  const results = await fetchBulkElasticV2({
    url: searchPath,
    body,
    signal: cancelPrevious ? abortController.signal : undefined,
  })
  return results.hits as ElasticsearchIngredientHit[]
}

export interface FetchProductIngredientsParams {
  filters?: {
    search?: string
    ownWorkspacesIds?: number[] | string[]
    statuses?: string[]
  }
}
export const fetchProductIngredients = async ({ filters = {} }: FetchProductIngredientsParams) => {
  const { search, ownWorkspacesIds = [], statuses } = filters
  if (!search) {
    return []
  }
  let productsQuery: ProductsBoolQuery = {
    filter: [
      {
        terms: {
          'workspaces.id': ownWorkspacesIds,
        },
      },
    ],
  }
  if (statuses) {
    const filter = productsQuery.filter as estypes.QueryDslQueryContainer[]
    filter.push({
      terms: {
        formulation_status: statuses,
      },
    })
  }

  if (search) {
    productsQuery.should = [{ match: { name: { query: search, boost: 10 } } }]
    productsQuery.must = [
      {
        multi_match: {
          query: search,
          type: 'phrase_prefix',
          fields: ['name', 'inventories.internal_id', 'upc'],
        },
      },
    ]
  }
  const resultsProducts = await fetchElasticV2({
    url: FORMULATIONS_SEARCH_PATH,
    body: {
      size: 20,
      _source: ingredientSourceFields,
      highlight: {
        pre_tags: [''],
        post_tags: [''],
        fragment_size: 200,
        no_match_size: 0,
        fields: {
          name: { number_of_fragments: 1 },
          synonyms: { number_of_fragments: 1 },
        },
      },
      query: {
        bool: {
          should: [{ bool: productsQuery }],
        },
      },
    },
  })

  return unwrapElasticResults(resultsProducts)
}
