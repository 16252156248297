import React, { forwardRef } from 'react'
import { createUseStyles } from 'react-jss'
import { PDFExport } from '@progress/kendo-react-pdf'
import { Logo } from '@/components'
import { RichText } from '@/contentfully'
import { useExportStyles } from '@/styles'
import { PageFooter } from './PageFooter'
import { useContentfulDocument } from '@/contentfully/hooks'

const useStyles = createUseStyles({
  logo: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
})

export const ExportMethodology = forwardRef<PDFExport>((_props, ref) => {
  const exportClasses = useExportStyles()
  const classes = useStyles()
  const content = useContentfulDocument('scope-3-methodology')

  if (!content) {
    return null
  }

  return (
    <div className={exportClasses.outerContainer}>
      <PDFExport ref={ref} paperSize="A4" margin="2cm" fileName="HowGood Scope 3 Methodology" pageTemplate={PageFooter}>
        <div className={exportClasses.container}>
          <div className={classes.logo}>
            <Logo />
          </div>
          <RichText document={content.document} />
          <RichText document={content.sources} />
          <RichText document={content.versionHistory} />
        </div>
      </PDFExport>
    </div>
  )
})

ExportMethodology.displayName = 'ExportMethodology'
