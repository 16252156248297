import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Stack, Text } from '@howgood/design'
import { selectDisplayedProduct } from '@/state/productOverview'
import { selectPageView, DRAFT } from '@/state/pageSettings'
import { VendorDetails } from './VendorDetails'
import { BeverageTemperature } from './BeverageTemperature'

export const ProductData: FC = () => {
  const product = useSelector(selectDisplayedProduct)
  const view = useSelector(selectPageView)
  const isBeverage = product.agribalyze_category?.is_beverage

  return (
    <Stack gap={view === DRAFT ? 1 : undefined}>
      {product.upc && <Text>UPC/GTIN: {product.upc}</Text>}
      {isBeverage && <BeverageTemperature />}
      <VendorDetails />
    </Stack>
  )
}
