import React, { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { faAdd, faSearch } from '@fortawesome/pro-solid-svg-icons'
import { Autocomplete, Button, Box, Stack, TextField, Icon } from '@howgood/design'

import { selectDisplayedTags, updateDraftInventoryTags } from '@/state/productOverview'
import { selectFormulationTags } from '@/state/formulationTags'
import { FormulationTag } from '@/api'

export const AddTags: FC = () => {
  const dispatch = useDispatch()
  const allFormulationTags = useSelector(selectFormulationTags)
  const productTags = useSelector(selectDisplayedTags)
  const [isTagSearchOpen, setIsTagSearchOpen] = useState(false)

  const handleAddTag = (_event: React.SyntheticEvent<Element, Event>, tag: FormulationTag | string) => {
    const tagObj = typeof tag === 'string' ? { name: tag } : tag
    setIsTagSearchOpen(false)
    if (tag) {
      dispatch(updateDraftInventoryTags([...productTags, tagObj]))
    }
  }

  return (
    <Box width="100%" minWidth={230}>
      {isTagSearchOpen ? (
        <Autocomplete
          id="tags-autocomplete"
          aria-label="Product tags"
          options={allFormulationTags.filter((tag) => !productTags.find((prodTag) => prodTag.name === tag.name))}
          defaultValue={''}
          freeSolo
          onBlur={() => setIsTagSearchOpen(false)}
          onChange={handleAddTag}
          getOptionLabel={(option) => option.name || ''}
          renderInput={(params) => (
            <Stack direction="row" alignItems="center" justifyContent="center" gap={1}>
              <Icon fontSize={14} icon={faSearch} />
              <TextField
                {...params}
                variant="standard"
                data-testid="tag-input"
                autoFocus
                placeholder="Start typing a tag name..."
              />
            </Stack>
          )}
          fullWidth
        />
      ) : (
        <Button
          id="add-tag-button"
          variant="text"
          size="small"
          aria-label="Add tag button"
          startIcon={faAdd}
          onClick={() => setIsTagSearchOpen(true)}
        >
          Add tags
        </Button>
      )}
    </Box>
  )
}
