import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Autocomplete, Chip, TextField } from '@howgood/design'

import { IProductStandard } from '@/records'
import { selectProductCertificationStandards } from '@/state/productStandards'
import { GridItemTitle } from '@/features/ProductPage/panels/ProductInfo/GridItemTitle'
import { panelStyles } from '@/features/ProductPage/panels/ProductInfo/panelStyles'

interface Props {
  onChange: (selected: IProductStandard[]) => void
  selectedStandards: IProductStandard[]
  placeholder?: string
  disabled?: boolean
}

export const StandardsSelector: FC<Props> = ({
  onChange,
  selectedStandards,
  placeholder = 'Select standards that apply across entire formula',
  disabled = false,
}) => {
  const options = useSelector(selectProductCertificationStandards)

  return (
    <>
      <GridItemTitle
        title="Standards"
        tooltipText="Formula-level standards inform standards and impact scores for all relevant ingredients"
      />
      <Autocomplete
        id="product-standards-autocomplete"
        aria-label="Product standards"
        disabled={disabled}
        multiple
        value={selectedStandards}
        onChange={(_e, selectedOptions) => onChange(selectedOptions)}
        options={options}
        getOptionLabel={(standard) =>
          selectedStandards.find((item) => item.id === standard.id) ? `${standard.title} \u2713` : standard.title
        }
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              id={`standard-chip-${index}`}
              key={option.id}
              label={option.title}
              variant="outlined"
              {...getTagProps({ index })}
            />
          ))
        }
        fullWidth
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={selectedStandards?.length ? '' : placeholder}
            sx={panelStyles.autocomplete}
          />
        )}
        ListboxProps={{ id: 'product-standards-menu' }}
      />
    </>
  )
}
