import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NumericInput, Stack } from '@howgood/design'
import { selectProductHitById, updateProductScenarioData } from '@/state/products'
import { selectCurrentScenarioId, selectCurrentInitiativeGoalMetrics } from '@/state/initiatives/initiatives.selectors'
import { addMessage } from '@/state/messages'
import { ScenarioProductAttributes } from '@/records'

interface ProductGoalRendererProps {
  productId: number
  field: string
}
export const DEFAULT_SCENARIO_DATA: ScenarioProductAttributes = { goals: {}, mt_per_year: null }

export const ProductGoalRenderer: FC<ProductGoalRendererProps> = ({ productId, field }) => {
  const dispatch = useDispatch()
  const metricsWithScenarioGoals = useSelector(selectCurrentInitiativeGoalMetrics)
  const currentScenario = useSelector(selectCurrentScenarioId)
  const product = useSelector(selectProductHitById(productId))

  // Display renderer only if scenario view and metric has a goal at workspace level
  if (!currentScenario || !product || !metricsWithScenarioGoals.map((g) => g.value).includes(field)) {
    return null
  }

  const scenarioProductData = product.scenarios?.find((s) => s.id === currentScenario) || {
    product: DEFAULT_SCENARIO_DATA,
  }
  const goals = scenarioProductData.product?.goals || {}
  const metricGoal = goals[field] || null

  const onUpdateGoal = (updatedGoal: number) => {
    if (metricGoal === updatedGoal) {
      return
    }
    if (product?.inventories) {
      dispatch(
        updateProductScenarioData({
          product,
          data: {
            // include existing product data so we do not overwrite anything
            ...scenarioProductData.product,
            goals: {
              ...goals,
              [field]: updatedGoal,
            },
          },
          scenarioId: currentScenario,
        })
      )
    }
  }

  return (
    <Stack>
      <NumericInput
        id={`goal-field-${product.id}-${field}`}
        ariaLabel={`Goal for ${product.name} ${field}`}
        numericValue={metricGoal}
        onUpdateValue={onUpdateGoal}
        onBadValue={(value) => {
          dispatch(addMessage({ severity: 'error', message: `${value} is not a valid numeric goal value.` }))
        }}
        size="small"
      />
    </Stack>
  )
}
