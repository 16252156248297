import React, { forwardRef } from 'react'
import MuiPopover, { PopoverProps as MuiPopoverProps } from '@mui/material/Popover'

interface PopoverProps extends Omit<MuiPopoverProps, 'className'> {
  ['aria-label']?: string
  ['data-pendo-id']?: string
  ['data-testid']?: string
}

export const Popover = forwardRef<HTMLDivElement, PopoverProps>(
  (
    { ['aria-label']: ariaLabel, ['data-pendo-id']: dataPendoId, ['data-testid']: dataTestId, children, ...rest },
    ref
  ) => {
    return (
      <MuiPopover ref={ref} aria-label={ariaLabel} data-pendo-id={dataPendoId} data-testid={dataTestId} {...rest}>
        {children}
      </MuiPopover>
    )
  }
)

Popover.displayName = 'Popover'
