import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button } from '@howgood/design'
import { faStore } from '@fortawesome/pro-solid-svg-icons'
import { RequestVendorDataDialog } from './RequestVendorDataDialog'
import { RequestMultipleVendorDataDialog } from './RequestMultipleVendorDataDialog'
import { selectSplitTreatmentEnabled } from '@/state/splitio'

export const RequestVendorData: FC = () => {
  const [openDialog, setOpenDialog] = useState(false)
  const hideRequestVendorData = useSelector(selectSplitTreatmentEnabled('hide-request-vendor-data'))
  const collectDataMultipleVendors = useSelector(selectSplitTreatmentEnabled('collect_data_multiple_vendors'))

  const RequestVendorDataDialogCmp = collectDataMultipleVendors
    ? RequestMultipleVendorDataDialog
    : RequestVendorDataDialog

  if (hideRequestVendorData) {
    return null
  }

  return (
    <>
      <Button
        data-testid="request-vendor-data-button"
        data-pendo-id="RequestVendorData"
        size="small"
        startIcon={faStore}
        onClick={() => setOpenDialog(true)}
      >
        Request vendor data
      </Button>
      {openDialog && <RequestVendorDataDialogCmp onClose={() => setOpenDialog(false)} />}
    </>
  )
}
