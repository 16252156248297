import React, { FC } from 'react'
import { CircularProgress, Icon, MenuItem, Select, SelectChangeEvent, Stack, Text } from '@howgood/design'
import { GridRenderCellParams } from '@mui/x-data-grid-pro'
import { VendorInfo, UNKNOWN_VENDOR_OPTION } from '@/records'
import { selectIsUpdatingVendor, selectVendorJustUpdated, updateVendorByBrand } from '@/state/vendorManagement'
import { useDispatch, useSelector } from 'react-redux'
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons'

const priorityMap = {
  1: 'High',
  2: 'Medium',
  3: 'Low',
  1000: 'No Priority Set', // Value as specified on the backend
}

interface PriorityCellProps extends GridRenderCellParams {
  row: VendorInfo
}

export const PriorityCell: FC<PriorityCellProps> = ({ row: vendor }) => {
  const vendorBeingUpdated = useSelector(selectIsUpdatingVendor) // null if nothing is being updated
  const vendorJustUpdated = useSelector(selectVendorJustUpdated) // Set for a few seconds after a change; null otherwise
  const dispatch = useDispatch()

  // Don't display a priority for the unknown vendors row
  if (vendor.brandId === UNKNOWN_VENDOR_OPTION.value) {
    return null
  }

  if (vendorBeingUpdated === vendor.brandId) {
    return (
      <Stack direction="row" alignItems="center" gap={1}>
        <CircularProgress size={14} />
        <Text variant="body2">Updating vendor...</Text>
      </Stack>
    )
  }

  const handleChange = (event: SelectChangeEvent) => {
    const newPriority = +event.target.value
    if (newPriority !== vendor.priority) {
      dispatch(updateVendorByBrand({ brandId: vendor.brandId as number, vendorInfo: { priority: newPriority } }))
    }
  }

  return (
    // Make the Select wide enough to fill the grid cell without the padding
    <Stack width="calc(100% + 12px)" ml={-1}>
      <Select
        id="vendor-priority-select"
        value={vendor.priority || 1000}
        onChange={handleChange}
        color="secondary"
        renderValue={(value: number) => (
          <Stack direction="row" alignItems="center" gap={1} overflow="hidden">
            <Text variant="body2">{priorityMap[value]}</Text>
            {vendorJustUpdated === vendor.brandId && <Icon icon={faCheckCircle} />}
          </Stack>
        )}
        sx={{ fontSize: 14, borderRadius: 0 }}
      >
        {Object.entries(priorityMap).map(([key, value]) => (
          <MenuItem key={key} value={key}>
            <Text variant="body2">{value}</Text>
          </MenuItem>
        ))}
      </Select>
    </Stack>
  )
}
