import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DataGridPremium, GridCellParams, GridRowParams, GridColumnResizeParams } from '@mui/x-data-grid-premium'
import { Box, CircularProgress, Text, Stack, Icon } from '@howgood/design'

import { DRAFT, selectPageView } from '@/state/pageSettings'
import { selectIsRecipeLoading } from '@/state/recipe'
import { selectGridSettings, setGridSettings } from '@/state/preferences'
import { Field, PERMISSIONS_TO_FIELDS_MAP, PRODUCT_INGREDIENT_HEATMAP_PERMISSIONS } from '@/constants/impactScore'
import { Toolbar } from './Toolbar/Toolbar'
import { GridToolbar } from './Toolbar/GridToolbar'
import { ScoreInputs } from '../../util/getScore'
import { useGridColumns } from './useGridColumns'
import { useGridRows } from './useGridRows'
import { faDownload } from '@fortawesome/pro-regular-svg-icons'
import { selectIsSupplierConnect } from '@/state/organization'

export const IngredientsGrid: FC = () => {
  const gridSettings = useSelector(selectGridSettings)
  const dispatch = useDispatch()

  const view = useSelector(selectPageView)
  const columns = useGridColumns()
  const rows = useGridRows()
  const isLoading = useSelector(selectIsRecipeLoading)
  const isSupplierConnect = useSelector(selectIsSupplierConnect)

  const impactScoreFields = PRODUCT_INGREDIENT_HEATMAP_PERMISSIONS.map(
    (permission) => PERMISSIONS_TO_FIELDS_MAP[permission]
  )

  const handleColumnWidthChange = (params: GridColumnResizeParams) => {
    if (params.colDef.field !== 'weight') {
      dispatch(
        setGridSettings({
          ...gridSettings,
          colWidths: {
            ...gridSettings.colWidths,
            [params.colDef.field]: params.width,
          },
        })
      )
    }
  }

  return (
    <>
      <Toolbar />
      <Box
        id="ingredients-grid-wrapper" // IMPORTANT - used by useGridWidth
        width="100%"
        maxHeight={500}
        overflow="auto"
      >
        <DataGridPremium
          rows={rows}
          columns={columns}
          autoHeight
          initialState={{
            density: 'compact',
            pinnedColumns: {
              left: [
                'delete_replace',
                'ingredient',
                'sourcing_location',
                'sourcing_standards',
                'processing_location',
                'vendor',
                'up_down',
                'weight',
              ],
            },
          }}
          disableRowSelectionOnClick
          disableColumnMenu
          disableColumnReorder
          columnVisibilityModel={{
            delete_replace: view === DRAFT,
            up_down: view === DRAFT,
          }}
          onColumnWidthChange={handleColumnWidthChange}
          hideFooter={rows.length <= 100}
          sx={{
            border: 'none',
            '& .MuiDataGrid-main': {
              overflow: 'unset',
            },
            '& .MuiDataGrid-columnHeaderTitleContainerContent': {
              width: '100%',
            },
            '& .MuiDataGrid-columnHeaders': {
              borderBottom: 0,
              position: 'sticky',
              top: -2,
              zIndex: 2,
              background: 'white',
              display: rows.length ? 'flex' : 'none',
            },
            // Prevent the cell outline from showing when you click in a header cell
            '& .MuiDataGrid-columnHeader': {
              '&:focus-within': { outline: 'none' },
            },
            '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: 400,
              fontSize: 15,
            },
            '& .MuiDataGrid-cell': {
              '&.impact': {
                p: 0,
                borderRight: 2,
                borderColor: 'white',
                '& .MuiTypography-root': { width: '100%', textAlign: 'center' },
              },
            },
            '& .MuiDataGrid-cell:focus': { outline: 'none' },
            '& .MuiDataGrid-cell:focus-within': { outline: 'none' },
          }}
          slots={{
            toolbar: !isSupplierConnect ? GridToolbar : null,
            exportIcon: () => <Icon icon={faDownload} />,
            noRowsOverlay: () => (
              <Stack alignItems="center" justifyContent="center">
                {isLoading ? (
                  <>
                    <CircularProgress size={24} />
                    <Box mt={2}>
                      <Text>Loading ingredient information</Text>
                    </Box>
                  </>
                ) : (
                  <Text>Add at least one ingredient</Text>
                )}
              </Stack>
            ),
          }}
          getRowClassName={(params: GridRowParams) => {
            return params.row.isTopLevel ? 'top-level' : ''
          }}
          getCellClassName={(params: GridCellParams<ScoreInputs>) => {
            if (impactScoreFields.includes(params.field as Field)) {
              return 'impact'
            }
            return ''
          }}
        />
      </Box>
    </>
  )
}
