import { MaterialType } from '@/api'
import { WorkspaceType } from './Workspace'

interface ElasticsearchSourceInventory {
  buyer_name: string
  buyer_role: string
  formulation_tags: string[]
  id: number
  internal_id: string
  mt_per_year: number
  packaging_description: string
  workspace_id: number
}

type ElasticsearchIngredientSource = Readonly<{
  [key: string]: any
  name: string
  id: number
  pk?: number
  default_origin_location: {
    id: number
    region_name: string
    region_id: number
  }
  inventories?: ElasticsearchSourceInventory[]
  material_types?: MaterialType[]
  ingredients?: { origin_location_id: number }[]
  workspaces?: { id: number; name: string; workspace_type: WorkspaceType }[]
}>

export interface IngredientFilterSearchOption extends ElasticsearchIngredientSource {
  value: number
  label: string
}

export interface ElasticsearchIngredientHit extends Readonly<Record<string, any>> {
  _id?: string
  _source?: ElasticsearchIngredientSource
}

export class IngredientSearchOption {
  /**
   * An option used in the Ingredients autocomplete select, created using a single `hit`
   * from an Elasticsearch request.
   */

  raw: ElasticsearchIngredientHit
  type: string
  query: string

  constructor(hit: ElasticsearchIngredientHit, query: string = '') {
    this.raw = hit
    this.type = this.raw._index?.includes('ingredient_tokens')
      ? 'token'
      : this.raw._index?.includes('products')
      ? 'product'
      : 'ingredient'
    this.query = query || ''
  }

  get label() {
    return this.raw._source?.name
  }

  get value() {
    return this.raw._id
  }

  get isProduct() {
    return this.type === 'product'
  }
}
