import React, { FC, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { Alert, Stack, theme } from '@howgood/design'
import { Field } from '@/constants/impactScore'
import { selectContentfulImpactHistoryAttributesData, selectContentfulMetricByKey } from '@/state/contentfulData'
import { selectImpactHistory } from '@/state/impactHistory'
import { ImpactHistoryChartTooltip } from './ImpactHistoryChartTooltip'
import { ImpactHistoryData } from './ImpactHistory.types'
import { selectIsVendors } from '@/state/router'

interface Props {
  metricKey: Field
}

export const ImpactHistoryChart: FC<Props> = ({ metricKey }) => {
  const impactHistory = useSelector(selectImpactHistory)
  const metric = useSelector(selectContentfulMetricByKey(metricKey))
  const attributeData = useSelector(selectContentfulImpactHistoryAttributesData)
  const isVendorsPage = useSelector(selectIsVendors)

  const title = metric?.title === 'Carbon Footprint' ? metric?.subtitle : metric?.title

  const attributes = useMemo(() => {
    if (!attributeData || !impactHistory) return []
    return attributeData.map((attribute) => ({
      key: attribute.key,
      title: attribute.title,
      values: impactHistory[attribute.key],
    }))
  }, [attributeData, impactHistory])

  const data = useMemo<ImpactHistoryData[]>(() => {
    const metricValues = impactHistory?.[metricKey]
    if (!metricValues) return []
    const noValues = Object.values(metricValues).findIndex((score) => score !== null) === -1
    if (noValues) return []
    return Object.entries(metricValues)
      .map(([date, score]) => ({
        date,
        score,
        title,
        attributes,
        units: metric?.units,
        valuesMap: metric?.valuesMap,
        retailRating: impactHistory?.retail_rating_impact?.[date],
      }))
      .reverse() // db returns newest scores first
  }, [impactHistory, metricKey, metric, title, attributes])

  if (data.length === 0) {
    return (
      <Alert data-testid="impact-history-info" severity="info">
        No historical data available for this metric ({title})
      </Alert>
    )
  }

  return (
    <Stack data-testid="impact-history-chart" height={300}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={data}
          // offset y axis width to make space for axis label
          margin={{ left: -50 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="date"
            angle={-35}
            fontSize={12}
            textAnchor="end"
            tickFormatter={(date) => new Date(date).toLocaleDateString()}
            height={50}
          />
          <YAxis width={120} label={{ value: metric?.units, angle: -90, position: 'center' }} />
          <Line type="monotone" dataKey="score" stroke={theme.palette.primary.main} />
          {!isVendorsPage && <Tooltip content={<ImpactHistoryChartTooltip />} />}
        </LineChart>
      </ResponsiveContainer>
    </Stack>
  )
}
