import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  ListSubheader,
  Stack,
  Icon,
} from '@howgood/design'
import {
  AVAILABLE_VIEWS,
  View,
  selectView,
  setView,
  selectSelectedMetric,
  getSelectedMetricData,
  ProductType,
  selectProductType,
  setProductType,
} from '../state/portfolioDashboard'
import { faCube, faCubes } from '@fortawesome/pro-solid-svg-icons'

export const optionsMap: Record<View, string> = {
  tags: 'Tags',
  categories: 'Sales Categories',
  workspaces: 'Workspaces',
}

interface ViewSubheaderProps {
  type: ProductType
}
const ViewSubheader: FC<ViewSubheaderProps> = ({ type }) => {
  return (
    <ListSubheader data-testid={`${type}-subheader`}>
      <Stack flexDirection="row" gap={0.5}>
        <Icon data-testid={`${type}-subheader-icon`} icon={type === 'materials' ? faCubes : faCube} size="xs" />
        {type === 'materials' ? 'Materials' : 'Products'}
      </Stack>
    </ListSubheader>
  )
}

export const ViewSelect: FC = () => {
  const dispatch = useDispatch()
  const productType = useSelector(selectProductType)
  const view = useSelector(selectView)
  const selectedMetric = useSelector(selectSelectedMetric)

  const handleChange = (event: SelectChangeEvent) => {
    const value = event.target.value
    const selectedType = value.replace(/(-workspaces)|(-tags)|(-categories)/g, '') as ProductType
    const selectedView = value.replace(/(products-)|(materials-)/g, '') as View
    dispatch(setProductType(selectedType))
    dispatch(setView(selectedView))
    dispatch(getSelectedMetricData(selectedMetric))
  }

  return (
    <FormControl sx={{ minWidth: 130 }}>
      <InputLabel id="view-select-label">View</InputLabel>
      <Select
        labelId="view-select-label"
        id="view-select"
        data-testid="view-select"
        value={`${productType}-${view}`}
        startAdornment={
          <Stack pr={0.5}>
            <Icon data-testid="view-select-icon" icon={productType === 'products' ? faCube : faCubes} size="xs" />
          </Stack>
        }
        label="View"
        onChange={handleChange}
      >
        <ViewSubheader type="products" />
        {AVAILABLE_VIEWS.map((v) => (
          <MenuItem key={v} data-testid={`products-view-option-${v}`} value={`products-${v}`}>
            {optionsMap[v]}
          </MenuItem>
        ))}
        <ViewSubheader type="materials" />
        {AVAILABLE_VIEWS.map((v) => (
          <MenuItem key={v} data-testid={`materials-view-option-${v}`} value={`materials-${v}`}>
            {optionsMap[v]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
