import React, { FC } from 'react'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { faAbacus } from '@fortawesome/pro-regular-svg-icons'
import {
  faGaugeLow,
  faBug,
  faCubes,
  faSprout,
  faBook,
  faCube,
  faStore,
  faFlagSwallowtail,
} from '@fortawesome/pro-solid-svg-icons'
import { Text, theme } from '@howgood/design'

import { useHasFormulationsAccess, useHasProcurementAccess } from '@/hooks/permissionHooks'
import { selectDebugToolsSplit, selectFlagGoalsSplit, selectSplitTreatmentEnabled } from '@/state/splitio'
import { SidebarItem } from './SidebarItem'
import { SidebarFooter } from './SidebarFooter'
import { CreateNewButton } from './CreateNewButton'
import { useStyles } from './sidebarStyles'
import { WorkspaceSidebarItem } from './WorkspaceSidebarItem'
import { selectHasWorkspaceOfType } from '@/state/workspaces'
import { PRODUCTS, MATERIALS } from '@/records'
import { SupplierConnectAddButton } from './SupplierConnectAddButton'
import { selectIsSupplierConnect } from '@/state/organization'
import { selectUserHasCarbonAccountingAccess } from '@/state/user'

export const Sidebar: FC = () => {
  const classes = useStyles()
  const hasFormulationWorkspaces = useSelector(selectHasWorkspaceOfType(PRODUCTS))
  const hasProcurementWorkspaces = useSelector(selectHasWorkspaceOfType(MATERIALS))
  const procurementPaywallEnabled = useSelector(selectSplitTreatmentEnabled('procurement-paywall'))
  const vendorsPageEnabled = useSelector(selectSplitTreatmentEnabled('vendors_page'))
  const dashboardEnabled = useSelector(selectSplitTreatmentEnabled('dashboard-home'))
  const flagEnabled = useSelector(selectFlagGoalsSplit)
  const carbonAccountingEnabled = useSelector(selectUserHasCarbonAccountingAccess)
  const debugToolsEnabled = useSelector(selectDebugToolsSplit)
  const hasProcurementAccess = useHasProcurementAccess()
  const hasFormulationsAccess = useHasFormulationsAccess()
  const isSupplierConnect = useSelector(selectIsSupplierConnect)

  return (
    <div className={classes.sidebar}>
      <Link to="/">
        <div data-testid="sidebar-header" className={classNames([classes.latis, classes.header])}>
          <Text color="white" fontSize={isSupplierConnect ? 24 : 38} fontWeight={theme.typography.display.fontWeight}>
            {isSupplierConnect ? 'SupplierConnect' : 'Latis'}
          </Text>
          <div className={classes.spacer} />
          <Text color="white">by HowGood</Text>
        </div>
      </Link>
      {!isSupplierConnect && <CreateNewButton />}
      {isSupplierConnect && <SupplierConnectAddButton />}
      <div className={classes.section}>
        {dashboardEnabled && !isSupplierConnect && (
          <SidebarItem
            label="Dashboard"
            ariaLabel="Dashboard navigation item"
            path="/"
            exactMatch
            icon={faGaugeLow}
            pendoId="Menu:Dashboard"
          />
        )}
        {carbonAccountingEnabled && (
          <SidebarItem
            label="Carbon Accounting"
            ariaLabel="Carbon Accounting navigation item"
            data-testid="carbon-accounting-nav-item"
            path="/carbon-accounting"
            icon={faAbacus}
          />
        )}
        {hasFormulationsAccess && (
          <SidebarItem
            label="Company Impact"
            ariaLabel="Company Impact navigation item"
            path="/company"
            icon={faBook}
            pendoId="Menu:Analytics>Portfolio"
          />
        )}
        {flagEnabled && hasFormulationsAccess && (
          <SidebarItem
            label="Initiatives"
            ariaLabel="Initiatives navigation item"
            path="/initiatives"
            icon={faFlagSwallowtail}
            pendoId="Menu:Initiatives"
          />
        )}
        {hasFormulationsAccess && hasFormulationWorkspaces && (
          <WorkspaceSidebarItem
            label="Products"
            ariaLabel="Products navigation item"
            path="/products"
            icon={faCube}
            data-testid="menu-workspaces"
            pendoId="Menu:Workspaces"
            workspaceType="Formulation"
          />
        )}
        {hasProcurementAccess && hasProcurementWorkspaces && (
          <WorkspaceSidebarItem
            label="Materials"
            ariaLabel="Materials navigation item"
            path={procurementPaywallEnabled ? null : '/materials'}
            icon={faCubes}
            disabled={procurementPaywallEnabled}
            pendoId={procurementPaywallEnabled ? 'procurement-paywall' : 'Menu:Procurement'}
            workspaceType="Procurement"
          />
        )}
        {vendorsPageEnabled && (
          <SidebarItem
            label="Vendors"
            ariaLabel="Vendors navigation item"
            path="/vendors"
            icon={faStore}
            pendoId="Menu:Analytics>Vendors"
          />
        )}
        {hasFormulationsAccess && (
          <SidebarItem
            label={'Ingredient Library'}
            ariaLabel={'Ingredient Library navigation item'}
            path={'/ingredients'}
            icon={faSprout}
            pendoId="Menu:Planning>Ingredient"
          />
        )}
        {debugToolsEnabled && (
          <SidebarItem label="Debug Tools" path="/debug-tools" icon={faBug} pendoId="Menu:DebugTools" />
        )}
      </div>
      <SidebarFooter />
    </div>
  )
}
