import React, { Fragment, FC, useMemo } from 'react'
import { Box, Text, Checkbox, Stack } from '@howgood/design'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { useOptionGroups } from './hooks'
import { ControlPanelDropdown } from '../ControlPanelDropdown'

const MAX_ITEMS_PER_COLUMN = 13

export interface CheckboxOption {
  disabled?: boolean
  value?: string | boolean
  key: string
  label: string
  'data-pendo-id'?: string
  checked: boolean
  colGroup?: string
}
interface Props {
  options: CheckboxOption[]
  onOptionCheckChange: (option: CheckboxOption) => void
  onOptionsCheckChange?: (options: CheckboxOption[]) => void
  onCloseDropdown?: () => void
  icon?: IconDefinition
  label?: string
  applySelectDeselectAll?: boolean
  ['data-testid']?: string
}

export const CheckboxesDropdown: FC<Props> = ({
  options,
  onOptionCheckChange,
  onOptionsCheckChange,
  onCloseDropdown = () => null,
  icon,
  label = '',
  applySelectDeselectAll = false,
  ...rest
}) => {
  const optionGroups = useOptionGroups(options, MAX_ITEMS_PER_COLUMN)

  const onOptionClick = (option: CheckboxOption) => {
    if (option.disabled) {
      return
    }
    if (onOptionCheckChange) {
      onOptionCheckChange(option)
    }
  }

  const onGroupOptionsClick = (opts: CheckboxOption[]) => {
    if (onOptionsCheckChange) {
      onOptionsCheckChange(opts)
    }
  }

  const hasSingleVisibleGroup: boolean = useMemo(() => {
    const groups = optionGroups.flat()
    let visbleGroupsCount: number = 0
    for (let group of groups) {
      if (group.options.some((opt) => opt.label)) {
        visbleGroupsCount++
        if (visbleGroupsCount > 1) {
          return false
        }
      }
    }
    return true
  }, [optionGroups])

  const allGroupsOptions = useMemo(
    () =>
      optionGroups
        .flat()
        .map((group) => group.options)
        .flat(),
    [optionGroups]
  )

  const allGroupsEnabledOptions = useMemo(() => allGroupsOptions.filter((opt) => !opt.disabled), [allGroupsOptions])

  return (
    <ControlPanelDropdown
      data-pendo-id={rest['data-pendo-id']}
      data-testid={rest['data-testid']}
      icon={icon}
      label={label}
      onClose={onCloseDropdown}
    >
      <Box display="flex" p="8px 0 12px" fontSize={10}>
        {optionGroups.map((optionGroup, i) => (
          <Stack gap={0.5} key={i} mx={1} ml={i === 0 ? 0 : 1} mr={i === optionGroups.length - 1 ? 0 : 1}>
            {optionGroup.map((group, idx) => {
              const applyHeaderCheckbox =
                applySelectDeselectAll && group.options.some((opt) => opt.label) && !hasSingleVisibleGroup
              const enabledOptions = group.options.filter((opt) => !opt.disabled)
              return (
                <Fragment key={`${idx}-${group.name}`}>
                  {(group.name || applyHeaderCheckbox) && (
                    <Box
                      onClick={() => applyHeaderCheckbox && onGroupOptionsClick(group.options)}
                      display={applyHeaderCheckbox ? 'flex' : null}
                      alignItems={applyHeaderCheckbox ? 'center' : null}
                      mt={idx > 0 ? 1 : 0}
                      px={1}
                      sx={{ cursor: applyHeaderCheckbox ? 'pointer' : null }}
                    >
                      {applyHeaderCheckbox && (
                        <Checkbox
                          checked={enabledOptions.every((opt) => opt.checked)}
                          size="small"
                          indeterminate={
                            enabledOptions.some((opt) => opt.checked) && enabledOptions.some((opt) => !opt.checked)
                          }
                          onChange={() => null}
                          label=""
                        />
                      )}
                      <Text fontSize={14} fontWeight="bold" lineHeight="17px">
                        {group.name || 'select/deselect all'}
                      </Text>
                    </Box>
                  )}
                  {group.options
                    .filter((opt) => opt.label)
                    .map((option, j) => (
                      <Box
                        key={option.key || j}
                        onClick={() => onOptionClick(option)}
                        display="flex"
                        alignItems="center"
                        px={1}
                        sx={{
                          cursor: 'pointer',
                          '&:hover': {
                            bgcolor: '#DEEBFF',
                          },
                        }}
                      >
                        <Checkbox
                          data-testid={`${option.key || option.value}-checkbox`}
                          checked={option.checked}
                          onChange={() => null}
                          disabled={option.disabled}
                          size="small"
                          label=""
                        />
                        <Text data-testid="checkbox-text" fontSize={14} lineHeight="17px">
                          {option.label}
                        </Text>
                      </Box>
                    ))}
                </Fragment>
              )
            })}
          </Stack>
        ))}
      </Box>
      {applySelectDeselectAll && (
        <Box
          onClick={() => onGroupOptionsClick(allGroupsOptions)}
          display="flex"
          alignItems="center"
          pl={1}
          sx={{
            cursor: 'pointer',
            '&:hover': {
              bgcolor: '#DEEBFF',
            },
          }}
        >
          <Checkbox
            checked={allGroupsEnabledOptions.every((opt) => opt.checked)}
            size="small"
            indeterminate={
              allGroupsEnabledOptions.some((opt) => opt.checked) && allGroupsEnabledOptions.some((opt) => !opt.checked)
            }
            onChange={() => null}
          />
          <Text fontSize={14} fontWeight="bold">
            select/deselect all
          </Text>
        </Box>
      )}
    </ControlPanelDropdown>
  )
}
