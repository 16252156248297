import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@howgood/design'

import { DRAFT, selectPageView, setView } from '@/state/pageSettings'
import {
  selectCanEditProduct,
  selectHasImpactsOverriddenNestedProduct,
  selectIsImpactsOverridden,
} from '@/state/productOverview'

export const EditButton: FC<{ buttonText?: string }> = ({ buttonText = 'Edit' }) => {
  const dispatch = useDispatch()
  const canEdit = useSelector(selectCanEditProduct)
  const pageView = useSelector(selectPageView)
  const impactsOverriddenProduct = useSelector(selectIsImpactsOverridden)
  const hasImpactsOverriddenNestedProduct = useSelector(selectHasImpactsOverriddenNestedProduct)

  if (!canEdit || pageView === DRAFT || impactsOverriddenProduct || hasImpactsOverriddenNestedProduct) {
    return null
  }

  return (
    <Button
      id="main-edit-button"
      data-testid="edit-formula-button"
      aria-label="Edit product details"
      color="secondary"
      size="small"
      onClick={() => dispatch(setView(DRAFT))}
    >
      {buttonText}
    </Button>
  )
}
