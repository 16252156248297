import { AppState } from '@/store'
import { Basis, ESIngredient, ProductDashboardSettings, ProductHit } from './productDashboard.slice'
import { Workspace } from '@/records'
import { AggregationsByMetric } from './productDashboard.thunk'
import { Field } from '@/constants/impactScore'
import { ImpactScoreResponse } from '@/api'
import { createSelector } from 'reselect'

export const selectAvailableMetrics = (state: AppState): Field[] => state.productDashboard.availableMetrics
export const selectAllWorkspaceIds = (state: AppState): number[] => state.productDashboard.allWorkspaceIds
export const selectSelectedWorkspace = (state: AppState): Workspace => state.productDashboard.settings.workspace
export const selectWorkspaceProductCount = (state: AppState): number => state.productDashboard.workspaceProductCount
export const selectSelectedMetric = (state: AppState): string => state.productDashboard.settings.metric
export const selectBasis = (state: AppState): Basis => state.productDashboard.settings.basis
export const selectIsLoadingProducts = (state: AppState): boolean => state.productDashboard.isLoadingProducts
export const selectAggregationsByMetric = (state: AppState): AggregationsByMetric =>
  state.productDashboard.aggregationsByMetric
export const selectProductImpactData = (state: AppState): ImpactScoreResponse =>
  state.productDashboard.productImpactData
export const selectSelectedProductId = (state: AppState): number => state.productDashboard.selectedProductId
export const selectProductDashboardSettings = (state: AppState): ProductDashboardSettings =>
  state.productDashboard.settings

/**
 * Returns the top products for the selected metric and basis, but only if the selected metric is non-zero
 * The check is required because ES always returns 5 products in each bucket, even if their scores are zero
 */
export const selectTopProducts = createSelector(
  selectAggregationsByMetric,
  selectSelectedMetric,
  selectBasis,
  (aggregationsByMetric, selectedMetric, basis) => {
    const top5 = (aggregationsByMetric?.[selectedMetric]?.top5[basis] || []) as ProductHit[]
    const filteredTop5 = top5.reduce((acc: ProductHit[], product) => {
      const score =
        basis === 'kg' ? product.impact_score?.[selectedMetric] : product.custom_fields[`${basis}.${selectedMetric}`]
      if (score) {
        return [...acc, product]
      }
      return acc
    }, [])
    return filteredTop5
  }
)

/**
 * Returns the id of the top product for the selected metric and basis (i.e. the top item in the grid)
 */
export const selectTopProductId = createSelector(selectTopProducts, (topProducts): number => {
  return topProducts[0]?.pk
})

export const selectSelectedProduct = createSelector(
  selectTopProducts,
  selectSelectedProductId,
  (topProducts, selectedProductId) => {
    return topProducts.find((product) => product.pk === selectedProductId)
  }
)

/**
 * Creates a D3 hierarchy structure of ingredients for the selected product
 */
export interface IngredientInfo extends ESIngredient {
  contribution: number
}

export interface TreeNode {
  name: string
  value: number // Must be 0 for nested items
  nodeInfo: IngredientInfo
  children: TreeNode[]
}

export const selectSelectedProductTree = createSelector(
  selectSelectedProduct,
  selectSelectedMetric,
  (selectedProduct, selectedMetric) => {
    const productImpactScore = selectedProduct?.impact_score?.[selectedMetric]

    const nestedProducts: number[] = []
    // The ingredients need to be modified slightly to work with the hierarchy chart
    const updatedIngredients: ESIngredient[] = selectedProduct.ingredients?.reduce(
      (newIngredientList: ESIngredient[], ingredient) => {
        // It's possible the product has the same nested product multiple times, and we need to deduplicate because
        // child ingredients of duplicate nested products have the same product_id, and we use these ids to build the tree.
        // All ingredients of duplicate nested products will go under a single parent with the product weights summed.
        // Also, nested products don't have a flat_weight, so compute it from the parent's weight and insert here.
        let flat_weight = ingredient.flat_weight

        if (ingredient.nested_product_id) {
          // If we've already seen this nested product, don't add it to the accumulator
          if (nestedProducts.includes(ingredient.nested_product_id)) {
            return newIngredientList
          }
          // Add the new nested product id to the list
          nestedProducts.push(ingredient.nested_product_id)

          // Find the parent of the nested product, using the accumulator since it has the computed flat_weight
          const parent = newIngredientList.find((i) => i.nested_product_id === ingredient.product_id)

          // Get all instances of the nested product (usually there's just one, but there could be multiple)
          const allInstances = selectedProduct.ingredients.filter(
            (i) => i.nested_product_id === ingredient.nested_product_id
          )

          // Compute the total weight of all instances
          const totalWeight = allInstances.reduce((total, i) => total + i.weight, 0)

          // Compute the combined nested product's flat_weight
          flat_weight = parent ? parent.flat_weight * (totalWeight / 100) : totalWeight
        }

        return [
          ...newIngredientList,
          {
            ...ingredient,
            flat_weight: Math.round(flat_weight * 100) / 100,
            weight: Math.round(ingredient.weight * 100) / 100,
          },
        ]
      },
      []
    )

    // Recursive function that turns an array of ingredients into a tree structure
    // Each node includes the ingredient's contribution to the selected impact metric for the product
    const createTree = (items: ESIngredient[], id: number) =>
      items
        .filter((item) => item.product_id === id)
        .map((ingredient) => {
          const contribution = productImpactScore
            ? (ingredient.impact_score?.[selectedMetric] * ingredient.flat_weight) / productImpactScore
            : 0

          return {
            name: ingredient.name,
            value: ingredient.nested_product_id ? 0 : contribution,
            nodeInfo: { ...ingredient, contribution: contribution },
            // For the chart to work, nested products must have a weight of 0
            children: createTree(items, ingredient.nested_product_id),
          }
        })

    const nestedProduct: TreeNode = {
      name: selectedProduct.name,
      value: 0,
      nodeInfo: null,
      children: createTree(updatedIngredients || [], selectedProduct.pk),
    }

    return nestedProduct
  }
)
