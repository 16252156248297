import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { Tooltip, Text, Stack, Box } from '@howgood/design'

import { ProductListRenderCellParams } from './ProductGridTypes'
import { useGetFormulaIconBadge } from '@/hooks/useFormulaIconBadge'
import { ProductBrand, UNKNOWN_VENDOR_LABEL } from '@/records'
import { FormulaIconBadge } from '../Shared/IngredientSearch/FormulaIconBadge'

interface Props extends ProductListRenderCellParams {
  value: ProductBrand
}

export const VendorRenderer: FC<Props> = ({ value, id, row }) => {
  const getFormulaIconBadge = useGetFormulaIconBadge()

  const badge = getFormulaIconBadge({
    workspaces: row.workspaces?.map((w) => w.id),
    material_types: row.material_types,
    isProduct: true,
  })

  const displayValue = value?.name || UNKNOWN_VENDOR_LABEL

  return (
    <Stack data-testid={`vendor-cell-${id}`} direction="row" alignItems="center" width="100%">
      <FormulaIconBadge badge={badge} />
      {value?.id ? (
        <Tooltip title={<Text variant="body2">{displayValue}</Text>} disableInteractive>
          <Link to={`/vendors/${value.id}`} data-testid={`vendor-link-${id}`}>
            <Box sx={{ '&:hover': { textDecoration: 'underline' } }}>
              <Text variant="body2">{displayValue}</Text>
            </Box>
          </Link>
        </Tooltip>
      ) : (
        <Tooltip title={<Text variant="body2">{displayValue}</Text>} disableInteractive>
          <Text variant="body2">{displayValue}</Text>
        </Tooltip>
      )}
    </Stack>
  )
}
