import { matchPath } from 'react-router'

export default function() {
  const match = matchPath<{ page: string }>(window.location.pathname, {
    path: '/(products)?/:product?/(parent)?/:parent?/:page',
  })

  const page = match && match.params && match.params.page
  let path = page as string | number
  if (page && page.indexOf('step-') !== -1) {
    path = Number(page.replace('step-', ''))
  }
  return path
}
