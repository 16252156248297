import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { InventoriesResponse } from '@/api'
import {
  getAllProducts,
  getProducts,
  initializeProductFilters,
  initializeProductsPage,
  updateAssignee,
  updateProductInventories,
  updateProductScenarioData,
  updateProductScenarioMTperYr,
  updateScenarioProductStandards,
} from './products.thunk'
import { prepareProductsFromHits } from './products.utils'
import { ProductFilters, initialState } from './products.state'
import { OrgUser } from '../organization'

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    resetTableProducts: (state) => {
      state.tableProducts = initialState.tableProducts
      state.total = initialState.total
    },
    resetAllProducts: (state) => {
      state.allProducts = initialState.allProducts
    },
    updateProductFilters: (state, action: PayloadAction<Partial<ProductFilters>>) => {
      state.productFilters = { ...state.productFilters, ...action.payload }
    },
    setEditingFilter: (state, action: PayloadAction<string>) => {
      state.productFilters.editingFilter = action.payload
    },
    setShowLiveImpactData: (state, action: PayloadAction<boolean>) => {
      state.showLiveImpactData = action.payload
    },
    setShowAtRiskOnly: (state, action: PayloadAction<boolean>) => {
      state.showAtRiskOnly = action.payload
    },
    setProductAssignee: (state, action: PayloadAction<{ id: number; user: OrgUser }>) => {
      const product = state.tableProducts.find((item) => item.id === action.payload.id)
      product.assignee = action.payload.user
    },
    clearActiveBulkJob: (state) => {
      state.activeBulkJob = null
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProducts.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getProducts.fulfilled, (state, action) => {
      const products = action.payload
      state.isLoading = false
      if (Object.hasOwn(products, 'hits')) {
        state.tableProducts = prepareProductsFromHits(products.hits)
        state.total = products.total
        state.customAggs = products.custom_aggs
      }
    })
    builder.addCase(getProducts.rejected, (state) => {
      state.isLoading = false
    })

    builder.addCase(getAllProducts.pending, (state) => {
      state.isLoading = true
      state.hasError = false
    })
    builder.addCase(getAllProducts.fulfilled, (state, action) => {
      state.allProducts = action.payload
      state.isLoading = false
      state.hasError = false
    })
    builder.addCase(getAllProducts.rejected, (state) => {
      state.isLoading = false
      state.hasError = true
    })

    builder.addCase(initializeProductsPage.pending, (state) => {
      state.isLoading = true
    })

    builder.addCase(updateProductInventories.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(updateProductInventories.fulfilled, (state, action) => {
      // When updating inventories on an uncloned scenario product, the backend creates a cloned product with a new id
      // and returns new inventories for the cloned product. We need the original product id to update the products list.
      const { productId: originalProductId, updatedInventories } = action.payload
      updatedInventories.forEach((updatedInventory) => {
        const productId = originalProductId
        const productIndex = state.tableProducts.findIndex((item) => item.id === productId)
        const productData = state.tableProducts[productIndex]
        if (!productData) return
        const inventoryIndex = productData?.inventories?.findIndex((item) => item.id === updatedInventory.id) ?? -1

        if (inventoryIndex !== -1) {
          productData.inventories[inventoryIndex] = updatedInventory as InventoriesResponse
          state.tableProducts[productIndex] = productData
        } else {
          // When setting a goal on an uncloned scenario product, the inventories are not in the list
          productData.inventories = updatedInventories as InventoriesResponse[]
          productData.id = updatedInventory.product.id
          state.tableProducts[productIndex] = productData
        }
      })
      state.isLoading = false
    })
    builder.addCase(updateProductInventories.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(updateProductScenarioData.fulfilled, (state, action) => {
      state.tableProducts = action.payload
    })
    builder.addCase(updateProductScenarioMTperYr.fulfilled, (state, action) => {
      state.tableProducts = action.payload.updatedProducts
    })

    builder.addCase(initializeProductFilters.fulfilled, (state, action) => {
      const filters = { ...state.productFilters, ...action.payload }
      state.productFilters = filters
    })

    builder.addCase(updateAssignee.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(updateAssignee.fulfilled, (state, action) => {
      const product = state.tableProducts.find((item) => item.id === action.payload.id)
      product.assignee = action.payload.assignee
      state.isLoading = false
    })
    builder.addCase(updateAssignee.rejected, (state) => {
      state.isLoading = false
    })

    builder.addCase(updateScenarioProductStandards.fulfilled, (state, action) => {
      state.activeBulkJob = action.payload
    })
    builder.addCase(updateScenarioProductStandards.rejected, (state) => {
      state.activeBulkJob = null
    })
  },
})

export const {
  resetTableProducts,
  updateProductFilters,
  setEditingFilter,
  setShowLiveImpactData,
  setShowAtRiskOnly,
  clearActiveBulkJob,
  resetAllProducts,
} = productsSlice.actions

export const productsReducer = productsSlice.reducer
