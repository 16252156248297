import React, { FC, useEffect, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router'
import { captureException } from '@sentry/react'
import { faBook, faSprout } from '@fortawesome/pro-solid-svg-icons'
import { Box } from '@howgood/design'

import { Module, DebugTools, IngredientPlanning, MyFormulations, IndustryNews } from '@/components'
import { ContentfulContext } from '@/contentfully'
import { FormulaProfile } from '@/features/ProductPage/FormulaProfile'
import { PortfolioPage } from '@/features/PortfolioPage/PortfolioPage'
import { getContentfulData, selectIsContentfulLoading } from '@/state/contentfulData'
import {
  selectDebugToolsSplit,
  selectFlagGoalsSplit,
  selectSplitIsReady,
  selectSplitIsTimedOut,
  selectSplitTreatmentEnabled,
} from '@/state/splitio'
import { DEFAULT_USER_ID, PRODUCTS, MATERIALS } from '@/records'
import { selectUserId } from '@/state/user/user.selectors'
import { appInit } from '@/thunks'
import NoMatch from '../NoMatch'
import { DashboardLoader } from './DashboardLoader'
import { ProcurementPage } from '@/features/ProcurementPage/ProcurementPage'
import { VendorManagementPage } from '@/features/VendorManagementPage/VendorManagementPage'
import { CarbonAccountingPage, CarbonAccountingReportPage } from '@/features/CarbonAccounting'
import { InitiativeDetail } from '@/features/Initiative/InitiativeDetail/InitiativeDetail'
import { Scenario } from '@/features/Initiative/Scenario/Scenario'
import { Initiatives } from '@/features/Initiative/Initiatives/Initiatives'
import { selectisInitiativesLoading, selectisScenarioLoading } from '@/state/initiatives'
import { Sidebar } from '@/components/Sidebar/Sidebar'
import { LiteUserPaywallDialog } from '@/components/LiteUserPaywallDialog/LiteUserPaywallDialog'
import { DashboardHome } from '@/components/DashboardHome/DashboardHome'
import { VendorDetailPage } from '@/features/VendorDetailPage/VendorDetailPage'
import { selectHasWorkspaceOfType } from '@/state/workspaces'
import { setFavoriteCards, setFavoriteScores } from '@/state/preferences'
import { selectIsSupplierConnect } from '@/state/organization'

export const Dashboard: FC = () => {
  const dispatch = useDispatch()
  const splitIsReady = useSelector(selectSplitIsReady)
  const splitIsTimedOut = useSelector(selectSplitIsTimedOut)
  const splitReady = splitIsReady || splitIsTimedOut
  const userId = useSelector(selectUserId)
  const contentfulClient = useContext(ContentfulContext)
  const debugToolsEnabled = useSelector(selectDebugToolsSplit)
  const industryNewsEnabled = useSelector(selectSplitTreatmentEnabled('industry-news'))
  const flagEnabled = useSelector(selectFlagGoalsSplit)
  const isInitiativesLoading = useSelector(selectisInitiativesLoading)
  const isScenarioLoading = useSelector(selectisScenarioLoading)
  const dashboardHomeEnabled = useSelector(selectSplitTreatmentEnabled('dashboard-home'))
  const hasFormulationWorkspaces = useSelector(selectHasWorkspaceOfType(PRODUCTS))
  const hasProcurementWorkspaces = useSelector(selectHasWorkspaceOfType(MATERIALS))
  const isSupplierConnect = useSelector(selectIsSupplierConnect)
  const isContentfulLoading = useSelector(selectIsContentfulLoading)

  useEffect(() => {
    if (splitIsTimedOut) {
      captureException(new Error('Split timed out, treatments not applied. Check https://status.split.io/'))
    }
  }, [splitIsTimedOut])

  useEffect(() => {
    if (userId !== DEFAULT_USER_ID && splitReady) {
      dispatch(appInit())
    }
    // If switching from full Latis to SupplierConnect, set the favorites to the ones that are available in SupplierConnect
    if (isSupplierConnect) {
      dispatch(setFavoriteScores(['cf_ftm_gate_ct_verified_impact', 'raw_blue_water_usage_impact']))
      dispatch(setFavoriteCards([]))
    }
  }, [dispatch, userId, splitReady, isSupplierConnect])

  useEffect(() => {
    dispatch(getContentfulData({ contentfulClient }))
  }, [dispatch, contentfulClient])

  if (
    userId === DEFAULT_USER_ID ||
    !splitReady ||
    isInitiativesLoading ||
    isScenarioLoading ||
    isContentfulLoading // Don't load page until Contentful metric data is available
  ) {
    return <DashboardLoader />
  }

  return (
    <Box data-testid="dashboard-container" display="flex" overflow="auto" flex={1}>
      {industryNewsEnabled && <IndustryNews />}
      <Sidebar />
      <Switch>
        {dashboardHomeEnabled && !isSupplierConnect && <Redirect from="/dashboard" to={'/'} />}
        {dashboardHomeEnabled && !isSupplierConnect ? (
          <Route
            path={'/'}
            exact
            render={() => {
              return (
                <Module>
                  <DashboardHome />
                </Module>
              )
            }}
          />
        ) : (
          <Redirect from="/dashboard" to="/products" />
        )}
        <Redirect from="/formulas/:formula" to="/products/:formula" />
        <Redirect from="/procurement/formulas/:formula" to="/materials/:formula" />
        <Route
          path={[
            '/products/:formula',
            '/formulas', // for create new
            '/materials/:formula',
          ]}
          component={() => (
            <Module>
              <FormulaProfile />
            </Module>
          )}
        />
        {!hasFormulationWorkspaces && hasProcurementWorkspaces && (
          <Redirect exact from="/formulations" to="/materials" />
        )}
        {!hasFormulationWorkspaces && hasProcurementWorkspaces && <Redirect exact from="/products" to="/materials" />}
        <Route
          exact
          path={
            dashboardHomeEnabled && !isSupplierConnect
              ? ['/formulations', '/products']
              : ['/', '/formulations', '/products']
          }
          render={() => {
            return (
              <Module>
                <MyFormulations />
              </Module>
            )
          }}
        />
        <Route
          exact
          path={['/carbon-accounting']}
          render={() => {
            return (
              <Module>
                <CarbonAccountingPage />
              </Module>
            )
          }}
        />
        <Route
          exact
          path={['/carbon-accounting/:report']}
          render={() => {
            return (
              <Module>
                <CarbonAccountingReportPage />
              </Module>
            )
          }}
        />
        {flagEnabled ? (
          <Route
            path={'/initiatives/:initiative/scenarios/:scenario'}
            render={() => {
              return (
                <Module>
                  <Scenario />
                </Module>
              )
            }}
          />
        ) : (
          <Redirect from="/initiatives/:initiative/scenarios/:scenario" to={'/company'} />
        )}
        {flagEnabled ? (
          <Route
            path={'/initiatives/:initiative'}
            exact
            render={() => {
              return (
                <Module>
                  <InitiativeDetail />
                </Module>
              )
            }}
          />
        ) : (
          <Redirect from="/initiatives/:initiative" to={'/company'} />
        )}
        {flagEnabled ? (
          <Route
            path={'/initiatives'}
            exact
            render={() => {
              return (
                <Module>
                  <Initiatives />
                </Module>
              )
            }}
          />
        ) : (
          <Redirect from="/initiatives" to={'/company'} />
        )}
        <Route
          exact
          path={'/debug-tools'}
          component={() => {
            if (debugToolsEnabled) {
              return (
                <Module title="Debug Tools">
                  <DebugTools />
                </Module>
              )
            }
            return null
          }}
        />
        <Redirect from="/portfolio" to="/company" />
        <Redirect from="/analytics/portfolio" to="/company" />
        <Route
          path={['/company']}
          component={() => {
            return (
              <Module title="Company Impact" icon={faBook}>
                <PortfolioPage />
              </Module>
            )
          }}
        />
        <Route
          path={['/vendor-management/:vendor', '/vendors/:vendor']}
          component={() => (
            <Module>
              <VendorDetailPage />
            </Module>
          )}
        />
        <Redirect from="/vendor-management" to="/vendors" />
        <Redirect from="/analytics/vendor-management" to="/vendors" />
        <Redirect from="/analytics/vendor-management" to="/vendor-management" />
        <Route
          exact
          path={['/analytics/vendor-management', '/vendor-management', '/vendors']}
          component={() => {
            return (
              <Module>
                <VendorManagementPage />
              </Module>
            )
          }}
        />
        <Redirect from="/analytics" to="/" />
        <Redirect from="/analytics/impact" to="/" />
        <Redirect from="/analytics/risk-assessment" to="/" />
        <Redirect from="/planning/ingredient" to="/ingredients" />
        <Route
          exact
          path={['/planning/ingredient', '/ingredients']}
          component={() => (
            <Module title="Ingredient Library" icon={faSprout}>
              <IngredientPlanning />
            </Module>
          )}
        />
        <Redirect from="/planning/formula" to="/" />
        <Redirect from="/tables/bases" to="/" />
        <Redirect from="/tables/origins" to="/" />
        <Redirect from="/tables/origin-locations" to="/" />
        <Redirect from="/procurement" to="/materials" />
        {hasFormulationWorkspaces && !hasProcurementWorkspaces && <Redirect exact from="/materials" to="/" />}
        <Route
          path={['/materials']}
          component={() => (
            <Module>
              <ProcurementPage />
            </Module>
          )}
        />
        <Route component={NoMatch} />
      </Switch>
      <LiteUserPaywallDialog />
    </Box>
  )
}
