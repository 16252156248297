import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormulationTagFilterOption } from '@/state/products/products.state'
import {
  selectVendorDataIsLoading,
  selectEditingVendorsFilter,
  selectVendorTags,
  updateFiltersUrlAndVendorData,
  setEditingVendorManagementFilters,
} from '@/state/vendorManagement'
import { FormulationTagFilter } from '@/components/Shared/Filters/FormulationTagFilter'

export const VendorTagsFilter: FC = () => {
  const dispatch = useDispatch()
  const isLoading = useSelector(selectVendorDataIsLoading)
  const formulationTags = useSelector(selectVendorTags)
  const editingFilter = useSelector(selectEditingVendorsFilter)
  const id = 'formulation-tag-search-for-aggregated-vendors'

  const handleFormulationTagsChange = (options: FormulationTagFilterOption[]) => {
    dispatch(updateFiltersUrlAndVendorData({ updatedFilters: { formulationTags: options } }))
    dispatch(setEditingVendorManagementFilters(id))
  }

  return (
    <FormulationTagFilter
      value={formulationTags}
      handleChange={handleFormulationTagsChange}
      id={id}
      disabled={isLoading}
      autoFocus={editingFilter === id}
      fullWidth
    />
  )
}
