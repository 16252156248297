import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { selectIngredientFilters, updateFiltersUrlAndProducts } from '@/state/products'
import { fetchIngredientsByBaseIds } from '@/api'
import { fetchIngredients } from '@/utils/ingredientSearch'
import { selectDefaultWorkspaceIds, selectWorkspacesIds } from '@/state/workspaces'
import { selectCanViewPublicIngredients } from '@/state/user'
import { IngredientFilterSearchOption } from '@/records'
import { AutocompleteFilter } from '../Shared/Filters/AutocompleteFilter'

export const IngredientFilter: FC = () => {
  const dispatch = useDispatch()
  const ingredients = useSelector(selectIngredientFilters)
  const workspaceIds = useSelector(selectWorkspacesIds)
  const defaultWorkspaceIds = useSelector(selectDefaultWorkspaceIds)
  const canViewPublicIngredients = useSelector(selectCanViewPublicIngredients)

  const fetchIngredientOptions = async (ingredientQuery: string) => {
    const hits = await fetchIngredients({
      filters: {
        defaultWorkspaceIds,
        search: ingredientQuery,
        ownWorkspacesIds: workspaceIds,
        canViewPublicIngredients,
      },
      onlyIngredients: true,
      size: 20,
      cancelPrevious: true,
    })
    return hits.map((hit) => {
      const ingredient = hit._source
      return { ...ingredient, value: ingredient.id, label: ingredient.name }
    })
  }

  const handleIngredientChange = async (options: IngredientFilterSearchOption[]) => {
    let ingredientIds: string[] = []
    let baseIds = options.map((option) => option.id)

    try {
      const fetchedIngredients = await fetchIngredientsByBaseIds(baseIds)
      ingredientIds = ingredientIds.concat(fetchedIngredients.map((ingredient) => ingredient._source.id))
    } catch (e) {
      console.error(e)
    }
    dispatch(updateFiltersUrlAndProducts({ updatedFilters: { ingredients: options, ingredientIds } }))
  }

  return (
    <AutocompleteFilter
      id="ingredient-search"
      selected={ingredients}
      filterType="ingredients"
      handleChangeFilter={handleIngredientChange}
      onUpdateQuery={fetchIngredientOptions}
    />
  )
}
