import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Accordion, AccordionDetails, Grid, Stack, Box } from '@howgood/design'
import { AccordionCardHeader } from '@/components/Scores/AccordionCardHeader'
import { selectImpactReductionSplit } from '@/state/splitio'
import { useFormulaDataGranularityBreakdown } from './ImpactReduction.hooks'
import { MaterialGranularityBreakdown } from '@/components/Procurement/MaterialGranularityBreakdown/MaterialGranularityBreakdown'
import { AgriculturalPractices } from './AgriculturalPractices'
import { SAVED, selectPageView } from '@/state/pageSettings'
import { selectIsRollup } from '@/state/router'
import { selectIsSupplierConnect } from '@/state/organization'

export const ImpactReduction: FC<{ isFavorites?: boolean }> = ({ isFavorites }) => {
  const pageView = useSelector(selectPageView)
  const impactReductionEnabled = useSelector(selectImpactReductionSplit)
  const isRollup = useSelector(selectIsRollup)
  const isSupplierConnect = useSelector(selectIsSupplierConnect)
  const dataGranularity = useFormulaDataGranularityBreakdown()

  if (!impactReductionEnabled || isRollup || pageView !== SAVED || isSupplierConnect) {
    return null
  }

  return (
    <Grid item lg={12} md={12} xs={12} sm={12}>
      <Accordion id="impact-reduction-notes-panel" data-testid="impact-reduction-panel" defaultExpanded>
        <AccordionCardHeader
          id="impact-reduction-panel-header"
          cardKey="impactReduction"
          title="Impact Reduction"
          favoritesTab={isFavorites}
        />
        <AccordionDetails>
          <Stack direction="row">
            <Box flex={1}>
              <MaterialGranularityBreakdown
                data={dataGranularity}
                subtitle="Breakdown of data granularity collected for this formula."
                titleVariant="body1"
                titleColor="secondary"
                withRequestVendorData
              />
            </Box>
            <AgriculturalPractices />
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Grid>
  )
}
