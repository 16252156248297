import { AppState } from '@/store'
import { createSelector } from 'reselect'
import { selectIsVendors } from '../router'

export const selectIsLoadingImpactHistory = (state: AppState) => state.impactHistory.isLoading
const selectProductImpactHistory = (state: AppState) => state.impactHistory.productImpactHistory
const selectVendorImpactHistory = (state: AppState) => state.impactHistory.vendorImpactHistory

export const selectImpactHistory = createSelector(
  selectIsVendors,
  selectProductImpactHistory,
  selectVendorImpactHistory,
  (isVendorsPage, productImpactHistory, vendorImpactHistory) =>
    isVendorsPage ? vendorImpactHistory : productImpactHistory
)
