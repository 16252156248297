import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Accordion, AccordionDetails, Grid } from '@howgood/design'

import { AccordionCardHeader } from '../../AccordionCardHeader'
import { ImpactBar } from './ImpactBar'
import { selectIsRollup } from '@/state/router'
import { selectScoresImpactDataForOverview } from '@/selectors/selectScoresData'

export const OverviewBars: FC<{ isFavorites?: boolean }> = ({ isFavorites }) => {
  const isRollup = useSelector(selectIsRollup)
  const impacts = useSelector(selectScoresImpactDataForOverview)

  if (!impacts.length) {
    return null
  }

  return (
    <Grid item>
      <Accordion id="impact-scores-panel" data-testid="impact-scores-panel" defaultExpanded>
        <AccordionCardHeader
          id="impact-scores-panel-header"
          cardKey="impacts"
          title="Impact Details"
          compareTo={!isRollup}
          favoritesTab={isFavorites}
        />
        <AccordionDetails>
          <Grid container columnSpacing={4} px={2}>
            {impacts.map((impact) => (
              <Grid data-testid="overview-bar" item pt={0} xs={6} key={impact.content.key}>
                <ImpactBar impact={impact} />
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  )
}
