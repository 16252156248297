import React, { FC, useEffect } from 'react'
import { Stack } from '@howgood/design'
import { Toolbar } from './Toolbar/Toolbar'
import { ProductGrid } from './ProductGrid/ProductGrid'
import { useDispatch, useSelector } from 'react-redux'
import {
  initializeTopProducts,
  selectAggregationsByMetric,
  selectBasis,
  selectIsLoadingProducts,
  selectSelectedMetric,
  selectSelectedProduct,
  selectSelectedWorkspace,
} from './state/productDashboard'
import { selectEditableWorkspaces } from '@/state/workspaces'
import { ImpactChart } from './ImpactChart/ImpactChart'
import { useGridApiRef } from '@mui/x-data-grid-premium'
import { ChartFooter } from './ChartFooter'
import { updateDashboardSettings } from '@/state/user'

const chartWidth = 440

export const ProductDashboard: FC = () => {
  const editableWorkspaces = useSelector(selectEditableWorkspaces)
  const aggregationsByMetric = useSelector(selectAggregationsByMetric)
  const selectedProduct = useSelector(selectSelectedProduct)
  const isLoadingProducts = useSelector(selectIsLoadingProducts)
  const selectedWorkspace = useSelector(selectSelectedWorkspace)
  const selectedMetric = useSelector(selectSelectedMetric)
  const basis = useSelector(selectBasis)
  const dispatch = useDispatch()
  const gridApiRef = useGridApiRef()

  useEffect(() => {
    if (!aggregationsByMetric) {
      // Initialize using all workspaces
      dispatch(initializeTopProducts())
    }
  }, [dispatch, aggregationsByMetric, editableWorkspaces])

  // This saves the user's dashboard settings when they change the workspace, basis, or metric
  useEffect(() => {
    dispatch(updateDashboardSettings())
  }, [dispatch, basis, selectedMetric, selectedWorkspace])

  return (
    <Stack gap={1} data-testid="product-dashboard-wrapper">
      <Stack direction="row" alignItems="start" gap={2} width="100%">
        <Stack width={`calc(100% - ${chartWidth}px)`} gap={2} mt={2}>
          <Toolbar />
          <ProductGrid gridApiRef={gridApiRef} />
        </Stack>
        {selectedProduct && !isLoadingProducts && (
          <Stack width={chartWidth} gap={1}>
            <ImpactChart width={chartWidth} />
          </Stack>
        )}
      </Stack>
      {selectedProduct && !isLoadingProducts && <ChartFooter />}
    </Stack>
  )
}
