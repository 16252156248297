import useSWR from 'swr'
import { useSelector } from 'react-redux'
import { fetchOriginLocations } from '@/state/originLocations/originLocations.requests'
import { OriginLocationMapping } from '@/state/originLocations'
import { RecipeIngredient, RecipeNestedIngredient, fetchImpactScores, selectDisplayedRecipe } from '@/state/recipe'
import { ImpactScore } from '@/api'

export function useFetchOriginLocations(baseId: number) {
  const url = baseId === null ? null : `ingredients/origin-locations?base_ids=${baseId}`
  const { data, error } = useSWR(url, () => fetchOriginLocations([baseId]), { fallbackData: [] })

  return {
    data,
    isError: Boolean(error),
    isLoading: !data && !error,
  }
}

interface UseFetchIngredientImpactScores {
  data: ImpactScore[]
  isLoading: boolean
  isError: boolean
}
export function useFetchIngredientImpactScores(
  ingredient: RecipeIngredient | RecipeNestedIngredient,
  originLocations: OriginLocationMapping[] = []
): UseFetchIngredientImpactScores {
  const recipe = useSelector(selectDisplayedRecipe)
  const tempIngredientList = originLocations.map((ol) => ({
    ...ingredient,
    origin_location_id: ol.origin_location_id,
    origin_location_name: ol.region_name,
  }))
  const { data, error } = useSWR(
    // Bust cache if origin locations change
    originLocations.map((ol) => ol.origin_location_id).join(','),
    async () => {
      const impactData = await fetchImpactScores({ ...recipe, ingredients: tempIngredientList })
      return impactData.ingredients
    },
    {
      fallbackData: [],
    }
  )
  return {
    data,
    isError: Boolean(error),
    isLoading: !data && !error,
  }
}
