import React, { FC } from 'react'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { Text } from '@howgood/design'
import { IImpactMetricOption, Workspace } from '@/records'
import { formatNumber } from '@howgood/utils'

interface ScoreCellParams extends GridRenderCellParams {
  metric: IImpactMetricOption
  value: { workspace: Workspace; benchmark: string }
}

export const BenchmarkCell: FC<ScoreCellParams> = ({ metric, value }) => {
  const { workspace, benchmark } = value

  return (
    <Text
      id={`benchmark-${workspace.id}-${metric.value}`}
      data-testid={`benchmark-${workspace.id}-${metric.value}`}
      width="100%"
      textAlign="right"
    >
      {formatNumber(benchmark, 1)}
    </Text>
  )
}
