import { fetchHelperV2, Standard } from '@/api'
import * as Sentry from '@sentry/react'

export interface OriginLocationStandard {
  origin_location: {
    origin: {
      available_standards: Standard[]
    }
    region: {
      name: string
    }
  }
  standards: Standard[]
}
export interface OptimalGhgPotential {
  ghg_potential: number
  origin_location_standards: OriginLocationStandard[]
}

export const OPTIMAL_GHG_POTENTIAL_DEFAULT: OptimalGhgPotential = {
  ghg_potential: null,
  origin_location_standards: [],
}

export const fetchOptimalGhgPotential = async (id: number): Promise<OptimalGhgPotential> => {
  let data: OptimalGhgPotential = OPTIMAL_GHG_POTENTIAL_DEFAULT
  try {
    data = await fetchHelperV2<OptimalGhgPotential>({ url: `products/${id}/optimal-ghg-potential/` })
  } catch (e) {
    Sentry.captureException(e)
  }
  return data
}
