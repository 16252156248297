import React, { FC, useMemo, useState } from 'react'
import { Autocomplete, MenuItem, Stack, Tab, Tabs, TextField, Tooltip } from '@howgood/design'
import { MATERIALS, PRODUCTS, WorkspaceOption } from '@/records'

interface Props {
  options: WorkspaceOption[]
  value: WorkspaceOption[]
  handleChange: (options: WorkspaceOption[]) => void
  id?: string
  disabled?: boolean
  autoFocus?: boolean
  fullWidth?: boolean
  placeholder?: string
}

// Shared filter for usage in Formulations and Aggregated Vendors screens
export const WorkspaceFilter: FC<Props> = ({
  options,
  value,
  handleChange,
  id,
  disabled = false,
  autoFocus = false,
  fullWidth = false,
  placeholder = 'Workspace',
}) => {
  const hasProductWorkspace = options.some((option) => option.workspace_type === PRODUCTS)
  const hasMaterialsWorkspace = options.some((option) => option.workspace_type === MATERIALS)
  const [showTab, setShowTab] = useState<number>(0)
  const filteredOptions = useMemo(() => {
    if (!hasProductWorkspace || !hasMaterialsWorkspace) {
      return options
    }
    return options.filter((option) =>
      showTab === 0 ? option.workspace_type === PRODUCTS : option.workspace_type === MATERIALS
    )
  }, [options, showTab, hasMaterialsWorkspace, hasProductWorkspace])

  return (
    <Tooltip title="Filter by workspace" placement="top">
      <Autocomplete
        data-pendo-id="SearchWorkspaces"
        id={id}
        data-testid={id}
        options={filteredOptions}
        filterSelectedOptions
        onChange={(_event, ops) => handleChange(ops)}
        value={value}
        isOptionEqualToValue={(option, val) => option.value === val.value}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            autoFocus={autoFocus}
            placeholder={value.length ? '' : placeholder}
            fullWidth={fullWidth}
          />
        )}
        slotProps={{ paper: { sx: { minWidth: 500 } } }}
        renderHeader={
          hasProductWorkspace && hasMaterialsWorkspace
            ? () => (
                <Stack flexDirection="row" justifyContent="space-between" alignItems="center" pr={2}>
                  <Tabs value={showTab} variant="fullWidth" onChange={() => setShowTab(showTab === 0 ? 1 : 0)}>
                    <Tab label="Products" />
                    <Tab label="Materials" />
                  </Tabs>
                </Stack>
              )
            : null
        }
        renderOption={(props, option) => (
          <MenuItem {...props} key={option.value} value={option.value} onClick={() => handleChange([...value, option])}>
            {option.label}
          </MenuItem>
        )}
        multiple
        fullWidth={fullWidth}
        componentsProps={{ popper: { style: { width: 'fit-content' }, placement: 'bottom-start' } }}
      />
    </Tooltip>
  )
}
