import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Button, Dialog, DialogContent, DialogActions, Text } from '@howgood/design'
import { selectProductIsLocked } from '@/state/productOverview'
import { selectPageView } from '@/state/pageSettings'

const accuracyStatement =
  'Please verify that the product information is correct and your sourcing information is accurate.'

interface Props {
  onClose: () => void
}

export const AttachmentNotificationDialog: FC<Props> = ({ onClose }) => {
  const isLocked = useSelector(selectProductIsLocked)
  const pageView = useSelector(selectPageView)

  return (
    <Dialog data-testid="attachment-notification-dialog" id="attachment-notification-dialog" open onClose={onClose}>
      <DialogContent>
        {isLocked && (
          <Text>
            This will attach a report for the published version of the product.
            {pageView !== 'Published' ? ' This may be different from the live version you are viewing.' : ''}
          </Text>
        )}
        {!isLocked && <Text>This will lock the product and create the report. {accuracyStatement}</Text>}
      </DialogContent>
      <DialogActions>
        <Button id="attachment-notification-ok-button" color="primary" onClick={() => onClose()}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export const ShareNotificationDialog: FC<Props> = ({ onClose }) => {
  const isLocked = useSelector(selectProductIsLocked)
  const pageView = useSelector(selectPageView)

  return (
    <Dialog data-testid="share-notification-dialog" id="share-notification-dialog" open onClose={onClose}>
      <DialogContent>
        {isLocked && (
          <Text>
            This will share the published version of the product with the organization you specify.
            {pageView !== 'Published' ? ' This may be different from the live version you are viewing.' : ''}
          </Text>
        )}
        {!isLocked && (
          <Text>This will lock the product and share it with the organization you specify. {accuracyStatement}</Text>
        )}
      </DialogContent>
      <DialogActions>
        <Button id="share-notification-ok-button" color="primary" onClick={() => onClose()}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}
