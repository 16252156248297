import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Autocomplete, MenuItem, TextField, Tooltip } from '@howgood/design'
import {
  selectVendorDataIsLoading,
  selectEditingVendorsFilter,
  selectVendorManufacturingTypes,
  setEditingVendorManagementFilters,
  updateFiltersUrlAndVendorData,
} from '@/state/vendorManagement'
import { ManufacturingTypeOption, selectManufacturingTypeOptions } from '@/state/manufacturingTypes'

export const VendorManufacturingTypeFilter: FC = () => {
  const dispatch = useDispatch()
  const isLoading = useSelector(selectVendorDataIsLoading)
  const manufacturingTypeOptions = useSelector(selectManufacturingTypeOptions)
  const manufacturingTypes = useSelector(selectVendorManufacturingTypes)
  const editingFilter = useSelector(selectEditingVendorsFilter)
  const id = 'manufacturing-type-search-for-aggregated-vendors'

  const handleChange = async (updatedManufacturingTypes: ManufacturingTypeOption[]) => {
    dispatch(updateFiltersUrlAndVendorData({ updatedFilters: { manufacturingTypes: updatedManufacturingTypes } }))
    dispatch(setEditingVendorManagementFilters(id))
  }

  return (
    <Tooltip title="Filter by manufacturing type" placement="top">
      <Autocomplete
        data-pendo-id="SearchManufacturingTypes"
        id={id}
        data-testid={id}
        options={manufacturingTypeOptions}
        filterSelectedOptions
        onChange={(_event, ops) => handleChange(ops)}
        value={manufacturingTypes}
        isOptionEqualToValue={(option, val) => option.value === val.value}
        disabled={isLoading}
        renderInput={(params) => (
          <TextField
            {...params}
            autoFocus={editingFilter === id}
            placeholder={manufacturingTypes.length ? '' : 'Manufacturing Type'}
            fullWidth
          />
        )}
        renderOption={(props, option) => (
          <MenuItem
            {...props}
            key={option.value}
            value={option.value}
            onClick={() => handleChange([...manufacturingTypes, option])}
          >
            {option.label}
          </MenuItem>
        )}
        multiple
        fullWidth
        componentsProps={{ popper: { style: { width: 'fit-content' }, placement: 'bottom-start' } }}
      />
    </Tooltip>
  )
}
