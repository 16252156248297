import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { GridColDef, GridColumnGroupingModel, GridComparatorFn, GridRowsProp } from '@mui/x-data-grid-premium'
import { Text } from '@howgood/design'

import { ALL_FOOD_INDUSTRY } from '@/constants/industryCategories'
import { WorkspaceGoalCell } from '@/features/PortfolioPage/components/WorkspaceGoalCell'
import { ScoreCell, ScoreCellParams, ScoreCellValue } from '@/components/GridWithMetrics/ScoreCell'
import { BenchmarkCell } from '@/features/PortfolioPage/components/BenchmarkCell'
import { CategorySelect } from '@/features/PortfolioPage/components/CategorySelect'
import { WorkspaceTypeCell } from '@/features/PortfolioPage/components/WorkspaceTypeCell'
import { AllWorkspaceScoresData, WorkspaceScores } from '@/state/workspaces'
import { WorkspaceNameCell } from '@/features/PortfolioPage/components/WorkspaceNameCell'
import { MetricHeaderCell } from '@/features/PortfolioPage/components/MetricHeaderCell'
import { ProductBrand, Workspace } from '@/records'
import { selectBenchmarkData, selectSalesCategories } from '@/state/salesCategories'
import { WorkspaceListCheckbox } from '@/state/user'
import {
  selectCurrentInitiative,
  selectCurrentInitiativeBaseline,
  selectCurrentInitiativeGoalMetrics,
  selectCurrentInitiativeScenarios,
  selectCurrentInitiativeScores,
  selectScenarioScores,
} from '@/state/initiatives'
import {
  ScenarioGoalCell,
  ScenarioGoalCellParams,
  ScenarioGoalCellValue,
} from '@/features/Initiative/ScenarioGrid/ScenarioGoalCell'
import { SpreadCell, SpreadCellParams, SpreadCellValue } from './SpreadCell'
import { selectContentfulMetricData } from '@/state/contentfulData'
import { MetricEntry } from '@/contentfully'
import { getContentData } from '@/records/scoreCardData'
import { DateCell, DateCellParams } from './DateCell'
import {
  ScenarioNameCell,
  ScenarioNameCellParams,
  ScenarioNameCellValue,
} from '@/features/Initiative/ScenarioGrid/ScenarioNameCell'
import { GridScenario, InitiativeBasic, Scenario } from '@/records/Initiatives'
import { WorkspaceAssigneeCell } from '@/features/PortfolioPage/components/WorkspaceAssigneeCell'
import {
  ScenarioAssigneeCell,
  ScenarioAssigneeCellParams,
} from '@/features/Initiative/ScenarioGrid/ScenarioAssigneeCell'
import {
  generateScoreLabel,
  getScenarioYear,
  getScenarioYearAsNumber,
  getScoreValues,
} from '@/state/initiatives/initiatives.utils'
import { ScenarioWorkspaceGoalCell } from '@/features/PortfolioPage/components/ScenarioWorkspaceCell'
import { selectGoalSettingSplit } from '@/state/splitio'
import { MetricOption, selectMetricGridMetricsOptions } from '@/selectors/selectAllWorkspaceGridOptions'
import { ScoreType, selectScoreType } from '@/state/pageSettings'
import { VendorNameCell } from '@/features/PortfolioPage/components/VendorNameCell'
import { ScenarioVendorGoalCell } from '@/features/PortfolioPage/components/ScenarioVendorGoalCell'

export const generateColumnGroupFields = (value) => [
  `${value}-score`,
  `${value}-goal`,
  `${value}-benchmark`,
  `${value}-spread`,
]

const assigneeComparator: GridComparatorFn<Workspace> = (a, b) => {
  // Sort by name if there is one, otherwise by email
  // Push unassigned to the bottom by assigning the character at the end of the unicode table (for ascending)
  return (a.assignee?.first_name || a.assignee?.last_name || a.assignee?.email || '\uFFFF').localeCompare(
    b.assignee?.first_name || b.assignee?.last_name || b.assignee?.email || '\uFFFF'
  )
}

export function useColumnGroupingModel(): GridColumnGroupingModel {
  const metricOptions = useSelector(selectMetricGridMetricsOptions)
  const columnGroupingModel = useMemo(
    () =>
      metricOptions.reduce((colGroups, metric) => {
        const groupFields = generateColumnGroupFields(metric.value)
        const colGroup = {
          groupId: metric.value,
          description: '',
          headerName: metric.label,
          renderHeaderGroup: () => <MetricHeaderCell metric={metric} />,
          children: groupFields.map((field) => ({ field })),
        }
        return [...colGroups, colGroup]
      }, []),
    [metricOptions]
  )

  return columnGroupingModel
}

const generateWorkspaceMetricColumns = (
  metricOptions: WorkspaceListCheckbox[],
  isGoalSettingEnabled: boolean,
  goalMetrics: string[],
  scenario: Scenario = null,
  scoreSetting: ScoreType,
  customFields: string[] = []
) => {
  return metricOptions
    .map((metric) => {
      const isCustom = customFields.includes(metric.key) // Custom metrics don't have benchmarks so make columns wider
      const groupFields = generateColumnGroupFields(metric.value)
      const isGoalEnabledMetric = isGoalSettingEnabled && scenario && goalMetrics.includes(metric.value)
      const score = {
        field: groupFields[0],
        renderHeader: () => (
          <Text variant="body2">
            {!metric?.units ? 'Score' : scoreSetting === 'displayedSum' ? 'Total' : 'Average'}
          </Text>
        ),
        renderCell: (params) => <ScoreCell {...params} metric={metric} />,
        sortComparator: (a, b) => a.score - b.score,
        width: isGoalEnabledMetric ? (isCustom ? 90 : 70) : isCustom ? 130 : 90,
      }
      const goal = {
        field: groupFields[1],
        renderHeader: () => <Text variant="body2">Goal</Text>,
        renderCell: (params) =>
          scenario ? (
            <ScenarioWorkspaceGoalCell {...params} metric={metric} scenario={scenario} />
          ) : (
            <WorkspaceGoalCell {...params} metric={metric} />
          ),
        sortComparator: (a, b) => a.goal - b.goal,
        width: 90,
      }
      const benchmark = {
        field: groupFields[2],
        renderHeader: () => <Text variant="body2">Benchmark</Text>,
        renderCell: (params) => <BenchmarkCell {...params} metric={metric} />,
        sortComparator: (a, b) => a.benchmark - b.benchmark,
        width: isGoalEnabledMetric ? 70 : 90,
      }
      if (isCustom) {
        return isGoalEnabledMetric ? [score, goal] : [score]
      }
      return isGoalEnabledMetric ? [score, goal, benchmark] : [score, benchmark]
    })
    .flat()
}

export function useWorkspaceColumns(scenario: Scenario = null, customFields: string[] = []): GridColDef[] {
  const metricOptions = useSelector(selectMetricGridMetricsOptions)
  const isGoalSettingEnabled = useSelector(selectGoalSettingSplit)
  const initiativeMetrics = useSelector(selectCurrentInitiativeGoalMetrics)
  const scoreSetting = useSelector(selectScoreType)
  const metricColumns = useMemo(
    () =>
      generateWorkspaceMetricColumns(
        metricOptions,
        isGoalSettingEnabled,
        initiativeMetrics.map((m) => m.value),
        scenario,
        scoreSetting,
        customFields
      ),
    [metricOptions, scenario, isGoalSettingEnabled, initiativeMetrics, scoreSetting, customFields]
  )

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Workspace',
        width: 210,
        renderCell: (params) => <WorkspaceNameCell {...params} />,
        sortComparator: (a: Workspace, b: Workspace) => a.name?.localeCompare(b.name),
      },
      {
        field: 'workspace_type',
        headerName: 'Workspace Type',
        width: 130,
        renderCell: (params) => <WorkspaceTypeCell {...params} />,
        sortComparator: (a: Workspace, b: Workspace) => a.workspace_type?.localeCompare(b.workspace_type),
      },
      {
        field: 'category',
        headerName: 'Benchmark Category',
        width: 200,
        renderCell: (params) => <CategorySelect {...params} />,
        sortComparator: (a: Workspace, b: Workspace) => {
          return (a.sales_category?.title || '').localeCompare(b.sales_category?.title || '')
        },
      },
      ...metricColumns,
      {
        field: 'assignee',
        headerName: 'Assignee',
        width: 200,
        renderCell: (params) => <WorkspaceAssigneeCell {...params} />,
        sortComparator: assigneeComparator,
      },
    ],
    [metricColumns]
  )

  return columns
}

const generateVendorMetricColumns = (
  metricOptions: WorkspaceListCheckbox[],
  isGoalSettingEnabled: boolean,
  goalMetrics: string[],
  scenario: Scenario = null,
  scoreSetting: ScoreType
) => {
  return metricOptions
    .map((metric) => {
      const groupFields = generateColumnGroupFields(metric.value)
      const isGoalEnabledMetric = isGoalSettingEnabled && scenario && goalMetrics.includes(metric.value)
      const score = {
        field: groupFields[0],
        renderHeader: () => (
          <Text variant="body2">
            {!metric?.units ? 'Score' : scoreSetting === 'displayedSum' ? 'Total' : 'Average'}
          </Text>
        ),
        renderCell: (params) => <ScoreCell {...params} metric={metric} />,
        sortComparator: (a, b) => a.score - b.score,
        width: isGoalEnabledMetric ? 70 : 120,
      }
      const goal = {
        field: groupFields[1],
        sortable: false,
        renderHeader: () => <Text variant="body2">Goal</Text>,
        renderCell: (params) => <ScenarioVendorGoalCell {...params} metric={metric} scenario={scenario} />,
        width: 90,
      }
      return isGoalEnabledMetric ? [score, goal] : [score]
    })
    .flat()
}

export function useVendorColumns(scenario: Scenario = null): GridColDef[] {
  const allAvailableMetrics = useSelector(selectMetricGridMetricsOptions)
  const isGoalSettingEnabled = useSelector(selectGoalSettingSplit)
  const metricsWithGoals = useSelector(selectCurrentInitiativeGoalMetrics)
  const scoreSetting = useSelector(selectScoreType)
  const metricColumns = useMemo(
    () =>
      generateVendorMetricColumns(
        allAvailableMetrics,
        isGoalSettingEnabled,
        metricsWithGoals.map((m) => m.value),
        scenario,
        scoreSetting
      ),
    [allAvailableMetrics, isGoalSettingEnabled, metricsWithGoals, scenario, scoreSetting]
  )

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Vendor',
        width: 210,
        renderCell: (params) => <VendorNameCell {...params} />,
        sortComparator: (a: ProductBrand, b: ProductBrand) => a.name?.localeCompare(b.name),
      },
      ...metricColumns,
    ],
    [metricColumns]
  )

  return columns
}

export function useWorkspaceRows(
  workspaces: Workspace[],
  workspaceImpactScores: AllWorkspaceScoresData = {}
): GridRowsProp {
  const metricOptions = useSelector(selectMetricGridMetricsOptions)
  const salesCategories = useSelector(selectSalesCategories)
  const benchmarkData = useSelector(selectBenchmarkData)
  const scoreSetting = useSelector(selectScoreType)
  const scoreType = scoreSetting === 'displayedSum' ? 'sum' : 'avg'

  const generateMetrics = (workspace: Workspace, scores: Partial<WorkspaceScores>) => {
    return metricOptions.reduce((acc, metric) => {
      const groupFields = generateColumnGroupFields(metric.value)
      const workspaceSalesCategory =
        salesCategories.find((s) => s.id === workspace.sales_category?.id) || ALL_FOOD_INDUSTRY

      const score = scores?.[`${[metric.value]}_${metric.units ? scoreType : 'avg'}`] || scores?.[metric.value] || null
      const count = metric.units && scoreType === 'sum' ? scores?.[`${[metric.value]}_count`] || 1 : 1
      const benchmark = benchmarkData[workspaceSalesCategory?.title]?.[metric.value] * count || null
      const goal = workspace.goals[metric.value] || null

      return {
        ...acc,
        [metric.value]: {
          workspace,
        },
        [groupFields[0]]: {
          id: workspace.id,
          score,
        },
        [groupFields[1]]: {
          workspace,
          goal,
        },
        [groupFields[2]]: {
          workspace,
          benchmark,
        },
      }
    }, {})
  }

  const rows = workspaces.map((workspace) => {
    const metrics = generateMetrics(workspace, workspaceImpactScores[workspace.id] || null)

    return {
      id: workspace.id,
      name: workspace,
      category: workspace,
      workspace_type: workspace.workspace_type,
      ...metrics,
      assignee: workspace,
    }
  })
  return rows
}

export function useVendorRows(brands: ProductBrand[], vendorImpactScores: any = {}): GridRowsProp {
  const allAvailableMetrics = useSelector(selectMetricGridMetricsOptions)
  const scoreSetting = useSelector(selectScoreType)
  const scoreType = scoreSetting === 'displayedSum' ? 'sum' : 'avg'

  const generateMetrics = (brand: ProductBrand, scores: Partial<any>) => {
    return allAvailableMetrics.reduce((acc, metric) => {
      const groupFields = generateColumnGroupFields(metric.value)

      const score = scores?.[`${[metric.value]}_${metric.units ? scoreType : 'avg'}`] || scores?.[metric.value] || null

      return {
        ...acc,
        [metric.value]: {
          vendor: brand,
        },
        [groupFields[0]]: {
          id: brand.id,
          score,
        },
        [groupFields[1]]: {
          vendor: brand,
          // Goals exist at the vendor level, not the brand level, so get inserted in the ScenarioVendorGoalCell
        },
      }
    }, {})
  }

  // Include only vendors that have scores (i.e. have products)
  const rows = brands.reduce((acc, brand) => {
    if (brand.id in vendorImpactScores) {
      const metrics = generateMetrics(brand, vendorImpactScores[brand.id])
      return [
        ...acc,
        {
          id: brand.id,
          name: brand,
          ...metrics,
        },
      ]
    }
    return acc
  }, [])
  return rows
}

export const generateSingleMetricColumns = ({
  metric,
  name = metric.value,
  units = null,
  editable = false,
  scoreSetting,
}) => {
  const groupFields = generateColumnGroupFields(metric.value)
  const score = {
    field: groupFields[0],
    headerName: `${name} (score)`,
    renderHeader: () => <Text variant="body2">{generateScoreLabel(units, scoreSetting)}</Text>,
    renderCell: (params: ScoreCellParams) => <ScoreCell {...params} metric={metric} />,
    sortComparator: (a, b) => a.score - b.score,
    valueFormatter: (params: ScoreCellValue) => params.score,
    width: 70,
  }
  const goal = {
    field: groupFields[1],
    headerName: `${name} (goal)`,
    renderHeader: () => <Text variant="body2">Goal</Text>,
    renderCell: (params: ScenarioGoalCellParams) => <ScenarioGoalCell {...params} metric={metric} />,
    sortComparator: (a, b) => a.avgGoal - b.avgGoal,
    valueFormatter: (params: ScenarioGoalCellValue) => params.avgGoal,
    width: editable ? 90 : 70,
  }
  const spread = {
    field: groupFields[3],
    headerName: `${name} (spread)`,
    renderHeader: () => <Text variant="body2">Spread</Text>,
    renderCell: (params: SpreadCellParams) => <SpreadCell {...params} metric={metric} units={units} />,
    valueFormatter: (params: SpreadCellValue) => params.spread,
    width: 70,
  }
  return { score, goal, spread }
}

const generateScenarioMetricColumns = (
  metricOptions: WorkspaceListCheckbox[],
  contentfulMetricData: MetricEntry[],
  scoreSetting: ScoreType
) => {
  return metricOptions
    .map((metric) => {
      const { contentfulEntry } = getContentData({
        key: metric.value,
        contentfulMetricData,
        showProcurementCarbonContent: true,
      })
      const { score, goal, spread } = generateSingleMetricColumns({
        metric,
        name: contentfulEntry?.title,
        units: contentfulEntry?.units,
        editable: true,
        scoreSetting,
      })
      return [score, goal, spread]
    })
    .flat()
}

export const generateScenarioColumns = (selectedInitiative: InitiativeBasic, sortable: boolean = true) => {
  const name = {
    field: 'name',
    headerName: 'Scenario',
    width: 200,
    renderCell: (params: ScenarioNameCellParams) => <ScenarioNameCell {...params} initiative={selectedInitiative} />,
    sortComparator: (a: { scenario: Scenario }, b: { scenario: Scenario }) =>
      a.scenario.name?.localeCompare(b.scenario.name),
    valueFormatter: (params: ScenarioNameCellValue) => params.scenario?.name,
    sortable,
  }
  const date = {
    field: 'date',
    headerName: 'Year',
    width: 73,
    sortable,
    renderCell: (params: DateCellParams) => <DateCell {...params} />,
    sortComparator: (a: Scenario, b: Scenario) =>
      getScenarioYearAsNumber(a.target_date) - getScenarioYearAsNumber(b.target_date),
    valueFormatter: (params: GridScenario) => (params?.target_date ? getScenarioYear(params.target_date) : ''),
  }
  const assignee = {
    field: 'assignee',
    headerName: 'Assignee',
    width: 200,
    renderCell: (params: ScenarioAssigneeCellParams) => <ScenarioAssigneeCell {...params} />,
    valueFormatter: (params: GridScenario) => params.assignee?.email || '',
    sortable,
    sortComparator: assigneeComparator,
  }
  return { name, date, assignee }
}

export function useScenarioColumns(): GridColDef[] {
  const metricOptions = useSelector(selectMetricGridMetricsOptions)
  const selectedInitiative = useSelector(selectCurrentInitiative)
  const contentfulMetricData = useSelector(selectContentfulMetricData)
  const scoreSetting = useSelector(selectScoreType)

  const columns: GridColDef[] = useMemo(() => {
    const metricColumns = generateScenarioMetricColumns(metricOptions, contentfulMetricData, scoreSetting)

    const { name, date, assignee } = generateScenarioColumns(selectedInitiative)

    return [name, date, ...metricColumns, assignee]
  }, [metricOptions, contentfulMetricData, selectedInitiative, scoreSetting])

  return columns
}

const generateScenarioMetrics = (
  metricOptions: MetricOption[],
  scenario: Scenario,
  scores: Partial<WorkspaceScores>,
  productCount: number,
  scoreType: ScoreType,
  isChartTable: boolean
) => {
  return metricOptions.reduce((acc, metric) => {
    const groupFields = generateColumnGroupFields(metric.value)
    const { score, goal, avgGoal, sumGoal, count } = getScoreValues(
      metric.value,
      scoreType,
      scores,
      scenario.goals,
      productCount
    )
    const spread = score && goal ? score - goal : null

    return {
      ...acc,
      [metric.value]: {
        scenario,
        isChartTable,
      },
      [groupFields[0]]: {
        id: scenario.id,
        score,
        isChartTable,
      },
      [groupFields[1]]: {
        scenario,
        avgGoal,
        sumGoal,
        count,
        isChartTable,
      },
      [groupFields[3]]: {
        scenario,
        spread,
        isChartTable,
      },
    }
  }, {})
}

export function useScenarioRows(isChartTable: boolean = false): GridRowsProp {
  const scoreSetting = useSelector(selectScoreType)
  const scenarios = useSelector(selectCurrentInitiativeScenarios)
  const metricOptions = useSelector(selectMetricGridMetricsOptions)
  const selectedInitiative = useSelector(selectCurrentInitiative)
  const scenarioImpactScores = useSelector(selectScenarioScores)
  const baseline = useSelector(selectCurrentInitiativeBaseline)
  const current = useSelector(selectCurrentInitiativeScores)
  const initiativeProductList = selectedInitiative?.products?.map((product) => product.id) || []

  const baselineData = {
    // negative id to prevent id collision with scenarios
    id: -baseline?.id || -selectedInitiative?.baseline?.id || 0,
    name: baseline?.name.replace('.csv', ' ') || 'Baseline',
    target_date: selectedInitiative?.baseline_date || baseline?.date_created || selectedInitiative?.date_created,
    goals: {},
    initiative: { id: selectedInitiative?.id },
    isBaseline: true,
  }
  const metadata = baseline?.metadata
    ? Object.entries(baseline.metadata).reduce((allData, item) => {
        allData[item[0]] = item[1]
        return allData
      }, {})
    : null
  const baselineMetrics = generateScenarioMetrics(
    metricOptions,
    baselineData,
    metadata,
    initiativeProductList.length,
    scoreSetting,
    isChartTable
  )
  const baselineRowData = {
    id: baselineData.id,
    name: { scenario: baselineData, isChartTable },
    date: baselineData,
    assignee: baselineData,
    ...baselineMetrics,
  }
  const currentData = {
    // negative id to prevent id collision with scenarios
    id: -selectedInitiative?.id,
    name: 'Current Scores',
    target_date: new Date().toLocaleDateString(),
    goals: {},
    initiative: { id: selectedInitiative?.id },
    isBaseline: true,
  }
  const currentMetrics = generateScenarioMetrics(
    metricOptions,
    currentData,
    current,
    initiativeProductList.length,
    scoreSetting,
    isChartTable
  )
  const currentRowData = {
    id: currentData.id,
    name: { scenario: currentData, isChartTable },
    date: currentData,
    assignee: currentData,
    ...currentMetrics,
  }

  const rows = scenarios.map((scenario) => {
    const metrics = generateScenarioMetrics(
      metricOptions,
      scenario,
      scenarioImpactScores[scenario.id] || null,
      initiativeProductList.length,
      scoreSetting,
      isChartTable
    )

    return {
      id: scenario.id,
      name: { scenario, isChartTable },
      date: scenario,
      assignee: scenario,
      ...metrics,
    }
  })
  return [baselineRowData, currentRowData, ...rows]
}
