import React, { FC, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@howgood/design'
import {
  getTopProductsAllMetrics,
  selectAvailableMetrics,
  selectAggregationsByMetric,
  selectSelectedMetric,
  setSelectedMetric,
} from '../state/productDashboard'
import { useUnsortedContentfulMetrics } from '@/contentfully/hooks'
import { Field } from '@/constants/impactScore'

interface MetricMenuItem {
  key: Field
  menuText: string
}

export const MetricSelect: FC = () => {
  const selectedMetric = useSelector(selectSelectedMetric)
  const aggregationsByMetric = useSelector(selectAggregationsByMetric)
  const dispatch = useDispatch()

  const availableMetrics = useSelector(selectAvailableMetrics)
  const metricEntries = useUnsortedContentfulMetrics(availableMetrics)
  const menuItems: MetricMenuItem[] = useMemo(
    () =>
      metricEntries
        .map((entry) => {
          return {
            key: entry.key,
            menuText: entry.subtitle ? `${entry.title} (${entry.subtitle})` : entry.title,
          }
        })
        .sort((a, b) => a.menuText.localeCompare(b.menuText)),
    [metricEntries]
  )

  const selectedMenuItem = menuItems.find((item) => item.key === selectedMetric)

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const metric = event.target.value as MetricMenuItem
    dispatch(setSelectedMetric(metric.key))

    // The initial load fetches results for the first metric only, to minimize dashboard load time
    // When the selected metric changes, fetch results for all metrics
    if (!(metric.key in aggregationsByMetric)) {
      dispatch(getTopProductsAllMetrics())
    }
  }

  return (
    <FormControl>
      <InputLabel id="metric-select-label">Metric</InputLabel>
      <Select
        labelId="metric-select-label"
        id="metric-select"
        value={selectedMenuItem || ''}
        label="Metric"
        onChange={handleChange}
      >
        {menuItems.map((item) => (
          <MenuItem key={item.key} value={item as any}>
            {item.menuText}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
