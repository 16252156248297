import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { selectAllMetricGridOptions } from '@/selectors/selectAllWorkspaceGridOptions'
import { selectMetricGridColumns, setSelectedMetricGridColumns } from '@/state/preferences'
import { ColumnSelect } from '@/components/ColumnSelect/ColumnSelect'

export const WorkspaceColumnSelect: FC = () => {
  const dispatch = useDispatch()
  const selected = useSelector(selectMetricGridColumns)
  const options = useSelector(selectAllMetricGridOptions)

  return (
    <ColumnSelect
      dataPendoId="WorkspaceColumnOptions"
      selected={selected}
      options={options}
      onChange={(updated) => dispatch(setSelectedMetricGridColumns(updated))}
    />
  )
}
