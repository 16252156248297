import React, { FC } from 'react'
import MuiDialog, { DialogProps as MuiDialogProps } from '@mui/material/Dialog'
import MuiDialogContent, { DialogContentProps as MuiDialogContentProps } from '@mui/material/DialogContent'
import MuiDialogActions, { DialogActionsProps as MuiDialogActionsProps } from '@mui/material/DialogActions'
import MuiDialogTitle, { DialogTitleProps as MuiDialogTitleProps } from '@mui/material/DialogTitle'
import { Text } from './Text'

interface DialogActionsProps extends Pick<MuiDialogActionsProps, 'children'> {}

export const DialogActions: FC<DialogActionsProps> = ({ children }) => {
  return <MuiDialogActions sx={{ pt: 0, px: 3, pb: 3 }}>{children}</MuiDialogActions>
}

interface DialogContentProps extends Pick<MuiDialogContentProps, 'children'> {}

export const DialogContent: FC<DialogContentProps> = ({ children }) => {
  return <MuiDialogContent>{children}</MuiDialogContent>
}

interface DialogTitleProps extends Pick<MuiDialogTitleProps, 'children'> {
  'data-testid'?: string
  subtitle?: string
}

export const DialogTitle: FC<DialogTitleProps> = ({ ['data-testid']: dataTestId, children, subtitle }) => {
  return (
    <MuiDialogTitle data-testid={dataTestId} pb={1}>
      <Text fontSize={20} fontWeight={600}>
        {children}
      </Text>
      {subtitle && <Text>{subtitle}</Text>}
    </MuiDialogTitle>
  )
}

const dialogBreakpoints = {
  xs: 440,
  sm: 712,
  md: 960,
  lg: 1152,
  xl: 1400,
}
interface DialogProps extends Pick<MuiDialogProps, 'id' | 'maxWidth' | 'open' | 'onClose' | 'children'> {
  ['data-testid']?: string
}

export const Dialog: FC<DialogProps> = ({ ['data-testid']: dataTestId, id, maxWidth, open, onClose, children }) => {
  return (
    <MuiDialog
      data-testid={dataTestId}
      id={id}
      maxWidth={maxWidth}
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { pt: 1, maxWidth: maxWidth ? dialogBreakpoints[maxWidth] : null } }}
      fullWidth
    >
      {children}
    </MuiDialog>
  )
}
