import React, { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@howgood/design'
import { Region } from '@/api'
import {
  RecipeIngredient,
  selectDisplayedRecipe,
  selectDisplayedSalesAndDistribution,
  selectDisplayedTransportation,
  updateRecipeAndScores,
} from '@/state/recipe'
import { ProcessingLocationInput } from '../ProcessingLocationInput'
import { CustomTransportation, TransportationData, TransportationType } from './TransportationData'
import { selectTransportationModes } from '@/state/transportation'

interface Props {
  open: boolean
  onClose: () => void
  ingredient: RecipeIngredient
}

export const TransportationDialog: FC<Props> = ({ open, onClose, ingredient }) => {
  const dispatch = useDispatch()
  const displayedRecipe = useSelector(selectDisplayedRecipe)
  const displayedProductTransportation = useSelector(selectDisplayedTransportation)
  const salesAndDistribution = useSelector(selectDisplayedSalesAndDistribution)
  const ingredients = displayedRecipe.ingredients
  const modes = useSelector(selectTransportationModes)
  const [processingLocation, setProcessingLocation] = useState<Region>({
    id: ingredient.processing_location_id,
    name: ingredient.processing_location_name,
  })
  const productMtr = displayedProductTransportation.mtr
  const productManufacturingRegion = productMtr.length
    ? productMtr[0]?.waypoint
    : salesAndDistribution.manufacturing_region
  const ingredientTransportation = ingredient?.route_legs?.ptm?.[0]

  const defaultTransportation = {
    mode: ingredientTransportation?.mode ? modes.find((m) => m.id === ingredientTransportation.mode.id) : null,
    distance_override: ingredientTransportation?.distance_override || null,
    waypoint: productManufacturingRegion,
  }
  const [customTransportation, setCustomTransportation] = useState<CustomTransportation>(defaultTransportation)

  const defaultTransportationType = ingredientTransportation
    ? ('custom' as TransportationType)
    : ('latis-default' as TransportationType)
  const [transportationType, setTransportationType] = useState<TransportationType>(defaultTransportationType)

  // Mode and manufacturing region are required
  const saveDisabled =
    transportationType === 'custom' ? !customTransportation.mode || !customTransportation.waypoint : false

  function onSave() {
    const ingredientProcessingLocationUpdate = processingLocation
      ? { processing_location_id: processingLocation.id, processing_location_name: processingLocation.name }
      : {}
    const ingredientTransportationUpdate =
      transportationType === 'latis-default'
        ? { route_legs: { ptm: [], mtm: [], ftp: [] } }
        : { route_legs: { ptm: [customTransportation], mtm: [], ftp: [] } }
    // At product level, replace first route waypoint (manufacturing region)
    const productTransportation = productMtr?.length
      ? {
          mtr: productMtr.map((mtr, i) => (i === 0 ? { ...mtr, waypoint: customTransportation.waypoint } : mtr)),
          mts: [],
        }
      : { mtr: [], mts: [] }
    dispatch(
      updateRecipeAndScores({
        recipeUpdates: {
          // Update product manufacturing region
          transportation: productTransportation,
          sales_distribution: {
            ...displayedRecipe.sales_distribution,
            manufacturing_region: customTransportation.waypoint,
          },
          // Update ingredient-level transportation
          ingredients: ingredients.map((ing) =>
            ing.id === ingredient.id
              ? { ...ing, ...ingredientProcessingLocationUpdate, ...ingredientTransportationUpdate }
              : ing
          ),
        },
        change: 'Updated product mtr transportation',
      })
    )
    onClose()
  }

  function onChangeTransportation(change: Partial<CustomTransportation>) {
    const transportationUpdate = transportationType === 'latis-default' ? defaultTransportation : customTransportation
    setCustomTransportation({ ...transportationUpdate, ...change })
  }

  return (
    <Dialog data-testid="transportation-dialog" open={open}>
      <DialogTitle data-testid="transportation-dialog-title">Processing & Transportation</DialogTitle>
      <DialogContent>
        <Stack gap={2} pt={1}>
          <ProcessingLocationInput
            label="Processing Location"
            ingredient={{
              ...ingredient,
              processing_location_id: processingLocation.id,
              processing_location_name: processingLocation.name,
            }}
            onChange={(region) => setProcessingLocation(region)}
          />
          <TransportationData
            mode={customTransportation.mode}
            distance={customTransportation.distance_override}
            region={customTransportation.waypoint}
            type={transportationType}
            onChange={onChangeTransportation}
            onChangeType={(type) => setTransportationType(type)}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack flexDirection="row" gap={1}>
          <Button data-testid="transportation-dialog-cancel-button" onClick={onClose}>
            Cancel
          </Button>
          <Button
            data-testid="transportation-dialog-save-button"
            color="primary"
            onClick={onSave}
            disabled={saveDisabled}
          >
            Save
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}
