import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { faFileCsv, faFileExcel, faScroll } from '@fortawesome/pro-solid-svg-icons'
import { Stack, Drawer, Box, Text, Button } from '@howgood/design'

import { selectIsVendors } from '@/state/router'
import { ReportHistory } from './ReportHistory'
import { ExportDialog } from '../Products/MUIExport/ExportDialog'
import { ExportType } from '../Products/MUIExport/ExportDialog.types'

interface ReportsDrawerProps {
  reportsDrawerOpen: boolean
  closeReportsDrawer: () => void
}

export const ReportsDrawer: FC<ReportsDrawerProps> = ({ reportsDrawerOpen, closeReportsDrawer }) => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [exportType, setExportType] = useState<ExportType>()

  const isVendors = useSelector(selectIsVendors)

  function handleClose() {
    closeReportsDrawer()
  }
  function onClick(type: ExportType) {
    setExportType(type)
    setDialogOpen(true)
  }

  return (
    <Drawer data-testid="reports-drawer" open={reportsDrawerOpen} onClose={handleClose}>
      <Box width={632}>
        <Stack gap={2}>
          <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
            <Text variant="h2">Reports</Text>
            <Stack flexDirection="row" gap={1}>
              <Button startIcon={faFileExcel} size="small" onClick={() => onClick('download-excel')}>
                Download Excel
              </Button>
              <Button startIcon={faFileCsv} size="small" onClick={() => onClick('download-csv')}>
                Download CSV
              </Button>
              {!isVendors && (
                <Button startIcon={faScroll} size="small" onClick={() => onClick('save')}>
                  Save in Latis
                </Button>
              )}
            </Stack>
          </Stack>
          <ReportHistory />
        </Stack>
        <ExportDialog open={dialogOpen} onClose={() => setDialogOpen(false)} exportType={exportType} />
      </Box>
    </Drawer>
  )
}
