import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Box, Stack, Text, Tooltip } from '@howgood/design'
import { BestInClassIcon } from '@/components/Icons/BestInClassIcon'
import { selectIsProcurement } from '@/state/router'
import { ProductListRenderCellParams } from './ProductGridTypes'

interface Props extends ProductListRenderCellParams {
  value?: string
  showBulkActions: boolean
  scenarioId?: number
}

export const FormulaNameRenderer: FC<Props> = ({ value, id, row, scenarioId, showBulkActions }) => {
  const isProcurement = useSelector(selectIsProcurement)
  let path = id ? (isProcurement ? `/materials/${id}` : `/products/${id}`) : '/formulas'
  if (scenarioId) {
    path += `/?scenario=${scenarioId}`
  }

  return (
    <Tooltip disableInteractive title={value}>
      <Box width="100%">
        <Link to={path} disabled={showBulkActions} data-testid={`formula-name-link-${id}`} data-pendo-id="AnyFormula">
          <Stack
            alignItems="center"
            direction="row"
            ml={0.5}
            gap={1}
            sx={{ '&:hover': { textDecoration: 'underline' } }}
          >
            {!!row.best_in_class && <BestInClassIcon fill="#009963" />}
            <Text data-testid={`formula-name-${id}`} variant="body2" overflow="hidden" textOverflow="ellipsis">
              {value}
            </Text>
          </Stack>
        </Link>
      </Box>
    </Tooltip>
  )
}
