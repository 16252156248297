import { Workspace } from '@/records'

export const createIWorkspaceOption = (workspace: Workspace) => ({
  id: workspace.id,
  value: workspace.id,
  label: workspace.name,
  name: workspace.name,
  edit: workspace.edit,
  read: workspace.read,
  workspace_type: workspace.workspace_type,
  foreign_read: workspace.foreign_read,
  is_published: workspace.is_published,
  ownership: workspace.ownership,
})
