export function getDateParts() {
  const date = new Date()

  const year = date
    .getFullYear()
    .toString()
    .substr(2)
  const day = date
    .getDate()
    .toString()
    .padStart(2, '0')
  const month = (date.getMonth() + 1).toString().padStart(2, '0')

  return { year, month, day }
}

// Converts the object key for claims reports to a date time
export const parseReportDateKey = (dateString: string) => {
  // Format the string to a format recognized by Date.parse
  const formattedString = dateString.replace(/(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1-$2-$3T$4:$5:$6Z')

  // Parse the formatted string to create a Date object
  return new Date(Date.parse(formattedString))
}

type DateType = 'long' | 'medium' | 'short'
export const LONG = 'long' as DateType // '7/11/2023'
export const MEDIUM = 'medium' as DateType // 'Jul 11, 2023'
export const SHORT = 'short' as DateType // 'July 11, 2023'
type FormatDateStringT = {
  dateString?: string
  type?: DateType
  withTime?: boolean // date + 12:34:11 PM
}
export const formatDateString = ({ dateString, type = SHORT, withTime }: FormatDateStringT) => {
  const results = []
  const date = dateString ? new Date(dateString) : new Date()
  switch (type) {
    case SHORT:
      results.push(date.toLocaleDateString())
      break
    case MEDIUM:
      results.push(
        date?.toLocaleDateString('en-us', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        })
      )
      break
    case LONG:
      results.push(
        date.toLocaleDateString('en-us', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })
      )
      break
    default:
      results.push(date.toLocaleDateString())
  }
  if (withTime) {
    results.push(date.toLocaleTimeString())
  }
  return results.join(' ')
}
