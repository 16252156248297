import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@howgood/design'

import { selectIsImpactsOverridden, selectProductIsLocked } from '@/state/productOverview'
import { MainDrawer } from './MainDrawer'
import { DRAFT, selectPageView, setReportToolkitDrawer } from '@/state/pageSettings'

export const MainButton: FC = () => {
  const dispatch = useDispatch()
  const isProductLocked = useSelector(selectProductIsLocked)
  const view = useSelector(selectPageView)
  const impactsOverriddenProduct = useSelector(selectIsImpactsOverridden)

  return (
    <>
      {view !== DRAFT && (
        <Button
          id="validation-flow-button"
          data-testid="validation-flow-button"
          size="small"
          onClick={() => dispatch(setReportToolkitDrawer(true))}
          color="primary"
          disabled={impactsOverriddenProduct}
        >
          {isProductLocked ? 'Open report toolkit' : 'Create report'}
        </Button>
      )}
      <MainDrawer />
    </>
  )
}
