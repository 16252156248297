import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Stack } from '@howgood/design'
import { initializeProductsPage } from '@/state/products'
import { Products } from '@/components'
import { Scores } from '@/components/Scores/Scores'
import { WorkspaceProductFilter } from '@/components/ProductsFilters/WorkspaceProductFilter'
import { FormulaNameFilter } from '@/components/ProductsFilters/FormulaNameFilter'
import { TagsFilter } from '@/components/ProductsFilters/TagsFilter'
import { IngredientFilter } from '@/components/ProductsFilters/IngredientFilter'
import { useScoreCardDataReady } from '@/components/Scores/ScoreCards'
import { FormulationStatusProductFilter } from '@/components/ProductsFilters/FormulationStatusProductFilter'
import { StandardsFilter } from '@/components/ProductsFilters/StandardsFilter'
import { MaterialDestinationFilter } from '@/components/ProductsFilters/MaterialDestinationFilter'
import { useLiteUserPaywallDialogForPages } from '@/components/LiteUserPaywallDialog/LiteUserPaywallDialog.hook'
import { getCurrentVendorId } from '@/utils/getUrl'
import { ContainsComponentFilter } from '@/components/ProductsFilters/ContainsComponentFilter'
import { getVendorDetails } from '@/state/vendor'
import { initializeInitiativeDetail, selectInitiativeByVendorId } from '@/state/initiatives'
import { useHistory } from 'react-router'
import { VendorDetailHeader } from './VendorDetailHeader'

export const VendorDetailPage: FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const vendorId = getCurrentVendorId()
  const vendorInitiative = useSelector(selectInitiativeByVendorId(vendorId))
  useLiteUserPaywallDialogForPages()
  const isScoreDataLoaded = useScoreCardDataReady()

  useEffect(() => {
    dispatch(getVendorDetails(vendorId))
    if (vendorInitiative?.id) {
      dispatch(initializeInitiativeDetail(vendorInitiative?.id, history))
    }
    // the actual name isn't needed, so intead of awaiting the getVendorDetails response, just dispatch the action
    dispatch(initializeProductsPage({ vendors: [{ value: vendorId, label: 'Vendor' }], workspaceTypes: [] }))
  }, [dispatch, vendorId, vendorInitiative?.id, history])

  return (
    <Stack>
      <VendorDetailHeader />
      <Stack data-testid="product-list-with-filters">
        <Stack flexDirection="row" alignItems="center" justifyContent="stretch" gap={1} mb={2}>
          <WorkspaceProductFilter />
          <FormulaNameFilter />
          <IngredientFilter />
          <ContainsComponentFilter />
          <FormulationStatusProductFilter />
          <TagsFilter />
          <StandardsFilter />
          <MaterialDestinationFilter />
        </Stack>
        {isScoreDataLoaded && <Scores />}
        <Products />
      </Stack>
    </Stack>
  )
}
