import React, { FC, useMemo, useState } from 'react'
import { LegacyCard, InlineSelect, Text, Box } from '@howgood/design'
import { Field } from '@/constants/impactScore'
import { CarbonFootprintBreakdownChart } from '@/components/Procurement/CarbonFootprintBreakdown/CarbonFootprintBreakdownChart'
import { roundBy } from '@/utils/numbers'
import { useContentfulMetric } from '@/contentfully/hooks'
import { Loader } from '@/components/Layout'
import { UNKNOWN_VENDOR_LABEL } from '@/records'

const CARBON_FOOTPRINT_MATERIAL_BREAKDOWN_KEY_SEPARATOR = 'CARBON_FOOTPRINT_MATERIAL_BREAKDOWN_KEY_SEPARATOR'

interface CarbonFootprintBreakdownData {
  totalCarbonFootprint: number
  carbonFootprintByVendor: Record<string, number>
  carbonFootprintByMaterial: Record<string, number>
}
interface CarbonFootprinBreakdownProps {
  data: CarbonFootprintBreakdownData
  headerPrefix?: string
  field: Field
  subTitleOverride?: string
  isLoading?: boolean
}

export const CarbonFootprintBreakdown: FC<CarbonFootprinBreakdownProps> = ({
  data,
  headerPrefix = 'Carbon Footprint',
  field,
  subTitleOverride,
  isLoading = false,
}) => {
  const [type, setType] = useState<'vendor' | 'material'>('material')

  const content = useContentfulMetric(field)
  const subTitle = subTitleOverride ?? content?.subtitle ?? field

  const top5Vendors = useMemo(() => {
    const values = Object.entries(data.carbonFootprintByVendor)
      .filter((item) => item[1])
      .map((item) => ({
        name: item[0],
        value: item[1],
        pct: roundBy((item[1] / data.totalCarbonFootprint) * 100, 0),
      }))

    if (values.length === 0) {
      return []
    }

    const unknownVendor = values.splice(
      values.findIndex((v) => v.name === UNKNOWN_VENDOR_LABEL),
      1
    )[0]

    values.sort((a, b) => b.value - a.value)

    const top4Vendors = values.splice(0, 4)
    const top4VendorsPct = top4Vendors.reduce((acc, item) => acc + item.pct, 0)
    const rest = values.reduce((acc, item) => acc + item.value, 0)
    let result = []
    if (unknownVendor) {
      if (top4Vendors.length < 4) {
        result = [...top4Vendors, unknownVendor]
        if (rest) {
          result.push({ value: rest, name: 'Other', pct: 100 - top4VendorsPct - unknownVendor.pct })
        }
      } else if (rest) {
        const last = top4Vendors.pop()
        result = [
          ...top4Vendors,
          unknownVendor,
          { value: rest + last.value, name: 'Other', pct: 100 - top4VendorsPct - unknownVendor.pct + last.pct },
        ]
      } else {
        result = [...top4Vendors, unknownVendor]
      }
    } else {
      result = [...top4Vendors, { value: rest, name: 'Other', pct: 100 - top4VendorsPct }]
    }

    result.forEach((res) => {
      if (res.name !== 'Other' && res.pct === 0) {
        res.pct = 1
        result.at(-1).pct = result.at(-1).pct - 1 || 1
      }
    })

    return result
  }, [data])

  const top5Materials = useMemo(() => {
    const values = Object.entries(data.carbonFootprintByMaterial)
      .filter((item) => item[1])
      .map((item) => ({
        name: item[0].split(CARBON_FOOTPRINT_MATERIAL_BREAKDOWN_KEY_SEPARATOR)[0],
        value: item[1],
        pct: roundBy((item[1] / data.totalCarbonFootprint) * 100, 0),
      }))

    if (values.length === 0) {
      return []
    }

    values.sort((a, b) => b.value - a.value)

    if (values.length <= 5) {
      return values
    }

    const top4Materials = values.splice(0, 4)
    const top4MaterialsPct = top4Materials.reduce((acc, item) => acc + item.pct, 0)
    const rest = values.reduce((acc, item) => acc + item.value, 0)
    const result = [...top4Materials, { value: rest, name: 'Other', pct: 100 - top4MaterialsPct }]

    result.forEach((res) => {
      if (res.name !== 'Other' && res.pct === 0) {
        res.pct = 1
        result.at(-1).pct = result.at(-1).pct - 1 || 1
      }
    })

    return result
  }, [data])

  const hasVendorsData = top5Vendors.length > 0

  const hasMaterialsData = top5Materials.length > 0

  return (
    <LegacyCard>
      <Text variant={'h4'}>
        {headerPrefix}
        <InlineSelect
          placeholder={type === 'vendor' ? 'Vendor' : 'Material'}
          items={[
            {
              label: 'Vendor',
              onClick: () => setType('vendor'),
            },
            {
              label: 'Material',
              onClick: () => setType('material'),
            },
          ]}
          hasSurroundingText
        />
        Breakdown
      </Text>
      <Text variant={'body2'} fontSize={12}>
        {subTitle}
      </Text>
      {isLoading && (
        <Box height={270} width={390}>
          <Loader />
        </Box>
      )}
      {type === 'vendor' && hasVendorsData && (
        <CarbonFootprintBreakdownChart
          data={top5Vendors}
          total={data.totalCarbonFootprint}
          units={content?.unitsTotal}
        />
      )}
      {type === 'material' && hasMaterialsData && (
        <CarbonFootprintBreakdownChart
          data={top5Materials}
          total={data.totalCarbonFootprint}
          units={content?.unitsTotal}
        />
      )}
    </LegacyCard>
  )
}
