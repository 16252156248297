import React, { ChangeEvent, FC } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/pro-regular-svg-icons'
import { TextField } from '@howgood/design'

interface Props {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  value: any
  placeholder: string
}

export const InputSearchBar: FC<Props> = ({ onChange, value, placeholder }) => {
  return (
    <TextField
      placeholder={placeholder}
      InputProps={{
        startAdornment: <FontAwesomeIcon icon={faSearch} style={{ fontSize: 'inherit', marginRight: '8px' }} />,
        sx: { fontSize: '14px', width: '300px' },
      }}
      value={value}
      onChange={onChange}
    />
  )
}
