import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { GridColumnVisibilityModel } from '@mui/x-data-grid-premium'

import {
  useAttributeColumns,
  useCarbonFootprintColumns,
  useCarbonLifecycleColumns,
  useDataHealthColumns,
  useHeatmapScoreColumns,
  useImpactColumns,
  useSDGColumns,
  useScope3Columns,
  useTier2Columns,
} from './ProductList.hooks'
import { useDefaultColumns, useDetailColumns, useWorkflowColumns } from './useNonMetricColumns'
import { ProductsGridColDef } from '@/components/GridCellRenderers'
import { selectProductListSelectedColumns } from '@/state/user'
import { selectProductsSortOptions } from '@/state/products'
import { selectIsSupplierConnect } from '@/state/organization'

export const useProductListColumns = ({
  showBulkActions = false,
  scenarioId = null,
  ignoreRenderCell = false,
}: {
  showBulkActions?: boolean
  scenarioId?: number
  ignoreRenderCell?: boolean
}): ProductsGridColDef[] => {
  const defaultColumns = useDefaultColumns(showBulkActions, scenarioId)
  const impactColumns = useImpactColumns()
  const scope3Cols = useScope3Columns()
  const detailColumns = useDetailColumns()
  const carbonFootprintColumns = useCarbonFootprintColumns()
  const productMetricColumns = useHeatmapScoreColumns()
  const tier2Columns = useTier2Columns()
  const attributeColumns = useAttributeColumns()
  const carbonLifecycleColumns = useCarbonLifecycleColumns()
  const dataHealthColumns = useDataHealthColumns()
  const sdgColumns = useSDGColumns()
  const productsWorkflowColumns = useWorkflowColumns()

  return useMemo(() => {
    const columns = [
      ...defaultColumns,
      ...detailColumns,
      ...impactColumns,
      ...scope3Cols,
      ...carbonLifecycleColumns,
      ...carbonFootprintColumns,
      ...productMetricColumns,
      ...tier2Columns,
      ...attributeColumns,
      ...dataHealthColumns,
      ...sdgColumns,
      ...productsWorkflowColumns,
    ]

    if (ignoreRenderCell) {
      return columns.map((col) => {
        return {
          ...col,
          renderCell: undefined,
        }
      })
    }

    return columns
  }, [
    defaultColumns,
    impactColumns,
    scope3Cols,
    detailColumns,
    carbonFootprintColumns,
    productMetricColumns,
    tier2Columns,
    attributeColumns,
    carbonLifecycleColumns,
    dataHealthColumns,
    sdgColumns,
    productsWorkflowColumns,
    ignoreRenderCell,
  ])
}

// Create a column visibility model for the MUI DataGrid
// Record<Fieldname, boolean>
export const useColumnVisibilityModel = (columns: ProductsGridColDef[]): GridColumnVisibilityModel => {
  const selectedColumns = useSelector(selectProductListSelectedColumns)
  const sortOptions = useSelector(selectProductsSortOptions)
  // SupplierConnect users do cannot select column so we show them all available
  const isSupplierConnect = useSelector(selectIsSupplierConnect)

  return useMemo(
    () =>
      columns.reduce((visModel, col) => {
        const visible =
          isSupplierConnect ||
          selectedColumns.find((item) => item === col.field) ||
          (sortOptions?.length && col.sortfield === Object.keys(sortOptions[0])[0])
        if (!visible) {
          visModel[col.field] = false
          return visModel
        }
        visModel[col.field] = true
        return visModel
      }, {}),
    [columns, selectedColumns, sortOptions, isSupplierConnect]
  )
}
