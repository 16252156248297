import React, { forwardRef, useImperativeHandle } from 'react'
import { useSelector } from 'react-redux'
import { IFormulationSearchOption, IImpactMetricOption, IProduct, Organization } from '@/records'
import { Box, Stack, Text } from '@howgood/design'
import { savePDF } from '@progress/kendo-react-pdf'
import { getDateParts } from '@/utils/dates'
import { IndustryBenchmark } from '@/components/ProductImpact/ExportProductOverview/IndustryBenchmark'
import { useIndustryBenchmark } from '@/api/elastic/industryBenchmarkEsRepo'
import { selectWorkspacesIds } from '@/state/workspaces'
import { Field } from '@/constants/impactScore'
import { roundBy } from '@/utils/numbers'
import { ImpactScore } from '@/api'
import { transformScoreToZeroToTenScale } from '@/utils/impactScoreHelpers'
import { CategoryType } from '@/components/ProductImpact/ExportProductOverview/CategoryType'
import {
  FormulationTagOption,
  PortfolioOption,
  WorkspaceOption,
} from '@/components/SustainabilityScorecard/useScorecardPortfolioOptions'
import { useContentfulMetric } from '@/contentfully/hooks'
import { useProductOverviewExportContainerStyles } from './ProductOverviewExportContainer.styles'
import { SustainabilityScorecardExplanation } from './SustainabilityScorecardExplanation'
import { ProductOverview } from './ProductOverview'
import { PortfolioType } from './PortfolioSelect'
import { ProductOverviewExportFooter } from './ProductOverviewExportFooter'
import { useScorecardFields } from '@/components/SustainabilityScorecard/useScorecardFields'
import { ProductOverviewVendor } from './ProductOverviewVendor'
import { FunctionalCategoryOption, NO_CATEGORY_OPTION, SalesCategoryOption } from '@/constants/industryCategories'

interface OwnProps {
  product: IProduct | null
  category: SalesCategoryOption | FunctionalCategoryOption
  categoryType: CategoryType
  portfolioType: PortfolioType
  portfolio: PortfolioOption
  metric: IImpactMetricOption
  organization?: Organization
  title: string
  enableClaims?: boolean
  impactScore: ImpactScore | null
  comparisonProduct?: IFormulationSearchOption
  comparisonProductImpactScore?: ImpactScore | null
  onDownloadComplete?: () => void
  onReady?: () => void
  isInProductComparisonScorecard?: boolean
}

export interface ProductOverviewExportContainerApi {
  exportPdf: () => void
}

export const ProductOverviewExportContainer = forwardRef<ProductOverviewExportContainerApi, OwnProps>(
  (
    {
      product,
      category,
      categoryType,
      portfolio,
      portfolioType,
      metric,
      title,
      impactScore,
      comparisonProduct,
      comparisonProductImpactScore,
      enableClaims = true,
      organization,
      onDownloadComplete,
      onReady,
      isInProductComparisonScorecard,
    }: OwnProps,
    ref
  ) => {
    const classes = useProductOverviewExportContainerStyles()

    const { year, month, day } = getDateParts()
    const fileName = `${title} - Sustainability Scorecard - ${month}.${day}.${year}`
    const pdfFileName = `${fileName}.pdf`
    const containerRef = React.useRef()

    const handleExportPdf = () => {
      let element = containerRef.current
      savePDF(element, {
        paperSize: 'auto',
        margin: 32,
        fileName: pdfFileName,
        forcePageBreak: '.page-break',
      })

      if (onDownloadComplete) {
        onDownloadComplete()
      }
    }

    useImperativeHandle(ref, () => {
      return {
        exportPdf: () => handleExportPdf(),
      }
    })

    let workspaceIds = useSelector(selectWorkspacesIds)
    let formulationTag = ''

    if (portfolioType === 'workspace') {
      const workspaceOption = portfolio as WorkspaceOption
      if (workspaceOption.value && workspaceOption.value !== 'no') {
        workspaceIds = [workspaceOption.value]
      }
    } else {
      const formulationTagOption = portfolio as FormulationTagOption
      if (formulationTagOption.value && formulationTagOption.value !== 'no') {
        formulationTag = formulationTagOption.label
      }
    }

    const { mainField, displayField, showGhgDisclaimer } = useScorecardFields(metric, impactScore)

    const metricContentfulEntry = useContentfulMetric(metric.value)

    const { data: industryBenchmarkData, isLoading } = useIndustryBenchmark({
      workspaceIds,
      formulationTag,
      categoryType,
      category: category.value ? category.label : null,
      field: metric.value as Field,
    })

    const rawImpactScore = impactScore?.[metric.value]
    const comparisonProductRawImpactScore = comparisonProductImpactScore?.[metric.value]

    let scaledImpactScore =
      metric.value === 'howgood_one_metric'
        ? rawImpactScore
        : transformScoreToZeroToTenScale(metric.value, rawImpactScore)
    const comparisonProductScaledImpactScore =
      metric.value === 'howgood_one_metric'
        ? comparisonProductRawImpactScore
        : transformScoreToZeroToTenScale(metric.value, comparisonProductRawImpactScore)

    let unit = metricContentfulEntry?.units || null

    let rawImpactScoreFormatted
    let comparisonProductRawImpactScoreFormatted
    switch (metric.value) {
      case 'raw_greenhouse_gas_impact':
        rawImpactScoreFormatted = roundBy(rawImpactScore, 2)
        comparisonProductRawImpactScoreFormatted = roundBy(comparisonProductRawImpactScore, 2)
        break
      case 'raw_blue_water_usage_impact':
        unit = 'm3/ton'
        rawImpactScoreFormatted = roundBy(rawImpactScore, 2)
        comparisonProductRawImpactScoreFormatted = roundBy(comparisonProductRawImpactScore, 2)
        break
      case 'howgood_one_metric':
        rawImpactScoreFormatted = roundBy(rawImpactScore, 0)
        comparisonProductRawImpactScoreFormatted = roundBy(comparisonProductRawImpactScore, 0)
        break
      default:
        rawImpactScoreFormatted = roundBy(rawImpactScore, 1)
        comparisonProductRawImpactScoreFormatted = roundBy(comparisonProductRawImpactScore, 1)
    }

    return (
      <div ref={containerRef} className={`${classes.container} ProductImpact__pdfExportContainer`}>
        <div className={classes.scorecardFirstPage}>
          <Stack
            direction={comparisonProduct ? 'row-reverse' : 'column'}
            alignItems={comparisonProduct ? 'flex-end' : 'flex-start'}
            justifyContent="space-between"
            sx={{
              borderBottom: '1px solid #EBEBEB',
              marginBottom: '24px',
            }}
          >
            <Text variant="body1" fontWeight="bold">
              HowGood Sustainability Scorecard
            </Text>
            <Box mt={0.8} pb={1.6}>
              <Text variant="h1" fontSize={40}>
                {title}
              </Text>
            </Box>
            {!comparisonProduct && <ProductOverviewVendor name={product?.brand?.name} />}
          </Stack>
          <ProductOverview // clean up once the legacy page is gone and there is only one place to get the product and impact score
            product={product}
            category={category}
            categoryType={categoryType}
            organization={organization}
            enableClaims={enableClaims}
            impactScore={impactScore}
            comparisonProduct={comparisonProduct}
            comparisonProductImpactScore={comparisonProductImpactScore}
            workspaceIds={workspaceIds}
            formulationTag={formulationTag}
            mainField={mainField}
          />

          <IndustryBenchmark
            data={industryBenchmarkData}
            impactScore={scaledImpactScore}
            comparisonProductImpactScore={comparisonProductScaledImpactScore}
            rawImpact={rawImpactScoreFormatted}
            comparisonProducRawImpact={comparisonProductRawImpactScoreFormatted}
            productName={comparisonProduct ? product?.name || organization.organizationName : title}
            comparisonProductName={comparisonProduct?.label}
            categoryLabel={category.label}
            categoryType={categoryType}
            metric={metric}
            loading={isLoading}
            unit={unit}
            porfolioTitle={
              !portfolio?.value
                ? `${organization?.organizationName} Portfolio`
                : `${portfolio.label}${portfolioType === 'workspace' ? ' Portfolio' : ''}`
            }
            hidePortfolioLine={portfolio?.value === 'no'}
            hideProductLine={!product}
            hideIndustry={category.label === NO_CATEGORY_OPTION.label}
            onReady={onReady}
          />
          <ProductOverviewExportFooter
            showGhgDisclaimer={showGhgDisclaimer}
            displayField={displayField}
            isInProductComparisonScorecard={isInProductComparisonScorecard}
          />
        </div>
        <SustainabilityScorecardExplanation page={2} />
        <SustainabilityScorecardExplanation page={3} />
      </div>
    )
  }
)

ProductOverviewExportContainer.displayName = 'ProductOverviewExportContainer'
