import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { IImpactMetricOption, ProductBrand } from '@/records'
import { GoalCell } from '@/components/GridWithMetrics/GoalCell'
import { Scenario } from '@/records/Initiatives'
import { selectScenarioVendorGoalsByVendor, selectisScenarioLoading, updateScenario } from '@/state/initiatives'

interface CellParams extends GridRenderCellParams {
  metric: IImpactMetricOption
  value: { vendor: ProductBrand }
  scenario: Scenario
}

export const ScenarioVendorGoalCell: FC<CellParams> = ({ metric, value, scenario }) => {
  const dispatch = useDispatch()
  const { vendor } = value

  const scenarioVendorGoals = useSelector(selectScenarioVendorGoalsByVendor(vendor.id, scenario.id))
  const isLoading = useSelector(selectisScenarioLoading)

  if (isLoading) {
    return null
  }

  const onUpdateGoal = (updatedGoal: number) => {
    const updatedGoals = {
      ...scenarioVendorGoals,
      [metric.value]: updatedGoal === null ? undefined : updatedGoal,
    }
    // if this scenario brand is already in the list update it, otherwise add it
    const scenarioBrand = scenario.scenario_brands.find((brand) => brand.id === vendor.id)
    dispatch(
      updateScenario({
        id: scenario.id,
        scenario_brands: scenarioBrand
          ? scenario.scenario_brands.map((brand) => {
              if (brand.id === vendor.id) {
                return {
                  ...brand,
                  goals: updatedGoals,
                }
              }
              return brand
            })
          : [...scenario.scenario_brands, { id: vendor.id, goals: updatedGoals }],
      })
    )
  }

  return (
    <GoalCell
      metric={metric}
      id={vendor.id}
      name={vendor.name}
      metricGoal={scenarioVendorGoals?.[metric.value] || null}
      onUpdateGoal={onUpdateGoal}
      editable={true}
    />
  )
}
