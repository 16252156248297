import React, { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Autocomplete, TextField } from '@howgood/design'

import { searchAgribalyzeCategories, AgribalyzeCategory } from '@/api'
import { selectDisplayedAgribalyze, updateRecipeAndScores } from '@/state/recipe'
import { DRAFT, selectPageView } from '@/state/pageSettings'
import { panelStyles } from '../panelStyles'
import { GridItemTitle } from '../GridItemTitle'

export const Agribalyze: FC = () => {
  const dispatch = useDispatch()
  const agribalyzeCategory = useSelector(selectDisplayedAgribalyze)
  const view = useSelector(selectPageView)

  const [categories, setCategories] = useState<AgribalyzeCategory[]>([])

  const handleTextChange = async (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const searchString = e.currentTarget.value
    if (searchString) {
      const results = await searchAgribalyzeCategories(searchString)
      setCategories(
        (results as AgribalyzeCategory[]).map((result) => ({
          id: result.id,
          name: result.name,
          is_beverage: result.is_beverage,
          value: result.value,
        }))
      )
    } else {
      setCategories([])
    }
  }

  const handleCategorySelected = (_event: React.SyntheticEvent<Element, Event>, category: AgribalyzeCategory) => {
    dispatch(
      updateRecipeAndScores({
        recipeUpdates: {
          agribalyze_category: category,
        },
        change: `Changed agribalyze category to ${category?.name || 'unset'}`,
      })
    )
  }

  return (
    <>
      <GridItemTitle title="Agribalyze" tooltipText="Required for Eco-Score" />
      <Autocomplete
        id="agribalyze-category-autocomplete"
        aria-label="Select agribalyze category using autocomplete"
        disabled={view !== DRAFT}
        options={categories}
        noOptionsText="Start typing"
        multiple={false}
        getOptionLabel={(option: AgribalyzeCategory) => option.name || ''}
        value={agribalyzeCategory}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        onChange={(e, category: AgribalyzeCategory) => handleCategorySelected(e, category)}
        renderInput={(params) => <TextField {...params} onChange={handleTextChange} sx={panelStyles.autocomplete} />}
        fullWidth
        ListboxProps={{ id: 'agribalyze-menu' }}
      />
    </>
  )
}
