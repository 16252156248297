import React, { FC } from 'react'
import { faEllipsis } from '@fortawesome/pro-regular-svg-icons'
import { Box, Icon, Stack, Text, Tooltip } from '@howgood/design'

import { ProductListRenderCellParams } from './ProductGridTypes'
import { FormulationTag } from '@/api'

interface Props extends ProductListRenderCellParams {
  value?: FormulationTag[]
}

export const TagListRenderer: FC<Props> = ({ value = [], id, colDef }) => {
  if (!value.length) {
    return null
  }

  const multipleItems = value.length > 1

  return (
    <Stack alignItems="center" direction="row" height="100%" width="100%">
      <Box
        maxWidth={multipleItems ? 'calc(100% - 28px)' : '100%'}
        padding="4px 8px 4px"
        boxSizing="border-box"
        borderRadius={12}
        sx={{ backgroundColor: '#eee' }}
      >
        <Tooltip disableInteractive title={value[0].name}>
          <Text
            data-testid={`${colDef.field}-cell-${id}`}
            textOverflow="ellipsis"
            fontSize={14}
            lineHeight="16px"
            noWrap
          >
            {value[0].name}
          </Text>
        </Tooltip>
      </Box>
      {multipleItems && (
        <Tooltip
          disableInteractive
          title={<Text data-testid={`${colDef.field}-more-tooltip-${id}`}>{value.map((t) => t.name).join(', ')}</Text>}
        >
          <Box data-testid={`${colDef.field}-more-${id}`} m="0 6px -6px">
            <Icon icon={faEllipsis} />
          </Box>
        </Tooltip>
      )}
    </Stack>
  )
}
