import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { faClockRotateLeft } from '@fortawesome/pro-regular-svg-icons'
import { MenuItem, ListItemIcon, ListItemText } from '@howgood/design'

import { selectIsCreateNew } from '@/state/productOverview'
import { PUBLISHED, selectPageView } from '@/state/pageSettings'

export const ViewHistory: FC<{ onClick: () => void }> = ({ onClick }) => {
  const pageView = useSelector(selectPageView)
  const createNew = useSelector(selectIsCreateNew)
  if (pageView === PUBLISHED || createNew) {
    return null
  }

  return (
    <MenuItem id="history-option" data-testid="history-option" onClick={onClick}>
      <ListItemIcon icon={faClockRotateLeft} />
      <ListItemText>View history</ListItemText>
    </MenuItem>
  )
}
