import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './transportation.state'
import { getTransportationModes } from './transportation.thunk'

const transportationSlice = createSlice({
  name: 'transportation',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTransportationModes.fulfilled, (state, action) => {
      state.modes = action.payload
    })
  },
})

export const transportationReducer = transportationSlice.reducer
