import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button, Stack, Text } from '@howgood/design'
import { selectSelectedProduct } from './state/productDashboard'
import { SAVED, setAbatementStrategiesDrawer, setView } from '@/state/pageSettings'

export const ChartFooter: FC = () => {
  const dispatch = useDispatch()
  const selectedProduct = useSelector(selectSelectedProduct)

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-end" gap={1} mb={1}>
      <Text
        data-testid={`selected-product-label-${selectedProduct.pk}`}
        variant="body2"
        fontWeight="bold"
        textAlign="center"
      >
        {selectedProduct.name}
      </Text>
      <Link to={`/products/${selectedProduct.pk}`}>
        <Button
          color="primary"
          size="small"
          onClick={() => {
            dispatch(setView(SAVED))
            dispatch(setAbatementStrategiesDrawer(true))
          }}
        >
          Explore abatement strategies
        </Button>
      </Link>
    </Stack>
  )
}
