import React, { FC } from 'react'
import { VIEW_AGG_ECO_SCORE, VIEW_PRODUCT_NUTRITION } from '@/constants/impactScore'
import { EcoScoreImage, NutriScoreImageWrapped } from '@/components'
import { Stack, Text } from '@howgood/design'

interface OtherClaimsProps {
  permissions: string[]
  ecoScore: number | null
  nutriScore: string | null
}

export const OtherClaims: FC<OtherClaimsProps> = ({ permissions, nutriScore, ecoScore }) => {
  return (
    <Stack direction="row" gap={3} sx={{ alignItems: 'baseline' }}>
      {permissions.includes(VIEW_AGG_ECO_SCORE) && ecoScore != null && (
        <Stack gap={0.5} sx={{ padding: '4px 0', alignItems: 'center', maxWidth: '120px' }}>
          <EcoScoreImage score={ecoScore} variant="wrapped" />
          <Text variant="caption" textAlign="center">
            European Environmental Scoring System
          </Text>
        </Stack>
      )}
      {permissions.includes(VIEW_PRODUCT_NUTRITION) && nutriScore != null && (
        <Stack gap={0.5} sx={{ padding: '4px 0', alignItems: 'center', maxWidth: '120px' }}>
          <NutriScoreImageWrapped score={nutriScore} />
          <Text variant="caption" textAlign="center">
            European Nutritional Scoring System
          </Text>
        </Stack>
      )}
    </Stack>
  )
}
