import { createSlice } from '@reduxjs/toolkit'
import { getProductImpactHistory, getVendorImpactHistory } from './impactHistory.thunk'
import { ImpactHistory } from './impactHistory.types'

export interface State {
  isLoading: boolean
  isError: boolean
  productImpactHistory: ImpactHistory
  vendorImpactHistory: ImpactHistory
}

export const initialState: State = {
  isLoading: false,
  isError: false,
  productImpactHistory: null,
  vendorImpactHistory: null,
}

const impactHistorySlice = createSlice({
  name: 'impactHistory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProductImpactHistory.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getProductImpactHistory.rejected, (state) => {
      state.isLoading = false
      state.isError = true
      state.productImpactHistory = initialState.productImpactHistory
    })
    builder.addCase(getProductImpactHistory.fulfilled, (state, action) => {
      state.isLoading = false
      state.isError = false
      state.productImpactHistory = action.payload
    })

    builder.addCase(getVendorImpactHistory.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getVendorImpactHistory.fulfilled, (state, action) => {
      state.isLoading = false
      state.vendorImpactHistory = action.payload
    })
    builder.addCase(getVendorImpactHistory.rejected, (state) => {
      state.isLoading = false
      state.vendorImpactHistory = initialState.vendorImpactHistory
    })
  },
})

export const impactHistoryReducer = impactHistorySlice.reducer
