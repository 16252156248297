import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Grid } from '@howgood/design'

import { selectProductNutrition } from '@/state/nutrition'
import { ScoreCards } from './ScoreCards/ScoreCards'
import { useScoreCardData } from './ScoreCards/useScoreCardData'
import { Nutrition } from './Nutrition/Nutrition'
import { PanelGrid } from './PanelGrid'
import { selectIsRollup } from '@/state/router'

export const PeoplePanel: FC = () => {
  const scoreCardData = useScoreCardData()
  const nutrition = useSelector(selectProductNutrition)
  const isRollup = useSelector(selectIsRollup)

  return (
    <PanelGrid>
      <ScoreCards cards={scoreCardData.people.data} />
      {nutrition && !isRollup && (
        <Grid item width="100%">
          <Nutrition />
        </Grid>
      )}
    </PanelGrid>
  )
}
