import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Stack, Text } from '@howgood/design'
import { selectCarbonAccountingReportById } from '@/state/carbonAccounting'
import { CarbonAccountingTotal } from './CarbonAccountingTotal'
import { useFlagEmissionsTotal } from '../CarbonAccounting.hooks'

interface Props {
  reportId: number
}

export const CarbonAccountingTotals: FC<Props> = ({ reportId }) => {
  const flagEmissions = useFlagEmissionsTotal()
  const report = useSelector(selectCarbonAccountingReportById(reportId))
  const scope1Total = report?.scope_categories?.scope_1?.score
  const scope2Total = report?.scope_categories?.scope_2?.score
  const scope3Total = report?.scope_3_total
  const allEmissionsTotal = report?.all_scopes_total

  return (
    <Stack flex={1} width="100%" maxWidth={310} gap={1} p={1}>
      <CarbonAccountingTotal scope="scope_1" title="Scope 1 Total" score={scope1Total} />
      <CarbonAccountingTotal scope="scope_2" title="Scope 2 Total" score={scope2Total} />
      <CarbonAccountingTotal scope="scope_3" title="Scope 3 Total" score={scope3Total} />
      <CarbonAccountingTotal title="Total Scope 3 FLAG Emissions" score={flagEmissions} />
      <CarbonAccountingTotal title="Total Annual Emissions" score={allEmissionsTotal} />
      <Text textAlign="right" variant="caption">
        MT CO2e/year
      </Text>
    </Stack>
  )
}
