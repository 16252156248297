import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Switch, FormControlLabel } from '@howgood/design'
import { getProducts, selectShowLiveImpactData, setShowLiveImpactData } from '@/state/products'
import { selectCurrentScenarioId } from '@/state/initiatives'

export const ShowLiveScoresToggle: FC = () => {
  const dispatch = useDispatch()
  const showLiveData = useSelector(selectShowLiveImpactData)
  const scenarioId = useSelector(selectCurrentScenarioId)

  const handleChange = () => {
    dispatch(setShowLiveImpactData(!showLiveData))
    dispatch(getProducts({ size: 0 }))
  }

  return (
    <FormControlLabel
      data-testid="show-live-score-toggle"
      control={<Switch checked={showLiveData} onChange={handleChange} />}
      label="Show only live formula values"
      disabled={!!scenarioId}
    />
  )
}
