import React, { forwardRef } from 'react'
import MuiFormControlLabel, { FormControlLabelProps as MuiFormControlLabelProps } from '@mui/material/FormControlLabel'

export interface FormControlLabelProps
  extends Pick<MuiFormControlLabelProps, 'value' | 'control' | 'label' | 'labelPlacement' | 'disabled' | 'sx'> {}

export const FormControlLabel = forwardRef<HTMLLabelElement, FormControlLabelProps>(
  ({ value, control, label, labelPlacement, disabled, ...rest }, ref) => {
    return (
      <MuiFormControlLabel
        ref={ref}
        value={value}
        control={control}
        label={label}
        labelPlacement={labelPlacement}
        disabled={disabled}
        {...rest}
      />
    )
  }
)

FormControlLabel.displayName = 'FormControlLabel'
