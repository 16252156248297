import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NumericInput, Tooltip, Stack } from '@howgood/design'
import { getInitiativeInventories } from '@/records'
import { selectCurrentScenarioId } from '@/state/initiatives/initiatives.selectors'
import { addMessage } from '@/state/messages'
import { updateProductScenarioMTperYr } from '@/state/products'
import { DEFAULT_SCENARIO_DATA } from './ProductGoalRenderer'
import { ProductListRenderCellParams } from './ProductGridTypes'
import { RoundedValueRenderer } from './RoundedValueRenderer'
import { selectEditableWorkspaces } from '@/state/workspaces'

interface Props extends ProductListRenderCellParams {
  value: number
}

export const MtPerYearRenderer: FC<Props> = ({ value: liveMtPerYr, row: product, id }) => {
  const dispatch = useDispatch()
  const userWorkspaces = useSelector(selectEditableWorkspaces)
  const currentScenario = useSelector(selectCurrentScenarioId)
  const inventories = getInitiativeInventories(product, userWorkspaces)
  const scenarioProductData = (currentScenario && product.scenarios?.find((s) => s.id === currentScenario)) || {
    product: DEFAULT_SCENARIO_DATA,
  }
  const scenarioMtPerYr = scenarioProductData.product?.mt_per_year
  const mt_per_year = isNaN(scenarioMtPerYr) || scenarioMtPerYr === null ? liveMtPerYr : scenarioMtPerYr

  const onUpdateMTperYr = (updatedMtPerYr: number) => {
    if (mt_per_year === updatedMtPerYr) {
      return
    }
    dispatch(
      updateProductScenarioMTperYr({
        productId: product.id,
        data: {
          // include existing product data so we do not overwrite anything
          ...scenarioProductData.product,
          mt_per_year: updatedMtPerYr || updatedMtPerYr === 0 ? updatedMtPerYr : null,
        },
        scenarioId: currentScenario,
      })
    )
  }

  if (!currentScenario) {
    return <RoundedValueRenderer dataTestId={`mtPerYr-cell-${id}`} value={mt_per_year} />
  }
  const disabled = inventories.length !== 1

  return (
    <Tooltip title={disabled ? 'We currently do not support setting MT/yr on products in multiple workspaces.' : null}>
      <Stack width={'80px'} height="100%" justifyContent="center">
        <NumericInput
          id={`MTperYr-field-${product.id}`}
          ariaLabel={`MT/yr for ${product.name}`}
          disabled={disabled}
          numericValue={mt_per_year}
          onUpdateValue={onUpdateMTperYr}
          onBadValue={(badVal) => {
            dispatch(addMessage({ severity: 'error', message: `${badVal} is not a valid numeric value.` }))
          }}
          size="small"
        />
      </Stack>
    </Tooltip>
  )
}
