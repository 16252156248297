import { createAsyncThunk } from '@reduxjs/toolkit'
import { fetchAllV2 } from '@/api'
import { FunctionalCategory } from '@/api'
import { AppState } from '@/store'

export const getFunctionalCategories = createAsyncThunk<FunctionalCategory[], void, { state: AppState }>(
  'functionalCategories/getFunctionalCategories',
  async () => {
    try {
      const functionalCategories = await fetchAllV2<FunctionalCategory>({ url: 'ingredients/labels/' })
      return functionalCategories
    } catch (e) {
      return []
    }
  }
)
