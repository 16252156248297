import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useGridApiRef } from '@mui/x-data-grid-premium'
import { CircularProgress, Stack, Text } from '@howgood/design'
import { useLiteUserPaywallDialogForPages } from '@/components/LiteUserPaywallDialog/LiteUserPaywallDialog.hook'
import { initializeVendorManagementPage, selectVendorData, selectVendorDataIsLoading } from '@/state/vendorManagement'
import { VendorGrid } from './Grid/VendorGrid'
import { Charts } from './Charts/Charts'
import { Toolbar } from './Toolbar/Toolbar'
import { Filters } from './Filters/Filters'
import { VendorManagementHeader } from './VendorManagementHeader'

export const VendorManagementPage = () => {
  useLiteUserPaywallDialogForPages()

  const vendorData = useSelector(selectVendorData)
  const isLoading = useSelector(selectVendorDataIsLoading)
  const dispatch = useDispatch()
  const gridApiRef = useGridApiRef()

  useEffect(() => {
    // Check if data is already loaded and don't re-initialize if it is
    if (!vendorData.length) {
      dispatch(initializeVendorManagementPage())
    }
  }, [dispatch, vendorData.length])

  return (
    <Stack height="100%">
      <VendorManagementHeader gridApiRef={gridApiRef} />
      <Filters />
      {isLoading && (
        <Stack direction="row" width="100%" height="50%" justifyContent="center" alignItems="center" gap={2}>
          <CircularProgress size={28} />
          <Text>Analyzing vendor data, please wait...</Text>
        </Stack>
      )}
      {!isLoading && vendorData.length === 0 && (
        <Stack direction="row" width="100%" height="50%" justifyContent="center" alignItems="center" gap={2}>
          <Text>No vendor data for the selected filters</Text>
        </Stack>
      )}
      {!isLoading && vendorData.length !== 0 && (
        <>
          <Charts />
          <Toolbar />
          <Stack height="100%" minHeight={200} overflow="auto">
            <VendorGrid gridApiRef={gridApiRef} />
          </Stack>
        </>
      )}
    </Stack>
  )
}
