import React, { FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select } from '@howgood/design'
import { FormulationStatus, IProduct } from '@/records'
import { patchProduct } from '@/state/products'

interface UpdateStatusDialogProps {
  onClose: () => void
  applyToSelection: (cb: (product: IProduct) => void) => void
}

export const UpdateStatusDialog: FC<UpdateStatusDialogProps> = ({ onClose, applyToSelection }) => {
  const dispatch = useDispatch()
  const [selectedStatus, setSelectedStatus] = useState<FormulationStatus | ''>('')

  const handleUpdateStatus = (status: string) => {
    applyToSelection((product) => {
      dispatch(patchProduct({ id: product.id, data: { formulation_status: status } }))
    })
    onClose()
  }

  return (
    <Dialog onClose={onClose} open data-testid="update-status-dialog">
      <DialogTitle>Update Status</DialogTitle>
      <DialogContent>
        <Select
          data-testid="update_status_select"
          fullWidth
          onChange={(e) => setSelectedStatus(e.target.value as FormulationStatus)}
        >
          <MenuItem value="">Choose an option...</MenuItem>
          <MenuItem value="pipeline">Pipeline</MenuItem>
          <MenuItem data-testid="competitor-status-option" value="competitor">
            Competitor
          </MenuItem>
          <MenuItem value="archived">Archived</MenuItem>
        </Select>
      </DialogContent>

      <DialogActions>
        <Button
          data-pendo-id="BulkEdit>UpdateStatus>Save"
          data-testid="update-status-save-button"
          disabled={!selectedStatus}
          onClick={() => handleUpdateStatus(selectedStatus as FormulationStatus)}
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
