import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { MenuItem } from '@howgood/design'
import { selectDraftProductInfo } from '@/state/productOverview'
import { DRAFT, selectPageView } from '@/state/pageSettings'
import { selectIsEditing } from '@/state/recipe'

interface MenuItemsProps {
  isSaved: boolean
  handleSave: () => void
  handleSaveAs: () => void
}

export const MenuItems: FC<MenuItemsProps> = ({ isSaved, handleSave, handleSaveAs }) => {
  const view = useSelector(selectPageView)
  const editing = useSelector(selectIsEditing)
  const productDraft = useSelector(selectDraftProductInfo)

  // New formula
  if (!isSaved) {
    return (
      <MenuItem id="save-as-option" onClick={handleSaveAs}>
        Save new formula
      </MenuItem>
    )
  }

  // Not editing, so can only save a copy
  if (view !== DRAFT) {
    return (
      <MenuItem id="save-as-option" onClick={handleSaveAs}>
        Save as new formula
      </MenuItem>
    )
  }

  // When editing with name and workspace, can save changes to existing or save as new
  if (productDraft.name && productDraft.workspaces.length && editing) {
    return (
      <>
        <MenuItem id="save-option" onClick={() => handleSave()}>
          Save changes
        </MenuItem>
        <MenuItem id="save-as-option" onClick={handleSaveAs}>
          Save as new formula
        </MenuItem>
      </>
    )
  }

  // Existing formula is missing name (unlikely) or workspace (impossible)
  return (
    <MenuItem id="save-as-option" onClick={handleSaveAs}>
      Save as new formula
    </MenuItem>
  )
}
