import React, { FC, ReactNode } from 'react'
import { Geography } from 'react-simple-maps'
import { Tooltip } from '@howgood/design'
import { Geo } from './Country.types'

interface Props {
  ['data-testid']?: string
  tooltipContent: ReactNode
  geo: Geo
  fill: string
}

export const Country: FC<Props> = ({ ['data-testid']: dataTestId, tooltipContent, geo, fill }) => {
  return (
    <Tooltip title={tooltipContent} enterDelay={0} enterNextDelay={0} leaveDelay={0} disableInteractive>
      <Geography data-testid={dataTestId} geography={geo} stroke="white" fill={fill} />
    </Tooltip>
  )
}
