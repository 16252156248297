import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'
import { useSelector } from 'react-redux'
import { EmailForm, Auth0Form, StandardForm } from '@/components'
import { selectUserId, selectUserProvider } from '@/state/user'

import './Login.scss'

export default function Login() {
  const history = useHistory()
  const location = useLocation()
  const provider = useSelector(selectUserProvider)
  const userId = useSelector(selectUserId)

  function renderForm() {
    switch (provider) {
      case 'auth0':
        return <Auth0Form />
      case 'default':
        return <StandardForm />
      case '':
        return <EmailForm />
      default:
        return null
    }
  }

  useEffect(() => {
    if (userId) {
      // @ts-ignore
      history.push(location.state?.from?.pathname || '/')
    }
  }, [history, location, userId])

  return (
    <section className="hero is-fullheight-with-navbar login-screen">
      <div className="hero-body login-body">
        <div className="login-left-side" />

        <div className="login-right-side">
          <div className="login-title-wrapper">
            <span className="login-header-text login-title">Latis</span>
            <span className="login-header-text login-subtitle">SUSTAINABILITY INTELLIGENCE by HOWGOOD</span>
          </div>
          {renderForm()}
        </div>
      </div>
    </section>
  )
}
