import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { Text, Tooltip } from '@howgood/design'
import { Scenario } from '@/records/Initiatives'
import { getContentData } from '@/records/scoreCardData'
import { selectContentfulMetricData } from '@/state/contentfulData'

export interface ScenarioListGoalsCellParams extends GridRenderCellParams {
  value: Scenario
}

export const ScenarioListGoalsCell: FC<ScenarioListGoalsCellParams> = ({ value: scenario }) => {
  const contentfulMetricData = useSelector(selectContentfulMetricData)
  const metricTitles = Object.keys(scenario.goals).map((metric) => {
    const { contentfulEntry } = getContentData({
      key: metric,
      contentfulMetricData,
      showProcurementCarbonContent: true,
    })
    return contentfulEntry.subtitle || contentfulEntry.title
  })
  const cellValue = metricTitles.join(', ')

  return (
    <Tooltip title={cellValue}>
      <Text data-testid={`scenario-set-goals-${scenario.id}`} variant="body2" textOverflow="ellipsis" overflow="hidden">
        {cellValue}
      </Text>
    </Tooltip>
  )
}
