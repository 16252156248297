import { createSelector } from 'reselect'
import { estypes } from '@elastic/elasticsearch'

import { AppState } from '@/store'
import {
  ConstructedFilters,
  selectConstructedFilters,
  selectShowAtRiskOnly,
  selectShowLiveImpactData,
} from '@/state/products'
import { Workspace } from '@/records'
import { selectThirdPartyWorkspaces } from '@/state/workspaces/workspaces.selectors'
import { ProductsBoolQuery, constructProductsBoolQuery } from '@/api/elastic/productsEsRepo'
import { selectMaterialIconAgpracticeSplit } from '@/state/splitio'
import { selectProductMetricsPermissionGroups, selectProductMetricsPermissions } from '@/state/user'
import { selectCurrentInitiativeWorkspaceIds } from '@/state/initiatives/initiatives.selectors'
import {
  generateMtPerYear,
  scope3Category1Annual,
  scope3Category4Annual,
  scope3FlagAnnual,
  scope3TotalAnnual,
} from '@/api/elastic/runtimeMappings'
import {
  PERMISSIONS_TO_FIELDS_MAP,
  SCOPE_3_CATEGORY_1_FIELD,
  SCOPE_3_CATEGORY_4_FIELD,
  SCOPE_3_FLAG_FIELD,
  VIEW_AGG_IMPACT_APPLEGATE_HEALTH_SCORE,
  VIEW_PRODUCT_NUTRITION,
} from '@/constants/impactScore'

export const selectProductsBoolQuery = createSelector<
  AppState,
  ConstructedFilters,
  boolean,
  string[],
  Workspace[],
  boolean,
  { boolQuery: ProductsBoolQuery; workspaceIds: number[]; scenario: number }
>(
  selectConstructedFilters,
  selectShowAtRiskOnly,
  selectProductMetricsPermissions,
  selectThirdPartyWorkspaces,
  selectMaterialIconAgpracticeSplit,
  (constructedFilters, atRisk, productImpactPermissions, thirdPartyWorkspaces, includeVendorVerifiedPlus) => {
    const {
      filters: { ingredient: _ingredient, ...filters },
    } = constructedFilters
    return {
      boolQuery: constructProductsBoolQuery({
        ...filters,
        atRisk,
        productImpactPermissions,
        thirdPartyWorkspaces: thirdPartyWorkspaces.map((w) => w.id),
        includeVendorVerifiedPlus,
      }),
      workspaceIds: filters.workspaceIds,
      scenario: filters.scenario,
    }
  }
)

export const selectProductsRuntimeMappings = (workspaceOverride: number[] = [], scenario: number = null) =>
  createSelector<AppState, number[], ConstructedFilters, estypes.MappingRuntimeFields>(
    selectCurrentInitiativeWorkspaceIds,
    selectConstructedFilters,
    (initiativeWorkspaceIds, constructedFilters) => {
      // If we are in an initiative view we need only pull mt/yr for the workspaces in the initiative
      const workspaceIds = workspaceOverride.length
        ? workspaceOverride
        : constructedFilters.filters.scenario && initiativeWorkspaceIds.length
        ? initiativeWorkspaceIds
        : constructedFilters.filters.workspaceIds

      return {
        mt_per_year: generateMtPerYear(workspaceIds, scenario),
        [SCOPE_3_CATEGORY_1_FIELD]: scope3Category1Annual,
        [SCOPE_3_CATEGORY_4_FIELD]: scope3Category4Annual,
        scope_3_total_annual: scope3TotalAnnual,
        [SCOPE_3_FLAG_FIELD]: scope3FlagAnnual,
      }
    }
  )

const generatePainlessScript = (field: string) => {
  return `if (doc.containsKey('locked_claims.${field}') && doc['locked_claims.${field}'].size() != 0) {
    return doc['locked_claims.${field}'].value;} else if (doc.containsKey('locked_claims')) {return null;
    } else if (doc.containsKey('${field}') && doc['${field}'].size() != 0) {return doc['${field}'].value;}return null;`
}
export const selectProductsAggregation = createSelector<
  AppState,
  {
    impactPermissions: string[]
    analysisImpactPermissions: string[]
    secondTierPermissions: string[]
  },
  boolean,
  Record<string, estypes.AggregationsAggregationContainer>
>(selectProductMetricsPermissionGroups, selectShowLiveImpactData, (permissionGroups, showLiveImpactData) => {
  const { impactPermissions, analysisImpactPermissions, secondTierPermissions } = permissionGroups
  const aggs = { impact_score_total: { avg: { field: 'impact_score_total' } } }
  impactPermissions.forEach((permission: string) => {
    const field = PERMISSIONS_TO_FIELDS_MAP[permission]
    if (field) {
      const fieldSource = showLiveImpactData
        ? { field: `impact_score.${field}` }
        : { script: { source: generatePainlessScript(`impact_score.${field}`) } }
      aggs[field] = {
        avg: fieldSource,
      }
    }
  })

  analysisImpactPermissions.forEach((permission: string) => {
    const field = PERMISSIONS_TO_FIELDS_MAP[permission]
    const fieldToUse = [VIEW_AGG_IMPACT_APPLEGATE_HEALTH_SCORE, VIEW_PRODUCT_NUTRITION].includes(permission)
      ? field
      : `impact_score.${field}`
    const fieldSource = showLiveImpactData
      ? {
          field: fieldToUse,
        }
      : { script: { source: generatePainlessScript(fieldToUse) } }
    aggs[field] = {
      avg: fieldSource,
    }
    if (permission === VIEW_PRODUCT_NUTRITION) {
      aggs[`${field}_count`] = { value_count: { field } }
    }
  })

  secondTierPermissions.forEach((permission: string) => {
    const fieldName = PERMISSIONS_TO_FIELDS_MAP[permission]
    const field = `impact_score.${fieldName}`
    // TODO: This is temporary until the BE fixes the data
    if (
      [
        'impact_score.cf_ftm_gate_ct_verified_impact',
        'impact_score.cf_cradle_to_shelf_ct_verified_impact',
        'impact_score.cf_biogenic_cradle_to_shelf_impact',
      ].includes(field)
    ) {
      return
    }
    const fieldSource = showLiveImpactData ? { field } : { script: { source: generatePainlessScript(field) } }

    aggs[fieldName] = { avg: fieldSource }
    if (fieldName.includes('annual')) {
      aggs[`${fieldName}_avg`] = { avg: fieldSource }
      aggs[`${fieldName}_sum`] = { sum: fieldSource }
      aggs[`${fieldName}_count`] = { value_count: fieldSource }
    }
  })
  return aggs
})
