import { fetchHelperV2 } from '@/api'
import { OriginLocationMapping } from './originLocations.slice'

export const fetchOriginLocations = async (baseIds: number[], workspaceIds = []): Promise<OriginLocationMapping[]> => {
  const baseIdsQuery = baseIds.length ? `&base_ids=${baseIds.join(',')}` : ''
  const workspaceIdsQuery = workspaceIds.length ? `&workspace_ids=${workspaceIds.join(',')}` : ''
  return await fetchHelperV2({
    url: `ingredients/origin-locations/?status=Published${baseIdsQuery}${workspaceIdsQuery}`,
  })
}
