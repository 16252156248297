import React, { FC } from 'react'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { RecipeIngredient, RecipeNestedIngredient } from '@/state/recipe/recipe.state'
import { ReplaceItem } from './ReplaceItem'
import { DeleteItem } from './DeleteItem'
import { Stack } from '@howgood/design'

interface DeleteReplaceProps extends GridRenderCellParams {
  value?: {
    ingredient: RecipeIngredient | RecipeNestedIngredient
  }
}

export const DeleteReplace: FC<DeleteReplaceProps> = ({ value }) => {
  const { ingredient } = value

  if (!ingredient.isTopLevel) {
    return null
  }

  return (
    <Stack flexDirection="row">
      <DeleteItem ingredient={ingredient} />
      <ReplaceItem ingredient={ingredient} />
    </Stack>
  )
}
