import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { GridValidRowModel } from '@mui/x-data-grid-premium'
import { selectContentfulIngredientImpactData } from '@/state/recipe'
import { ScoreInputs } from '../../../util/getScore'
import { IngredientImpactV2 } from '@/components'

interface SourceGridRowParams {
  ingredients: IngredientImpactV2[]
}

export function useSourceGridRows({ ingredients }: SourceGridRowParams): GridValidRowModel[] {
  const contentfulItems = useSelector(selectContentfulIngredientImpactData)

  return useMemo(() => {
    return ingredients.map((location, index) => {
      // For each impact, create an object with the info needed by the score cell renderer
      const columnValues = contentfulItems.reduce((acc, contentfulItem) => {
        return {
          ...acc,
          [contentfulItem.key]: {
            impactKey: contentfulItem.key,
            currentScore: location.impact_score[contentfulItem.key],
            flatWeight: 100,
            units: contentfulItem.units || '',
          } as ScoreInputs,
        }
      }, {})

      return {
        id: index,
        isTopLevel: true,
        location: {
          source: {
            region_name: location.origin_location.name,
            origin_location_id: location.origin_location.id,
          },
        },
        ...columnValues,
      }
    })
  }, [contentfulItems, ingredients])
}
