import { Middleware, configureStore, applyMiddleware } from '@reduxjs/toolkit'
import thunk from 'redux-thunk'
import { routerMiddleware } from 'connected-react-router'
import * as Sentry from '@sentry/react'
import history from '@/utils/history'
import { rootReducer } from './state'

/**
 * `createAsyncThunk` converts errors to type `SerializedError`, which is not
 * compatible with Sentry and needs to be reformatted to capture the correct
 * error message, type, and stack trace
 */
class SentryError extends Error {
  constructor({ message, stack, name }) {
    super(message)
    this.name = name
    this.stack = stack
  }
}

// Sentry Config
if (process.env.REACT_APP_SENTRY_DSN && process.env.REACT_APP_ENVIRONMENT !== 'development') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_SENTRY_RELEASE_VERSION || '',
    environment: process.env.REACT_APP_ENVIRONMENT,
    normalizeDepth: 8,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.3,
    beforeSend(event) {
      // We have a health check test set up in datadog and Intercom does not work
      // well with headless browsers and causes an error; filtering this out
      if (
        event?.request?.headers?.['User-Agent'] &&
        event?.request?.headers?.['User-Agent'].toLowerCase().includes('headless')
      ) {
        return null
      }
      return event
    },
  })
}

const rtkQueryErrorLogger: Middleware = () => (next) => (action) => {
  // Catch errors from react toolkit createAsyncThunk and report to Sentry
  // Ignore the errors that were intentionally rejected with value and user
  // login errors as those are known and expected
  if (action?.error && !action.payload && !action.type.includes('user/') && action?.error?.name !== 'AbortError') {
    Sentry.captureException(new SentryError(action.error), {
      extra: { action, meta: action.meta },
    })
  }
  return next(action)
}

export const store = configureStore({
  reducer: rootReducer,
  enhancers: () => [
    Sentry.createReduxEnhancer({
      stateTransformer: (state) => {
        // Transform the state to remove big unhelpful info
        return {
          organization: state.organization.settings,
          user: {
            id: state.user.id,
            email: state.user.email,
            first_name: state.user.first_name,
            last_name: state.user.last_name,
            organization: state.user.organization,
            workspaces: state.user.workspaces,
          },
          products: {
            productFilters: state.products.productFilters,
            total: state.products.total,
            setShowLiveImpactData: state.products.setShowLiveImpactData,
            showAtRiskOnly: state.products.showAtRiskOnly,
          },
          workspaces: {
            workspaces: state.workspaces.workspaces,
          },
          productOverview: state.productOverview,
          recipes: state.recipes,
          preferences: state.preferences,
          pageSettings: state.pageSettings,
        }
      },
    }),
    applyMiddleware(rtkQueryErrorLogger, thunk),
    applyMiddleware(routerMiddleware(history)),
  ],
})

export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
