import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { Text, Tooltip } from '@howgood/design'

import { ProductBrand } from '@/records'
import { selectCurrentInitiativeId, selectCurrentScenarioId, setScenarioPageTab } from '@/state/initiatives'
import { updateFiltersUrlAndProducts } from '@/state/products'

interface VendorNameCellParams extends GridRenderCellParams {
  value?: ProductBrand
}

export const VendorNameCell: FC<VendorNameCellParams> = (params) => {
  const dispatch = useDispatch()
  const { value: vendor } = params
  const { name, id } = vendor
  const currentScenarioId = useSelector(selectCurrentScenarioId)
  const currentInitiativeId = useSelector(selectCurrentInitiativeId)

  const handleClickName = () => {
    if (currentInitiativeId && currentScenarioId) {
      dispatch(updateFiltersUrlAndProducts({ updatedFilters: { vendors: [{ value: id, label: name }] } }))
      dispatch(setScenarioPageTab('formulas'))
    }
  }

  return (
    <Tooltip title={name}>
      <Text
        onClick={handleClickName}
        variant="body2"
        overflow="hidden"
        textOverflow="ellipsis"
        fontWeight="bold"
        data-testid={`vendor-${name?.replaceAll(' ', '')}`}
      >
        {name}
      </Text>
    </Tooltip>
  )
}
