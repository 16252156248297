import React, { FC, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Autocomplete, Text, TextField } from '@howgood/design'
import { searchRegions, Region } from '@/api'
import { DRAFT, selectPageView } from '@/state/pageSettings'
import { selectIsProcurement } from '@/state/router'

export type RegionType = 'manufacturing' | 'transfer' | 'retail'
interface Props {
  type: RegionType
  region?: Region
  disabled?: boolean
  labelOverride?: string
  onChange: (region: Region) => void
}

const DISABLED_REGION = { id: -1, name: '--' }

export const RegionSelect: FC<Props> = ({ type, region, disabled, labelOverride, onChange }) => {
  const [options, setOptions] = useState<Region[]>([])
  const [value, setValue] = useState<Region>({ id: region?.id, name: region?.name })
  const view = useSelector(selectPageView)
  const isProcurement = useSelector(selectIsProcurement)

  const labelMap: Record<RegionType, string> = useMemo(
    () => ({
      manufacturing: 'Manufacturing region',
      transfer: 'Transfer point',
      retail: `${isProcurement ? 'Material' : 'Product'} Destination Region`,
    }),
    [isProcurement]
  )

  const label = labelOverride || labelMap[type]

  async function onSearch(search: string) {
    const regions = await searchRegions(search)
    setOptions(regions)
  }

  if (view !== DRAFT) {
    return (
      <Text data-testid={`${type}-text`}>
        {label}: <b>{region?.name}</b>
      </Text>
    )
  }

  return (
    <Autocomplete
      data-testid={`${type}-select`}
      disabled={disabled}
      options={options}
      freeSolo
      noOptionsText="Start typing"
      value={disabled ? DISABLED_REGION : value}
      onChange={(_e, reg: Region) => {
        setValue(reg)
        onChange(reg)
      }}
      getOptionLabel={(option: Region) => option.name || ''}
      fullWidth
      renderInput={(params) => (
        <TextField
          {...params}
          data-testid={`${type}-input`}
          label={label}
          onChange={(event) => onSearch(event.target.value)}
          value={value}
          required
        />
      )}
    />
  )
}
