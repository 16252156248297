import React, { FC } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { IBrandSearchOption } from '@/records'
import { selectVendorFilters, updateProductFilters } from '@/state/products'
import { fetchBrandOptions } from '@/api/brandApi'
import { AutocompleteFilter } from '@/components/Shared/Filters/AutocompleteFilter'

export const VendorFilter: FC = () => {
  const dispatch = useDispatch()
  const selectedVendors = useSelector(selectVendorFilters)

  const handleChange = (newOptions: IBrandSearchOption[]) => {
    dispatch(updateProductFilters({ vendors: newOptions }))
  }

  const fetchOptions = async (query: string) => {
    return await fetchBrandOptions(query)
  }

  return (
    <AutocompleteFilter
      id="initiative-vendors-search"
      selected={selectedVendors}
      filterType="vendors"
      handleChangeFilter={handleChange}
      onUpdateQuery={fetchOptions}
      placeholder="Start typing a vendor name..."
      shouldUpdateEditingFilter={false}
      tooltipTitle="Filter by vendor"
    />
  )
}
