import { MetricEntry } from '@/contentfully'

export const primaryImpactKeys = [
  'raw_greenhouse_gas_impact',
  'cf_luc_impact',
  'processing_impact',
  'raw_blue_water_usage_impact',
  'raw_blue_water_consumption',
  'raw_land_use_impact',
  'cf_removals_impact',
  'raw_soc_impact',
  'soc_impact',
  'biodiversity_impact',
  'labor_impact',
  'animal_welfare_impact',
  'deforestation_impact',
]

export function getSortedImpacts(allImpactItems: MetricEntry[]): MetricEntry[] {
  const primaryImpactItems = primaryImpactKeys.reduce((acc: MetricEntry[], impactKey) => {
    const impactItem = allImpactItems.find((item) => item.key === impactKey)
    return impactItem ? [...acc, impactItem] : acc
  }, [])

  const secondaryImpactItems = allImpactItems.filter((item) => !primaryImpactKeys.includes(item.key))

  return [...primaryImpactItems, ...secondaryImpactItems]
}

export const scope3CarbonOrderMap = {
  cf_manufacturing_impact: 1,
  cf_mtm_transportation_impact: 2,
  cf_total_upstream_transportation_impact: 3,
  cf_mtr_transportation_impact: 4,
  cf_total_upstream_processing_impact: 5,
  cf_ftm_gate_ct_verified_impact: 6,
  carbon_footprint_farm_to_gate_impact: 7,
  cf_flag_impact: 8,
  scope_3_flag_impact: 9,
  scope_3_category_1_impact: 10,
  scope_3_category_4_impact: 11,
}
