/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { FC, useEffect, useRef, useState } from 'react'
import * as d3 from 'd3'
import { useSelector } from 'react-redux'
import { TreeElement, selectBasis, selectHideTagPrefix, selectPortfolioDataAsTree } from '../state/portfolioDashboard'
import { Box, Stack, Text, Tooltip } from '@howgood/design'
import { useWidth } from './useWidth'
import { formatNumber } from '@howgood/utils'
import { useUnits } from './useUnits'

const wrapperId = 'portfolio-treemap-wrapper'

/**
 * This component is based on https://observablehq.com/@d3/treemap/2
 */
interface TreeMapProps {
  height: number
}

export const TreeMap: FC<TreeMapProps> = ({ height }) => {
  const data = useSelector(selectPortfolioDataAsTree)
  const width = useWidth(500, wrapperId)
  const svgContainer = useRef(null)

  const [chartElements, setChartElements] = useState<any[]>([])

  useEffect(() => {
    // Reset the svg when the data or container size changes
    const svg = d3.select(svgContainer.current)
    svg.selectAll('*').remove()

    svg
      .attr('viewBox', [0, 0, width, height])
      .attr('width', width)
      .attr('height', height)
      .attr('style', 'max-width: 100%; height: auto; font-size: 13px;')

    // Specify the color scale
    const color = d3.scaleOrdinal(
      data.children.map((d) => d.id),
      d3.schemeTableau10
    )

    // Compute the layout
    const root = d3
      .treemap()
      .tile(d3.treemapBinary) // https://d3js.org/d3-hierarchy/treemap#treemap-tiling
      .size([width, height])
      .padding(1)
      .round(true)(
      d3
        .hierarchy(data)
        .sum((d) => d.value)
        .sort((a, b) => b.value - a.value)
    )

    setChartElements(root.children)

    // Add a cell for each leaf of the hierarchy.
    const leaf = svg
      .selectAll('g')
      .data(root.leaves())
      .join('g')
      .attr('transform', (d) => `translate(${d.x0},${d.y0})`)

    // Append a color rectangle.
    leaf
      .append('rect')
      .attr('fill', (d: d3.HierarchyRectangularNode<TreeElement>) => {
        // eslint-disable-next-line no-param-reassign
        while (d.depth > 1) d = d.parent
        return color(d.data.id)
      })
      .attr('fill-opacity', 0.6)
      .attr('width', (d) => d.x1 - d.x0)
      .attr('height', (d) => d.y1 - d.y0)
  }, [data, height, width])

  return (
    <Stack id={wrapperId} data-testid={wrapperId} position="relative">
      <svg className="d3-treemap" ref={svgContainer} />
      {/* Don't render more than 100 text overlays on the treemap */}
      {chartElements.slice(0, 100).map((el, i) => (
        <Box key={i} position="absolute" top={el.y0} left={el.x0} width={el.x1 - el.x0} height={el.y1 - el.y0}>
          <BoxContent el={el} />
        </Box>
      ))}
    </Stack>
  )
}

const BoxContent = ({ el }: { el: any }) => {
  const hideTagPrefix = useSelector(selectHideTagPrefix)
  const basis = useSelector(selectBasis)
  const units = useUnits()

  const width = el.x1 - el.x0
  const height = el.y1 - el.y0

  const fullName = el.data.name
  const name =
    hideTagPrefix && fullName?.includes(':')
      ? fullName
          .split(':')
          .slice(1)
          .join(': ')
      : fullName
  const value = `${formatNumber(el.data.value) || '__'} ${units}`
  const percentage = `${formatNumber(el.data.percentage, 1) || '__'}% of portfolio total`
  const fillRate = `Fill rate: ${el.data.fillRate} ${el.data.count === 1 ? 'product' : 'products'}`

  return (
    <Tooltip
      title={<TooltipContent name={fullName} value={value} percentage={percentage} productCount={fillRate} />}
      placement="top"
    >
      <Stack
        p={0.5}
        maxHeight="100%"
        overflow="hidden"
        sx={{ '& .MuiTypography-root': { color: width < 8 || height < 8 ? 'transparent' : 'null' } }}
      >
        <Text variant={width * height < 3000 ? 'caption' : 'body2'} lineHeight={1}>
          {name}
        </Text>
        <Text variant="caption">{value}</Text>
        {basis !== 'kg' && <Text variant="caption">{percentage}</Text>}
        <Text variant="caption">{fillRate}</Text>
      </Stack>
    </Tooltip>
  )
}

interface TooltipProps {
  name: string
  value: string
  percentage: string
  productCount: string
}

const TooltipContent: FC<TooltipProps> = ({ name, value, percentage, productCount }) => {
  return (
    <Stack p={0.5}>
      <Text fontWeight="bold">{name}</Text>
      <Text variant="body2">{value}</Text>
      <Text variant="body2">{percentage}</Text>
      <Text variant="body2">{productCount}</Text>
    </Stack>
  )
}
