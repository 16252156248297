import { createAsyncThunk } from '@reduxjs/toolkit'
import { fetchAllV2 } from '@/api'
import { PackagingMaterial } from '@/records'
import { AppState } from '@/store'

export const getPackagingMaterials = createAsyncThunk<PackagingMaterial[], void, { state: AppState }>(
  'packaging/getPackagingMaterials',
  async () => {
    return await fetchAllV2<PackagingMaterial>({ url: 'packaging/materials/?new_material=1' })
  }
)

interface ProductPackagingApi {
  product_id: string
  material_id: number
  shape_id: number
}

export interface UpdateProductPackagingPayload {
  productId: string
  toDelete: number[]
  toCreate: ProductPackagingApi[]
}
