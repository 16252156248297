import React, { FC, useState } from 'react'
import { Link } from 'react-router-dom'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { faTrashXmark } from '@fortawesome/pro-regular-svg-icons'
import { Box, Text, Tooltip, Stack, IconButton } from '@howgood/design'

import { getScenarioYear } from '@/state/initiatives/initiatives.utils'
import { GridScenario, InitiativeBasic } from '@/records/Initiatives'
import { DeleteScenarioDialog } from './DeleteScenarioDialog'

export interface ScenarioNameCellValue {
  scenario: GridScenario
  isChartTable?: boolean
}
export interface ScenarioNameCellParams extends GridRenderCellParams {
  value: ScenarioNameCellValue
  initiative: InitiativeBasic
}

export const ScenarioNameCell: FC<ScenarioNameCellParams> = (params) => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const {
    value: { scenario, isChartTable },
    initiative,
  } = params
  const { name, id, target_date: date } = scenario

  const onClickDelete = () => {
    setDialogOpen(true)
  }

  const renderComponent = (
    <Tooltip title={`${name} (${getScenarioYear(date)})`}>
      <Text
        variant="body2"
        overflow="hidden"
        textOverflow="ellipsis"
        fontWeight="bold"
        data-testid={`scenario-${name.replaceAll(' ', '')}${isChartTable ? '-chart' : ''}`}
      >
        {name}
      </Text>
    </Tooltip>
  )

  if (scenario.isBaseline) {
    return <Box style={{ overflow: 'hidden' }}>{renderComponent}</Box>
  }

  return (
    <Stack gap={0.25} direction="row" alignItems="center">
      {!isChartTable && (
        <Tooltip title={`Delete scenario ${name}. This action cannot be undone.`}>
          <IconButton data-testid={`delete-${scenario.id}`} icon={faTrashXmark} size="small" onClick={onClickDelete} />
        </Tooltip>
      )}
      <Link
        data-testid={`scenario-name-link-${scenario.id}`}
        to={`/initiatives/${initiative.id}/scenarios/${id}`}
        style={{ overflow: 'hidden' }}
      >
        {renderComponent}
      </Link>
      {dialogOpen && <DeleteScenarioDialog scenario={scenario} closeDialog={() => setDialogOpen(false)} />}
    </Stack>
  )
}
