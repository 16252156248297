import React from 'react'
import { createUseStyles } from 'react-jss'
import { NodeRenderer } from '@contentful/rich-text-react-renderer'
import { theme } from '@howgood/design'

const useStyles = createUseStyles({
  ol: {
    paddingLeft: theme.spacing(3),
  },
})

export const OrderedListRenderer: NodeRenderer = (_node, children) => {
  const classes = useStyles()
  return <ol className={classes.ol}>{children}</ol>
}
