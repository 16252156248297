import React, { FC } from 'react'
import { Stack } from '@howgood/design'
import { ColumnOptionsButton } from './ColumnOptionsButton'

export const Toolbar: FC = () => {
  return (
    <Stack flexDirection="row" alignItems="center" justifyContent="right" pt={2} pb={2} gap={1}>
      <ColumnOptionsButton />
    </Stack>
  )
}
