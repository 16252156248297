import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Text } from '@howgood/design'
import { Year } from '@/components/Layout/Year'

interface Props {
  open: boolean
  name: string
  year: number
  onDelete: () => void
  onClose: () => void
}

export const DeleteReportDialog: FC<Props> = ({ open, name, year, onClose, onDelete }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle data-testid="dialog-title">Delete this report?</DialogTitle>
      <DialogContent>
        <Stack pt={1} gap={2}>
          <Stack flexDirection="row" alignItems="center" gap={1}>
            <Year year={year} data-testid="report-year" />
            <Text data-testid="name">{name}</Text>
          </Stack>
          <Alert data-testid="delete-warning" severity="warning">
            This cannot be undone
          </Alert>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button data-testid="cancel-button" onClick={onClose}>
          Cancel
        </Button>
        <Stack ml={1}>
          <Link to="/carbon-accounting">
            <Button data-testid="delete-button" color="error" onClick={onDelete}>
              Delete
            </Button>
          </Link>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}
