import React, { FC, useRef } from 'react'
import { useSelector } from 'react-redux'
import { PDFExport } from '@progress/kendo-react-pdf'
import { faFileMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons'
import { MenuItem, ListItemIcon, ListItemText } from '@howgood/design'

import { usePendoTrack } from '@/hooks'
import { selectCarbonTrustPcfSplit } from '@/state/splitio'
import { MethodologyExport } from '../MethodologyExport'

export const ExportCarbonFootprintMethodology: FC<{ onClick: () => void }> = ({ onClick }) => {
  const pendoTrack = usePendoTrack()
  const carbonTrustPcfMethodologyEnabled = useSelector(selectCarbonTrustPcfSplit)
  const methodologyPdfExportRef = useRef<PDFExport>()

  const handleExportMethodology = () => {
    pendoTrack('Formula: Export Methodology')
    onClick()
    if (carbonTrustPcfMethodologyEnabled) {
      const link = document.createElement('a')
      link.setAttribute('href', 'https://drive.google.com/file/d/1tGdm5-4WTA6zmQ3qzc8GVjYvYZX2oO2H')
      link.setAttribute('target', '_blank')
      link.setAttribute('rel', 'noopener noreferrer')
      link.style.display = 'none'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } else if (methodologyPdfExportRef.current) {
      methodologyPdfExportRef.current.save()
    }
  }

  return (
    <>
      <MethodologyExport ref={methodologyPdfExportRef} />
      <MenuItem
        id="export-methodology-option"
        data-testid="export-methodology-option"
        onClick={handleExportMethodology}
      >
        <ListItemIcon icon={faFileMagnifyingGlass} />
        <ListItemText>Export Product Carbon Footprint Methodology</ListItemText>
      </MenuItem>
    </>
  )
}
