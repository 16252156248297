import React from 'react'
import { GridColDef } from '@mui/x-data-grid-premium'
import { formatNumber } from '@howgood/utils'
import { NameCell } from './NameCell'
import { ManufacturingTypesCell } from './ManufacturingTypesCell'
import { useMetricHeader } from './MetricHeader'
import { PriorityCell } from './PriorityCell'
import { VendorInfo } from '@/records'
import { SBTiActionHeader, SBTiTargetTypeHeader } from './SBTiHeaders'
import { EngagementStatusHeader } from './EngagementStatusHeader'
import { AggregationsStatsAggregate } from '@elastic/elasticsearch/api/types'
import { availableVendorMetrics } from '@/state/vendorManagement'
import { NonMetricHeader } from './NonMetricHeader'
import { useSelector } from 'react-redux'
import { selectSplitTreatmentEnabled } from '@/state/splitio'

const getPriority = (vendor: VendorInfo) => {
  return vendor.priority ?? (vendor.brandId === 'unknown vendor' ? 9999 : 1000)
}

// Add these columns so we can use the same column definitions for the vendor grid and the column options dropdown
type VendorColDef = GridColDef & {
  colGroup: string
  checkBoxDisabled?: boolean
}

export const useGridColumns = (): VendorColDef[] => {
  const sbtiEnabled = useSelector(selectSplitTreatmentEnabled('STBi'))

  const columns: VendorColDef[] = [
    {
      field: 'name',
      headerName: 'Vendor',
      renderHeader: NonMetricHeader,
      flex: 1,
      minWidth: 120,
      renderCell: (params) => <NameCell {...params} />,
      colGroup: 'Default',
      checkBoxDisabled: true,
    },
    {
      field: 'priority',
      headerName: 'Priority',
      renderHeader: NonMetricHeader,
      width: 165,
      renderCell: (params) => <PriorityCell {...params} />,
      sortComparator: (a: VendorInfo, b: VendorInfo) => getPriority(a) - getPriority(b),
      colGroup: 'Default',
    },
    {
      field: 'count',
      headerName: 'Number of Materials Purchased',
      renderHeader: NonMetricHeader,
      width: 80,
      colGroup: 'Default',
    },
    // Create a column for each vendor metric
    ...Object.entries(availableVendorMetrics).map(([metric, info]) => ({
      field: metric,
      headerName: info.title,
      flex: 1,
      renderHeader: useMetricHeader,
      // ES sum aggregations are zero if no data is present, but display empty cell if there were no non-zero values
      valueFormatter: (value: AggregationsStatsAggregate) => (value.count ? formatNumber(value[info.aggType]) : ''),
      sortComparator: (a: AggregationsStatsAggregate, b: AggregationsStatsAggregate) =>
        a[info.aggType] - b[info.aggType],
      colGroup: info.colGroup,
    })),
    // TODO: Re-add scope 3 contribution after product figures some things out
    // {
    //   field: 'scope_3_contribution',
    //   headerName: 'Scope 3 Contribution',
    //   flex: 1,
    //   renderCell: (params) => (params?.value ? `${formatNumber(params?.value * 100, 1)}%` : 'N/A'),
    //   colGroup: 'Scope 3 Emissions',
    // },
    {
      field: 'mtPerYear',
      flex: 1,
      headerName: 'Purchasing Volume (MT/year)',
      renderHeader: NonMetricHeader,
      valueFormatter: (value) => (value ? formatNumber(value) : 'Not Provided'),
      colGroup: 'Default',
    },
    {
      field: 'engagementStatus',
      flex: 1,
      headerName: 'Vendor Engagement Status',
      renderHeader: EngagementStatusHeader,
      colGroup: 'Workflow',
    },
    {
      field: 'manufacturingTypes',
      flex: 1,
      headerName: `Manufacturing Type`,
      renderHeader: NonMetricHeader,
      renderCell: (params) => <ManufacturingTypesCell {...params} />,
      colGroup: 'Workflow',
    },
    {
      field: 'sbtAction',
      headerName: 'SBTi Action',
      renderHeader: SBTiActionHeader,
      width: 120,
      colGroup: 'Workflow',
    },
    {
      field: 'sbtType',
      headerName: 'SBTi Type',
      renderHeader: SBTiTargetTypeHeader,
      width: 130,
      colGroup: 'Workflow',
    },
  ]

  return sbtiEnabled ? columns : columns.filter((col) => !col.field.startsWith('sbt'))
}
