import { createAsyncThunk } from '@reduxjs/toolkit'
import { OriginLocationMapping } from './originLocations.slice'
import { fetchOriginLocations } from './originLocations.requests'

interface GetOriginLocations {
  baseIds?: number[]
  workspaceIds?: number[]
}
// Fetch origin locations when adding a new ingredient
export const getOriginLocations = createAsyncThunk<OriginLocationMapping[], GetOriginLocations>(
  'originLocations/getOriginLocations',
  async ({ baseIds = [], workspaceIds = [] }) => {
    if (baseIds.length) {
      return await fetchOriginLocations(baseIds, workspaceIds)
    }
    return []
  }
)
