import React, { FC } from 'react'
import { Stack, Text } from '@howgood/design'
import { useSelector } from 'react-redux'
import { selectSelectedWorkspace } from '../state/productDashboard'

interface PercentageCellProps {
  value: { value: string; id: number }
}

export const PercentageCell: FC<PercentageCellProps> = ({ value: { value, id } }) => {
  const selectedWorkspace = useSelector(selectSelectedWorkspace)

  if (!value) return null

  return (
    <Stack data-testid={`percent-cell-${id}`} width="100%" alignItems="end" sx={{ pointerEvents: 'none' }}>
      <Text fontWeight="bold">{value}%</Text>
      <Text variant="caption">of {selectedWorkspace?.id === -1 ? 'total portfolio' : 'workspace total'}</Text>
    </Stack>
  )
}
