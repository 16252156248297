import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Text, theme, Stack } from '@howgood/design'

import { selectSavedProductInfo } from '@/state/productOverview'
import { selectDisplayedProductImpactData } from '@/state/recipe'
import { getDataHealthValue } from '@/utils/products'

export const ProductInfo: FC = () => {
  const impactData = useSelector(selectDisplayedProductImpactData)
  const savedProduct = useSelector(selectSavedProductInfo)

  const ingredientPercentagesValue = getDataHealthValue('has_default_weights', savedProduct.has_default_weights)
  const sourcingLocationValue = getDataHealthValue(
    'default_origin_percent_impact',
    impactData.default_origin_percent_impact
  )

  return (
    <Stack px={0} py={0.75}>
      <Stack direction="row" justifyContent="space-between">
        <Text>Name</Text>
        <Text>{savedProduct.name}</Text>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Text>Ingredient percentages</Text>
        <Text color={ingredientPercentagesValue ? null : theme.palette.text.secondary}>
          {ingredientPercentagesValue || 'Not Provided'}
        </Text>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Text>Sourcing location</Text>
        <Text color={sourcingLocationValue ? null : theme.palette.text.secondary}>
          {sourcingLocationValue || 'Not Provided'}
        </Text>
      </Stack>
    </Stack>
  )
}
