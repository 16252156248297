import React, { FC, MouseEvent } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { faRotateLeft } from '@fortawesome/pro-regular-svg-icons'
import { Button } from '@howgood/design'

import { resetProductToSaved } from '@/thunks'
import { DRAFT, selectPageView } from '@/state/pageSettings'
import { selectIsEditing } from '@/state/recipe'

export const ResetButton: FC = () => {
  const dispatch = useDispatch()
  const pageView = useSelector(selectPageView)
  const editing = useSelector(selectIsEditing)

  const handleBackToOriginalClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    dispatch(resetProductToSaved())
  }

  if (!editing || pageView !== DRAFT) {
    return null
  }

  return (
    <Button
      id="reset-button"
      data-testid="reset-formula-button"
      aria-label="Reset to saved version"
      color="error"
      size="small"
      startIcon={faRotateLeft}
      onClick={handleBackToOriginalClick}
    >
      Reset
    </Button>
  )
}
