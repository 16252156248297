import React, { FC, ReactNode, useState } from 'react'
import { useSelector } from 'react-redux'
import { Accordion, AccordionDetails, Box, Tabs, Tab } from '@howgood/design'

import { selectIsProductLoading } from '@/state/productOverview'
import { AccordionCardHeader } from '@/components/Scores/AccordionCardHeader'
import { Packaging } from './Packaging'
import { SalesDistribution } from './SalesDistribution'
import { General } from './General'
import { Workspaces } from './Workspaces'
import { OtherInfo } from './OtherInfo'
import { Transportation } from './Transportation'
import { selectCurrentScenarioId } from '@/state/initiatives'
import { ScenarioDetails } from './ScenarioDetails'
import { ProductInfoSupplier } from './ProductInfoSupplier'
import { selectIsSupplierConnect } from '@/state/organization'

interface TabPanelProps {
  children?: ReactNode
  index: number
  value: number
}

const TabPanel: FC<TabPanelProps> = ({ children, value, index }) => {
  return (
    <div role="tabpanel" hidden={value !== index} id={`product-info-tabpanel-${index}`}>
      {value === index && <>{children}</>}
    </div>
  )
}

export interface CardData {
  label: string
  subTitle?: string
  value: string
  change?: number
}

export const ProductInfo: FC = () => {
  const isLoading = useSelector(selectIsProductLoading)
  const currentScenarioId = useSelector(selectCurrentScenarioId)
  const isSupplierConnect = useSelector(selectIsSupplierConnect)

  const [tabValue, setTabValue] = useState(0)

  if (isSupplierConnect) {
    return <ProductInfoSupplier />
  }

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  return (
    <Accordion defaultExpanded={true}>
      <AccordionCardHeader id="product-information-panel" title="Other Details" />
      {!isLoading && (
        <AccordionDetails>
          <Box p={1} border="1px solid lightgray" borderRadius={1}>
            <Box borderBottom={1} borderColor="divider" mb={1}>
              <Tabs
                id="product-info-tabs"
                value={tabValue}
                onChange={handleTabChange}
                aria-label="Product information tabs"
              >
                <Tab id="general-tab" label="General" />
                <Tab id="packaging-tab" label="Packaging" />
                <Tab id="sales-distribution-tab" label="Sales & Distribution" />
                {currentScenarioId ? (
                  <Tab id="scenarios-tab" data-testid="scenarios-tab" label="Scenario Details" />
                ) : (
                  <Tab id="workspaces-tab" data-testid="workspaces-tab" label="Workspaces" />
                )}
                <Tab id="other-tab" label="Other Info" />
                <Tab data-testid="transportation-tab" id="transportation-tab" label="Transportation" />
              </Tabs>
            </Box>
            <TabPanel value={tabValue} index={0} key={0}>
              <General />
            </TabPanel>
            <TabPanel value={tabValue} index={1} key={1}>
              <Packaging />
            </TabPanel>
            <TabPanel value={tabValue} index={2} key={2}>
              <SalesDistribution />
            </TabPanel>
            <TabPanel value={tabValue} index={3} key={3}>
              {currentScenarioId ? <ScenarioDetails /> : <Workspaces />}
            </TabPanel>
            <TabPanel value={tabValue} index={4} key={4}>
              <OtherInfo />
            </TabPanel>
            <TabPanel data-testid="transportation-panel" value={tabValue} index={5} key={5}>
              <Transportation />
            </TabPanel>
          </Box>
        </AccordionDetails>
      )}
    </Accordion>
  )
}
