import React, { FC, useMemo } from 'react'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import ToggleButton from '@mui/material/ToggleButton'

interface ToggleOption {
  id: string
  label: string
  active: boolean
  pendoId?: string
  disabled?: boolean
}

export type ToggleGroupOptions = ToggleOption[]
interface Props {
  ['data-testid']?: string
  options: ToggleGroupOptions
  exclusive?: boolean
  onChange: (value: string | string[]) => void
  size?: 'small' | 'medium' | 'large'
  stretchChildren?: boolean
}

export const ToggleGroup: FC<Props> = ({
  ['data-testid']: dataTestId,
  options,
  exclusive = true,
  onChange,
  size = 'medium',
  stretchChildren,
}) => {
  const active = useMemo(() => options.filter((o) => o.active).map((o) => o.id), [options])

  const onToggle = (_e: React.MouseEvent<HTMLDivElement, MouseEvent>, value: string | string[]) => {
    onChange(value)
  }

  return (
    <ToggleButtonGroup data-testid={dataTestId} onChange={onToggle} exclusive={exclusive} value={active} size={size}>
      {options.map((option) => (
        <ToggleButton
          key={option.id}
          value={option.id}
          data-testid={`toggle-button-${option.id}`}
          data-pendo-id={option.pendoId}
          aria-label={option.label}
          disabled={option.disabled}
          sx={{ flex: stretchChildren ? 1 : 'none' }}
        >
          {option.label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  )
}
