import React, { FC, useMemo, useState } from 'react'
import { Box, CircularProgress, Text, Tooltip, Menu, theme, Icon } from '@howgood/design'
import { RecipeIngredient } from '@/state/recipe/recipe.state'
import { SourceGrid } from './SourceGrid'
import { useDispatch, useSelector } from 'react-redux'
import { selectOriginLocations } from '@/state/originLocations'
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons'
import { fetchHelperV2 } from '@/api'
import { IngredientImpactV2 } from '@/components'
import { addMessage } from '@/state/messages'
import { SimpleSelect } from './SimpleSelect'
import { selectIsSupplierConnect } from '@/state/organization'

interface SourceSelectProps {
  ingredient: RecipeIngredient
}

const getCombinedScores = (impactScores: IngredientImpactV2[], selectedStandards: string[]) => {
  const results = impactScores.reduce((acc: IngredientImpactV2[], impactItem) => {
    // The API returns multiple items for each origin location: one for each selected standard
    // and one for all selected standards combined. For each origin location, include
    // only the item that has the combined standards (or no standards if none are selected)
    if (
      impactItem.standards.length === selectedStandards.length &&
      (selectedStandards.length === 0 || selectedStandards.includes(impactItem.standards[0]?.identifier))
    ) {
      return [...acc, impactItem]
    }
    return acc
  }, [])
  return results
}

export const SourceSelect: FC<SourceSelectProps> = ({ ingredient }) => {
  const originLocations = useSelector(selectOriginLocations)
  const isSupplierConnect = useSelector(selectIsSupplierConnect)
  const dispatch = useDispatch()

  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null)
  const [impactData, setImpactData] = useState<IngredientImpactV2[]>(null)

  const isInvalidSelection = useMemo(() => {
    const foundOrigin = originLocations.find(
      (ol) => ol.base_id === ingredient.base_id && ol.origin_location_id === ingredient.origin_location_id
    )
    if (!foundOrigin && originLocations.length) {
      return true
    }
    return false
  }, [originLocations, ingredient])

  // Get the impact score data and use it to populate the source selection grid
  // Do a new fetch on each click since the selected standards may have changed
  const handleButtonClicked = async (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    try {
      const standardsParam = ingredient.standards.length
        ? ingredient.standards.map((standard) => standard.identifier).join('&standard=')
        : 'NONE'
      const impactScores = await fetchHelperV2<IngredientImpactV2[]>({
        url: `ingredients/bases/${ingredient.base_id}/impact-score/?standard=${standardsParam}`,
      })
      const combinedScores = getCombinedScores(
        impactScores,
        ingredient.standards.map((standard) => standard.identifier || 'NONE')
      )
      setImpactData(combinedScores)
    } catch (e) {
      dispatch(
        addMessage({
          severity: 'error',
          message:
            'Error fetching ingredient impact scores. Please try again, or contact support@howgood.com if the problem persists.',
        })
      )
      setImpactData([])
    }
  }

  const handleClose = () => {
    setImpactData(null)
    setAnchorEl(null)
  }

  return (
    <>
      <Tooltip
        title={
          <Text>
            {isInvalidSelection
              ? `The source location (${ingredient.origin_location_id}) in the product definition is invalid. Select one of the available source locations.`
              : ingredient.origin_location_name}
          </Text>
        }
      >
        <>
          {isSupplierConnect && <SimpleSelect ingredient={ingredient} isInvalidSelection={isInvalidSelection} />}
          {!isSupplierConnect && (
            <Box
              id={`source-location-button-${ingredient.index}`}
              role="button"
              tabIndex={0}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              gap={1}
              width="100%"
              height={28}
              px={1}
              onClick={handleButtonClicked}
              border={1}
              bgcolor="white"
              borderColor={isInvalidSelection ? theme.palette.error.main : theme.palette.secondary.light}
              borderRadius={1}
              sx={{ cursor: 'pointer' }}
            >
              <Text textOverflow="ellipsis" color={isInvalidSelection ? 'error' : 'inherit'} noWrap>
                {ingredient.origin_location_name || '\u2014Invalid\u2014'}
              </Text>
              <Icon icon={faCaretDown} color={isInvalidSelection ? theme.palette.error.main : 'inherit'} />
            </Box>
          )}
        </>
      </Tooltip>
      <Menu
        id="source-location-grid-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{ '& .MuiPaper-root': { width: 800 } }}
      >
        {impactData ? (
          <SourceGrid ingredient={ingredient} impactData={impactData} handleClose={handleClose} />
        ) : (
          <Box display="flex" justifyContent="center" alignItems="center" flexGrow={1} height={100}>
            <Box mr={1}>
              <CircularProgress size={20} />
            </Box>
            <Text>Fetching impact scores</Text>
          </Box>
        )}
      </Menu>
    </>
  )
}
