import React, { ChangeEvent, FC, useState } from 'react'
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium'
import { InputSearchBar } from '@/components/InputSearchBar'

interface QuickFilterProps {
  gridApi: GridApiPremium
}

export const QuickFilter: FC<QuickFilterProps> = ({ gridApi }) => {
  const [filter, setFilter] = useState<string>('')

  return (
    <InputSearchBar
      placeholder="Quick Search..."
      value={filter}
      onChange={(event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        setFilter(value)
        gridApi.setQuickFilterValues([value])
      }}
    />
  )
}
