import React, { FC } from 'react'
import { Text, Stack } from '@howgood/design'
import { FormulaIconBadge } from '@/components/Shared/IngredientSearch/FormulaIconBadge'
import { FORMULA_ICON_BADGE } from '@/contentfully'

export const DataGranularitySpectrum: FC = () => {
  return (
    <Stack gap={1}>
      <Text variant="h4">Data Granularity</Text>
      <Stack direction="row" gap={2}>
        <Stack alignItems="center">
          <FormulaIconBadge badge={FORMULA_ICON_BADGE.purchasedMaterial} noMargin />
          <Text>Least Granular</Text>
        </Stack>
        <Stack>
          <FormulaIconBadge badge={FORMULA_ICON_BADGE.vendorVerified} noMargin />
        </Stack>
        <Stack alignItems="center">
          <FormulaIconBadge badge={FORMULA_ICON_BADGE.vendorVerifiedPlus} noMargin />
          <Text>Most Granular</Text>
        </Stack>
      </Stack>
    </Stack>
  )
}
