import { fetchElasticV2 } from '@/api'
import { FORMULATIONS_SEARCH_PATH } from '@/constants/config'
import { calculateMtPerYear, kgPerYear, generateMtPerYear } from '@/api/elastic/runtimeMappings'
import { estypes } from '@elastic/elasticsearch'
import { MappingRuntimeFieldType, SortCombinations } from '@elastic/elasticsearch/api/types'
import { Field } from '@/constants/impactScore'

interface ESProductData {
  productCount: estypes.SearchTotalHits
  aggregations: Record<string, estypes.AggregationsAggregate>
}

interface SearchParams {
  workspaceIds: number[]
  metrics: Field[]
}

const generateEsFields = (metrics: Field[]) => [
  'pk',
  'name',
  'is_foreign',
  'ingredients.name',
  'ingredients.ingredient_origin_location_region_name',
  'ingredients.standards',
  'ingredients.weight',
  'ingredients.flat_weight',
  'ingredients.product_id',
  'ingredients.nested_product_id',
  // Specific `ingredient.impact_score` fields are added below
  'workspaces',
  ...metrics.map((metric) => `impact_score.${metric}`),
]

export async function fetchProductData(searchParams: SearchParams): Promise<ESProductData> {
  const { workspaceIds, metrics } = searchParams

  // In addition to the ESFIELDS, include the ingredient.impact_score field for each requested metric
  const impactMetrics = metrics.map((metric) => `ingredients.impact_score.${metric}`)
  const allESFields = [...generateEsFields(metrics), ...impactMetrics]

  const esResponse = await fetchElasticV2({
    url: FORMULATIONS_SEARCH_PATH,
    body: {
      size: 0,
      query: {
        bool: {
          filter: [{ terms: { 'workspaces.id': workspaceIds } }, { terms: { formulation_status: ['pipeline'] } }],
        },
      },
      runtime_mappings: {
        // Compute total mt_per_year for each product
        'user_inventories.mt_per_year': generateMtPerYear(workspaceIds),

        // Compute kg_per_year for each product
        'sales.kg_per_year': kgPerYear,

        // Compute metric * mt_per_year for each metric on each product
        ...metrics.reduce(
          (acc, metric) => ({
            ...acc,
            [`inventories.${metric}`]: {
              type: 'double' as MappingRuntimeFieldType,
              script: `
              if(doc['inventories.mt_per_year'].size() != 0 && doc['impact_score.${metric}'].size() != 0) {
                ${calculateMtPerYear(workspaceIds)}
                if (mt_per_year > 0) {
                  double value = doc['impact_score.${metric}'].value;
                  emit(mt_per_year * value);
                }
              }
            `,
            },
          }),
          {}
        ),

        // Compute metric * kg_per_year for each metric on each product
        ...metrics.reduce(
          (acc, metric) => ({
            ...acc,
            [`sales.${metric}`]: {
              type: 'double' as MappingRuntimeFieldType,
              script: `
              if(doc['annual_sales_volume'].size() != 0 && doc['weight_kg'].size() != 0 && doc['impact_score.${metric}'].size() != 0) {
                double volume = doc['annual_sales_volume'].value * doc['weight_kg'].value;
                if (volume > 0) {
                  emit(volume * doc['impact_score.${metric}'].value);
                }
              }
            `,
            },
          }),
          {}
        ),
      },

      // Include all runtime mapping fields here
      fields: [
        'user_inventories.mt_per_year',
        'sales.kg_per_year',
        ...metrics.map((metric) => `inventories.${metric}`),
        ...metrics.map((metric) => `sales.${metric}`),
      ],
      aggs: {
        ...metrics.reduce(
          (acc, metric) => ({
            ...acc,
            [`top5.kg.${metric}`]: {
              top_hits: {
                sort: [
                  {
                    [`impact_score.${metric}`]: {
                      order: 'desc',
                    },
                  },
                ] as SortCombinations[],
                _source: {
                  includes: [...allESFields],
                },
                size: 5,
              },
            },
            [`top5.inventories.${metric}`]: {
              top_hits: {
                sort: [
                  {
                    [`inventories.${metric}`]: {
                      order: 'desc',
                    },
                  },
                ] as SortCombinations[],
                _source: {
                  includes: [...allESFields, 'user_inventories.mt_per_year', `inventories.${metric}`],
                },
                size: 5,
              },
            },
            [`top5.sales.${metric}`]: {
              top_hits: {
                sort: [
                  {
                    [`sales.${metric}`]: {
                      order: 'desc',
                    },
                  },
                ] as SortCombinations[],
                _source: {
                  includes: [...allESFields, 'sales.kg_per_year', `sales.${metric}`],
                },
                size: 5,
              },
            },
            // For each metric, compute the portfolio's total CO2e per kg and per year (inventories and sales)
            [`totals.kg.${metric}`]: {
              sum: {
                field: `impact_score.${metric}`,
              },
            },
            [`totals.inventories.${metric}`]: {
              sum: {
                field: `inventories.${metric}`,
              },
            },
            [`totals.sales.${metric}`]: {
              sum: {
                field: `sales.${metric}`,
              },
            },
          }),
          {}
        ),
      } as Record<string, estypes.AggregationsAggregationContainer>,
    },
  })

  const productCount = esResponse?.hits?.total as estypes.SearchTotalHits
  const aggregations = esResponse?.aggregations

  return { productCount, aggregations }
}
