import React, { FC, MutableRefObject } from 'react'
import { useSelector } from 'react-redux'
import { DataGridPremium, GridApi } from '@mui/x-data-grid-premium'
import { DataGridExportContainer } from '@/components/DataGrid'
import { selectAllProducts } from '@/state/products'
import { useScope3Columns } from '../hooks/useExcelExportAggRows'
import { useColumnVisibilityModel, useProductListColumns } from '../hooks/useProductListColumns'
import { ExportType } from './ExportDialog.types'

interface Props {
  gridApiRef: MutableRefObject<GridApi>
  exportType?: ExportType
}

export const ExportProductsGrid: FC<Props> = ({ gridApiRef, exportType }) => {
  const columns = useProductListColumns({ ignoreRenderCell: true })
  const columnVisibilityModel = useColumnVisibilityModel(columns)
  const scope3Columns = useScope3Columns()
  const productRowData = useSelector(selectAllProducts)

  return (
    <DataGridExportContainer>
      {/* This grid is for the products to be exported */}
      <DataGridPremium
        apiRef={gridApiRef}
        rows={productRowData}
        columns={exportType === 'download-scope3' ? scope3Columns : columns}
        columnVisibilityModel={exportType === 'download-scope3' ? undefined : columnVisibilityModel}
      />
    </DataGridExportContainer>
  )
}
