import { createSlice } from '@reduxjs/toolkit'
import { IProductStandard } from '@/records'
import { getProductStandards } from './productStandards.thunk'
import { getStandards } from './productStandards.utils'

export interface State {
  isLoading: boolean
  productStandards: IProductStandard[]
}
export const initialState: State = {
  isLoading: false,
  productStandards: [],
}

const productStandardsSlice = createSlice({
  name: 'productStandards',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProductStandards.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getProductStandards.fulfilled, (state, action) => {
      state.isLoading = false
      state.productStandards = getStandards(action.payload)
    })
    builder.addCase(getProductStandards.rejected, (state) => {
      state.isLoading = false
      state.productStandards = initialState.productStandards
    })
  },
})

export const productStandardsReducer = productStandardsSlice.reducer
