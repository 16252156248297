import { scope3Cat1Metric, scope3Cat4Metric, scope3FlagMetric } from '@/api/elastic/runtimeMappings'
import { CustomMetric } from './portfolioDashboard.types'
import { SCOPE_3_CATEGORY_1_FIELD, SCOPE_3_CATEGORY_4_FIELD, SCOPE_3_FLAG_FIELD } from '@/constants/impactScore'

// IMPORTANT! do not include a dot (.) in the custom metric name
export const customMetrics: CustomMetric[] = [
  // {
  //   key: 'custom_scope_3_total',
  //   title: 'Scope 3 Total',
  //   subtitle: 'Category 1 + Category 4',
  //   units: 'kg CO2e/kg',
  //   metrics: [
  //     { metric: scope3Cat1Metric, weight: 1 },
  //     { metric: scope3Cat4Metric, weight: 1 },
  //   ],
  // },
  {
    key: SCOPE_3_CATEGORY_1_FIELD,
    metrics: [{ metric: scope3Cat1Metric, weight: 1 }],
    title: 'Scope 3 Category 1',
  },
  {
    key: SCOPE_3_CATEGORY_4_FIELD,
    metrics: [{ metric: scope3Cat4Metric, weight: 1 }],
    title: 'Scope 3 Category 4',
  },
  {
    key: SCOPE_3_FLAG_FIELD,
    metrics: [{ metric: scope3FlagMetric, weight: 1 }],
    title: 'Scope 3 Flag',
  },
  // Example of a custom metric that combines multiple base metrics
  // {
  //   key: 'custom_ftg_emissions_and_water',
  //   title: 'Farm-to-Gate Emissions and Water Usage',
  //   subtitle: '',
  //   units: '',
  //   metrics: [
  //     { metric: 'carbon_footprint_farm_to_gate_impact', weight: 1 },
  //     { metric: 'raw_blue_water_usage_impact', weight: 0.001 },
  //   ],
  // },
]
