import React, { FC, useEffect, useState } from 'react'
import {
  Alert,
  Autocomplete,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Text,
  CircularProgress,
} from '@howgood/design'
import { batch, useDispatch, useSelector } from 'react-redux'
import { selectProductCertificationStandards } from '@/state/productStandards'
import { IProductStandard } from '@/records'
import { clearActiveBulkJob, getProducts, selectActiveBulkJob, updateScenarioProductStandards } from '@/state/products'
import { selectCurrentScenario } from '@/state/initiatives'
import { fetchHelperV2 } from '@/api'
import { getWorkspaceImpactScores } from '@/state/workspaces'

interface AddStandardsProps {
  productIds: number[]
  closeDialog: () => void
}

type RequestStatus = 'FAILURE' | 'RECEIVED' | 'REVOKED' | 'PENDING' | 'STARTED' | 'RETRY' | 'SUCCESS'

export const ScenarioAddStandardsDialog: FC<AddStandardsProps> = ({ productIds, closeDialog }) => {
  const standardOptions = useSelector(selectProductCertificationStandards)
  const scenario = useSelector(selectCurrentScenario)
  const activeBulkJob = useSelector(selectActiveBulkJob)
  const dispatch = useDispatch()

  const [selectedStandards, setSelectedStandards] = useState<IProductStandard[]>([])
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(null)

  useEffect(() => {
    const interval = activeBulkJob
      ? setInterval(async () => {
          let response: RequestStatus
          try {
            response = await fetchHelperV2({ url: `tasks/${activeBulkJob.jobId}/` })
          } catch (e) {
            response = 'FAILURE'
          }
          setRequestStatus(response)
          if (['SUCCESS', 'FAILURE', 'REVOKED'].includes(response)) {
            clearInterval(interval)
            setSelectedStandards([])
            batch(() => {
              dispatch(clearActiveBulkJob())
              if (response === 'SUCCESS') {
                dispatch(getProducts({}))
                dispatch(getWorkspaceImpactScores())
              }
            })
          }
        }, 1000)
      : null
    // Clear the interval timer on dismount
    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [activeBulkJob, dispatch])

  const handleSelectedOptionsChanged = (selectedOptions: IProductStandard[]) => {
    setSelectedStandards(selectedOptions)
  }

  const handleOKClicked = async () => {
    setRequestStatus('STARTED')
    const response = await dispatch(
      updateScenarioProductStandards({
        productIds: productIds,
        standards: selectedStandards,
        scenarioId: scenario.id,
      })
    )
    if ('error' in response) {
      setRequestStatus('FAILURE')
    }
  }

  return (
    <Dialog id="add-standards-dialog" open>
      <DialogTitle>Add standards</DialogTitle>
      <DialogContent>
        <Stack gap={2} mt={1}>
          <Autocomplete
            id="product-standards-autocomplete"
            aria-label="Product standards"
            disabled={requestStatus !== null}
            multiple
            value={selectedStandards}
            onChange={(_e, selectedOptions) => handleSelectedOptionsChanged(selectedOptions)}
            options={standardOptions}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip
                  id={`standard-chip-${index}`}
                  key={option.id}
                  label={option.title}
                  variant="outlined"
                  {...getTagProps({ index })}
                />
              ))
            }
            fullWidth
            renderInput={(params) => (
              <TextField {...params} placeholder={selectedStandards.length ? '' : 'Product standards'} />
            )}
            ListboxProps={{ id: 'product-standards-menu' }}
          />
          {requestStatus === 'STARTED' && (
            <Alert severity="info">
              <Stack gap={1}>
                <Text fontWeight="bold">
                  Updating {productIds.length} {productIds.length === 1 ? 'product' : 'products'}
                </Text>
                <Text>Please wait for the process to complete.</Text>
                <Stack direction="row" gap={2}>
                  <CircularProgress size={20} />
                  <Text>Updates in progress...</Text>
                </Stack>
              </Stack>
            </Alert>
          )}
          {requestStatus === 'SUCCESS' && (
            <Alert severity="success">
              <Stack direction="row" gap={2} alignItems="center">
                <Text>Updates complete</Text>
              </Stack>
            </Alert>
          )}
          {['FAILURE', 'REVOKED'].includes(requestStatus) && (
            <Alert severity="error">
              <Stack direction="row" gap={2} alignItems="center">
                <Text>Updates failed</Text>
              </Stack>
            </Alert>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        {!requestStatus && (
          <>
            <Button id="add-standards-cancel-button" onClick={() => closeDialog()}>
              Cancel
            </Button>
            <Button id="add-standards-ok-button" color="primary" onClick={handleOKClicked}>
              OK
            </Button>
          </>
        )}
        {requestStatus && requestStatus !== 'STARTED' && (
          <Button id="add-standards-close-button" color="primary" onClick={() => closeDialog()}>
            Close
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
