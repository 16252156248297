import React, { FC } from 'react'
import { Bar, BarChart, CartesianGrid, ReferenceLine, ResponsiveContainer, XAxis, YAxis } from 'recharts'
import { theme } from '@howgood/design'
import { Highlight, ProductCarbonFootprintData } from './types'
import { formatTick, getChartData } from './utils'

interface Props {
  data: ProductCarbonFootprintData
  domain: [number, number]
  ticks: number[]
  setHighlight: (highlight: Highlight) => void
}

export const ProductCarbonFootprintChart: FC<Props> = ({ data, domain, ticks, setHighlight }) => {
  const chartData = getChartData(data)

  return (
    <ResponsiveContainer width="100%" height={80}>
      <BarChart layout="vertical" width={300} height={80} data={[{ ...chartData, name: '' }]}>
        <CartesianGrid strokeDasharray="3 3" />
        <ReferenceLine x={0} stroke="#000" />
        <XAxis type="number" domain={domain} ticks={ticks} tickFormatter={formatTick} interval="preserveStartEnd" />
        <YAxis dataKey="name" type="category" hide />
        <Bar
          dataKey="cf_ftm_gate_annual_impact"
          fill={theme.palette.info.light}
          name={data.cf_ftm_gate_annual_impact.title}
          onMouseOver={() => setHighlight('all')}
          onMouseOut={() => setHighlight(null)}
        />
      </BarChart>
    </ResponsiveContainer>
  )
}
