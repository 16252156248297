import React, { useState, FC } from 'react'
import { useSelector } from 'react-redux'
import { Box, Stack, Text, Tooltip } from '@howgood/design'
import { selectSplitTreatmentEnabled } from '@/state/splitio'
import { ScoreCellDialog } from './ScoreCellDialog'

interface Props {
  title: string
  description: string
  units: string
}
export const ScoreHeader: FC<Props> = ({ title, description, units }) => {
  const [open, setOpen] = useState(false)
  const dialogEnabled = useSelector(selectSplitTreatmentEnabled('show-data-sources'))

  return (
    <>
      <ScoreCellDialog
        open={open}
        title={title}
        description={description}
        units={units}
        onClose={() => setOpen(false)}
      />
      <Tooltip title={<Text>{title}</Text>}>
        <Stack width="100%">
          <Box
            data-testid="score-title"
            onClick={() => {
              if (dialogEnabled) {
                setOpen(true)
              }
            }}
            sx={dialogEnabled ? { cursor: 'pointer', '& :hover': { textDecoration: 'underline' } } : null}
          >
            <Text textOverflow="ellipsis" textAlign="center" noWrap>
              {title}
            </Text>
          </Box>
          <Text data-testid="score-units" textOverflow="ellipsis" textAlign="center" fontSize={12} noWrap>
            {units}
          </Text>
        </Stack>
      </Tooltip>
    </>
  )
}
