import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Switch, FormControlLabel } from '@howgood/design'
import { selectShowMaterialDirectory, toggleMaterialDirectory } from '@/state/products'

export const ShowMaterialDirectory: FC = () => {
  const dispatch = useDispatch()
  const showMaterialDirectory = useSelector(selectShowMaterialDirectory)

  const onToggle = () => {
    dispatch(toggleMaterialDirectory())
  }

  return (
    <FormControlLabel
      data-testid="show-material-directory-toggle"
      control={<Switch checked={showMaterialDirectory} onChange={onToggle} />}
      label="Show Material Directory"
    />
  )
}
