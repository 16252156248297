import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium'
import { GridCsvExportOptions } from '@mui/x-data-grid-premium'
import { faArrowUpFromBracket } from '@fortawesome/pro-regular-svg-icons'
import { Button } from '@howgood/design'

import { selectisInitiativesLoading } from '@/state/initiatives'

interface ExportButtonProps {
  gridApiRef: React.MutableRefObject<GridApiPremium>
}

export const ExportButton: FC<ExportButtonProps> = ({ gridApiRef }) => {
  const isLoading = useSelector(selectisInitiativesLoading)

  const handleExportClicked = () => {
    // @ts-ignore includeColumnGroupsHeaders is a valid GridCsvExportOption but causes an error in the build pipeline
    gridApiRef.current.exportDataAsCsv({ includeColumnGroupsHeaders: false } as GridCsvExportOptions)
  }

  return (
    <Button
      data-testid="export-button"
      startIcon={faArrowUpFromBracket}
      disabled={isLoading}
      onClick={handleExportClicked}
    >
      Export
    </Button>
  )
}
