import React, { FC, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectDisplayedPackaging } from '@/state/recipe'
import { packagingUnitMap } from '../../panels/ProductInfo/Packaging/PackagingUnit'

export const Packaging: FC = () => {
  const displayedPackaging = useSelector(selectDisplayedPackaging)

  const packagings = useMemo(() => {
    return displayedPackaging.map((item) => ({
      id: item.id,
      type: { value: item.packaging_unit, label: packagingUnitMap[item.packaging_unit] },
      material: { value: item.material.id, label: item.material.name },
    }))
  }, [displayedPackaging])

  return (
    <>
      <h3>Packaging</h3>
      {packagings?.length ? (
        <table>
          <tbody>
            <tr>
              <th style={{ width: '50%' }}>Packaging Type</th>
              <th style={{ width: '50%' }}>Packaging Material</th>
            </tr>
            {packagings.map((packaging, idx) => (
              <tr key={packaging.id ?? `key${idx}`}>
                <td style={{ width: '50%' }}>{packaging.type.label}</td>
                <td>{packaging.material.label}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <>No packaging has been added</>
      )}
    </>
  )
}
