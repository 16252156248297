import React, { FC } from 'react'
import { Stack, Text } from '@howgood/design'

interface Props {
  name: string
}

export const ProductOverviewVendor: FC<Props> = ({ name }) => {
  if (!name) {
    return null
  }

  return (
    <Stack direction="row" alignItems="baseline" gap={0.3}>
      <Text fontWeight="bold">Vendor:</Text>
      <Text aria-label="product-overview-vendor-name">{name}</Text>
    </Stack>
  )
}
