import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Accordion, AccordionDetails, Button, Dialog, DialogActions, DialogContent, Text } from '@howgood/design'

import {
  selectIngredientsMoved,
  selectHasNoValidIngredients,
  selectDisplayedIngredientList,
  selectIsRecipeLoading,
} from '@/state/recipe'
import {
  selectCanEditProduct,
  selectIsImpactsOverridden,
  selectIsSupplierConnectCreateNew,
} from '@/state/productOverview'
import { IngredientsGrid } from './IngredientsGrid'
import { AccordionCardHeader } from '../../../../components/Scores/AccordionCardHeader'
import { selectIsSupplierConnect } from '@/state/organization'
import { DRAFT, selectPageView, setView } from '@/state/pageSettings'

export const Ingredients: FC = () => {
  const ingredientsMoved = useSelector(selectIngredientsMoved)
  const canEdit = useSelector(selectCanEditProduct)
  const hasNoValidIngredients = useSelector(selectHasNoValidIngredients)
  const impactsOverriddenProduct = useSelector(selectIsImpactsOverridden)
  const isSupplierConnectCreateNew = useSelector(selectIsSupplierConnectCreateNew)
  const isSupplierConnect = useSelector(selectIsSupplierConnect)
  const ingredientList = useSelector(selectDisplayedIngredientList)
  const isRecipeLoading = useSelector(selectIsRecipeLoading)
  const pageView = useSelector(selectPageView)
  const dispatch = useDispatch()

  // For SupplierConnect only -- if products are preloaded without ingredients there's a dialog that prompts the user to add them
  const [displayNoIngredientsDialog, setDisplayNoIngredientsDialog] = useState(false)

  useEffect(() => {
    if (isSupplierConnect && canEdit && !isRecipeLoading && pageView !== 'Draft' && !ingredientList?.length) {
      setDisplayNoIngredientsDialog(true)
    }
  }, [isSupplierConnect, isRecipeLoading, ingredientList?.length, pageView, canEdit])

  if ((!canEdit && hasNoValidIngredients) || impactsOverriddenProduct) {
    return null
  }

  const handleEditFormulaDetailsClicked = () => {
    setDisplayNoIngredientsDialog(false)
    dispatch(setView(DRAFT))
  }

  return (
    <>
      <Accordion defaultExpanded={!isSupplierConnectCreateNew}>
        <AccordionCardHeader
          id="formula-detail-header-panel"
          title={
            isSupplierConnectCreateNew
              ? 'Add Ingredient Information'
              : isSupplierConnect
              ? 'Ingredients'
              : 'Formula Details'
          }
          compareTo={!ingredientsMoved}
        />
        <AccordionDetails>
          <IngredientsGrid />
        </AccordionDetails>
      </Accordion>
      <Dialog id="supplier-connect-dialog" open={displayNoIngredientsDialog}>
        <DialogContent>
          <Text>
            Click to edit formula below to start adding ingredient, packaging, and distribution details to calculate the
            footprint of this product.
          </Text>
        </DialogContent>
        <DialogActions>
          <Button id="supplier-connect-dialog-cancel-button" onClick={() => setDisplayNoIngredientsDialog(false)}>
            Cancel
          </Button>
          <Button id="supplier-connect-dialog-ok-button" color="primary" onClick={handleEditFormulaDetailsClicked}>
            Edit Formula Details
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
