import React, { FC, useState } from 'react'
import { Alert, Button } from '@howgood/design'
import { useProductIsStale } from './StaleProductAlert.hooks'
import { fetchProductRefresh } from './StaleProductAlert.requests'

interface Props {
  productId: number
}

export const StaleProductAlert: FC<Props> = ({ productId }) => {
  const { data } = useProductIsStale(productId)
  const [loading, setLoading] = useState(false)

  /**
   * Refresh metric calculations for a product that has been deemed "stale"
   */
  async function handleRefresh() {
    setLoading(true)
    await fetchProductRefresh(productId)
    setLoading(false)
    // Reload page to show fresh impact scores
    window.location.reload()
  }

  if (!data?.stale) {
    return null
  }

  return (
    <Alert
      data-testid="stale-product-alert"
      severity="warning"
      action={
        <Button data-testid="refresh-product-button" size="small" loading={loading} onClick={handleRefresh}>
          Refresh data
        </Button>
      }
    >
      The data for this product does not reflect the latest updates to Latis.
    </Alert>
  )
}
