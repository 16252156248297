import React, { FC, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ToggleGroupOptions, ToggleGroup, Stack } from '@howgood/design'

import { selectIsThirdParty, selectProductIsLocked, selectCanEditProduct } from '@/state/productOverview'
import { DRAFT, PUBLISHED, SAVED, selectPageView, setView, View } from '@/state/pageSettings'
import { selectIsEditing } from '@/state/recipe'
import { EditButton } from './EditButton'
import { ResetButton } from './ResetButton'

export const ProductViewToggle: FC = () => {
  const dispatch = useDispatch()
  const pageView = useSelector(selectPageView)
  const editing = useSelector(selectIsEditing)
  const isThirdParty = useSelector(selectIsThirdParty)
  const canViewPublished = useSelector(selectProductIsLocked)
  const canEdit = useSelector(selectCanEditProduct)

  const toggleOptions: ToggleGroupOptions = useMemo(() => {
    const toggleGroupOptions = []
    if (!isThirdParty) {
      toggleGroupOptions.push({
        id: SAVED,
        label: SAVED,
        active: pageView === SAVED,
      })
    }
    if (canViewPublished) {
      toggleGroupOptions.push({
        id: PUBLISHED,
        label: canEdit ? 'Locked' : PUBLISHED,
        active: pageView === PUBLISHED,
      })
    }
    if (canEdit && (editing || pageView === DRAFT)) {
      toggleGroupOptions.unshift({
        id: DRAFT,
        label: DRAFT,
        active: pageView === DRAFT,
      })
    }
    return toggleGroupOptions
  }, [isThirdParty, pageView, canEdit, canViewPublished, editing])

  const showToggle = toggleOptions.length > 1

  return (
    <Stack direction="row" gap={1}>
      {showToggle && <ResetButton />}
      {!editing && <EditButton />}
      {showToggle && (
        <ToggleGroup
          options={toggleOptions}
          size="small"
          onChange={(prodState: View) => {
            dispatch(setView(prodState))
          }}
        />
      )}
    </Stack>
  )
}
