import isEmpty from 'lodash/isEmpty'
import { estypes } from '@elastic/elasticsearch'
import { ELASTIC_SEARCH_LIMIT_DEFAULT } from '@/constants/config'
import { getSessionData, removeSessionData } from '@/utils/sessionData'
import { FetchV2PaginatedResponse } from './types'

type Message = { message: string }
type DetailString = { detail: string }
type ValidationBody = {
  detail: [
    {
      loc: [string, string]
      msg: string
      type: string
    }
  ]
}
type ErrorBody = Message | DetailString | ValidationBody

const handleFetchError = async (response: Response) => {
  const status = response.status
  const url = response.url
  let errorMessage = `${status}: There has been an error with this fetch request. ${url}`
  try {
    const textResponse = response
    const errorText = await textResponse.text()
    return `${errorText} (Status ${status}: ${url})`
  } catch (e) {
    console.error(e)
  }
  try {
    const errorBody: ErrorBody = await response.json()
    if (typeof errorBody === 'string') {
      errorMessage = errorBody
    } else if ('message' in errorBody) {
      errorMessage = errorBody.message
    } else if ('detail' in errorBody) {
      if (typeof errorBody.detail === 'string') {
        errorMessage = errorBody.detail
      } else if (errorBody.detail.length) {
        errorMessage = errorBody.detail
          .map((detail) => {
            const loc = detail.loc ? detail.loc.join(' - ') : 'Error'
            const message = detail.msg || ''
            return `${loc}: ${message}`
          })
          .join('. ')
      }
    } else if (!isEmpty(errorBody)) {
      errorMessage = JSON.stringify(errorBody)
    }

    return `${errorMessage} (Status ${status}: ${url})`
  } catch (e) {
    console.error(e)
    return `${response} (Status ${status}: ${url})`
  }
}

const parseUrl = (url: string): string =>
  ['_settings', '_close', '_open', '_search', '_msearch', 'ingredient_tokens', 'rules', 'http', 'https'].find((elem) =>
    url.includes(elem)
  )
    ? url
    : `${process.env.REACT_APP_API_URL}${url.replace(process.env.REACT_APP_API_URL || '', '')}`

export async function fetchHelper<ReturnType>(
  url: string,
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' = 'GET',
  headers: any = null,
  body: any = null,
  parse: any = true
): Promise<ReturnType> {
  return await fetch(parseUrl(url), {
    mode: 'cors',
    method,
    headers: new Headers({
      Accept: 'application/json',
      Authorization: `Bearer ${getSessionData().access_token}`,
      ...headers,
    }),
    ...(body ? { body } : {}),
  })
    .then(async (res) => {
      if (!res.ok) {
        const errorInfo = await handleFetchError(res)
        throw new Error(errorInfo)
      }
      return parse ? res.json() : res
    })
    .then((resp) => resp)
}

interface FetchV2 {
  url: string
  method?: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'
  headers?: object
  body?: string
  parse?: boolean
  signal?: AbortSignal
}
export async function fetchHelperV2<ReturnType>({
  url,
  method = 'GET',
  headers = {},
  body = '',
  parse = true,
  signal = null,
}: FetchV2): Promise<ReturnType> {
  return await fetch(`${process.env.REACT_APP_API_V2_URL}/${url}`, {
    mode: 'cors',
    signal,
    method,
    headers: new Headers({
      Accept: 'application/json',
      ['Content-Type']: 'application/json',
      Authorization: `Bearer ${getSessionData().access_token}`,
      ...headers,
    }),
    ...(body ? { body } : {}),
  })
    .then(async (res) => {
      if (!res.ok) {
        const errorInfo = await handleFetchError(res)
        if (errorInfo.includes('Not logged in')) {
          removeSessionData()
          location.reload()
        } else {
          throw new Error(errorInfo)
        }
      }
      return parse ? res.json() : res
    })
    .then((resp) => resp)
}

export async function fetchAllV2<ReturnTypeItem>({
  url,
  method = 'GET',
  headers = {},
  body = '',
  parse = true,
  signal = null,
}: FetchV2): Promise<ReturnTypeItem[]> {
  const allData: ReturnTypeItem[] = []
  let page = 1
  let pages: number
  do {
    const paginationQuery = `page=${page}&size=100`
    const urlWithParams = url.includes('?') ? `${url}&${paginationQuery}` : `${url}?${paginationQuery}`
    const response = await fetchHelperV2<FetchV2PaginatedResponse<ReturnTypeItem>>({
      url: urlWithParams,
      method,
      headers,
      body,
      parse,
      signal,
    })
    allData.push(...response.items)
    page++
    pages = response.pages
  } while (pages >= page)
  return allData
}

export async function fetchElasticV2<ReturnType>({
  url,
  body,
  signal,
}: {
  url: string
  body: string | estypes.SearchRequest['body'] | estypes.ScrollRequest['body']
  signal?: AbortSignal
}): Promise<estypes.SearchResponse<ReturnType>> {
  return await fetchHelperV2<estypes.SearchResponse<ReturnType>>({
    url: `elastic/${url}`,
    method: 'POST',
    body: typeof body === 'string' ? body : JSON.stringify(body),
    signal,
  })
}

interface FetchBulkElasticV2<ReturnType> {
  hits: estypes.SearchHit<ReturnType>[] | null
  total: number
  aggregations: Record<string, estypes.AggregationsAggregate>
}

export async function fetchBulkElasticV2<ReturnType>({
  url,
  body,
  signal,
  sendCount,
}: {
  url: string
  body: string | estypes.SearchRequest['body']
  signal?: AbortSignal
  sendCount?: (progress: { count: number; total: number }) => void
}): Promise<FetchBulkElasticV2<ReturnType>> {
  let hits: estypes.SearchHit<ReturnType>[] = null
  let total: number = 0
  let response: estypes.SearchResponse<ReturnType> = null
  try {
    if (typeof body === 'string') {
      response = await fetchElasticV2({
        url,
        body,
        signal,
      })
      hits = response.hits?.hits || []
      total = (response.hits?.total as estypes.SearchTotalHits).value
    } else {
      let from = body?.from || 0
      const size = body.size || ELASTIC_SEARCH_LIMIT_DEFAULT
      let newHits: estypes.SearchHit<ReturnType>[] = []
      do {
        response = await fetchElasticV2({
          url,
          body: {
            ...body,
            from,
            size,
          },
          signal,
        })
        from += size
        newHits = newHits.concat(response.hits?.hits || [])
        // Report progress back to the requestor, if callback function provided
        if (sendCount)
          sendCount({ count: newHits.length, total: (response.hits?.total as estypes.SearchTotalHits).value })
      } while (response.hits?.hits.length)
      hits = newHits
      total = newHits.length
    }
  } catch (e) {
    console.log(e)
  }
  return { hits, total, aggregations: response?.aggregations || null }
}
