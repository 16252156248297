import React, { FC, useMemo } from 'react'
import { Stack, Badge, Tooltip, StatusIcon } from '@howgood/design'

import { CONFIRMED, DENIED, REQUESTED, SHARE_CONFIRMED, SHARE_DENIED, SHARE_REQUESTED, ValidationRequest } from '@/api'
import { SharingRequest } from '@/records'

interface Props {
  requests: (ValidationRequest | SharingRequest)[]
}

export const RequestCountRow: FC<Props> = ({ requests }) => {
  // Determine how many requests of each status type exist (used for the icon badges)
  const counts = useMemo(
    () =>
      requests.reduce(
        (acc, request) => ({
          requested: acc.requested + ([REQUESTED, SHARE_REQUESTED].includes(request.status) ? 1 : 0),
          confirmed: acc.confirmed + ([CONFIRMED, SHARE_CONFIRMED].includes(request.status) ? 1 : 0),
          denied: acc.denied + ([DENIED, SHARE_DENIED].includes(request.status) ? 1 : 0),
        }),
        { requested: 0, confirmed: 0, denied: 0 }
      ),
    [requests]
  )

  return (
    <Stack direction="row" spacing={2}>
      {counts.requested > 0 && (
        <Tooltip title={`${counts.requested} awaiting confirmation`}>
          <Badge badgeContent={counts.requested} color="info" fontSize="sm" invisible={counts.requested < 2}>
            <StatusIcon status="pending" />
          </Badge>
        </Tooltip>
      )}
      {counts.confirmed > 0 && (
        <Tooltip title={`${counts.confirmed} confirmed`}>
          <Badge badgeContent={counts.confirmed} color="success" fontSize="sm" invisible={counts.confirmed < 2}>
            <StatusIcon status="confirmed" />
          </Badge>
        </Tooltip>
      )}
      {counts.denied > 0 && (
        <Tooltip title={`${counts.denied} denied`}>
          <Badge badgeContent={counts.denied} color="error" fontSize="sm" invisible={counts.denied < 2}>
            <StatusIcon status="denied" />
          </Badge>
        </Tooltip>
      )}
    </Stack>
  )
}
