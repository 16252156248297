import React, { FC, useState } from 'react'
import { faCheck, faEyeSlash } from '@fortawesome/pro-regular-svg-icons'
import { Box, Icon, Stack, Text, theme } from '@howgood/design'
import { formatScore } from '../CarbonAccounting.utils'

interface Props {
  title: string
  subtitle?: string
  score: number
  units: string
  clickable?: boolean
  complete?: boolean
  notApplicable?: boolean
}

export const CarbonAccountingCategory: FC<Props> = ({
  title,
  subtitle,
  score,
  units,
  clickable,
  complete,
  notApplicable,
}) => {
  const [hover, setHover] = useState(false)
  const textColor = notApplicable ? 'text.secondary' : 'text.primary'
  const textColorSecondary = notApplicable ? 'text.disabled' : 'text.secondary'

  return (
    <Box
      data-testid="carbon-accounting-category-container"
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      m={0.5}
      p={0.75}
      border={clickable ? 1 : 0}
      borderColor={hover ? 'secondary.light' : 'divider'}
      borderRadius={1}
      onMouseOver={() => {
        if (clickable) setHover(true)
      }}
      onMouseOut={() => setHover(false)}
      sx={{ cursor: clickable ? 'pointer' : null }}
    >
      <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
        <Stack>
          <Stack flexDirection="row" alignItems="center" gap={1}>
            <Text data-testid="carbon-accounting-category-title" color={textColor}>
              {title}
            </Text>
            {(complete || notApplicable) && (
              <Icon
                data-testid="carbon-accounting-category-icon"
                icon={notApplicable ? faEyeSlash : faCheck}
                color={notApplicable ? theme.palette.text.disabled : theme.palette.success.main}
                size="sm"
              />
            )}
          </Stack>
          <Text data-testid="carbon-accounting-category-subtitle" variant="caption" color={textColorSecondary}>
            {subtitle}
          </Text>
        </Stack>
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
        <Stack>
          <Text data-testid="carbon-accounting-category-score" fontWeight={600} textAlign="right" color={textColor}>
            {formatScore(score)}
          </Text>
          <Text
            data-testid="carbon-accounting-category-units"
            variant="caption"
            textAlign="right"
            color={textColorSecondary}
          >
            {units}
          </Text>
        </Stack>
      </Box>
    </Box>
  )
}
