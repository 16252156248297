import React, { FC } from 'react'
import { Chip, Stack, Text, Tooltip } from '@howgood/design'
import { formatNumber } from '@howgood/utils'
import { IngredientSearchOption } from '@/records'
import { FormulaIconBadge } from '@/components/Shared/IngredientSearch/FormulaIconBadge'
import { useGetFormulaIconBadge } from '@/hooks/useFormulaIconBadge'
import { useSelector } from 'react-redux'
import { selectWorkspaces } from '@/state/workspaces'

interface IngredientOptionProps {
  data: IngredientSearchOption
}

export const IngredientOption: FC<IngredientOptionProps> = ({ data }) => {
  const workspaces = useSelector(selectWorkspaces)
  const getFormulaIconBadge = useGetFormulaIconBadge()

  const ingredientLabel = data?.label
  const vendorLabel = data?.raw?._source?.brand?.name
  const productData = data.raw._source

  const badge = getFormulaIconBadge({
    workspaces: productData.workspaces?.map((w) => w.id),
    material_types: data.raw._source?.material_types,
    isProduct: data.isProduct,
  })
  const inventories = data?.raw?._source?.inventories || []

  const score = productData.impact_score?.cf_ftm_gate_ct_verified_impact
  const roundedScore = formatNumber(score)

  const isScoreVisible = data.isProduct && score !== null && !isNaN(score)

  return (
    <Stack aria-label="Ingredient base" id="add-ingredient-menu" flexDirection="row" alignItems="center" width="100%">
      {ingredientLabel && (
        <Text minWidth={50} noWrap>
          {ingredientLabel}
        </Text>
      )}
      {badge && <FormulaIconBadge badge={badge} />}
      {inventories
        .filter((inventory) => inventory.internal_id)
        .map((inventory) => (
          <Tooltip
            key={inventory.internal_id}
            title={workspaces.find((workspace) => workspace.id === inventory.workspace_id)?.name}
          >
            <Stack mr={0.5}>
              <Chip label={inventory.internal_id} aria-label="Internal ID" color="info" size="small" />
            </Stack>
          </Tooltip>
        ))}
      {vendorLabel && (
        <Text variant="body2" color="secondary" noWrap>
          {vendorLabel}
        </Text>
      )}
      {isScoreVisible && (
        <Stack flex={1} alignItems="flex-end">
          <Text>{roundedScore}</Text>
        </Stack>
      )}
    </Stack>
  )
}
