import React, { FC } from 'react'
import { IFormulationSearchOption, IProduct } from '@/records'
import { useSelector } from 'react-redux'
import {
  selectUserHasAccessToClaims,
  selectUserProductsPermissions,
  selectAttributePermissions,
} from '@/state/user/user.selectors'
import { selectProductNutrition } from '@/state/nutrition'
import { useIndustryBenchmark, usePortfolioAttributes } from '@/api/elastic/industryBenchmarkEsRepo'
import { Stack, Text } from '@howgood/design'
import { PERMISSIONS_TO_FIELDS_MAP } from '@/constants/impactScore'
import { ContentfulFieldKey } from '@/contentfully'
import { OtherClaims } from './OtherClaims'
import { ScorecardImpactScore } from '@/components/ProductImpact/ExportProductOverview/ScorecardImpactScore'
import { ScorecardOverviewTitle } from '@/components/ProductImpact/ExportProductOverview/ScorecardOverviewTitle'
import { ImpactScore } from '@/api'
import { Organization } from '@/records'
import { CategoryType } from '@/components/ProductImpact/ExportProductOverview/CategoryType'
import { HowgoodPoweredClaims } from './HowgoodPoweredClaims'
import { useContentfulMetric } from '@/contentfully/hooks'
import { ProductOverviewVendor } from './ProductOverviewVendor'
import { FunctionalCategoryOption, SalesCategoryOption } from '@/constants/industryCategories'

interface ProductOverviewProps {
  product: IProduct | null
  category: SalesCategoryOption | FunctionalCategoryOption
  categoryType: CategoryType
  organization?: Organization
  enableClaims?: boolean
  impactScore: ImpactScore | null
  comparisonProduct?: IFormulationSearchOption
  comparisonProductImpactScore?: ImpactScore | null
  workspaceIds: number[]
  formulationTag: string
  mainField: string
}

export const ProductOverview: FC<ProductOverviewProps> = ({
  product,
  category,
  categoryType,
  impactScore,
  comparisonProduct,
  comparisonProductImpactScore,
  enableClaims = true,
  organization,
  workspaceIds,
  formulationTag,
  mainField,
}) => {
  const productNutrition = useSelector(selectProductNutrition)

  const hasAccessToClaims = useSelector(selectUserHasAccessToClaims)
  const attributesPermissions = useSelector(selectAttributePermissions)

  let displayField: ContentfulFieldKey = (impactScore?.[mainField] != null
    ? mainField
    : 'howgood_one_metric') as ContentfulFieldKey

  if (comparisonProduct && displayField !== 'howgood_one_metric' && comparisonProductImpactScore) {
    displayField = (comparisonProductImpactScore?.[mainField] != null
      ? mainField
      : 'howgood_one_metric') as ContentfulFieldKey
  }

  const allAttributes = attributesPermissions.map((permission) => ({
    field: PERMISSIONS_TO_FIELDS_MAP[permission],
    value: true,
  }))
  const nonComparisonAttributes = product ? allAttributes.filter((attr) => impactScore[attr.field]) : allAttributes

  const comparisonAttributes = comparisonProduct
    ? allAttributes.filter((attr) => impactScore?.[attr.field] || comparisonProductImpactScore?.[attr.field])
    : []

  const displayFieldContent = useContentfulMetric(displayField)

  const permissions = useSelector(selectUserProductsPermissions)

  const { data: mainMetricBenchmarkData, isLoading: isMainMetricLoading } = useIndustryBenchmark({
    workspaceIds,
    formulationTag,
    categoryType,
    category: category.value ? category.label : null,
    field: 'howgood_one_metric',
  })

  const { data: attributesData, isLoading: isLoadingAttributes } = usePortfolioAttributes(
    workspaceIds,
    formulationTag,
    nonComparisonAttributes.map((attr) => attr.field)
  )

  const ecoScore = impactScore?.eco_score_impact
  const nutriScore = productNutrition?.nutrition_score

  const howgoodRating = impactScore?.retail_rating_impact
  const comparisonProductHowgoodRating = comparisonProductImpactScore?.retail_rating_impact

  const hasAtLeastOneOtherClaim = ecoScore != null || nutriScore != null
  const showOtherClaims = enableClaims && hasAccessToClaims && hasAtLeastOneOtherClaim

  const hasAtLeastOneHowGoodPoweredClaim = Boolean(nonComparisonAttributes.length > 0 || !!howgoodRating) // don't accept 0 as value for HG Rating
  const hasAtLeastOneHowGoodPoweredClaimForComparison = Boolean(
    comparisonAttributes.length > 0 || comparisonProductHowgoodRating || howgoodRating
  ) // don't accept 0 as value for HG Rating

  const showClaimsSection = showOtherClaims || hasAtLeastOneHowGoodPoweredClaim
  const showClaimsSectionForComparison = !!hasAtLeastOneHowGoodPoweredClaimForComparison

  const aggregateImpactScore = { [displayField]: mainMetricBenchmarkData.hgPortfolioAverage.score }

  const howGoodPoweredClaimsTitle =
    product || comparisonProduct
      ? 'HowGood-powered Claims'
      : 'Percentage of Products that Qualify for Sustainability Claims'

  const isDisplayFieldCarbonFootprint: boolean = ['supplier_cf_ftm_gate_impact', 'cpg_cf_f2mg_w_pkg_impact'].includes(
    displayField
  )

  const isDisplayFieldBlueWaterUsage: boolean = displayField === 'raw_blue_water_usage_impact'

  const showScorecardImpactScore = !!displayFieldContent
  const scorecardImpactScoreTitle = displayFieldContent?.title
  const scorecardImpactScoreUrl =
    !isDisplayFieldCarbonFootprint &&
    !isDisplayFieldBlueWaterUsage &&
    'https://latis-help.howgood.com/en/articles/5595721-howgood-impact-score'
  const scorecardImpactScoreTitleFormatted = isDisplayFieldCarbonFootprint
    ? scorecardImpactScoreTitle + '*'
    : scorecardImpactScoreTitle

  let scorecardImpactScoreCmpWidth = '280px'
  if (isDisplayFieldCarbonFootprint) {
    scorecardImpactScoreCmpWidth = '360px'
  }
  if (isDisplayFieldBlueWaterUsage) {
    scorecardImpactScoreCmpWidth = '380px'
  }

  if (comparisonProduct) {
    return (
      <>
        <Stack direction="row" alignItems="flex-start" gap={2} sx={{ marginBottom: '8px' }}>
          <ScorecardOverviewTitle width="165px" title="Product Name" />
          {showScorecardImpactScore && (
            <ScorecardOverviewTitle
              width={scorecardImpactScoreCmpWidth}
              title={scorecardImpactScoreTitleFormatted}
              url={scorecardImpactScoreUrl}
            />
          )}
          {showClaimsSectionForComparison && (
            <ScorecardOverviewTitle
              title={howGoodPoweredClaimsTitle}
              url="https://latis-help.howgood.com/en/collections/3491297-communicating-product-sustainability#sustainability-attributes"
            />
          )}
        </Stack>
        <Stack
          direction="row"
          alignItems="flex-start"
          gap={2}
          sx={{ marginBottom: '8px', borderBottom: '1px solid #EBEBEB' }}
        >
          <Stack minWidth="165px" width="165px">
            <Text>{product.name || organization.organizationName}</Text>
            <ProductOverviewVendor name={product.brand?.name} />
          </Stack>
          {!isMainMetricLoading && showScorecardImpactScore && (
            <ScorecardImpactScore
              impactScore={impactScore}
              field={displayField}
              isCarbonFootprint={isDisplayFieldCarbonFootprint}
              isBlueWaterUsage={isDisplayFieldBlueWaterUsage}
              width={scorecardImpactScoreCmpWidth}
              minWidth={scorecardImpactScoreCmpWidth}
              hideTitle
            />
          )}
          {!isLoadingAttributes && showClaimsSectionForComparison && (
            <HowgoodPoweredClaims
              howgoodRating={howgoodRating}
              howgoodRatingWidth="120px"
              showHowgoodRating={Boolean(comparisonProductHowgoodRating || howgoodRating)}
              withHowgoodRatingDescription={false}
              withAttributeDescription={false}
              permissions={permissions}
              attributes={comparisonAttributes.map((attr) => ({ ...attr, value: impactScore[attr.field] }))}
              attributeWidth="120px"
              portfolioPercentage={null}
            />
          )}
        </Stack>
        <Stack direction="row" alignItems="flex-start" gap={2} sx={{ marginBottom: '8px' }}>
          <Stack minWidth="165px" width="165px">
            <Text>{comparisonProduct.label}</Text>
            <ProductOverviewVendor name={comparisonProduct.raw?.brand?.name} />
          </Stack>
          {!isMainMetricLoading && showScorecardImpactScore && (
            <ScorecardImpactScore
              impactScore={comparisonProductImpactScore}
              field={displayField}
              isCarbonFootprint={isDisplayFieldCarbonFootprint}
              isBlueWaterUsage={isDisplayFieldBlueWaterUsage}
              width={scorecardImpactScoreCmpWidth}
              minWidth={scorecardImpactScoreCmpWidth}
              hideTitle
            />
          )}
          {!isLoadingAttributes && showClaimsSectionForComparison && (
            <HowgoodPoweredClaims
              howgoodRating={comparisonProductHowgoodRating}
              howgoodRatingWidth="120px"
              showHowgoodRating={Boolean(comparisonProductHowgoodRating || howgoodRating)}
              howgoodRatingDescription="HowGood Rating"
              permissions={permissions}
              attributes={comparisonAttributes.map((attr) => ({
                ...attr,
                value: comparisonProductImpactScore[attr.field],
              }))}
              attributeWidth="120px"
              portfolioPercentage={null}
            />
          )}
        </Stack>
      </>
    )
  }

  return (
    <Stack direction="row" alignItems="flex-start" sx={{ marginBottom: '32px' }}>
      <Stack>
        {!isMainMetricLoading && (
          <ScorecardImpactScore
            impactScore={product ? impactScore : aggregateImpactScore}
            field={displayField}
            isCarbonFootprint={isDisplayFieldCarbonFootprint}
            isBlueWaterUsage={isDisplayFieldBlueWaterUsage}
          />
        )}
      </Stack>
      {showClaimsSection && (
        <Stack
          direction="row"
          flex={1}
          gap={9}
          sx={{
            marginLeft: '40px',
          }}
        >
          {hasAtLeastOneHowGoodPoweredClaim && (
            <Stack>
              <ScorecardOverviewTitle
                title={howGoodPoweredClaimsTitle}
                url="https://latis-help.howgood.com/en/collections/3491297-communicating-product-sustainability#sustainability-attributes"
              />
              {!isLoadingAttributes && (
                <HowgoodPoweredClaims
                  howgoodRating={howgoodRating}
                  permissions={permissions}
                  attributes={nonComparisonAttributes}
                  portfolioPercentage={!product ? attributesData : null}
                  hideEmptyAttributes={!product}
                />
              )}
            </Stack>
          )}
          {showOtherClaims && (
            <Stack>
              <ScorecardOverviewTitle title="Other Claims" />
              <OtherClaims ecoScore={ecoScore} nutriScore={nutriScore} permissions={permissions} />
            </Stack>
          )}
        </Stack>
      )}
    </Stack>
  )
}
