import { faScroll, faStamp, faShareNodes } from '@fortawesome/pro-solid-svg-icons'
import { StatusItemT } from '@howgood/design'

import {
  CONFIRMED,
  DENIED,
  NOT_STARTED,
  REQUESTED,
  SHARE_CONFIRMED,
  SHARE_DENIED,
  SHARE_NOT_STARTED,
  SHARE_REQUESTED,
  ValidationRequest,
} from '@/api'
import {
  lockedText,
  sharingText,
  validationText,
} from '../features/ProductPage/panels/Header/ValidationFlow/validationCopy'
import { SharingRequest } from '@/records'

const validationStatusMap = {
  [NOT_STARTED]: { status: 'none', text: 'Not validated' },
  [REQUESTED]: { status: 'pending', text: 'Validation pending' },
  [CONFIRMED]: { status: 'confirmed', text: 'Validated' },
  [DENIED]: { status: 'denied', text: 'Validation denied' },
}

const sharingStatusMap = {
  [SHARE_NOT_STARTED]: { status: 'none', text: 'Not shared' },
  [SHARE_REQUESTED]: { status: 'pending', text: 'Sharing pending' },
  [SHARE_CONFIRMED]: { status: 'confirmed', text: 'Shared' },
  [SHARE_DENIED]: { status: 'denied', text: 'Sharing denied' },
}

/**
 * Generate icon and display text for report status
 * @param isSaved
 * @param isProductLocked
 * @param additionalId
 * @returns StatusItemT
 */
export const generateReportStatusOption = (isSaved: boolean, isProductLocked: boolean, additionalId: string = '') => {
  const status = isSaved ? (isProductLocked ? 'confirmed' : 'none') : 'disabled'
  return {
    id: `report-status${additionalId}`,
    tooltipText: !isSaved
      ? 'Product must be saved before a report can be created'
      : isProductLocked
      ? lockedText.split('.')[0]
      : 'A report has not been generated.',
    icon: faScroll,
    status: status as StatusItemT['status'],
    statusText: '',
  }
}

/**
 * Generate icon and display text for share status
 * @param recentRequest
 * @param isProductLocked
 * @param additionalId
 * @returns StatusItemT
 */
export const generateShareStatusOption = (
  recentRequest: SharingRequest,
  isProductLocked: boolean,
  additionalId: string = ''
) => {
  const sharingStatus = recentRequest?.status || SHARE_NOT_STARTED
  const status = isProductLocked ? sharingStatusMap[sharingStatus].status : 'disabled'

  return {
    id: `share-status${additionalId}`,
    tooltipText: sharingText[sharingStatus],
    icon: faShareNodes,
    status: status as StatusItemT['status'],
    statusText: '',
  }
}

/**
 * Generate icon and display text for validation status
 * @param recentRequest
 * @param isProductLocked
 * @param additionalId
 * @returns StatusItemT
 */
export const generateValidationStatusOption = (
  recentRequest: ValidationRequest,
  isProductLocked: boolean,
  additionalId: string = ''
) => {
  const validationStatus = recentRequest?.status || NOT_STARTED
  const status = isProductLocked ? validationStatusMap[validationStatus].status : 'disabled'

  return {
    id: `validation-status${additionalId}`,
    tooltipText: validationText[validationStatus],
    icon: faStamp,
    status: status as StatusItemT['status'],
    statusText: '',
  }
}
