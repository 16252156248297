import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Text, TextField } from '@howgood/design'
import { selectPageView, DRAFT } from '@/state/pageSettings'
import { updateDraftInventory } from '@/state/productOverview'
import { InventoriesResponse } from '@/api'
import { selectWorkspaces } from '@/state/workspaces'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'

interface InternalIdProps extends GridRenderCellParams {
  value?: InventoriesResponse
}

export const InternalId: FC<InternalIdProps> = ({ value: inventory }) => {
  const dispatch = useDispatch()
  const view = useSelector(selectPageView)
  const workspaces = useSelector(selectWorkspaces)

  const [internalId, setInternalId] = useState(inventory.internal_id)

  const workspace = workspaces.find((wkspace) => wkspace.id === inventory.workspace.id)

  // Handle toggling from draft to saved view, etc.
  useEffect(() => {
    setInternalId(inventory.internal_id)
  }, [inventory.internal_id])

  const handleLeaveInternalId = () => {
    dispatch(updateDraftInventory({ id: inventory.id, fields: { internal_id: internalId } }))
  }

  if (view !== DRAFT || !workspace.edit) {
    return <Text>{inventory.internal_id || 'Not supplied'}</Text>
  }

  return (
    <TextField
      key={inventory.id}
      id="inventory-id-field"
      value={internalId}
      onChange={(e) => setInternalId(e.currentTarget.value)}
      onBlur={handleLeaveInternalId}
      onKeyDown={(event) => event.stopPropagation()} // Required to allow spaces in the textfield
    />
  )
}
