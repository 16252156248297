import { createSelector } from 'reselect'
import { ImpactScore } from '@/api'
import { AppState } from '@/store'
import { isImpactScoreNull } from '../recipe/recipe.utils'

export const selectProductMetrics = (state: AppState): ImpactScore => state.productMetrics.productMetrics

export const selectHasProductMetrics = createSelector(selectProductMetrics, (metrics) => {
  return !isImpactScoreNull(metrics)
})

export const selectProductMetricsLoading = (state: AppState): boolean => state.productMetrics.isLoading
