import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Button, Stack } from '@howgood/design'

import { selectSavedProductInfo } from '@/state/productOverview'
import { selectCurrentScenario, selectSourceProductVersionData } from '@/state/initiatives'
import { useNavigateToFormula } from '@/hooks'
import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons'

export const ScenarioViewSourceButton: FC = () => {
  const productInfo = useSelector(selectSavedProductInfo)
  const currentScenario = useSelector(selectCurrentScenario)
  const versionData = useSelector(selectSourceProductVersionData)
  const navigateToFormula = useNavigateToFormula()

  if (!currentScenario?.id && !productInfo.scenarios?.length && !versionData.sourceProductId) return null

  return (
    <Stack mb={0.4}>
      <Button
        variant="text"
        color="primary"
        size="small"
        startIcon={faArrowUpRightFromSquare}
        onClick={() => {
          navigateToFormula(versionData.sourceProductId.toString(), true)
        }}
      >
        View source
      </Button>
    </Stack>
  )
}
