import { fetchHelperV2 } from '@/api'

export interface ProductIsStaleResponse {
  stale: boolean
  note: string
}
export async function fetchProductIsStale(productId: number): Promise<ProductIsStaleResponse> {
  return await fetchHelperV2<ProductIsStaleResponse>({ url: `products/${productId}/impact-score/stale/` })
}

export async function fetchProductRefresh(productId: number): Promise<void> {
  await fetchHelperV2({ url: `products/${productId}/impact-score/refresh/` })
}
